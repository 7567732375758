import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, useHistoricalReportsInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import ExpandAll from 'components/ExpandSettings';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';

import { CompanyInformation } from '../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';

export function HistoricalReportsDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data: company } = useCompany(key);
    const { data: survey, isLoading } = useHistoricalReportsInfo(key);
    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, headingId: headingId });
  });

  const authContext = useAuthContext();
  const defaultExpand = authContext?.expand_collapse_settings['historical-reports'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  const [disable, setDisable] = React.useState<boolean>(false);

  return (
    <Page aria-label="Financial Post Historical Reports" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0].comp}
        hideHistReports={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Financial Post Historical Reports
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.comp?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>
        <Row>
          <div className="detail--page--card">
            {i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname={'historical-reports'} />
              </div>
            </HideOnPrint>
            }
            <Card mb="lg">
              <CompanyInformation isLoading={element.isLoading} company={element.comp} />
            </Card>

            {element.survey?.htmlComparativeData &&
              element.survey?.htmlComparativeData?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Comparative Data
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlComparativeData?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlQuickRefData &&
              element.survey?.htmlQuickRefData?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Quick Reference Data
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlQuickRefData?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlProfile &&
              element.survey?.htmlProfile?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Profile
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlProfile?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlOperations &&
              element.survey?.htmlOperations?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Operations
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlOperations?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlPerformanceAnalysis?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlRelatedCompanies &&
              element.survey?.htmlRelatedCompanies?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Related Companies
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlRelatedCompanies?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlHistory &&
              element.survey?.htmlHistory?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      History
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlHistory?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlDirectors &&
              element.survey?.htmlDirectors?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Directors &amp; Executives
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlDirectors?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlCapitalStock &&
              element.survey?.htmlCapitalStock?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Capital Stock
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlCapitalStock?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlPriceRange &&
              element.survey?.htmlPriceRange?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Price Range
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlPriceRange?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlCapitalStockChanges &&
              element.survey?.htmlCapitalStockChanges?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Capital Stock Changes
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlCapitalStockChanges?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlDividends &&
              element.survey?.htmlDividends?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Dividends
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlDividends?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlLongTermDebt &&
              element.survey?.htmlLongTermDebt?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Long-Term Debt
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlLongTermDebt?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlQuarterlyEarnings &&
              element.survey?.htmlQuarterlyEarnings?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Quarterly Earnings
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlQuarterlyEarnings?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlConsolidatedBalanceSheets &&
              element.survey?.htmlConsolidatedBalanceSheets?.body.innerHTML.length >
                0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Consolidated Balance Sheets
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlConsolidatedBalanceSheets?.body
                                .innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlSharesOutstanding &&
              element.survey?.htmlSharesOutstanding?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Shares Outstanding
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlSharesOutstanding?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlConsolidatedStatementsIRE &&
              element.survey?.htmlConsolidatedStatementsIRE?.body.innerHTML.length >
                0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Consolidated Statements Of Income and Retained Earnings
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlConsolidatedStatementsIRE?.body
                                .innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlEPS && element.survey?.htmlEPS?.body.innerHTML.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={expand}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Earnings Per Share
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Tiles columns={[1, 1]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: element.survey?.htmlEPS?.body.innerHTML || ''
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}

            {element.survey?.htmlDPS && element.survey?.htmlDPS?.body.innerHTML.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={expand}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Dividends Declared
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Tiles columns={[1, 1]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: element.survey?.htmlDPS?.body.innerHTML || ''
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}

            {element.survey?.htmlConsolidatedStatementsCashFlow &&
              element.survey?.htmlConsolidatedStatementsCashFlow?.body.innerHTML
                .length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Consolidated Statements Of Cash Flow
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlConsolidatedStatementsCashFlow?.body
                                .innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlCashFlowPerShare &&
              element.survey?.htmlCashFlowPerShare?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Cash Flow Per Share
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlCashFlowPerShare?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlHistoricalSummary &&
              element.survey?.htmlHistoricalSummary?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Historical Summary
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlHistoricalSummary?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hideHistReports={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
