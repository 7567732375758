import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, useInvestorReportsInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { PrintFriendly } from '../../../components/PrintFriendly';
import ExpandAll from 'components/ExpandSettings';
import { Col } from 'react-grid-system';
import {
  useAuthContext,
} from 'containers/AuthProvider';

import { CompanyInformation } from '../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';
import { StatisticsTable } from 'components/StatisticsTable/StatisticTable';
import { renderPriceOnDate, roundToTwo } from '../../../helpers';
import Alert from 'components/Alert';
import { right } from '@popperjs/core';
import { HideOnPrint } from 'components/HideOnMobile';

export function InvestorReportsDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  enum RatioModel {
    Dupont = '48'
  }
  keys.forEach((key: string) => {
    const { data: company } = useCompany(key);
    const { data: survey, isLoading } = useInvestorReportsInfo(key);
    const dupontmodel = survey?.data?.RECORD?.find((Fid) => {
      return Fid['@attributes'].fragid === RatioModel.Dupont;
    });
    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, dupontmodel: dupontmodel, headingId: headingId });
  });

  const authContext = useAuthContext();

  const defaultExpand = authContext?.expand_collapse_settings['investor-reports'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  const [disable, setDisable] = React.useState<boolean>(false);

  return (
    <Page aria-label="Financial Post Investor Reports" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0].comp}
        hideInvestorReports={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Financial Post Investor Reports
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.comp?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>
        <Row>
          <div className="detail--page--card">
            { i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname='investor-reports' />
              </div>
            </HideOnPrint>
            }
            <Card mb="lg">
              <CompanyInformation isLoading={element.isLoading} company={element.comp} />
            </Card>

            {element.survey?.htmlBusiness &&
              element.survey?.htmlBusiness?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Business
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlBusiness?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Share Price Statistics
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.survey?.htmlSharePriceStats?.body.innerHTML || ''
                      }}
                    />
                  </Tiles>
                  <StatisticsTable
                    columns={[
                      <>
                        Recent <br />
                        Price
                      </>,
                      <>
                        Recent <br />
                        Daily Volume
                      </>,
                      <>
                        Annual Avg <br />
                        Daily Volume
                      </>,
                      <>
                        52-wk <br />
                        range
                      </>,
                      <>
                        Shares <br />
                        outstanding
                      </>
                    ]}
                    rows={[
                      [
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.closings?.closing,
                          date: element.comp?.dailysnap?.closings?.closingdate,
                          qualifierParam: 'currency'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.volumes.volume,
                          date: element.comp?.dailysnap?.volumes.volumedate
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.volumes.volume52wkavg
                        }),
                        <>
                          {renderPriceOnDate({
                            price: element.comp?.dailysnap?.closings?.high52wk,
                            date: element.comp?.dailysnap?.closings?.high52wkdate,
                            qualifierParam: 'currency'
                          })}
                          <br />
                          {renderPriceOnDate({
                            price: element.comp?.dailysnap?.closings?.low52wk,
                            date: element.comp?.dailysnap?.closings?.low52wkdate
                          })}
                        </>,
                        renderPriceOnDate({
                          price:
                            Number(element.comp?.dailysnap?.shares.outstanding) *
                            1000
                        })
                      ]
                    ]}
                  />
                  <br />
                  <StatisticsTable
                    columns={[
                      <>
                        Market <br />
                        Capitalization
                      </>,
                      <>
                        {' '}
                        <br />
                        IAD
                      </>,
                      <>
                        Ex-Dividend
                        <br />
                        Date
                      </>,
                      <>
                        <br />
                        Yield
                      </>,
                      <>
                        <br />
                        Listed
                      </>,
                      <>
                        {' '}
                        price/
                        <br />
                        Book
                      </>
                    ]}
                    rows={[
                      [
                        renderPriceOnDate({
                          price: Number(element.comp?.dailysnap?.marketcap) * 1000,
                          qualifierParam: 'currency'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.dividend?.iad
                        }),
                        renderPriceOnDate({}),
                        renderPriceOnDate({}),
                        element.comp?.securityexchangelead?.securityexchange
                          .map(
                            (s: { [x: string]: { status: string; }; exchange: any; symbol: any; }) =>
                              `${s.exchange}/${s.symbol} ${
                                s['@attributes'].status === 'S' ? ' (s)' : ''
                              }`
                          )
                          .join(';') || '',
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.priceratio.perbookvalue
                        })
                      ]
                    ]}
                  />
                  <br />
                  <StatisticsTable
                    columns={[
                      <>
                        <br />
                        P/E
                      </>,
                      <>
                        %Below
                        <br />
                        High
                      </>,
                      <>
                        Book
                        <br />
                        Value
                      </>,
                      <>
                        12-mo
                        <br />
                        EPS
                      </>,
                      <>
                        3-yr
                        <br />
                        Return
                      </>,
                      <>
                        10-yr
                        <br />
                        Beta
                      </>
                    ]}
                    rows={[
                      [
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.priceratio?.peratio
                        }),
                        // renderPriceOnDate({ price: 100-(Number(company?.dailysnap?.closings))/ Number(company?.dailysnap?.closings.high52wk)*100}),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.closings.belowhigh,
                          qualifierParam: 'percentage'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.bookvalue,
                          qualifierParam: 'currency'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.eps.eps12mo,
                          qualifierParam: 'currency'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.return?.return3yr,
                          qualifierParam: 'percentage'
                        }),
                        renderPriceOnDate({
                          price: element.comp?.dailysnap?.beta.beta120mo
                        })
                      ]
                    ]}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            {/* )} */}
            {element.survey?.htmlValueSciences &&
              element.survey?.htmlValueSciences?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Value Sciences Inc. Recommendation
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlValueSciences?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlThomsonReuters &&
              element.survey?.htmlThomsonReuters?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      <span>
                        <span style={{ fontStyle: 'italic' }}>
                          Refinitiv I/B/E/S
                        </span>{' '}
                        Forecasts
                      </span>
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlThomsonReuters?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlRevenue &&
              element.survey?.htmlRevenue?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Revenue
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlRevenue?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlEPS && element.survey?.htmlEPS?.body.innerHTML.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={expand}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Earnings Per Share
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <Tiles columns={[1, 1]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: element.survey?.htmlEPS?.body.innerHTML || ''
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}
            {element.survey?.htmlLatestDividend &&
              element.survey?.htmlLatestDividend?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Latest Dividend Declared
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlLatestDividend?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlShortPosition &&
              element.survey?.htmlShortPosition.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Short Position
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlShortPosition?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlTop3MarketCap &&
              element.survey?.htmlTop3MarketCap?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Top 3 in Industry (by Market Capitalization)
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlTop3MarketCap?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {/* */}
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Dupont Model
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <Tiles columns={[1, 1]}>
                    {/*  <div
                      dangerouslySetInnerHTML={{
                        __html: survey?.htmlOperatingStats?.body.innerHTML || ''
                      }}
                    /> */}
                  </Tiles>{' '}
                  <br />
                  <StatisticsTable
                    columns={
                      element.dupontmodel?.RECORDSECTION?.RECORDSECTION?.find(
                        (col: { [x: string]: { colformat: string; }; }) => col['@attributes'].colformat === 'colhead'
                      )?.RECORDITEM.map((d: any) =>
                        typeof d === 'string' ? d.substring(0, 4) : ''
                      ) || []
                    }
                    rows={
                      element.dupontmodel?.RECORDSECTION?.RECORDSECTION?.filter(
                        (colf: { [x: string]: { colformat: string; }; }) => colf['@attributes'].colformat === 'colbody'
                      ).map((row: { RECORDITEM: any[]; }) => {
                        return (
                          row.RECORDITEM.map((col, index) => {
                            return index === 0
? (
                              <b>{col}</b>
                            )
: (
                              roundToTwo(Number(col))
                            );
                          }) || []
                        );
                      }) || []
                    }
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            {/* */}
            {element.survey?.htmlOperatingStats &&
              element.survey?.htmlOperatingStats?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Ratios
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlOperatingStats?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlSplitsConsolidations &&
              element.survey?.htmlSplitsConsolidations?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Splits/Consolidations
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlSplitsConsolidations?.body
                                .innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlLatestResults &&
              element.survey?.htmlLatestResults?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Latest Results
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlLatestResults?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.htmlRecentDevelopments &&
              element.survey?.htmlRecentDevelopments?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Recent Developments
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlRecentDevelopments?.body.innerHTML ||
                              ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.company}
            hideInvestorReports={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
