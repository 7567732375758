import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function CheckIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={[
        'M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM3 10L8 15L17 6L15.59 4.58L8 12.17L4.41 8.59L3 10Z',
      ]}
    />
  );
}
