import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useExternalDatabasesDetails } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from 'components/PrintFriendly';
import { externalDbBySlug } from '../../../data/searchDatabases';

export function ExternalDatabasesDetailsPage () {
  const params = useParams<{ dbCode: string; docKey: string }>();
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data, isLoading } = useExternalDatabasesDetails(key);
    const headingId = useId();
    companies.push({ data: data, isLoading: isLoading, headingId: headingId });
  });

  const dbFullName =
    externalDbBySlug(params.dbCode)?.name ||
    `External Database ${params.dbCode}`;

  return (
    <Page aria-label="External Databases" padding="none" width="full">
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              External Databases - {dbFullName} - Details
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            {/* <Text mt="sm" color="heading" loading={isLoading} loadingWidth={200} size={[ "xl", "xl", "xxl" ]} weight="semi-bold">{company?.name}</Text> */}
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div style={{ flexGrow: 1 }}>
            {i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '0px', marginBottom: '12px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
              </div>
            </HideOnPrint>
            }
            {element.data?.htmlInfo && element.data?.htmlInfo.body.innerHTML.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Details
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Tiles columns={[1, 1]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: element.data?.htmlInfo.body.innerHTML || '',
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}
          </div>
        </Row>
      </Page>
      )}
    </Page>
  );
}
