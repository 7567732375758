import React from 'react';
import './index.css';

interface Tooltip {
  title: string;
  children: React.ReactNode;
}

export const Tooltip: React.FC<Tooltip> = ({ title, children }) => {
  return (
    <div className="demo">
      <p>
        <a href="#" data-tooltip={title}>
          {children}
        </a>
      </p>
    </div>
  );
};
