import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { useFixedIncomeSearchResults } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { HideOnPrint } from 'components/HideOnMobile';
import { Link, useHistory } from 'react-router-dom';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { stringify } from 'query-string';

export function FixedIncomeSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    [
      'issuerName',
      'stockSymbol',
      'issueType',
      'dateInfo',
      'termsOfIssue',
      'market',
      'currency',
      'incorporation',
      'gics',
      'naic',
      'sic',
      'amountIssuedGT',
      'amountIssuedLT',
      'debtMaturity',
      'dbrsRatingLT',
      'dbrsRatingGT',
      'couponFeature',
      'couponPrcntMin',
      'couponPrcntMax',
      'optionCallable',
      'optionConvertible',
      'optionChangeControl',
      'savedSearchName',
      'page'
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );

  const sortOptions = [
    {
      id: 'issuer',
      name: 'Issuer Name',
    },
    {
      id: 'maturity',
      name: 'Maturity Date',
    },
  ];

  const [sortOption, setSortOption] = React.useState('issuer');
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const searchResults = useFixedIncomeSearchResults({
    issuerName: queryParams.issuerName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    issueType: queryParams.issueType || undefined,
    termsOfIssue: queryParams.termsOfIssue || undefined,
    market: queryParams.market || undefined,
    currency: queryParams.currency || undefined,
    incorporation: queryParams.incorporation || undefined,
    gics: queryParams.gics
      ? queryParams.gics.split(',')
      : undefined,
    naic: queryParams.naic || undefined,
    sic: queryParams.sic || undefined,
    amountIssuedGT: queryParams.amountIssuedGT || undefined,
    amountIssuedLT: queryParams.amountIssuedLT || undefined,
    debtMaturity: queryParams.debtMaturity || undefined,
    dbrsRatingLT: queryParams.dbrsRatingLT || undefined,
    dbrsRatingGT: queryParams.dbrsRatingGT || undefined,
    couponFeature: queryParams.couponFeature || undefined,
    couponPrcntMin: queryParams.couponPrcntMin || undefined,
    couponPrcntMax: queryParams.couponPrcntMax || undefined,
    optionCallable: queryParams.optionCallable === 'True',
    optionConvertible: queryParams.optionConvertible === 'True',
    optionChangeControl: queryParams.optionChangeControl === 'True',
    savedSearchName: queryParams.savedSearchName || undefined,
    dateInfo: queryParams.dateInfo || undefined,
    sort: sortOption || undefined,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;

    const handleSetPage = (page: number) => {
      const newPage = page + 1;
      const replaceUrl = '/fixed-income/results';
      const qs = {
        ...queryParams,
        page: newPage,
      };
      history.replace(`${replaceUrl}?${stringify(qs)}`, {
        ...(history.location.state as object),
      });
      setPage(newPage);
    };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/fixed-income/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: '/fixed-income/csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Financial Post Fixed Income - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/fixed-income/search"
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.issuerName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.issuerName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}
      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={4}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            sortOptions={sortOptions}
            sortOption={sortOption}
            setSortOption={setSortOption}
            history={props.history.location.state}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell align="left">
                  <Text>Issuer Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="center">
                  <Text>Issue Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="right">
                  <Text>Amount</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="center">
                  <Text>Maturity</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Text>{r.name}</Text>
                  </Table.Cell>

                  <Table.Cell minWidth="md">
                    <Link
                      to={`/fixed-income/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.lead}</Text>
                    </Link>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="right">
                    <Text>{r.amount}</Text>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="center">
                    <Text>{r.maturitydate}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
