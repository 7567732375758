import * as React from 'react';

/**
 * Prevents VoicerOver from splitting text up.  Be careful not to put semantic
 * elements inside here, such as links or images, etc.
 *
 * @see https://axesslab.com/text-splitting/
 * @see https://github.com/dequelabs/axe-core/issues/1597
 */
export function ScreenReaderAvoidSplitting (props: {
  children: React.ReactNode;
}) {
  return <span role="article">{props.children}</span>;
}
