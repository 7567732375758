import * as React from 'react';
import { Heading } from '../../components/Heading';
import { Page } from '../../components/Page';
import { useId } from '../../hooks';

export function OrderingProductsPage () {
  const headingId = useId();

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Ordering Products
      </Heading>
    </Page>
  );
}
