import * as React from 'react';

export interface IconBaseProps {
  paths: string[];
  size?: 'sm' | 'md' | 'lg';
  onClick?: (e: any) => void;
  className?: string;
	fill?: string;
}

export function IconBase (props: IconBaseProps) {
  const size = props.size === 'sm' ? 20 : props.size === 'lg' ? 40 : 24;

  return (
    <svg
      aria-hidden
      role="img"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      onClick={(e) => props.onClick && props.onClick(e)}
      className={props.className}
    >
      {props.paths.map((d, i) => (
        <path key={i} fill={props.fill || 'black'} d={d} />
      ))}
    </svg>
  );
}
