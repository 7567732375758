import * as React from 'react';
import { Page } from '../../components/Page';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Card } from '../../components/Card';
import { Tiles } from '../../components/Tiles';
import { PeopleIcon } from '../../icons/PeopleIcon';
import { ChartIcon } from '../../icons/ChartIcon';
import { WrenchIcon } from '../../icons/WrenchIcon';
import { InsightsIcon } from '../../icons/InsightsIcon';
import { useIsLoggedIn, useGetUserInfo } from '../../containers/AuthProvider';
import { Row } from '../../components/Row';
import { Button } from '../../components/Button';
import { Box } from '../../components/Box';
import styled from 'styled-components/macro';
import { useId } from '../../hooks';
import { DateRangePicker } from '../../components/DateRangePicker';

import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { TextField } from '../../components/TextField';

export function HomePage () {
  const isLoggedIn = useIsLoggedIn();
  const headingId = useId();
  const userInfo = useGetUserInfo();

  // const [locale, setLocale] = React.useState('ja');
  const [startDate, setStartDate] = React.useState(new Date());
  const [endate, setEndDate] = React.useState(new Date());
  const [isFirstOpen, setIsFirstOpen] = React.useState(false);
  const [isSecondOpen, setIsSecondOpen] = React.useState(false);

  return (
    <>
      <Page aria-labelledby={headingId}>
        <div>
          <Row
            aria-labelledby={headingId}
            as="header"
            justifyContent="space-between"
            alignItems="flex-end"
            mb="lg"
          >
            <div>
              <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
                {isLoggedIn
                  ? `Welcome ${userInfo?.firstName}!`
                  : 'Get more from FP Advisor today'}
              </Heading>
              {!isLoggedIn && (
                <Text
                  size="lg"
                  color="light"
                  weight="medium"
                  lineHeight="md"
                  mr="sm"
                >
                  Gain full access to our in depth database and feature rich
                  investment tools.
                </Text>
              )}
            </div>

            {!isLoggedIn && (
              <div style={{ minWidth: '135px' }}>
                <Button link="/help/#contact-us" label="Contact us" />
              </div>
            )}
          </Row>
          <Text mb="lg" lineHeight="md">
            FP Advisor contains detailed information about Canadian public and
            private companies. <br />
            Getting started?
          </Text>

          { isLoggedIn && (
            <>
              <Tiles columns={[1, 2]}>
                <Card
                  icon={<WrenchIcon size="lg" />}
                  height="full"
                  link="/corporate-analyzer/search"
                >
                  <Text size="lg" weight="medium">
                    Special Analytical Tools
                  </Text>
                </Card>

                <Card
                  icon={<ChartIcon size="lg" />}
                  height="full"
                  link="/historical-reports/search"
                >
                  <Text size="lg" weight="medium">
                    Archival Financial Information
                  </Text>
                </Card>

                <Card
                  icon={<PeopleIcon size="lg" />}
                  height="full"
                  link="/directory-of-directors/search"
                >
                  <Text size="lg" weight="medium">
                    Directory of Directors
                  </Text>
                </Card>

                <Card
                  icon={<InsightsIcon size="lg" />}
                  height="full"
                  link="/lead-list-generator/"
                >
                  <Text size="lg" weight="medium">
                    Lead List Generator
                  </Text>
                </Card>
              </Tiles>
            </>
          )}
        </div>
      </Page>
    </>
  );
}

const TextButton = styled.button((props) => ({
  padding: 0,
  margin: 0,
  background: 'none',
  color: 'inherit',
  fontSize: props.theme.typography.textSizes.md,
  display: 'inline',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 500,
  outline: 'none',
  border: '2px solid transparent',
  marginLeft: '-2px',
  marginRight: '-2px',
  '&.focus-visible': {
    border: `2px solid ${props.theme.palette.primary.main}`,
    borderRadius: '4px',
  },
}));
