import * as React from 'react';
import styled from 'styled-components/macro';
import {
  getMarginStyles,
  MarginProps,
  getResponsiveVisibility,
  ResponsiveVisibilityProps,
} from '../../utils/style';

export interface BoxProps extends MarginProps, ResponsiveVisibilityProps {
  as?: 'header' | 'section';
  className?: string;
  justifyContent?:
    | 'center'
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'space-around';
  flexDirection?: 'row' | 'column';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  flexBasis?: 0;
  flexGrow?: 1;
  flexWrap?: 'wrap';
  children?: React.ReactNode;
  height?: 'full';
  width?: 'full';
  padding?: 'md';
}

export const Box = styled.div<BoxProps>((props) => ({
  display: props.flexDirection ? 'flex' : 'block',
  flexDirection: props.flexDirection,
  alignItems: props.alignItems,
  flexBasis: props.flexBasis,
  flexGrow: props.flexGrow,
  flexWrap: props.flexWrap,
  justifyContent: props.justifyContent,
  height: props.height === 'full' ? '100%' : 'auto',
  width: props.width === 'full' ? '100%' : 'auto',
  padding: props.padding === 'md' ? props.theme.spacing.md : 0,
  ...getMarginStyles(props),
  ...getResponsiveVisibility(props),
}));
