import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function TrashIcon (props: { color?: 'subdued'; size?: 'sm' | 'md' }) {
  const size = props.size === 'sm' ? 24 : 28;
  const theme = useTheme();

  return (
    <svg aria-hidden role="img" height={size} viewBox="0 0 24 24" width={size}>
      <path
        fill={props.color ? theme.palette[props.color].main : 'black'}
				stroke='white'
        d="M10.5 1H14V3H0V1H3.5L4.5 0H9.5L10.5 1ZM3 18C1.9 18 1 17.1 1 16V4H13V16C13 17.1 12.1 18 11 18H3Z"
      />
    </svg>
  );
}
