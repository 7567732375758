import React from 'react';
import { Button } from '../../components/Button';
import EditPassword from '../../components/EditPassword';

interface IProps {
  onCancel: () => void;
  changePassword: () => Promise<boolean | undefined>;
  changePasswordError: any;
  clearChangePasswordError: () => void;
  isChangingPassword: boolean;
}

const ChangePassword: React.FC<IProps> = ({
  onCancel,
  changePassword,
  changePasswordError,
  clearChangePasswordError,
  isChangingPassword,
}) => {
  return (
    <div>
      <h3>Edit account password</h3>
      <div>
        <EditPassword
          changePassword={changePassword}
          changePasswordError={changePasswordError}
          clearChangePasswordError={clearChangePasswordError}
          isChangingPassword={isChangingPassword}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
