import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function CorporateSurveysCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();

  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);
  const [exportSelectDeselectCompany, setExportSelectDeselectCompany] = React.useState(true);
  const [exportSelectDeselectQuickReferenceData, setExportSelectDeselectQuickReferenceData] = React.useState(true);
  const [exportSelectDeselectServiceAgents, setExportSelectDeselectServiceAgents] = React.useState(true);
  const [exportSelectDeselectMarketData, setExportSelectDeselectMarketData] = React.useState(true);
  const [exportSelectDeselectOperations, setExportSelectDeselectOperations] = React.useState(true);
  const [exportSelectDeselectFinancialStatistics, setExportSelectDeselectFinancialStatistics] = React.useState(true);

  const [exportCompanyName, setExportCompanyName] = React.useState(true);
  const [exportHeadOfficeStreet, setExportHeadOfficeStreet] = React.useState(true);
  const [exportHeadOfficeCity, setExportHeadOfficeCity] = React.useState(true);
  const [exportHeadOfficeProvince, setExportHeadOfficeProvince] = React.useState(true);
  const [exportHeadOfficeCountry, setExportHeadOfficeCountry] = React.useState(true);
  const [exportHeadOfficePostalCode, setExportHeadOfficePostalCode] = React.useState(true);
  const [exportHeadOfficeTelephone, setExportHeadOfficeTelephone] = React.useState(true);
  const [exportHeadOfficeFax, setExportHeadOfficeFax] = React.useState(true);
  const [exportEmail, setExportEmail] = React.useState(true);
  const [exportWebsite, setExportWebsite] = React.useState(true);
  const [exportIrContactName, setExportIrContactName] = React.useState(true);
  const [exportIrContactTelephone, setExportIrContactTelephone] = React.useState(true);
  const [exportIrContactEmail, setExportIrContactEmail] = React.useState(true);
  const [exportMajorShareholder, setExportMajorShareholder] = React.useState(true);
  const [exportForeignOwned, setExportForeignOwned] = React.useState(true);
  const [exportOfficerInterest, setExportOfficerInterest] = React.useState(true);
  const [exportNumberOfShareholders, setExportNumberOfShareholders] = React.useState(true);
  const [exportNumberOfEmployees, setExportNumberOfEmployees] = React.useState(true);
  const [exportFP500, setExportFP500] = React.useState(true);
  const [exportExchanges, setExportExchanges] = React.useState(true);
  const [exportGics, setExportGics] = React.useState(true);
  const [exportNaics, setExportNaics] = React.useState(true);
  const [exportSic, setExportSic] = React.useState(true);
  const [exportIncorporation, setExportIncorporation] = React.useState(true);
  const [exportAuditor, setExportAuditor] = React.useState(true);
  const [exportBanker, setExportBanker] = React.useState(true);
  const [exportLawyer, setExportLawyer] = React.useState(true);
  const [exportTransferAgent, setExportTransferAgent] = React.useState(true);
  const [exportFiftytwoWHigh, setExportFiftytwoWHigh] = React.useState(true);
  const [exportFiftytwoWLow, setExportFiftytwoWLow] = React.useState(true);
  const [exportClose, setExportClose] = React.useState(true);
  const [exportIad, setExportIad] = React.useState(true);
  const [exportYield, setExportYield] = React.useState(true);
  const [exportLatestDividend, setExportLatestDividend] = React.useState(true);
  const [exportTwelveMontheps, setExportTwelveMontheps] = React.useState(true);
  const [exportEpsDate, setExportEpsDate] = React.useState(true);
  const [exportPe, setExportPe] = React.useState(true);
  const [exportSixtyMonthBeta, setExportSixtyMonthBeta] = React.useState(true);
  const [exportOneYearReturn, setExportOneYearReturn] = React.useState(true);
  const [exportThreeYearReturn, setExportThreeYearReturn] = React.useState(true);
  const [exportMarketCapitalization, setExportMarketCapitalization] = React.useState(true);
  const [exportAvg20DayVolume, setExportAvg20DayVolume] = React.useState(true);
  const [exportSptsxComposite, setExportSptsxComposite] = React.useState(true);
  const [exportSptsx60, setExportSptsx60] = React.useState(true);
  const [exportTsx30, setExportTsx30] = React.useState(true);
  const [exportTsxventier1, setExportTsxventier1] = React.useState(true);
  const [exportTsxventier2, setExportTsxventier2] = React.useState(true);
  const [exportTsxventier3, setExportTsxventier3] = React.useState(true);
  const [exportCannabis, setExportCannabis] = React.useState(true);
  const [exportBlockchain, setExportBlockchain] = React.useState(true);
  const [exportCleantech, setExportCleantech] = React.useState(true);
  const [exportPrivateEquity, setExportPrivateEquity] = React.useState(true);
  const [exportVentureCapital, setExportVentureCapital] = React.useState(true);
  const [exportCompanyType, setExportCompanyType] = React.useState(true);
  const [exportCompanySubtype, setExportCompanySubtype] = React.useState(true);
  const [exportCompanyBrief, setExportCompanyBrief] = React.useState(true);
  const [exportFinancialPeriod, setExportFinancialPeriod] = React.useState(true);
  const [exportFinancialLength, setExportFinancialLength] = React.useState(true);
  const [exportReportingCurrency, setExportReportingCurrency] = React.useState(true);
  const [exportOperatingRevenue, setExportOperatingRevenue] = React.useState(true);
  const [exportDepreciationAmortization, setExportDepreciationAmortization] = React.useState(true);
  const [exportInterestExpenseGross, setExportInterestExpenseGross] = React.useState(true);
  const [exportInvestmentIncome, setExportInvestmentIncome] = React.useState(true);
  const [exportWriteDownsWriteoffs, setExportWriteDownsWriteoffs] = React.useState(true);
  const [exportPretaxIncome, setExportPretaxIncome] = React.useState(true);
  const [exportIncomeTaxes, setExportIncomeTaxes] = React.useState(true);
  const [exportNetIncomebBeforedDiscontinuedOperations, setExporttNetIncomebBeforedDiscontinuedOperations] = React.useState(true);
  const [exportNetIncome, setExportNetIncome] = React.useState(true);
  const [exportNetIncomeForEquityHolders, setExportNetIncomeForEquityHolders] = React.useState(true);
  const [exportNetIncomeForNonControllingInterest, setExportNetIncomeForNonControllingInterest] = React.useState(true);
  const [exportCurrentAssets, setExportCurrentAssets] = React.useState(true);
  const [exportLongTermInvestments, setExportLongTermInvestments] = React.useState(true);
  const [exportFixedAssetsNet, setExportFixedAssetsNet] = React.useState(true);
  const [exportIntangiblesNet, setExportIntangiblesNet] = React.useState(true);
  const [exportExplorationDevelopmentProperties, setExportExplorationDevelopmentProperties] = React.useState(true);
  const [exportTotalAssets, setExportTotalAssets] = React.useState(true);
  const [exportCurrentLiabilities, setExportCurrentLiabilities] = React.useState(true);
  const [exportLongTermDebtNet, setExportLongTermDebtNet] = React.useState(true);
  const [exportPreferredShareEquity, setExportPreferredShareEquity] = React.useState(true);
  const [exportShareHoldersEquity, setExportShareHoldersEquity] = React.useState(true);
  const [exportNonControllingInterest, setExportNonControllingInterest] = React.useState(true);
  const [exportCashFromOperatingActivities, setExportCashFromOperatingActivities] = React.useState(true);
  const [exportCashFromFinancingActivities, setExportCashFromFinancingActivities] = React.useState(true);
  const [exportCashFromInvestingActivities, setExportCashFromInvestingActivities] = React.useState(true);
  const [exportNetCashPosition, setExportNetCashPosition] = React.useState(true);
  const [exportCapitalExpenditures, setExportCapitalExpenditures] = React.useState(true);
  const [exportCapitalExpendituresNet, setExportCapitalExpendituresNet] = React.useState(true);
  const [exportUnfundedPensionLiability, setExportUnfundedPensionLiability] = React.useState(true);
  const [exportPensionFundSurplus, setExportPensionFundSurplus] = React.useState(true);
  const [exportAuditFees, setExportAuditFees] = React.useState(true);
  const [exportOtherAuditorFees, setExportOtherAuditorFees] = React.useState(true);
  const [exportEarningsPerShareBeforeDiscontinuedOperations, setExportEarningsPerShareBeforeDiscontinuedOperations] = React.useState(true);
  const [exportEarningsPerShare, setExportEarningsPerShare] = React.useState(true);
  const [exportCashFlowPerShare, setExportCashFlowPerShare] = React.useState(true);
  const [exportDividendPerShare, setExportDividendPerShare] = React.useState(true);
  const [exportCommonSharesOs, setExportCommonSharesOs] = React.useState(true);
  const [exportAverageCommonSharesOs, setExportAverageCommonSharesOs] = React.useState(true);
  const [exportNetProfitMargin, setExportNetProfitMargin] = React.useState(true);
  const [exportRoe, setExportRoe] = React.useState(true);
  const [exportRoa, setExportRoa] = React.useState(true);
  const [exportForeignSales, setExportForeignSales] = React.useState(true);
  const [exportEmployees, setExportEmployees] = React.useState(true);
  const [exportExchangeRateFiscalClose, setExportExchangeRateFiscalClose] = React.useState(true);
  const [exportExchangeRateFiscalAverage, setExportExchangeRateFiscalAverage] = React.useState(true);
  const [exportExchangeRateCalendarClose, setExportExchangeRateCalendarClose] = React.useState(true);
  const [exportExchangeRateCalendarAverage, setExportExchangeRateCalendarAverage] = React.useState(true);

  const getAccessToken = useGetAccessToken();
  // All
  const selectDeselectAll = (checked: boolean) => {
    setExportSelectDeselect(checked);

    selectDeselectCompany(checked);
    selectDeselectQuickReferenceData(checked);
    selectDeselectServiceAgents(checked);
    selectDeselectMarketData(checked);
    selectDeselectOperations(checked);
    selectDeselectFinancialStatistics(checked);
  }
  // Company
  const selectDeselectCompany = (checked: boolean) => {
    setExportSelectDeselectCompany(checked);

    setExportCompanyName(checked);
    setExportHeadOfficeStreet(checked);
    setExportHeadOfficeCity(checked);
    setExportHeadOfficeProvince(checked);
    setExportHeadOfficeCountry(checked);
    setExportHeadOfficePostalCode(checked);
    setExportHeadOfficeTelephone(checked);
    setExportHeadOfficeFax(checked);
    setExportEmail(checked);
    setExportWebsite(checked);
    setExportIrContactName(checked);
    setExportIrContactTelephone(checked);
    setExportIrContactEmail(checked);
  }

  // Quick Reference Data
  const selectDeselectQuickReferenceData = (checked: boolean) => {
    setExportSelectDeselectQuickReferenceData(checked);

    setExportMajorShareholder(checked);
    setExportForeignOwned(checked);
    setExportOfficerInterest(checked);
    setExportNumberOfShareholders(checked);
    setExportNumberOfEmployees(checked);
    setExportFP500(checked);
    setExportExchanges(checked);
    setExportGics(checked);
    setExportNaics(checked);
    setExportSic(checked);
    setExportIncorporation(checked);
  }

  // Service Agents
  const selectDeselectServiceAgents = (checked: boolean) => {
    setExportSelectDeselectServiceAgents(checked);

    setExportAuditor(checked);
    setExportBanker(checked);
    setExportLawyer(checked);
    setExportTransferAgent(checked);
  }

  // Market Data
  const selectDeselectMarketData = (checked: boolean) => {
    setExportSelectDeselectMarketData(checked);

    setExportFiftytwoWHigh(checked);
    setExportFiftytwoWLow(checked);
    setExportClose(checked);
    setExportIad(checked);
    setExportYield(checked);
    setExportLatestDividend(checked);
    setExportTwelveMontheps(checked);
    setExportEpsDate(checked);
    setExportPe(checked);
    setExportSixtyMonthBeta(checked);
    setExportOneYearReturn(checked);
    setExportThreeYearReturn(checked);
    setExportMarketCapitalization(checked);
    setExportAvg20DayVolume(checked);
    setExportSptsxComposite(checked);
    setExportSptsx60(checked);
    setExportTsx30(checked);
    setExportTsxventier1(checked);
    setExportTsxventier2(checked);
    setExportTsxventier3(checked);
    setExportCannabis(checked);
    setExportBlockchain(checked);
    setExportCleantech(checked);
    setExportPrivateEquity(checked);
    setExportVentureCapital(checked);
  }

  // Operations
  const selectDeselectOperations = (checked: boolean) => {
    setExportSelectDeselectOperations(checked);

    setExportCompanyType(checked);
    setExportCompanySubtype(checked);
    setExportCompanyBrief(checked);
  }

  // Financial Statistics
  const selectDeselectFinancialStatistics = (checked: boolean) => {
    setExportSelectDeselectFinancialStatistics(checked);

    setExportFinancialPeriod(checked);
    setExportFinancialLength(checked);
    setExportReportingCurrency(checked);
    setExportOperatingRevenue(checked);
    setExportDepreciationAmortization(checked);
    setExportInterestExpenseGross(checked);
    setExportInvestmentIncome(checked);
    setExportWriteDownsWriteoffs(checked);
    setExportPretaxIncome(checked);
    setExportIncomeTaxes(checked);
    setExporttNetIncomebBeforedDiscontinuedOperations(checked);
    setExportNetIncome(checked);
    setExportNetIncomeForEquityHolders(checked);
    setExportNetIncomeForNonControllingInterest(checked);
    setExportCurrentAssets(checked);
    setExportLongTermInvestments(checked);
    setExportFixedAssetsNet(checked);
    setExportIntangiblesNet(checked);
    setExportExplorationDevelopmentProperties(checked);
    setExportTotalAssets(checked);
    setExportCurrentLiabilities(checked);
    setExportLongTermDebtNet(checked);
    setExportPreferredShareEquity(checked);
    setExportShareHoldersEquity(checked);
    setExportNonControllingInterest(checked);
    setExportCashFromOperatingActivities(checked);
    setExportCashFromFinancingActivities(checked);
    setExportCashFromInvestingActivities(checked);
    setExportNetCashPosition(checked);
    setExportCapitalExpenditures(checked);
    setExportCapitalExpendituresNet(checked);
    setExportUnfundedPensionLiability(checked);
    setExportPensionFundSurplus(checked);
    setExportAuditFees(checked);
    setExportOtherAuditorFees(checked);
    setExportEarningsPerShareBeforeDiscontinuedOperations(checked);
    setExportEarningsPerShare(checked);
    setExportCashFlowPerShare(checked);
    setExportDividendPerShare(checked);
    setExportCommonSharesOs(checked);
    setExportAverageCommonSharesOs(checked);
    setExportNetProfitMargin(checked);
    setExportRoe(checked);
    setExportRoa(checked);
    setExportForeignSales(checked);
    setExportEmployees(checked);
    setExportExchangeRateFiscalClose(checked);
    setExportExchangeRateFiscalAverage(checked);
    setExportExchangeRateCalendarClose(checked);
    setExportExchangeRateCalendarAverage(checked);
  }

  const generateCsvFields = () => {
    const fields = [];
    if (exportCompanyName) {
      fields.push('companyname');
    }
    if (exportHeadOfficeStreet) {
      fields.push('headofficestreet');
    }
    if (exportHeadOfficeCity) {
      fields.push('headofficecity');
    }
    if (exportHeadOfficeProvince) {
      fields.push('headofficeprovince');
    }
    if (exportHeadOfficeCountry) {
      fields.push('headofficecountry');
    }
    if (exportHeadOfficePostalCode) {
      fields.push('headofficepostalcode');
    }
    if (exportHeadOfficeTelephone) {
      fields.push('headofficetelephone');
    }
    if (exportHeadOfficeFax) {
      fields.push('headofficefax');
    }
    if (exportEmail) {
      fields.push('email');
    }
    if (exportWebsite) {
      fields.push('website');
    }
    if (exportIrContactName) {
      fields.push('ircontactname');
    }
    if (exportIrContactTelephone) {
      fields.push('ircontacttelephone');
    }
    if (exportIrContactEmail) {
      fields.push('ircontactemail');
    }
    if (exportMajorShareholder) {
      fields.push('majorshareholder');
    }
    if (exportForeignOwned) {
      fields.push('foreignowned');
    }
    if (exportOfficerInterest) {
      fields.push('officerinterest');
    }
    if (exportNumberOfShareholders) {
      fields.push('numberofshareholders');
    }
    if (exportNumberOfEmployees) {
      fields.push('numberofemployees');
    }
    if (exportFP500) {
      fields.push('fp500');
    }
    if (exportExchanges) {
      fields.push('exchanges');
    }
    if (exportGics) {
      fields.push('gics');
    }
    if (exportNaics) {
      fields.push('naics');
    }
    if (exportSic) {
      fields.push('sic');
    }
    if (exportIncorporation) {
      fields.push('incorporation');
    }
    if (exportAuditor) {
      fields.push('auditor');
    }
    if (exportBanker) {
      fields.push('banker');
    }
    if (exportLawyer) {
      fields.push('lawyer');
    }
    if (exportTransferAgent) {
      fields.push('transferagent');
    }
    if (exportFiftytwoWHigh) {
      fields.push('fiftytwoWHigh');
    }
    if (exportFiftytwoWLow) {
      fields.push('fiftytwoWLow');
    }
    if (exportClose) {
      fields.push('close');
    }
    if (exportIad) {
      fields.push('iad');
    }
    if (exportYield) {
      fields.push('yield');
    }
    if (exportLatestDividend) {
      fields.push('latestdividend');
    }
    if (exportTwelveMontheps) {
      fields.push('twelvemontheps');
    }
    if (exportEpsDate) {
      fields.push('epsdate');
    }
    if (exportPe) {
      fields.push('pe');
    }
    if (exportSixtyMonthBeta) {
      fields.push('sixtymonthbeta');
    }
    if (exportOneYearReturn) {
      fields.push('oneyearreturn');
    }
    if (exportThreeYearReturn) {
      fields.push('threeyearreturn');
    }
    if (exportMarketCapitalization) {
      fields.push('marketcapitalization');
    }
    if (exportAvg20DayVolume) {
      fields.push('avg20dayvolume');
    }
    if (exportSptsxComposite) {
      fields.push('sptsxcomposite');
    }
    if (exportSptsx60) {
      fields.push('sptsx60');
    }
    if (exportTsx30) {
      fields.push('tsx30');
    }
    if (exportTsxventier1) {
      fields.push('tsxventier1');
    }
    if (exportTsxventier2) {
      fields.push('tsxventier2');
    }
    if (exportTsxventier3) {
      fields.push('tsxventier3');
    }
    if (exportCannabis) {
      fields.push('cannabis');
    }
    if (exportBlockchain) {
      fields.push('blockchain');
    }
    if (exportCleantech) {
      fields.push('cleantech');
    }
    if (exportPrivateEquity) {
      fields.push('privateequity');
    }
    if (exportVentureCapital) {
      fields.push('venturecapital');
    }
    if (exportCompanyType) {
      fields.push('companytype');
    }
    if (exportCompanySubtype) {
      fields.push('companysubtype');
    }
    if (exportCompanyBrief) {
      fields.push('companybrief');
    }
    if (exportFinancialPeriod) {
      fields.push('financialperiod');
    }
    if (exportFinancialLength) {
      fields.push('financiallength');
    }
    if (exportReportingCurrency) {
      fields.push('reportingcurrency');
    }
    if (exportOperatingRevenue) {
      fields.push('operatingrevenue');
    }
    if (exportDepreciationAmortization) {
      fields.push('depreciationamortization');
    }
    if (exportInterestExpenseGross) {
      fields.push('interestexpensegross');
    }
    if (exportInvestmentIncome) {
      fields.push('investmentincome');
    }
    if (exportWriteDownsWriteoffs) {
      fields.push('writedownswriteoffs');
    }
    if (exportPretaxIncome) {
      fields.push('pretaxincome');
    }
    if (exportIncomeTaxes) {
      fields.push('incometaxes');
    }
    if (exportNetIncomebBeforedDiscontinuedOperations) {
      fields.push('netincomebeforediscontinuedoperations');
    }
    if (exportNetIncome) {
      fields.push('netincome');
    }
    if (exportNetIncomeForEquityHolders) {
      fields.push('netincomeforequityholders');
    }
    if (exportNetIncomeForNonControllingInterest) {
      fields.push('netincomefornoncontrollinginterest');
    }
    if (exportCurrentAssets) {
      fields.push('currentassets');
    }
    if (exportLongTermInvestments) {
      fields.push('longterminvestments');
    }
    if (exportFixedAssetsNet) {
      fields.push('fixedassetsnet');
    }
    if (exportIntangiblesNet) {
      fields.push('intangiblesnet');
    }
    if (exportExplorationDevelopmentProperties) {
      fields.push('explorationdevelopmentproperties');
    }
    if (exportTotalAssets) {
      fields.push('totalassets');
    }
    if (exportCurrentLiabilities) {
      fields.push('currentliabilities');
    }
    if (exportLongTermDebtNet) {
      fields.push('longtermdebtnet');
    }
    if (exportPreferredShareEquity) {
      fields.push('preferredshareequity');
    }
    if (exportShareHoldersEquity) {
      fields.push('shareholdersequity');
    }
    if (exportNonControllingInterest) {
      fields.push('noncontrollinginterest');
    }
    if (exportCashFromOperatingActivities) {
      fields.push('cashfromoperatingactivities');
    }
    if (exportCashFromFinancingActivities) {
      fields.push('cashfromfinancingactivities');
    }
    if (exportCashFromInvestingActivities) {
      fields.push('cashfrominvestingactivities');
    }
    if (exportNetCashPosition) {
      fields.push('netcashposition');
    }
    if (exportCapitalExpenditures) {
      fields.push('capitalexpenditures');
    }
    if (exportCapitalExpendituresNet) {
      fields.push('capitalexpendituresnet');
    }
    if (exportUnfundedPensionLiability) {
      fields.push('unfundedpensionliability');
    }
    if (exportPensionFundSurplus) {
      fields.push('pensionfundsurplus');
    }
    if (exportAuditFees) {
      fields.push('auditfees');
    }
    if (exportOtherAuditorFees) {
      fields.push('otherauditorfees');
    }
    if (exportEarningsPerShareBeforeDiscontinuedOperations) {
      fields.push('earningspersharebeforediscontinuedoperations');
    }
    if (exportEarningsPerShare) {
      fields.push('earningspershare');
    }
    if (exportCashFlowPerShare) {
      fields.push('cashflowpershare');
    }
    if (exportDividendPerShare) {
      fields.push('dividendpershare');
    }
    if (exportCommonSharesOs) {
      fields.push('commonsharesos');
    }
    if (exportAverageCommonSharesOs) {
      fields.push('averagecommonsharesos');
    }
    if (exportNetProfitMargin) {
      fields.push('netprofitmargin');
    }
    if (exportRoe) {
      fields.push('roe');
    }
    if (exportRoa) {
      fields.push('roa');
    }
    if (exportForeignSales) {
      fields.push('foreignsales');
    }
    if (exportEmployees) {
      fields.push('employees');
    }
    if (exportExchangeRateFiscalClose) {
      fields.push('exchangeratefiscalclose');
    }
    if (exportExchangeRateFiscalAverage) {
      fields.push('exchangeratefiscalaverage');
    }
    if (exportExchangeRateCalendarClose) {
      fields.push('exchangeratecalendarclose');
    }
    if (exportExchangeRateCalendarAverage) {
      fields.push('exchangeratecalendaraverage');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/fpsu/su_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Corporate Surveys - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Corporate Surveys record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            selectDeselectAll(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <h3>
                          Company
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_comp"
                            checked={exportSelectDeselectCompany}
                            onChange={(e) => {
                              selectDeselectCompany(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_comp"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_company_name"
                            checked={exportCompanyName}
                            onChange={(e) => {
                              setExportCompanyName(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_company_name"
                          >
                            Company Name
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_street"
                            checked={exportHeadOfficeStreet}
                            onChange={(e) => {
                              setExportHeadOfficeStreet(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_street"
                          >
                            Head Office Street
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_city"
                            checked={exportHeadOfficeCity}
                            onChange={(e) => {
                              setExportHeadOfficeCity(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_city"
                          >
                            Head Office City
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_prov"
                            checked={exportHeadOfficeProvince}
                            onChange={(e) => {
                              setExportHeadOfficeProvince(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_prov"
                          >
                            Head Office Province/State
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_country"
                            checked={exportHeadOfficeCountry}
                            onChange={(e) => {
                              setExportHeadOfficeCountry(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_country"
                          >
                            Head Office Country
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_pc"
                            checked={exportHeadOfficePostalCode}
                            onChange={(e) => {
                              setExportHeadOfficePostalCode(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_pc"
                          >
                            Head Office Postal Code
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ho_fax"
                            checked={exportHeadOfficeFax}
                            onChange={(e) => {
                              setExportHeadOfficeFax(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ho_fax"
                          >
                            Head Office Fax
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_email"
                            checked={exportEmail}
                            onChange={(e) => {
                              setExportEmail(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_email"
                          >
                            Email
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_web"
                            checked={exportWebsite}
                            onChange={(e) => {
                              setExportWebsite(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_web"
                          >
                            Website
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ir_contact_name"
                            checked={exportIrContactName}
                            onChange={(e) => {
                              setExportIrContactName(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ir_contact_name"
                          >
                            IR Contact Name
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ir_contact_phone"
                            checked={exportIrContactTelephone}
                            onChange={(e) => {
                              setExportIrContactTelephone(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ir_contact_phone"
                          >
                            IR Contact Telephone
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_ir_contact_email"
                            checked={exportIrContactEmail}
                            onChange={(e) => {
                              setExportIrContactEmail(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_ir_contact_email"
                          >
                            IR Contact Email
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                        Quick Reference Data
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_ref"
                            checked={exportSelectDeselectQuickReferenceData}
                            onChange={(e) => {
                              selectDeselectQuickReferenceData(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_ref"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_major_share_holder"
                            checked={exportMajorShareholder}
                            onChange={(e) => {
                              setExportMajorShareholder(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_major_share_holder"
                          >
                            Major Shareholder
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_foreign_owned"
                            checked={exportForeignOwned}
                            onChange={(e) => {
                              setExportForeignOwned(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_foreign_owned"
                          >
                            Foreign Owned
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_officer_int"
                            checked={exportOfficerInterest}
                            onChange={(e) => {
                              setExportOfficerInterest(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_officer_int"
                          >
                            Officer Interest
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_nb_share_holders"
                            checked={exportNumberOfShareholders}
                            onChange={(e) => {
                              setExportNumberOfShareholders(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_nb_share_holders"
                          >
                            Number of Shareholders
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_nb_empl"
                            checked={exportNumberOfEmployees}
                            onChange={(e) => {
                              setExportNumberOfEmployees(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_nb_empl"
                          >
                            Number of Employees
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_fp_500"
                            checked={exportFP500}
                            onChange={(e) => {
                              setExportFP500(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_fp_500"
                          >
                            FP500
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_exchanges"
                            checked={exportExchanges}
                            onChange={(e) => {
                              setExportExchanges(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_exchanges"
                          >
                            Exchanges
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_gics"
                            checked={exportGics}
                            onChange={(e) => {
                              setExportGics(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_gics"
                          >
                            GICS
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_naics"
                            checked={exportNaics}
                            onChange={(e) => {
                              setExportNaics(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_naics"
                          >
                            NAICS
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_sic"
                            checked={exportSic}
                            onChange={(e) => {
                              setExportSic(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_sic"
                          >
                            SIC
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_incorporation"
                            checked={exportIncorporation}
                            onChange={(e) => {
                              setExportIncorporation(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_incorporation"
                          >
                            Incorporation
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                        Service Agents
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_agents"
                            checked={exportSelectDeselectServiceAgents}
                            onChange={(e) => {
                              selectDeselectServiceAgents(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_agents"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_auditor"
                            checked={exportAuditor}
                            onChange={(e) => {
                              setExportAuditor(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_auditor"
                          >
                            Auditor
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_banker"
                            checked={exportBanker}
                            onChange={(e) => {
                              setExportBanker(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_banker"
                          >
                            Banker
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_lawyer"
                            checked={exportLawyer}
                            onChange={(e) => {
                              setExportLawyer(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_lawyer"
                          >
                            Lawyer
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_transfer_agent"
                            checked={exportTransferAgent}
                            onChange={(e) => {
                              setExportTransferAgent(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_transfer_agent"
                          >
                            Transfer Agent
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                        Market Data
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_market_data"
                            checked={exportSelectDeselectMarketData}
                            onChange={(e) => {
                              selectDeselectMarketData(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_market_data"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_fifty_high"
                            checked={exportFiftytwoWHigh}
                            onChange={(e) => {
                              setExportFiftytwoWHigh(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_fifty_high"
                          >
                            52W High
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_fifty_low"
                            checked={exportFiftytwoWLow}
                            onChange={(e) => {
                              setExportFiftytwoWLow(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_fifty_low"
                          >
                            52W High
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_close"
                            checked={exportClose}
                            onChange={(e) => {
                              setExportClose(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_close"
                          >
                            Close
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_iad"
                            checked={exportIad}
                            onChange={(e) => {
                              setExportIad(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_iad"
                          >
                            IAD
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_yield"
                            checked={exportYield}
                            onChange={(e) => {
                              setExportYield(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_yield"
                          >
                            Yield
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_latest_dividend"
                            checked={exportLatestDividend}
                            onChange={(e) => {
                              setExportLatestDividend(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_latest_dividend"
                          >
                            Latest Dividend
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_twelve_months"
                            checked={exportTwelveMontheps}
                            onChange={(e) => {
                              setExportTwelveMontheps(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_twelve_months"
                          >
                            12-Month EPS
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_eps_date"
                            checked={exportEpsDate}
                            onChange={(e) => {
                              setExportEpsDate(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_eps_date"
                          >
                            EPS Date
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_pe"
                            checked={exportPe}
                            onChange={(e) => {
                              setExportPe(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_pe"
                          >
                            P/E
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_sixty_months"
                            checked={exportSixtyMonthBeta}
                            onChange={(e) => {
                              setExportSixtyMonthBeta(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_sixty_months"
                          >
                            60-Month Beta
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_one_year_return"
                            checked={exportOneYearReturn}
                            onChange={(e) => {
                              setExportOneYearReturn(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_one_year_return"
                          >
                            1-Year Return
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_three_year_return"
                            checked={exportThreeYearReturn}
                            onChange={(e) => {
                              setExportThreeYearReturn(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_three_year_return"
                          >
                            3-Year Return
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_market_cap"
                            checked={exportMarketCapitalization}
                            onChange={(e) => {
                              setExportMarketCapitalization(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_market_cap"
                          >
                            Market Capitalization (000s)
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_average_20_day_vol"
                            checked={exportAvg20DayVolume}
                            onChange={(e) => {
                              setExportAvg20DayVolume(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_average_20_day_vol"
                          >
                            Avg 20-day Volume
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_sptsx_comp"
                            checked={exportSptsxComposite}
                            onChange={(e) => {
                              setExportSptsxComposite(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_sptsx_comp"
                          >
                            S&amp;P/TSX Composite
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_sptsx_60"
                            checked={exportSptsx60}
                            onChange={(e) => {
                              setExportSptsx60(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_sptsx_60"
                          >
                            S&amp;P/TSX 60
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_tsx_30"
                            checked={exportTsx30}
                            onChange={(e) => {
                              setExportTsx30(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_tsx_30"
                          >
                            TSX 30
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_tsx_ven_1"
                            checked={exportTsxventier1}
                            onChange={(e) => {
                              setExportTsxventier1(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_tsx_ven_1"
                          >
                            TSX-VEN Tier 1
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_tsx_ven_2"
                            checked={exportTsxventier2}
                            onChange={(e) => {
                              setExportTsxventier2(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_tsx_ven_2"
                          >
                            TSX-VEN Tier 2
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_tsx_ven_3"
                            checked={exportTsxventier3}
                            onChange={(e) => {
                              setExportTsxventier3(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_tsx_ven_3"
                          >
                            TSX-VEN Tier 3
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cannabis"
                            checked={exportCannabis}
                            onChange={(e) => {
                              setExportCannabis(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cannabis"
                          >
                            Cannabis
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_blockchain"
                            checked={exportBlockchain}
                            onChange={(e) => {
                              setExportBlockchain(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_blockchain"
                          >
                            Blockchain
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cleantech"
                            checked={exportCleantech}
                            onChange={(e) => {
                              setExportCleantech(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cleantech"
                          >
                            Cleantech
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_private_equity"
                            checked={exportPrivateEquity}
                            onChange={(e) => {
                              setExportPrivateEquity(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_private_equity"
                          >
                            Private Equity
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_venture_capital"
                            checked={exportVentureCapital}
                            onChange={(e) => {
                              setExportVentureCapital(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_venture_capital"
                          >
                            Venture Capital
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                        Operations
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_operations"
                            checked={exportSelectDeselectOperations}
                            onChange={(e) => {
                              selectDeselectOperations(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_operations"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_comp_type"
                            checked={exportCompanyType}
                            onChange={(e) => {
                              setExportCompanyType(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_comp_type"
                          >
                            Company Type
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_comp_subtype"
                            checked={exportCompanySubtype}
                            onChange={(e) => {
                              setExportCompanySubtype(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_comp_subtype"
                          >
                            Company Sub-Type
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_comp_subtype"
                            checked={exportCompanyBrief}
                            onChange={(e) => {
                              setExportCompanyBrief(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_comp_brief"
                          >
                            Company Brief
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                        Financial Statistics
                        </h3>
                        <div style={{ marginBottom: '14px' }}>
                          <input
                            type="checkbox"
                            id="chk_select_deselect_financial_statistics"
                            checked={exportSelectDeselectFinancialStatistics}
                            onChange={(e) => {
                              selectDeselectFinancialStatistics(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_select_deselect_financial_statistics"
                          >
                            SELECT/UNSELECT
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_financial_period"
                            checked={exportFinancialPeriod}
                            onChange={(e) => {
                              setExportFinancialPeriod(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_financial_period"
                          >
                            Financial Period
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_financial_length"
                            checked={exportFinancialLength}
                            onChange={(e) => {
                              setExportFinancialLength(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_financial_length"
                          >
                            Financial Length
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_reporting_currency"
                            checked={exportReportingCurrency}
                            onChange={(e) => {
                              setExportReportingCurrency(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_reporting_currency"
                          >
                            Reporting Currency
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_operating_revenue"
                            checked={exportOperatingRevenue}
                            onChange={(e) => {
                              setExportOperatingRevenue(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_operating_revenue"
                          >
                            Operating Revenue
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_dep_amort"
                            checked={exportDepreciationAmortization}
                            onChange={(e) => {
                              setExportDepreciationAmortization(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_dep_amort"
                          >
                            Depreciation &amp; Amortization
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_int_exp"
                            checked={exportInterestExpenseGross}
                            onChange={(e) => {
                              setExportInterestExpenseGross(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_int_exp"
                          >
                            Interest Expense, Gross
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_inv_inc"
                            checked={exportInvestmentIncome}
                            onChange={(e) => {
                              setExportInvestmentIncome(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_inv_inc"
                          >
                            Investment Income
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_write_down"
                            checked={exportWriteDownsWriteoffs}
                            onChange={(e) => {
                              setExportWriteDownsWriteoffs(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_write_down"
                          >
                            Write-downs/Write-offs
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_pretax_inc"
                            checked={exportPretaxIncome}
                            onChange={(e) => {
                              setExportPretaxIncome(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_pretax_inc"
                          >
                            Pre-tax Income
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_inc_tx"
                            checked={exportIncomeTaxes}
                            onChange={(e) => {
                              setExportIncomeTaxes(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_inc_tx"
                          >
                            Income Taxes
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_inc_before_disc"
                            checked={exportNetIncomebBeforedDiscontinuedOperations}
                            onChange={(e) => {
                              setExporttNetIncomebBeforedDiscontinuedOperations(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_inc_before_disc"
                          >
                            Net Income Before Discontinued Operations
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_inc"
                            checked={exportNetIncome}
                            onChange={(e) => {
                              setExportNetIncome(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_inc"
                          >
                            Net Income
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_inc_eq_holders"
                            checked={exportNetIncomeForEquityHolders}
                            onChange={(e) => {
                              setExportNetIncomeForEquityHolders(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_inc_eq_holders"
                          >
                            Net Income for Equity Holders
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_inc_non_cont_int"
                            checked={exportNetIncomeForNonControllingInterest}
                            onChange={(e) => {
                              setExportNetIncomeForNonControllingInterest(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_inc_non_cont_int"
                          >
                            Net Income for Non-controlling Interest
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_current_assets"
                            checked={exportCurrentAssets}
                            onChange={(e) => {
                              setExportCurrentAssets(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_current_assets"
                          >
                            Current Assets
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_long_term_inv"
                            checked={exportLongTermInvestments}
                            onChange={(e) => {
                              setExportLongTermInvestments(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_long_term_inv"
                          >
                            Long-term Investments
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_fixed_assets_net"
                            checked={exportFixedAssetsNet}
                            onChange={(e) => {
                              setExportFixedAssetsNet(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_fixed_assets_net"
                          >
                            Fixed Assets, Net
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_intangibles_net"
                            checked={exportIntangiblesNet}
                            onChange={(e) => {
                              setExportIntangiblesNet(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_intangibles_net"
                          >
                            Intangibles, Net
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_explor_dev_prop"
                            checked={exportExplorationDevelopmentProperties}
                            onChange={(e) => {
                              setExportExplorationDevelopmentProperties(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_explor_dev_prop"
                          >
                            Exploration/Development Properties
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_total_assets"
                            checked={exportTotalAssets}
                            onChange={(e) => {
                              setExportTotalAssets(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_total_assets"
                          >
                            Total Assets
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_current_liabilities"
                            checked={exportCurrentLiabilities}
                            onChange={(e) => {
                              setExportCurrentLiabilities(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_current_liabilities"
                          >
                            Current Liabilities
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_long_term_debt"
                            checked={exportLongTermDebtNet}
                            onChange={(e) => {
                              setExportLongTermDebtNet(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_long_term_debt"
                          >
                            Long-term Debt, Net
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_pref_shared_eq"
                            checked={exportPreferredShareEquity}
                            onChange={(e) => {
                              setExportPreferredShareEquity(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_pref_shared_eq"
                          >
                            Preferred Share Equity
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_share_hold_eq"
                            checked={exportShareHoldersEquity}
                            onChange={(e) => {
                              setExportShareHoldersEquity(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_share_hold_eq"
                          >
                            Shareholders’ Equity
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_non_cont_int"
                            checked={exportNonControllingInterest}
                            onChange={(e) => {
                              setExportNonControllingInterest(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_non_cont_int"
                          >
                            Non-controlling Interest
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cash_from_fin_act"
                            checked={exportCashFromFinancingActivities}
                            onChange={(e) => {
                              setExportCashFromFinancingActivities(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cash_from_fin_act"
                          >
                            Cash from Financing Activities
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cash_from_inv_act"
                            checked={exportCashFromInvestingActivities}
                            onChange={(e) => {
                              setExportCashFromInvestingActivities(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cash_from_inv_act"
                          >
                            Cash from Investing Activities
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_cash_pos"
                            checked={exportNetCashPosition}
                            onChange={(e) => {
                              setExportNetCashPosition(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_cash_pos"
                          >
                            Net Cash Position
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cap_exp"
                            checked={exportCapitalExpenditures}
                            onChange={(e) => {
                              setExportCapitalExpenditures(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cap_exp"
                          >
                            Capital Expenditures
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cap_exp_net"
                            checked={exportCapitalExpendituresNet}
                            onChange={(e) => {
                              setExportCapitalExpendituresNet(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cap_exp_net"
                          >
                            Capital Expenditures, Net
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_unfunded_pens_lia"
                            checked={exportUnfundedPensionLiability}
                            onChange={(e) => {
                              setExportUnfundedPensionLiability(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_unfunded_pens_lia"
                          >
                            Unfunded Pension Liability
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_pension_fund_sur"
                            checked={exportPensionFundSurplus}
                            onChange={(e) => {
                              setExportPensionFundSurplus(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_pension_fund_sur"
                          >
                            Pension Fund Surplus
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_audit_fees"
                            checked={exportAuditFees}
                            onChange={(e) => {
                              setExportAuditFees(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_audit_fees"
                          >
                            Audit Fees
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_other_audit_fees"
                            checked={exportOtherAuditorFees}
                            onChange={(e) => {
                              setExportOtherAuditorFees(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_other_audit_fees"
                          >
                            Other Auditor Fees
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_earnings_per_share_before_disc_op"
                            checked={exportEarningsPerShareBeforeDiscontinuedOperations}
                            onChange={(e) => {
                              setExportEarningsPerShareBeforeDiscontinuedOperations(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_earnings_per_share_before_disc_op"
                          >
                            Earnings Per Share Before Discontinued Operations
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_earnings_per_share"
                            checked={exportEarningsPerShare}
                            onChange={(e) => {
                              setExportEarningsPerShare(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_earnings_per_share"
                          >
                            Earnings Per Share
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_cash_flow_per_share"
                            checked={exportCashFlowPerShare}
                            onChange={(e) => {
                              setExportCashFlowPerShare(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_cash_flow_per_share"
                          >
                            Cash Flow Per Share
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_dividend_per_share"
                            checked={exportDividendPerShare}
                            onChange={(e) => {
                              setExportDividendPerShare(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_dividend_per_share"
                          >
                            Dividend Per Share
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_common_share_os"
                            checked={exportCommonSharesOs}
                            onChange={(e) => {
                              setExportCommonSharesOs(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_common_share_os"
                          >
                            Common Shares O/s
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_avg_common_share_os"
                            checked={exportAverageCommonSharesOs}
                            onChange={(e) => {
                              setExportAverageCommonSharesOs(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_avg_common_share_os"
                          >
                            Average Common Shares O/s
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_net_prof_marg"
                            checked={exportNetProfitMargin}
                            onChange={(e) => {
                              setExportNetProfitMargin(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_net_prof_marg"
                          >
                            Net Profit Margin
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_roe"
                            checked={exportRoe}
                            onChange={(e) => {
                              setExportRoe(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_roe"
                          >
                            ROE
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_roa"
                            checked={exportRoa}
                            onChange={(e) => {
                              setExportRoa(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_roa"
                          >
                            ROA
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_foreign_sales"
                            checked={exportForeignSales}
                            onChange={(e) => {
                              setExportForeignSales(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_foreign_sales"
                          >
                            Foreign Sales %
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_employees"
                            checked={exportEmployees}
                            onChange={(e) => {
                              setExportEmployees(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_employees"
                          >
                            Employees
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_exchg_rate_fisc_close"
                            checked={exportExchangeRateFiscalClose}
                            onChange={(e) => {
                              setExportExchangeRateFiscalClose(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_exchg_rate_fisc_close"
                          >
                            Exchange Rate - Fiscal Close
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_exchg_rate_fisc_avg"
                            checked={exportExchangeRateFiscalAverage}
                            onChange={(e) => {
                              setExportExchangeRateFiscalAverage(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_exchg_rate_fisc_avg"
                          >
                            Exchange Rate - Fiscal Average
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_exchg_rate_cal_close"
                            checked={exportExchangeRateCalendarClose}
                            onChange={(e) => {
                              setExportExchangeRateCalendarClose(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_exchg_rate_cal_close"
                          >
                            Exchange Rate - Calendar Close
                          </label>
                        </div>
                        <div className='col-third'>
                          <input
                            type="checkbox"
                            id="chk_exchg_rate_cal_close"
                            checked={exportExchangeRateCalendarAverage}
                            onChange={(e) => {
                              setExportExchangeRateCalendarAverage(e.target.checked);
                            }}
                          ></input>
                          <label
                            htmlFor="chk_exchg_rate_cal_avg"
                          >
                            Exchange Rate - Calendar Average
                          </label>
                        </div>
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
