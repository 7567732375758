import * as React from 'react';
import { Text } from '../../components/Text';

export interface NumberOfSearchResultsProps {
  descriptionId: string | undefined
  startNum: number
  endNum: number
  loadingFirstTime: boolean | undefined
  companyName: string
  isPaginatedTable: boolean
  isEmpty: boolean | undefined
  numResults: number | undefined
  numSelected: number | undefined
}

export function NumberOfSearchResults (props: NumberOfSearchResultsProps) {
  const { descriptionId, startNum, endNum, loadingFirstTime, companyName, isPaginatedTable, isEmpty, numResults } = props;
  return (
    props.loadingFirstTime
    ? <Text id={descriptionId} size="xl" weight="medium">
        Loading results for{' '}
        <Text display="inline" size="xl" weight="bold">
          {companyName}
        </Text>
      </Text>
    : <Text id={descriptionId} size="xl" weight="medium">
        {isEmpty
          ? (
            <>No Result Found</>
          )
          : (
            <>
              {isPaginatedTable &&
                `${startNum}-${endNum} of ${numResults} results`}
              {!isPaginatedTable && `${numResults} results`} {companyName
                ? (
                  <span>
                    for{' '}
                    <Text display="inline" size="xl" weight="bold">
                      {companyName}
                    </Text>
                  </span>
                )
                : null}
                <div>
                {props.numSelected !== undefined &&
                    `${props.numSelected} selected`}
                </div>
            </>
          )}
      </Text>
  );
}
