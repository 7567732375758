import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function TrophyIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={[
        'M21.3333333,2.66666667 L18.6666667,2.66666667 L18.6666667,0 L5.33333333,0 L5.33333333,2.66666667 L2.66666667,2.66666667 C1.2,2.66666667 0,3.86666667 0,5.33333333 L0,6.66666667 C0,10.0666667 2.56,12.84 5.85333333,13.2533333 C6.69333333,15.2533333 8.49333333,16.76 10.6666667,17.2 L10.6666667,21.3333333 L5.33333333,21.3333333 L5.33333333,24 L18.6666667,24 L18.6666667,21.3333333 L13.3333333,21.3333333 L13.3333333,17.2 C15.5066667,16.76 17.3066667,15.2533333 18.1466667,13.2533333 C21.44,12.84 24,10.0666667 24,6.66666667 L24,5.33333333 C24,3.86666667 22.8,2.66666667 21.3333333,2.66666667 Z M2.66666667,6.66666667 L2.66666667,5.33333333 L5.33333333,5.33333333 L5.33333333,10.4266667 C3.78666667,9.86666667 2.66666667,8.4 2.66666667,6.66666667 Z M21.3333333,6.66666667 C21.3333333,8.4 20.2133333,9.86666667 18.6666667,10.4266667 L18.6666667,5.33333333 L21.3333333,5.33333333 L21.3333333,6.66666667 Z',
      ]}
    />
  );
}
