import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, useDirectorsConnectorsDetails } from '../../../../data';
import { Page } from '../../../../components/Page';
import { Card } from '../../../../components/Card';
import { Link } from '../../../../components/Link';
import { Accordion } from '../../../../components/Accordion';
import { Text } from '../../../../components/Text';
import { Heading } from '../../../../components/Heading';
import { useId } from '../../../../hooks';
import { ScreenReaderOnly } from '../../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../../components/Row';
import { Tiles } from '../../../../components/Tiles';
import { CompanyInformation } from '../../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../../containers/FpCoverageTopNav';
import { Button } from '../../../../components/Button';
import ExpandAll from 'components/ExpandSettings';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from 'components/PrintFriendly';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';
import { ConnectorIcon } from 'icons/ConnectorIcon';
import { BriefcaseIcon } from 'icons/BriefcaseIcon';
import { GenderIcon } from 'icons/GenderIcon';
import { PersonIcon } from 'icons/PersonIcon';
import { EducationIcon } from 'icons/EducationIcon';
import { isNoSubstitutionTemplateLiteral } from 'typescript';
import { ApartmentIcon } from 'icons/ApartmentIcon';

export function DirectorsConnectorsDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data, isLoading } = useDirectorsConnectorsDetails({ docKey: key });
    let companyId;
    try {
      companyId = data?.person_info?.data.CurrentPositions[0]?.FPID;
    } catch {

    }
    const { data: company, isLoading: isLoadingCompany } = useCompany(
      companyId ? `fp|fpsn|${companyId}` : ''
    );
    const numConnections = data?.connectors?.companies?.map(c => c.board_members?.length).reduce((p, c) => (p || 0) + (c || 0), 0);
    const headingId = useId();
    const previousPositionsGrouped:any = {};
    data?.person_info.data.CurrentPositions.forEach(c => {
      previousPositionsGrouped[c.Legal || ''] = [c];
    });

    const prevPositions = data?.person_info.data.PreviousPositions.sort((a, b) => { return ((b.Sort || 0) - (a.Sort || 0)) });
    const uniqueCompanies = new Set(prevPositions?.map(p => p.CompanyName));
    uniqueCompanies.forEach(c => {
      if (c) {
        if (!previousPositionsGrouped[c]) {
          previousPositionsGrouped[c] = new Array();
        }
        previousPositionsGrouped[c] = previousPositionsGrouped[c].concat(data?.person_info.data.PreviousPositions.filter(p => p.CompanyName === c));
      }
    });
    companies.push({
      data: data,
      isLoading: isLoading,
      company: company,
      isLoadingCompany: isLoadingCompany,
      numConnections: numConnections,
      previousPositionsGrouped: previousPositionsGrouped,
      uniqueCompanies: uniqueCompanies,
      headingId: headingId,
    });
  });

  const authContext = useAuthContext();
  const defaultExpand = authContext?.expand_collapse_settings['directory-of-directors-connectors'] ?? authContext?.expand_collapse_settings.default;
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  return (
      <Page aria-label="Directory of Directors">
        {companies.map((element, i) =>
        <Page aria-labelledby={element.headingId}>
          <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
            <ScreenReaderAvoidSplitting>
              <Text
                mt="sm"
                color="heading"
                size={['xxl', 'xxl', 'xxxl']}
                weight="semi-bold"
              >
                Directory of Directors
              </Text>
              <Text
                mt="sm"
                color="heading"
                loading={element.isLoading}
                loadingWidth={200}
                size={['xl', 'xl', 'xxl']}
                weight="semi-bold"
              >
                { element.company?.name || element.data?.person_info.data.CompanyName }
              </Text>
            </ScreenReaderAvoidSplitting>
          </Heading>
          <Row>
            <div className="detail--page--card">
              {i === 0 &&
              <HideOnPrint>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, margin: '16px', textAlign: 'right' }}>
                    <PrintFriendly></PrintFriendly>
                  </div>
                  { /* }
                  <ExpandAll setExpand={setExpand} expand={expand} modalname={'directory-of-directors-connectors'}/>
                  { */ }
                </div>
              </HideOnPrint>
              }
              <Card mb="lg">
                <Row>
                  <div style={{ paddingTop: '10px' }}>
                    <PersonIcon size='lg' />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Heading mt="xxs" size="lg" level="2" mb="sm">
                      { element.data?.connectors?.connector_name }
                    </Heading>
                    <div>
                      <Text color='light' mb='md'>
                        { element.data?.person_info.data.Association ? element.data?.person_info.data.Association + ', ' : '' }
                        { element.data?.person_info.data.Degree }
                      </Text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text mr="lg">
                        <span style={{ verticalAlign: 'inherit' }}>
                            <ApartmentIcon /> &nbsp;
                        </span>
                        { element.company?.name || element.data?.person_info.data.CompanyName }
                      </Text>

                      { element.data?.person_info.data.Gender && (
                        <Text mr="lg">
                          <span style={{ verticalAlign: 'inherit' }}>
                            <GenderIcon /> &nbsp;
                          </span>
                          { element.data?.person_info.data.Gender }
                        </Text>
                      )}

                      { element.data?.person_info.data.BirthYear !== undefined && (
                        <Text mr="lg">
                          <span style={{ verticalAlign: 'bottom', lineHeight: 'normal' }}>
                            Birth Year: { element.data?.person_info.data.BirthYear }
                          </span>
                        </Text>
                      )}

                      { element.numConnections !== undefined && element.numConnections > 0 && (
                          <Text mr="lg">
                            <span style={{ verticalAlign: 'inherit' }}>
                              <ConnectorIcon /> &nbsp;
                            </span>
                            { element.numConnections }  Connections
                          </Text>
                      )}
                    </div>
                  </div>
                  </Row>
                {/* </Tiles> */}
              </Card>

              { element.company && (
              <Card mb="lg">
                <CompanyInformation isLoading={element.isLoadingCompany} company={element.company} expand={expand} />
              </Card>
              )}

              <Card mb="lg">
                <Heading mt="xxs" size="lg" level="3" mb="sm">
                  Connections
                </Heading>

                <Text size="lg" mb='lg'>
                  <ConnectorIcon />  { element.numConnections } Connections
                </Text>

                <Tiles columns={[1, 2]}>
                { element.data?.connectors?.companies?.map((c: any) => (
                  <div>
                      <Heading mt="xxs" size="lg" level="3" mb="sm">
                        <ApartmentIcon />
                        { c.company_name }
                      </Heading>
                      { c.board_members?.map((b: { key: any; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                        <div>
                          <Text mb="md" textDecoration='underline'>
                            <Link to={`/directory-of-directors/details/${b.key}`}>{ b.name }</Link>
                          </Text>
                        </div>
                      )) }
                  </div>
                ))}
                </Tiles>
              </Card>

              <Card mb="lg">
                <Heading mt="xxs" size="lg" level="3" mb="sm">
                  Experience
                </Heading>

                { Object.keys(element.previousPositionsGrouped).map(c => (
                    <Row>
                      <div style={{ marginRight: '20px', width: '40px', height: '40px', background: 'grey', padding: '10px', textAlign: 'center' }}>
                            <ApartmentIcon fill='white' size="sm" />
                      </div>
                      <div>
                        <Heading level='4' size="lg">
                          { c }
                        </Heading>
                        <ul className="experiences">
                          { element.previousPositionsGrouped[c] && element.previousPositionsGrouped[c].map((p:any) => (
                            <li>
                              <Row>
                                <Text size='md' mb='md' weight='bold'>
                                  { p.Position }
                                </Text>
                                <Text size='md' mb='md' color='light'>
                                  &nbsp;- ({ p.StartDate } - { p.EndDate })
                                </Text>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Row>
                ))}
              </Card>

              { element.data?.person_info.data.Education !== undefined && element.data?.person_info.data.Education.length > 0 && (
                <Card mb="lg">
                  <Heading mt="xxs" size="lg" level="3" mb="sm">
                    Education
                  </Heading>

                  <Row>
                    <div style={{ marginRight: '20px', width: '40px', height: '40px', background: 'grey', padding: '12px 10px', textAlign: 'center' }}>
                      <EducationIcon fill='white' size="sm" />
                    </div>
                    <div>
                      <Heading level='4' size="lg">
                      { element.data?.person_info.data.Education }
                      </Heading>
                    </div>
                  </Row>
                </Card>
              )}

              { element.data?.person_info.data.Associations !== undefined && element.data?.person_info.data.Associations.length > 0 && (
                <Card mb="lg">
                  <Heading mt="xxs" size="lg" level="3">
                    Associations
                  </Heading>
                  <div>
                      { element.data?.person_info.data.Associations.map((a:any) => (
                        <Row mt='lg'>
                            <div style={{ background: 'grey', padding: '12px 10px', textAlign: 'center' }}>
                              <BriefcaseIcon size="sm" />
                            </div>

                            <div style={{ paddingLeft: '18px', paddingTop: '14px' }}>
                            <Text mb="md" size="md">
                              <strong>
                                { a.Association !== undefined && a.Association.length > 0 && (
                                  <span>{ a.Association } - </span>
                                )}

                                { a.AssociationDesce }&nbsp;
                              </strong>
                              { (a.AssociationYear || 0) > 0 && (
                                  <span>({a.AssociationYear})</span>
                              )}
                            </Text>
                            </div>
                        </Row>
                      ))}
                  </div>
                </Card>
              )}

            </div>
          </Row>
        </Page>
        )}
      </Page>
  );
}
