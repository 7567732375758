import * as React from 'react';
import { useParams } from 'react-router';
import { useCompany, useNewIssues } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';
import { useHistory } from 'react-router-dom';
import './index.css';

export function NewIssuesDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];

  keys.forEach((key: string) => {
    const keyCompanyId = key.split('|')[2];
    const companyId = `fp|fpsn|${keyCompanyId}`;
    const { data: issueData, isLoading } = useNewIssues(key);
    const { data: company, isLoading: isLoadingCompany } = useCompany(companyId);
    const headingId = useId();
    companies.push({ comp: company, issueData: issueData, headingId: headingId });
  });

  return (
    <Page aria-label="Financial Post New Issues" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0].comp}
        hideNewIssues={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          Financial Post New Issues
        </Heading>

        <Row>
          <div style={{ flexGrow: 1 }} className="new--issues--info--sec">
            { i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '0px', marginBottom: '12px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
              </div>
            </HideOnPrint>
            }
            <Card mb="lg">
              <Accordion openOnLoad>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  New Issue Information
                </Accordion.Heading>
                <Accordion.Content>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: element.issueData?.html || '',
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hideNewIssues={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
