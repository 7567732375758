import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { Tabs, Tab } from '../../../components/Tabs';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import ClearButton from '../../../components/ClearButton';
import { Text } from '../../../components/Text';
import { SelectField } from '../../../components/SelectField';
import { SelectFieldLtGt } from '../../../components/SelectFieldLtGt';
import { MultiSelectField } from '../../../components/MultiSelectField';
import { CheckOptions } from '../../../components/CheckOptions';
import { Row } from '../../../components/Row';
import { getFixedIncomeSearchResults, deleteSearch, useNaicsCodes } from '../../../data';
import { useSavedSearches, useRefreshSavedSearches, useGetAccessToken } from '../../../containers/AuthProvider';
import { TrashIcon } from '../../../icons/TrashIcon';
import { StarIcon } from '../../../icons/StarIcon';
import toast, { Toaster } from 'react-hot-toast';
import { CheckIcon } from '../../../icons/CheckIcon';
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';

import {
  gicsLookup,
	dbrsRatingsLookup,
	marketLookup,
	currencyLookup,
	incorporationLookup,
	sicLookup,
  termsOfIssueLookup,
  copuponFeatureLookup,
} from '../../../data/lookups';
import moment from 'moment';

export function FixedIncomeSearchPage () {
  const history = useHistory();
  const headingId = useId();

  // basic
  const [issuerName, setIssuerName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');
  const [issueType, setIssueType] = React.useState(
    localStorage.getItem('issueTypeFI') || 'both'
    );
  // advanced
  const [termsOfIssue, setTermsOfIssue] = React.useState('');
  const [market, setMarket] = React.useState('');
  const [currency, setCurrency] = React.useState('');
  const [incorporation, setIncorporation] = React.useState('');
  const [gics, setGics] = React.useState<string[]>([]);
  const [naic, setNaic] = React.useState('');
  const [sic, setSic] = React.useState('');
  const [amountIssuedGT, setAmountIssuedGT] = React.useState('');
  const [amountIssuedLT, setAmountIssuedLT] = React.useState('');
  const [debtMaturity, setDebtMaturity] = React.useState('');
  const [dbrsRatingLT, setDbrsRatingLT] = React.useState('');
  const [dbrsRatingGT, setDbrsRatingGT] = React.useState('');
  const [couponFeature, setCouponFeature] = React.useState('');
  const [couponPrcntMin, setCouponPrcntMin] = React.useState('');
  const [couponPrcntMax, setCouponPrcntMax] = React.useState('');
  const [optionCallable, setOptionCallable] = React.useState(false);
  const [optionConvertible, setOptionConvertible] = React.useState(false);
  const [optionChangeControl, setOptionChangeControl] = React.useState(false);
  const [selectedSavedSearch, setSelectedSavedSearch] = React.useState('');
  const [newSavedSearchName, setNewSavedSearchName] = React.useState('');
  const [naicsLookupCodes, setNaicsLookupCodes] = React.useState<any>([{ id: '', name: 'NAICS Codes' }]);

  const { data } = useNaicsCodes();
  const { searches: savedSearches } = useSavedSearches();
  const { refreshSavedSearches } = useRefreshSavedSearches();

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
    refreshSavedSearches();
  }, []);

  const loadLatestEntries = () => {
    const selectedSavedSearchFI = localStorage.getItem('selectedSavedSearchFI');
    if (selectedSavedSearchFI) {
      setSelectedSavedSearch(JSON.parse(selectedSavedSearchFI));
    }
    const issuerName = localStorage.getItem('issuerNameFI');
    if (issuerName) {
      setIssuerName(JSON.parse(issuerName));
    }
    const stockSymbol = localStorage.getItem('stockSymbolFI');
    if (stockSymbol) {
      setStockSymbol(JSON.parse(stockSymbol));
    }
    const issueType = localStorage.getItem('issueTypeFI');
    if (issueType) {
      setIssueType(JSON.parse(issueType));
    }
    const termsOfIssue = localStorage.getItem('termsOfIssueFI');
    if (termsOfIssue) {
      setTermsOfIssue(JSON.parse(termsOfIssue));
    }
    const market = localStorage.getItem('marketFI');
    if (market) {
      setMarket(JSON.parse(market));
    }
    const currency = localStorage.getItem('currencyFI');
    if (currency) {
      setCurrency(JSON.parse(currency));
    }
    const incorporation = localStorage.getItem('incorporationFI');
    if (incorporation) {
      setIncorporation(JSON.parse(incorporation));
    }
    const gics = localStorage.getItem('gicsFI');
    if (gics) {
      setGics(JSON.parse(gics));
    }
    const naic = localStorage.getItem('naicFI');
    if (naic) {
      setNaic(JSON.parse(naic));
    }
    const sic = localStorage.getItem('sicFI');
    if (sic) {
      setSic(JSON.parse(sic));
    }
    const amountIssuedGT = localStorage.getItem('amountIssuedGTFI');
    if (amountIssuedGT) {
      setAmountIssuedGT(JSON.parse(amountIssuedGT));
    }
    const amountIssuedLT = localStorage.getItem('amountIssuedLTFI');
    if (amountIssuedLT) {
      setAmountIssuedLT(JSON.parse(amountIssuedLT));
    }
    const debtMaturity = localStorage.getItem('debtMaturityFI');
    if (debtMaturity) {
      setDebtMaturity(JSON.parse(debtMaturity));
    }
    const dbrsRatingLT = localStorage.getItem('dbrsRatingLTFI');
    if (dbrsRatingLT) {
      setDbrsRatingLT(JSON.parse(dbrsRatingLT));
    }
    const dbrsRatingGT = localStorage.getItem('dbrsRatingGTFI');
    if (dbrsRatingGT) {
      setDbrsRatingGT(JSON.parse(dbrsRatingGT));
    }
    const couponFeature = localStorage.getItem('couponFeatureFI');
    if (couponFeature) {
      setCouponFeature(JSON.parse(couponFeature));
    }
    const couponPrcntMin = localStorage.getItem('couponPrcntMinFI');
    if (couponPrcntMin) {
      setCouponPrcntMin(JSON.parse(couponPrcntMin));
    }
    const couponPrcntMax = localStorage.getItem('couponPrcntMaxFI');
    if (couponPrcntMax) {
      setCouponPrcntMax(JSON.parse(couponPrcntMax));
    }
    const optionCallable = localStorage.getItem('optionCallableFI');
    if (optionCallable) {
      setOptionCallable(JSON.parse(optionCallable));
    }
    const optionConvertible = localStorage.getItem('optionConvertibleFI');
    if (optionConvertible) {
      setOptionConvertible(JSON.parse(optionConvertible));
    }
    const optionChangeControl = localStorage.getItem('optionChangeControlFI');
    if (optionChangeControl) {
      setOptionChangeControl(JSON.parse(optionChangeControl));
    }
  }

  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  /* selectedSavedSearch */
  const prevSelectedSavedSearch = usePreviousStorageData(selectedSavedSearch) || '';
  React.useEffect(() => {
    if (prevSelectedSavedSearch?.length !== selectedSavedSearch.length) {
      localStorage.setItem('selectedSavedSearchFI', JSON.stringify(selectedSavedSearch));
    }
  }, [selectedSavedSearch]);

  /* issuerName */
  const prevIssuerName = usePreviousStorageData(issuerName) || '';
  React.useEffect(() => {
    if (prevIssuerName !== issuerName) {
      localStorage.setItem('issuerNameFI', JSON.stringify(issuerName));
    }
  }, [issuerName]);

  /* stockSymbol */
  const prevStockSymbol = usePreviousStorageData(stockSymbol) || '';
  React.useEffect(() => {
    if (prevStockSymbol !== stockSymbol) {
      localStorage.setItem('stockSymbolFI', JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  /* issueType */
  const prevIssueType = usePreviousStorageData(issueType) || '';
  React.useEffect(() => {
    if (prevIssueType !== issueType) {
      localStorage.setItem('issueTypeFI', JSON.stringify(issueType));
    }
  }, [issueType]);

  /* issueType */
  const prevTermsOfIssue = usePreviousStorageData(termsOfIssue) || '';
  React.useEffect(() => {
    if (prevTermsOfIssue !== termsOfIssue) {
      localStorage.setItem('termsOfIssueFI', JSON.stringify(termsOfIssue));
    }
  }, [termsOfIssue]);

  /* market */
  const prevMarket = usePreviousStorageData(market) || '';
  React.useEffect(() => {
    if (prevMarket !== market) {
      localStorage.setItem('marketFI', JSON.stringify(market));
    }
  }, [market]);

  /* currency */
  const prevCurrency = usePreviousStorageData(currency) || '';
  React.useEffect(() => {
    if (prevCurrency !== currency) {
      localStorage.setItem('currencyFI', JSON.stringify(currency));
    }
  }, [currency]);

  /* incorporation */
  const prevIncorporation = usePreviousStorageData(incorporation) || '';
  React.useEffect(() => {
    if (prevIncorporation !== incorporation) {
      localStorage.setItem('incorporationFI', JSON.stringify(incorporation));
    }
  }, [incorporation]);

  /* gics */
  const prevGics = usePreviousStorageData(gics) || '';
  React.useEffect(() => {
      localStorage.setItem('gicsFI', JSON.stringify(gics));
  }, [gics]);

  /* naics */
  const prevNaic = usePreviousStorageData(naic) || '';
  React.useEffect(() => {
    if (prevNaic !== naic) {
      localStorage.setItem('naicFI', JSON.stringify(naic));
    }
  }, [naic]);

  /* sic */
  const prevSic = usePreviousStorageData(sic) || '';
  React.useEffect(() => {
    if (prevSic !== sic) {
      localStorage.setItem('sicFI', JSON.stringify(sic));
    }
  }, [sic]);

  /* amountIssuedGT */
  const prevAmountIssuedGT = usePreviousStorageData(amountIssuedGT) || '';
  React.useEffect(() => {
    if (prevAmountIssuedGT !== amountIssuedGT) {
      localStorage.setItem('amountIssuedGTFI', JSON.stringify(amountIssuedGT));
    }
  }, [amountIssuedGT]);

  /* amountIssuedLT */
  const prevAmountIssuedLT = usePreviousStorageData(amountIssuedLT) || '';
  React.useEffect(() => {
    if (prevAmountIssuedLT !== amountIssuedLT) {
      localStorage.setItem('amountIssuedLTFI', JSON.stringify(amountIssuedLT));
    }
  }, [amountIssuedLT]);

  /* debtMaturity */
  const prevDebtMaturity = usePreviousStorageData(debtMaturity) || '';
  React.useEffect(() => {
    if (prevDebtMaturity !== debtMaturity) {
      localStorage.setItem('debtMaturityFI', JSON.stringify(debtMaturity));
    }
  }, [debtMaturity]);

  /* dbrsRatingLT */
  const prevDbrsRatingLT = usePreviousStorageData(dbrsRatingLT) || '';
  React.useEffect(() => {
    if (prevDbrsRatingLT !== dbrsRatingLT) {
      localStorage.setItem('dbrsRatingLTFI', JSON.stringify(dbrsRatingLT));
    }
  }, [dbrsRatingLT]);

  /* dbrsRatingGT */
  const prevDbrsRatingGT = usePreviousStorageData(dbrsRatingGT) || '';
  React.useEffect(() => {
    if (prevDbrsRatingGT !== dbrsRatingGT) {
      localStorage.setItem('dbrsRatingGTFI', JSON.stringify(dbrsRatingGT));
    }
  }, [dbrsRatingGT]);

  /* couponPrcntMin */
  const prevCouponPrcntMin = usePreviousStorageData(couponPrcntMin) || '';
  React.useEffect(() => {
    if (prevCouponPrcntMin !== couponPrcntMin) {
      localStorage.setItem('couponPrcntMinFI', JSON.stringify(couponPrcntMin));
    }
  }, [couponPrcntMin]);

  /* couponPrcntMax */
  const prevCouponPrcntMax = usePreviousStorageData(couponPrcntMax) || '';
  React.useEffect(() => {
    if (prevCouponPrcntMax !== couponPrcntMax) {
      localStorage.setItem('couponPrcntMaxFI', JSON.stringify(couponPrcntMax));
    }
  }, [couponPrcntMax]);

  /* couponFeature */
  const prevCouponFeature = usePreviousStorageData(couponFeature) || '';
  React.useEffect(() => {
    if (prevCouponFeature !== couponFeature) {
      localStorage.setItem('couponFeatureFI', JSON.stringify(couponFeature));
    }
  }, [couponFeature]);

  /* optionCallable */
  const prevOptionCallable = usePreviousStorageData(optionCallable) || false;
  React.useEffect(() => {
    if (prevOptionCallable !== optionCallable) {
      localStorage.setItem('optionCallableFI', JSON.stringify(optionCallable));
    }
  }, [optionCallable]);

  /* optionConvertible */
  const prevOptionConvertible = usePreviousStorageData(optionConvertible) || false;
  React.useEffect(() => {
    if (prevOptionConvertible !== optionConvertible) {
      localStorage.setItem('optionConvertibleFI', JSON.stringify(optionConvertible));
    }
  }, [optionConvertible]);

  /* OptionChangeControl */
  const prevOptionChangeControl = usePreviousStorageData(optionChangeControl) || false;
  React.useEffect(() => {
    if (prevOptionChangeControl !== optionChangeControl) {
      localStorage.setItem('optionChangeControlFI', JSON.stringify(optionChangeControl));
    }
  }, [optionChangeControl]);

  const populateFromSavedSearch = (savedSearchId: string) => {
    // clearCheck(); // clear form values

    setSelectedSavedSearch(savedSearchId);

    const savedSearch = savedSearches?.fixedIncomeAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    );

    if (!savedSearch) {
      return;
    }

    const savedSearchValues = savedSearch.searchValues;

    if (savedSearchValues.dp) {
      let value;
      switch (savedSearchValues.dp) {
        case 'b':
          value = 'both'; break;
        case 'd':
          value = 'debt'; break;
        case 'p':
          value = 'preferred'; break;
        default:
          value = 'both';
      }
      setIssueType(value);
    }
    if (savedSearchValues.terms_issue) {
      setTermsOfIssue(savedSearchValues.terms_issue);
    }
    if (savedSearchValues.market) {
      setMarket(savedSearchValues.market);
    }
    if (savedSearchValues.currency) {
      setCurrency(savedSearchValues.currency);
    }
    if (savedSearchValues.incorp) {
      setIncorporation(savedSearchValues.incorp);
    }
    if (savedSearchValues.gics) {
      // change the array of ids to an array of names
      const gicsNames = savedSearchValues.gics.toString().split(',')
      setGics(gicsNames);
    }
    if (savedSearchValues.naics) {
      setNaic(savedSearchValues.naics);
    }
    if (savedSearchValues.sic) {
      setSic(savedSearchValues.sic);
    }
    if (savedSearchValues.amount_gt) {
      setAmountIssuedGT(savedSearchValues.amount_gt);
    }
    if (savedSearchValues.amount_lt) {
      setAmountIssuedLT(savedSearchValues.amount_lt);
    }
    if (savedSearchValues.maturity) {
      setDebtMaturity(savedSearchValues.maturity);
    }
    if (savedSearchValues.rating_lt) {
      setDbrsRatingLT(savedSearchValues.rating_lt);
    }
    if (savedSearchValues.rating_gt) {
      setDbrsRatingGT(savedSearchValues.rating_gt);
    }
    if (savedSearchValues.coupon) {
      setCouponFeature(savedSearchValues.coupon);
    }
    if (savedSearchValues.coupon_per_min) {
      setCouponPrcntMin(savedSearchValues.coupon_per_min);
    }
    if (savedSearchValues.coupon_per_max) {
      setCouponPrcntMax(savedSearchValues.coupon_per_max);
    }
    if (savedSearchValues.call) {
      setOptionCallable(true);
    }
    if (savedSearchValues.convert) {
      setOptionConvertible(true);
    }
    if (savedSearchValues.change) {
      setOptionChangeControl(true);
    }
  }

  const getAccessToken = useGetAccessToken();

  const saveSearch = async () => {
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    // saved search must have a name set...
    if (!newSavedSearchName || newSavedSearchName.toString().trim().length < 1) {
      return;
    }
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await getFixedIncomeSearchResults({
      issuerName: issuerName || '',
      stockSymbol: stockSymbol,
      issueType: issueType,
      termsOfIssue: termsOfIssue,
      market: market,
      currency: currency,
      incorporation: incorporation,
      gics: gics || [],
      naic: naic,
      sic: sic,
      amountIssuedGT: amountIssuedGT,
      amountIssuedLT: amountIssuedLT,
      debtMaturity: debtMaturity,
      dbrsRatingLT: dbrsRatingLT,
      dbrsRatingGT: dbrsRatingGT,
      couponFeature: couponFeature,
      couponPrcntMin: couponPrcntMin,
      couponPrcntMax: couponPrcntMax,
      optionCallable: optionCallable || false,
      optionConvertible: optionConvertible || false,
      optionChangeControl: optionChangeControl,
      dateInfo: dateInfo,
      savedSearchName: newSavedSearchName || undefined,
      page: 1,
      numberOfRows: 1,
    }, getAccessToken);
    refreshSavedSearches();

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#0E643D', width: '280px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <CheckIcon fill="white" />
        <div>Search form saved</div>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#0E643D', color: 'white' } });
  }

  const deleteSavedSearch = async (savedSearchId: string) => {
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await deleteSearch({
      id: savedSearchId
    }, getAccessToken);

    refreshSavedSearches();

    const deletedSearchName = savedSearches?.fixedIncomeAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    )?.searchName;

    setNewSavedSearchName('');

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#333333', width: '320px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <div>Search form removed</div>
        <button style={{ margin: 0, padding: 0, fontWeight: 'bold', background: 0, border: 0, color: 'white', textDecoration: 'underline' }} onClick={async () => {
          if (deletedSearchName) {
            toast.dismiss(t.id);
            await setNewSavedSearchName(deletedSearchName);
            await saveSearch();
            await refreshSavedSearches();
          }
        }}>
          Undo
        </button>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#333333', color: 'white' } });
  };

	const fixedBasicSubmitData = () => {
		const newDate = new Date()
		const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL('/fixed-income/results', baseUrl);

    resultsUrl.searchParams.set('searchType', 'basic');

    if (issuerName) {
      resultsUrl.searchParams.set('issuerName', issuerName);
    }
    if (stockSymbol) {
      resultsUrl.searchParams.set('stockSymbol', stockSymbol);
    }

    resultsUrl.searchParams.set('issueType', issueType);
    resultsUrl.searchParams.set('dateInfo', dateInfo);

    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true
    });
	}

  const fixedAdvanceSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL('/fixed-income/results', baseUrl);

    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    resultsUrl.searchParams.set('searchType', 'advanced');

    resultsUrl.searchParams.set('dateInfo', dateInfo);

    if (issueType) {
      resultsUrl.searchParams.set('issueType', issueType);
    }
    if (termsOfIssue) {
      resultsUrl.searchParams.set('termsOfIssue', termsOfIssue);
    }
    if (market) {
      resultsUrl.searchParams.set('market', market);
    }
    if (currency) {
      resultsUrl.searchParams.set('currency', currency);
    }
    if (incorporation) {
      resultsUrl.searchParams.set('incorporation', incorporation);
    }
    if (gics) {
      resultsUrl.searchParams.set('gics', gics.toString());
    }
    if (naic) {
      resultsUrl.searchParams.set('naic', naic);
    }
    if (sic) {
      resultsUrl.searchParams.set('sic', sic);
    }
    if (amountIssuedGT) {
      resultsUrl.searchParams.set('amountIssuedGT', amountIssuedGT);
    }
    if (amountIssuedLT) {
      resultsUrl.searchParams.set('amountIssuedLT', amountIssuedLT);
    }
    if (debtMaturity) {
      resultsUrl.searchParams.set('debtMaturity', debtMaturity);
    }
    if (dbrsRatingLT) {
      resultsUrl.searchParams.set('dbrsRatingLT', dbrsRatingLT);
    }
    if (dbrsRatingGT) {
      resultsUrl.searchParams.set('dbrsRatingGT', dbrsRatingGT);
    }
    if (couponFeature) {
      resultsUrl.searchParams.set('couponFeature', couponFeature);
    }
    if (couponPrcntMin) {
      resultsUrl.searchParams.set('couponPrcntMin', couponPrcntMin);
    }
    if (couponPrcntMax) {
      resultsUrl.searchParams.set('couponPrcntMax', couponPrcntMax);
    }
    if (optionCallable) {
      resultsUrl.searchParams.set('optionCallable', 'true');
    }
    if (optionConvertible) {
      resultsUrl.searchParams.set('optionConvertible', 'true');
    }
    if (optionChangeControl) {
      resultsUrl.searchParams.set('optionChangeControl', 'true');
    }
    if (newSavedSearchName) {
      resultsUrl.searchParams.set('savedSearchName', newSavedSearchName);
    }
    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true
    });
  }

  const clearCheck = ():void => {
    setIssuerName('');
    setStockSymbol('');
    setIssueType('both');
    setTermsOfIssue('');
    setMarket('');
    setCurrency('');
    setIncorporation('');
    setGics([]);
    setNaic('');
    setSic('');
    setAmountIssuedGT('');
    setAmountIssuedLT('');
    setDebtMaturity('');
    setDbrsRatingLT('');
    setDbrsRatingGT('');
    setCouponFeature('');
    setCouponPrcntMin('');
    setCouponPrcntMax('');
    setOptionCallable(false);
    setOptionConvertible(false);
    setOptionChangeControl(false);
    setSelectedSavedSearch('');
    setNewSavedSearchName('');
  }

  return (
    <Page aria-labelledby={headingId}>
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Fixed Income - Basic Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on Financial Post Fixed Income including public
            offerings and private placements of Canadian companies. Data goes
            back to 1993. Updated hourly.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              fixedBasicSubmitData();
            }}
          >
            <TextField
              mt="lg"
              label="Issuer Name"
              value={issuerName}
              onChange={setIssuerName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />

            <RadioList
              aria-label="Issue type"
              value={issueType}
              onChange={setIssueType}
              options={[
                {
                  value: 'debt',
                  label: 'Debt',
                },
                {
                  value: 'preferred',
                  label: 'Preferred',
                },
                {
                  value: 'both',
                  label: 'Both',
                },
              ]}
            />

            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>

        <Tab label="Advanced">
          <Heading level="1" size="xxxl">
            Financial Post Fixed Income - Advanced Search
          </Heading>

          <Text mt='md' mb='md' lineHeight='md'>
            Information on Financial Post Fixed Income including public offerings and private placements of Canadian companies. Data goes back to 1993. Updated hourly.
          </Text>

          <Text mt='sm' mb='lg' color='light'>
            Use only the search options that you need. Providing more details will narrow your search results.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              fixedAdvanceSubmitData();
            }}
          >
            <div style={{ marginBottom: '32px' }}>
              <Heading level="2" size="lg" mb="sm">
                  <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
                  My Saved Searches
              </Heading>

              <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches">Saved Searches</label>
              <Row>
                <select id="select_saved_searches"
                  value={selectedSavedSearch}
                  onChange={(e) => {
                        populateFromSavedSearch(e.target.value);
                  }}>
                  <option>No Search Selected</option>
                  { savedSearches?.fixedIncomeAdvanced.map(search => (
                      <option value={search.searchId}>{search.searchName}</option>
                  ))}
                </select>

                {selectedSavedSearch && (
                    <button type="button" style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) }}>
                      <TrashIcon size='sm' />
                    </button>
                  )
                }
              </Row>
            </div>

            <RadioList
              aria-label="Issue type"
              value={issueType}
              onChange={setIssueType}
              direction='row'
              options={[
                {
                  value: 'debt',
                  label: 'Debt',
                },
                {
                  value: 'preferred',
                  label: 'Preferred',
                },
              ]}
            />

            <SelectField
              title='Terms of Issue'
              value={termsOfIssue}
              setValue={setTermsOfIssue}
              options={termsOfIssueLookup}
            />

            <SelectField
              title='Market'
              value={market}
              setValue={setMarket}
              options={marketLookup}
            />

            <SelectField
              title='Currency'
              value={currency}
              setValue={setCurrency}
              options={currencyLookup}
            />

            <SelectField
              title='Incorporation'
              value={incorporation}
              setValue={setIncorporation}
              options={incorporationLookup}
            />

            <div style={{ marginTop: '24px' }}>
              <MultiSelectField
                label="GICS"
                items={gicsLookup}
                value={gics}
                onChange={setGics}
              />
            </div>

            <SelectField
              title='NAICs Codes'
              value={naic}
              setValue={setNaic}
              options={naicsLookupCodes}
            />
            <Text color="light" size="md">(2015 onwards)</Text>

            <SelectField
              title='SIC Codes'
              value={sic}
              setValue={setSic}
              options={sicLookup}
            />

            <SelectFieldLtGt
              title='Amount Issued'
              valueGt={amountIssuedGT}
              setValueGt={setAmountIssuedGT}
              optionsGt={[
                { id: '', name: 'Input' },
                { id: '10000000', name: '=> 10,000,000' },
                { id: '50000000', name: '=> 50,000,000' },
                { id: '100000000', name: '=> 100,000,000' },
                { id: '500000000', name: '=> 500,000,000' },
                { id: '1000000000', name: '=> 1,000,000,000' },
              ]}
              valueLt={amountIssuedLT}
              setValueLt={setAmountIssuedLT}
              optionsLt={[
                { id: '', name: 'Input' },
                { id: '10000000', name: '< 10,000,000' },
                { id: '50000000', name: '< 50,000,000' },
                { id: '100000000', name: '< 100,000,000' },
                { id: '500000000', name: '< 500,000,000' },
                { id: '1000000000', name: '< 1,000,000,000' },
              ]}
            />

            <SelectField
              title='Debt Maturity'
              value={debtMaturity}
              setValue={setDebtMaturity}
              options={[
                { id: '', name: '---' },
                { id: '0-5', name: '0 - 5 years' },
                { id: '5-10', name: '5 - 10 years' },
                { id: '>10', name: '> 10 years' },
              ]}
            />

            <SelectFieldLtGt
              title='DBRS Rating'
              valueLt={dbrsRatingLT}
              setValueLt={setDbrsRatingLT}
              optionsLt={dbrsRatingsLookup.map(d => ({ id: d.id, name: `< ${d.name}` }))}
              valueGt={dbrsRatingGT}
              setValueGt={setDbrsRatingGT}
              optionsGt={dbrsRatingsLookup.map(d => ({ id: d.id, name: `=> ${d.name}` }))}
            />

            <SelectField
              title='Coupon Feature'
              value={couponFeature}
              setValue={setCouponFeature}
              options={copuponFeatureLookup}
            />

            <Row>
              <TextField
                mt='lg'
                mr='md'
                label='Coupon % Min'
                value={couponPrcntMin}
                onChange={setCouponPrcntMin}
              />
              <TextField
                mt='lg'
                label='Coupon % Max'
                value={couponPrcntMax}
                onChange={setCouponPrcntMax}
              />
            </Row>

            <CheckOptions options={[
              {
                value: optionCallable,
                setValue: setOptionCallable,
                label: 'Callable'
              },
              {
                value: optionConvertible,
                setValue: setOptionConvertible,
                label: 'Convertible'
              },
              {
                value: optionChangeControl,
                setValue: setOptionChangeControl,
                label: 'Change of Control'
              },
            ]} />
            <div>
              <Heading level="2" size="lg" mb="sm" mt="lg">
                Save Search
              </Heading>
              <Row>
                <div style={{ maxWidth: '90%' }}>
                  <TextField
                    label="Name of search form"
                    value={newSavedSearchName}
                    onChange={setNewSavedSearchName}
                  />
                  <Text color="light" size="sm" mt="xxs">
                    Give your search form a meaningful name
                  </Text>
                </div>

                <div>
                  { ((savedSearches?.fixedIncomeAdvanced.find(s => s.searchName === newSavedSearchName)
                    ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) } }>
                      <StarIcon size='sm' />
                      <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
                    </button>)
                    : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={saveSearch}>
                      <StarIcon size='sm' color='clear' />
                      <ScreenReaderOnly>Save search</ScreenReaderOnly>
                    </button>
                  )))}
                </div>
              </Row>
            </div>
            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>
      </Tabs>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />
    </Page>
  );
}
