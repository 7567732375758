/**
 * Accessible drop down menu
 *
 * See: https://www.w3.org/TR/wai-aria-practices-1.1/examples/menu-button/menu-button-actions.html
 */
import * as React from 'react';
import { usePopper } from 'react-popper';
import {
  useId,
  useOnClickAway,
  useOnFocusChange,
  usePrevious,
} from '../../hooks';
import styled, { useTheme } from 'styled-components/macro';
import { Button } from '../Button';

export interface DropDownProps {
  label: string | React.ReactNode;
  options: Array<{ text: string; onClick: () => void }>;
}

const Menu = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  borderRadius: '4px',
  border: '1px solid rgb(231,231,231)',
  boxShadow:
    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  paddingTop: '8px',
  paddingBottom: '8px',
  backgroundColor: 'white',
});

const MenuButton = styled.div((props) => ({
  background: 'transparent',
  border: 'none',
  fontSize: props.theme.typography.textSizes.md,
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
  textAlign: 'left',
  position: 'relative',
  zIndex: 1,
  '&.focus-visible': {
    backgroundColor: '#f3f3f3',
  },
  '&:hover': {
    backgroundColor: '#f3f3f3',
  },
}));

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: -5px;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  }
`;

export function DropDownMenu (props: DropDownProps) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 0,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    placement: 'bottom',
  });
  const firstItemRef = React.useRef<HTMLButtonElement>(null);
  const lastItemRef = React.useRef<HTMLButtonElement>(null);

  const popoverId = useId();
  const menuId = useId();

  // Close the drop-down if focus changed
  useOnFocusChange(() => {
    if (!document.activeElement) {
      setExpanded(false);
      return;
    }

    if (
      popperElement?.contains &&
      !popperElement.contains(document.activeElement)
    ) {
      setExpanded(false);
    }
  });

  // CLose the drop-down if user clicks outside menu
  useOnClickAway(popperElement, () => {
    setExpanded(false);
  });
  return (
    <>
      <Button
        id={menuId}
        type="button"
        className="mainDropdownButton"
        ref={setReferenceElement}
        size="sm"
        aria-label="none"
        onClick={() => {
          if (expanded) {
            setExpanded(false);
          } else {
            setExpanded(true);
            setTimeout(() => {
              if (firstItemRef.current) {
                firstItemRef.current.focus();
              }
            }, 0);
          }
        }}
        aria-haspopup
        aria-expanded={expanded}
        aria-controls={popoverId}
        onKeyDown={(e) => {
          if (e.key === 'Escape' || e.key === 'Esc') {
            setExpanded(false);
            referenceElement?.focus();
          }
        }}
        label={props.label}
      />

      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          visibility: expanded ? 'visible' : 'hidden',
          pointerEvents: expanded ? undefined : 'none',
          zIndex: theme.zIndex.dropDownMenus,
        }}
        {...attributes.popper}
      >
        <div id={popoverId} aria-hidden={!expanded} aria-labelledby={menuId}>
          <Menu role="menu">
            {props.options.map((option, idx) => (
              <li role="none">
                <MenuButton
                  role="menuitem"
                  tabIndex={-1}
                  onClick={() => {
                    option.onClick();
                    setExpanded(false);
                    referenceElement?.focus();
                  }}
                  // ref={
                  //   idx === 0
                  //     ? firstItemRef
                  //     : idx === props.options.length - 1
                  //     ? lastItemRef
                  //     : undefined
                  // }
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowDown') {
                      const nextButton =
                        document.activeElement?.parentElement
                          ?.nextElementSibling?.firstElementChild;
                      if (isFocusable(nextButton)) {
                        nextButton.focus();
                      } else {
                        firstItemRef.current?.focus();
                      }
                    }

                    if (e.key === 'ArrowUp') {
                      const prevButton =
                        document.activeElement?.parentElement
                          ?.previousElementSibling?.firstElementChild;
                      if (isFocusable(prevButton)) {
                        prevButton.focus();
                      } else {
                        lastItemRef.current?.focus();
                      }
                    }

                    if (e.key === 'Escape' || e.key === 'Esc') {
                      setExpanded(false);
                      referenceElement?.focus();
                    }
                  }}
                >
                  {option.text}
                </MenuButton>
              </li>
            ))}
          </Menu>
        </div>
        <Arrow ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  );
}

function isFocusable (
  element: Element | null | undefined
): element is Element & { focus: () => void } {
  return Boolean(element && typeof (element as any).focus === 'function');
}
