import { clear } from 'console';
import React from 'react';
import styled from 'styled-components';

// export interface ClearProps {
//  onCLick: any;
// }

const Button = styled.button`
  //border-radius: 3px;
  border-color: white;
  font-size: 20px;
  border-radius: 3px;
  background-color: white;
  border: none;
  background: none;
  font-size: 18px;
  color: #666666;
  font-weight: bolder;
  margin: 30px;
  &:focus {
    cursor: pointer;
    background-color: #ffffff;
    font-size: 20px;
  }
`;

const ClearButton = ({ onClick, className }: any) => {
  return (
    <div>
      <Button type="button" onClick={onClick} className={className}>
        Clear
      </Button>
    </div>
  );
};

export default ClearButton;
