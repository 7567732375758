import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { RadioList } from '../../../components/RadioList';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function MergersAcquisitionsCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();

  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);

  const [exportAnnoucementDate, setExportAnnoucementDate] = React.useState(true);
  const [exportRevisedDate, setExportRevisedDate] = React.useState(true);
  const [exportStatus, setExportStatus] = React.useState(true);
  const [exportClassification, setExportClassification] = React.useState(true);
  const [exportDealValue, setExportDealValue] = React.useState(true);
  const [exportPaymentTerms, setExportPaymentTerms] = React.useState(true);
  const [exportPercentageBought, setExportPercentageBought] = React.useState(true);
  const [exportDealDescription, setExportDealDescription] = React.useState(true);
  const [exportFpIndustry, setExportFpIndustry] = React.useState(true);
  const [exportDealType, setExportDealType] = React.useState(true);
  const [exportTarget, setExportTarget] = React.useState(true);
  const [exportAcquiror, setExportAcquiror] = React.useState(true);
  const [exportVendor, setExportVendor] = React.useState(true);
  const [exportDealMultiples, setExportDealMultiples] = React.useState(true);
  const [exportType, setExportType] = React.useState('viewerfriendly');

  const getAccessToken = useGetAccessToken();
  const generateCsvFields = () => {
    const fields = [];
    if (exportAnnoucementDate) {
      fields.push('adate');
    }
    if (exportRevisedDate) {
      fields.push('rdate');
    }
    if (exportStatus) {
      fields.push('mastatus');
    }
    if (exportClassification) {
      fields.push('class');
    }
    if (exportDealValue) {
      fields.push('dvalue');
    }
    if (exportPaymentTerms) {
      fields.push('payment');
    }
    if (exportPercentageBought) {
      fields.push('bought');
    }
    if (exportDealDescription) {
      fields.push('ddesc');
    }
    if (exportFpIndustry) {
      fields.push('indc');
    }
    if (exportDealType) {
      fields.push('dtype');
    }
    if (exportTarget) {
      fields.push('target');
    }
    if (exportAcquiror) {
      fields.push('acquiror');
    }
    if (exportVendor) {
      fields.push('vendor');
    }
    if (exportDealMultiples) {
      fields.push('dmult');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        outputtype: exportType,
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/fpma/ma_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Post Mergers &amp; Acquisitions in Canada - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Mergers &amp; Acquisitions record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            setExportSelectDeselect(e.target.checked);
                            setExportAnnoucementDate(e.target.checked);
                            setExportRevisedDate(e.target.checked);
                            setExportStatus(e.target.checked);
                            setExportClassification(e.target.checked);
                            setExportDealValue(e.target.checked);
                            setExportPaymentTerms(e.target.checked);
                            setExportPercentageBought(e.target.checked);
                            setExportDealDescription(e.target.checked);
                            setExportFpIndustry(e.target.checked);
                            setExportDealType(e.target.checked);
                            setExportTarget(e.target.checked);
                            setExportAcquiror(e.target.checked);
                            setExportVendor(e.target.checked);
                            setExportDealMultiples(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_annoucement_date"
                          checked={exportAnnoucementDate}
                          onChange={(e) => {
                            setExportAnnoucementDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_annoucement_date"
                        >
                          Announcement Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_revised_date"
                          checked={exportRevisedDate || exportSelectDeselect}
                          onChange={(e) => {
                            setExportRevisedDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_revised_date"
                        >
                          Revised Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_status"
                          checked={exportStatus}
                          onChange={(e) => {
                            setExportStatus(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_status"
                        >
                          Status
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_classification"
                          checked={exportClassification}
                          onChange={(e) => {
                            setExportClassification(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_classification"
                        >
                          Classification
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_deal_value"
                          checked={exportDealValue}
                          onChange={(e) => {
                            setExportDealValue(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_deal_value"
                        >
                          Deal Value
                        </label>
                        <ul>
                          <li>Deal Currency</li>
                          <li>Deal Value</li>
                          <li>Deal Value (Canadian Equivalent)</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_payment_terms"
                          checked={exportPaymentTerms}
                          onChange={(e) => {
                            setExportPaymentTerms(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_payment_terms"
                        >
                          Payment Terms
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_percentage_bought"
                          checked={exportPercentageBought}
                          onChange={(e) => {
                            setExportPercentageBought(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_percentage_bought"
                        >
                          % Bought
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_deal_description"
                          checked={exportDealDescription}
                          onChange={(e) => {
                            setExportDealDescription(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_deal_description"
                        >
                          Deal Description
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_fp_industry"
                          checked={exportFpIndustry}
                          onChange={(e) => {
                            setExportFpIndustry(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_fp_industry"
                        >
                          Industry Category (FP Industries for Deal)
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_deal_type"
                          checked={exportDealType}
                          onChange={(e) => {
                            setExportDealType(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_deal_type"
                        >
                          Deal Type
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_target"
                          checked={exportTarget}
                          onChange={(e) => {
                            setExportTarget(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_target"
                        >
                          Target
                        </label>
                        <ul>
                          <li>Name</li>
                          <li>Location (City/Province/Country)</li>
                          <li>NAICS</li>
                          <li>SIC</li>
                          <li>GICS</li>
                          <li>Business Description</li>
                          <li>Financial Advisors</li>
                          <li>Legal Advisors</li>
                          <li>Financials (Year/Revenue/Assets/Net Book Value/Net Income)</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_acquiror"
                          checked={exportAcquiror}
                          onChange={(e) => {
                            setExportAcquiror(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_acquiror"
                        >
                          Acquiror
                        </label>
                        <ul>
                          <li>Name</li>
                          <li>Location (City/Province/Country)</li>
                          <li>NAICS</li>
                          <li>SIC</li>
                          <li>GICS</li>
                          <li>Business Description</li>
                          <li>Financial Advisors</li>
                          <li>Legal Advisors</li>
                          <li>Financials (Year/Revenue/Assets/Net Book Value/Net Income)</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_vendor"
                          checked={exportVendor}
                          onChange={(e) => {
                            setExportVendor(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_vendor"
                        >
                          Vendor
                        </label>
                        <ul>
                          <li>Name</li>
                          <li>Location (City/Province/Country)</li>
                          <li>NAICS</li>
                          <li>SIC</li>
                          <li>GICS</li>
                          <li>Business Description</li>
                          <li>Financial Advisors</li>
                          <li>Legal Advisors</li>
                          <li>Financials (Year/Revenue/Assets/Net Book Value/Net Income)</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_deal_multiples"
                          checked={exportDealMultiples}
                          onChange={(e) => {
                            setExportDealMultiples(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_deal_multiples"
                        >
                          Deal Multiples
                        </label>
                      </div>
                      <div>
                        <RadioList
                          aria-label="CSV Export Type"
                          value={exportType}
                          onChange={(e) => {
                            setExportType(e);
                          }}
                          direction="row"
                          options={[
                            {
                              value: 'viewerfriendly',
                              label: 'Viewer friendly'
                            },
                            {
                              value: 'pivotfriendly',
                              label: 'Pivot friendly'
                            }
                          ]}
                        />
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
