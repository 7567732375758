import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Row } from '../../../components/Row';
import { Tabs, Tab } from '../../../components/Tabs';
import { DateRangePicker } from '../../../components/DateRangePicker';
import { SelectField } from '../../../components/SelectField';
import { MultiSelectField } from '../../../components/MultiSelectField';
import { SelectFieldLtGt } from '../../../components/SelectFieldLtGt';
import { CheckOptions } from '../../../components/CheckOptions';
import { dateFilter } from '../../../utils';
import ClearButton from 'components/ClearButton';
import { useSavedSearches, useRefreshSavedSearches, useGetAccessToken } from '../../../containers/AuthProvider';
import { getNewIssuesSearchResults, deleteSearch, useNaicsCodes } from '../../../data';
import { TrashIcon } from '../../../icons/TrashIcon';
import { StarIcon } from '../../../icons/StarIcon';
import toast, { Toaster } from 'react-hot-toast';
import { CheckIcon } from '../../../icons/CheckIcon';

import '../../../pages/MergersAcquisitions/SearchPage/searchMna.scss';

import {
  gicsLookup,
  sicLookup,
  corporateGovernmentLookup,
  offeringProcedureLookup,
  termsLookup,
  marketLookup,
  currencyLookup,
  exchangesNewIssuesLookup,
  incorporationLookup,
  leagueTablesLookup,
  securityTypesLookup,
  securityConvertibleDebtLookup,
  distributionTypeLookup,
  statusDatesTypeLookup,
  leagueCreditTypeLookup,
  leagueCountByLookup,
  grossProceedsGTLookup,
  grossProceedsLTLookup,
  underwriterLookup
} from '../../../data/lookups';
import moment from 'moment';
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';

export function NewIssuesSearchPage () {
  const history = useHistory();
  const headingBasicId = useId();
  const headingAdvancedId = useId();
  const headingLeagueId = useId();
  // const authInfo = useAuthContext();
  const minDate = new Date(1993, 0, 1);
  const [selectedSavedSearch, setSelectedSavedSearch] = React.useState('');
  const [issuerName, setIssuerName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');
  const [newSavedSearchName, setNewSavedSearchName] = React.useState('');
  const [statusDatesType, setStatusDatesType] = React.useState(
    localStorage.getItem('basicSearchRadioNI') || 'C'
  );

  const { searches: savedSearches } = useSavedSearches();
  const { refreshSavedSearches } = useRefreshSavedSearches();

  const [endDate, setEndDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getFullYear() - 1, 0, 1)
  );

  // advanced
  const [leagueTables, setLeagueTables] = React.useState(
    localStorage.getItem('leagueReadioNI') || 'b'
  );

  const [creditType, setCreditType] = React.useState(
    localStorage.getItem('creditTypeNI') || 'fc'
  );
  const [countBy, setCountBy] = React.useState(
    localStorage.getItem('creditTypeNI') || 'd'
  );

  const [searchNewIssuesGICS, setSearchNewIssuesGICS] = React.useState<string[]>([]);
  const [searchTargetNAICS, setSearchTargetNAICS] = React.useState('');
  const [searchTargetSIC, setSearchTargetSIC] = React.useState('');

  const [securityTypes, setSecurityTypes] = React.useState(
    localStorage.getItem('securityRadioNI') || 'all'
  );
  const [distributionType, setDistributionType] = React.useState(
    localStorage.getItem('distributionRadioNI') || 'b'
  );
  const [corporateGovernment, setCorporateGovernment] = React.useState('');
  const [offeringProcedure, setOfferingProcedure] = React.useState('');
  const [terms, setTerms] = React.useState('');
  const [market, setMarket] = React.useState('');
  const [currency, setCurrency] = React.useState('');
  const [incorporation, setIncorporation] = React.useState('');
  const [exchange, setExchange] = React.useState('');
  const [grossProceedsGt, setGrossProceedsGt] = React.useState('');
  const [grossProceedsLt, setGrossProceedsLt] = React.useState('');
  const [searchUnderwriter, setSearchUnderwriter] = React.useState('');
  const [optionAsBookrunner, setOptionAsBookrunner] = React.useState(
    localStorage.getItem('optionAsBookrunnerNI') === 'true'
  );
  const [optionAsLead, setOptionAsLead] = React.useState(
    localStorage.getItem('optionAsLeadNI') === 'true'
  );

  /* Security Types */
  const [stSelectDeselectCommonEquity, setStSelectDeselectCommonEquity] = React.useState(false);
  const [stSelectDeselectPreferredEquity, setStSelectDeselectPreferredEquity] = React.useState(false);
  const [stSelectDeselectStructuredProduct, setStSelectDeselectStructuredProduct] = React.useState(false);
  const [stSelectDeselectDebt, setStSelectDeselectDebt] = React.useState(false);
  const [stSelectDeselectIncomeFunds, setStSelectDeselectIncomeFunds] = React.useState(false);

  const [stCommon, setStCommon] = React.useState(false);
  const [stFlowThrough, setStFlowThrough] = React.useState(false);
  const [stConvertibleDebt, setStConvertibleDebt] = React.useState(false);

  const [stPreferredShares, setStPreferredShares] = React.useState(false);
  const [stPreferredSecurity, setStPreferredSecurity] = React.useState(false);

  const [stSplitShares, setStSplitShares] = React.useState(false);
  const [stInvestmentTrust, setStInvestmentTrust] = React.useState(false);
  const [stFlowThroughLtdPartnership, setStFlowThroughLtdPartnership] = React.useState(false);

  const [stBondDebentureNote, setStBondDebentureNote] = React.useState(false);
  const [stMediumTermNote, setStMediumTermNote] = React.useState(false);
  const [stMortgageBond, setStMortgageBond] = React.useState(false);
  const [stDepositNote, setStDepositNote] = React.useState(false);
  const [stSerialDebt, setStSerialDebt] = React.useState(false);
  const [stAssetBackedSecurities, setStAssetBackedSecurities] = React.useState(false);
  const [stMortgageBackedSecurities, setStMortgageBackedSecurities] = React.useState(false);
  const [stCapitalSecurities, setStCapitalSecurities] = React.useState(false);
  const [stMapleBond, setStMapleBond] = React.useState(false);

  const [stBusinessTrust, setStBusinessTrust] = React.useState(false);
  const [stOilGasRoyaltyTrust, setStOilGasRoyaltyTrust] = React.useState(false);
  const [stREIT, setStREIT] = React.useState(false);
  const [stPowerPipelineTrust, setStPowerPipelineTrust] = React.useState(false);
  const [stOtherTrust, setStOtherTrust] = React.useState(false);

  const [stConvertibleTypes, setStConvertibleTypes] = React.useState(
    localStorage.getItem('stConvertibleTypesNI') || 'both'
  );

  const [naicsLookupCodes, setNaicsLookupCodes] = React.useState<any>([{ id: '', name: 'NAICS Codes' }]);

  const { data } = useNaicsCodes();

  const selectDeselectCommonEquity = (checked: boolean) => {
    setStSelectDeselectCommonEquity(checked);
    setStCommon(checked);
    setStFlowThrough(checked);
    setStConvertibleDebt(checked);
  }
  const selectDeselectPreferredEquity = (checked: boolean) => {
    setStSelectDeselectPreferredEquity(checked);
    setStPreferredShares(checked);
    setStPreferredSecurity(checked);
  }
  const selectDeselectStructuredProduct = (checked: boolean) => {
    setStSelectDeselectStructuredProduct(checked);
    setStSplitShares(checked);
    setStInvestmentTrust(checked);
    setStFlowThroughLtdPartnership(checked);
  }
  const selectDeselectDebt = (checked: boolean) => {
    setStSelectDeselectDebt(checked);
    setStBondDebentureNote(checked);
    setStMediumTermNote(checked);
    setStMortgageBond(checked);
    setStDepositNote(checked);
    setStSerialDebt(checked);
    setStAssetBackedSecurities(checked);
    setStMortgageBackedSecurities(checked);
    setStCapitalSecurities(checked);
    setStMapleBond(checked);
  }
  const selectDeselectIncomeFunds = (checked: boolean) => {
    setStSelectDeselectIncomeFunds(checked);
    setStBusinessTrust(checked);
    setStOilGasRoyaltyTrust(checked);
    setStREIT(checked);
    setStPowerPipelineTrust(checked);
    setStOtherTrust(checked);
  }

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
    refreshSavedSearches();
  }, []);

  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  const loadLatestEntries = () => {
    const selectedSavedSearchNI = localStorage.getItem('selectedSavedSearchNI');
    if (selectedSavedSearchNI) {
      setSelectedSavedSearch(JSON.parse(selectedSavedSearchNI));
    }
    const issuerNameNI = localStorage.getItem('issuerNameNI');
    if (issuerNameNI) {
      setIssuerName(JSON.parse(issuerNameNI));
    }
    const stockSymbolNI = localStorage.getItem('stockSymbolNI');
    if (stockSymbolNI) {
      setStockSymbol(JSON.parse(stockSymbolNI));
    }
    const leagueTablesNI = localStorage.getItem('leagueReadioNI');
    if (leagueTablesNI) {
      setLeagueTables(JSON.parse(leagueTablesNI));
    }
    const corporateGovernmentNI = localStorage.getItem('corporateGovernmentNI');
    if (corporateGovernmentNI) {
      setCorporateGovernment(JSON.parse(corporateGovernmentNI));
    }
    const securityTypesNI = localStorage.getItem('securityTypesNI');
    if (securityTypesNI) {
      setSecurityTypes(JSON.parse(securityTypesNI));
    }
    const stSelectDeselectCommonEquityNI = localStorage.getItem('stSelectDeselectCommonEquityNI');
    if (stSelectDeselectCommonEquityNI) {
      setStSelectDeselectCommonEquity(JSON.parse(stSelectDeselectCommonEquityNI));
    }
    const stSelectDeselectPreferredEquityNI = localStorage.getItem('stSelectDeselectPreferredEquityNI');
    if (stSelectDeselectPreferredEquityNI) {
      setStSelectDeselectPreferredEquity(JSON.parse(stSelectDeselectPreferredEquityNI));
    }
    const stSelectDeselectStructuredProductNI = localStorage.getItem('stSelectDeselectStructuredProductNI');
    if (stSelectDeselectStructuredProductNI) {
      setStSelectDeselectStructuredProduct(JSON.parse(stSelectDeselectStructuredProductNI));
    }
    const stSelectDeselectDebtNI = localStorage.getItem('stSelectDeselectDebtNI');
    if (stSelectDeselectDebtNI) {
      setStSelectDeselectDebt(JSON.parse(stSelectDeselectDebtNI));
    }
    const stSelectDeselectIncomeFundsNI = localStorage.getItem('stSelectDeselectIncomeFundsNI');
    if (stSelectDeselectIncomeFundsNI) {
      setStSelectDeselectIncomeFunds(JSON.parse(stSelectDeselectIncomeFundsNI));
    }
    const stCommonNI = localStorage.getItem('stCommonNI');
    if (stCommonNI) {
      setStCommon(JSON.parse(stCommonNI));
    }
    const stFlowThroughNI = localStorage.getItem('stFlowThroughNI');
    if (stFlowThroughNI) {
      setStFlowThrough(JSON.parse(stFlowThroughNI));
    }
    const stConvertibleDebtNI = localStorage.getItem('stConvertibleDebtNI');
    if (stConvertibleDebtNI) {
      setStConvertibleDebt(JSON.parse(stConvertibleDebtNI));
    }
    const stPreferredSharesNI = localStorage.getItem('stPreferredSharesNI');
    if (stPreferredSharesNI) {
      setStPreferredShares(JSON.parse(stPreferredSharesNI));
    }
    const stPreferredSecurityNI = localStorage.getItem('stPreferredSecurityNI');
    if (stPreferredSecurityNI) {
      setStPreferredSecurity(JSON.parse(stPreferredSecurityNI));
    }
    const stSplitSharesNI = localStorage.getItem('stSplitSharesNI');
    if (stSplitSharesNI) {
      setStSplitShares(JSON.parse(stSplitSharesNI));
    }
    const stInvestmentTrustNI = localStorage.getItem('stInvestmentTrustNI');
    if (stInvestmentTrustNI) {
      setStInvestmentTrust(JSON.parse(stInvestmentTrustNI));
    }
    const stFlowThroughLtdPartnershipNI = localStorage.getItem('stFlowThroughLtdPartnershipNI');
    if (stFlowThroughLtdPartnershipNI) {
      setStFlowThroughLtdPartnership(JSON.parse(stFlowThroughLtdPartnershipNI));
    }
    const stBondDebentureNoteNI = localStorage.getItem('stBondDebentureNoteNI');
    if (stBondDebentureNoteNI) {
      setStBondDebentureNote(JSON.parse(stBondDebentureNoteNI));
    }
    const stMediumTermNoteNI = localStorage.getItem('stMediumTermNoteNI');
    if (stMediumTermNoteNI) {
      setStMediumTermNote(JSON.parse(stMediumTermNoteNI));
    }
    const stMortgageBondNI = localStorage.getItem('stMortgageBondNI');
    if (stMortgageBondNI) {
      setStMortgageBond(JSON.parse(stMortgageBondNI));
    }
    const stDepositNoteNI = localStorage.getItem('stDepositNoteNI');
    if (stDepositNoteNI) {
      setStDepositNote(JSON.parse(stDepositNoteNI));
    }
    const stSerialDebtNI = localStorage.getItem('stSerialDebtNI');
    if (stSerialDebtNI) {
      setStSerialDebt(JSON.parse(stSerialDebtNI));
    }
    const stAssetBackedSecuritiesNI = localStorage.getItem('stAssetBackedSecuritiesNI');
    if (stAssetBackedSecuritiesNI) {
      setStAssetBackedSecurities(JSON.parse(stAssetBackedSecuritiesNI));
    }
    const stMortgageBackedSecuritiesNI = localStorage.getItem('stMortgageBackedSecuritiesNI');
    if (stMortgageBackedSecuritiesNI) {
      setStMortgageBackedSecurities(JSON.parse(stMortgageBackedSecuritiesNI));
    }
    const stCapitalSecuritiesNI = localStorage.getItem('stCapitalSecuritiesNI');
    if (stCapitalSecuritiesNI) {
      setStCapitalSecurities(JSON.parse(stCapitalSecuritiesNI));
    }
    const stMapleBondNI = localStorage.getItem('stMapleBondNI');
    if (stMapleBondNI) {
      setStMapleBond(JSON.parse(stMapleBondNI));
    }
    const stBusinessTrustNI = localStorage.getItem('stBusinessTrustNI');
    if (stBusinessTrustNI) {
      setStBusinessTrust(JSON.parse(stBusinessTrustNI));
    }
    const stOilGasRoyaltyTrustNI = localStorage.getItem('stOilGasRoyaltyTrustNI');
    if (stOilGasRoyaltyTrustNI) {
      setStOilGasRoyaltyTrust(JSON.parse(stOilGasRoyaltyTrustNI));
    }
    const stREITNI = localStorage.getItem('stREITNI');
    if (stREITNI) {
      setStREIT(JSON.parse(stREITNI));
    }
    const stPowerPipelineTrustNI = localStorage.getItem('stPowerPipelineTrustNI');
    if (stPowerPipelineTrustNI) {
      setStPowerPipelineTrust(JSON.parse(stPowerPipelineTrustNI));
    }
    const stOtherTrustNI = localStorage.getItem('stOtherTrustNI');
    if (stOtherTrustNI) {
      setStOtherTrust(JSON.parse(stOtherTrustNI));
    }
    const stConvertibleTypesNI = localStorage.getItem('stConvertibleTypesNI');
    if (stConvertibleTypesNI) {
      setStConvertibleTypes(JSON.parse(stConvertibleTypesNI));
    }
    const distributionTypeNI = localStorage.getItem('distributionRadioNI');
    if (distributionTypeNI) {
      setDistributionType(JSON.parse(distributionTypeNI));
    }
    const offeringProcedureNI = localStorage.getItem('offeringProcedureNI');
    if (offeringProcedureNI) {
      setOfferingProcedure(JSON.parse(offeringProcedureNI));
    }
    const termsNI = localStorage.getItem('termsNI');
    if (termsNI) {
      setTerms(JSON.parse(termsNI));
    }
    const marketNI = localStorage.getItem('marketNI');
    if (marketNI) {
      setMarket(JSON.parse(marketNI));
    }
    const currencyNI = localStorage.getItem('currencyNI');
    if (currencyNI) {
      setCurrency(JSON.parse(currencyNI));
    }
    const exchangeNI = localStorage.getItem('exchangeNI');
    if (exchangeNI) {
      setExchange(JSON.parse(exchangeNI));
    }
    const incorporationNI = localStorage.getItem('incorporationNI');
    if (incorporationNI) {
      setIncorporation(JSON.parse(incorporationNI));
    }
    const searchNewIssuesGICSNI = localStorage.getItem('searchNewIssuesGICSNI');
    if (searchNewIssuesGICSNI) {
      setSearchNewIssuesGICS(JSON.parse(searchNewIssuesGICSNI));
    }
    const searchTargetNAICSNI = localStorage.getItem('searchTargetNAICSNI');
    if (searchTargetNAICSNI) {
      setSearchTargetNAICS(JSON.parse(searchTargetNAICSNI));
    }
    const searchTargetSICNI = localStorage.getItem('searchTargetSICNI');
    if (searchTargetSICNI) {
      setSearchTargetSIC(JSON.parse(searchTargetSICNI));
    }
    const grossProceedsGtNI = localStorage.getItem('grossProceedsGtNI');
    if (grossProceedsGtNI) {
      setGrossProceedsGt(JSON.parse(grossProceedsGtNI));
    }
    const grossProceedsLtNI = localStorage.getItem('grossProceedsLtNI');
    if (grossProceedsLtNI) {
      setGrossProceedsLt(JSON.parse(grossProceedsLtNI));
    }
    const searchUnderwriterNI = localStorage.getItem('searchUnderwriterNI');
    if (searchUnderwriterNI) {
      setSearchUnderwriter(JSON.parse(searchUnderwriterNI));
    }
    const optionAsBookrunnerNI = localStorage.getItem('optionAsBookrunnerNI');
    if (optionAsBookrunnerNI) {
      setOptionAsBookrunner(JSON.parse(optionAsBookrunnerNI));
    }
    const optionAsLeadNI = localStorage.getItem('optionAsLeadNI');
    if (optionAsLeadNI) {
      setOptionAsLead(JSON.parse(optionAsLeadNI));
    }
    const statusDatesTypeNI = localStorage.getItem('statusDatesTypeNI');
    if (statusDatesTypeNI) {
      setStatusDatesType(JSON.parse(statusDatesTypeNI));
    }
    const startDateNI = localStorage.getItem('startDateNI');
    if (startDateNI) {
      setStartDate(new Date(JSON.parse(startDateNI)));
    }
    const endDateNI = localStorage.getItem('endDateNI');
    if (endDateNI) {
      setEndDate(new Date(JSON.parse(endDateNI)));
    }
  }

  /* selectedSavedSearch */
  const prevSelectedSavedSearch = usePreviousStorageData(selectedSavedSearch) || '';
  React.useEffect(() => {
    if (prevSelectedSavedSearch !== selectedSavedSearch) {
      localStorage.setItem('selectedSavedSearchNI', JSON.stringify(selectedSavedSearch));
    }
  }, [selectedSavedSearch]);

  /* issuerName */
  const newIssue = usePreviousStorageData(issuerName) || '';
  React.useEffect(() => {
    if (newIssue !== issuerName) {
      localStorage.setItem('issuerNameNI', JSON.stringify(issuerName));
    }
  }, [issuerName]);

  /* stockSymbol */
  const stockSymbolName = usePreviousStorageData(stockSymbol) || '';
  React.useEffect(() => {
    if (stockSymbolName !== stockSymbol) {
      localStorage.setItem('stockSymbolNI', JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  /* leagueTables */
  const leagueTablesName = usePreviousStorageData(leagueTables) || '';
  React.useEffect(() => {
    if (leagueTablesName !== leagueTables) {
      localStorage.setItem('leagueReadioNI', JSON.stringify(leagueTables));
    }
  }, [leagueTables]);

  /* corporateGovernment */
  const corporateGovernmentName =
    usePreviousStorageData(corporateGovernment) || '';
  React.useEffect(() => {
    if (corporateGovernmentName !== corporateGovernment) {
      localStorage.setItem(
        'corporateGovernmentNI',
        JSON.stringify(corporateGovernment)
      );
    }
  }, [corporateGovernment]);

  /* Security Types */
  const securityTypesPrev = usePreviousStorageData(securityTypes) || 'all';
  React.useEffect(() => {
    if (securityTypesPrev !== securityTypes.toString()) {
      localStorage.setItem(
        'securityTypesNI',
        JSON.stringify(securityTypes)
      );
    }
  }, [securityTypes]);

  /* stSelectDeselectCommonEquity */
  const stSelectDeselectCommonEquityPrev = usePreviousStorageData(stSelectDeselectCommonEquity) || '';
  React.useEffect(() => {
    if (stSelectDeselectCommonEquityPrev !== stSelectDeselectCommonEquity.toString()) {
      localStorage.setItem(
        'stSelectDeselectCommonEquityNI',
        JSON.stringify(stSelectDeselectCommonEquity)
      );
    }
  }, [stSelectDeselectCommonEquity]);

  /* stSelectDeselectPreferredEquity */
  const stSelectDeselectPreferredEquityPrev = usePreviousStorageData(stSelectDeselectPreferredEquity) || '';
  React.useEffect(() => {
    if (stSelectDeselectPreferredEquityPrev !== stSelectDeselectPreferredEquity.toString()) {
      localStorage.setItem(
        'stSelectDeselectPreferredEquityNI',
        JSON.stringify(stSelectDeselectPreferredEquity)
      );
    }
  }, [stSelectDeselectPreferredEquity]);

  /* stSelectDeselectStructuredProduct */
  const stSelectDeselectStructuredProductPrev = usePreviousStorageData(stSelectDeselectStructuredProduct) || '';
  React.useEffect(() => {
    if (stSelectDeselectStructuredProductPrev !== stSelectDeselectStructuredProduct.toString()) {
      localStorage.setItem(
        'stSelectDeselectStructuredProductNI',
        JSON.stringify(stSelectDeselectStructuredProduct)
      );
    }
  }, [stSelectDeselectStructuredProduct]);

  /* stSelectDeselectDebt */
  const stSelectDeselectDebtPrev = usePreviousStorageData(stSelectDeselectDebt) || '';
  React.useEffect(() => {
    if (stSelectDeselectDebtPrev !== stSelectDeselectDebt.toString()) {
      localStorage.setItem(
        'stSelectDeselectDebtNI',
        JSON.stringify(stSelectDeselectDebt)
      );
    }
  }, [stSelectDeselectDebt]);

  /* stSelectDeselectIncomeFunds */
  const stSelectDeselectIncomeFundsPrev = usePreviousStorageData(stSelectDeselectIncomeFunds) || '';
  React.useEffect(() => {
    if (stSelectDeselectIncomeFundsPrev !== stSelectDeselectIncomeFunds.toString()) {
      localStorage.setItem(
        'stSelectDeselectIncomeFundsNI',
        JSON.stringify(stSelectDeselectIncomeFunds)
      );
    }
  }, [stSelectDeselectIncomeFunds]);

  /* stCommon */
  const stCommonPrev = usePreviousStorageData(stCommon) || '';
  React.useEffect(() => {
    if (stCommonPrev !== stCommon.toString()) {
      localStorage.setItem(
        'stCommonNI',
        JSON.stringify(stCommon)
      );
    }
  }, [stCommon]);

  /* stFlowThrough */
  const stFlowThroughPrev = usePreviousStorageData(stFlowThrough) || '';
  React.useEffect(() => {
    if (stFlowThroughPrev !== stFlowThrough.toString()) {
      localStorage.setItem(
        'stFlowThroughNI',
        JSON.stringify(stFlowThrough)
      );
    }
  }, [stFlowThrough]);

  /* stConvertibleDebt */
  const stConvertibleDebtPrev = usePreviousStorageData(stConvertibleDebt) || '';
  React.useEffect(() => {
    if (stConvertibleDebtPrev !== stConvertibleDebt.toString()) {
      localStorage.setItem(
        'stConvertibleDebtNI',
        JSON.stringify(stConvertibleDebt)
      );
    }
  }, [stConvertibleDebt]);

  /* stPreferredShares */
  const stPreferredSharesPrev = usePreviousStorageData(stPreferredShares) || '';
  React.useEffect(() => {
    if (stPreferredSharesPrev !== stPreferredShares.toString()) {
      localStorage.setItem(
        'stPreferredSharesNI',
        JSON.stringify(stPreferredShares)
      );
    }
  }, [stPreferredShares]);

  /* stPreferredSecurity */
  const stPreferredSecurityPrev = usePreviousStorageData(stPreferredSecurity) || '';
  React.useEffect(() => {
    if (stPreferredSecurityPrev !== stPreferredSecurity.toString()) {
      localStorage.setItem(
        'stPreferredSecurityNI',
        JSON.stringify(stPreferredSecurity)
      );
    }
  }, [stPreferredSecurity]);

  /* stSplitShares */
  const stSplitSharesPrev = usePreviousStorageData(stSplitShares) || '';
  React.useEffect(() => {
    if (stSplitSharesPrev !== stSplitShares.toString()) {
      localStorage.setItem(
        'stSplitSharesNI',
        JSON.stringify(stSplitShares)
      );
    }
  }, [stSplitShares]);

  /* stInvestmentTrust */
  const stInvestmentTrustPrev = usePreviousStorageData(stInvestmentTrust) || '';
  React.useEffect(() => {
    if (stInvestmentTrustPrev !== stInvestmentTrust.toString()) {
      localStorage.setItem(
        'stInvestmentTrustNI',
        JSON.stringify(stInvestmentTrust)
      );
    }
  }, [stInvestmentTrust]);

  /* stFlowThroughLtdPartnership */
  const stFlowThroughLtdPartnershipPrev = usePreviousStorageData(stFlowThroughLtdPartnership) || '';
  React.useEffect(() => {
    if (stFlowThroughLtdPartnershipPrev !== stFlowThroughLtdPartnership.toString()) {
      localStorage.setItem(
        'stFlowThroughLtdPartnershipNI',
        JSON.stringify(stFlowThroughLtdPartnership)
      );
    }
  }, [stFlowThroughLtdPartnership]);

  /* stBondDebentureNote */
  const stBondDebentureNotePrev = usePreviousStorageData(stBondDebentureNote) || '';
  React.useEffect(() => {
    if (stBondDebentureNotePrev !== stBondDebentureNote.toString()) {
      localStorage.setItem(
        'stBondDebentureNoteNI',
        JSON.stringify(stBondDebentureNote)
      );
    }
  }, [stBondDebentureNote]);

  /* stMediumTermNote */
  const stMediumTermNotePrev = usePreviousStorageData(stMediumTermNote) || '';
  React.useEffect(() => {
    if (stMediumTermNotePrev !== stMediumTermNote.toString()) {
      localStorage.setItem(
        'stMediumTermNoteNI',
        JSON.stringify(stMediumTermNote)
      );
    }
  }, [stMediumTermNote]);

  /* stMortgageBond */
  const stMortgageBondPrev = usePreviousStorageData(stMortgageBond) || '';
  React.useEffect(() => {
    if (stMortgageBondPrev !== stMortgageBond.toString()) {
      localStorage.setItem(
        'stMortgageBondNI',
        JSON.stringify(stMortgageBond)
      );
    }
  }, [stMortgageBond]);

  /* stDepositNote */
  const stDepositNotePrev = usePreviousStorageData(stDepositNote) || '';
  React.useEffect(() => {
    if (stDepositNotePrev !== stDepositNote.toString()) {
      localStorage.setItem(
        'stDepositNoteNI',
        JSON.stringify(stDepositNote)
      );
    }
  }, [stDepositNote]);

  /* stSerialDebt */
  const stSerialDebtPrev = usePreviousStorageData(stSerialDebt) || '';
  React.useEffect(() => {
    if (stSerialDebtPrev !== stSerialDebt.toString()) {
      localStorage.setItem(
        'stSerialDebtNI',
        JSON.stringify(stSerialDebt)
      );
    }
  }, [stSerialDebt]);

  /* stAssetBackedSecurities */
  const stAssetBackedSecuritiesPrev = usePreviousStorageData(stAssetBackedSecurities) || '';
  React.useEffect(() => {
    if (stAssetBackedSecuritiesPrev !== stAssetBackedSecurities.toString()) {
      localStorage.setItem(
        'stAssetBackedSecuritiesNI',
        JSON.stringify(stAssetBackedSecurities)
      );
    }
  }, [stAssetBackedSecurities]);

  /* stMortgageBackedSecurities */
  const stMortgageBackedSecuritiesPrev = usePreviousStorageData(stMortgageBackedSecurities) || '';
  React.useEffect(() => {
    if (stMortgageBackedSecuritiesPrev !== stMortgageBackedSecurities.toString()) {
      localStorage.setItem(
        'stMortgageBackedSecuritiesNI',
        JSON.stringify(stMortgageBackedSecurities)
      );
    }
  }, [stMortgageBackedSecurities]);

  /* stCapitalSecurities */
  const stCapitalSecuritiesPrev = usePreviousStorageData(stCapitalSecurities) || '';
  React.useEffect(() => {
    if (stCapitalSecuritiesPrev !== stCapitalSecurities.toString()) {
      localStorage.setItem(
        'stCapitalSecuritiesNI',
        JSON.stringify(stCapitalSecurities)
      );
    }
  }, [stCapitalSecurities]);

  /* stMapleBond */
  const stMapleBondPrev = usePreviousStorageData(stMapleBond) || '';
  React.useEffect(() => {
    if (stMapleBondPrev !== stMapleBond.toString()) {
      localStorage.setItem(
        'stMapleBondNI',
        JSON.stringify(stMapleBond)
      );
    }
  }, [stMapleBond]);

  /* stBusinessTrust */
  const stBusinessTrustPrev = usePreviousStorageData(stBusinessTrust) || '';
  React.useEffect(() => {
    if (stBusinessTrustPrev?.length !== stBusinessTrust.toString().length) {
      localStorage.setItem(
        'stBusinessTrustNI',
        JSON.stringify(stBusinessTrust)
      );
    }
  }, [stBusinessTrust]);

  /* stOilGasRoyaltyTrust */
  const stOilGasRoyaltyTrustPrev = usePreviousStorageData(stOilGasRoyaltyTrust) || '';
  React.useEffect(() => {
    if (stOilGasRoyaltyTrustPrev?.length !== stOilGasRoyaltyTrust.toString().length) {
      localStorage.setItem(
        'stOilGasRoyaltyTrustNI',
        JSON.stringify(stOilGasRoyaltyTrust)
      );
    }
  }, [stOilGasRoyaltyTrust]);

  /* stREIT */
  const stREITPrev = usePreviousStorageData(stREIT) || '';
  React.useEffect(() => {
    if (stREITPrev?.length !== stREIT.toString().length) {
      localStorage.setItem(
        'stREITNI',
        JSON.stringify(stREIT)
      );
    }
  }, [stREIT]);

  /* stPowerPipelineTrust */
  const stPowerPipelineTrustPrev = usePreviousStorageData(stPowerPipelineTrust) || '';
  React.useEffect(() => {
    if (stPowerPipelineTrustPrev?.length !== stPowerPipelineTrust.toString().length) {
      localStorage.setItem(
        'stPowerPipelineTrustNI',
        JSON.stringify(stPowerPipelineTrust)
      );
    }
  }, [stPowerPipelineTrust]);

  /* stOtherTrust */
  const stOtherTrustPrev = usePreviousStorageData(stOtherTrust) || '';
  React.useEffect(() => {
    if (stOtherTrustPrev?.length !== stOtherTrust.toString().length) {
      localStorage.setItem(
        'stOtherTrustNI',
        JSON.stringify(stOtherTrust)
      );
    }
  }, [stOtherTrust]);

  /* stConvertibleTypes */
  const stConvertibleTypesPrev = usePreviousStorageData(stConvertibleTypes) || 'both';
  React.useEffect(() => {
    if (stConvertibleTypesPrev && (stConvertibleTypesPrev !== stConvertibleTypes.toString())) {
      localStorage.setItem(
        'stConvertibleTypesNI',
        JSON.stringify(stConvertibleTypes)
      );
    }
  }, [stConvertibleTypes]);

  /* distributionType */
  const distributionTypePrev = usePreviousStorageData(distributionType) || 'both';
  React.useEffect(() => {
    if (distributionType && (distributionTypePrev !== distributionType.toString())) {
      localStorage.setItem(
        'distributionRadioNI',
        JSON.stringify(distributionType)
      );
    }
  }, [distributionType]);

  /* offeringProcedure */
  const offeringProcedureName = usePreviousStorageData(offeringProcedure) || '';
  React.useEffect(() => {
    if (offeringProcedureName?.length !== offeringProcedure.length) {
      localStorage.setItem(
        'offeringProcedureNI',
        JSON.stringify(offeringProcedure)
      );
    }
  }, [offeringProcedure]);

  /* terms */
  const termsName = usePreviousStorageData(terms) || '';
  React.useEffect(() => {
    const localRepoItems1 = localStorage.getItem('termsNI');
    if (localRepoItems1) {
      setTerms(JSON.parse(localRepoItems1));
    }
  }, []);

  React.useEffect(() => {
    if (termsName?.length !== terms.length) {
      localStorage.setItem('termsNI', JSON.stringify(terms));
    }
  }, [terms]);

  /* market */
  const marketName = usePreviousStorageData(market) || '';
  React.useEffect(() => {
    if (marketName?.length !== market.length) {
      localStorage.setItem('marketNI', JSON.stringify(market));
    }
  }, [market]);

  /* currency */
  const currencyName = usePreviousStorageData(currency) || '';
  React.useEffect(() => {
    if (currencyName?.length !== currency.length) {
      localStorage.setItem('currencyNI', JSON.stringify(currency));
    }
  }, [currency]);

  /* exchange */
  const exchangeName = usePreviousStorageData(exchange) || '';
  React.useEffect(() => {
    if (exchangeName?.length !== exchange.length) {
      localStorage.setItem('exchangeNI', JSON.stringify(exchange));
    }
  }, [exchange]);

  /* incorporation */
  const incorporationName = usePreviousStorageData(incorporation) || '';
  React.useEffect(() => {
    if (incorporationName?.length !== incorporation.length) {
      localStorage.setItem('incorporationNI', JSON.stringify(incorporation));
    }
  }, [incorporation]);

  /* searchNewIssuesGICS */
  const searchTargetGICSName = usePreviousStorageData(searchNewIssuesGICS) || [];
  React.useEffect(() => {
    if (searchTargetGICSName?.length !== searchNewIssuesGICS.length) {
      localStorage.setItem(
        'searchNewIssuesGICSNI',
        JSON.stringify(searchNewIssuesGICS)
      );
    }
  }, [searchNewIssuesGICS]);

  /* searchTargetNAICS */
  const searchTargetNA = usePreviousStorageData(searchTargetNAICS) || '';
  React.useEffect(() => {
    if (searchTargetNA?.length !== searchTargetNAICS.length) {
      localStorage.setItem(
        'searchTargetNAICSNI',
        JSON.stringify(searchTargetNAICS)
      );
    }
  }, [searchTargetNAICS]);

  /* searchTargetSIC */
  const searchTargetSI = usePreviousStorageData(searchTargetSIC) || '';
  React.useEffect(() => {
    if (searchTargetSI?.length !== searchTargetSIC.length) {
      localStorage.setItem(
        'searchTargetSICNI',
        JSON.stringify(searchTargetSIC)
      );
    }
  }, [searchTargetSIC]);

  /* grossProceedsGt */
  const grossProcGt = usePreviousStorageData(grossProceedsGt) || '';
  React.useEffect(() => {
    if (grossProcGt?.length !== grossProceedsGt.length) {
      localStorage.setItem(
        'grossProceedsGtNI',
        JSON.stringify(grossProceedsGt)
      );
    }
  }, [grossProceedsGt]);

  /* searchPercentageBoughtLt */
  const grossProLt = usePreviousStorageData(grossProceedsLt) || '';
  React.useEffect(() => {
    if (grossProLt?.length !== grossProceedsLt.length) {
      localStorage.setItem(
        'grossProceedsLtNI',
        JSON.stringify(grossProceedsLt)
      );
    }
  }, [grossProceedsLt]);

  /* searchUnderwriter */
  const searchUnderwriterPrev = usePreviousStorageData(searchUnderwriter) || '';
  React.useEffect(() => {
    if (searchUnderwriterPrev?.length !== searchUnderwriter.length) {
      localStorage.setItem(
        'searchUnderwriterNI',
        JSON.stringify(searchUnderwriter)
      );
    }
  }, [searchUnderwriter]);

  /* optionAsBookrunner */
  const optionAsBookrunnerPrev = usePreviousStorageData(optionAsBookrunner) || '';
  React.useEffect(() => {
    if (optionAsBookrunnerPrev?.length !== optionAsBookrunner.toString().length) {
      localStorage.setItem(
        'optionAsBookrunnerNI',
        JSON.stringify(optionAsBookrunner)
      );
    }
  }, [optionAsBookrunner]);

  /* optionAsLead */
  const optionAsLeadPrev = usePreviousStorageData(optionAsLead) || '';
  React.useEffect(() => {
    if (optionAsLeadPrev?.length !== optionAsLead.toString().length) {
      localStorage.setItem(
        'optionAsLeadNI',
        JSON.stringify(optionAsLead)
      );
    }
  }, [optionAsLead]);

  /* statusDatesType */
  const statusDatesTypePrev = usePreviousStorageData(statusDatesType) || '';
  React.useEffect(() => {
    if (statusDatesTypePrev !== statusDatesType.toString()) {
      localStorage.setItem(
        'statusDatesTypeNI',
        JSON.stringify(statusDatesType)
      );
    }
  }, [statusDatesType]);

  /* startDate */
  const searchStartDate = usePreviousStorageData(startDate) || '0';
  React.useEffect(() => {
    if (searchStartDate !== startDate.getTime().toString()) {
      localStorage.setItem(
        'startDateNI',
        startDate.getTime().toString()
      );
    }
  }, [startDate]);

  /* endDate */
  const searchEndDate = usePreviousStorageData(endDate) || '0';
  React.useEffect(() => {
    if (searchEndDate !== endDate.getTime().toString()) {
      localStorage.setItem(
        'endDateNI',
        endDate.getTime().toString()
      );
    }
  }, [endDate]);

  const clearCheck = () => {
    setSelectedSavedSearch('');
    setIssuerName('');
    setStockSymbol('');
    setStatusDatesType('C');
    setLeagueTables('b');
    setCreditType('fc');
    setCountBy('d');
    setGrossProceedsGt('');
    setGrossProceedsLt('');
    setSearchNewIssuesGICS([]);
    setSearchTargetNAICS('');
    setSearchTargetSIC('');
    setSecurityTypes('all');
    setStSelectDeselectCommonEquity(false);
    setStSelectDeselectPreferredEquity(false);
    setStSelectDeselectStructuredProduct(false);
    setStSelectDeselectDebt(false);
    setStSelectDeselectIncomeFunds(false);
    setStCommon(false);
    setStFlowThrough(false);
    setStConvertibleDebt(false);
    setStPreferredShares(false);
    setStPreferredSecurity(false);
    setStSplitShares(false);
    setStInvestmentTrust(false);
    setStFlowThroughLtdPartnership(false);
    setStBondDebentureNote(false);
    setStMediumTermNote(false);
    setStMortgageBond(false);
    setStDepositNote(false);
    setStSerialDebt(false);
    setStAssetBackedSecurities(false);
    setStMortgageBackedSecurities(false);
    setStCapitalSecurities(false);
    setStMapleBond(false);
    setStBusinessTrust(false);
    setStOilGasRoyaltyTrust(false);
    setStREIT(false);
    setStPowerPipelineTrust(false);
    setStOtherTrust(false);
    setStConvertibleTypes('both');
    setDistributionType('b');
    setCorporateGovernment('');
    setOfferingProcedure('');
    setTerms('');
    setMarket('');
    setCurrency('');
    setExchange('');
    setIncorporation('');
    setSearchUnderwriter('');
    setOptionAsBookrunner(false);
    setOptionAsLead(false);
    setStartDate(new Date(new Date().getFullYear() - 1, 0, 1));
    setEndDate(new Date(new Date().getFullYear(), 11, 31));
    setNewSavedSearchName('');
    localStorage.removeItem('searchTargetGICSNI');
    localStorage.removeItem('searchTargetNAICSNI');
    localStorage.removeItem('searchTargetSICNI');
    localStorage.removeItem('basicSearchRadioNI');
    localStorage.removeItem('leagueReadioNI');
    localStorage.removeItem('securityRadioNI');
    localStorage.removeItem('distributionRadioNI');
    localStorage.removeItem('statusDatesTypeNI');
    localStorage.removeItem('startDateNI');
    localStorage.removeItem('endDateNI');
    localStorage.removeItem('grossProceedsGtNI');
    localStorage.removeItem('grossProceedsLtNI');
    localStorage.removeItem('searchUnderwriterNI');
    localStorage.removeItem('optionAsBookrunnerNI');
    localStorage.removeItem('optionAsLeadNI');
    localStorage.removeItem('endDateNI');
  };

  const populateFromSavedSearch = (savedSearchId: string, searchType: string) => {
    clearCheck(); // clear form values

    setSelectedSavedSearch(savedSearchId);
    let savedSearch: any;
    if (searchType === 'advanced') {
      savedSearch = savedSearches?.newIssuesAdvanced.find(
        s => (s.searchId === parseInt(savedSearchId, 10))
      );
    } else if (searchType === 'league') {
      savedSearch = savedSearches?.newIssuesLeague.find(
        s => (s.searchId === parseInt(savedSearchId, 10))
      );
    }

    if (!savedSearch) {
      return;
    }

    const savedSearchValues = savedSearch.searchValues;

    if (savedSearchValues.name) {
      setIssuerName(savedSearchValues.name);
    }

    if (savedSearchValues.symbol) {
      setStockSymbol(savedSearchValues.symbol);
    }

    // whatever option begins with letter...
    if (savedSearchValues.status) {
      setStatusDatesType(savedSearchValues.status);
    }

    if (savedSearchValues.dm_filter) {
      setLeagueTables(savedSearchValues.dm_filter);
    }

    if (savedSearchValues.credit) {
      setCreditType(savedSearchValues.credit);
    }

    if (savedSearchValues.counting) {
      setCountBy(savedSearchValues.counting);
    }

    if (savedSearchValues.gics) {
      // change the array of ids to an array of names
      const gicsIds = savedSearchValues.gics;
      const gics = gicsIds.toString().split(',').map((id: string) => gicsLookup.filter(f => f.id === id));
      const gicsNames = gics.map((gic: { name: any; }[]) => gic[0]?.name)
      setSearchNewIssuesGICS(gicsNames);
    }

    if (savedSearchValues.naics) {
      setSearchTargetNAICS(savedSearchValues.naics)
    }

    if (savedSearchValues.sic) {
      setSearchTargetSIC(savedSearchValues.sic)
    }

    if (savedSearchValues.uw) {
      setSearchUnderwriter(savedSearchValues.uw)
    }

    if (savedSearchValues.uw_book) {
      setOptionAsBookrunner(savedSearchValues.uw_book === 'Y')
    }

    if (savedSearchValues.uw_lead) {
      setOptionAsLead(savedSearchValues.uw_lead === 'Y')
    }

    savedSearchValues.sec_type.length === 0 ? setSecurityTypes('all') : setSecurityTypes('choose');

    if (savedSearchValues.st.length > 0) {
      if (savedSearchValues.st.includes('e_ft+e_cu+e_cd')) {
        setStSelectDeselectCommonEquity(true);
      }
      if (savedSearchValues.st.includes('e_cu')) {
        setStCommon(true);
      }
      if (savedSearchValues.st.includes('e_ft')) {
        setStFlowThrough(true);
      }
      if (savedSearchValues.st.includes('e_cd')) {
        setStConvertibleDebt(true);
      }

      if (savedSearchValues.st.includes('p_sh+p_se')) {
        setStSelectDeselectPreferredEquity(true);
      }
      if (savedSearchValues.st.includes('p_sh')) {
        setStPreferredShares(true);
      }
      if (savedSearchValues.st.includes('p_se')) {
        setStPreferredSecurity(true);
      }

      if (savedSearchValues.st.includes('s_ss+s_it+s_ft')) {
        setStSelectDeselectStructuredProduct(true);
      }
      if (savedSearchValues.st.includes('s_ss')) {
        setStSplitShares(true);
      }
      if (savedSearchValues.st.includes('s_it')) {
        setStInvestmentTrust(true);
      }
      if (savedSearchValues.st.includes('s_ft')) {
        setStFlowThroughLtdPartnership(true);
      }

      if (savedSearchValues.st.includes('d_bd+d_mt+d_mb+d_dn+d_sd+d_as+d_ms+d_cs+d_mab')) {
        setStSelectDeselectDebt(true);
      }
      if (savedSearchValues.st.includes('d_bd')) {
        setStBondDebentureNote(true);
      }
      if (savedSearchValues.st.includes('d_mt')) {
        setStMediumTermNote(true);
      }
      if (savedSearchValues.st.includes('d_mb')) {
        setStMortgageBond(true);
      }
      if (savedSearchValues.st.includes('d_dn')) {
        setStDepositNote(true);
      }
      if (savedSearchValues.st.includes('d_sd')) {
        setStSerialDebt(true);
      }
      if (savedSearchValues.st.includes('d_as')) {
        setStAssetBackedSecurities(true);
      }
      if (savedSearchValues.st.includes('d_ms')) {
        setStMortgageBackedSecurities(true);
      }
      if (savedSearchValues.st.includes('d_cs')) {
        setStCapitalSecurities(true);
      }
      if (savedSearchValues.st.includes('d_mab')) {
        setStMapleBond(true);
      }

      if (savedSearchValues.st.includes('i_bt+i_og+i_re+i_pp+i_ot')) {
        setStSelectDeselectIncomeFunds(true);
      }
      if (savedSearchValues.st.includes('i_ct')) {
        setStConvertibleTypes('include');
      }
      if (savedSearchValues.st.includes('i_xt')) {
        setStConvertibleTypes('exclude');
      }
      if (savedSearchValues.st.includes('i_bt')) {
        setStBusinessTrust(true);
      }
      if (savedSearchValues.st.includes('i_og')) {
        setStOilGasRoyaltyTrust(true);
      }
      if (savedSearchValues.st.includes('i_re')) {
        setStREIT(true);
      }
      if (savedSearchValues.st.includes('i_pp')) {
        setStPowerPipelineTrust(true);
      }
      if (savedSearchValues.st.includes('i_ot')) {
        setStOtherTrust(true);
      }
    }

    if (savedSearchValues.off_type) {
      setDistributionType(savedSearchValues.off_type);
    }

    if (savedSearchValues.gov_type) {
      setCorporateGovernment(savedSearchValues.gov_type);
    }

    if (savedSearchValues.comp_type) {
      setOfferingProcedure(savedSearchValues.comp_type);
    }

    if (savedSearchValues.terms) {
      setTerms(savedSearchValues.terms);
    }

    if (savedSearchValues.market) {
      setMarket(savedSearchValues.market);
    }

    if (savedSearchValues.currency) {
      setCurrency(savedSearchValues.currency);
    }
    if (savedSearchValues.exch) {
      setExchange(savedSearchValues.exch);
    }

    if (savedSearchValues.incorp) {
      setIncorporation(savedSearchValues.incorp);
    }

    if (savedSearchValues.sy && savedSearchValues.sm && savedSearchValues.sd) {
      setStartDate(new Date(parseInt(savedSearchValues.sy, 10), parseInt(savedSearchValues.sm, 10) - 1, parseInt(savedSearchValues.sd, 10)));
    }

    if (savedSearchValues.ey && savedSearchValues.em && savedSearchValues.ed) {
      setEndDate(new Date(parseInt(savedSearchValues.ey, 10), parseInt(savedSearchValues.em, 10) - 1, parseInt(savedSearchValues.ed, 10)));
    }

    if (savedSearchValues.gross_gt) {
      setGrossProceedsGt(savedSearchValues.gross_gt)
    }

    if (savedSearchValues.gross_lt) {
      setGrossProceedsLt(savedSearchValues.gross_lt)
    }

    // setNewSavedSearchName(savedSearch.searchName);
  }

  const financialBasicSubmitData = () => {
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    history.push(
      `/new-issues/results?searchType=basic&dateInfo=${dateInfo}&issuerName=${issuerName}&stockSymbol=${stockSymbol}&startDate=${startDate
        ?.toISOString()
        .substring(0, 10)}&endDate=${endDate
        ?.toISOString()
        .substring(0, 10)}&statusDatesType=${statusDatesType}`,
      { data: true }
    );
  };

  const financialAdvanceSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL('/new-issues/results', baseUrl);

    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    resultsUrl.searchParams.set('searchType', 'advanced');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (issuerName) {
      resultsUrl.searchParams.set('issuerName', issuerName);
    }
    if (stockSymbol) {
      resultsUrl.searchParams.set('stockSymbol', stockSymbol);
    }
    if (leagueTables) {
      resultsUrl.searchParams.set('leagueTables', leagueTables);
    }
    if (securityTypes) {
      resultsUrl.searchParams.set('securityTypes', securityTypes);
    }
    if (stSelectDeselectCommonEquity) {
      resultsUrl.searchParams.set('stSelectDeselectCommonEquity', `${stSelectDeselectCommonEquity}`);
    }
    if (stSelectDeselectPreferredEquity) {
      resultsUrl.searchParams.set('stSelectDeselectPreferredEquity', `${stSelectDeselectPreferredEquity}`);
    }
    if (stSelectDeselectStructuredProduct) {
      resultsUrl.searchParams.set('stSelectDeselectStructuredProduct', `${stSelectDeselectStructuredProduct}`);
    }
    if (stSelectDeselectDebt) {
      resultsUrl.searchParams.set('stSelectDeselectDebt', `${stSelectDeselectDebt}`);
    }
    if (stSelectDeselectIncomeFunds) {
      resultsUrl.searchParams.set('stSelectDeselectIncomeFunds', `${stSelectDeselectIncomeFunds}`);
    }

    if (stCommon) {
      resultsUrl.searchParams.set('stCommon', `${stCommon}`);
    }
    if (stFlowThrough) {
      resultsUrl.searchParams.set('stFlowThrough', `${stFlowThrough}`);
    }
    if (stConvertibleDebt) {
      resultsUrl.searchParams.set('stConvertibleDebt', `${stConvertibleDebt}`);
    }

    if (stPreferredShares) {
      resultsUrl.searchParams.set('stPreferredShares', `${stPreferredShares}`);
    }
    if (stPreferredSecurity) {
      resultsUrl.searchParams.set('stPreferredSecurity', `${stPreferredSecurity}`);
    }

    if (stSplitShares) {
      resultsUrl.searchParams.set('stSplitShares', `${stSplitShares}`);
    }
    if (stInvestmentTrust) {
      resultsUrl.searchParams.set('stInvestmentTrust', `${stInvestmentTrust}`);
    }
    if (stFlowThroughLtdPartnership) {
      resultsUrl.searchParams.set('stFlowThroughLtdPartnership', `${stFlowThroughLtdPartnership}`);
    }

    if (stBondDebentureNote) {
      resultsUrl.searchParams.set('stBondDebentureNote', `${stBondDebentureNote}`);
    }
    if (stMediumTermNote) {
      resultsUrl.searchParams.set('stMediumTermNote', `${stMediumTermNote}`);
    }
    if (stMortgageBond) {
      resultsUrl.searchParams.set('stMortgageBond', `${stMortgageBond}`);
    }
    if (stDepositNote) {
      resultsUrl.searchParams.set('stDepositNote', `${stDepositNote}`);
    }
    if (stSerialDebt) {
      resultsUrl.searchParams.set('stSerialDebt', `${stSerialDebt}`);
    }
    if (stAssetBackedSecurities) {
      resultsUrl.searchParams.set('stAssetBackedSecurities', `${stAssetBackedSecurities}`);
    }
    if (stMortgageBackedSecurities) {
      resultsUrl.searchParams.set('stMortgageBackedSecurities', `${stMortgageBackedSecurities}`);
    }
    if (stCapitalSecurities) {
      resultsUrl.searchParams.set('stCapitalSecurities', `${stCapitalSecurities}`);
    }
    if (stMapleBond) {
      resultsUrl.searchParams.set('stMapleBond', `${stMapleBond}`);
    }

    if (stBusinessTrust) {
      resultsUrl.searchParams.set('stBusinessTrust', `${stBusinessTrust}`);
    }
    if (stOilGasRoyaltyTrust) {
      resultsUrl.searchParams.set('stOilGasRoyaltyTrust', `${stOilGasRoyaltyTrust}`);
    }
    if (stREIT) {
      resultsUrl.searchParams.set('stREIT', `${stREIT}`);
    }
    if (stPowerPipelineTrust) {
      resultsUrl.searchParams.set('stPowerPipelineTrust', `${stPowerPipelineTrust}`);
    }
    if (stOtherTrust) {
      resultsUrl.searchParams.set('stOtherTrust', `${stOtherTrust}`);
    }

    if (stConvertibleTypes) {
      resultsUrl.searchParams.set('stConvertibleTypes', stConvertibleTypes);
    }

    if (distributionType) {
      resultsUrl.searchParams.set('distributionType', distributionType);
    }
    if (corporateGovernment) {
      resultsUrl.searchParams.set('corporateGovernment', corporateGovernment);
    }
    if (searchNewIssuesGICS) {
      resultsUrl.searchParams.set('searchNewIssuesGICS', searchNewIssuesGICS.toString());
    }
    if (searchTargetNAICS) {
      resultsUrl.searchParams.set('searchTargetNAICS', searchTargetNAICS);
    }
    if (searchTargetSIC) {
      resultsUrl.searchParams.set('searchTargetSIC', searchTargetSIC);
    }
    if (searchUnderwriter) {
      resultsUrl.searchParams.set('searchUnderwriter', searchUnderwriter);
    }
    if (optionAsBookrunner) {
      resultsUrl.searchParams.set('optionAsBookrunner', `${optionAsBookrunner}`);
    }
    if (optionAsLead) {
      resultsUrl.searchParams.set('optionAsLead', `${optionAsLead}`);
    }

    if (offeringProcedure) {
      resultsUrl.searchParams.set('offeringProcedure', offeringProcedure);
    }
    if (terms) {
      resultsUrl.searchParams.set('terms', terms);
    }
    if (market) {
      resultsUrl.searchParams.set('market', market);
    }
    if (currency) {
      resultsUrl.searchParams.set('currency', currency);
    }
    if (exchange) {
      resultsUrl.searchParams.set('exchange', exchange);
    }
    if (incorporation) {
      resultsUrl.searchParams.set('incorporation', incorporation);
    }
    if (statusDatesType) {
      resultsUrl.searchParams.set('statusDatesType', statusDatesType);
    }
    if (startDate) {
      resultsUrl.searchParams.set('startDate', dateFilter(startDate));
    }
    if (endDate) {
      resultsUrl.searchParams.set('endDate', dateFilter(endDate));
    }
    if (grossProceedsGt) {
      resultsUrl.searchParams.set('grossProceedsGt', grossProceedsGt);
    }
    if (grossProceedsLt) {
      resultsUrl.searchParams.set('grossProceedsLt', grossProceedsLt);
    }
    if (newSavedSearchName) {
      resultsUrl.searchParams.set('savedSearchName', newSavedSearchName);
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true
    });
  };

  const financialLeagueSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL('/new-issues/results', baseUrl);

    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    resultsUrl.searchParams.set('searchType', 'league');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (leagueTables) {
      resultsUrl.searchParams.set('leagueTables', leagueTables);
    }
    if (creditType) {
      resultsUrl.searchParams.set('creditType', creditType);
    }
    if (countBy) {
      resultsUrl.searchParams.set('countBy', countBy);
    }
    if (corporateGovernment) {
      resultsUrl.searchParams.set('corporateGovernment', corporateGovernment);
    }
    if (securityTypes) {
      resultsUrl.searchParams.set('securityTypes', securityTypes);
    }
    if (offeringProcedure) {
      resultsUrl.searchParams.set('offeringProcedure', offeringProcedure);
    }
    if (terms) {
      resultsUrl.searchParams.set('terms', terms);
    }
    if (market) {
      resultsUrl.searchParams.set('market', market);
    }
    if (currency) {
      resultsUrl.searchParams.set('currency', currency);
    }
    if (exchange) {
      resultsUrl.searchParams.set('exchange', exchange);
    }
    if (incorporation) {
      resultsUrl.searchParams.set('incorporation', incorporation);
    }
    if (searchNewIssuesGICS) {
      resultsUrl.searchParams.set('searchNewIssuesGICS', searchNewIssuesGICS.toString());
    }
    if (searchTargetNAICS) {
      resultsUrl.searchParams.set('searchTargetNAICS', searchTargetNAICS);
    }
    if (searchTargetSIC) {
      resultsUrl.searchParams.set('searchTargetSIC', searchTargetSIC);
    }
    if (startDate) {
      resultsUrl.searchParams.set('startDate', dateFilter(startDate));
    }
    if (endDate) {
      resultsUrl.searchParams.set('endDate', dateFilter(endDate));
    }
    resultsUrl.searchParams.set('statusDatesType', 'C');
    if (grossProceedsLt) {
      resultsUrl.searchParams.set('grossProceedsLt', grossProceedsLt);
    }
    if (grossProceedsGt) {
      resultsUrl.searchParams.set('grossProceedsGt', grossProceedsGt);
    }
    if (newSavedSearchName) {
      resultsUrl.searchParams.set('savedSearchName', newSavedSearchName);
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true
    });
  };

  const getAccessToken = useGetAccessToken();

  const saveSearch = async (searchType: string) => {
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    // saved search must have a name set...
    if (!newSavedSearchName || newSavedSearchName.toString().trim().length < 1) {
      return;
    }

    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await getNewIssuesSearchResults({
      searchType: searchType,
      issuerName: issuerName || undefined,
      stockSymbol: stockSymbol || undefined,
      statusDatesType: statusDatesType || 'C',
      startDate: dateFilter(startDate),
      endDate: dateFilter(endDate),
      leagueTables: leagueTables || '',
      creditType: creditType || '',
      countBy: countBy || '',
      securityTypes: securityTypes || '',

      stSelectDeselectCommonEquity: stSelectDeselectCommonEquity || false,
      stSelectDeselectPreferredEquity: stSelectDeselectPreferredEquity || false,
      stSelectDeselectStructuredProduct: stSelectDeselectStructuredProduct || false,
      stSelectDeselectDebt: stSelectDeselectDebt || false,
      stSelectDeselectIncomeFunds: stSelectDeselectIncomeFunds || false,

      stCommon: stCommon || false,
      stFlowThrough: stFlowThrough || false,
      stConvertibleDebt: stConvertibleDebt || false,

      stPreferredShares: stPreferredShares || false,
      stPreferredSecurity: stPreferredSecurity || false,

      stSplitShares: stSplitShares || false,
      stInvestmentTrust: stInvestmentTrust || false,
      stFlowThroughLtdPartnership: stFlowThroughLtdPartnership || false,

      stBondDebentureNote: stBondDebentureNote || false,
      stMediumTermNote: stMediumTermNote || false,
      stMortgageBond: stMortgageBond || false,
      stDepositNote: stDepositNote || false,
      stSerialDebt: stSerialDebt || false,
      stAssetBackedSecurities: stAssetBackedSecurities || false,
      stMortgageBackedSecurities: stMortgageBackedSecurities || false,
      stCapitalSecurities: stCapitalSecurities || false,
      stMapleBond: stMapleBond || false,

      stBusinessTrust: stBusinessTrust || false,
      stOilGasRoyaltyTrust: stOilGasRoyaltyTrust || false,
      stREIT: stREIT || false,
      stPowerPipelineTrust: stPowerPipelineTrust || false,
      stOtherTrust: stOtherTrust || false,

      stConvertibleTypes: stConvertibleTypes || '',

      distributionType: distributionType || '',
      corporateGovernment: corporateGovernment || undefined,
      searchNewIssuesGICS: searchNewIssuesGICS || [],
      searchTargetNAICS: searchTargetNAICS || '',
      searchTargetSIC: searchTargetSIC || '',
      searchUnderwriter: searchUnderwriter || '',
      optionAsBookrunner: optionAsBookrunner || false,
      optionAsLead: optionAsLead || false,
      offeringProcedure: offeringProcedure || undefined,
      terms: terms || undefined,
      market: market || undefined,
      currency: currency || undefined,
      exchange: exchange || undefined,
      incorporation: incorporation || undefined,
      grossProceedsGt: grossProceedsGt || '',
      grossProceedsLt: grossProceedsLt || '',
      savedSearchName: newSavedSearchName || undefined,
      page: 1,
      numberOfRows: 1,
    }, getAccessToken);

    refreshSavedSearches();
    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#0E643D', width: '280px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <CheckIcon fill="white" />
        <div>Search form saved</div>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#0E643D', color: 'white' } });
  }

  const deleteSavedSearch = async (savedSearchId: string, searchType: string) => {
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await deleteSearch({
      id: savedSearchId
    }, getAccessToken);

    refreshSavedSearches();
    let deletedSearchName: any;
    if (searchType === 'advanced') {
      deletedSearchName = savedSearches?.newIssuesAdvanced.find(
        s => (s.searchId === parseInt(savedSearchId, 10))
      )?.searchName;
    } else {
      deletedSearchName = savedSearches?.newIssuesLeague.find(
        s => (s.searchId === parseInt(savedSearchId, 10))
      )?.searchName;
    }

    setNewSavedSearchName('');

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#333333', width: '320px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <div>Search form removed</div>
        <button style={{ margin: 0, padding: 0, fontWeight: 'bold', background: 0, border: 0, color: 'white', textDecoration: 'underline' }} onClick={async () => {
          if (deletedSearchName) {
            toast.dismiss(t.id);
            await setNewSavedSearchName(deletedSearchName);
            await saveSearch(searchType);
            await refreshSavedSearches();
          }
        }}>
          Undo
        </button>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#333333', color: 'white' } });
  };

  return (
    <Page aria-label="Financial Post New Issues">
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingBasicId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post New Issues - Basic Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on new issues including public offerings and private
            placements of Canadian companies, governments and agencies. Data
            goes back to 1993. Updated hourly.
          </Text>

          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details
            will narrow your search results.
          </Text>

          <form
            aria-labelledby={headingBasicId}
            onSubmit={(e) => {
              e.preventDefault();
              financialBasicSubmitData();
            }}
          >
            <TextField
              mt="lg"
              label="Issuer Name"
              value={issuerName}
              onChange={setIssuerName}
            />
            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />
            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px', fontWeight: 500 }}>
                Status Dates
              </label>
              <RadioList
                aria-label="Status dates"
                value={statusDatesType}
                onChange={(e) => {
                  localStorage.setItem('statusDatesTypeNI', `${e}`);
                  setStatusDatesType(e);
                }}
                options={[
                  {
                    value: 'P',
                    label: 'Preliminary'
                  },
                  {
                    value: 'C',
                    label: 'Completed'
                  },
                  {
                    value: 'W',
                    label: 'Withdrawn'
                  },
                  {
                    value: 'A',
                    label: 'All'
                  }
                ]}
              />
              <DateRangePicker
                startDate={startDate}
                retainEndDateOnFirstSelection={true}
                moveRangeOnFirstSelection={false}
                // eslint-disable-next-line no-octal
                minDate={minDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
            <div>
              <Button mt="lg" label="Search" mb={'lg'} type="submit"/>
            </div>
          </form>
          <div>
            <ClearButton
              onClick={clearCheck}
              className="clearstatus-new-search"
            />
          </div>
        </Tab>
        <Tab label="Advanced">
          <Heading id={headingAdvancedId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post New Issues - Advanced Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on new issues including public offerings and private
            placements of Canadian companies, governments and agencies. Data
            goes back to 1993. Updated hourly.
          </Text>

          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details
            will narrow your search results.
          </Text>

          <form
            aria-labelledby={headingAdvancedId}
            onSubmit={(e) => {
              e.preventDefault();
              financialAdvanceSubmitData();
            }}
          >

            <div>
              <Heading level="2" size="lg" mb="sm">
                  <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
                  My Saved Searches
              </Heading>

              <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches_adv">Saved Searches</label>
              <Row>
                <select id="select_saved_searches_adv"
                  value={selectedSavedSearch}
                  onChange={(e) => {
                    populateFromSavedSearch(e.target.value, 'advanced');
                  }}>
                  <option>No Search Selected</option>
                  { savedSearches?.newIssuesAdvanced.map(search => (
                      <option value={search.searchId}>{search.searchName}</option>
                  ))}
                </select>

                {selectedSavedSearch && (
                    <button type="button" style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} aria-label="Delete saved search" onClick={(e) => { deleteSavedSearch(selectedSavedSearch, 'advanced') }}>
                      <TrashIcon size='sm' />
                    </button>
                  )
                }
              </Row>
            </div>

            <TextField
              mt="lg"
              label="Issuer Name"
              value={issuerName}
              onChange={setIssuerName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />

            <div>
              <label style={{ display: 'block', marginBottom: '10px', fontWeight: 500 }}>
                Dealmakers League Table
              </label>
              <RadioList
                aria-label="Dealmakers League Table"
                value={leagueTables}
                onChange={(e) => {
                  localStorage.setItem('leagueReadioNI', `${e}`);
                  setLeagueTables(e);
                }}
                // onChange={setLeagueTables}
                direction="row"
                options={leagueTablesLookup}
              />
            </div>

            <div>
              <SelectField
                title='Corporate / Government'
                value={corporateGovernment}
                setValue={setCorporateGovernment}
                options={corporateGovernmentLookup}
              />
            </div>

            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px', fontWeight: 500 }}>
                Security Types
              </label>
              <RadioList
                aria-label="Security Types"
                value={securityTypes}
                onChange={(e) => {
                  localStorage.setItem('securityRadioNI', `${e}`);
                  setSecurityTypes(e);
                }}
                direction="row"
                options={securityTypesLookup}
              />
            </div>

            { securityTypes === 'choose' &&
            <section style={{ display: 'inline' }} aria-label='Security Types'>
              <div style={{ marginLeft: '40px' }} >
                <text>
                Here you may select multiple security filters in order to get a combined result.
                </text>
                <div>
                  <h3>
                  Common Equity
                  </h3>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type="checkbox"
                      id="chk_select_common_eq"
                      checked={stSelectDeselectCommonEquity}
                      onChange={(e) => {
                        setStSelectDeselectCommonEquity(e.target.checked);
                        selectDeselectCommonEquity(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_select_common_eq"
                    >
                      SELECT/UNSELECT
                    </label>
                  </div>

                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_common"
                      checked={stCommon}
                      onChange={(e) => {
                        setStCommon(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_common"
                    >
                      Common & Common Units
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_flow_through"
                      checked={stFlowThrough}
                      onChange={(e) => {
                        setStFlowThrough(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_flow_through"
                    >
                      Flow-Through
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_conv_debt"
                      checked={stConvertibleDebt}
                      onChange={(e) => {
                        setStConvertibleDebt(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_conv_debt"
                    >
                      Convertible Debt
                    </label>
                  </div>
                </div>
                <div>
                  <h3>
                  Income Funds
                  </h3>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type="checkbox"
                      id="chk_select_income_funds"
                      checked={stSelectDeselectIncomeFunds}
                      onChange={(e) => {
                        setStSelectDeselectIncomeFunds(e.target.checked);
                        selectDeselectIncomeFunds(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_select_income_funds"
                    >
                      SELECT/UNSELECT
                    </label>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <RadioList
                      aria-label="Trust unit or Convertible debt or both"
                      value={stConvertibleTypes}
                      onChange={(e) => {
                        localStorage.setItem('stConvertibleTypesNI', `${e}`);
                        setStConvertibleTypes(e);
                      }}
                      direction="row"
                      options={securityConvertibleDebtLookup}
                    />
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_bus_trust"
                      checked={stBusinessTrust}
                      onChange={(e) => {
                        setStBusinessTrust(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_bus_trust"
                    >
                      Business Trust
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_oil_gas_roy"
                      checked={stOilGasRoyaltyTrust}
                      onChange={(e) => {
                        setStOilGasRoyaltyTrust(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_oil_gas_roy"
                    >
                      Oil &amp; Gas Royalty Trust
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_reit"
                      checked={stREIT}
                      onChange={(e) => {
                        setStREIT(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_reit"
                    >
                      REITs (Real Estate Investment Trust)
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_po_pp"
                      checked={stPowerPipelineTrust}
                      onChange={(e) => {
                        setStPowerPipelineTrust(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_po_pp"
                    >
                      Power &amp; Pipeline Trust
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_other_trust"
                      checked={stOtherTrust}
                      onChange={(e) => {
                        setStOtherTrust(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_other_trust"
                    >
                      Other Trust
                    </label>
                  </div>
                </div>
                <div>
                  <h3>
                  Preferred Equity
                  </h3>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type="checkbox"
                      id="chk_select_pref_eq"
                      checked={stSelectDeselectPreferredEquity}
                      onChange={(e) => {
                        setStSelectDeselectPreferredEquity(e.target.checked);
                        selectDeselectPreferredEquity(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_select_pref_eq"
                    >
                      SELECT/UNSELECT
                    </label>
                  </div>

                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_pref_shares"
                      checked={stPreferredShares}
                      onChange={(e) => {
                        setStPreferredShares(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_pref_shares"
                    >
                      Preferred Shares
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_pref_sec"
                      checked={stPreferredSecurity}
                      onChange={(e) => {
                        setStPreferredSecurity(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_pref_sec"
                    >
                      Preferred Security
                    </label>
                  </div>
                </div>
                <div>
                  <h3>
                  Structured Product
                  </h3>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type="checkbox"
                      id="chk_select_struct_prod"
                      checked={stSelectDeselectStructuredProduct}
                      onChange={(e) => {
                        setStSelectDeselectStructuredProduct(e.target.checked);
                        selectDeselectStructuredProduct(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_select_struct_prod"
                    >
                      SELECT/UNSELECT
                    </label>
                  </div>

                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_split_shares"
                      checked={stSplitShares}
                      onChange={(e) => {
                        setStSplitShares(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_split_shares"
                    >
                      Split Shares
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_inv_trust"
                      checked={stInvestmentTrust}
                      onChange={(e) => {
                        setStInvestmentTrust(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_inv_trust"
                    >
                      Investment Trust
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_flow_thr_ltd_part"
                      checked={stFlowThroughLtdPartnership}
                      onChange={(e) => {
                        setStFlowThroughLtdPartnership(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_flow_thr_ltd_part"
                    >
                      Flow-through Limited Partnership
                    </label>
                  </div>
                </div>
                <div>
                  <h3>
                  Debt (excluding convertible)
                  </h3>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type="checkbox"
                      id="chk_select_debt"
                      checked={stSelectDeselectDebt}
                      onChange={(e) => {
                        setStSelectDeselectDebt(e.target.checked);
                        selectDeselectDebt(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_select_debt"
                    >
                      SELECT/UNSELECT
                    </label>
                  </div>

                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_bond_deb_note"
                      checked={stBondDebentureNote}
                      onChange={(e) => {
                        setStBondDebentureNote(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_bond_deb_note"
                    >
                      Bond, Debenture and Note
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_med_term_note"
                      checked={stMediumTermNote}
                      onChange={(e) => {
                        setStMediumTermNote(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_med_term_note"
                    >
                      Medium Term Note
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_morg_bond"
                      checked={stMortgageBond}
                      onChange={(e) => {
                        setStMortgageBond(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_morg_bond"
                    >
                      Mortgage Bond
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_dep_note"
                      checked={stDepositNote}
                      onChange={(e) => {
                        setStDepositNote(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_dep_note"
                    >
                      Deposit Note
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_ser_debt"
                      checked={stSerialDebt}
                      onChange={(e) => {
                        setStSerialDebt(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_ser_debt"
                    >
                      Serial Debt
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_asst_bck_sec"
                      checked={stAssetBackedSecurities}
                      onChange={(e) => {
                        setStAssetBackedSecurities(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_asst_bck_sec"
                    >
                      Asset-backed Securities
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_morg_bck_sec"
                      checked={stMortgageBackedSecurities}
                      onChange={(e) => {
                        setStMortgageBackedSecurities(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_morg_bck_sec"
                    >
                      Mortgage-backed Securities
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_cap_sec"
                      checked={stCapitalSecurities}
                      onChange={(e) => {
                        setStCapitalSecurities(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_cap_sec"
                    >
                      Capital Securities
                    </label>
                  </div>
                  <div className='col-third'>
                    <input
                      type="checkbox"
                      id="chk_maple_bond"
                      checked={stMapleBond}
                      onChange={(e) => {
                        setStMapleBond(e.target.checked);
                      }}
                    ></input>
                    <label
                      htmlFor="chk_maple_bond"
                    >
                      Maple Bond
                    </label>
                  </div>
                </div>
              </div>
            </section>
            }

            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px', fontWeight: 500 }}>
                Distribution Types
              </label>
              <RadioList
                aria-label="Distribution Types"
                value={distributionType}
                onChange={(e) => {
                  localStorage.setItem('distributionRadioNI', `${e}`);
                  setDistributionType(e);
                }}
                direction="row"
                options={distributionTypeLookup}
              />
            </div>
            <div>
              <SelectField
                title='Offering Procedure'
                value={offeringProcedure}
                setValue={setOfferingProcedure}
                options={offeringProcedureLookup}
              />
            </div>
            <div>
              <SelectField
                title='Terms'
                value={terms}
                setValue={setTerms}
                options={termsLookup}
              />
            </div>
            <div>
              <SelectField
                title='Market'
                value={market}
                setValue={setMarket}
                options={marketLookup}
              />
            </div>
            <div>
              <SelectField
                title='Currency'
                value={currency}
                setValue={setCurrency}
                options={currencyLookup}
              />
            </div>
            <div>
              <SelectField
                title='Exchange'
                value={exchange}
                setValue={setExchange}
                options={exchangesNewIssuesLookup}
              />
            </div>
            <div>
              <SelectField
                title='Incorporation'
                value={incorporation}
                setValue={setIncorporation}
                options={incorporationLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectField
                label="GICS"
                items={gicsLookup}
                value={searchNewIssuesGICS}
                onChange={setSearchNewIssuesGICS}
              />
            </div>
            <div>
              <SelectField
                title='NAICS Codes (2015 onwards)'
                value={searchTargetNAICS}
                setValue={setSearchTargetNAICS}
                options={naicsLookupCodes}
              />
            </div>
            <div>
              <SelectField
                title='SIC Codes'
                value={searchTargetSIC}
                setValue={setSearchTargetSIC}
                options={sicLookup}
              />
            </div>
            <div>
              <SelectFieldLtGt
                title='Gross Proceeds'
                valueGt={grossProceedsGt}
                setValueGt={setGrossProceedsGt}
                optionsGt={grossProceedsGTLookup}
                valueLt={grossProceedsLt}
                setValueLt={setGrossProceedsLt}
                optionsLt={grossProceedsLTLookup}
              />
            </div>
            <div>
              <SelectField
                title='Underwriter'
                value={searchUnderwriter}
                setValue={setSearchUnderwriter}
                options={underwriterLookup}
              />
              <CheckOptions options={[
                {
                  value: optionAsBookrunner,
                  setValue: setOptionAsBookrunner,
                  label: 'As Bookrunner'
                },
                {
                  value: optionAsLead,
                  setValue: setOptionAsLead,
                  label: 'As Lead'
                },
              ]} />
            </div>
            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px', fontWeight: 500 }}>
                Status Dates
              </label>
              <RadioList
                aria-label="Status Dates"
                value={statusDatesType}
                onChange={(e) => {
                  localStorage.setItem('statusDatesTypeNI', `${e}`);
                  setStatusDatesType(e);
                }}
                // onChange={setStatusDatesType}
                direction="row"
                options={statusDatesTypeLookup}
              />
            </div>

            <DateRangePicker
              startDate={startDate}
              retainEndDateOnFirstSelection={true}
              moveRangeOnFirstSelection={false}
              minDate={minDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />

            <div>
              <Heading level="2" size="lg" mb="sm" mt="lg">
                Save Search
              </Heading>
              <Row>
                <div style={{ maxWidth: '90%' }}>
                  <TextField
                    label="Name of search form"
                    value={newSavedSearchName}
                    onChange={setNewSavedSearchName}
                  />
                  <Text color="light" size="sm" mt="xxs">
                    Give your search form a meaningful name
                  </Text>
                </div>

                <div>
                  { ((savedSearches?.newIssuesAdvanced.find(s => s.searchName === newSavedSearchName)
                    ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch, 'advanced') } }>
                      <StarIcon size='sm' />
                      <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
                    </button>)
                    : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => saveSearch('advanced')}>
                      <StarIcon size='sm' color='clear' />
                      <ScreenReaderOnly>Save search</ScreenReaderOnly>
                    </button>
                  )))}
                </div>
              </Row>
            </div>

            <div>
              <Button
                mt="xl"
                label="Search"
                mb={'lg'}
                type="submit"
              />
            </div>
          </form>
          <div>
            <ClearButton
              onClick={clearCheck}
              className="clearstatus-new-search"
            />
          </div>
        </Tab>
        {
          false && (
        <Tab label="League Tables">
          <Heading id={headingLeagueId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post New Issues - League Tables Search
          </Heading>
          <Text mt="md" mb="md" lineHeight="md">
            Information on new issues including public offerings and private
            placements of Canadian companies, governments and agencies. Data
            goes back to 1993. Updated hourly.
          </Text>

          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details
            will narrow your search results.
          </Text>
          <form
            aria-labelledby={headingLeagueId}
            onSubmit={(e) => {
              e.preventDefault();
              financialLeagueSubmitData();
            }}
          >
            <div>
              <Heading level="2" size="lg" mb="sm">
                  <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
                  My Saved Searches
              </Heading>

              <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches_league">Saved Searches</label>
              <Row>
                <select id="select_saved_searches_league"
                  value={selectedSavedSearch}
                  onChange={(e) => {
                    populateFromSavedSearch(e.target.value, 'league');
                  }}>
                  <option>No Search Selected</option>
                  { savedSearches?.newIssuesLeague.map(search => (
                      <option value={search.searchId}>{search.searchName}</option>
                  ))}
                </select>

                {selectedSavedSearch && (
                    <button style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch, 'league') }}>
                      <TrashIcon size='sm' />
                    </button>
                  )
                }
              </Row>
            </div>
            <br></br>
            <div>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                Dealmakers League Table
              </label>
              <RadioList
                aria-label="Dealmakers League Table"
                value={leagueTables}
                onChange={(e) => {
                  localStorage.setItem('leagueReadioNI', `${e}`);
                  setLeagueTables(e);
                }}
                // onChange={setLeagueTables}
                direction="row"
                options={leagueTablesLookup}
              />
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                Credit Type
              </label>
              <RadioList
                aria-label="Credit Type"
                value={creditType}
                /*
                onChange={(e) => {
                  localStorage.setItem('creditTypeNI', `${e}`);
                  setLeagueTables(e);
                }} */
                onChange={setCreditType}
                direction="row"
                options={leagueCreditTypeLookup}
              />
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                Count By
              </label>
            <RadioList
                aria-label="Count By"
                value={countBy}
                /*
                onChange={(e) => {
                  localStorage.setItem('creditTypeNI', `${e}`);
                  setLeagueTables(e);
                }} */
                onChange={setCountBy}
                direction="row"
                options={leagueCountByLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <SelectField
                title='Corporate / Government'
                value={corporateGovernment}
                setValue={setCorporateGovernment}
                options={corporateGovernmentLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                Security Types
              </label>
              <RadioList
                aria-label="Security Types"
                value={securityTypes}
                onChange={(e) => {
                  localStorage.setItem('securityRadioNI', `${e}`);
                  setSecurityTypes(e);
                }}
                // onChange={setSecurityTypes}
                direction="row"
                options={securityTypesLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <label htmlFor='offering_procedure' style={{ display: 'block', marginBottom: '5px' }}>
                Offering Procedure
              </label>
              <select
                id="offering_procedure"
                value={offeringProcedure}
                name="offering_procedure"
                onChange={(e) => {
                  setOfferingProcedure(e.target.value);
                }}
              >
                {offeringProcedureLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label htmlFor='terms' style={{ display: 'block', marginBottom: '5px' }}>
                Terms
              </label>
              <select
                id="terms"
                value={terms}
                name="terms"
                onChange={(e) => {
                  setTerms(e.target.value);
                }}
              >
                {termsLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label htmlFor='market' style={{ display: 'block', marginBottom: '5px' }}>
                Market
              </label>
              <select
                id="market"
                name="market"
                value={market}
                onChange={(e) => {
                  setMarket(e.target.value);
                }}
              >
                {marketLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label htmlFor='currency' style={{ display: 'block', marginBottom: '5px' }}>
                Currency
              </label>
              <select
                id="currency"
                value={currency}
                name="currency"
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                {currencyLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>
            <div style={{ marginTop: '24px' }}>
              <label htmlFor='exchange' style={{ display: 'block', marginBottom: '5px' }}>
                Exchange
              </label>
              <select
                id="exchange"
                value={exchange}
                name="exchange"
                onChange={(e) => {
                  setExchange(e.target.value);
                }}
              >
                {exchangesNewIssuesLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>
            <div style={{ marginTop: '24px' }}>
              <label htmlFor='incorporation' style={{ display: 'block', marginBottom: '5px' }}>
                Incorporation
              </label>
              <select
                id="incorporation"
                name="incorporation"
                value={incorporation}
                onChange={(e) => {
                  setIncorporation(e.target.value);
                }}
              >
                {incorporationLookup.map((l) => (
                  <option
                    value={l.id}
                    dangerouslySetInnerHTML={{ __html: l.name }}
                  ></option>
                ))}
              </select>
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectField
                label="GICS"
                items={gicsLookup}
                value={searchNewIssuesGICS}
                onChange={setSearchNewIssuesGICS}
              />
            </div>
            <div>
              <SelectField
                title='NAICS Codes (2015 onwards)'
                value={searchTargetNAICS}
                setValue={setSearchTargetNAICS}
                options={naicsLookupCodes}
              />
            </div>
            <div>
              <SelectField
                title='SIC Codes'
                value={searchTargetSIC}
                setValue={setSearchTargetSIC}
                options={sicLookup}
              />
            </div>
            <Heading id="gross_proceed_id_league" level="2" size={['md', 'md', 'lg']} weight="bold">
              Gross Proceeds
            </Heading>
            <div aria-label="gross_proceed_id_league">
              <SelectFieldLtGt
                title=''
                valueGt={grossProceedsGt}
                setValueGt={setGrossProceedsGt}
                optionsGt={grossProceedsGTLookup}
                valueLt={grossProceedsLt}
                setValueLt={setGrossProceedsLt}
                optionsLt={grossProceedsLTLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                Completed
              </label>
              <DateRangePicker
                startDate={startDate}
                retainEndDateOnFirstSelection={true}
                moveRangeOnFirstSelection={false}
                minDate={minDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
            <div>
              <Heading level="2" size="lg" mb="sm" mt="lg">
                Save Search
              </Heading>
              <Row>
                <div style={{ maxWidth: '90%' }}>
                  <TextField
                    label="Name of search form"
                    value={newSavedSearchName}
                    onChange={setNewSavedSearchName}
                  />
                  <Text color="light" size="sm" mt="xxs">
                    Give your search form a meaningful name
                  </Text>
                </div>
                <div>
                  { ((savedSearches?.newIssuesAdvanced.find(s => s.searchName === newSavedSearchName)
                    ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch, 'league') } }>
                      <StarIcon size='sm' />
                      <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
                    </button>)
                    : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => saveSearch('league')}>
                      <StarIcon size='sm' color='clear' />
                      <ScreenReaderOnly>Save search</ScreenReaderOnly>
                    </button>
                  )))}
                </div>
              </Row>
            </div>
            <div>
              <Button
                mt="xl"
                label="Search"
                mb={'lg'}
                type="submit"
              />
            </div>
          </form>
          <div>
            <ClearButton
              onClick={clearCheck}
              className="clearstatus-new-search"
            />
          </div>
        </Tab>
          )
        }
      </Tabs>

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />

    </Page>
  );
}
