import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { HideOnPrint } from 'components/HideOnMobile';

import { useId, useQueryParams } from '../../../hooks';

import { useCorporateSurveysSearchResults } from '../../../data';

import {
  useNumberOfRows,
  useSetNumberOfRows
} from '../../../containers/AuthProvider';
import { stringify } from 'query-string';

export function CorporateSurveysResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    [
      'searchForm',
      'companyName',
      'stockSymbol',
      'city',
      'provinces[]',
      'country[]',
      'searchPredecessor',
      'searchType',
      'exchange',
      'exchangeListed',
      'exchangeSuspended',
      'contacttype',
      'areaCode',
      'companyType',
      'companySubType',
      'jurisdiction',
      'incorporationYear',
      'majorShareholder',
      'optShareholderInterest10Prcnt',
      'optForeignOwnership10Prcnt',
      'subsidiary',
      'directorOfficerName',
      'auditor',
      'banker',
      'lawyer',
      'transferAgent',
      'idxSPTSXComp',
      'idxSPTSX60',
      'idxTSX30',
      'idxTSXVenTier1',
      'idxTSXVenTier2',
      'idxTSXVenTier3',
      'idxFP500',
      'idxCleantech',
      'idxBlockchain',
      'idxCannabis',
      'sicCodes',
      'sicCodeType',
      'gicCodes',
      'naicsCodes',
      'naicsCodeType',
      'normalCourseIssuerBid',
      'substantialIssuerBid',
      'dividendReinvestmentPlan',

      // begin financial search
      'textSearch',
      'finSearchSortOpt',
      'finSearchSortDirection',

      'category1',
      'dataItem1',
      'year1',
      'boolOp1',
      'operation1',
      'value1',

      'category2',
      'dataItem2',
      'year2',
      'boolOp2',
      'operation2',
      'value2',

      'category3',
      'dataItem3',
      'year3',
      'boolOp3',
      'operation3',
      'value3',

      'category4',
      'dataItem4',
      'year4',
      'boolOp4',
      'operation4',
      'value4',

      'category5',
      'dataItem5',
      'year5',
      'boolOp5',
      'operation5',
      'value5',
      // end financial search

      'dateInfo',
      'db',
      'page',
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();

  const searchResults = useCorporateSurveysSearchResults({
    searchForm: queryParams.searchForm || 'basic',
    companyName: queryParams.companyName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    city: queryParams.city || undefined,
    provinces: queryParams['provinces[]'] || '',
    country: queryParams['country[]'] || '',
    searchType: queryParams.searchType || '',
    searchPredecessor: queryParams.searchPredecessor || '',
    exchange: queryParams.exchange || '',
    exchangeListed: queryParams.exchangeListed || '',
    exchangeSuspended: queryParams.exchangeSuspended || '',
    contacttype: queryParams.contacttype || '',
    areaCode: queryParams.areaCode || '',
    companyType: queryParams.companyType || '',
    companySubType: queryParams.companySubType || '',
    jurisdiction: queryParams.jurisdiction || '',
    incorporationYear: queryParams.incorporationYear || '',
    majorShareholder: queryParams.majorShareholder || '',
    directorOfficerName: queryParams.directorOfficerName || '',
    optShareholderInterest10Prcnt: queryParams.optShareholderInterest10Prcnt || '',
    optForeignOwnership10Prcnt: queryParams.optForeignOwnership10Prcnt || '',
    subsidiary: queryParams.subsidiary || '',
    auditor: queryParams.auditor || '',
    banker: queryParams.banker || '',
    lawyer: queryParams.lawyer || '',
    transferAgent: queryParams.transferAgent || '',
    idxSPTSXComp: queryParams.idxSPTSXComp || '',
    idxSPTSX60: queryParams.idxSPTSX60 || '',
    idxTSX30: queryParams.idxTSX30 || '',
    idxTSXVenTier1: queryParams.idxTSXVenTier1 || '',
    idxTSXVenTier2: queryParams.idxTSXVenTier2 || '',
    idxTSXVenTier3: queryParams.idxTSXVenTier3 || '',
    idxFP500: queryParams.idxFP500 || '',
    idxCleantech: queryParams.idxCleantech || '',
    idxBlockchain: queryParams.idxBlockchain || '',
    idxCannabis: queryParams.idxCannabis || '',
    naicsCodes: queryParams.naicsCodes || '',
    naicsCodeType: queryParams.naicsCodeType || '',
    sicCodes: queryParams.sicCodes || '',
    sicCodeType: queryParams.sicCodeType || '',
    gicCodes: queryParams.gicCodes || '',
    normalCourseIssuerBid: queryParams.normalCourseIssuerBid || '',
    substantialIssuerBid: queryParams.substantialIssuerBid || '',
    dividendReinvestmentPlan: queryParams.dividendReinvestmentPlan || '',

    textSearch: queryParams.textSearch || '',
    finSearchSortOpt: queryParams.finSearchSortOpt || '',
    finSearchSortDirection: queryParams.finSearchSortDirection || '',

    category1: queryParams.category1 || '',
    dataItem1: queryParams.dataItem1 || '',
    year1: queryParams.year1 || '',
    boolOp1: queryParams.boolOp1 || '',
    operation1: queryParams.operation1 || '',
    value1: queryParams.value1 || '',

    category2: queryParams.category2 || '',
    dataItem2: queryParams.dataItem2 || '',
    year2: queryParams.year2 || '',
    boolOp2: queryParams.boolOp2 || '',
    operation2: queryParams.operation2 || '',
    value2: queryParams.value2 || '',

    category3: queryParams.category3 || '',
    dataItem3: queryParams.dataItem3 || '',
    year3: queryParams.year3 || '',
    boolOp3: queryParams.boolOp3 || '',
    operation3: queryParams.operation3 || '',
    value3: queryParams.value3 || '',

    category4: queryParams.category4 || '',
    dataItem4: queryParams.dataItem4 || '',
    year4: queryParams.year4 || '',
    boolOp4: queryParams.boolOp4 || '',
    operation4: queryParams.operation4 || '',
    value4: queryParams.value4 || '',

    category5: queryParams.category5 || '',
    dataItem5: queryParams.dataItem5 || '',
    year5: queryParams.year5 || '',
    boolOp5: queryParams.boolOp5 || '',
    operation5: queryParams.operation5 || '',
    value5: queryParams.value5 || '',

    dateInfo: queryParams.dateInfo || '',
    db: queryParams.db || undefined,
    page,
    numberOfRows
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/corporate-surveys/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/corporate-surveys/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: '/corporate-surveys/csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const finSearchUsed = (queryParams.category1 && queryParams?.category1.length > 0);

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Corporate Surveys - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/corporate-surveys/search"
        />
      </HideOnPrint>

      {finSearchUsed && noResults && (
        <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found.
        </Text>
      )}

      {!finSearchUsed && noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && finSearchUsed && (
        <Card padding="none">
        <Table
          aria-labelledby={headingId}
          aria-describedby={descriptionId}
          loading={loading}
          expectedColumns={4}
          expectedRows={numberOfRows}
          isEmpty={!searchResults.resolvedData}
          data={searchResults.resolvedData}
          queryParams={queryParams}
          history={props.history.location.state}
        >
          <Table.Head>
            <Table.Row>
              { searchResults.resolvedData?.headings?.map(heading => (
                <Table.HeaderCell>
                  <Text alignX='center' alignY='middle'>
                    <span dangerouslySetInnerHTML={{ __html: heading.replaceAll('|', '<br>') }}></span>
                  </Text>
                </Table.HeaderCell>
              )) }
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {searchResults.resolvedData?.results.map((r, i) => (
              <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                <Table.Cell minWidth="md">
                  <Link
                    to={`/corporate-surveys/details/corp|fpsu|${r.key}`}
                    className="black-text"
                  >
                    <Text>{r.name}</Text>
                  </Link>
                </Table.Cell>
                {
                  r.values?.map(v => (
                  <Table.Cell>
                    <Text alignX='right'>
                        { v }
                    </Text>
                  </Table.Cell>
                  ))
                }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
      )}

      {!noResults && !finSearchUsed && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={4}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            history={props.history.location.state}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Company Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Former Names</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Symbols</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Coverage Status</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/corporate-surveys/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.name}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    {r.formerNames && r.formerNames.length > 0 && (
                      <BulletedList>
                        {r.formerNames.map((formerName) => (
                          <li>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {r.symbols && r.symbols.length > 0 && (
                      <BulletedList>
                        {r.symbols.map((formerName) => (
                          <li style={{ whiteSpace: 'nowrap' }}>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Text>
                      <span
                        style={{
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {r.coverageStatus?.toUpperCase()}
                      </span>
                    </Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
