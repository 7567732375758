import moment from 'moment';
import { number } from 'yup/lib/locale';
import React from 'react';
/**
 * format the date string.
 * @param {any} dateString?:string, row date string eg: 20201029
 * @returns {any} string | null, eg: 29/10/2020
 */
export const dateFormatter = (
  dateString?: string | number,
  outFormat: string = 'DD/MM/YYYY',
  inputFormat: string = 'YYYYMMDD'
): string | null => {
  return moment(dateString, inputFormat).format(outFormat);
};
/**
 *
 * @param currency?:number , currently USD
 * @returns string | null  eg: $200,000
 */
export const formatCurrency = (currency: number) => {
  const curencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return curencyFormatter.format(currency);
};
export const renderPriceOnDate = ({
  price,
  date,
  qualifierParam,
}: {
  price?: number;
  date?: number;
  qualifierParam?: 'currency' | 'percentage';
}) => {
  return (
    <>
      {price
        ? `${qualifierParam === 'currency' ? '$' : ''} 
				${Number(price).toLocaleString('en-US')} 
				${qualifierParam === 'percentage' ? '%' : ''}`
        : 'n.a.'}
      {date && (
        <>
          {' '}
          on
          <br />
          {dateFormatter(String(date), 'MMMM DD/YY')}
        </>
      )}
    </>
  );
};
export const roundToTwo = (num: Number) => {
  return parseFloat(String(num)).toFixed(2);
};
