import * as React from 'react';
import { useParams } from 'react-router';
import { useHelpDetails } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';

export function HelpDetailsPage () {
  const params = useParams<{ categorySlug: string }>();
  const helpDetails = useHelpDetails(
    params.categorySlug
  );

  const headingId = useId();

	// ensure window loads scrolled to top
	// on load
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

  return (
    <Page aria-labelledby={headingId} padding="none" width="full">
      <Page aria-labelledby={headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Help Details
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div style={{ flexGrow: 1 }}>
            {helpDetails?.contents && helpDetails?.contents.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    { helpDetails.title }
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Tiles columns={[1, 1]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: helpDetails.contents,
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}
          </div>
        </Row>
      </Page>
    </Page>
  );
}
