import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { RadioList } from '../../../components/RadioList';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function PredecessorDefunctCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();

  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);

  const [exportCompanyName, setExportCompanyName] = React.useState(true);
  const [exportIncorporationJurisdiction, setExportIncorporationJurisdiction] = React.useState(true);
  const [exportIncorporationDate, setExportIncorporationDate] = React.useState(true);
  const [exportIncorporationNote, setExportIncorporationNote] = React.useState(true);
  const [exportHistoryDate, setExportHistoryDate] = React.useState(true);
  const [exportHistory, setExportHistory] = React.useState(true);

  const getAccessToken = useGetAccessToken();
  const generateCsvFields = () => {
    const fields = [];
    if (exportCompanyName) {
      fields.push('companyname');
    }
    if (exportIncorporationJurisdiction) {
      fields.push('incorporationjurisdiction');
    }
    if (exportIncorporationDate) {
      fields.push('incorporationdate');
    }
    if (exportIncorporationNote) {
      fields.push('incorporationnote');
    }
    if (exportHistoryDate) {
      fields.push('historydate');
    }
    if (exportHistory) {
      fields.push('history');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/fppd/pd_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Post Predecessor &amp; Defunct - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Predecessor &amp; Defunct record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            setExportSelectDeselect(e.target.checked);
                            setExportCompanyName(e.target.checked);
                            setExportHistory(e.target.checked);
                            setExportHistoryDate(e.target.checked);
                            setExportIncorporationDate(e.target.checked);
                            setExportIncorporationJurisdiction(e.target.checked);
                            setExportIncorporationNote(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_company_name"
                          checked={exportCompanyName}
                          onChange={(e) => {
                            setExportCompanyName(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_company_name"
                        >
                          Company Name
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_incorp_jur"
                          checked={exportIncorporationJurisdiction}
                          onChange={(e) => {
                            setExportIncorporationJurisdiction(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_incorp_jur"
                        >
                          Incorporation Jurisdiction
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_incorp_date"
                          checked={exportIncorporationDate}
                          onChange={(e) => {
                            setExportIncorporationDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_incorp_date"
                        >
                          Incorporation Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_incorp_note"
                          checked={exportIncorporationNote}
                          onChange={(e) => {
                            setExportIncorporationNote(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_incorp_note"
                        >
                          Incorporation Note
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_hist_date"
                          checked={exportHistoryDate}
                          onChange={(e) => {
                            setExportHistoryDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_hist_date"
                        >
                          History Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_hist"
                          checked={exportHistory}
                          onChange={(e) => {
                            setExportHistory(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_hist"
                        >
                          History
                        </label>
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
