import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { getMarginStyles, MarginProps } from '../../utils/style';

export function ArrowLeftIcon (
  props: { color?: 'primary'; removeWhitespace?: boolean } & MarginProps
) {
  const theme = useTheme();
  const viewBox = props.removeWhitespace ? '7 0 8 24' : '0 0 24 24';
  return (
    <Svg
      height={24}
      viewBox={viewBox}
      width={props.removeWhitespace ? 8 : 24}
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      aria-hidden
      role="img"
    >
      <path
        fill={props.color ? theme.palette[props.color].main : 'black'}
        d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
      />
    </Svg>
  );
}

const Svg = styled.svg<MarginProps>((props) => ({
  ...getMarginStyles(props),
}));
