import { DefaultTheme } from 'styled-components/macro';
import { rem } from 'polished';

export const theme: DefaultTheme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1.5, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
      xl: 80, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 36, // em
      md: 48, // em
      lg: 62, // em
      xl: 75, // em
    },
  },
  palette: {
    primary: {
      main: '#2E4EBF',
    },
    subdued: {
      main: '#555555',
    },
    border: {
      main: '#767676',
      light: '#CACACA',
    },
    text: {
      main: '#000000',
      light: '#5A5A5A',
    },
    footer: {
      main: '#252525',
    },
    error: {
      main: '#950000',
    },
  },
  typography: {
    textSizes: {
      xs: rem('12px'),
      sm: rem('14px'),
      md: rem('16px'),
      lg: rem('24px'),
      xl: rem('26px'),
      xxl: rem('32px'),
      xxxl: rem('40px'),
    },
    defaultHeaderSizes: {
      1: 'xxxl',
      2: 'xxl',
      3: 'md',
      4: 'md',
      5: 'md',
      6: 'md',
    },
  },
  // Taken from https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  spacing: {
    xxxs: '2px',
    xxs: '4px',
    xs: '6px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },
  zIndex: {
    topNav: 100,
    sideNav: 100,
    dropDownMenus: 900,
    skipNavigationButton: 1000,
  },
};
