import * as React from 'react';
import { ScreenReaderOnly } from '../ScreenReaderOnly';

export function Status (props: {
  id?: string;
  message: string;
  visuallyHidden?: boolean;
  onRender?: (message: string) => React.ReactNode;
}) {
  const [currentMessage, setCurrentMessage] = React.useState('');
  React.useLayoutEffect(() => {
    if (props.message !== '') {
      if (props.message === currentMessage) {
        setCurrentMessage(props.message + '\u00A0');
      } else {
        setCurrentMessage(props.message);
      }
    }
  }, [props.message]);

  return (
    <div id={props.id} aria-live="polite" aria-atomic role="status">
      {props.message.length > 0
? (
        !props.visuallyHidden
? (
          <div>
            {props.onRender ? props.onRender(currentMessage) : currentMessage}
          </div>
        )
: (
          <ScreenReaderOnly>{currentMessage}</ScreenReaderOnly>
        )
      )
: null}
    </div>
  );
}
