export function combineNames (
  params:
    | { firstname?: string; lastname?: string; middlename?: string }
    | null
    | undefined
): string {
  if (!params) {
    return '';
  }

  if (params.middlename) {
    return `${params.firstname} ${params.middlename} ${params.lastname}`.trim();
  }

  return `${params.firstname} ${params.lastname}`.trim();
}

export const dateFilter = (date: Date = new Date()) => {
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  );
};
