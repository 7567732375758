/* eslint-disable */
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { stringify } from "query-string";
import { Page } from "../../../components/Page";
import { Heading } from "../../../components/Heading";
import { Table } from "../../../components/Table";
import { Card } from "../../../components/Card";
import { Text } from "../../../components/Text";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from "../../../hooks";
import { useMergersAcquisitionsSearchResults } from "../../../data";
import { LoadingScreenReaderMessage } from "../../../components/LoadingScreenReaderMessage";
import { BulletedList } from "../../../components/BulletedList";
import { ListItem } from "../../../components/ListItem";
import { HideOnPrint } from 'components/HideOnMobile';
import {
  useNumberOfRows,
  useSetNumberOfRows
} from '../../../containers/AuthProvider';
import { useSearchParams } from 'components/UseSearchParams';
import {
  advisorLookup,
  gicsLookup,
  legalAdvisorLookup,
  naicsLookup,
  sicLookup,
  fpIndustryLookup,
  targetTypeLookup
} from 'data/lookups';

export function MergersAcquisitionsResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const pageStateValue = history.location.state;
  const queryParams = useQueryParams(
    [
      'companyName',
      'searchRestrictTarget',
      'searchRestrictVendor',
      'searchRestrictAcquiror',
      'searchTargetType',
      'searchVendorType',
      'searchAcquirorType',
      'searchTargetFPIndustry',
      'searchVendorFPIndustry',
      'searchAcquirorFPIndustry',
      'searchTargetNAICS',
      'searchVendorNAICS',
      'searchAcquirorNAICS',
      'searchTargetSIC',
      'searchVendorSIC',
      'searchAcquirorSIC',
      'searchTargetGICS',
      'searchVendorGICS',
      'searchAcquirorGICS',
      'searchLocationTarget',
      'searchLocationVendor',
      'searchLocationAcquiror',
      'searchFinancialAdvisor',
      'searchLegalAdvisor',
		  'searchPubliclyTraded',
		  'searchDivestiture',
		  'searchPrivatelyOwned',
		  'searchForeignTarget',
      'searchDealValueGt',
      'searchDealValueLt',
      'searchPercentageBoughtGt',
      'searchPercentageBoughtLt',
      'keywords',
      'searchType',
      'searchDealDescription',
      'searchTargetBusinessDescription',
      'searchVendorBusinessDescription',
      'searchAcquirorBusinessDescription',
      'searchPaymentCash',
      'searchPaymentStock',
      'searchPaymentAssumptionDebt',
      'searchPaymentOther',
      'startDate',
      'endDate',
      'searchDatesAnnounced',
      'searchDatesUpdated',
      'searchDealStatusCompleted',
      'searchDealStatusPending',
      'searchDealStatusTerminated',
      'dateInfo',
      'page'
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();

  const sortOptions = [
    {
      id: 'target',
      name: 'Target',
    },
    {
      id: 'vendor',
      name: 'Vendor',
    },
    {
      id: 'acquiror',
      name: 'Acquiror',
    },
    {
      id: 'value',
      name: 'Deal Value',
    },
    {
      id: 'date',
      name: 'Announced',
    },
    {
      id: 'status',
      name: 'Deal Status',
    },
  ];

  const [sortOption, setSortOption] = React.useState('date');

  const searchResults = useMergersAcquisitionsSearchResults({
    companyName: queryParams.companyName || undefined,
    searchRestrictTarget: queryParams.searchRestrictTarget ? true : false,
    searchRestrictVendor: queryParams.searchRestrictVendor ? true : false,
    searchRestrictAcquiror: queryParams.searchRestrictAcquiror ? true : false,
    searchTargetType: queryParams.searchTargetType || undefined,
    searchVendorType: queryParams.searchVendorType || undefined,
    searchAcquirorType: queryParams.searchAcquirorType || undefined,
    searchTargetFPIndustry: queryParams.searchTargetFPIndustry || undefined,
    searchVendorFPIndustry: queryParams.searchVendorFPIndustry || undefined,
    searchAcquirorFPIndustry: queryParams.searchAcquirorFPIndustry || undefined,
    searchTargetNAICS: queryParams.searchTargetNAICS || undefined,
    searchVendorNAICS: queryParams.searchVendorNAICS || undefined,
    searchAcquirorNAICS: queryParams.searchAcquirorNAICS || undefined,
    searchTargetSIC: queryParams.searchTargetSIC || undefined,
    searchVendorSIC: queryParams.searchVendorSIC || undefined,
    searchAcquirorSIC: queryParams.searchAcquirorSIC || undefined,
    searchTargetGICS: queryParams.searchTargetGICS || undefined,
    searchVendorGICS: queryParams.searchVendorGICS || undefined,
    searchAcquirorGICS: queryParams.searchAcquirorGICS || undefined,
    searchLocationTarget: queryParams.searchLocationTarget ?
      queryParams.searchLocationTarget.split(',') : undefined,
    searchLocationVendor: queryParams.searchLocationVendor ?
      queryParams.searchLocationVendor.split(',') : undefined,
    searchLocationAcquiror: queryParams.searchLocationAcquiror ?
      queryParams.searchLocationAcquiror.split(',') : undefined,
    searchFinancialAdvisor: queryParams.searchFinancialAdvisor || undefined,
    searchLegalAdvisor: queryParams.searchLegalAdvisor || undefined,
    searchPubliclyTraded: queryParams.searchPubliclyTraded ? true : false,
    searchDivestiture: queryParams.searchDivestiture ? true : false,
    searchPrivatelyOwned: queryParams.searchPrivatelyOwned ? true : false,
    searchForeignTarget: queryParams.searchForeignTarget ? true : false,
    searchDealValueGt: queryParams.searchDealValueGt || undefined,
    searchDealValueLt: queryParams.searchDealValueLt || undefined,
    searchPercentageBoughtGt: queryParams.searchPercentageBoughtGt || undefined,
    searchPercentageBoughtLt: queryParams.searchPercentageBoughtLt || undefined,
    searchPaymentCash: queryParams.searchPaymentCash ? true : false,
    searchPaymentStock: queryParams.searchPaymentStock ? true : false,
    searchPaymentAssumptionDebt: queryParams.searchPaymentAssumptionDebt ? true : false,
    searchPaymentOther: queryParams.searchPaymentOther ? true : false,
    keywords: queryParams.keywords || undefined,
    searchType: queryParams.keywords || 'E',
    startDate: queryParams.startDate || undefined,
    endDate: queryParams.endDate || undefined,
    searchDealDescription: queryParams.searchDealDescription ? true : false,
    searchTargetBusinessDescription: queryParams.searchTargetBusinessDescription ? true : false,
    searchVendorBusinessDescription: queryParams.searchVendorBusinessDescription ? true : false,
    searchAcquirorBusinessDescription: queryParams.searchAcquirorBusinessDescription ? true : false,
    searchDatesAnnounced: queryParams.searchDatesAnnounced ? true : false,
    searchDatesUpdated: queryParams.searchDatesUpdated ? true : false,
    searchDealStatusCompleted:
      queryParams.searchDealStatusCompleted ? true : false,
    searchDealStatusPending: queryParams.searchDealStatusPending ? true : false,
    dateInfo: queryParams.dateInfo || undefined,
    searchDealStatusTerminated:
      queryParams.searchDealStatusTerminated ? true : false,
    sort: sortOption,
    page,
    numberOfRows
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const loading = searchResults.isFetching && !searchResults.latestData;

  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;

  const numberFormatter = new Intl.NumberFormat('en-CA');

  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const currentPage = page;
    const replaceUrl = '/mergers-and-acquisitions/results';
    const qs = {
      ...queryParams,
      page: newPage
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object)
    });
    setPage(newPage);
  };
  const switchCase = (param: any, value: any) => {
    switch (param) {
      case 'companyName':
        return queryParams.companyName;
      case 'searchRestrictTarget': {
        return queryParams.searchRestrictTarget;
      }
      case 'searchRestrictVendor': {
        return queryParams.searchRestrictVendor;
      }
      case 'searchRestrictAcquiror': {
        return queryParams.searchRestrictAcquiror;
      }
      case 'searchTargetType': {
        return targetTypeLookup.find(
          (p) => p.id === queryParams.searchTargetType
        )?.name;
      }
      case 'searchTargetFPIndustry': {
        return fpIndustryLookup.find(
          (p) => p.id === queryParams.searchTargetFPIndustry
        )?.name;
      }
      case 'searchTargetNAICS': {
        return naicsLookup.find((p) => p.id === queryParams.searchTargetNAICS)
          ?.name;
      }
      case 'searchTargetSIC': {
        return sicLookup.find((p) => p.id === queryParams.searchTargetSIC)
          ?.name;
      }
      case 'searchTargetGICS': {
        return gicsLookup.find((p) => p.id === queryParams.searchTargetGICS)
          ?.name;
      }
      case 'searchFinancialAdvisor': {
        return advisorLookup.find(
          (p) => p.id === queryParams.searchFinancialAdvisor
        )?.name;
      }
      case 'searchLegalAdvisor': {
        return legalAdvisorLookup.find(
          (p) => p.id === queryParams.searchLegalAdvisor
        )?.name;
      }
      // case 'searchLegalAdvisor': {
      //   return queryParams.searchLegalAdvisor;
      // }
      case 'searchDealValueGt': {
        return queryParams.searchDealValueGt;
      }
      case 'searchDealValueLt': {
        return queryParams.searchDealValueLt;
      }
      case 'startDate': {
        return queryParams.startDate;
      }
      case 'endDate': {
        return queryParams.endDate;
      }
      case 'searchDatesAnnounced': {
        return queryParams.searchDatesAnnounced;
      }
      case 'searchDatesUpdated': {
        return queryParams.searchDatesUpdated;
      }
      case 'searchDealStatusCompleted': {
        return queryParams.searchDealStatusCompleted;
      }
      case 'searchDealStatusPending': {
        return queryParams.searchDealStatusPending;
      }
      case 'searchDealStatusTerminated': {
        return queryParams.searchDealStatusTerminated;
      }
      case 'dateInfo': {
        return queryParams.dateInfo;
      }
      default:
        return [];
    }
  };
  const removePageVariable = queryParams;
  delete removePageVariable.page;
  const objectLength = Object.values(removePageVariable)?.filter((e) => e);
  const { value }: any = useSearchParams();

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/mergers-and-acquisitions/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: '/mergers-and-acquisitions/csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Financial Post Mergers &amp; Acquisitions in Canada - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/mergers-and-acquisitions/search"
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />

          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={7}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            sortOptions={sortOptions}
            sortOption={sortOption}
            setSortOption={setSortOption}
            history={props.history.location.state}
            switchCase={switchCase}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Target</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Vendor</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Acquiror</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="right">
                  <Text>
                    Deal Value
                    <br />
                    (Cdn$)
                  </Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Announced</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Updated</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>
                    Deal
                    <br />
                    Status
                  </Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/mergers-and-acquisitions/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.targets?.map((t) => t.name).join(', ')}</Text>
                    </Link>
                  </Table.Cell>

                  <Table.Cell>
                    <Text>{r.vendors?.map((v) => v.name).join(', ')}</Text>
                  </Table.Cell>

                  <Table.Cell>
                    <Text>{r.acquirors?.map((a) => a.name).join(', ')}</Text>
                  </Table.Cell>

                  <Table.Cell align="right">
                    <Text>
                      {r.cdn_value && r.cdn_value !== '0'
                        ? numberFormatter.format(parseInt(r.cdn_value))
                        : ''}
                    </Text>
                  </Table.Cell>

                  <Table.Cell align="center">
                    <Text>{r.AnnouncementDate}</Text>
                  </Table.Cell>

                  <Table.Cell align="center">
                    <Text>{r.Updated}</Text>
                  </Table.Cell>

                  <Table.Cell align="center">
                    <span
                      style={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <Text>{r.deal_status_description?.toUpperCase()}</Text>
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
