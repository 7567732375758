import * as React from 'react';
import {
  OnChangeProps,
  Range,
  RangeFocus,
  RangeWithKey,
  DateRangePicker as DateRange
} from 'react-date-range';
import { Text } from '../Text';
import { useOnClickAway } from '../../hooks';
import styled from 'styled-components';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { borderRadius } from 'polished';

interface DateRangeTypes {
  range1: Range;
  onChange: any;
  selection: RangeWithKey;
}
const CalendarWrapper: any = styled.div<{
  showPicker: boolean;
  isStartActive: boolean | null;
}>(({ theme, showPicker, isStartActive }) => ({
  display: 'inline-block',
  // [".rdrDateRangeWrapper"]: {
  // boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.2)'
  // },
  '.rdrDateDisplay': {
    margin: '0.2em 0 0.833em 0'
  },
  '.rdrDateDisplayWrapper': {
    width: '335px',
    backgroundColor: '#fff'
  },
  '.rdrDateDisplayItemActive': {
    borderColor: showPicker
      ? `${theme.palette.primary.main} !important}`
      : 'white'
  },
  '.rdrDateDisplayItem': {
    borderColor: theme.palette.border.main,
    textAlign: 'left',
    paddingLeft: '5px',
    boxShadow: 'none'
  },
  '.rdrDateDisplayItem + .rdrDateDisplayItem': {
    marginLeft: '0.833em'
  },
  '.rdrDateDisplayItem input': {
    color: '#333333'
  },
  '.rdrMonthAndYearWrapper': {
    display: showPicker ? 'flex' : 'none',
    marginTop: 'unset',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    boxShadow:
      '0 10px 0 rgba(255, 255, 255, 1), 4px 1px 4px rgba(0, 0, 0, 0.08), -4px 1px 4px rgba(0, 0, 0, 0.08)'
  },
  '.rdrMonths': {
    display: showPicker ? 'flex' : 'none',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    marginTop: '-4px',
    boxShadow:
      '5px 6px 4px rgba(0, 0, 0, 0.08), -4px 6px 4px rgba(0, 0, 0, 0.08)'
  },
  '.rdrMonthAndYearPickers select': {
    width: 'auto'
  },
  '.rdrStartEdge': {
    backgroundColor: theme.palette.primary.main
  },
  '.rdrEndEdge': {
    backgroundColor: theme.palette.primary.main
  },
  '.rdrInRange': {
    backgroundColor: '#E6F0FF'
  },
  '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
    color: 'black'
  },
  '.rdrDefinedRangesWrapper': {
    display: 'none'
  }
}));

const LabelContainer: any = styled.div((props) => ({
  width: '320px',
  display: 'flex',
  //  padding: '10px 0',
  backgroundColor: '#fff',
  '.dateLabel': {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  '.dateLabel span': {
    fontWeight: 500
  },
  '.ml-20': {
    marginLeft: '20px'
  }
}));

function isStartDateFocused (focusNumber: number) {
  return focusNumber === 0;
}

function isEndDateFocused (focusNumber: number) {
  return focusNumber === 1;
}

export function DateRangePicker (props: {
  retainEndDateOnFirstSelection?: boolean;
  moveRangeOnFirstSelection?: boolean;
  disabled?: boolean|false;
  minDate?: Date|undefined;
  maxDate?: Date|undefined;
  startDate: Date|undefined;
  endDate: Date|undefined;
  setStartDate: any;
  setEndDate: any;
}) {
  const { minDate, maxDate, startDate, endDate, setStartDate, setEndDate, disabled } = props;
  const [outSideElement, setOutSideElement] =
    React.useState<HTMLElement | null>(null);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  const [isStartActive, setIsStartActive] = React.useState<boolean | null>(
    null
  );
  let endSelected = false;
  const [focusedRange, setFocusedRange] = React.useState<RangeFocus>([0, 0]);
  const [selectionRange, setSelectionRange] = React.useState<any>([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);
  const retainEndDateOnFirstSelection = props.retainEndDateOnFirstSelection;
  const moveRangeOnFirstSelection = props.moveRangeOnFirstSelection;

  React.useEffect(() => {
    document
      .querySelectorAll('.rdrPprevButton').forEach(element => {
        element.setAttribute('aria-label', 'Previous Button');
      });
    document
      .querySelectorAll('.rdrNextButton').forEach(element => {
        element.setAttribute('aria-label', 'Next Button');
      });
    let index = 0;
    const inputRanges = document.getElementsByClassName('rdrDateInput');
    document.querySelectorAll('.rdrDateInput')
      .forEach(dateInput => {
        if (props.disabled) {
          dateInput.firstElementChild?.setAttribute('disabled', 'disabled');
        } else {
          dateInput.firstElementChild?.removeAttribute('disabled');
        }
        if (index % 2 === 0) {
          dateInput?.firstElementChild?.setAttribute('aria-label', 'Start Date');
          dateInput?.firstElementChild?.setAttribute('placeholder', 'Pick Start Date');
        } else {
          dateInput?.firstElementChild?.setAttribute('aria-label', 'End Date');
          dateInput?.firstElementChild?.setAttribute('placeholder', 'Pick End Date');
        }
        index++;
      });
  }, [props.disabled]);

  React.useEffect(() => {
    setSelectionRange([
      {
        startDate,
        endDate,
        key: 'selection'
      }
    ]);
  }, [startDate, endDate]);

  // setting max and min year for dropdown
  const minDateDefault = new Date(1994, 0, 1);
  const maxDateDefault = new Date(new Date().getFullYear(), 11, 31);

  const clearDate = [
    {
      endDate: new Date(new Date().getFullYear(), 11, 31),
      startDate: new Date(new Date().getFullYear() - 1, 0, 1),
      key: 'selection'
    }
  ];
  const handleSelect = (range: OnChangeProps) => {
    const updatedTypeRange = range as DateRangeTypes;
    const startDate: any = updatedTypeRange?.selection?.startDate || new Date();
    const endDate: any = updatedTypeRange?.selection?.endDate || new Date();
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectionRange([updatedTypeRange.selection]);
    if (isEndDateFocused(focusedRange[1])) {
      setShowPicker(false);
      setIsStartActive(true);
      endSelected = true;
    }
  };

  const onRangeFocusChange = (range: RangeFocus) => {
    // for start date
    if (isStartDateFocused(range[1])) {
      if (endSelected) {
        setShowPicker(false);
        setIsStartActive(null);
      } else {
        setShowPicker(true);
        setIsStartActive(true);
      }
    }
    // for end date
    if (isEndDateFocused(range[1])) {
      setShowPicker(true);
      setIsStartActive(false);
    }
    setFocusedRange(range);
  };

  // CLose the drop-down if user clicks outside datepicker
  useOnClickAway(outSideElement, () => {
    endSelected = false;
    setShowPicker(false);
  });

  return (
    <>
      <LabelContainer className="dateLabelContainer">
        <div className={'dateLabel'}>
          <Text>From</Text>
        </div>
        <div className={'dateLabel ml-20'}>
          <Text>To</Text>
        </div>
      </LabelContainer>
      <CalendarWrapper
        showPicker={showPicker}
        isStartActive={isStartActive}
        ref={setOutSideElement}
      >
        <DateRange
          staticRanges={[]}
          inputRanges={[]}
          retainEndDateOnFirstSelection={retainEndDateOnFirstSelection}
          autoFocus={false}
          moveRangeOnFirstSelection={moveRangeOnFirstSelection}
          ranges={selectionRange}
          onChange={handleSelect}
          minDate={minDate || minDateDefault}
          maxDate={maxDate || maxDateDefault}
          focusedRange={focusedRange}
          onRangeFocusChange={onRangeFocusChange}
        />
      </CalendarWrapper>
    </>
  );
}
