import * as React from 'react';
import { useRouteMatch } from 'react-router';

import Modal from 'react-modal';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { useIsLoggedIn } from '../AuthProvider';

const BetaModalContext = React.createContext(false);
const BetaModalContextSetContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => false);

const BetaModalDidAcceptContext = React.createContext<boolean | undefined>(
  undefined
);
const BetaModalDidAcceptSetContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean | undefined>>
>(() => undefined);

export function useBetaModalOpen () {
  return React.useContext(BetaModalContext);
}

export function useSetBetaModalOpen () {
  return React.useContext(BetaModalContextSetContext);
}

export function useBetaModalDidAccept () {
  return React.useContext(BetaModalDidAcceptContext);
}

export function useSetBetaModalDidAccept () {
  return React.useContext(BetaModalDidAcceptSetContext);
}

export function BetaModalProvider (props: { children: React.ReactNode }) {
  const [didAccept, setDidAccept] = React.useState<boolean | undefined>(
    undefined
  );
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <BetaModalDidAcceptContext.Provider value={didAccept}>
      <BetaModalDidAcceptSetContext.Provider value={setDidAccept}>
        <BetaModalContext.Provider value={isOpen}>
          <BetaModalContextSetContext.Provider value={setIsOpen}>
            {props.children}
          </BetaModalContextSetContext.Provider>
        </BetaModalContext.Provider>
      </BetaModalDidAcceptSetContext.Provider>
    </BetaModalDidAcceptContext.Provider>
  );
}

Modal.setAppElement('#root');

export function BetaModal () {
  const modalIsOpen = React.useContext(BetaModalContext);
  const setModalIsOpen = React.useContext(BetaModalContextSetContext);

  const modalDidAccept = React.useContext(BetaModalDidAcceptContext);
  const setModalDidAccept = React.useContext(BetaModalDidAcceptSetContext);

  const isRootPage = useRouteMatch('/')?.isExact;
  const loggedIn = useIsLoggedIn();

  // if ( isRootPage && loggedIn ) {
  // 	setModalIsOpen(true);
  // }

  function openModal () {
    setModalIsOpen(true);
  }

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal () {
    setModalIsOpen(false);
  }

  function setDisagree () {
    setModalDidAccept(false);
    setModalIsOpen(false);
  }

  function setAgree () {
    setModalDidAccept(true);
    setModalIsOpen(false);
  }

  // Modal.setAppElement('#root');

  // setTimeout(function() {
  // 	setIsOpen(true);
  // }, 5000);

  const customStyles = {
    content: {
      maxWidth: '500px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      maxHeight: '100%',
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Terms of Service"
    >
      <Heading alignX="center" size="xl" level="1" mt="lg" mb="lg">
        Terms of Service
      </Heading>

      <Text lineHeight="md" mt="md" mb="md">
        Please note FP Advisor is still undergoing rollout of remaining
        functionalities from the legacy platform which remains accessible via
        a click-through option at the bottom of the left menu pane. The platform,
        its software and all content found on it are provided on an “as is” and
        “as available” basis. FP Advisor and Postmedia Network Inc. does not give
        any warranties, whether express or implied, as to the suitability or
        usability of the website, its software or any of its content.
      </Text>
      <Text lineHeight="md" mt="md" mb="md">
        FP Advisor and Postmedia will not be liable for any loss, whether such
        loss is direct, indirect, special or consequential, suffered by any
        party as a result of their use of the FP Advisor platform, its software
        or content. Any downloading or uploading of material to the website is
        done at the user’s own risk and the user will be solely responsible for
        any damage to any computer system or loss of data that results from such
        activities.
      </Text>
      <Text lineHeight="md" mt="md" mb="md">
        Should you encounter any bugs, glitches, lack of functionality or other
        problems on the website, please let us know immediately so we can
        rectify these accordingly. Your help in this regard is greatly
        appreciated! You can write to us at this address{' '}
        <a href="mailto:fpadvisor@postmedia.com">fpadvisor@postmedia.com</a>.
      </Text>

      <div style={{ textAlign: 'center' }}>
        <Button
          label="Disagree"
          design="secondary"
          mr="lg"
          mt="xl"
          mb="xl"
          size="md"
          onClick={() => {
            setDisagree();
          }}
        ></Button>
        <Button
          label="Agree"
          ml="lg"
          mt="xl"
          mb="xl"
          size="md"
          onClick={() => {
            setAgree();
          }}
        ></Button>
      </div>
    </Modal>
  );
}
