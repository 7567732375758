import * as React from 'react';
import { useId } from '../../hooks';
import { useTheme } from 'styled-components/macro';
import { ScreenReaderOnly } from '../ScreenReaderOnly';

export function SelectField (props: {
	title: string;
	placeholder?: string;
	options: Array<{ id: string, name: string }>;
	value?: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
  hideLabel?: boolean;
}) {
	const id = useId();
	const theme = useTheme();

	const { title, placeholder, options, value, setValue } = props
  return (
		<div style={{ marginTop: theme.spacing.lg }}>
      { props.hideLabel && (
        <ScreenReaderOnly>
          <label htmlFor={id} style={{ display: 'block', marginBottom: theme.spacing.xs }}>
            { title }
          </label>
        </ScreenReaderOnly>
      )}

      { !props.hideLabel && (
        <label htmlFor={id} style={{ display: 'block', fontWeight: 500, marginBottom: theme.spacing.xxxs }}>
        { title }
        </label>
      )}

			<select
				id={id}
				value={value}
				onChange={(e) => { setValue(e.target.value) }}
			>
				{ options.map(o => (
					<option value={ o.id }>{ o.name }</option>
				)) }
			</select>
		</div>
  );
}
/*
SelectField.defaultProps = {
	placeholder: '--',
}
*/
