/* eslint-disable */
import * as React from "react";
import { useHistory } from "react-router";
import { Page } from "../../../components/Page";
import { Heading } from "../../../components/Heading";
import { useId } from "../../../hooks";
import { TextField } from "../../../components/TextField";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text";
import { Row } from "../../../components/Row";
import { searchDBs } from "../../../data/searchDatabases";
import "./index.scss";
import moment from "moment";

export function PersonSearchPage() {
  const history = useHistory();
  const headingId = useId();

  const [personName, setPersonName] = React.useState("");
  const [searchDbs, setSearchDbs] = React.useState(
    searchDBs.map((db) => {
      return { code: db.code, name: db.name };
    })
  );      

  const personSearchSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL("/person-search/results", baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    resultsUrl.searchParams.set("name", personName);
    const listDbsChecked = Array.from(document.querySelectorAll<HTMLInputElement>(".personSearchCriteria")).
                                    filter(element => element.checked).map(element => element.value);
    if (listDbsChecked.length) {
      resultsUrl.searchParams.set('dbs', listDbsChecked.join(','))
    }

    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
        Person Search
      </Heading>

      <Text mt="sm" lineHeight="md">
        Person Search searches contact information in multiple corporate data
        sources at one time.
      </Text>

      <form aria-labelledby={headingId}
        onSubmit={(e) => {
          e.preventDefault();
          personSearchSubmitData();
        }}
      >
        {/* <Tiles columns={[1,2]}> */}
        <Row className="person--search--row">
          <div style={{ paddingRight: "24px" }}>
            <TextField
              mt="xl"
              label="Person Name"
              value={personName}
              onChange={setPersonName}
            />
            <Text color="light" mb="lg" mt="sm">
              A search for 'John Smith' will also retrieve
              <br />
              'John F. Smith' or 'John Frederick Smith'.
            </Text>
          </div>

          <div style={{ minWidth: "200px" }}>
            <Heading level="2" size="md" mt="md">
              Data Sources to Search
            </Heading>
            
            <input
              aria-label={'Select / Unselect All'}
              type="checkbox"
              className="person--search--selectall"
              defaultChecked={true}
              onChange={(e) => {
                e.target.checked 
                  ? Array.from(document.querySelectorAll<HTMLInputElement>(".personSearchCriteria")).
                                    map((element) => {if (element.checked == false) {
                                                        element.checked=true;
                                                      }
                                                    })
                  : Array.from(document.querySelectorAll<HTMLInputElement>(".personSearchCriteria")).
                                    map((element) => {if (element.checked == true) {
                                      element.checked=false;
                                    }
                                  })
              }}
            ></input>
            <label> SELECT / UNSELECT ALL</label>
            <ul className="data-source-list" style={{ listStyle: "none" }}>
              {searchDBs.map((db) => (
                <li>
                  <input
                    aria-label={db.name}
                    type="checkbox"
                    value={db.code}
                    defaultChecked={true}
                    name={db.name}
                    className={"personSearchCriteria"}
                    onChange={(e) => {
                      Array.from(document.querySelectorAll<HTMLInputElement>(".person--search--selectall")).
                                    map((element) => element.checked=false )
                  }}
                  ></input>
                  <label>{db.name}</label>
                </li>
              ))}
            </ul>
          </div>
        </Row>
        <Button mt="lg" label="Search" mb={"lg"} disabled={personName === ''} type="submit"/>
        {/* </Tiles> */}
      </form>
    </Page>
  );
}
