import * as React from 'react';
import { DefaultTheme, useTheme } from 'styled-components/macro';
import { MarginProps } from '../../utils/style';
import { Text } from '../Text';

export interface HeadingProps extends MarginProps {
  id?: string;
  level: '1' | '2' | '3' | '4' | '5' | '6';
  loading?: boolean;
  loadingWidth?: number;
  children: React.ReactNode;
  alignX?: 'center';
  color?: 'primary' | 'heading' | 'light';
  weight?: 'regular' | 'medium' | 'bold' | 'semi-bold';
  size?:
    | keyof DefaultTheme['typography']['textSizes']
    | Array<keyof DefaultTheme['typography']['textSizes']>;
    showArrowText?: '0' | '1';
}

export function Heading (props: HeadingProps) {
  const tags = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  } as const;

  const theme = useTheme();

  return (
    <Text
      id={props.id}
      as={tags[props.level]}
      weight={props.weight || 'semi-bold'}
      size={props.size || theme.typography.defaultHeaderSizes[props.level]}
      mt={props.mt}
      mb={props.mb}
      mr={props.mr}
      ml={props.ml}
      loading={props.loading}
      loadingWidth={props.loadingWidth}
      alignX={props.alignX}
      color={props.color || 'heading'}
    >
      {props.children}
    </Text>
  );
}
