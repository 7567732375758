import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function GenderIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={[
        'M14 10.5C14 8.57 12.43 7 10.5 7C8.57 7 7 8.57 7 10.5C7 11.8136 7.72731 12.9605 8.80041 13.559C8.30992 14.088 7.69383 14.4988 6.99956 14.7441C5.77797 13.7357 5 12.2096 5 10.5C5 7.46 7.46 5 10.5 5C11.66 5 12.73 5.36 13.61 5.97L17.58 2H15V0H21V6H19V3.42L15.03 7.38C15.64 8.27 16 9.34 16 10.5C16 13.54 13.54 16 10.5 16C10.0517 16 9.61605 15.9465 9.19901 15.8456C9.91868 15.3466 10.5327 14.7061 11.0009 13.9643C12.6944 13.7206 14 12.26 14 10.5Z',
        'M5 15.9V15.4834L4.59029 15.4082C2.26215 14.9808 0.5 12.9531 0.5 10.5C0.5 7.73614 2.73614 5.5 5.5 5.5C8.26386 5.5 10.5 7.73614 10.5 10.5C10.5 12.9531 8.73785 14.9808 6.40971 15.4082L6 15.4834V15.9V18V18.5H6.5H8V19.5H6.5H6V20V21.5H5V20V19.5H4.5H3V18.5H4.5H5V18V15.9ZM5.5 6.5C3.29386 6.5 1.5 8.29386 1.5 10.5C1.5 12.7061 3.29386 14.5 5.5 14.5C7.70614 14.5 9.5 12.7061 9.5 10.5C9.5 8.29386 7.70614 6.5 5.5 6.5Z',
      ]}
    />
  );
}
