import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Tabs, Tab } from '../../../components/Tabs';
import ClearButton from 'components/ClearButton';

export function CorporateAnalyzerSearchPage () {
  const history = useHistory();
  const headingId = useId();
  const [companyName, setCompanyName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  /* companyName */
  const company = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (company !== companyName) {
      localStorage.setItem('companyNameCa', JSON.stringify(companyName));
    }
  }, [companyName]);

  /* stockSymbol */
  const stock = usePreviousStorageData(stockSymbol) || '';
  React.useEffect(() => {
    if (stock !== stockSymbol) {
      localStorage.setItem('stockSymbolCa', JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  const loadLatestEntries = () => {
    const companyName = localStorage.getItem('companyNameCa');
    if (companyName) {
      setCompanyName(JSON.parse(companyName));
    }
    const stockSymbol = localStorage.getItem('stockSymbolCa');
    if (stockSymbol) {
      setStockSymbol(JSON.parse(stockSymbol));
    }
  }

  const clearCheck = () => {
    setCompanyName('');
    setStockSymbol('');
  }

  const analyzerSubmitData = () => {
    history.push(
      `/corporate-analyzer/results?companyName=${companyName}&stockSymbol=${stockSymbol}`
    );
  }

  return (
    <Page aria-label="Financial Post Corporate Analyzer">
      <Tabs aria-label="Search Options">
        <Tab label="Search">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Corporate Analyzer - Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Provides quick access to share price statistics, company briefs, capital
            stock, current operations, M&amp;A activities, debt, 10 years of
            financial statement items, ratios, market data and I/B/E/S estimates for
            the top 1,500 publicly traded Canadian companies.
          </Text>

          <Text mt="md" mb="lg" lineHeight="md">
            You can also browse the list of available companies.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              analyzerSubmitData();
            }}
          >
            <TextField
              mt="lg"
              label="Company Name"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />

            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>
      </Tabs>
    </Page>
  );
}
