import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, usePredecessorDefunctInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import ExpandAll from 'components/ExpandSettings';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';

import { CompanyInformation } from '../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';

export function PredecessorDefunctDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data: survey, isLoading } = usePredecessorDefunctInfo(key);
    const { data: company, isLoading: isLoadingCompany } = useCompany(
      key?.replace('fppd', 'fpsu')
    );
    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, headingId: headingId });
  });

  const authContext = useAuthContext();
  const defaultExpand = authContext?.expand_collapse_settings['predecessor-and-defunct'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  return (
    <Page aria-label="Financial Post Predecessor &amp; Defunct" padding="none" width="full">
      { companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0].comp}
        hidePredecessor={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Financial Post Predecessor &amp; Defunct
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.comp?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>
        <Row>
          <div className="detail--page--card">
            {i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname='predecessor-and-defunct' />
              </div>
            </HideOnPrint>
            }
            <Card mb="lg">
              <CompanyInformation isLoading={element.isLoading} company={element.comp} />
            </Card>

            {element.survey?.htmlInfo && element.survey?.htmlInfo?.body.innerHTML.length > 0 && (
              <Card mb="lg">
                <Accordion openOnLoad={expand}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Predecessor &amp; Defunct Information
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <Tiles columns={[1, 1]}>
                      <div
                        className="predecessor-defunct-info"
                        dangerouslySetInnerHTML={{
                          __html: element.survey?.htmlInfo?.body.innerHTML || '',
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hidePredecessor={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
