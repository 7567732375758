import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

export function useSearchParams () {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [value, setValue] = React.useState({});
  const val = {};
  useEffect(() => {
    Object.keys(parsed)
      .map((item) => {
        if (parsed[item]) {
          return Object.assign(val, { [item]: parsed[item] });
        }
      })
    setValue(val);
  }, []);
  return { value };
}
