import * as React from 'react';
import { HideOnMobile } from '../../components/HideOnMobile';
import { PrintIcon } from '../../icons/PrintIcon';
import './index.scss';

export function PrintFriendly () {
  return (
    <button className="button-print" onClick={() => window.print()}>
      <span className='button-icon'>
        <PrintIcon size="sm" />
      </span>
      <span className='button-text'>&nbsp;Print</span>
    </button>
  );
};
