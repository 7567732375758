import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, useCorporateAnalyzerInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import ExpandAll from 'components/ExpandSettings';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';

import { CompanyInformation } from '../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';
import { StatisticsTable } from 'components/StatisticsTable/StatisticTable';
import { dateFormatter, renderPriceOnDate, roundToTwo } from '../../../helpers';
import styled from 'styled-components';

const StatsRenderer = styled.div`
  table {
    margin-top: 2rem;
    width: 100%;
    .cellbold {
      font-size: 25px;
      text-align: left;
    }
    tr {
      &.evenrow {
        background: #cacaca;
        font-size: bold;
      }
    }
  }
`;

export function CorporateAnalyzerDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data: company } = useCompany(
      key.replace('fpan', 'fpsn')
    );
    const { data: survey, isLoading } = useCorporateAnalyzerInfo(key);
    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, headingId: headingId });
  });

  const authContext = useAuthContext();
	const defaultExpand = authContext?.expand_collapse_settings['corporate-analyzer'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  const [disable, setDisable] = React.useState<boolean>(false);

  const renderPriceRatios = (price?: number) =>
    price ? Number(price).toLocaleString('en-US') : 'n.a.';

  return (
    <Page aria-label="Corporate Analyzer" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0]}
        hideCorpAnalyzer={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Corporate Analyzer<ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.comp?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>
        <Row>
          <div className="detail--page--card">
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname={'corporate-analyzer'}/>
              </div>
            </HideOnPrint>

            <Card mb="lg">
              <CompanyInformation
                isLoading={element.isLoading}
                company={element.comp}
                hideCorporateDescription={true}
              />
            </Card>

            {element.survey?.htmlSharePriceStats &&
              element.survey?.htmlSharePriceStats?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Share Price Statistics
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      {/* <StatisticsTable
												title={(<><strong>Stock Symbols:</strong> {stockSymbols}</>)}
												columns={[
													(<>52 Week <br />High</>),
													(<>52 Week <br />Low</>),
													(<>Latest <br />Price</>),
													(<>Latest <br />Volume</>),
													(<>Avg. Daily Volume <br />over last 52 Weeks</>),
													(<></>),
													(<></>),
													(<></>),
												]}
												rows={[[
													(<>{company?.securityexchangelead?.securityexchange?.map(
														(exchange) => (
															<span>
																{exchange.exchange}/{exchange.symbol}
															</span>
														)
													)}</>),
													company?.dailysnap?.closings?.closing || 0,
													(<>{company?.dailysnap?.closings?.high52wk || 0}{' '}<br />{dateFormatter(String(company?.dailysnap?.closings?.high52wkdate))}</>),
													(<>{company?.dailysnap?.closings?.low52wk || 0}{' '}<br />{dateFormatter(String(company?.dailysnap?.closings?.low52wkdate))}</>),
													company?.dailysnap?.dividend?.iad || 0,
													company?.dailysnap?.dividend?.latestdividend || 0,
													(company?.dailysnap?.priceratio?.dividendyield || 0) / 100.0,
													company?.dailysnap?.beta?.beta60mo || 0
												]]}
											/>

											<StatisticsTable
												columns={[
													(<>Avg 20-day<br />Volume</>),
													(<>P/E</>),
													(<>12-mo <br />EPS</>),
													(<>EPS Date</>),
													(<>1-yr <br />Return</>),
													(<>3-yr<br />Return</>),
													(<>5-yr<br />Return</>),
													(<>Market<br />Cap (000s)</>),
												]}
												rows={[[
													company?.dailysnap?.volumes?.volume4wkavg || 0,
													company?.dailysnap?.priceratio?.peratio || 0,
													company?.dailysnap?.eps?.interim || 0,
													dateFormatter(String(company?.dailysnap?.eps?.epsdate)) || '',
													(company?.dailysnap?.return?.return1yr || 0) / 100.0,
													(company?.dailysnap?.return?.return3yr || 0) / 100.0,
													(company?.dailysnap?.return?.return5yr || 0) / 100.0,
													`$${company?.dailysnap?.marketcap || 0}`
												]]}
											/>

											<br /> */}

                      <StatisticsTable
                        title="Share Prices and Volumes"
                        columns={[
                          <>
                            52 Week <br />
                            High
                          </>,
                          <>
                            52 Week <br />
                            Low
                          </>,
                          <>
                            Latest <br />
                            Price
                          </>,
                          <>
                            Latest <br />
                            Volume
                          </>,
                          <>
                            Avg. Daily Volume <br />
                            over last 52 Weeks
                          </>
                        ]}
                        rows={[
                          [
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.closings?.high52wk,
                              date: element.comp?.dailysnap?.closings?.high52wkdate,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.closings?.low52wk,
                              date: element.comp?.dailysnap?.closings?.low52wkdate,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.closings?.closing,
                              date: element.comp?.dailysnap?.closings?.closingdate,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.volumes?.volume,
                              date: element.comp?.dailysnap?.volumes?.volumedate,
                              qualifierParam: 'currency'
                            }),
                            Number(
                              element.comp?.dailysnap?.volumes?.volume52wkavg
                            ).toLocaleString('en-US')
                          ]
                        ]}
                      />

                      <br />
                      <StatisticsTable
                        title="Share Price Ratios"
                        columns={[
                          <>
                            Price/ <br />
                            Earnings
                          </>,
                          <>
                            Price/ <br />
                            Cash Flow
                          </>,
                          <>
                            Price/ <br />
                            Sales
                          </>,
                          <>
                            Earnings <br />
                            Yield
                          </>,
                          <>
                            Dividend <br />
                            Yield
                          </>,
                          <>
                            Price/ <br />
                            Book Value
                          </>
                        ]}
                        rows={[
                          [
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.peratio
                            ),
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.percashflow
                            ),
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.persales
                            ),
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.epsyield
                            ),
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.dividendyield
                            ),
                            renderPriceRatios(
                              element.comp?.dailysnap?.priceratio?.perbookvalue
                            )
                          ]
                        ]}
                      />

                      <br />
                      <StatisticsTable
                        title="Per Share Data"
                        columns={[
                          <>
                            Latest Int.
                            <br /> EPS
                          </>,
                          <>
                            Year-ago
                            <br />
                            Int. EPS
                          </>,
                          <>
                            Earnings
                            <br />
                            Momentum
                          </>,
                          <>
                            12 Months
                            <br /> EPS
                          </>,
                          <>
                            Latest <br />
                            Dividend Paid
                          </>,
                          <>
                            Indicated <br />
                            Dividend Rate
                          </>,
                          <>
                            Book Value/
                            <br />
                            Share
                          </>
                        ]}
                        rows={[
                          [
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.eps?.interim,
                              date: element.comp?.dailysnap?.eps?.epsdate,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.eps?.yearago,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.eps?.momentum,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.eps?.eps12mo,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price:
                                element.comp?.dailysnap?.dividend?.latestdividend,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.dividend?.iad,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.bookvalue,
                              qualifierParam: 'currency'
                            })
                          ]
                        ]}
                      />

                      <br />
                      <StatisticsTable
                        title="Forward Ratios"
                        columns={[
                          <>
                            1-year Fwd
                            <br />
                            EPS
                          </>,
                          <>
                            1-year Fwd
                            <br />
                            P/E
                          </>,
                          <>
                            1-year Fwd
                            <br />
                            EPS Growth
                          </>,
                          <>
                            5-year Fwd
                            <br />
                            EPS Growth
                          </>,
                          <>
                            1-year Fwd
                            <br />
                            PEG Ratio
                          </>,
                          <>
                            5-year Fwd
                            <br />
                            PEG Ratio
                          </>
                        ]}
                        rows={[
                          [
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.forwardratio?.eps1yr,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price:
                                element.comp?.dailysnap?.forwardratio?.peratio1yr,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price:
                                element.comp?.dailysnap?.forwardratio?.epsgrowth1yr,
                              qualifierParam: 'percentage'
                            }),
                            renderPriceOnDate({
                              price: Number(
                                  element.comp?.dailysnap?.forwardratio?.epsgrowth5yr[
                                  '@attributes'
                                ]?.textvalue
                              ),
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price:
                                element.comp?.dailysnap?.forwardratio?.pegratio1yr,
                              qualifierParam: 'percentage'
                            }),
                            renderPriceOnDate({
                              price: Number(
                                element.comp?.dailysnap?.forwardratio?.pegratio5yr[
                                  '@attributes'
                                ]?.textvalue
                              ),
                              qualifierParam: 'currency'
                            })
                          ]
                        ]}
                      />

                      <br />
                      <StatisticsTable
                        title="Valuation & Multiples"
                        columns={[
                          <>
                            Market Capitalization <br />
                            (000s)
                          </>,
                          <>
                            Enterprise Value <br />
                            (000s)
                          </>,
                          <>
                            EV/
                            <br />
                            EBITDA
                          </>
                        ]}
                        rows={[
                          [
                            renderPriceOnDate({
                              price: element.comp?.dailysnap?.marketcap,
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: Number(
                                element.comp?.dailysnap?.enterprisevalue[
                                  '@attributes'
                                ]?.textvalue
                              ),
                              qualifierParam: 'currency'
                            }),
                            renderPriceOnDate({
                              price: Number(
                                element.comp?.dailysnap?.evebitda['@attributes']
                                  ?.textvalue
                              ),
                              qualifierParam: 'percentage'
                            })
                          ]
                        ]}
                      />

                      <br />
                      <StatisticsTable
                        title="Price Performance"
                        columns={[
                          '',
                          '1 Week',
                          '4 Weeks',
                          '13 Weeks',
                          '40 Weeks',
                          '52 Weeks',
                          '36 Months',
                          '60 Months'
                        ]}
                        // rows={[
                        // ]}
                        rows={
                          element.survey?.data?.priceperformance?.performance?.map(
                            (performance: { description: any; chg1wk: any; chg4wk: any; chg13wk: any; chg36mo: any; chg40wk: any; chg52wk: any; chg60mo: any; }) => {
                              return [
                                performance.description
                                  ? `${performance.description}%`
                                  : '',
                                performance?.chg1wk
                                  ? `${performance?.chg1wk}%`
                                  : '',
                                performance?.chg4wk
                                  ? `${performance?.chg4wk}%`
                                  : '',
                                performance?.chg13wk
                                  ? `${performance?.chg13wk}%`
                                  : '',
                                performance?.chg36mo
                                  ? `${performance?.chg36mo}%`
                                  : '',
                                performance?.chg40wk
                                  ? `${performance?.chg40wk}%`
                                  : '',
                                performance?.chg52wk
                                  ? `${performance?.chg52wk}%`
                                  : '',
                                performance?.chg60mo
                                  ? `${performance?.chg60mo}%`
                                  : ''
                              ];
                            }
                          ) || []
                        }
                      />

                      <br />
                      <StatisticsTable
                        title="Miscellaneous"
                        columns={[
                          <>
                            Latest Short <br />
                            Position
                          </>,
                          <>
                            Prior Short <br />
                            Position
                          </>,
                          <>
                            Dividend
                            <br />
                            Payout Ratio
                          </>,
                          <>
                            Effective Income
                            <br />
                            Tax Rate
                          </>
                        ]}
                        rows={[
                          [
                            ...(element.survey.data?.shortposition?.position
                              ? element.survey.data?.shortposition?.position.map(
                                  (pos: { shares: any; date: any; }) => {
                                    return renderPriceOnDate({
                                      price: Number(pos.shares),
                                      date: Number(pos.date)
                                    });
                                  }
                                )
                              : ['n.a', 'n.a']),
                            element.comp?.dailysnap?.dividendpayoutratio !== '0'
                              ? roundToTwo(
                                  Number(
                                    element.comp?.dailysnap?.dividendpayoutratio
                                  )
                                )
                              : 'n.a.',
                            element.comp?.dailysnap?.effectivetaxrate !== '0'
                              ? roundToTwo(
                                  Number(element.comp?.dailysnap?.effectivetaxrate)
                                )
                              : 'n.a.'
                          ]
                        ]}
                      />
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {element.survey?.rawHtmlThompsonReuters &&
              element.survey?.rawHtmlThompsonReuters.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      <span style={{ fontStyle: 'italic' }}>
                        Refinitiv I/B/E/S Estimates
                      </span>
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          style={{ maxWidth: '800px' }}
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.rawHtmlThompsonReuters || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlBusiness &&
              element.survey?.htmlBusiness?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Business
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlBusiness?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlCapitalStock &&
              element.survey?.htmlCapitalStock?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Capital Stock
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlCapitalStock?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlCurrentOps &&
              element.survey?.htmlCurrentOps?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Current Operations
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.htmlCurrentOps?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlLatestResults &&
              element.survey?.htmlLatestResults?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Latest Results
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlLatestResults?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.htmlLongTermDebt &&
              element.survey?.htmlLongTermDebt?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Long-Term Debt
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlLongTermDebt?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {/* htmlSegmented */}

            {element.survey?.htmlGeneralInfo &&
              element.survey?.htmlGeneralInfo?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      General Information
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              element.survey?.htmlGeneralInfo?.body.innerHTML || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}

            {element.survey?.rawHtmlFinancialInfo &&
              element.survey?.rawHtmlFinancialInfo.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
										Financial Information
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          style={{ maxWidth: '800px' }}
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.rawHtmlFinancialInfo || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
            {/* htmlAddtionalData */}

						{element.survey?.rawHtmlMarketData &&
              element.survey?.rawHtmlMarketData.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Market Data
                    </Accordion.Heading>
                    <Accordion.Content isOverflowScrollStyling>
                      <Tiles columns={[1, 1]}>
                        <div
                          style={{ maxWidth: '800px' }}
                          dangerouslySetInnerHTML={{
                            __html: element.survey?.rawHtmlMarketData || ''
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hideCorpAnalyzer={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
