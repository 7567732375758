import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function PersonIcon (props: { color?: 'subdued'|'primary'; size?: 'sm' | 'md' | 'lg' }) {
	const sizes = {
		sm: '20',
		md: '24',
		lg: '120',
	}

  const size = sizes[props.size || 'md'];
  const theme = useTheme();

  return (
    <svg aria-hidden role="img" viewBox={'0 0 24 24'} width={size} height={size}>
      <path d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" fill="white" />
      <path
        fill="rgb(120,120,120)"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z"
      />
    </svg>
  );
}
