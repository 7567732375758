/* eslint-disable */
import * as React from "react";
import { useParams, useHistory } from "react-router";
import { Page } from "../../../components/Page";
import { Heading } from "../../../components/Heading";
import { Row } from "../../../components/Row";
import { useId, usePreviousStorageData } from "../../../hooks";
import { TextField } from "../../../components/TextField";
import { Button } from "../../../components/Button";
import { RadioList } from "../../../components/RadioList";
import { Text } from "../../../components/Text";
import { MultiSelectField } from "../../../components/MultiSelectField";
import {
  locationTypesLookup,
  companyTypesLookup,
  provincesLookup,
  legalRecordTypeLookup,
  organizationTypesLookup,
  searchTypePredecessorLookup,
  legalTypeLookup,
  sortOptionLookup,
  salesVolumeGTLookup,
  salesVolumeLTLookup,
  locationTypeLookup,
  companyTypeLookup,
} from "../../../data/lookups";
import { externalDbBySlug } from "../../../data/searchDatabases";
import styled from "styled-components";
import moment from "moment";

export function ExternalDatabasesSearchPage() {
  const history = useHistory();
  const headingId = useId();

  const [companyName, setCompanyName] = React.useState("");
  const [legalType, setLegalType] = React.useState("both");
  const [searchType, setSearchType] = React.useState("exact-phrase");
  const [legalRecordType, setLegalRecordType] = React.useState<string[]>([]);
  const [locationType, setLocationType] = React.useState<string[]>([]);

  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [directorName, setDirectorName] = React.useState("");
  const [organizationType, setOrganizationType] = React.useState<string[]>([]);
  const [companyType, setCompanyType] = React.useState<string[]>([]);
  const [areaCode, setAreaCode] = React.useState("");
  const [sicCode, setSicCode] = React.useState("");
  const [naicsCode, setNaicsCode] = React.useState("");
  const [employeesGT, setEmployeesGT] = React.useState("");
  const [employeesLT, setEmployeesLT] = React.useState("");
  const [membersGT, setMembersGT] = React.useState("");
  const [membersLT, setMembersLT] = React.useState("");
  const [sortOption, setSortOption] = React.useState("d");
  const [salesVolumeGT, setSalesVolumeGT] = React.useState("");
  const [salesVolumeLT, setSalesVolumeLT] = React.useState("");

  const params = useParams<{ dbCode: string }>();

  const externalDb = externalDbBySlug(params.dbCode);
  const dbFullName = externalDb?.name || `External Database ${params.dbCode}`;

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);


  /*
  switch(externalDb?.slug) {
    case "equifax-commerical-law-record":
      break;
    case "candian-corporate-names":
      break;
    case "canadian-federal-coporations-and-directors":
      break;
    case "encyclopedia-of-associations":
      break;
    case "wards-business-directory":
      break;
  }
  */
  /* companyName */
  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        localStorage.setItem('companyNameCLR', JSON.stringify(companyName));
        break;
      case "candian-corporate-names":
        localStorage.setItem('companyNameCCN', JSON.stringify(companyName));
        break;
      case "canadian-federal-coporations-and-directors":
        localStorage.setItem('companyNameFCD', JSON.stringify(companyName));
        break;
      case "encyclopedia-of-associations":
        localStorage.setItem('companyNameEA', JSON.stringify(companyName));
        break;
      case "wards-business-directory":
        localStorage.setItem('companyNameWBD', JSON.stringify(companyName));
        break;
    }
  }, [companyName]);

  React.useEffect(() => {
    localStorage.setItem('legalTypeCLR', JSON.stringify(legalType));
  }, [legalType]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        localStorage.setItem('searchTypeCLR', JSON.stringify(searchType));
        break;
      case "candian-corporate-names":
        localStorage.setItem('searchTypeCCN', JSON.stringify(searchType));
        break;
      case "canadian-federal-coporations-and-directors":
        localStorage.setItem('searchTypeFCD', JSON.stringify(searchType));
        break;
      case "wards-business-directory":
        localStorage.setItem('searchTypeWBD', JSON.stringify(searchType));
        break;
    }
  }, [searchType]);

  React.useEffect(() => {
    localStorage.setItem('legalRecordTypeCLR', JSON.stringify(legalRecordType));
  }, [legalRecordType]);

  React.useEffect(() => {
    localStorage.setItem('locationTypeWBD', JSON.stringify(locationType));
  }, [locationType]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        localStorage.setItem('cityCLR', JSON.stringify(city));
        break;
      case "encyclopedia-of-associations":
        localStorage.setItem('cityEA', JSON.stringify(city));
        break;
      case "wards-business-directory":
        localStorage.setItem('cityWBD', JSON.stringify(city));
        break;
    }
  }, [city]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "canadian-federal-coporations-and-directors":
        localStorage.setItem('addressFCD', JSON.stringify(address));
        break;
      case "encyclopedia-of-associations":
        localStorage.setItem('addressEA', JSON.stringify(address));
        break;
      case "wards-business-directory":
        localStorage.setItem('addressWBD', JSON.stringify(address));
        break;
    }
  }, [address]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        localStorage.setItem('provincesCLR', JSON.stringify(provinces));
        break;
      case "canadian-federal-coporations-and-directors":
        localStorage.setItem('provincesFCD', JSON.stringify(provinces));
        break;
      case "encyclopedia-of-associations":
        localStorage.setItem('provincesEA', JSON.stringify(provinces));
        break;
      case "wards-business-directory":
        localStorage.setItem('provincesWBD', JSON.stringify(provinces));
        break;
    }
  }, [provinces]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "canadian-federal-coporations-and-directors":
        localStorage.setItem('directorNameFCD', JSON.stringify(directorName));
        break;
      case "encyclopedia-of-associations":
        localStorage.setItem('directorNameEA', JSON.stringify(directorName));
        break;
      case "wards-business-directory":
        localStorage.setItem('directorNameWBD', JSON.stringify(directorName));
        break;
    }
  }, [directorName]);

  React.useEffect(() => {
    localStorage.setItem('organizationTypeEA', JSON.stringify(organizationType));
  }, [organizationType]);

  React.useEffect(() => {
    localStorage.setItem('companyTypeWBD', JSON.stringify(companyType));
  }, [companyType]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "encyclopedia-of-associations":
        localStorage.setItem('areaCodeEA', JSON.stringify(areaCode));
        break;
      case "wards-business-directory":
        localStorage.setItem('areaCodeWBD', JSON.stringify(areaCode));
        break;
    }
  }, [areaCode]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "encyclopedia-of-associations":
        localStorage.setItem('sicCodeEA', JSON.stringify(sicCode));
        break;
      case "wards-business-directory":
        localStorage.setItem('sicCodeWBD', JSON.stringify(sicCode));
        break;
    }
  }, [sicCode]);

  React.useEffect(() => {
    localStorage.setItem('naicsCodeWBD', JSON.stringify(naicsCode));
  }, [naicsCode]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "encyclopedia-of-associations":
        localStorage.setItem('employeeGtEA', JSON.stringify(employeesGT));
        break;
      case "wards-business-directory":
        localStorage.setItem('employeeGtWBD', JSON.stringify(employeesGT));
        break;
    }
  }, [employeesGT]);

  React.useEffect(() => {
    switch(externalDb?.slug) {
      case "encyclopedia-of-associations":
        localStorage.setItem('employeeLtEA', JSON.stringify(employeesLT));
        break;
      case "wards-business-directory":
        localStorage.setItem('employeeLtWBD', JSON.stringify(employeesLT));
        break;
    }
  }, [employeesLT]);

  React.useEffect(() => {
    localStorage.setItem('memberGtEA', JSON.stringify(membersGT));
  }, [membersGT]);

  React.useEffect(() => {
    localStorage.setItem('memberLtEA', JSON.stringify(membersLT));
  }, [membersLT]);

  React.useEffect(() => {
    localStorage.setItem('sortOptionCLR', JSON.stringify(sortOption));
  }, [sortOption]);

  React.useEffect(() => {
    localStorage.setItem('salesVolGtWBD', JSON.stringify(salesVolumeGT));
  }, [salesVolumeGT]);

  React.useEffect(() => {
    localStorage.setItem('salesVolLtWBD', JSON.stringify(salesVolumeLT));
  }, [salesVolumeLT]);
  
  
  const loadLatestEntries = () => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        const companyNameCLR = localStorage.getItem('companyNameCLR');
        if (companyNameCLR) {
          setCompanyName(JSON.parse(companyNameCLR));
        }
        const searchTypeCLR = localStorage.getItem('searchTypeCLR');
        if (searchTypeCLR) {
          setSearchType(JSON.parse(searchTypeCLR));
        }
        const legalTypeCLR = localStorage.getItem('legalTypeCLR');
        if (legalTypeCLR) {
          setLegalType(JSON.parse(legalTypeCLR));
        }
        const legalRecordTypeCLR = localStorage.getItem('legalRecordTypeCLR');
        if (legalRecordTypeCLR) {
          setLegalRecordType(JSON.parse(legalRecordTypeCLR));
        }
        const cityCLR = localStorage.getItem('cityCLR');
        if (cityCLR) {
          setCity(JSON.parse(cityCLR));
        }
        const provincesCLR = localStorage.getItem('provincesCLR');
        if (provincesCLR) {
          setProvinces(JSON.parse(provincesCLR));
        }
        const sortOptionCLR = localStorage.getItem('sortOptionCLR');
        if (sortOptionCLR) {
          setSortOption(JSON.parse(sortOptionCLR));
        }
        break;
      case "candian-corporate-names":
        const companyNameCCN = localStorage.getItem('companyNameCCN');
        if (companyNameCCN) {
          setCompanyName(JSON.parse(companyNameCCN));
        }
        const searchTypeCCN = localStorage.getItem('searchTypeCCN');
        if (searchTypeCCN) {
          setSearchType(JSON.parse(searchTypeCCN));
        }
        break;
      case "canadian-federal-coporations-and-directors":
        const companyNameFCD = localStorage.getItem('companyNameFCD');
        if (companyNameFCD) {
          setCompanyName(JSON.parse(companyNameFCD));
        }
        const searchTypeFCD = localStorage.getItem('searchTypeFCD');
        if (searchTypeFCD) {
          setSearchType(JSON.parse(searchTypeFCD));
        }
        const addressFCD = localStorage.getItem('addressFCD');
        if (addressFCD) {
          setAddress(JSON.parse(addressFCD));
        }
        const directorNameFCD = localStorage.getItem('directorNameFCD');
        if (directorNameFCD) {
          setDirectorName(JSON.parse(directorNameFCD));
        }
        const provincesFCD = localStorage.getItem('provincesFCD');
        if (provincesFCD) {
          setProvinces(JSON.parse(provincesFCD));
        }
        break;
      case "encyclopedia-of-associations":
        const companyNameEA = localStorage.getItem('companyNameEA');
        if (companyNameEA) {
          setCompanyName(JSON.parse(companyNameEA));
        }
        const directorNameEA = localStorage.getItem('directorNameEA');
        if (directorNameEA) {
          setDirectorName(JSON.parse(directorNameEA));
        }
        const cityEA = localStorage.getItem('cityEA');
        if (cityEA) {
          setCity(JSON.parse(cityEA));
        }
        const addressEA = localStorage.getItem('addressEA');
        if (addressEA) {
          setAddress(JSON.parse(addressEA));
        }
        const provincesEA = localStorage.getItem('provincesEA');
        if (provincesEA) {
          setProvinces(JSON.parse(provincesEA));
        }
        const areaCodeEA = localStorage.getItem('areaCodeEA');
        if (areaCodeEA) {
          setAreaCode(JSON.parse(areaCodeEA));
        }
        const organizationTypeEA = localStorage.getItem('organizationTypeEA');
        if (organizationTypeEA) {
          setOrganizationType(JSON.parse(organizationTypeEA));
        }
        const sicCodeEA = localStorage.getItem('sicCodeEA');
        if (sicCodeEA) {
          setSicCode(JSON.parse(sicCodeEA));
        }
        const employeeGtEA = localStorage.getItem('employeeGtEA');
        if (employeeGtEA) {
          setEmployeesGT(JSON.parse(employeeGtEA));
        }
        const employeeLtEA = localStorage.getItem('employeeLtEA');
        if (employeeLtEA) {
          setEmployeesLT(JSON.parse(employeeLtEA));
        }
        const memberGtEA = localStorage.getItem('memberGtEA');
        if (memberGtEA) {
          setMembersGT(JSON.parse(memberGtEA));
        }
        const memberLtEA = localStorage.getItem('memberLtEA');
        if (memberLtEA) {
          setMembersLT(JSON.parse(memberLtEA));
        }
        break;
      case "wards-business-directory":
        const companyNameWBD = localStorage.getItem('companyNameWBD');
        if (companyNameWBD) {
          setCompanyName(JSON.parse(companyNameWBD));
        }
        const searchTypeWBD = localStorage.getItem('searchTypeWBD');
        if (searchTypeWBD) {
          setSearchType(JSON.parse(searchTypeWBD));
        }
        const directorNameWBD = localStorage.getItem('directorNameWBD');
        if (directorNameWBD) {
          setDirectorName(JSON.parse(directorNameWBD));
        }
        const cityWBD = localStorage.getItem('cityWBD');
        if (cityWBD) {
          setCity(JSON.parse(cityWBD));
        }
        const addressWBD = localStorage.getItem('addressWBD');
        if (addressWBD) {
          setAddress(JSON.parse(addressWBD));
        }
        const provincesWBD = localStorage.getItem('provincesWBD');
        if (provincesWBD) {
          setProvinces(JSON.parse(provincesWBD));
        }
        const areaCodeWBD = localStorage.getItem('areaCodeWBD');
        if (areaCodeWBD) {
          setAreaCode(JSON.parse(areaCodeWBD));
        }
        const locationTypeWBD = localStorage.getItem('locationTypeWBD');
        if (locationTypeWBD) {
          setLocationType(JSON.parse(locationTypeWBD));
        }
        const companyTypeWBD = localStorage.getItem('companyTypeWBD');
        if (companyTypeWBD) {
          setCompanyType(JSON.parse(companyTypeWBD));
        }
        const sicCodeWBD = localStorage.getItem('sicCodeWBD');
        if (sicCodeWBD) {
          setSicCode(JSON.parse(sicCodeWBD));
        }
        const naicsCodeWBD = localStorage.getItem('naicsCodeWBD');
        if (naicsCodeWBD) {
          setNaicsCode(JSON.parse(naicsCodeWBD));
        }
        const salesVolGtWBD = localStorage.getItem('salesVolGtWBD');
        if (salesVolGtWBD) {
          setSalesVolumeGT(JSON.parse(salesVolGtWBD));
        }
        const salesVolLtWBD = localStorage.getItem('salesVolLtWBD');
        if (salesVolLtWBD) {
          setSalesVolumeLT(JSON.parse(salesVolLtWBD))
        }
        const employeGtWBD = localStorage.getItem('employeeGtWBD');
        if (employeGtWBD) {
          setEmployeesGT(JSON.parse(employeGtWBD))
        }
        const employeLtWBD = localStorage.getItem('employeeLtWBD');
        if (employeLtWBD) {
          setEmployeesLT(JSON.parse(employeLtWBD))
        }
        break;
    }
  }

  const clearCheckCommercialLaw = () => {
    setCompanyName('');
    setSearchType('exact-phrase');
    setLegalType('both');
    setLegalRecordType([]);
    setCity('');
    setProvinces([]);
    setSortOption('d');
  }
  const clearCheckCorporateNames = () => {
    setCompanyName('');
    setSearchType('exact-phrase');
  }
  const clearCheckFederalCorp = () => {
    setCompanyName('');
    setSearchType('exact-phrase');
    setAddress('');
    setDirectorName('');
    setProvinces([]);
  }
  const clearCheckEncyclopediaAssociations = () => {
    setCompanyName('');
    setDirectorName('');
    setCity('');
    setAddress('');
    setProvinces([]);
    setAreaCode('');
    setOrganizationType([]);
    setSicCode('');
    setEmployeesGT('');
    setEmployeesLT('');
    setMembersGT('');
    setMembersLT('');
  }
  const clearCheckBusinessDirectory = () => {
    setCompanyName('');
    setSearchType('exact-phrase');
    setDirectorName('');
    setCity('');
    setAddress('');
    setProvinces([]);
    setAreaCode('');
    setLocationType([]);
    setCompanyType([]);
    setSicCode('');
    setNaicsCode('');
    setSalesVolumeGT('');
    setSalesVolumeLT('');
    setEmployeesGT('');
    setEmployeesLT('');
  }

  const clearCheck = () => {
    switch(externalDb?.slug) {
      case "equifax-commerical-law-record":
        clearCheckCommercialLaw();
        break;
      case "candian-corporate-names":
        clearCheckCorporateNames();
        break;
      case "canadian-federal-coporations-and-directors":
        clearCheckFederalCorp();
        break;
      case "encyclopedia-of-associations":
        clearCheckEncyclopediaAssociations();
        break;
      case "wards-business-directory":
        clearCheckBusinessDirectory();
        break;
    }
  }

  const P = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    margin: 0;
  `;
  const externalDatabaseSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL(
      `/external-databases/results/${params.dbCode}`,
      baseUrl
    );
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);
    const slugArray = [
      "equifax-commerical-law-record",
      "candian-corporate-names",
      "canadian-federal-coporations-and-directors",
      "wards-business-directory",
    ];
    const slugCommercialArray = [
      "equifax-commerical-law-record",
    ];
    if (companyName) {
      resultsUrl.searchParams.set("companyName", companyName);
    }
    // lookup
    if (searchType && slugArray.includes(externalDb?.slug || "")) {
      resultsUrl.searchParams.set("searchType", searchType);
    }

    // lookup
    if (legalType && slugCommercialArray.includes(externalDb?.slug || "")) {
      resultsUrl.searchParams.set("legalType", legalType);
    }
    // lookup
    if (legalRecordType) {
      resultsUrl.searchParams.set(
        "legalRecordType",
        legalRecordType.join(", ")
      );
    }
    if (city) {
      resultsUrl.searchParams.set("city", city);
    }
    if (address) {
      resultsUrl.searchParams.set("address", address);
    }

    // lookup
    if (provinces) {
      resultsUrl.searchParams.set("provinces", provinces.join(", "));
    }

    if (directorName) {
      resultsUrl.searchParams.set("directorName", directorName);
    }
    // lookup
    if (organizationType) {
      resultsUrl.searchParams.set(
        "organizationType",
        organizationType.join(", ")
      );
    }
    if (areaCode) {
      resultsUrl.searchParams.set("areaCode", areaCode);
    }
    if (sicCode) {
      resultsUrl.searchParams.set("sicCode", sicCode);
    }
    if (naicsCode) {
      resultsUrl.searchParams.set("naicsCode", naicsCode);
    }
    if (employeesGT) {
      resultsUrl.searchParams.set("employeesGT", employeesGT);
    }
    if (employeesLT) {
      resultsUrl.searchParams.set("employeesLT", employeesLT);
    }
    if (membersGT) {
      resultsUrl.searchParams.set("membersGT", membersGT);
    }
    if (membersLT) {
      resultsUrl.searchParams.set("membersLT", membersLT);
    }
    // lookup
    if (sortOption && slugCommercialArray.includes(externalDb?.slug || "")) {
      resultsUrl.searchParams.set("sortOption", sortOption);
    }
    // lookup
    if (locationType) {
      resultsUrl.searchParams.set("locationType", locationType.join(", "));
    }
    // lookup
    if (companyType) {
      resultsUrl.searchParams.set("companyType", companyType.join(", "));
    }
    if (salesVolumeLT) {
      resultsUrl.searchParams.set("salesVolumeLT", salesVolumeLT);
    }
    if (salesVolumeGT) {
      resultsUrl.searchParams.set("salesVolumeGT", salesVolumeGT);
    }

    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
        External Databases - {dbFullName} - Search
      </Heading>

      <form
        aria-labelledby={headingId}
        onSubmit={(e) => {
          e.preventDefault();
          externalDatabaseSubmitData();
        }}
      >
        {externalDb?.slug === "equifax-commerical-law-record" && (
          <>
            <Text lineHeight="md" mb="md">
              Provides public information on over 410,000 commercial legal suits
              and judgements from courts across Canada.
            </Text>

            <Text color="light" lineHeight="md" mb="lg">
              Use only the search options that you need. Providing more details
              will narrow your search results.
            </Text>

            <Row flexWrap="wrap">
              <TextField
                label="Company Name"
                value={companyName}
                onChange={setCompanyName}
              />

              <div style={{ margin: "24px" }}>
                <RadioList
                  direction="row"
                  aria-label="Search Type"
                  value={searchType}
                  onChange={(e) => {
                    localStorage.setItem("searchType", `${e}`);
                    setSearchType(e);
                  }}
                  options={searchTypePredecessorLookup}
                />
              </div>
            </Row>

            <Row>
              <div>
                <RadioList
                  aria-label="Legal type"
                  value={legalType}
                  onChange={(e) => {
                    localStorage.setItem("legalType", `${e}`);
                    setLegalType(e);
                  }}
                  direction="row"
                  options={legalTypeLookup}
                />
              </div>
            </Row>

            <div style={{ marginTop: "24px" }}>
              <MultiSelectField
                label="Record Type"
                items={legalRecordTypeLookup}
                value={legalRecordType}
                onChange={setLegalRecordType}
              />
            </div>

            <Row>
              <TextField
                mt="lg"
                label="City (Defendant's Residence)"
                value={city}
                onChange={setCity}
              />
            </Row>

            <Row>
              <div style={{ marginTop: "24px" }}>
                <MultiSelectField
                  label="Province"
                  items={provincesLookup}
                  value={provinces}
                  onChange={setProvinces}
                />
              </div>
            </Row>

            <Row>
              <div style={{ marginTop: "24px" }}>
                <RadioList
                  aria-label="Sort by"
                  value={sortOption}
                  // onChange={setSortOption}
                  onChange={(e) => {
                    localStorage.setItem("sortOption", `${e}`);
                    setSortOption(e);
                  }}
                  direction="row"
                  options={sortOptionLookup}
                />
              </div>
            </Row>
          </>
        )}

        {externalDb?.slug === "candian-corporate-names" && (
          <>
            <Text lineHeight="md" mb="lg">
              Provides summary information relating to federally incorporated
              companies included in the Canadian Federal Corporations and
              Directors database.
            </Text>

            <Row flexWrap="wrap">
              <TextField
                label="Company Name"
                value={companyName}
                onChange={setCompanyName}
              />

              <div style={{ margin: "24px" }}>
                <RadioList
                  direction="row"
                  aria-label="Search Type"
                  value={searchType}
                  onChange={setSearchType}
                  options={searchTypePredecessorLookup}
                />
              </div>
            </Row>
          </>
        )}

        {externalDb?.slug === "canadian-federal-coporations-and-directors" && (
          <>
            <Text lineHeight="md" mb="md">
              Provides information on over 630,000 federally incorporated
              companies.
            </Text>

            <Text weight="bold" lineHeight="md" mb="md">
              NOTE: The Canadian Federal Corporations and Directors database no
              longer contains personal address information for directors.
            </Text>

            <Text color="light" lineHeight="md" mb="lg">
              Use only the search options that you need. Providing more details
              will narrow your search results.
            </Text>

            <Row flexWrap="wrap">
              <TextField
                label="Company Name"
                value={companyName}
                onChange={setCompanyName}
              />

              <div style={{ margin: "24px" }}>
                <RadioList
                  direction="row"
                  aria-label="Search Type"
                  value={searchType}
                  onChange={setSearchType}
                  options={searchTypePredecessorLookup}
                />
              </div>
            </Row>

            <TextField
              mt="lg"
              label="Company Address"
              value={address}
              onChange={setAddress}
            />
            <Text mt="xs" color="light">
              Street, City or Postal Code (six chars, no space, i.e. M2R1A7)
            </Text>

            <TextField
              mt="lg"
              label="Director Name"
              value={directorName}
              onChange={setDirectorName}
            />
            <Text mt="xs" color="light">
              (a search for 'John Smith' will also retrieve 'John F. Smith'
              <br /> or 'John Frederick Smith')
            </Text>

            <Row>
              <div style={{ marginTop: "24px" }}>
                <MultiSelectField
                  label="Province"
                  items={provincesLookup}
                  value={provinces}
                  onChange={setProvinces}
                />
              </div>
            </Row>
          </>
        )}

        {externalDb?.slug === "encyclopedia-of-associations" && (
          <>
            <Text lineHeight="md" mb="md">
              Includes profiles of 3,000 Canadian associations extracted from
              the Gale database the{" "}
              <em>Encyclopedia of Associations: International Organizations</em>
              . The profiles provide addresses and descriptions of professional
              societies, trade associations, labor unions, cultural and
              religious organizations, fan clubs, and other groups of all types.
            </Text>

            <Text color="light" lineHeight="md" mb="lg">
              Use only the search options that you need. Providing more details
              will narrow your search results.
            </Text>

            <TextField
              label="Organization Name or Acronym"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              label="Person Name &amp; Position Title"
              value={directorName}
              onChange={setDirectorName}
            />
            <Text mt="xs" color="light">
              (a search for 'John Smith' will also retrieve 'John F. Smith'
              <br /> or 'John Frederick Smith')
            </Text>

            <TextField mt="lg" label="City" value={city} onChange={setCity} />

            <TextField
              mt="lg"
              label="Address &amp; Postal Code"
              value={address}
              onChange={setAddress}
            />

            <Row>
              <div style={{ marginTop: "24px" }}>
                <MultiSelectField
                  label="Province"
                  items={provincesLookup}
                  value={provinces}
                  onChange={setProvinces}
                />
              </div>
            </Row>

            <TextField
              mt="lg"
              label="Area Code"
              value={areaCode}
              onChange={setAreaCode}
            />

            <div style={{ marginTop: "24px" }}>
              <MultiSelectField
                label="Organization Type"
                items={organizationTypesLookup}
                value={organizationType}
                onChange={setOrganizationType}
              />
            </div>

            <TextField
              mt="lg"
              label="SIC Code"
              value={sicCode}
              onChange={setSicCode}
            />
            <Text mt="xs" color="light">
              (to search more than one, separate with spaces)
            </Text>

            <Row flexWrap="wrap">
              <TextField
                mt="lg"
                mr="sm"
                label="Employees (greater than)"
                placeholder="Greater than"
                value={employeesGT}
                onChange={setEmployeesGT}
              />
              <TextField
                mt="lg"
                label="Employees (less than)"
                placeholder="Less than"
                value={employeesLT}
                onChange={setEmployeesLT}
              />
            </Row>

            <Row flexWrap="wrap">
              <TextField
                mt="lg"
                mr="sm"
                label="Members (greater than)"
                placeholder="Greater than"
                value={membersGT}
                onChange={setMembersGT}
              />
              <TextField
                mt="lg"
                label="Members (less than)"
                placeholder="Less than"
                value={membersLT}
                onChange={setMembersLT}
              />
            </Row>
          </>
        )}

        {externalDb?.slug === "wards-business-directory" && (
          <>
            <Text lineHeight="md" mb="md">
              Includes profiles of 9,000 Canadian companies extracted from the
              Gale database{" "}
              <em>
                Ward's Business Directory of Private and Public Companies in
                Canada and Mexico
              </em>
              . The profiles provide current company information of Canadian
              firms, most of which are private companies.
            </Text>

            <Text color="light" lineHeight="md" mb="lg">
              Use only the search options that you need. Providing more details
              will narrow your search results.
            </Text>

            <Row flexWrap="wrap">
              <TextField
                label="Company Name"
                value={companyName}
                onChange={setCompanyName}
              />
              <div style={{ margin: "24px" }}>
                <RadioList
                  direction="row"
                  aria-label="Search Type"
                  value={searchType}
                  onChange={setSearchType}
                  options={searchTypePredecessorLookup}
                />
              </div>
            </Row>

            <TextField
              label="Person Name &amp; Position Title"
              value={directorName}
              onChange={setDirectorName}
            />
            <Text mt="xs" color="light" size="sm">
              (a search for 'John Smith' will also retrieve 'John F. Smith'
              <br /> or 'John Frederick Smith')
            </Text>

            <TextField mt="lg" label="City" value={city} onChange={setCity} />

            <TextField
              mt="lg"
              label="Address &amp; Postal Code"
              value={address}
              onChange={setAddress}
            />

            <Row>
              <div style={{ marginTop: "24px" }}>
                <MultiSelectField
                  label="Province"
                  items={provincesLookup}
                  value={provinces}
                  onChange={setProvinces}
                />
              </div>
            </Row>

            <TextField
              mt="lg"
              label="Area Code"
              value={areaCode}
              onChange={setAreaCode}
            />

            <div style={{ marginTop: "24px" }}>
              <MultiSelectField
                label="Location Type"
                items={locationTypesLookup}
                value={locationType}
                onChange={setLocationType}
              />
            </div>

            <div style={{ marginTop: "24px" }}>
              <MultiSelectField
                label="Company Type"
                items={companyTypeLookup}
                value={companyType}
                onChange={setCompanyType}
              />
            </div>

            <TextField
              mt="lg"
              label="SIC Code"
              value={sicCode}
              onChange={setSicCode}
            />
            <Text mt="xs" color="light">
              (to search more than one, separate with spaces)
            </Text>

            <TextField
              mt="lg"
              label="NAICS Code"
              value={naicsCode}
              onChange={setNaicsCode}
            />
            <Text mt="xs" color="light">
              (to search more than one, separate with spaces)
            </Text>

            <div style={{ marginTop: "24px" }}>
              <label htmlFor="sales_volume">Sales Volume ($000's)</label>
              <br />

              <Row flexWrap="wrap">
                <select
                  style={{ marginRight: "8px" }}
                  id="sales_volume_gt"
                  name="sales_volume_gt"
                  value={salesVolumeGT}
                  onChange={(e) => {
                    setSalesVolumeGT(e.target.value);
                  }}
                >
                  {salesVolumeGTLookup.map((l) => (
                    <option value={l.id}>{l.name}</option>
                  ))}
                </select>

                <select
                  id="sales_volume_lt"
                  name="sales_volume_lt"
                  value={salesVolumeLT}
                  onChange={(e) => {
                    setSalesVolumeLT(e.target.value);
                  }}
                >
                  {salesVolumeLTLookup.map((l) => (
                    <option value={l.id}>{l.name}</option>
                  ))}
                </select>
              </Row>
            </div>

            <Row flexWrap="wrap">
              <TextField
                mt="lg"
                mr="sm"
                label="Employees (greater than)"
                placeholder="Greater than"
                value={employeesGT}
                onChange={setEmployeesGT}
              />
              <TextField
                mt="lg"
                label="Employees (less than)"
                placeholder="Less than"
                value={employeesLT}
                onChange={setEmployeesLT}
              />
            </Row>
          </>
        )}

        <Button
          mt="lg"
          label="Search"
          mb={"lg"}
          type="submit"
        />
      </form>
    </Page>
  );
}
