import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { useNewIssuesSearchResults } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { HideOnPrint } from 'components/HideOnMobile';
import { Link, useHistory } from 'react-router-dom';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { Tooltip } from 'components/Tooltip';
import { stringify } from 'query-string';

export function NewIssuesSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();

  const queryParams = useQueryParams(
    [
      'searchType',
      'issuerName',
      'stockSymbol',
      'startDate',
      'endDate',
      'grossProceedsGt',
      'grossProceedsLt',
      'leagueTables',
      'creditType',
      'countBy',
      'statusDatesType',
      'securityTypes',
      'stSelectDeselectCommonEquity',
      'stSelectDeselectPreferredEquity',
      'stSelectDeselectStructuredProduct',
      'stSelectDeselectDebt',
      'stSelectDeselectIncomeFunds',
      'stCommon',
      'stFlowThrough',
      'stConvertibleDebt',
      'stPreferredShares',
      'stPreferredSecurity',
      'stSplitShares',
      'stInvestmentTrust',
      'stFlowThroughLtdPartnership',
      'stBondDebentureNote',
      'stMediumTermNote',
      'stMortgageBond',
      'stDepositNote',
      'stSerialDebt',
      'stAssetBackedSecurities',
      'stMortgageBackedSecurities',
      'stCapitalSecurities',
      'stMapleBond',
      'stBusinessTrust',
      'stOilGasRoyaltyTrust',
      'stREIT',
      'stPowerPipelineTrust',
      'stOtherTrust',
      'stConvertibleTypes',
      'distributionType',
      'corporateGovernment',
      'searchNewIssuesGICS',
      'searchTargetNAICS',
      'searchTargetSIC',
      'searchUnderwriter',
      'optionAsBookrunner',
      'optionAsLead',
      'offeringProcedure',
      'terms',
      'market',
      'currency',
      'exchange',
      'incorporation',
      'dateInfo',
      'savedSearchName',
      'page',
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );

  const sortOptions = [
    {
      id: 'name',
      name: 'Issuer Name',
    },
    {
      id: 'announced',
      name: 'Announced',
    },
    {
      id: 'status',
      name: 'Status',
    },
  ];

  const [sortOption, setSortOption] = React.useState('name');

  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const searchResults = useNewIssuesSearchResults({
    searchType: queryParams.searchType || undefined,
    issuerName: queryParams.issuerName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    statusDatesType: queryParams.statusDatesType || '',
    startDate: queryParams.startDate || undefined,
    endDate: queryParams.endDate || undefined,
    grossProceedsGt: queryParams.grossProceedsGt || undefined,
    grossProceedsLt: queryParams.grossProceedsLt || undefined,
    leagueTables: queryParams.leagueTables || '',
    creditType: queryParams.creditType || '',
    countBy: queryParams.countBy || '',
    securityTypes: queryParams.securityTypes || '',
    stSelectDeselectCommonEquity: queryParams.stSelectDeselectCommonEquity === 'true',
    stSelectDeselectPreferredEquity: queryParams.stSelectDeselectPreferredEquity === 'true',
    stSelectDeselectStructuredProduct: queryParams.stSelectDeselectStructuredProduct === 'true',
    stSelectDeselectDebt: queryParams.stSelectDeselectDebt === 'true',
    stSelectDeselectIncomeFunds: queryParams.stSelectDeselectIncomeFunds === 'true',
    stCommon: queryParams.stCommon === 'true',
    stFlowThrough: queryParams.stFlowThrough === 'true',
    stConvertibleDebt: queryParams.stConvertibleDebt === 'true',
    stPreferredShares: queryParams.stPreferredShares === 'true',
    stPreferredSecurity: queryParams.stPreferredSecurity === 'true',
    stSplitShares: queryParams.stSplitShares === 'true',
    stInvestmentTrust: queryParams.stInvestmentTrust === 'true',
    stFlowThroughLtdPartnership: queryParams.stFlowThroughLtdPartnership === 'true',
    stBondDebentureNote: queryParams.stBondDebentureNote === 'true',
    stMediumTermNote: queryParams.stMediumTermNote === 'true',
    stMortgageBond: queryParams.stMortgageBond === 'true',
    stDepositNote: queryParams.stDepositNote === 'true',
    stSerialDebt: queryParams.stSerialDebt === 'true',
    stAssetBackedSecurities: queryParams.stAssetBackedSecurities === 'true',
    stMortgageBackedSecurities: queryParams.stMortgageBackedSecurities === 'true',
    stCapitalSecurities: queryParams.stCapitalSecurities === 'true',
    stMapleBond: queryParams.stMapleBond === 'true',
    stBusinessTrust: queryParams.stBusinessTrust === 'true',
    stOilGasRoyaltyTrust: queryParams.stOilGasRoyaltyTrust === 'true',
    stREIT: queryParams.stREIT === 'true',
    stPowerPipelineTrust: queryParams.stPowerPipelineTrust === 'true',
    stOtherTrust: queryParams.stOtherTrust === 'true',
    stConvertibleTypes: queryParams.stConvertibleTypes || '',
    distributionType: queryParams.distributionType || '',
    corporateGovernment: queryParams.corporateGovernment || undefined,
    searchNewIssuesGICS: queryParams.searchNewIssuesGICS
      ? queryParams.searchNewIssuesGICS.split(',')
      : undefined,
    searchTargetNAICS: queryParams.searchTargetNAICS || undefined,
    searchTargetSIC: queryParams.searchTargetSIC || undefined,
    searchUnderwriter: queryParams.searchUnderwriter || undefined,
    optionAsBookrunner: queryParams.optionAsBookrunner === 'true',
    optionAsLead: queryParams.optionAsLead === 'true',
    offeringProcedure: queryParams.offeringProcedure || undefined,
    terms: queryParams.terms || undefined,
    market: queryParams.market || undefined,
    currency: queryParams.currency || undefined,
    exchange: queryParams.exchange || undefined,
    incorporation: queryParams.incorporation || undefined,
    dateInfo: queryParams.dateInfo || undefined,
    savedSearchName: queryParams.savedSearchName || undefined,
    sort: sortOption,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;

  const numberFormatter = new Intl.NumberFormat('en-CA');

  const currencyFormatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  });
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/new-issues/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/new-issues/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: '/new-issues/csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Financial Post New Issues - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/new-issues/search"
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />

          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.issuerName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.issuerName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={6}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            sortOptions={sortOptions}
            sortOption={sortOption}
            setSortOption={setSortOption}
            history={props.history.location.state}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell align="left">
                  <Text>Issuer Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="left">
                  <Text>Issue Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="right">
                  <Text>Total Proceeds</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="center">
                  <Text>Announced</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="center">
                  <Text>Status</Text>
                </Table.HeaderCell>
                <Table.HeaderCell align="center">
                  <Text>Settled</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell align="left" minWidth="md">
                    <Text>{r.name}</Text>
                  </Table.Cell>

                  <Table.Cell align="left" minWidth="md">
                    <Link
                      to={`/new-issues/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.lead}</Text>
                    </Link>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="right">
                    <Text>
                      {r.currency_symbol}{' '}
                      {numberFormatter.format(parseInt(r.total_proceeds || ''))}
                    </Text>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="center">
                    <Text>{r.announcement_date}</Text>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="center">
                    <Text>{r.offering_status_description}</Text>
                  </Table.Cell>

                  <Table.Cell minWidth="md" align="center">
                    <Text>
                      { r.offering_status_description === 'Preliminary' && r.offering_status_date && (
                        <Tooltip title="Estimated Completion Date">
                        {`${r.offering_status_date} *`}
                        </Tooltip>
                      )}

                      { r.offering_status_description !== 'Preliminary' && (
                        <span>{r.offering_status_date}</span>
                      )}
                    </Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
