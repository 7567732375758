import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useId } from '../../hooks';
import { MarginProps, getMarginStyles } from '../../utils/style';
import { Box } from '../Box';
import { ScreenReaderOnly } from '../ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../ScreenReaderAvoidSplitting';
import { Status } from '../Status';
import { Text } from '../Text';
import './index.scss';

export interface TextFieldProps extends MarginProps {
  label: string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  value: string;
  error?: string;
  placeholder?: string;
  size?: number;
  type?: 'password' | 'text' | 'email' | 'number';
  width?: 'md' | 'full';
  autocorrect?: boolean;
  autocapitalize?: boolean;
  autocomplete?: boolean;
  fullWidth?: boolean;
  hideLabel?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink'
})`
  top: 7px;
  position: relative;
  right: 34px;
`;

export const EyeIcon = ({
  className,
  onClick,
  iconHeight,
  open = true
}: {
  iconHeight?: number;
  className?: string;
  onClick: (e: any) => void;
  open: boolean;
}) => (
  <Svg
    viewBox="0 0 26 26 "
     height='26'
    // height={iconHeight || '26'}
     width="26"
    className={className}
    onClick={onClick}
  >
    {open
? (
      <>
        <path
          d="M11 .5C6 .5 1.73 3.61 0 8c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5C20.27 3.61 16 .5 11 .5zM11 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8C9.34 5 8 6.34 8 8s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill="#555"
        />
        <path
          d="M11 .5C6 .5 1.73 3.61 0 8c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5C20.27 3.61 16 .5 11 .5zM11 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8C9.34 5 8 6.34 8 8s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill="#555"
        />
      </>
    )
: (
      <>
        <path
          d="M11 4c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C9.74 4.13 10.35 4 11 4zM1 1.27l2.28 2.28.46.46A11.804 11.804 0 000 9c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L18.73 19 20 17.73 2.27 0 1 1.27zM6.53 6.8l1.55 1.55C8.03 8.56 8 8.78 8 9c0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
          fill="#555"
        />
        <path
          d="M11 .5C6 .5 1.73 3.61 0 8c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5C20.27 3.61 16 .5 11 .5zM11 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8C9.34 5 8 6.34 8 8s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill="#555"
        />
      </>
    )}
  </Svg>
);

const Input = styled.input<{ widthCode?: 'md' | 'full'; hasError?: boolean }>(
  (props) => ({
    padding: '8px',
    width: props.widthCode === 'full' ? '100%' : '341px',
    maxWidth: '100%',
    outline: 'none',
    border: `1px solid ${
      props.hasError
        ? props.theme.palette.error.main
        : props.theme.palette.border.main
    }`,
    borderRadius: '3px',
    '&:focus': {
      border: `2px solid ${
        props.hasError
          ? props.theme.palette.error.main
          : props.theme.palette.primary.main
      }`,
      padding: '7px'
    },
    fontSize: props.theme.typography.textSizes.md
  })
);

const Label = styled.label<MarginProps>((props) => ({
  // display: "inline-block",
  ...getMarginStyles(props)
}));

export function TextField (props: TextFieldProps) {
  const elementId = useId();

  const [isPasswordHidden, setIsPasswordHidden] = React.useState<boolean>(true);

  const inputType =
    props.type === 'password'
      ? isPasswordHidden
        ? 'password'
        : 'text'
      : props.type || 'text';

  return (
    <Box
      mb={props.mb}
      mt={props.mt}
      ml={props.ml}
      mr={props.mr}
      className={props.fullWidth ? 'full-width' : 'icon'}
    >
      <Label htmlFor={ `input_${elementId}` }>
        { props.hideLabel && (
          <ScreenReaderOnly>
            {props.label}
          </ScreenReaderOnly>
        )}

        { !props.hideLabel && (
          <ScreenReaderAvoidSplitting>
            <Text mb="xxs" weight="medium">
              {props.label}
            </Text>
          </ScreenReaderAvoidSplitting>
        )}

        <div className={`${props.type === 'password' && 'eyeIcon'}`}>
          <Input
						id={ `input_${elementId}` }
            onBlur={props.onBlur}
            aria-describedby={ `error_${elementId}` }
            aria-invalid={!!props.error}
            hasError={!!props.error}
            type={inputType || 'text'}
            size={props.size}
            value={props.value}
            placeholder={props.placeholder}
            autoCorrect={props.autocorrect === false ? 'off' : 'on'}
            autoCapitalize={props.autocapitalize === false ? 'off' : 'on'}
            autoComplete={props.autocomplete === false ? 'off' : 'on'}
            onChange={(e) => props.onChange(e.target.value)}
            onFocus={props.onFocus}
            widthCode={props.width}
          />

          {props.type === 'password' && (
            <div className="text">
              <span>{isPasswordHidden ? 'Show' : 'Hide'}</span>
              <div className="sv">
                <EyeIcon
                  iconHeight={19}
                  onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                  open={isPasswordHidden}
                />
              </div>
            </div>
          )}
        </div>
      </Label>
      <Status
        id={ `error_${elementId}` }
        message={props.error || ''}
        onRender={(error) => (
          <Text mt="xxs" color="error" size="sm">
            <ErrorIcon />
            <span style={{ verticalAlign: 'middle' }}>{error}</span>
          </Text>
        )}
      />
    </Box>
  );
}

function ErrorIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={14}
      width={14}
      viewBox="0 0 24 24"
      style={{ verticalAlign: 'middle', marginRight: '2px' }}
    >
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </svg>
  );
}
