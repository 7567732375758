import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BackgroundProvider } from '../../components/BackgroundProvider';
import { CompanyLogo } from '../../components/CompanyLogo';
import { Text } from '../../components/Text';

export const footerHeight = '164px';

const Row = styled.div(
  (props) => `
display: flex;
flex-wrap: wrap;
justify-content: space-between;
@media (min-width: 576px) {
	justify-content: flex-start;
  }
`
);
const FooterLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  margin-right: 0;
  width: 50%;
  line-height: 1.5;
  text-align: ${(props: { textAlign?: string }) => props.textAlign};
  @media (min-width: 576px) {
    line-height: 1.2;
    margin-right: 1rem;
    width: unset;
    text-align: unset;
  }
`;

export const Root = styled.footer(
  (props) => `
  height: auto;
  min-height: ${footerHeight};
	background: ${props.theme.palette.footer.main};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0;
  padding: 1.5rem 0;
	@media(min-width: 768px) {
		margin-left: 16.7rem;
  }
`
);

export const Container = styled.div(
  (props) => `
	max-width: 100%;
	width: 1200px;
	margin: auto;
	padding: 0rem 1.2rem 2.5rem;
	@media(min-width: 576px) {
		padding: 0 1.75rem 2.5rem;
  }
	@media(min-width: 768px) {
		padding: 0 1.75rem 0;
  }
	@media(min-width: 1200px) {
		width: 1100px;
  }
`
);

export const Separator = styled.div((props) => ({
  borderRight: '1px solid #CACACA',
  height: '1em',
  width: '0px',
  marginLeft: props.theme.spacing.md,
  marginRight: props.theme.spacing.md,
}));

export function Footer () {
  return (
    <Root>
      <BackgroundProvider type="dark">
        <Container>
          <CompanyLogo />
          <Text mt="md" size="xs" weight="bold">
            365 Bloor St East, Toronto, ON, M4W 3L4
          </Text>
          <Text mt="xs" size="xs" weight="bold">
            &copy; {new Date().getFullYear()} Financial Post Data, a business unit of Postmedia Network Inc.
            All rights reserved. Unauthorized distribution, transmission or
            republication strictly prohibited.
          </Text>
          <Text mt="md" size="xs" weight="bold">
            <Row>
              <FooterLink
                textAlign="start"
                href="https://pages.postmedia.com/privacy-statement/"
                target="_blank"
              >
                Privacy - Updated
              </FooterLink>
              <FooterLink
                textAlign="end"
                href="https://pages.postmedia.com/termsofservice/"
                target="_blank"
              >
                Terms
              </FooterLink>
              <FooterLink textAlign="start" as={Link} to="/copyright">
                Copyright
              </FooterLink>
              <FooterLink
                textAlign="end"
                href="https://adregistry.postmedia.com/"
                target="_blank"
              >
                Digital Ad Registry
              </FooterLink>
            </Row>
          </Text>
        </Container>
      </BackgroundProvider>
    </Root>
  );
}
