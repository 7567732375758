/**
 * @see https://marvelapp.com/prototype/fc76dda/screen/74867272 (Medium Screen)
 * @see https://marvelapp.com/prototype/6dh8c7c/screen/74867280 (Small SCreen)
 * @see https://marvelapp.com/prototype/7g2jd1b/screen/74867190 (Large Screen)
 */
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';

import { useId, useQueryParams } from '../../../hooks';
import { useSearchResults } from '../../../data';

import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';

import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { countriesLookup, provincesLookupbyAlphaCode } from 'data/lookups';
import { useSearchParams } from 'components/UseSearchParams';
import { stringify } from 'query-string';
import { Row } from 'components/Row';

export function CorporateSnapshotSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    [
      'companyName',
      'stockSymbol',
      'city',
      'provinces[]',
      'countrySnapshot[]',
      'dateInfo',
      'page',
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );

  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const setNumberOfRowsInContext = useSetNumberOfRows();

  const searchResults = useSearchResults({
    companyName: queryParams.companyName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    city: queryParams.city || undefined,
    provinces: queryParams['provinces[]'] || '',
    country: queryParams['countrySnapshot[]'] || '',
    dateInfo: queryParams.dateInfo || undefined,
    page,
    numberOfRows,
  });
  const loading = searchResults.isFetching && !searchResults.latestData;

  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/corporate-snapshots/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;
    const switchCase = (param: any, value: any) => {
      switch (param) {
        case 'companyName':
          return queryParams.companyName
        case 'stockSymbol': {
          return queryParams.stockSymbol
        }
        case 'city': {
          return queryParams.city
        }
        case 'provinces[]': {
          /* @ts-ignore */
          const data = value?.split(',')
          return provincesLookupbyAlphaCode.filter((item) => data.includes(item.id)).map((item) => item.name).join(', ')
        }
        case 'countrySnapshot[]': {
          /* @ts-ignore */
          const data = value?.split(',')
          return countriesLookup.filter((item) => data.includes(item.id)).map((item) => item.name).join(', ')
        }
        case 'dateInfo': {
          return queryParams.dateInfo
        }
        default:
          return [];
      }
    }

    return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Corporate Snapshot - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <Button
        label="New Search"
        mb={noResults ? 'xxl' : 'lg'}
        link="/corporate-snapshots/search"
      />
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={4}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            history={props.history.location.state}
            switchCase={switchCase}
          >
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>
                  <Text>Company Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Former Names</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Symbols</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Coverage Status</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r) => (
                <Table.Row>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/corporate-snapshots/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.name}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    {r.formerNames && r.formerNames.length > 0 && (
                      <BulletedList>
                        {r.formerNames.map((formerName) => (
                          <li>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {r.symbols && r.symbols.length > 0 && (
                      <BulletedList>
                        {r.symbols.map((formerName) => (
                          <li style={{ whiteSpace: 'nowrap' }}>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Text>
                      <span
                        style={{
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {r.coverageStatus?.toUpperCase()}
                      </span>
                    </Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
