import React from 'react';

export interface ICheckOption {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
}

export const CheckOptions = (props: { options: Array<ICheckOption>, labelledBy?: string, vertical?: boolean }) => {
  return (
    <div aria-labelledby={props.labelledBy} style={{ marginTop: '24px', marginBottom: '24px' }}>
      { props.options.map(opt => (
        <label style={{
          marginRight: '16px',
          display: `${props.vertical ? 'block' : 'inline'}`,
          marginBottom: `${props.vertical ? '16px' : '0px'}`
          }}>
          <input
            type='checkbox'
            checked={opt.value}
            style={{ marginRight: '8px' }}
            onChange={(e) => {
              opt.setValue(e.target.checked);
            }}></input>

          { opt.label }
        </label>
      ))}
    </div>
  )
}

export default CheckOptions;
