import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useCurrentBackground } from '../../components/BackgroundProvider';
import { getMarginStyles, MarginProps } from '../../utils/style';

export function ArrowDownIcon (props: {
  color?: string;
  size?: string;
  onClick?: (e: any) => void;
  className?: string;
}) {
  const { color = 'text.light', size = 'sm', onClick, className } = props;
  const theme = useTheme();
  const background = useCurrentBackground();
  const fillColor =
    color === 'text.light'
      ? theme.palette.text.light
      : color || (background === 'dark' ? 'white' : 'black');
  const sizeSvg = props.size === 'sm' ? 16 : 24;

  return (
    <svg
      aria-hidden
      role="img"
      height={size}
      width={sizeSvg}
      viewBox="0 0 24 24"
      style={{ flexShrink: 0 }}
      onClick={(e) => onClick && onClick(e)}
      className={className}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill={fillColor} d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );
}
