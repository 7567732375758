import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function ArrowUpIcon (props: {
  color?: 'text.light';
  size?: 'sm' | 'md';
  onClick?: (e: any) => void;
  className?: string;
}) {
  const { onClick, className } = props;
  const theme = useTheme();
  const color =
    props.color === 'text.light' ? theme.palette.text.light : 'black';
  const size = props.size === 'sm' ? 16 : 24;

  return (
    <svg
      aria-hidden
      role="img"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      style={{ flexShrink: 0 }}
      onClick={(e) => onClick && onClick(e)}
      className={className}
    >
      <path fill={color} d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
    </svg>
  );
}
