import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { useCorporateAnalyzerSearchResults } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { Link, useHistory } from 'react-router-dom';
import { HideOnPrint } from 'components/HideOnMobile';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { stringify } from 'query-string';

export function CorporateAnalyzerSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    ['companyName', 'stockSymbol', 'city', 'provinces', 'countries', 'page'],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const setNumberOfRowsInContext = useSetNumberOfRows();
  const searchResults = useCorporateAnalyzerSearchResults({
    companyName: queryParams.companyName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    city: queryParams.city || undefined,
    provinces: undefined,
    countries: undefined,
    page,
    numberOfRows,
  });
  const loading = searchResults.isFetching && !searchResults.latestData;
  const loadingFirstTime = loading && !searchResults.resolvedData;

  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/corporate-analyzer/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/corporate-analyzer/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Corporate Analyzer - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/corporate-analyzer/search"
        />
      </HideOnPrint>

      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={history}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}
      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={4}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Company Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Former Names</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Symbols</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Coverage Status</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/corporate-analyzer/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.name}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    {r.formerNames && r.formerNames.length > 0 && (
                      <BulletedList>
                        {r.formerNames.map((formerName) => (
                          <li>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {r.symbols && r.symbols.length > 0 && (
                      <BulletedList>
                        {r.symbols.map((formerName) => (
                          <li style={{ whiteSpace: 'nowrap' }}>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Text>{r.coverageStatus?.toUpperCase()}</Text>
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
