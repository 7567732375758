import * as React from 'react';
import styled from 'styled-components/macro';
import { DebugGrid } from '../DebugGrid';

const Root = styled.main<{
  $padding?: 'none';
  $width?: 'full';
  $marginBottom?: 'none';
}>(({ theme, $padding, $width }) => ({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: $width === 'full' ? '100%' : '1100px',
  maxWidth: '100%',
  paddingBottom: $padding === 'none' ? '0px' : '100px',
  outline: 'none',
  border: '2px solid transparent',
  paddingTop: $padding === 'none' ? '0px' : theme.spacing.lg,
  paddingLeft: $padding === 'none' ? '0px' : '16px',
  paddingRight: $padding === 'none' ? '0px' : '16px',
  [`@media(min-width: ${theme.breakpoints.sm})`]: {
    paddingTop: $padding === 'none' ? '0px' : theme.spacing.xl,
    paddingLeft: $padding === 'none' ? '0px' : '24px',
    paddingRight: $padding === 'none' ? '0px' : '24px',
  },
  '&.focus-visible': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

export function Page (props: {
  'aria-labelledby'?: string;
  children: React.ReactNode;
  padding?: 'none';
  width?: 'full';
}) {
  return (
    <Root
      tabIndex={-1}
      aria-labelledby={props['aria-labelledby']}
      $padding={props.padding}
      $width={props.width}
    >
      <DebugGrid />
      {props.children}
    </Root>
  );
}
