import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { usePredecessorDefunctSearchResults } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { Link, useHistory } from 'react-router-dom';
import { HideOnPrint } from 'components/HideOnMobile';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { ReadMoreLess } from 'components/ReadMoreLess';
import { useSearchParams } from 'components/UseSearchParams';
import { titleValues } from 'data/lookups';
import { stringify } from 'query-string';

export function PredecessorDefunctSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    [
      'companyName',
      'stockSymbol',
      'statusActive',
      'statusInactive',
      'searchPdFPIndustry',
      'searchPdGics',
      'searchPdNaics',
      'searchPdSic',
      'searchPdNameChange',
      'searchPdMergedNewName',
      'searchPdMergedSameName',
      'searchPdMergedAmalgamated',
      'searchPdPrivatizedNewName',
      'searchPdPrivatizedAcquired',
      'searchPdSucceeded',
      'searchPdBankruptLiquidated',
      'searchPdCharterCancelled',
      'searchPdCharterRevived',
      'searchPdCharterSurrendered',
      'searchPdInBankruptcy',
      'searchPdInReceivership',
      'searchPdRelisted',
      'searchPdStruckFromRegister',
      'searchPdUnderCcaa',
      'searchPdWindingUp',
      'searchPdWoundUpLiquidated',
      'searchPdContinuance',
      'searchPdFormedByAmalgamation',
      'searchPdSuccessor',
      'searchPdIncorporationFrom',
      'searchPdIncorporationTo',
      'searchPdInitial',
      'searchPdCurrent',
      'naicsCodeType',
      'sicCodeType',
      'companyHistoryText',
      'historyMatchType',
      'startDatePd',
      'endDatePd',
      'dateInfo',
      'page',
      'savedSearchName',
    ],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const searchResults = usePredecessorDefunctSearchResults({
    companyName: queryParams.companyName || undefined,
    stockSymbol: queryParams.stockSymbol || undefined,
    statusActive: !!queryParams.statusActive,
    statusInactive: !!queryParams.statusInactive,
    searchPdNameChange: !!queryParams.searchPdNameChange,
    searchPdMergedNewName: !!queryParams.searchPdMergedNewName,
    searchPdMergedSameName: !!queryParams.searchPdMergedSameName,
    searchPdMergedAmalgamated: !!queryParams.searchPdMergedAmalgamated,
    searchPdPrivatizedNewName: !!queryParams.searchPdPrivatizedNewName,
    searchPdPrivatizedAcquired: !!queryParams.searchPdPrivatizedAcquired,
    searchPdSucceeded: !!queryParams.searchPdSucceeded,
    searchPdBankruptLiquidated: !!queryParams.searchPdBankruptLiquidated,
    searchPdCharterCancelled: !!queryParams.searchPdCharterCancelled,
    searchPdCharterRevived: !!queryParams.searchPdCharterRevived,
    searchPdCharterSurrendered: !!queryParams.searchPdCharterSurrendered,
    searchPdInBankruptcy: !!queryParams.searchPdInBankruptcy,
    searchPdInReceivership: !!queryParams.searchPdInReceivership,
    searchPdRelisted: !!queryParams.searchPdRelisted,
    searchPdStruckFromRegister: !!queryParams.searchPdStruckFromRegister,
    searchPdUnderCcaa: !!queryParams.searchPdUnderCcaa,
    searchPdWindingUp: !!queryParams.searchPdWindingUp,
    searchPdWoundUpLiquidated: !!queryParams.searchPdWoundUpLiquidated,
    searchPdContinuance: !!queryParams.searchPdContinuance,
    searchPdFormedByAmalgamation: !!queryParams.searchPdFormedByAmalgamation,
    searchPdSuccessor: !!queryParams.searchPdSuccessor,
    searchPdFPIndustry: queryParams.searchPdFPIndustry || undefined,
    searchPdGics: queryParams.searchPdGics
      ? queryParams.searchPdGics.split(',')
      : undefined,
    searchPdNaics: queryParams.searchPdNaics
      ? queryParams.searchPdNaics.split(',')
      : undefined,
    searchPdSic: queryParams.searchPdSic
      ? queryParams.searchPdSic.split(',')
      : undefined,
    searchPdIncorporationFrom: queryParams.searchPdIncorporationFrom
      ? queryParams.searchPdIncorporationFrom.split(',')
      : undefined,
    searchPdIncorporationTo: queryParams.searchPdIncorporationTo
      ? queryParams.searchPdIncorporationTo.split(',')
      : undefined,
    searchPdInitial: !!queryParams.searchPdInitial,
    searchPdCurrent: !!queryParams.searchPdCurrent,
    companyHistoryText: queryParams.companyHistoryText || undefined,
    historyMatchType: queryParams.historyMatchType || undefined,
    startDatePd: queryParams.startDatePd || undefined,
    endDatePd: queryParams.endDatePd || undefined,
    dateInfo: queryParams.dateInfo || undefined,
    naicsCodeType: queryParams.naicsCodeType || undefined,
    sicCodeType: queryParams.sicCodeType || undefined,
    savedSearchName: queryParams.savedSearchName || undefined,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;
  const switchCase = (param: any, value: any) => {
    switch (param) {
      case 'companyName':
        return queryParams.companyName
      case 'stockSymbol': {
        return queryParams.stockSymbol
      }
      case 'dateInfo': {
        return queryParams.dateInfo
      }
      default:
        return [];
    }
  }
  const objectLength = Object.values(queryParams)?.filter(e => e);
  const { value }: any = useSearchParams()
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/predecessor-and-defunct/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/predecessor-and-defunct/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: '/predecessor-and-defunct/csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Financial Post Predecessor &amp; Defunct - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link="/predecessor-and-defunct/search"
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}
      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={4}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            history={props.history.location.state}
            switchCase={switchCase}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Company Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Former Names</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Symbols</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Coverage Status</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/predecessor-and-defunct/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.name}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    {r.formerNames && r.formerNames.length > 0 && (
                      <BulletedList>
                        {r.formerNames.map((formerName) => (
                          <li>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {r.symbols && r.symbols.length > 0 && (
                      <BulletedList>
                        {r.symbols.map((formerName) => (
                          <li style={{ whiteSpace: 'nowrap' }}>
                            <Text>{formerName}</Text>
                          </li>
                        ))}
                      </BulletedList>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Text>{r.coverageStatus?.toUpperCase()}</Text>
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
