import yup from 'validations';

/**
 * Validation Schema for Edit password.
 */
const passwordSchema = yup.object({
  password: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .password()
    .required('Review password requirements')
    .min(4, 'Review password requirements'),
  confirmPassword: yup
    .string()
    .required('Review password requirements')
    .oneOf(
      [yup.ref('newPassword'), null],
      'This password doesn’t match new password'
    ),
});

export default passwordSchema;
