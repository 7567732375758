import * as React from 'react';
import styled from 'styled-components/macro';
import {
  getMarginStyles,
  getResponsiveVisibility,
  MarginProps,
  ResponsiveVisibilityProps,
} from '../../utils/style';
import { useCurrentBackground } from '../BackgroundProvider';

export interface IconButtonProps
  extends MarginProps,
    ResponsiveVisibilityProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  'aria-label': string;
}

export function IconButton (props: IconButtonProps) {
  const currentBackground = useCurrentBackground();

  return (
    <Root
      mr={props.mr}
      ml={props.ml}
      mt={props.mt}
      mb={props.mb}
      hideAbove={props.hideAbove}
    >
      <StyledIconButton
        disabled={props.disabled}
        currentBackground={currentBackground}
        aria-label={props['aria-label']}
        type="button"
        onClick={props.onClick}
      >
        {props.children}
      </StyledIconButton>
    </Root>
  );
}

const Root = styled.div<MarginProps & ResponsiveVisibilityProps>((props) => ({
  ...getResponsiveVisibility(props),
  ...getMarginStyles(props),
}));

const StyledIconButton = styled.button<
  { currentBackground: 'light' | 'dark' } & MarginProps &
    ResponsiveVisibilityProps
>((props) => ({
  display: 'block',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: '6px',
  margin: '-6px',
  font: 'inherit',
  outline: 'inherit',
  position: 'relative',
  borderRadius: '50%',
  overflow: 'hidden',
  '&:not([disabled])': {
    cursor: 'pointer',
  },
  '&:not([disabled]):not(.focus-visible):hover': {
    backgroundColor:
      props.currentBackground === 'dark'
        ? 'rgba(255,255,255,0.1)'
        : 'rgba(0,0,0,0.1)',
  },
  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    content: "''",
    zIndex: -1,
    borderRadius: '50%',
    backgroundColor:
      props.currentBackground === 'dark'
        ? 'rgba(255,255,255,0.25)'
        : 'rgba(0,0,0,0.25)',
    transform: 'scale(0)',
    transition: 'all 0s',
    opacity: 0,
  },
  '&[disabled]:before': {
    backgroundColor: 'rgba(255,255,255,0.6)',
    transform: 'scale(1)',
    opacity: 1,
    zIndex: 1,
  },
  '&:not([disabled]).focus-visible:before': {
    transform: 'scale(1)',
    opacity: 1,
    transition: 'all 0.2s',
  },
}));
