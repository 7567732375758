/* eslint-disable */
import * as React from 'react';
import { Button } from 'components/Button';
import './index.scss'

export function ReadMoreLess(props: {
	children: React.ReactNode;
	readMoreBtnClass?: string;
	readLessBtnClass?: string;
	readMoreLink?: string;
	readLessLink?: string;
	readMoreBtn?: string;
	readLessBtn?: string;
	objectLength: (string | string[] | null | undefined)[]
}) {
	const [isHidden, setIsHidden] = React.useState(false);
	const readChildrenSec = document.querySelector('.read--sec--children');
	const height = readChildrenSec?.clientHeight ?? 0

	return (
		<>
			<div className={`${isHidden ? 'read--more--less--child--hidden--sec' :  ''} read--more--less--child--sec`}>
				<div className={`${isHidden ? 'button-hidden' : 'button-show'} read--sec--children`}>
					<div className={`${isHidden ? 'hidden--div--styling' : 'show--div--styling'} read--content--div--styling`}>
						{props.children}
					</div>
				</div>
			</div>
			{height > 52 &&
				<Button
					size="sm"
					className={`${isHidden ? props.readMoreBtnClass : props.readLessBtnClass} read--btn--class`}
					onClick={() => setIsHidden(!isHidden)}
					label={isHidden ? props.readLessLink : props.readMoreLink}
				/>}

		</>
	);
}

ReadMoreLess.defaultProps = {
	readMoreLink: '⬇ More',
	readLessLink: '⬆ Less',
}
