import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function ConnectorIcon (props: { color?: 'subdued'|'primary'; size?: 'sm' | 'md' | 'lg' }) {
	const sizes = {
		sm: '20',
		md: '24',
		lg: '28',
	}

  const size = sizes[props.size || 'md'];
  const theme = useTheme();

  return (
    <svg aria-hidden role="img" height={size} viewBox="0 0 24 24" width={size}>
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01"/>
      <path d="M6.75 19.6853C8.1954 20.822 10.0186 21.5 12 21.5C13.9814 21.5 15.8046 20.822 17.25 19.6853" stroke="#333333" strokeWidth="2"/>
      <path d="M9.5 4.87354C6.02565 5.94109 3.5 9.17559 3.5 12.9999C3.5 13.9623 3.65994 14.8874 3.9547 15.7499" stroke="#333333" strokeWidth="2"/>
      <path d="M14.5 4.87354C17.9743 5.94109 20.5 9.17559 20.5 12.9999C20.5 13.9623 20.34 14.8874 20.0453 15.7499" stroke="#333333" strokeWidth="2"/>
      <path d="M21.5 18C21.5 18.6708 21.2358 19.2798 20.8058 19.7288C20.3507 20.2041 19.7099 20.5 19 20.5C17.6193 20.5 16.5 19.3807 16.5 18C16.5 16.995 17.0931 16.1285 17.9484 15.7313C18.268 15.5829 18.6244 15.5 19 15.5C20.3807 15.5 21.5 16.6193 21.5 18Z" stroke="#333333" strokeWidth="2"/>
      <path d="M7.5 18C7.5 18.6708 7.2358 19.2798 6.80585 19.7288C6.35075 20.2041 5.70995 20.5 5 20.5C3.61929 20.5 2.5 19.3807 2.5 18C2.5 16.995 3.09307 16.1285 3.94833 15.7313C4.26802 15.5829 4.62433 15.5 5 15.5C6.3807 15.5 7.5 16.6193 7.5 18Z" stroke="#333333" strokeWidth="2"/>
      <path d="M14.5 4.5C14.5 5.1708 14.2358 5.77985 13.8059 6.22885C13.3508 6.7041 12.7099 7 12 7C10.6193 7 9.5 5.8807 9.5 4.5C9.5 3.49495 10.093 2.62843 10.9483 2.2313C11.268 2.08286 11.6244 2 12 2C13.3807 2 14.5 3.11929 14.5 4.5Z" stroke="#333333" strokeWidth="2"/>
    </svg>
  );
}
