import * as React from 'react';
import styled from 'styled-components/macro';

const Root = styled.button((props) => ({
  position: 'fixed',
  left: '-5000px',
  top: props.theme.spacing.md,
  zIndex: props.theme.zIndex.skipNavigationButton,
  '&:focus': {
    left: props.theme.spacing.md,
  },
}));

export function SkipNavigationButton () {
  return (
    <Root
      onClick={() => {
        const main = document.querySelector('main');
        if (main) {
          main.focus();
        }
      }}
    >
      Skip navigation
    </Root>
  );
}
