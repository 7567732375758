import * as React from 'react';
import { Page } from '../../components/Page';
import { Heading } from '../../components/Heading';
import { useId } from '../../hooks';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { usePasswordReminder } from '../../containers/AuthProvider';
import { useHistory } from 'react-router';
import { Status } from '../../components/Status';
import { Text } from '../../components/Text';

export function LoginForgotPasswordPage () {
  const headingId = useId();
  const [email, setEmail] = React.useState('');

  const {
    sendPasswordReminder,
    sendPasswordReminderError,
    sendPasswordReminderSuccess,
    clearSendPasswordReminderError,
    isSendingPasswordReminder,
  } = usePasswordReminder();

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="md">
        Password Reminder
      </Heading>

      <Text weight="semi-bold" mb="md" lineHeight="md">
        Forgot your Username or Password?
      </Text>

      <Text mb="md" lineHeight="md">
        Don't worrry. We can help!
      </Text>

      <Text mb="md" lineHeight="md">
        Just type your email address in the box below and press Submit.
      </Text>

      <Text mb="md" lineHeight="md">
        Once we receive this Email address, we'll send you your Username and
        Password.
      </Text>

      <TextField
        placeholder="youremail@mail.com"
        type="email"
        mb="md"
        label="Email"
        value={email}
        onChange={setEmail}
      />

      <div>
        <Button
          mt="md"
          label={isSendingPasswordReminder ? 'Submitting...' : 'Submit'}
          onClick={() => {
            clearSendPasswordReminderError();
            sendPasswordReminder({
              email,
            });
          }}
        />
      </div>
      <Status
        visuallyHidden
        message={isSendingPasswordReminder ? 'Signing in...' : ''}
      />
      <Status
        message={
          sendPasswordReminderError &&
          sendPasswordReminderError?.code !== 'invalid-email'
            ? 'There was an error. Please provide a valid email address.'
            : ''
        }
        onRender={(message) => (
          <Text color="error" mt="sm">
            {message}
          </Text>
        )}
      />
      <Status
        message={
          sendPasswordReminderSuccess
            ? 'Successfully requested password reminder email.'
            : ''
        }
        onRender={(message) => (
          <Text color="primary" mt="sm">
            {message}
          </Text>
        )}
      />
    </Page>
  );
}
