import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Company, useCompany, useCorporateSurveyInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { CompanyInformation } from '../../../containers/CompanyInformation';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';
import { Button } from '../../../components/Button';
import ExpandAll from 'components/ExpandSettings';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';
import './index.scss';

export function CorporateSurveysDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ companyKey: string }>();
    keys = decodeURIComponent(params.companyKey).split(',');
  }
  const companies: any [] = [];

  keys.forEach(key => {
    const { data: company } = useCompany(key);
    const { data: survey, isLoading } = useCorporateSurveyInfo(key);
    const stockSymbol: string | undefined =
      company?.securityexchangelead?.securityexchange
        ?.map((s: { symbol: any; }) => s.symbol)
        .shift();
    const urlGFactset = 'https://custom.factsetdigitalsolutions.com/custom/fpinfomart-ca/jsinc-chart.asp?symb=' + stockSymbol + '&debug=1';

    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, stockSymbol: stockSymbol, urlGFactset: urlGFactset, headingId: headingId });
  });

  const authContext = useAuthContext();
	const defaultExpand = authContext?.expand_collapse_settings['corporate-surveys'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  return (
    <Page aria-label="Corporate Surveys" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0]}
        hideCorpSurveys={true}
      />
      }

      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Corporate Surveys<ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.comp?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>
        <Row>
          <div className="detail--page--card">
            {i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname={'corporate-surveys'}/>
              </div>
            </HideOnPrint>
            }

            <Card mb="lg">
              <CompanyInformation isLoading={element.isLoading} company={element.comp} />
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Quick Reference Data
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <Tiles columns={[1, 1]} isTileOverflowStyling>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: element.survey?.htmlQuickRef?.body.innerHTML || ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            {element.stockSymbol && (
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Stock Activity
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <Tiles columns={[1, 1]} isTileOverflowStyling>
                    <div className='factset--graph' style={{ textAlign: 'center' }}>
                      <iframe src={element.urlGFactset} style={{ width: '616px', height: '300px', border: 'none' }}></iframe>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                      <a
                        href="https://www.factset.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{ width: '74px', height: '14px' }}
													alt='factset-logo'
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATQAAAA7CAMAAADCfobMAAAAM1BMVEUAAAAAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8mkIoTAAAAEHRSTlMAQIC/EO8wn99gIM+Pr1BwpwrBMgAABO5JREFUeNrtnFl24yAQRRkK0Kza/2p7Por0QFU20LZP9/uNQuDqgihbxPwI2dtMJpttZebBkXkw9slEaGmymRBeVB9s3QW+j48Gk/hX5geoRecDP51hM18yroWm0mSORM9ts/wa7sxiAlIbD6RaYsvAlRnNn+xB1dsYuGEOSZzqJoNOXwBY1aRscsvjbxbz/bigly3jjDG6e7GjaKJqHWZJ+vXHg9LIjTskGGNZlRVF06tGCzfKcNywuyzmVxz3iNVC8yCaRjWYTNX50Zyit+8BLYFoatW2wBWB1YoGfrFpUQttBNG0qo1cE6ShmeobQHvFmjajaKhaf2ZhMpOqtz2h7UpoMwkg/N9htqk4hNgTWjIqaGEhEE2pWuSGWaNmKx4SmX7QhtFcoCWbTckeWTVCvPOy29t8pYJlH534uGKFitB2q89egjIZc4XmjBAQTVBtvVrgJiPF8xEDsTAN7+Kgg3JkKHpoKJqsmr0iI2Mqoe2nR+nnQBs4GytdOQuWSdBwwn0OtFFVM+CViUxjaOlzoA1ciMUrYYQ10PBG7J8CDUQrqrbBDyuh4TK50mdAO+mzBFAt/+gM1AgaXbe7nwDtJFogV7aJsHSth4ab25XeH9rAp+uprNoGOJtAG7G2egdocXenbFQUzZilqNoCotVBw9uGssnQNnfO1gQaeazmxpJoxkz4AMXBB9MQWmTIYJXQLBIfYj00yhbEY0G070kl1fhIqoQmP78X0kCL+c9EqqE5ziWURCurRsC8FTQTg1a25dw1z7ms1dA8Z2MLopVVs4iyFTRDq1I2f+4E51MJTSgsUTRULdcuVUDTf+8wRwGa5Xx6moai5VXDdk0VNL1s7h4a9YK2cC6BSqKhap2h6WXD6Tl3WtMocCauLBqqhu1OHaCJsiG0wvyM1dBMHBiymLJoqFrPB4FeNoSWf58hbC0qAhqTP8VNx4UgWlk1PrLXQ9O/7xD2MjRD+3lwaaeutacH0e5VC7BqNIeW3+OzJ2jrBQW7IBqoBh2mptBk2eKbQAPRJNUcXF8LTS9boLeAhqKVVUvwG4GaQpNlW98CGogGqsEWIwgtV0CTZbNvAA1E29bjIe8IVAP7YldoKFt6A2hX0dLl/ZicavEMuic0lC28HtpFNHw/DVQD0jP1hIayxZdD8yx8FhJzqlnYqneDhm+O2BdDwxUNSz5ZNQ5jZ2j2raBdRMtCK69qR7z9d6CBaHj2YMvv1RxUONu/Mj1BNIAxlPZqM0O82+zXkA6afEZsvX8Q7DaX2AkaimZohlovr9oUWIy3j0EbBy4Ftxxihr0DNBANT+6tU7Ys0J/oGh+ARgIIeBKJSbXQZNFwjhQrUP3xpKiHtrAQKKPEuDpoStEwZdWMVbi2qqFN6jNUemiB2kLTF0Oo2qI/cxnU0Ha1aHpobFtDG0E0rWr+gWVIDc0pTwO9FpoD0bSq+S9+SLK1NW1+dAGsmX67DFWlGi5UU1Ksacj9uSMwnh48ZIRrmvQiyiB8PxlQNFk1/GsxKW/1lmOJIpazwODEX4CEE1HMJhxm2LWiXXazM/zIDape+9tPLmlWbpXV28SZnhj0KHzRtjz0llkMN0ct4r4iuGEDLIeqGLpRNiT78OH5hZ4adEyBeR5NKXYN35Fao8uUwv3/OIli9bd/xxbSZAohW8gkFqoYKg6af0L5n0b5BsxLDpVMr+1WAAAAAElFTkSuQmCC"
                        />
                      </a>

                      <Text size="xs" color="light" alignX="center">
                        Quotes delayed at least 15 minutes. Market data provided
                        by{' '}
                        <a
                          href="https://www.factset.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Factset
                        </a>
                        .<br />
                        Powered and implemented by{' '}
                        <a
                          href="https://www.factset.com/products-data/digital-solutions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          FactSet Digital Solutions.
                        </a>
                        <a
                          href="https://www.factset.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Legal Statement.
                        </a>
                      </Text>
                    </div>
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            )}
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Financial Ratios
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <Tiles columns={[1, 1]} isTileOverflowStyling>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.survey?.htmlFinancialRatios?.body.innerHTML || ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Operations
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlOperations?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Directors &amp; Executives
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlExecutives?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Related Companies
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlRelatedCompanies?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Capital Stock
                </Accordion.Heading>
								<Accordion.Content isOverflowScrollStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlCapitalStock?.body.innerHTML || ''
                    }}
                  />
									</Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Price Range
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlPriceRange?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Capital Stock Changes
                </Accordion.Heading>
                <Accordion.Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlCapStockChanges?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Dividends
                </Accordion.Heading>
                <Accordion.Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlDividends?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Long-Term Debt
                </Accordion.Heading>
                <Accordion.Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlLongTermDebt?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Financial Statistics
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlFinStats?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Historical Summary
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.survey?.htmlHistoricalSumm?.body.innerHTML || ''
                    }}
                  />
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hideCorpSurveys={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}
