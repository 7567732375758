import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function DirectorsCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();

  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);

  const [exportTitle, setExportTitle] = React.useState(true);
  const [exportContact, setExportContact] = React.useState(true);
  const [exportIndustry, setExportIndustry] = React.useState(true);
  const [exportGics, setExportGics] = React.useState(true);
  const [exportSic, setExportSic] = React.useState(true);
  const [exportNaics, setExportNaics] = React.useState(true);
  const [exportPrevious, setExportPrevious] = React.useState(true);
  const [exportEducation, setExportEducation] = React.useState(true);
  const [exportAssociations, setExportAssociations] = React.useState(true);
  const [exportGender, setExportGender] = React.useState(true);
  const [exportBirth, setExportBirth] = React.useState(true);

  const getAccessToken = useGetAccessToken();
  const generateCsvFields = () => {
    const fields = [];
    if (exportTitle) {
      fields.push('title');
    }
    if (exportContact) {
      fields.push('contact');
    }
    if (exportIndustry) {
      fields.push('industry');
    }
    if (exportGics) {
      fields.push('gics');
    }
    if (exportSic) {
      fields.push('sic');
    }
    if (exportNaics) {
      fields.push('naics');
    }
    if (exportPrevious) {
      fields.push('previous');
    }
    if (exportEducation) {
      fields.push('education');
    }
    if (exportAssociations) {
      fields.push('associations');
    }
    if (exportGender) {
      fields.push('gender');
    }
    if (exportBirth) {
      fields.push('birth');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/fpdd/dd_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Post Directory of Directors - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Directory of Directors record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            setExportSelectDeselect(e.target.checked);
                            setExportTitle(e.target.checked);
                            setExportContact(e.target.checked);
                            setExportIndustry(e.target.checked);
                            setExportGics(e.target.checked);
                            setExportSic(e.target.checked);
                            setExportNaics(e.target.checked);
                            setExportPrevious(e.target.checked);
                            setExportEducation(e.target.checked);
                            setExportAssociations(e.target.checked);
                            setExportGender(e.target.checked);
                            setExportBirth(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_title"
                          checked={exportTitle}
                          onChange={(e) => {
                            setExportTitle(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_title"
                        >
                          Title
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_contact"
                          checked={exportContact}
                          onChange={(e) => {
                            setExportContact(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_contact"
                        >
                          Business Contact
                        </label>
                        <ul>
                          <li>Address</li>
                          <li>Phone</li>
                          <li>Toll-free</li>
                          <li>Fax</li>
                          <li>Email</li>
                          <li>Website</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_industry"
                          checked={exportIndustry}
                          onChange={(e) => {
                            setExportIndustry(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_industry"
                        >
                          FP Industry
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_gics"
                          checked={exportGics}
                          onChange={(e) => {
                            setExportGics(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_gics"
                        >
                          GICS
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_sic"
                          checked={exportSic}
                          onChange={(e) => {
                            setExportSic(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_sic"
                        >
                          SIC
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_naics"
                          checked={exportNaics}
                          onChange={(e) => {
                            setExportNaics(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_naics"
                        >
                          NAICS
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_previous"
                          checked={exportPrevious}
                          onChange={(e) => {
                            setExportPrevious(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_previous"
                        >
                          Previous Positions
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_educ"
                          checked={exportEducation}
                          onChange={(e) => {
                            setExportEducation(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_educ"
                        >
                          Education
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_associations"
                          checked={exportAssociations}
                          onChange={(e) => {
                            setExportAssociations(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_associations"
                        >
                          Associations
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_gender"
                          checked={exportGender}
                          onChange={(e) => {
                            setExportGender(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_gender"
                        >
                          Gender
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_birth"
                          checked={exportBirth}
                          onChange={(e) => {
                            setExportBirth(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_birth"
                        >
                          Birth Year
                        </label>
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
