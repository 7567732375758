import * as React from 'react';
import styled from 'styled-components/macro';
import { useId } from '../../hooks';
import { lighten } from 'polished';

export function RadioList (props: {
  'aria-label': string;
  value: string;
  disabled?: boolean;
  direction?: 'column' | 'row';
  onChange: (newValue: string) => void;
  options: Array<{ label: string; value: string }>;
  flex?: boolean;
  legend?: string;
  showLegend?: boolean
}) {
  const radioListName = useId();

  return (
    <fieldset
      role="group"
      aria-label={props['aria-label']}
      style={{ border: 'none', display: props.flex ? 'flex' : 'block' }}
    >
      {props.options.map((option) => (
        <RadioLabel
          style={{
            display: props.direction === 'row' ? 'inline-block' : 'block',
          }}
        >
          {option.label}
          <RadioInput
            type="radio"
            checked={props.value === option.value}
            name={radioListName}
            onChange={() => props.onChange(option.value)}
            disabled={props.disabled}
          />
          <FakeRadioCircle />
        </RadioLabel>
      ))}
      <legend style={{ display: props.showLegend ? 'inline' : 'none' }}>{props.legend}</legend>
    </fieldset>
  );
}

const RadioInput = styled.input({
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
});

const FakeRadioCircle = styled.span((props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '22px',
  width: '22px',
  borderRadius: '50%',
  border: '1px solid #666666',
  '&:after': {
    content: "''",
    position: 'absolute',
    transform: 'scale(0)',
    willChange: 'transform',
    top: '5px',
    left: '5px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: props.theme.palette.primary.main,
    transition: 'all 0.2s',
  },
}));

const RadioLabel = styled.label((props) => ({
  display: props.style?.display || 'block',
  position: 'relative',
  paddingLeft: '30px',
  paddingRight: '30px',
  paddingTop: '2px',
  paddingBottom: '4px',
  marginBottom: '16px',
  cursor: 'pointer',
  fontSize: props.theme.typography.textSizes.md,
  [`&:hover ${RadioInput} ~ ${FakeRadioCircle}`]: {
    backgroundColor: lighten(0.4, props.theme.palette.primary.main),
  },
  [`& ${RadioInput}.focus-visible ~ ${FakeRadioCircle}`]: {
    backgroundColor: lighten(0.4, props.theme.palette.primary.main),
  },
  [`& ${RadioInput}:checked ~ ${FakeRadioCircle}`]: {
    border: `1px solid ${props.theme.palette.primary.main}`,
  },
  [`& ${RadioInput}:checked ~ ${FakeRadioCircle}:after`]: {
    transform: 'scale(1)',
  },
}));
