/* eslint-disable*/
import { useHistory } from 'react-router';
import { Button } from "components/Button";
import { SearchField } from "components/SearchField";
import * as React from "react";
import styled from "styled-components";
import { Heading } from "../../../components/Heading";
import { Page } from "../../../components/Page";
import { Text } from "../../../components/Text";
import { useId, useQueryParams } from "../../../hooks";
import ResultIcon from "./result-icon.png";
import { useHelpSearchResults } from "../../../data";
import './index.scss';
import { useParams } from "react-router";
import { Link } from '../../../components/Link';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 2px 5px 2px rgba(215, 215, 215, 0.5);
  border-radius: 8px;
  padding: 1rem;
`;

const P = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  margin: 0;
`;

const MAX_ITEMS = 10;

export function HelpSearchResultPage() {
  const headingId = useId();
	const history = useHistory();
	const [searchValue, setSearchValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

	const queryParams = useQueryParams(
		[
			'query',
		],
		{ onlyStrings: true }
	);

	const searchResults = useHelpSearchResults(
		queryParams.query || undefined
	);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const getRenderedItems = () => {
		if (!searchResults) {
			return [];
		}
    if (isOpen) {
      return searchResults;
    }
    return searchResults?.slice(0, MAX_ITEMS);
  };

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
        Help Centre Search Results
      </Heading>
      <div
        style={{
          marginTop: "16px",
          maxWidth: "600px",
        }}
      >
				<form
					aria-labelledby={headingId}
					role="search"
					onSubmit={(e) => {
						e.preventDefault();
						window.location.href = `/help/search-results?query=${searchValue}`;
					}}
				>
					<SearchField placeholder='Search' aria-label='Search' value={searchValue} setValue={setSearchValue} />
				</form>
      </div>
      <div style={{ marginTop: "24px" }}>
        <Heading level="3" size="lg" mb="md">
          Found {searchResults?.length} result(s) for {queryParams.query}
        </Heading>
      </div>

      <div style={{ marginTop: "24px" }}>
        {/* start */}
        {getRenderedItems().map((item) => (
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "24px" }}
          >
						<Link to={`/help/details/${item.slug}`}>
							<img
								src={ResultIcon}
								height="40"
								width="40"
								alt="Document Icon"
								style={{ marginRight: "15px", verticalAlign:'bottom' }}
							/>
							<span style={{ lineHeight: '40px' }}>
							{item.title}
							</span>
						</Link>
          </div>
        ))}
				<div className='hep--result--btn--sec'>
					{ ((searchResults?.length || 0) > MAX_ITEMS) && (
					<Button 
					mt="lg" 
					label={isOpen ? "View Less" : "View More"} 
					mb="lg" 
					onClick={toggle}
					className='hep--result--btn'
					type="submit" />
					)}
				</div>
        {/* end */}
      </div>
    </Page>
  );
}
