export interface ISearchDb {
  code: string;
  name: string;
  slug: string;
}

export function searchDbByCode (code: string): ISearchDb | undefined {
  return searchDBs.find((db) => db.code === code);
}

export function searchDbBySlug (slug: string): ISearchDb | undefined {
  return searchDBs.find((db) => db.slug === slug);
}

export function searchDbByName (name: string): ISearchDb | undefined {
  return searchDBs.find((db) => db.name === name);
}

export const searchDBs: Array<ISearchDb> = [
  {
    code: 'fpsu',
    slug: 'fp-corporate-surveys',
    name: 'Financial Post Corporate Surveys',
  },
  {
    code: 'fpdd',
    slug: 'fp-directory-of-directors',
    name: 'Financial Post Directory Of Directors',
  },
  {
    code: 'dcfc',
    slug: 'canadian-federal-coporations-and-directors',
    name: 'Industry Canada - Canadian Federal Corporations and Directors',
  },
  // THE BELOW DATABASES HAVE BEEN DISABLED AS PER CHARLES YEOW'S REQUEST
  // {
  // 	code: 'dcti',
  //  slug: 'canadian-trade-index',
  // 	name: 'Owen Media Partners - Canadian Trade Index',
  // },
  // {
  // 	code: 'dpro',
  // slug: 'pro-file-canada',
  // 	name: 'Owen Media Partners - ProFile Canada',
  // },
  {
    code: 'tmga',
    slug: 'encyclopedia-of-associations',
    name: 'Cengage Learning Gale - Encyclopedia of Associations',
  },
  {
    code: 'tmgw',
    slug: 'wards-business-directory',
    name: "Cengage Learning Gale - Ward's Business Directory",
  },
];

export function externalDbByCode (code: string): ISearchDb | undefined {
  return externalDatabases.find((db) => db.code === code);
}

export function externalDbBySlug (slug: string): ISearchDb | undefined {
  return externalDatabases.find((db) => db.slug === slug);
}

export function externalDbByName (name: string): ISearchDb | undefined {
  return externalDatabases.find((db) => db.name === name);
}

export const externalDatabases: Array<ISearchDb> = [
  {
    code: 'ccre',
    slug: 'equifax-commerical-law-record',
    name: 'Equifax Commercial Law Record',
  },
  {
    code: 'cccn',
    slug: 'candian-corporate-names',
    name: 'Industry Canada - Canadian Corporate Names',
  },
  {
    code: 'dcfc',
    slug: 'canadian-federal-coporations-and-directors',
    name: 'Industry Canada - Canadian Federal Corporations and Directors',
  },
  {
    code: 'tmga',
    slug: 'encyclopedia-of-associations',
    name: 'Cengage Learning Gale - Encyclopedia of Associations',
  },
  {
    code: 'tmgw',
    slug: 'wards-business-directory',
    name: "Cengage Learning Gale - Ward's Business Directory",
  },
];
