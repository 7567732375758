import * as React from 'react';
import { Company } from '../../data';
import { Text } from '../../components/Text';
import { Heading } from '../../components/Heading';
import { ScreenReaderAvoidSplitting } from '../../components/ScreenReaderAvoidSplitting';
import { Link } from 'react-router-dom';
import { HideOnMobile, HideOnPrint, HideOnTablet } from '../../components/HideOnMobile';

export function FpCoverageSideNav (props: {
  isLoading: boolean;
  company?: Company;
  hideCorpSnapshots?: boolean;
  hideCorpSurveys?: boolean;
  hideCorpAnalyzer?: boolean;
  hideIndustryReports?: boolean;
  hideInvestorReports?: boolean;
  hideHistReports?: boolean;
  hidePredecessor?: boolean;
  hideMergers?: boolean;
  hideDividends?: boolean;
  hideDirectors?: boolean;
  hideNewIssues?: boolean;
  hideFixedIncome?: boolean;
}) {
  const { isLoading, company } = props;

  const {
    hideCorpSnapshots,
    hideCorpSurveys,
    hideCorpAnalyzer,
    hideIndustryReports,
    hideInvestorReports,
    hideHistReports,
    hidePredecessor,
    hideMergers,
    hideDividends,
    hideDirectors,
    hideNewIssues,
    hideFixedIncome,
  } = props;

  if (
    (company?.products?.filter((p) => p?.fp_code === 'fpsn').length || 0) < 1
  ) {
    company?.products?.unshift({
      fp_code: 'fpsn',
      name: 'Corporate Snapshots',
      enabled: true,
    });
  }

  return (
    <HideOnPrint>
      <HideOnMobile>
        <HideOnTablet>
          <div
            className="hide-mobile hide-tablet"
            style={{
              minWidth: '240px',
              width: '240px',
              marginLeft: '40px',
              marginTop: '-25px',
            }}
          >
            <div style={{ position: 'fixed' }}>
              <Heading level="2" mb="lg" mt="lg" id="fp-coverage-side-nav">
                <ScreenReaderAvoidSplitting>
                  <Text
                    loading={isLoading}
                    color="heading"
                    loadingWidth={200}
                    size="xxl"
                    weight="semi-bold"
                  >
                    FP Advisor Coverage
                  </Text>

                  {!isLoading &&
                    company?.products?.map((product) => {
                      let fpUrl = '';

                      switch (product.fp_code) {
                        case 'fpsn': {
                          if (hideCorpSnapshots) return;
                          fpUrl = `/corporate-snapshots/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fpsu': {
                          if (hideCorpSurveys) return;
                          fpUrl = `/corporate-surveys/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fpan': {
                          if (hideCorpAnalyzer) return;
                          fpUrl = `/corporate-analyzer/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fpir': {
                          if (hideIndustryReports) return;
                          const industryKey = product.fp_id_code
                            ?.split('?key=')
                            .slice(-1);
                          fpUrl = `/industry-reports/details/${industryKey}`;
                          break;
                        }
                        case 'fpcr': {
                          if (hideInvestorReports) return;
                          fpUrl = `/investor-reports/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fphr': {
                          if (hideHistReports) return;
                          fpUrl = `/historical-reports/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fppd': {
                          if (hidePredecessor) return;
                          fpUrl = `/predecessor-and-defunct/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fpma': {
                          if (hideMergers) return;
                          fpUrl = `/mergers-and-acquisitions/results?companyName=${company?.name}`;
                          break;
                        }
                        case 'fpdv': {
                          if (hideDividends) return;
                          fpUrl = `/dividends/details/fp|${product.fp_code}|${company?.fpid}`;
                          break;
                        }
                        case 'fpdd': {
                          if (hideDirectors) return;
                          fpUrl = `/directory-of-directors/results?companyName=${company?.name}&&companySearchType=current-company`;
                          break;
                        }
                        case 'fpni': {
                          if (hideNewIssues) return;
                          fpUrl = `/new-issues/results?issuerName=${company?.name}`;
                          break;
                        }
                        case 'fpfi': {
                          if (hideFixedIncome) return;
                          fpUrl = `/fixed-income/results?issuerName=${company?.name}&issueType=both`;
                          break;
                        }
                      }

                      return (
                        <Link
                          to={!product.enabled ? '#' : `${fpUrl}`}
                          className="black-text"
                          style={{ textDecoration: 'none', cursor: product.enabled ? 'pointer' : 'not-allowed' }}
                        >
                          <Text
                            mt="md"
                            color={product.enabled ? 'main' : 'light'}
                            textDecoration="none"
                            loading={isLoading}
                            loadingWidth={200}
                            size="md"
                            weight="medium"
                          >
                            {product.name
                              .replace('FP ', '')
                              .replace('&amp;', '&')}
                          </Text>
                        </Link>
                      );
                    })}
                </ScreenReaderAvoidSplitting>
              </Heading>
            </div>
          </div>
        </HideOnTablet>
      </HideOnMobile>
    </HideOnPrint>
  );
}
