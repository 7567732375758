import { clear } from 'node:console';
import { darken } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getMarginStyles, MarginProps } from '../../utils/style';
import { BackgroundProvider } from '../BackgroundProvider';

export interface ButtonProps extends MarginProps {
  id?: string;
  label: string | React.ReactNode;
  link?: string;
  noBorder?: boolean;
  clearStatus?: boolean;
  /**
   * Only applicable if link is provided
   */
  target?: '_blank';
  type?: 'submit' | 'button' | 'reset';
  design?: 'primary' | 'secondary';
  size?: 'sm' | 'md';
  weight?: 'medium' | 'semi-bold' | 'bold';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  'aria-label'?: string;
  'aria-haspopup'?: boolean;
  'aria-expanded'?: boolean;
  'aria-controls'?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  className?: string;
}

const StyledButton = styled.button<
  Pick<
    ButtonProps,
    | 'mr'
    | 'ml'
    | 'mt'
    | 'mb'
    | 'design'
    | 'size'
    | 'weight'
    | 'noBorder'
    | 'clearStatus'
  >
>((props) => ({
  display: 'inline-block',
  textDecoration: 'none',
  background: 'none',
  border: props.noBorder
    ? 'none'
    : `1px solid ${props.theme.palette.primary.main}`,
  margin: 0,
  color:
    props.design === 'secondary' ? props.theme.palette.primary.main : 'white',
  lineHeight: '1em',
  fontSize:
    props.size === 'sm'
      ? props.theme.typography.textSizes.sm
      : props.theme.typography.textSizes.md,
  backgroundColor:
    props.design === 'secondary' ? 'white' : props.theme.palette.primary.main,
  borderRadius: '4px',
  padding: props.size === 'sm' ? '8px' : '12px',
  paddingRight: props.size === 'sm' ? '0' : '28px',
  paddingLeft: props.size === 'sm' ? '8px' : '28px',
  fontWeight:
    props.weight === 'medium' ? 500 : props.weight === 'semi-bold' ? 600 : 700,
  cursor: 'pointer',
  outline: 'none',
  position: 'relative',
  transition: 'all 0.2s',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor:
      props.design === 'secondary'
        ? darken(0.1, 'white')
        : darken(0.1, props.theme.palette.primary.main)
  },
  '&.focus-visible': {
    backgroundColor: darken(0.1, props.theme.palette.primary.main)
  },
  '&:active': {
    backgroundColor: darken(0.25, props.theme.palette.primary.main),
    transform: 'scale(0.95)'
  },
  '&:before': {
    position: 'absolute',
    left: '15%',
    content: "''",
    backgroundColor: 'rgba(255,255,255,0.25)',
    width: '70%',
    height: 0,
    marginTop: props.size === 'sm' ? '-25%' : '-30%',
    paddingBottom: '70%',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: 'all 0s',
    opacity: 0
  },
  '&.focus-visible:before': {
    transform: 'scale(1)',
    opacity: 1,
    transition: 'all 0.2s'
  },
  ...getMarginStyles(props)
}));

export const Button = React.forwardRef<any, ButtonProps>(function Button (
  props,
  ref
) {
  if (props.link) {
    const isRelativeLink = props.link[0] === '/';
    const linkProps = isRelativeLink
      ? {
          as: Link,
          to: props.link
        }
      : {
          as: 'a',
          href: props.link,
          target: props.target
        };

    return (
      <StyledButton
        className={props.className}
        id={props.id}
        {...(linkProps as any)}
        mr={props.mr}
        ml={props.ml}
        clearStatus={props.clearStatus}
        mb={props.mb}
        mt={props.mt}
        design={props.design}
        onClick={props.onClick}
        size={props.size}
        weight={props.weight}
        ref={ref}
        aria-label={props['aria-label']}
      >
        <BackgroundProvider type="dark">{props.label}</BackgroundProvider>
      </StyledButton>
    );
  }

  return (
    <StyledButton
      noBorder={props.noBorder}
      className={props.className}
      id={props.id}
      mr={props.mr}
      ml={props.ml}
      mb={props.mb}
      mt={props.mt}
      onClick={props.onClick}
      type={props.type || 'button'}
      size={props.size}
      weight={props.weight}
      design={props.design}
      ref={ref}
      aria-haspopup={props['aria-haspopup']}
      aria-expanded={props['aria-expanded']}
      aria-controls={props['aria-controls']}
      aria-label={props['aria-label']}
      onKeyDown={props.onKeyDown}
      disabled={props.disabled}
    >
      <BackgroundProvider type="dark">{props.label}</BackgroundProvider>
    </StyledButton>
  );
});
