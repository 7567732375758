import { StringSchema } from 'yup';
const yup = require('yup');

/**
 * RegEx will check for edit password has atleast 4 characters,
 * contains lowercase letters
 * contains a number,
 * contains underscore
 */
const passwordRedExp = /^(?=.*[a-z])(?=.*[0-9])(?=.*_).*$/;

/**
 * yup method for password complexity validation.
 */
yup.addMethod(
  yup.string,
  'password',
  function (this: StringSchema, message: string) {
    const tmessage =
      typeof message === 'undefined' ? 'Review password requirements' : message;
    return this.matches(passwordRedExp, tmessage);
  }
);

export default yup;
