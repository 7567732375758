import { config } from '../config';
import {
  useAuthenticatedPaginatedQuery,
  useAuthenticatedQuery,
  useGetUserInfo,
} from '../containers/AuthProvider';
import { combineNames } from '../utils';
import {
  RawCompanyResponse,
  RawSearchResponse,
  RawPersonSearchResult,
  ILeadListResults,
  IExternalDbResults,
  ICompanyTextRecord,
} from './rawApiResponses';
import {
  ILookup,
  locationLookup,
  provincesLookup,
  countriesLookup,
  schoolsLookup,
  degreesLookup,
  specialtiesLookup,
  fellowshipsLookup,
} from '../data/lookups'
import { IRecord } from './records';

import { IHelpDetails, HelpDetails } from './help/help_articles';

import {
  ICporateSurveyInfo,
  ICporateAnalyzerInfo,
  INewIssueInfo,
  IDvidendInfo,
  IPredecessorDefunctInfo,
  IInvestorReportsInfo,
  IHistoricalReportsInfo,
  IMergersAcquisitionInfo,
  IexportCSV,
  IIndustryReportsInfo,
  IExternalDbInfo,
} from './specs/corporateSurveys';

import {
  ISearchDb,
  searchDbByCode,
  externalDbBySlug,
} from './searchDatabases';
import { createEmitAndSemanticDiagnosticsBuilderProgram } from 'typescript';

interface IPDF {
  id: string;
  name?: string;
  year?: number;
  current?: number;
}

export interface ISecurityExchangeInfo {
  '@attributes': {
    status: string;
    trading: number;
  };
  exchange: string;
  symbol: string;
}

export interface SearchResults {
  numResults: number;
  page: number;
  colomns?: [];
  headings?: Array<string>;
  results: Array<{
    values?: Array<string>;
    id: number;
		fppid?: string;
    key: string;
    name: string;
    formerNames?: string[];
    symbols?: string[];
    coverageStatus?: string | null;
    lead?: string | null;
    position?: string | null;
    amount?: string | null;
    maturitydate?: string | null;
    total_proceeds?: string | null;
    announcement_date?: string | null;
    offering_status_description?: string | null;
    offering_status_date?: string | null;
    currency_symbol?: string | null;
    pdfs?: Array<IPDF>;
    cdn_value?: string | null;
    value?: string | null;
    acquirors?: Array<{
      id: string;
      name: string;
    }>;
    targets?: Array<{
      id: string;
      name: string;
    }>;
    vendors?: Array<{
      id: string;
      name: string;
    }>;
    Updated?: string;
    AnnouncementDate?: string;
    deal_status_description?: string;
  }>;
}

export interface PersonSearchResultEntry {
  numResults: number;
  location?: string;
  columns?: string[];
  name?: string;
  page: number;
  results: Array<{
    id?: number;
    key: string;
    name?: string;
    formerNames?: string[];
    symbols?: string[];
    coverageStatus?: string | null;
    lead?: string;
  }>;
}

interface PersonSearchDbResult {
  database: ISearchDb;
  results: PersonSearchResultEntry;
}

interface PersonSearchResults {
  dbResults: Array<PersonSearchDbResult>;
}

export interface NaicsSearchResults {
  dbResult: Array<ILookup>;
}

// interface CorporateSurveyInfo {
// 	data: {
// 		fpsecurity: {
// 			fpcompany: {
// 				fpid: string;
// 				gics: string;
// 				naics: string;
// 				sic: string;
// 				jurisdiction: string;
// 				names: {
// 					legalname: string;
// 					sortname: string;
// 				}
// 			}
// 		}
// 	}
// }

interface FixedIncomeInfo {
  xml?: string;
  htmlFixedIncomeDetails?: Document;
  data: {
    fpsecurity: {
      fpcompany: {
        fpid: string;
        gics: string;
        naics: string;
        sic: string;
        jurisdiction: string;
        names: {
          legalname: string;
          sortname: string;
        };

        contactaddresses?: {
          fpid: string;
          webaddress: string;
          contactaddress: Array<{
            '@attributes': { code: string; desc: string };
            address: {
              datakey: string;
              line1: string;
              city: string;
              province: string;
              country: string;
              postalcode: string;
            };
            contact?: {
              datakey: string;
              phone?: { areacode: string; number: string };
              fax?: { areacode: string; number: string };
              tollfree?: { areacode: string; number: string };
            };
          }>;
        };

        security: {
          '@attributes': {
            debtequitytype: string;
            securitystatus: string;
            securitytype: string;
            securitydesc: string;
          };
          fpsid: string;
          issuename: string;
          dbrs: {
            '@attributes': { code: string };
            rating: string;
            date: string;
          };
          issued: {
            maturitydate: string;
            amount: string;
            date: string;
            price: string;
            market: string;
          };
          outstanding: { amount: string; date: string };
          payments?: {
            '@attributes': { currencycode: string; currency: string };
            securitypayment: string;
            frequency: string;
            dates: { date: Array<String> };
          };
          paymentdetail?: {
            '@attributes': { debtequitytype: string };
            id: string;
            future: string;
          };
          privateplacement: string;
          cumulative: string;
          redemption?: {
            '@attributes': { debtequitytype: string };
            id: string;
            string: string;
          };
          exchangeable?: {
            '@attributes': { debtequitytype: string };
            id: string;
            string: string;
          };
          companyconvertible?: {
            '@attributes': { debtequitytype: string };
            id: number;
            string: string;
          };
          note: string;
          leadunderwriter: { underwriter: string };
          agents: { agent: string };
          exchange: string;
          issueyield: string;
          cusip: string;
          closing?: number;
          symbol?: string;
          currentyield?: number;
        };
      };
    };
  };
}

export interface IAddress {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
  province: string;
}
export interface Company {
  contactaddresses?: {
    contactaddress?: Array<{
      contact?: {
        email?: string;
      };
    }>;
  };
  name: string;
  fpid: number;
  description: string;
  address: IAddress | null;
  executiveOffice: IAddress | null;
  contact: {
    phone: string | null;
    tollfree: string | null;
    fax: string | null;
    webaddress: string | null;
    contactName: string | null;
    contact: string | null;
    email: string | null;
  };
  executiveContact: {
    phone: string | null;
    tollfree: string | null;
    fax: string | null;
    webaddress: string | null;
    contactName: string | null;
    contact: string | null;
    email: string | null;
  };
  keyExecutives?: Array<{
    name: string;
  }>;
  industry: {
    id: number;
    name: string | null;
  };
  fp500: {
    year: string | null;
    salesRank: string | null;
    assetsRank: string | null;
    incomeRank: string | null;
  };
  dailysnap?: {
    id: number;
    closings: {
      '@attributes': {
        country: string;
        currencysymbol: string;
      };
      closing: number;
      closingdate: number;
      high: number;
      low: number;
      high52wk: number;
      high52wkdate: number;
      low52wk: number;
      low52wkdate: number;
      belowhigh: number;
    };
    volumes: {
      volume: number;
      volumedate: number;
      volume4wkavg: number;
      volume52wkavg: number;
    };
    priceratio: {
      peratio: number;
      percashflow: number;
      persales: number;
      epsyield: number;
      dividendyield: number;
      perbookvalue: number;
    };
    eps: {
      '@attributes': {
        currencysymbol: string;
      };
      interim: number;
      epsdate: number;
      yearago: number;
      momentum: number;
      eps12mo: number;
    };
    forwardratio: {
      eps1yr: number;
      peratio1yr: number;
      epsgrowth1yr: number;
      epsgrowth5yr: {
        '@attributes': {
          textvalue: string;
        };
      };
      pegratio1yr: number;
      pegratio5yr: {
        '@attributes': {
          textvalue: string;
        };
      };
    };
    dividend?: {
      latestdividend: number;
      exdividenddate: number;
      iad: number;
    };
    bookvalue: number;
    dividendpayoutratio: number | string;
    effectivetaxrate: number | string;
    beta: {
      beta52wk: number;
      beta36mo: number;
      beta60mo: number;
      beta120mo: number;
    };
    priceperformance: {
      price1wkchg: number;
      price4wkchg: number;
      price13wkchg: number;
      price40wkchg: number;
      price52wkchg: number;
      price36mochg: number;
      price60mochg: number;
    };
    return: {
      return1yr: number;
      return3yr: number;
      return5yr: number;
    };
    marketcap: number;
    enterprisevalue: {
      '@attributes': {
        textvalue: string;
      };
    };
    evebitda: {
      '@attributes': {
        textvalue: string;
      };
    };
    shares: {
      outstanding: number;
      outstandingqtr: number;
      outstandingdate: number;
    };
  };
  securityexchangelead?: {
    fpid: number;
    fpsid: number;
    securityexchange: Array<ISecurityExchangeInfo>;
  };
  players?: {
    description: string | null;
    companies: Array<{
      name: string;
      fpid: number;
      key: string;
      revenue: number;
    }>;
  };
  records?: Array<IRecord>;
  products?: Array<{
    fp_code: string;
    fp_id_code?: string;
    enabled: boolean;
    name: string;
  }>;
  shortposition?: {
    id?: string;
    position?: Array<{
      date: string;
      shares: string;
    }>;
  };
  htmlFinancialTables?: Document;
  __dangerousHTML: string;
}

interface IPersonConnectorDetails {
	person_info: {
		unique_key: string;
		db_code: string;
		pub_id: boolean;
		data: {
			FullName?: string;
			Title?: string;
			FirstName?: string;
			MiddleName?: string;
			Nickname?: string;
			LastName?: string;
			Kinship?: string;
			Gender?: string;
			BirthYear?: string;
			Retired?: string;
			RetirementDate?: string;
			UpdateOn?: string;
			Datakey?: string;
			FPID?: string;
			CompanyName?: string;
			CompanyWWW?: string;
			Street?: string;
			Line1?: string;
			Line2?: string;
			Line3?: string;
			City?: string;
			Province?: string;
			Country?: string;
			Postal?: string;
			Phone?: string;
			Fax?: string;
			Toll?: string;
			Email?: string;
			FPIndustry?: string;
			SIC?: string;
			NAICS?: string;
			GICS?: string;
			Association?: string;
			Degree?: string;
			Education?: string;
			Associations: Array<{
				Association?: string;
				AssociationDesce?: string;
				AssociationYear?: number;
			}>;
			CurrentPositions: Array<{
				Legal?: string;
				FPID?: string;
				Position?: string;
				LeadCompany?: string;
				StartDate?: string;
				EndDate?: string;
			}>;
			PreviousPositions: Array<{
				FPID?: number;
				CompanyName?: string;
				Position?: string;
				StartDate?: string;
				EndDate?: string;
				Sort?: number;
			}>;
			TEXT?: string,
			HEADLINE?: string;
		},
		error_code?: number;
		media_type?: string;
		partial_content?: boolean;
		highlight_fail?: boolean;
		fast?: boolean;
		expired?: boolean;
		private_doctype?: string;
		clip_start?: string;
		clip_length?: boolean;
		fppid?: number;
		exact_hash?: string;
		similar_hash?: string;
	},
	connectors?: {
		connector_name?: string;
		companies?: Array<{
				company_name: string;
				board_members?: Array<{
						key?: string;
						fppid?: number;
						count?: number;
						name?: string;
						co_board_companies?: boolean;
					}>;
			}>;
	};
}

export function useNaicsCodes () {
  return useAuthenticatedQuery(
    ['naics-codes', ''],
    getNaicsCodes
  );
}

async function getNaicsCodes (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<NaicsSearchResults> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/fp/naics.php`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  )
  const docData = await docResponse.json();
  return docData;
}

export function useCorporateSurveysSearchResults (params: {
  searchForm: string,
  companyName?: string,
  stockSymbol?: string,
  city?: string,
  provinces?: string,
  country?: string,
  searchType?: string,
  searchPredecessor?: string,
  exchange?: string,
  exchangeListed?: string,
  exchangeSuspended?: string,
  contacttype?: string,
  areaCode?: string,
  companyType?: string,
  companySubType?: string,
  jurisdiction?: string,
  incorporationYear?: string,
  majorShareholder?: string,
  directorOfficerName?: string,
  optShareholderInterest10Prcnt?: string,
  optForeignOwnership10Prcnt?: string,
  subsidiary?: string,
  auditor?: string,
  banker?: string,
  lawyer?: string,
  transferAgent?: string,
  idxSPTSXComp?: string,
  idxSPTSX60?: string,
  idxTSX30?: string,
  idxTSXVenTier1?: string,
  idxTSXVenTier2?: string,
  idxTSXVenTier3?: string,
  idxFP500?: string,
  idxCleantech?: string,
  idxCannabis?: string,
  idxBlockchain?: string,
  sicCodes?: string,
  sicCodeType?: string,
  gicCodes?: string,
  naicsCodes?: string,
  naicsCodeType?: string,
  normalCourseIssuerBid?: string,
  substantialIssuerBid?: string,
  dividendReinvestmentPlan?: string,

  textSearch?: string,
  finSearchSortOpt?: string,
  finSearchSortDirection?: string,

  category1?: string,
  dataItem1?: string,
  year1?: string,
  boolOp1?: string,
  operation1?: string,
  value1?: string,

  category2?: string,
  dataItem2?: string,
  year2?: string,
  boolOp2?: string,
  operation2?: string,
  value2?: string,

  category3?: string,
  dataItem3?: string,
  year3?: string,
  boolOp3?: string,
  operation3?: string,
  value3?: string,

  category4?: string,
  dataItem4?: string,
  year4?: string,
  boolOp4?: string,
  operation4?: string,
  value4?: string,

  category5?: string,
  dataItem5?: string,
  year5?: string,
  boolOp5?: string,
  operation5?: string,
  value5?: string,

  dateInfo?: string,
  db?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['corporate-surveys-search-results', params],
    getCorporateSurveysSearchResults
  );
}

export function useCorporateAnalyzerSearchResults (params: {
  companyName?: string;
  stockSymbol?: string;
  city?: string;
  provinces?: Array<string>;
  countries?: Array<String>;
  searchPredecessor?: boolean;
  searchType?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['corporate-analyzer-search-results', params],
    getCorpateAnalyzerSearchResults
  );
}

export function usePersonSearchResults (params: {
  searchText: string;
  dbs: string;
  page: number;
  numberOfRows: number;
  dateInfo?: string;
}) {
  return useAuthenticatedPaginatedQuery(
    ['person-search-results', params],
    getPersonSearchResults
  );
}

export function useDirectorsSearchResults (params: {
  personName: string;
  companyName: string;
  companySearchType: string;
  position?: string;
  gender?: string;
  companyTypePublic?: string;
  companyTypePrivate?: string;
  companyTypeFP500?: string;
  companyTypeNotForProfit?: string;
  targetFPIndustry?: string;
  deptHeads?: string;
  gicCodes?: string;
  sicCodes?: string;
  sicPrimary?: string;
  naicsCodes?: string;
  naicsPrimary?: string;
  city?: string;
  provinces?: Array<string>;
  countries?: Array<string>;
  school?: Array<string>;
  degree?: Array<string>;
  speciality?: Array<string>;
  fellowship?: Array<string>;
  dateInfo?: string;
  sort?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['directors-search-results', params],
    getDirectorsSearchResults
  );
}

export function useDirectorsConnectorsSearchResults (params: {
  personName: string;
  sort?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['directors-connectors-search-results', params],
    getDirectorsConnectorsSearchResults
  );
}

export function useDirectorsConnectorsDetails (params: {
  docKey: string;
}) {
  return useAuthenticatedPaginatedQuery(
    ['directors-connectors-details', params],
    getDirectorsConnectorsDetails
  );
}

export function useDividendsSearchResults (params: {
  companyName?: string;
  stockSymbol?: string;
  issueType?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['dividends-search-results', params],
    getDividendsSearchResults
  );
}

export function useFixedIncomeSearchResults (params: {
  issuerName?: string;
  stockSymbol?: string;
  issueType?: string;
  termsOfIssue?: string;
  market?: string;
  currency?: string;
  incorporation?: string;
  gics?: Array<string>;
  naic?: string;
  sic?: string;
  amountIssuedGT?: string;
  amountIssuedLT?: string;
  debtMaturity?: string;
  dbrsRatingLT?: string;
  dbrsRatingGT?: string;
  couponFeature?: string;
  couponPrcntMin?: string;
  couponPrcntMax?: string;
  optionCallable?: boolean;
  optionConvertible?: boolean;
  optionChangeControl?: boolean;
  dateInfo?: string;
  savedSearchName?: string;
  sort?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['fixed-income-search-results', params],
    getFixedIncomeSearchResults
  );
}

export function useInvestorReportsSearchResults (params: {
  companyName?: string;
  stockSymbol?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['investor-reports-search-results', params],
    getInvestorReportsSearchResults
  );
}

export function useIndustryReportsListing (params: {
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['industry-reports-search-results', params],
    getIndustryReportsSearchResults
  );
}

export function useMergersAcquisitionsSearchResults (params: {
  companyName?: string;
  searchRestrictTarget?: boolean;
  searchRestrictVendor?: boolean;
  searchRestrictAcquiror?: boolean;
  searchTargetType?: string;
  searchVendorType?: string;
  searchAcquirorType?: string;
  searchTargetFPIndustry?: string;
  searchVendorFPIndustry?: string;
  searchAcquirorFPIndustry?: string;
  searchTargetNAICS?: string;
  searchVendorNAICS?: string;
  searchAcquirorNAICS?: string;
  searchTargetSIC?: string;
  searchVendorSIC?: string;
  searchAcquirorSIC?: string;
  searchTargetGICS?: string;
  searchVendorGICS?: string;
  searchAcquirorGICS?: string;
	searchLocationTarget?: Array<string>;
	searchLocationVendor?: Array<string>;
	searchLocationAcquiror?: Array<string>;
	searchPubliclyTraded?: boolean;
	searchDivestiture?: boolean;
	searchPrivatelyOwned?: boolean;
	searchForeignTarget?: boolean;
  searchFinancialAdvisor?: string;
  searchLegalAdvisor?: string;
  searchDealValueGt?: string;
  searchDealValueLt?: string;
	searchPercentageBoughtGt?: string;
	searchPercentageBoughtLt?: string;
  keywords?: string;
  searchType?: string;
  searchDealDescription?: boolean;
  searchTargetBusinessDescription?: boolean;
  searchVendorBusinessDescription?: boolean;
  searchAcquirorBusinessDescription?: boolean;
  searchPaymentCash?: boolean;
  searchPaymentStock?: boolean;
  searchPaymentAssumptionDebt?: boolean;
  searchPaymentOther?: boolean;
  startDate?: string;
  endDate?: string;
  searchDatesAnnounced?: boolean;
  searchDatesUpdated?: boolean;
  searchDealStatusCompleted?: boolean;
  searchDealStatusPending?: boolean;
  searchDealStatusTerminated?: boolean;
  savedSearchName?: string;
  sort?: string;
  dateInfo?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['megers-acquisitions-search-results', params],
    getMergersAcquisitionsSearchResults
  );
}

export function useNewIssuesSearchResults (params: {
  searchType?: string;
  issuerName?: string;
  stockSymbol?: string;
  statusDatesType?: string;
  startDate?: string;
  endDate?: string;
  grossProceedsGt?: string;
  grossProceedsLt?: string;
  leagueTables?: string;
  creditType?: string;
  countBy?: string;
  securityTypes?: string;
  stSelectDeselectCommonEquity?: boolean;
  stSelectDeselectPreferredEquity?: boolean;
  stSelectDeselectStructuredProduct?: boolean;
  stSelectDeselectDebt?: boolean;
  stSelectDeselectIncomeFunds?: boolean;
  stCommon?: boolean;
  stFlowThrough?: boolean;
  stConvertibleDebt?: boolean;
  stPreferredShares?: boolean;
  stPreferredSecurity?: boolean;
  stSplitShares?: boolean;
  stInvestmentTrust?: boolean;
  stFlowThroughLtdPartnership?: boolean;
  stBondDebentureNote?: boolean;
  stMediumTermNote?: boolean;
  stMortgageBond?: boolean;
  stDepositNote?: boolean;
  stSerialDebt?: boolean;
  stAssetBackedSecurities?: boolean;
  stMortgageBackedSecurities?: boolean;
  stCapitalSecurities?: boolean;
  stMapleBond?: boolean;
  stBusinessTrust?: boolean;
  stOilGasRoyaltyTrust?: boolean;
  stREIT?: boolean;
  stPowerPipelineTrust?: boolean;
  stOtherTrust?: boolean;
  stConvertibleTypes?: string;
  distributionType?: string;
  corporateGovernment?: string;
  searchNewIssuesGICS?: Array<string>;
  searchTargetNAICS?: string;
  searchTargetSIC?: string;
  searchUnderwriter?: string;
  optionAsBookrunner?: boolean;
  optionAsLead?: boolean;
  offeringProcedure?: string;
  terms?: string;
  market?: string;
  currency?: string;
  exchange?: string;
  incorporation?: string;
  dateInfo?: string;
  savedSearchName?: string;
  sort?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['new-issues-search-results', params],
    getNewIssuesSearchResults
  );
}

export function usePredecessorDefunctSearchResults (params: {
  companyName?: string;
  contactType?: string;
  stockSymbol?: string;
  statusActive?: boolean;
  statusInactive?: boolean;
  searchPdFPIndustry?: string;
  searchPdGics?: Array<string>;
  searchPdNaics?: Array<string>;
  searchPdSic?: Array<string>;
  searchPdNameChange?: boolean;
  searchPdMergedNewName?: boolean;
  searchPdMergedSameName?: boolean;
  searchPdMergedAmalgamated?: boolean;
  searchPdPrivatizedNewName?: boolean;
  searchPdPrivatizedAcquired?: boolean;
  searchPdSucceeded?: boolean;
  searchPdBankruptLiquidated?: boolean;
  searchPdCharterCancelled?: boolean;
  searchPdCharterRevived?: boolean;
  searchPdCharterSurrendered?: boolean;
  searchPdInBankruptcy?: boolean;
  searchPdInReceivership?: boolean;
  searchPdRelisted?: boolean;
  searchPdStruckFromRegister?: boolean;
  searchPdUnderCcaa?: boolean;
  searchPdWindingUp?: boolean;
  searchPdWoundUpLiquidated?: boolean;
  searchPdContinuance?: boolean;
  searchPdFormedByAmalgamation?: boolean;
  searchPdSuccessor?: boolean;
  searchPdIncorporationFrom?: Array<string>;
  searchPdIncorporationTo?: Array<string>;
  searchPdInitial?: boolean;
  searchPdCurrent?: boolean;
  naicsCodeType?: string;
  sicCodeType?: string;
  companyHistoryText?: string;
  historyMatchType?: string;
  startDatePd?: string;
  endDatePd?: string;
  dateInfo?: string;
  savedSearchName?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['predecessor-defunct-search-results', params],
    getPredecessorDefunctSearchResults
  );
}

export function useLeadListGeneratorSearchResults (params: {
  auditor?: string;
  transferAgent?: string;
  lawyer?: string;
  banker?: string;
  idxSPTSXComp?: string;
  idxSPTSX60?: string;
  idxTSX30?: string;
  idxTSXVenTier1?: string;
  idxTSXVenTier2?: string;
  idxTSXVenTier3?: string;
  idxFP500?: string;
  idxCleantech?: string;
  exchangeListed?: string;
  exchangeSuspended?: string;
  idxAll?: string;
  normalCourseIssuerBid?: string;
  substantialIssuerBid?: string;
  dividendReinvestmentPlan?: string;
  majorShareholder?: string;
  country?: string;
  contacttype?: string;
  sicCodeType?: string;
  dbCode: string;
  companyName?: string;
  street?: string,
  city?: string;
  address?: string;
  provinces?: string;
  exchange?: string;
  areaCode?: string;
  locationType?: string;
  companyType?: string;
  companySubType?: string;
  jurisdiction?: string;
  gicCodes?: string;
  sicCodes?: string;
  employeesGT?: string;
  employeesLT?: string;
  companyNameType?: string;
  position?: string;
  gender?: string;
  school?: string;
  degree?: string;
  speciality?: string;
  fellowship?: string;
  sicCode?: string;
  dateInfo?: string;
  companyTypes?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['lead-list-generator-search-results', params],
    getLeadListGeneratorSearchResults
  );
}

export function useExternalDatabasesSearchResults (params: {
  dbCode: string;
  contactType?: string;
  companyName?: string;
  searchType?: string;
  legalType?: string;
  legalRecordType?: string;
  city?: string;
  address?: string;
  provinces?: string;
  directorName?: string;
  organizationType?: string;
  areaCode?: string;
  sicCode?: string;
  naicsCode?: string;
  employeesGT?: string;
  employeesLT?: string;
  membersGT?: string;
  membersLT?: string;
  sortOption?: string;
  country?: string;
  locationType?: string;
  companyType?: string;
  salesVolumeLT?: string;
  salesVolumeGT?: string;
  cengageCompanyType?: string;
  dateInfo?: string;
  page: number;
  numberOfRows: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['external-databases-search-results', params],
    getExternalDatabasesSearchResults
  );
}

export function useSearchResults (params: {
  companyName?: string;
  stockSymbol?: string;
  city?: string;
  provinces?: string;
  country?: string;
  dateInfo?: string;
  page: number;
  numberOfRows?: number;
}) {
  return useAuthenticatedPaginatedQuery(
    ['search-results', params],
    getSearchResults
  );
}

export function useCompany (companyKey: string) {
  return useAuthenticatedQuery(['company', companyKey], getCompany);
}

export function useInvestorReportsInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['inevestor-reports-details', docKey],
    getInvestorReportsDetails
  );
}

export function useIndustryReportInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['industry-reports-details', docKey],
    getIndustryReportsDetails
  );
}

export function useHistoricalReportsInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['historical-reports-details', docKey],
    getHistoricalReportsDetails
  );
}

export function useMergersAcquisitionsDetails (docKey: string) {
  return useAuthenticatedQuery(
    ['mergers-acquisitions-details', docKey],
    getMergersAcquisitionsDetails
  );
}

export function useHelpDetails (categorySlug: string): IHelpDetails|undefined {
  return HelpDetails.find(hd => hd.slug === categorySlug)
}

export function useHelpSearchResults (query?: string): Array<IHelpDetails>|undefined {
  if (!query) {
    return;
  }
  return HelpDetails.filter(h => h.contents.toLowerCase().includes(query.toLowerCase()));
}

export function useFixedIncomeInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['fixed-income-details', docKey],
    getFixedIncomeDetails
  );
}

export function useDividendInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['dividend-details', docKey],
    getDividendDetails
  );
}

export function usePredecessorDefunctInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['predecessor-defunct-details', docKey],
    getPredecessorDefunctDetails
  );
}

export function useNewIssues (docKey: string) {
  return useAuthenticatedQuery(
    ['new-issue-details', docKey],
    getNewIssueDetails
  );
}

export function useExternalDatabasesDetails (docKey: string) {
  return useAuthenticatedQuery(
    ['external-databases-details', docKey],
    getExternalDatabasesDetails
  );
}

async function getInvestorReportsDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IInvestorReportsInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IInvestorReportsInfo = await docResponse.json();

  docData.htmlBusiness = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_business.xsl'
  );
  docData.htmlValueSciences = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_value_sciences.xsl'
  );
  docData.htmlThomsonReuters = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_thomson_reuters.xsl'
  );
  docData.htmlRevenue = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_revenue.xsl'
  );
  docData.htmlEPS = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_eps.xsl'
  );
  docData.htmlLatestDividend = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_latest_dividend.xsl'
  );
  docData.htmlShortPosition = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_short_position.xsl'
  );
  docData.htmlOperatingStats = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_operating_stats.xsl'
  );
  docData.htmlTop3MarketCap = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_top_3_marketcap.xsl'
  );
  docData.htmlSplitsConsolidations = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_splits_consolidations.xsl'
  );
  docData.htmlLatestResults = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_latest_results.xsl'
  );
  docData.htmlRecentDevelopments = await applyXslt(
    docData.xml || '',
    '/xsl/fpcr/wrapper_investorreport_recent_developments.xsl'
  );

  return docData;
}

async function getIndustryReportsDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IIndustryReportsInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IIndustryReportsInfo = await docResponse.json();

  docData.industryName = docData.data?.industry?.['@attributes'].desc;
  docData.numCompaniesIncluded =
    docData.data?.industry?.included?.company?.length;

  // docData.htmlIndustryRecommendation = await applyXslt(docData.xml || '', '/xsl/fpir/wrapper_industry.xsl');

  docData.htmlIndustryRecommendation = await applyXslt(
    docData.xml || '',
    '/xsl/fpir/wrapper_industry_recommendation.xsl'
  );

  docData.htmlLatestQuarterlyResults = await applyXslt(
    docData.xml || '',
    '/xsl/fpir/wrapper_industry_quarterly_results.xsl'
  );

  docData.htmlIndustryDataBank = await applyXslt(
    docData.xml || '',
    '/xsl/fpir/wrapper_industry_data_bank.xsl'
  );

  docData.htmlCompaniesIncluded = await applyXslt(
    docData.xml || '',
    '/xsl/fpir/wrapper_industry_companies_included.xsl'
  );

  return docData;
}

async function getHistoricalReportsDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IHistoricalReportsInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IHistoricalReportsInfo = await docResponse.json();

  // docData.htmlDoc = await applyXslt(docData.xml || '', '/xsl/fphr/wrapper_historical.xsl');
  docData.htmlComparativeData = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_comparative_data.xsl'
  );
  docData.htmlQuickRefData = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_quick_ref.xsl'
  );
  docData.htmlProfile = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_profile.xsl'
  );
  docData.htmlOperations = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_operations.xsl'
  );
  docData.htmlRelatedCompanies = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_related_companies.xsl'
  );
  docData.htmlHistory = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_history.xsl'
  );
  docData.htmlDirectors = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_directors.xsl'
  );
  docData.htmlOtherExecOfficers = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_other_officers.xsl'
  );
  docData.htmlCapitalStock = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_capital_stock.xsl'
  );
  docData.htmlPriceRange = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_price_range.xsl'
  );
  docData.htmlCapitalStockChanges = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_cap_stock_changes.xsl'
  );
  docData.htmlDividends = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_dividends.xsl'
  );
  docData.htmlLongTermDebt = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_long_term_debt.xsl'
  );
  docData.htmlPerformanceAnalysis = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_perf_analysis.xsl'
  );
  docData.htmlQuarterlyEarnings = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_quarterly_earnings.xsl'
  );
  docData.htmlConsolidatedBalanceSheets = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_balance_sheets.xsl'
  );
  docData.htmlSharesOutstanding = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_shares_outstanding.xsl'
  );
  docData.htmlConsolidatedStatementsIRE = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_consolidated_statements_ire.xsl'
  );
  docData.htmlEPS = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_eps.xsl'
  );
  docData.htmlDPS = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_dps.xsl'
  );
  docData.htmlConsolidatedStatementsCashFlow = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_cash_flow_statements.xsl'
  );
  docData.htmlCashFlowPerShare = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_cfps.xsl'
  );
  docData.htmlHistoricalSummary = await applyXslt(
    docData.xml || '',
    '/xsl/fphr/wrapper_historical_summary.xsl'
  );

  return docData;
}

async function getFixedIncomeDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<FixedIncomeInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: FixedIncomeInfo = await docResponse.json();

  docData.htmlFixedIncomeDetails = await applyXslt(
    docData.xml || '',
    '/xsl/fpfi/wrapper_security.xsl'
  );

  return docData;
}

export function useCorporateAnalyzerInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['corporate-details-details', docKey],
    getCorporateAnalzyerDetails
  );
}

export function useCorporateSurveyInfo (docKey: string) {
  return useAuthenticatedQuery(
    ['corporate-survey-details', docKey],
    getCorporateSurveyDetails
  );
}

async function applyXslt (
  xmlString: string,
  xsltPath: string
): Promise<Document | undefined> {
  // parse XML
  const xmlParser = new DOMParser();
  const xmlDoc = xmlParser.parseFromString(xmlString, 'text/xml');
  if (!xmlDoc) {
    console.error('no XML');
    return undefined;
  }

  // parse XSL
  const xslStr = await (await fetch(xsltPath)).text();

  const xslParser = new DOMParser();
  const xslDoc = xslParser.parseFromString(xslStr, 'text/xml');

  if (!xslDoc) {
    console.error('no XSL');
    return undefined;
  }

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xslDoc);
  const resultDocument = xsltProcessor.transformToDocument(xmlDoc);

  return resultDocument;
}

async function getCorporateAnalzyerDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<ICporateAnalyzerInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: ICporateAnalyzerInfo = await docResponse.json();

  const xml = docData.xml;

  docData.htmlSharePriceStats = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analyzer_share_price_stats.xsl'
  );
  docData.htmlBusiness = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analyzer_business.xsl'
  );
  docData.htmlCapitalStock = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analzyer_capital_stock.xsl'
  );
  docData.htmlCurrentOps = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analzyer_current_ops.xsl'
  );
  docData.htmlLatestResults = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analzyer_latest_results.xsl'
  );
  docData.htmlLongTermDebt = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analzyer_long_term_debt.xsl'
  );
  // docData.htmlSegmented = await applyXslt(xml, '/xsl/fpan/wrapper_analzyer_quick_ref.xsl');
  docData.htmlGeneralInfo = await applyXslt(
    xml,
    '/xsl/fpan/wrapper_analzyer_general_info.xsl'
  );

  const additionalTables = docData.additional_tables || ''
  const parser = new DOMParser()
  const additionalTablesDoc = parser.parseFromString(additionalTables, 'text/html');

  const tables = additionalTablesDoc.getElementsByTagName('table');

  const additionalTablesRows = tables && tables.length > 0
    ? Array.from(additionalTablesDoc.getElementsByTagName('table')[0].rows)
    : [];

  const reutersTableRows = tables && tables.length > 1
    ? additionalTablesDoc.getElementsByTagName('table')[1].rows
    : [];

  let financialInfo: string|undefined = '';
  let marketData: string|undefined = '';
  let ibesData: string|undefined = '';

  const tableBegin = '<table><tbody>';
  const tableEnd = '</tbody></table>';

  const rowsNb = additionalTablesRows.length;
  if (rowsNb) {
    const marketDataRowIndex = additionalTablesRows.findIndex(r => r.innerHTML.includes('Market Data'));

    const ibesRowIndex = additionalTablesRows.findIndex(r => r.innerHTML.includes('I/B/E/S'));

    financialInfo = ibesRowIndex > 0
    ? additionalTablesRows.slice(0, marketDataRowIndex).map(r => r.outerHTML).join('')
    : undefined;

    if (financialInfo) {
      financialInfo = `${tableBegin}${financialInfo}${tableEnd}`;
    }

    marketData = additionalTablesRows.slice(marketDataRowIndex, ibesRowIndex > 0 ? ibesRowIndex : rowsNb).map(r => r.outerHTML).join('');

    if (marketData) {
      marketData = `${tableBegin}${marketData}${tableEnd}`;
    }

    ibesData = ibesRowIndex > 0
    ? additionalTablesRows.slice(ibesRowIndex, rowsNb).map(r => r.outerHTML).join('')
    : undefined;

    if (ibesData) {
      ibesData = `${tableBegin}${ibesData}${tableEnd}`;
    }
  }

  docData.rawHtmlFinancialInfo = financialInfo;
  docData.rawHtmlMarketData = marketData;
  docData.rawHtmlThompsonReuters = ibesData;
  return docData;
}

async function getMergersAcquisitionsDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IMergersAcquisitionInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IMergersAcquisitionInfo = await docResponse.json();

  const xml = docData.xml;

  docData.htmlDoc = await applyXslt(xml, '/xsl/fpma/wrapper_merger.xsl');

  return docData;
}

export async function getExportCSVurl (
  params: any,
  api: string,
  getAccessToken: () => Promise<string | null>
  ): Promise<String> {
  const accessToken = await getAccessToken();
  const requestOptions = {
    method: 'POST',
    headers: {
        AUTHTOKEN: accessToken || '',
        'Content-Type': 'application/json',
      },
    body: JSON.stringify(params)
  };
  const docResponse = await fetch(
    config.apiUrl + api, requestOptions);
  let url = '';
  try {
    const docData: IexportCSV = await docResponse.json();
    url = docData.url;
  } catch (err) {
    console.error('Error parsing json:', docResponse);
  }

  return url;
}

async function getCorporateSurveyDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<ICporateSurveyInfo> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: ICporateSurveyInfo = await docResponse.json();

  const xml = docData.xml;

  docData.htmlQuickRef = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_quick_ref.xsl'
  );

  docData.htmlFinancialRatios = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_financial_ratios.xsl'
  );

  docData.htmlOperations = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_operations.xsl'
  );

  docData.htmlExecutives = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_executives.xsl'
  );

  docData.htmlRelatedCompanies = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_related_companies.xsl'
  );
  docData.htmlCapitalStock = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_capital_stock.xsl'
  );
  docData.htmlPriceRange = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_price_range.xsl'
  );
  docData.htmlCapStockChanges = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_cap_stock_changes.xsl'
  );
  docData.htmlDividends = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_dividends.xsl'
  );
  docData.htmlLongTermDebt = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_long_term_debt.xsl'
  );
  docData.htmlFinStats = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_financial_stats.xsl'
  );
  docData.htmlHistoricalSumm = await applyXslt(
    xml,
    '/xsl/fpsu/wrapper_survey_historial_summary.xsl'
  );

  return docData;
}

async function getDividendDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IDvidendInfo> {
  const accessToken = await getAccessToken();

  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IDvidendInfo = await docResponse.json();

  const xml = docData.xml;

  docData.xml = xml;
  // docData.html = docData.html;

  docData.htmlDividendAnnouncements = await applyXslt(
    xml,
    '/xsl/fpdv/wrapper_dividend_accouncements.xsl'
  );

  return docData;
}

async function getPredecessorDefunctDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IPredecessorDefunctInfo> {
  const accessToken = await getAccessToken();

  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IPredecessorDefunctInfo = await docResponse.json();

  const xml = docData.xml;
  docData.xml = xml;

  docData.htmlInfo = await applyXslt(xml, '/xsl/fppd/wrapper_pnd_info.xsl');

  return docData;
}

async function getExternalDatabasesDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<IExternalDbInfo> {
  const accessToken = await getAccessToken();

  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IExternalDbInfo = await docResponse.json();

  const htmlParser = new DOMParser();
  docData.htmlInfo = htmlParser.parseFromString(docData.html, 'text/html');

  // remove the document naviagtion table
  docData.htmlInfo.querySelector('table.noprint')?.remove();

  // remove database title
  docData.htmlInfo.querySelector('.contentstitle')?.remove();

  // Replace href with new link
  const links = docData.htmlInfo.querySelectorAll('a').forEach(function (link) {
    link.href = link.href.replace('doc/doc_display.php?key=corp|fpsu|', 'corporate-surveys/details/corp|fpsu|');
  });

  return docData;
}

async function getNewIssueDetails (
  docKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<INewIssueInfo> {
  const accessToken = await getAccessToken();

  const docResponse = await fetch(
    `${config.apiUrl}/doc/doc_display_api.php?key=${docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: INewIssueInfo = await docResponse.json();

  const xml = docData.xml;

  docData.xml = xml;
  docData.html = docData.html;

  if (docData.html) {
    const htmlParser = new DOMParser();
    const htmlDoc = htmlParser.parseFromString(docData.html, 'text/html');

    let elements = htmlDoc.getElementsByClassName('noprint');
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }

    elements = htmlDoc.getElementsByClassName('contentstitle');
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }

    elements = htmlDoc.getElementsByTagName('table');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add('normal-table');
    }

    docData.html = htmlDoc.body.innerHTML;
  }

  return docData;
}

async function getCompany (
  companyKey: string,
  getAccessToken: () => Promise<string | null>
): Promise<Company> {
  const accessToken = await getAccessToken();
  const companyResponse = await fetch(
    `${config.apiUrl}/fpsn/sn_display_new_api.php?key=${companyKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const companyData: unknown = await companyResponse.json();
  if (!isCompanyResponse(companyData)) {
    throw new Error('Unexpected company response from api');
  }

  const headOffice =
    companyData.snapshot_data.contactaddresses.contactaddress.find(
      (address) => {
        return address['@attributes'].desc === 'head office';
      }
    );

  const executiveOffice =
    companyData.snapshot_data.contactaddresses.contactaddress.find(
      (address) => {
        return address['@attributes'].desc === 'executive';
      }
    );

  const investorRelations =
    companyData.snapshot_data.contactaddresses.contactaddress.find(
      (address) => {
        return address['@attributes'].desc === 'investor relations';
      }
    );

  let companyDescription: string | undefined;
  if (
    companyData.snapshot_data.companytextrecord &&
    Array.isArray(companyData.snapshot_data.companytextrecord.companytext)
  ) {
    companyDescription = (
      companyData.snapshot_data.companytextrecord
        ?.companytext as ICompanyTextRecord[]
    ).find((b) => b['@attributes'] && b['@attributes'].desc === 'Company Brief')
      ?.coverage.text.p;
  } else {
    companyDescription = (
      [
        companyData.snapshot_data.companytextrecord?.companytext,
      ] as ICompanyTextRecord[]
    ).find(
      (b) => b && b['@attributes'] && b['@attributes'].desc === 'Company Brief'
    )?.coverage.text.p;
  }

  const executivePersons = Array.isArray(
    companyData.snapshot_data.executive?.person
  )
    ? companyData.snapshot_data.executive?.person
    : [companyData.snapshot_data.executive?.person] || [];
  const industryTiers = companyData.snapshot_data.companygics?.tier || [];
  const industryName =
    industryTiers.find((tier) => tier.level === 'sub-industry')?.gics || null;

  const htmlFinancialTables = await applyXslt(
    companyData.xml || '',
    '/xsl/fpsn/wrapper_snapshot_fin_tables.xsl'
  );

  const returnData: Company = {
    name: companyData.company.company_name,
    fpid: companyData.company.fpid,
    description: companyDescription || '',
    address: headOffice
      ? {
          city: headOffice.address.city,
          country: headOffice.address.country,
          line1: headOffice.address.line1,
          postalCode: headOffice.address.postalcode,
          province: headOffice.address.province,
        }
      : null,
    executiveOffice: executiveOffice
      ? {
          city: executiveOffice.address.city,
          country: executiveOffice.address.country,
          line1: executiveOffice.address.line1,
          postalCode: executiveOffice.address.postalcode,
          province: executiveOffice.address.province,
        }
      : null,
    executiveContact: {
      phone: executiveOffice?.contact?.phone
        ? `(${executiveOffice.contact.phone.areacode}) ${executiveOffice.contact.phone.number}`
        : null,
      tollfree: executiveOffice?.contact?.tollfree
        ? `(${executiveOffice.contact.tollfree.areacode}) ${executiveOffice.contact.tollfree.number}`
        : null,
      fax: executiveOffice?.contact?.fax
        ? `(${executiveOffice.contact.fax.areacode}) ${executiveOffice.contact.fax.number}`
        : null,
      webaddress: companyData.snapshot_data.contactaddresses.webaddress || null,
      contactName: combineNames(investorRelations?.contact?.name) || null,
      contact: investorRelations?.contact?.phone
        ? `(${investorRelations.contact.phone?.areacode}) ${investorRelations?.contact.phone.number}`
        : null,
      email: executiveOffice?.contact?.email || null,
    },
    contact: {
      phone: headOffice?.contact?.phone
        ? `(${headOffice.contact.phone.areacode}) ${headOffice.contact.phone.number}`
        : null,
      tollfree: headOffice?.contact?.tollfree
        ? `(${headOffice.contact.tollfree.areacode}) ${headOffice.contact.tollfree.number}`
        : null,
      fax: headOffice?.contact?.fax
        ? `(${headOffice.contact.fax.areacode}) ${headOffice.contact.fax.number}`
        : null,
      webaddress: companyData.snapshot_data.contactaddresses.webaddress || null,
      contactName: combineNames(investorRelations?.contact?.name) || null,
      contact: investorRelations?.contact?.phone
        ? `(${investorRelations.contact.phone?.areacode}) ${investorRelations?.contact.phone.number}`
        : null,
      email: headOffice?.contact?.email || null,
    },
    keyExecutives: executivePersons?.map((person) => ({
      name: combineNames(person),
      address: headOffice
        ? {
            city: headOffice.address.city,
            country: headOffice.address.country,
            line1: headOffice.address.line1,
            postalCode: headOffice.address.postalcode,
            province: headOffice.address.province,
          }
        : null,
    })),

    industry: {
      id: companyData.company.gics,
      name: industryName,
    },
    fp500: {
      year: companyData.snapshot_data.rankingfp500?.rankfp500?.year || null,
      salesRank:
        companyData.snapshot_data.rankingfp500?.rankfp500?.revenuerank || null,
      assetsRank:
        companyData.snapshot_data.rankingfp500?.rankfp500?.assetrank || null,
      incomeRank:
        companyData.snapshot_data.rankingfp500?.rankfp500?.incomerank || null,
    },
    dailysnap: companyData.snapshot_data.dailysnap,
    players: companyData.players,
    // records: parseRecords( companyData.snapshot_data.RECORD ),
    products: companyData.products,
    shortposition: companyData.shortposition,
    htmlFinancialTables,
    __dangerousHTML: companyData.snapshot_html,
  };

  if (companyData.snapshot_data.securityexchangelead) {
    returnData.securityexchangelead = {
      fpid: companyData.snapshot_data.securityexchangelead.fpid,
      fpsid: companyData.snapshot_data.securityexchangelead.fpsid,
      securityexchange: Array.isArray(
        companyData.snapshot_data.securityexchangelead.securityexchange
      )
        ? companyData.snapshot_data.securityexchangelead.securityexchange
        : ([
            companyData.snapshot_data.securityexchangelead.securityexchange,
          ] as Array<ISecurityExchangeInfo>),
    };
  }

  return returnData;
}

export async function getMergersAcquisitionsSearchResults (
  params: {
    companyName?: string;
    searchRestrictTarget?: boolean;
    searchRestrictVendor?: boolean;
    searchRestrictAcquiror?: boolean;
    searchTargetType?: string;
    searchVendorType?: string;
    searchAcquirorType?: string;
    searchTargetFPIndustry?: string;
    searchVendorFPIndustry?: string;
    searchAcquirorFPIndustry?: string;
    searchTargetNAICS?: string;
    searchVendorNAICS?: string;
    searchAcquirorNAICS?: string;
    searchTargetSIC?: string;
    searchVendorSIC?: string;
    searchAcquirorSIC?: string;
    searchTargetGICS?: string;
    searchVendorGICS?: string;
    searchAcquirorGICS?: string;
    searchLocationTarget?: Array<string>;
    searchLocationVendor?: Array<string>;
    searchLocationAcquiror?: Array<string>;
    searchFinancialAdvisor?: string;
    searchLegalAdvisor?: string;
		searchPubliclyTraded?: boolean;
		searchDivestiture?: boolean;
		searchPrivatelyOwned?: boolean;
		searchForeignTarget?: boolean;
    searchDealValueGt?: string;
    searchDealValueLt?: string;
		searchPercentageBoughtGt?: string;
		searchPercentageBoughtLt?: string;
    keywords?: string;
    searchType?: string;
    searchDealDescription?: boolean;
    searchTargetBusinessDescription?: boolean;
    searchVendorBusinessDescription?: boolean;
    searchAcquirorBusinessDescription?: boolean;
		searchPaymentCash?: boolean;
		searchPaymentStock?: boolean;
		searchPaymentAssumptionDebt?: boolean;
		searchPaymentOther?: boolean;
    startDate?: string;
    endDate?: string;
    searchDatesAnnounced?: boolean;
    searchDatesUpdated?: boolean;
    searchDealStatusCompleted?: boolean;
    searchDealStatusPending?: boolean;
    searchDealStatusTerminated?: boolean;
    savedSearchName?: string;
    sort?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    companyName,
    searchRestrictTarget,
    searchRestrictVendor,
    searchRestrictAcquiror,
    searchTargetType,
    searchVendorType,
    searchAcquirorType,
    searchTargetFPIndustry,
    searchVendorFPIndustry,
    searchAcquirorFPIndustry,
    searchTargetNAICS,
    searchVendorNAICS,
    searchAcquirorNAICS,
    searchTargetSIC,
    searchVendorSIC,
    searchAcquirorSIC,
    searchTargetGICS,
    searchVendorGICS,
    searchAcquirorGICS,
    searchLocationTarget,
    searchLocationVendor,
    searchLocationAcquiror,
    searchFinancialAdvisor,
    searchLegalAdvisor,
		searchPubliclyTraded,
		searchDivestiture,
		searchPrivatelyOwned,
		searchForeignTarget,
    searchDealValueGt,
    searchDealValueLt,
		searchPercentageBoughtGt,
		searchPercentageBoughtLt,
    keywords,
    searchType,
    searchDealDescription,
    searchTargetBusinessDescription,
    searchVendorBusinessDescription,
    searchAcquirorBusinessDescription,
		searchPaymentCash,
		searchPaymentStock,
		searchPaymentAssumptionDebt,
		searchPaymentOther,
    startDate,
    endDate,
    searchDatesAnnounced,
    searchDatesUpdated,
    searchDealStatusCompleted,
    searchDealStatusPending,
    searchDealStatusTerminated,
    savedSearchName,
    sort,
    page = 1,
    numberOfRows = '10',
  } = params;

  // if ( ! companyName ) {
  // 	return {
  // 		numResults: 0,
  // 		page: 1,
  // 		results: []
  // 	}
  // }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpma/ma_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('cname', companyName);
  }
  if (searchRestrictTarget) {
    searchUrl.searchParams.append('trest', '1');
  }
  if (searchRestrictVendor) {
    searchUrl.searchParams.append('vrest', '1');
  }
  if (searchRestrictAcquiror) {
    searchUrl.searchParams.append('arest', '1');
  }

  searchUrl.searchParams.append('tinct', searchTargetType || '');
  searchUrl.searchParams.append('vinct', searchVendorType || '');
  searchUrl.searchParams.append('ainct', searchAcquirorType || '');
  searchUrl.searchParams.append('tfpics', searchTargetFPIndustry || '');
  searchUrl.searchParams.append('vfpics', searchVendorFPIndustry || '');
  searchUrl.searchParams.append('afpics', searchAcquirorFPIndustry || '');
  searchUrl.searchParams.append('tnaics', searchTargetNAICS || '');
  searchUrl.searchParams.append('vnaics', searchVendorNAICS || '');
  searchUrl.searchParams.append('anaics', searchAcquirorNAICS || '');
  searchUrl.searchParams.append('tsic', searchTargetSIC || '');
  searchUrl.searchParams.append('vsic', searchVendorSIC || '');
  searchUrl.searchParams.append('asic', searchAcquirorSIC || '');
  searchUrl.searchParams.append('tgics', searchTargetGICS || '');
  searchUrl.searchParams.append('vgics', searchVendorGICS || '');
  searchUrl.searchParams.append('agics', searchAcquirorGICS || '');
  // Location
  searchLocationTarget?.forEach((c) => {
    const location = locationLookup.filter(loc => loc.name === c);
    if (location[0]?.id !== '') {
      searchUrl.searchParams.append('tcountry[]', location[0]?.id);
    }
  });
  searchLocationVendor?.forEach((c) => {
    const location = locationLookup.filter(loc => loc.name === c);
    if (location[0]?.id !== '') {
      searchUrl.searchParams.append('vcountry[]', location[0]?.id);
    }
  });
  searchLocationAcquiror?.forEach((c) => {
    const location = locationLookup.filter(loc => loc.name === c);
    if (location[0]?.id !== '') {
      searchUrl.searchParams.append('acountry[]', location[0]?.id);
    }
  });

  searchUrl.searchParams.append('advisor', searchFinancialAdvisor || '');
  searchUrl.searchParams.append('legaladvisor', searchLegalAdvisor || '');

  // Deal Type
  if (searchPubliclyTraded) {
    searchUrl.searchParams.append('tincpub', '1');
  }
  if (searchDivestiture) {
    searchUrl.searchParams.append('tincdiv', '2');
  }
  if (searchPrivatelyOwned) {
    searchUrl.searchParams.append('tincpri', '3');
  }
  if (searchForeignTarget) {
    searchUrl.searchParams.append('tincfor', '4');
  }

  searchUrl.searchParams.append('deal_gt', searchDealValueGt || '');
  searchUrl.searchParams.append('deal_lt', searchDealValueLt || '');

  // %Bought
  searchUrl.searchParams.append('bought_gt', searchPercentageBoughtGt || '');
  searchUrl.searchParams.append('bought_lt', searchPercentageBoughtLt || '');

  // Payment
  if (searchPaymentCash) {
    searchUrl.searchParams.append('pcash', '1');
  }
  if (searchPaymentStock) {
    searchUrl.searchParams.append('pstock', '1');
  }
  if (searchPaymentAssumptionDebt) {
    searchUrl.searchParams.append('pdebt', '1');
  }
  if (searchPaymentOther) {
    searchUrl.searchParams.append('pother', '1');
  }

  // keywords
  if (keywords) {
    searchUrl.searchParams.append('keyword', keywords);
  }
  searchUrl.searchParams.append('kexact', `${searchType || 'E'}`);

  if (searchDealDescription) {
    searchUrl.searchParams.append('ddesc', '1');
  }
  if (searchTargetBusinessDescription) {
    searchUrl.searchParams.append('tdesc', '1');
  }
  if (searchVendorBusinessDescription) {
    searchUrl.searchParams.append('vdesc', '1');
  }
  if (searchAcquirorBusinessDescription) {
    searchUrl.searchParams.append('adesc', '1');
  }

  if (searchDatesAnnounced) {
    searchUrl.searchParams.append('announced', '1');
  }
  if (searchDatesUpdated) {
    searchUrl.searchParams.append('updated', '1');
  }

  if (startDate) {
    searchUrl.searchParams.append('sm', startDate.substr(5, 2));
  }
  if (startDate) {
    searchUrl.searchParams.append('sd', startDate.substr(8, 2));
  }
  if (startDate) {
    searchUrl.searchParams.append('sy', startDate.substr(0, 4));
  }

  if (endDate) {
    searchUrl.searchParams.append('em', endDate.substr(5, 2));
  }
  if (endDate) {
    searchUrl.searchParams.append('ed', endDate.substr(8, 2));
  }
  if (endDate) {
    searchUrl.searchParams.append('ey', endDate.substr(0, 4));
  }

  if (searchDealStatusPending) {
    searchUrl.searchParams.append('pending', '1');
  }
  if (searchDealStatusCompleted) {
    searchUrl.searchParams.append('completed', '1');
  }
  if (searchDealStatusTerminated) {
    searchUrl.searchParams.append('terminated', '1');
  }

  if (sort) {
    searchUrl.searchParams.append('sort', sort);
  }

  if (savedSearchName) {
    searchUrl.searchParams.append('search_name', savedSearchName);
    searchUrl.searchParams.append('save_search', 'SaveAPI');
  }

  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });
  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
      value: peek.value,
      cdn_value: peek.cdn_value,
      acquirors: peek.acquirors,
      targets: peek.targets,
      vendors: peek.vendors,
      Updated: peek.Updated,
      AnnouncementDate: peek.AnnouncementDate,
      deal_status_description: peek.deal_status_description,
    })),
  };
}

export async function deleteSearch (
  params: {
    id: string
  },
  getAccessToken: () => Promise<string | null>
): Promise<boolean> {
  const { id } = params;

  const accessToken = await getAccessToken();
  const searchUrl = new URL(`${config.apiUrl}/fpni/ni_adv_form.php`);

  const formData = new FormData();
  formData.append('search_id', id);
  formData.append('delete_search', 'Delete');

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
    method: 'POST',
    body: formData,
  });

  const status = await response.status;

  return (status === 200);
}

export async function getNewIssuesSearchResults (
  params: {
    issuerName?: string;
    stockSymbol?: string;
    statusDatesType?: string;
    startDate?: string;
    endDate?: string;
    grossProceedsGt?: string;
    grossProceedsLt?: string;
    leagueTables?: string;
    creditType?: string;
    countBy?: string;
    securityTypes?: string;
    stSelectDeselectCommonEquity?: boolean;
    stSelectDeselectPreferredEquity?: boolean;
    stSelectDeselectStructuredProduct?: boolean;
    stSelectDeselectDebt?: boolean;
    stSelectDeselectIncomeFunds?: boolean;
    stCommon?: boolean;
    stFlowThrough?: boolean;
    stConvertibleDebt?: boolean;
    stPreferredShares?: boolean;
    stPreferredSecurity?: boolean;
    stSplitShares?: boolean;
    stInvestmentTrust?: boolean;
    stFlowThroughLtdPartnership?: boolean;
    stBondDebentureNote?: boolean;
    stMediumTermNote?: boolean;
    stMortgageBond?: boolean;
    stDepositNote?: boolean;
    stSerialDebt?: boolean;
    stAssetBackedSecurities?: boolean;
    stMortgageBackedSecurities?: boolean;
    stCapitalSecurities?: boolean;
    stMapleBond?: boolean;
    stBusinessTrust?: boolean;
    stOilGasRoyaltyTrust?: boolean;
    stREIT?: boolean;
    stPowerPipelineTrust?: boolean;
    stOtherTrust?: boolean;
    stConvertibleTypes?: string;
    distributionType?: string;
    corporateGovernment?: string;
    searchNewIssuesGICS?: Array<string>;
    searchTargetNAICS?: string;
    searchTargetSIC?: string;
    searchUnderwriter?: string;
    optionAsBookrunner?: boolean;
    optionAsLead?: boolean;
    offeringProcedure?: string;
    terms?: string;
    market?: string;
    currency?: string;
    exchange?: string;
    incorporation?: string;
    searchType?: string;
    savedSearchName?: string;
    sort?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    issuerName,
    stockSymbol,
    statusDatesType,
    startDate,
    endDate,
    grossProceedsGt,
    grossProceedsLt,
    leagueTables,
    creditType,
    countBy,
    securityTypes,
    stSelectDeselectCommonEquity,
    stSelectDeselectPreferredEquity,
    stSelectDeselectStructuredProduct,
    stSelectDeselectDebt,
    stSelectDeselectIncomeFunds,
    stCommon,
    stFlowThrough,
    stConvertibleDebt,
    stPreferredShares,
    stPreferredSecurity,
    stSplitShares,
    stInvestmentTrust,
    stFlowThroughLtdPartnership,
    stBondDebentureNote,
    stMediumTermNote,
    stMortgageBond,
    stDepositNote,
    stSerialDebt,
    stAssetBackedSecurities,
    stMortgageBackedSecurities,
    stCapitalSecurities,
    stMapleBond,
    stBusinessTrust,
    stOilGasRoyaltyTrust,
    stREIT,
    stPowerPipelineTrust,
    stOtherTrust,
    stConvertibleTypes,
    distributionType,
    corporateGovernment,
    searchNewIssuesGICS,
    searchTargetNAICS,
    searchTargetSIC,
    searchUnderwriter,
    optionAsBookrunner,
    optionAsLead,
    offeringProcedure,
    terms,
    market,
    currency,
    exchange,
    incorporation,
    searchType,
    savedSearchName,
    sort,
    page = 1,
    numberOfRows = '10',
  } = params;

  // if (!issuerName && !stockSymbol) {
  // 	return {
  // 		numResults: 0,
  // 		page: 1,
  // 		results: []
  // 	}
  // }

  const accessToken = await getAccessToken();
  let apiEndPoint = 'ni_search_api.php'
  if (searchType === 'league') {
    apiEndPoint = 'ni_league_search_api.php';
  }
  const searchUrl = new URL(`${config.apiUrl}/fpni/${apiEndPoint}`);
  if (issuerName) {
    searchUrl.searchParams.append('name', issuerName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('symbol', stockSymbol);
  }
  if (grossProceedsGt) {
    searchUrl.searchParams.append('gross_gt', grossProceedsGt || '');
  }
  if (grossProceedsLt) {
    searchUrl.searchParams.append('gross_lt', grossProceedsLt || '');
  }
  if (searchUnderwriter) {
    searchUrl.searchParams.append('uw', searchUnderwriter);
  }
  if (optionAsBookrunner) {
    searchUrl.searchParams.append('uw_book', 'Y' || '');
  }
  if (optionAsLead) {
    searchUrl.searchParams.append('uw_lead', 'Y' || '');
  }
  if (startDate) {
    searchUrl.searchParams.append('sm', startDate.substr(5, 2));
  }
  if (startDate) {
    searchUrl.searchParams.append('sd', startDate.substr(8, 2));
  }
  if (startDate) {
    searchUrl.searchParams.append('sy', startDate.substr(0, 4));
  }

  if (endDate) {
    searchUrl.searchParams.append('em', endDate.substr(5, 2));
  }
  if (endDate) {
    searchUrl.searchParams.append('ed', endDate.substr(8, 2));
  }
  if (endDate) {
    searchUrl.searchParams.append('ey', endDate.substr(0, 4));
  }
  if (statusDatesType) {
    searchUrl.searchParams.append('status', `${statusDatesType}`);
  }

  const leagueTablesTemp = leagueTables === 'b' ? '' : leagueTables;
  if (leagueTables) {
    searchUrl.searchParams.append('dm_filter', `${leagueTablesTemp}`);
  }
  if (creditType) {
    searchUrl.searchParams.append('credit', `${creditType}`);
  }
  if (countBy) {
    searchUrl.searchParams.append('counting', `${countBy}`);
  }

  let securityTypesTemp = securityTypes === 'all' ? '' : securityTypes;
  if (searchType === 'league') {
    securityTypesTemp = securityTypes;
  }
  if (securityTypes) {
    searchUrl.searchParams.append('sec_check', `${securityTypesTemp}`);
  }

  if (stSelectDeselectCommonEquity) {
    searchUrl.searchParams.append('st[]', 'e_ft+e_cu+e_cd');
  }
  if (stCommon) {
    searchUrl.searchParams.append('st[]', 'e_cu');
  }
  if (stFlowThrough) {
    searchUrl.searchParams.append('st[]', 'e_ft');
  }
  if (stConvertibleDebt) {
    searchUrl.searchParams.append('st[]', 'e_cd');
  }

  if (stSelectDeselectPreferredEquity) {
    searchUrl.searchParams.append('st[]', 'p_sh+p_se');
  }
  if (stPreferredShares) {
    searchUrl.searchParams.append('st[]', 'p_sh');
  }
  if (stPreferredSecurity) {
    searchUrl.searchParams.append('st[]', 'p_se');
  }

  if (stSelectDeselectStructuredProduct) {
    searchUrl.searchParams.append('st[]', 's_ss+s_it+s_ft');
  }
  if (stSplitShares) {
    searchUrl.searchParams.append('st[]', 's_ss');
  }
  if (stInvestmentTrust) {
    searchUrl.searchParams.append('st[]', 's_it');
  }
  if (stFlowThroughLtdPartnership) {
    searchUrl.searchParams.append('st[]', 's_ft');
  }

  if (stSelectDeselectDebt) {
    searchUrl.searchParams.append('st[]', 'd_bd+d_mt+d_mb+d_dn+d_sd+d_as+d_ms+d_cs+d_mab');
  }
  if (stBondDebentureNote) {
    searchUrl.searchParams.append('st[]', 'd_bd');
  }
  if (stMediumTermNote) {
    searchUrl.searchParams.append('st[]', 'd_mt');
  }
  if (stMortgageBond) {
    searchUrl.searchParams.append('st[]', 'd_mb');
  }
  if (stDepositNote) {
    searchUrl.searchParams.append('st[]', 'd_dn');
  }
  if (stSerialDebt) {
    searchUrl.searchParams.append('st[]', 'd_sd');
  }
  if (stAssetBackedSecurities) {
    searchUrl.searchParams.append('st[]', 'd_as');
  }
  if (stMortgageBackedSecurities) {
    searchUrl.searchParams.append('st[]', 'd_ms');
  }
  if (stCapitalSecurities) {
    searchUrl.searchParams.append('st[]', 'd_cs');
  }
  if (stMapleBond) {
    searchUrl.searchParams.append('st[]', 'd_mab');
  }

  if (stSelectDeselectIncomeFunds) {
    searchUrl.searchParams.append('st[]', 'i_bt+i_og+i_re+i_pp+i_ot');
  }
  if (stConvertibleTypes) {
    if (stConvertibleTypes === 'exclude') {
      searchUrl.searchParams.append('st[]', 'i_xt');
    } else if (stConvertibleTypes === 'include') {
      searchUrl.searchParams.append('st[]', 'i_ct');
    }
  }
  if (stBusinessTrust) {
    searchUrl.searchParams.append('st[]', 'i_bt');
  }
  if (stOilGasRoyaltyTrust) {
    searchUrl.searchParams.append('st[]', 'i_og');
  }
  if (stREIT) {
    searchUrl.searchParams.append('st[]', 'i_re');
  }
  if (stPowerPipelineTrust) {
    searchUrl.searchParams.append('st[]', 'i_pp');
  }
  if (stOtherTrust) {
    searchUrl.searchParams.append('st[]', 'i_ot');
  }

  const distributionTypeTemp = distributionType === 'b' ? '' : distributionType;
  if (distributionType) {
     searchUrl.searchParams.append('off_type', `${distributionTypeTemp}`);
  }

  searchUrl.searchParams.append('search_type', `${searchType || 'basic'}`);

  if (statusDatesType) {
    searchUrl.searchParams.append('status', statusDatesType);
  }
  if (corporateGovernment) {
    searchUrl.searchParams.append('gov_type', corporateGovernment || '');
  }
  if (offeringProcedure) {
  searchUrl.searchParams.append('comp_type', offeringProcedure || '');
  }
  if (terms) {
  searchUrl.searchParams.append('terms', terms || '');
  }
  if (market) {
    searchUrl.searchParams.append('market', market || '');
  }
  if (currency) {
    searchUrl.searchParams.append('currency', currency || '');
  }
  if (exchange) {
    searchUrl.searchParams.append('exch', exchange || '');
  }
  if (incorporation) {
    searchUrl.searchParams.append('incorp', incorporation || '');
  }
  searchNewIssuesGICS?.forEach((c) => {
    searchUrl.searchParams.append('gics[]', c);
  });
  if (searchTargetNAICS) {
    searchUrl.searchParams.append('naics', searchTargetNAICS);
  }
  if (searchTargetSIC) {
    searchUrl.searchParams.append('sic', searchTargetSIC);
  }

  if (sort) {
    searchUrl.searchParams.append('sort', sort);
  }

  if (savedSearchName) {
    searchUrl.searchParams.append('search_name', savedSearchName);
    searchUrl.searchParams.append('save_search', 'Save');
  }

  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      lead: peek.lead,
      total_proceeds: peek.total_proceeds,
      announcement_date: peek.announcement_date,
      offering_status_description: peek.offering_status_description,
      offering_status_date: peek.offering_status_date,
      currency_symbol: peek.currency_symbol,
    })),
  };
}

async function getInvestorReportsSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const { companyName, stockSymbol, page = 1, numberOfRows = '10' } = params;

  if (!companyName && !stockSymbol) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpcr/inv_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('name', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('ticker', stockSymbol);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

async function getHistoricalReportsSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const { companyName, stockSymbol, page = 1, numberOfRows = '10' } = params;
  if (!companyName && !stockSymbol) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fphr/hr_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('name', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('ticker', stockSymbol);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
      pdfs: peek.pdfs,
    })),
  };
}

async function getIndustryReportsSearchResults (
  params: { page: number; numberOfRows: number },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const accessToken = await getAccessToken();
  const { page = 1, numberOfRows = '10' } = params;
  const searchUrl = new URL(`${config.apiUrl}/fpir/ir_list_api.php`);
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
      pdfs: peek.pdfs,
    })),
  };
}

export async function getPredecessorDefunctSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    statusActive?: boolean;
    statusInactive?: boolean;
    searchPdFPIndustry?: string;
    searchPdGics?: Array<string>;
    searchPdNaics?: Array<string>;
    searchPdSic?: Array<string>;
    searchPdNameChange?: boolean;
    searchPdMergedNewName?: boolean;
    searchPdMergedSameName?: boolean;
    searchPdMergedAmalgamated?: boolean;
    searchPdPrivatizedNewName?: boolean;
    searchPdPrivatizedAcquired?: boolean;
    searchPdSucceeded?: boolean;
    searchPdBankruptLiquidated?: boolean;
    searchPdCharterCancelled?: boolean;
    searchPdCharterRevived?: boolean;
    searchPdCharterSurrendered?: boolean;
    searchPdInBankruptcy?: boolean;
    searchPdInReceivership?: boolean;
    searchPdRelisted?: boolean;
    searchPdStruckFromRegister?: boolean;
    searchPdUnderCcaa?: boolean;
    searchPdWindingUp?: boolean;
    searchPdWoundUpLiquidated?: boolean;
    searchPdContinuance?: boolean;
    searchPdFormedByAmalgamation?: boolean;
    searchPdSuccessor?: boolean;
    searchPdIncorporationFrom?: Array<string>;
    searchPdIncorporationTo?: Array<string>;
    searchPdInitial?: boolean;
    searchPdCurrent?: boolean;
    naicsCodeType?: string;
    sicCodeType?: string;
    companyHistoryText?: string;
    historyMatchType?: string;
    startDatePd?: string;
    endDatePd?: string;
    savedSearchName?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    companyName,
    stockSymbol,
    statusActive,
    statusInactive,
    searchPdFPIndustry,
    searchPdGics,
    searchPdNaics,
    searchPdSic,
    searchPdNameChange,
    searchPdMergedNewName,
    searchPdMergedSameName,
    searchPdMergedAmalgamated,
    searchPdPrivatizedNewName,
    searchPdPrivatizedAcquired,
    searchPdSucceeded,
    searchPdBankruptLiquidated,
    searchPdCharterCancelled,
    searchPdCharterRevived,
    searchPdCharterSurrendered,
    searchPdInBankruptcy,
    searchPdInReceivership,
    searchPdRelisted,
    searchPdStruckFromRegister,
    searchPdUnderCcaa,
    searchPdWindingUp,
    searchPdWoundUpLiquidated,
    searchPdContinuance,
    searchPdFormedByAmalgamation,
    searchPdSuccessor,
    searchPdIncorporationFrom,
    searchPdIncorporationTo,
    searchPdInitial,
    searchPdCurrent,
    naicsCodeType,
    sicCodeType,
    companyHistoryText,
    historyMatchType,
    startDatePd,
    endDatePd,
    savedSearchName,
    page = 1,
    numberOfRows = '10'
  } = params;

  // if (!companyName && !stockSymbol) {
  //   return {
  //     numResults: 0,
  //     page: 1,
  //     results: [],
  //   };
  // }

  const accessToken = await getAccessToken();
  const searchUrl = new URL(`${config.apiUrl}/fppd/pd_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('name', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('symbol', stockSymbol);
  }
  if (statusActive) {
    searchUrl.searchParams.append('active', '1');
  }
  if (statusInactive) {
    searchUrl.searchParams.append('inactive', '1');
  }
  searchUrl.searchParams.append('fpindustry', searchPdFPIndustry || '');
  searchPdGics?.forEach((c) => {
    searchUrl.searchParams.append('gics[]', c);
  });
  searchPdNaics?.forEach((c) => {
    searchUrl.searchParams.append('naics[]', c);
  });
  searchPdSic?.forEach((c) => {
    searchUrl.searchParams.append('sic[]', c);
  });
  if (searchPdNameChange) {
    searchUrl.searchParams.append('namechange', '1');
  }
  if (searchPdMergedNewName) {
    searchUrl.searchParams.append('mergednewname', '1');
  }
  if (searchPdMergedSameName) {
    searchUrl.searchParams.append('mergedsamename', '1');
  }
  if (searchPdMergedAmalgamated) {
    searchUrl.searchParams.append('mergedamalgamated', '1');
  }
  if (searchPdPrivatizedNewName) {
    searchUrl.searchParams.append('privatizednewname', '1');
  }
  if (searchPdPrivatizedAcquired) {
    searchUrl.searchParams.append('privatizedacquired', '1');
  }
  if (searchPdSucceeded) {
    searchUrl.searchParams.append('succeeded', '1');
  }
  if (searchPdBankruptLiquidated) {
    searchUrl.searchParams.append('bankruptliquidated', '1');
  }
  if (searchPdCharterCancelled) {
    searchUrl.searchParams.append('chartercancelled', '1');
  }
  if (searchPdCharterRevived) {
    searchUrl.searchParams.append('charterrevived', '1');
  }
  if (searchPdCharterSurrendered) {
    searchUrl.searchParams.append('chartersurrendered', '1');
  }
  if (searchPdInBankruptcy) {
    searchUrl.searchParams.append('inbankruptcy', '1');
  }
  if (searchPdInReceivership) {
    searchUrl.searchParams.append('inreceivership', '1');
  }
  if (searchPdRelisted) {
    searchUrl.searchParams.append('relisted', '1');
  }
  if (searchPdStruckFromRegister) {
    searchUrl.searchParams.append('struckfromregister', '1');
  }
  if (searchPdUnderCcaa) {
    searchUrl.searchParams.append('underccaa', '1');
  }
  if (searchPdWindingUp) {
    searchUrl.searchParams.append('windingup', '1');
  }
  if (searchPdWoundUpLiquidated) {
    searchUrl.searchParams.append('woundupliquidated', '1');
  }
  if (searchPdContinuance) {
    searchUrl.searchParams.append('change', '1');
  }
  if (searchPdFormedByAmalgamation) {
    searchUrl.searchParams.append('amalgamation', '1');
  }
  if (searchPdSuccessor) {
    searchUrl.searchParams.append('successor', '1');
  }
  searchPdIncorporationFrom?.forEach((c) => {
    searchUrl.searchParams.append('incorpjurisdictionfrom[]', c);
  });
  if (searchPdInitial) {
    searchUrl.searchParams.append('initial', '1');
  }
  searchPdIncorporationTo?.forEach((c) => {
    searchUrl.searchParams.append('incorpjurisdictionto[]', c);
  });
  if (searchPdCurrent) {
    searchUrl.searchParams.append('current', '1');
  }
  if (naicsCodeType) {
    searchUrl.searchParams.append('naicstype', naicsCodeType);
  }
  if (sicCodeType) {
    searchUrl.searchParams.append('sictype', sicCodeType);
  }
  if (companyHistoryText) {
    searchUrl.searchParams.append('companyhistorytext', companyHistoryText);
  }
  searchUrl.searchParams.append('historymatchtype', `${historyMatchType || 'exactphrase'}`);

  if (startDatePd) {
    searchUrl.searchParams.append('start_month', startDatePd.substr(5, 2));
    searchUrl.searchParams.append('start_day', startDatePd.substr(8, 2));
    searchUrl.searchParams.append('start_year', startDatePd.substr(0, 4));
  }
  if (endDatePd) {
    searchUrl.searchParams.append('end_month', endDatePd.substr(5, 2));
    searchUrl.searchParams.append('end_day', endDatePd.substr(8, 2));
    searchUrl.searchParams.append('end_year', endDatePd.substr(0, 4));
  }
  if (savedSearchName) {
    searchUrl.searchParams.append('search_name', savedSearchName);
    searchUrl.searchParams.append('save_search', 'Save');
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));
  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  }).catch(err => {
    console.log(err);
  });
  // const data: unknown = await response.json();
  let data = {};
  if (response) {
    data = await response.json()
      .catch(err => {
        console.log(err);
      });
  }
  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

async function getExternalDatabasesSearchResults (
  params: {
    dbCode: string;
    contactType?: string;
    companyName?: string;
    searchType?: string;
    legalType?: string;
    legalRecordType?: string;
    city?: string;
    address?: string;
    provinces?: string;
    directorName?: string;
    organizationType?: string;
    areaCode?: string;
    sicCode?: string;
    naicsCode?: string;
    employeesGT?: string;
    employeesLT?: string;
    membersGT?: string;
    membersLT?: string;
    sortOption?: string;
    locationType?: string;
    companyType?: string;
    salesVolumeLT?: string;
    salesVolumeGT?: string;
    cengageCompanyType?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<IExternalDbResults> {
  const {
    dbCode,
    contactType,
    companyName,
    searchType,
    legalType,
    legalRecordType,
    city,
    address,
    provinces,
    directorName,
    organizationType,
    areaCode,
    sicCode,
    naicsCode,
    employeesGT,
    employeesLT,
    membersGT,
    membersLT,
    sortOption,
    locationType,
    companyType,
    salesVolumeLT,
    salesVolumeGT,
    cengageCompanyType,
    page = 1,
    numberOfRows = 200,
  } = params;

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/doss/add_search_api.php`);

  const shortDbCode = externalDbBySlug(params.dbCode)?.code || '';

  if (companyName) {
    searchUrl.searchParams.append('cname', `${companyName}`);
  }
  if (contactType) {
    searchUrl.searchParams.append('ctype', `${contactType}`);
  }
  if (searchType) {
    searchUrl.searchParams.append(
      'cexact',
      searchType === 'all-words' ? 'all' : 'phrase'
    );
  }
  if (legalType) {
    searchUrl.searchParams.append('coption', `${legalType}`);
  }
  if (legalRecordType) {
    searchUrl.searchParams.append(
      `${shortDbCode}_rcdtype`,
      `${legalRecordType}`
    );
  }
  if (directorName) {
    searchUrl.searchParams.append('person', `${directorName}`);
  }
  if (organizationType) {
    searchUrl.searchParams.append('orgtype', `${organizationType}`);
  }
  if (organizationType) {
    searchUrl.searchParams.append(
      `${shortDbCode}_inctype`,
      `${organizationType}`
    );
  }
  if (address) {
    searchUrl.searchParams.append('address', `${address}`);
  }
  if (city) {
    searchUrl.searchParams.append('city', `${city}`);
  }
  if (provinces) {
    searchUrl.searchParams.append('provs[]', `${provinces}`);
  }
  if (areaCode) {
    searchUrl.searchParams.append('area', `${areaCode}`);
  }
  if (sicCode) {
    searchUrl.searchParams.append('sic', `${sicCode}`);
  }
  if (naicsCode) {
    searchUrl.searchParams.append('naics', `${naicsCode}`);
  }
  if (employeesGT) {
    searchUrl.searchParams.append(`${shortDbCode}_emp_gt`, `${employeesGT}`);
  }
  if (employeesLT) {
    searchUrl.searchParams.append(`${shortDbCode}_emp_lt`, `${employeesLT}`);
  }
  if (membersGT) {
    searchUrl.searchParams.append(`${shortDbCode}_mem_gt`, `${membersGT}`);
  }
  if (membersLT) {
    searchUrl.searchParams.append(`${shortDbCode}_mem_lt`, `${membersLT}`);
  }
  if (sortOption) {
    searchUrl.searchParams.append('sortoption', `${sortOption}`);
  }

  if (locationType) {
    searchUrl.searchParams.append(`${shortDbCode}_loctype`, `${locationType}`);
  }
  if (companyType) {
    searchUrl.searchParams.append(`${shortDbCode}_loctype`, `${companyType}`);
  }
  if (cengageCompanyType) {
    searchUrl.searchParams.append('companyType', `${cengageCompanyType}`);
  }
  if (salesVolumeLT) {
    searchUrl.searchParams.append('sales_lt', `${salesVolumeLT}`);
  }
  if (salesVolumeGT) {
    searchUrl.searchParams.append('sales_gt', `${salesVolumeGT}`);
  }

  searchUrl.searchParams.append('db', `${shortDbCode}`);
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', `${numberOfRows}`);

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isExternalDbResults(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return data;
}

async function getLeadListGeneratorSearchResults (
  params: {
    sicCodeType?: string;
    auditor?: string;
    transferAgent?: string;
    lawyer?: string;
    banker?: string;
    idxSPTSXComp?: string;
    idxSPTSX60?: string;
    idxTSX30?: string;
    idxTSXVenTier1?: string;
    idxTSXVenTier2?: string;
    idxTSXVenTier3?: string;
    idxFP500?: string;
    idxCleantech?: string;
    exchangeListed?: string;
    exchangeSuspended?: string;
    idxAll?: string;
    normalCourseIssuerBid?: string;
    substantialIssuerBid?: string;
    dividendReinvestmentPlan?: string;
    majorShareholder?: string;
    country?: string;
    contacttype?: string;
    dbCode: string;
    companyName?: string;
    city?: string;
    address?: string;
    provinces?: string;
    exchange?: string;
    areaCode?: string;
    locationType?: string;
    companyType?: string;
    companySubType?: string;
    jurisdiction?: string;
    sicCodes?: string;
    gicCodes?: string;
    employeesGT?: string;
    employeesLT?: string;
    companyNameType?: string;
    position?: string;
    gender?: string;
    school?: string;
    degree?: string;
    speciality?: string;
    fellowship?: string;
    sicCode?: string;
    companyTypes?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<ILeadListResults> {
  const {
    sicCodeType,
    auditor,
    transferAgent,
    lawyer,
    banker,
    idxSPTSXComp,
    idxSPTSX60,
    idxTSX30,
    idxTSXVenTier1,
    idxTSXVenTier2,
    idxTSXVenTier3,
    idxFP500,
    idxCleantech,
    exchangeListed,
    exchangeSuspended,
    normalCourseIssuerBid,
    substantialIssuerBid,
    dividendReinvestmentPlan,
    majorShareholder,
    country,
    contacttype,
    dbCode,
    companyName,
    city,
    address,
    provinces,
    exchange,
    areaCode,
    locationType,
    companyType,
    companySubType,
    jurisdiction,
    sicCodes,
    gicCodes,
    employeesGT,
    employeesLT,
    companyNameType,
    position,
    gender,
    school,
    degree,
    speciality,
    fellowship,
    companyTypes,
    sicCode,
    page = 1,
    numberOfRows = '10',
  } = params;
  const accessToken = await getAccessToken();
  const searchUrl = new URL(`${config.apiUrl}/doss/lead_search_api.php`);

  let shortDbCode = '';
  switch (dbCode) {
    case 'fp-corporate-surveys':
      shortDbCode = 'fpsu';
      break;
    case 'fp-directory-of-directors':
      shortDbCode = 'fpdd';
      break;
    case 'pro-file-canada':
      shortDbCode = 'dpro';
      break;
    case 'encyclopedia-of-associations':
      shortDbCode = 'tmga';
      break;
    case 'wards-business-directory':
      shortDbCode = 'tmgw';
      break;
  }

  let genderCode = '';
  if (gender === 'Male') {
    genderCode = 'M';
  }
  if (gender === 'Female') {
    genderCode = 'F';
  }

  let companyNameTypeCode = '';
  if (companyNameType === 'current-position') {
    companyNameTypeCode = 'cp';
  }
  if (companyNameType === 'previous-position') {
    companyNameTypeCode = 'pp';
  }

  if (contacttype) {
    searchUrl.searchParams.append('contacttype', `${contacttype}`);
  }
  searchUrl.searchParams.append('city', city ? `${city}` : '');
  country?.split(',').forEach((c) => {
    searchUrl.searchParams.append('country[]', c);
  });
  searchUrl.searchParams.append('area', areaCode ? `${areaCode}` : '');
  if (majorShareholder) {
    searchUrl.searchParams.append('shareholder', `${majorShareholder}`);
  }
  if (idxSPTSXComp) {
    searchUrl.searchParams.append('sp_composite', `${idxSPTSXComp}`);
  }
  if (idxSPTSX60) {
    searchUrl.searchParams.append('sp_60', `${idxSPTSX60}`);
  }
  if (idxTSX30) {
    searchUrl.searchParams.append('tsx_30', `${idxTSX30}`);
  }
  if (idxTSXVenTier1) {
    searchUrl.searchParams.append('ventier1', `${idxTSXVenTier1}`);
  }
  if (idxTSXVenTier2) {
    searchUrl.searchParams.append('ventier2', `${idxTSXVenTier2}`);
  }
  if (idxTSXVenTier3) {
    searchUrl.searchParams.append('ventier3', `${idxTSXVenTier3}`);
  }
  if (idxFP500) {
    searchUrl.searchParams.append('fp500', `${idxFP500}`);
  }
  if (idxCleantech) {
    searchUrl.searchParams.append('cleantech', `${idxCleantech}`);
  }
  if (exchangeListed) {
    searchUrl.searchParams.append('exch_listed', `${exchangeListed}`);
  }
  if (exchangeSuspended) {
    searchUrl.searchParams.append('exch_suspended', `${exchangeSuspended}`);
  }
  if (normalCourseIssuerBid) {
    searchUrl.searchParams.append('ncib', `${normalCourseIssuerBid}`);
  }
  if (substantialIssuerBid) {
    searchUrl.searchParams.append('sib', `${substantialIssuerBid}`);
  }
  if (sicCode) {
    searchUrl.searchParams.append('sicCode', `${sicCode}`);
  }
  if (dividendReinvestmentPlan) {
    searchUrl.searchParams.append('reinvest', `${dividendReinvestmentPlan}`);
  }
  auditor?.split(',').forEach((c) => {
    searchUrl.searchParams.append('auditor', c);
  });
  banker?.split(',').forEach((c) => {
    searchUrl.searchParams.append('banker', c);
  });
  lawyer?.split(',').forEach((c) => {
    searchUrl.searchParams.append('lawyer', c);
  });
  transferAgent?.split(',').forEach((c) => {
    searchUrl.searchParams.append('transferAgent', c);
  });
  searchUrl.searchParams.append('sictype', `${sicCodeType}`);
  if (companyName) {
    searchUrl.searchParams.append('cname', `${companyName}`);
  }
  if (address) {
    searchUrl.searchParams.append(`${shortDbCode}_address`, `${address}`);
  }
  if (locationType) {
    searchUrl.searchParams.append(`${shortDbCode}_loctype`, `${locationType}`);
  }
  if (employeesGT) {
    searchUrl.searchParams.append(`${shortDbCode}_emp_gt`, `${employeesGT}`);
  }
  if (employeesLT) {
    searchUrl.searchParams.append(`${shortDbCode}_emp_lt`, `${employeesLT}`);
  }
  if (transferAgent) {
    searchUrl.searchParams.append('transferAgent', `${transferAgent}`);
  }

  exchange?.split(',').forEach((c) => {
    searchUrl.searchParams.append('exch[]', c);
  });
  provinces?.split(',').forEach((c) => {
    searchUrl.searchParams.append('provs[]', c);
  });
  provinces?.split(',').forEach((c) => {
    searchUrl.searchParams.append('canprov[]', c);
  });
  // if (provinces) { searchUrl.searchParams.append('provs[]', `${provinces}`) }
  // if (provinces) { searchUrl.searchParams.append('canprov[]', `${provinces}`) }
  // if (companyType) { searchUrl.searchParams.append(`${shortDbCode}_inctype`, `${companyType}`) }
  companyType?.split(',').forEach((c) => {
    searchUrl.searchParams.append('ctype[]', c);
  });

  sicCodes?.split(',').forEach((c) => {
    searchUrl.searchParams.append('sic[]', c);
  });

  gicCodes?.split(',').forEach((c) => {
    searchUrl.searchParams.append('gics[]', c);
  });

  companySubType?.split(',').forEach((c) => {
    searchUrl.searchParams.append('csubtype[]', c);
  });

  jurisdiction?.split(',').forEach((c) => {
    searchUrl.searchParams.append('incorpjuris', c);
  });

  // fpdd
  if (companyNameType) {
    searchUrl.searchParams.append('ptype', `${companyNameTypeCode}`);
  }
  if (companyTypes) {
    searchUrl.searchParams.append('ptype', `${companyTypes}`);
  }
  if (position) {
    searchUrl.searchParams.append('position', `${position}`);
  }
  if (gender) {
    searchUrl.searchParams.append('gender', `${genderCode}`);
  }
  if (school) {
    searchUrl.searchParams.append('school[]', `${school}`);
  }
  if (degree) {
    searchUrl.searchParams.append('degree[]', `${degree}`);
  }
  if (speciality) {
    searchUrl.searchParams.append('specialty[]', `${speciality}`);
  }
  if (fellowship) {
    searchUrl.searchParams.append('association[]', `${fellowship}`);
  }

  searchUrl.searchParams.append('db', `${shortDbCode}`);
  searchUrl.searchParams.append('source', 'lead');
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isLeadResults(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return data;
}

async function getDividendsSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const { companyName, stockSymbol, page = 1, numberOfRows = '10' } = params;

  if (!companyName && !stockSymbol) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpdv/dv_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('name', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('ticker', stockSymbol);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

export async function getFixedIncomeSearchResults (
  params: {
    issuerName?: string;
    stockSymbol?: string;
    issueType?: string;
    termsOfIssue?: string;
    market?: string;
    currency?: string;
    incorporation?: string;
    gics?: Array<string>;
    naic?: string;
    sic?: string;
    amountIssuedGT?: string;
    amountIssuedLT?: string;
    debtMaturity?: string;
    dbrsRatingLT?: string;
    dbrsRatingGT?: string;
    couponFeature?: string;
    couponPrcntMin?: string;
    couponPrcntMax?: string;
    optionCallable?: boolean;
    optionConvertible?: boolean;
    optionChangeControl?: boolean;
    dateInfo?: string;
    savedSearchName?: string;
    sort?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    issuerName,
    stockSymbol,
    issueType,
    termsOfIssue,
    market,
    currency,
    incorporation,
    gics,
    naic,
    sic,
    amountIssuedGT,
    amountIssuedLT,
    debtMaturity,
    dbrsRatingLT,
    dbrsRatingGT,
    couponFeature,
    couponPrcntMin,
    couponPrcntMax,
    optionCallable,
    optionConvertible,
    optionChangeControl,
    dateInfo,
    savedSearchName,
    sort,
    page = 1,
    numberOfRows = '10',
  } = params;

  const issueTypeChar: string =
    issueType && issueType.length > 0 ? issueType[0] : '';

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpfi/fi_search_api.php`);
  if (issuerName) {
    searchUrl.searchParams.append('name', issuerName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('symbol', stockSymbol);
  }
  if (issueType) {
    searchUrl.searchParams.append('dp', issueTypeChar);
  }
  if (termsOfIssue) {
    searchUrl.searchParams.append('terms_issue', termsOfIssue);
  }
  if (market) {
    searchUrl.searchParams.append('market', market);
  }
  if (currency) {
    searchUrl.searchParams.append('currency', currency);
  }
  if (incorporation) {
    searchUrl.searchParams.append('incorp', incorporation);
  }
  gics?.forEach((c) => {
    searchUrl.searchParams.append('gics[]', c);
  });
  if (naic) {
    searchUrl.searchParams.append('naics', naic);
  }
  if (sic) {
    searchUrl.searchParams.append('sic', sic);
  }
  if (amountIssuedGT) {
    searchUrl.searchParams.append('amount_gt', amountIssuedGT);
  }
  if (amountIssuedLT) {
    searchUrl.searchParams.append('amount_lt', amountIssuedLT);
  }
  if (debtMaturity) {
    searchUrl.searchParams.append('maturity', debtMaturity);
  }
  if (dbrsRatingLT) {
    searchUrl.searchParams.append('rating_lt', dbrsRatingLT);
  }
  if (dbrsRatingGT) {
    searchUrl.searchParams.append('rating_gt', dbrsRatingGT);
  }
  if (couponFeature) {
    searchUrl.searchParams.append('coupon', couponFeature);
  }
  if (couponPrcntMin) {
    searchUrl.searchParams.append('coupon_per_min', couponPrcntMin);
  }
  if (couponPrcntMax) {
    searchUrl.searchParams.append('coupon_per_max', couponPrcntMax);
  }
  if (optionCallable) {
    searchUrl.searchParams.append('call', 'y');
  }
  if (optionConvertible) {
    searchUrl.searchParams.append('convert', 'y');
  }
  if (optionChangeControl) {
    searchUrl.searchParams.append('change', 'y');
  }
  if (sort) {
    searchUrl.searchParams.append('sort', sort);
  }
  if (savedSearchName) {
    searchUrl.searchParams.append('search_name', savedSearchName);
    searchUrl.searchParams.append('save_search', 'SaveAPI');
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      lead: peek.lead,
      amount: peek.amount,
      maturitydate: peek.maturitydate,
    })),
  };
}

async function getDirectorsSearchResults (
  params: {
    personName?: string;
    companyName?: string;
    companySearchType?: string;
    position?: string;
    gender?: string;
    companyTypePublic?: string;
    companyTypePrivate?: string;
    companyTypeFP500?: string;
    companyTypeNotForProfit?: string;
    targetFPIndustry?: string;
    deptHeads?: string;
    gicCodes?: string;
    sicCodes?: string;
    sicPrimary?: string;
    naicsCodes?: string;
    naicsPrimary?: string;
    city?: string;
    provinces?: Array<string>;
    countries?: Array<string>;
    school?: Array<string>;
    degree?: Array<string>;
    speciality?: Array<string>;
    fellowship?: Array<string>;
    dateInfo?: string;
    sort?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    personName,
    companyName,
    companySearchType,
    position,
    gender,
    companyTypePublic,
    companyTypePrivate,
    companyTypeFP500,
    companyTypeNotForProfit,
    targetFPIndustry,
    deptHeads,
    gicCodes,
    sicCodes,
    sicPrimary,
    naicsCodes,
    naicsPrimary,
    city,
    provinces,
    countries,
    school,
    degree,
    speciality,
    fellowship,
    sort,
    page = 1,
    numberOfRows = '10',
  } = params;

  // if (!personName && !companyName) {
  //   return {
  //     numResults: 0,
  //     page: 1,
  //     results: [],
  //   };
  // }

  let pType: string = '';
  switch (companySearchType) {
    case 'lead-company':
      pType = 'lp';
      break;
    case 'current-company':
      pType = 'cp';
      break;
    case 'previous-company':
      pType = 'pp';
      break;
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpdd/dd_search_api.php`);
  if (personName) {
    searchUrl.searchParams.append('person', personName);
  }
  if (companyName) {
    searchUrl.searchParams.append('cname', companyName);
  }
  if (companySearchType) {
    searchUrl.searchParams.append('ptype', pType);
  }

  if (sort) {
    searchUrl.searchParams.append('sort', sort);
  }

  if (position) {
    position.split(',').forEach(p => {
      searchUrl.searchParams.append('position[]', p);
    });
  }
  if (gender) {
    searchUrl.searchParams.append('gender', gender);
  }
  if (companyTypePublic) {
    searchUrl.searchParams.append('public', '1');
  }
  if (companyTypePrivate) {
    searchUrl.searchParams.append('private', '1');
  }
  if (companyTypeFP500) {
    searchUrl.searchParams.append('fp500', '1');
  }
  if (companyTypeNotForProfit) {
    searchUrl.searchParams.append('np', '1');
  }
  if (targetFPIndustry) {
    searchUrl.searchParams.append('fpics', targetFPIndustry);
  }
  if (deptHeads) {
    searchUrl.searchParams.append('depthead', deptHeads);
  }
  if (gicCodes) {
    searchUrl.searchParams.append('gics[]', gicCodes);
  }
  if (sicCodes) {
    searchUrl.searchParams.append('sic[]', sicCodes);
  }
  if (sicPrimary) {
    searchUrl.searchParams.append('sictype', sicPrimary === 'primary' ? '1' : '0');
  }
  if (naicsCodes) {
    searchUrl.searchParams.append('naics[]', naicsCodes);
  }
  if (naicsPrimary) {
    searchUrl.searchParams.append('naicstype', naicsPrimary === 'primary' ? '1' : '0');
  }
  if (city) {
    searchUrl.searchParams.append('city', city);
  }
  provinces?.forEach((c) => {
    const province = provincesLookup.filter(prov => prov.name === c);
    if (province[0]?.id !== '') {
      searchUrl.searchParams.append('canprov[]', province[0]?.id);
    }
  });
  countries?.forEach((c) => {
    const location = countriesLookup.filter(loc => loc.name === c);
    if (location[0]?.id !== '') {
      searchUrl.searchParams.append('country[]', location[0]?.id);
    }
  });
  school?.forEach((c) => {
    const sch = schoolsLookup.filter(s => s.name.replaceAll(',', '&#44;') === c);
    if (sch[0]?.id !== '') {
      searchUrl.searchParams.append('school[]', sch[0]?.id);
    }
  });
  degree?.forEach((c) => {
    const deg = degreesLookup.filter(d => d.name.replaceAll(',', '&#44;') === c);
    if (deg[0]?.id !== '') {
      searchUrl.searchParams.append('degree[]', deg[0]?.id);
    }
  });
  speciality?.forEach((c) => {
    const spec = specialtiesLookup.filter(s => s.name.replaceAll(',', '&#44;') === c);
    if (spec[0]?.id !== '') {
      searchUrl.searchParams.append('specialty[]', spec[0]?.id);
    }
  });
  fellowship?.forEach((c) => {
    const fell = fellowshipsLookup.filter(f => f.name.replaceAll(',', '&#44;') === c);
    if (fell[0]?.id !== '') {
      searchUrl.searchParams.append('association[]', fell[0]?.id);
    }
  });

  searchUrl.searchParams.append('source', 'advanced');

  // searchUrl.searchParams.append('source', 'basic');
  searchUrl.searchParams.append('cexact', 'all');
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));
  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });
  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      lead: peek.lead,
      position: peek.position,
    })),
  };
}

async function getDirectorsConnectorsSearchResults (
  params: {
		personName?: string;
		dateInfo?: string;
    sort?: string;
		page: number;
		numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    personName,
    sort,
    page = 1,
    numberOfRows = '10',
  } = params;

  if (!personName) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpdd/dd_connect_search_api.php`);
  if (personName) {
    searchUrl.searchParams.append('person', personName);
  }

  // searchUrl.searchParams.append('source', 'basic');
  searchUrl.searchParams.append('cexact', 'all');
  if (sort) {
    searchUrl.searchParams.append('sort', sort);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });
  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
			fppid: peek.fppid,
      key: peek.unique_key,
      name: peek.headline,
      lead: peek.lead,
      position: peek.position,
    })),
  };
}

async function getDirectorsConnectorsDetails (
	params: { docKey: string },
  getAccessToken: () => Promise<string | null>
): Promise<IPersonConnectorDetails> {
  const accessToken = await getAccessToken();
  const docResponse = await fetch(
    `${config.apiUrl}/fpdd/dd_connectors_api.php?fppid=${params.docKey}`,
    {
      headers: accessToken
        ? {
            AUTHTOKEN: accessToken,
          }
        : {},
    }
  );

  const docData: IPersonConnectorDetails = await docResponse.json();

	if (docData?.person_info.data.Gender) {
		const genderString = docData?.person_info.data.Gender.toString().trim().toLowerCase();
		switch (genderString) {
			case 'female':
				docData.person_info.data.Gender = 'Female'; break;
			case 'male':
				docData.person_info.data.Gender = 'Male'; break;
		}
	}

	return docData;
}

async function getPersonSearchResults (
  params: {
    searchText: string;
    dbs: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<PersonSearchResults> {
  const { searchText, dbs, page = 1, numberOfRows = '10' } = params;

  if (searchText === '') {
    return {
      dbResults: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/doss/person_search_api.php`);
  if (dbs) {
  dbs.split(',').map((db) => {
    searchUrl.searchParams.append('dbs[]', db);
  });
}

  if (searchText) {
    searchUrl.searchParams.append('person', searchText);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  // const response = await fetch(`${config.apiUrl}/doss/person_search_api.php?person=${searchText}&page=${page}&delta=10&&dbs[]=fpsu&dbs[]=fpdd&dbs[]=dcfc&dbs[]=dcti&dbs[]=dpro&dbs[]=tmga&dbs[]=tmgw`, {
  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();

  if (!isPersonSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  const results = {} as PersonSearchResults;
  results.dbResults = [];
  if (data.fpsu) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('fpsu');

    if (db) {
      dbResult.database = db;
    }
    dbResult.results = {
      numResults: data.fpsu.hit_count,
      page,
      results: (data.fpsu.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        formerNames: peek.former_names,
        symbols: peek.symbols,
        coverageStatus: peek.status_description,
      })),
    };

    results.dbResults.push(dbResult);
  }

  if (data.fpdd) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('fpdd');
    if (db) {
      dbResult.database = db;
    }
    dbResult.results = {
      numResults: data.fpdd.hit_count,
      columns: ['Person Name', 'Company Name'],
      page,
      results: (data.fpdd.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        lead: peek.lead,
      })),
    };

    results.dbResults.push(dbResult);
  }

  if (data.dcfc) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('dcfc');
    if (db) {
      dbResult.database = db;
    }

    dbResult.results = {
      numResults: data.dcfc.hit_count,
      columns: ['Company Name', 'Location'],
      page,
      results: (data.dcfc.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        lead: peek.lead,
      })),
    };

    results.dbResults.push(dbResult);
  }

  if (data.dcti) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('dcti');
    if (db) {
      dbResult.database = db;
    }

    dbResult.results = {
      numResults: data.dcti.hit_count,
      page,
      columns: ['Company Name', 'Location'],
      results: (data.dcti.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        lead: peek.lead,
      })),
    };

    results.dbResults.push(dbResult);
  }

  if (data.tmga) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('tmga');
    if (db) {
      dbResult.database = db;
    }

    dbResult.results = {
      numResults: data.tmga.hit_count,
      columns: ['Company Name', 'City'],
      page,
      results: (data.tmga.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        lead: peek.lead,
      })),
    };
    results.dbResults.push(dbResult);
  }

  if (data.dpro) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('dpro');
    if (db) {
      dbResult.database = db;
    }

    dbResult.results = {
      numResults: data.dpro.hit_count,
      page,
      columns: ['Company Name', 'Location'],
      results: (data.dpro.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        formerNames: peek.former_names,
        symbols: peek.symbols,
        coverageStatus: peek.status_description,
      })),
    };
    results.dbResults.push(dbResult);
  }

  if (data.tmgw) {
    const dbResult = {} as PersonSearchDbResult;
    const db = searchDbByCode('tmgw');
    if (db) {
      dbResult.database = db;
    }

    dbResult.results = {
      numResults: data.tmgw.hit_count,
      columns: ['Company Name', 'City'],
      page,
      results: (data.tmgw.peeks || []).map((peek) => ({
        id: peek.fpid,
        key: peek.unique_key,
        name: peek.headline,
        formerNames: peek.former_names,
        symbols: peek.symbols,
        coverageStatus: peek.status_description,
      })),
    };
    results.dbResults.push(dbResult);
  }

  return results;
}

async function getCorpateAnalyzerSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    city?: string;
    provinces?: Array<string>;
    countries?: Array<String>;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    companyName,
    stockSymbol,
    city,
    provinces,
    countries,
    page = 1,
    numberOfRows = '10',
  } = params;

  if (!companyName && !stockSymbol && !city && !provinces) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }

  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpan/an_basic_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('company', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('ticker', stockSymbol);
  }
  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });
  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  if (data.key && (!data.peeks || data.peeks.length === 0)) {
    const companyResponse = await fetch(
      `${config.apiUrl}/fpsn/sn_display_new_api.php?key=${data.key}`
    );
    const companyData: unknown = await companyResponse.json();
    if (!isCompanyResponse(companyData)) {
      throw new Error('Unexpected company response from api');
    }

    return {
      numResults: 1,
      page,
      results: [
        {
          id: companyData.company.fpid,
          name: companyData.company.company_name,
          key: data.key,
          formerNames: [],
          symbols: [],
          coverageStatus: null,
        },
      ],
    };
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

export async function getCorporateSurveysSearchResults (
  params: {
    searchForm: string,
    companyName?: string,
    stockSymbol?: string,
    city?: string,
    provinces?: string,
    country?: string,
    searchType?: string,
    searchPredecessor?: string,
    exchange?: string,
    exchangeListed?: string,
    exchangeSuspended?: string,
    contacttype?: string,
    areaCode?: string,
    companyType?: string,
    companySubType?: string,
    jurisdiction?: string,
    incorporationYear?: string,
    majorShareholder?: string,
    directorOfficerName?: string,
    optShareholderInterest10Prcnt?: string,
    optForeignOwnership10Prcnt?: string,
    subsidiary?: string,
    auditor?: string,
    banker?: string,
    lawyer?: string,
    transferAgent?: string,
    idxSPTSXComp?: string,
    idxSPTSX60?: string,
    idxTSX30?: string,
    idxTSXVenTier1?: string,
    idxTSXVenTier2?: string,
    idxTSXVenTier3?: string,
    idxFP500?: string,
    idxCleantech?: string,
    idxCannabis?: string,
    idxBlockchain?: string,
    sicCodes?: string,
    sicCodeType?: string,
    gicCodes?: string,
    naicsCodes?: string,
    naicsCodeType?: string,
    normalCourseIssuerBid?: string,
    substantialIssuerBid?: string,
    dividendReinvestmentPlan?: string,

    textSearch?: string,
    finSearchSortOpt?: string,
    finSearchSortDirection?: string,

    category1?: string,
    dataItem1?: string,
    year1?: string,
    boolOp1?: string,
    operation1?: string,
    value1?: string,

    category2?: string,
    dataItem2?: string,
    year2?: string,
    boolOp2?: string,
    operation2?: string,
    value2?: string,

    category3?: string,
    dataItem3?: string,
    year3?: string,
    boolOp3?: string,
    operation3?: string,
    value3?: string,

    category4?: string,
    dataItem4?: string,
    year4?: string,
    boolOp4?: string,
    operation4?: string,
    value4?: string,

    category5?: string,
    dataItem5?: string,
    year5?: string,
    boolOp5?: string,
    operation5?: string,
    value5?: string,

    dateInfo?: string;
    db?: string;
    savedSearchName?: string;
    page: number;
    numberOfRows: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    searchForm,
    companyName,
    stockSymbol,
    city,
    provinces,
    country,
    searchType,
    searchPredecessor,
    exchange,
    exchangeListed,
    exchangeSuspended,
    contacttype,
    sicCodeType,
    areaCode,
    companyType,
    companySubType,
    jurisdiction,
    incorporationYear,
    majorShareholder,
    directorOfficerName,
    optShareholderInterest10Prcnt,
    optForeignOwnership10Prcnt,
    subsidiary,
    auditor,
    banker,
    lawyer,
    transferAgent,
    idxSPTSXComp,
    idxSPTSX60,
    idxTSX30,
    idxTSXVenTier1,
    idxTSXVenTier2,
    idxTSXVenTier3,
    idxFP500,
    idxCleantech,
    idxCannabis,
    idxBlockchain,
    sicCodes,
    gicCodes,
    naicsCodes,
    naicsCodeType,
    normalCourseIssuerBid,
    substantialIssuerBid,
    dividendReinvestmentPlan,

    textSearch,
    finSearchSortOpt,
    finSearchSortDirection,

    category1,
    dataItem1,
    year1,
    boolOp1,
    operation1,
    value1,

    category2,
    dataItem2,
    year2,
    boolOp2,
    operation2,
    value2,

    category3,
    dataItem3,
    year3,
    boolOp3,
    operation3,
    value3,

    category4,
    dataItem4,
    year4,
    boolOp4,
    operation4,
    value4,

    category5,
    dataItem5,
    year5,
    boolOp5,
    operation5,
    value5,

    dateInfo,
    db = 'fpsu',
    savedSearchName,
    page = 1,
    numberOfRows = '10',
  } = params;

  const accessToken = await getAccessToken();

  const isAdvanced = (searchForm === 'advanced');

  const endpointAction = isAdvanced ? 'su_search_api' : 'su_basic_search_api';

  const searchUrl = new URL(`${config.apiUrl}/fpsu/${endpointAction}.php`);
  if (isAdvanced) {
    searchUrl.searchParams.append('product', 'FPSurveyAdvanced');
  }
  if (companyName) {
    searchUrl.searchParams.append('cname', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('bticker', stockSymbol);
  }
  if (city) {
    searchUrl.searchParams.append('city', city);
  }
  provinces?.split(',').forEach((c) => {
    searchUrl.searchParams.append('prov[]', c);
  });
  country?.split(',').forEach((c) => {
    searchUrl.searchParams.append('country[]', c);
  });
  if (searchPredecessor === 'true') {
    searchUrl.searchParams.append('coption', 'Y');
  }
  if (searchType) {
    searchUrl.searchParams.append('cexact', searchType === 'exact-phrase' ? 'phrase' : 'all');
  }
  if (exchange) {
    searchUrl.searchParams.append('exch[]', exchange);
  }
  if (exchangeListed === 'true') {
    searchUrl.searchParams.append('exch_listed', 'y');
  }
  if (exchangeSuspended) {
    searchUrl.searchParams.append('exch_suspended', 'y');
  }
  // if (contacttype) {
  //   searchUrl.searchParams.append('', contacttype);
  // }

  // if (areaCode) {
  //   searchUrl.searchParams.append('', areaCode);
  // }
  if (companyType) {
    searchUrl.searchParams.append('ctype[]', companyType);
  }
  if (companySubType) {
    searchUrl.searchParams.append('csubtype[]', companySubType);
  }
  if (jurisdiction) {
    searchUrl.searchParams.append('incorpjuris', jurisdiction);
  }
  if (incorporationYear) {
    searchUrl.searchParams.append('incorpyear', incorporationYear);
  }
  if (majorShareholder) {
    searchUrl.searchParams.append('shareholder', majorShareholder);
  }
  if (directorOfficerName) {
    searchUrl.searchParams.append('person', directorOfficerName)
  }
  if (optShareholderInterest10Prcnt) {
    searchUrl.searchParams.append('officerinterest', '1');
  }
  if (optForeignOwnership10Prcnt) {
    searchUrl.searchParams.append('foreignowned', '1');
  }
  if (subsidiary) {
    searchUrl.searchParams.append('subsid', subsidiary);
  }
  if (auditor) {
    searchUrl.searchParams.append('auditor', auditor);
  }
  if (banker) {
    searchUrl.searchParams.append('banker', banker);
  }
  if (lawyer) {
    searchUrl.searchParams.append('lawyer', lawyer);
  }
  if (transferAgent) {
    searchUrl.searchParams.append('transferagent', transferAgent);
  }
  if (idxSPTSXComp === 'true') {
    searchUrl.searchParams.append('sp_composite', '1');
  }
  if (idxSPTSX60 === 'true') {
    searchUrl.searchParams.append('sp_60', '1');
  }
  if (idxTSX30 === 'true') {
    searchUrl.searchParams.append('tsx_30', '1');
  }
  if (idxTSXVenTier1 === 'true') {
    searchUrl.searchParams.append('ventier1', '1');
  }
  if (idxTSXVenTier2 === 'true') {
    searchUrl.searchParams.append('ventier2', '1');
  }
  if (idxTSXVenTier3 === 'true') {
    searchUrl.searchParams.append('ventier3', '1');
  }
  if (idxFP500 === 'true') {
    searchUrl.searchParams.append('fp500', '1');
  }
  if (idxCleantech === 'true') {
    searchUrl.searchParams.append('cleantech', '1');
  }
  if (idxCannabis === 'true') {
    searchUrl.searchParams.append('cannabis', '1');
  }
  if (idxBlockchain === 'true') {
    searchUrl.searchParams.append('blockchain', '1');
  }
  if (sicCodes) {
    searchUrl.searchParams.append('sic[]', sicCodes);
  }
  if (sicCodeType) {
    searchUrl.searchParams.append('sictype', sicCodeType);
  }
  if (gicCodes) {
    searchUrl.searchParams.append('gics[]', gicCodes);
  }
  if (naicsCodes) {
    searchUrl.searchParams.append('naics[]', naicsCodes);
  }
  if (naicsCodeType) {
    searchUrl.searchParams.append('naicstype', naicsCodeType);
  }
  if (normalCourseIssuerBid === 'true') {
    searchUrl.searchParams.append('ncib', '1');
  }
  if (substantialIssuerBid === 'true') {
    searchUrl.searchParams.append('sib', '1');
  }
  if (dividendReinvestmentPlan === 'true') {
    searchUrl.searchParams.append('reinvest', '1');
  }

  if (textSearch) {
    searchUrl.searchParams.append('ctext', textSearch);
  }

  if (finSearchSortOpt) {
    searchUrl.searchParams.append('sortField', finSearchSortOpt);
  }

  if (finSearchSortDirection) {
    searchUrl.searchParams.append('sortOrder', finSearchSortDirection);
  }

  if (category1) {
    searchUrl.searchParams.append('line1', '1');
    searchUrl.searchParams.append('C1', 'ON');
    searchUrl.searchParams.append('Category1', category1);
    searchUrl.searchParams.append('Dataitem1', dataItem1 || '');
    searchUrl.searchParams.append('Year1', year1 || '');
    searchUrl.searchParams.append('Operation1', operation1 || '');
    searchUrl.searchParams.append('Value1', value1 || '');
  }

  if (category2) {
    searchUrl.searchParams.append('line2', '2');
    searchUrl.searchParams.append('C2', 'ON');
    searchUrl.searchParams.append('Oper2', boolOp2 || '');
    searchUrl.searchParams.append('Category2', category2);
    searchUrl.searchParams.append('Dataitem2', dataItem2 || '');
    searchUrl.searchParams.append('Year2', year2 || '');
    searchUrl.searchParams.append('Operation2', operation2 || '');
    searchUrl.searchParams.append('Value2', value2 || '');
  }

  if (category3) {
    searchUrl.searchParams.append('line3', '3');
    searchUrl.searchParams.append('C3', 'ON');
    searchUrl.searchParams.append('Category3', category3);
    searchUrl.searchParams.append('Dataitem3', dataItem3 || '');
    searchUrl.searchParams.append('Year3', year3 || '');
    searchUrl.searchParams.append('Operation3', operation3 || '');
    searchUrl.searchParams.append('Value3', value3 || '');
  }

  if (category4) {
    searchUrl.searchParams.append('line4', '4');
    searchUrl.searchParams.append('C4', 'ON');
    searchUrl.searchParams.append('Category3', category4);
    searchUrl.searchParams.append('Dataitem3', dataItem4 || '');
    searchUrl.searchParams.append('Year3', year4 || '');
    searchUrl.searchParams.append('Operation3', operation4 || '');
    searchUrl.searchParams.append('Value3', value4 || '');
  }

  if (category5) {
    searchUrl.searchParams.append('line5', '5');
    searchUrl.searchParams.append('C5', 'ON');
    searchUrl.searchParams.append('Category5', category5);
    searchUrl.searchParams.append('Dataitem5', dataItem5 || '');
    searchUrl.searchParams.append('Year5', year5 || '');
    searchUrl.searchParams.append('Operation5', operation5 || '');
    searchUrl.searchParams.append('Value5', value5 || '');
  }

  if (db) {
    searchUrl.searchParams.append('db', db);
  }

  if (savedSearchName) {
    searchUrl.searchParams.append('search_name', savedSearchName);
    searchUrl.searchParams.append('save_search', 'Save');
  }

  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });

  const data: unknown = await response.json();
  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }
  if (data.key && (!data.peeks || data.peeks.length === 0)) {
    const companyResponse = await fetch(
      `${config.apiUrl}/fpsn/sn_display_new_api.php?key=${data.key}`
    );
    const companyData: unknown = await companyResponse.json();
    if (!isCompanyResponse(companyData)) {
      throw new Error('Unexpected company response from api');
    }

    return {
      numResults: 1,
      page,
      results: [
        {
          id: companyData.company.fpid,
          name: companyData.company.company_name,
          key: data.key,
          formerNames: [],
          symbols: [],
          coverageStatus: null,
        },
      ],
    };
  }

  // if headings are set, it indicates the financial search
  // was used, and a custom table will display
  if (data.headings) {
    return {
      numResults: data.hit_count,
      page,
      headings: data.headings.slice(2),
      results: (data.peeks || []).map((peek) => ({
        id: 0,
        key: peek.fpid.toString(),
        name: peek.name || '',
        values: peek.values,
      })),
    };
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

async function getSearchResults (
  params: {
    companyName?: string;
    stockSymbol?: string;
    city?: string;
    provinces?: string;
    country?: string;
    page: number;
    numberOfRows?: number;
  },
  getAccessToken: () => Promise<string | null>
): Promise<SearchResults> {
  const {
    companyName,
    stockSymbol,
    city,
    provinces,
    country,
    page = 1,
    numberOfRows,
  } = params;
/*
  if (!companyName && !stockSymbol && !city && !provinces) {
    return {
      numResults: 0,
      page: 1,
      results: [],
    };
  }
*/
  const accessToken = await getAccessToken();

  const searchUrl = new URL(`${config.apiUrl}/fpsn/sn_basic_search_api.php`);
  if (companyName) {
    searchUrl.searchParams.append('cname', companyName);
  }
  if (stockSymbol) {
    searchUrl.searchParams.append('symbol', stockSymbol);
  }
  if (city) {
    searchUrl.searchParams.append('city', city);
  }
  provinces?.split(',').forEach((c) => {
    searchUrl.searchParams.append('provs[]', c);
  });

  if (country?.length !== 0) {
    country?.split(',').forEach((c) => {
      const country: any = countriesLookup
        .filter((p) => p.id === c)
      searchUrl.searchParams.append('country[]', country[0].name ? country[0].name : '');
    });
  } else {
    searchUrl.searchParams.append('country[]', '');
  }

  searchUrl.searchParams.append('page', `${page}`);
  searchUrl.searchParams.append('delta', String(numberOfRows));

  const response = await fetch(searchUrl.href, {
    headers: accessToken
      ? {
          AUTHTOKEN: accessToken,
        }
      : {},
  });
  const data: unknown = await response.json();

  if (!isSearchResponse(data)) {
    throw new Error('Unexpected search results response from api');
  }

  if (data.key && (!data.peeks || data.peeks.length === 0)) {
    const companyResponse = await fetch(
      `${config.apiUrl}/fpsn/sn_display_new_api.php?key=${data.key}`
    );
    const companyData: unknown = await companyResponse.json();
    if (!isCompanyResponse(companyData)) {
      throw new Error('Unexpected company response from api');
    }

    return {
      numResults: 1,
      page,
      results: [
        {
          id: companyData.company.fpid,
          name: companyData.company.company_name,
          key: data.key,
          formerNames: [],
          symbols: [],
          coverageStatus: null,
        },
      ],
    };
  }

  return {
    numResults: data.hit_count,
    page,
    results: (data.peeks || []).map((peek) => ({
      id: peek.fpid,
      key: peek.unique_key,
      name: peek.headline,
      formerNames: peek.former_names,
      symbols: peek.symbols,
      coverageStatus: peek.status_description,
    })),
  };
}

function isPersonSearchResponse (data: unknown): data is RawPersonSearchResult {
  return true;
  // return data && typeof data === "object" && typeof (data as { [key: string]: unknown }).hit_count === "number";
}

function isSearchResponse (data: unknown): data is RawSearchResponse {
  return (
    data !== undefined &&
    typeof data === 'object' &&
    typeof (data as { [key: string]: unknown }).hit_count === 'number'
  );
}

function isLeadResults (data: unknown): data is ILeadListResults {
  return (
    data !== undefined &&
    typeof data === 'object' &&
    typeof (data as { [key: string]: unknown }).display_url === 'string' &&
    typeof (data as { [key: string]: unknown }).leads === 'object'
  );
}

function isExternalDbResults (data: unknown): data is IExternalDbResults {
  // return data && typeof data === "object" && typeof (data as { [key: string]: unknown }).display_url === 'string' && typeof (data as { [key: string]: unknown }).leads === 'object';
  return true;
}

function isCompanyResponse (data: unknown): data is RawCompanyResponse {
  return Boolean(
    data &&
      typeof data === 'object' &&
      typeof (data as { [key: string]: unknown }).company === 'object' &&
      (data as { [key: string]: unknown }).company
  );
}

export function useUser () {
  return useGetUserInfo();
}
