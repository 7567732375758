import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCompany, useMergersAcquisitionsDetails } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import './index.css';

export function MergersAcquisitionsDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];

  keys.forEach((key: string) => {
    const { data: company } = useCompany(key);
    const { data: survey, isLoading } = useMergersAcquisitionsDetails(key);

    const headingId = useId();

    companies.push({ comp: company, survey: survey, isLoading: isLoading, headingId: headingId });
  });

  return (
    <Page aria-label="Financial Post Mergers &amp; Acquisitions in Canada" padding='none' width='full'>
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={element.headingId}>
          Financial Post Mergers &amp; Acquisitions in Canada - Search Results
        </Heading>

        <Row>
          <div className='detail--page--card'>
            { i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '0px', marginBottom: '12px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
              </div>
            </HideOnPrint>
            }
            {element.survey?.htmlDoc && element.survey?.htmlDoc?.body.innerHTML.length > 0 && (
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    Transaction Details
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div
											className='financial--post--table--style'
                        dangerouslySetInnerHTML={{
                          __html: element.survey?.htmlDoc?.body.innerHTML || '',
                        }}
                      />
                    </Tiles>
                  </Accordion.Content>
                  <Accordion.Footer size='xl' level='2' weight='semi-bold'>
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}
          </div>
        </Row>
      </Page>
      )}
    </Page>
  );
}
