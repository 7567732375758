import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { useId } from '../../../hooks';
import { useIndustryReportsListing } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { Link } from 'react-router-dom';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { ArrowRightIcon } from '../../../icons/ArrowRightIcon';
import { ArrowDownIcon } from '../../../icons/ArrowDownIcon';

export function IndustryReportsListingPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();

  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState({}) as any;
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  let [numberOfRows, setNumberOfRows] = React.useState(selectedKeys.length);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const industryListing = useIndustryReportsListing({ page, numberOfRows });

  const loading = industryListing.isFetching && !industryListing.latestData;
  const loadingFirstTime = loading && !industryListing.resolvedData;

  const noResults =
    industryListing.resolvedData &&
    industryListing.resolvedData.numResults === 0;

  numberOfRows = industryListing.resolvedData?.results.length || 50;
  const [selectedRows, setSelectedRows] = React.useState(Array(numberOfRows));

  const toggleList = (e: React.MouseEvent, elementId: string) => {
    const hidden = document
      .getElementById(elementId)
      ?.classList.contains('hide');
    if (hidden) {
      setOpen({
        ...open,
        [elementId]: true,
      });
      document.getElementById(elementId)?.classList.remove('hide');
    } else {
      setOpen({
        ...open,
        [elementId]: false,
      });
      document.getElementById(elementId)?.classList.add('hide');
    }
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/industry-reports/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Industry Reports
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>

      <Text mt="md" lineHeight="md" mb="md">
        In-depth analysis of 30 industries in which the top 450 publicly traded
        Canadian companies compete. Organized by Global Industry Classification
        Standard (GICS&reg;).
      </Text>

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            // onPageChange={setPage}
            // numTotalRows={industryListing.resolvedData?.numResults}
            // numRowsPerPage={industryListing.resolvedData?.numResults || numberOfRows  }
            // page={1}
            loading={loading}
            expectedColumns={2}
            expectedRows={industryListing.resolvedData?.numResults}
            isEmpty={!industryListing.resolvedData}
            data={industryListing.resolvedData}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            // queryParams={queryParams}
            // onNumberOfRowChange={(latestNumberOfRows) => {
            // 	setNumberOfRows(latestNumberOfRows)
            // 	setNumberOfRowsInContext(latestNumberOfRows)
            // }}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={industryListing.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Industry</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Reports</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {industryListing.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <div style={{ verticalAlign: 'middle', display: 'flex' }}>
                      <div style={{ padding: '10px' }}>
                        {!open[`archive_list_${r.key}`]
                          ? (
                          <ArrowRightIcon
                            onClick={(e) => {
                              toggleList(e, `archive_list_${r.key}`);
                            }}
                          />
                          )
                          : (
                          <ArrowDownIcon
                            onClick={(e) => {
                              toggleList(e, `archive_list_${r.key}`);
                            }}
                          />
                        )}
                      </div>
                      <div style={{ padding: '10px', paddingTop: '15px' }}>
                        <Link
                          to={`/industry-reports/details/${r.key}`}
                          className="black-text"
                        >
                          <span>
                            <Text>{r.name}</Text>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <ul style={{ listStyle: 'none', minWidth: '150px' }}>
                      {r.pdfs &&
                        r.pdfs
                          .filter((pdf) => pdf.current === 1)
                          .map((pdf) => (
                            <li>
                              <a
                                href={`https://legacy-fpadvisor.financialpost.com/fpir/pdf/ir_${pdf.id}.pdf`}
                              >
                                <Text>Current</Text>
                              </a>
                            </li>
                          ))}
                    </ul>
                    <ul
                      id={`archive_list_${r.key}`}
                      className="hide"
                      style={{ listStyle: 'none', minWidth: '150px' }}
                    >
                      {r.pdfs &&
                        r.pdfs
                          .filter((pdf) => pdf.current !== 1)
                          .map((pdf) => (
                            <li style={{ marginBottom: '10px' }}>
                              <a
                                href={`https://legacy-fpadvisor.financialpost.com/fpir/pdf/ar_${pdf.id}.pdf`}
                              >
                                <Text>{pdf.year}</Text>
                              </a>
                            </li>
                          ))}
                    </ul>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
