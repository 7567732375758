import * as React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../components/Text';
import { Row } from '../../components/Row';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { lighten } from 'polished';
import { ArrowUpIcon } from '../../icons/ArrowUpIcon';
import { ExternalLinkIcon } from '../../icons/ExternalLinkIcon';
import { Link, useRouteMatch } from 'react-router-dom';
import { useId } from '../../hooks';
import { DelayUnmount } from '../../components/DelayUnmount';
import {
  getResponsiveVisibility,
  MarginProps,
  ResponsiveVisibilityProps,
} from '../../utils/style';
import { IconButton } from '../../components/IconButton';
import { useIsLoggedIn, useAuthRoles, useAuthContext } from '../AuthProvider';
import { Button } from '../../components/Button';
import { ShowOnMobile } from '../../components/ShowOnMobile';

const SideNavContext = React.createContext(false);
const SideNavSetContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => false);

export function SideNav () {
  const isOpen = React.useContext(SideNavContext);
  const setIsOpen = React.useContext(SideNavSetContext);
  const isLoggedIn = useIsLoggedIn();
  const authRoles = useAuthRoles();
  const authContext = useAuthContext();

  const onLoginPage = !!useRouteMatch('/login');

  return (
    <div>
      <FakeNavBarPusher />
      <StyledNav open={isOpen} aria-label="Side bar">
        <NavItem
          hideAbove="tablet"
          ml="none"
          as={Link}
          to="/about-us"
          onClick={() => setIsOpen(false)}
        >
          <Text weight="medium">About Us</Text>
        </NavItem>
        <NavItem
          hideAbove="tablet"
          ml="none"
          as={Link}
          to="/corporate-data"
          onClick={() => setIsOpen(false)}
        >
          <Text weight="medium">Corporate Data</Text>
        </NavItem>
        <NavItem
          hideAbove="tablet"
          ml="none"
          as={'a'}
          href="https://legacy-fpadvisor.financialpost.com/prod/prod_list.php"
          onClick={() => setIsOpen(false)}
        >
          <Text weight="medium">Ordering FP Products</Text>
        </NavItem>

        <ShowOnMobile>
          <hr style={{ border: '0', borderTop: '1px solid #ccc' }} />
        </ShowOnMobile>

        <NavAccordion
          title="Company Reports"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'Corporate Snapshots',
              link: '/corporate-snapshots/search',
            },
            {
              label: 'Corporate Surveys',
              link: '/corporate-surveys/search',
              disableWhenLoggedOut: true,
              disable: !authRoles?.corporate_surveys,
            },
            {
              label: 'Corporate Analyzer',
              link: '/corporate-analyzer/search',
              disableWhenLoggedOut: true,
              disable: !authRoles?.corporate_analyzer,
            },
          ]}
        />

        <NavAccordion
          title="FP Reports"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'Investor Reports',
              link: '/investor-reports/search',
              disable: !authRoles?.investor_reports,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Historical Reports',
              link: '/historical-reports/search',
              disable: !authRoles?.historical_reports,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Industry Reports',
              link: '/industry-reports/',
              disable: !authRoles?.industry_reports,
              disableWhenLoggedOut: true,
            },
          ]}
        />
        <NavAccordion
          title="FP Datasets"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'Mergers & Acquisitions',
              link: '/mergers-and-acquisitions/search',
              disable: !authRoles?.mergers_acquisitions,
              disableWhenLoggedOut: true,
            },
            {
              label: 'New Issues',
              link: '/new-issues/search',
              disable: !authRoles?.new_issues,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Dividends',
              link: '/dividends/search',
              disable: !authRoles?.dividends,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Fixed Income',
              link: '/fixed-income/search',
              disable: !authRoles?.fixed_income,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Predecessor & Defunct',
              link: '/predecessor-and-defunct/search',
              disable: !authRoles?.predecessor_defunct,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Lead List Generator',
              link: '/lead-list-generator',
              disable: !authRoles?.lead_list_generator,
              disableWhenLoggedOut: true,
            },
          ]}
        />
        <NavAccordion
          title="People"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'Directory of Directors',
              link: '/directory-of-directors/search',
              disable: !authRoles?.directory_directors,
              disableWhenLoggedOut: true,
            },
            {
              label: 'Person Search',
              link: '/person-search/search',
              disable: !authRoles?.person_search,
              disableWhenLoggedOut: true,
            },
          ]}
        />
        <NavAccordion
          title="Other"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'External Databases',
              link: '/external-databases',
              disable: !authRoles?.external_databases,
              disableWhenLoggedOut: true,
            },
          ]}
        />
        <NavAccordion
          title="Product Version"
          onItemClick={() => setIsOpen(false)}
          items={[
            {
              label: 'FP Advisor Classic',
              link: authContext?.ip_based_login
              ? `https://legacy-fpadvisor.financialpost.com/login/ip/${authContext.IC_User.UserID}`
              : 'https://legacy-fpadvisor.financialpost.com/',
            },
          ]}
        />

        {!isLoggedIn && (
          <div>
            <Text color="primary" weight="semi-bold" mt="lg" mb="md">
              Subscribe now to unlock more features.
            </Text>
            <Button
              onClick={() => setIsOpen(false)}
              link="/help/#contact-us"
              label="Contact us"
            />
          </div>
        )}
      </StyledNav>
    </div>
  );
}

function NavAccordion (props: {
  title: string;
  onItemClick: () => void;
  disableWhenLoggedOut?: boolean;
  items: Array<{
    label: string;
    link: string;
    disableWhenLoggedOut?: boolean;
    disable?: boolean;
  }>;
}) {
  const [internalOpen, setOpen] = React.useState(true);
  const isLoggedIn = useIsLoggedIn();
  const itemHeight = 43;
  const titleId = useId();
  const open = internalOpen && (!props.disableWhenLoggedOut || isLoggedIn);
  const expandableSectionId = useId();
  const disabled = props.disableWhenLoggedOut && !isLoggedIn;
  return (
    <>
      <StyledButton
        id={titleId}
        type="button"
        onClick={() => setOpen(!open)}
        aria-controls={expandableSectionId}
        aria-expanded={open}
        disabled={disabled}
      >
        <Text color="light" weight="medium">
          {props.title}
        </Text>

        {open
? (
          <ArrowUpIcon color="text.light" size="sm" />
        )
: (
          <ArrowDownIcon color="text.light" size="sm" />
        )}
      </StyledButton>
      <ExpandableSection
        id={expandableSectionId}
        role="region"
        aria-hidden={disabled}
        aria-labelledby={titleId}
        expandedHeight={itemHeight * props.items.length}
        open={open}
      >
        <DelayUnmount mounted={open} delay={300}>
          {props.items.map((item) =>
            (item.disableWhenLoggedOut && !isLoggedIn) || item.disable
? (
              <NavItem appearDisabled onClick={props.onItemClick}>
                <Text weight="medium">{item.label}</Text>
              </NavItem>
            )
: item.link.indexOf('://') < 0
? (
              <NavItem
                tabIndex={disabled || !open ? -1 : undefined}
                as={Link}
                to={item.link}
                onClick={props.onItemClick}
              >
                <Text weight="medium">{item.label}</Text>
              </NavItem>
            )
: (
              <NavItem
                tabIndex={disabled || !open ? -1 : undefined}
                as={'a'}
                href={item.link}
                target="_blank"
                onClick={props.onItemClick}
              >
                <Row>
                  <Text weight="medium" mr="md">
                    {item.label}
                  </Text>
                  <ExternalLinkIcon />
                </Row>
              </NavItem>
            )
          )}
        </DelayUnmount>
      </ExpandableSection>
    </>
  );
}

const NavItem = styled.div<
  { ml?: 'none'; appearDisabled?: boolean } & ResponsiveVisibilityProps
>((props) => ({
  display: 'block',
  padding: '11px',
  marginLeft: props.ml === 'none' ? 0 : '7px',
  paddingLeft: '4px',
  textDecoration: 'none',
  color: 'inherit',
  outline: 'none',
  borderBottom: '1px solid transparent',
  position: 'relative',
  '&.focus-visible': {
    backgroundColor: lighten(0.6, props.theme.palette.text.light),
  },
  ...(props.appearDisabled
    ? {
        '&:before': {
          position: 'absolute',
          content: "''",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(255,255,255,0.6)',
        },
      }
    : {}),
  ...getResponsiveVisibility(props),
}));

const ExpandableSection = styled.div<{
  open?: boolean;
  expandedHeight?: number;
}>((props) => ({
  height: props.open ? props.expandedHeight : '0px',
  overflow: 'hidden',
  transition: 'all 0.3s',
  borderBottom: `1px solid ${props.theme.palette.border.light}`,
  marginBottom: '2px',
}));

const StyledButton = styled.button((props) => ({
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'inherit',
  borderRadius: '4px',
  paddingLeft: '4px',
  paddingRight: '24px',
  paddingTop: '12px',
  paddingBottom: '12px',
  cursor: 'pointer',
  outline: 'none',
  position: 'relative',
  '&[disabled]:before': {
    position: 'absolute',
    content: "''",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
  '&.focus-visible': {
    backgroundColor: lighten(0.6, props.theme.palette.text.light),
  },
}));

const navBarHeight = 48;
const StyledNav = styled.nav<{ open?: boolean }>((props) => ({
  width: '100%',
  height: `calc(100% - ${navBarHeight}px)`,
  position: 'fixed',
  paddingTop: '16px',
  paddingLeft: '16px',
  paddingBottom: '16px',
  backgroundColor: 'white',
  display: props.open ? 'block' : 'none', // TODO: animate this
  transition: 'all 0.3s',
  zIndex: props.theme.zIndex.sideNav,
  borderRight: `1px solid ${props.theme.palette.border.light}`,
  [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
    width: '270px',
  },
  [`@media(min-width: ${props.theme.breakpoints.md})`]: {
    display: 'block',
  },
  overflowY: 'scroll',
}));

const FakeNavBarPusher = styled.div((props) => ({
  width: '270px',
  display: 'none',
  [`@media(min-width: ${props.theme.breakpoints.md})`]: {
    display: 'block',
  },
}));

export function SideNavProvider (props: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <SideNavContext.Provider value={isOpen}>
      <SideNavSetContext.Provider value={setIsOpen}>
        {props.children}
      </SideNavSetContext.Provider>
    </SideNavContext.Provider>
  );
}

export function ContentBesideSideNav (props: { children: React.ReactNode }) {
  const isOpen = useIsSideBarOpen();

  return (
    <StyledContentBesideSideNav isOpen={isOpen}>
      {props.children}
    </StyledContentBesideSideNav>
  );
}

const StyledContentBesideSideNav = styled.div<{ isOpen?: boolean }>(
  (props) => ({
    display: props.isOpen ? 'none' : 'flex',
    flexBasis: 0,
    flexGrow: 1,
    overflowX: 'hidden',
    justifyContent: 'center',
    [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
      display: 'flex',
    },
  })
);

export function SideNavToggleButton (
  props: Pick<MarginProps, 'mr'> & Pick<ResponsiveVisibilityProps, 'hideAbove'>
) {
  const toggleSideNav = useToggleSideNav();
  const isOpen = useIsSideBarOpen();

  return (
    <IconButton
      hideAbove={props.hideAbove}
      aria-label={isOpen ? 'Close navigation' : 'Open navigation'}
      onClick={toggleSideNav}
      mr={props.mr}
    >
      <svg
        height={24}
        viewBox="0 0 24 24"
        style={{ display: 'block' }}
        width={24}
      >
        {isOpen
? (
          <path
            fill="white"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        )
: (
          <path
            fill="white"
            d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
          />
        )}
      </svg>
    </IconButton>
  );
}

export function useToggleSideNav () {
  const setIsOpen = React.useContext(SideNavSetContext);
  return React.useCallback(() => setIsOpen((open) => !open), []);
}

export function useIsSideBarOpen () {
  return React.useContext(SideNavContext);
}
