import { rgba } from 'polished';
import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function StarIcon (props: { color?: 'subdued' | 'clear'; size?: 'sm' | 'md' }) {
  const size = props.size === 'sm' ? 24 : 28;
  const theme = useTheme();

	const colors = {
		subdued: theme.palette.subdued.main,
		clear: rgba(1, 1, 1, 0),
		default: '#FECF0A',
	}

  return (
    <svg aria-hidden role="img" height={size} viewBox="0 0 24 24" width={size}>
      <path
        fill={props.color ? colors[props.color] : colors.default}
				stroke='black'
        d="M11 16.0614L16.15 19.3333L14.7833 13.1667L19.3333 9.01753L13.3417 8.48245L11 2.66666L8.65833 8.48245L2.66667 9.01753L7.21667 13.1667L5.85 19.3333L11 16.0614Z"
      />
    </svg>
  );
}
