import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ArrowRightIcon } from '../../icons/ArrowRightIcon';
import { MarginProps, getMarginStyles } from '../../utils/style';
import { Box } from '../Box';
import { Row } from '../Row';

interface CardProps extends MarginProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  height?: 'full' | 'auto';
  link?: string;
  padding?: 'none';
}

const Root = styled.div<CardProps & { to?: string }>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0 2px 5px 2px rgba(215, 215, 215, 0.5)',
  borderRadius: '8px',
  padding: props.padding === 'none' ? 0 : props.theme.spacing.md,
  height: props.height === 'full' ? '100%' : 'auto',
  textDecoration: 'none',
  color: 'inherit',
  border: props.to ? '2px solid transparent' : undefined,
  overflow: 'hidden',
  '&.focus-visible': {
    border: props.to
      ? `2px solid ${props.theme.palette.primary.main}`
      : undefined,
    outline: props.to ? 'none' : undefined,
  },
  ...getMarginStyles(props),
}));

export function Card (props: CardProps) {
  const linkProps = props.link ? { as: Link, to: props.link || '' } : {};
  return (
    <Root
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      height={props.height}
      padding={props.padding}
      {...(linkProps as any)}
    >
      {props.icon
? (
        <Row alignItems="center">
          <Box mr="md">{props.icon}</Box>
          {props.link
? (
            <Row
              flexGrow={1}
              flexBasis={0}
              justifyContent="space-between"
              alignItems="center"
            >
              <div>{props.children}</div>
              <ArrowRightIcon size="lg" />
            </Row>
          )
: (
            props.children
          )}
        </Row>
      )
: (
        props.children
      )}
    </Root>
  );
}
