/* eslint-disable */
import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../../components/Page';
import { Heading } from '../../../../components/Heading';
import { useId } from '../../../../hooks';
import moment from 'moment';
import { Tab, Tabs } from 'components/Tabs';
import '../../../MergersAcquisitions/SearchPage/searchMna.scss';
import './index.css';
import { CorporateSurveysAdvanceTab, ICorpSurveysAdvProps } from 'components/CorporateSurveysAdvanceTab';

import {
  sicLookup,
  gicsLookup,
  exchangesLookup,
  provincesLookup,
  companyTypesExtendedLookup,
  companySubTypesExtendedLookup,
  countriesLookup,
  bankersLookup,
  auditorsLookup,
  transferAgentLookup,
  lawyersLookup,
  jurisdictionLookup,
} from '../../../../data/lookups';

export function LeadListGeneratorSearchCorporateSurveysPage () {
  const history = useHistory();
  const headingId = useId();

  const [companyName, setCompanyName] = React.useState("");
  const [stockSymbol, setStockSymbol] = React.useState("");
  const [searchPredecessor, setSearchPredecessor] = React.useState(true);
  const [searchType, setSearchType] = React.useState(
    localStorage.getItem('basicSearchRadio') || 'exact-phrase'
    );
  const [city, setCity] = React.useState("");
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [country, setCountry] = React.useState<string[]>([]);
  const [currentTime, setCurrentTime] = React.useState(
    new Date().toLocaleString()
  );

  const [ClearValue, setClearValue] = React.useState(true);

  const [exchange, setExchange] = React.useState<string[]>([]);

  const [exchangeListed, setExchangeListed] = React.useState(
    localStorage.getItem('chk_exchange_listed') === 'true'
  );
  const [exchangeSuspended, setExchangeSuspended] = React.useState(
    localStorage.getItem('chk_exchange_suspended') === 'true'
  );

  const [contacttype, setContacttype] = React.useState(
    localStorage.getItem('contactTypeRadio') || '1'
  );
  const [sicCodeType, setSicCodeType] = React.useState(
    localStorage.getItem('sicCodeRadio') || '0'
  );

  const [areaCode, setAreaCode] = React.useState('');
  const [companyType, setCompanyType] = React.useState<string[]>([]);
  const [companySubType, setCompanySubType] = React.useState<string[]>([]);
  const [incorporationYear, setIncorporationYear] = React.useState('');
  const [jurisdiction, setJurisdiction] = React.useState<string>('');
  const [majorShareholder, setMajorShareholder] = React.useState('');
  const [optShareholderInterest10Prcnt, setOptShareholderInterest10Prcnt] = React.useState<boolean>(false);
  const [optForeignOwnership10Prcnt, setOptForeignOwnership10Prcnt] = React.useState<boolean>(false);
  const [subsidiary, setSubsidiary] = React.useState('');
  const [directorOfficerName, setDirectorOfficerName] = React.useState('');
  const [auditor, setAuditor] = React.useState<string>('');
  const [banker, setBanker] = React.useState<string>('');
  const [lawyer, setLawyer] = React.useState<string>('');
  const [transferAgent, setTransferAgent] = React.useState<string>('');
  const [idxSPTSXComp, setIdxSPTSXComp] = React.useState<boolean>(false);
  const [idxSPTSX60, setIdxSPTSX60] = React.useState<boolean>(false);
  const [idxTSX30, setIdxTSX30] = React.useState<boolean>(false);
  const [idxTSXVenTier1, setIdxTSXVenTier1] = React.useState<boolean>(false);
  const [idxTSXVenTier2, setIdxTSXVenTier2] = React.useState<boolean>(false);
  const [idxTSXVenTier3, setIdxTSXVenTier3] = React.useState<boolean>(false);
  const [idxFP500, setIdxFP500] = React.useState<boolean>(false);
  const [idxCleantech, setIdxCleantech] = React.useState<boolean>(false);
  const [idxCannabis, setIdxCannabis] = React.useState<boolean>(false);
  const [idxBlockchain, setIdxBlockchain] = React.useState<boolean>(false);
  const [gicCodes, setGicCodes] = React.useState<string[]>([]);
  const [naicsCodes, setNaicsCodes] = React.useState<string[]>([]);
  const [naicsCodeType, setNaicsCodeType] = React.useState(
    localStorage.getItem('naicsCodeRadio') || '0'
  );
  const [sicCodes, setSicCodes] = React.useState<string[]>([]);
  

  const [normalCourseIssuerBid, setNormalCourseIssuerBid] = React.useState<boolean>(false);
  const [substantialIssuerBid, setSubstantialIssuerBid] = React.useState<boolean>(false);
  const [dividendReinvestmentPlan, setDividendReinvestmentPlan] = React.useState<boolean>(false);

  const onSubmit = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;
    const resultsUrl = new URL(
      '/lead-list-generator/results/fp-corporate-surveys',
      baseUrl
    );
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set('companyName', companyName);
    }

    if (contacttype) {
      resultsUrl.searchParams.set('contacttype', contacttype);
    }

    if (sicCodeType) {
      resultsUrl.searchParams.set('sicCodeType', sicCodeType);
    }

    if (majorShareholder) {
      resultsUrl.searchParams.set('majorShareholder', `${majorShareholder}`);
    }
    if (exchangeListed) {
      resultsUrl.searchParams.set('exchangeListed', `${exchangeListed}`);
    }
    if (exchangeSuspended) {
      resultsUrl.searchParams.set('exchangeSuspended', `${exchangeSuspended}`);
    }
    if (idxSPTSXComp) {
      resultsUrl.searchParams.set('idxSPTSXComp', `${idxSPTSXComp}`);
    }
    if (idxSPTSX60) {
      resultsUrl.searchParams.set('idxSPTSX60', `${idxSPTSX60}`);
    }
    if (idxTSX30) {
      resultsUrl.searchParams.set('idxTSX30', `${idxTSX30}`);
    }
    if (idxTSXVenTier1) {
      resultsUrl.searchParams.set('idxTSXVenTier1', `${idxTSXVenTier1}`);
    }
    if (idxTSXVenTier2) {
      resultsUrl.searchParams.set('idxTSXVenTier2', `${idxTSXVenTier2}`);
    }
    if (idxTSXVenTier3) {
      resultsUrl.searchParams.set('idxTSXVenTier3', `${idxTSXVenTier3}`);
    }
    if (idxFP500) {
      resultsUrl.searchParams.set('idxFP500', `${idxFP500}`);
    }
    if (idxCleantech) {
      resultsUrl.searchParams.set('idxCleantech', `${idxCleantech}`);
    }
    if (idxCannabis) {
      resultsUrl.searchParams.set('idxCannabis', `${idxCannabis}`);
    }
    if (idxBlockchain) {
      resultsUrl.searchParams.set('idxBlockchain', `${idxBlockchain}`);
    }
    if (normalCourseIssuerBid) {
      resultsUrl.searchParams.set(
        'normalCourseIssuerBid',
        `${normalCourseIssuerBid}`
      );
    }
    if (substantialIssuerBid) {
      resultsUrl.searchParams.set(
        'substantialIssuerBid',
        `${substantialIssuerBid}`
      );
    }
    if (dividendReinvestmentPlan) {
      resultsUrl.searchParams.set(
        'dividendReinvestmentPlan',
        `${dividendReinvestmentPlan}`
      );
    }

    const bankers = bankersLookup
      .filter((p) => banker.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('banker', bankers.join(','));

    const lawyers = lawyersLookup
      .filter((p) => lawyer.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('lawyer', lawyers.join(','));
    const transferAgents = transferAgentLookup
      .filter((p) => transferAgent.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('transferAgent', transferAgents.join(','));

    const auditors = auditorsLookup
      .filter((p) => auditor.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('auditor', auditors.join(','));

    const province = provincesLookup
      .filter((p) => provinces.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('provinces[]', province.join(','));
    const exchanges = exchangesLookup
      .filter((p) => exchange.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('exchange[]', exchanges.join(','));

    const countries = countriesLookup
      .filter((p) => country.includes(p.id))
      .map((p) => p.name);
    resultsUrl.searchParams.set("country[]", countries.join(","));

    const companyTypes = companyTypesExtendedLookup
      .filter((p) => companyType.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('companyType[]', companyTypes.join(','));

    const sicCode = sicLookup
      .filter((p) => sicCodes.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('sicCodes[]', sicCode.join(','));
    const gicCode = gicsLookup
      .filter((p) => gicCodes.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('gicCodes[]', gicCode.join(','));

    const companySubTypes = companySubTypesExtendedLookup
      .filter((p) => companySubType.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('companySubType[]', companySubTypes.join(','));

    const jurisdictions = jurisdictionLookup
      .filter((p) => jurisdiction.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('jurisdiction', jurisdictions.join(','));

    resultsUrl.searchParams.set('city', `${city}`);

    resultsUrl.searchParams.set('areaCode', `${areaCode}`);

    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set('order', 'date');
    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true,
    });
  };

  const advancedFormProps: ICorpSurveysAdvProps = {
    companyName,
    setCompanyName,
    city,
    setCity,
    provinces,
    setProvinces,
    country,
    setCountry,
    exchange,
    setExchange,
    exchangeListed,
    setExchangeListed,
    exchangeSuspended,
    setExchangeSuspended,
    contacttype,
    setContacttype,
    sicCodeType,
    setSicCodeType,
    areaCode,
    setAreaCode,
    companyType,
    setCompanyType,
    incorporationYear,
    setIncorporationYear,
    companySubType,
    setCompanySubType,
    jurisdiction,
    setJurisdiction,
    majorShareholder,
    setMajorShareholder,
    optShareholderInterest10Prcnt,
    setOptShareholderInterest10Prcnt,
    optForeignOwnership10Prcnt,
    setOptForeignOwnership10Prcnt,
    subsidiary,
    setSubsidiary,
    directorOfficerName,
    setDirectorOfficerName,
    auditor,
    setAuditor,
    banker,
    setBanker,
    lawyer,
    setLawyer,
    transferAgent,
    setTransferAgent,
    idxSPTSXComp,
    setIdxSPTSXComp,
    idxSPTSX60,
    setIdxSPTSX60,
    idxTSX30,
    setIdxTSX30,
    idxTSXVenTier1,
    setIdxTSXVenTier1,
    idxTSXVenTier2,
    setIdxTSXVenTier2,
    idxTSXVenTier3,
    setIdxTSXVenTier3,
    idxFP500,
    setIdxFP500,
    idxCleantech,
    setIdxCleantech,
    idxCannabis,
    setIdxCannabis,
    idxBlockchain,
    setIdxBlockchain,
    gicCodes,
    setGicCodes,
    naicsCodes,
    setNaicsCodes,
    naicsCodeType,
    setNaicsCodeType,
    sicCodes,
    setSicCodes,
    normalCourseIssuerBid,
    setNormalCourseIssuerBid,
    substantialIssuerBid,
    setSubstantialIssuerBid,
    dividendReinvestmentPlan,
    setDividendReinvestmentPlan,
    formSubmit: onSubmit,
  }

  return (
    <>
      <Page aria-labelledby={headingId}>
        {/* <Text mt="sm" lineHeight="md">
          Corporate address information for all publicly traded Canadian
          companies.
        </Text> */}
        <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
          Lead List Generator - Corporate Surveys
        </Heading>
        <CorporateSurveysAdvanceTab {...advancedFormProps} />
        <br />
      </Page>
    </>
  );
}
