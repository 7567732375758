/**
 * Can be used to tell child components that the current background is dark or light.
 * This lets children decide whether to show black or white content for the best contrast.
 *
 * @see https://seek-oss.github.io/braid-design-system/components/BackgroundProvider
 */
import * as React from 'react';

const BackgroundContext = React.createContext<'light' | 'dark'>('light');

export function BackgroundProvider (props: {
  children: React.ReactNode;
  type: 'dark' | 'light';
}) {
  return (
    <BackgroundContext.Provider value={props.type}>
      {props.children}
    </BackgroundContext.Provider>
  );
}

export function useCurrentBackground () {
  return React.useContext(BackgroundContext);
}
