import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../components/Page';
import { Heading } from '../../components/Heading';
import { useId } from '../../hooks';
import { Button } from '../../components/Button';
import { Row } from '../../components/Row';
import { Tabs, Tab } from '../../components/Tabs';
import { Card } from '../../components/Card';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { Text } from '../../components/Text';
import Alert from 'components/Alert';
import {
	useIsLoggedIn,
	useLogin,
	useGetUserInfo,
	useChangePassword,
	useNumberOfRows,
  useSetUserSettings,
	useExpandSettings,
	useAuthContext,
	AuthContext
} from '../../containers/AuthProvider';
import {
	useBetaModalOpen,
	useSetBetaModalOpen,
	useBetaModalDidAccept
} from '../../containers/BetaModal';
import ChangePassword from './ChangePasswordPage';
import { config } from 'config';
import { Toaster } from 'react-hot-toast';

export function SettingsPage () {
	const history = useHistory();
	const headingId = useId();
	const authContext = useAuthContext();
	const [edit, setEdit] = React.useState<boolean>(false);
	const editHandler = () => {
		setEdit(true);
	};
	const tabVar = history.location.hash === '#profile' ? 0 : 1;

  const {
    updateUserSettings,
    isUpdatingUserSettings,
    updatingUserSettingsError,
    clearUpdatinguserSettingsError
  } = useSetUserSettings();

	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [settingExpandContentCards, setSettingExpandContentCards] =
		React.useState(Boolean(authContext?.expand_collapse_settings?.default) || false);
	const setExpandSettings = useExpandSettings()
	const [sessionSetting, setSessionSetting] = React.useState(
		'save-on-this-computer'
	);
	const userInfo = useGetUserInfo();
	const authInfo = React.useContext(AuthContext);
	const isLoggedIn = useIsLoggedIn();
	const [submit, setSubmit] = React.useState(false);

	const [resultsPerPage, setResultsPerPage] = React.useState(
		String(authInfo?.DefaultNumberRows || 10)
	);

	const {
		changePassword,
		changePasswordError,
		clearChangePasswordError,
		isChangingPassword
	} = useChangePassword();

	return (
		<Page>
			<Heading
				id={`profile_${headingId}`}
				level="1"
				size={['xxl', 'xxl', 'xxxl']}
			>
				Profile
			</Heading>

			<Heading
				id={`greeting_${headingId}`}
				mb="lg"
				level="2"
				size={['lg', 'lg', 'xl']}
			>
				{isLoggedIn ? `Hi, ${userInfo?.firstName}!` : ''}
			</Heading>

			<Tabs aria-label="Search Options" selectedTab={tabVar}
				tabsClassName='setting--tab--section'
			>
				<Tab label="Personal Info">
					<Card>
						{!edit
							? (
								<>
									<Heading
										id={`name_${headingId}`}
										mt="lg"
										mb="sm"
										level="3"
										size={['lg', 'lg', 'lg']}
									>
										Name
									</Heading>
									<Text color="light">{userInfo?.firstName}</Text>
									<Row>
										<Heading
											id={`password_${headingId}`}
											mt="lg"
											mb="sm"
											level="3"
											size={['lg', 'lg', 'lg']}
										>
											Password
										</Heading>
										<div style={{ marginLeft: '120px', marginTop: '30px' }}>
											<span
												style={{ cursor: 'pointer', color: 'blue' }}
												onClick={editHandler}
											>
												Edit
											</span>
										</div>
									</Row>
									<Text color="light">Account Password</Text>
								</>
							)
							: (
								<ChangePassword
									changePassword={changePassword}
									changePasswordError={changePasswordError}
									clearChangePasswordError={clearChangePasswordError}
									isChangingPassword={isChangingPassword}
									onCancel={() => setEdit(false)}
								/>
							)}
					</Card>
				</Tab>
				<Tab label="Settings">
					<Card>
						<Heading
							id={`resulting_${headingId}`}
							mt="lg"
							mb="xs"
							level="3"
							size={['xl', 'xl', 'xl']}
						>
							Result Display Settings
						</Heading>

						<Text mb="md" lineHeight="md">
							Some result sets are divided into pages. How many results do you
							prefer per page?
						</Text>

						<label htmlFor="search_result_rows">Results Per Page</label>
						<Row flexWrap="wrap">
							<select
								style={{ height: '40px', marginRight: '8px', width: '300px' }}
								id="search_result_rows"
								name="search_result_rows"
                value={resultsPerPage}
								onChange={(e) => {
									setResultsPerPage(e.target.value);
								}}
							>
								<option value="10">
									10 results per page
								</option>
								<option value="50">
									50 results per page
								</option>
								<option value="100">
									100 results per page
								</option>
								<option value="200">
									200 results per page
								</option>
								<option value="500">
									500 results per page
								</option>
								<option value="1000">
									1000 results per page
								</option>
								<option value="2000">
									2000 results per page
								</option>
							</select>
						</Row>

						<Heading
							id={`content_${headingId}`}
							mt="xl"
							mb="xs"
							level="3"
							size={['xl', 'xl', 'xl']}
						>
							Content Display Settings
						</Heading>

						<Text mb="md" lineHeight="md">
							Aside from the first section, content sections are collapsed by
							default. You can modify this setting by switching toggle position
							below. This setting will be applied globally accross FP Advisor.
						</Text>

						<div style={{ margin: '20px', marginLeft: '0px', display: 'flex' }}>
							<label>Expand all content cards</label>
							<ToggleSwitch
								checked={settingExpandContentCards === true}
								onChange={(value) => {
									setExpandSettings('', value)
									setSettingExpandContentCards(value)
								}}
							/>
							<span style={{ margin: '2px', marginLeft: '10px' }}>
								{settingExpandContentCards === true ? 'On' : 'Off'}
							</span>
						</div>
						<div>
							<Button
								size="md"
								mt="md"
								label="Save"
								type="submit"
								onClick={async () => {
                  const success = await updateUserSettings({ numberRows: Number(resultsPerPage), expandCollapse: settingExpandContentCards });
                  if (success) {
                    setSubmit(true);
                  }
								}}
							/>
						</div>
					</Card>
					<div style={{ margin: '20px' }}>
						<Alert
							alertClassName='setting--alert'
							dismissible
							autoHide
							show={submit}
							onDismiss={() => (submit === true ? setSubmit(false) : '')}
							message={'Content display settings updated'}
							design="success"
						/>
					</div>
				</Tab>
			</Tabs>
		</Page>
	);
}
