/* eslint-disable */
// Third party
import * as React from 'react';
import { PaginatedQueryResult } from 'react-query';
import styled from 'styled-components/macro';
import ReactPaginate from 'react-paginate';

// Icons
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../../icons/ArrowRightIcon';
import { PrintIcon } from '../../icons/PrintIcon';
import { CsvIcon } from '../../icons/CsvIcon';

// Components
import { Box } from '../Box';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';

// Interface
import { PersonSearchResultEntry, SearchResults } from '../../data';
import { DropDownMenu } from '../DropDownMenu';
import { Row } from '../Row';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';

// Data
import { OptionsList } from '../../data/numberOfRows';
import { useQueryParams } from '../../hooks';

// Search Criteria
import { CommonSearchSelectedResult } from 'components/CommonSearchSelectedResult';
import { NumberOfSearchResults } from 'components/NumberOfSearchResults';
import { PrintFriendly } from 'components/PrintFriendly';
import { HideOnPrint } from 'components/HideOnMobile';
import { string } from 'yup';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

const StyledTable = styled.table({
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: 0,
});

const PaginateTable = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  '.pagination': {
    paddingLeft: '0',
    margin: '22px 0',
    borderRadius: '4px',
    // new css
    alignItems: 'center',
    display: 'flex',
  },
  '.pagination > li': {
    display: 'flex',
    margin: '0 2px 0 2px',
  },
  '.pagination > li > a,.pagination > li > span': {
    position: 'relative',
    float: 'left',
    padding: '6px 12px',
    lineHeight: '1.42857143',
    textDecoration: 'none',
    color: '#000',
    backgroundColor: '#fff',

    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
  },
  '.pagination > li:first-child > a, .pagination > li:first-child > span': {
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
  },
  '.pagination > li: last- child > a,.pagination > li: last-child > span': {
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
  },
  '.pagination > li > a:hover,.pagination > li > span:hover,.pagination > li > a:focus,.pagination > li > span:focus':
  {
    zIndex: 2,
    color: '#1b4060',
    backgroundColor: '#eeeeee',
    borderColor: '#ddd',
    borderRadius: '20px',
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    fontSize: '14px',
  },
  '.pagination > .active > a,.pagination > .active > span,.pagination > .active > a:hover,.pagination > .active > span:hover,.pagination > .active > a:focus,.pagination > .active > span:focus':
  {
    zIndex: 3,
    color: '#2c689c',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    border: '2px solid #2c689c',
    cursor: 'default',
    borderRadius: '20px',
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    fontSize: '14px',
  },
  '.pagination > .disabled > span,.pagination > .disabled > span:hover,.pagination > .disabled > span:focus,.pagination > .disabled > a,.pagination > .disabled > a:hover,.pagination > .disabled > a:focus':
  {
    color: '#777777',
    backgroundColor: '#fff',
    borderColor: '#ddd',
    cursor: 'not-allowed',
  },
  '.pagination-lg > li > a,.pagination-lg > li > span': {
    padding: '10px 16px',
    fontSize: '20px',
    lineHeight: '1.3333333',
  },
  '.pagination-lg > li:first-child > a,.pagination-lg > li:first-child > span':
  {
    borderBottomLeftRadius: '6px',
    borderTopLeftRadius: '6px',
  },
  '.pagination-lg > li:last-child > a,.pagination-lg > li:last-child > span': {
    borderBottomRightRadius: '6px',
    borderTopRightRadius: '6px',
  },
  '.pagination-sm > li > a,.pagination-sm > li > span': {
    padding: '5px 10px',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  '.pagination-sm > li:first-child > a,.pagination-sm > li:first-child > span':
  {
    borderBottomLeftRadius: '3px',
    borderTopLeftRadius: '3px',
  },
  '.pagination-sm > li:last-child > a,.pagination-sm > li:last-child > span': {
    borderBottomRightRadius: '3px',
    borderTopRightRadius: '3px',
  },
});

const StyledDropdown = styled.div((props) => ({
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  '.mainDropdownButton': {
    background: 'white',
    border: '1px solid black',
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 'unset',
    div: {
      span: {
        color: 'black',
      },
    },
  },
}));

const StyledHr = styled.div((props) => ({
  height: 20,
  width: 1,
  background: props.theme.palette.border.light,
  marginRight: props.theme.spacing.sm,
}));

export interface TableProps {
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  children: React.ReactNode;
  loading?: boolean;
  isEmpty?: boolean;
  expectedColumns?: number;
  expectedRows?: number;
  data?: PaginatedQueryResult<SearchResults | PersonSearchResultEntry, unknown>['resolvedData'];
  queryParams?: any;
  sortOptions?: Array<{ id: string; name: string; }>;
  sortOption?: string;
  setSortOption?: React.Dispatch<React.SetStateAction<string>>;
  nbSelectedRows?: number;
  export?: () => void;
  preview?: () => void;
  print?: boolean;
  history?: any;
  switchCase?: any;
}
export interface NonPaginatedTableProps extends TableProps {
  page?: never;
  onPageChange?: never;
  numRowsPerPage?: never;
  numTotalRows?: never;
  onNumberOfRowChange?: never;
  dataLength?: number;
}

export interface PaginatedTableProps extends TableProps {
  page: number;
  onPageChange: (newPage: number) => void;
  onNumberOfRowChange?: (newLimit: number) => void;
  numRowsPerPage: number;
  numTotalRows: number | undefined;
}

const TableContext = React.createContext({
  isEmpty: false,
  loading: false,
  expectedColumns: 0,
  expectedRows: 10,
});

export function Table (props: NonPaginatedTableProps | PaginatedTableProps) {
  const contextValue = React.useMemo(
    () => ({
      expectedColumns: props.expectedColumns || 0,
      expectedRows: props.expectedRows || 10,
      isEmpty: !!props.isEmpty,
      loading: !!props.loading,
    }),
    [props.isEmpty, props.loading, props.expectedRows, props.expectedColumns]
  );
  const resolvedPage = props.data?.page || (props.page as number);
  const totalResult =   props.numTotalRows || props.expectedRows;
  const startNum = resolvedPage * contextValue.expectedRows - (contextValue.expectedRows - 1)/*10 - 9*/;
  let endNum = startNum + (props.expectedRows || 1) - 1;
  if ( totalResult && endNum > totalResult){
    endNum = totalResult;
  }
  const loadingFirstTime = props.loading && !props.data;
  return (
    <div>
      <TableContext.Provider value={contextValue}>
        <Box padding="md">
          <HideOnPrint>
            <NumberOfSearchResults
              descriptionId={props['aria-describedby']}
              startNum={startNum}
              endNum={endNum}
              loadingFirstTime={loadingFirstTime}
              companyName={props.queryParams?.companyName}
              isPaginatedTable={isPaginatedTable(props)}
              isEmpty={props.isEmpty}
              numResults={totalResult}
              numSelected={props.nbSelectedRows}
            />
          </HideOnPrint>
          <Row>
          <div className="report--generated" style={{ padding: '16px 0' }}>
              { props.queryParams?.dateInfo && (
                <Text>
                  Report Generated: { props.queryParams.dateInfo }
                </Text>
              )}
            </div>
          </Row>
          <Row>
            <div style={{ width: '50%' }}>
              {!props.isEmpty && isPaginatedTable(props) && (
                <HideOnPrint>
                  <StyledDropdown>
                    {/* <Text mr={"sm"} size="lg">0 result selected</Text>
                    <StyledHr />
                    <Text mr={"xxl"} size="lg" >Clear</Text>
                    <Text mr={"xs"} size="lg">Show</Text> */}
                    <DropDownMenu
                      label={
                        <Row alignItems="center">
                          <Text weight="semi-bold" ml="xs" mr="xxs">
                            {props.numRowsPerPage}
                          </Text>
                          <ArrowDownIcon color="black" />
                        </Row>
                      }
                      options={OptionsList.map((option) => ({
                        text: String(option),
                        onClick: () => {
                          props.onNumberOfRowChange &&
                          props.onNumberOfRowChange(option)
                        },
                        // onChange: () => {
                        // 	props.onNumberOfRowChange &&
                        // 		props.onNumberOfRowChange(option)
                        // }
                      }))}
                    />
                  </StyledDropdown>
                </HideOnPrint>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', width: '50%' }}>
              { props.sortOptions && (
                <HideOnPrint>
                  <StyledDropdown>
                    <Text mr={"xs"} size="lg">Sort By</Text> 
                    <DropDownMenu
                      label={
                        <Row alignItems="center">
                          <Text weight="semi-bold" ml="xs" mr="xxs">
                            { props.sortOptions.filter(opt => opt.id === props.sortOption)[0].name }
                          </Text>
                          <ArrowDownIcon color="black" />
                        </Row>
                      }
                      options={props.sortOptions.map((option) => ({
                        text: String(option.name),
                        onClick: () => {
                          props.setSortOption && props.setSortOption(option.id)
                        }
                      }))}
                    />
                  </StyledDropdown>
                </HideOnPrint>   
              )}
            </div>       
          </Row>
        </Box>
        { props.queryParams !== undefined && (
          <Box padding="md">
            <CommonSearchSelectedResult
              searchCriteria={props.queryParams}
              history={props.history}
            />
          </Box>
        )}

        <HideOnPrint>
          <div style={{ textAlign: 'right' }}>
            { props.export && (
              <button className="button-print" style={{ margin: '14px' }} onClick={props.export}>
                <span className='button-icon'>
                  <CsvIcon size="sm" />
                </span>
                <span className='button-text'>&nbsp;Export CSV</span>
              </button>
            )}
            { props.preview && (
            <button className="button-print" style={{ margin: '14px 0px' }} onClick={props.preview}>
              <span className='button-icon'>
                <PrintIcon size="sm" />
              </span>
              <span className='button-text'>&nbsp;Print Detail</span>
            </button>
            )}
            { props.print && (
            <div style={{ float: 'right', margin: '14px', textAlign: 'right' }}>
              <PrintFriendly></PrintFriendly>
            </div>
            )}
          </div>
        </HideOnPrint>
        
        <TableContainer>
          {props.loading && (
            <TableLoadingSpinnerContainer>
              <LoadingSpinner />
            </TableLoadingSpinnerContainer>
          )}
          <StyledTable
            aria-labelledby={props['aria-labelledby']}
            aria-describedby={props['aria-describedby']}
          >
            {props.children}
          </StyledTable>
        </TableContainer>
        {!props.isEmpty && isPaginatedTable(props) && (
          <HideOnPrint>
            <TablePagination
              page={props.page}
              onPageChange={props.onPageChange}
              numRowsPerPage={props.numRowsPerPage}
              numTotalRows={props.numTotalRows}
            />
          </HideOnPrint>
        )}
      </TableContext.Provider>
    </div>
  );
}

const TableContainer = styled.div({
  position: 'relative',
  width: '100%',
  overflowX: 'auto',
});

const TableLoadingSpinnerContainer = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  backgroundColor: 'rgba(255,255,255,0.5)',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function isPaginatedTable (
  props: TableProps | PaginatedTableProps
): props is PaginatedTableProps {
  return Boolean('page' in props && typeof props.page === 'number');
}

export interface TablePaginationProps {
  page: number;
  onPageChange: (newPage: number) => void;
  numRowsPerPage: number;
  numTotalRows: number | undefined;
}

function TablePagination (props: TablePaginationProps) {
  const { page } = props;
  const numTotalRows = props.numTotalRows || 0;
  const numRowsPerPage = props.numRowsPerPage || 0;
  const numPages =
    numTotalRows === 0 || numRowsPerPage === 0
      ? 1
      : Math.ceil(numTotalRows / numRowsPerPage);
  return (
    <PaginateTable>
      <ReactPaginate
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
        breakLabel={'...'}
        pageCount={numPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        initialPage={page - 1}
        onPageChange={(e) => {
          props.onPageChange(e.selected);
        }}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </PaginateTable>
  );
}
const PageButton = styled.button<{ 'aria-current'?: boolean }>((props) => ({
  display: 'block',
  background: 'none',
  padding: props.theme.spacing.xs,
  borderRadius: '4px',
  border: 'none',
  fontSize: props.theme.typography.textSizes.lg,
  marginRight: props.theme.spacing.xs,
  outline: 'none',
  paddingBottom: props.theme.spacing.xxs,
  fontWeight: props['aria-current'] ? 700 : 500,
  borderBottom: props['aria-current']
    ? `3px solid ${props.theme.palette.primary.main}`
    : '3px solid transparent',
  borderBottomLeftRadius: props['aria-current'] ? 0 : undefined,
  borderBottomRightRadius: props['aria-current'] ? 0 : undefined,
  lineHeight: '1em',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  '&:focus-visible': {
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
}));

Table.Foot = function TableFoot (props: { children: React.ReactNode }) {
  return <tfoot>{props.children}</tfoot>;
};

Table.Head = function TableHead (props: { children: React.ReactNode }) {
  return <thead>{props.children}</thead>;
};

Table.Body = function TableBody (props: { children: React.ReactNode }) {
  return (
    <tbody>
      {props.children}
      <LoadingEmptyTablePlaceholder />
    </tbody>
  );
};

function LoadingEmptyTablePlaceholder () {
  const { isEmpty, loading, expectedRows, expectedColumns } =
    React.useContext(TableContext);

  const cols =
    expectedColumns === 0
      ? [
        <StyledCell key="col" colSpan={42}>
          <span aria-hidden>&nbsp;</span>
        </StyledCell>,
      ]
      : [];
  for (let i = 0; i < expectedColumns; i++) {
    cols.push(
      <StyledCell key={`key_${i}`}>
        <span aria-hidden>&nbsp;</span>
      </StyledCell>
    );
  }

  const rows = [];
  for (let i = 0; i < expectedRows; i++) {
    rows.push(<tr key={`key_${i}`}>{cols}</tr>);
  }

  if (!(isEmpty && loading)) {
    return null;
  }

  return <>{rows}</>;
}

const StyledHeaderCell = styled.th<{
  align?: 'left' | 'right' | 'center';
  minWidth?: 'md';
}>((props) => ({
  textAlign: props.align,
  backgroundColor: '#CACACA',
  padding: '12px',
  borderRight: '1px solid #555555',
  fontSize: props.theme.typography.textSizes.sm,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  '&:last-child': {
    borderRight: 'none',
  },
}));

Table.HeaderCell = function TableCell (props: {
  align?: 'left' | 'right' | 'center';
  children: React.ReactNode;
  colSpan?: number;
  hidePrint?: boolean;
}) {
  return (
    <StyledHeaderCell colSpan={props?.colSpan} align={props.align} className={props.hidePrint ? 'hide-print' : ''}>
      {props.children}
    </StyledHeaderCell>
  );
};

const StyledCell = styled.td<{
  align?: 'left' | 'right' | 'center';
  minWidth?: 'md';
}>((props) => ({
  padding: '12px',
  borderRight: '1px solid #D6D6D6',
  borderBottom: '1px solid #D6D6D6',
  fontSize: props.theme.typography.textSizes.sm,
  textAlign: props.align,
  minWidth: props.minWidth === 'md' ? '180px' : undefined,
  '&:last-child': {
    borderRight: 'none',
  },
}));

Table.Cell = function TableCell (props: {
  align?: 'left' | 'right' | 'center';
  minWidth?: 'md';
  hidePrint?: boolean;
  children: React.ReactNode;
}) {
  return (
    <StyledCell align={props.align} minWidth={props.minWidth} className={props.hidePrint ? 'hide-print' : ''}>
      {props.children}
    </StyledCell>
  );
};

Table.Row = function TableRow (props: { 
  hidePrint?: boolean;
  children: React.ReactNode;
}) {
  return <tr className={props.hidePrint ?  'hide-print' : 'show-print'}>{props.children}</tr>;
};

export interface TableCellSelectProps {
  index?: number;
  //numRows?: number;
  companyKey?: string;
  selectedKeys?: Array<string>;
  companyKeys?: Array<string>;
  setSelectedKeys?: React.Dispatch<React.SetStateAction<Array<string>>>;
}

Table.CellSelect = function TableCellSelect(props: TableCellSelectProps) {
  const [checkedRowsState, setCheckedRowsState] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (props.selectedKeys) {
      const selected = props.selectedKeys.filter((key) => key === props.companyKey)
      if (selected.length === 1) {
        setCheckedRowsState(selected);
      } else {
        setCheckedRowsState([]);
      }
    }
  }, [props.selectedKeys]);

  return (
    <Table.Cell hidePrint={true}>
      <input type='checkbox' className='table-checkbox' aria-label='Select/Deselect'
        checked={(checkedRowsState[0] === props.companyKey) || (props.selectedKeys && props.selectedKeys.filter((key) => key === props.companyKey).length === 1)}
        value={props.index} onChange={
        (e) => {
          if (props.companyKey !== undefined) {
            const isCheckedState = checkedRowsState[0] === props.companyKey;
            if ( !isCheckedState ) {
              setCheckedRowsState([props.companyKey]);
            } else {
              setCheckedRowsState([]);
            }
          }
          
          if (props.companyKey !== undefined && props.selectedKeys !== undefined && props.setSelectedKeys !== undefined) {
            let newKeys = [];
            const isSelected = props.selectedKeys.filter((key) => key === props.companyKey).length === 1;
            if ( !isSelected ) {
              newKeys=[...props.selectedKeys,props.companyKey];
            } else {
              newKeys = props.selectedKeys.filter((key) => key !== props.companyKey);
            }
            props.setSelectedKeys(newKeys);
          }
        }
      } />
    </Table.Cell>
  );
};

Table.SelectAllHeaderCell = function TableSelectAllHeaderCell(props: TableCellSelectProps) {
  return (
    <Table.HeaderCell hidePrint={true}>
      <label
        style={{ marginRight: '10px' }}
        htmlFor='chk_select_all'
      >
        <input type='checkbox' id='chk_select_all' checked={props.companyKeys && props.selectedKeys && props.companyKeys.length === props.selectedKeys.length} aria-label='Select/Deselect All' value={props.index} onChange={
          (e) => {
            if (e.target.checked && props.companyKeys !== undefined && props.setSelectedKeys !== undefined) {
              props.setSelectedKeys(props.companyKeys);
            }
            else if (!e.target.checked && props.setSelectedKeys !== undefined){
              props.setSelectedKeys([]);
            }
          }
        } />
        &nbsp;All
      </label>
    </Table.HeaderCell>
  );
};
