export const CategoryArray: Array<Array<string>> = new Array(
  new Array('0', ''),
  new Array('21', '--Financial Statistics'),
  new Array('22', '--Market Data'),
  new Array('25', '--Ratios - Summary'),
  new Array('93', '--Oil & Gas Operating Statistics'),
  new Array('94', '--Mining Operating Statistics'),
  new Array('95', '--Telecom Operating Statistics'),
  new Array('96', '--Wood Products Operating Statistics'),
  new Array('97', '--Utilities Operating Statistics'),
  new Array('98', '--Drillers Operating Statistics'),
  new Array(' ', ' ')
);

export const DataItemArray = new Array(
new Array('0', '', '0'),
new Array('21', '31.00', 'Accounts Payable & Accrued Liabilities ($000)'),
new Array('21', '3.00', 'Accounts Receivable ($000)'),
new Array('21', '114.70', 'Adj. Funds From Opers. Per Sh. ($)'),
new Array('21', '76.00', 'After-tax Income (Expense) ($000)'),
new Array('21', '68.03', 'Amort Of Debt Issue Costs ($000)'),
new Array('21', '161.00', 'Audit Fees ($000)'),
new Array('21', '142.00', 'Avg. No. Of Shs. O/s (Actual)'),
new Array('21', '122.02', 'Capital Disposals ($000)'),
new Array('21', '122.01', 'Capital Expenditures ($000)'),
new Array('21', '122.03', 'Capital Expenditures, Net ($000)'),
new Array('21', '111.70', 'Capital Gains Divd. Per Sh. ($)'),
new Array('21', '68.90', 'Capitalized Int - Notes ($000)'),
new Array('21', '68.04', 'Capitalized Interest ($000)'),
new Array('21', '1.00', 'Cash & Equivalent ($000)'),
new Array('21', '101.70', 'Cash Divd. Per Share ($)'),
new Array('21', '106.70', 'Cash Flow Per Share ($)'),
new Array('21', '121.00', 'Cash From Fin. Activs. ($000)'),
new Array('21', '122.00', 'Cash From Invest. Activs. ($000)'),
new Array('21', '120.00', 'Cash From Oper. Activs. ($000)'),
new Array('21', '3009.00', 'Change In Property - Net ($000)'),
new Array('21', '37.00', 'Claims Provisions ($000)'),
new Array('21', '64.14', 'Commissions - Ins ($000)'),
new Array('21', '90.90', 'Common Dividends Declared ($000)'),
new Array('21', '64.01', 'Cost Of Goods Sold ($000)'),
new Array('21', '64.09', 'Cost Of Real Estate Sales ($000)'),
new Array('21', '64.03', 'Cost Of Sales ($000)'),
new Array('21', '12.00', 'Current Assets ($000)'),
new Array('21', '40.00', 'Current Liabilities ($000)'),
new Array('21', '49.00', 'Deposits ($000)'),
new Array('21', '66.00', 'Deprec., Depl. & Amort. ($000)'),
new Array('21', '79.01', 'Disc. Opers., Equity Hldrs ($000)'),
new Array('21', '79.02', 'Disc. Opers., NCI ($000)'),
new Array('21', '68.05', 'Divids. - Debt Portion Pref Shs ($000)'),
new Array('21', '97.70', 'Earnings Per Share ($)'),
new Array('21', '95.70', 'Earns. Per Sh. Bef Disc Opers ($)'),
new Array('21', '96.70', 'Earns. Per Sh. Bef. Ext. Items ($)'),
new Array('21', '53.05', 'Equity Portion of Conv. Debs. ($000)'),
new Array('21', '20.02', 'Explor./Devel. Properties ($000)'),
new Array('21', '1020.00', 'Exploration Expense ($000)'),
new Array('21', '103.70', 'Extra Cash Divd. Per Sh. ($)'),
new Array('21', '104.70', 'Extra Stk. Divd. (Actual)'),
new Array('21', '109.70', 'Extra Stk. Divd. - Cash Equiv. ($)'),
new Array('21', '81.00', 'Extraordinary Items ($000)'),
new Array('21', '68.00', 'Finance Costs, Gross ($000)'),
new Array('21', '69.00', 'Finance Costs, Net ($000)'),
new Array('21', '63.00', 'Finance Income ($000)'),
new Array('21', '18.00', 'Fixed Assets, Net ($000)'),
new Array('21', '202.00', 'Foreign Sales Percent (%)'),
new Array('21', '113.70', 'Funds From Opers. Per Sh. ($)'),
new Array('21', '64.12', 'General & Admin. Expense ($000)'),
new Array('21', '79.00', 'Income From Disc. Opers. ($000)'),
new Array('21', '74.00', 'Income Taxes ($000)'),
new Array('21', '16.05', 'Income-producing Properties ($000)'),
new Array('21', '20.01', 'Intangibles, Net ($000)'),
new Array('21', '71.01', 'Interest Charged To Construction ($000)'),
new Array('21', '68.06', 'Interest Expense - Lease Liabs ($000)'),
new Array('21', '69.01', 'Interest Expense, Net ($000)'),
new Array('21', '2.00', 'Inventories ($000)'),
new Array('21', '70.00', 'Investment/Other Income ($000)'),
new Array('21', '41.00', 'Long-Term Debt, Gross ($000)'),
new Array('21', '42.00', 'Long-Term Debt, Net ($000)'),
new Array('21', '68.01', 'Long-Term Interest Expense ($000)'),
new Array('21', '13.00', 'Long-term Investments ($000)'),
new Array('21', '45.02', 'Long-Term Lease Liabilities ($000)'),
new Array('21', '75.00', 'Minority Interest ($000)'),
new Array('21', '112.70', 'Net Asset Value Per Share ($)'),
new Array('21', '129.00', 'Net Cash Position ($000)'),
new Array('21', '78.01', 'Net Inc Bef Disc Ops, Eqhldrs ($000)'),
new Array('21', '78.02', 'Net Inc Bef Disc Ops, NCI ($000)'),
new Array('21', '78.00', 'Net Inc. Bef. Disc. Opers. ($000)'),
new Array('21', '80.00', 'Net Inc. Bef. Ext. Items ($000)'),
new Array('21', '84.00', 'Net Inc. For Com. Shldrs. ($000)'),
new Array('21', '91.00', 'Net Inc. For Equity Hldrs. ($000)'),
new Array('21', '92.00', 'Net Inc. For Non-cont. Int. ($000)'),
new Array('21', '82.00', 'Net Income ($000)'),
new Array('21', '65.95', 'Net Interest Income (TEB) ($000)'),
new Array('21', '14.91', 'Net Non-performing Loans ($000)'),
new Array('21', '141.00', 'No. Of Shs. O/s (Actual)'),
new Array('21', '56.00', 'Non-Controlling Interest ($000)'),
new Array('21', '72.00', 'Non-Interest Expense - Banks/Trusts ($000)'),
new Array('21', '67.01', 'Non-Operating Overhead ($000)'),
new Array('21', '30.00', 'Notes & Loans Currently Due ($000)'),
new Array('21', '172.00', 'Number Of Employees (Actual)'),
new Array('21', '64.00', 'Operating Expense ($000)'),
new Array('21', '65.00', 'Operating Income ($000)'),
new Array('21', '162.00', 'Other Auditor Fees ($000)'),
new Array('21', '68.07', 'Other Interest Expense ($000)'),
new Array('21', '36.00', 'Other Liabilities ($000)'),
new Array('21', '130.00', 'Participating Policyholders\' Equity ($000)'),
new Array('21', '153.00', 'Pension Fund Surplus ($000)'),
new Array('21', '46.00', 'Policy Liabilities & Claims ($000)'),
new Array('21', '73.00', 'Pre-tax Income ($000)'),
new Array('21', '90.00', 'Pref. & Com. Divds. Declared ($000)'),
new Array('21', '50.00', 'Preferred Share Equity ($000)'),
new Array('21', '60.10', 'Premium Income ($000)'),
new Array('21', '16.08', 'Properties - Inventory ($000)'),
new Array('21', '16.07', 'Properties For Future Devel. ($000)'),
new Array('21', '16.04', 'Properties Held For Resale ($000)'),
new Array('21', '16.06', 'Properties Under Development ($000)'),
new Array('21', '19.00', 'Properties, Net ($000)'),
new Array('21', '67.02', 'Provision For Loan Losses ($000)'),
new Array('21', '60.06', 'Realized Invest. Gain (Loss) ($000)'),
new Array('21', '64.08', 'Rental Operation Expense ($000)'),
new Array('21', '1019.00', 'Research & Development Expense ($000)'),
new Array('21', '20.06', 'Right-of-Use Assets ($000)'),
new Array('21', '1017.00', 'Salaries & Benefits ($000)'),
new Array('21', '22.00', 'Segregated Fund Assets ($000)'),
new Array('21', '47.00', 'Segregated Fund Liabilities ($000)'),
new Array('21', '58.00', 'Shareholders\' Equity ($000)'),
new Array('21', '68.02', 'Short-Term Interest Expense ($000)'),
new Array('21', '108.70', 'Stk. Divd. - Cash Equiv. ($)'),
new Array('21', '102.70', 'Stk. Divd. Per Sh. (Actual)'),
new Array('21', '64.13', 'Stock-based Compensation ($000)'),
new Array('21', '29.00', 'Total Assets ($000)'),
new Array('21', '5102.00', 'Total Divd. Per Share ($)'),
new Array('21', '14.00', 'Total Loans ($000)'),
new Array('21', '60.00', 'Total Revenue ($000)'),
new Array('21', '152.00', 'Unfunded Pension Liability ($000)'),
new Array('21', '53.04', 'Unissued Capital Reserve ($000)'),
new Array('21', '60.07', 'Unrealized Invest. Gain (Loss) ($000)'),
new Array('21', '77.00', 'Unusual Items ($000)'),
new Array('21', '71.02', 'Write-downs/Write-offs ($000)'),
new Array('22', '-2.00', 'Beta - 60 Months'),
new Array('22', '-4.00', 'Dividend Rate Indicated ($)'),
new Array('22', '-5.00', 'Dividend Yield (%)'),
new Array('22', '-6.00', 'Earnings Per Share - 12 Mos ($)'),
new Array('22', '-26.00', 'Latest Dividend ($)'),
new Array('22', '-8.00', 'Market Capitalization ($000)'),
new Array('22', '-10.00', 'Price - 52-Week High ($)'),
new Array('22', '-11.00', 'Price - 52-Week Low ($)'),
new Array('22', '-9.00', 'Price - Current Close ($)'),
new Array('22', '-14.00', 'Price/Earnings'),
new Array('22', '-101.00', 'Total Return - 1 Yr (%)'),
new Array('22', '-103.00', 'Total Return - 3 Yr (%)'),
new Array('22', '-100.00', 'Volume - Avg 20 Day (Actual)'),
new Array('25', '5204.00', 'Accts Rec/Accts Pay'),
new Array('25', '5203.00', 'Acid Test (Quick Ratio)'),
new Array('25', '5304.00', 'After-Tax Items/Assets (%)'),
new Array('25', '5307.00', 'Apparent Tax Rate (%)'),
new Array('25', '5301.00', 'Asset Turnover'),
new Array('25', '5405.00', 'Assets - 1-Yr Growth (%)'),
new Array('25', '5107.00', 'Avg Price/Book Value'),
new Array('25', '5109.00', 'Avg Price/Cash Flow'),
new Array('25', '5104.00', 'Avg Price/Earnings'),
new Array('25', '5108.00', 'Avg Price/Sales'),
new Array('25', '180.90', 'Basel III Com Equity Tier 1'),
new Array('25', '180.91', 'Basel III Tier 1'),
new Array('25', '180.92', 'Basel III Tier 2'),
new Array('25', '180.93', 'Basel III Total Capital Ratio'),
new Array('25', '5403.00', 'Book Value/Share - 1-Yr Growth (%)'),
new Array('25', '5026.00', 'Book Value/Share - 10-Yr Growth (%)'),
new Array('25', '5024.00', 'Book Value/Share - 3-Yr Growth (%)'),
new Array('25', '5025.00', 'Book Value/Share - 5-Yr Growth (%)'),
new Array('25', '5218.00', 'Cash & Securities/Deposits (%)'),
new Array('25', '5402.00', 'Cash Flow - 1-Yr Growth (%)'),
new Array('25', '5208.00', 'Cash Flow/Net Inc Bef Discont Opers'),
new Array('25', '5409.00', 'Cash Flow/Share - 1-Yr Growth (%)'),
new Array('25', '5029.00', 'Cash Flow/Share - 10-Yr Growth (%)'),
new Array('25', '5027.00', 'Cash Flow/Share - 3-Yr Growth (%)'),
new Array('25', '5028.00', 'Cash Flow/Share - 5-Yr Grwoth (%)'),
new Array('25', '5201.00', 'Cash Flow/Total Debt (%)'),
new Array('25', '5219.00', 'Common Equity/Assets (%)'),
new Array('25', '5202.00', 'Current Ratio'),
new Array('25', '5206.00', 'Debt - Long Term/Common Equity'),
new Array('25', '5207.00', 'Debt - Total/Common Equity'),
new Array('25', '5213.00', 'Debt/Common Equity'),
new Array('25', '5407.00', 'Deposits - 1-Yr Growth (%)'),
new Array('25', '5005.00', 'Dividend Payout Ratio'),
new Array('25', '5103.00', 'Dividend Yield (%)'),
new Array('25', '5404.00', 'Dividends/Share - 1-Yr Growth (%)'),
new Array('25', '5023.00', 'Dividends/Share - 10-Yr Growth (%)'),
new Array('25', '5021.00', 'Dividends/Share - 3-Yr Growth (%)'),
new Array('25', '5022.00', 'Dividends/Share - 5-Yr Growth (%)'),
new Array('25', '5112.00', 'Earnings Yield (%)'),
new Array('25', '5101.00', 'Earnings/Share - 1-Yr Growth (%)'),
new Array('25', '5020.00', 'Earnings/Share - 10-Yr Growth (%)'),
new Array('25', '5018.00', 'Earnings/Share - 3-Yr Growth (%)'),
new Array('25', '5019.00', 'Earnings/Share - 5-Yr Growth (%)'),
new Array('25', '5300.00', 'EBIT Margin (%)'),
new Array('25', '5113.00', 'Estimated Payback (Yrs) (Actual)'),
new Array('25', '5302.00', 'Interest Burden (%)'),
new Array('25', '5212.00', 'Interest Capitalized/Interest (%)'),
new Array('25', '5209.00', 'Interest Coverage'),
new Array('25', '5221.00', 'Interest Rate Sensitivity/Assets (%)'),
new Array('25', '5305.00', 'Leverage'),
new Array('25', '5406.00', 'Loans - 1-Yr Growth (%)'),
new Array('25', '5506.00', 'Net Interest Margin (%)'),
new Array('25', '5502.00', 'Net Profit Margin (%)'),
new Array('25', '5509.00', 'Non Int Expenses/Revenue (%)'),
new Array('25', '5508.00', 'Non-Int Expenses/Assets (%)'),
new Array('25', '5215.00', 'Non-Perform Loans/Equity (%)'),
new Array('25', '5214.00', 'Non-Perform Loans/Loans (%)'),
new Array('25', '5211.00', 'Operating Leverage'),
new Array('25', '5500.00', 'Operating Margin (%)'),
new Array('25', '5507.00', 'Other Income/Assets (%)'),
new Array('25', '5501.00', 'Pre-Tax Margin (%)'),
new Array('25', '5308.00', 'Pre-Tax Return On Assets (%)'),
new Array('25', '5217.00', 'Provision For Losses/Assets (%)'),
new Array('25', '5216.00', 'Provision For Losses/Loans (%)'),
new Array('25', '5220.00', 'Reserves/Non-Perform Loans (%)'),
new Array('25', '5303.00', 'Retention (%)'),
new Array('25', '5504.00', 'Return On Assets (%)'),
new Array('25', '5503.00', 'Return On Equity (%)'),
new Array('25', '5505.00', 'Return On Invested Capital (%)'),
new Array('25', '5510.00', 'Return On Risk-Adjusted Assets (%)'),
new Array('25', '5210.00', 'ROE/ROA'),
new Array('25', '5400.00', 'Sales - 1-Yr Growth (%)'),
new Array('25', '5603.00', 'Sales/Cash & Equiv'),
new Array('25', '5602.00', 'Sales/Fixed Assets'),
new Array('25', '5601.00', 'Sales/Inventory'),
new Array('25', '5600.00', 'Sales/Receivables'),
new Array('25', '5401.00', 'Sustainable Growth (%)'),
new Array('25', '5205.00', 'Working Capital/Total Assets (%)'),
new Array('93', '800.14', 'Avg. BOE price, $/bbl ($)'),
new Array('93', '800.15', 'Avg. BOE price, US$/bbl ($)'),
new Array('93', '800.05', 'Avg. BOE prod., bbl/d (Actual)'),
new Array('93', '800.12', 'Avg. gas price, $/mcf ($)'),
new Array('93', '800.13', 'Avg. gas price, US$/mcf ($)'),
new Array('93', '800.04', 'Avg. gas prod., mcf/d (Actual)'),
new Array('93', '800.28', 'Avg. heavy oil price, $/bbl ($)'),
new Array('93', '800.30', 'Avg. heavy oil price, US$/bbl ($)'),
new Array('93', '800.27', 'Avg. light oil price, $/bbl ($)'),
new Array('93', '800.29', 'Avg. light oil price, US$/bbl ($)'),
new Array('93', '800.08', 'Avg. NGL price, $/bbl ($)'),
new Array('93', '800.09', 'Avg. NGL price, US$/bbl ($)'),
new Array('93', '800.02', 'Avg. NGL prod., bbl/d (Actual)'),
new Array('93', '800.10', 'Avg. oil & NGL price, $/bbl ($)'),
new Array('93', '800.11', 'Avg. oil & NGL price, US$/bbl ($)'),
new Array('93', '800.03', 'Avg. oil & NGL prod., bbl/d (Actual)'),
new Array('93', '800.06', 'Avg. oil price, $/bbl ($)'),
new Array('93', '800.07', 'Avg. oil price, US$/bbl ($)'),
new Array('93', '800.01', 'Avg. oil prod., bbl/d (Actual)'),
new Array('93', '800.24', 'BOE reserves, gross, mbbl (Actual)'),
new Array('93', '800.25', 'BOE reserves, net, mbbl (Actual)'),
new Array('93', '800.22', 'Gas reserves, gross, mmcf (Actual)'),
new Array('93', '800.23', 'Gas reserves, net, mmcf (Actual)'),
new Array('93', '800.18', 'NGL reserves, gross, mbbl (Actual)'),
new Array('93', '800.19', 'NGL reserves, net, mbbl (Actual)'),
new Array('93', '800.20', 'Oil & NGL reserves, gross, mbbl (Actual)'),
new Array('93', '800.21', 'Oil & NGL reserves, net, mbbl (Actual)'),
new Array('93', '800.16', 'Oil reserves, gross, mbbl (Actual)'),
new Array('93', '800.17', 'Oil reserves, net, mbbl (Actual)'),
new Array('93', '800.26', 'Reserve life index, yrs. (Actual)'),
new Array('94', '810.42', 'Avg. real molybdenum price, $/lb. ($)'),
new Array('94', '810.43', 'Avg. real molybdenum price, US$/lb. ($)'),
new Array('94', '810.60', 'Avg. real nickel price, $/lb. ($)'),
new Array('94', '810.61', 'Avg. real nickel price, US$/lb. ($)'),
new Array('94', '810.53', 'Avg. real palladium price, $/oz. ($)'),
new Array('94', '810.54', 'Avg. real palladium price, US$/oz. ($)'),
new Array('94', '810.70', 'Avg. real. coal price, $/tonne ($)'),
new Array('94', '810.19', 'Avg. real. copper price, $/lb ($)'),
new Array('94', '810.20', 'Avg. real. copper price, US$/lb ($)'),
new Array('94', '810.05', 'Avg. real. gold price, $/oz ($)'),
new Array('94', '810.06', 'Avg. real. gold price, US$/oz ($)'),
new Array('94', '810.65', 'Avg. real. lead price, $/lb. ($)'),
new Array('94', '810.66', 'Avg. real. lead price, US$/lb. ($)'),
new Array('94', '810.37', 'Avg. real. methanol price, US$/tonne ($)'),
new Array('94', '810.12', 'Avg. real. silver price, $/oz ($)'),
new Array('94', '810.13', 'Avg. real. silver price, US$/oz ($)'),
new Array('94', '810.72', 'Avg. real. uranium price, $/lb. ($)'),
new Array('94', '810.73', 'Avg. real. uranium price, US$/lb. ($)'),
new Array('94', '810.68', 'Avg. real. zinc price, $/lb. ($)'),
new Array('94', '810.69', 'Avg. real. zinc price, US$/lb. ($)'),
new Array('94', '810.71', 'Coal cash cost, $/tonne ($)'),
new Array('94', '810.28', 'Coal prod., tonnes (Actual)'),
new Array('94', '810.21', 'Copper cash cost, $/lb. ($)'),
new Array('94', '810.22', 'Copper cash cost, US$/lb. ($)'),
new Array('94', '810.52', 'Copper cathode prod., lbs. (Actual)'),
new Array('94', '810.51', 'Copper cathode sales, lbs. (Actual)'),
new Array('94', '810.27', 'Copper cathodes, tonnes (Actual)'),
new Array('94', '810.26', 'Copper in concentrate, tonnes (Actual)'),
new Array('94', '810.17', 'Copper prod., lbs. (Actual)'),
new Array('94', '810.23', 'Copper reserves, lbs. (Actual)'),
new Array('94', '810.18', 'Copper sales, lbs. (Actual)'),
new Array('94', '810.07', 'Gold cash cost, $/oz. ($)'),
new Array('94', '810.08', 'Gold cash cost, US$/oz. ($)'),
new Array('94', '810.03', 'Gold prod., oz. (Actual)'),
new Array('94', '810.09', 'Gold reserves, oz. (Actual)'),
new Array('94', '810.04', 'Gold sales, oz. (Actual)'),
new Array('94', '810.31', 'Lead in concentrate, tonnes (Actual)'),
new Array('94', '810.38', 'Lead prod., lbs. (Actual)'),
new Array('94', '810.64', 'Lead sales, lbs. (Actual)'),
new Array('94', '810.35', 'Methanol production, tonnes (Actual)'),
new Array('94', '810.44', 'Molybdenum cash cost, $/lb. ($)'),
new Array('94', '810.45', 'Molybdenum cash cost, US$/lb. ($)'),
new Array('94', '810.29', 'Molybdenum in concentrate, lbs. (Actual)'),
new Array('94', '810.40', 'Molybdenum prod., lbs. (Actual)'),
new Array('94', '810.41', 'Molybdenum sales, lbs. (Actual)'),
new Array('94', '810.62', 'Nickel cash cost, $/lb. ($)'),
new Array('94', '810.63', 'Nickel cash cost, US$/lb. ($)'),
new Array('94', '810.50', 'Nickel prod., lbs. (Actual)'),
new Array('94', '810.58', 'Nickel sales, lbs. (Actual)'),
new Array('94', '810.55', 'Palladium cash cost, $/oz. ($)'),
new Array('94', '810.56', 'Palladium cash cost, US$/oz. ($)'),
new Array('94', '810.49', 'Palladium prod., oz. (Actual)'),
new Array('94', '810.57', 'Palladium sales, oz. (Actual)'),
new Array('94', '810.48', 'Platinum prod., oz. (Actual)'),
new Array('94', '810.59', 'Platinum sales, oz. (Actual)'),
new Array('94', '810.36', 'Purchased methanol, tonnes (Actual)'),
new Array('94', '810.30', 'Refined lead, tonnes (Actual)'),
new Array('94', '810.24', 'Refined zinc, tonnes (Actual)'),
new Array('94', '810.14', 'Silver cash cost, $/oz. ($)'),
new Array('94', '810.15', 'Silver cash cost, US$/oz. ($)'),
new Array('94', '810.10', 'Silver prod., oz. (Actual)'),
new Array('94', '810.16', 'Silver reserves, oz. (Actual)'),
new Array('94', '810.11', 'Silver sales, oz. (Actual)'),
new Array('94', '810.02', 'Tonnes milled (Actual)'),
new Array('94', '810.47', 'Tonnes mined (Actual)'),
new Array('94', '810.01', 'Tons milled (Actual)'),
new Array('94', '810.46', 'Tons mined (Actual)'),
new Array('94', '810.32', 'Uranium prod., lbs. (Actual)'),
new Array('94', '810.34', 'Uranium reserves, lbs. (Actual)'),
new Array('94', '810.33', 'Uranium sales, lbs. (Actual)'),
new Array('94', '810.25', 'Zinc in concentrate, tonnes (Actual)'),
new Array('94', '810.39', 'Zinc prod., lbs. (Actual)'),
new Array('94', '810.67', 'Zinc sales, lbs. (Actual)'),
new Array('95', '830.03', 'Basic cable subscribers (Actual)'),
new Array('95', '830.06', 'Cable telephony lines (Actual)'),
new Array('95', '830.04', 'Digital cable subscribers (Actual)'),
new Array('95', '830.05', 'Internet subscribers (Actual)'),
new Array('95', '830.07', 'IPTV subscribers (Actual)'),
new Array('95', '830.01', 'Network access services (Actual)'),
new Array('95', '830.02', 'Wireless subscribers (Actual)'),
new Array('96', '840.01', 'Annual allowable cut, m³ (Actual)'),
new Array('96', '840.08', 'Avg. log price, $/m³ ($)'),
new Array('96', '840.04', 'Avg. lumber price, $/mfbm ($)'),
new Array('96', '840.15', 'BCTMP prod., tonnes (Actual)'),
new Array('96', '840.10', 'Kraft paper prod., tonnes (Actual)'),
new Array('96', '840.05', 'Log prod., m³ (Actual)'),
new Array('96', '840.06', 'Log purchases, m³ (Actual)'),
new Array('96', '840.07', 'Log sales, m³ (Actual)'),
new Array('96', '840.02', 'Lumber prod., mfbm (Actual)'),
new Array('96', '840.03', 'Lumber sales, mfbm (Actual)'),
new Array('96', '840.14', 'LVL prod., mcf (Actual)'),
new Array('96', '840.09', 'Market pulp prod., tonnes (Actual)'),
new Array('96', '840.12', 'MDF prod., msf (Actual)'),
new Array('96', '840.16', 'NBSK pulp prod., tonnes (Actual)'),
new Array('96', '840.17', 'Newsprint prod., tonnes (Actual)'),
new Array('96', '840.13', 'Plywood prod., msf (Actual)'),
new Array('97', '850.13', 'Avg. electric price, $/MWh ($)'),
new Array('97', '850.06', 'Distribution lines, km (Actual)'),
new Array('97', '850.03', 'Electric gen., GWh (Actual)'),
new Array('97', '850.01', 'Electric sales, GWh (Actual)'),
new Array('97', '850.08', 'Electric. customers (Actual)'),
new Array('97', '850.07', 'Electric. distrib., million KWh (Actual)'),
new Array('97', '850.02', 'Generating capacity, MW (Actual)'),
new Array('97', '850.04', 'Independent pwr. prod., MW (Actual)'),
new Array('97', '850.12', 'Nat. gas customers (Actual)'),
new Array('97', '850.09', 'Nat. gas distrib. pipelines, km (Actual)'),
new Array('97', '850.11', 'Nat. gas distrib., pj (Actual)'),
new Array('97', '850.10', 'Nat. gas transmission pipelines, km (Actual)'),
new Array('97', '850.05', 'Transmission lines, km (Actual)'),
new Array('98', '860.02', 'Drill rig operating days (Actual)'),
new Array('98', '860.03', 'Drill rig rev. per oper. day, $ ($)'),
new Array('98', '860.04', 'Drill rig rev. per oper. day, US$ ($)'),
new Array('98', '860.05', 'Drill rig utilization rate, % (%)'),
new Array('98', '860.01', 'No. of drill rigs (Actual)'),
new Array('98', '860.06', 'No. of service rigs (Actual)'),
new Array('98', '860.07', 'Service rig operating hrs. (Actual)'),
new Array('98', '860.08', 'Service rig rev. per oper. hr. $ ($)'),
new Array('98', '860.09', 'Service rig rev. per oper. hr. US$ ($)'),
new Array('98', '860.10', 'Service rig utilization rate, % (%)'),
new Array(' ', ' ', ' ')
);

export const YearArray: Array<Array<string>> = new Array(
  new Array('', ''),
  new Array('MRA', 'MRA'),
  new Array('2021', '2021'),
  new Array('2020', '2020'),
  new Array('2019', '2019'),
  new Array('2018', '2018'),
  new Array('2017', '2017'),
  new Array('2016', '2016'),
  new Array('2015', '2015'),
  new Array('2014', '2014'),
  new Array('2013', '2013'),
  new Array('2012', '2012'),
  new Array('', '')
);

export const QYearArray = new Array();

export const AuditorsArray = new Array(new Array('0', ''),
new Array('98165', 'A Chan & Company LLP, C.A.'),
new Array('154229', 'A.R.T. Tax and Audit, C.A.'),
new Array('107317', 'ACAL Group, C.P.A.'),
new Array('166366', 'Accell Audit & Compliance, C.P.A.'),
new Array('124740', 'Adam Sung Kim Ltd., C.A.'),
new Array('165621', 'AGT Partners LLP, C.A.'),
new Array('86519', 'Allard, Lévesque S.E.N.C., C.A.'),
new Array('148143', 'AMC Auditing, LLC, C.P.A.'),
new Array('86882', 'Amisano Hanson, C.A.'),
new Array('165582', 'Antares Professional Corporation, C.A.'),
new Array('130254', 'Anton & Chia, LLP, C.P.A.'),
new Array('87782', 'Anton, Bryson & Schindler, C.A.'),
new Array('141130', 'AO PricewaterhouseCoopers Audit, C.A.'),
new Array('129645', 'Arsenault Best Cameron Ellis, C.A.'),
new Array('169659', 'Assure CPA, LLC'),
new Array('133062', 'ATC Management Group Inc.'),
new Array('87998', 'Audet, Beaudoin, Girard, C.A.'),
new Array('86254', 'Auditor General of Alberta'),
new Array('87786', 'Auditor General of British Columbia'),
new Array('86255', 'Auditor General of Canada'),
new Array('87787', 'Auditor General of Manitoba'),
new Array('87789', 'Auditor General of Ontario'),
new Array('87224', 'Auditor General of Quebec'),
new Array('109288', 'Baker Tilly HMA LLP, C.A.'),
new Array('166276', 'Baker Tilly Isle of Man LLC'),
new Array('151363', 'Baker Tilly US, LLP, C.P.A.'),
new Array('58730', 'Baker Tilly WM LLP, C.A.'),
new Array('95269', 'BDO Audit (WA) Pty Ltd., C.A.'),
new Array('168530', 'BDO Audit Pty Ltd.'),
new Array('15696', 'BDO Canada LLP, C.A.'),
new Array('86316', 'BDO Dunwoody Ward Mallette, C.A.'),
new Array('121872', 'BDO East Coast Partnership'),
new Array('87819', 'BDO Limited, C.P.A.'),
new Array('110020', 'BDO LLP, C.A.'),
new Array('139480', 'BDO RCS Auditores Independentes SS'),
new Array('86256', 'BDO Seidman LLP, C.P.A.'),
new Array('136618', 'BDO Unicon AO'),
new Array('110036', 'BDO USA LLP, C.P.A.'),
new Array('153952', 'BDO Ziv Haft, C.P.A.'),
new Array('86262', 'Beauchamp & Company, C.A.'),
new Array('86263', 'Bedford Curry & Co., C.A.'),
new Array('87741', 'Bélanger Dalcourt CPA Inc., C.A.'),
new Array('171129', 'Bentleys Audit & Corporate (WA) Pty Ltd.'),
new Array('87950', 'Berkovits, Lago & Company, LLP, C.P.A.'),
new Array('117595', 'Berman & Company, P.A., C.P.A.'),
new Array('87661', 'Berris Mangan, C.A.'),
new Array('138868', 'BGD LLP, C.A.'),
new Array('87790', 'Blanchette Vachon et Associés, C.A.'),
new Array('87833', 'Boisjoli Sabbag LLP, C.A.'),
new Array('87624', 'Bourassa Boyer Inc., C.A.'),
new Array('87199', 'Bratt Fremeth Star, C.A.'),
new Array('121582', 'Brenham & Co.'),
new Array('158596', 'Brightman Almagor Zohar & Co., C.P.A.'),
new Array('87601', 'Brunet Roy Dubé LLP, C.A.'),
new Array('86274', 'Buchanan Barry LLP, C.A.'),
new Array('86983', 'Buckley Dodds LLP, C.A.'),
new Array('106211', 'Calvetti, Ferguson & Wagner, P.C., C.P.A.'),
new Array('126239', 'Calvista LLP, C.A.'),
new Array('170285', 'Centurion ZD CPA & Co.'),
new Array('87063', 'Chang Lee LLP, C.A.'),
new Array('87273', 'Charlton & Company, C.A.'),
new Array('86680', 'Clancy & Company, C.A.'),
new Array('151584', 'Clearhouse LLP, C.A.'),
new Array('82354', 'Collins Barrow Calgary LLP, C.A.'),
new Array('87871', 'Collins Barrow Edmonton LLP, C.A.'),
new Array('87549', 'Collins Barrow, C.A.'),
new Array('106226', 'Coulter & Justus, P.C., C.P.A.'),
new Array('140613', 'Crowe Clark Whitehall LLP, C.A.'),
new Array('125308', 'Crowe Horwath LLP, C.P.A.'),
new Array('86398', 'Crowe MacKay LLP, C.A.'),
new Array('16183', 'Crowe Soberman LLP, C.A.'),
new Array('87771', 'Culver & Co., C.A.'),
new Array('86331', 'Cunningham LLP, C.A.'),
new Array('87543', 'CW Partners LLP, C.A.'),
new Array('87371', 'Czechowsky & Graham, C.A.'),
new Array('86317', 'D & H Group LLP, C.A.'),
new Array('83365', 'Dale Matheson Carr-Hilton LaBonte LLP, C.A.'),
new Array('87475', 'Dallaire & Lapointe Inc., C.A.'),
new Array('82786', 'Danziger Hochman Partners LLP, C.A.'),
new Array('87855', 'Daurio & Franklin LLP, C.A.'),
new Array('86306', 'Davidson & Company LLP, C.A.'),
new Array('87884', 'DCS, C.A.'),
new Array('112875', 'De Joya Griffith & Company, LLC, C.P.A.'),
new Array('86308', 'De Visser Gray LLP, C.A.'),
new Array('108221', 'DeCoria, Maichel & Teague, C.P.A.'),
new Array('154228', 'Deloitte & Associés'),
new Array('173819', 'Deloitte & Touche Ltda.'),
new Array('147528', 'Deloitte Audit S.A.R.L.'),
new Array('33723', 'Deloitte LLP, C.A.'),
new Array('88037', 'Deloitte LLP, C.P.A.'),
new Array('115041', 'Deloitte S.A.'),
new Array('38936', 'Deloitte s.e.n.c.r.l., C.A.'),
new Array('64638', 'Deloitte Touche Tohmatsu, C.A.'),
new Array('104138', 'Deloitte Touche Tohmatsu, C.P.A.'),
new Array('87663', 'Delves Freer Anderson Raniga Caine, C.G.A.'),
new Array('79354', 'Demers Beaulne LLP, C.A.'),
new Array('87840', 'Dixon Hughes Goodman LLP, C.P.A.'),
new Array('87675', 'DMCT, LLP, C.A.'),
new Array('86416', 'DNTW Chartered Accountants, LLP, C.A.'),
new Array('126567', 'DNTW Toronto LLP, C.A.'),
new Array('119931', 'EAL Partners, C.A.'),
new Array('150905', 'EBT Chartered Accountants LLP'),
new Array('87027', 'Edmund Cachia & Co. LLP, C.A.'),
new Array('86318', 'Edward A. Jakubo, C.A.'),
new Array('127394', 'EisnerAmper LLP, C.P.A.'),
new Array('86920', 'EKS&H LLLP, C.P.A.'),
new Array('88073', 'EPR Daye Kelly & Associates, C.G.A.'),
new Array('143765', 'Ernst & Young Cyprus Ltd.'),
new Array('31470', 'Ernst & Young LLP, C.A.'),
new Array('88036', 'Ernst & Young LLP, C.P.A.'),
new Array('112876', 'Farber Hass Hurley LLP, C.P.A.'),
new Array('138297', 'Fareed Sheik LLP, C.A.'),
new Array('87525', 'Fazzari & Partners LLP, C.A.'),
new Array('105861', 'FBL LLP, C.G.A.'),
new Array('86887', 'Feldman & Associates, LLP, C.A.'),
new Array('87301', 'Fernandez Young and Associates, C.G.A.'),
new Array('87387', 'Flabbi & Associates LLP, C.A.'),
new Array('161513', 'Foley Broderick LLP, C.A.'),
new Array('86555', 'Frackt, Piro & Woodfine, C.A.'),
new Array('168507', 'Frazier & Deeter, LLC, C.P.A.'),
new Array('142881', 'Fruci & Associates II, PLLC, C.P.A.'),
new Array('87775', 'Galloway Botteselle & Company, C.G.A.'),
new Array('86369', 'Geib & Company Professional Corporation, C.A.'),
new Array('123187', 'GHP Horwarth, P.C.'),
new Array('87246', 'Ghulam M. Malik, C.A.'),
new Array('126466', 'Goodman & Associates LLP, C.A.'),
new Array('86341', 'Goodman Parker & Associates, C.A.'),
new Array('86794', 'Gordon K.W. Gee, C.A.'),
new Array('171188', 'Gordon Levy Limited'),
new Array('139975', 'Gram LLP, C.A.'),
new Array('59515', 'Grant Thornton LLP, C.A.'),
new Array('88038', 'Grant Thornton LLP, C.P.A.'),
new Array('162804', 'Grant Thornton New Zealand Audit Partnership, C.A.'),
new Array('109095', 'Grant Thornton UK LLP, C.A.'),
new Array('86781', 'Grant Thornton, C.P.A.'),
new Array('154708', 'GreenGrowth, C.P.A.'),
new Array('87757', 'Guimond Lavallée Inc., C.A.'),
new Array('165308', 'Hall & Company Certified Public Accountants & Consultants, Inc., C.P.A.'),
new Array('166716', 'Harbourside CPA LLP, C.A.'),
new Array('87317', 'Harris & Partners LLP, C.A.'),
new Array('156689', 'Haskell & White LLP'),
new Array('87792', 'Hawkings Epp Dumont LLP, C.A.'),
new Array('86346', 'Hay & Watson, C.A.'),
new Array('129093', 'Haynie & Company, C.P.A.'),
new Array('87214', 'Hein & Associates LLP, C.P.A.'),
new Array('63072', 'Hergott Duval Stack LLP, C.A.'),
new Array('137047', 'Hillary CPA Group, LLC, C.P.A.'),
new Array('87540', 'HLB Cinnamon, Jang, Willoughby & Company, C.A.'),
new Array('106496', 'HLB Mann Judd, C.A.'),
new Array('87623', 'Horwath Leebosh Appel LLP, C.A.'),
new Array('33848', 'Horwath Orenstein LLP, C.A.'),
new Array('90393', 'HS & Partners LLP, C.A.'),
new Array('87372', 'Hudson LLP, C.A.'),
new Array('96838', 'I Vellmer Inc., C.A.'),
new Array('128578', 'I&A Professional Corporation, C.P.A.'),
new Array('87060', 'I. J. Boga, C.A.'),
new Array('87025', 'Jackson & Company, C.A.'),
new Array('87456', 'James Stafford, Inc., C.A.'),
new Array('109844', 'John Scholz, C.A.'),
new Array('87534', 'Jones & O’Connell LLP, C.A.'),
new Array('86542', 'Jones, Richards & Company, C.G.A.'),
new Array('141722', 'JSC KPMG'),
new Array('96637', 'K. R. Margetson Ltd., C.A.'),
new Array('86842', 'Kanester, Johal, C.A.'),
new Array('105369', 'Ken Lee & Company, C.A.'),
new Array('48117', 'Kenway Mack Slusarchuk Stewart LLP, C.A.'),
new Array('86382', 'Kingston Ross Pasnak LLP, C.A.'),
new Array('86888', 'Klasner & Solomon LLP, C.A.'),
new Array('87731', 'Kost Forer Gabbay & Kasierer, C.A.'),
new Array('127569', 'KPMG Audit Limited'),
new Array('87476', 'KPMG Audit Plc, C.A.'),
new Array('87401', 'KPMG Deutsche Treuhand-Gesellschaft AG'),
new Array('70663', 'KPMG Inc.'),
new Array('31039', 'KPMG LLP, C.A.'),
new Array('88040', 'KPMG LLP, C.P.A.'),
new Array('87836', 'KPMG, C.A.'),
new Array('100784', 'KPMG, C.P.A.'),
new Array('86469', 'Kraft Berger LLP, C.A.'),
new Array('125463', 'Kreston GTA LLP, C.A.'),
new Array('87284', 'KWCO, PC, C.P.A.'),
new Array('149664', 'L J Soldinger Associates, LLC'),
new Array('87332', 'Lancaster & David, C.A.'),
new Array('87508', 'Lane Gorman Trubitt, L.L.P., C.P.A.'),
new Array('87949', 'Larry O\' Donnell, C.P.A.'),
new Array('121929', 'Lawler Hacketts Audit, C.A.'),
new Array('87858', 'LEED Advisors Inc., C.A.'),
new Array('94597', 'Lemieux Nolet LLP, C.A.'),
new Array('86390', 'Levitt, Feldstein, Dubin, Epstein, Pinsky, C.A.'),
new Array('87626', 'Lippman Leebosh April, C.A.'),
new Array('61063', 'Lipton LLP, C.A.'),
new Array('113632', 'Littlejohn LLP, C.A.'),
new Array('86392', 'Loewen, Stronach & Co., C.A.'),
new Array('103349', 'M&K CPAS, PLLC, C.P.A.'),
new Array('149170', 'Macias Gini & O\'Connell LLP, C.P.A.'),
new Array('87735', 'Magnus Chartered Accountants LLP, C.A.'),
new Array('92952', 'Mahendra CA Professional Corporation, C.A.'),
new Array('87482', 'Maldaner Crooks Watson, C.A.'),
new Array('86400', 'Mallette LLP, C.A.'),
new Array('88120', 'MaloneBailey, LLP, C.P.A.'),
new Array('86320', 'Manning Elliott LLP, C.A.'),
new Array('131448', 'Mao & Ying LLP, C.A.'),
new Array('113362', 'Marcum LLP, C.P.A.'),
new Array('87837', 'Marinucci & Company, C.A.'),
new Array('109460', 'MartinelliMick PLLC, C.P.A.'),
new Array('86404', 'Massie Turcotte & Associates, C.A.'),
new Array('86345', 'Mazars Harel Drouin, LLP, C.A.'),
new Array('142353', 'Mazars LLP, C.A.'),
new Array('86709', 'McCarney Greenwood LLP, C.A.'),
new Array('86653', 'McCormack, Su & Company Inc., C.G.A.'),
new Array('86409', 'McGovern Hurley LLP, C.A.'),
new Array('87615', 'Millard, DesLauriers & Shoemaker LLP, C.A.'),
new Array('86550', 'Minni, Clark & Company, C.G.A.'),
new Array('33951', 'Mintz & Partners LLP, C.A.'),
new Array('36840', 'MNP LLP, C.A.'),
new Array('86418', 'Moen and Company, C.A.'),
new Array('111031', 'Moore Stephens Stylianou & Co.'),
new Array('86419', 'Morgan & Company LLP, C.A.'),
new Array('116254', 'Morrill & Associates, LLC, C.P.A.'),
new Array('86422', 'Moss Adams LLP, C.P.A.'),
new Array('87799', 'MPG, C.A.'),
new Array('116347', 'MS Partners LLP, C.A.'),
new Array('86295', 'MSCM LLP, C.A.'),
new Array('168510', 'MSLL CPA LLP'),
new Array('86643', 'Mulleny, Royce, C.A.'),
new Array('86874', 'Murray A. Finkelman, C.A.'),
new Array('88116', 'Nawaz Taub Noor Wasserman LLP, C.A.'),
new Array('130928', 'ND LLP, C.A.'),
new Array('104384', 'Nelson Hagerman, C.A.'),
new Array('108436', 'Nexia Brisbane Audit Pty Ltd., C.A.'),
new Array('87082', 'Nexia Friedman, C.A.'),
new Array('87820', 'NPT LLP, C.A.'),
new Array('169160', 'Nunn Hayward LLP'),
new Array('87958', 'NVS Chartered Accountants Professional Corporation, C.A.'),
new Array('87650', 'Palmer Reed, C.A.'),
new Array('86788', 'Pannell Kerr Forster LLP, C.A.'),
new Array('101735', 'Pannell Kerr Forster of Texas, P.C., C.P.A.'),
new Array('66985', 'Peat Marwick Thorne Inc., C.A.'),
new Array('87305', 'Pennock Acheson Nielsen Devany, C.A.'),
new Array('87014', 'Perreault, Wolman, Grzywacz & Co., C.A.'),
new Array('153316', 'Personal Finance Consulting Professional Corporation, C.P.A.'),
new Array('86500', 'Peterson Sullivan PLLC, C.P.A.'),
new Array('34026', 'Petrie Raymond LLP, C.A.'),
new Array('87582', 'Philip Gigan, C.A.'),
new Array('160745', 'Pitcher Partners, C.A.'),
new Array('60375', 'Pivotal LLP, C.A.'),
new Array('87842', 'PKF (UK) LLP, C.A.'),
new Array('86353', 'PKF Hill LLP, C.A.'),
new Array('141266', 'PKF Littlejohn LLP, C.A.'),
new Array('151583', 'Plante & Moran, PLLC, C.P.A.'),
new Array('157148', 'Prager Metis CPAs, LLC'),
new Array('88035', 'Price Waterhouse LLP, C.P.A.'),
new Array('74137', 'Price Waterhouse, C.A.'),
new Array('87848', 'PricewaterhouseCoopers'),
new Array('130117', 'PricewaterhouseCoopers AG, C.A.'),
new Array('101892', 'PricewaterhouseCoopers CI LLP, C.A.'),
new Array('111569', 'PricewaterhouseCoopers Inc.'),
new Array('16110', 'PricewaterhouseCoopers LLP, C.A.'),
new Array('88039', 'PricewaterhouseCoopers LLP, C.P.A.'),
new Array('118369', 'PricewaterhouseCoopers SA, C.A.'),
new Array('163770', 'PricewaterhouseCoopers SRL'),
new Array('98058', 'PSB Boisjoli LLP, C.A.'),
new Array('88119', 'R. W. GIC, C.A.'),
new Array('57640', 'Raymond Chabot Grant Thornton LLP, C.A.'),
new Array('136035', 'RBSM LLP, C.P.A.'),
new Array('86899', 'Rich Rotstein LLP, C.A.'),
new Array('16138', 'Richter LLP, C.A.'),
new Array('168461', 'RLB LLP, C.A.'),
new Array('167000', 'Rosenberg Rich Baker Berman & Company, C.P.A.'),
new Array('86450', 'Ross Pope LLP, C.A.'),
new Array('173756', 'Rotenberg Meril Solomon Bertiger & Guttilla, P.C., C.P.A.'),
new Array('153313', 'RSM Alberta LLP, C.A.'),
new Array('166048', 'RSM Australia Pty Ltd., C.A.'),
new Array('101303', 'RSM Canada LLP, C.A.'),
new Array('136222', 'RSM Hong Kong, C.P.A.'),
new Array('87096', 'RSM US LLP, C.P.A.'),
new Array('120780', 'RZN, LLP, C.A.'),
new Array('87756', 'S & W LLP, C.A.'),
new Array('146298', 'Sadler, Gibb & Associates, LLC, C.P.A.'),
new Array('86455', 'Sam S. Mah Inc., C.A.'),
new Array('102487', 'Saturna Group Chartered Accountants LLP, C.A.'),
new Array('86793', 'Scarrow & Donald LLP, C.A.'),
new Array('129450', 'Scarrow Yurman & CO. CPA Professional Corporation, C.A.'),
new Array('16166', 'Schwartz Levitsky Feldman LLP, C.A.'),
new Array('135620', 'Scrudato & Co., C.P.A.'),
new Array('136237', 'SCS Audit & Corporate Services Pty Ltd., C.A.'),
new Array('153543', 'SD Mayer & Associates LLP'),
new Array('87085', 'SF Partnership, LLP, C.A.'),
new Array('154653', 'SHIM & Associates LLP, C.A.'),
new Array('87077', 'Shimmerman Penn LLP, C.A.'),
new Array('87100', 'Silver, Gold, Glatt & Grosman LLP, C.A.'),
new Array('87041', 'Simone & Company, C.A.'),
new Array('87227', 'SingerLewak LLP, C.P.A.'),
new Array('87861', 'Sloan Partners LLP, C.A.'),
new Array('87639', 'Smith Chartered Accountant, C.A.'),
new Array('86464', 'Smith Nixon LLP, C.A.'),
new Array('83192', 'Smythe LLP, C.A.'),
new Array('125795', 'Squar Milner LLP, C.P.A.'),
new Array('130844', 'SRCO Professional Corporation, C.A.'),
new Array('86789', 'Staley, Okada & Partners, C.A.'),
new Array('87667', 'Stamatopoulos & Scholz, LLP, C.A.'),
new Array('86939', 'Stan Peloski, C.A.'),
new Array('135251', 'Stegman & Company, C.P.A.'),
new Array('87447', 'Steingarten Schechter & Co., C.A.'),
new Array('86522', 'Stern & Lovrics LLP, C.A.'),
new Array('87189', 'Stout & Company LLP, C.A.'),
new Array('86478', 'T. Robert Hambley, C.A.'),
new Array('87362', 'The Exchange Chartered Accountants LLP, C.A.'),
new Array('87356', 'Thomas Tong & Co. Inc., C.G.A.'),
new Array('87026', 'Thompson Penner & Lo LLP, C.G.A.'),
new Array('87220', 'Twigg & Co., C.A.'),
new Array('88010', 'UHY LLP, C.P.A.'),
new Array('136830', 'Urish Popeck & Co., LLC'),
new Array('34178', 'Verrier Paquin Hébert, C.A.'),
new Array('87378', 'Vertefeuille Kassam, C.A.'),
new Array('87753', 'Virtus Group LLP, C.A.'),
new Array('87215', 'Wasserman Ramsay, C.A.'),
new Array('86487', 'WDM Chartered Accountants, C.A.'),
new Array('34188', 'Welch LLP, C.A.'),
new Array('87988', 'Whitley Penn LLP, C.P.A.'),
new Array('132711', 'Williams & Partners, Chartered Accountants LLP, C.A.'),
new Array('99645', 'Zeifmans LLP, C.A.'),
new Array(' ', ' ')
);
