import * as React from 'react';
import { useTheme } from 'styled-components/macro';

export function BriefcaseIcon (props: { color?: 'subdued'|'primary'; size?: 'sm' | 'md' | 'lg' }) {
	const sizes = {
		sm: '20',
		md: '24',
		lg: '28',
	}

  const size = sizes[props.size || 'md'];
  const theme = useTheme();

  return (
    <svg aria-hidden role="img" height={size} viewBox="0 0 20 19" width={size}>
      <path d="M18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V6C20 4.89 19.11 4 18 4ZM12 4H8V2H12V4Z" fill="white"/>
    </svg>
  );
}
