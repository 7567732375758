import * as React from 'react';
import styled from 'styled-components';
import { SearchIcon } from 'icons/SearchIcon';
import { getMarginStyles, MarginProps } from 'utils/style';
import { useId } from '../../hooks';
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';

const SearchInput = styled.input<MarginProps>((props) => ({
  flexBasis: 0,
  flexGrow: 1,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  border: '1px solid #767676',
  padding: '12px 30px 12px 12px',
  color: '#767676',
  ...getMarginStyles(props),
}));

const SetIsShowingContext = React.createContext((newValue: boolean) => {});

export function SearchField (props: {
	placeholder?: string;
	ariaLabel?: string;
	value?: string;
	setValue: React.Dispatch<React.SetStateAction<string>>
}) {
	const id = useId();
  const setShowingSearchResults = React.useContext(SetIsShowingContext);
	const { placeholder, ariaLabel } = props
  return (
      <div
        style={{
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          aria-hidden
          style={{ position: 'absolute', top: '10px', right: '7px' }}
        >
          <SearchIcon size='sm' color='primary' />
        </div>
				<ScreenReaderOnly>
					<label htmlFor={id}>Search</label>
				</ScreenReaderOnly>
        <SearchInput
          placeholder={placeholder}
					id={id}
          type='text'
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
            if (e.target.value.length > 0) {
              setShowingSearchResults(true);
            }
          }}
        />
      </div>
  );
}
SearchField.defaultProps = {
	placeholder: 'Search',
}
