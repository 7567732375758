import { darken } from 'polished';
import * as React from 'react';
import styled from 'styled-components/macro';
import { useId } from '../../hooks';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { ArrowUpIcon } from '../../icons/ArrowUpIcon';
import { Row } from '../Row';
import { Text } from '../Text';

const PageAccordionBar = styled.button((props) => ({
  width: '100%',
  paddingTop: props.theme.spacing.md,
  paddingBottom: props.theme.spacing.md,
  backgroundColor: '#E2E2E2',
  border: 'none',
  outline: 'none',
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 0,
  transition: 'all 0.2s',
  cursor: 'pointer',
  '&[aria-expanded="false"]': {
    borderBottom: `1px solid ${darken(0.5, '#E2E2E2')}`,
  },
  '&:nth-last-child(2)': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: darken(0.1, '#E2E2E2'),
  },
  '&.focus-visible': {
    backgroundColor: darken(0.2, '#E2E2E2'),
  },
  '&:before': {
    position: 'absolute',
    left: '15%',
    content: "''",
    backgroundColor: 'rgba(255,255,255,0.25)',
    width: '70%',
    height: 0,
    marginTop: '-35%',
    paddingBottom: '70%',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: 'all 0s',
    opacity: 0,
    zIndex: -1,
  },
  '&.focus-visible:before': {
    transform: 'scale(1)',
    opacity: 1,
    transition: 'all 0.2s',
  },
}));

const PageAccordionContent = styled.div(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '1100px',
  maxWidth: '100%',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  [`@media(min-width: ${theme.breakpoints.sm})`]: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

export function PageAccordion (props: {
  title: string;
  children: React.ReactNode;
}) {
  const buttonId = useId();
  const contentId = useId();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <PageAccordionBar
        id={buttonId}
        type="button"
        aria-controls={contentId}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Row justifyContent="space-between" alignItems="center">
          <div style={{ width: '24px' }}></div>
          <Text weight="medium" alignX="center">
            {props.title}
          </Text>
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Row>
      </PageAccordionBar>
      <div
        style={{
          display: isOpen ? 'block' : 'none',
          boxShadow: '0 2px 5px 2px rgba(215, 215, 215, 0.5)',
        }}
      >
        <PageAccordionContent
          id={contentId}
          role="region"
          aria-labelledby={buttonId}
        >
          {props.children}
        </PageAccordionContent>
      </div>
    </>
  );
}
