// Snippet for scraping options
// var theOptions = [];
// $.each($('select[name="tgics"]').children(), function(i, el) {
//    theOptions.push( { 'id': $(el).attr('value'), 'name': $(el).html() } );
// });
// JSON.stringify(theOptions);

export interface ILookup {
  id: string;
  name: string;
	value?: string;
	label?: string;
}

export interface OLookup {
	value: string;
	label: string;
}

export const locationTypesLookup: Array<ILookup> = [
  { id: '', name: 'All' },
  { id: 'Branch', name: 'Branch' },
  { id: 'Division', name: 'Division' },
  { id: 'Headquarter', name: 'Headquarters' } /* note: different (s) */,
  { id: 'Single Location', name: 'Single Location' },
];

export const companyTypesLookup: Array<ILookup> = [
  { id: '', name: 'All' },
  { id: 'Crown Company', name: 'Crown Company' },
  { id: 'Private Company', name: 'Private Company' },
  { id: 'Public Company', name: 'Public Company' },
];

export const titleValues = {
 contacttype: 'Contact Type',
 'country[]': 'Country',
 sicCodeType: 'Sic Code Type',
 companyName: 'Company Name',
 city: 'City',
 address: 'Address',
 'provinces[]': 'Province',
 'exchange[]': 'Exchange',
 areaCode: 'Area Code',
 locationType: 'Location Type',
 'companyType[]': 'Company Type',
 'companySubType[]': 'Company Sub Type',
 jurisdiction: 'Jurisdiction',
 'sicCodes[]': 'Sic Codes',
 'gicCodes[]': 'Gic Codes',
 employeesGT: 'Employee GT',
 employeesLT: 'Employee LT',
 membersGT: 'Members GT',
 membersLT: 'Members LT',
 companyNameType: 'Company Name Type',
 position: 'Position',
 gender: 'Gender',
 school: 'School',
 degree: 'Degree',
 speciality: 'Speciality',
 fellowship: 'Fellowship',
 majorShareholder: 'Major Shareholder',
 idxSPTSXComp: 'S&P/TSX Composite',
 idxSPTSX60: 'S&P/TSX 60',
 idxTSX30: 'TSX 30',
 idxTSXVenTier1: ' TSX-VEN Tier 1',
 idxTSXVenTier2: ' TSX-VEN Tier 2',
 idxTSXVenTier3: ' TSX-VEN Tier 3',
 idxFP500: 'FP500',
 idxCleantech: 'Cleantech',
 banker: 'Banker',
 auditor: 'Auditor',
 normalCourseIssuerBid: 'Normal Course Issuer Bid',
 substantialIssuerBid: 'Substantial Issuer Bid',
 dividendReinvestmentPlan: 'Dividend Reinvestment Plan',
 exchangeListed: 'Listed',
 exchangeSuspended: 'Suspended',
 lawyer: 'lawyer',
 transferAgent: 'transferAgent',
 stockSymbol: 'Stock Symbol',
 searchRestrictTarget: 'Target',
 searchRestrictVendor: 'Vendor',
 searchRestrictAcquiror: 'Acquiror',
 searchTargetType: 'Target Type',
 searchTargetFPIndustry: 'Target FP Industry',
 searchTargetNAICS: 'Target NAICS Code',
 searchTargetSIC: 'Target SIC Code',
 searchTargetGICS: 'Target GICS',
 searchLocationTarget: 'Target Location',
 searchFinancialAdvisor: 'Financial Advisor',
 searchLegalAdvisor: 'Legal Advisor',
 searchDealValueGt: 'Deal Value Greater Than',
 searchDealValueLt: 'Deal Value Less Than',
 searchDatesAnnounced: 'Dates Announced',
 searchDatesUpdated: 'Dates Updated',
 startDate: 'Start Date',
 endDate: 'End Date',
 searchDealStatusCompleted: 'Completed',
 searchDealStatusPending: 'Pending',
 searchDealStatusTerminated: 'Terminated',
 statusDatesType: 'Status Dates Type',
 issuerName: 'Issuer Name',
 issueType: 'Issue Type',
 personName: 'Person Name',
 companySearchType: 'Company Search Type',
 searchType: 'Search Type',
 searchPredecessor: 'Include predecessor names in Search',
 dateInfo: 'Report Generated',
 leagueTables: 'Dealmakers League Table',
 creditType: 'Credit Type',
 countBy: 'Count By',
 securityTypes: 'Security Types',
 distributionType: 'Distribution Types',
 corporateGovernment: 'Corporate / Government',
 offeringProcedure: 'Offering Procedure',
 terms: 'Terms',
 market: 'Market',
 currency: 'Currency',
 exchange: 'Exchange',
 incorporation: 'Incorporation',
 name: 'Name',
 dbs: 'Database Name',
 sortOption: 'Sort Option',
 legalType: 'Legal Type',
 directorName: 'Position Title & Person Name',
 provinces: 'Province',
 organizationType: 'Organization Type',
 sicCode: 'SIC Code',
 naicsCode: 'NAICS Code',
 legalRecordType: 'Record Type',
 companyType: 'Company Type',
 salesVolumeLT: 'Sales Volume Less Than',
 salesVolumeGT: 'salesVolume Greater Than',
 companyTypes: 'Company Type'
};

export const locationTypeLookup: Array<ILookup> = [
  { id: '1', name: 'All' },
  { id: '2', name: 'Headquarters' },
  { id: '3', name: 'Division' },
];
export const companyTypeLookup: Array<ILookup> = [
  { id: '1', name: 'All' },
  { id: '2', name: 'Private Company' },
  { id: '3', name: 'Public Company' },
];
export const provincesLookup: Array<ILookup> = [
  { id: '1', name: 'Alberta' },
  { id: '2', name: 'British Columbia' },
  { id: '3', name: 'Manitoba' },
  { id: '4', name: 'New Brunswick' },
  { id: '5', name: 'Newfoundland and Labrador' },
  { id: '6', name: 'Northwest Territories' },
  { id: '7', name: 'Nova Scotia' },
  { id: '64', name: 'Nunavut' },
  { id: '8', name: 'Ontario' },
  { id: '9', name: 'Prince Edward Island' },
  { id: '10', name: 'Quebec' },
  { id: '11', name: 'Yukon' },
  { id: '12', name: 'Saskatchewan' },
];
export const provincesLookupbyAlphaCode: Array<ILookup> = [
  { id: 'AB', name: 'Alberta' },
  { id: 'BC', name: 'British Columbia' },
  { id: 'MB', name: 'Manitoba' },
  { id: 'NB', name: 'New Brunswick' },
  { id: 'NL', name: 'Newfoundland and Labrador' },
  { id: 'NT', name: 'Northwest Territories' },
  { id: 'NS', name: 'Nova Scotia' },
  { id: 'NU', name: 'Nunavut' },
  { id: 'ON', name: 'Ontario' },
  { id: 'PE', name: 'Prince Edward Island' },
  { id: 'QC', name: 'Quebec' },
  { id: 'YK', name: 'Yukon' },
  { id: 'SK', name: 'Saskatchewan' },
];
export const specialtiesLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '613', name: 'Accountancy' },
  { id: '1', name: 'Accounting' },
  { id: '2', name: 'Accounting & Business' },
  { id: '199', name: 'Accounting & Business Administration' },
  { id: '485', name: 'Accounting & Business Management' },
  { id: '486', name: 'Accounting & Commerce' },
  { id: '488', name: 'Accounting & Computer Science' },
  { id: '3', name: 'Accounting & Economics' },
  { id: '4', name: 'Accounting & Finance' },
  { id: '460', name: 'Accounting & International Business' },
  { id: '461', name: 'Accounting & Law' },
  { id: '462', name: 'Accounting & Management Information Sys' },
  { id: '463', name: 'Accounting & Marketing' },
  { id: '489', name: 'Accounting & Tax' },
  { id: '192', name: 'Accounting Science(s)' },
  { id: '5', name: 'Actuarial Mathematics' },
  { id: '76', name: 'Actuarial Science' },
  { id: '508', name: 'Actuarial Science & Mathematics' },
  { id: '200', name: 'Actuarial Science & Statistics' },
  { id: '6', name: 'Administration' },
  { id: '614', name: 'Administration Science' },
  { id: '487', name: 'Administrative & Commercial Studies' },
  { id: '211', name: 'Administrative Management' },
  { id: '513', name: 'Administrative Studies' },
  { id: '77', name: 'Adult Education' },
  { id: '545', name: 'Advanced Management' },
  { id: '615', name: 'Advertising' },
  { id: '616', name: 'Aeronautical Engineering' },
  { id: '617', name: 'Aeronautics' },
  { id: '127', name: 'Aerospace Engineering' },
  { id: '512', name: 'Aerospace Science' },
  { id: '514', name: 'Aerospace Studies' },
  { id: '515', name: 'Aerospace Systems Engineering' },
  { id: '618', name: 'Agricultural Business' },
  { id: '78', name: 'Agricultural Economics' },
  { id: '8', name: 'Agricultural Engineering' },
  { id: '212', name: 'Agricultural Science' },
  { id: '7', name: 'Agriculture' },
  { id: '213', name: 'Agronomy' },
  { id: '519', name: 'Air & Space Law' },
  { id: '217', name: 'Anaesthesiology' },
  { id: '214', name: 'Analytical Chemistry' },
  { id: '216', name: 'Anatomy' },
  { id: '509', name: 'Animal Biology' },
  { id: '510', name: 'Animal Nutrition' },
  { id: '511', name: 'Animal Physiology' },
  { id: '218', name: 'Animal Science' },
  { id: '9', name: 'Anthropology' },
  { id: '516', name: 'Applied Accounting' },
  { id: '517', name: 'Applied Biochemistry' },
  { id: '235', name: 'Applied Biology' },
  { id: '10', name: 'Applied Chemistry' },
  { id: '113', name: 'Applied Computer Science' },
  { id: '219', name: 'Applied Earth Science' },
  { id: '11', name: 'Applied Economics' },
  { id: '220', name: 'Applied Finance' },
  { id: '546', name: 'Applied Finance & Investment' },
  { id: '520', name: 'Applied Geography' },
  { id: '128', name: 'Applied Geology' },
  { id: '521', name: 'Applied Geophysics' },
  { id: '522', name: 'Applied Management' },
  { id: '12', name: 'Applied Mathematics' },
  { id: '523', name: 'Applied Physics' },
  { id: '129', name: 'Applied Science' },
  { id: '222', name: 'Archaeology' },
  { id: '619', name: 'Architectural Science' },
  { id: '666', name: 'Architectural Studies' },
  { id: '221', name: 'Architectural Technology' },
  { id: '13', name: 'Architecture' },
  { id: '223', name: 'Art History' },
  { id: '524', name: 'Artificial Intelligence' },
  { id: '224', name: 'Asian Studies' },
  { id: '225', name: 'Astronomy' },
  { id: '226', name: 'Astrophysics' },
  { id: '228', name: 'Atmospheric Physics' },
  { id: '229', name: 'Atmospheric Science' },
  { id: '490', name: 'Automation Engineering' },
  { id: '491', name: 'Automotive Engineering' },
  { id: '525', name: 'Automotive Mechanics' },
  { id: '526', name: 'Automotive Technology' },
  { id: '527', name: 'Aviation' },
  { id: '528', name: 'Aviation Management' },
  { id: '529', name: 'Banking & Finance' },
  { id: '530', name: 'Banking & Financial Services' },
  { id: '493', name: 'Bio-organic Chemistry' },
  { id: '15', name: 'Biochemistry' },
  { id: '620', name: 'Biochemistry & Chemistry' },
  { id: '446', name: 'Biochemistry & Molecular Biology' },
  { id: '231', name: 'Bioengineering' },
  { id: '230', name: 'Biological Science' },
  { id: '14', name: 'Biology' },
  { id: '124', name: 'Biology & Chemistry' },
  { id: '492', name: 'Biology & Physics' },
  { id: '547', name: 'Biology & Psychology' },
  { id: '621', name: 'Biomechanical Engineering' },
  { id: '232', name: 'Biomedical Engineering' },
  { id: '233', name: 'Biomedical Sciences' },
  { id: '671', name: 'Biomedicine' },
  { id: '494', name: 'Biopharmaceutics' },
  { id: '130', name: 'Biophysics' },
  { id: '234', name: 'Biotechnology' },
  { id: '236', name: 'Botany' },
  { id: '497', name: 'Botany & Biology' },
  { id: '237', name: 'Broadcast Journalism' },
  { id: '622', name: 'Building Engineering' },
  { id: '496', name: 'Building Technology' },
  { id: '16', name: 'Business' },
  { id: '623', name: 'Business & Accounting' },
  { id: '79', name: 'Business & Economics' },
  { id: '624', name: 'Business & Finance' },
  { id: '625', name: 'Business & Marketing' },
  { id: '17', name: 'Business Administration' },
  { id: '131', name: 'Business Administration & Accounting' },
  { id: '626', name: 'Business Administration & Economics' },
  { id: '132', name: 'Business Administration & Finance' },
  { id: '133', name: 'Business Administration & Management' },
  { id: '134', name: 'Business Administration & Marketing' },
  { id: '239', name: 'Business Commerce' },
  { id: '240', name: 'Business Communications' },
  { id: '532', name: 'Business Computing' },
  { id: '241', name: 'Business Economics' },
  { id: '242', name: 'Business Education' },
  { id: '577', name: 'Business Finance' },
  { id: '227', name: 'Business Information Systems' },
  { id: '531', name: 'Business Law' },
  { id: '18', name: 'Business Management' },
  { id: '627', name: 'Business Management & Marketing' },
  { id: '243', name: 'Business Marketing' },
  { id: '534', name: 'Business Organization' },
  { id: '238', name: 'Business Studies' },
  { id: '244', name: 'Canadian History' },
  { id: '535', name: 'Canadian Literature' },
  { id: '628', name: 'Canadian Studies' },
  { id: '533', name: 'Cartography' },
  { id: '245', name: 'Cellular Biology' },
  { id: '20', name: 'Chemical Engineering' },
  { id: '541', name: 'Chemical Engineering & Applied Chemistry' },
  { id: '536', name: 'Chemical Engineering Technology' },
  { id: '537', name: 'Chemical Metallurgy' },
  { id: '246', name: 'Chemical Physics' },
  { id: '247', name: 'Chemical Sciences' },
  { id: '248', name: 'Chemical Technology' },
  { id: '19', name: 'Chemistry' },
  { id: '464', name: 'Chemistry & Biochemistry' },
  { id: '465', name: 'Chemistry & Chemical Engineering' },
  { id: '466', name: 'Chemistry & Mathematics' },
  { id: '80', name: 'Chemistry & Petroleum Engineering' },
  { id: '538', name: 'Civil & Common Law' },
  { id: '290', name: 'Civil & Environmental Engineering' },
  { id: '21', name: 'Civil Engineering' },
  { id: '197', name: 'Civil Engineering Technology' },
  { id: '250', name: 'Civil Industrial Engineering' },
  { id: '206', name: 'Civil Law' },
  { id: '249', name: 'Civil Technology' },
  { id: '252', name: 'Classical Studies' },
  { id: '251', name: 'Classics' },
  { id: '544', name: 'Clinical Biochemistry' },
  { id: '299', name: 'Clinical Epidemiology' },
  { id: '253', name: 'Clinical Pharmacology' },
  { id: '254', name: 'Clinical Psychology' },
  { id: '255', name: 'Cognitive Psychology' },
  { id: '81', name: 'Commerce' },
  { id: '467', name: 'Commerce & Accounting' },
  { id: '542', name: 'Commerce & Administration' },
  { id: '117', name: 'Commerce & Economics' },
  { id: '578', name: 'Commerce & Finance' },
  { id: '207', name: 'Commercial Law' },
  { id: '208', name: 'Commercial Sciences' },
  { id: '256', name: 'Common Law' },
  { id: '22', name: 'Communications' },
  { id: '257', name: 'Communications Electronics' },
  { id: '259', name: 'Communications Engineering' },
  { id: '258', name: 'Communications Studies' },
  { id: '260', name: 'Computer & Electronics Engineering' },
  { id: '261', name: 'Computer & Information Science' },
  { id: '23', name: 'Computer Engineering' },
  { id: '210', name: 'Computer Information Sciences' },
  { id: '209', name: 'Computer Information Systems' },
  { id: '24', name: 'Computer Science' },
  { id: '579', name: 'Computer Science & Business Administration' },
  { id: '468', name: 'Computer Science & Economics' },
  { id: '498', name: 'Computer Science & Electronics' },
  { id: '499', name: 'Computer Science & Engineering' },
  { id: '469', name: 'Computer Science & Mathematics' },
  { id: '580', name: 'Computer Science & Statistics' },
  { id: '262', name: 'Computer Science Technology' },
  { id: '629', name: 'Computer Systems Engineering' },
  { id: '263', name: 'Computer Systems Technology' },
  { id: '264', name: 'Computer Technology' },
  { id: '265', name: 'Construction Management' },
  { id: '266', name: 'Control Systems' },
  { id: '267', name: 'Control Systems Engineering' },
  { id: '548', name: 'Corporate & Financial Law' },
  { id: '269', name: 'Corporate Finance' },
  { id: '135', name: 'Corporate Governance' },
  { id: '268', name: 'Corporate Law' },
  { id: '630', name: 'Counselling' },
  { id: '270', name: 'Counselling Psychology' },
  { id: '271', name: 'Criminal Justice' },
  { id: '272', name: 'Criminology' },
  { id: '549', name: 'Development Economics' },
  { id: '470', name: 'Earth & Ocean Science' },
  { id: '552', name: 'Earth Resources, Petroleum' },
  { id: '26', name: 'Earth Science' },
  { id: '631', name: 'Ecology' },
  { id: '277', name: 'Econometrics' },
  { id: '551', name: 'Economic Development' },
  { id: '275', name: 'Economic Geography' },
  { id: '87', name: 'Economic Geology' },
  { id: '668', name: 'Economic Geology & Geochemistry' },
  { id: '276', name: 'Economic Science' },
  { id: '27', name: 'Economics' },
  { id: '136', name: 'Economics & Accounting' },
  { id: '82', name: 'Economics & Business' },
  { id: '83', name: 'Economics & Business Administration' },
  { id: '581', name: 'Economics & Business Management' },
  { id: '137', name: 'Economics & Commerce' },
  { id: '471', name: 'Economics & Computer Science' },
  { id: '632', name: 'Economics & English' },
  { id: '84', name: 'Economics & Finance' },
  { id: '472', name: 'Economics & Financial Management' },
  { id: '582', name: 'Economics & French' },
  { id: '473', name: 'Economics & Geography' },
  { id: '474', name: 'Economics & Geology' },
  { id: '85', name: 'Economics & History' },
  { id: '475', name: 'Economics & International Business' },
  { id: '633', name: 'Economics & International Finance' },
  { id: '476', name: 'Economics & International Relations' },
  { id: '477', name: 'Economics & Management' },
  { id: '478', name: 'Economics & Marketing' },
  { id: '86', name: 'Economics & Mathematics' },
  { id: '479', name: 'Economics & Philosophy' },
  { id: '28', name: 'Economics & Political Science' },
  { id: '634', name: 'Economics & Politics' },
  { id: '480', name: 'Economics & Psychology' },
  { id: '481', name: 'Economics & Sociology' },
  { id: '482', name: 'Economics & Statistics' },
  { id: '550', name: 'Economics, Marketing & Finance' },
  { id: '88', name: 'Education' },
  { id: '278', name: 'Educational Administration' },
  { id: '279', name: 'Educational Psychology' },
  { id: '273', name: 'Electrical & Electronics Engineering' },
  { id: '29', name: 'Electrical Engineering' },
  { id: '280', name: 'Electrical Engineering & Computer Sc.' },
  { id: '274', name: 'Electrical Engineering Technology' },
  { id: '139', name: 'Electronic Engineering Technology' },
  { id: '140', name: 'Electronics' },
  { id: '138', name: 'Electronics & Computer Engineering' },
  { id: '125', name: 'Electronics Engineering' },
  { id: '583', name: 'Energy & Mineral Resources' },
  { id: '30', name: 'Engineering' },
  { id: '180', name: 'Engineering Chemistry' },
  { id: '283', name: 'Engineering Economic Systems' },
  { id: '281', name: 'Engineering Geology' },
  { id: '142', name: 'Engineering Management' },
  { id: '282', name: 'Engineering Mechanics' },
  { id: '31', name: 'Engineering Physics' },
  { id: '144', name: 'Engineering Science' },
  { id: '141', name: 'Engineering Technology' },
  { id: '32', name: 'English' },
  { id: '285', name: 'English & Economics' },
  { id: '284', name: 'English & History' },
  { id: '604', name: 'English & Political Science' },
  { id: '286', name: 'English & Psychology' },
  { id: '287', name: 'English & Sociology' },
  { id: '145', name: 'English Literature' },
  { id: '605', name: 'Enterprise Risk Management' },
  { id: '606', name: 'Entrepreneurial Management' },
  { id: '607', name: 'Entrepreneurship' },
  { id: '293', name: 'Enviornmental Economics' },
  { id: '288', name: 'Environment & Management' },
  { id: '289', name: 'Environment Studies' },
  { id: '292', name: 'Environmental Biology' },
  { id: '598', name: 'Environmental Chemistry' },
  { id: '143', name: 'Environmental Engineering' },
  { id: '295', name: 'Environmental Geology' },
  { id: '291', name: 'Environmental Health' },
  { id: '296', name: 'Environmental Planning' },
  { id: '294', name: 'Environmental Politics' },
  { id: '215', name: 'Environmental Science' },
  { id: '89', name: 'Environmental Studies' },
  { id: '297', name: 'Environmental Technology' },
  { id: '298', name: 'Epidemiology' },
  { id: '300', name: 'Executive Development' },
  { id: '147', name: 'Executive Management' },
  { id: '635', name: 'Exploration & Mining Geology' },
  { id: '301', name: 'Exploration Geology' },
  { id: '302', name: 'Exploration Geophysics' },
  { id: '146', name: 'Extractive Metallurgy' },
  { id: '33', name: 'Finance' },
  { id: '34', name: 'Finance & Accounting' },
  { id: '148', name: 'Finance & Administration' },
  { id: '90', name: 'Finance & Economics' },
  { id: '91', name: 'Finance & International Business' },
  { id: '303', name: 'Finance & Investments' },
  { id: '149', name: 'Finance & Management' },
  { id: '92', name: 'Finance & Marketing' },
  { id: '500', name: 'Finance & Operations Research' },
  { id: '501', name: 'Finance & Real Estate' },
  { id: '304', name: 'Finance & Statistics' },
  { id: '636', name: 'Finance & Strategic Management' },
  { id: '150', name: 'Finance & Strategy' },
  { id: '596', name: 'Financial Economics' },
  { id: '305', name: 'Financial Engineering' },
  { id: '120', name: 'Financial Management' },
  { id: '608', name: 'Financial Mathematics' },
  { id: '151', name: 'Financial Services' },
  { id: '306', name: 'Food Science' },
  { id: '307', name: 'Food Science & Technology' },
  { id: '637', name: 'Foreign Service' },
  { id: '308', name: 'Forest Engineering' },
  { id: '584', name: 'Forest Soils' },
  { id: '35', name: 'Forestry' },
  { id: '309', name: 'Forestry Science' },
  { id: '36', name: 'French' },
  { id: '638', name: 'French Literature' },
  { id: '585', name: 'French Studies' },
  { id: '586', name: 'Gas Technology' },
  { id: '310', name: 'General Business' },
  { id: '311', name: 'General Management' },
  { id: '93', name: 'Genetics' },
  { id: '114', name: 'Geochemistry' },
  { id: '152', name: 'Geographic Information Systems' },
  { id: '37', name: 'Geography' },
  { id: '312', name: 'Geography & Economics' },
  { id: '313', name: 'Geography & Geology' },
  { id: '39', name: 'Geological Engineering' },
  { id: '38', name: 'Geology' },
  { id: '314', name: 'Geology & Business Administration' },
  { id: '122', name: 'Geology & Chemistry' },
  { id: '315', name: 'Geology & Economics' },
  { id: '553', name: 'Geology & Geochemistry' },
  { id: '316', name: 'Geology & Geography' },
  { id: '317', name: 'Geology & Geological Engineering' },
  { id: '119', name: 'Geology & Geophysics' },
  { id: '319', name: 'Geology & Mineralogy' },
  { id: '318', name: 'Geology & Mining Engineering' },
  { id: '320', name: 'Geology & Petroleum Engineering' },
  { id: '321', name: 'Geology & Physics' },
  { id: '459', name: 'Geology & Zoology' },
  { id: '94', name: 'Geology Science' },
  { id: '322', name: 'Geomatics Engineering' },
  { id: '153', name: 'Geophysical Engineering' },
  { id: '40', name: 'Geophysics' },
  { id: '323', name: 'Geosciences' },
  { id: '540', name: 'Geostatistics' },
  { id: '324', name: 'Geotechnical Engineering' },
  { id: '639', name: 'Global Management' },
  { id: '325', name: 'Government' },
  { id: '609', name: 'Graphic Design' },
  { id: '326', name: 'Health Administration' },
  { id: '554', name: 'Health Care Administration' },
  { id: '640', name: 'Health Science' },
  { id: '41', name: 'History' },
  { id: '95', name: 'History & Economics' },
  { id: '327', name: 'History & Philosophy' },
  { id: '96', name: 'History & Political Science' },
  { id: '328', name: 'History & Sociology' },
  { id: '641', name: 'Home Economics' },
  { id: '329', name: 'Horticulture' },
  { id: '505', name: 'Hospitality' },
  { id: '330', name: 'Hospitality Management' },
  { id: '610', name: 'Hotel & Food Administration' },
  { id: '611', name: 'Hotel & Restaurant Administration' },
  { id: '612', name: 'Hotel & Restaurant Management' },
  { id: '503', name: 'Hotel Administration' },
  { id: '331', name: 'Hotel Management' },
  { id: '587', name: 'Human Biology' },
  { id: '97', name: 'Human Resource Management' },
  { id: '42', name: 'Human Resources' },
  { id: '504', name: 'Hydrocarbon Engineering' },
  { id: '642', name: 'Hydrogeology' },
  { id: '154', name: 'Immunology' },
  { id: '555', name: 'Industrial & Labour Relations' },
  { id: '332', name: 'Industrial & Organizational Psychology' },
  { id: '334', name: 'Industrial Administration' },
  { id: '333', name: 'Industrial Automation' },
  { id: '643', name: 'Industrial Design' },
  { id: '336', name: 'Industrial Economics' },
  { id: '43', name: 'Industrial Engineering' },
  { id: '561', name: 'Industrial Engineering & Operations Research' },
  { id: '121', name: 'Industrial Management' },
  { id: '338', name: 'Industrial Pharmacy' },
  { id: '337', name: 'Industrial Psychology' },
  { id: '44', name: 'Industrial Relations' },
  { id: '335', name: 'Industrial Studies' },
  { id: '644', name: 'Industrial System Engineering' },
  { id: '155', name: 'Information Systems' },
  { id: '339', name: 'Information Technology' },
  { id: '645', name: 'Information Technology Management' },
  { id: '340', name: 'Inorganic Chemistry' },
  { id: '588', name: 'Instrument Engineering Technology' },
  { id: '646', name: 'Intellectual Property Law' },
  { id: '647', name: 'Interior Design' },
  { id: '556', name: 'International & European Law' },
  { id: '341', name: 'International Affairs' },
  { id: '45', name: 'International Business' },
  { id: '346', name: 'International Business & Finance' },
  { id: '349', name: 'International Business Administration' },
  { id: '348', name: 'International Business Law' },
  { id: '557', name: 'International Commercial Law' },
  { id: '111', name: 'International Economics' },
  { id: '157', name: 'International Finance' },
  { id: '342', name: 'International Law' },
  { id: '112', name: 'International Management' },
  { id: '599', name: 'International Marketing' },
  { id: '350', name: 'International Marketing & Finance' },
  { id: '343', name: 'International Politics' },
  { id: '98', name: 'International Relations' },
  { id: '558', name: 'International Relations & Economics' },
  { id: '344', name: 'International Studies' },
  { id: '347', name: 'International Tax Law' },
  { id: '345', name: 'International Trade' },
  { id: '648', name: 'Investment Management' },
  { id: '46', name: 'Journalism' },
  { id: '351', name: 'Journalism & Communications' },
  { id: '158', name: 'Jurisprudence' },
  { id: '202', name: 'Kinesiology' },
  { id: '502', name: 'Labour & Industrial Relations' },
  { id: '47', name: 'Labour Relations' },
  { id: '352', name: 'Labour Studies' },
  { id: '48', name: 'Law' },
  { id: '353', name: 'Law & Accounting' },
  { id: '354', name: 'Law & Diplomacy' },
  { id: '118', name: 'Law & Economics' },
  { id: '355', name: 'Law & Sociology' },
  { id: '356', name: 'Leadership' },
  { id: '357', name: 'Leadership & Training' },
  { id: '358', name: 'Liberal Arts' },
  { id: '359', name: 'Liberal Studies' },
  { id: '159', name: 'Life Sciences' },
  { id: '160', name: 'Linguistics' },
  { id: '161', name: 'Literature' },
  { id: '52', name: 'Management' },
  { id: '371', name: 'Management & Economics' },
  { id: '372', name: 'Management & Finance' },
  { id: '165', name: 'Management & Marketing' },
  { id: '652', name: 'Management Accounting' },
  { id: '591', name: 'Management Development' },
  { id: '373', name: 'Management Economics' },
  { id: '156', name: 'Management Information Systems' },
  { id: '563', name: 'Management of Technology' },
  { id: '166', name: 'Management Science' },
  { id: '167', name: 'Management Studies' },
  { id: '649', name: 'Managerial Economics' },
  { id: '507', name: 'Manufacturing Engineering' },
  { id: '162', name: 'Marine Biology' },
  { id: '360', name: 'Marine Engineering' },
  { id: '361', name: 'Marine Geology' },
  { id: '56', name: 'Marketing' },
  { id: '654', name: 'Marketing & Accounting' },
  { id: '384', name: 'Marketing & Communications' },
  { id: '564', name: 'Marketing & Economics' },
  { id: '107', name: 'Marketing & Finance' },
  { id: '385', name: 'Marketing & Industrial Relations' },
  { id: '386', name: 'Marketing & International Business' },
  { id: '387', name: 'Marketing & Management' },
  { id: '168', name: 'Marketing Management' },
  { id: '650', name: 'Mass Communications' },
  { id: '366', name: 'Materials Chemistry' },
  { id: '163', name: 'Materials Science' },
  { id: '589', name: 'Materials Science & Engineering' },
  { id: '543', name: 'Materials Science Engineering' },
  { id: '362', name: 'Mathematical Statistics' },
  { id: '49', name: 'Mathematics' },
  { id: '595', name: 'Mathematics & Accounting' },
  { id: '590', name: 'Mathematics & Business' },
  { id: '651', name: 'Mathematics & Chemistry' },
  { id: '99', name: 'Mathematics & Computer Science' },
  { id: '116', name: 'Mathematics & Economics' },
  { id: '100', name: 'Mathematics & Physics' },
  { id: '123', name: 'Mathematics & Statistics' },
  { id: '101', name: 'Mechanical' },
  { id: '363', name: 'Mechanical & Aerospace Engineering' },
  { id: '364', name: 'Mechanical & Civil Engineering' },
  { id: '365', name: 'Mechanical & Electrical Engineering' },
  { id: '367', name: 'Mechanical & Materials Engineering' },
  { id: '50', name: 'Mechanical Engineering' },
  { id: '164', name: 'Mechanical Engineering Technology' },
  { id: '368', name: 'Medical Biophysics' },
  { id: '369', name: 'Medical Microbiology' },
  { id: '370', name: 'Medical Science' },
  { id: '559', name: 'Medicinal Chemistry' },
  { id: '560', name: 'Medicine' },
  { id: '51', name: 'Metallurgical Engineering' },
  {
    id: '562',
    name: 'Metallurgical Engineering & Materials Science',
  },
  { id: '102', name: 'Metallurgy' },
  { id: '506', name: 'Metallurgy & Materials Science' },
  { id: '53', name: 'Microbiology' },
  { id: '103', name: 'Microbiology & Immunology' },
  { id: '667', name: 'Mineral & Geological Engineering' },
  { id: '105', name: 'Mineral Exploration' },
  { id: '381', name: 'Mineral Exploration & Mining Geology' },
  { id: '483', name: 'Mineral Processing' },
  { id: '382', name: 'Mineral Production Management' },
  { id: '600', name: 'Mineral Resource Engineering' },
  { id: '653', name: 'Mineral Resources' },
  { id: '603', name: 'Mining & Geology' },
  { id: '374', name: 'Mining & Metallurgical Engineering' },
  { id: '25', name: 'Mining & Metallurgy' },
  { id: '376', name: 'Mining & Mineral Process Engineering' },
  { id: '377', name: 'Mining & Petroleum Engineering' },
  { id: '55', name: 'Mining Engineering' },
  { id: '379', name: 'Mining Engineering Management' },
  { id: '380', name: 'Mining Engineering Technology' },
  { id: '484', name: 'Mining Geomechanics' },
  { id: '378', name: 'Mining Law' },
  { id: '54', name: 'Mining or Mineral' },
  { id: '104', name: 'Mining or Mineral Economics' },
  { id: '106', name: 'Mining or Mineral Geology' },
  { id: '375', name: 'Mining Process Engineering' },
  { id: '383', name: 'Mining Technology' },
  { id: '388', name: 'Modern History' },
  { id: '389', name: 'Modern Languages' },
  { id: '390', name: 'Molecular & Cell Biology' },
  { id: '169', name: 'Molecular Biology' },
  { id: '391', name: 'Molecular Genetics' },
  { id: '602', name: 'Molecular Immunology' },
  { id: '539', name: 'Music' },
  { id: '670', name: 'Nanoscience' },
  { id: '669', name: 'Nanotechnology' },
  { id: '392', name: 'Natural Gas Engineering' },
  { id: '170', name: 'Natural Science' },
  { id: '393', name: 'Network Engineering' },
  { id: '655', name: 'Neuroscience' },
  { id: '57', name: 'Nuclear Engineering' },
  { id: '656', name: 'Nuclear Physics' },
  { id: '171', name: 'Nursing' },
  { id: '204', name: 'Nutrition' },
  { id: '657', name: 'Occupational Health & Safety' },
  { id: '173', name: 'Operations Management' },
  { id: '172', name: 'Operations Research' },
  { id: '565', name: 'Optics & Laser' },
  { id: '566', name: 'Ore Deposits & Exploration' },
  { id: '176', name: 'Organic Chemistry' },
  { id: '174', name: 'Organizational Behaviour' },
  { id: '58', name: 'Organizational Development' },
  { id: '658', name: 'Organizational Leadership' },
  { id: '175', name: 'Organizational Psychology' },
  { id: '567', name: 'Paralegal' },
  { id: '394', name: 'Pathology' },
  { id: '568', name: 'Personnel & Industrial Relations' },
  { id: '395', name: 'Personnel Administration' },
  { id: '396', name: 'Personnel Management' },
  { id: '59', name: 'Petroleum Engineering' },
  { id: '196', name: 'Petroleum Engineering Technology' },
  { id: '115', name: 'Petroleum Geology' },
  { id: '108', name: 'Petroleum Land Management' },
  { id: '397', name: 'Petroleum Reservoir Engineering' },
  { id: '398', name: 'Petroleum Reservoir Technology' },
  { id: '177', name: 'Petroleum Technology' },
  { id: '401', name: 'Pharmaceutical Chemistry' },
  { id: '402', name: 'Pharmaceutical Science' },
  { id: '495', name: 'Pharmaceutics' },
  { id: '178', name: 'Pharmacology' },
  { id: '60', name: 'Pharmacy' },
  { id: '61', name: 'Philosophy' },
  { id: '403', name: 'Philosophy & Economics' },
  { id: '404', name: 'Philosophy & Political Science' },
  { id: '405', name: 'Philosophy & Politics' },
  { id: '406', name: 'Philosophy, Politics & Economics' },
  { id: '184', name: 'Physical Chemistry' },
  { id: '181', name: 'Physical Education' },
  { id: '185', name: 'Physical Engineering' },
  { id: '186', name: 'Physical Geography' },
  { id: '400', name: 'Physical Inorganic Chemistry' },
  { id: '187', name: 'Physical Metallurgy' },
  { id: '399', name: 'Physical Oceanography' },
  { id: '183', name: 'Physical Science' },
  { id: '408', name: 'Physical Therapy' },
  { id: '62', name: 'Physics' },
  { id: '407', name: 'Physics & Chemistry' },
  { id: '601', name: 'Physics & Earth Sciences' },
  { id: '592', name: 'Physics & Economics' },
  { id: '179', name: 'Physics & Mathematics' },
  { id: '182', name: 'Physics Engineering' },
  { id: '63', name: 'Physiology' },
  { id: '409', name: 'Plant Ecology' },
  { id: '410', name: 'Plant Science' },
  { id: '569', name: 'Plasma Physics' },
  { id: '188', name: 'Political Economics' },
  { id: '64', name: 'Political Science' },
  { id: '412', name: 'Political Science & Business Admin.' },
  { id: '109', name: 'Political Science & Economics' },
  { id: '593', name: 'Political Science & Government' },
  { id: '189', name: 'Political Science & History' },
  { id: '413', name: 'Political Science & International Rel.' },
  { id: '570', name: 'Political Science & Literature' },
  { id: '414', name: 'Political Science & Sociology' },
  { id: '411', name: 'Political Studies' },
  { id: '415', name: 'Politics' },
  { id: '659', name: 'Politics & Economics' },
  { id: '660', name: 'Politics, Philosophy & Economics' },
  { id: '661', name: 'Polymer Science' },
  { id: '416', name: 'Power Engineering' },
  { id: '417', name: 'Production Engineering' },
  { id: '65', name: 'Project Management' },
  { id: '66', name: 'Psychology' },
  { id: '571', name: 'Psychology & Economics' },
  { id: '662', name: 'Psychology & Sociology' },
  { id: '426', name: 'Public Accountancy' },
  { id: '110', name: 'Public Accounting' },
  { id: '67', name: 'Public Administration' },
  { id: '663', name: 'Public Management' },
  { id: '418', name: 'Public Policy' },
  { id: '201', name: 'Public Relations' },
  { id: '572', name: 'Quantity Surveying' },
  { id: '419', name: 'Radio & Television Arts' },
  { id: '420', name: 'Real Estate' },
  { id: '421', name: 'Real Estate Development' },
  { id: '422', name: 'Real Estate Finance' },
  { id: '573', name: 'Real Estate Management' },
  { id: '423', name: 'Real Property Development' },
  { id: '424', name: 'Retailing' },
  { id: '425', name: 'Risk Management' },
  { id: '574', name: 'Robotics & Automation Technology' },
  { id: '190', name: 'Rock Mechanics' },
  { id: '575', name: 'Russian Studies' },
  { id: '427', name: 'Sales & Marketing' },
  { id: '428', name: 'Science & Technology' },
  { id: '191', name: 'Science(s)' },
  { id: '429', name: 'Secretarial Arts' },
  { id: '430', name: 'Securities Law' },
  { id: '193', name: 'Social Sciences' },
  { id: '664', name: 'Social Work' },
  { id: '68', name: 'Sociology' },
  { id: '431', name: 'Sociology & Anthropology' },
  { id: '432', name: 'Sociology & Economics' },
  { id: '433', name: 'Sociology & Marketing' },
  { id: '434', name: 'Sociology & Political Science' },
  { id: '435', name: 'Sociology & Psychology' },
  { id: '436', name: 'Software Engineering' },
  { id: '437', name: 'Soil Science' },
  { id: '438', name: 'Sports Administration' },
  { id: '439', name: 'Sports Medicine' },
  { id: '69', name: 'Statistics' },
  { id: '440', name: 'Statistics & Economics' },
  { id: '441', name: 'Strategic Management' },
  { id: '442', name: 'Strategy' },
  { id: '665', name: 'Strategy & Finance' },
  { id: '443', name: 'Strategy & Marketing' },
  { id: '194', name: 'Structural Engineering' },
  { id: '70', name: 'Structural Geology' },
  { id: '444', name: 'Supply Chain Management' },
  { id: '445', name: 'Synthetic Organic Chemistry' },
  { id: '447', name: 'Systems Design' },
  { id: '71', name: 'Systems Design Engineering' },
  { id: '195', name: 'Systems Engineering' },
  { id: '72', name: 'Taxation' },
  { id: '448', name: 'Teaching' },
  { id: '449', name: 'Technical Management' },
  { id: '451', name: 'Technical Sciences' },
  { id: '73', name: 'Technology' },
  { id: '450', name: 'Technology Management' },
  { id: '198', name: 'Telecommunications' },
  { id: '594', name: 'Telecommunications Engineering' },
  { id: '203', name: 'Theology' },
  { id: '452', name: 'Theoretical Physics' },
  { id: '576', name: 'Training & Development' },
  { id: '453', name: 'Translation' },
  { id: '597', name: 'Transportation Management' },
  { id: '126', name: 'Urban & Regional Planning' },
  { id: '455', name: 'Urban Development' },
  { id: '456', name: 'Urban Economics' },
  { id: '74', name: 'Urban Land Economics' },
  { id: '205', name: 'Urban Planning' },
  { id: '454', name: 'Urban Studies' },
  { id: '457', name: 'Visual Arts' },
  { id: '458', name: 'Water Resources Engineering' },
  { id: '75', name: 'Zoology              ' },
];

export const fellowshipsLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'Accredited Appraiser, Canadian Institute' },
  { id: '647', name: 'Accredited Business Communicator' },
  { id: '14', name: 'Accredited Canadian Credit Union Director' },
  { id: '787', name: 'Accredited Commercial Manager' },
  { id: '764', name: 'Accredited Director' },
  {
    id: '33',
    name: 'Accredited in Public Relations, Canadian Public Relations Society, Inc.',
  },
  { id: '755', name: 'Accredited Senior Appraiser' },
  { id: '44', name: 'Administrateur Agréé' },
  { id: '710', name: 'Administrateur de sociétés certifié' },
  { id: '653', name: 'Advocatus Emeritus' },
  { id: '39', name: 'Agréé en relations publiques' },
  { id: '723', name: 'Alberta Institute of Agrologists' },
  { id: '565', name: 'Alberta Order of Excellence' },
  { id: '714', name: 'Alberta Society of Engineering Technologists' },
  { id: '722', name: 'Alberta Society of Professional Biologists' },
  { id: '623', name: 'American Association of Petroleum Geologists' },
  { id: '684', name: 'American Association of Petroleum Landmen' },
  { id: '728', name: 'American College of Surgeons' },
  { id: '16', name: 'American Institute of Architects' },
  {
    id: '621',
    name: 'American Institute of Certified Public Accountants',
  },
  { id: '686', name: 'American Institute of Professional Geologists' },
  { id: '767', name: 'American Society of Civil Engineers' },
  { id: '677', name: 'American Society of Interior Designers' },
  { id: '689', name: 'Applied Science Technologist' },
  {
    id: '15',
    name: 'Associate Fellow of the Canadian Aeronautic and Space Institute',
  },
  { id: '38', name: 'Associate in Risk Management' },
  {
    id: '25',
    name: 'Associate Member of the British Institute of Management',
  },
  {
    id: '27',
    name: 'Associate Member of The Institute of Mechanical Engineers (U.K.)',
  },
  { id: '26', name: 'Associate Member, Engineering Institute of Canada' },
  { id: '5', name: 'Associate of the Casualty Actuarial Society' },
  {
    id: '9',
    name: 'Associate of the Chartered Insurance Institute (U.K.)',
  },
  { id: '18', name: 'Associate of the Institute of Canadian Bankers' },
  {
    id: '4',
    name: 'Associate of the Institute of Chartered Accountants',
  },
  {
    id: '10',
    name: 'Associate of the Institute of Chartered Secretaries and Administrators',
  },
  {
    id: '11',
    name: 'Associate of the Institute of Cost and Management Accountants (U.K.)',
  },
  { id: '21', name: 'Associate of the Insurance Institute of Canada' },
  { id: '34', name: 'Associate of the Royal College of Science' },
  {
    id: '35',
    name: 'Associate of the Royal Conservatory of Music in Toronto',
  },
  { id: '36', name: 'Associate of the Royal Institute of Chemistry' },
  { id: '40', name: 'Associate of the Royal School of Mines' },
  { id: '42', name: 'Associate of the Society of Actuaries' },
  { id: '589', name: 'Associate, Life and Health Claims' },
  {
    id: '720',
    name: 'Association of Certified Engineering Technicians and Technologists of P.E.I.',
  },
  {
    id: '672',
    name: 'Association of Chartered Certified Accountants (U.K.)',
  },
  {
    id: '708',
    name: 'Association of International Petroleum Negotiators',
  },
  { id: '695', name: 'Association of Ontario Land Economists' },
  {
    id: '622',
    name: 'Association of Professional Engineers and Geoscientists of Alberta',
  },
  {
    id: '641',
    name: 'Association of Professional Engineers and Geoscientists of British Columbia',
  },
  {
    id: '690',
    name: 'Association of Professional Engineers and Geoscientists of Manitoba',
  },
  {
    id: '651',
    name: 'Association of Professional Engineers and Geoscientists of New Brunswick',
  },
  {
    id: '673',
    name: 'Association of Professional Engineers and Geoscientists of Saskatchewan',
  },
  {
    id: '669',
    name: 'Association of Professional Engineers of Nova Scotia',
  },
  { id: '687', name: 'Association of Professional Engineers of Ontario' },
  {
    id: '721',
    name: 'Association of Professional Engineers of Prince Edward Island',
  },
  { id: '766', name: 'Association of Professional Engineers of Yukon' },
  {
    id: '680',
    name: 'Association of Professional Engineers, Geologists and Geophysicists of the Northwest Territories',
  },
  {
    id: '771',
    name: 'Association of Professional Geoscientists of Nova Scotia',
  },
  {
    id: '640',
    name: 'Association of Professional Geoscientists of Ontario',
  },
  {
    id: '676',
    name: 'Association of Registered Interior Designers of Ontario',
  },
  { id: '579', name: 'Assureur-vie agréé' },
  { id: '730', name: 'Audit Committee Certified' },
  { id: '745', name: 'British Columbia Crime Prevention Association' },
  { id: '120', name: 'Canadian Accredited Insurance Broker' },
  {
    id: '724',
    name: 'Canadian Association of Accredited Mortgage Professionals',
  },
  { id: '675', name: 'Canadian Association of Chiefs of Police' },
  { id: '683', name: 'Canadian Association of Petroleum Landmen' },
  {
    id: '635',
    name: 'Canadian Association of Petroleum Production Accounting',
  },
  {
    id: '743',
    name: 'Canadian Association of Special Investigation Units',
  },
  { id: '597', name: 'Canadian Certified Insurance Broker' },
  { id: '631', name: 'Canadian Council of Chief Executives' },
  { id: '132', name: 'Canadian Forces Decoration' },
  { id: '716', name: 'Canadian Heavy Oil Association' },
  { id: '147', name: 'Canadian Insolvency Practitioner' },
  { id: '750', name: 'Canadian Institute of Chartered Accountants' },
  { id: '747', name: 'Canadian Investor Relations Association' },
  { id: '751', name: 'Canadian Pharmacists Association' },
  { id: '603', name: 'Canadian Registered Safety Professional' },
  { id: '523', name: 'Canadian Residential Appraiser' },
  { id: '165', name: 'Canadian Risk Manager' },
  { id: '643', name: 'Canadian Society of Corporate Secretaries' },
  { id: '668', name: 'Canadian Society of Exploration Geoscientists' },
  { id: '715', name: 'Canadian Society of Landscape Architects' },
  { id: '166', name: 'Canadian Society of Petroleum Geologists' },
  { id: '706', name: "Canadian Vehicle Manufacturers' Association" },
  { id: '746', name: 'Canadian Wind Energy Association' },
  { id: '620', name: 'Certified Aboriginal Financial Manager' },
  { id: '121', name: 'Certified Administrative Manager' },
  { id: '119', name: 'Certified Advertising Agency Practitioner' },
  { id: '22', name: 'Certified Anti-Money Laundering Specialist' },
  { id: '123', name: 'Certified Arbitrator' },
  { id: '6', name: 'Certified Business Appraiser' },
  { id: '737', name: 'Certified Business Intelligence Professional' },
  { id: '575', name: 'Certified Compensation Professional' },
  { id: '20', name: 'Certified Credit Union Director' },
  { id: '133', name: 'Certified Data Processor' },
  { id: '13', name: 'Certified Economic Developer' },
  { id: '2', name: 'Certified Employee Benefits Specialist' },
  { id: '135', name: 'Certified Engineering Technologist' },
  { id: '584', name: 'Certified Executive Accountant' },
  { id: '139', name: 'Certified Financial Planner' },
  { id: '780', name: 'Certified Financial Services Auditor' },
  { id: '138', name: 'Certified Fraud Examiner' },
  { id: '140', name: 'Certified General Accountant' },
  { id: '591', name: 'Certified Ground Water Professional' },
  { id: '754', name: 'Certified Hasardous Materials Management' },
  { id: '17', name: 'Certified Health and Safety Consultant' },
  { id: '667', name: 'Certified Health Executive' },
  { id: '637', name: 'Certified Hotel Administrator' },
  { id: '786', name: 'Certified Human Resources Executive' },
  { id: '776', name: 'Certified Human Resources Leader' },
  { id: '143', name: 'Certified Human Resources Professional' },
  { id: '615', name: 'Certified Information Systems Auditor' },
  {
    id: '19',
    name: 'Certified Information Systems Security Professional',
  },
  { id: '709', name: 'Certified Insolvency & Restructuring Advisor' },
  { id: '144', name: 'Certified Internal Auditor' },
  {
    id: '705',
    name: 'Certified Internet Marketing and Business Strategist',
  },
  { id: '518', name: 'Certified Investment Manager' },
  { id: '774', name: 'Certified Leasing Specialist' },
  { id: '645', name: 'Certified Legal Assistant' },
  { id: '658', name: 'Certified Licensing Professional' },
  { id: '152', name: 'Certified Management Accountant' },
  { id: '153', name: 'Certified Management Consultant' },
  { id: '156', name: 'Certified Manufacturing Engineer' },
  { id: '155', name: 'Certified Market Technician (U.S.)' },
  { id: '731', name: 'Certified Patent Valuation Analyst' },
  { id: '682', name: 'Certified Professional Contracts Manager' },
  { id: '785', name: 'Certified Professional Facilitator' },
  { id: '639', name: 'Certified Professional Geologist' },
  { id: '778', name: 'Certified Professional in Investor Relations' },
  { id: '702', name: 'Certified Professional Landman' },
  { id: '161', name: 'Certified Professional Purchaser' },
  { id: '160', name: 'Certified Property Manager' },
  { id: '736', name: 'Certified Protection Officer' },
  { id: '157', name: 'Certified Public Accountant' },
  { id: '164', name: 'Certified Residential Broker' },
  { id: '772', name: 'Certified Retail Property Executive' },
  { id: '753', name: 'Certified Safety Professional' },
  { id: '748', name: 'Certified Sales Professional' },
  { id: '773', name: 'Certified Shopping Center Manager' },
  { id: '782', name: 'Certified Strategic Alliance Professional' },
  { id: '758', name: 'Certified Supply Chain Professional' },
  { id: '624', name: 'Certified Treasury Professional' },
  { id: '118', name: 'Chartered Accountant' },
  { id: '756', name: 'Chartered Accountant - Information Technology' },
  { id: '122', name: 'Chartered Administrator' },
  { id: '729', name: 'Chartered Alternative Investment Analyst' },
  { id: '576', name: 'Chartered Biologist' },
  { id: '126', name: 'Chartered Business Valuator' },
  { id: '117', name: 'Chartered Certified Accountant' },
  { id: '130', name: 'Chartered Chemist' },
  { id: '599', name: 'Chartered Director' },
  { id: '136', name: 'Chartered Engineer' },
  { id: '762', name: 'Chartered Enterprise Risk Analyst' },
  { id: '137', name: 'Chartered Financial Analyst' },
  { id: '141', name: 'Chartered Financial Consultant' },
  { id: '3', name: 'Chartered Geologist' },
  { id: '769', name: 'Chartered Global Management Accountant' },
  {
    id: '629',
    name: 'Chartered Insolvency and Restructuring Professional',
  },
  { id: '656', name: 'Chartered Institute of Arbitrators' },
  { id: '661', name: 'Chartered Institute of Housing' },
  { id: '12', name: 'Chartered Institute of Management Accountants' },
  { id: '784', name: 'Chartered Institute of Personnel and Development' },
  { id: '145', name: 'Chartered Insurance Broker' },
  { id: '781', name: 'Chartered Insurance Professional' },
  { id: '146', name: 'Chartered Investment Counsellor' },
  { id: '150', name: 'Chartered Life Underwriter' },
  { id: '650', name: 'Chartered Mediator' },
  {
    id: '8',
    name: 'Chartered Professional (Geology), Member of the Australasian Institute of Mining & Metallurgy',
  },
  { id: '636', name: 'Chartered Professional, Strategic Wealth' },
  { id: '24', name: 'Chartered Professionals in Human Resources' },
  { id: '158', name: 'Chartered Property Casualty Underwriter' },
  { id: '172', name: 'Chevalier - Legion of Honour' },
  { id: '738', name: "Chevalier de l'ordre National du Mérite (France)" },
  { id: '598', name: "Chevalier de l'ordre national du Québec" },
  { id: '134', name: 'Civil Engineer' },
  { id: '124', name: 'Commander of the British Empire' },
  {
    id: '154',
    name: "Commander of the Order of Military Merit/Commandeur de l'Ordre du Mérite Militaire",
  },
  { id: '167', name: 'Commander of the Order of St. John of Jerusalem' },
  { id: '605', name: 'Companion of the Chartered Management Institute' },
  { id: '206', name: 'Companion of the Distinguished Service Order' },
  {
    id: '127',
    name: "Companion of the Order of Canada/Compagnon de l'Ordre du Canada",
  },
  { id: '163', name: 'Conseil de la Reine' },
  { id: '588', name: 'Conseiller en ressources humaines agréé' },
  {
    id: '671',
    name: 'Conseillers en ressources humaines et relations industrielles agréés',
  },
  { id: '657', name: 'Corporate Finance' },
  { id: '742', name: 'Council of International Investigators' },
  { id: '600', name: 'Director Institute of Corporate Directors' },
  { id: '192', name: 'Distinguished Flying Cross' },
  {
    id: '697',
    name: 'European Association of Geoscientists & Engineers',
  },
  { id: '23', name: 'Family Enterprise Advisor' },
  { id: '783', name: 'Fellow Certified Human Resources Professional' },
  { id: '779', name: 'Fellow of Engineers Canada' },
  { id: '28', name: 'Fellow of Financial Planning Canada' },
  { id: '231', name: 'Fellow of the Administration Association' },
  { id: '553', name: 'Fellow of the American Academy of Dermatology' },
  {
    id: '602',
    name: 'Fellow of the American Association for the Advancement of Science',
  },
  { id: '526', name: 'Fellow of the American College of Cardiology' },
  {
    id: '611',
    name: 'Fellow of the American College of Healthcare Executives',
  },
  { id: '224', name: 'Fellow of the American College of Physicians' },
  { id: '225', name: 'Fellow of the American College of Surgeons' },
  { id: '226', name: 'Fellow of the American College of Trial Lawyers' },
  { id: '227', name: 'Fellow of the American Institute of Architects' },
  {
    id: '229',
    name: 'Fellow of the American Institute of Electrical Engineers',
  },
  {
    id: '570',
    name: 'Fellow of the American Society in Clinical Pathology',
  },
  {
    id: '230',
    name: 'Fellow of the American Society of Civil Engineers',
  },
  { id: '228', name: 'Fellow of the Architectural Institute of Canada' },
  {
    id: '223',
    name: 'Fellow of the Association of Certified and Corporate Accountants',
  },
  {
    id: '240',
    name: 'Fellow of the Association of Chartered Certified Accountants',
  },
  {
    id: '655',
    name: 'Fellow of the Australasian Institute of Mining & Metallurgy',
  },
  { id: '232', name: 'Fellow of the British Academy' },
  { id: '233', name: 'Fellow of the British Institute of Management' },
  {
    id: '572',
    name: 'Fellow of the Canadian Academy of Clinical Biochemistry',
  },
  { id: '235', name: 'Fellow of the Canadian Academy of Engineering' },
  {
    id: '788',
    name: 'Fellow of the Canadian Academy of Health Sciences',
  },
  {
    id: '244',
    name: 'Fellow of the Canadian Certified General Accountants Association',
  },
  {
    id: '610',
    name: 'Fellow of the Canadian College of Health Service Executives',
  },
  { id: '245', name: 'Fellow of the Canadian Credit Institute' },
  { id: '242', name: 'Fellow of the Canadian Credit Union Institute' },
  {
    id: '614',
    name: 'Fellow of the Canadian Information Processing Society',
  },
  { id: '246', name: 'Fellow of the Canadian Institute of Actuaries' },
  {
    id: '581',
    name: 'Fellow of the Canadian Institute of Chartered Business Valuators',
  },
  {
    id: '573',
    name: 'Fellow of the Canadian Institute of Mining, Metallurgy & Petroleum',
  },
  { id: '612', name: 'Fellow of the Canadian Nuclear Society' },
  { id: '606', name: 'Fellow of the Canadian Psychological Association' },
  { id: '630', name: 'Fellow of the Canadian Public Relations Society' },
  { id: '257', name: 'Fellow of the Canadian Securities Institute' },
  {
    id: '256',
    name: 'Fellow of the Canadian Society of Civil Engineers',
  },
  {
    id: '759',
    name: 'Fellow of the Canadian Society of Landscape Architects',
  },
  { id: '237', name: 'Fellow of the Casualty Actuarial Science' },
  {
    id: '601',
    name: 'Fellow of the Center for Excellence in Manufacturing Management',
  },
  { id: '566', name: 'Fellow of the Chartered Institute of Arbitrators' },
  { id: '247', name: 'Fellow of the Chartered Institute of Bankers' },
  {
    id: '768',
    name: 'Fellow of the Chartered Institute of Management Accountants',
  },
  { id: '250', name: 'Fellow of the Chartered Institute of Marketing' },
  {
    id: '604',
    name: 'Fellow of the Chartered Institute of Personnel & Development',
  },
  { id: '252', name: 'Fellow of the Chartered Institute of Transport' },
  { id: '249', name: 'Fellow of the Chartered Insurance Institute' },
  { id: '255', name: 'Fellow of the College of Physicians' },
  { id: '617', name: 'Fellow of the Commonwealth of Learning' },
  { id: '628', name: 'Fellow of the Credit Union Institute of Canada' },
  { id: '262', name: 'Fellow of the Engineering Institute of Canada' },
  { id: '539', name: 'Fellow of the Geological Association of Canada' },
  { id: '265', name: 'Fellow of the Geological Society' },
  { id: '266', name: 'Fellow of the Geological Society of America' },
  { id: '670', name: 'Fellow of the Geological Society of South Africa' },
  { id: '269', name: 'Fellow of the Heraldry Society of Canada' },
  {
    id: '582',
    name: 'Fellow of the Indian Association of Cardiovascular-Thoracic Surgeons',
  },
  { id: '270', name: 'Fellow of the Institute of Actuaries' },
  { id: '272', name: 'Fellow of the Institute of Bankers' },
  { id: '274', name: 'Fellow of the Institute of Canadian Bankers' },
  {
    id: '236',
    name: 'Fellow of the Institute of Certified Administrative Managers',
  },
  {
    id: '254',
    name: 'Fellow of the Institute of Certified Management Consultants',
  },
  { id: '234', name: 'Fellow of the Institute of Chartered Accountants' },
  {
    id: '251',
    name: 'Fellow of the Institute of Chartered Secretaries and Administrators',
  },
  { id: '549', name: 'Fellow of the Institute of Corporate Directors' },
  { id: '289', name: 'Fellow of the Institute of Directors' },
  {
    id: '279',
    name: 'Fellow of the Institute of Electrical and Electronic Engineers',
  },
  { id: '286', name: 'Fellow of the Institute of Management' },
  {
    id: '283',
    name: 'Fellow of the Institute of Management Consultants',
  },
  { id: '282', name: 'Fellow of the Institute of Materials' },
  {
    id: '652',
    name: 'Fellow of the Institute of Materials, Minerals & Mining (U.K.)',
  },
  {
    id: '594',
    name: 'Fellow of the Institute of Materials, Minerals and Mining',
  },
  { id: '284', name: 'Fellow of the Institute of Mining and Metallurgy' },
  { id: '290', name: 'Fellow of the Institute of Physics' },
  {
    id: '586',
    name: 'Fellow of the Institute of Professional Investigators (UK)',
  },
  { id: '559', name: 'Fellow of the Institute of Public Economics' },
  { id: '775', name: 'Fellow of the Institution of Chemical Engineers' },
  {
    id: '278',
    name: 'Fellow of the Institution of Electrical Engineers',
  },
  {
    id: '688',
    name: 'Fellow of the Institution of Engineering and Technology',
  },
  {
    id: '285',
    name: 'Fellow of the Institution of Mechanical Engineers',
  },
  {
    id: '626',
    name: 'Fellow of the Instrumentation, Systems and Automation Society',
  },
  { id: '281', name: 'Fellow of the Insurance Institute of Canada' },
  { id: '291', name: 'Fellow of the Life Management Institute' },
  { id: '267', name: 'Fellow of the Ontario Hostelry Institute' },
  { id: '293', name: "Fellow of the Ontario Teachers' Federation" },
  {
    id: '660',
    name: 'Fellow of the Purchasing Management Association of Canada',
  },
  { id: '307', name: 'Fellow of the Real Estate Institute' },
  { id: '619', name: 'Fellow of the Royal Academy of Engineering' },
  { id: '297', name: 'Fellow of the Royal Aeronautical Society' },
  {
    id: '295',
    name: 'Fellow of the Royal Architectural Institute of Canada',
  },
  { id: '296', name: 'Fellow of the Royal Astronomical Society' },
  { id: '299', name: 'Fellow of the Royal College of Medicine' },
  { id: '300', name: 'Fellow of the Royal College of Physicians' },
  {
    id: '592',
    name: 'Fellow of the Royal College of Physicians and Surgeons of Canada',
  },
  {
    id: '301',
    name: 'Fellow of the Royal College of Physicians of Canada',
  },
  { id: '302', name: 'Fellow of the Royal College of Surgeons' },
  {
    id: '303',
    name: 'Fellow of the Royal College of Surgeons of Canada',
  },
  { id: '770', name: 'Fellow of the Royal Conservatory of Music' },
  { id: '305', name: 'Fellow of the Royal Geographical Society' },
  {
    id: '309',
    name: 'Fellow of the Royal Institute of Chartered Surveyors (U.K.)',
  },
  { id: '310', name: 'Fellow of the Royal Society' },
  { id: '311', name: 'Fellow of the Royal Society of Arts' },
  { id: '312', name: 'Fellow of the Royal Society of Canada' },
  {
    id: '314',
    name: 'Fellow of the Society of Accountants and Auditors',
  },
  { id: '313', name: 'Fellow of the Society of Actuaries' },
  { id: '7', name: 'Fellow of the Society of Economic Geologists' },
  {
    id: '253',
    name: 'Fellow of the Society of Management Accountants of Canada',
  },
  {
    id: '760',
    name: 'Fellow of the South African Institute of Mining and Metallurgy',
  },
  { id: '525', name: 'Fellow of the Trust Companies Institute' },
  { id: '318', name: 'Fellow of Trust Institute' },
  { id: '562', name: 'Fellow, Academy of Management' },
  { id: '560', name: 'Fellow, Chartered Insurance Professionals' },
  { id: '564', name: 'Fellow, Institut Canadien des Actuaries' },
  { id: '707', name: 'Financial Executives Institute Canada' },
  { id: '649', name: 'Financial Management Advisor' },
  { id: '618', name: 'Financial Risk Manager' },
  { id: '613', name: 'Grand  Commander Order of Saint Joachim' },
  { id: '320', name: "Grand Officier de l'Ordre national du Québec" },
  {
    id: '733',
    name: 'Human Resources and Compensation Committee Certified',
  },
  {
    id: '679',
    name: 'Human Resources Professional Association of Ontario',
  },
  { id: '726', name: 'Incorporated Engineer' },
  { id: '696', name: 'Independent Fee Appraiser' },
  { id: '692', name: 'Independent Petroleum Association of America' },
  { id: '323', name: 'Information Systems Professional' },
  { id: '515', name: 'ingénieur' },
  { id: '739', name: 'Institute of Chartered Accountants of Alberta' },
  {
    id: '666',
    name: 'Institute of Electrical & Electronic Engineers',
  },
  { id: '674', name: 'International Association of Chiefs of Police' },
  {
    id: '740',
    name: 'International Association of Security and Investigative Regulators',
  },
  { id: '678', name: 'International Facility Management Association' },
  {
    id: '328',
    name: 'Knight Commander of the Order of the British Empire',
  },
  {
    id: '757',
    name: 'Knight Commander of the Royal Order of Francis I of the Two Sicilies',
  },
  { id: '561', name: 'Knight of St. Gregory' },
  { id: '331', name: 'Knight of the Holy Sepulchre of Jerusalem' },
  {
    id: '333',
    name: 'Knight of the Military and Hospitalier Order of St. Lazarus',
  },
  { id: '335', name: 'Knight of the Order of St. John of Jerusalem' },
  {
    id: '334',
    name: 'Knight of the Sovereign and Military Order of Malta',
  },
  {
    id: '700',
    name: 'Leadership in Energy & Environmental Design® Accredited Professional',
  },
  { id: '336', name: "Légion d'Honneur" },
  { id: '363', name: 'Lieutenant of the Victorian Order' },
  { id: '654', name: 'LIMRA Leadership Institute Fellow' },
  { id: '665', name: 'Master of Geographic Information Sciences' },
  { id: '424', name: 'Member of Parliament' },
  { id: '425', name: 'Member of Provincial Parliament' },
  { id: '365', name: 'Member of the American Academy of Actuaries' },
  {
    id: '367',
    name: 'Member of the Architectural Institute of British Columbia',
  },
  {
    id: '663',
    name: 'Member of the Australasian Institute of Mining & Metallurgy',
  },
  { id: '376', name: 'Member of the British Computer Society' },
  { id: '378', name: 'Member of the British Institute of Management' },
  { id: '718', name: 'Member of the Canadian Institute of Planners' },
  { id: '749', name: 'Member of the Chartered Institute of Arbitrators' },
  { id: '585', name: 'Member of the Chartered Management Institute' },
  { id: '659', name: 'Member of the Chemical Institute of Canada' },
  {
    id: '411',
    name: 'Member of the Institute of Management Consultants',
  },
  {
    id: '552',
    name: 'Member of the Institute of Structural Engineers (UK)',
  },
  {
    id: '538',
    name: 'Member of the Institution of Civil Engineers (U.K.)',
  },
  {
    id: '409',
    name: 'Member of the Institution of Electrical and Electronic Engineers',
  },
  {
    id: '408',
    name: 'Member of the Institution of Electrical Engineers',
  },
  {
    id: '725',
    name: 'Member of the Institution of Incorporated Engineers',
  },
  {
    id: '414',
    name: 'Member of the Institution of Mechanical Engineers',
  },
  { id: '416', name: 'Member of the Legislative Assembly' },
  { id: '417', name: 'Member of the Legislative Council' },
  {
    id: '151',
    name: "Member of the Order of Canada/Membre de l'Ordre du Canada",
  },
  {
    id: '421',
    name: "Member of the Order of Military Merit/Membre de l'Ordre du Mérite Militaire",
  },
  { id: '377', name: 'Member of the Order of the British Empire' },
  { id: '430', name: 'Member of the Pharmaceutical Society' },
  {
    id: '435',
    name: 'Member of the Royal Architectural Institute of Canada',
  },
  { id: '436', name: 'Member of the Royal College of Physicians' },
  { id: '433', name: 'Member of the Royal Institute of Chemistry' },
  {
    id: '634',
    name: 'Member of the Society for Mining, Metallurgy & Exploration',
  },
  {
    id: '703',
    name: 'Member of the South African Mine Managers Association',
  },
  { id: '455', name: 'Member of the Trust Institute' },
  { id: '646', name: 'Membre de la Ordre des Architects du Québec' },
  { id: '578', name: 'Meritorious Service Cross' },
  { id: '777', name: 'Meritorious Service Medal' },
  { id: '380', name: 'Military Cross' },
  { id: '393', name: 'Mining Engineer' },
  {
    id: '741',
    name: 'National Association of Professional Background Screeners',
  },
  {
    id: '704',
    name: 'Northwest Territories and Nunavut Association of Professional Engineers and Geoscientists',
  },
  {
    id: '644',
    name: 'Northwest Territories Association of Engineers and Geoscientists',
  },
  { id: '461', name: 'Notary Public' },
  {
    id: '465',
    name: "Officer of the Order of Canada/Officier de l'Ordre du Canada",
  },
  {
    id: '478',
    name: "Officer of the Order of Military Merit/Officier de l'Ordre du Mérite Militaire",
  },
  { id: '482', name: 'Officer of the Order of St. John of Jerusalem' },
  { id: '464', name: 'Officer of the Order of the British Empire' },
  { id: '481', name: "Officier de l'Ordre national du Québec" },
  { id: '625', name: 'Ontario Association of Architects' },
  { id: '694', name: 'Ontario Professional Planners Institute' },
  { id: '732', name: 'Operational Risk Management Professional' },
  { id: '727', name: 'Orden del Sol' },
  { id: '463', name: 'Order of British Columbia' },
  { id: '541', name: 'Order of Engineers of Quebec' },
  { id: '476', name: 'Order of Manitoba' },
  { id: '590', name: 'Order of New Brunswick' },
  { id: '607', name: 'Order of Newfoundland and Labrador' },
  { id: '765', name: 'Order of Nova Scotia' },
  { id: '479', name: 'Order of Ontario' },
  { id: '608', name: 'Order of Rio Branco' },
  { id: '474', name: 'Order of St. Lazarus of Jerusalem' },
  { id: '711', name: 'Ordre de la Pléiade' },
  { id: '712', name: 'Ordre des Arts et des Lettres' },
  { id: '662', name: 'Ordre des évaluateurs agréés du Québec' },
  { id: '681', name: 'Ordre des géologues du Québec' },
  { id: '664', name: 'Ordre des ingénieurs du Québec' },
  { id: '752', name: 'Ordre des Pharmaciens du Québec' },
  { id: '735', name: 'Personal Financial Planner' },
  { id: '701', name: 'Petroleum Exploration Society of Great Britain' },
  { id: '495', name: 'Pharmaceutical Chemist' },
  { id: '580', name: 'Planificateur Financier' },
  {
    id: '744',
    name: 'Private Investigators Association of British Columbia',
  },
  { id: '486', name: 'Privy Councillor' },
  { id: '484', name: 'Professional Administrator' },
  { id: '485', name: 'Professional Agrologist' },
  { id: '717', name: 'Professional Appraiser' },
  { id: '488', name: 'Professional Engineer' },
  {
    id: '642',
    name: 'Professional Engineers & Geoscientists of Newfoundland and Labrador',
  },
  { id: '489', name: 'Professional Geologist' },
  { id: '490', name: 'Professional Geophysicist' },
  { id: '571', name: 'Professional Geoscientist' },
  { id: '491', name: 'Professional Land Economist' },
  { id: '492', name: 'Professional Landman' },
  { id: '568', name: 'Professional Logistician' },
  { id: '494', name: 'Professional Manager' },
  { id: '627', name: 'Project Management Professional' },
  { id: '498', name: "Queen's Counsel" },
  { id: '761', name: "Queen's Diamond Jubilee Medal" },
  { id: '593', name: "Queen's Golden Jubilee Medal" },
  { id: '719', name: 'Registered Dietitian' },
  { id: '500', name: 'Registered Engineering Technologist' },
  { id: '502', name: 'Registered Industrial and Cost Accountant' },
  { id: '699', name: 'Registered Interior Designer' },
  { id: '504', name: 'Registered Nurse' },
  { id: '713', name: 'Registered Pharmacist' },
  { id: '638', name: 'Registered Professional Biologist' },
  { id: '505', name: 'Registered Professional Forester' },
  { id: '543', name: 'Registered Public Accountant' },
  {
    id: '577',
    name: 'Registered Quality Assurance Professional in Good Laboratory Practices',
  },
  { id: '506', name: 'Registered Technician' },
  { id: '503', name: 'Royal Institute of Chartered Surveyors (U.K.)' },
  { id: '517', name: 'Saskatchewan Order of Merit' },
  { id: '734', name: 'Senior Human Resources Professional' },
  { id: '648', name: 'Senior Professional in Human Resources' },
  {
    id: '508',
    name: 'Serving Brother of the Order of St. John of Jerusalem',
  },
  { id: '693', name: 'Society of Economic Geologists' },
  { id: '691', name: 'Society of Petroleum Engineers' },
  { id: '685', name: 'Society of Petrophysicists and Well Log Analysts' },
  { id: '583', name: 'Trust and Estate Practitioner              ' },
];

export const degreesLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '28', name: 'Advanced Management Program' },
  { id: '903', name: 'Associate Degree in Applied Science' },
  { id: '895', name: 'Associate Degree in Science' },
  { id: '938', name: 'Associate of Arts ' },
  {
    id: '48',
    name: 'Baccalauréat en administration des affaires; Bachelor of Applied Arts',
  },
  { id: '117', name: 'Baccalauréat en ingénerie' },
  {
    id: '107',
    name: 'Baccalauréat en sciences appliquées; Bachelier ès sciences appliquées',
  },
  {
    id: '542',
    name: 'Baccalaureus in Arte Ingeniaria (Bachelor of Engineering)',
  },
  { id: '892', name: 'Baccalaureus Procurationis' },
  { id: '550', name: 'Bachelor in Business Studies' },
  { id: '961', name: 'Bachelor in Service Social ' },
  { id: '53', name: 'Bachelor of Accountancy' },
  { id: '949', name: 'Bachelor of Accounting and Financial Management ' },
  { id: '924', name: 'Bachelor of Accounting Science ' },
  { id: '54', name: 'Bachelor of Actuarial Science' },
  {
    id: '46',
    name: 'Bachelor of Actuarial Science/Bachelor of Computer Science',
  },
  { id: '55', name: 'Bachelor of Administration' },
  {
    id: '894',
    name: 'Bachelor of Administrative and Commercial Studies',
  },
  { id: '51', name: 'Bachelor of Administrative Studies' },
  { id: '883', name: 'Bachelor of Agricultural Economics' },
  { id: '56', name: 'Bachelor of Agricultural Science' },
  {
    id: '562',
    name: 'Bachelor of Applied Business and Entrepreneurship',
  },
  { id: '52', name: 'Bachelor of Applied Science' },
  { id: '957', name: 'Bachelor of Applied Technology ' },
  {
    id: '906',
    name: 'Bachelor of Applied Technology Petroleum Engineering ',
  },
  { id: '943', name: 'Bachelor of Architectural Science ' },
  { id: '57', name: 'Bachelor of Architecture' },
  { id: '45', name: 'Bachelor of Arts' },
  { id: '536', name: 'Bachelor of Arts & Science' },
  { id: '2', name: 'Bachelor of Arts (U.S.)' },
  { id: '49', name: 'Bachelor of Arts in Education' },
  { id: '47', name: 'Bachelor of Arts/Bachelor of Science' },
  { id: '922', name: 'Bachelor of Business' },
  { id: '58', name: 'Bachelor of Business Administration' },
  { id: '952', name: 'Bachelor of Business Economics' },
  { id: '59', name: 'Bachelor of Business Management' },
  { id: '858', name: 'Bachelor of Business Science' },
  { id: '64', name: 'Bachelor of Chemical Engineering' },
  { id: '60', name: 'Bachelor of Civil Law' },
  { id: '857', name: 'Bachelor of Civil Law/Bachelor of Laws' },
  { id: '67', name: 'Bachelor of Commerce' },
  { id: '108', name: 'Bachelor of Commercial Science' },
  { id: '969', name: 'Bachelor of Communications ' },
  { id: '948', name: 'Bachelor of Computer Information Systems ' },
  { id: '62', name: 'Bachelor of Computer Science' },
  { id: '956', name: 'Bachelor of Design ' },
  { id: '72', name: 'Bachelor of Economics' },
  { id: '73', name: 'Bachelor of Education' },
  { id: '902', name: 'Bachelor of Electrical Engineering' },
  { id: '74', name: 'Bachelor of Engineering' },
  { id: '69', name: 'Bachelor of Engineering (U.S.)' },
  { id: '567', name: 'Bachelor of Engineering and Management' },
  { id: '71', name: 'Bachelor of Engineering Science' },
  { id: '917', name: 'Bachelor of Environmenal Sciences ' },
  { id: '890', name: 'Bachelor of Environmental Design' },
  { id: '70', name: 'Bachelor of Environmental Studies' },
  { id: '954', name: 'Bachelor of Financial Administration' },
  { id: '75', name: 'Bachelor of Fine Arts' },
  { id: '870', name: 'Bachelor of General Studies' },
  { id: '897', name: 'Bachelor of Health Science' },
  { id: '77', name: 'Bachelor of Home Economics' },
  { id: '945', name: 'Bachelor of Hospitality Management ' },
  { id: '79', name: 'Bachelor of Human Ecology' },
  { id: '904', name: 'Bachelor of Human Kinetics' },
  { id: '971', name: 'Bachelor of Human Resource Management ' },
  {
    id: '974',
    name: 'Bachelor of Human Resources and Labour Relations ',
  },
  { id: '571', name: 'Bachelor of Humanities' },
  { id: '859', name: 'Bachelor of Industrial Design' },
  { id: '78', name: 'Bachelor of Industrial Engineering' },
  { id: '80', name: 'Bachelor of Industrial Relations' },
  { id: '887', name: 'Bachelor of Information Systems' },
  { id: '888', name: 'Bachelor of Interior Design' },
  { id: '81', name: 'Bachelor of Journalism' },
  { id: '976', name: 'Bachelor of Kinesiology ' },
  { id: '901', name: 'Bachelor of Landscape Architecture' },
  { id: '342', name: 'Bachelor of Laws' },
  { id: '5', name: 'Bachelor of Laws/Master of Business Administration' },
  { id: '84', name: 'Bachelor of Letters' },
  { id: '89', name: 'Bachelor of Management' },
  {
    id: '905',
    name: 'Bachelor of Management and Organizational Studies ',
  },
  { id: '942', name: 'Bachelor of Management Studies ' },
  { id: '919', name: 'Bachelor of Management/Bachelor of Engineering' },
  { id: '87', name: 'Bachelor of Mathematics' },
  { id: '88', name: 'Bachelor of Medical Science' },
  { id: '374', name: 'Bachelor of Medicine' },
  { id: '872', name: 'Bachelor of Medicine and Bachelor of Surgery' },
  { id: '523', name: 'Bachelor of Music' },
  { id: '90', name: 'Bachelor of Music' },
  { id: '546', name: 'Bachelor of Nursing' },
  { id: '93', name: 'Bachelor of Pedagogy' },
  { id: '95', name: 'Bachelor of Pharmacy' },
  { id: '96', name: 'Bachelor of Philosophy' },
  { id: '92', name: 'Bachelor of Physical and Health Education' },
  { id: '91', name: 'Bachelor of Physical Education' },
  { id: '877', name: 'Bachelor of Professional Arts' },
  { id: '50', name: 'Bachelor of Public Relations' },
  { id: '97', name: 'Bachelor of Recreation Education' },
  { id: '106', name: 'Bachelor of Science' },
  { id: '507', name: 'Bachelor of Science' },
  { id: '98', name: 'Bachelor of Science (U.S.)' },
  { id: '99', name: 'Bachelor of Science in Agriculture' },
  { id: '885', name: 'Bachelor of Science in Business' },
  { id: '100', name: 'Bachelor of Science in Business Administration' },
  { id: '103', name: 'Bachelor of Science in Electrical Engineering' },
  { id: '920', name: 'Bachelor of Science in Foreign Service' },
  { id: '104', name: 'Bachelor of Science in Forestry' },
  { id: '111', name: 'Bachelor of Science in Forestry' },
  { id: '112', name: 'Bachelor of Science in Nursing' },
  { id: '529', name: 'Bachelor of Science in Pharmacy' },
  { id: '572', name: 'Bachelor of Science in Pharmacy' },
  { id: '109', name: 'Bachelor of Science, Engineering' },
  { id: '955', name: 'Bachelor of Science, Engineering (U.S.) ' },
  { id: '114', name: 'Bachelor of Social Science' },
  { id: '105', name: 'Bachelor of Social Work' },
  { id: '170', name: 'Bachelor of Surgery' },
  { id: '115', name: 'Bachelor of Technology' },
  { id: '116', name: 'Bachelor of Theology' },
  { id: '884', name: 'Bachelor of Vocational Education' },
  { id: '169', name: 'Certificate' },
  { id: '864', name: 'Diploma in Health Administration' },
  { id: '874', name: 'Diploma in Investigative and Forensic Accounting' },
  {
    id: '197',
    name: 'Diploma of the Imperial College of Science, Technology and Medicine',
  },
  { id: '215', name: 'Diploma/Diplôme' },
  { id: '559', name: "Diplôme d'études approfondies" },
  { id: '185', name: "Diplôme d'études collégiales" },
  { id: '583', name: "Diplôme d'études supérieures spécialisées" },
  { id: '182', name: 'Diplôme de droit notarial' },
  { id: '205', name: 'Diplôme en Sciences Administratives' },
  { id: '212', name: "Docteur d'Université/Doctor of the University" },
  { id: '871', name: 'Docteur en droit' },
  { id: '173', name: 'Doctor of Administration' },
  { id: '174', name: 'Doctor of Agricultural Science' },
  { id: '175', name: 'Doctor of Business Administration' },
  { id: '178', name: 'Doctor of Civil Law' },
  { id: '180', name: 'Doctor of Commerce' },
  { id: '566', name: 'Doctor of Dental Medicine' },
  { id: '183', name: 'Doctor of Dental Surgery' },
  { id: '181', name: 'Doctor of Divinity' },
  { id: '187', name: 'Doctor of Economic Science' },
  { id: '861', name: 'Doctor of Education' },
  { id: '537', name: 'Doctor of Engineering' },
  { id: '190', name: 'Doctor of Engineering' },
  { id: '184', name: 'Doctor of Engineering (U.S.)' },
  { id: '196', name: 'Doctor of Humane Letters' },
  { id: '195', name: 'Doctor of Humanities' },
  { id: '862', name: 'Doctor of Juridical Science' },
  { id: '3', name: 'Doctor of Jurisprudence' },
  { id: '343', name: 'Doctor of Laws' },
  { id: '198', name: 'Doctor of Letters' },
  { id: '934', name: 'Doctor of Management ' },
  { id: '967', name: 'Doctor of Mathematics ' },
  { id: '390', name: 'Doctor of Medicine' },
  { id: '553', name: 'Doctor of Medicine and Master of Surgery' },
  { id: '886', name: 'Doctor of Naturopathic Medicine' },
  { id: '468', name: 'Doctor of Optometry' },
  { id: '527', name: 'Doctor of Pharmacy' },
  { id: '953', name: 'Doctor of Pharmacy' },
  { id: '561', name: 'Doctor of Philanthropy' },
  { id: '496', name: 'Doctor of Philosophy' },
  { id: '203', name: 'Doctor of Philosophy' },
  { id: '940', name: 'Doctor of Professional Studies' },
  { id: '931', name: 'Doctor of Public Health ' },
  { id: '199', name: 'Doctor of Sacred Letters' },
  { id: '208', name: 'Doctor of Science' },
  { id: '896', name: 'Doctor of Social Sciences' },
  { id: '575', name: 'Doctor of Technology' },
  { id: '213', name: 'Doctor of Veterinary Medicine' },
  { id: '200', name: 'Doctorat en Médecine Vétérinaire' },
  { id: '555', name: 'Doctorat Honorifique' },
  { id: '193', name: 'Doctorat Honoris Causa' },
  { id: '876', name: 'Executive Development Programme' },
  { id: '220', name: 'Executive Management Program' },
  { id: '569', name: 'Executive Master of Business Administration' },
  { id: '586', name: "Executive Master's in Technology Management" },
  { id: '914', name: 'General Management Program' },
  { id: '910', name: 'Graduate Certificate ' },
  { id: '321', name: 'Graduate Diploma' },
  { id: '320', name: 'Graduate Diploma in Public Accountancy' },
  { id: '879', name: 'Higher National Certificate' },
  {
    id: '323',
    name: 'Honours Bachelor of Arts in Business Administration',
  },
  { id: '937', name: 'Honours Bachelor of Outdoor Recreation ' },
  { id: '913', name: 'International Executive Program' },
  { id: '860', name: 'International Master in Practising Management ' },
  { id: '915', name: 'International Master of Business Administration' },
  { id: '326', name: 'Juris Doctor' },
  { id: '4', name: 'Juris Doctor/Master of Business Administration' },
  { id: '354', name: 'Licence in Administration' },
  { id: '339', name: 'Licence in Economic Sciences' },
  { id: '568', name: 'Licencié en Pédagogie' },
  { id: '565', name: 'Licentiate' },
  { id: '341', name: 'Licentiate in Accountancy' },
  { id: '355', name: 'Licentiate in Chemistry' },
  { id: '356', name: 'Licentiate in Commercial Science' },
  { id: '345', name: 'Licentiate in Laws' },
  { id: '584', name: 'Licentiate in Laws/Bachelor of Laws' },
  { id: '362', name: 'Licentiate in Theology' },
  { id: '348', name: 'Licentiate of Philosophy' },
  { id: '347', name: 'Licentiate of the Medical College of Canada' },
  { id: '368', name: 'Maître en administration publique' },
  { id: '404', name: 'Maîtrise en Fiscalité' },
  { id: '900', name: 'Master 2' },
  { id: '388', name: 'Master in Commercial Science' },
  { id: '450', name: 'Master in Commercial Science' },
  { id: '399', name: 'Master in Education' },
  { id: '970', name: 'Master in Financial Economics ' },
  { id: '865', name: 'Master in Public Policy' },
  { id: '370', name: 'Master of Accountancy' },
  { id: '372', name: 'Master of Administration' },
  { id: '909', name: 'Master of Adult Education ' },
  { id: '950', name: 'Master of Applied Finance ' },
  { id: '448', name: 'Master of Applied Science' },
  { id: '369', name: 'Master of Applied Science' },
  { id: '373', name: 'Master of Architecture' },
  { id: '152', name: 'Master of Arts' },
  { id: '24', name: 'Master of Arts (U.S.)' },
  { id: '514', name: 'Master of Arts in Teaching' },
  { id: '958', name: 'Master of Biomedical Technology ' },
  { id: '941', name: 'Master of Business' },
  { id: '375', name: 'Master of Business Administration' },
  { id: '935', name: 'Master of Business and Technology' },
  { id: '946', name: 'Master of Business Leadership ' },
  { id: '918', name: 'Master of Business Sciences' },
  { id: '377', name: 'Master of Business Studies' },
  { id: '932', name: 'Master of Business Taxation ' },
  {
    id: '962',
    name: 'Master of Business, Entrepreneurship and Technology ',
  },
  { id: '387', name: 'Master of Commerce' },
  { id: '963', name: 'Master of Communication and Media' },
  { id: '959', name: 'Master of Community and Regional Planning ' },
  { id: '873', name: 'Master of Computer Science' },
  { id: '936', name: 'Master of Design ' },
  { id: '392', name: 'Master of Divinity' },
  { id: '398', name: 'Master of Economics' },
  { id: '947', name: 'Master of Electronic Commerce ' },
  { id: '400', name: 'Master of Engineering' },
  { id: '939', name: 'Master of Engineering Management ' },
  { id: '928', name: 'Master of Engineering Science ' },
  { id: '966', name: 'Master of Enterprise ' },
  { id: '394', name: 'Master of Environmental Design' },
  { id: '397', name: 'Master of Environmental Science' },
  { id: '899', name: 'Master of Finance' },
  { id: '867', name: 'Master of Fine Arts' },
  { id: '973', name: 'Master of Forensic Sciences ' },
  { id: '911', name: 'Master of Foresty ' },
  { id: '972', name: 'Master of Geology' },
  { id: '951', name: 'Master of Health Administration ' },
  { id: '548', name: 'Master of Health Science' },
  { id: '577', name: 'Master of Health Services Administration' },
  {
    id: '968',
    name: 'Master of Human Resources and Industrial Relations ',
  },
  { id: '898', name: 'Master of Human Resources Management' },
  { id: '585', name: 'Master of Industrial and Labour Relations' },
  { id: '415', name: 'Master of Industrial Relations' },
  { id: '889', name: 'Master of Information Science' },
  { id: '574', name: 'Master of Intellectual Property' },
  { id: '912', name: 'Master of International Affairs ' },
  { id: '933', name: 'Master of International Business ' },
  { id: '893', name: 'Master of International Management' },
  { id: '869', name: 'Master of Journalism' },
  { id: '346', name: 'Master of Laws' },
  { id: '564', name: 'Master of Letters' },
  { id: '927', name: 'Master of Liberal Arts' },
  { id: '929', name: 'Master of Library & Information Sciences' },
  { id: '570', name: 'Master of Library Science' },
  { id: '351', name: 'Master of Life Science' },
  { id: '521', name: 'Master of Management' },
  {
    id: '880',
    name: 'Master of Management & Professional Accounting',
  },
  { id: '960', name: 'Master of Management Analytics ' },
  { id: '916', name: 'Master of Management Sciences ' },
  { id: '882', name: 'Master of Management Studies' },
  { id: '944', name: 'Master of Marketing ' },
  { id: '422', name: 'Master of Mathematics' },
  { id: '563', name: 'Master of Medicine ' },
  { id: '581', name: 'Master of Pharmacy' },
  { id: '431', name: 'Master of Philosophy' },
  { id: '433', name: 'Master of Planning' },
  { id: '881', name: 'Master of Professional Accounting' },
  { id: '964', name: 'Master of Professional Communication ' },
  { id: '930', name: 'Master of Public & Private Management ' },
  { id: '425', name: 'Master of Public Administration' },
  { id: '524', name: 'Master of Public Health' },
  { id: '908', name: 'Master of Resource Management' },
  { id: '447', name: 'Master of Science' },
  { id: '510', name: 'Master of Science' },
  { id: '441', name: 'Master of Science (U.S.)' },
  { id: '921', name: 'Master of Science in Administration' },
  {
    id: '578',
    name: 'Master of Science in Chemical Engineering Practice',
  },
  { id: '442', name: 'Master of Science in Commerce' },
  { id: '449', name: 'Master of Science in Commerce' },
  { id: '452', name: 'Master of Science in Economics' },
  { id: '866', name: 'Master of Science in Electrical Engineering' },
  { id: '451', name: 'Master of Science in Engineering' },
  { id: '443', name: 'Master of Science in Engineering' },
  { id: '556', name: 'Master of Science in Industrial Administration' },
  { id: '863', name: 'Master of Science in Journalism' },
  { id: '445', name: 'Master of Science in Management' },
  { id: '573', name: 'Master of Science in Mechanical Engineering' },
  { id: '519', name: 'Master of Science in Nursing' },
  { id: '530', name: 'Master of Science in Pharmacy' },
  { id: '878', name: 'Master of Science in Real Estate Development' },
  { id: '446', name: 'Master of Social Work' },
  { id: '965', name: 'Master of Strategic Studies' },
  { id: '554', name: 'Master of Surgery' },
  { id: '582', name: 'Master of Taxation' },
  { id: '875', name: 'Master of Technology' },
  { id: '453', name: 'Master of Textile Chemistry' },
  { id: '925', name: 'Master of Theological Studies' },
  { id: '926', name: 'Master of Theology' },
  { id: '458', name: 'Master of Urban and Regional Planning' },
  { id: '907', name: 'Master of Urban Planning ' },
  { id: '891', name: 'Masters Certificate' },
  {
    id: '975',
    name: 'Masters in Earth and Energy Resources Leadership ',
  },
  { id: '923', name: 'National Higher Diploma' },
  { id: '868', name: 'Owner/President Management Program' },
  { id: '10', name: "Professional Science Master's" },
  {
    id: '493',
    name: 'Program for Management Development               ',
  },
];

export const schoolsLookup: Array<ILookup> = [
  { id: '', name: '---' },
  {
    id: '852',
    name: 'A. B. Freeman School of Business, Tulane U. of Louisiana',
  },
  { id: '1667', name: 'Aalborg U.' },
  { id: '959', name: 'Aarhus School of Business, Aarhus U.' },
  { id: '769', name: 'Abilene Christian U.' },
  { id: '1', name: 'Abo Akademi' },
  { id: '2', name: 'Acadia U.' },
  { id: '1791', name: 'Acharya Nagarjuna U.' },
  { id: '1382', name: 'Adamson U.' },
  { id: '785', name: 'Adelphi U.' },
  { id: '846', name: 'Agricultural U. of Athens' },
  { id: '1558', name: 'Ahmadu Bello U.' },
  { id: '3', name: 'Ain Shams U.' },
  { id: '745', name: 'Air Force Institute of Technology' },
  { id: '4', name: 'Air War College' },
  { id: '1483', name: 'Aix-Marseille U.' },
  { id: '762', name: 'Alabama State U.' },
  { id: '1562', name: 'Alaska Pacific U.' },
  { id: '1138', name: 'Albert Einstein College of Medicine, Yeshiva U.' },
  { id: '545', name: 'Albert-Ludwigs-U. Freiburg' },
  { id: '1722', name: 'Albion College' },
  { id: '6', name: 'Alexandria U.' },
  { id: '1413', name: 'Alfred U.' },
  { id: '1203', name: 'Algerian Petroleum Institute' },
  { id: '7', name: 'Algonquin College' },
  { id: '1435', name: 'Allegheny College' },
  { id: '1126', name: 'Alliant International U.' },
  { id: '8', name: 'Alma College' },
  { id: '930', name: 'Almeda U.' },
  { id: '1010', name: 'American College of Sports Medicine' },
  { id: '9', name: 'American College of Switzerland' },
  {
    id: '10',
    name: 'American Graduate School of International Management',
  },
  {
    id: '887',
    name: 'American Graduate School of International Relations and Diplomacy',
  },
  { id: '1180', name: 'American InterContinental U.' },
  { id: '12', name: 'American U.' },
  { id: '13', name: 'American U. in Cairo' },
  { id: '835', name: 'American U. of Beirut' },
  { id: '14', name: 'Amherst College' },
  {
    id: '15',
    name: 'Amos Tuck School of Business Administration, Dartmouth College',
  },
  {
    id: '16',
    name: 'Anderson Graduate School of Management, U. of California',
  },
  { id: '17', name: 'Angelicum U.' },
  { id: '1082', name: 'Angelo State U.' },
  { id: '1499', name: 'Anhui U. of Finance and Economics' },
  { id: '1656', name: 'Aoyama Gakuin U.' },
  {
    id: '1417',
    name: 'Architectural Association School of Architecture',
  },
  { id: '1817', name: 'Aristotle U. of Thessaloniki' },
  { id: '18', name: 'Arizona State U.' },
  { id: '1391', name: 'Arthur D. Little School of Management' },
  { id: '1797', name: 'Aryamehr U. of Technology' },
  { id: '19', name: 'Ashland College' },
  { id: '711', name: 'Ashland U.' },
  { id: '1248', name: 'Asia Pacific International Institute' },
  { id: '1039', name: 'Aspen U.' },
  { id: '1130', name: 'Asper School of Business, U. of Manitoba' },
  { id: '1545', name: 'Assumption College' },
  { id: '20', name: 'Assumption U. (U. of Windsor)' },
  { id: '21', name: 'Aston U.' },
  { id: '779', name: 'Ateneo de Manila U.' },
  { id: '1380', name: 'Ateneo de Zamboango U.' },
  { id: '761', name: 'Athabasca U.' },
  { id: '1591', name: 'Athens U. of Economics & Business' },
  { id: '1607', name: 'Athlone Institute of Technology' },
  { id: '22', name: 'Atlantic School of Theology' },
  { id: '783', name: 'Atlantic Union College' },
  { id: '23', name: 'Auburn U.' },
  { id: '1569', name: 'Auckland Institute of Studies' },
  { id: '808', name: 'Auckland Technical Institute' },
  { id: '807', name: 'Auckland U. of Technology' },
  { id: '1491', name: 'Augsburg College' },
  { id: '1162', name: 'Australian Graduate School of Management' },
  { id: '24', name: 'Australian National U.' },
  { id: '1270', name: 'Azusa Pacific U.' },
  { id: '25', name: 'Babson College' },
  { id: '26', name: 'Baker U.' },
  { id: '27', name: 'Baldwin-Wallace College' },
  { id: '28', name: 'Ball State U.' },
  { id: '895', name: 'Banaras Hindu U.' },
  { id: '29', name: 'Banff School of Advanced Management' },
  { id: '30', name: 'Bangalore U.' },
  { id: '1287', name: 'Bangor U.' },
  { id: '31', name: 'Bar-Ilan U.' },
  { id: '1361', name: 'Bard College' },
  { id: '1685', name: 'Barry U.' },
  { id: '32', name: 'Bates College' },
  { id: '912', name: 'Baylor U.' },
  { id: '33', name: 'Beaver College' },
  { id: '34', name: 'Beckley College' },
  { id: '1528', name: 'Beedie School of Business, Simon Fraser U.' },
  { id: '1835', name: 'Beijing Forestry U.' },
  { id: '1728', name: 'Beijing Jiaotong U.' },
  { id: '1339', name: 'Belk School of Business, U. of North Carolina' },
  { id: '952', name: 'Bemidji State U.' },
  { id: '731', name: 'Ben Gurion U. of the Negev' },
  { id: '921', name: 'Bentley College' },
  { id: '293', name: 'Bentley U.' },
  { id: '929', name: 'Berea College' },
  { id: '1800', name: 'Berry College' },
  { id: '987', name: 'Bethel U.' },
  { id: '1804', name: 'Bharathiar U.' },
  { id: '1838', name: 'Bilkent U.' },
  { id: '1362', name: 'Binghamton U.' },
  { id: '1821', name: 'Biola U.' },
  {
    id: '1615',
    name: 'Birla Institute of Technology and Science, Pilani',
  },
  { id: '35', name: 'Birmingham-Southern College' },
  { id: '36', name: "Bishop's U." },
  { id: '1093', name: 'Bloomsburg U. of Pennsylvania' },
  { id: '37', name: 'Bob Jones U.' },
  { id: '1843', name: 'Bocconi U.' },
  { id: '1014', name: 'Bogaziçi U.' },
  { id: '949', name: 'Boise State U.' },
  { id: '1028', name: 'Bombay U.' },
  { id: '1634', name: 'Booth School of Business, U. of Chicago' },
  { id: '38', name: 'Boston College' },
  { id: '39', name: 'Boston College School of Law' },
  { id: '40', name: 'Boston U.' },
  { id: '1399', name: 'Bowdoin College' },
  { id: '1199', name: 'Bowie State U.' },
  { id: '41', name: 'Bowling Green State U.' },
  { id: '42', name: 'Bradley U.' },
  { id: '43', name: 'Brandeis U.' },
  { id: '44', name: 'Brandon U.' },
  { id: '1277', name: 'Briercrest Bible College' },
  { id: '45', name: 'Brigham Young U.' },
  { id: '47', name: 'British Columbia Institute of Technology' },
  {
    id: '48',
    name: 'British Columbia Open Learning Institute, U. of British Columbia',
  },
  { id: '49', name: 'Brock U.' },
  { id: '50', name: 'Brooklyn College' },
  { id: '1168', name: 'Brooklyn Law School' },
  { id: '51', name: 'Brooklyn U.' },
  { id: '52', name: 'Brown U.' },
  { id: '913', name: 'Brunel U. London' },
  { id: '920', name: 'Bryant College' },
  { id: '1366', name: 'Bryant U.' },
  { id: '869', name: 'Bryn Mawr College' },
  { id: '53', name: 'Bucknell U.' },
  { id: '54', name: 'Budapest U. of Economic Sciences' },
  { id: '55', name: 'Cairo U.' },
  { id: '56', name: 'California American U.' },
  { id: '1020', name: 'California Coast U.' },
  { id: '1226', name: 'California Institute of Integral Studies' },
  { id: '57', name: 'California Institute of Technology' },
  { id: '1046', name: 'California Lutheran U.' },
  { id: '58', name: 'California Polytechnic State U.' },
  { id: '59', name: 'California State U.' },
  { id: '1688', name: 'California State U., Fullerton' },
  { id: '1819', name: 'California State U., Sacramento' },
  { id: '1674', name: 'California State U., Stanislaus' },
  { id: '1165', name: 'California U. of Pennsylvania' },
  { id: '774', name: 'California Western School of Law' },
  { id: '60', name: 'Calvin College' },
  { id: '901', name: 'Camborne School of Mines, U. of Exeter' },
  { id: '1320', name: 'Cambrian College of Applied Arts and Technology' },
  {
    id: '958',
    name: 'Cambridge School of Clinical Medicine, U. of Cambridge',
  },
  { id: '1255', name: 'Cameron U.' },
  { id: '1822', name: 'Camosun College' },
  { id: '1181', name: 'Canadian College of Naturopathic Medicine' },
  { id: '1489', name: 'Canadian Forces College' },
  { id: '1529', name: 'Canadian Mennonite U.' },
  { id: '870', name: 'Canadian School of Management' },
  {
    id: '1551',
    name: 'Canadian Southern Baptist Seminary & College',
  },
  { id: '61', name: 'Canisius College' },
  { id: '464', name: 'Cape Breton U.' },
  { id: '1619', name: 'Capella U.' },
  { id: '876', name: 'Capilano U.' },
  { id: '62', name: 'Capital U.' },
  { id: '916', name: 'Cardiff U.' },
  { id: '162', name: 'Carleton College' },
  { id: '63', name: 'Carleton U.' },
  { id: '64', name: 'Carnegie Mellon U.' },
  { id: '1106', name: 'Carthage College' },
  { id: '65', name: 'Case Institute of Technology' },
  { id: '66', name: 'Case Western Reserve School of Law' },
  { id: '746', name: 'Case Western Reserve U.' },
  { id: '1324', name: 'Cass Business School' },
  { id: '67', name: 'Catholic U. of America' },
  { id: '1189', name: 'Catholic U. of Buenos Aires' },
  { id: '1105', name: 'Catholic U. of Dominican Republic' },
  { id: '1653', name: 'Catholic U. of Louvain' },
  { id: '68', name: 'Catholic U. of Sao Paulo' },
  { id: '1801', name: 'Catholic U. of the Sacred Heart' },
  { id: '1269', name: 'Cégep de Jonquière' },
  { id: '1201', name: "Cégep de l'Abitibi-Témiscaminge" },
  { id: '93', name: 'Cégep de Saint-Laurent' },
  { id: '1458', name: 'Cégep de Sherbrooke' },
  { id: '1457', name: 'Cégep du Vieux Montréal' },
  { id: '1094', name: 'Cégep Édouard-Montpetit' },
  { id: '256', name: 'Cégep Marie-Victorin' },
  { id: '1321', name: 'Centenary College' },
  { id: '1022', name: 'Centennial College' },
  { id: '69', name: 'Central Connecticut State U.' },
  { id: '70', name: 'Central Michigan U.' },
  { id: '244', name: 'Central South University' },
  { id: '71', name: 'Central Washington U.' },
  { id: '1080', name: 'Centralia College' },
  { id: '1608', name: 'Centre College' },
  {
    id: '1214',
    name: "Centre d'Études Financières, Économiques et Bancaires (Paris)",
  },
  { id: '72', name: "Centre d'Études Industrielles" },
  {
    id: '1702',
    name: 'Centro de Estudios Superiores del Estado de Sonora',
  },
  { id: '1305', name: 'Chaminade U.' },
  { id: '1349', name: 'Champlain Regional College' },
  { id: '856', name: 'Chapman U.' },
  { id: '1573', name: 'Charles Sturt U.' },
  { id: '1547', name: 'Chicago-Kent College of Law' },
  { id: '251', name: 'China Europe International Business School' },
  { id: '1385', name: 'China U. of Geosciences' },
  { id: '1439', name: 'China U. of Mining & Technology' },
  { id: '193', name: 'Chinese Academy of Social Sciences' },
  { id: '587', name: 'Chongqing U.' },
  { id: '1527', name: 'Christian-Albrechts-U. zu Kiel' },
  { id: '325', name: 'Chungnam National U.' },
  { id: '73', name: 'Chuo U.' },
  { id: '1279', name: 'Ciril & Methodius U.' },
  { id: '1301', name: 'City of London College' },
  { id: '74', name: 'City of Westminster College' },
  { id: '77', name: 'City U.' },
  { id: '148', name: 'City U. of Macau' },
  { id: '75', name: 'City U. of New York' },
  { id: '76', name: 'City U. of Seattle' },
  { id: '1378', name: 'City U. of Seattle, Vancouver' },
  { id: '789', name: 'Claremont Graduate U.' },
  { id: '1670', name: 'Clarion U.' },
  { id: '78', name: 'Clark U.' },
  { id: '80', name: 'Clarkson College' },
  { id: '79', name: 'Clarkson College of Technology' },
  { id: '81', name: 'Clarkson U.' },
  { id: '1121', name: 'Clausthal-Zellerfeld U.' },
  { id: '82', name: 'Clemson U.' },
  { id: '83', name: 'Cleveland State U.' },
  { id: '1390', name: 'Coastal Carolina U.' },
  { id: '1715', name: 'Coe College' },
  { id: '1011', name: 'Colby College' },
  { id: '85', name: 'Colgate U.' },
  { id: '1297', name: 'Collège Ahuntsic' },
  { id: '1396', name: 'Collège de Ingénieurs (Paris)' },
  { id: '92', name: 'Collège de Lévis' },
  { id: '1065', name: 'Collège des Jésuites' },
  { id: '94', name: 'Collège Jean-de-Brébeuf' },
  { id: '1124', name: 'Collège Lionel-Groulx' },
  { id: '970', name: 'Collège Marguerite Bourgeoys' },
  { id: '95', name: 'Collège Militaire Royal de Saint-Jean' },
  { id: '1013', name: 'Collège Mont-Saint-Louis' },
  { id: '87', name: 'College of New Caledonia' },
  { id: '1401', name: 'College of Saint Rose' },
  { id: '88', name: 'College of Sequoias' },
  { id: '1492', name: 'College of St. Thomas' },
  { id: '89', name: 'College of Surgeons and Physicians of Quebec' },
  { id: '1716', name: 'College of the Rockies' },
  { id: '91', name: 'College of William and Mary in Virginia' },
  { id: '683', name: 'Collège Saint-Charles-Garnier' },
  { id: '281', name: 'Collège Saint-Ignace' },
  { id: '820', name: 'Collège Saint-Louis' },
  { id: '425', name: 'Collège Sainte-Anne' },
  { id: '97', name: 'Collège Sainte-Marie' },
  { id: '96', name: 'Collège Stanislas' },
  { id: '98', name: 'Colorado College' },
  { id: '99', name: 'Colorado School of Mines' },
  { id: '100', name: 'Colorado State U.' },
  { id: '101', name: 'Columbia Bible College' },
  { id: '922', name: 'Columbia Business School, Columbia U.' },
  { id: '795', name: 'Columbia College' },
  { id: '102', name: 'Columbia Pacific U.' },
  { id: '103', name: 'Columbia U.' },
  { id: '1603', name: 'Comenius U.' },
  { id: '1407', name: 'Communication U. of China (Beijing)' },
  { id: '1190', name: 'CompuCollege School of Business' },
  { id: '1246', name: 'Concordia College' },
  { id: '105', name: 'Concordia Lutheran Seminary' },
  { id: '106', name: 'Concordia U.' },
  { id: '813', name: 'Conestoga College' },
  { id: '1412', name: 'Connecticut College' },
  { id: '107', name: 'Cooperative College of Canada' },
  { id: '1233', name: 'Copenhagen Business School' },
  {
    id: '750',
    name: 'COPPEAD Graduate School of Business, U. Federal do Rio de Janeiro',
  },
  { id: '1694', name: 'Cork Institute of Technology' },
  { id: '108', name: 'Cornell U.' },
  { id: '200', name: 'Coventry Polytechnic' },
  { id: '955', name: 'Coventry U.' },
  { id: '109', name: 'Cranfield Institute of Technology' },
  { id: '110', name: 'Cranfield School of Management' },
  { id: '111', name: 'Cranfield U.' },
  { id: '112', name: 'Creighton U.' },
  { id: '849', name: 'Curtin U.' },
  { id: '829', name: 'Czech Technical U. in Prague' },
  { id: '1723', name: "D'Youville College" },
  { id: '113', name: 'Dalhousie Law School' },
  { id: '114', name: 'Dalhousie U.' },
  { id: '832', name: 'Dalhousie U. Polytechnic (DalTech)' },
  { id: '1471', name: 'Dalian U. of Technology' },
  { id: '1678', name: 'Damascus U.' },
  { id: '1303', name: 'Damelin Correspondence College (Pty) Ltd.' },
  { id: '115', name: 'Darden School of Business, U. of Virginia' },
  { id: '117', name: 'Dartmouth College' },
  { id: '1802', name: 'Davenport U.' },
  { id: '118', name: 'Davidson College' },
  { id: '119', name: 'Dawson College' },
  { id: '1700', name: 'De La Salle U.' },
  { id: '1482', name: 'De Montfort U.' },
  { id: '1272', name: 'Deakin U.' },
  { id: '972', name: 'Defiance College' },
  { id: '1247', name: 'DeGroote School of Business, McMaster U.' },
  { id: '120', name: 'Delft U. of Technology' },
  { id: '1263', name: 'Delta State U.' },
  { id: '742', name: 'Denison U.' },
  { id: '121', name: 'DePaul U.' },
  { id: '122', name: 'DePauw U.' },
  { id: '1530', name: 'Desautels Faculty of Management, McGill U.' },
  { id: '123', name: 'Detroit Institute of Technology' },
  { id: '834', name: 'DeVry Institute of Technology' },
  { id: '1196', name: 'Dickinson College' },
  { id: '1796', name: 'Dongbei U. of Finance and Economics' },
  { id: '1734', name: 'Dordt College' },
  { id: '1735', name: 'Dordt U.' },
  { id: '814', name: 'Douglas College' },
  { id: '351', name: 'Dowling College' },
  { id: '864', name: 'Dr. B.R. Ambedkar Open U.' },
  { id: '124', name: 'Drake U.' },
  { id: '125', name: 'Drexel U.' },
  { id: '1586', name: 'Drexel U. College of Medicine' },
  { id: '1504', name: 'Dublin City U.' },
  { id: '1232', name: 'Dublin Institute of Technology' },
  { id: '126', name: 'Duke U.' },
  { id: '757', name: 'Duquesne U.' },
  { id: '1597', name: 'Durham College of Applied Arts & Technology' },
  { id: '127', name: 'Durham U.' },
  {
    id: '1647',
    name: 'Dwight D. Eisenhower School for National Security and Resource Stategy',
  },
  { id: '732', name: 'East Carolina U.' },
  { id: '1280', name: 'East Central U.' },
  { id: '1519', name: 'East China Normal U.' },
  { id: '1582', name: 'East China U. of Science & Technology' },
  { id: '1076', name: 'East Tennessee State U.' },
  { id: '1275', name: 'Eastern Illinois U.' },
  { id: '1792', name: 'Eastern Mediterranean U.' },
  { id: '1217', name: 'Eastern Michigan U.' },
  { id: '1834', name: 'Eastern U.' },
  { id: '128', name: 'Eastern Washington U.' },
  { id: '129', name: 'École Centrale de Lyon' },
  {
    id: '1038',
    name: "École d'Electricité et Mécanique Industrielles, Paris",
  },
  { id: '1346', name: 'École de Management de Lyon' },
  { id: '131', name: 'École des Hautes Études Commerciales (Montréal)' },
  { id: '130', name: 'École des Hautes Études Commerciales (Paris)' },
  { id: '1235', name: 'École des Hautes Études en Sciences Sociales' },
  { id: '805', name: 'École des Mines de Paris' },
  { id: '1601', name: 'École du Barreau' },
  { id: '1285', name: 'École Européenne des Affaires' },
  { id: '1828', name: 'École Hoteliere de Lausanne' },
  { id: '1040', name: 'École Internationale des Affaires' },
  { id: '1322', name: "École Nationale d'Administration du Sénégal" },
  {
    id: '132',
    name: "École Nationale d'Administration Publique, U. du Québec",
  },
  { id: '133', name: "École Nationale de l'Aéronautique" },
  { id: '134', name: 'École Nationale des Ponts et Chaussées' },
  {
    id: '1433',
    name: "École Nationale Supérieure d'Arts et Métiers (Paris)",
  },
  {
    id: '1131',
    name: "École Nationale Supérieure d'Électricité et de Mécanique de Nancy",
  },
  {
    id: '1736',
    name: "École Nationale Supérieure d'Électrotechnique, d'Électronique, d'Informatique, d'Hydraulique et des Télécommunicat",
  },
  {
    id: '735',
    name: "École Nationale Supérieure d'Ingénieurs Électriciens de Grenoble",
  },
  { id: '135', name: 'École Nationale Supérieure de Géologie' },
  {
    id: '1256',
    name: 'École Nationale Supérieure de Techniques Avancées (Paris)',
  },
  { id: '136', name: 'École Nationale Supérieure des Mines de Nancy' },
  {
    id: '999',
    name: 'École Nationale Supérieure des Télécommunications',
  },
  { id: '138', name: 'École Polytechnique' },
  { id: '137', name: 'École Polytechnique de Montréal' },
  { id: '1242', name: 'École Polytechnique Fédérale de Lausanne' },
  {
    id: '911',
    name: "École Spéciale des Travaux Publics, du Bâtiment et de l'Industrie",
  },
  { id: '139', name: 'École Supérieure de Commerce de Lyon' },
  { id: '140', name: 'École Supérieure de Commerce de Paris' },
  { id: '1332', name: 'École Supérieure de Commerce de Tours-Poitiers' },
  {
    id: '1486',
    name: "École Supérieure des Sciences Commerciales d'Angers",
  },
  {
    id: '141',
    name: 'École Supérieure des Sciences Économiques et Commerciales (Paris)',
  },
  {
    id: '1056',
    name: 'École Supérieure Libre des Sciences Commerciales Appliquées (Paris)',
  },
  { id: '831', name: 'Edinburgh Business School, Heriot-Watt U.' },
  { id: '1490', name: 'Edinburgh Napier U.' },
  { id: '142', name: 'Edinburgh U.' },
  { id: '1610', name: 'Edith Cowan U.' },
  { id: '1531', name: 'Edwards School of Business, U. of Saskatchewan' },
  { id: '1057', name: 'Ehime U.' },
  { id: '1055', name: 'Eindhoven U. of Technology' },
  { id: '979', name: 'Elmhurst College' },
  { id: '971', name: 'Embry-Riddle Aeronautical U.' },
  { id: '954', name: 'Emerson College' },
  { id: '671', name: 'Emily Carr U. of Art and Design' },
  { id: '143', name: 'Emory U.' },
  { id: '867', name: 'Erasmus U. Rotterdam' },
  { id: '1447', name: 'ESAN, Graduate School of Business' },
  { id: '1079', name: 'Escola de Administracao de Empresas (Sao Paulo)' },
  { id: '1726', name: 'Escola Superior de Propaganda e Marketing' },
  {
    id: '1160',
    name: "Escola Tècnica Superior d'Enginyeria Industrial de Barcelona",
  },
  { id: '1584', name: 'Escuela Bancaria y Comercial' },
  { id: '1506', name: 'Escuela de Organización Industrial' },
  { id: '906', name: 'European U.' },
  { id: '1117', name: 'Evangel U.' },
  { id: '145', name: 'Exeter U.' },
  { id: '1206', name: 'Fairfield U.' },
  { id: '147', name: 'Fairleigh Dickinson U.' },
  { id: '146', name: 'Fanshawe College' },
  { id: '1397', name: 'Far Eastern U.' },
  { id: '1025', name: 'Federal Institute of Technology' },
  { id: '1799', name: 'Federation U., Australia' },
  { id: '1067', name: 'Ferris State U.' },
  { id: '1300', name: 'Fielding Graduate U.' },
  { id: '149', name: 'Fletcher School of Law and Diplomacy, Tufts U.' },
  { id: '463', name: 'Flinders U.' },
  { id: '150', name: 'Florida Atlantic U.' },
  { id: '1660', name: 'Florida Gulf Coast U.' },
  { id: '1461', name: 'Florida Institute of Technology' },
  { id: '943', name: 'Florida International U.' },
  { id: '151', name: 'Florida State U.' },
  { id: '152', name: 'Fordham U.' },
  { id: '1194', name: 'Fort Lewis College' },
  { id: '799', name: 'Franklin & Marshall College' },
  { id: '908', name: 'Franklin Pierce Law Center' },
  { id: '1195', name: 'Franklin U.' },
  { id: '191', name: 'Franklin U. Switzerland' },
  { id: '1092', name: 'Freed-Hardeman U.' },
  { id: '1027', name: 'Freie U. Berlin' },
  { id: '1616', name: 'Frostburg State U.' },
  { id: '1388', name: 'Fu-Jen Catholic U.' },
  { id: '1288', name: 'Fudan U.' },
  { id: '156', name: 'Fukushima U.' },
  { id: '1473', name: 'Fundação Dom Cabral' },
  { id: '828', name: 'Fuqua School of Business, Duke U.' },
  { id: '523', name: 'Furman U.' },
  { id: '157', name: 'Gannon U.' },
  { id: '898', name: 'Georg-August-U. Göttingen' },
  {
    id: '159',
    name: 'George Brown College of Applied Arts and Technology',
  },
  { id: '1282', name: 'George Fox U.' },
  { id: '947', name: 'George Mason U.' },
  { id: '160', name: 'George Washington U.' },
  { id: '161', name: 'Georgetown U.' },
  { id: '163', name: 'Georgia Institute of Technology' },
  { id: '1127', name: 'Georgia Southern U.' },
  { id: '164', name: 'Georgia State U.' },
  { id: '1343', name: 'Georgian College' },
  { id: '165', name: 'Gettysburg College' },
  { id: '755', name: 'Ghent U.' },
  { id: '740', name: 'Glasgow Caledonian U.' },
  { id: '166', name: 'GMI Engineering & Management Institute' },
  { id: '1793', name: 'Goethe U. Frankfurt' },
  { id: '1172', name: 'Golden Gate U.' },
  { id: '1815', name: 'Goldey-Beacom College' },
  { id: '167', name: 'Gonzaga U.' },
  { id: '1532', name: 'Goodman School of Business, Brock U.' },
  { id: '811', name: 'Goshen College' },
  {
    id: '168',
    name: 'Gothenburg School of Economics and Commercial Law, U. of Gothenburg',
  },
  { id: '945', name: 'Goucher College' },
  { id: '169', name: 'Graceland College' },
  { id: '190', name: 'Graceland U.' },
  { id: '1381', name: 'Graduate School of Business, De La Salle U.' },
  { id: '1844', name: 'Grambling State U.' },
  { id: '171', name: "Gray's Inn, London" },
  { id: '1340', name: 'Greenville College' },
  { id: '172', name: 'Griffith U.' },
  { id: '1709', name: 'Grove City College' },
  { id: '1724', name: 'Guangdong U. of Finance and Economics' },
  { id: '830', name: 'Guilford Technical Community College' },
  { id: '1605', name: 'Guilin U. of Electronic Technology' },
  { id: '1118', name: 'Hagen U.' },
  { id: '174', name: 'Haileybury School of Mines' },
  { id: '175', name: 'Hamilton College' },
  { id: '176', name: 'Hamline U.' },
  { id: '1227', name: 'Hampshire College' },
  { id: '1113', name: 'Handelavond College' },
  { id: '753', name: 'Hanyang U.' },
  { id: '1790', name: 'Harbin Institute of Technology' },
  { id: '1627', name: 'Harding U.' },
  { id: '1114', name: 'Hartfordshire U.' },
  { id: '1031', name: 'Harvard Business School' },
  { id: '1016', name: 'Harvard College, Harvard U.' },
  {
    id: '177',
    name: 'Harvard Graduate School of Business Administration',
  },
  { id: '815', name: 'Harvard Graduate School of Design' },
  { id: '787', name: 'Harvard Graduate School of Public Administration' },
  { id: '178', name: 'Harvard Law School' },
  { id: '1375', name: 'Harvard Medical School' },
  { id: '179', name: 'Harvard U.' },
  { id: '1178', name: 'Haskayne School of Business, U. of Calgary' },
  { id: '1424', name: 'Haverford College' },
  { id: '181', name: 'Hebrew U. of Jerusalem' },
  { id: '1147', name: 'Heidelberg College' },
  { id: '1521', name: 'Heidelberg U.' },
  { id: '262', name: 'Heilongjiang U. of Science & Technology' },
  {
    id: '182',
    name: 'Helsinki School of Economics and Business Administration',
  },
  { id: '1659', name: 'Henderson State U.' },
  { id: '1550', name: 'Hendrix College' },
  { id: '183', name: 'Henley Management College, Brunel U.' },
  { id: '184', name: 'Heriot-Watt U.' },
  { id: '185', name: 'High Point U.' },
  { id: '186', name: 'Hillsdale College' },
  { id: '187', name: 'Hitotsubashi U.' },
  { id: '1418', name: 'Hobart College' },
  { id: '786', name: 'Hofstra U.' },
  { id: '1830', name: 'Holon Institute of Technology' },
  { id: '932', name: 'Holy Cross College' },
  { id: '1344', name: 'Hong Kong Baptist College' },
  { id: '188', name: 'Hong Kong Polytechnic U.' },
  { id: '1638', name: 'Hong Kong U. of Science and Technology' },
  { id: '986', name: 'Honolulu U.' },
  { id: '1515', name: 'Hosei U.' },
  { id: '1365', name: 'Houghton College' },
  { id: '1372', name: 'Howard U.' },
  { id: '1427', name: 'Huazhong U. of Science & Technology' },
  { id: '1077', name: 'Hull U.' },
  { id: '189', name: 'Humber College' },
  { id: '1392', name: 'Humboldt U. of Berlin' },
  { id: '1341', name: 'Hunter College' },
  { id: '1096', name: 'Huntingdon College' },
  { id: '1639', name: 'Huron College' },
  { id: '885', name: 'Huron U. College, U. of Western Ontario' },
  { id: '1840', name: 'IAE Business School, U. Austral' },
  { id: '1827', name: 'Idaho State U.' },
  { id: '1520', name: 'IE Business School' },
  { id: '1049', name: 'Illinois Institute of Technology' },
  { id: '1276', name: 'Illinois State U.' },
  {
    id: '956',
    name: 'IMD - International Institute for Management Development',
  },
  { id: '1574', name: 'Imperial College London' },
  {
    id: '192',
    name: 'Imperial College of Science, Technology and Medicine, U. of London',
  },
  { id: '1478', name: 'INALDE Business School, U. de la Sabana' },
  { id: '923', name: 'Indian Institute of Management' },
  { id: '194', name: 'Indian Institute of Technology' },
  { id: '848', name: 'Indian School of Mines' },
  { id: '961', name: 'Indiana State U.' },
  { id: '195', name: 'Indiana U.' },
  { id: '1689', name: 'Indiana U., Bloomington' },
  { id: '196', name: 'Indiana U., South Bend' },
  { id: '1695', name: 'Indiana Wesleyan U.' },
  { id: '1646', name: 'Industrial College of the Armed Forces' },
  { id: '197', name: "Ingénieur École Supérieure d'Électricité, Paris" },
  { id: '1784', name: 'Inha U.' },
  { id: '202', name: 'INSEAD' },
  { id: '1405', name: 'INSEEC Business School' },
  { id: '1133', name: 'Institut Algérien du Pétrole' },
  {
    id: '1198',
    name: "Institut d'Administration des Entreprises de Paris",
  },
  { id: '917', name: "Institut d'Études Politiques de Bordeaux" },
  { id: '201', name: "Institut d'Études Politiques de Paris" },
  { id: '1419', name: 'Institut de Technologie de Trois-Rivières' },
  {
    id: '1192',
    name: "Institut des Hautes Études de Droit Rural et d'Économie Agricole",
  },
  {
    id: '203',
    name: 'Institut National de la Recherche Scientifique, U. du Québec',
  },
  { id: '204', name: 'Institut National des Sciences Appliquées' },
  { id: '842', name: 'Institut National des Télécommunications' },
  { id: '850', name: 'Institut National Polytechnique de Grenoble' },
  { id: '1197', name: 'Institut Supérieur de Gestion, Paris' },
  { id: '1071', name: 'Institute of Mining Geology, Akita U.' },
  { id: '767', name: 'Institute of Paper Chemistry' },
  { id: '768', name: 'Institute of Paper Science and Technology' },
  { id: '206', name: 'Institute of Personnel Management' },
  { id: '207', name: 'Institute of Quantity Surveyors' },
  { id: '208', name: 'Institute of Social Sciences (Rome)' },
  { id: '988', name: 'Institute of Tropical Medicine Antwerp' },
  { id: '1312', name: 'Instituto Catolico de Artes e Industrias' },
  { id: '1311', name: 'Instituto Politécnico Nacional' },
  { id: '1048', name: 'Instituto Tecnológico Autónomo de México' },
  { id: '1810', name: 'Instituto Tecnológico de Buenos Aires' },
  {
    id: '1036',
    name: 'Instituto Tecnológico y de Estudios Superiores de Monterrey',
  },
  { id: '836', name: 'International Business School (Lippstadt)' },
  {
    id: '210',
    name: 'International Institute for Management Development',
  },
  {
    id: '1357',
    name: 'International Institute of Aerial Survey and Earth Sciences',
  },
  { id: '211', name: 'International Management Institute' },
  { id: '1223', name: 'International U. of Civil Aviation' },
  { id: '1370', name: 'International U. of Japan' },
  { id: '1376', name: 'International U. of Monaco' },
  { id: '734', name: 'Iona College' },
  { id: '212', name: 'Iowa State U.' },
  { id: '1679', name: 'Istanbul Technical U.' },
  { id: '994', name: 'Ithaca College' },
  { id: '1533', name: 'Ivey Business School, U. of Western Ontario' },
  { id: '213', name: 'Jacksonville State U.' },
  { id: '214', name: 'Jacksonville U.' },
  { id: '964', name: 'James Cook U.' },
  { id: '1595', name: 'James Madison U.' },
  { id: '215', name: 'Jamestown College' },
  { id: '736', name: 'Janus U.' },
  { id: '801', name: 'Jefferson Medical College' },
  { id: '1174', name: 'Jianghan Petroleum Institute' },
  { id: '1210', name: 'Jiangxi Medical College' },
  { id: '1352', name: 'Jiangxi U. of Science and Technology' },
  { id: '892', name: 'Jilin Radio & TV U.' },
  { id: '1271', name: 'Jilin U.' },
  { id: '1692', name: 'Jiwaji U.' },
  { id: '1193', name: 'Johannes Gutenberg U.' },
  { id: '851', name: 'Johannes Kepler U. Linz' },
  { id: '1553', name: 'John Brown U.' },
  { id: '902', name: 'John Carroll U.' },
  { id: '788', name: 'John F. Kennedy School of Government, Harvard U.' },
  { id: '219', name: 'John Molson School of Business, Concordia U.' },
  { id: '1266', name: 'John Moores U.' },
  { id: '217', name: 'Johns Hopkins U.' },
  { id: '1524', name: 'José Rizal U.' },
  { id: '1809', name: 'Kagoshima U.' },
  { id: '1657', name: 'Kalamazoo College' },
  { id: '218', name: 'Kansas State U.' },
  { id: '1472', name: 'Kaplan Business School' },
  { id: '1451', name: 'Karlsruhe Institute of Technology' },
  { id: '868', name: 'Karnataka U.' },
  { id: '941', name: 'Karolinska Institutet' },
  { id: '924', name: 'Katholieke U. Leuven' },
  { id: '1693', name: 'Kean U.' },
  { id: '1306', name: 'Keble College, U. of Oxford' },
  { id: '1318', name: 'Keene State College' },
  { id: '220', name: 'Keio U.' },
  { id: '1336', name: 'Keller School of Management, Devry U.' },
  { id: '1487', name: 'Kelley School of Business, Indiana U.' },
  { id: '1611', name: 'Kellogg Business School, Northwestern U.' },
  {
    id: '221',
    name: 'Kellogg Graduate School of Management, Northwestern U.',
  },
  {
    id: '1122',
    name: 'Kelsey Institute, Saskatchewan Institute of Applied Science & Technology',
  },
  { id: '1459', name: 'Kennesaw State U.' },
  {
    id: '1267',
    name: 'Kenneth Levene Graduate School of Business, U. of Regina',
  },
  { id: '222', name: 'Kent State U.' },
  { id: '223', name: 'Kenyon College' },
  { id: '871', name: 'Kettering U.' },
  { id: '1572', name: 'King Fahd U. of Petroleum & Minerals' },
  { id: '226', name: "King's College" },
  { id: '225', name: "King's College, U. of Cambridge" },
  { id: '224', name: "King's College, U. of London" },
  { id: '1041', name: 'Kingston University' },
  { id: '227', name: 'Knox College, U. of Toronto' },
  { id: '228', name: 'Kobe U.' },
  { id: '739', name: 'Korea U.' },
  { id: '1123', name: 'Krannert School of Management, Perdue U.' },
  { id: '1291', name: 'Kunming U. of Science & Technology' },
  { id: '1719', name: 'Kutztown U.' },
  { id: '1820', name: 'Kutztown U.' },
  { id: '1703', name: 'Kwame Nkrumah U. of Science and Technology' },
  { id: '1090', name: 'Kwantlen Polytechnic U.' },
  { id: '1710', name: 'Kyiv Polytechnic Institute' },
  { id: '1249', name: 'Kyiv State U.' },
  { id: '229', name: 'Kyoto U.' },
  { id: '230', name: 'Kyung Hee U.' },
  { id: '1356', name: "L'Institut de Technologie Agroalimentaire" },
  { id: '231', name: 'La Salle U.' },
  { id: '1452', name: 'La Trobe U.' },
  { id: '232', name: 'Lafayette College' },
  { id: '804', name: 'Lake Forest College' },
  { id: '1099', name: 'Lake Forest Graduate School of Management' },
  { id: '233', name: 'Lake Superior State U.' },
  { id: '234', name: 'Lakehead U.' },
  { id: '918', name: 'Lamar U.' },
  { id: '1222', name: 'Lambton College' },
  { id: '235', name: 'Lanchester College of Technology' },
  { id: '199', name: 'Lanchester Polytechnic' },
  { id: '1185', name: 'Langara College' },
  { id: '1187', name: 'Lanzhou U.' },
  { id: '1567', name: 'Lapland U. of Applied Sciences' },
  { id: '1701', name: 'Lappeenranta-Lahti U. of Technology LUT' },
  { id: '236', name: 'Laurentian U.' },
  { id: '237', name: 'Lawrence Institute of Technology' },
  { id: '173', name: 'Lawrence Technological U.' },
  { id: '1449', name: 'Lawrence U.' },
  {
    id: '1534',
    name: 'Lazaridis School of Business & Economics, Wilfrid Laurier U.',
  },
  { id: '238', name: 'Le Moyne College' },
  { id: '1518', name: 'Lebanese American U.' },
  { id: '86', name: 'Leeds Beckett U.' },
  { id: '1200', name: 'Leeds Metropolitan U.' },
  { id: '239', name: 'Lehigh U.' },
  { id: '763', name: 'Leibniz U. Hannover' },
  { id: '903', name: 'Leicester Polytechnic' },
  { id: '240', name: 'Leiden U.' },
  { id: '873', name: 'Leonard N. Stern School of Business, New York U.' },
  { id: '1283', name: 'Lethbridge College' },
  { id: '960', name: 'LeTourneau U.' },
  { id: '241', name: 'Lewis and Clark College' },
  { id: '1476', name: 'Limestone College' },
  { id: '1636', name: 'Lindenwood U.' },
  { id: '1698', name: 'Liverpool John Moores U.' },
  { id: '782', name: 'Loma Linda U.' },
  { id: '242', name: 'London Business School' },
  { id: '245', name: 'London School of Economics and Political Science' },
  { id: '1563', name: 'London South Bank U.' },
  { id: '246', name: 'Long Island U.' },
  { id: '247', name: 'Loughborough U.' },
  { id: '248', name: 'Louisiana State U.' },
  { id: '249', name: 'Louisiana Tech U.' },
  { id: '1088', name: 'Loyalist College' },
  { id: '250', name: 'Loyola College' },
  { id: '854', name: 'Loyola Marymount U.' },
  { id: '252', name: 'Loyola U.' },
  { id: '810', name: 'Ludwig-Maximilians-Universität München' },
  { id: '1485', name: 'Luleå U. of Technology' },
  { id: '940', name: 'Lund U.' },
  { id: '1705', name: 'Maastricht U.' },
  { id: '1225', name: 'Macalester College' },
  { id: '1645', name: 'MacEwan U.' },
  { id: '253', name: 'Mackay School of Mines, U. of Nevada - Reno' },
  { id: '1727', name: 'Mackenzie Presbyterian U.' },
  { id: '1037', name: 'Macquarie Graduate School of Management' },
  { id: '254', name: 'Macquarie U.' },
  { id: '1803', name: 'Madonna U.' },
  { id: '967', name: 'Maharaja Sayajirao U. of Baroda' },
  { id: '1183', name: 'Makerere U.' },
  { id: '255', name: 'Malmo Technical Institute' },
  { id: '865', name: 'Malone College' },
  { id: '243', name: 'Malone U.' },
  { id: '749', name: 'Manchester Metropolitan U.' },
  { id: '748', name: 'Manchester Polytechnic' },
  { id: '1338', name: 'Manhattan College' },
  { id: '725', name: 'Mankato State U.' },
  { id: '1463', name: 'Mansfield U. of Pennsylvania' },
  { id: '1070', name: 'Mapúa Institute of Technology' },
  { id: '1143', name: 'Marietta College' },
  { id: '927', name: 'Marquette U.' },
  { id: '382', name: 'Marriott School of Management, Brigham Young U.' },
  {
    id: '1402',
    name: 'Marshall School of Business, U. of Southern California',
  },
  { id: '973', name: 'Marshall U.' },
  { id: '897', name: 'Martin-Luther-U. Halle-Wittenberg' },
  { id: '257', name: 'Marymount College' },
  {
    id: '995',
    name: 'Massachusetts College of Pharmacy and Health Sciences',
  },
  { id: '258', name: 'Massachusetts Institute of Technology' },
  { id: '1377', name: 'Massey U.' },
  {
    id: '1371',
    name: 'McColl School of Business, Queens U. of Charlotte',
  },
  { id: '259', name: 'McGill U.' },
  { id: '260', name: 'McGill U. Management Institute' },
  { id: '1585', name: 'McKendree U.' },
  { id: '261', name: 'McMaster U.' },
  { id: '1098', name: 'McMurry U.' },
  { id: '1587', name: 'MCP Hahnemann U.' },
  { id: '1588', name: 'Medical College of Pennsylvania' },
  {
    id: '1087',
    name: 'Medical College of Virginia, Virginia Commonwealth U.',
  },
  { id: '1289', name: 'Medicine Hat College' },
  { id: '1286', name: 'Meiji U.' },
  { id: '216', name: 'Melbourne Business School, U. of Melbourne' },
  { id: '263', name: 'Memorial U. of Newfoundland' },
  { id: '264', name: 'Memphis State U.' },
  { id: '265', name: 'Mercantile School (Copenhagen)' },
  { id: '266', name: 'Mercantile Society School (Zurich)' },
  { id: '1389', name: 'Mercer U.' },
  { id: '1202', name: 'Merrimack College' },
  { id: '1571', name: 'Metropolitan State U.' },
  { id: '267', name: 'Miami U. (Ohio)' },
  { id: '268', name: 'Michigan State U.' },
  { id: '269', name: 'Michigan Technological U.' },
  { id: '1204', name: 'Middle East Technical U.' },
  { id: '1166', name: 'Middlebury College' },
  { id: '777', name: 'Middlesex Polytechnic' },
  { id: '1254', name: 'Middlesex U.' },
  { id: '1623', name: 'Midland Lutheran College' },
  { id: '1624', name: 'Midland U.' },
  { id: '270', name: 'Millersville U. of Pennsylvania' },
  { id: '1622', name: 'Millikin U.' },
  { id: '1317', name: 'Milwaukee School of Engineering' },
  { id: '1159', name: 'Minnesota State U.' },
  { id: '1549', name: 'Minot State U.' },
  { id: '1691', name: 'MiraCosta College' },
  { id: '271', name: 'Mississippi College' },
  { id: '272', name: 'Mississippi State U.' },
  { id: '909', name: 'Mississippi U. for Women' },
  { id: '365', name: 'Missouri School of Mines & Metallurgy' },
  { id: '1314', name: 'Missouri State U.' },
  { id: '273', name: 'Mohawk College' },
  { id: '1060', name: 'Monash U.' },
  { id: '1409', name: 'Mongolian Technical U.' },
  { id: '1431', name: 'Monmouth College' },
  { id: '274', name: 'Monmouth U.' },
  {
    id: '963',
    name: 'Montana College of Mineral Science and Technology',
  },
  { id: '275', name: 'Montana School of Mines' },
  { id: '276', name: 'Montana State U.' },
  { id: '1017', name: 'Montana Tech, The U. of Montana' },
  { id: '1811', name: 'Montana Technological U.' },
  { id: '1243', name: 'Montclair State U.' },
  { id: '983', name: 'Morehead State U.' },
  { id: '371', name: 'Morehouse College' },
  { id: '1704', name: 'Morrison U.' },
  { id: '1241', name: 'Moscow Engineering Physics Institute' },
  { id: '1182', name: 'Moscow Institute of Physics and Technology' },
  {
    id: '1278',
    name: 'Moscow State Institute of Radio-Engineering, Electronics and Automation',
  },
  { id: '1064', name: 'Moscow State Technological U. (Stankin)' },
  { id: '1139', name: 'Moscow State U.' },
  { id: '277', name: 'Mount Allison U.' },
  { id: '1687', name: 'Mount Holyoke College' },
  { id: '1115', name: 'Mount Morgan Technical College' },
  { id: '833', name: 'Mount Royal College' },
  { id: '46', name: 'Mount Royal U.' },
  { id: '278', name: "Mount Saint Mary's College" },
  { id: '1355', name: "Mount Saint Mary's U." },
  { id: '279', name: 'Mount Saint Vincent U.' },
  { id: '1188', name: 'Mount Sinai School of Medicine' },
  { id: '766', name: 'Mount Union College' },
  { id: '1146', name: 'Mt Eliza Business School' },
  { id: '747', name: 'Muhlenberg College' },
  { id: '1395', name: 'Murdoch U.' },
  { id: '1630', name: 'Murray State U.' },
  { id: '737', name: 'Nagoya U.' },
  { id: '1438', name: 'Nanjing U.' },
  { id: '1151', name: 'Nankai U.' },
  { id: '759', name: 'Nanyang Technological U. of Singapore' },
  { id: '968', name: 'Napier U.' },
  { id: '1229', name: 'Nasson College' },
  { id: '280', name: 'National and Kapodistrian U. of Athens' },
  { id: '1173', name: 'National Chiao Tung U.' },
  { id: '1523', name: 'National College of Business and Arts' },
  { id: '1403', name: 'National Taipei U. of Technology' },
  { id: '284', name: 'National Taiwan U.' },
  { id: '939', name: 'National Technical U. of Athens' },
  { id: '877', name: 'National Tsing Hua U.' },
  { id: '822', name: 'National U.' },
  { id: '1264', name: 'National U. of Ireland' },
  { id: '1408', name: 'National U. of Mongolia' },
  { id: '998', name: 'National U. of Singapore' },
  { id: '1718', name: 'Naval War College' },
  { id: '1308', name: 'Nelson Mandela Metropolitan U.' },
  { id: '1706', name: 'NEOMA Business School' },
  { id: '285', name: 'Netherlands School of Economics' },
  { id: '1149', name: 'New Jersey City U.' },
  { id: '1783', name: 'New Jersey Institute of Technology' },
  { id: '286', name: 'New Mexico Institute of Mining and Technology' },
  { id: '287', name: 'New Mexico State U.' },
  { id: '1596', name: 'New York Law School' },
  { id: '288', name: 'New York State Maritime College' },
  { id: '289', name: 'New York U.' },
  { id: '1663', name: 'Newman U.' },
  { id: '803', name: 'Newport U.' },
  { id: '797', name: 'Niagara College of Applied Arts & Technology' },
  { id: '291', name: 'Niagara U.' },
  { id: '1640', name: 'Nichols College' },
  { id: '1299', name: 'Nicolaus Copernicus U.' },
  { id: '294', name: 'Nipissing U.' },
  { id: '295', name: 'North American Institute of Technology' },
  { id: '1374', name: 'North Arizona U.' },
  { id: '296', name: 'North Carolina State U.' },
  { id: '1330', name: 'North Central College' },
  { id: '1033', name: 'North East London Polytechnic' },
  { id: '977', name: 'North-West U.' },
  { id: '1103', name: 'Northeast Normal U.' },
  { id: '1432', name: 'Northeastern Illinois U.' },
  {
    id: '886',
    name: 'Northeastern Ohio Universities College of Medicine',
  },
  { id: '297', name: 'Northeastern U.' },
  { id: '1552', name: 'Northeastern U.' },
  { id: '298', name: 'Northern Alberta Institute of Technology' },
  { id: '1369', name: 'Northern Arizona U.' },
  { id: '1358', name: 'Northern College of Applied Arts and Technology' },
  { id: '299', name: 'Northern Illinois U.' },
  { id: '300', name: 'Northern Michigan U.' },
  { id: '925', name: 'Northland College' },
  { id: '1556', name: 'Northwestern Industry U.' },
  { id: '302', name: 'Northwestern U.' },
  { id: '1650', name: 'Northwood U.' },
  { id: '303', name: 'Norwegian School of Economics and Business' },
  { id: '1095', name: 'Norwegian U. of Science and Technology' },
  { id: '304', name: 'Norwich U.' },
  { id: '305', name: 'Nottingham U.' },
  { id: '953', name: 'Nova Scotia Agricultural College' },
  { id: '1825', name: 'Nova Scotia College of Art and Desgin' },
  { id: '306', name: 'Nova Scotia Community College' },
  { id: '1158', name: 'Nova Scotia Institute of Technology' },
  { id: '307', name: 'Nova Scotia Technical College' },
  { id: '826', name: 'Nova Southeastern U.' },
  { id: '1460', name: 'Nueva Vizcaya State U.' },
  { id: '308', name: 'Oakland U.' },
  { id: '292', name: 'Oakwood U.' },
  { id: '863', name: 'Oberlin College' },
  { id: '1598', name: 'OCAD U.' },
  { id: '309', name: 'Occidental College' },
  { id: '1364', name: 'Odette School of Business, U. of Windsor' },
  { id: '310', name: 'Ohio Institute of Technology' },
  { id: '1548', name: 'Ohio Northern U.' },
  { id: '311', name: 'Ohio State U.' },
  { id: '312', name: 'Ohio U.' },
  { id: '844', name: 'Ohio Wesleyan U.' },
  { id: '1125', name: 'Okanagan College' },
  { id: '1164', name: 'Okanagan U. College' },
  { id: '1681', name: 'Oklahoma Christian U.' },
  { id: '1107', name: 'Oklahoma City U.' },
  { id: '313', name: 'Oklahoma State U.' },
  { id: '1725', name: 'Old Dominion U.' },
  { id: '773', name: 'Olds College' },
  { id: '314', name: 'Ontario Agricultural College' },
  { id: '315', name: 'Ontario College of Art' },
  { id: '792', name: 'Ontario College of Art & Design' },
  { id: '316', name: 'Ontario College of Education' },
  { id: '317', name: 'Ontario Institute for Studies in Education' },
  { id: '318', name: 'Ontario Veterinary College' },
  { id: '1626', name: 'Open U. of Hong Kong' },
  { id: '1829', name: 'Open U. of Israel' },
  { id: '809', name: 'Open U. of Sri Lanka' },
  { id: '319', name: 'Oregon State U.' },
  { id: '320', name: 'Osaka U.' },
  { id: '992', name: 'Osaka U. of Foreign Studies' },
  { id: '321', name: 'Osgoode Hall Law School' },
  { id: '1205', name: 'Osmania U.' },
  { id: '1063', name: 'Otago School of Mines' },
  { id: '322', name: 'Ottawa Teachers College' },
  { id: '907', name: 'Otterbein College' },
  { id: '209', name: 'Otterbein U.' },
  { id: '1231', name: 'Oxford Brookes U.' },
  { id: '323', name: 'Oxford Polytechnic' },
  { id: '324', name: 'Pace U.' },
  { id: '860', name: 'Pacific Lutheran U.' },
  { id: '1590', name: 'Pacific U.' },
  { id: '1244', name: 'Paisley College' },
  { id: '1294', name: 'Panjab U.' },
  { id: '1084', name: 'Paris Institute of Political Science' },
  {
    id: '1062',
    name: 'Paul H. Nitze School of Advanced International Studies, John Hopkins U.',
  },
  { id: '1097', name: 'Peking U.' },
  { id: '326', name: 'Pennsylvania State U.' },
  { id: '327', name: 'Pepperdine U.' },
  {
    id: '1637',
    name: 'Peter A. Allard School of Law, U. of British Columbia',
  },
  {
    id: '1535',
    name: 'Peter B. Gustavson School of Business, U. of Victoria',
  },
  { id: '328', name: 'Petit Séminaire de Québec' },
  { id: '1522', name: 'Pforzheim U. of Applied Sciences' },
  { id: '329', name: 'Philadelphia College of Textiles and Science' },
  { id: '1566', name: 'Philadelphia U.' },
  { id: '409', name: 'Philippine School of Business Administration' },
  { id: '975', name: 'Philipps-U. Marburg' },
  { id: '1150', name: 'Phoenix U.' },
  { id: '721', name: 'Pittsburg State U.' },
  { id: '1565', name: 'Plymouth U.' },
  { id: '1839', name: 'Politecnico di Milano' },
  { id: '827', name: 'Politecnico di Torino' },
  { id: '330', name: 'Polytechnic Institute of Brooklyn' },
  { id: '1018', name: 'Polytechnic of Central London' },
  { id: '1577', name: 'Polytechnic of East London' },
  { id: '331', name: 'Polytechnic U.' },
  { id: '1383', name: 'Polytechnic U. of the Philippines' },
  { id: '778', name: 'Pomona College' },
  { id: '332', name: 'Pontifical Institute of Mediaeval Studies' },
  { id: '730', name: 'Pontifical U. of Saint Thomas Aquinas' },
  { id: '1161', name: 'Pontificia U. Católica del Peru' },
  { id: '1720', name: 'Pontificia U. Catolica do Paraná' },
  { id: '1448', name: 'Pontificia U. Católica do Rio de Janeiro' },
  { id: '1001', name: 'Pontificia U. Gregoriana' },
  { id: '1345', name: 'Pontificio Istituto Biblico' },
  { id: '333', name: 'Portland State U.' },
  { id: '1245', name: 'Potchefstroom U.' },
  { id: '334', name: 'Potomac State College' },
  { id: '335', name: 'Prague U. of Economics' },
  { id: '1682', name: 'Pratt Institute' },
  { id: '1292', name: 'Presbyterian College' },
  { id: '336', name: 'Princeton U.' },
  { id: '1831', name: 'Principia College' },
  { id: '816', name: 'Providence College' },
  { id: '337', name: 'Punjabi U.' },
  { id: '338', name: 'Purdue U.' },
  { id: '1500', name: 'Qingdao U.' },
  { id: '1034', name: 'Queen Elizabeth College, U. of London' },
  { id: '1474', name: 'Queen Mary U. of London' },
  { id: '339', name: "Queen's U." },
  { id: '340', name: "Queen's U. of Belfast" },
  { id: '764', name: "Queens' College, U. of Cambridge" },
  { id: '950', name: 'Queensland U. of Technology' },
  { id: '1575', name: 'Quincy University' },
  { id: '341', name: 'Quinnipiac U.' },
  { id: '1479', name: 'Radboud U.' },
  { id: '1652', name: 'Rand Afrikaans U.' },
  { id: '1015', name: 'RAND Graduate Institute' },
  { id: '855', name: 'RCC Institute of Technology' },
  { id: '490', name: 'Reading College' },
  { id: '1302', name: 'Reading College of Arts and Technology' },
  { id: '993', name: 'Red Deer College' },
  { id: '1707', name: 'Red River College' },
  { id: '342', name: 'Red River Community College' },
  { id: '1253', name: 'Redeemer U. College' },
  { id: '1331', name: 'Reed College' },
  { id: '343', name: 'Regis College' },
  { id: '770', name: 'Regis U.' },
  { id: '1220', name: 'Reims Management School' },
  { id: '1384', name: 'Renmin U. of China' },
  { id: '344', name: 'Rensselaer Polytechnic Institute' },
  { id: '1258', name: 'Revans U.' },
  { id: '346', name: 'Rhodes U.' },
  { id: '347', name: 'Rice U.' },
  {
    id: '741',
    name: 'Richard Ivey School of Business, U. of Western Ontario',
  },
  { id: '796', name: 'Rider U.' },
  { id: '348', name: 'Ritsumeikan U.' },
  { id: '349', name: 'Rivier College' },
  { id: '1525', name: 'Rizal Technological U.' },
  { id: '1841', name: 'RMIT U.' },
  { id: '1262', name: 'Roanoke College' },
  { id: '350', name: 'Robert Gordon U.' },
  { id: '1561', name: 'Robert Morris U.' },
  { id: '1807', name: 'Roberts Wesleyan Colllege' },
  { id: '352', name: 'Rochester Institute of Technology' },
  { id: '353', name: 'Rockhurst College' },
  { id: '862', name: 'Rockhurst U.' },
  { id: '1557', name: 'Rocky Mountain College' },
  { id: '354', name: 'Rollins College' },
  { id: '355', name: 'Roosevelt U.' },
  { id: '1074', name: 'Rose-Hulman Institute of Technology' },
  { id: '969', name: 'Rotherham College of Arts & Technology' },
  { id: '1179', name: 'Rotman School of Management, U. of Toronto' },
  { id: '1568', name: 'Rovaniemi U. of Applied Sciences' },
  { id: '1505', name: 'Rowan U.' },
  { id: '1536', name: 'Rowe School of Business, Dalhousie U.' },
  { id: '356', name: 'Royal Canadian Naval College' },
  { id: '875', name: 'Royal College of Surgeons in Ireland' },
  { id: '357', name: 'Royal Institute of Chemistry' },
  { id: '358', name: 'Royal Institute of Technology' },
  { id: '1156', name: 'Royal Melbourne Institute of Technology' },
  { id: '359', name: 'Royal Military Academy' },
  { id: '361', name: 'Royal Military College of Canada' },
  { id: '363', name: 'Royal Roads Military College' },
  { id: '858', name: 'Royal Roads U.' },
  { id: '364', name: 'Royal School of Mines, U. of London' },
  { id: '1351', name: 'Royal Technical U. (Stockholm)' },
  { id: '366', name: 'Rugby College of Engineering Technology' },
  { id: '367', name: 'Rush U.' },
  { id: '368', name: 'Rutgers U.' },
  { id: '345', name: 'RWTH Aachen U.' },
  { id: '891', name: 'Ryerson Institute of Technology' },
  { id: '369', name: 'Ryerson Polytechnic U.' },
  { id: '370', name: 'Ryerson Polytechnical Institute' },
  { id: '719', name: 'Ryerson U.' },
  { id: '372', name: 'Sacred Heart U.' },
  { id: '1309', name: "Saint Dunstan's U." },
  { id: '1373', name: 'Saint Francis U.' },
  { id: '373', name: "Saint John's U. (Minnesota)" },
  { id: '374', name: "Saint Joseph's U." },
  { id: '375', name: 'Saint Louis U.' },
  { id: '376', name: "Saint Mary's U. (Halifax)" },
  { id: '1494', name: "Saint Mary's U. of Minnesota" },
  { id: '1785', name: "Saint Michael's College" },
  { id: '1191', name: 'Saint Paul U.' },
  { id: '1511', name: "Saint Peter's College" },
  { id: '1512', name: "Saint Peter's University" },
  { id: '402', name: 'Saint Petersburg State U.' },
  { id: '1632', name: 'Saints Cyril and Methodius U. of Skopje' },
  { id: '996', name: 'Salem State College' },
  { id: '377', name: 'Samford U.' },
  { id: '1007', name: 'San Diego State U.' },
  { id: '879', name: 'San Francisco State U.' },
  { id: '379', name: 'San José State U.' },
  {
    id: '1537',
    name: 'Sandermoen School of Business, U. of Fredericton',
  },
  { id: '380', name: 'Santa Clara U.' },
  { id: '1406', name: 'Sarah Lawrence College' },
  {
    id: '984',
    name: 'Saskatchewan Institute of Applied Arts & Technology',
  },
  {
    id: '1786',
    name: 'Saskatchewan Institute of Applied Science and Technology',
  },
  { id: '1787', name: 'Saskatchewan Polytechnic' },
  { id: '1251', name: 'Saskatchewan Technical Institute' },
  {
    id: '1538',
    name: 'Sauder School of Business, U. of British Columbia',
  },
  { id: '381', name: 'Sault College' },
  { id: '896', name: 'Schiller International U.' },
  { id: '1594', name: 'Schiller International U. (Heidelberg)' },
  {
    id: '841',
    name: 'School of Business and Economics, Wilfrid Laurier U.',
  },
  { id: '383', name: 'School of Economics' },
  { id: '743', name: 'Schulich School of Business, York U.' },
  { id: '1649', name: 'Schulich School of Law, Dalhousie U.' },
  { id: '724', name: 'Scripps College' },
  { id: '1812', name: 'Scuola Mattei' },
  { id: '1304', name: 'SDA Bocconi School of Management' },
  { id: '1212', name: 'Seattle Pacific U.' },
  { id: '384', name: 'Seattle U.' },
  { id: '385', name: 'Securities Institute of Australia' },
  { id: '1295', name: 'Selkirk College' },
  { id: '429', name: 'Séminaire de Saint-Hyacinthe' },
  { id: '430', name: 'Séminaire de Trois-Rivières' },
  { id: '819', name: 'Semmelweis U. of Medicine' },
  { id: '386', name: 'Seneca College' },
  { id: '387', name: 'Seoul National U.' },
  { id: '1005', name: 'Seton Hall U.' },
  { id: '1257', name: 'Shahid Beheshti U.' },
  { id: '1400', name: 'Shanghai Jiao Tong U.' },
  { id: '1583', name: 'Shanghai Normal U.' },
  { id: '1462', name: 'Shanghai U.' },
  { id: '1539', name: 'Shannon School of Business, Cape Breton U.' },
  { id: '1729', name: 'Shanxi U. of Finance and Economics' },
  { id: '1239', name: 'Sharif U. of Technology' },
  { id: '1334', name: 'Sheffield Hallam U.' },
  { id: '388', name: 'Sheffield U.' },
  { id: '389', name: 'Sheridan College' },
  { id: '390', name: 'Shiraz U.' },
  { id: '1832', name: 'Shri Ram College of Commerce' },
  { id: '883', name: 'Siena College' },
  { id: '1446', name: 'Simon Business School, U. of Rochester' },
  { id: '391', name: 'Simon Fraser U.' },
  { id: '392', name: 'Sir George Williams U.' },
  { id: '1066', name: 'Sir Sandford Fleming College' },
  { id: '1466', name: 'SIT Graduate Institute' },
  { id: '1167', name: 'Skidmore College' },
  { id: '1818', name: 'Slippery Rock U.' },
  { id: '393', name: 'Sloan School of Management., M.I.T.' },
  { id: '394', name: 'Slovak Technical U. in Bratislava' },
  { id: '395', name: 'Smith College' },
  { id: '1540', name: "Sobey School of Business, Saint Mary's U." },
  { id: '396', name: 'Sophia U.' },
  { id: '1813', name: 'Sorbonne U.' },
  { id: '1274', name: 'South Australian Institute of Technology' },
  { id: '398', name: 'South Dakota School of Mines and Technology' },
  { id: '399', name: 'South Dakota State U.' },
  { id: '1296', name: 'South Texas College of Law' },
  { id: '1284', name: 'Southeast Missouri State U.' },
  { id: '400', name: 'Southern Alberta Institute of Technology' },
  { id: '282', name: 'Southern Connecticut State U.' },
  { id: '1842', name: 'Southern Cross U.' },
  { id: '401', name: 'Southern Illinois U.' },
  { id: '403', name: 'Southern Methodist U.' },
  { id: '966', name: 'Southern Nazarene U.' },
  { id: '893', name: 'Southern New Hampshire U.' },
  { id: '1816', name: 'Southern Oregon U.' },
  { id: '1628', name: 'Southern Wesleyan U.' },
  { id: '301', name: 'Southwest Jiaotong U.' },
  { id: '404', name: 'Southwest Missouri State U.' },
  { id: '405', name: 'Southwest Texas State U.' },
  { id: '406', name: 'Southwestern Oklahoma State U.' },
  { id: '1307', name: 'Southwestern U.' },
  { id: '926', name: 'Spring Hill College' },
  { id: '407', name: 'Springfield College' },
  { id: '1481', name: 'Sprott School of Business, Carleton U.' },
  { id: '1806', name: 'St. Ambrose U.' },
  { id: '1137', name: 'St. Anselm College' },
  { id: '1420', name: 'St. Bonaventure U.' },
  { id: '410', name: 'St. Clair College' },
  { id: '1635', name: 'St. Cloud State U.' },
  { id: '991', name: 'St. Francis College' },
  { id: '411', name: 'St. Francis Xavier U.' },
  { id: '412', name: 'St. Gall Graduate School' },
  {
    id: '413',
    name: "St. Gallen's Graduate School of Economics, Law, Business Administration and Social Sciences",
  },
  { id: '415', name: "St. John's College, U. of Oxford" },
  { id: '931', name: "St. John's U. (New York)" },
  { id: '1416', name: "St. John's U. (Shanghai)" },
  { id: '881', name: 'St. Lawrence College' },
  { id: '416', name: 'St. Lawrence U.' },
  { id: '417', name: "St. Mary's College of California" },
  { id: '933', name: "St. Mary's U. (Calgary)" },
  { id: '1493', name: "St. Mary's U. (Texas)" },
  { id: '419', name: 'St. Neots Technical College' },
  { id: '1507', name: 'St. Norbert College' },
  { id: '1526', name: 'St. Olaf College' },
  { id: '420', name: 'St. Paul U.' },
  { id: '421', name: "St. Stephen's College" },
  { id: '422', name: 'St. Thomas U.' },
  { id: '1613', name: 'Staffordshire U.' },
  { id: '1026', name: 'Stanford Graduate School of Business' },
  { id: '423', name: 'Stanford U.' },
  { id: '1342', name: 'State Technical Institute at Memphis' },
  { id: '424', name: 'State U. of New York' },
  { id: '812', name: 'State U. of New York, Buffalo' },
  { id: '1629', name: 'State U. of New York, Geneseo' },
  { id: '1086', name: 'State U. of New York, Oneonta' },
  { id: '1135', name: 'State U. of New York, Oswego' },
  { id: '1044', name: 'Stavanger U.' },
  { id: '837', name: 'Stellenbosch U.' },
  { id: '965', name: 'Stephen F. Austin State U.' },
  {
    id: '1541',
    name: "Stephen J.R. Smith School of Business, Queen's U.",
  },
  { id: '1798', name: 'Stephens College' },
  { id: '1091', name: 'Stetson U.' },
  { id: '1444', name: 'Stevens Institute of Technology' },
  { id: '838', name: 'Stockholm School of Economics' },
  { id: '1043', name: 'Stockholm U.' },
  { id: '1673', name: 'Stockton U.' },
  { id: '426', name: 'Suffolk U.' },
  { id: '1555', name: 'Sun Yat-sen U.' },
  { id: '1363', name: 'Superior Technical Institute (Wisconsin)' },
  { id: '1116', name: 'Susquehanna U.' },
  { id: '702', name: 'Swansea U.' },
  { id: '1021', name: 'Swarthmore College' },
  { id: '1593', name: 'Swinburne U. of Technology' },
  { id: '427', name: 'Swiss Federal Institute of Technology' },
  { id: '1721', name: 'Sydenham College of Commerce and Economics' },
  { id: '428', name: 'Syracuse U.' },
  { id: '431', name: 'Tacoma U.' },
  { id: '432', name: 'Taipei Institute of Technology' },
  { id: '1290', name: 'Tamkang U.' },
  { id: '1281', name: 'Tampere U. of Technology' },
  { id: '1470', name: 'Technical U. of Berlin' },
  { id: '1154', name: 'Technical U. of Braunschweig' },
  { id: '434', name: 'Technical U. of Budapest' },
  { id: '798', name: 'Technical U. of Denmark' },
  { id: '1602', name: 'Technical U. of Dortmund' },
  { id: '845', name: 'Technical U. of Gdansk' },
  { id: '1134', name: 'Technical U. of Heavy Industry' },
  { id: '435', name: 'Technical U. of Mining and Metallurgy of Ostrava' },
  { id: '436', name: 'Technical U. of Nova Scotia' },
  { id: '843', name: 'Technikon Witwatersrand' },
  { id: '437', name: 'Technion - Israel Institute of Technology' },
  { id: '1606', name: 'Technische U. Chemnitz' },
  { id: '438', name: 'Technische U. München' },
  { id: '1140', name: 'Tecnologico de Monterrey' },
  { id: '1542', name: 'Ted Rogers School of Management, Ryerson U.' },
  { id: '439', name: 'Tel Aviv U.' },
  { id: '1600', name: 'Télécom ParisTech' },
  { id: '1543', name: 'Telfer School of Management, U. of Ottawa' },
  { id: '440', name: 'Temple U.' },
  { id: '441', name: 'Texas A & I U.' },
  { id: '442', name: 'Texas A & M U.' },
  { id: '443', name: 'Texas Christian U.' },
  { id: '378', name: 'Texas Southern U.' },
  { id: '1666', name: 'Texas State U.' },
  { id: '1665', name: 'Texas State U.-San Marcos' },
  { id: '444', name: 'Texas Technological U.' },
  { id: '1176', name: 'Thames Polytechnic' },
  { id: '790', name: 'Thayer School of Engineering, Dartmouth College' },
  { id: '1323', name: 'The Chinese U. of Hong Kong' },
  {
    id: '445',
    name: 'The Citadel, The Military Collge of South Carolina',
  },
  { id: '974', name: 'The City College of New York' },
  { id: '1329', name: 'The College of Management Academic Studies' },
  { id: '1354', name: 'The College of New Jersey' },
  { id: '90', name: 'The College of the Holy Cross' },
  { id: '1019', name: 'The College of Wooster' },
  { id: '857', name: 'The Hague Academy of International Law' },
  { id: '944', name: 'The Hong Kong U. of Science and Technology' },
  { id: '772', name: 'The John Marshall Law School' },
  { id: '1250', name: 'The Niagara Institute' },
  { id: '793', name: 'The Open U.' },
  { id: '1032', name: 'The Rotterdam Institute for Technology' },
  { id: '1455', name: 'Thompson Rivers U.' },
  {
    id: '446',
    name: 'Thunderbird Graduate School of International Management',
  },
  { id: '11', name: 'Thunderbird School of Global Management' },
  { id: '1570', name: 'Tianjin U.' },
  { id: '447', name: 'Tilburg U.' },
  { id: '1488', name: 'Tohoku U.' },
  { id: '1259', name: 'Tokyo Electric U.' },
  { id: '448', name: 'Tokyo U. of Agriculture and Technology' },
  { id: '449', name: 'Tokyo U. of Foreign Studies' },
  { id: '882', name: 'Tongji Medical U.' },
  { id: '360', name: 'Tongji U.' },
  { id: '450', name: "Toronto Teachers' College" },
  { id: '1544', name: 'Touro College' },
  { id: '451', name: 'Towson State U.' },
  { id: '1211', name: 'Toyama Medical & Pharmaceutical U.' },
  { id: '414', name: 'Transylvania U.' },
  { id: '452', name: 'Trent U.' },
  { id: '457', name: 'Tri-State U.' },
  { id: '453', name: 'Trinity College (Dublin)' },
  { id: '454', name: 'Trinity College (Hartford)' },
  { id: '455', name: 'Trinity College London' },
  { id: '982', name: 'Trinity U.' },
  { id: '456', name: 'Trinity Western U.' },
  { id: '1207', name: 'Truman State U.' },
  { id: '1213', name: 'Tsinghua U.' },
  { id: '458', name: 'Tufts U.' },
  { id: '459', name: 'Tulane U. of Louisiana' },
  { id: '460', name: 'U. Basel' },
  { id: '758', name: 'U. Bern' },
  { id: '915', name: 'U. Bonn' },
  { id: '1795', name: 'U. Candido Mendes' },
  { id: '461', name: 'U. Catholique de Louvain' },
  { id: '502', name: 'U. Católica Andrés Bello' },
  { id: '1789', name: 'U. Católica Argentina' },
  { id: '1580', name: 'U. Católica del Norte' },
  { id: '1059', name: 'U. Central de Venezuela' },
  { id: '780', name: 'U. Claude Bernard Lyon 1' },
  { id: '1437', name: 'U. College Cork' },
  { id: '673', name: 'U. College Dublin' },
  { id: '889', name: 'U. College London' },
  { id: '1175', name: 'U. College, Cardiff' },
  { id: '726', name: 'U. Complutense de Madrid' },
  { id: '1347', name: "U. d'Aix en Provence" },
  { id: '465', name: "U. d'Aix-Marseille" },
  { id: '738', name: "U. d'Orléans" },
  { id: '466', name: 'U. de Barcelona' },
  { id: '1684', name: 'U. de Belgrano' },
  { id: '467', name: 'U. de Bordeaux' },
  { id: '1350', name: 'U. de Buenos Aires' },
  { id: '1393', name: 'U. de Buenos Aires' },
  { id: '1450', name: 'U. de Cantabria' },
  { id: '84', name: 'U. de Coimbra' },
  { id: '1230', name: 'U. de Concepcion' },
  { id: '468', name: 'U. de Fribourg' },
  { id: '1024', name: 'U. de Genève' },
  { id: '469', name: 'U. de Grenoble' },
  { id: '1047', name: 'U. de Guadalajara' },
  { id: '1035', name: 'U. de Guanajuato' },
  { id: '1421', name: 'U. de Hermosillo' },
  { id: '1581', name: 'U. de la República' },
  { id: '470', name: 'U. de Lausanne' },
  { id: '472', name: 'U. de Liège' },
  { id: '471', name: 'U. de Lille' },
  { id: '1141', name: 'U. de Lima' },
  { id: '1508', name: 'U. de Lisboa' },
  { id: '1425', name: 'U. de los Andes' },
  { id: '934', name: 'U. de Montpellier lll (U. Paul-Valéry)' },
  { id: '475', name: 'U. de Montréal' },
  { id: '476', name: 'U. de Nancy' },
  { id: '477', name: 'U. de Nice' },
  { id: '478', name: 'U. de Paris' },
  { id: '1814', name: 'U. de Paris' },
  { id: '1434', name: 'U. de Paris I (Panthéon-Sorbonne)' },
  { id: '433', name: 'U. de Paris II (Panthéon-Assas)' },
  { id: '479', name: 'U. de Paris III (Sorbonne Nouvelle)' },
  { id: '728', name: 'U. de Paris IV (Paris-Sorbonne)' },
  { id: '480', name: 'U. de Paris IX (Paris Dauphine)' },
  { id: '853', name: 'U. de Paris V (René Descartes)' },
  { id: '170', name: 'U. de Paris VI (Pierre-et-Marie-Curie)' },
  { id: '775', name: 'U. de Paris VII (Denis Diderot)' },
  { id: '1335', name: 'U. de Paris VIII (Vincennes-Saint-Denis)' },
  { id: '473', name: 'U. de Paris X (Ouest Nanterre la Défense)' },
  { id: '655', name: 'U. de Paris XI (Paris-Sud)' },
  { id: '674', name: 'U. de Paris XII (Paris-Est Créteil Val-de-Marne)' },
  { id: '866', name: 'U. de Paris XIII' },
  { id: '1826', name: 'U. de Piura' },
  { id: '1085', name: 'U. de Puerto Rico' },
  { id: '1348', name: 'U. de Rennes' },
  { id: '765', name: 'U. de Rouen' },
  { id: '1484', name: 'U. de Saint-Boniface' },
  { id: '1468', name: 'U. de Salamanca' },
  { id: '1502', name: 'U. de San Carlos de Guatemala' },
  { id: '1708', name: 'U. de Santiago de Chile' },
  { id: '978', name: 'U. de São Paulo' },
  { id: '397', name: 'U. de Sevilla' },
  { id: '481', name: 'U. de Sherbrooke' },
  { id: '1422', name: 'U. de Sonora' },
  { id: '482', name: 'U. de Strasbourg' },
  { id: '1221', name: 'U. de Technologie de Compiègne' },
  { id: '1132', name: 'U. de Tunis' },
  { id: '484', name: 'U. degli Studi di Milano' },
  { id: '1788', name: 'U. del CEMA' },
  { id: '1426', name: 'U. del Norte' },
  { id: '824', name: 'U. del Pacifico' },
  { id: '290', name: 'U. del Rosario' },
  { id: '1733', name: 'U. del Valle' },
  { id: '1824', name: 'U. del Valle de Mexico' },
  { id: '1012', name: 'U. della Calabria' },
  { id: '1697', name: 'U. des Saarlandes' },
  { id: '1794', name: 'U. do Estado do Rio de Janeiro' },
  { id: '485', name: 'U. du Québec' },
  { id: '486', name: 'U. du Québec à Chicoutimi' },
  { id: '487', name: 'U. du Québec à Hull' },
  { id: '488', name: 'U. du Québec à Montréal' },
  { id: '489', name: 'U. du Québec à Rimouski' },
  { id: '491', name: 'U. du Québec à Trois-Rivières' },
  { id: '859', name: 'U. du Québec en Abitibi-Témiscamingue' },
  { id: '980', name: 'U. du Québec en Outaouais' },
  { id: '1497', name: 'U. ESAN' },
  { id: '492', name: 'U. Essen' },
  { id: '1368', name: 'U. Federal da Bahia' },
  { id: '104', name: 'U. Federal de Minas Gerais' },
  { id: '1319', name: 'U. Federal de Ouro Preto' },
  { id: '1823', name: 'U. Federal de Pernambuco' },
  { id: '936', name: 'U. Federal de Viçosa' },
  { id: '1367', name: 'U. Federal do Rio Grande do Sul' },
  { id: '776', name: 'U. Federal Fluminense' },
  { id: '1353', name: 'U. Francisco Marroquin' },
  { id: '1050', name: 'U. François Rabelais' },
  { id: '1643', name: 'U. Gabriela Mistral' },
  { id: '937', name: 'U. Iberoamericana Ciudad de México' },
  { id: '1359', name: 'U. Javeriana' },
  { id: '914', name: 'U. Konstanz' },
  { id: '1129', name: 'U. La Salle' },
  { id: '493', name: 'U. Laval' },
  { id: '1717', name: 'U. libre de Bruxelles' },
  { id: '494', name: 'U. Lumière' },
  { id: '495', name: 'U. Lyon' },
  { id: '116', name: 'U. Metropolitana' },
  { id: '1612', name: 'U. Metropolitana' },
  { id: '900', name: 'U. Montpellier' },
  { id: '1252', name: 'U. Nacional Autonoma de Mexico' },
  { id: '1325', name: 'U. Nacional de Colombia' },
  { id: '1599', name: 'U. Nacional de Córdoba' },
  { id: '1072', name: 'U. Nacional de Ingenieria' },
  { id: '1604', name: 'U. Nacional de Salta' },
  { id: '180', name: 'U. Nacional de San Antonio Abad del Cusco' },
  { id: '1120', name: 'U. Nacional del Sur' },
  { id: '1514', name: 'U. Nacional Federico Villarreal' },
  { id: '1327', name: 'U. Nacional Mayor de San Marcos' },
  { id: '1119', name: 'U. Nova de Lisboa' },
  { id: '1029', name: 'U. of Aachen' },
  { id: '496', name: 'U. of Aberdeen' },
  { id: '497', name: 'U. of Adelaide' },
  { id: '498', name: 'U. of Akron' },
  { id: '794', name: 'U. of Alabama' },
  { id: '1712', name: 'U. of Alaska Anchorage' },
  { id: '1054', name: 'U. of Alaska Fairbanks' },
  { id: '499', name: 'U. of Alberta' },
  { id: '1453', name: 'U. of Amsterdam' },
  { id: '1293', name: 'U. of Antwerp' },
  { id: '1313', name: 'U. of Applied Science, Esslingen' },
  { id: '500', name: 'U. of Arizona' },
  { id: '501', name: 'U. of Arkansas' },
  { id: '503', name: 'U. of Auckland' },
  { id: '1224', name: 'U. of Ballarat' },
  { id: '504', name: 'U. of Baltimore' },
  { id: '505', name: 'U. of Bath' },
  { id: '1672', name: 'U. of Bedfordshire' },
  { id: '899', name: 'U. of Belgrade' },
  { id: '506', name: 'U. of Birmingham' },
  { id: '507', name: 'U. of Boston' },
  { id: '508', name: 'U. of Bradford' },
  { id: '509', name: 'U. of Bridgeport' },
  { id: '510', name: 'U. of Brighton' },
  { id: '511', name: 'U. of Bristol' },
  { id: '512', name: 'U. of British Columbia' },
  { id: '1837', name: 'U. of Buckingham' },
  { id: '513', name: 'U. of Calcutta' },
  { id: '514', name: 'U. of Calgary' },
  { id: '515', name: 'U. of California' },
  { id: '516', name: 'U. of California, Berkeley' },
  { id: '884', name: 'U. of California, Davis' },
  { id: '1644', name: 'U. of California, Hastings College of Law' },
  { id: '1465', name: 'U. of California, Irvine' },
  { id: '519', name: 'U. of California, Los Angeles' },
  { id: '1430', name: 'U. of California, Riverside' },
  { id: '518', name: 'U. of California, San Diego' },
  { id: '1617', name: 'U. of California, San Francisco' },
  { id: '878', name: 'U. of California, Santa Barbara' },
  { id: '1559', name: 'U. of California, Santa Cruz' },
  { id: '521', name: 'U. of Cambridge' },
  { id: '1683', name: 'U. of Canberra' },
  { id: '1268', name: 'U. of Canterbury' },
  { id: '522', name: 'U. of Canterbury' },
  { id: '1083', name: 'U. of Cape Coast' },
  { id: '524', name: 'U. of Cape Town' },
  { id: '1737', name: 'U. of Carabobo' },
  { id: '525', name: 'U. of Central Florida' },
  { id: '1699', name: 'U. of Central Lancashire' },
  { id: '1100', name: 'U. of Central Missouri' },
  { id: '526', name: 'U. of Central Oklahoma' },
  { id: '1157', name: 'U. of Ceylon' },
  { id: '527', name: 'U. of Chicago' },
  { id: '528', name: 'U. of Chile' },
  { id: '529', name: 'U. of Cincinnati' },
  { id: '1676', name: 'U. of Cologne' },
  { id: '723', name: 'U. of Colombo' },
  { id: '530', name: 'U. of Colorado' },
  { id: '1144', name: 'U. of Colorado Boulder' },
  { id: '531', name: 'U. of Connecticut' },
  { id: '532', name: 'U. of Copenhagen' },
  { id: '533', name: 'U. of Dallas' },
  { id: '534', name: 'U. of Dayton' },
  { id: '535', name: 'U. of Delaware' },
  { id: '536', name: 'U. of Delhi' },
  { id: '537', name: 'U. of Denver' },
  { id: '538', name: 'U. of Detroit' },
  { id: '1053', name: 'U. of Detroit Mercy' },
  { id: '539', name: 'U. of Dundee' },
  { id: '540', name: 'U. of Durham' },
  { id: '541', name: 'U. of East Africa' },
  { id: '946', name: 'U. of East Anglia' },
  { id: '1578', name: 'U. of East London' },
  { id: '542', name: 'U. of Edinburgh' },
  {
    id: '1379',
    name: 'U. of Electronic Science and Technology of China',
  },
  { id: '1411', name: 'U. of Engineering & Technology' },
  { id: '1051', name: 'U. of Erlangen' },
  { id: '1215', name: 'U. of Essex' },
  { id: '976', name: 'U. of Evansville' },
  { id: '543', name: 'U. of Exeter' },
  { id: '1428', name: 'U. of Findlay' },
  { id: '825', name: 'U. of Florida' },
  { id: '800', name: 'U. of Florida Law School' },
  { id: '544', name: 'U. of Frankfurt' },
  { id: '1675', name: 'U. of Fredericton' },
  { id: '1513', name: 'U. of Gdansk' },
  { id: '546', name: 'U. of Geneva' },
  { id: '547', name: 'U. of Genoa' },
  { id: '548', name: 'U. of Georgia' },
  { id: '462', name: 'U. of Ghana' },
  { id: '549', name: 'U. of Glasgow' },
  { id: '1668', name: 'U. of Gloucestershire' },
  { id: '1436', name: 'U. of Gothenburg' },
  { id: '1686', name: 'U. of Greenwich' },
  { id: '1669', name: 'U. of Groningen' },
  { id: '550', name: 'U. of Guelph' },
  { id: '791', name: 'U. of Guyana' },
  { id: '910', name: 'U. of Haifa' },
  { id: '1394', name: 'U. of Hamburg' },
  { id: '752', name: 'U. of Hartford' },
  { id: '551', name: 'U. of Havana' },
  { id: '552', name: 'U. of Hawaii' },
  { id: '806', name: 'U. of Hertfordshire' },
  { id: '951', name: 'U. of Hohenheim' },
  { id: '553', name: 'U. of Hong Kong' },
  { id: '554', name: 'U. of Houston' },
  { id: '555', name: 'U. of Hull' },
  { id: '556', name: 'U. of Idaho' },
  { id: '557', name: 'U. of Illinois' },
  { id: '1579', name: 'U. of Indianapolis' },
  { id: '1442', name: 'U. of International Business & Economics' },
  { id: '558', name: 'U. of Iowa' },
  { id: '1260', name: 'U. of Johannesburg' },
  { id: '1680', name: 'U. of Jordan' },
  { id: '559', name: 'U. of Kansas' },
  { id: '560', name: 'U. of Karachi' },
  { id: '561', name: 'U. of Karlsruhe' },
  { id: '562', name: 'U. of Keele' },
  { id: '563', name: 'U. of Keio' },
  { id: '564', name: 'U. of Kent at Canterbury' },
  { id: '565', name: 'U. of Kentucky' },
  { id: '1219', name: 'U. of Kerala' },
  { id: '1714', name: 'U. of Kiel' },
  { id: '566', name: "U. of King's College" },
  { id: '1238', name: 'U. of Konstanz' },
  { id: '1209', name: 'U. of KwaZulu-Natal' },
  { id: '567', name: 'U. of Lancaster' },
  { id: '1326', name: 'U. of Lausanne' },
  { id: '568', name: 'U. of Leeds' },
  { id: '569', name: 'U. of Leicester' },
  { id: '928', name: 'U. of Leoben' },
  { id: '570', name: 'U. of Lethbridge' },
  { id: '1398', name: 'U. of Liège' },
  { id: '1510', name: 'U. of Limerick' },
  { id: '1808', name: 'U. of Linköping' },
  { id: '571', name: 'U. of Liverpool' },
  { id: '572', name: 'U. of London' },
  { id: '1081', name: 'U. of London Institute of Cancer Research' },
  { id: '1662', name: 'U. of Louisana, Lafayette' },
  { id: '1102', name: 'U. of Louisiana' },
  { id: '1661', name: 'U. of Louisiana, Monroe' },
  { id: '573', name: 'U. of Louisville' },
  { id: '574', name: 'U. of Madras' },
  { id: '575', name: 'U. of Maine' },
  { id: '576', name: 'U. of Malta' },
  { id: '577', name: 'U. of Manchester' },
  { id: '578', name: 'U. of Manitoba' },
  { id: '1110', name: 'U. of Mannheim' },
  { id: '579', name: 'U. of Maryland' },
  { id: '1101', name: 'U. of Maryland U. College, Europe' },
  { id: '580', name: 'U. of Massachusetts' },
  { id: '1469', name: 'U. of Massachusetts at Amherst' },
  { id: '802', name: 'U. of Massachusetts at Lowell' },
  { id: '581', name: 'U. of Melbourne' },
  { id: '1456', name: 'U. of Memphis' },
  { id: '582', name: 'U. of Miami' },
  { id: '583', name: 'U. of Michigan' },
  { id: '1441', name: 'U. of Milano-Bicocca' },
  { id: '1152', name: 'U. of Mining and Geology "St. Ivan Rils"' },
  { id: '584', name: 'U. of Minnesota' },
  { id: '585', name: 'U. of Mississippi' },
  { id: '586', name: 'U. of Missouri' },
  { id: '1833', name: 'U. of Missouri-Columbia' },
  { id: '1004', name: 'U. of Missouri-Kansas City' },
  { id: '990', name: 'U. of Missouri-Rolla' },
  { id: '1560', name: 'U. of Missouri-St. Louis' },
  { id: '474', name: 'U. of Moncton' },
  { id: '588', name: 'U. of Montana' },
  { id: '205', name: 'U. of Mount Union' },
  { id: '720', name: 'U. of Mumbai' },
  { id: '1836', name: 'U. of Nairobi' },
  { id: '589', name: 'U. of Natal' },
  { id: '1261', name: 'U. of Nebraska' },
  { id: '1576', name: 'U. of Nebraska at Kearney' },
  { id: '1633', name: 'U. of Nebraska Medical Center' },
  { id: '997', name: 'U. of Nebraska-Lincoln' },
  { id: '590', name: 'U. of Nevada' },
  { id: '1631', name: 'U. of Nevada, Reno' },
  { id: '591', name: 'U. of New Brunswick' },
  { id: '592', name: 'U. of New England' },
  { id: '593', name: 'U. of New Hampshire' },
  { id: '1163', name: 'U. of New Hampshire School of Law' },
  { id: '594', name: 'U. of New Haven' },
  { id: '595', name: 'U. of New Mexico' },
  { id: '1108', name: 'U. of New Orleans' },
  { id: '596', name: 'U. of New South Wales' },
  { id: '597', name: 'U. of New Zealand' },
  { id: '1711', name: 'U. of Newcastle' },
  { id: '598', name: 'U. of Newcastle upon Tyne' },
  { id: '599', name: 'U. of North Carolina' },
  { id: '744', name: 'U. of North Carolina, Chapel Hill' },
  { id: '1654', name: 'U. of North Carolina, Wilmington' },
  { id: '600', name: 'U. of North Dakota' },
  { id: '894', name: 'U. of North Florida' },
  { id: '942', name: 'U. of North Texas' },
  { id: '1170', name: 'U. of Northern British Columbia' },
  { id: '1731', name: 'U. of Northern Colorado' },
  { id: '1618', name: 'U. of Northern Iowa' },
  { id: '1592', name: 'U. of Northumbria at Newcastle' },
  { id: '601', name: 'U. of Notre Dame' },
  { id: '602', name: 'U. of Nottingham' },
  { id: '603', name: 'U. of Oklahoma' },
  { id: '1642', name: 'U. of Ontario Institute of Technology' },
  { id: '604', name: 'U. of Oregon' },
  { id: '605', name: 'U. of Oslo' },
  { id: '1073', name: 'U. of Otago' },
  { id: '606', name: 'U. of Ottawa' },
  { id: '607', name: 'U. of Oxford' },
  { id: '1089', name: 'U. of Oxford, Rhodes Scholar' },
  { id: '1030', name: 'U. of Padua' },
  { id: '872', name: 'U. of Paisley' },
  { id: '669', name: 'U. of Papua New Guinea' },
  { id: '608', name: 'U. of Pennsylvania' },
  { id: '1315', name: 'U. of Pennsylvania Law School' },
  { id: '1337', name: 'U. of Philadelphia' },
  { id: '1112', name: 'U. of Phoenix' },
  { id: '609', name: 'U. of Pittsburgh' },
  { id: '1328', name: 'U. of Poona' },
  { id: '1177', name: 'U. of Portland' },
  { id: '1169', name: 'U. of Portsmouth' },
  { id: '610', name: 'U. of Prague' },
  { id: '611', name: 'U. of Pretoria' },
  { id: '612', name: 'U. of Prince Edward Island' },
  { id: '1237', name: 'U. of Pune' },
  { id: '613', name: 'U. of Queensland' },
  { id: '771', name: 'U. of Rajasthan' },
  { id: '614', name: 'U. of Reading' },
  { id: '615', name: 'U. of Redlands' },
  { id: '1387', name: 'U. of Regensburg' },
  { id: '616', name: 'U. of Regina' },
  { id: '617', name: 'U. of Rhode Island' },
  { id: '727', name: 'U. of Rhodesia' },
  { id: '1273', name: 'U. of Richmond' },
  { id: '618', name: 'U. of Rochester' },
  { id: '619', name: 'U. of Salford' },
  { id: '620', name: 'U. of Salzburg' },
  { id: '1003', name: 'U. of San Diego' },
  { id: '1002', name: 'U. of San Francisco' },
  { id: '621', name: 'U. of Santa Clara' },
  { id: '622', name: 'U. of Santo Tomas' },
  { id: '623', name: 'U. of Saskatchewan' },
  { id: '198', name: 'U. of Science & Technology Beijing' },
  { id: '624', name: 'U. of Scranton' },
  { id: '625', name: 'U. of Sheffield' },
  { id: '626', name: 'U. of South Africa' },
  { id: '1023', name: 'U. of South Australia' },
  { id: '627', name: 'U. of South Carolina' },
  { id: '989', name: 'U. of South Dakota' },
  { id: '847', name: 'U. of South Florida' },
  { id: '628', name: 'U. of Southampton' },
  { id: '629', name: 'U. of Southern California' },
  { id: '630', name: 'U. of Southern Mississippi' },
  { id: '418', name: 'U. of Southern Queensland' },
  { id: '631', name: 'U. of Southwestern Louisiana' },
  { id: '362', name: 'U. of Sri Lanka' },
  { id: '632', name: 'U. of St. Andrews' },
  { id: '957', name: 'U. of St. Gallen' },
  { id: '861', name: 'U. of St. Thomas' },
  { id: '729', name: 'U. of Stirling' },
  { id: '633', name: 'U. of Strathclyde' },
  { id: '760', name: 'U. of Surrey' },
  { id: '634', name: 'U. of Sussex' },
  { id: '1713', name: 'U. of Swaziland' },
  { id: '635', name: 'U. of Sydney' },
  { id: '1104', name: 'U. of Tampa' },
  { id: '636', name: 'U. of Tasmania' },
  { id: '1386', name: 'U. of Technology, Sydney' },
  { id: '637', name: 'U. of Tehran' },
  { id: '638', name: 'U. of Tennessee' },
  { id: '639', name: 'U. of Texas' },
  { id: '1690', name: 'U. of Texas at Arlington' },
  { id: '640', name: 'U. of Texas at Austin' },
  { id: '1142', name: 'U. of Texas at Dallas' },
  { id: '1423', name: 'U. of Texas at El Paso' },
  { id: '1228', name: 'U. of the District of Columbia' },
  { id: '641', name: 'U. of the East' },
  { id: '1621', name: 'U. of the Fraser Valley' },
  { id: '1614', name: 'U. of the Free State' },
  { id: '1546', name: 'U. of the Pacific' },
  { id: '642', name: 'U. of the Philippines' },
  { id: '1609', name: 'U. of the Punjab' },
  { id: '1078', name: 'U. of the Sciences, Philadelphia' },
  { id: '643', name: 'U. of the West Indies' },
  { id: '1006', name: 'U. of the West of England' },
  { id: '283', name: 'U. of the West of Scotland' },
  { id: '644', name: 'U. of the Witwatersrand' },
  { id: '520', name: 'U. of Tirana' },
  { id: '645', name: 'U. of Tokyo' },
  { id: '646', name: 'U. of Toledo' },
  { id: '647', name: 'U. of Toronto' },
  { id: '648', name: 'U. of Tulsa' },
  { id: '1360', name: 'U. of Turin' },
  { id: '153', name: 'U. of Twente' },
  { id: '880', name: 'U. of Ulm' },
  { id: '1310', name: 'U. of Ulster' },
  { id: '649', name: 'U. of Umea' },
  { id: '650', name: 'U. of Utah' },
  { id: '651', name: 'U. of Vermont' },
  { id: '652', name: 'U. of Victoria' },
  { id: '1061', name: 'U. of Vienna' },
  { id: '653', name: 'U. of Virginia' },
  { id: '654', name: 'U. of Wales' },
  { id: '1000', name: 'U. of Wales, Bangor' },
  { id: '919', name: 'U. of Wales, Swansea' },
  { id: '905', name: 'U. of Warsaw' },
  { id: '656', name: 'U. of Warwick' },
  { id: '657', name: 'U. of Washington' },
  { id: '658', name: 'U. of Waterloo' },
  { id: '1068', name: 'U. of West Florida' },
  { id: '1298', name: 'U. of West Los Angeles' },
  { id: '659', name: 'U. of West Virginia' },
  { id: '756', name: 'U. of Western Australia' },
  { id: '660', name: 'U. of Western Ontario' },
  { id: '1454', name: 'U. of Westminster' },
  { id: '661', name: 'U. of Windsor' },
  { id: '662', name: 'U. of Winnipeg' },
  { id: '663', name: 'U. of Wisconsin' },
  { id: '1564', name: 'U. of Wisconsin-La Crosse' },
  { id: '664', name: 'U. of Wisconsin-Madison' },
  { id: '1410', name: 'U. of Wisconsin-Milwaukee' },
  { id: '1658', name: 'U. of Wisconsin-Superior' },
  { id: '1641', name: 'U. of Wisconsin-Whitewater' },
  { id: '1404', name: 'U. of Wollongong' },
  { id: '1415', name: 'U. of Wolverhampton' },
  { id: '666', name: 'U. of Würzburg' },
  { id: '665', name: 'U. of Wyoming' },
  { id: '1009', name: 'U. of Yonsei' },
  { id: '717', name: 'U. of York' },
  { id: '667', name: 'U. of Zagreb' },
  { id: '158', name: 'U. of Zimbabwe' },
  { id: '1058', name: 'U. of Zurich' },
  { id: '1234', name: 'U. Politécnica de Madrid' },
  { id: '1671', name: 'U. Politècnica de València' },
  { id: '1148', name: 'U. Politehnica of Bucharest' },
  { id: '1429', name: 'U. Pontificia Bolivariana' },
  { id: '1184', name: 'U. Pontificia Comillas' },
  { id: '1655', name: 'U. Presbiteriana Mackenzie' },
  { id: '890', name: 'U. Sains Malaysia' },
  { id: '1333', name: 'U. Saint-Louis' },
  { id: '668', name: 'U. Sainte-Anne' },
  { id: '1498', name: 'U. Stuttgart' },
  { id: '1664', name: 'U. Técnica Federico Santa Maria' },
  { id: '672', name: 'U. Tecnológica Nacional' },
  { id: '1501', name: 'U. Teknologi Malaysia' },
  { id: '1496', name: 'U. Toulouse - Jean Jaurès' },
  { id: '483', name: 'U. Toulouse I (Capitole)' },
  { id: '1495', name: 'U. Toulouse II (Le Mirail)' },
  { id: '754', name: 'U. Toulouse III (Paul Sabatier)' },
  { id: '1480', name: 'U. van Amsterdam' },
  { id: '1216', name: 'U. Veracruzana' },
  { id: '935', name: 'U.S. Air Force Academy' },
  { id: '676', name: 'U.S. Brewers Academy' },
  { id: '677', name: 'U.S. Military Academy' },
  { id: '678', name: 'U.S. Naval Academy' },
  { id: '670', name: 'U.S. Sports Academy' },
  { id: '1075', name: 'Uniformed Services U. of the Health Sciences' },
  { id: '1109', name: 'Union College' },
  { id: '1732', name: 'United Arab Emirates U.' },
  { id: '1730', name: 'United States Army War College' },
  { id: '1136', name: 'Uppsala U.' },
  { id: '675', name: 'Upsala College' },
  { id: '1265', name: 'Urrbrae College' },
  { id: '679', name: 'Utah State U.' },
  { id: '823', name: 'Utrecht U.' },
  { id: '1171', name: 'Valparaiso U. School of Law' },
  { id: '682', name: "Van't Hoff Institute" },
  { id: '1516', name: 'Vancouver City College' },
  { id: '680', name: 'Vancouver Community College' },
  { id: '1805', name: 'Vancouver Island U.' },
  { id: '1517', name: 'Vancouver Vocational Institute' },
  { id: '681', name: 'Vanderbilt U.' },
  { id: '817', name: 'Vassar College' },
  { id: '985', name: 'Victoria U.' },
  { id: '684', name: 'Victoria U. of Wellington' },
  { id: '1218', name: 'Vienna U. of Economics and Business' },
  { id: '1464', name: 'Vienna U. of Technology' },
  { id: '1042', name: 'Vikram U.' },
  { id: '685', name: 'Villanova U.' },
  { id: '686', name: 'Virginia Military Institute' },
  { id: '687', name: 'Virginia Polytechnic Institute and State U.' },
  { id: '155', name: 'Vrije U. Brussels' },
  { id: '154', name: 'VU U. Amsterdam' },
  { id: '688', name: 'Wabash College' },
  { id: '1316', name: 'Wageningen U.' },
  { id: '938', name: 'Wake Forest U.' },
  {
    id: '1208',
    name: 'Walsh College of Accountancy and Business Administration',
  },
  {
    id: '1445',
    name: 'Walter A. Hass School of Business, U. of California, Berkeley',
  },
  { id: '948', name: 'Warsaw School of Economics' },
  { id: '689', name: 'Warsaw U. of Technology' },
  { id: '1111', name: 'Warwick Business School, U. of Warwick' },
  { id: '690', name: 'Waseda U.' },
  { id: '840', name: 'Washburn U.' },
  { id: '691', name: 'Washington and Jefferson College' },
  { id: '981', name: 'Washington and Lee U.' },
  { id: '692', name: 'Washington College' },
  { id: '693', name: 'Washington State U.' },
  { id: '694', name: 'Washington U. (Missouri)' },
  { id: '695', name: 'Waterloo Lutheran U.' },
  { id: '839', name: 'Watford College of Technology' },
  { id: '696', name: 'Wayne State U.' },
  { id: '1467', name: 'Weber State U.' },
  { id: '697', name: 'Webster U.' },
  { id: '1651', name: 'Webster U. Leiden' },
  { id: '698', name: 'Wellesley College' },
  { id: '1155', name: 'Wentworth Institute of Technology' },
  { id: '1045', name: 'Wesleyan U.' },
  { id: '821', name: 'West Chester U.' },
  { id: '144', name: 'West Herts College' },
  { id: '888', name: 'West Liberty State College' },
  { id: '699', name: 'West Virginia U.' },
  { id: '784', name: 'Western Australian Institute of Technology' },
  { id: '1069', name: 'Western Australian School of Mines, Curtin U.' },
  { id: '781', name: 'Western Business School' },
  { id: '733', name: 'Western Carolina U.' },
  { id: '904', name: 'Western Illinois U.' },
  { id: '700', name: 'Western Michigan U.' },
  { id: '1440', name: 'Western New Mexico U.' },
  { id: '1153', name: 'Western Oregon U.' },
  { id: '962', name: 'Western State College of Colorado' },
  { id: '1620', name: 'Western Sydney U.' },
  { id: '1145', name: 'Western Washington State College' },
  { id: '701', name: 'Western Washington U.' },
  { id: '1509', name: 'Westfälische Wilhelms-Universität Münster' },
  { id: '1677', name: 'Westmont College' },
  { id: '703', name: 'Wharton School, U. of Pennsylvania' },
  { id: '704', name: 'Wheaton College' },
  { id: '818', name: 'Whitman College' },
  { id: '1186', name: 'Whittier College' },
  { id: '705', name: 'Whitworth College' },
  { id: '706', name: 'Wichita State U.' },
  { id: '1236', name: 'Widener U.' },
  { id: '707', name: 'Wilfrid Laurier U.' },
  { id: '708', name: 'Wilkes U.' },
  { id: '709', name: 'Willesdon College of Technology' },
  { id: '1443', name: 'William Mitchell College of Law' },
  { id: '1475', name: 'William Woods U.' },
  { id: '751', name: 'Williams College' },
  { id: '1240', name: 'Wittenberg U.' },
  { id: '1625', name: 'Wofford College' },
  { id: '1128', name: 'Woodrow Wilson Law School, Princeton U.' },
  { id: '710', name: 'Worcester Polytechnic Institute' },
  { id: '517', name: 'Wright State U.' },
  { id: '1589', name: 'Wroclaw U. of Science & Technology' },
  { id: '1477', name: 'Wuhan U.' },
  { id: '712', name: 'Xavier U.' },
  { id: '874', name: "Xi'an Jiaotong U." },
  { id: '1696', name: 'Xi’an U. of Finance and Economics' },
  { id: '1503', name: 'Yale Law School' },
  { id: '713', name: 'Yale U.' },
  { id: '1008', name: 'Yamagata U.' },
  { id: '5', name: 'Yangtze U.' },
  { id: '714', name: 'Yeshiva U.' },
  { id: '715', name: 'Yonsei U.' },
  { id: '716', name: 'York U.' },
  { id: '1414', name: 'Yorkville U.' },
  { id: '718', name: 'Youngstown State U.' },
  { id: '1554', name: 'Zhongnan U. of Economics & Law' },
  { id: '1648', name: 'Zurich U. of Applied Sciences              ' },
];

export const positionsLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '110', name: 'acting chair' },
  { id: '70', name: 'acting chief executive officer' },
  { id: '77', name: 'acting chief financial officer' },
  { id: '72', name: 'acting president' },
  { id: '1', name: 'advisory board member' },
  { id: '64', name: 'advisory council member' },
  { id: '146', name: 'associate general counsel' },
  { id: '10', name: 'board of directors' },
  { id: '121', name: 'board of managing directors' },
  { id: '5', name: 'chair' },
  { id: '104', name: 'chair of division' },
  { id: '97', name: 'chair, emeritus' },
  { id: '127', name: 'chief accountant' },
  { id: '128', name: 'chief accounting officer' },
  { id: '129', name: 'chief actuary' },
  { id: '2', name: 'chief administrative officer' },
  { id: '203', name: 'chief anti-money laundering officer' },
  { id: '175', name: 'chief architect' },
  { id: '176', name: 'chief auditor' },
  { id: '152', name: 'chief brand officer' },
  { id: '130', name: 'chief business development officer' },
  { id: '131', name: 'chief business officer' },
  { id: '153', name: 'chief client officer' },
  { id: '132', name: 'chief commercial officer' },
  { id: '151', name: 'chief communication officer' },
  { id: '133', name: 'chief compliance officer' },
  { id: '191', name: 'chief corporate development officer' },
  { id: '157', name: 'chief corporate officer' },
  { id: '155', name: 'chief creative officer' },
  { id: '154', name: 'chief credit officer' },
  { id: '156', name: 'chief customer officer' },
  { id: '134', name: 'chief development officer' },
  { id: '192', name: 'chief digital officer' },
  { id: '193', name: 'chief distribution officer' },
  { id: '135', name: 'chief economist' },
  { id: '136', name: 'chief engineer' },
  { id: '3', name: 'chief executive officer' },
  { id: '158', name: 'chief financial & administrative officer' },
  { id: '194', name: 'chief financial & risk officer' },
  { id: '4', name: 'chief financial officer' },
  { id: '195', name: 'chief geological officer' },
  { id: '137', name: 'chief geologist' },
  { id: '177', name: 'chief geophysicist' },
  { id: '138', name: 'chief geoscientist' },
  { id: '196', name: 'chief governance officer' },
  { id: '139', name: 'chief human resources officer' },
  { id: '197', name: 'chief information & technology officer' },
  { id: '32', name: 'chief information officer' },
  { id: '198', name: 'chief information security officer' },
  { id: '178', name: 'chief innovation officer' },
  { id: '179', name: 'chief internal auditor' },
  { id: '92', name: 'chief investment officer' },
  { id: '180', name: 'chief investment strategist' },
  { id: '199', name: 'chief legal & administration officer' },
  { id: '200', name: 'chief legal & compliance officer' },
  { id: '82', name: 'chief legal officer' },
  { id: '171', name: 'chief marketing & sales officer' },
  { id: '93', name: 'chief marketing officer' },
  { id: '94', name: 'chief medical officer' },
  { id: '172', name: 'chief merchandising officer' },
  { id: '181', name: 'chief nuclear officer' },
  { id: '182', name: 'chief of staff' },
  { id: '8', name: 'chief operating officer' },
  { id: '173', name: 'chief operations officer' },
  { id: '201', name: 'chief people & culture officer' },
  { id: '159', name: 'chief people officer' },
  { id: '140', name: 'chief privacy officer' },
  { id: '160', name: 'chief procurement officer' },
  { id: '161', name: 'chief product officer' },
  { id: '184', name: 'chief regulatory officer' },
  { id: '59', name: 'chief research officer' },
  { id: '162', name: 'chief restructuring officer' },
  { id: '163', name: 'chief revenue officer' },
  { id: '185', name: 'chief risk & compliance officer' },
  { id: '141', name: 'chief risk officer' },
  { id: '167', name: 'chief safety officer' },
  { id: '168', name: 'chief sales & marketing officer' },
  { id: '186', name: 'chief sales officer' },
  { id: '166', name: 'chief science officer' },
  { id: '95', name: 'chief scientific officer' },
  { id: '164', name: 'chief scientist' },
  { id: '165', name: 'chief security officer' },
  { id: '169', name: 'chief strategic officer' },
  { id: '142', name: 'chief strategy officer' },
  { id: '170', name: 'chief supply chain officer' },
  { id: '143', name: 'chief talent officer' },
  { id: '144', name: 'chief technical officer' },
  { id: '54', name: 'chief technology officer' },
  { id: '174', name: 'chief transformation officer' },
  { id: '145', name: 'chief underwriting officer' },
  { id: '56', name: 'co-chair' },
  { id: '55', name: 'co-chief executive officer' },
  { id: '91', name: 'co-chief financial officer' },
  { id: '189', name: 'co-founder' },
  { id: '204', name: 'co-head' },
  { id: '75', name: 'co-managing partner' },
  { id: '205', name: 'co-owner' },
  { id: '9', name: 'co-president' },
  { id: '57', name: 'commissioner' },
  { id: '6', name: 'comptroller' },
  { id: '7', name: 'controller' },
  { id: '60', name: 'corporate counsel' },
  { id: '21', name: 'corporate secretary' },
  { id: '61', name: 'counsel' },
  { id: '106', name: 'department head' },
  { id: '62', name: 'deputy chair' },
  { id: '109', name: 'deputy chief executive officer' },
  { id: '111', name: 'deputy executive chair' },
  { id: '114', name: 'deputy governor' },
  { id: '183', name: 'editor-in-chief' },
  { id: '90', name: 'executive chair' },
  { id: '190', name: 'executive co-chair' },
  { id: '63', name: 'executive committee member' },
  { id: '100', name: 'executive vice-chair' },
  { id: '30', name: 'executive vice-president' },
  { id: '224', name: 'executive vice-president, business development' },
  { id: '222', name: 'executive vice-president, corporate development' },
  { id: '226', name: 'executive vice-president, exploration' },
  { id: '31', name: 'executive vice-president, finance' },
  {
    id: '85',
    name: 'executive vice-president, finance & administration',
  },
  { id: '223', name: 'executive vice-president, human resources' },
  { id: '227', name: 'executive vice-president, information technology' },
  { id: '229', name: 'executive vice-president, marketing' },
  { id: '221', name: 'executive vice-president, operations' },
  { id: '228', name: 'executive vice-president, sales' },
  { id: '225', name: 'executive vice-president, sales & marketing' },
  { id: '115', name: 'first vice-chair' },
  { id: '117', name: 'first vice-president' },
  { id: '188', name: 'founder' },
  { id: '38', name: 'general counsel' },
  { id: '37', name: 'general manager' },
  { id: '150', name: 'global head' },
  { id: '11', name: 'governor' },
  { id: '107', name: 'group chair' },
  { id: '126', name: 'group head' },
  { id: '120', name: 'group president' },
  { id: '12', name: 'group vice-president' },
  { id: '39', name: 'head' },
  { id: '40', name: 'honorary chair' },
  { id: '113', name: 'interim chair' },
  { id: '71', name: 'interim chief executive officer' },
  { id: '78', name: 'interim chief financial officer' },
  { id: '73', name: 'interim president' },
  { id: '68', name: 'key executive officer' },
  { id: '69', name: 'key financial officer' },
  { id: '53', name: 'lead director' },
  { id: '96', name: 'lead trustee' },
  { id: '147', name: 'legal counsel' },
  { id: '43', name: 'management committee member' },
  { id: '42', name: 'managing director' },
  { id: '15', name: 'managing partner' },
  { id: '76', name: 'managing principal' },
  { id: '13', name: 'member' },
  { id: '14', name: 'member of the board' },
  { id: '187', name: 'member of the board of partners' },
  { id: '74', name: 'national chair' },
  { id: '105', name: 'national managing partner' },
  { id: '108', name: 'national vice-president' },
  { id: '102', name: 'office managing partner' },
  { id: '19', name: 'partner' },
  { id: '148', name: 'partnership board member' },
  { id: '17', name: 'policy board member' },
  { id: '18', name: 'president' },
  { id: '65', name: 'president of division/subsidiary' },
  { id: '46', name: 'principal' },
  { id: '101', name: 'regional managing partner' },
  { id: '48', name: 'regional vice-president' },
  { id: '116', name: 'second vice-chair' },
  { id: '118', name: 'second vice-president' },
  { id: '20', name: 'secretary-treasurer' },
  { id: '24', name: 'senior executive vice-president' },
  { id: '79', name: 'senior executive vice-president, finance' },
  {
    id: '84',
    name: 'senior executive vice-president, finance & administration',
  },
  { id: '149', name: 'senior managing director' },
  { id: '123', name: 'senior managing partner' },
  { id: '50', name: 'senior partner' },
  { id: '22', name: 'senior vice-president' },
  { id: '215', name: 'senior vice-president, business development' },
  { id: '214', name: 'senior vice-president, corporate development' },
  { id: '216', name: 'senior vice-president, exploration' },
  { id: '23', name: 'senior vice-president, finance' },
  {
    id: '86',
    name: 'senior vice-president, finance & administration',
  },
  { id: '213', name: 'senior vice-president, human resources' },
  { id: '220', name: 'senior vice-president, information technology' },
  { id: '219', name: 'senior vice-president, marketing' },
  { id: '212', name: 'senior vice-president, operations' },
  { id: '218', name: 'senior vice-president, sales' },
  { id: '217', name: 'senior vice-president, sales & marketing' },
  { id: '25', name: 'treasurer' },
  { id: '26', name: 'trustee' },
  { id: '27', name: 'vice-chair' },
  { id: '119', name: 'vice-chief executive officer' },
  { id: '28', name: 'vice-president' },
  { id: '206', name: 'vice-president, business development' },
  { id: '208', name: 'vice-president, corporate development' },
  { id: '103', name: 'vice-president, exploration' },
  { id: '29', name: 'vice-president, finance' },
  { id: '87', name: 'vice-president, finance & administration' },
  { id: '207', name: 'vice-president, human resources' },
  { id: '211', name: 'vice-president, information technology' },
  { id: '125', name: 'vice-president, investor relations' },
  { id: '209', name: 'vice-president, marketing' },
  { id: '122', name: 'vice-president, operations' },
  { id: '124', name: 'vice-president, portfolio management' },
  { id: '230', name: 'vice-president, sales' },
  {
    id: '210',
    name: 'vice-president, sales & marketing                ',
  },
];

export const countriesLookup: Array<ILookup> = [
  { id: '1', name: 'Afghanistan' },
  { id: '2', name: 'Albania' },
  { id: '3', name: 'Algeria' },
  { id: '4', name: 'Andorra' },
  { id: '5', name: 'Angola' },
  { id: '6', name: 'Anguilla' },
  { id: '9', name: 'Antigua and Barbuda' },
  { id: '10', name: 'Argentina' },
  { id: '210', name: 'Armenia' },
  { id: '11', name: 'Aruba' },
  { id: '12', name: 'Australia' },
  { id: '13', name: 'Austria' },
  { id: '14', name: 'Azerbaijan' },
  { id: '15', name: 'Bahamas' },
  { id: '16', name: 'Bahrain' },
  { id: '17', name: 'Bangladesh' },
  { id: '18', name: 'Barbados' },
  { id: '222', name: 'Belarus' },
  { id: '19', name: 'Belgium' },
  { id: '20', name: 'Belize' },
  { id: '21', name: 'Benin' },
  { id: '22', name: 'Bermuda' },
  { id: '23', name: 'Bhutan' },
  { id: '24', name: 'Bolivia' },
  { id: '215', name: 'Bosnia and Herzegovina' },
  { id: '25', name: 'Botswana' },
  { id: '26', name: 'Brazil' },
  { id: '27', name: 'British Virgin Islands' },
  { id: '29', name: 'Brunei' },
  { id: '30', name: 'Bulgaria' },
  { id: '31', name: 'Burkina Faso' },
  { id: '32', name: 'Burundi' },
  { id: '33', name: 'Cambodia' },
  { id: '34', name: 'Cameroon' },
  { id: '35', name: 'Canada' },
  { id: '36', name: 'Cape Verde' },
  { id: '37', name: 'Cayman Islands' },
  { id: '38', name: 'Central African Republic' },
  { id: '39', name: 'Chad' },
  { id: '41', name: 'Chile' },
  { id: '42', name: 'Colombia' },
  { id: '43', name: 'Comoros' },
  { id: '45', name: 'Cook Islands' },
  { id: '46', name: 'Costa Rica' },
  { id: '94', name: "Côte d'Ivoire" },
  { id: '214', name: 'Croatia' },
  { id: '47', name: 'Cuba' },
  { id: '236', name: 'Curacao' },
  { id: '49', name: 'Cyprus' },
  { id: '50', name: 'Czech Republic' },
  { id: '203', name: 'Democratic Republic of Congo' },
  { id: '52', name: 'Denmark' },
  { id: '53', name: 'Djibouti' },
  { id: '54', name: 'Dominica' },
  { id: '55', name: 'Dominican Republic' },
  { id: '56', name: 'Ecuador' },
  { id: '57', name: 'Egypt' },
  { id: '58', name: 'El Salvador' },
  { id: '60', name: 'Equatorial Guinea' },
  { id: '219', name: 'Eritrea' },
  { id: '223', name: 'Estonia' },
  { id: '61', name: 'Ethiopia' },
  { id: '62', name: 'Fiji' },
  { id: '63', name: 'Finland' },
  { id: '64', name: 'France' },
  { id: '65', name: 'French Guiana' },
  { id: '66', name: 'French Polynesia' },
  { id: '67', name: 'Gabon' },
  { id: '68', name: 'Gambia' },
  { id: '209', name: 'Georgia' },
  { id: '69', name: 'Germany' },
  { id: '70', name: 'Ghana' },
  { id: '71', name: 'Gibraltar' },
  { id: '73', name: 'Greece' },
  { id: '74', name: 'Greenland' },
  { id: '75', name: 'Grenada' },
  { id: '76', name: 'Guadeloupe' },
  { id: '206', name: 'Guam' },
  { id: '77', name: 'Guatemala' },
  { id: '217', name: 'Guernsey' },
  { id: '78', name: 'Guinea' },
  { id: '79', name: 'Guinea-Bissau' },
  { id: '80', name: 'Guyana' },
  { id: '81', name: 'Haiti' },
  { id: '82', name: 'Honduras' },
  { id: '84', name: 'Hungary' },
  { id: '85', name: 'Iceland' },
  { id: '86', name: 'India' },
  { id: '87', name: 'Indonesia' },
  { id: '88', name: 'Iran' },
  { id: '89', name: 'Iraq' },
  { id: '90', name: 'Ireland' },
  { id: '91', name: 'Isle of Man' },
  { id: '92', name: 'Israel' },
  { id: '93', name: 'Italy' },
  { id: '95', name: 'Jamaica' },
  { id: '96', name: 'Japan' },
  { id: '216', name: 'Jersey' },
  { id: '97', name: 'Jordan' },
  { id: '98', name: 'Kazakhstan' },
  { id: '99', name: 'Kenya' },
  { id: '230', name: 'Kiribati' },
  { id: '221', name: 'Kosovo' },
  { id: '100', name: 'Kuwait' },
  { id: '208', name: 'Kyrgyzstan' },
  { id: '101', name: 'Laos' },
  { id: '102', name: 'Latvia' },
  { id: '103', name: 'Lebanon' },
  { id: '104', name: 'Lesotho' },
  { id: '105', name: 'Liberia' },
  { id: '106', name: 'Libya' },
  { id: '107', name: 'Liechtenstein' },
  { id: '224', name: 'Lithuania' },
  { id: '108', name: 'Luxembourg' },
  { id: '225', name: 'Macedonia' },
  { id: '110', name: 'Madagascar' },
  { id: '213', name: 'Madeira Islands' },
  { id: '111', name: 'Malawi' },
  { id: '112', name: 'Malaysia' },
  { id: '113', name: 'Maldives' },
  { id: '114', name: 'Mali' },
  { id: '115', name: 'Malta' },
  { id: '116', name: 'Marshall Islands' },
  { id: '117', name: 'Martinique' },
  { id: '118', name: 'Mauritania' },
  { id: '119', name: 'Mauritius' },
  { id: '120', name: 'Mexico' },
  { id: '231', name: 'Micronesia' },
  { id: '226', name: 'Moldova' },
  { id: '121', name: 'Monaco' },
  { id: '122', name: 'Mongolia' },
  { id: '220', name: 'Montenegro' },
  { id: '123', name: 'Morocco' },
  { id: '124', name: 'Mozambique' },
  { id: '125', name: 'Myanmar' },
  { id: '126', name: 'Namibia' },
  { id: '127', name: 'Nauru' },
  { id: '128', name: 'Nepal' },
  { id: '129', name: 'Netherlands' },
  { id: '131', name: 'New Caledonia' },
  { id: '132', name: 'New Zealand' },
  { id: '133', name: 'Nicaragua' },
  { id: '134', name: 'Niger' },
  { id: '135', name: 'Nigeria' },
  { id: '136', name: 'North Korea' },
  { id: '138', name: 'Norway' },
  { id: '139', name: 'Oman' },
  { id: '140', name: 'Pakistan' },
  { id: '232', name: 'Palau' },
  { id: '141', name: 'Panama' },
  { id: '142', name: 'Papua New Guinea' },
  { id: '143', name: 'Paraguay' },
  { id: '144', name: "People's Republic of China" },
  { id: '145', name: 'Peru' },
  { id: '146', name: 'Philippines' },
  { id: '147', name: 'Poland' },
  { id: '148', name: 'Portugal' },
  { id: '149', name: 'Puerto Rico' },
  { id: '150', name: 'Qatar' },
  { id: '179', name: 'Republic of China' },
  { id: '44', name: 'Republic of Congo' },
  { id: '151', name: 'Romania' },
  { id: '152', name: 'Russia' },
  { id: '153', name: 'Rwanda' },
  { id: '169', name: 'Saint Kitts and Nevis' },
  { id: '170', name: 'Saint Lucia' },
  {
    id: '171',
    name: 'Saint Vincent and the Grenadines',
  },
  { id: '200', name: 'Samoa' },
  { id: '154', name: 'San Marino' },
  { id: '155', name: 'Sao Tome and Principe' },
  { id: '156', name: 'Saudi Arabia' },
  { id: '158', name: 'Senegal' },
  { id: '202', name: 'Serbia' },
  { id: '159', name: 'Seychelles' },
  { id: '160', name: 'Sierra Leone' },
  { id: '161', name: 'Singapore' },
  { id: '235', name: 'Sint Maarten' },
  { id: '162', name: 'Slovakia' },
  { id: '211', name: 'Slovenia' },
  { id: '163', name: 'Solomon Islands' },
  { id: '164', name: 'Somalia' },
  { id: '165', name: 'South Africa' },
  { id: '166', name: 'South Korea' },
  { id: '237', name: 'South Sudan' },
  { id: '167', name: 'Spain' },
  { id: '168', name: 'Sri Lanka' },
  { id: '173', name: 'Sudan' },
  { id: '174', name: 'Suriname' },
  { id: '175', name: 'Swaziland' },
  { id: '176', name: 'Sweden' },
  { id: '177', name: 'Switzerland' },
  { id: '178', name: 'Syria' },
  { id: '227', name: 'Tajikistan' },
  { id: '180', name: 'Tanzania' },
  { id: '181', name: 'Thailand' },
  { id: '229', name: 'Timor-Leste' },
  { id: '182', name: 'Togo' },
  { id: '183', name: 'Tonga' },
  { id: '184', name: 'Trinidad and Tobago' },
  { id: '185', name: 'Tunisia' },
  { id: '186', name: 'Turkey' },
  { id: '218', name: 'Turkmenistan' },
  { id: '187', name: 'Turks and Caicos Islands' },
  { id: '233', name: 'Tuvalu' },
  { id: '197', name: 'U.S. Virgin Islands' },
  { id: '188', name: 'Uganda' },
  { id: '189', name: 'Ukraine' },
  { id: '190', name: 'United Arab Emirates' },
  { id: '191', name: 'United Kingdom' },
  { id: '192', name: 'United States' },
  { id: '193', name: 'Uruguay' },
  { id: '228', name: 'Uzbekistan' },
  { id: '194', name: 'Vanuatu' },
  { id: '234', name: 'Vatican City' },
  { id: '195', name: 'Venezuela' },
  { id: '196', name: 'Vietnam' },
  { id: '201', name: 'Yemen' },
  { id: '204', name: 'Zambia' },
  { id: '205', name: 'Zimbabwe' },
];

export const locationLookup: Array<ILookup> = [
  { id: '0', name: '   Select All' },
  { id: '35', name: '   Select All Canada' },
  { id: '999', name: '   Select All Excluding Canada' },
  { id: '', name: 'By Province' },
  { id: '-1', name: '   Alberta' },
  { id: '-2', name: '   British Columbia' },
  { id: '-3', name: '   Manitoba' },
  { id: '-4', name: '   New Brunswick' },
  { id: '-5', name: '   Newfoundland and Labrador' },
  { id: '-6', name: '   Northwest Territories' },
  { id: '-7', name: '   Nova Scotia' },
  { id: '-64', name: '   Nunavut' },
  { id: '-8', name: '   Ontario' },
  { id: '-9', name: '   Prince Edward Island' },
  { id: '-10', name: '   Quebec' },
  { id: '-11', name: '   Saskatchewan' },
  { id: '-12', name: '   Yukon' },
  { id: '', name: 'By Continent' },
  { id: '=1', name: '   Africa' },
  { id: '=2', name: '   Antarctica' },
  { id: '=4', name: '   Australia/Oceania' },
  { id: '=5', name: '   Europe' },
  { id: '=6', name: '   North America' },
  { id: '=7', name: '   South America' },
  { id: '', name: 'By Country' },
  { id: '1', name: '   Afghanistan' },
  { id: '2', name: '   Albania' },
  { id: '3', name: '   Algeria' },
  { id: '4', name: '   Andorra' },
  { id: '5', name: '   Angola' },
  { id: '6', name: '   Anguilla' },
  { id: '9', name: '   Antigua and Barbuda' },
  { id: '10', name: '   Argentina' },
  { id: '210', name: '   Armenia' },
  { id: '11', name: '   Aruba' },
  { id: '12', name: '   Australia' },
  { id: '13', name: '   Austria' },
  { id: '14', name: '   Azerbaijan' },
  { id: '15', name: '   Bahamas' },
  { id: '16', name: '   Bahrain' },
  { id: '17', name: '   Bangladesh' },
  { id: '18', name: '   Barbados' },
  { id: '222', name: '   Belarus' },
  { id: '19', name: '   Belgium' },
  { id: '20', name: '   Belize' },
  { id: '21', name: '   Benin' },
  { id: '22', name: '   Bermuda' },
  { id: '23', name: '   Bhutan' },
  { id: '24', name: '   Bolivia' },
  { id: '215', name: '   Bosnia and Herzegovina' },
  { id: '25', name: '   Botswana' },
  { id: '26', name: '   Brazil' },
  { id: '27', name: '   British Virgin Islands' },
  { id: '29', name: '   Brunei' },
  { id: '30', name: '   Bulgaria' },
  { id: '31', name: '   Burkina Faso' },
  { id: '32', name: '   Burundi' },
  { id: '33', name: '   Cambodia' },
  { id: '34', name: '   Cameroon' },
  { id: '1000', name: '   Canada (unspecified)' },
  { id: '36', name: '   Cape Verde' },
  { id: '37', name: '   Cayman Islands' },
  { id: '38', name: '   Central African Republic' },
  { id: '39', name: '   Chad' },
  { id: '41', name: '   Chile' },
  { id: '42', name: '   Colombia' },
  { id: '43', name: '   Comoros' },
  { id: '45', name: '   Cook Islands' },
  { id: '46', name: '   Costa Rica' },
  { id: '94', name: "   Côte d'Ivoire" },
  { id: '214', name: '   Croatia' },
  { id: '47', name: '   Cuba' },
  { id: '236', name: '   Curacao' },
  { id: '49', name: '   Cyprus' },
  { id: '50', name: '   Czech Republic' },
  { id: '203', name: '   Democratic Republic of Congo' },
  { id: '52', name: '   Denmark' },
  { id: '53', name: '   Djibouti' },
  { id: '54', name: '   Dominica' },
  { id: '55', name: '   Dominican Republic' },
  { id: '56', name: '   Ecuador' },
  { id: '57', name: '   Egypt' },
  { id: '58', name: '   El Salvador' },
  { id: '60', name: '   Equatorial Guinea' },
  { id: '219', name: '   Eritrea' },
  { id: '223', name: '   Estonia' },
  { id: '61', name: '   Ethiopia' },
  { id: '62', name: '   Fiji' },
  { id: '63', name: '   Finland' },
  { id: '64', name: '   France' },
  { id: '65', name: '   French Guiana' },
  { id: '66', name: '   French Polynesia' },
  { id: '67', name: '   Gabon' },
  { id: '68', name: '   Gambia' },
  { id: '209', name: '   Georgia' },
  { id: '69', name: '   Germany' },
  { id: '70', name: '   Ghana' },
  { id: '71', name: '   Gibraltar' },
  { id: '73', name: '   Greece' },
  { id: '74', name: '   Greenland' },
  { id: '75', name: '   Grenada' },
  { id: '76', name: '   Guadeloupe' },
  { id: '206', name: '   Guam' },
  { id: '77', name: '   Guatemala' },
  { id: '217', name: '   Guernsey' },
  { id: '78', name: '   Guinea' },
  { id: '79', name: '   Guinea-Bissau' },
  { id: '80', name: '   Guyana' },
  { id: '81', name: '   Haiti' },
  { id: '82', name: '   Honduras' },
  { id: '84', name: '   Hungary' },
  { id: '85', name: '   Iceland' },
  { id: '86', name: '   India' },
  { id: '87', name: '   Indonesia' },
  { id: '88', name: '   Iran' },
  { id: '89', name: '   Iraq' },
  { id: '90', name: '   Ireland' },
  { id: '91', name: '   Isle of Man' },
  { id: '92', name: '   Israel' },
  { id: '93', name: '   Italy' },
  { id: '95', name: '   Jamaica' },
  { id: '96', name: '   Japan' },
  { id: '216', name: '   Jersey' },
  { id: '97', name: '   Jordan' },
  { id: '98', name: '   Kazakhstan' },
  { id: '99', name: '   Kenya' },
  { id: '230', name: '   Kiribati' },
  { id: '221', name: '   Kosovo' },
  { id: '100', name: '   Kuwait' },
  { id: '208', name: '   Kyrgyzstan' },
  { id: '101', name: '   Laos' },
  { id: '102', name: '   Latvia' },
  { id: '103', name: '   Lebanon' },
  { id: '104', name: '   Lesotho' },
  { id: '105', name: '   Liberia' },
  { id: '106', name: '   Libya' },
  { id: '107', name: '   Liechtenstein' },
  { id: '224', name: '   Lithuania' },
  { id: '108', name: '   Luxembourg' },
  { id: '225', name: '   Macedonia' },
  { id: '110', name: '   Madagascar' },
  { id: '213', name: '   Madeira Islands' },
  { id: '111', name: '   Malawi' },
  { id: '112', name: '   Malaysia' },
  { id: '113', name: '   Maldives' },
  { id: '114', name: '   Mali' },
  { id: '115', name: '   Malta' },
  { id: '116', name: '   Marshall Islands' },
  { id: '117', name: '   Martinique' },
  { id: '118', name: '   Mauritania' },
  { id: '119', name: '   Mauritius' },
  { id: '120', name: '   Mexico' },
  { id: '231', name: '   Micronesia' },
  { id: '226', name: '   Moldova' },
  { id: '121', name: '   Monaco' },
  { id: '122', name: '   Mongolia' },
  { id: '220', name: '   Montenegro' },
  { id: '123', name: '   Morocco' },
  { id: '124', name: '   Mozambique' },
  { id: '125', name: '   Myanmar' },
  { id: '126', name: '   Namibia' },
  { id: '127', name: '   Nauru' },
  { id: '128', name: '   Nepal' },
  { id: '129', name: '   Netherlands' },
  { id: '131', name: '   New Caledonia' },
  { id: '132', name: '   New Zealand' },
  { id: '133', name: '   Nicaragua' },
  { id: '134', name: '   Niger' },
  { id: '135', name: '   Nigeria' },
  { id: '136', name: '   North Korea' },
  { id: '138', name: '   Norway' },
  { id: '139', name: '   Oman' },
  { id: '140', name: '   Pakistan' },
  { id: '232', name: '   Palau' },
  { id: '141', name: '   Panama' },
  { id: '142', name: '   Papua New Guinea' },
  { id: '143', name: '   Paraguay' },
  { id: '144', name: "   People's Republic of China" },
  { id: '145', name: '   Peru' },
  { id: '146', name: '   Philippines' },
  { id: '147', name: '   Poland' },
  { id: '148', name: '   Portugal' },
  { id: '149', name: '   Puerto Rico' },
  { id: '150', name: '   Qatar' },
  { id: '179', name: '   Republic of China' },
  { id: '44', name: '   Republic of Congo' },
  { id: '151', name: '   Romania' },
  { id: '152', name: '   Russia' },
  { id: '153', name: '   Rwanda' },
  { id: '169', name: '   Saint Kitts and Nevis' },
  { id: '170', name: '   Saint Lucia' },
  {
    id: '171',
    name: '   Saint Vincent and the Grenadines',
  },
  { id: '200', name: '   Samoa' },
  { id: '154', name: '   San Marino' },
  { id: '155', name: '   Sao Tome and Principe' },
  { id: '156', name: '   Saudi Arabia' },
  { id: '158', name: '   Senegal' },
  { id: '202', name: '   Serbia' },
  { id: '159', name: '   Seychelles' },
  { id: '160', name: '   Sierra Leone' },
  { id: '161', name: '   Singapore' },
  { id: '235', name: '   Sint Maarten' },
  { id: '162', name: '   Slovakia' },
  { id: '211', name: '   Slovenia' },
  { id: '163', name: '   Solomon Islands' },
  { id: '164', name: '   Somalia' },
  { id: '165', name: '   South Africa' },
  { id: '166', name: '   South Korea' },
  { id: '237', name: '   South Sudan' },
  { id: '167', name: '   Spain' },
  { id: '168', name: '   Sri Lanka' },
  { id: '173', name: '   Sudan' },
  { id: '174', name: '   Suriname' },
  { id: '175', name: '   Swaziland' },
  { id: '176', name: '   Sweden' },
  { id: '177', name: '   Switzerland' },
  { id: '178', name: '   Syria' },
  { id: '227', name: '   Tajikistan' },
  { id: '180', name: '   Tanzania' },
  { id: '181', name: '   Thailand' },
  { id: '229', name: '   Timor-Leste' },
  { id: '182', name: '   Togo' },
  { id: '183', name: '   Tonga' },
  { id: '184', name: '   Trinidad and Tobago' },
  { id: '185', name: '   Tunisia' },
  { id: '186', name: '   Turkey' },
  { id: '218', name: '   Turkmenistan' },
  { id: '187', name: '   Turks and Caicos Islands' },
  { id: '233', name: '   Tuvalu' },
  { id: '197', name: '   U.S. Virgin Islands' },
  { id: '188', name: '   Uganda' },
  { id: '189', name: '   Ukraine' },
  { id: '190', name: '   United Arab Emirates' },
  { id: '191', name: '   United Kingdom' },
  { id: '192', name: '   United States' },
  { id: '193', name: '   Uruguay' },
  { id: '228', name: '   Uzbekistan' },
  { id: '194', name: '   Vanuatu' },
  { id: '234', name: '   Vatican City' },
  { id: '195', name: '   Venezuela' },
  { id: '196', name: '   Vietnam' },
  { id: '201', name: '   Yemen' },
  { id: '204', name: '   Zambia' },
  { id: '205', name: '   Zimbabwe' },
];

export const naicsLookup: Array<ILookup> = [
  { id: '', name: 'NAICS Codes' },
  { id: '11', name: '11 - Agriculture, forestry, fishing and hunting' },
  { id: '111', name: '111 - Crop production' },
  {
    id: '1111',
    name: '1111 - Oilseed and grain farming',
  },
  {
    id: '11111',
    name: '11111 - Soybean farming',
  },
  {
    id: '111110',
    name: '111110 - Soybean farming',
  },
  {
    id: '11112',
    name: '11112 - Oilseed (except soybean) farming',
  },
  {
    id: '111120',
    name: '111120 - Oilseed (except soybean) farming',
  },
  {
    id: '11113',
    name: '11113 - Dry pea and bean farming',
  },
  {
    id: '111130',
    name: '111130 - Dry pea and bean farming',
  },
  {
    id: '11114',
    name: '11114 - Wheat farming',
  },
  {
    id: '111140',
    name: '111140 - Wheat farming',
  },
  {
    id: '11115',
    name: '11115 - Corn farming',
  },
  {
    id: '111150',
    name: '111150 - Corn farming',
  },
  {
    id: '11116',
    name: '11116 - Rice farming',
  },
  {
    id: '111160',
    name: '111160 - Rice farming',
  },
  {
    id: '11119',
    name: '11119 - Other grain farming',
  },
  {
    id: '111191',
    name: '111191 - Oilseed and grain combination farming',
  },
  {
    id: '111199',
    name: '111199 - All other grain farming',
  },
  {
    id: '1112',
    name: '1112 - Vegetable and melon farming',
  },
  {
    id: '11121',
    name: '11121 - Vegetable and melon farming',
  },
  {
    id: '111211',
    name: '111211 - Potato farming',
  },
  {
    id: '111219',
    name: '111219 - Other vegetable (except potato) and melon farming',
  },
  {
    id: '1113',
    name: '1113 - Fruit and tree nut farming',
  },
  {
    id: '11131',
    name: '11131 - Orange groves',
  },
  {
    id: '111310',
    name: '111310 - Orange groves',
  },
  {
    id: '11132',
    name: '11132 - Citrus (except orange) groves',
  },
  {
    id: '111320',
    name: '111320 - Citrus (except orange) groves',
  },
  {
    id: '11133',
    name: '11133 - Noncitrus fruit and tree nut farming',
  },
  {
    id: '111331',
    name: '111331 - Apple orchards',
  },
  {
    id: '111332',
    name: '111332 - Grape vineyards',
  },
  {
    id: '111333',
    name: '111333 - Strawberry farming',
  },
  {
    id: '111334',
    name: '111334 - Berry (except strawberry) farming',
  },
  {
    id: '111335',
    name: '111335 - Tree nut farming',
  },
  {
    id: '111336',
    name: '111336 - Fruit and tree nut combination farming',
  },
  {
    id: '111339',
    name: '111339 - Other noncitrus fruit farming',
  },
  {
    id: '1114',
    name: '1114 - Greenhouse, nursery, and floriculture production',
  },
  {
    id: '11141',
    name: '11141 - Food crops grown under cover',
  },
  {
    id: '111411',
    name: '111411 - Mushroom production',
  },
  {
    id: '111419',
    name: '111419 - Other food crops grown under cover',
  },
  {
    id: '11142',
    name: '11142 - Nursery and floriculture production',
  },
  {
    id: '111421',
    name: '111421 - Nursery and tree production',
  },
  {
    id: '111422',
    name: '111422 - Floriculture production',
  },
  {
    id: '1119',
    name: '1119 - Other crop farming',
  },
  {
    id: '11191',
    name: '11191 - Tobacco farming',
  },
  {
    id: '111910',
    name: '111910 - Tobacco farming',
  },
  {
    id: '11192',
    name: '11192 - Cotton farming',
  },
  {
    id: '111920',
    name: '111920 - Cotton farming',
  },
  {
    id: '11193',
    name: '11193 - Sugarcane farming',
  },
  {
    id: '111930',
    name: '111930 - Sugarcane farming',
  },
  {
    id: '11194',
    name: '11194 - Hay farming',
  },
  {
    id: '111940',
    name: '111940 - Hay farming',
  },
  {
    id: '11199',
    name: '11199 - All other crop farming',
  },
  {
    id: '111991',
    name: '111991 - Sugar beet farming',
  },
  {
    id: '111992',
    name: '111992 - Peanut farming',
  },
  {
    id: '111998',
    name: '111998 - All other miscellaneous crop farming',
  },
  {
    id: '112',
    name: '112 - Animal production and aquaculture',
  },
  {
    id: '1121',
    name: '1121 - Cattle ranching and farming',
  },
  {
    id: '11211',
    name: '11211 - Beef cattle ranching and farming, including feedlots',
  },
  {
    id: '112111',
    name: '112111 - Beef cattle ranching and farming',
  },
  {
    id: '112112',
    name: '112112 - Cattle feedlots',
  },
  {
    id: '11212',
    name: '11212 - Dairy cattle and milk production',
  },
  {
    id: '112120',
    name: '112120 - Dairy cattle and milk production',
  },
  {
    id: '11213',
    name: '11213 - Dual-purpose cattle ranching and farming',
  },
  {
    id: '112130',
    name: '112130 - Dual-purpose cattle ranching and farming',
  },
  {
    id: '1122',
    name: '1122 - Hog and pig farming',
  },
  {
    id: '11221',
    name: '11221 - Hog and pig farming',
  },
  {
    id: '112210',
    name: '112210 - Hog and pig farming',
  },
  {
    id: '1123',
    name: '1123 - Poultry and egg production',
  },
  {
    id: '11231',
    name: '11231 - Chicken egg production',
  },
  {
    id: '112310',
    name: '112310 - Chicken egg production',
  },
  {
    id: '11232',
    name: '11232 - Broilers and other meat type chicken production',
  },
  {
    id: '112320',
    name: '112320 - Broilers and other meat type chicken production',
  },
  {
    id: '11233',
    name: '11233 - Turkey production',
  },
  {
    id: '112330',
    name: '112330 - Turkey production',
  },
  {
    id: '11234',
    name: '11234 - Poultry hatcheries',
  },
  {
    id: '112340',
    name: '112340 - Poultry hatcheries',
  },
  {
    id: '11239',
    name: '11239 - Other poultry production',
  },
  {
    id: '112390',
    name: '112390 - Other poultry production',
  },
  {
    id: '1124',
    name: '1124 - Sheep and goat farming',
  },
  {
    id: '11241',
    name: '11241 - Sheep farming',
  },
  {
    id: '112410',
    name: '112410 - Sheep farming',
  },
  {
    id: '11242',
    name: '11242 - Goat farming',
  },
  {
    id: '112420',
    name: '112420 - Goat farming',
  },
  {
    id: '1125',
    name: '1125 - Aquaculture',
  },
  {
    id: '11251',
    name: '11251 - Aquaculture',
  },
  {
    id: '112511',
    name: '112511 - Finfish farming and fish hatcheries',
  },
  {
    id: '112512',
    name: '112512 - Shellfish farming',
  },
  {
    id: '112519',
    name: '112519 - Other aquaculture',
  },
  {
    id: '1129',
    name: '1129 - Other animal production',
  },
  {
    id: '11291',
    name: '11291 - Apiculture',
  },
  {
    id: '112910',
    name: '112910 - Apiculture',
  },
  {
    id: '11292',
    name: '11292 - Horses and other equine production',
  },
  {
    id: '112920',
    name: '112920 - Horses and other equine production',
  },
  {
    id: '11293',
    name: '11293 - Fur-bearing animal and rabbit production',
  },
  {
    id: '112930',
    name: '112930 - Fur-bearing animal and rabbit production',
  },
  {
    id: '11299',
    name: '11299 - All other animal production',
  },
  {
    id: '112990',
    name: '112990 - All other animal production',
  },
  {
    id: '113',
    name: '113 - Forestry and logging',
  },
  {
    id: '1131',
    name: '1131 - Timber tract operations',
  },
  {
    id: '11311',
    name: '11311 - Timber tract operations',
  },
  {
    id: '113110',
    name: '113110 - Timber tract operations',
  },
  {
    id: '1132',
    name: '1132 - Forest nurseries and gathering of forest products',
  },
  {
    id: '11321',
    name: '11321 - Forest nurseries and gathering of forest products',
  },
  {
    id: '113210',
    name: '113210 - Forest nurseries and gathering of forest products',
  },
  {
    id: '1133',
    name: '1133 - Logging',
  },
  {
    id: '11331',
    name: '11331 - Logging',
  },
  {
    id: '113310',
    name: '113310 - Logging',
  },
  {
    id: '114',
    name: '114 - Fishing, hunting and trapping',
  },
  {
    id: '1141',
    name: '1141 - Fishing',
  },
  {
    id: '11411',
    name: '11411 - Fishing',
  },
  {
    id: '114111',
    name: '114111 - Finfish fishing',
  },
  {
    id: '114112',
    name: '114112 - Shellfish fishing',
  },
  {
    id: '114119',
    name: '114119 - Other marine fishing',
  },
  {
    id: '1142',
    name: '1142 - Hunting and trapping',
  },
  {
    id: '11421',
    name: '11421 - Hunting and trapping',
  },
  {
    id: '114210',
    name: '114210 - Hunting and trapping',
  },
  {
    id: '115',
    name: '115 - Support activities for agriculture and forestry',
  },
  {
    id: '1151',
    name: '1151 - Support activities for crop production',
  },
  {
    id: '11511',
    name: '11511 - Support activities for crop production',
  },
  {
    id: '115111',
    name: '115111 - Cotton ginning',
  },
  {
    id: '115112',
    name: '115112 - Soil preparation, planting, and cultivating',
  },
  {
    id: '115113',
    name: '115113 - Crop harvesting, primarily by machine',
  },
  {
    id: '115114',
    name: '115114 - Postharvest crop activities (except cotton ginning)',
  },
  {
    id: '115115',
    name: '115115 - Farm labor contractors and crew leaders',
  },
  {
    id: '115116',
    name: '115116 - Farm management services',
  },
  {
    id: '1152',
    name: '1152 - Support activities for animal production',
  },
  {
    id: '11521',
    name: '11521 - Support activities for animal production',
  },
  {
    id: '115210',
    name: '115210 - Support activities for animal production',
  },
  {
    id: '1153',
    name: '1153 - Support activities for forestry',
  },
  {
    id: '11531',
    name: '11531 - Support activities for forestry',
  },
  {
    id: '115310',
    name: '115310 - Support activities for forestry',
  },
  {
    id: '21',
    name: '21 - Mining, quarrying, and oil and gas extraction',
  },
  {
    id: '211',
    name: '211 - Oil and gas extraction',
  },
  {
    id: '2111',
    name: '2111 - Oil and gas extraction',
  },
  {
    id: '21112',
    name: '21112 - Crude petroleum extraction',
  },
  {
    id: '211120',
    name: '211120 - Crude petroleum extraction',
  },
  {
    id: '21113',
    name: '21113 - Natural gas extraction',
  },
  {
    id: '211130',
    name: '211130 - Natural gas extraction',
  },
  {
    id: '212',
    name: '212 - Mining (except oil and gas)',
  },
  {
    id: '2121',
    name: '2121 - Coal mining',
  },
  {
    id: '21211',
    name: '21211 - Coal mining',
  },
  {
    id: '212111',
    name: '212111 - Bituminous coal and lignite surface mining',
  },
  {
    id: '212112',
    name: '212112 - Bituminous coal underground mining',
  },
  {
    id: '212113',
    name: '212113 - Anthracite mining',
  },
  {
    id: '2122',
    name: '2122 - Metal ore mining',
  },
  {
    id: '21221',
    name: '21221 - Iron ore mining',
  },
  {
    id: '212210',
    name: '212210 - Iron ore mining',
  },
  {
    id: '21222',
    name: '21222 - Gold ore and silver ore mining',
  },
  {
    id: '212221',
    name: '212221 - Gold ore mining',
  },
  {
    id: '212222',
    name: '212222 - Silver ore mining',
  },
  {
    id: '21223',
    name: '21223 - Copper, nickel, lead, and zinc mining',
  },
  {
    id: '212230',
    name: '212230 - Copper, nickel, lead, and zinc mining',
  },
  {
    id: '21229',
    name: '21229 - Other metal ore mining',
  },
  {
    id: '212291',
    name: '212291 - Uranium-radium-vanadium ore mining',
  },
  {
    id: '212299',
    name: '212299 - All other metal ore mining',
  },
  {
    id: '2123',
    name: '2123 - Nonmetallic mineral mining and quarrying',
  },
  {
    id: '21231',
    name: '21231 - Stone mining and quarrying',
  },
  {
    id: '212311',
    name: '212311 - Dimension stone mining and quarrying',
  },
  {
    id: '212312',
    name: '212312 - Crushed and broken limestone mining and quarrying',
  },
  {
    id: '212313',
    name: '212313 - Crushed and broken granite mining and quarrying',
  },
  {
    id: '212319',
    name: '212319 - Other crushed and broken stone mining and quarrying',
  },
  {
    id: '21232',
    name: '21232 - Sand, gravel, clay, and ceramic and refractory minerals mining and quarrying',
  },
  {
    id: '212321',
    name: '212321 - Construction sand and gravel mining',
  },
  {
    id: '212322',
    name: '212322 - Industrial sand mining',
  },
  {
    id: '212324',
    name: '212324 - Kaolin and ball clay mining',
  },
  {
    id: '212325',
    name: '212325 - Clay and ceramic and refractory minerals mining',
  },
  {
    id: '21239',
    name: '21239 - Other nonmetallic mineral mining and quarrying',
  },
  {
    id: '212391',
    name: '212391 - Potash, soda, and borate mineral mining',
  },
  {
    id: '212392',
    name: '212392 - Phosphate rock mining',
  },
  {
    id: '212393',
    name: '212393 - Other chemical and fertilizer mineral mining',
  },
  {
    id: '212399',
    name: '212399 - All other nonmetallic mineral mining',
  },
  {
    id: '2129',
    name: '2129 - Mineral exploration',
  },
  {
    id: '21299',
    name: '21299 - Mineral exploration',
  },
  {
    id: '212999',
    name: '212999 - Mineral exploration',
  },
  {
    id: '213',
    name: '213 - Support activities for mining',
  },
  {
    id: '2131',
    name: '2131 - Support activities for mining',
  },
  {
    id: '21311',
    name: '21311 - Support activities for mining',
  },
  {
    id: '213111',
    name: '213111 - Drilling oil and gas wells',
  },
  {
    id: '213112',
    name: '213112 - Support activities for oil and gas operations',
  },
  {
    id: '213113',
    name: '213113 - Support activities for coal mining',
  },
  {
    id: '213114',
    name: '213114 - Support activities for metal mining',
  },
  {
    id: '213115',
    name: '213115 - Support activities for nonmetallic minerals (except fuels) mining',
  },
  { id: '22', name: '22 - Utilities' },
  { id: '221', name: '221 - Utilities' },
  {
    id: '2211',
    name: '2211 - Electric power generation, transmission and distribution',
  },
  {
    id: '22111',
    name: '22111 - Electric power generation',
  },
  {
    id: '221111',
    name: '221111 - Hydroelectric power generation',
  },
  {
    id: '221112',
    name: '221112 - Fossil fuel electric power generation',
  },
  {
    id: '221113',
    name: '221113 - Nuclear electric power generation',
  },
  {
    id: '221114',
    name: '221114 - Solar electric power generation',
  },
  {
    id: '221115',
    name: '221115 - Wind electric power generation',
  },
  {
    id: '221116',
    name: '221116 - Geothermal electric power generation',
  },
  {
    id: '221117',
    name: '221117 - Biomass electric power generation',
  },
  {
    id: '221118',
    name: '221118 - Other electric power generation',
  },
  {
    id: '22112',
    name: '22112 - Electric power transmission, control, and distribution',
  },
  {
    id: '221121',
    name: '221121 - Electric bulk power transmission and control',
  },
  {
    id: '221122',
    name: '221122 - Electric power distribution',
  },
  {
    id: '2212',
    name: '2212 - Natural gas distribution',
  },
  {
    id: '22121',
    name: '22121 - Natural gas distribution',
  },
  {
    id: '221210',
    name: '221210 - Natural gas distribution',
  },
  {
    id: '2213',
    name: '2213 - Water, sewage and other systems',
  },
  {
    id: '22131',
    name: '22131 - Water supply and irrigation systems',
  },
  {
    id: '221310',
    name: '221310 - Water supply and irrigation systems',
  },
  {
    id: '22132',
    name: '22132 - Sewage treatment facilities',
  },
  {
    id: '221320',
    name: '221320 - Sewage treatment facilities',
  },
  {
    id: '22133',
    name: '22133 - Steam and air-conditioning supply',
  },
  {
    id: '221330',
    name: '221330 - Steam and air-conditioning supply',
  },
  { id: '23', name: '23 - Construction' },
  {
    id: '236',
    name: '236 - Construction of buildings',
  },
  {
    id: '2361',
    name: '2361 - Residential building construction',
  },
  {
    id: '23611',
    name: '23611 - Residential building construction',
  },
  {
    id: '236115',
    name: '236115 - New single-family housing construction (except for-sale builders)',
  },
  {
    id: '236116',
    name: '236116 - New multifamily housing construction (except for-sale builders)',
  },
  {
    id: '236117',
    name: '236117 - New housing for-sale builders',
  },
  {
    id: '236118',
    name: '236118 - Residential remodelers',
  },
  {
    id: '2362',
    name: '2362 - Nonresidential building construction',
  },
  {
    id: '23621',
    name: '23621 - Industrial building construction',
  },
  {
    id: '236210',
    name: '236210 - Industrial building construction',
  },
  {
    id: '23622',
    name: '23622 - Commercial and institutional building construction',
  },
  {
    id: '236220',
    name: '236220 - Commercial and institutional building construction',
  },
  {
    id: '237',
    name: '237 - Heavy and civil engineering construction',
  },
  {
    id: '2371',
    name: '2371 - Utility system construction',
  },
  {
    id: '23711',
    name: '23711 - Water and sewer line and related structures construction',
  },
  {
    id: '237110',
    name: '237110 - Water and sewer line and related structures construction',
  },
  {
    id: '23712',
    name: '23712 - Oil and gas pipeline and related structures construction',
  },
  {
    id: '237120',
    name: '237120 - Oil and gas pipeline and related structures construction',
  },
  {
    id: '23713',
    name: '23713 - Power and communication line and related structures construction',
  },
  {
    id: '237130',
    name: '237130 - Power and communication line and related structures construction',
  },
  {
    id: '2372',
    name: '2372 - Land subdivision',
  },
  {
    id: '23721',
    name: '23721 - Land subdivision',
  },
  {
    id: '237210',
    name: '237210 - Land subdivision',
  },
  {
    id: '2373',
    name: '2373 - Highway, street, and bridge construction',
  },
  {
    id: '23731',
    name: '23731 - Highway, street, and bridge construction',
  },
  {
    id: '237310',
    name: '237310 - Highway, street, and bridge construction',
  },
  {
    id: '2379',
    name: '2379 - Other heavy and civil engineering construction',
  },
  {
    id: '23799',
    name: '23799 - Other heavy and civil engineering construction',
  },
  {
    id: '237990',
    name: '237990 - Other heavy and civil engineering construction',
  },
  {
    id: '238',
    name: '238 - Specialty trade contractors',
  },
  {
    id: '2381',
    name: '2381 - Foundation, structure, and building exterior contractors',
  },
  {
    id: '23811',
    name: '23811 - Poured concrete foundation and structure contractors',
  },
  {
    id: '238110',
    name: '238110 - Poured concrete foundation and structure contractors',
  },
  {
    id: '23812',
    name: '23812 - Structural steel and precast concrete contractors',
  },
  {
    id: '238120',
    name: '238120 - Structural steel and precast concrete contractors',
  },
  {
    id: '23813',
    name: '23813 - Framing contractors',
  },
  {
    id: '238130',
    name: '238130 - Framing contractors',
  },
  {
    id: '23814',
    name: '23814 - Masonry contractors',
  },
  {
    id: '238140',
    name: '238140 - Masonry contractors',
  },
  {
    id: '23815',
    name: '23815 - Glass and glazing contractors',
  },
  {
    id: '238150',
    name: '238150 - Glass and glazing contractors',
  },
  {
    id: '23816',
    name: '23816 - Roofing contractors',
  },
  {
    id: '238160',
    name: '238160 - Roofing contractors',
  },
  {
    id: '23817',
    name: '23817 - Siding contractors',
  },
  {
    id: '238170',
    name: '238170 - Siding contractors',
  },
  {
    id: '23819',
    name: '23819 - Other foundation, structure, and building exterior contractors',
  },
  {
    id: '238190',
    name: '238190 - Other foundation, structure, and building exterior contractors',
  },
  {
    id: '2382',
    name: '2382 - Building equipment contractors',
  },
  {
    id: '23821',
    name: '23821 - Electrical contractors and other wiring installation contractors',
  },
  {
    id: '238210',
    name: '238210 - Electrical contractors and other wiring installation contractors',
  },
  {
    id: '23822',
    name: '23822 - Plumbing, heating, and air-conditioning contractors',
  },
  {
    id: '238220',
    name: '238220 - Plumbing, heating, and air-conditioning contractors',
  },
  {
    id: '23829',
    name: '23829 - Other building equipment contractors',
  },
  {
    id: '238290',
    name: '238290 - Other building equipment contractors',
  },
  {
    id: '2383',
    name: '2383 - Building finishing contractors',
  },
  {
    id: '23831',
    name: '23831 - Drywall and insulation contractors',
  },
  {
    id: '238310',
    name: '238310 - Drywall and insulation contractors',
  },
  {
    id: '23832',
    name: '23832 - Painting and wall covering contractors',
  },
  {
    id: '238320',
    name: '238320 - Painting and wall covering contractors',
  },
  {
    id: '23833',
    name: '23833 - Flooring contractors',
  },
  {
    id: '238330',
    name: '238330 - Flooring contractors',
  },
  {
    id: '23834',
    name: '23834 - Tile and terrazzo contractors',
  },
  {
    id: '238340',
    name: '238340 - Tile and terrazzo contractors',
  },
  {
    id: '23835',
    name: '23835 - Finish carpentry contractors',
  },
  {
    id: '238350',
    name: '238350 - Finish carpentry contractors',
  },
  {
    id: '23839',
    name: '23839 - Other building finishing contractors',
  },
  {
    id: '238390',
    name: '238390 - Other building finishing contractors',
  },
  {
    id: '2389',
    name: '2389 - Other specialty trade contractors',
  },
  {
    id: '23891',
    name: '23891 - Site preparation contractors',
  },
  {
    id: '238910',
    name: '238910 - Site preparation contractors',
  },
  {
    id: '23899',
    name: '23899 - All other specialty trade contractors',
  },
  {
    id: '238990',
    name: '238990 - All other specialty trade contractors',
  },
  { id: '31', name: '31 - Manufacturing' },
  { id: '311', name: '311 - Food manufacturing' },
  {
    id: '3111',
    name: '3111 - Animal food manufacturing',
  },
  {
    id: '31111',
    name: '31111 - Animal food manufacturing',
  },
  {
    id: '311111',
    name: '311111 - Dog and cat food manufacturing',
  },
  {
    id: '311119',
    name: '311119 - Other animal food manufacturing',
  },
  {
    id: '3112',
    name: '3112 - Grain and oilseed milling',
  },
  {
    id: '31121',
    name: '31121 - Flour milling and malt manufacturing',
  },
  {
    id: '311211',
    name: '311211 - Flour milling',
  },
  {
    id: '311212',
    name: '311212 - Rice milling',
  },
  {
    id: '311213',
    name: '311213 - Malt manufacturing',
  },
  {
    id: '31122',
    name: '31122 - Starch and vegetable fats and oils manufacturing',
  },
  {
    id: '311221',
    name: '311221 - Wet corn milling',
  },
  {
    id: '311224',
    name: '311224 - Soybean and other oilseed processing',
  },
  {
    id: '311225',
    name: '311225 - Fats and oils refining and blending',
  },
  {
    id: '31123',
    name: '31123 - Breakfast cereal manufacturing',
  },
  {
    id: '311230',
    name: '311230 - Breakfast cereal manufacturing',
  },
  {
    id: '3113',
    name: '3113 - Sugar and confectionery product manufacturing',
  },
  {
    id: '31131',
    name: '31131 - Sugar manufacturing',
  },
  {
    id: '311313',
    name: '311313 - Beet sugar manufacturing',
  },
  {
    id: '311314',
    name: '311314 - Cane sugar manufacturing',
  },
  {
    id: '31134',
    name: '31134 - Nonchocolate confectionery manufacturing',
  },
  {
    id: '311340',
    name: '311340 - Nonchocolate confectionery manufacturing',
  },
  {
    id: '31135',
    name: '31135 - Chocolate and confectionery manufacturing',
  },
  {
    id: '311351',
    name: '311351 - Chocolate and confectionery manufacturing from cacao beans',
  },
  {
    id: '311352',
    name: '311352 - Confectionery manufacturing from purchased chocolate',
  },
  {
    id: '3114',
    name: '3114 - Fruit and vegetable preserving and specialty food manufacturing',
  },
  {
    id: '31141',
    name: '31141 - Frozen food manufacturing',
  },
  {
    id: '311411',
    name: '311411 - Frozen fruit, juice, and vegetable manufacturing',
  },
  {
    id: '311412',
    name: '311412 - Frozen specialty food manufacturing',
  },
  {
    id: '31142',
    name: '31142 - Fruit and vegetable canning, pickling, and drying',
  },
  {
    id: '311421',
    name: '311421 - Fruit and vegetable canning',
  },
  {
    id: '311422',
    name: '311422 - Specialty canning',
  },
  {
    id: '311423',
    name: '311423 - Dried and dehydrated food manufacturing',
  },
  {
    id: '3115',
    name: '3115 - Dairy product manufacturing',
  },
  {
    id: '31151',
    name: '31151 - Dairy product (except frozen) manufacturing',
  },
  {
    id: '311511',
    name: '311511 - Fluid milk manufacturing',
  },
  {
    id: '311512',
    name: '311512 - Creamery butter manufacturing',
  },
  {
    id: '311513',
    name: '311513 - Cheese manufacturing',
  },
  {
    id: '311514',
    name: '311514 - Dry, condensed, and evaporated dairy product manufacturing',
  },
  {
    id: '31152',
    name: '31152 - Ice cream and frozen dessert manufacturing',
  },
  {
    id: '311520',
    name: '311520 - Ice cream and frozen dessert manufacturing',
  },
  {
    id: '3116',
    name: '3116 - Animal slaughtering and processing',
  },
  {
    id: '31161',
    name: '31161 - Animal slaughtering and processing',
  },
  {
    id: '311611',
    name: '311611 - Animal (except poultry) slaughtering',
  },
  {
    id: '311612',
    name: '311612 - Meat processed from carcasses',
  },
  {
    id: '311613',
    name: '311613 - Rendering and meat byproduct processing',
  },
  {
    id: '311615',
    name: '311615 - Poultry processing',
  },
  {
    id: '3117',
    name: '3117 - Seafood product preparation and packaging',
  },
  {
    id: '31171',
    name: '31171 - Seafood product preparation and packaging',
  },
  {
    id: '311710',
    name: '311710 - Seafood product preparation and packaging',
  },
  {
    id: '3118',
    name: '3118 - Bakeries and tortilla manufacturing',
  },
  {
    id: '31181',
    name: '31181 - Bread and bakery product manufacturing',
  },
  {
    id: '311811',
    name: '311811 - Retail bakeries',
  },
  {
    id: '311812',
    name: '311812 - Commercial bakeries',
  },
  {
    id: '311813',
    name: '311813 - Frozen cakes, pies, and other pastries manufacturing',
  },
  {
    id: '31182',
    name: '31182 - Cookie, cracker, and pasta manufacturing',
  },
  {
    id: '311821',
    name: '311821 - Cookie and cracker manufacturing',
  },
  {
    id: '311824',
    name: '311824 - Dry pasta, dough, and flour mixes manufacturing from purchased flour',
  },
  {
    id: '31183',
    name: '31183 - Tortilla manufacturing',
  },
  {
    id: '311830',
    name: '311830 - Tortilla manufacturing',
  },
  {
    id: '3119',
    name: '3119 - Other food manufacturing',
  },
  {
    id: '31191',
    name: '31191 - Snack food manufacturing',
  },
  {
    id: '311911',
    name: '311911 - Roasted nuts and peanut butter manufacturing',
  },
  {
    id: '311919',
    name: '311919 - Other snack food manufacturing',
  },
  {
    id: '31192',
    name: '31192 - Coffee and tea manufacturing',
  },
  {
    id: '311920',
    name: '311920 - Coffee and tea manufacturing',
  },
  {
    id: '31193',
    name: '31193 - Flavoring syrup and concentrate manufacturing',
  },
  {
    id: '311930',
    name: '311930 - Flavoring syrup and concentrate manufacturing',
  },
  {
    id: '31194',
    name: '31194 - Seasoning and dressing manufacturing',
  },
  {
    id: '311941',
    name: '311941 - Mayonnaise, dressing, and other prepared sauce manufacturing',
  },
  {
    id: '311942',
    name: '311942 - Spice and extract manufacturing',
  },
  {
    id: '31199',
    name: '31199 - All other food manufacturing',
  },
  {
    id: '311991',
    name: '311991 - Perishable prepared food manufacturing',
  },
  {
    id: '311999',
    name: '311999 - All other miscellaneous food manufacturing',
  },
  {
    id: '312',
    name: '312 - Beverage and tobacco product manufacturing',
  },
  {
    id: '3121',
    name: '3121 - Beverage manufacturing',
  },
  {
    id: '31211',
    name: '31211 - Soft drink and ice manufacturing',
  },
  {
    id: '312111',
    name: '312111 - Soft drink manufacturing',
  },
  {
    id: '312112',
    name: '312112 - Bottled water manufacturing',
  },
  {
    id: '312113',
    name: '312113 - Ice manufacturing',
  },
  {
    id: '31212',
    name: '31212 - Breweries',
  },
  {
    id: '312120',
    name: '312120 - Breweries',
  },
  {
    id: '31213',
    name: '31213 - Wineries',
  },
  {
    id: '312130',
    name: '312130 - Wineries',
  },
  {
    id: '31214',
    name: '31214 - Distilleries',
  },
  {
    id: '312140',
    name: '312140 - Distilleries',
  },
  {
    id: '3122',
    name: '3122 - Tobacco manufacturing',
  },
  {
    id: '31223',
    name: '31223 - Tobacco manufacturing',
  },
  {
    id: '312230',
    name: '312230 - Tobacco manufacturing',
  },
  { id: '313', name: '313 - Textile mills' },
  {
    id: '3131',
    name: '3131 - Fiber, yarn, and thread mills',
  },
  {
    id: '31311',
    name: '31311 - Fiber, yarn, and thread mills',
  },
  {
    id: '313110',
    name: '313110 - Fiber, yarn, and thread mills',
  },
  {
    id: '3132',
    name: '3132 - Fabric mills',
  },
  {
    id: '31321',
    name: '31321 - Broadwoven fabric mills',
  },
  {
    id: '313210',
    name: '313210 - Broadwoven fabric mills',
  },
  {
    id: '31322',
    name: '31322 - Narrow fabric mills and Schiffli machine embroidery',
  },
  {
    id: '313220',
    name: '313220 - Narrow fabric mills and Schiffli machine embroidery',
  },
  {
    id: '31323',
    name: '31323 - Nonwoven fabric mills',
  },
  {
    id: '313230',
    name: '313230 - Nonwoven fabric mills',
  },
  {
    id: '31324',
    name: '31324 - Knit fabric mills',
  },
  {
    id: '313240',
    name: '313240 - Knit fabric mills',
  },
  {
    id: '3133',
    name: '3133 - Textile and fabric finishing and fabric coating mills',
  },
  {
    id: '31331',
    name: '31331 - Textile and fabric finishing mills',
  },
  {
    id: '313310',
    name: '313310 - Textile and fabric finishing mills',
  },
  {
    id: '31332',
    name: '31332 - Fabric coating mills',
  },
  {
    id: '313320',
    name: '313320 - Fabric coating mills',
  },
  {
    id: '314',
    name: '314 - Textile product mills',
  },
  {
    id: '3141',
    name: '3141 - Textile furnishings mills',
  },
  {
    id: '31411',
    name: '31411 - Carpet and rug mills',
  },
  {
    id: '314110',
    name: '314110 - Carpet and rug mills',
  },
  {
    id: '31412',
    name: '31412 - Curtain and linen mills',
  },
  {
    id: '314120',
    name: '314120 - Curtain and linen mills',
  },
  {
    id: '3149',
    name: '3149 - Other textile product mills',
  },
  {
    id: '31491',
    name: '31491 - Textile bag and canvas mills',
  },
  {
    id: '314910',
    name: '314910 - Textile bag and canvas mills',
  },
  {
    id: '31499',
    name: '31499 - All other textile product mills',
  },
  {
    id: '314994',
    name: '314994 - Rope, cordage, twine, tire cord, and tire fabric mills',
  },
  {
    id: '314999',
    name: '314999 - All other miscellaneous textile product mills',
  },
  {
    id: '315',
    name: '315 - Apparel manufacturing',
  },
  {
    id: '3151',
    name: '3151 - Apparel knitting mills',
  },
  {
    id: '31511',
    name: '31511 - Hosiery and sock mills',
  },
  {
    id: '315110',
    name: '315110 - Hosiery and sock mills',
  },
  {
    id: '31519',
    name: '31519 - Other apparel knitting mills',
  },
  {
    id: '315190',
    name: '315190 - Other apparel knitting mills',
  },
  {
    id: '3152',
    name: '3152 - Cut and sew apparel manufacturing',
  },
  {
    id: '31521',
    name: '31521 - Cut and sew apparel contractors',
  },
  {
    id: '315210',
    name: '315210 - Cut and sew apparel contractors',
  },
  {
    id: '31522',
    name: "            31522 - Men's and boys' cut and sew apparel manufacturing",
  },
  {
    id: '315220',
    name: "                315220 - Men's and boys' cut and sew apparel manufacturing",
  },
  {
    id: '31524',
    name: "            31524 - Women's, girls', and infants' cut and sew apparel manufacturing",
  },
  {
    id: '315240',
    name: "                315240 - Women's, girls', and infants' cut and sew apparel manufacturing",
  },
  {
    id: '31528',
    name: '31528 - Other cut and sew apparel manufacturing',
  },
  {
    id: '315280',
    name: '315280 - Other cut and sew apparel manufacturing',
  },
  {
    id: '3159',
    name: '3159 - Apparel accessories and other apparel manufacturing',
  },
  {
    id: '31599',
    name: '31599 - Apparel accessories and other apparel manufacturing',
  },
  {
    id: '315990',
    name: '315990 - Apparel accessories and other apparel manufacturing',
  },
  {
    id: '316',
    name: '316 - Leather and allied product manufacturing',
  },
  {
    id: '3161',
    name: '3161 - Leather and hide tanning and finishing',
  },
  {
    id: '31611',
    name: '31611 - Leather and hide tanning and finishing',
  },
  {
    id: '316110',
    name: '316110 - Leather and hide tanning and finishing',
  },
  {
    id: '3162',
    name: '3162 - Footwear manufacturing',
  },
  {
    id: '31621',
    name: '31621 - Footwear manufacturing',
  },
  {
    id: '316210',
    name: '316210 - Footwear manufacturing',
  },
  {
    id: '3169',
    name: '3169 - Other leather and allied product manufacturing',
  },
  {
    id: '31699',
    name: '31699 - Other leather and allied product manufacturing',
  },
  {
    id: '316992',
    name: "                316992 - Women's handbag and purse manufacturing",
  },
  {
    id: '316998',
    name: '316998 - All other leather good and allied product manufacturing',
  },
  { id: '32', name: '32 - Manufacturing' },
  {
    id: '321',
    name: '321 - Wood product manufacturing',
  },
  {
    id: '3211',
    name: '3211 - Sawmills and wood preservation',
  },
  {
    id: '32111',
    name: '32111 - Sawmills and wood preservation',
  },
  {
    id: '321113',
    name: '321113 - Sawmills',
  },
  {
    id: '321114',
    name: '321114 - Wood preservation',
  },
  {
    id: '3212',
    name: '3212 - Veneer, plywood, and engineered wood product manufacturing',
  },
  {
    id: '32121',
    name: '32121 - Veneer, plywood, and engineered wood product manufacturing',
  },
  {
    id: '321211',
    name: '321211 - Hardwood veneer and plywood manufacturing',
  },
  {
    id: '321212',
    name: '321212 - Softwood veneer and plywood manufacturing',
  },
  {
    id: '321213',
    name: '321213 - Engineered wood member (except truss) manufacturing',
  },
  {
    id: '321214',
    name: '321214 - Truss manufacturing',
  },
  {
    id: '321219',
    name: '321219 - Reconstituted wood product manufacturing',
  },
  {
    id: '3219',
    name: '3219 - Other wood product manufacturing',
  },
  {
    id: '32191',
    name: '32191 - Millwork',
  },
  {
    id: '321911',
    name: '321911 - Wood window and door manufacturing',
  },
  {
    id: '321912',
    name: '321912 - Cut stock, resawing lumber, and planing',
  },
  {
    id: '321918',
    name: '321918 - Other millwork (including flooring)',
  },
  {
    id: '32192',
    name: '32192 - Wood container and pallet manufacturing',
  },
  {
    id: '321920',
    name: '321920 - Wood container and pallet manufacturing',
  },
  {
    id: '32199',
    name: '32199 - All other wood product manufacturing',
  },
  {
    id: '321991',
    name: '321991 - Manufactured home (mobile home) manufacturing',
  },
  {
    id: '321992',
    name: '321992 - Prefabricated wood building manufacturing',
  },
  {
    id: '321999',
    name: '321999 - All other miscellaneous wood product manufacturing',
  },
  {
    id: '322',
    name: '322 - Paper manufacturing',
  },
  {
    id: '3221',
    name: '3221 - Pulp, paper, and paperboard mills',
  },
  {
    id: '32211',
    name: '32211 - Pulp mills',
  },
  {
    id: '322110',
    name: '322110 - Pulp mills',
  },
  {
    id: '32212',
    name: '32212 - Paper mills',
  },
  {
    id: '322121',
    name: '322121 - Paper (except newsprint) mills',
  },
  {
    id: '322122',
    name: '322122 - Newsprint mills',
  },
  {
    id: '32213',
    name: '32213 - Paperboard mills',
  },
  {
    id: '322130',
    name: '322130 - Paperboard mills',
  },
  {
    id: '3222',
    name: '3222 - Converted paper product manufacturing',
  },
  {
    id: '32221',
    name: '32221 - Paperboard container manufacturing',
  },
  {
    id: '322211',
    name: '322211 - Corrugated and solid fiber box manufacturing',
  },
  {
    id: '322212',
    name: '322212 - Folding paperboard box manufacturing',
  },
  {
    id: '322219',
    name: '322219 - Other paperboard container manufacturing',
  },
  {
    id: '32222',
    name: '32222 - Paper bag and coated and treated paper manufacturing',
  },
  {
    id: '322220',
    name: '322220 - Paper bag and coated and treated paper manufacturing',
  },
  {
    id: '32223',
    name: '32223 - Stationery product manufacturing',
  },
  {
    id: '322230',
    name: '322230 - Stationery product manufacturing',
  },
  {
    id: '32229',
    name: '32229 - Other converted paper product manufacturing',
  },
  {
    id: '322291',
    name: '322291 - Sanitary paper product manufacturing',
  },
  {
    id: '322299',
    name: '322299 - All other converted paper product manufacturing',
  },
  {
    id: '323',
    name: '323 - Printing and related support activities',
  },
  {
    id: '3231',
    name: '3231 - Printing and related support activities',
  },
  {
    id: '32311',
    name: '32311 - Printing',
  },
  {
    id: '323111',
    name: '323111 - Commercial printing (except screen and books)',
  },
  {
    id: '323113',
    name: '323113 - Commercial screen printing',
  },
  {
    id: '323117',
    name: '323117 - Books printing',
  },
  {
    id: '32312',
    name: '32312 - Support activities for printing',
  },
  {
    id: '323120',
    name: '323120 - Support activities for printing',
  },
  {
    id: '324',
    name: '324 - Petroleum and coal products manufacturing',
  },
  {
    id: '3241',
    name: '3241 - Petroleum and coal products manufacturing',
  },
  {
    id: '32411',
    name: '32411 - Petroleum refineries',
  },
  {
    id: '324110',
    name: '324110 - Petroleum refineries',
  },
  {
    id: '32412',
    name: '32412 - Asphalt paving, roofing, and saturated materials manufacturing',
  },
  {
    id: '324121',
    name: '324121 - Asphalt paving mixture and block manufacturing',
  },
  {
    id: '324122',
    name: '324122 - Asphalt shingle and coating materials manufacturing',
  },
  {
    id: '32419',
    name: '32419 - Other petroleum and coal products manufacturing',
  },
  {
    id: '324191',
    name: '324191 - Petroleum lubricating oil and grease manufacturing',
  },
  {
    id: '324199',
    name: '324199 - All other petroleum and coal products manufacturing',
  },
  {
    id: '325',
    name: '325 - Chemical manufacturing',
  },
  {
    id: '3251',
    name: '3251 - Basic chemical manufacturing',
  },
  {
    id: '32511',
    name: '32511 - Petrochemical manufacturing',
  },
  {
    id: '325110',
    name: '325110 - Petrochemical manufacturing',
  },
  {
    id: '32512',
    name: '32512 - Industrial gas manufacturing',
  },
  {
    id: '325120',
    name: '325120 - Industrial gas manufacturing',
  },
  {
    id: '32513',
    name: '32513 - Synthetic dye and pigment manufacturing',
  },
  {
    id: '325130',
    name: '325130 - Synthetic dye and pigment manufacturing',
  },
  {
    id: '32518',
    name: '32518 - Other basic inorganic chemical manufacturing',
  },
  {
    id: '325180',
    name: '325180 - Other basic inorganic chemical manufacturing',
  },
  {
    id: '32519',
    name: '32519 - Other basic organic chemical manufacturing',
  },
  {
    id: '325193',
    name: '325193 - Ethyl alcohol manufacturing',
  },
  {
    id: '325194',
    name: '325194 - Cyclic crude, intermediate, and gum and wood chemical manufacturing',
  },
  {
    id: '325199',
    name: '325199 - All other basic organic chemical manufacturing',
  },
  {
    id: '3252',
    name: '3252 - Resin, synthetic rubber, and artificial synthetic fibers and filaments manufacturing',
  },
  {
    id: '32521',
    name: '32521 - Resin and synthetic rubber manufacturing',
  },
  {
    id: '325211',
    name: '325211 - Plastics material and resin manufacturing',
  },
  {
    id: '325212',
    name: '325212 - Synthetic rubber manufacturing',
  },
  {
    id: '32522',
    name: '32522 - Artificial and synthetic fibers and filaments manufacturing',
  },
  {
    id: '325220',
    name: '325220 - Artificial and synthetic fibers and filaments manufacturing',
  },
  {
    id: '3253',
    name: '3253 - Pesticide, fertilizer, and other agricultural chemical manufacturing',
  },
  {
    id: '32531',
    name: '32531 - Fertilizer manufacturing',
  },
  {
    id: '325311',
    name: '325311 - Nitrogenous fertilizer manufacturing',
  },
  {
    id: '325312',
    name: '325312 - Phosphatic fertilizer manufacturing',
  },
  {
    id: '325314',
    name: '325314 - Fertilizer (mixing only) manufacturing',
  },
  {
    id: '32532',
    name: '32532 - Pesticide and other agricultural chemical manufacturing',
  },
  {
    id: '325320',
    name: '325320 - Pesticide and other agricultural chemical manufacturing',
  },
  {
    id: '3254',
    name: '3254 - Pharmaceutical and medicine manufacturing',
  },
  {
    id: '32541',
    name: '32541 - Pharmaceutical and medicine manufacturing',
  },
  {
    id: '325411',
    name: '325411 - Medicinal and botanical manufacturing',
  },
  {
    id: '325412',
    name: '325412 - Pharmaceutical preparation manufacturing',
  },
  {
    id: '325413',
    name: '325413 - In-vitro diagnostic substance manufacturing',
  },
  {
    id: '325414',
    name: '325414 - Biological product (except diagnostic) manufacturing',
  },
  {
    id: '3255',
    name: '3255 - Paint, coating, and adhesive manufacturing',
  },
  {
    id: '32551',
    name: '32551 - Paint and coating manufacturing',
  },
  {
    id: '325510',
    name: '325510 - Paint and coating manufacturing',
  },
  {
    id: '32552',
    name: '32552 - Adhesive manufacturing',
  },
  {
    id: '325520',
    name: '325520 - Adhesive manufacturing',
  },
  {
    id: '3256',
    name: '3256 - Soap, cleaning compound, and toilet preparation manufacturing',
  },
  {
    id: '32561',
    name: '32561 - Soap and cleaning compound manufacturing',
  },
  {
    id: '325611',
    name: '325611 - Soap and other detergent manufacturing',
  },
  {
    id: '325612',
    name: '325612 - Polish and other sanitation good manufacturing',
  },
  {
    id: '325613',
    name: '325613 - Surface active agent manufacturing',
  },
  {
    id: '32562',
    name: '32562 - Toilet preparation manufacturing',
  },
  {
    id: '325620',
    name: '325620 - Toilet preparation manufacturing',
  },
  {
    id: '3259',
    name: '3259 - Other chemical product and preparation manufacturing',
  },
  {
    id: '32591',
    name: '32591 - Printing ink manufacturing',
  },
  {
    id: '325910',
    name: '325910 - Printing ink manufacturing',
  },
  {
    id: '32592',
    name: '32592 - Explosives manufacturing',
  },
  {
    id: '325920',
    name: '325920 - Explosives manufacturing',
  },
  {
    id: '32599',
    name: '32599 - All other chemical product and preparation manufacturing',
  },
  {
    id: '325991',
    name: '325991 - Custom compounding of purchased resins',
  },
  {
    id: '325992',
    name: '325992 - Photographic film, paper, plate, and chemical manufacturing',
  },
  {
    id: '325998',
    name: '325998 - All other miscellaneous chemical product and preparation manufacturing',
  },
  {
    id: '326',
    name: '326 - Plastics and rubber products manufacturing',
  },
  {
    id: '3261',
    name: '3261 - Plastics product manufacturing',
  },
  {
    id: '32611',
    name: '32611 - Plastics packaging materials and unlaminated film and sheet manufacturing',
  },
  {
    id: '326111',
    name: '326111 - Plastics bag and pouch manufacturing',
  },
  {
    id: '326112',
    name: '326112 - Plastics packaging film and sheet (including laminated) manufacturing',
  },
  {
    id: '326113',
    name: '326113 - Unlaminated plastics film and sheet (except packaging) manufacturing',
  },
  {
    id: '32612',
    name: '32612 - Plastics pipe, pipe fitting, and unlaminated profile shape manufacturing',
  },
  {
    id: '326121',
    name: '326121 - Unlaminated plastics profile shape manufacturing',
  },
  {
    id: '326122',
    name: '326122 - Plastics pipe and pipe fitting manufacturing',
  },
  {
    id: '32613',
    name: '32613 - Laminated plastics plate, sheet (except packaging), and shape manufacturing',
  },
  {
    id: '326130',
    name: '326130 - Laminated plastics plate, sheet (except packaging), and shape manufacturing',
  },
  {
    id: '32614',
    name: '32614 - Polystyrene foam product manufacturing',
  },
  {
    id: '326140',
    name: '326140 - Polystyrene foam product manufacturing',
  },
  {
    id: '32615',
    name: '32615 - Urethane and other foam product (except polystyrene) manufacturing',
  },
  {
    id: '326150',
    name: '326150 - Urethane and other foam product (except polystyrene) manufacturing',
  },
  {
    id: '32616',
    name: '32616 - Plastics bottle manufacturing',
  },
  {
    id: '326160',
    name: '326160 - Plastics bottle manufacturing',
  },
  {
    id: '32619',
    name: '32619 - Other plastics product manufacturing',
  },
  {
    id: '326191',
    name: '326191 - Plastics plumbing fixture manufacturing',
  },
  {
    id: '326199',
    name: '326199 - All other plastics product manufacturing',
  },
  {
    id: '3262',
    name: '3262 - Rubber product manufacturing',
  },
  {
    id: '32621',
    name: '32621 - Tire manufacturing',
  },
  {
    id: '326211',
    name: '326211 - Tire manufacturing (except retreading)',
  },
  {
    id: '326212',
    name: '326212 - Tire retreading',
  },
  {
    id: '32622',
    name: '32622 - Rubber and plastics hoses and belting manufacturing',
  },
  {
    id: '326220',
    name: '326220 - Rubber and plastics hoses and belting manufacturing',
  },
  {
    id: '32629',
    name: '32629 - Other rubber product manufacturing',
  },
  {
    id: '326291',
    name: '326291 - Rubber product manufacturing for mechanical use',
  },
  {
    id: '326299',
    name: '326299 - All other rubber product manufacturing',
  },
  {
    id: '327',
    name: '327 - Nonmetallic mineral product manufacturing',
  },
  {
    id: '3271',
    name: '3271 - Clay product and refractory manufacturing',
  },
  {
    id: '32711',
    name: '32711 - Pottery, ceramics, and plumbing fixture manufacturing',
  },
  {
    id: '327110',
    name: '327110 - Pottery, ceramics, and plumbing fixture manufacturing',
  },
  {
    id: '32712',
    name: '32712 - Clay building material and refractories manufacturing',
  },
  {
    id: '327120',
    name: '327120 - Clay building material and refractories manufacturing',
  },
  {
    id: '3272',
    name: '3272 - Glass and glass product manufacturing',
  },
  {
    id: '32721',
    name: '32721 - Glass and glass product manufacturing',
  },
  {
    id: '327211',
    name: '327211 - Flat glass manufacturing',
  },
  {
    id: '327212',
    name: '327212 - Other pressed and blown glass and glassware manufacturing',
  },
  {
    id: '327213',
    name: '327213 - Glass container manufacturing',
  },
  {
    id: '327215',
    name: '327215 - Glass product manufacturing made of purchased glass',
  },
  {
    id: '3273',
    name: '3273 - Cement and concrete product manufacturing',
  },
  {
    id: '32731',
    name: '32731 - Cement manufacturing',
  },
  {
    id: '327310',
    name: '327310 - Cement manufacturing',
  },
  {
    id: '32732',
    name: '32732 - Ready-mix concrete manufacturing',
  },
  {
    id: '327320',
    name: '327320 - Ready-mix concrete manufacturing',
  },
  {
    id: '32733',
    name: '32733 - Concrete pipe, brick, and block manufacturing',
  },
  {
    id: '327331',
    name: '327331 - Concrete block and brick manufacturing',
  },
  {
    id: '327332',
    name: '327332 - Concrete pipe manufacturing',
  },
  {
    id: '32739',
    name: '32739 - Other concrete product manufacturing',
  },
  {
    id: '327390',
    name: '327390 - Other concrete product manufacturing',
  },
  {
    id: '3274',
    name: '3274 - Lime and gypsum product manufacturing',
  },
  {
    id: '32741',
    name: '32741 - Lime manufacturing',
  },
  {
    id: '327410',
    name: '327410 - Lime manufacturing',
  },
  {
    id: '32742',
    name: '32742 - Gypsum product manufacturing',
  },
  {
    id: '327420',
    name: '327420 - Gypsum product manufacturing',
  },
  {
    id: '3279',
    name: '3279 - Other nonmetallic mineral product manufacturing',
  },
  {
    id: '32791',
    name: '32791 - Abrasive product manufacturing',
  },
  {
    id: '327910',
    name: '327910 - Abrasive product manufacturing',
  },
  {
    id: '32799',
    name: '32799 - All other nonmetallic mineral product manufacturing',
  },
  {
    id: '327991',
    name: '327991 - Cut stone and stone product manufacturing',
  },
  {
    id: '327992',
    name: '327992 - Ground or treated mineral and earth manufacturing',
  },
  {
    id: '327993',
    name: '327993 - Mineral wool manufacturing',
  },
  {
    id: '327999',
    name: '327999 - All other miscellaneous nonmetallic mineral product manufacturing',
  },
  { id: '33', name: '33 - Manufacturing' },
  {
    id: '331',
    name: '331 - Primary metal manufacturing',
  },
  {
    id: '3311',
    name: '3311 - Iron and steel mills and ferroalloy manufacturing',
  },
  {
    id: '33111',
    name: '33111 - Iron and steel mills and ferroalloy manufacturing',
  },
  {
    id: '331110',
    name: '331110 - Iron and steel mills and ferroalloy manufacturing',
  },
  {
    id: '3312',
    name: '3312 - Steel product manufacturing from purchased steel',
  },
  {
    id: '33121',
    name: '33121 - Iron and steel pipe and tube manufacturing from purchased steel',
  },
  {
    id: '331210',
    name: '331210 - Iron and steel pipe and tube manufacturing from purchased steel',
  },
  {
    id: '33122',
    name: '33122 - Rolling and drawing of purchased steel',
  },
  {
    id: '331221',
    name: '331221 - Rolled steel shape manufacturing',
  },
  {
    id: '331222',
    name: '331222 - Steel wire drawing',
  },
  {
    id: '3313',
    name: '3313 - Alumina and aluminum production and processing',
  },
  {
    id: '33131',
    name: '33131 - Alumina and aluminum production and processing',
  },
  {
    id: '331313',
    name: '331313 - Alumina refining and primary aluminum production',
  },
  {
    id: '331314',
    name: '331314 - Secondary smelting and alloying of aluminum',
  },
  {
    id: '331315',
    name: '331315 - Aluminum sheet, plate, and foil manufacturing',
  },
  {
    id: '331318',
    name: '331318 - Other aluminum rolling, drawing, and extruding',
  },
  {
    id: '3314',
    name: '3314 - Nonferrous metal (except aluminum) production and processing',
  },
  {
    id: '33141',
    name: '33141 - Nonferrous metal (except aluminum) smelting and fefining',
  },
  {
    id: '331410',
    name: '331410 - Nonferrous metal (except aluminum) smelting and refining',
  },
  {
    id: '33142',
    name: '33142 - Copper rolling, drawing, extruding, and alloying',
  },
  {
    id: '331420',
    name: '331420 - Copper rolling, drawing, extruding, and alloying',
  },
  {
    id: '33149',
    name: '33149 - Nonferrous metal (except copper and aluminum) rolling, drawing, extruding, and alloying',
  },
  {
    id: '331491',
    name: '331491 - Nonferrous metal (except copper and aluminum) rolling, drawing, and extruding',
  },
  {
    id: '331492',
    name: '331492 - Secondary smelting, refining, and alloying of nonferrous metal (except copper and aluminum)',
  },
  {
    id: '3315',
    name: '3315 - Foundries',
  },
  {
    id: '33151',
    name: '33151 - Ferrous metal foundries',
  },
  {
    id: '331511',
    name: '331511 - Iron foundries',
  },
  {
    id: '331512',
    name: '331512 - Steel investment foundries',
  },
  {
    id: '331513',
    name: '331513 - Steel foundries (except investment)',
  },
  {
    id: '33152',
    name: '33152 - Nonferrous metal foundries',
  },
  {
    id: '331523',
    name: '331523 - Nonferrous metal die-casting foundries',
  },
  {
    id: '331524',
    name: '331524 - Aluminum foundries (except die-casting)',
  },
  {
    id: '331529',
    name: '331529 - Other nonferrous metal foundries (except die-casting)',
  },
  {
    id: '332',
    name: '332 - Fabricated metal product manufacturing',
  },
  {
    id: '3321',
    name: '3321 - Forging and stamping',
  },
  {
    id: '33211',
    name: '33211 - Forging and stamping',
  },
  {
    id: '332111',
    name: '332111 - Iron and steel forging',
  },
  {
    id: '332112',
    name: '332112 - Nonferrous forging',
  },
  {
    id: '332114',
    name: '332114 - Custom roll forming',
  },
  {
    id: '332117',
    name: '332117 - Powder metallurgy part manufacturing',
  },
  {
    id: '332119',
    name: '332119 - Metal crown, closure, and other metal stamping (except automotive)',
  },
  {
    id: '3322',
    name: '3322 - Cutlery and handtool manufacturing',
  },
  {
    id: '33221',
    name: '33221 - Cutlery and handtool manufacturing',
  },
  {
    id: '332215',
    name: '332215 - Metal kitchen cookware, utensil, cutlery, and flatware (except precious) manufacturing',
  },
  {
    id: '332216',
    name: '332216 - Saw blade and handtool manufacturing',
  },
  {
    id: '3323',
    name: '3323 - Architectural and structural metals manufacturing',
  },
  {
    id: '33231',
    name: '33231 - Plate work and fabricated structural product manufacturing',
  },
  {
    id: '332311',
    name: '332311 - Prefabricated metal building and component manufacturing',
  },
  {
    id: '332312',
    name: '332312 - Fabricated structural metal manufacturing',
  },
  {
    id: '332313',
    name: '332313 - Plate work manufacturing',
  },
  {
    id: '33232',
    name: '33232 - Ornamental and architectural metal products manufacturing',
  },
  {
    id: '332321',
    name: '332321 - Metal window and door manufacturing',
  },
  {
    id: '332322',
    name: '332322 - Sheet metal work manufacturing',
  },
  {
    id: '332323',
    name: '332323 - Ornamental and architectural metal work manufacturing',
  },
  {
    id: '3324',
    name: '3324 - Boiler, tank, and shipping container manufacturing',
  },
  {
    id: '33241',
    name: '33241 - Power boiler and heat exchanger manufacturing',
  },
  {
    id: '332410',
    name: '332410 - Power boiler and heat exchanger manufacturing',
  },
  {
    id: '33242',
    name: '33242 - Metal tank (heavy gauge) manufacturing',
  },
  {
    id: '332420',
    name: '332420 - Metal tank (heavy gauge) manufacturing',
  },
  {
    id: '33243',
    name: '33243 - Metal can, box, and other metal container (light gauge) manufacturing',
  },
  {
    id: '332431',
    name: '332431 - Metal can manufacturing',
  },
  {
    id: '332439',
    name: '332439 - Other metal container manufacturing',
  },
  {
    id: '3325',
    name: '3325 - Hardware manufacturing',
  },
  {
    id: '33251',
    name: '33251 - Hardware manufacturing',
  },
  {
    id: '332510',
    name: '332510 - Hardware manufacturing',
  },
  {
    id: '3326',
    name: '3326 - Spring and wire product manufacturing',
  },
  {
    id: '33261',
    name: '33261 - Spring and wire product manufacturing',
  },
  {
    id: '332613',
    name: '332613 - Spring manufacturing',
  },
  {
    id: '332618',
    name: '332618 - Other fabricated wire product manufacturing',
  },
  {
    id: '3327',
    name: '3327 - Machine shops; turned product; and screw, nut, and bolt manufacturing',
  },
  {
    id: '33271',
    name: '33271 - Machine shops',
  },
  {
    id: '332710',
    name: '332710 - Machine shops',
  },
  {
    id: '33272',
    name: '33272 - Turned product and screw, nut, and bolt manufacturing',
  },
  {
    id: '332721',
    name: '332721 - Precision turned product manufacturing',
  },
  {
    id: '332722',
    name: '332722 - Bolt, nut, screw, rivet, and washer manufacturing',
  },
  {
    id: '3328',
    name: '3328 - Coating, engraving, heat treating, and allied activities',
  },
  {
    id: '33281',
    name: '33281 - Coating, engraving, heat treating, and allied activities',
  },
  {
    id: '332811',
    name: '332811 - Metal heat treating',
  },
  {
    id: '332812',
    name: '332812 - Metal coating, engraving (except jewelry and silverware), and allied services to manufacturers',
  },
  {
    id: '332813',
    name: '332813 - Electroplating, plating, polishing, anodizing, and coloring',
  },
  {
    id: '3329',
    name: '3329 - Other fabricated metal product manufacturing',
  },
  {
    id: '33291',
    name: '33291 - Metal valve manufacturing',
  },
  {
    id: '332911',
    name: '332911 - Industrial valve manufacturing',
  },
  {
    id: '332912',
    name: '332912 - Fluid power valve and hose fitting manufacturing',
  },
  {
    id: '332913',
    name: '332913 - Plumbing fixture fitting and trim manufacturing',
  },
  {
    id: '332919',
    name: '332919 - Other metal valve and pipe fitting manufacturing',
  },
  {
    id: '33299',
    name: '33299 - All other fabricated metal product manufacturing',
  },
  {
    id: '332991',
    name: '332991 - Ball and roller bearing manufacturing',
  },
  {
    id: '332992',
    name: '332992 - Small arms ammunition manufacturing',
  },
  {
    id: '332993',
    name: '332993 - Ammunition (except small arms) manufacturing',
  },
  {
    id: '332994',
    name: '332994 - Small arms, ordnance, and ordnance accessories manufacturing',
  },
  {
    id: '332996',
    name: '332996 - Fabricated pipe and pipe fitting manufacturing',
  },
  {
    id: '332999',
    name: '332999 - All other miscellaneous fabricated metal product manufacturing',
  },
  {
    id: '333',
    name: '333 - Machinery manufacturing',
  },
  {
    id: '3331',
    name: '3331 - Agriculture, construction, and mining machinery manufacturing',
  },
  {
    id: '33311',
    name: '33311 - Agricultural implement manufacturing',
  },
  {
    id: '333111',
    name: '333111 - Farm machinery and equipment manufacturing',
  },
  {
    id: '333112',
    name: '333112 - Lawn and garden tractor and home lawn and garden equipment manufacturing',
  },
  {
    id: '33312',
    name: '33312 - Construction machinery manufacturing',
  },
  {
    id: '333120',
    name: '333120 - Construction machinery manufacturing',
  },
  {
    id: '33313',
    name: '33313 - Mining and oil and gas field machinery manufacturing',
  },
  {
    id: '333131',
    name: '333131 - Mining machinery and equipment manufacturing',
  },
  {
    id: '333132',
    name: '333132 - Oil and gas field machinery and equipment manufacturing',
  },
  {
    id: '3332',
    name: '3332 - Industrial machinery manufacturing',
  },
  {
    id: '33324',
    name: '33324 - Industrial machinery manufacturing',
  },
  {
    id: '333241',
    name: '333241 - Food product machinery manufacturing',
  },
  {
    id: '333242',
    name: '333242 - Semiconductor machinery manufacturing',
  },
  {
    id: '333243',
    name: '333243 - Sawmill, woodworking, and paper machinery manufacturing',
  },
  {
    id: '333244',
    name: '333244 - Printing machinery and equipment manufacturing',
  },
  {
    id: '333249',
    name: '333249 - Other industrial machinery manufacturing',
  },
  {
    id: '3333',
    name: '3333 - Commercial and service industry machinery manufacturing',
  },
  {
    id: '33331',
    name: '33331 - Commercial and service industry machinery manufacturing',
  },
  {
    id: '333314',
    name: '333314 - Optical instrument and lens manufacturing',
  },
  {
    id: '333316',
    name: '333316 - Photographic and photocopying equipment manufacturing',
  },
  {
    id: '333318',
    name: '333318 - Other commercial and service industry machinery manufacturing',
  },
  {
    id: '3334',
    name: '3334 - Ventilation, heating, air-conditioning, and commercial refrigeration equipment manufacturing',
  },
  {
    id: '33341',
    name: '33341 - Ventilation, heating, air-conditioning, and commercial refrigeration equipment manufacturing',
  },
  {
    id: '333413',
    name: '333413 - Industrial and commercial fan and blower and air purification equipment manufacturing',
  },
  {
    id: '333414',
    name: '333414 - Heating equipment (except warm air furnaces) manufacturing',
  },
  {
    id: '333415',
    name: '333415 - Air-conditioning and warm air heating equipment and commercial and Industrial refrigeration equipment manufacturing',
  },
  {
    id: '3335',
    name: '3335 - Metalworking machinery manufacturing',
  },
  {
    id: '33351',
    name: '33351 - Metalworking machinery manufacturing',
  },
  {
    id: '333511',
    name: '333511 - Industrial mold manufacturing',
  },
  {
    id: '333514',
    name: '333514 - Special die and tool, die set, jig, and fixture manufacturing',
  },
  {
    id: '333515',
    name: '333515 - Cutting tool and machine tool accessory manufacturing',
  },
  {
    id: '333517',
    name: '333517 - Machine tool manufacturing',
  },
  {
    id: '333519',
    name: '333519 - Rolling mill and other metalworking machinery manufacturing',
  },
  {
    id: '3336',
    name: '3336 - Engine, turbine, and power transmission equipment manufacturing',
  },
  {
    id: '33361',
    name: '33361 - Engine, turbine, and power transmission equipment manufacturing',
  },
  {
    id: '333611',
    name: '333611 - Turbine and turbine generator set units manufacturing',
  },
  {
    id: '333612',
    name: '333612 - Speed changer, industrial high-speed drive, and gear manufacturing',
  },
  {
    id: '333613',
    name: '333613 - Mechanical power transmission equipment manufacturing',
  },
  {
    id: '333618',
    name: '333618 - Other engine equipment manufacturing',
  },
  {
    id: '3339',
    name: '3339 - Other general purpose machinery manufacturing',
  },
  {
    id: '33391',
    name: '33391 - Pump and compressor manufacturing',
  },
  {
    id: '333912',
    name: '333912 - Air and gas compressor manufacturing',
  },
  {
    id: '333914',
    name: '333914 - Measuring, dispensing, and other pumping equipment manufacturing',
  },
  {
    id: '33392',
    name: '33392 - Material handling equipment manufacturing',
  },
  {
    id: '333921',
    name: '333921 - Elevator and moving stairway manufacturing',
  },
  {
    id: '333922',
    name: '333922 - Conveyor and conveying equipment manufacturing',
  },
  {
    id: '333923',
    name: '333923 - Overhead traveling crane, hoist, and monorail system manufacturing',
  },
  {
    id: '333924',
    name: '333924 - Industrial truck, tractor, trailer, and stacker machinery manufacturing',
  },
  {
    id: '33399',
    name: '33399 - All other general purpose machinery manufacturing',
  },
  {
    id: '333991',
    name: '333991 - Power-driven handtool manufacturing',
  },
  {
    id: '333992',
    name: '333992 - Welding and soldering equipment manufacturing',
  },
  {
    id: '333993',
    name: '333993 - Packaging machinery manufacturing',
  },
  {
    id: '333994',
    name: '333994 - Industrial process furnace and oven manufacturing',
  },
  {
    id: '333995',
    name: '333995 - Fluid power cylinder and actuator manufacturing',
  },
  {
    id: '333996',
    name: '333996 - Fluid power pump and motor manufacturing',
  },
  {
    id: '333997',
    name: '333997 - Scale and balance manufacturing',
  },
  {
    id: '333999',
    name: '333999 - All other miscellaneous general purpose machinery Manufacturing',
  },
  {
    id: '334',
    name: '334 - Computer and electronic product manufacturing',
  },
  {
    id: '3341',
    name: '3341 - Computer and peripheral equipment manufacturing',
  },
  {
    id: '33411',
    name: '33411 - Computer and peripheral equipment manufacturing',
  },
  {
    id: '334111',
    name: '334111 - Electronic computer manufacturing',
  },
  {
    id: '334112',
    name: '334112 - Computer storage device manufacturing',
  },
  {
    id: '334118',
    name: '334118 - Computer terminal and other computer peripheral equipment manufacturing',
  },
  {
    id: '3342',
    name: '3342 - Communications equipment manufacturing',
  },
  {
    id: '33421',
    name: '33421 - Telephone apparatus manufacturing',
  },
  {
    id: '334210',
    name: '334210 - Telephone apparatus manufacturing',
  },
  {
    id: '33422',
    name: '33422 - Radio and television broadcasting and wireless communications equipment manufacturing',
  },
  {
    id: '334220',
    name: '334220 - Radio and television broadcasting and wireless communications equipment manufacturing',
  },
  {
    id: '33429',
    name: '33429 - Other communications equipment manufacturing',
  },
  {
    id: '334290',
    name: '334290 - Other communications equipment manufacturing',
  },
  {
    id: '3343',
    name: '3343 - Audio and video equipment manufacturing',
  },
  {
    id: '33431',
    name: '33431 - Audio and video equipment manufacturing',
  },
  {
    id: '334310',
    name: '334310 - Audio and video equipment manufacturing',
  },
  {
    id: '3344',
    name: '3344 - Semiconductor and other electronic component manufacturing',
  },
  {
    id: '33441',
    name: '33441 - Semiconductor and other electronic component manufacturing',
  },
  {
    id: '334412',
    name: '334412 - Bare printed circuit board manufacturing',
  },
  {
    id: '334413',
    name: '334413 - Semiconductor and related device manufacturing',
  },
  {
    id: '334416',
    name: '334416 - Capacitor, resistor, coil, transformer, and other inductor manufacturing',
  },
  {
    id: '334417',
    name: '334417 - Electronic connector manufacturing',
  },
  {
    id: '334418',
    name: '334418 - Printed circuit assembly (electronic assembly) manufacturing',
  },
  {
    id: '334419',
    name: '334419 - Other electronic component manufacturing',
  },
  {
    id: '3345',
    name: '3345 - Navigational, measuring, electromedical, and control instruments manufacturing',
  },
  {
    id: '33451',
    name: '33451 - Navigational, measuring, electromedical, and control instruments manufacturing',
  },
  {
    id: '334510',
    name: '334510 - Electromedical and electrotherapeutic apparatus manufacturing',
  },
  {
    id: '334511',
    name: '334511 - Search, detection, navigation, guidance, aeronautical, and nautical system and instrument manufacturing',
  },
  {
    id: '334512',
    name: '334512 - Automatic environmental control manufacturing for residential, commercial, and appliance use',
  },
  {
    id: '334513',
    name: '334513 - Instruments and related products manufacturing for measuring, displaying, and controlling industrial process variables',
  },
  {
    id: '334514',
    name: '334514 - Totalizing fluid meter and counting device manufacturing',
  },
  {
    id: '334515',
    name: '334515 - Instrument manufacturing for measuring and testing electricity and electrical signals',
  },
  {
    id: '334516',
    name: '334516 - Analytical laboratory instrument manufacturing',
  },
  {
    id: '334517',
    name: '334517 - Irradiation apparatus manufacturing',
  },
  {
    id: '334519',
    name: '334519 - Other measuring and controlling device manufacturing',
  },
  {
    id: '3346',
    name: '3346 - Manufacturing and reproducing magnetic and optical media',
  },
  {
    id: '33461',
    name: '33461 - Manufacturing and reproducing magnetic and optical media',
  },
  {
    id: '334613',
    name: '334613 - Blank magnetic and optical recording media manufacturing',
  },
  {
    id: '334614',
    name: '334614 - Software and other prerecorded compact disc, tape, and record reproducing',
  },
  {
    id: '335',
    name: '335 - Electrical equipment, appliance, and component manufacturing',
  },
  {
    id: '3351',
    name: '3351 - Electric lighting equipment manufacturing',
  },
  {
    id: '33511',
    name: '33511 - Electric lamp bulb and part manufacturing',
  },
  {
    id: '335110',
    name: '335110 - Electric lamp bulb and part manufacturing',
  },
  {
    id: '33512',
    name: '33512 - Lighting fixture manufacturing',
  },
  {
    id: '335121',
    name: '335121 - Residential electric lighting fixture manufacturing',
  },
  {
    id: '335122',
    name: '335122 - Commercial, industrial, and institutional electric lighting fixture manufacturing',
  },
  {
    id: '335129',
    name: '335129 - Other lighting equipment manufacturing',
  },
  {
    id: '3352',
    name: '3352 - Household appliance manufacturing',
  },
  {
    id: '33521',
    name: '33521 - Small electrical appliance manufacturing',
  },
  {
    id: '335210',
    name: '335210 - Small electrical appliance manufacturing',
  },
  {
    id: '33522',
    name: '33522 - Major household appliance manufacturing',
  },
  {
    id: '335220',
    name: '335220 - Major household appliance manufacturing',
  },
  {
    id: '3353',
    name: '3353 - Electrical equipment manufacturing',
  },
  {
    id: '33531',
    name: '33531 - Electrical equipment manufacturing',
  },
  {
    id: '335311',
    name: '335311 - Power, distribution, and specialty transformer manufacturing',
  },
  {
    id: '335312',
    name: '335312 - Motor and generator manufacturing',
  },
  {
    id: '335313',
    name: '335313 - Switchgear and switchboard apparatus manufacturing',
  },
  {
    id: '335314',
    name: '335314 - Relay and industrial control manufacturing',
  },
  {
    id: '3359',
    name: '3359 - Other electrical equipment and component manufacturing',
  },
  {
    id: '33591',
    name: '33591 - Battery manufacturing',
  },
  {
    id: '335911',
    name: '335911 - Storage battery manufacturing',
  },
  {
    id: '335912',
    name: '335912 - Primary battery manufacturing',
  },
  {
    id: '33592',
    name: '33592 - Communication and energy wire and cable manufacturing',
  },
  {
    id: '335921',
    name: '335921 - Fiber optic cable manufacturing',
  },
  {
    id: '335929',
    name: '335929 - Other communication and energy wire manufacturing',
  },
  {
    id: '33593',
    name: '33593 - Wiring device manufacturing',
  },
  {
    id: '335931',
    name: '335931 - Current-carrying wiring device manufacturing',
  },
  {
    id: '335932',
    name: '335932 - Noncurrent-carrying wiring device manufacturing',
  },
  {
    id: '33599',
    name: '33599 - All other electrical equipment and component manufacturing',
  },
  {
    id: '335991',
    name: '335991 - Carbon and graphite product manufacturing',
  },
  {
    id: '335999',
    name: '335999 - All other miscellaneous electrical equipment and component manufacturing',
  },
  {
    id: '336',
    name: '336 - Transportation equipment manufacturing',
  },
  {
    id: '3361',
    name: '3361 - Motor vehicle manufacturing',
  },
  {
    id: '33611',
    name: '33611 - Automobile and light duty motor vehicle manufacturing',
  },
  {
    id: '336111',
    name: '336111 - Automobile manufacturing',
  },
  {
    id: '336112',
    name: '336112 - Light truck and utility vehicle manufacturing',
  },
  {
    id: '33612',
    name: '33612 - Heavy duty truck manufacturing',
  },
  {
    id: '336120',
    name: '336120 - Heavy duty truck manufacturing',
  },
  {
    id: '3362',
    name: '3362 - Motor vehicle body and trailer manufacturing',
  },
  {
    id: '33621',
    name: '33621 - Motor vehicle body and trailer manufacturing',
  },
  {
    id: '336211',
    name: '336211 - Motor vehicle body manufacturing',
  },
  {
    id: '336212',
    name: '336212 - Truck trailer manufacturing',
  },
  {
    id: '336213',
    name: '336213 - Motor home manufacturing',
  },
  {
    id: '336214',
    name: '336214 - Travel trailer and camper manufacturing',
  },
  {
    id: '3363',
    name: '3363 - Motor vehicle parts manufacturing',
  },
  {
    id: '33631',
    name: '33631 - Motor vehicle gasoline engine and engine parts manufacturing',
  },
  {
    id: '336310',
    name: '336310 - Motor vehicle gasoline engine and engine parts manufacturing',
  },
  {
    id: '33632',
    name: '33632 - Motor vehicle electrical and electronic equipment manufacturing',
  },
  {
    id: '336320',
    name: '336320 - Motor vehicle electrical and electronic equipment manufacturing',
  },
  {
    id: '33633',
    name: '33633 - Motor vehicle steering and suspension components (except spring) manufacturing',
  },
  {
    id: '336330',
    name: '336330 - Motor vehicle steering and suspension components (except spring) manufacturing',
  },
  {
    id: '33634',
    name: '33634 - Motor vehicle brake system manufacturing',
  },
  {
    id: '336340',
    name: '336340 - Motor vehicle brake system manufacturing',
  },
  {
    id: '33635',
    name: '33635 - Motor vehicle transmission and power train parts manufacturing',
  },
  {
    id: '336350',
    name: '336350 - Motor vehicle transmission and power train parts manufacturing',
  },
  {
    id: '33636',
    name: '33636 - Motor vehicle seating and interior trim manufacturing',
  },
  {
    id: '336360',
    name: '336360 - Motor vehicle seating and interior trim manufacturing',
  },
  {
    id: '33637',
    name: '33637 - Motor vehicle metal stamping',
  },
  {
    id: '336370',
    name: '336370 - Motor vehicle metal stamping',
  },
  {
    id: '33639',
    name: '33639 - Other motor vehicle parts manufacturing',
  },
  {
    id: '336390',
    name: '336390 - Other motor vehicle parts manufacturing',
  },
  {
    id: '3364',
    name: '3364 - Aerospace product and parts manufacturing',
  },
  {
    id: '33641',
    name: '33641 - Aerospace product and parts manufacturing',
  },
  {
    id: '336411',
    name: '336411 - Aircraft manufacturing',
  },
  {
    id: '336412',
    name: '336412 - Aircraft engine and engine parts manufacturing',
  },
  {
    id: '336413',
    name: '336413 - Other aircraft parts and auxiliary equipment manufacturing',
  },
  {
    id: '336414',
    name: '336414 - Guided missile and space vehicle manufacturing',
  },
  {
    id: '336415',
    name: '336415 - Guided missile and space vehicle propulsion unit and propulsion unit parts manufacturing',
  },
  {
    id: '336419',
    name: '336419 - Other guided missile and space vehicle parts and auxiliary equipment manufacturing',
  },
  {
    id: '3365',
    name: '3365 - Railroad rolling stock manufacturing',
  },
  {
    id: '33651',
    name: '33651 - Railroad rolling stock manufacturing',
  },
  {
    id: '336510',
    name: '336510 - Railroad rolling stock manufacturing',
  },
  {
    id: '3366',
    name: '3366 - Ship and boat building',
  },
  {
    id: '33661',
    name: '33661 - Ship and boat building',
  },
  {
    id: '336611',
    name: '336611 - Ship building and repairing',
  },
  {
    id: '336612',
    name: '336612 - Boat building',
  },
  {
    id: '3369',
    name: '3369 - Other transportation equipment manufacturing',
  },
  {
    id: '33699',
    name: '33699 - Other transportation equipment manufacturing',
  },
  {
    id: '336991',
    name: '336991 - Motorcycle, bicycle, and parts manufacturing',
  },
  {
    id: '336992',
    name: '336992 - Military armored vehicle, tank, and tank component manufacturing',
  },
  {
    id: '336999',
    name: '336999 - All other transportation equipment manufacturing',
  },
  {
    id: '337',
    name: '337 - Furniture and related product manufacturing',
  },
  {
    id: '3371',
    name: '3371 - Household and institutional furniture and kitchen cabinet manufacturing',
  },
  {
    id: '33711',
    name: '33711 - Wood kitchen cabinet and countertop manufacturing',
  },
  {
    id: '337110',
    name: '337110 - Wood kitchen cabinet and countertop manufacturing',
  },
  {
    id: '33712',
    name: '33712 - Household and institutional furniture manufacturing',
  },
  {
    id: '337121',
    name: '337121 - Upholstered household furniture manufacturing',
  },
  {
    id: '337122',
    name: '337122 - Nonupholstered wood household furniture manufacturing',
  },
  {
    id: '337124',
    name: '337124 - Metal household furniture manufacturing',
  },
  {
    id: '337125',
    name: '337125 - Household furniture (except wood and metal) manufacturing',
  },
  {
    id: '337127',
    name: '337127 - Institutional furniture manufacturing',
  },
  {
    id: '3372',
    name: '3372 - Office furniture (including fixtures) manufacturing',
  },
  {
    id: '33721',
    name: '33721 - Office furniture (including fixtures) manufacturing',
  },
  {
    id: '337211',
    name: '337211 - Wood office furniture manufacturing',
  },
  {
    id: '337212',
    name: '337212 - Custom architectural woodwork and millwork manufacturing',
  },
  {
    id: '337214',
    name: '337214 - Office furniture (except wood) manufacturing',
  },
  {
    id: '337215',
    name: '337215 - Showcase, partition, shelving, and locker manufacturing',
  },
  {
    id: '3379',
    name: '3379 - Other furniture related product manufacturing',
  },
  {
    id: '33791',
    name: '33791 - Mattress manufacturing',
  },
  {
    id: '337910',
    name: '337910 - Mattress manufacturing',
  },
  {
    id: '33792',
    name: '33792 - Blind and shade manufacturing',
  },
  {
    id: '337920',
    name: '337920 - Blind and shade manufacturing',
  },
  {
    id: '339',
    name: '339 - Miscellaneous manufacturing',
  },
  {
    id: '3391',
    name: '3391 - Medical equipment and supplies manufacturing',
  },
  {
    id: '33911',
    name: '33911 - Medical equipment and supplies manufacturing',
  },
  {
    id: '339112',
    name: '339112 - Surgical and medical instrument manufacturing',
  },
  {
    id: '339113',
    name: '339113 - Surgical appliance and supplies manufacturing',
  },
  {
    id: '339114',
    name: '339114 - Dental equipment and supplies manufacturing',
  },
  {
    id: '339115',
    name: '339115 - Ophthalmic goods manufacturing',
  },
  {
    id: '339116',
    name: '339116 - Dental laboratories',
  },
  {
    id: '3399',
    name: '3399 - Other miscellaneous manufacturing',
  },
  {
    id: '33991',
    name: '33991 - Jewelry and silverware manufacturing',
  },
  {
    id: '339910',
    name: '339910 - Jewelry and silverware manufacturing',
  },
  {
    id: '33992',
    name: '33992 - Sporting and athletic goods manufacturing',
  },
  {
    id: '339920',
    name: '339920 - Sporting and athletic goods manufacturing',
  },
  {
    id: '33993',
    name: '33993 - Doll, toy, and game manufacturing',
  },
  {
    id: '339930',
    name: '339930 - Doll, toy, and game manufacturing',
  },
  {
    id: '33994',
    name: '33994 - Office supplies (except paper) manufacturing',
  },
  {
    id: '339940',
    name: '339940 - Office supplies (except paper) manufacturing',
  },
  {
    id: '33995',
    name: '33995 - Sign manufacturing',
  },
  {
    id: '339950',
    name: '339950 - Sign manufacturing',
  },
  {
    id: '33999',
    name: '33999 - All other miscellaneous manufacturing',
  },
  {
    id: '339991',
    name: '339991 - Gasket, packing, and sealing device manufacturing',
  },
  {
    id: '339992',
    name: '339992 - Musical instrument manufacturing',
  },
  {
    id: '339993',
    name: '339993 - Fastener, button, needle, and pin manufacturing',
  },
  {
    id: '339994',
    name: '339994 - Broom, brush, and mop manufacturing',
  },
  {
    id: '339995',
    name: '339995 - Burial casket manufacturing',
  },
  {
    id: '339999',
    name: '339999 - All other miscellaneous manufacturing',
  },
  { id: '42', name: '42 - Wholesale trade' },
  {
    id: '423',
    name: '423 - Merchant wholesalers, durable goods',
  },
  {
    id: '4231',
    name: '4231 - Motor vehicle and motor vehicle parts and supplies merchant wholesalers',
  },
  {
    id: '42311',
    name: '42311 - Automobile and other motor vehicle merchant wholesalers',
  },
  {
    id: '423110',
    name: '423110 - Automobile and other motor vehicle merchant wholesalers',
  },
  {
    id: '42312',
    name: '42312 - Motor vehicle supplies and new parts merchant wholesalers',
  },
  {
    id: '423120',
    name: '423120 - Motor vehicle supplies and new parts merchant wholesalers',
  },
  {
    id: '42313',
    name: '42313 - Tire and tube merchant wholesalers',
  },
  {
    id: '423130',
    name: '423130 - Tire and tube merchant wholesalers',
  },
  {
    id: '42314',
    name: '42314 - Motor vehicle parts (used) merchant wholesalers',
  },
  {
    id: '423140',
    name: '423140 - Motor vehicle parts (used) merchant wholesalers',
  },
  {
    id: '4232',
    name: '4232 - Furniture and home furnishing merchant wholesalers',
  },
  {
    id: '42321',
    name: '42321 - Furniture merchant wholesalers',
  },
  {
    id: '423210',
    name: '423210 - Furniture merchant wholesalers',
  },
  {
    id: '42322',
    name: '42322 - Home furnishing merchant wholesalers',
  },
  {
    id: '423220',
    name: '423220 - Home furnishing merchant wholesalers',
  },
  {
    id: '4233',
    name: '4233 - Lumber and other construction materials merchant wholesalers',
  },
  {
    id: '42331',
    name: '42331 - Lumber, plywood, millwork, and wood panel merchant wholesalers',
  },
  {
    id: '423310',
    name: '423310 - Lumber, plywood, millwork, and wood panel merchant wholesalers',
  },
  {
    id: '42332',
    name: '42332 - Brick, stone, and related construction material merchant wholesalers',
  },
  {
    id: '423320',
    name: '423320 - Brick, stone, and related construction material merchant wholesalers',
  },
  {
    id: '42333',
    name: '42333 - Roofing, siding, and insulation material merchant wholesalers',
  },
  {
    id: '423330',
    name: '423330 - Roofing, siding, and insulation material merchant wholesalers',
  },
  {
    id: '42339',
    name: '42339 - Other construction material merchant wholesalers',
  },
  {
    id: '423390',
    name: '423390 - Other construction material merchant wholesalers',
  },
  {
    id: '4234',
    name: '4234 - Professional and commercial equipment and supplies merchant wholesalers',
  },
  {
    id: '42341',
    name: '42341 - Photographic equipment and supplies merchant wholesalers',
  },
  {
    id: '423410',
    name: '423410 - Photographic equipment and supplies merchant wholesalers',
  },
  {
    id: '42342',
    name: '42342 - Office equipment merchant wholesalers',
  },
  {
    id: '423420',
    name: '423420 - Office equipment merchant wholesalers',
  },
  {
    id: '42343',
    name: '42343 - Computer and computer peripheral equipment and software merchant wholesalers',
  },
  {
    id: '423430',
    name: '423430 - Computer and computer peripheral equipment and software merchant wholesalers',
  },
  {
    id: '42344',
    name: '42344 - Other commercial equipment merchant wholesalers',
  },
  {
    id: '423440',
    name: '423440 - Other commercial equipment merchant wholesalers',
  },
  {
    id: '42345',
    name: '42345 - Medical, dental, and hospital equipment and supplies merchant wholesalers',
  },
  {
    id: '423450',
    name: '423450 - Medical, dental, and hospital equipment and supplies merchant wholesalers',
  },
  {
    id: '42346',
    name: '42346 - Ophthalmic goods merchant wholesalers',
  },
  {
    id: '423460',
    name: '423460 - Ophthalmic goods merchant wholesalers',
  },
  {
    id: '42349',
    name: '42349 - Other professional equipment and supplies merchant wholesalers',
  },
  {
    id: '423490',
    name: '423490 - Other professional equipment and supplies merchant wholesalers',
  },
  {
    id: '4235',
    name: '4235 - Metal and mineral (except petroleum) merchant wholesalers',
  },
  {
    id: '42351',
    name: '42351 - Metal service centers and other metal merchant wholesalers',
  },
  {
    id: '423510',
    name: '423510 - Metal service centers and other metal merchant wholesalers',
  },
  {
    id: '42352',
    name: '42352 - Coal and other mineral and ore merchant wholesalers',
  },
  {
    id: '423520',
    name: '423520 - Coal and other mineral and ore merchant wholesalers',
  },
  {
    id: '4236',
    name: '4236 - Household appliances and electrical and electronic goods merchant wholesalers',
  },
  {
    id: '42361',
    name: '42361 - Electrical apparatus and equipment, wiring supplies, and related equipment merchant wholesalers',
  },
  {
    id: '423610',
    name: '423610 - Electrical apparatus and equipment, wiring supplies, and related equipment merchant wholesalers',
  },
  {
    id: '42362',
    name: '42362 - Household appliances, electric housewares, and consumer electronics merchant wholesalers',
  },
  {
    id: '423620',
    name: '423620 - Household appliances, electric housewares, and consumer electronics merchant wholesalers',
  },
  {
    id: '42369',
    name: '42369 - Other electronic parts and equipment merchant wholesalers',
  },
  {
    id: '423690',
    name: '423690 - Other electronic parts and equipment merchant wholesalers',
  },
  {
    id: '4237',
    name: '4237 - Hardware, and plumbing and heating equipment and supplies merchant wholesalers',
  },
  {
    id: '42371',
    name: '42371 - Hardware merchant wholesalers',
  },
  {
    id: '423710',
    name: '423710 - Hardware merchant wholesalers',
  },
  {
    id: '42372',
    name: '42372 - Plumbing and heating equipment and supplies (hydronics) merchant wholesalers',
  },
  {
    id: '423720',
    name: '423720 - Plumbing and heating equipment and supplies (hydronics) merchant wholesalers',
  },
  {
    id: '42373',
    name: '42373 - Warm air heating and air-conditioning equipment and supplies merchant wholesalers',
  },
  {
    id: '423730',
    name: '423730 - Warm air heating and air-conditioning equipment and supplies merchant wholesalers',
  },
  {
    id: '42374',
    name: '42374 - Refrigeration equipment and supplies merchant wholesalers',
  },
  {
    id: '423740',
    name: '423740 - Refrigeration equipment and supplies merchant wholesalers',
  },
  {
    id: '4238',
    name: '4238 - Machinery, equipment, and supplies merchant wholesalers',
  },
  {
    id: '42381',
    name: '42381 - Construction and mining (except oil well) machinery and equipment merchant wholesalers',
  },
  {
    id: '423810',
    name: '423810 - Construction and mining (except oil well) machinery and equipment merchant wholesalers',
  },
  {
    id: '42382',
    name: '42382 - Farm and garden machinery and equipment merchant wholesalers',
  },
  {
    id: '423820',
    name: '423820 - Farm and garden machinery and equipment merchant wholesalers',
  },
  {
    id: '42383',
    name: '42383 - Industrial machinery and equipment merchant wholesalers',
  },
  {
    id: '423830',
    name: '423830 - Industrial machinery and equipment merchant wholesalers',
  },
  {
    id: '42384',
    name: '42384 - Industrial supplies merchant wholesalers',
  },
  {
    id: '423840',
    name: '423840 - Industrial supplies merchant wholesalers',
  },
  {
    id: '42385',
    name: '42385 - Service establishment equipment and supplies merchant wholesalers',
  },
  {
    id: '423850',
    name: '423850 - Service establishment equipment and supplies merchant wholesalers',
  },
  {
    id: '42386',
    name: '42386 - Transportation equipment and supplies (except motor vehicle) merchant wholesalers',
  },
  {
    id: '423860',
    name: '423860 - Transportation equipment and supplies (except motor vehicle) merchant wholesalers',
  },
  {
    id: '4239',
    name: '4239 - Miscellaneous durable goods merchant wholesalers',
  },
  {
    id: '42391',
    name: '42391 - Sporting and recreational goods and supplies merchant wholesalers',
  },
  {
    id: '423910',
    name: '423910 - Sporting and recreational goods and supplies merchant wholesalers',
  },
  {
    id: '42392',
    name: '42392 - Toy and hobby goods and supplies merchant wholesalers',
  },
  {
    id: '423920',
    name: '423920 - Toy and hobby goods and supplies merchant wholesalers',
  },
  {
    id: '42393',
    name: '42393 - Recyclable material merchant wholesalers',
  },
  {
    id: '423930',
    name: '423930 - Recyclable material merchant wholesalers',
  },
  {
    id: '42394',
    name: '42394 - Jewelry, watch, precious stone, and precious metal merchant wholesalers',
  },
  {
    id: '423940',
    name: '423940 - Jewelry, watch, precious stone, and precious metal merchant wholesalers',
  },
  {
    id: '42399',
    name: '42399 - Other miscellaneous durable goods merchant wholesalers',
  },
  {
    id: '423990',
    name: '423990 - Other miscellaneous durable goods merchant wholesalers',
  },
  {
    id: '424',
    name: '424 - Merchant wholesalers, nondurable goods',
  },
  {
    id: '4241',
    name: '4241 - Paper and paper product merchant wholesalers',
  },
  {
    id: '42411',
    name: '42411 - Printing and writing paper merchant wholesalers',
  },
  {
    id: '424110',
    name: '424110 - Printing and writing paper merchant wholesalers',
  },
  {
    id: '42412',
    name: '42412 - Stationery and office supplies merchant wholesalers',
  },
  {
    id: '424120',
    name: '424120 - Stationery and office supplies merchant wholesalers',
  },
  {
    id: '42413',
    name: '42413 - Industrial and personal service paper merchant wholesalers',
  },
  {
    id: '424130',
    name: '424130 - Industrial and personal service paper merchant wholesalers',
  },
  {
    id: '4242',
    name: "        4242 - Drugs and druggists' sundries merchant wholesalers",
  },
  {
    id: '42421',
    name: "            42421 - Drugs and druggists' sundries merchant wholesalers",
  },
  {
    id: '424210',
    name: "                424210 - Drugs and druggists' sundries merchant wholesalers",
  },
  {
    id: '4243',
    name: '4243 - Apparel, piece goods, and notions merchant wholesalers',
  },
  {
    id: '42431',
    name: '42431 - Piece goods, notions, and other dry goods merchant wholesalers',
  },
  {
    id: '424310',
    name: '424310 - Piece goods, notions, and other dry goods merchant wholesalers',
  },
  {
    id: '42432',
    name: "            42432 - Men's and boys' clothing and furnishings merchant wholesalers",
  },
  {
    id: '424320',
    name: "                424320 - Men's and boys' clothing and furnishings merchant wholesalers",
  },
  {
    id: '42433',
    name: "            42433 - Women's, children's, and infants' clothing and accessories merchant wholesalers",
  },
  {
    id: '424330',
    name: "                424330 - Women's, children's, and infants' clothing and accessories merchant wholesalers",
  },
  {
    id: '42434',
    name: '42434 - Footwear merchant wholesalers',
  },
  {
    id: '424340',
    name: '424340 - Footwear merchant wholesalers',
  },
  {
    id: '4244',
    name: '4244 - Grocery and related product merchant wholesalers',
  },
  {
    id: '42441',
    name: '42441 - General line grocery merchant wholesalers',
  },
  {
    id: '424410',
    name: '424410 - General line grocery merchant wholesalers',
  },
  {
    id: '42442',
    name: '42442 - Packaged frozen food merchant wholesalers',
  },
  {
    id: '424420',
    name: '424420 - Packaged frozen food merchant wholesalers',
  },
  {
    id: '42443',
    name: '42443 - Dairy product (except dried or canned) merchant wholesalers',
  },
  {
    id: '424430',
    name: '424430 - Dairy product (except dried or canned) merchant wholesalers',
  },
  {
    id: '42444',
    name: '42444 - Poultry and poultry product merchant wholesalers',
  },
  {
    id: '424440',
    name: '424440 - Poultry and poultry product merchant wholesalers',
  },
  {
    id: '42445',
    name: '42445 - Confectionery merchant wholesalers',
  },
  {
    id: '424450',
    name: '424450 - Confectionery merchant wholesalers',
  },
  {
    id: '42446',
    name: '42446 - Fish and seafood merchant wholesalers',
  },
  {
    id: '424460',
    name: '424460 - Fish and seafood merchant wholesalers',
  },
  {
    id: '42447',
    name: '42447 - Meat and meat product merchant wholesalers',
  },
  {
    id: '424470',
    name: '424470 - Meat and meat product merchant wholesalers',
  },
  {
    id: '42448',
    name: '42448 - Fresh fruit and vegetable merchant wholesalers',
  },
  {
    id: '424480',
    name: '424480 - Fresh fruit and vegetable merchant wholesalers',
  },
  {
    id: '42449',
    name: '42449 - Other grocery and related products merchant wholesalers',
  },
  {
    id: '424490',
    name: '424490 - Other grocery and related products merchant wholesalers',
  },
  {
    id: '4245',
    name: '4245 - Farm product raw material merchant wholesalers',
  },
  {
    id: '42451',
    name: '42451 - Grain and field bean merchant wholesalers',
  },
  {
    id: '424510',
    name: '424510 - Grain and field bean merchant wholesalers',
  },
  {
    id: '42452',
    name: '42452 - Livestock merchant wholesalers',
  },
  {
    id: '424520',
    name: '424520 - Livestock merchant wholesalers',
  },
  {
    id: '42459',
    name: '42459 - Other farm product raw material merchant wholesalers',
  },
  {
    id: '424590',
    name: '424590 - Other farm product raw material merchant wholesalers',
  },
  {
    id: '4246',
    name: '4246 - Chemical and allied products merchant wholesalers',
  },
  {
    id: '42461',
    name: '42461 - Plastics materials and basic forms and shapes merchant wholesalers',
  },
  {
    id: '424610',
    name: '424610 - Plastics materials and basic forms and shapes merchant wholesalers',
  },
  {
    id: '42469',
    name: '42469 - Other chemical and allied products merchant wholesalers',
  },
  {
    id: '424690',
    name: '424690 - Other chemical and allied products merchant wholesalers',
  },
  {
    id: '4247',
    name: '4247 - Petroleum and petroleum products merchant wholesalers',
  },
  {
    id: '42471',
    name: '42471 - Petroleum bulk stations and terminals',
  },
  {
    id: '424710',
    name: '424710 - Petroleum bulk stations and terminals',
  },
  {
    id: '42472',
    name: '42472 - Petroleum and petroleum products merchant wholesalers (except bulk stations and terminals)',
  },
  {
    id: '424720',
    name: '424720 - Petroleum and petroleum products merchant wholesalers (except bulk stations and terminals)',
  },
  {
    id: '4248',
    name: '4248 - Beer, wine, and distilled alcoholic beverage merchant wholesalers',
  },
  {
    id: '42481',
    name: '42481 - Beer and ale merchant wholesalers',
  },
  {
    id: '424810',
    name: '424810 - Beer and ale merchant wholesalers',
  },
  {
    id: '42482',
    name: '42482 - Wine and distilled alcoholic beverage merchant wholesalers',
  },
  {
    id: '424820',
    name: '424820 - Wine and distilled alcoholic beverage merchant wholesalers',
  },
  {
    id: '4249',
    name: '4249 - Miscellaneous nondurable goods merchant wholesalers',
  },
  {
    id: '42491',
    name: '42491 - Farm supplies merchant wholesalers',
  },
  {
    id: '424910',
    name: '424910 - Farm supplies merchant wholesalers',
  },
  {
    id: '42492',
    name: '42492 - Book, periodical, and newspaper merchant wholesalers',
  },
  {
    id: '424920',
    name: '424920 - Book, periodical, and newspaper merchant wholesalers',
  },
  {
    id: '42493',
    name: "            42493 - Flower, nursery stock, and florists' supplies merchant wholesalers",
  },
  {
    id: '424930',
    name: "                424930 - Flower, nursery stock, and florists' supplies merchant wholesalers",
  },
  {
    id: '42494',
    name: '42494 - Tobacco and tobacco product merchant wholesalers',
  },
  {
    id: '424940',
    name: '424940 - Tobacco and tobacco product merchant wholesalers',
  },
  {
    id: '42495',
    name: '42495 - Paint, varnish, and supplies merchant wholesalers',
  },
  {
    id: '424950',
    name: '424950 - Paint, varnish, and supplies merchant wholesalers',
  },
  {
    id: '42499',
    name: '42499 - Other miscellaneous nondurable goods merchant wholesalers',
  },
  {
    id: '424990',
    name: '424990 - Other miscellaneous nondurable goods merchant wholesalers',
  },
  {
    id: '425',
    name: '425 - Wholesale electronic markets and agents and brokers',
  },
  {
    id: '4251',
    name: '4251 - Wholesale electronic markets and agents and brokers',
  },
  {
    id: '42511',
    name: '42511 - Business to business electronic markets',
  },
  {
    id: '425110',
    name: '425110 - Business to business electronic markets',
  },
  {
    id: '42512',
    name: '42512 - Wholesale trade agents and brokers',
  },
  {
    id: '425120',
    name: '425120 - Wholesale trade agents and brokers',
  },
  { id: '44', name: '44 - Retail trade' },
  {
    id: '441',
    name: '441 - Motor vehicle and parts dealers',
  },
  {
    id: '4411',
    name: '4411 - Automobile dealers',
  },
  {
    id: '44111',
    name: '44111 - New car dealers',
  },
  {
    id: '441110',
    name: '441110 - New car dealers',
  },
  {
    id: '44112',
    name: '44112 - Used car dealers',
  },
  {
    id: '441120',
    name: '441120 - Used car dealers',
  },
  {
    id: '4412',
    name: '4412 - Other motor vehicle dealers',
  },
  {
    id: '44121',
    name: '44121 - Recreational vehicle dealers',
  },
  {
    id: '441210',
    name: '441210 - Recreational vehicle dealers',
  },
  {
    id: '44122',
    name: '44122 - Motorcycle, boat, and other motor vehicle dealers',
  },
  {
    id: '441222',
    name: '441222 - Boat dealers',
  },
  {
    id: '441228',
    name: '441228 - Motorcycle, ATV, and all other motor vehicle dealers',
  },
  {
    id: '4413',
    name: '4413 - Automotive parts, accessories, and tire stores',
  },
  {
    id: '44131',
    name: '44131 - Automotive parts and accessories stores',
  },
  {
    id: '441310',
    name: '441310 - Automotive parts and accessories stores',
  },
  {
    id: '44132',
    name: '44132 - Tire dealers',
  },
  {
    id: '441320',
    name: '441320 - Tire dealers',
  },
  {
    id: '442',
    name: '442 - Furniture and home furnishings stores',
  },
  {
    id: '4421',
    name: '4421 - Furniture stores',
  },
  {
    id: '44211',
    name: '44211 - Furniture stores',
  },
  {
    id: '442110',
    name: '442110 - Furniture stores',
  },
  {
    id: '4422',
    name: '4422 - Home furnishings stores',
  },
  {
    id: '44221',
    name: '44221 - Floor covering stores',
  },
  {
    id: '442210',
    name: '442210 - Floor covering stores',
  },
  {
    id: '44229',
    name: '44229 - Other home furnishings stores',
  },
  {
    id: '442291',
    name: '442291 - Window treatment stores',
  },
  {
    id: '442299',
    name: '442299 - All other home furnishings stores',
  },
  {
    id: '443',
    name: '443 - Electronics and appliance stores',
  },
  {
    id: '4431',
    name: '4431 - Electronics and appliance stores',
  },
  {
    id: '44314',
    name: '44314 - Electronics and appliance stores',
  },
  {
    id: '443141',
    name: '443141 - Household appliance stores',
  },
  {
    id: '443142',
    name: '443142 - Electronics stores',
  },
  {
    id: '444',
    name: '444 - Building material and garden equipment and supplies dealers',
  },
  {
    id: '4441',
    name: '4441 - Building material and supplies dealers',
  },
  {
    id: '44411',
    name: '44411 - Home centers',
  },
  {
    id: '444110',
    name: '444110 - Home centers',
  },
  {
    id: '44412',
    name: '44412 - Paint and wallpaper stores',
  },
  {
    id: '444120',
    name: '444120 - Paint and wallpaper stores',
  },
  {
    id: '44413',
    name: '44413 - Hardware stores',
  },
  {
    id: '444130',
    name: '444130 - Hardware stores',
  },
  {
    id: '44419',
    name: '44419 - Other building material dealers',
  },
  {
    id: '444190',
    name: '444190 - Other building material dealers',
  },
  {
    id: '4442',
    name: '4442 - Lawn and garden equipment and supplies stores',
  },
  {
    id: '44421',
    name: '44421 - Outdoor power equipment stores',
  },
  {
    id: '444210',
    name: '444210 - Outdoor power equipment stores',
  },
  {
    id: '44422',
    name: '44422 - Nursery, garden center, and farm supply stores',
  },
  {
    id: '444220',
    name: '444220 - Nursery, garden center, and farm supply stores',
  },
  {
    id: '445',
    name: '445 - Food and beverage stores',
  },
  {
    id: '4451',
    name: '4451 - Grocery stores',
  },
  {
    id: '44511',
    name: '44511 - Supermarkets and other grocery (except convenience) stores',
  },
  {
    id: '445110',
    name: '445110 - Supermarkets and other grocery (except convenience) stores',
  },
  {
    id: '44512',
    name: '44512 - Convenience stores',
  },
  {
    id: '445120',
    name: '445120 - Convenience stores',
  },
  {
    id: '4452',
    name: '4452 - Specialty food stores',
  },
  {
    id: '44521',
    name: '44521 - Meat markets',
  },
  {
    id: '445210',
    name: '445210 - Meat markets',
  },
  {
    id: '44522',
    name: '44522 - Fish and seafood markets',
  },
  {
    id: '445220',
    name: '445220 - Fish and seafood markets',
  },
  {
    id: '44523',
    name: '44523 - Fruit and vegetable markets',
  },
  {
    id: '445230',
    name: '445230 - Fruit and vegetable markets',
  },
  {
    id: '44529',
    name: '44529 - Other specialty food stores',
  },
  {
    id: '445291',
    name: '445291 - Baked goods stores',
  },
  {
    id: '445292',
    name: '445292 - Confectionery and nut stores',
  },
  {
    id: '445299',
    name: '445299 - All other specialty food stores',
  },
  {
    id: '4453',
    name: '4453 - Beer, wine, and liquor stores',
  },
  {
    id: '44531',
    name: '44531 - Beer, wine, and liquor stores',
  },
  {
    id: '445310',
    name: '445310 - Beer, wine, and liquor stores',
  },
  {
    id: '446',
    name: '446 - Health and personal care stores',
  },
  {
    id: '4461',
    name: '4461 - Health and personal care stores',
  },
  {
    id: '44611',
    name: '44611 - Pharmacies and drug stores',
  },
  {
    id: '446110',
    name: '446110 - Pharmacies and drug stores',
  },
  {
    id: '44612',
    name: '44612 - Cosmetics, beauty supplies, and perfume stores',
  },
  {
    id: '446120',
    name: '446120 - Cosmetics, beauty supplies, and perfume stores',
  },
  {
    id: '44613',
    name: '44613 - Optical goods stores',
  },
  {
    id: '446130',
    name: '446130 - Optical goods stores',
  },
  {
    id: '44619',
    name: '44619 - Other health and personal care stores',
  },
  {
    id: '446191',
    name: '446191 - Food (health) supplement stores',
  },
  {
    id: '446199',
    name: '446199 - All other health and personal care stores',
  },
  { id: '447', name: '447 - Gasoline stations' },
  {
    id: '4471',
    name: '4471 - Gasoline stations',
  },
  {
    id: '44711',
    name: '44711 - Gasoline stations with convenience stores',
  },
  {
    id: '447110',
    name: '447110 - Gasoline stations with convenience stores',
  },
  {
    id: '44719',
    name: '44719 - Other gasoline stations',
  },
  {
    id: '447190',
    name: '447190 - Other gasoline stations',
  },
  {
    id: '448',
    name: '448 - Clothing and clothing accessories stores',
  },
  {
    id: '4481',
    name: '4481 - Clothing stores',
  },
  {
    id: '44811',
    name: "            44811 - Men's clothing stores",
  },
  {
    id: '448110',
    name: "                448110 - Men's clothing stores",
  },
  {
    id: '44812',
    name: "            44812 - Women's clothing stores",
  },
  {
    id: '448120',
    name: "                448120 - Women's clothing stores",
  },
  {
    id: '44813',
    name: "            44813 - Children's and Infants' clothing stores",
  },
  {
    id: '448130',
    name: "                448130 - Children's and infants' clothing stores",
  },
  {
    id: '44814',
    name: '44814 - Family clothing stores',
  },
  {
    id: '448140',
    name: '448140 - Family clothing stores',
  },
  {
    id: '44815',
    name: '44815 - Clothing accessories stores',
  },
  {
    id: '448150',
    name: '448150 - Clothing accessories stores',
  },
  {
    id: '44819',
    name: '44819 - Other clothing stores',
  },
  {
    id: '448190',
    name: '448190 - Other clothing stores',
  },
  {
    id: '4482',
    name: '4482 - Shoe stores',
  },
  {
    id: '44821',
    name: '44821 - Shoe stores',
  },
  {
    id: '448210',
    name: '448210 - Shoe stores',
  },
  {
    id: '4483',
    name: '4483 - Jewelry, luggage, and leather goods stores',
  },
  {
    id: '44831',
    name: '44831 - Jewelry stores',
  },
  {
    id: '448310',
    name: '448310 - Jewelry stores',
  },
  {
    id: '44832',
    name: '44832 - Luggage and leather goods stores',
  },
  {
    id: '448320',
    name: '448320 - Luggage and leather goods stores',
  },
  { id: '45', name: '45 - Retail trade' },
  {
    id: '451',
    name: '451 - Sporting goods, hobby, musical instrument, and book stores',
  },
  {
    id: '4511',
    name: '4511 - Sporting goods, hobby, and musical instrument stores',
  },
  {
    id: '45111',
    name: '45111 - Sporting goods stores',
  },
  {
    id: '451110',
    name: '451110 - Sporting goods stores',
  },
  {
    id: '45112',
    name: '45112 - Hobby, toy, and game stores',
  },
  {
    id: '451120',
    name: '451120 - Hobby, toy, and game stores',
  },
  {
    id: '45113',
    name: '45113 - Sewing, needlework, and piece goods stores',
  },
  {
    id: '451130',
    name: '451130 - Sewing, needlework, and piece goods stores',
  },
  {
    id: '45114',
    name: '45114 - Musical instrument and supplies stores',
  },
  {
    id: '451140',
    name: '451140 - Musical instrument and supplies stores',
  },
  {
    id: '4512',
    name: '4512 - Book stores and news dealers',
  },
  {
    id: '45121',
    name: '45121 - Book stores and news dealers',
  },
  {
    id: '451211',
    name: '451211 - Book stores',
  },
  {
    id: '451212',
    name: '451212 - News dealers and newsstands',
  },
  {
    id: '452',
    name: '452 - General merchandise stores',
  },
  {
    id: '4522',
    name: '4522 - Department stores',
  },
  {
    id: '45221',
    name: '45221 - Department stores',
  },
  {
    id: '452210',
    name: '452210 - Department stores',
  },
  {
    id: '4523',
    name: '4523 - General merchandise stores, including warehouse clubs and supercenters',
  },
  {
    id: '45231',
    name: '45231 - General merchandise stores, including warehouse clubs and supercenters',
  },
  {
    id: '452311',
    name: '452311 - Warehouse clubs and supercenters',
  },
  {
    id: '452319',
    name: '452319 - All other general merchandise stores',
  },
  {
    id: '453',
    name: '453 - Miscellaneous store retailers',
  },
  {
    id: '4531',
    name: '4531 - Florists',
  },
  {
    id: '45311',
    name: '45311 - Florists',
  },
  {
    id: '453110',
    name: '453110 - Florists',
  },
  {
    id: '4532',
    name: '4532 - Office supplies, stationery, and gift stores',
  },
  {
    id: '45321',
    name: '45321 - Office supplies and stationery stores',
  },
  {
    id: '453210',
    name: '453210 - Office supplies and stationery stores',
  },
  {
    id: '45322',
    name: '45322 - Gift, novelty, and souvenir stores',
  },
  {
    id: '453220',
    name: '453220 - Gift, novelty, and souvenir stores',
  },
  {
    id: '4533',
    name: '4533 - Used merchandise stores',
  },
  {
    id: '45331',
    name: '45331 - Used merchandise stores',
  },
  {
    id: '453310',
    name: '453310 - Used merchandise stores',
  },
  {
    id: '4539',
    name: '4539 - Other miscellaneous store retailers',
  },
  {
    id: '45391',
    name: '45391 - Pet and pet supplies stores',
  },
  {
    id: '453910',
    name: '453910 - Pet and pet supplies stores',
  },
  {
    id: '45392',
    name: '45392 - Art dealers',
  },
  {
    id: '453920',
    name: '453920 - Art dealers',
  },
  {
    id: '45393',
    name: '45393 - Manufactured (mobile) home dealers',
  },
  {
    id: '453930',
    name: '453930 - Manufactured (mobile) home dealers',
  },
  {
    id: '45399',
    name: '45399 - All other miscellaneous store retailers',
  },
  {
    id: '453991',
    name: '453991 - Tobacco stores',
  },
  {
    id: '453998',
    name: '453998 - All other miscellaneous store retailers (except tobacco stores)',
  },
  { id: '454', name: '454 - Nonstore retailers' },
  {
    id: '4541',
    name: '4541 - Electronic shopping and mail-order houses',
  },
  {
    id: '45411',
    name: '45411 - Electronic shopping and mail-order houses',
  },
  {
    id: '454110',
    name: '454110 - Electronic shopping and mail-order houses',
  },
  {
    id: '4542',
    name: '4542 - Vending machine operators',
  },
  {
    id: '45421',
    name: '45421 - Vending machine operators',
  },
  {
    id: '454210',
    name: '454210 - Vending machine operators',
  },
  {
    id: '4543',
    name: '4543 - Direct selling establishments',
  },
  {
    id: '45431',
    name: '45431 - Fuel dealers',
  },
  {
    id: '454310',
    name: '454310 - Fuel dealers',
  },
  {
    id: '45439',
    name: '45439 - Other direct selling establishments',
  },
  {
    id: '454390',
    name: '454390 - Other direct selling establishments',
  },
  { id: '48', name: '48 - Transportation and warehousing' },
  { id: '481', name: '481 - Air transportation' },
  {
    id: '4811',
    name: '4811 - Scheduled air transportation',
  },
  {
    id: '48111',
    name: '48111 - Scheduled air transportation',
  },
  {
    id: '481111',
    name: '481111 - Scheduled passenger air transportation',
  },
  {
    id: '481112',
    name: '481112 - Scheduled freight air transportation',
  },
  {
    id: '4812',
    name: '4812 - Nonscheduled air transportation',
  },
  {
    id: '48121',
    name: '48121 - Nonscheduled air transportation',
  },
  {
    id: '481211',
    name: '481211 - Nonscheduled chartered passenger air transportation',
  },
  {
    id: '481212',
    name: '481212 - Nonscheduled chartered freight air transportation',
  },
  {
    id: '481219',
    name: '481219 - Other nonscheduled air transportation',
  },
  {
    id: '482',
    name: '482 - Rail transportation',
  },
  {
    id: '4821',
    name: '4821 - Rail transportation',
  },
  {
    id: '48211',
    name: '48211 - Rail transportation',
  },
  {
    id: '482111',
    name: '482111 - Line-haul railroads',
  },
  {
    id: '482112',
    name: '482112 - Short line railroads',
  },
  {
    id: '483',
    name: '483 - Water transportation',
  },
  {
    id: '4831',
    name: '4831 - Deep Sea, coastal, and Great Lakes water transportation',
  },
  {
    id: '48311',
    name: '48311 - Deep sea, coastal, and Great Lakes water transportation',
  },
  {
    id: '483111',
    name: '483111 - Deep sea freight transportation',
  },
  {
    id: '483112',
    name: '483112 - Deep sea passenger transportation',
  },
  {
    id: '483113',
    name: '483113 - Coastal and Great Lakes freight transportation',
  },
  {
    id: '483114',
    name: '483114 - Coastal and Great Lakes passenger transportation',
  },
  {
    id: '4832',
    name: '4832 - Inland water transportation',
  },
  {
    id: '48321',
    name: '48321 - Inland water transportation',
  },
  {
    id: '483211',
    name: '483211 - Inland water freight transportation',
  },
  {
    id: '483212',
    name: '483212 - Inland water passenger transportation',
  },
  {
    id: '484',
    name: '484 - Truck transportation',
  },
  {
    id: '4841',
    name: '4841 - General freight trucking',
  },
  {
    id: '48411',
    name: '48411 - General freight trucking, local',
  },
  {
    id: '484110',
    name: '484110 - General freight trucking, local',
  },
  {
    id: '48412',
    name: '48412 - General freight trucking, long-distance',
  },
  {
    id: '484121',
    name: '484121 - General freight trucking, long-distance, truckload',
  },
  {
    id: '484122',
    name: '484122 - General freight trucking, long-distance, less than truckload',
  },
  {
    id: '4842',
    name: '4842 - Specialized freight trucking',
  },
  {
    id: '48421',
    name: '48421 - Used household and office goods moving',
  },
  {
    id: '484210',
    name: '484210 - Used household and office goods moving',
  },
  {
    id: '48422',
    name: '48422 - Specialized freight (except used goods) trucking, local',
  },
  {
    id: '484220',
    name: '484220 - Specialized freight (except used goods) trucking, local',
  },
  {
    id: '48423',
    name: '48423 - Specialized freight (except used goods) trucking, long-distance',
  },
  {
    id: '484230',
    name: '484230 - Specialized freight (except used goods) trucking, long-distance',
  },
  {
    id: '485',
    name: '485 - Transit and ground passenger transportation',
  },
  {
    id: '4851',
    name: '4851 - Urban transit systems',
  },
  {
    id: '48511',
    name: '48511 - Urban transit systems',
  },
  {
    id: '485111',
    name: '485111 - Mixed mode transit systems',
  },
  {
    id: '485112',
    name: '485112 - Commuter rail systems',
  },
  {
    id: '485113',
    name: '485113 - Bus and other motor vehicle transit systems',
  },
  {
    id: '485119',
    name: '485119 - Other urban transit systems',
  },
  {
    id: '4852',
    name: '4852 - Interurban and rural bus transportation',
  },
  {
    id: '48521',
    name: '48521 - Interurban and rural bus transportation',
  },
  {
    id: '485210',
    name: '485210 - Interurban and rural bus transportation',
  },
  {
    id: '4853',
    name: '4853 - Taxi and limousine service',
  },
  {
    id: '48531',
    name: '48531 - Taxi service',
  },
  {
    id: '485310',
    name: '485310 - Taxi service',
  },
  {
    id: '48532',
    name: '48532 - Limousine service',
  },
  {
    id: '485320',
    name: '485320 - Limousine service',
  },
  {
    id: '4854',
    name: '4854 - School and employee bus transportation',
  },
  {
    id: '48541',
    name: '48541 - School and employee bus transportation',
  },
  {
    id: '485410',
    name: '485410 - School and employee bus transportation',
  },
  {
    id: '4855',
    name: '4855 - Charter bus industry',
  },
  {
    id: '48551',
    name: '48551 - Charter bus industry',
  },
  {
    id: '485510',
    name: '485510 - Charter bus industry',
  },
  {
    id: '4859',
    name: '4859 - Other transit and ground passenger transportation',
  },
  {
    id: '48599',
    name: '48599 - Other transit and ground passenger transportation',
  },
  {
    id: '485991',
    name: '485991 - Special needs transportation',
  },
  {
    id: '485999',
    name: '485999 - All other transit and ground passenger transportation',
  },
  {
    id: '486',
    name: '486 - Pipeline transportation',
  },
  {
    id: '4861',
    name: '4861 - Pipeline transportation of crude oil',
  },
  {
    id: '48611',
    name: '48611 - Pipeline transportation of crude oil',
  },
  {
    id: '486110',
    name: '486110 - Pipeline transportation of crude oil',
  },
  {
    id: '4862',
    name: '4862 - Pipeline transportation of natural gas',
  },
  {
    id: '48621',
    name: '48621 - Pipeline transportation of natural gas',
  },
  {
    id: '486210',
    name: '486210 - Pipeline transportation of natural gas',
  },
  {
    id: '4869',
    name: '4869 - Other pipeline transportation',
  },
  {
    id: '48691',
    name: '48691 - Pipeline transportation of refined petroleum products',
  },
  {
    id: '486910',
    name: '486910 - Pipeline transportation of refined petroleum products',
  },
  {
    id: '48699',
    name: '48699 - All other pipeline transportation',
  },
  {
    id: '486990',
    name: '486990 - All other pipeline transportation',
  },
  {
    id: '487',
    name: '487 - Scenic and sightseeing transportation',
  },
  {
    id: '4871',
    name: '4871 - Scenic and sightseeing transportation, land',
  },
  {
    id: '48711',
    name: '48711 - Scenic and sightseeing transportation, land',
  },
  {
    id: '487110',
    name: '487110 - Scenic and sightseeing transportation, land',
  },
  {
    id: '4872',
    name: '4872 - Scenic and sightseeing transportation, water',
  },
  {
    id: '48721',
    name: '48721 - Scenic and sightseeing transportation, water',
  },
  {
    id: '487210',
    name: '487210 - Scenic and sightseeing transportation, water',
  },
  {
    id: '4879',
    name: '4879 - Scenic and sightseeing transportation, other',
  },
  {
    id: '48799',
    name: '48799 - Scenic and sightseeing transportation, other',
  },
  {
    id: '487990',
    name: '487990 - Scenic and sightseeing transportation, other',
  },
  {
    id: '488',
    name: '488 - Support activities for transportation',
  },
  {
    id: '4881',
    name: '4881 - Support activities for air transportation',
  },
  {
    id: '48811',
    name: '48811 - Airport operations',
  },
  {
    id: '488111',
    name: '488111 - Air traffic control',
  },
  {
    id: '488119',
    name: '488119 - Other airport operations',
  },
  {
    id: '48819',
    name: '48819 - Other support activities for air transportation',
  },
  {
    id: '488190',
    name: '488190 - Other support activities for air transportation',
  },
  {
    id: '4882',
    name: '4882 - Support activities for rail transportation',
  },
  {
    id: '48821',
    name: '48821 - Support activities for rail transportation',
  },
  {
    id: '488210',
    name: '488210 - Support activities for rail transportation',
  },
  {
    id: '4883',
    name: '4883 - Support activities for water transportation',
  },
  {
    id: '48831',
    name: '48831 - Port and harbor operations',
  },
  {
    id: '488310',
    name: '488310 - Port and harbor operations',
  },
  {
    id: '48832',
    name: '48832 - Marine cargo handling',
  },
  {
    id: '488320',
    name: '488320 - Marine cargo handling',
  },
  {
    id: '48833',
    name: '48833 - Navigational services to shipping',
  },
  {
    id: '488330',
    name: '488330 - Navigational services to shipping',
  },
  {
    id: '48839',
    name: '48839 - Other support activities for water transportation',
  },
  {
    id: '488390',
    name: '488390 - Other support activities for water transportation',
  },
  {
    id: '4884',
    name: '4884 - Support activities for road transportation',
  },
  {
    id: '48841',
    name: '48841 - Motor vehicle towing',
  },
  {
    id: '488410',
    name: '488410 - Motor vehicle towing',
  },
  {
    id: '48849',
    name: '48849 - Other support activities for road transportation',
  },
  {
    id: '488490',
    name: '488490 - Other support activities for road transportation',
  },
  {
    id: '4885',
    name: '4885 - Freight transportation arrangement',
  },
  {
    id: '48851',
    name: '48851 - Freight transportation arrangement',
  },
  {
    id: '488510',
    name: '488510 - Freight transportation arrangement',
  },
  {
    id: '4889',
    name: '4889 - Other support activities for transportation',
  },
  {
    id: '48899',
    name: '48899 - Other support activities for transportation',
  },
  {
    id: '488991',
    name: '488991 - Packing and crating',
  },
  {
    id: '488999',
    name: '488999 - All other support activities for transportation',
  },
  { id: '49', name: '49 - Transportation and warehousing' },
  { id: '491', name: '491 - Postal service' },
  {
    id: '4911',
    name: '4911 - Postal service',
  },
  {
    id: '49111',
    name: '49111 - Postal service',
  },
  {
    id: '491110',
    name: '491110 - Postal service',
  },
  {
    id: '492',
    name: '492 - Couriers and messengers',
  },
  {
    id: '4921',
    name: '4921 - Couriers and express delivery services',
  },
  {
    id: '49211',
    name: '49211 - Couriers and express delivery services',
  },
  {
    id: '492110',
    name: '492110 - Couriers and express delivery services',
  },
  {
    id: '4922',
    name: '4922 - Local messengers and local delivery',
  },
  {
    id: '49221',
    name: '49221 - Local messengers and local delivery',
  },
  {
    id: '492210',
    name: '492210 - Local messengers and local delivery',
  },
  {
    id: '493',
    name: '493 - Warehousing and storage',
  },
  {
    id: '4931',
    name: '4931 - Warehousing and storage',
  },
  {
    id: '49311',
    name: '49311 - General warehousing and storage',
  },
  {
    id: '493110',
    name: '493110 - General warehousing and storage',
  },
  {
    id: '49312',
    name: '49312 - Refrigerated warehousing and storage',
  },
  {
    id: '493120',
    name: '493120 - Refrigerated warehousing and storage',
  },
  {
    id: '49313',
    name: '49313 - Farm product warehousing and storage',
  },
  {
    id: '493130',
    name: '493130 - Farm product warehousing and storage',
  },
  {
    id: '49319',
    name: '49319 - Other warehousing and storage',
  },
  {
    id: '493190',
    name: '493190 - Other warehousing and storage',
  },
  { id: '51', name: '51 - Information' },
  {
    id: '511',
    name: '511 - Publishing industries (except internet)',
  },
  {
    id: '5111',
    name: '5111 - Newspaper, periodical, book, and directory publishers',
  },
  {
    id: '51111',
    name: '51111 - Newspaper publishers',
  },
  {
    id: '511110',
    name: '511110 - Newspaper publishers',
  },
  {
    id: '51112',
    name: '51112 - Periodical publishers',
  },
  {
    id: '511120',
    name: '511120 - Periodical publishers',
  },
  {
    id: '51113',
    name: '51113 - Book publishers',
  },
  {
    id: '511130',
    name: '511130 - Book publishers',
  },
  {
    id: '51114',
    name: '51114 - Directory and mailing list publishers',
  },
  {
    id: '511140',
    name: '511140 - Directory and mailing list publishers',
  },
  {
    id: '51119',
    name: '51119 - Other publishers',
  },
  {
    id: '511191',
    name: '511191 - Greeting card publishers',
  },
  {
    id: '511199',
    name: '511199 - All other publishers',
  },
  {
    id: '5112',
    name: '5112 - Software publishers',
  },
  {
    id: '51121',
    name: '51121 - Software publishers',
  },
  {
    id: '511210',
    name: '511210 - Software publishers',
  },
  {
    id: '512',
    name: '512 - Motion picture and sound recording industries',
  },
  {
    id: '5121',
    name: '5121 - Motion picture and video industries',
  },
  {
    id: '51211',
    name: '51211 - Motion picture and video production',
  },
  {
    id: '512110',
    name: '512110 - Motion picture and video production',
  },
  {
    id: '51212',
    name: '51212 - Motion picture and video distribution',
  },
  {
    id: '512120',
    name: '512120 - Motion picture and video distribution',
  },
  {
    id: '51213',
    name: '51213 - Motion picture and video exhibition',
  },
  {
    id: '512131',
    name: '512131 - Motion picture theaters (except drive-ins)',
  },
  {
    id: '512132',
    name: '512132 - Drive-in motion picture theaters',
  },
  {
    id: '51219',
    name: '51219 - Postproduction services and other motion picture and video industries',
  },
  {
    id: '512191',
    name: '512191 - Teleproduction and other postproduction services',
  },
  {
    id: '512199',
    name: '512199 - Other motion picture and video industries',
  },
  {
    id: '5122',
    name: '5122 - Sound recording industries',
  },
  {
    id: '51223',
    name: '51223 - Music publishers',
  },
  {
    id: '512230',
    name: '512230 - Music publishers',
  },
  {
    id: '51224',
    name: '51224 - Sound recording studios',
  },
  {
    id: '512240',
    name: '512240 - Sound recording studios',
  },
  {
    id: '51225',
    name: '51225 - Record production and distribution',
  },
  {
    id: '512250',
    name: '512250 - Record production and distribution',
  },
  {
    id: '51229',
    name: '51229 - Other sound recording industries',
  },
  {
    id: '512290',
    name: '512290 - Other sound recording industries',
  },
  {
    id: '515',
    name: '515 - Broadcasting (except Internet)',
  },
  {
    id: '5151',
    name: '5151 - Radio and television broadcasting',
  },
  {
    id: '51511',
    name: '51511 - Radio broadcasting',
  },
  {
    id: '515111',
    name: '515111 - Radio networks',
  },
  {
    id: '515112',
    name: '515112 - Radio stations',
  },
  {
    id: '51512',
    name: '51512 - Television broadcasting',
  },
  {
    id: '515120',
    name: '515120 - Television broadcasting',
  },
  {
    id: '5152',
    name: '5152 - Cable and other subscription programming',
  },
  {
    id: '51521',
    name: '51521 - Cable and other subscription programming',
  },
  {
    id: '515210',
    name: '515210 - Cable and other subscription programming',
  },
  { id: '517', name: '517 - Telecommunications' },
  {
    id: '5173',
    name: '5173 - Wired and wireless telecommunications carriers',
  },
  {
    id: '51731',
    name: '51731 - Wired and wireless telecommunications carriers',
  },
  {
    id: '517311',
    name: '517311 - Wired telecommunications carriers',
  },
  {
    id: '517312',
    name: '517312 - Wireless telecommunications carriers (except satellite)',
  },
  {
    id: '5174',
    name: '5174 - Satellite telecommunications',
  },
  {
    id: '51741',
    name: '51741 - Satellite telecommunications',
  },
  {
    id: '517410',
    name: '517410 - Satellite telecommunications',
  },
  {
    id: '5179',
    name: '5179 - Other telecommunications',
  },
  {
    id: '51791',
    name: '51791 - Other telecommunications',
  },
  {
    id: '517911',
    name: '517911 - Telecommunications resellers',
  },
  {
    id: '517919',
    name: '517919 - All other telecommunications',
  },
  {
    id: '518',
    name: '518 - Data processing, hosting, and related services',
  },
  {
    id: '5182',
    name: '5182 - Data processing, hosting, and related services',
  },
  {
    id: '51821',
    name: '51821 - Data processing, hosting, and related services',
  },
  {
    id: '518210',
    name: '518210 - Data processing, hosting, and related services',
  },
  {
    id: '519',
    name: '519 - Other information services',
  },
  {
    id: '5191',
    name: '5191 - Other information services',
  },
  {
    id: '51911',
    name: '51911 - News syndicates',
  },
  {
    id: '519110',
    name: '519110 - News syndicates',
  },
  {
    id: '51912',
    name: '51912 - Libraries and archives',
  },
  {
    id: '519120',
    name: '519120 - Libraries and archives',
  },
  {
    id: '51913',
    name: '51913 - Internet publishing and broadcasting and web search portals',
  },
  {
    id: '519130',
    name: '519130 - Internet publishing and broadcasting and web search portals',
  },
  {
    id: '51919',
    name: '51919 - All other information services',
  },
  {
    id: '519190',
    name: '519190 - All other information services',
  },
  { id: '52', name: '52 - Finance and insurance' },
  {
    id: '521',
    name: '521 - Monetary authorities-Central Bank',
  },
  {
    id: '5211',
    name: '5211 - Monetary authorities-Central Bank',
  },
  {
    id: '52111',
    name: '52111 - Monetary authorities-Central Bank',
  },
  {
    id: '521110',
    name: '521110 - Monetary authorities-Central Bank',
  },
  {
    id: '522',
    name: '522 - Credit intermediation and related activities',
  },
  {
    id: '5221',
    name: '5221 - Depository credit intermediation',
  },
  {
    id: '52211',
    name: '52211 - Commercial banking',
  },
  {
    id: '522110',
    name: '522110 - Commercial banking',
  },
  {
    id: '52212',
    name: '52212 - Savings institutions',
  },
  {
    id: '522120',
    name: '522120 - Savings institutions',
  },
  {
    id: '52213',
    name: '52213 - Credit unions',
  },
  {
    id: '522130',
    name: '522130 - Credit unions',
  },
  {
    id: '52219',
    name: '52219 - Other depository credit intermediation',
  },
  {
    id: '522190',
    name: '522190 - Other depository credit intermediation',
  },
  {
    id: '5222',
    name: '5222 - Nondepository credit intermediation',
  },
  {
    id: '52221',
    name: '52221 - Credit card issuing',
  },
  {
    id: '522210',
    name: '522210 - Credit card issuing',
  },
  {
    id: '52222',
    name: '52222 - Sales financing',
  },
  {
    id: '522220',
    name: '522220 - Sales financing',
  },
  {
    id: '52229',
    name: '52229 - Other nondepository credit intermediation',
  },
  {
    id: '522291',
    name: '522291 - Consumer lending',
  },
  {
    id: '522292',
    name: '522292 - Real estate credit',
  },
  {
    id: '522293',
    name: '522293 - International trade financing',
  },
  {
    id: '522294',
    name: '522294 - Secondary market financing',
  },
  {
    id: '522298',
    name: '522298 - All other nondepository credit intermediation',
  },
  {
    id: '5223',
    name: '5223 - Activities related to credit intermediation',
  },
  {
    id: '52231',
    name: '52231 - Mortgage and nonmortgage loan brokers',
  },
  {
    id: '522310',
    name: '522310 - Mortgage and nonmortgage loan brokers',
  },
  {
    id: '52232',
    name: '52232 - Financial transactions processing, reserve, and clearinghouse activities',
  },
  {
    id: '522320',
    name: '522320 - Financial transactions processing, reserve, and clearinghouse activities',
  },
  {
    id: '52239',
    name: '52239 - Other activities related to credit intermediation',
  },
  {
    id: '522390',
    name: '522390 - Other activities related to credit intermediation',
  },
  {
    id: '523',
    name: '523 - Securities, commodity contracts, and other financial investments and related activities',
  },
  {
    id: '5231',
    name: '5231 - Securities and commodity contracts intermediation and brokerage',
  },
  {
    id: '52311',
    name: '52311 - Investment banking and securities dealing',
  },
  {
    id: '523110',
    name: '523110 - Investment banking and securities dealing',
  },
  {
    id: '52312',
    name: '52312 - Securities brokerage',
  },
  {
    id: '523120',
    name: '523120 - Securities brokerage',
  },
  {
    id: '52313',
    name: '52313 - Commodity contracts dealing',
  },
  {
    id: '523130',
    name: '523130 - Commodity contracts dealing',
  },
  {
    id: '52314',
    name: '52314 - Commodity contracts brokerage',
  },
  {
    id: '523140',
    name: '523140 - Commodity contracts brokerage',
  },
  {
    id: '5232',
    name: '5232 - Securities and commodity exchanges',
  },
  {
    id: '52321',
    name: '52321 - Securities and commodity exchanges',
  },
  {
    id: '523210',
    name: '523210 - Securities and commodity exchanges',
  },
  {
    id: '5239',
    name: '5239 - Other financial investment activities',
  },
  {
    id: '52391',
    name: '52391 - Miscellaneous intermediation',
  },
  {
    id: '523910',
    name: '523910 - Miscellaneous intermediation',
  },
  {
    id: '52392',
    name: '52392 - Portfolio management',
  },
  {
    id: '523920',
    name: '523920 - Portfolio management',
  },
  {
    id: '52393',
    name: '52393 - Investment advice',
  },
  {
    id: '523930',
    name: '523930 - Investment advice',
  },
  {
    id: '52399',
    name: '52399 - All other financial investment activities',
  },
  {
    id: '523991',
    name: '523991 - Trust, fiduciary, and custody activities',
  },
  {
    id: '523999',
    name: '523999 - Miscellaneous financial investment activities',
  },
  {
    id: '524',
    name: '524 - Insurance carriers and related activities',
  },
  {
    id: '5241',
    name: '5241 - Insurance carriers',
  },
  {
    id: '52411',
    name: '52411 - Direct life, health, and medical insurance carriers',
  },
  {
    id: '524113',
    name: '524113 - Direct life insurance carriers',
  },
  {
    id: '524114',
    name: '524114 - Direct health and medical insurance carriers',
  },
  {
    id: '52412',
    name: '52412 - Direct insurance (except life, health, and medical) carriers',
  },
  {
    id: '524126',
    name: '524126 - Direct property and casualty insurance carriers',
  },
  {
    id: '524127',
    name: '524127 - Direct title insurance carriers',
  },
  {
    id: '524128',
    name: '524128 - Other direct insurance (except life, health, and medical) carriers',
  },
  {
    id: '52413',
    name: '52413 - Reinsurance carriers',
  },
  {
    id: '524130',
    name: '524130 - Reinsurance carriers',
  },
  {
    id: '5242',
    name: '5242 - Agencies, brokerages, and other insurance related activities',
  },
  {
    id: '52421',
    name: '52421 - Insurance agencies and brokerages',
  },
  {
    id: '524210',
    name: '524210 - Insurance agencies and brokerages',
  },
  {
    id: '52429',
    name: '52429 - Other insurance related activities',
  },
  {
    id: '524291',
    name: '524291 - Claims adjusting',
  },
  {
    id: '524292',
    name: '524292 - Third party administration of insurance and pension funds',
  },
  {
    id: '524298',
    name: '524298 - All other insurance related activities',
  },
  {
    id: '525',
    name: '525 - Funds, trusts, and other financial vehicles',
  },
  {
    id: '5251',
    name: '5251 - Insurance and employee benefit funds',
  },
  {
    id: '52511',
    name: '52511 - Pension funds',
  },
  {
    id: '525110',
    name: '525110 - Pension funds',
  },
  {
    id: '52512',
    name: '52512 - Health and welfare funds',
  },
  {
    id: '525120',
    name: '525120 - Health and welfare funds',
  },
  {
    id: '52519',
    name: '52519 - Other insurance funds',
  },
  {
    id: '525190',
    name: '525190 - Other insurance funds',
  },
  {
    id: '5259',
    name: '5259 - Other investment pools and funds',
  },
  {
    id: '52591',
    name: '52591 - Open-end investment funds',
  },
  {
    id: '525910',
    name: '525910 - Open-end investment funds',
  },
  {
    id: '52592',
    name: '52592 - Trusts, estates, and agency accounts',
  },
  {
    id: '525920',
    name: '525920 - Trusts, estates, and agency accounts',
  },
  {
    id: '52599',
    name: '52599 - Other financial vehicles',
  },
  {
    id: '525990',
    name: '525990 - Other financial vehicles',
  },
  { id: '53', name: '53 - Real estate and rental and leasing' },
  { id: '531', name: '531 - Real estate' },
  {
    id: '5311',
    name: '5311 - Lessors of real estate',
  },
  {
    id: '53111',
    name: '53111 - Lessors of residential buildings and dwellings',
  },
  {
    id: '531110',
    name: '531110 - Lessors of residential buildings and dwellings',
  },
  {
    id: '53112',
    name: '53112 - Lessors of nonresidential buildings (except miniwarehouses)',
  },
  {
    id: '531120',
    name: '531120 - Lessors of nonresidential buildings (except miniwarehouses)',
  },
  {
    id: '53113',
    name: '53113 - Lessors of miniwarehouses and self-storage units',
  },
  {
    id: '531130',
    name: '531130 - Lessors of miniwarehouses and self-storage units',
  },
  {
    id: '53119',
    name: '53119 - Lessors of other real estate property',
  },
  {
    id: '531190',
    name: '531190 - Lessors of other real estate property',
  },
  {
    id: '5312',
    name: '5312 - Offices of real estate agents and brokers',
  },
  {
    id: '53121',
    name: '53121 - Offices of real estate agents and brokers',
  },
  {
    id: '531210',
    name: '531210 - Offices of real estate agents and brokers',
  },
  {
    id: '5313',
    name: '5313 - Activities related to real estate',
  },
  {
    id: '53131',
    name: '53131 - Real estate property managers',
  },
  {
    id: '531311',
    name: '531311 - Residential property managers',
  },
  {
    id: '531312',
    name: '531312 - Nonresidential property managers',
  },
  {
    id: '53132',
    name: '53132 - Offices of real estate appraisers',
  },
  {
    id: '531320',
    name: '531320 - Offices of real estate appraisers',
  },
  {
    id: '53139',
    name: '53139 - Other activities related to real estate',
  },
  {
    id: '531390',
    name: '531390 - Other activities related to real estate',
  },
  {
    id: '532',
    name: '532 - Rental and leasing services',
  },
  {
    id: '5321',
    name: '5321 - Automotive equipment rental and leasing',
  },
  {
    id: '53211',
    name: '53211 - Passenger car rental and leasing',
  },
  {
    id: '532111',
    name: '532111 - Passenger car rental',
  },
  {
    id: '532112',
    name: '532112 - Passenger car leasing',
  },
  {
    id: '53212',
    name: '53212 - Truck, utility trailer, and RV (recreational vehicle) rental and leasing',
  },
  {
    id: '532120',
    name: '532120 - Truck, utility trailer, and RV (recreational vehicle) rental and leasing',
  },
  {
    id: '5322',
    name: '5322 - Consumer goods rental',
  },
  {
    id: '53221',
    name: '53221 - Consumer electronics and appliances rental',
  },
  {
    id: '532210',
    name: '532210 - Consumer electronics and appliances rental',
  },
  {
    id: '53228',
    name: '53228 - Other consumer goods rental',
  },
  {
    id: '532281',
    name: '532281 - Formal wear and constume rental',
  },
  {
    id: '532282',
    name: '532282 - Video tape and disc rental',
  },
  {
    id: '532283',
    name: '532283 - Home health equipment rental',
  },
  {
    id: '532284',
    name: '532284 - Recreational goods rental',
  },
  {
    id: '532289',
    name: '532289 - All other consumer goods rental',
  },
  {
    id: '5323',
    name: '5323 - General rental centers',
  },
  {
    id: '53231',
    name: '53231 - General rental centers',
  },
  {
    id: '532310',
    name: '532310 - General rental centers',
  },
  {
    id: '5324',
    name: '5324 - Commercial and industrial machinery and equipment rental and leasing',
  },
  {
    id: '53241',
    name: '53241 - Construction, transportation, mining, and forestry machinery and equipment rental and leasing',
  },
  {
    id: '532411',
    name: '532411 - Commercial air, rail, and water transportation equipment rental and leasing',
  },
  {
    id: '532412',
    name: '532412 - Construction, mining, and forestry machinery and equipment rental and leasing',
  },
  {
    id: '53242',
    name: '53242 - Office machinery and equipment rental and leasing',
  },
  {
    id: '532420',
    name: '532420 - Office machinery and equipment rental and leasing',
  },
  {
    id: '53249',
    name: '53249 - Other commercial and industrial machinery and equipment rental and leasing',
  },
  {
    id: '532490',
    name: '532490 - Other commercial and industrial machinery and equipment rental and leasing',
  },
  {
    id: '533',
    name: '533 - Lessors of nonfinancial intangible assets (except copyrighted works)',
  },
  {
    id: '5331',
    name: '5331 - Lessors of nonfinancial intangible assets (except copyrighted works)',
  },
  {
    id: '53311',
    name: '53311 - Lessors of nonfinancial intangible assets (except copyrighted works)',
  },
  {
    id: '533110',
    name: '533110 - Lessors of nonfinancial intangible assets (except copyrighted works)',
  },
  {
    id: '54',
    name: '54 - Professional, scientific, and technical services',
  },
  {
    id: '541',
    name: '541 - Professional, scientific, and technical services',
  },
  {
    id: '5411',
    name: '5411 - Legal services',
  },
  {
    id: '54111',
    name: '54111 - Offices of lawyers',
  },
  {
    id: '541110',
    name: '541110 - Offices of lawyers',
  },
  {
    id: '54112',
    name: '54112 - Offices of notaries',
  },
  {
    id: '541120',
    name: '541120 - Offices of notaries',
  },
  {
    id: '54119',
    name: '54119 - Other legal services',
  },
  {
    id: '541191',
    name: '541191 - Title abstract and settlement offices',
  },
  {
    id: '541199',
    name: '541199 - All other legal services',
  },
  {
    id: '5412',
    name: '5412 - Accounting, tax preparation, bookkeeping, and payroll services',
  },
  {
    id: '54121',
    name: '54121 - Accounting, tax preparation, bookkeeping, and payroll services',
  },
  {
    id: '541211',
    name: '541211 - Offices of certified public accountants',
  },
  {
    id: '541213',
    name: '541213 - Tax preparation services',
  },
  {
    id: '541214',
    name: '541214 - Payroll services',
  },
  {
    id: '541219',
    name: '541219 - Other accounting services',
  },
  {
    id: '5413',
    name: '5413 - Architectural, engineering, and related services',
  },
  {
    id: '54131',
    name: '54131 - Architectural services',
  },
  {
    id: '541310',
    name: '541310 - Architectural services',
  },
  {
    id: '54132',
    name: '54132 - Landscape architectural services',
  },
  {
    id: '541320',
    name: '541320 - Landscape architectural services',
  },
  {
    id: '54133',
    name: '54133 - Engineering services',
  },
  {
    id: '541330',
    name: '541330 - Engineering services',
  },
  {
    id: '54134',
    name: '54134 - Drafting services',
  },
  {
    id: '541340',
    name: '541340 - Drafting services',
  },
  {
    id: '54135',
    name: '54135 - Building inspection services',
  },
  {
    id: '541350',
    name: '541350 - Building inspection services',
  },
  {
    id: '54136',
    name: '54136 - Geophysical surveying and mapping services',
  },
  {
    id: '541360',
    name: '541360 - Geophysical surveying and mapping services',
  },
  {
    id: '54137',
    name: '54137 - Surveying and mapping (except geophysical) services',
  },
  {
    id: '541370',
    name: '541370 - Surveying and mapping (except geophysical) services',
  },
  {
    id: '54138',
    name: '54138 - Testing laboratories',
  },
  {
    id: '541380',
    name: '541380 - Testing laboratories',
  },
  {
    id: '5414',
    name: '5414 - Specialized design services',
  },
  {
    id: '54141',
    name: '54141 - Interior design services',
  },
  {
    id: '541410',
    name: '541410 - Interior design services',
  },
  {
    id: '54142',
    name: '54142 - Industrial design services',
  },
  {
    id: '541420',
    name: '541420 - Industrial design services',
  },
  {
    id: '54143',
    name: '54143 - Graphic design services',
  },
  {
    id: '541430',
    name: '541430 - Graphic design services',
  },
  {
    id: '54149',
    name: '54149 - Other specialized design services',
  },
  {
    id: '541490',
    name: '541490 - Other specialized design services',
  },
  {
    id: '5415',
    name: '5415 - Computer systems design and related services',
  },
  {
    id: '54151',
    name: '54151 - Computer systems design and related services',
  },
  {
    id: '541511',
    name: '541511 - Custom computer programming services',
  },
  {
    id: '541512',
    name: '541512 - Computer systems design services',
  },
  {
    id: '541513',
    name: '541513 - Computer facilities management services',
  },
  {
    id: '541519',
    name: '541519 - Other computer related services',
  },
  {
    id: '5416',
    name: '5416 - Management, scientific, and technical consulting services',
  },
  {
    id: '54161',
    name: '54161 - Management consulting services',
  },
  {
    id: '541611',
    name: '541611 - Administrative management and general management consulting services',
  },
  {
    id: '541612',
    name: '541612 - Human resources consulting services',
  },
  {
    id: '541613',
    name: '541613 - Marketing consulting services',
  },
  {
    id: '541614',
    name: '541614 - Process, physical distribution, and logistics consulting services',
  },
  {
    id: '541618',
    name: '541618 - Other management consulting services',
  },
  {
    id: '54162',
    name: '54162 - Environmental consulting services',
  },
  {
    id: '541620',
    name: '541620 - Environmental consulting services',
  },
  {
    id: '54169',
    name: '54169 - Other scientific and technical consulting services',
  },
  {
    id: '541690',
    name: '541690 - Other scientific and technical consulting services',
  },
  {
    id: '5417',
    name: '5417 - Scientific research and development services',
  },
  {
    id: '54171',
    name: '54171 - Research and development in the physical, engineering, and life sciences',
  },
  {
    id: '541713',
    name: '541713 - Research and development in nanotechnology',
  },
  {
    id: '541714',
    name: '541714 - Research and development in biotechnology (except nanobiotechnology)',
  },
  {
    id: '541715',
    name: '541715 - Research and development in the physical, engineering, and life sciences (except nanotechnology and biotechnology)',
  },
  {
    id: '54172',
    name: '54172 - Research and development in the social sciences and humanities',
  },
  {
    id: '541720',
    name: '541720 - Research and development in the social sciences and humanities',
  },
  {
    id: '5418',
    name: '5418 - Advertising, public relations, and related services',
  },
  {
    id: '54181',
    name: '54181 - Advertising agencies',
  },
  {
    id: '541810',
    name: '541810 - Advertising agencies',
  },
  {
    id: '54182',
    name: '54182 - Public relations agencies',
  },
  {
    id: '541820',
    name: '541820 - Public relations agencies',
  },
  {
    id: '54183',
    name: '54183 - Media buying agencies',
  },
  {
    id: '541830',
    name: '541830 - Media buying agencies',
  },
  {
    id: '54184',
    name: '54184 - Media representatives',
  },
  {
    id: '541840',
    name: '541840 - Media representatives',
  },
  {
    id: '54185',
    name: '54185 - Outdoor advertising',
  },
  {
    id: '541850',
    name: '541850 - Outdoor advertising',
  },
  {
    id: '54186',
    name: '54186 - Direct mail advertising',
  },
  {
    id: '541860',
    name: '541860 - Direct mail advertising',
  },
  {
    id: '54187',
    name: '54187 - Advertising material distribution services',
  },
  {
    id: '541870',
    name: '541870 - Advertising material distribution services',
  },
  {
    id: '54189',
    name: '54189 - Other services related to advertising',
  },
  {
    id: '541890',
    name: '541890 - Other services related to advertising',
  },
  {
    id: '5419',
    name: '5419 - Other professional, scientific, and technical services',
  },
  {
    id: '54191',
    name: '54191 - Marketing research and public opinion polling',
  },
  {
    id: '541910',
    name: '541910 - Marketing research and public opinion polling',
  },
  {
    id: '54192',
    name: '54192 - Photographic services',
  },
  {
    id: '541921',
    name: '541921 - Photography studios, portrait',
  },
  {
    id: '541922',
    name: '541922 - Commercial photography',
  },
  {
    id: '54193',
    name: '54193 - Translation and interpretation services',
  },
  {
    id: '541930',
    name: '541930 - Translation and interpretation services',
  },
  {
    id: '54194',
    name: '54194 - Veterinary services',
  },
  {
    id: '541940',
    name: '541940 - Veterinary services',
  },
  {
    id: '54199',
    name: '54199 - All other professional, scientific, and technical services',
  },
  {
    id: '541990',
    name: '541990 - All other professional, scientific, and technical services',
  },
  { id: '55', name: '55 - Management of companies and enterprises' },
  {
    id: '551',
    name: '551 - Management of companies and enterprises',
  },
  {
    id: '5511',
    name: '5511 - Management of companies and enterprises',
  },
  {
    id: '55111',
    name: '55111 - Management of companies and enterprises',
  },
  {
    id: '551111',
    name: '551111 - Offices of bank holding companies',
  },
  {
    id: '551112',
    name: '551112 - Offices of other holding companies',
  },
  {
    id: '551114',
    name: '551114 - Corporate, subsidiary, and regional managing offices',
  },
  {
    id: '56',
    name: '56 - Administrative and support and waste management and remediation services',
  },
  {
    id: '561',
    name: '561 - Administrative and support services',
  },
  {
    id: '5611',
    name: '5611 - Office administrative services',
  },
  {
    id: '56111',
    name: '56111 - Office administrative services',
  },
  {
    id: '561110',
    name: '561110 - Office administrative services',
  },
  {
    id: '5612',
    name: '5612 - Facilities support services',
  },
  {
    id: '56121',
    name: '56121 - Facilities support services',
  },
  {
    id: '561210',
    name: '561210 - Facilities support services',
  },
  {
    id: '5613',
    name: '5613 - Employment services',
  },
  {
    id: '56131',
    name: '56131 - Employment placement agencies and executive search services',
  },
  {
    id: '561311',
    name: '561311 - Employment placement agencies',
  },
  {
    id: '561312',
    name: '561312 - Executive search services',
  },
  {
    id: '56132',
    name: '56132 - Temporary help services',
  },
  {
    id: '561320',
    name: '561320 - Temporary help services',
  },
  {
    id: '56133',
    name: '56133 - Professional employer organizations',
  },
  {
    id: '561330',
    name: '561330 - Professional employer organizations',
  },
  {
    id: '5614',
    name: '5614 - Business support services',
  },
  {
    id: '56141',
    name: '56141 - Document preparation services',
  },
  {
    id: '561410',
    name: '561410 - Document preparation services',
  },
  {
    id: '56142',
    name: '56142 - Telephone call centers',
  },
  {
    id: '561421',
    name: '561421 - Telephone answering services',
  },
  {
    id: '561422',
    name: '561422 - Telemarketing bureaus and other contact centers',
  },
  {
    id: '56143',
    name: '56143 - Business service centers',
  },
  {
    id: '561431',
    name: '561431 - Private mail centers',
  },
  {
    id: '561439',
    name: '561439 - Other business service centers (including copy shops)',
  },
  {
    id: '56144',
    name: '56144 - Collection agencies',
  },
  {
    id: '561440',
    name: '561440 - Collection agencies',
  },
  {
    id: '56145',
    name: '56145 - Credit bureaus',
  },
  {
    id: '561450',
    name: '561450 - Credit bureaus',
  },
  {
    id: '56149',
    name: '56149 - Other business support services',
  },
  {
    id: '561491',
    name: '561491 - Repossession services',
  },
  {
    id: '561492',
    name: '561492 - Court reporting and stenotype services',
  },
  {
    id: '561499',
    name: '561499 - All other business support services',
  },
  {
    id: '5615',
    name: '5615 - Travel arrangement and reservation services',
  },
  {
    id: '56151',
    name: '56151 - Travel agencies',
  },
  {
    id: '561510',
    name: '561510 - Travel agencies',
  },
  {
    id: '56152',
    name: '56152 - Tour operators',
  },
  {
    id: '561520',
    name: '561520 - Tour operators',
  },
  {
    id: '56159',
    name: '56159 - Other travel arrangement and reservation services',
  },
  {
    id: '561591',
    name: '561591 - Convention and visitors bureaus',
  },
  {
    id: '561599',
    name: '561599 - All other travel arrangement and reservation services',
  },
  {
    id: '5616',
    name: '5616 - Investigation and security services',
  },
  {
    id: '56161',
    name: '56161 - Investigation, guard, and armored car services',
  },
  {
    id: '561611',
    name: '561611 - Investigation services',
  },
  {
    id: '561612',
    name: '561612 - Security guards and patrol services',
  },
  {
    id: '561613',
    name: '561613 - Armored car services',
  },
  {
    id: '56162',
    name: '56162 - Security systems services',
  },
  {
    id: '561621',
    name: '561621 - Security systems services (except locksmiths)',
  },
  {
    id: '561622',
    name: '561622 - Locksmiths',
  },
  {
    id: '5617',
    name: '5617 - Services to buildings and dwellings',
  },
  {
    id: '56171',
    name: '56171 - Exterminating and pest control services',
  },
  {
    id: '561710',
    name: '561710 - Exterminating and pest control services',
  },
  {
    id: '56172',
    name: '56172 - Janitorial services',
  },
  {
    id: '561720',
    name: '561720 - Janitorial services',
  },
  {
    id: '56173',
    name: '56173 - Landscaping services',
  },
  {
    id: '561730',
    name: '561730 - Landscaping services',
  },
  {
    id: '56174',
    name: '56174 - Carpet and upholstery cleaning services',
  },
  {
    id: '561740',
    name: '561740 - Carpet and upholstery cleaning services',
  },
  {
    id: '56179',
    name: '56179 - Other services to buildings and dwellings',
  },
  {
    id: '561790',
    name: '561790 - Other services to buildings and dwellings',
  },
  {
    id: '5619',
    name: '5619 - Other support services',
  },
  {
    id: '56191',
    name: '56191 - Packaging and labeling services',
  },
  {
    id: '561910',
    name: '561910 - Packaging and labeling services',
  },
  {
    id: '56192',
    name: '56192 - Convention and trade show organizers',
  },
  {
    id: '561920',
    name: '561920 - Convention and trade show organizers',
  },
  {
    id: '56199',
    name: '56199 - All other support services',
  },
  {
    id: '561990',
    name: '561990 - All other support services',
  },
  {
    id: '562',
    name: '562 - Waste management and remediation services',
  },
  {
    id: '5621',
    name: '5621 - Waste collection',
  },
  {
    id: '56211',
    name: '56211 - Waste collection',
  },
  {
    id: '562111',
    name: '562111 - Solid waste collection',
  },
  {
    id: '562112',
    name: '562112 - Hazardous waste collection',
  },
  {
    id: '562119',
    name: '562119 - Other waste collection',
  },
  {
    id: '5622',
    name: '5622 - Waste treatment and disposal',
  },
  {
    id: '56221',
    name: '56221 - Waste treatment and disposal',
  },
  {
    id: '562211',
    name: '562211 - Hazardous waste treatment and disposal',
  },
  {
    id: '562212',
    name: '562212 - Solid waste landfill',
  },
  {
    id: '562213',
    name: '562213 - Solid waste combustors and incinerators',
  },
  {
    id: '562219',
    name: '562219 - Other nonhazardous waste treatment and disposal',
  },
  {
    id: '5629',
    name: '5629 - Remediation and other waste management services',
  },
  {
    id: '56291',
    name: '56291 - Remediation services',
  },
  {
    id: '562910',
    name: '562910 - Remediation services',
  },
  {
    id: '56292',
    name: '56292 - Materials recovery facilities',
  },
  {
    id: '562920',
    name: '562920 - Materials recovery facilities',
  },
  {
    id: '56299',
    name: '56299 - All other waste management services',
  },
  {
    id: '562991',
    name: '562991 - Septic tank and related services',
  },
  {
    id: '562998',
    name: '562998 - All other miscellaneous waste management services',
  },
  { id: '61', name: '61 - Educational services' },
  {
    id: '611',
    name: '611 - Educational services',
  },
  {
    id: '6111',
    name: '6111 - Elementary and secondary schools',
  },
  {
    id: '61111',
    name: '61111 - Elementary and Secondary Schools',
  },
  {
    id: '611110',
    name: '611110 - Elementary and secondary schools',
  },
  {
    id: '6112',
    name: '6112 - Junior colleges',
  },
  {
    id: '61121',
    name: '61121 - Junior colleges',
  },
  {
    id: '611210',
    name: '611210 - Junior colleges',
  },
  {
    id: '6113',
    name: '6113 - Colleges, universities, and professional schools',
  },
  {
    id: '61131',
    name: '61131 - Colleges, universities, and professional schools',
  },
  {
    id: '611310',
    name: '611310 - Colleges, universities, and professional schools',
  },
  {
    id: '6114',
    name: '6114 - Business schools and computer and management training',
  },
  {
    id: '61141',
    name: '61141 - Business and secretarial schools',
  },
  {
    id: '611410',
    name: '611410 - Business and secretarial schools',
  },
  {
    id: '61142',
    name: '61142 - Computer training',
  },
  {
    id: '611420',
    name: '611420 - Computer training',
  },
  {
    id: '61143',
    name: '61143 - Professional and management development training',
  },
  {
    id: '611430',
    name: '611430 - Professional and management development training',
  },
  {
    id: '6115',
    name: '6115 - Technical and trade schools',
  },
  {
    id: '61151',
    name: '61151 - Technical and trade schools',
  },
  {
    id: '611511',
    name: '611511 - Cosmetology and barber schools',
  },
  {
    id: '611512',
    name: '611512 - Flight training',
  },
  {
    id: '611513',
    name: '611513 - Apprenticeship training',
  },
  {
    id: '611519',
    name: '611519 - Other technical and trade schools',
  },
  {
    id: '6116',
    name: '6116 - Other schools and instruction',
  },
  {
    id: '61161',
    name: '61161 - Fine arts schools',
  },
  {
    id: '611610',
    name: '611610 - Fine arts schools',
  },
  {
    id: '61162',
    name: '61162 - Sports and recreation instruction',
  },
  {
    id: '611620',
    name: '611620 - Sports and recreation instruction',
  },
  {
    id: '61163',
    name: '61163 - Language schools',
  },
  {
    id: '611630',
    name: '611630 - Language schools',
  },
  {
    id: '61169',
    name: '61169 - All other schools and instruction',
  },
  {
    id: '611691',
    name: '611691 - Exam preparation and tutoring',
  },
  {
    id: '611692',
    name: '611692 - Automobile driving schools',
  },
  {
    id: '611699',
    name: '611699 - All other miscellaneous schools and instruction',
  },
  {
    id: '6117',
    name: '6117 - Educational support services',
  },
  {
    id: '61171',
    name: '61171 - Educational support services',
  },
  {
    id: '611710',
    name: '611710 - Educational support services',
  },
  { id: '62', name: '62 - Health care and social assistance' },
  {
    id: '621',
    name: '621 - Ambulatory health care services',
  },
  {
    id: '6211',
    name: '6211 - Offices of physicians',
  },
  {
    id: '62111',
    name: '62111 - Offices of physicians',
  },
  {
    id: '621111',
    name: '621111 - Offices of physicians (except mental health specialists)',
  },
  {
    id: '621112',
    name: '621112 - Offices of physicians, mental health specialists',
  },
  {
    id: '6212',
    name: '6212 - Offices of dentists',
  },
  {
    id: '62121',
    name: '62121 - Offices of dentists',
  },
  {
    id: '621210',
    name: '621210 - Offices of dentists',
  },
  {
    id: '6213',
    name: '6213 - Offices of other health practitioners',
  },
  {
    id: '62131',
    name: '62131 - Offices of chiropractors',
  },
  {
    id: '621310',
    name: '621310 - Offices of chiropractors',
  },
  {
    id: '62132',
    name: '62132 - Offices of optometrists',
  },
  {
    id: '621320',
    name: '621320 - Offices of optometrists',
  },
  {
    id: '62133',
    name: '62133 - Offices of mental health practitioners (except physicians)',
  },
  {
    id: '621330',
    name: '621330 - Offices of mental health practitioners (except physicians)',
  },
  {
    id: '62134',
    name: '62134 - Offices of physical, occupational and speech therapists, and audiologists',
  },
  {
    id: '621340',
    name: '621340 - Offices of physical, occupational and speech therapists, and audiologists',
  },
  {
    id: '62139',
    name: '62139 - Offices of all other health practitioners',
  },
  {
    id: '621391',
    name: '621391 - Offices of podiatrists',
  },
  {
    id: '621399',
    name: '621399 - Offices of all other miscellaneous health practitioners',
  },
  {
    id: '6214',
    name: '6214 - Outpatient care centers',
  },
  {
    id: '62141',
    name: '62141 - Family planning centers',
  },
  {
    id: '621410',
    name: '621410 - Family planning centers',
  },
  {
    id: '62142',
    name: '62142 - Outpatient mental health and substance abuse centers',
  },
  {
    id: '621420',
    name: '621420 - Outpatient mental health and substance abuse centers',
  },
  {
    id: '62149',
    name: '62149 - Other outpatient care centers',
  },
  {
    id: '621491',
    name: '621491 - HMO medical centers',
  },
  {
    id: '621492',
    name: '621492 - Kidney dialysis centers',
  },
  {
    id: '621493',
    name: '621493 - Freestanding ambulatory surgical and emergency centers',
  },
  {
    id: '621498',
    name: '621498 - All other outpatient care centers',
  },
  {
    id: '6215',
    name: '6215 - Medical and diagnostic laboratories',
  },
  {
    id: '62151',
    name: '62151 - Medical and diagnostic laboratories',
  },
  {
    id: '621511',
    name: '621511 - Medical laboratories',
  },
  {
    id: '621512',
    name: '621512 - Diagnostic imaging centers',
  },
  {
    id: '6216',
    name: '6216 - Home health care services',
  },
  {
    id: '62161',
    name: '62161 - Home health care services',
  },
  {
    id: '621610',
    name: '621610 - Home health care services',
  },
  {
    id: '6219',
    name: '6219 - Other ambulatory health care services',
  },
  {
    id: '62191',
    name: '62191 - Ambulance services',
  },
  {
    id: '621910',
    name: '621910 - Ambulance services',
  },
  {
    id: '62199',
    name: '62199 - All other ambulatory health care services',
  },
  {
    id: '621991',
    name: '621991 - Blood and organ banks',
  },
  {
    id: '621999',
    name: '621999 - All other miscellaneous ambulatory health care services',
  },
  { id: '622', name: '622 - Hospitals' },
  {
    id: '6221',
    name: '6221 - General medical and surgical hospitals',
  },
  {
    id: '62211',
    name: '62211 - General medical and surgical hospitals',
  },
  {
    id: '622110',
    name: '622110 - General medical and surgical hospitals',
  },
  {
    id: '6222',
    name: '6222 - Psychiatric and substance abuse hospitals',
  },
  {
    id: '62221',
    name: '62221 - Psychiatric and substance abuse hospitals',
  },
  {
    id: '622210',
    name: '622210 - Psychiatric and substance abuse hospitals',
  },
  {
    id: '6223',
    name: '6223 - Specialty (except psychiatric and substance abuse) hospitals',
  },
  {
    id: '62231',
    name: '62231 - Specialty (except psychiatric and substance abuse) hospitals',
  },
  {
    id: '622310',
    name: '622310 - Specialty (except psychiatric and substance abuse) hospitals',
  },
  {
    id: '623',
    name: '623 - Nursing and residential care facilities',
  },
  {
    id: '6231',
    name: '6231 - Nursing care facilities (skilled nursing facilities)',
  },
  {
    id: '62311',
    name: '62311 - Nursing care facilities (skilled nursing facilities)',
  },
  {
    id: '623110',
    name: '623110 - Nursing care facilities (skilled nursing facilities)',
  },
  {
    id: '6232',
    name: '6232 - Residential intellectual and developmental disability, mental health, and substance abuse facilities',
  },
  {
    id: '62321',
    name: '62321 - Residential intellectual and developmental disability facilities',
  },
  {
    id: '623210',
    name: '623210 - Residential intellectual and developmental disability facilities',
  },
  {
    id: '62322',
    name: '62322 - Residential mental health and substance abuse facilities',
  },
  {
    id: '623220',
    name: '623220 - Residential mental health and substance abuse facilities',
  },
  {
    id: '6233',
    name: '6233 - Continuing care retirement communities and assisted living facilities for the elderly',
  },
  {
    id: '62331',
    name: '62331 - Continuing care retirement communities and assisted living facilities for the elderly',
  },
  {
    id: '623311',
    name: '623311 - Continuing care retirement communities',
  },
  {
    id: '623312',
    name: '623312 - Assisted living facilities for the elderly',
  },
  {
    id: '6239',
    name: '6239 - Other residential care facilities',
  },
  {
    id: '62399',
    name: '62399 - Other residential care facilities',
  },
  {
    id: '623990',
    name: '623990 - Other residential care facilities',
  },
  { id: '624', name: '624 - Social assistance' },
  {
    id: '6241',
    name: '6241 - Individual and family services',
  },
  {
    id: '62411',
    name: '62411 - Child and youth services',
  },
  {
    id: '624110',
    name: '624110 - Child and youth services',
  },
  {
    id: '62412',
    name: '62412 - Services for the elderly and persons with disabilities',
  },
  {
    id: '624120',
    name: '624120 - Services for the elderly and persons with disabilities',
  },
  {
    id: '62419',
    name: '62419 - Other individual and family services',
  },
  {
    id: '624190',
    name: '624190 - Other individual and family services',
  },
  {
    id: '6242',
    name: '6242 - Community food and housing, and emergency and other relief services',
  },
  {
    id: '62421',
    name: '62421 - Community food services',
  },
  {
    id: '624210',
    name: '624210 - Community food services',
  },
  {
    id: '62422',
    name: '62422 - Community housing services',
  },
  {
    id: '624221',
    name: '624221 - Temporary shelters',
  },
  {
    id: '624229',
    name: '624229 - Other community housing services',
  },
  {
    id: '62423',
    name: '62423 - Emergency and other relief services',
  },
  {
    id: '624230',
    name: '624230 - Emergency and other relief services',
  },
  {
    id: '6243',
    name: '6243 - Vocational rehabilitation services',
  },
  {
    id: '62431',
    name: '62431 - Vocational rehabilitation services',
  },
  {
    id: '624310',
    name: '624310 - Vocational rehabilitation services',
  },
  {
    id: '6244',
    name: '6244 - Child day care services',
  },
  {
    id: '62441',
    name: '62441 - Child day care services',
  },
  {
    id: '624410',
    name: '624410 - Child day care services',
  },
  { id: '71', name: '71 - Arts, entertainment, and recreation' },
  {
    id: '711',
    name: '711 - Performing arts, spectator sports, and related industries',
  },
  {
    id: '7111',
    name: '7111 - Performing arts companies',
  },
  {
    id: '71111',
    name: '71111 - Theater companies and dinner theaters',
  },
  {
    id: '711110',
    name: '711110 - Theater companies and dinner theaters',
  },
  {
    id: '71112',
    name: '71112 - Dance companies',
  },
  {
    id: '711120',
    name: '711120 - Dance companies',
  },
  {
    id: '71113',
    name: '71113 - Musical groups and artists',
  },
  {
    id: '711130',
    name: '711130 - Musical groups and artists',
  },
  {
    id: '71119',
    name: '71119 - Other performing arts companies',
  },
  {
    id: '711190',
    name: '711190 - Other performing arts companies',
  },
  {
    id: '7112',
    name: '7112 - Spectator sports',
  },
  {
    id: '71121',
    name: '71121 - Spectator sports',
  },
  {
    id: '711211',
    name: '711211 - Sports teams and clubs',
  },
  {
    id: '711212',
    name: '711212 - Racetracks',
  },
  {
    id: '711219',
    name: '711219 - Other spectator sports',
  },
  {
    id: '7113',
    name: '7113 - Promoters of performing arts, sports, and similar events',
  },
  {
    id: '71131',
    name: '71131 - Promoters of performing arts, sports, and similar events with facilities',
  },
  {
    id: '711310',
    name: '711310 - Promoters of performing arts, sports, and similar events with facilities',
  },
  {
    id: '71132',
    name: '71132 - Promoters of performing arts, sports, and similar events without facilities',
  },
  {
    id: '711320',
    name: '711320 - Promoters of performing arts, sports, and similar events without facilities',
  },
  {
    id: '7114',
    name: '7114 - Agents and managers for artists, athletes, entertainers, and other public figures',
  },
  {
    id: '71141',
    name: '71141 - Agents and managers for artists, athletes, entertainers, and other public figures',
  },
  {
    id: '711410',
    name: '711410 - Agents and managers for artists, athletes, entertainers, and other public figures',
  },
  {
    id: '7115',
    name: '7115 - Independent artists, writers, and performers',
  },
  {
    id: '71151',
    name: '71151 - Independent artists, writers, and performers',
  },
  {
    id: '711510',
    name: '711510 - Independent artists, writers, and performers',
  },
  {
    id: '712',
    name: '712 - Museums, historical sites, and similar institutions',
  },
  {
    id: '7121',
    name: '7121 - Museums, historical sites, and similar institutions',
  },
  {
    id: '71211',
    name: '71211 - Museums',
  },
  {
    id: '712110',
    name: '712110 - Museums',
  },
  {
    id: '71212',
    name: '71212 - Historical sites',
  },
  {
    id: '712120',
    name: '712120 - Historical sites',
  },
  {
    id: '71213',
    name: '71213 - Zoos and botanical gardens',
  },
  {
    id: '712130',
    name: '712130 - Zoos and botanical gardens',
  },
  {
    id: '71219',
    name: '71219 - Nature parks and other similar institutions',
  },
  {
    id: '712190',
    name: '712190 - Nature parks and other similar institutions',
  },
  {
    id: '713',
    name: '713 - Amusement, gambling, and recreation industries',
  },
  {
    id: '7131',
    name: '7131 - Amusement parks and arcades',
  },
  {
    id: '71311',
    name: '71311 - Amusement and theme parks',
  },
  {
    id: '713110',
    name: '713110 - Amusement and theme parks',
  },
  {
    id: '71312',
    name: '71312 - Amusement arcades',
  },
  {
    id: '713120',
    name: '713120 - Amusement arcades',
  },
  {
    id: '7132',
    name: '7132 - Gambling industries',
  },
  {
    id: '71321',
    name: '71321 - Casinos (except casino hotels)',
  },
  {
    id: '713210',
    name: '713210 - Casinos (except casino hotels)',
  },
  {
    id: '71329',
    name: '71329 - Other gambling industries',
  },
  {
    id: '713290',
    name: '713290 - Other gambling industries',
  },
  {
    id: '7139',
    name: '7139 - Other amusement and recreation industries',
  },
  {
    id: '71391',
    name: '71391 - Golf courses and country clubs',
  },
  {
    id: '713910',
    name: '713910 - Golf courses and country clubs',
  },
  {
    id: '71392',
    name: '71392 - Skiing facilities',
  },
  {
    id: '713920',
    name: '713920 - Skiing facilities',
  },
  {
    id: '71393',
    name: '71393 - Marinas',
  },
  {
    id: '713930',
    name: '713930 - Marinas',
  },
  {
    id: '71394',
    name: '71394 - Fitness and recreational sports centers',
  },
  {
    id: '713940',
    name: '713940 - Fitness and recreational sports centers',
  },
  {
    id: '71395',
    name: '71395 - Bowling centers',
  },
  {
    id: '713950',
    name: '713950 - Bowling centers',
  },
  {
    id: '71399',
    name: '71399 - All other amusement and recreation industries',
  },
  {
    id: '713990',
    name: '713990 - All other amusement and recreation industries',
  },
  { id: '72', name: '72 - Accommodation and food services' },
  { id: '721', name: '721 - Accommodation' },
  {
    id: '7211',
    name: '7211 - Traveler accommodation',
  },
  {
    id: '72111',
    name: '72111 - Hotels (except casino hotels) and motels',
  },
  {
    id: '721110',
    name: '721110 - Hotels (except casino hotels) and motels',
  },
  {
    id: '72112',
    name: '72112 - Casino hotels',
  },
  {
    id: '721120',
    name: '721120 - Casino hotels',
  },
  {
    id: '72119',
    name: '72119 - Other traveler accommodation',
  },
  {
    id: '721191',
    name: '721191 - Bed-and-breakfast inns',
  },
  {
    id: '721199',
    name: '721199 - All other traveler accommodation',
  },
  {
    id: '7212',
    name: '7212 - RV (recreational vehicle) parks and recreational camps',
  },
  {
    id: '72121',
    name: '72121 - RV (recreational vehicle) parks and recreational camps',
  },
  {
    id: '721211',
    name: '721211 - RV (recreational vehicle) parks and campgrounds',
  },
  {
    id: '721214',
    name: '721214 - Recreational and vacation camps (except campgrounds)',
  },
  {
    id: '7213',
    name: "        7213 - Rooming and boarding houses, dormitories, and workers' camps",
  },
  {
    id: '72131',
    name: "            72131 - Rooming and boarding houses, dormitories, and workers' camps",
  },
  {
    id: '721310',
    name: "                721310 - Rooming and boarding houses, dormitories, and workers' camps",
  },
  {
    id: '722',
    name: '722 - Food services and drinking places',
  },
  {
    id: '7223',
    name: '7223 - Special food services',
  },
  {
    id: '72231',
    name: '72231 - Food service contractors',
  },
  {
    id: '722310',
    name: '722310 - Food service contractors',
  },
  {
    id: '72232',
    name: '72232 - Caterers',
  },
  {
    id: '722320',
    name: '722320 - Caterers',
  },
  {
    id: '72233',
    name: '72233 - Mobile food services',
  },
  {
    id: '722330',
    name: '722330 - Mobile food services',
  },
  {
    id: '7224',
    name: '7224 - Drinking places (alcoholic beverages)',
  },
  {
    id: '72241',
    name: '72241 - Drinking places (alcoholic beverages)',
  },
  {
    id: '722410',
    name: '722410 - Drinking places (alcoholic beverages)',
  },
  {
    id: '7225',
    name: '7225 - Restaurants and other eating places',
  },
  {
    id: '72251',
    name: '72251 - Restaurants and other eating places',
  },
  {
    id: '722511',
    name: '722511 - Full-service restaurants',
  },
  {
    id: '722513',
    name: '722513 - Limited-service restaurants',
  },
  {
    id: '722514',
    name: '722514 - Cafeterias, grill buffets, and buffets',
  },
  {
    id: '722515',
    name: '722515 - Snack and nonalcoholic beverage bars',
  },
  {
    id: '81',
    name: '81 - Other services (except public administration)',
  },
  {
    id: '811',
    name: '811 - Repair and maintenance',
  },
  {
    id: '8111',
    name: '8111 - Automotive repair and maintenance',
  },
  {
    id: '81111',
    name: '81111 - Automotive mechanical and electrical repair and maintenance',
  },
  {
    id: '811111',
    name: '811111 - General automotive repair',
  },
  {
    id: '811112',
    name: '811112 - Automotive exhaust system repair',
  },
  {
    id: '811113',
    name: '811113 - Automotive transmission repair',
  },
  {
    id: '811118',
    name: '811118 - Other automotive mechanical and electrical repair and maintenance',
  },
  {
    id: '81112',
    name: '81112 - Automotive body, paint, interior, and glass repair',
  },
  {
    id: '811121',
    name: '811121 - Automotive body, paint, and interior repair and maintenance',
  },
  {
    id: '811122',
    name: '811122 - Automotive glass replacement shops',
  },
  {
    id: '81119',
    name: '81119 - Other automotive repair and maintenance',
  },
  {
    id: '811191',
    name: '811191 - Automotive oil change and lubrication shops',
  },
  {
    id: '811192',
    name: '811192 - Car washes',
  },
  {
    id: '811198',
    name: '811198 - All other automotive repair and maintenance',
  },
  {
    id: '8112',
    name: '8112 - Electronic and precision equipment repair and maintenance',
  },
  {
    id: '81121',
    name: '81121 - Electronic and precision equipment repair and maintenance',
  },
  {
    id: '811211',
    name: '811211 - Consumer electronics repair and maintenance',
  },
  {
    id: '811212',
    name: '811212 - Computer and office machine repair and maintenance',
  },
  {
    id: '811213',
    name: '811213 - Communication equipment repair and maintenance',
  },
  {
    id: '811219',
    name: '811219 - Other electronic and precision equipment repair and maintenance',
  },
  {
    id: '8113',
    name: '8113 - Commercial and industrial machinery and equipment (except automotive and electronic) repair and maintenance',
  },
  {
    id: '81131',
    name: '81131 - Commercial and industrial machinery and equipment (except automotive and electronic) repair and maintenance',
  },
  {
    id: '811310',
    name: '811310 - Commercial and industrial machinery and equipment (except automotive and electronic) repair and maintenance',
  },
  {
    id: '8114',
    name: '8114 - Personal and household goods repair and maintenance',
  },
  {
    id: '81141',
    name: '81141 - Home and garden equipment and appliance repair and maintenance',
  },
  {
    id: '811411',
    name: '811411 - Home and garden equipment repair and maintenance',
  },
  {
    id: '811412',
    name: '811412 - Appliance repair and maintenance',
  },
  {
    id: '81142',
    name: '81142 - Reupholstery and furniture repair',
  },
  {
    id: '811420',
    name: '811420 - Reupholstery and furniture repair',
  },
  {
    id: '81143',
    name: '81143 - Footwear and leather goods repair',
  },
  {
    id: '811430',
    name: '811430 - Footwear and leather goods repair',
  },
  {
    id: '81149',
    name: '81149 - Other personal and household goods repair and maintenance',
  },
  {
    id: '811490',
    name: '811490 - Other personal and household goods repair and maintenance',
  },
  {
    id: '812',
    name: '812 - Personal and laundry services',
  },
  {
    id: '8121',
    name: '8121 - Personal care services',
  },
  {
    id: '81211',
    name: '81211 - Hair, nail, and skin care services',
  },
  {
    id: '812111',
    name: '812111 - Barber shops',
  },
  {
    id: '812112',
    name: '812112 - Beauty salons',
  },
  {
    id: '812113',
    name: '812113 - Nail salons',
  },
  {
    id: '81219',
    name: '81219 - Other personal care services',
  },
  {
    id: '812191',
    name: '812191 - Diet and weight reducing centers',
  },
  {
    id: '812199',
    name: '812199 - Other personal care services',
  },
  {
    id: '8122',
    name: '8122 - Death care services',
  },
  {
    id: '81221',
    name: '81221 - Funeral homes and funeral services',
  },
  {
    id: '812210',
    name: '812210 - Funeral homes and funeral services',
  },
  {
    id: '81222',
    name: '81222 - Cemeteries and crematories',
  },
  {
    id: '812220',
    name: '812220 - Cemeteries and crematories',
  },
  {
    id: '8123',
    name: '8123 - Drycleaning and laundry services',
  },
  {
    id: '81231',
    name: '81231 - Coin-operated laundries and drycleaners',
  },
  {
    id: '812310',
    name: '812310 - Coin-operated laundries and drycleaners',
  },
  {
    id: '81232',
    name: '81232 - Drycleaning and laundry services (except coin-operated)',
  },
  {
    id: '812320',
    name: '812320 - Drycleaning and laundry services (except coin-operated)',
  },
  {
    id: '81233',
    name: '81233 - Linen and uniform supply',
  },
  {
    id: '812331',
    name: '812331 - Linen supply',
  },
  {
    id: '812332',
    name: '812332 - Industrial launderers',
  },
  {
    id: '8129',
    name: '8129 - Other personal services',
  },
  {
    id: '81291',
    name: '81291 - Pet care (except veterinary) services',
  },
  {
    id: '812910',
    name: '812910 - Pet care (except veterinary) services',
  },
  {
    id: '81292',
    name: '81292 - Photofinishing',
  },
  {
    id: '812921',
    name: '812921 - Photofinishing laboratories (except one-hour)',
  },
  {
    id: '812922',
    name: '812922 - One-hour photofinishing',
  },
  {
    id: '81293',
    name: '81293 - Parking lots and garages',
  },
  {
    id: '812930',
    name: '812930 - Parking lots and garages',
  },
  {
    id: '81299',
    name: '81299 - All other personal services',
  },
  {
    id: '812990',
    name: '812990 - All other personal services',
  },
  {
    id: '813',
    name: '813 - Religious, grantmaking, civic, professional, and similar organizations',
  },
  {
    id: '8131',
    name: '8131 - Religious organizations',
  },
  {
    id: '81311',
    name: '81311 - Religious organizations',
  },
  {
    id: '813110',
    name: '813110 - Religious organizations',
  },
  {
    id: '8132',
    name: '8132 - Grantmaking and giving services',
  },
  {
    id: '81321',
    name: '81321 - Grantmaking and giving services',
  },
  {
    id: '813211',
    name: '813211 - Grantmaking foundations',
  },
  {
    id: '813212',
    name: '813212 - Voluntary health organizations',
  },
  {
    id: '813219',
    name: '813219 - Other grantmaking and giving services',
  },
  {
    id: '8133',
    name: '8133 - Social advocacy organizations',
  },
  {
    id: '81331',
    name: '81331 - Social advocacy organizations',
  },
  {
    id: '813311',
    name: '813311 - Human rights organizations',
  },
  {
    id: '813312',
    name: '813312 - Environment, conservation and wildlife organizations',
  },
  {
    id: '813319',
    name: '813319 - Other social advocacy organizations',
  },
  {
    id: '8134',
    name: '8134 - Civic and social organizations',
  },
  {
    id: '81341',
    name: '81341 - Civic and social organizations',
  },
  {
    id: '813410',
    name: '813410 - Civic and social organizations',
  },
  {
    id: '8139',
    name: '8139 - Business, professional, labor, political, and similar organizations',
  },
  {
    id: '81391',
    name: '81391 - Business associations',
  },
  {
    id: '813910',
    name: '813910 - Business associations',
  },
  {
    id: '81392',
    name: '81392 - Professional organizations',
  },
  {
    id: '813920',
    name: '813920 - Professional organizations',
  },
  {
    id: '81393',
    name: '81393 - Labor unions and similar labor organizations',
  },
  {
    id: '813930',
    name: '813930 - Labor unions and similar labor organizations',
  },
  {
    id: '81394',
    name: '81394 - Political organizations',
  },
  {
    id: '813940',
    name: '813940 - Political organizations',
  },
  {
    id: '81399',
    name: '81399 - Other similar organizations (except business, professional, labor, and political organizations)',
  },
  {
    id: '813990',
    name: '813990 - Other similar organizations (except business, professional, labor, and political organizations)',
  },
  { id: '814', name: '814 - Private households' },
  {
    id: '8141',
    name: '8141 - Private households',
  },
  {
    id: '81411',
    name: '81411 - Private households',
  },
  {
    id: '814110',
    name: '814110 - Private households',
  },
  {
    id: '912910',
    name: '912910 - Other provincial and territorial public administration',
  },
  {
    id: '913910',
    name: '913910 - Other local, municipal and regional public administration',
  },
  { id: '92', name: '92 - Public administration' },
  {
    id: '921',
    name: '921 - Executive, legislative, and other general government support',
  },
  {
    id: '9211',
    name: '9211 - Executive, legislative, and other general government support',
  },
  {
    id: '92111',
    name: '92111 - Executive offices',
  },
  {
    id: '921110',
    name: '921110 - Executive offices',
  },
  {
    id: '92112',
    name: '92112 - Legislative bodies',
  },
  {
    id: '921120',
    name: '921120 - Legislative bodies',
  },
  {
    id: '92113',
    name: '92113 - Public finance activities',
  },
  {
    id: '921130',
    name: '921130 - Public finance activities',
  },
  {
    id: '92114',
    name: '92114 - Executive and legislative offices, combined',
  },
  {
    id: '921140',
    name: '921140 - Executive and legislative offices, combined',
  },
  {
    id: '92115',
    name: '92115 - American indian and Alaska native tribal governments',
  },
  {
    id: '921150',
    name: '921150 - American indian and Alaska native tribal governments',
  },
  {
    id: '92119',
    name: '92119 - Other general government support',
  },
  {
    id: '921190',
    name: '921190 - Other general government support',
  },
  {
    id: '922',
    name: '922 - Justice, public order, and safety activities',
  },
  {
    id: '9221',
    name: '9221 - Justice, public order, and safety activities',
  },
  {
    id: '92211',
    name: '92211 - Courts',
  },
  {
    id: '922110',
    name: '922110 - Courts',
  },
  {
    id: '92212',
    name: '92212 - Police protection',
  },
  {
    id: '922120',
    name: '922120 - Police protection',
  },
  {
    id: '92213',
    name: '92213 - Legal counsel and prosecution',
  },
  {
    id: '922130',
    name: '922130 - Legal counsel and prosecution',
  },
  {
    id: '92214',
    name: '92214 - Correctional institutions',
  },
  {
    id: '922140',
    name: '922140 - Correctional institutions',
  },
  {
    id: '92215',
    name: '92215 - Parole offices and probation offices',
  },
  {
    id: '922150',
    name: '922150 - Parole offices and probation offices',
  },
  {
    id: '92216',
    name: '92216 - Fire protection',
  },
  {
    id: '922160',
    name: '922160 - Fire protection',
  },
  {
    id: '92219',
    name: '92219 - Other justice, public order, and safety activities',
  },
  {
    id: '922190',
    name: '922190 - Other justice, public order, and safety activities',
  },
  {
    id: '923',
    name: '923 - Administration of human resource programs',
  },
  {
    id: '9231',
    name: '9231 - Administration of human resource programs',
  },
  {
    id: '92311',
    name: '92311 - Administration of education programs',
  },
  {
    id: '923110',
    name: '923110 - Administration of education programs',
  },
  {
    id: '92312',
    name: '92312 - Administration of public health programs',
  },
  {
    id: '923120',
    name: '923120 - Administration of public health programs',
  },
  {
    id: '92313',
    name: "            92313 - Administration of human resource programs (except education, public health, and veterans' affairs programs)",
  },
  {
    id: '923130',
    name: "                923130 - Administration of human resource programs (except education, public health, and veterans' affairs programs)",
  },
  {
    id: '92314',
    name: "            92314 - Administration of veterans' affairs",
  },
  {
    id: '923140',
    name: "                923140 - Administration of veterans' affairs",
  },
  {
    id: '924',
    name: '924 - Administration of environmental quality programs',
  },
  {
    id: '9241',
    name: '9241 - Administration of environmental quality programs',
  },
  {
    id: '92411',
    name: '92411 - Administration of air and water resource and solid waste management programs',
  },
  {
    id: '924110',
    name: '924110 - Administration of air and water resource and solid waste management programs',
  },
  {
    id: '92412',
    name: '92412 - Administration of conservation programs',
  },
  {
    id: '924120',
    name: '924120 - Administration of conservation programs',
  },
  {
    id: '925',
    name: '925 - Administration of housing programs, urban planning, and community development',
  },
  {
    id: '9251',
    name: '9251 - Administration of housing programs, urban planning, and community development',
  },
  {
    id: '92511',
    name: '92511 - Administration of housing programs',
  },
  {
    id: '925110',
    name: '925110 - Administration of housing programs',
  },
  {
    id: '92512',
    name: '92512 - Administration of urban planning and community and rural development',
  },
  {
    id: '925120',
    name: '925120 - Administration of urban planning and community and rural development',
  },
  {
    id: '926',
    name: '926 - Administration of economic programs',
  },
  {
    id: '9261',
    name: '9261 - Administration of economic programs',
  },
  {
    id: '92611',
    name: '92611 - Administration of general economic programs',
  },
  {
    id: '926110',
    name: '926110 - Administration of general economic programs',
  },
  {
    id: '92612',
    name: '92612 - Regulation and administration of transportation programs',
  },
  {
    id: '926120',
    name: '926120 - Regulation and administration of transportation programs',
  },
  {
    id: '92613',
    name: '92613 - Regulation and administration of communications, electric, gas, and other utilities',
  },
  {
    id: '926130',
    name: '926130 - Regulation and administration of communications, electric, gas, and other utilities',
  },
  {
    id: '92614',
    name: '92614 - Regulation of agricultural marketing and commodities',
  },
  {
    id: '926140',
    name: '926140 - Regulation of agricultural marketing and commodities',
  },
  {
    id: '92615',
    name: '92615 - Regulation, licensing, and inspection of miscellaneous commercial sectors',
  },
  {
    id: '926150',
    name: '926150 - Regulation, licensing, and inspection of miscellaneous commercial sectors',
  },
  {
    id: '927',
    name: '927 - Space research and technology',
  },
  {
    id: '9271',
    name: '9271 - Space research and technology',
  },
  {
    id: '92711',
    name: '92711 - Space research and technology',
  },
  {
    id: '927110',
    name: '927110 - Space research and technology',
  },
  {
    id: '928',
    name: '928 - National security and international affairs',
  },
  {
    id: '9281',
    name: '9281 - National security and international affairs',
  },
  {
    id: '92811',
    name: '92811 - National security',
  },
  {
    id: '928110',
    name: '928110 - National security',
  },
  {
    id: '92812',
    name: '92812 - International affairs',
  },
  {
    id: '928120',
    name: '928120 - International affairs',
  },
  { id: '99', name: '99 - Nonclassifiable establishments' },
  {
    id: '999',
    name: '999 - Nonclassifiable establishments',
  },
  {
    id: '9999',
    name: '9999 - Nonclassifiable establishments',
  },
  {
    id: '99999',
    name: '99999 - Nonclassifiable establishments',
  },
  {
    id: '999999',
    name: '999999 - Nonclassifiable establishments                ',
  },
];

export const sicLookup: Array<ILookup> = [
  { id: '', name: 'SIC Codes' },
  { id: '01', name: '01 - AGRICULTURAL PRODUCTION CROPS' },
  { id: '011', name: '011 - Cash Grains' },
  {
    id: '0111',
    name: '0111 - Wheat',
  },
  {
    id: '0112',
    name: '0112 - Rice',
  },
  {
    id: '0115',
    name: '0115 - Corn',
  },
  {
    id: '0116',
    name: '0116 - Soybeans',
  },
  {
    id: '0119',
    name: '0119 - Cash grains, nec',
  },
  {
    id: '013',
    name: '013 - Field Crops, Except Cash Grains',
  },
  {
    id: '0131',
    name: '0131 - Cotton',
  },
  {
    id: '0132',
    name: '0132 - Tobacco',
  },
  {
    id: '0133',
    name: '0133 - Sugarcane and sugar beets',
  },
  {
    id: '0134',
    name: '0134 - Irish potatoes',
  },
  {
    id: '0139',
    name: '0139 - Field crops, except cash grains, nec',
  },
  {
    id: '016',
    name: '016 - Vegetables and Melons',
  },
  {
    id: '0161',
    name: '0161 - Vegetables and melons',
  },
  {
    id: '017',
    name: '017 - Fruits and Tree Nuts',
  },
  {
    id: '0171',
    name: '0171 - Berry crops',
  },
  {
    id: '0172',
    name: '0172 - Grapes',
  },
  {
    id: '0173',
    name: '0173 - Tree nuts',
  },
  {
    id: '0174',
    name: '0174 - Citrus fruits',
  },
  {
    id: '0175',
    name: '0175 - Deciduous tree fruits',
  },
  {
    id: '0179',
    name: '0179 - Fruits and tree nuts, nec',
  },
  {
    id: '018',
    name: '018 - Horticultural Specialties',
  },
  {
    id: '0181',
    name: '0181 - Ornamental floriculture and nursery products',
  },
  {
    id: '0182',
    name: '0182 - Food crops grown under cover',
  },
  {
    id: '019',
    name: '019 - General Farms, Primarily Crop',
  },
  {
    id: '0191',
    name: '0191 - General farms, primarily crop',
  },
  { id: '02', name: '02 - AGRICULTURAL PRODUCTION LIVESTOCK' },
  {
    id: '021',
    name: '021 - Livestock, Except Dairy and Poultry',
  },
  {
    id: '0211',
    name: '0211 - Beef cattle feedlots',
  },
  {
    id: '0212',
    name: '0212 - Beef cattle, except feedlots',
  },
  {
    id: '0213',
    name: '0213 - Hogs',
  },
  {
    id: '0214',
    name: '0214 - Sheep and goats',
  },
  {
    id: '0219',
    name: '0219 - General livestock, except dairy and poultry',
  },
  { id: '024', name: '024 - Dairy Farms' },
  {
    id: '0241',
    name: '0241 - Dairy farms',
  },
  { id: '025', name: '025 - Poultry and Eggs' },
  {
    id: '0251',
    name: '0251 - Broiler, fryer and roaster chickens',
  },
  {
    id: '0252',
    name: '0252 - Chicken eggs',
  },
  {
    id: '0253',
    name: '0253 - Turkeys and turkey eggs',
  },
  {
    id: '0254',
    name: '0254 - Poultry hatcheries',
  },
  {
    id: '0259',
    name: '0259 - Poultry and eggs, nec',
  },
  { id: '027', name: '027 - Animal Specialties' },
  {
    id: '0271',
    name: '0271 - Fur-bearing animals and rabbits',
  },
  {
    id: '0272',
    name: '0272 - Horses and other equines',
  },
  {
    id: '0273',
    name: '0273 - Animal aquaculture',
  },
  {
    id: '0279',
    name: '0279 - Animal specialties, nec',
  },
  {
    id: '029',
    name: '029 - General Farms, Primarily Livestock and Animal',
  },
  {
    id: '0291',
    name: '0291 - General farms, primarily livestock and animal',
  },
  { id: '07', name: '07 - AGRICULTURAL SERVICES' },
  {
    id: '071',
    name: '071 - Soil Preparation Services',
  },
  {
    id: '0711',
    name: '0711 - Soil preparation services',
  },
  { id: '072', name: '072 - Crop Services' },
  {
    id: '0721',
    name: '0721 - Crop planting, cultivating and protecting',
  },
  {
    id: '0722',
    name: '0722 - Crop harvesting, primarily by machine',
  },
  {
    id: '0723',
    name: '0723 - Crop preparation services for market, ex. cotton',
  },
  {
    id: '0724',
    name: '0724 - Cotton ginning',
  },
  {
    id: '074',
    name: '074 - Veterinary Services',
  },
  {
    id: '0741',
    name: '0741 - Veterinary services for livestock',
  },
  {
    id: '0742',
    name: '0742 - Veterinary services for animal specialties',
  },
  {
    id: '075',
    name: '075 - Animal Services, Except Veterinary',
  },
  {
    id: '0751',
    name: '0751 - Livestock services, except veterinary',
  },
  {
    id: '0752',
    name: '0752 - Animal specialty services, except veterinary',
  },
  {
    id: '076',
    name: '076 - Farm Labour and Management Services',
  },
  {
    id: '0761',
    name: '0761 - Farm labour contractors and crew leaders',
  },
  {
    id: '0762',
    name: '0762 - Farm management services',
  },
  {
    id: '078',
    name: '078 - Landscape and Horticultural Services',
  },
  {
    id: '0781',
    name: '0781 - Landscape counseling and planning',
  },
  {
    id: '0782',
    name: '0782 - Lawn and garden services',
  },
  {
    id: '0783',
    name: '0783 - Ornamental shrub and tree services',
  },
  { id: '08', name: '08 - FORESTRY' },
  { id: '081', name: '081 - Timber Tracts' },
  {
    id: '0811',
    name: '0811 - Timber tracts',
  },
  {
    id: '083',
    name: '083 - Forest Nurseries and Gathering of Forest Products',
  },
  {
    id: '0831',
    name: '0831 - Forest nurseries and gathering of forest products',
  },
  { id: '085', name: '085 - Forestry Services' },
  {
    id: '0851',
    name: '0851 - Forestry services',
  },
  { id: '09', name: '09 - FISHING, HUNTING AND TRAPPING' },
  { id: '091', name: '091 - Commercial Fishing' },
  {
    id: '0912',
    name: '0912 - Finfish',
  },
  {
    id: '0913',
    name: '0913 - Shellfish',
  },
  {
    id: '0919',
    name: '0919 - Misc. marine products',
  },
  {
    id: '092',
    name: '092 - Fish Hatcheries and Preserves',
  },
  {
    id: '0921',
    name: '0921 - Fish hatcheries and preserves',
  },
  {
    id: '097',
    name: '097 - Hunting, Trapping and Game Propagation',
  },
  {
    id: '0971',
    name: '0971 - Hunting, trapping and game propagation',
  },
  { id: '10', name: '10 - METAL MINING' },
  { id: '101', name: '101 - Iron Ores' },
  {
    id: '1011',
    name: '1011 - Iron ores',
  },
  { id: '102', name: '102 - Copper Ores' },
  {
    id: '1021',
    name: '1021 - Copper ores',
  },
  { id: '103', name: '103 - Lead and Zinc Ores' },
  {
    id: '1031',
    name: '1031 - Lead and zinc ores',
  },
  {
    id: '104',
    name: '104 - Gold and Silver Ores',
  },
  {
    id: '1041',
    name: '1041 - Gold ores',
  },
  {
    id: '1044',
    name: '1044 - Silver ores',
  },
  {
    id: '106',
    name: '106 - Ferroalloy Ores, Except Vanadium',
  },
  {
    id: '1061',
    name: '1061 - Ferroalloy ores, except vanadium',
  },
  {
    id: '108',
    name: '108 - Metal Mining Services',
  },
  {
    id: '1081',
    name: '1081 - Metal mining services',
  },
  { id: '109', name: '109 - Misc. Metal Ores' },
  {
    id: '1094',
    name: '1094 - Uranium-radium-vanadium ores',
  },
  {
    id: '1098',
    name: '1098 - Mineral exploration',
  },
  {
    id: '1099',
    name: '1099 - Misc. metal ores, nec',
  },
  { id: '12', name: '12 - COAL MINING' },
  {
    id: '122',
    name: '122 - Bituminous Coal and Lignite Mining',
  },
  {
    id: '1221',
    name: '1221 - Bituminous coal and lignite surface mining',
  },
  {
    id: '1222',
    name: '1222 - Bituminous coal underground mining',
  },
  { id: '123', name: '123 - Anthracite Mining' },
  {
    id: '1231',
    name: '1231 - Anthracite mining',
  },
  {
    id: '124',
    name: '124 - Coal Mining Services',
  },
  {
    id: '1241',
    name: '1241 - Coal mining services',
  },
  { id: '13', name: '13 - OIL AND GAS EXTRACTION' },
  {
    id: '131',
    name: '131 - Crude Petroleum and Natural Gas',
  },
  {
    id: '1311',
    name: '1311 - Crude petroleum and natural gas',
  },
  {
    id: '132',
    name: '132 - Natural Gas Liquids',
  },
  {
    id: '1321',
    name: '1321 - Natural gas liquids',
  },
  {
    id: '138',
    name: '138 - Oil and Gas Field Services',
  },
  {
    id: '1381',
    name: '1381 - Drilling oil and gas wells',
  },
  {
    id: '1382',
    name: '1382 - Oil and gas field exploration services',
  },
  {
    id: '1389',
    name: '1389 - Oil and gas field services, nec',
  },
  { id: '14', name: '14 - NONMETALLIC MINERALS, EXCEPT FUELS' },
  { id: '141', name: '141 - Dimension Stone' },
  {
    id: '1411',
    name: '1411 - Dimension stone',
  },
  {
    id: '142',
    name: '142 - Crushed and Broken Stone, Including Riprap',
  },
  {
    id: '1422',
    name: '1422 - Crushed and broken limestone',
  },
  {
    id: '1423',
    name: '1423 - Crushed and broken granite',
  },
  {
    id: '1429',
    name: '1429 - Crushed and broken stone, nec',
  },
  { id: '144', name: '144 - Sand and Gravel' },
  {
    id: '1442',
    name: '1442 - Construction sand and gravel',
  },
  {
    id: '1446',
    name: '1446 - Industrial sand',
  },
  {
    id: '145',
    name: '145 - Clay, Ceramic and Refractory Minerals',
  },
  {
    id: '1455',
    name: '1455 - Kaolin and ball clay',
  },
  {
    id: '1459',
    name: '1459 - Clay, ceramic and refractory minerals, nec',
  },
  {
    id: '147',
    name: '147 - Chemical and Fertilizer Mineral Mining',
  },
  {
    id: '1474',
    name: '1474 - Potash, soda and borate minerals',
  },
  {
    id: '1475',
    name: '1475 - Phosphate rock',
  },
  {
    id: '1479',
    name: '1479 - Chemical and fertilizer mineral mining, nec',
  },
  {
    id: '148',
    name: '148 - Nonmetallic Minerals Services, Except Fuels',
  },
  {
    id: '1481',
    name: '1481 - Nonmetallic minerals services, except fuels',
  },
  {
    id: '149',
    name: '149 - Misc. Nonmetallic Minerals, Except Fuels',
  },
  {
    id: '1499',
    name: '1499 - Misc. nonmetallic minerals, except fuels',
  },
  { id: '15', name: '15 - GENERAL BUILDING CONTRACTORS' },
  {
    id: '152',
    name: '152 - General Building Contractors - Residential',
  },
  {
    id: '1521',
    name: '1521 - General contractors - single-family houses',
  },
  {
    id: '1522',
    name: '1522 - General contractors - other residential buildings',
  },
  { id: '153', name: '153 - Operative Builders' },
  {
    id: '1531',
    name: '1531 - Operative builders',
  },
  {
    id: '154',
    name: '154 - General Building Contractors - Nonresidential',
  },
  {
    id: '1541',
    name: '1541 - General contractors - industrial buildings',
  },
  {
    id: '1542',
    name: '1542 - General contractors - nonresidential, nec',
  },
  { id: '16', name: '16 - HEAVY CONSTRUCTION, EXCEPT BUILDING' },
  {
    id: '161',
    name: '161 - Highway and Street Construction, Except Elevated',
  },
  {
    id: '1611',
    name: '1611 - Highway and street construction, except elevated',
  },
  {
    id: '162',
    name: '162 - Heavy Construction, Except Highway and Street',
  },
  {
    id: '1622',
    name: '1622 - Bridge, tunnel and elevated highway construction',
  },
  {
    id: '1623',
    name: '1623 - Water, sewer, pipeline and utility lines',
  },
  {
    id: '1629',
    name: '1629 - Heavy construction, nec',
  },
  { id: '17', name: '17 - SPECIAL TRADE CONTRACTORS' },
  {
    id: '171',
    name: '171 - Plumbing, Heating and Air-Conditioning',
  },
  {
    id: '1711',
    name: '1711 - Plumbing, heating and air-conditioning',
  },
  {
    id: '172',
    name: '172 - Painting and Paper Hanging',
  },
  {
    id: '1721',
    name: '1721 - Painting and paper hanging',
  },
  { id: '173', name: '173 - Electrical Work' },
  {
    id: '1731',
    name: '1731 - Electrical work',
  },
  {
    id: '174',
    name: '174 - Masonry, Stonework, Tile Setting and Plastering',
  },
  {
    id: '1741',
    name: '1741 - Masonry, stone setting and other stone work',
  },
  {
    id: '1742',
    name: '1742 - Plastering, drywall, acoustical and insulation',
  },
  {
    id: '1743',
    name: '1743 - Terrazzo, tile, marble and mosaic work',
  },
  {
    id: '175',
    name: '175 - Carpentry and Floor Work',
  },
  {
    id: '1751',
    name: '1751 - Carpentry work',
  },
  {
    id: '1752',
    name: '1752 - Floor laying and other floor work, nec',
  },
  {
    id: '176',
    name: '176 - Roofing, Siding and Sheet Metal Work',
  },
  {
    id: '1761',
    name: '1761 - Roofing, siding and sheet metal work',
  },
  { id: '177', name: '177 - Concrete Work' },
  {
    id: '1771',
    name: '1771 - Concrete work',
  },
  {
    id: '178',
    name: '178 - Water Well Drilling',
  },
  {
    id: '1781',
    name: '1781 - Water well drilling',
  },
  {
    id: '179',
    name: '179 - Misc. Special Trade Contractors',
  },
  {
    id: '1791',
    name: '1791 - Structural steel erection',
  },
  {
    id: '1793',
    name: '1793 - Glass and glazing work',
  },
  {
    id: '1794',
    name: '1794 - Excavation work',
  },
  {
    id: '1795',
    name: '1795 - Wrecking and demolition work',
  },
  {
    id: '1796',
    name: '1796 - Installation, erection of building equipment, nec',
  },
  {
    id: '1799',
    name: '1799 - Special trade contractors, nec',
  },
  { id: '20', name: '20 - FOOD AND KINDRED PRODUCTS' },
  { id: '201', name: '201 - Meat Products' },
  {
    id: '2011',
    name: '2011 - Meat packing plants',
  },
  {
    id: '2013',
    name: '2013 - Sausages and other prepared meat products',
  },
  {
    id: '2015',
    name: '2015 - Poultry slaughtering & processing',
  },
  { id: '202', name: '202 - Dairy Products' },
  {
    id: '2021',
    name: '2021 - Creamery butter',
  },
  {
    id: '2022',
    name: '2022 - Natural, processed and imitation cheese',
  },
  {
    id: '2023',
    name: '2023 - Dry, condensed and evaporated dairy products',
  },
  {
    id: '2024',
    name: '2024 - Ice cream and frozen desserts',
  },
  {
    id: '2026',
    name: '2026 - Fluid milk',
  },
  {
    id: '203',
    name: '203 - Canned, Frozen and Preserved Fruits and Vegetables',
  },
  {
    id: '2032',
    name: '2032 - Canned specialties',
  },
  {
    id: '2033',
    name: '2033 - Canned fruits, vegetables, preserves and jams',
  },
  {
    id: '2034',
    name: '2034 - Dried and dehydrated fruits, vegetables and soups',
  },
  {
    id: '2035',
    name: '2035 - Pickled fruits & vegs., sauces, & salad dressings',
  },
  {
    id: '2037',
    name: '2037 - Frozen fruits, fruit juices and vegetables',
  },
  {
    id: '2038',
    name: '2038 - Frozen specialties, nec',
  },
  {
    id: '204',
    name: '204 - Grain Mill Products',
  },
  {
    id: '2041',
    name: '2041 - Flour & other grain mill products',
  },
  {
    id: '2043',
    name: '2043 - Cereal breakfast foods',
  },
  {
    id: '2044',
    name: '2044 - Rice milling',
  },
  {
    id: '2045',
    name: '2045 - Prepared flour mixes and doughs',
  },
  {
    id: '2046',
    name: '2046 - Wet corn milling',
  },
  {
    id: '2047',
    name: '2047 - Dog and cat food',
  },
  {
    id: '2048',
    name: '2048 - Prepared feed and feed ingredients, nec',
  },
  { id: '205', name: '205 - Bakery Products' },
  {
    id: '2051',
    name: '2051 - Bread and other bakery products, ex. cookies',
  },
  {
    id: '2052',
    name: '2052 - Cookies and crackers',
  },
  {
    id: '2053',
    name: '2053 - Frozen bakery products, except bread',
  },
  {
    id: '206',
    name: '206 - Sugar and Confectionery Products',
  },
  {
    id: '2061',
    name: '2061 - Cane sugar, except refining',
  },
  {
    id: '2062',
    name: '2062 - Cane sugar refining',
  },
  {
    id: '2063',
    name: '2063 - Beet sugar',
  },
  {
    id: '2064',
    name: '2064 - Candy and other confectionery products',
  },
  {
    id: '2066',
    name: '2066 - Chocolate and cocoa products',
  },
  {
    id: '2067',
    name: '2067 - Chewing gum',
  },
  {
    id: '2068',
    name: '2068 - Salted and roasted nuts and seeds',
  },
  { id: '207', name: '207 - Fats and Oils' },
  {
    id: '2074',
    name: '2074 - Cottonseed oil mills',
  },
  {
    id: '2075',
    name: '2075 - Soybean oil mills',
  },
  {
    id: '2076',
    name: '2076 - Vegetable oil mills, nec',
  },
  {
    id: '2077',
    name: '2077 - Animal and marine fats and oils',
  },
  {
    id: '2079',
    name: '2079 - Edible fats and oils, nec',
  },
  { id: '208', name: '208 - Beverages' },
  {
    id: '2082',
    name: '2082 - Malt beverages',
  },
  {
    id: '2083',
    name: '2083 - Malt',
  },
  {
    id: '2084',
    name: '2084 - Wines, brandy, and brandy spirits',
  },
  {
    id: '2085',
    name: '2085 - Distilled and blended liquors',
  },
  {
    id: '2086',
    name: '2086 - Bottled and canned soft drinks and carb. water',
  },
  {
    id: '2087',
    name: '2087 - Flavoring extracts and flavoring syrups, nec',
  },
  {
    id: '209',
    name: '209 - Misc. Food Preparations and Kindred Products',
  },
  {
    id: '2091',
    name: '2091 - Canned and cured fish and seafoods',
  },
  {
    id: '2092',
    name: '2092 - Prepared fresh or frozen fish and seafoods',
  },
  {
    id: '2095',
    name: '2095 - Roasted coffee',
  },
  {
    id: '2096',
    name: '2096 - Potato chips, corn chips and similar snacks',
  },
  {
    id: '2097',
    name: '2097 - Manufactured ice',
  },
  {
    id: '2098',
    name: '2098 - Macaroni, spaghetti, vermicelli and noodles',
  },
  {
    id: '2099',
    name: '2099 - Food preparations, nec',
  },
  { id: '21', name: '21 - TOBACCO PRODUCTS' },
  { id: '211', name: '211 - Cigarettes' },
  {
    id: '2111',
    name: '2111 - Cigarettes',
  },
  { id: '212', name: '212 - Cigars' },
  {
    id: '2121',
    name: '2121 - Cigars',
  },
  {
    id: '213',
    name: '213 - Chewing and Smoking Tobacco and Snuff',
  },
  {
    id: '2131',
    name: '2131 - Chewing and smoking tobacco and snuff',
  },
  {
    id: '214',
    name: '214 - Tobacco Stemming and Redrying',
  },
  {
    id: '2141',
    name: '2141 - Tobacco stemming and redrying',
  },
  { id: '22', name: '22 - TEXTILE MILL PRODUCTS' },
  {
    id: '221',
    name: '221 - Broadwoven Fabric Mills, Cotton',
  },
  {
    id: '2211',
    name: '2211 - Broadwoven fabric mills, cotton',
  },
  {
    id: '222',
    name: '222 - Broadwoven Fabric Mills, Manmade Fiber and Silk',
  },
  {
    id: '2221',
    name: '2221 - Broadwoven fabric mills, manmade fiber and silk',
  },
  {
    id: '223',
    name: '223 - Broadwoven Fabric Mills, Wool',
  },
  {
    id: '2231',
    name: '2231 - Broadwoven fabric mills, wool',
  },
  {
    id: '224',
    name: '224 - Narrow Fabric and Other Smallwares Mills',
  },
  {
    id: '2241',
    name: '2241 - Narrow fabric and other smallwares mills',
  },
  { id: '225', name: '225 - Knitting Mills' },
  {
    id: '2251',
    name: "2251 - Women's hosiery, except socks",
  },
  {
    id: '2252',
    name: '2252 - Hosiery, nec',
  },
  {
    id: '2253',
    name: '2253 - Knit outerwear mills',
  },
  {
    id: '2254',
    name: '2254 - Knit underwear and nightwear mills',
  },
  {
    id: '2257',
    name: '2257 - Weft knit fabric mills',
  },
  {
    id: '2258',
    name: '2258 - Lace & warp knit fabric mills',
  },
  {
    id: '2259',
    name: '2259 - Knitting mills, nec',
  },
  {
    id: '226',
    name: '226 - Dyeing and Finishing Textiles, Except Wool Fabrics',
  },
  {
    id: '2261',
    name: '2261 - Finishers of broadwoven fabrics of cotton',
  },
  {
    id: '2262',
    name: '2262 - Finishers of broadwoven manmade fabrics and silk',
  },
  {
    id: '2269',
    name: '2269 - Finishers of textiles, nec',
  },
  { id: '227', name: '227 - Carpets and Rugs' },
  {
    id: '2273',
    name: '2273 - Carpets and rugs',
  },
  {
    id: '228',
    name: '228 - Yarn and Thread Mills',
  },
  {
    id: '2281',
    name: '2281 - Yarn spinning mills',
  },
  {
    id: '2282',
    name: '2282 - Yarn texturizing, throwing, twisting & winding',
  },
  {
    id: '2284',
    name: '2284 - Thread mills',
  },
  {
    id: '229',
    name: '229 - Misc. Textile Goods',
  },
  {
    id: '2295',
    name: '2295 - Coated fabrics, not rubberized',
  },
  {
    id: '2296',
    name: '2296 - Tire cord and fabrics',
  },
  {
    id: '2297',
    name: '2297 - Non-woven fabrics',
  },
  {
    id: '2298',
    name: '2298 - Cordage and twine',
  },
  {
    id: '2299',
    name: '2299 - Textile goods, nec',
  },
  { id: '23', name: '23 - APPAREL AND OTHER TEXTILE PRODUCTS' },
  {
    id: '231',
    name: "231 - Men's and Boys' Suits, Coats and Overcoats",
  },
  {
    id: '2311',
    name: "2311 - Men's and boys' suits, coats and overcoats",
  },
  {
    id: '232',
    name: "232 - Men's and Boys' Furnishings",
  },
  {
    id: '2321',
    name: "2321 - Men's and boys' shirts, except work shirts",
  },
  {
    id: '2322',
    name: "2322 - Men's & boys' underwear and nightwear",
  },
  {
    id: '2323',
    name: "2323 - Men's and boys' neckwear",
  },
  {
    id: '2325',
    name: "2325 - Men's & boys' separate rousers and slacks",
  },
  {
    id: '2326',
    name: "2326 - Men's and boys' work clothing",
  },
  {
    id: '2329',
    name: "2329 - Men's and boys' clothing, nec",
  },
  {
    id: '233',
    name: "233 - Women's, Misses' and Juniors' Outerwear",
  },
  {
    id: '2331',
    name: "2331 - Women's, misses' and juniors' blouses & shirts",
  },
  {
    id: '2335',
    name: "2335 - Women's, misses' and juniors' dresses",
  },
  {
    id: '2337',
    name: "2337 - Women's, misses' and juniors suits, skirts & coats",
  },
  {
    id: '2339',
    name: "2339 - Women's, misses' and juniors outerwear, nec",
  },
  {
    id: '234',
    name: "234 - Women's and Children's Undergarments",
  },
  {
    id: '2341',
    name: "2341 - Women's and children's underwear and nightwear",
  },
  {
    id: '2342',
    name: '2342 - Brassieres, girdles and allied garments',
  },
  {
    id: '235',
    name: '235 - Hats, Caps and Millinery',
  },
  {
    id: '2353',
    name: '2353 - Hats, caps and millinery',
  },
  {
    id: '236',
    name: "236 - Girls', Children's and Infants' Outerwear",
  },
  {
    id: '2361',
    name: "2361 - Girls', children's and infants' dresses & blouses",
  },
  {
    id: '2369',
    name: "2369 - Girls', children's and infants' outerwear, nec",
  },
  { id: '237', name: '237 - Fur Goods' },
  {
    id: '2371',
    name: '2371 - Fur goods',
  },
  {
    id: '238',
    name: '238 - Misc. Apparel and Accessories',
  },
  {
    id: '2381',
    name: '2381 - Dress and work gloves, except knit and all-leather',
  },
  {
    id: '2384',
    name: '2384 - Robes and dressing gowns',
  },
  {
    id: '2385',
    name: '2385 - Waterproof outerwear',
  },
  {
    id: '2386',
    name: '2386 - Leather and sheep-lined clothing',
  },
  {
    id: '2387',
    name: '2387 - Apparel belts',
  },
  {
    id: '2389',
    name: '2389 - Apparel and accessories, nec',
  },
  {
    id: '239',
    name: '239 - Misc. Fabricated Textile Products',
  },
  {
    id: '2391',
    name: '2391 - Curtains and draperies',
  },
  {
    id: '2392',
    name: '2392 - House furnishings, except curtains and draperies',
  },
  {
    id: '2393',
    name: '2393 - Textile bags',
  },
  {
    id: '2394',
    name: '2394 - Canvas and related products',
  },
  {
    id: '2395',
    name: '2395 - Pleating and stitching',
  },
  {
    id: '2396',
    name: '2396 - Automotive trimmings and apparel findings',
  },
  {
    id: '2397',
    name: '2397 - Schiffli machine embroideries',
  },
  {
    id: '2399',
    name: '2399 - Fabricated textile products, nec',
  },
  { id: '24', name: '24 - LUMBER AND WOOD PRODUCTS, EXCEPT FURNITURE' },
  { id: '241', name: '241 - Logging' },
  {
    id: '2411',
    name: '2411 - Logging',
  },
  {
    id: '242',
    name: '242 - Sawmills and Planing Mills',
  },
  {
    id: '2421',
    name: '2421 - Sawmills and planing mills, general',
  },
  {
    id: '2426',
    name: '2426 - Hardwood dimension and flooring mills',
  },
  {
    id: '2429',
    name: '2429 - Special product sawmills, nec',
  },
  {
    id: '243',
    name: '243 - Millwork, Veneer, Plywood and Structural Wood',
  },
  {
    id: '2431',
    name: '2431 - Millwork',
  },
  {
    id: '2434',
    name: '2434 - Wood kitchen cabinets',
  },
  {
    id: '2435',
    name: '2435 - Hardwood veneer and plywood',
  },
  {
    id: '2436',
    name: '2436 - Softwood veneer and plywood',
  },
  {
    id: '2439',
    name: '2439 - Structural wood members, nec',
  },
  { id: '244', name: '244 - Wood Containers' },
  {
    id: '2441',
    name: '2441 - Nailed and lock corner wood boxes and shook',
  },
  {
    id: '2448',
    name: '2448 - Wood pallets and skids',
  },
  {
    id: '2449',
    name: '2449 - Wood containers, nec',
  },
  {
    id: '245',
    name: '245 - Wood Buildings and Mobile Homes',
  },
  {
    id: '2451',
    name: '2451 - Mobile homes',
  },
  {
    id: '2452',
    name: '2452 - Prefabricated wood buildings and components',
  },
  {
    id: '249',
    name: '249 - Misc. Wood Products',
  },
  {
    id: '2491',
    name: '2491 - Wood preserving',
  },
  {
    id: '2493',
    name: '2493 - Reconstituted wood products',
  },
  {
    id: '2499',
    name: '2499 - Wood products, nec',
  },
  { id: '25', name: '25 - FURNITURE AND FIXTURES' },
  {
    id: '251',
    name: '251 - Household Furniture',
  },
  {
    id: '2511',
    name: '2511 - Wood household furniture, except upholstered',
  },
  {
    id: '2512',
    name: '2512 - Wood household furniture, upholstered',
  },
  {
    id: '2514',
    name: '2514 - Metal household furniture',
  },
  {
    id: '2515',
    name: '2515 - Mattresses, foundations and convertible beds',
  },
  {
    id: '2517',
    name: '2517 - Wood TV, radio, phonograph and sewing cabinets',
  },
  {
    id: '2519',
    name: '2519 - Household furniture, nec',
  },
  { id: '252', name: '252 - Office Furniture' },
  {
    id: '2521',
    name: '2521 - Wood office furniture',
  },
  {
    id: '2522',
    name: '2522 - Office furniture, except wood',
  },
  {
    id: '253',
    name: '253 - Public Building and Related Furniture',
  },
  {
    id: '2531',
    name: '2531 - Public building and related furniture',
  },
  {
    id: '254',
    name: '254 - Office and Store Partitions and Fixtures',
  },
  {
    id: '2541',
    name: '2541 - Office & store partitions & fixtures, wood',
  },
  {
    id: '2542',
    name: '2542 - Office & store partitions & fixtures, except wood',
  },
  {
    id: '259',
    name: '259 - Misc. Furniture and Fixtures',
  },
  {
    id: '2591',
    name: '2591 - Drapery hardware and windows blinds and shades',
  },
  {
    id: '2599',
    name: '2599 - Furniture and fixtures, nec',
  },
  { id: '26', name: '26 - PAPER AND ALLIED PRODUCTS' },
  { id: '261', name: '261 - Pulp Mills' },
  {
    id: '2611',
    name: '2611 - Pulp mills',
  },
  { id: '262', name: '262 - Paper Mills' },
  {
    id: '2621',
    name: '2621 - Paper mills',
  },
  { id: '263', name: '263 - Paperboard Mills' },
  {
    id: '2631',
    name: '2631 - Paperboard mills',
  },
  {
    id: '265',
    name: '265 - Paperboard Containers and Boxes',
  },
  {
    id: '2652',
    name: '2652 - Setup paperboard boxes',
  },
  {
    id: '2653',
    name: '2653 - Corrugated and solid fiber boxes',
  },
  {
    id: '2655',
    name: '2655 - Fiber cans, tubes, drums and similar products',
  },
  {
    id: '2656',
    name: '2656 - Sanitary food containers, except folding',
  },
  {
    id: '2657',
    name: '2657 - Folding paperboard boxes, including sanitary',
  },
  {
    id: '267',
    name: '267 - Misc. Converted Paper & Paperboard Product',
  },
  {
    id: '2671',
    name: '2671 - Packaging paper & plastic film, coated & laminated',
  },
  {
    id: '2672',
    name: '2672 - Coated and laminated paper, nec',
  },
  {
    id: '2673',
    name: '2673 - Bags: plastics, foil and coated paper',
  },
  {
    id: '2674',
    name: '2674 - Bags: uncoated paper & multiwall',
  },
  {
    id: '2675',
    name: '2675 - Die-cut paper and paperboard and cardboard',
  },
  {
    id: '2676',
    name: '2676 - Sanitary paper products',
  },
  {
    id: '2677',
    name: '2677 - Envelopes',
  },
  {
    id: '2678',
    name: '2678 - Stationery, tablets and related products',
  },
  {
    id: '2679',
    name: '2679 - Converted paper and paperboard products, nec',
  },
  { id: '27', name: '27 - PRINTING, PUBLISHING AND ALLIED INDUSTRIES' },
  {
    id: '271',
    name: '271 - Newspapers: Publishing or Publishing and Printing',
  },
  {
    id: '2711',
    name: '2711 - Newspapers: publishing or publishing and printing',
  },
  {
    id: '272',
    name: '272 - Periodicals: Publishing or Publishing and Printing',
  },
  {
    id: '2721',
    name: '2721 - Periodicals: publishing or publishing and printing',
  },
  { id: '273', name: '273 - Books' },
  {
    id: '2731',
    name: '2731 - Books: publishing or publishing and printing',
  },
  {
    id: '2732',
    name: '2732 - Book printing',
  },
  { id: '274', name: '274 - Misc. Publishing' },
  {
    id: '2741',
    name: '2741 - Misc. publishing',
  },
  {
    id: '275',
    name: '275 - Commercial Printing',
  },
  {
    id: '2752',
    name: '2752 - Commercial printing, lithographic',
  },
  {
    id: '2754',
    name: '2754 - Commercial printing, gravure',
  },
  {
    id: '2759',
    name: '2759 - Commercial printing, nec',
  },
  {
    id: '276',
    name: '276 - Manifold Business Forms',
  },
  {
    id: '2761',
    name: '2761 - Manifold business forms',
  },
  { id: '277', name: '277 - Greeting Cards' },
  {
    id: '2771',
    name: '2771 - Greeting cards',
  },
  {
    id: '278',
    name: '278 - Blankbooks. Looseleaf Binders and Bookbinding',
  },
  {
    id: '2782',
    name: '2782 - Blankbooks, looseleaf binders and devices',
  },
  {
    id: '2789',
    name: '2789 - Bookbinding and related work',
  },
  {
    id: '279',
    name: '279 - Service Industries for the Printing Trade',
  },
  {
    id: '2791',
    name: '2791 - Typesetting',
  },
  {
    id: '2796',
    name: '2796 - Platemaking and related services',
  },
  { id: '28', name: '28 - CHEMICALS AND ALLIED PRODUCTS' },
  {
    id: '281',
    name: '281 - Industrial Inorganic Chemicals',
  },
  {
    id: '2812',
    name: '2812 - Alkalies and chlorine',
  },
  {
    id: '2813',
    name: '2813 - Industrial gases',
  },
  {
    id: '2816',
    name: '2816 - Inorganic pigments',
  },
  {
    id: '2819',
    name: '2819 - Industrial inorganic chemicals nec',
  },
  {
    id: '282',
    name: '282 - Plastics Materials and Synthetics',
  },
  {
    id: '2821',
    name: '2821 - Plastics materials and resins',
  },
  {
    id: '2822',
    name: '2822 - Synthetic rubber',
  },
  {
    id: '2823',
    name: '2823 - Cellulosic manmade fibers',
  },
  {
    id: '2824',
    name: '2824 - Manmade Organic fibers, except cellulosic',
  },
  { id: '283', name: '283 - Drugs' },
  {
    id: '2833',
    name: '2833 - Medicinals chemicals and botanicals products',
  },
  {
    id: '2834',
    name: '2834 - Pharmaceutical preparations',
  },
  {
    id: '2835',
    name: '2835 - In vitro and in vivo diagnostic substances',
  },
  {
    id: '2836',
    name: '2836 - Biological products, except diagnostic substances',
  },
  {
    id: '284',
    name: '284 - Soap, Cleaners, and Toilet Goods',
  },
  {
    id: '2841',
    name: '2841 - Soap and other detergents',
  },
  {
    id: '2842',
    name: '2842 - Polishes and sanitation goods',
  },
  {
    id: '2843',
    name: '2843 - Surface active agents',
  },
  {
    id: '2844',
    name: '2844 - Toilet preparations',
  },
  {
    id: '285',
    name: '285 - Paints and Allied Products',
  },
  {
    id: '2851',
    name: '2851 - Paints and allied products',
  },
  {
    id: '286',
    name: '286 - Industrial Organic Chemicals',
  },
  {
    id: '2861',
    name: '2861 - Gum and wood chemicals',
  },
  {
    id: '2865',
    name: '2865 - Cyclic crudes and intermediates',
  },
  {
    id: '2869',
    name: '2869 - Industrial organic chemicals, nec',
  },
  {
    id: '287',
    name: '287 - Agricultural Chemicals',
  },
  {
    id: '2873',
    name: '2873 - Nitrogenous fertilizers',
  },
  {
    id: '2874',
    name: '2874 - Phosphatic fertilizers',
  },
  {
    id: '2875',
    name: '2875 - Fertilizers, mixing only',
  },
  {
    id: '2879',
    name: '2879 - Pesticides and agricultural chemicals, nec',
  },
  {
    id: '289',
    name: '289 - Misc. Chemical Products',
  },
  {
    id: '2891',
    name: '2891 - Adhesives and sealants',
  },
  {
    id: '2892',
    name: '2892 - Explosives',
  },
  {
    id: '2893',
    name: '2893 - Printing ink',
  },
  {
    id: '2895',
    name: '2895 - Carbon black',
  },
  {
    id: '2899',
    name: '2899 - Chemicals and chemical preparations, nec',
  },
  { id: '29', name: '29 - PETROLEUM REFINING AND RELATED INDUSTRIES' },
  { id: '291', name: '291 - Petroleum Refining' },
  {
    id: '2911',
    name: '2911 - Petroleum refining',
  },
  {
    id: '295',
    name: '295 - Asphalt Paving and Roofing Materials',
  },
  {
    id: '2951',
    name: '2951 - Asphalt paving mixtures and blocks',
  },
  {
    id: '2952',
    name: '2952 - Asphalt felts and coatings',
  },
  {
    id: '299',
    name: '299 - Misc. Petroleum and Coal Products',
  },
  {
    id: '2992',
    name: '2992 - Lubricating oils and greases',
  },
  {
    id: '2999',
    name: '2999 - Petroleum and coal products, nec',
  },
  { id: '30', name: '30 - RUBBER AND MISCELLANEOUS PLASTICS PRODUCTS' },
  {
    id: '301',
    name: '301 - Tires and Inner Tubes',
  },
  {
    id: '3011',
    name: '3011 - Tires and inner tubes',
  },
  {
    id: '302',
    name: '302 - Rubber and Plastics Footwear',
  },
  {
    id: '3021',
    name: '3021 - Rubber and plastics footwear',
  },
  {
    id: '305',
    name: '305 - Hose & Belts and Gaskets, Packing & Sealing Device',
  },
  {
    id: '3052',
    name: '3052 - Rubber & plastic hose and belting',
  },
  {
    id: '3053',
    name: '3053 - Gaskets, packing and sealing devices',
  },
  {
    id: '306',
    name: '306 - Fabricated Rubber Products, NEC',
  },
  {
    id: '3061',
    name: '3061 - Mechanical rubber goods',
  },
  {
    id: '3069',
    name: '3069 - Fabricated rubber products, nec',
  },
  {
    id: '308',
    name: '308 - Misc. Plastics Products, NEC',
  },
  {
    id: '3081',
    name: '3081 - Unsupported plastics film and sheet',
  },
  {
    id: '3082',
    name: '3082 - Unsupported plastics profile shapes',
  },
  {
    id: '3083',
    name: '3083 - Laminated plastics plate, sheet and profile shapes',
  },
  {
    id: '3084',
    name: '3084 - Plastics pipe',
  },
  {
    id: '3085',
    name: '3085 - Plastics bottles',
  },
  {
    id: '3086',
    name: '3086 - Plastics foam products',
  },
  {
    id: '3087',
    name: '3087 - Custom compounding of purchased plastics resins',
  },
  {
    id: '3088',
    name: '3088 - Plastics plumbing fixtures',
  },
  {
    id: '3089',
    name: '3089 - Plastics products, nec',
  },
  { id: '31', name: '31 - LEATHER AND LEATHER PRODUCTS' },
  {
    id: '311',
    name: '311 - Leather Tanning and Finishing',
  },
  {
    id: '3111',
    name: '3111 - Leather tanning and finishing',
  },
  { id: '313', name: '313 - Footwear Cut Stock' },
  {
    id: '3131',
    name: '3131 - Footwear cut stock',
  },
  {
    id: '314',
    name: '314 - Footwear, Except Rubber',
  },
  {
    id: '3142',
    name: '3142 - House slippers',
  },
  {
    id: '3143',
    name: "3143 - Men's footwear, except athletic",
  },
  {
    id: '3144',
    name: "3144 - Women's footwear, except athletic",
  },
  {
    id: '3149',
    name: '3149 - Footwear, except rubber, nec',
  },
  {
    id: '315',
    name: '315 - Leather Gloves and Mittens',
  },
  {
    id: '3151',
    name: '3151 - Leather gloves and mittens',
  },
  { id: '316', name: '316 - Luggage' },
  {
    id: '3161',
    name: '3161 - Luggage',
  },
  {
    id: '317',
    name: '317 - Handbags and Other Personal Leather Goods',
  },
  {
    id: '3171',
    name: "3171 - Women's handbags and purses",
  },
  {
    id: '3172',
    name: '3172 - Personal leather goods, nec',
  },
  { id: '319', name: '319 - Leather Goods, NEC' },
  {
    id: '3199',
    name: '3199 - Leather goods, nec',
  },
  { id: '32', name: '32 - STONE, CLAY, GLASS AND CONCRETE PRODUCTS' },
  { id: '321', name: '321 - Flat Glass' },
  {
    id: '3211',
    name: '3211 - Flat glass',
  },
  {
    id: '322',
    name: '322 - Glass and Glassware, Pressed or Blown',
  },
  {
    id: '3221',
    name: '3221 - Glass containers',
  },
  {
    id: '3229',
    name: '3229 - Pressed and blown glass and glassware, nec',
  },
  {
    id: '323',
    name: '323 - Products of Purchased Glass',
  },
  {
    id: '3231',
    name: '3231 - Products of purchased glass',
  },
  { id: '324', name: '324 - Cement, Hydraulic' },
  {
    id: '3241',
    name: '3241 - Cement, hydraulic',
  },
  {
    id: '325',
    name: '325 - Structural Clay Products',
  },
  {
    id: '3251',
    name: '3251 - Brick and structural clay tile',
  },
  {
    id: '3253',
    name: '3253 - Ceramic wall and floor tile',
  },
  {
    id: '3255',
    name: '3255 - Clay refractories',
  },
  {
    id: '3259',
    name: '3259 - Structural clay products, nec',
  },
  {
    id: '326',
    name: '326 - Pottery and Related Products',
  },
  {
    id: '3261',
    name: '3261 - Vitreous china plumbing fixtures and fittings',
  },
  {
    id: '3262',
    name: '3262 - Vitreous china table & kitchen articles',
  },
  {
    id: '3263',
    name: '3263 - Semivitreous table & kitchen articles',
  },
  {
    id: '3264',
    name: '3264 - Porcelain electrical supplies',
  },
  {
    id: '3269',
    name: '3269 - Pottery products, nec',
  },
  {
    id: '327',
    name: '327 - Concrete, Gypsum, and Plaster Products',
  },
  {
    id: '3271',
    name: '3271 - Concrete block and brick',
  },
  {
    id: '3272',
    name: '3272 - Concrete products, nec',
  },
  {
    id: '3273',
    name: '3273 - Ready-mixed concrete',
  },
  {
    id: '3274',
    name: '3274 - Lime',
  },
  {
    id: '3275',
    name: '3275 - Gypsum products',
  },
  {
    id: '328',
    name: '328 - Cut Stone and Stone Products',
  },
  {
    id: '3281',
    name: '3281 - Cut stone and stone products',
  },
  {
    id: '329',
    name: '329 - Misc. Nonmetallic Mineral Products',
  },
  {
    id: '3291',
    name: '3291 - Abrasive products',
  },
  {
    id: '3292',
    name: '3292 - Asbestos products',
  },
  {
    id: '3295',
    name: '3295 - Minerals and earth, ground or otherwise treated',
  },
  {
    id: '3296',
    name: '3296 - Mineral wool',
  },
  {
    id: '3297',
    name: '3297 - Nonclay refractories',
  },
  {
    id: '3299',
    name: '3299 - Nonmetallic mineral products, nec',
  },
  { id: '33', name: '33 - PRIMARY METAL INDUSTRIES' },
  {
    id: '331',
    name: '331 - Blast Furnace and Basic Steel Products',
  },
  {
    id: '3312',
    name: '3312 - Blast furnace and steel mills',
  },
  {
    id: '3313',
    name: '3313 - Electrometallurgical products',
  },
  {
    id: '3315',
    name: '3315 - Steel wire and related products',
  },
  {
    id: '3316',
    name: '3316 - Cold finishing of steel shapes',
  },
  {
    id: '3317',
    name: '3317 - Steel pipe and tubes',
  },
  {
    id: '332',
    name: '332 - Iron and Steel Foundries',
  },
  {
    id: '3321',
    name: '3321 - Gray & ductile & iron foundries',
  },
  {
    id: '3322',
    name: '3322 - Malleable iron foundries',
  },
  {
    id: '3324',
    name: '3324 - Steel investment foundries',
  },
  {
    id: '3325',
    name: '3325 - Steel foundries, nec',
  },
  {
    id: '333',
    name: '333 - Primary Nonferrous Metals',
  },
  {
    id: '3331',
    name: '3331 - Primary copper',
  },
  {
    id: '3334',
    name: '3334 - Primary aluminium',
  },
  {
    id: '3339',
    name: '3339 - Primary nonferrous metals, nec',
  },
  {
    id: '334',
    name: '334 - Secondary Nonferrous Metals',
  },
  {
    id: '3341',
    name: '3341 - Secondary nonferrous metals',
  },
  {
    id: '335',
    name: '335 - Nonferrous Rolling and Drawing',
  },
  {
    id: '3351',
    name: '3351 - Copper rolling and drawing',
  },
  {
    id: '3353',
    name: '3353 - Aluminium sheet, plate, and foil',
  },
  {
    id: '3354',
    name: '3354 - Aluminium extruded products',
  },
  {
    id: '3355',
    name: '3355 - Aluminium rolling and drawing, nec',
  },
  {
    id: '3356',
    name: '3356 - Nonferrous rolling & drawing, nec',
  },
  {
    id: '3357',
    name: '3357 - Nonferrous wiredrawing/insulating',
  },
  {
    id: '336',
    name: '336 - Nonferrous Foundries (Castings)',
  },
  {
    id: '3363',
    name: '3363 - Aluminium die-casting',
  },
  {
    id: '3364',
    name: '3364 - Nonferrous die-ca. exc. aluminium',
  },
  {
    id: '3365',
    name: '3365 - Aluminium foundries',
  },
  {
    id: '3366',
    name: '3366 - Copper foundries',
  },
  {
    id: '3369',
    name: '3369 - Nonferrous foundries, nec',
  },
  {
    id: '339',
    name: '339 - Misc. Primary Metal Products',
  },
  {
    id: '3398',
    name: '3398 - Metal heat treating',
  },
  {
    id: '3399',
    name: '3399 - Primary metal products, nec',
  },
  {
    id: '34',
    name: '34 - FABRICATED METAL PRODUCTS, EX. MACH. AND TRANS.',
  },
  {
    id: '341',
    name: '341 - Metal Cans and Shipping Containers',
  },
  {
    id: '3411',
    name: '3411 - Metal cans',
  },
  {
    id: '3412',
    name: '3412 - Metal barrels, drums, and pails',
  },
  {
    id: '342',
    name: '342 - Cutlery, Handtools, and Hardware',
  },
  {
    id: '3421',
    name: '3421 - Cutlery',
  },
  {
    id: '3423',
    name: '3423 - Hand and edge tools, nec',
  },
  {
    id: '3425',
    name: '3425 - Saw blades and handsaws',
  },
  {
    id: '3429',
    name: '3429 - Hardware, nec',
  },
  {
    id: '343',
    name: '343 - Plumbing and Heating, Except Electric',
  },
  {
    id: '3431',
    name: '3431 - Metal sanitary ware',
  },
  {
    id: '3432',
    name: '3432 - Plumbing fixture fittings and trim',
  },
  {
    id: '3433',
    name: '3433 - Heating equipment, except electric',
  },
  {
    id: '344',
    name: '344 - Fabricated Structural Metal Products',
  },
  {
    id: '3441',
    name: '3441 - Fabricated structural metal',
  },
  {
    id: '3442',
    name: '3442 - Metal doors, sash, and trim',
  },
  {
    id: '3443',
    name: '3443 - Fabricated plate work, boiler shop',
  },
  {
    id: '3444',
    name: '3444 - Sheet metal work',
  },
  {
    id: '3446',
    name: '3446 - Architectural metal work',
  },
  {
    id: '3448',
    name: '3448 - Prefabricated metal buildings',
  },
  {
    id: '3449',
    name: '3449 - Misc. metal work',
  },
  {
    id: '345',
    name: '345 - Screw Machine Products, Bolts, Etc.',
  },
  {
    id: '3451',
    name: '3451 - Screw machine products',
  },
  {
    id: '3452',
    name: '3452 - Bolts, nuts, rivets, and washers',
  },
  {
    id: '346',
    name: '346 - Metal Forgings and Stampings',
  },
  {
    id: '3462',
    name: '3462 - Iron and steel forgings',
  },
  {
    id: '3463',
    name: '3463 - Nonferrous forgings',
  },
  {
    id: '3465',
    name: '3465 - Automotive stampings',
  },
  {
    id: '3466',
    name: '3466 - Crowns and closures',
  },
  {
    id: '3469',
    name: '3469 - Metal stampings, nec',
  },
  {
    id: '347',
    name: '347 - Metal Services, NEC',
  },
  {
    id: '3471',
    name: '3471 - Plating and polishing',
  },
  {
    id: '3479',
    name: '3479 - Metal coating and allied services',
  },
  {
    id: '348',
    name: '348 - Ordinance and Accessories, NEC',
  },
  {
    id: '3482',
    name: '3482 - Small arms ammunition',
  },
  {
    id: '3483',
    name: '3483 - Ammunition exc. for small arms nec',
  },
  {
    id: '3484',
    name: '3484 - Small arms',
  },
  {
    id: '3489',
    name: '3489 - Ordinance and accessories, nec',
  },
  {
    id: '349',
    name: '349 - Misc. Fabricated Metal Products',
  },
  {
    id: '3491',
    name: '3491 - Industrial valves',
  },
  {
    id: '3492',
    name: '3492 - Fluid power valves & hose fittings',
  },
  {
    id: '3493',
    name: '3493 - Steel springs, except wire',
  },
  {
    id: '3494',
    name: '3494 - Valves and pipe fittings, nec',
  },
  {
    id: '3495',
    name: '3495 - Wire springs',
  },
  {
    id: '3496',
    name: '3496 - Misc. fabricated wire products',
  },
  {
    id: '3497',
    name: '3497 - Metal foil and leaf',
  },
  {
    id: '3498',
    name: '3498 - Fabricated pipe and fittings',
  },
  {
    id: '3499',
    name: '3499 - Fabricated metal products, nec',
  },
  {
    id: '35',
    name: '35 - INDUSTRIAL AND COMMERCIAL MACHINERY & COMPUTERS',
  },
  {
    id: '351',
    name: '351 - Engines and Turbines',
  },
  {
    id: '3511',
    name: '3511 - Turbines & turbine generator sets',
  },
  {
    id: '3519',
    name: '3519 - Internal combustion engines, nec',
  },
  {
    id: '352',
    name: '352 - Farm and Garden Machinery',
  },
  {
    id: '3523',
    name: '3523 - Farm machinery and equipment',
  },
  {
    id: '3524',
    name: '3524 - Lawn and garden equipment',
  },
  {
    id: '353',
    name: '353 - Construction and Related Machinery',
  },
  {
    id: '3531',
    name: '3531 - Construction machinery',
  },
  {
    id: '3532',
    name: '3532 - Mining machinery',
  },
  {
    id: '3533',
    name: '3533 - Oil and gas field machinery',
  },
  {
    id: '3534',
    name: '3534 - Elevators and moving stairways',
  },
  {
    id: '3535',
    name: '3535 - Conveyors and conveying equipment',
  },
  {
    id: '3536',
    name: '3536 - Hoists, cranes, and monorails',
  },
  {
    id: '3537',
    name: '3537 - Industrial trucks and tractors',
  },
  {
    id: '354',
    name: '354 - Metalworking Machinery',
  },
  {
    id: '3541',
    name: '3541 - Machine tools, metal cutting types',
  },
  {
    id: '3542',
    name: '3542 - Machine tools, metal forming types',
  },
  {
    id: '3543',
    name: '3543 - Industrial patterns',
  },
  {
    id: '3544',
    name: '3544 - Special dies tools jigs & fixtures',
  },
  {
    id: '3545',
    name: '3545 - Machine tool accessories',
  },
  {
    id: '3546',
    name: '3546 - Power-driven handtools',
  },
  {
    id: '3547',
    name: '3547 - Rolling mill machinery',
  },
  {
    id: '3548',
    name: '3548 - Welding apparatus',
  },
  {
    id: '3549',
    name: '3549 - Metalworking machinery, nec',
  },
  {
    id: '355',
    name: '355 - Special Industry Machinery',
  },
  {
    id: '3552',
    name: '3552 - Textile machinery',
  },
  {
    id: '3553',
    name: '3553 - Woodworking machinery',
  },
  {
    id: '3554',
    name: '3554 - Paper industries machinery',
  },
  {
    id: '3555',
    name: '3555 - Printing trades machinery',
  },
  {
    id: '3556',
    name: '3556 - Food products machinery',
  },
  {
    id: '3559',
    name: '3559 - Special industry machinery, nec',
  },
  {
    id: '356',
    name: '356 - General Industrial Machinery',
  },
  {
    id: '3561',
    name: '3561 - Pumps and pumping equipment',
  },
  {
    id: '3562',
    name: '3562 - Ball and roller bearings',
  },
  {
    id: '3563',
    name: '3563 - Air and gas compressors',
  },
  {
    id: '3564',
    name: '3564 - Blowers, fans & air purification',
  },
  {
    id: '3565',
    name: '3565 - Packaging machinery',
  },
  {
    id: '3566',
    name: '3566 - Speed changers, drives, and gears',
  },
  {
    id: '3567',
    name: '3567 - Industrial furnaces and ovens',
  },
  {
    id: '3568',
    name: '3568 - Power transmissions equipment, nec',
  },
  {
    id: '3569',
    name: '3569 - General industrial machinery, nec',
  },
  {
    id: '357',
    name: '357 - Computer and Office Equipment',
  },
  {
    id: '3571',
    name: '3571 - Electronic computers',
  },
  {
    id: '3572',
    name: '3572 - Computer storage devices',
  },
  {
    id: '3575',
    name: '3575 - Computer terminals',
  },
  {
    id: '3577',
    name: '3577 - Computer peripheral equipment',
  },
  {
    id: '3578',
    name: '3578 - Calculating & accounting equipment',
  },
  {
    id: '3579',
    name: '3579 - Office machines, nec',
  },
  {
    id: '358',
    name: '358 - Refrigeration and Service Machinery',
  },
  {
    id: '3581',
    name: '3581 - Automatic vending machines',
  },
  {
    id: '3582',
    name: '3582 - Commercial laundry equipment',
  },
  {
    id: '3585',
    name: '3585 - Refrigeration & heating equipment',
  },
  {
    id: '3586',
    name: '3586 - Measuring and dispensing pumps',
  },
  {
    id: '3589',
    name: '3589 - Service industry machinery, nec',
  },
  {
    id: '359',
    name: '359 - Industrial Machinery, NEC',
  },
  {
    id: '3592',
    name: '3592 - Carburetors pistons rings valves',
  },
  {
    id: '3593',
    name: '3593 - Fluid power cylinders & actuators',
  },
  {
    id: '3594',
    name: '3594 - Fluid power pumps and motors',
  },
  {
    id: '3596',
    name: '3596 - Scales & balances, exc. laboratory',
  },
  {
    id: '3599',
    name: '3599 - Industrial & commercial machinery, nec',
  },
  {
    id: '36',
    name: '36 - ELECTRONIC & OTHER ELECTRIC EQUIPMENT EX COMPUTERS',
  },
  {
    id: '361',
    name: '361 - Electric Distribution Equipment',
  },
  {
    id: '3612',
    name: '3612 - Transformers, except electronic',
  },
  {
    id: '3613',
    name: '3613 - Switchgear & switchboard apparatus',
  },
  {
    id: '362',
    name: '362 - Electrical Industrial Apparatus',
  },
  {
    id: '3621',
    name: '3621 - Motors and generators',
  },
  {
    id: '3624',
    name: '3624 - Carbon and graphite products',
  },
  {
    id: '3625',
    name: '3625 - Relays and industrial controls',
  },
  {
    id: '3629',
    name: '3629 - Electrical industrial apparat. nec',
  },
  {
    id: '363',
    name: '363 - Household Appliances',
  },
  {
    id: '3631',
    name: '3631 - Household cooking equipment',
  },
  {
    id: '3632',
    name: '3632 - Household refrigerators & freezers',
  },
  {
    id: '3633',
    name: '3633 - Household laundry equipment',
  },
  {
    id: '3634',
    name: '3634 - Electric housewares and fans',
  },
  {
    id: '3635',
    name: '3635 - Household vacuum cleaners',
  },
  {
    id: '3639',
    name: '3639 - Household appliances, nec',
  },
  {
    id: '364',
    name: '364 - Electric Lighting and Wiring Equipment',
  },
  {
    id: '3641',
    name: '3641 - Electric lamps',
  },
  {
    id: '3643',
    name: '3643 - Current-carrying wiring devices',
  },
  {
    id: '3644',
    name: '3644 - Noncurrent-carrying wiring devices',
  },
  {
    id: '3645',
    name: '3645 - Residential lighting fixtures',
  },
  {
    id: '3646',
    name: '3646 - Commercial lighting fixtures',
  },
  {
    id: '3647',
    name: '3647 - Vehicular lighting equipment',
  },
  {
    id: '3648',
    name: '3648 - Lighting equipment, nec',
  },
  {
    id: '365',
    name: '365 - Household Audio and Video Equipment',
  },
  {
    id: '3651',
    name: '3651 - Household audio & video equipment',
  },
  {
    id: '3652',
    name: '3652 - Prerecorded records and tapes',
  },
  {
    id: '366',
    name: '366 - Communications Equipment',
  },
  {
    id: '3661',
    name: '3661 - Telephone and telegraph apparatus',
  },
  {
    id: '3663',
    name: '3663 - Radio & TV communications equip.',
  },
  {
    id: '3669',
    name: '3669 - Communications equipment, nec',
  },
  {
    id: '367',
    name: '367 - Electronic Components and Accessories',
  },
  {
    id: '3671',
    name: '3671 - Electron tubes',
  },
  {
    id: '3672',
    name: '3672 - Printed circuit boards',
  },
  {
    id: '3674',
    name: '3674 - Semiconductors & relative devices',
  },
  {
    id: '3675',
    name: '3675 - Electronic capacitors',
  },
  {
    id: '3676',
    name: '3676 - Electronic resistors',
  },
  {
    id: '3677',
    name: '3677 - Electronic coils and transformers',
  },
  {
    id: '3678',
    name: '3678 - Electronic connectors',
  },
  {
    id: '3679',
    name: '3679 - Electronic components, nec',
  },
  {
    id: '369',
    name: '369 - Misc. Electrical Equipment & Supplies',
  },
  {
    id: '3691',
    name: '3691 - Storage batteries',
  },
  {
    id: '3692',
    name: '3692 - Primary batteries, dry and wet',
  },
  {
    id: '3694',
    name: '3694 - Engine electrical equipment',
  },
  {
    id: '3695',
    name: '3695 - Magnetic & optical recording media',
  },
  {
    id: '3699',
    name: '3699 - Electrical equip. & supplies nec',
  },
  { id: '37', name: '37 - TRANSPORTATION EQUIPMENT' },
  {
    id: '371',
    name: '371 - Motor Vehicles and Equipment',
  },
  {
    id: '3711',
    name: '3711 - Motor vehicles and car bodies',
  },
  {
    id: '3713',
    name: '3713 - Truck and bus bodies',
  },
  {
    id: '3714',
    name: '3714 - Motor vehicles parts & accessories',
  },
  {
    id: '3715',
    name: '3715 - Truck trailers',
  },
  {
    id: '3716',
    name: '3716 - Motor homes',
  },
  { id: '372', name: '372 - Aircraft and Parts' },
  {
    id: '3721',
    name: '3721 - Aircraft',
  },
  {
    id: '3724',
    name: '3724 - Aircraft engines and engine parts',
  },
  {
    id: '3728',
    name: '3728 - Aircraft parts and equipment, nec',
  },
  {
    id: '373',
    name: '373 - Ship and Boat Building and Repairing',
  },
  {
    id: '3731',
    name: '3731 - Ship building and repairing',
  },
  {
    id: '3732',
    name: '3732 - Boat building and repairing',
  },
  { id: '374', name: '374 - Railroad Equipment' },
  {
    id: '3743',
    name: '3743 - Railroad equipment',
  },
  {
    id: '375',
    name: '375 - Motorcycles, Bicycles, and Parts',
  },
  {
    id: '3751',
    name: '3751 - Motorcycles, bicycles, and parts',
  },
  {
    id: '376',
    name: '376 - Guided Missiles, Space Vehicles, Parts',
  },
  {
    id: '3761',
    name: '3761 - Guided missiles and space vehicles',
  },
  {
    id: '3764',
    name: '3764 - Space propulsion units and parts',
  },
  {
    id: '3769',
    name: '3769 - Space vehicle equipment, nec',
  },
  {
    id: '379',
    name: '379 - Misc. Transportation Equipment',
  },
  {
    id: '3792',
    name: '3792 - Travel trailers and campers',
  },
  {
    id: '3795',
    name: '3795 - Tanks and tank components',
  },
  {
    id: '3799',
    name: '3799 - Transportation equipment, nec',
  },
  { id: '38', name: '38 - INSTRUMENTS AND RELATED PRODUCTS' },
  {
    id: '381',
    name: '381 - Search and Navigational Equipment',
  },
  {
    id: '3812',
    name: '3812 - Search and navigational equipment',
  },
  {
    id: '382',
    name: '382 - Measuring and Controlling Devices',
  },
  {
    id: '3821',
    name: '3821 - Laboratory apparatus and furniture',
  },
  {
    id: '3822',
    name: '3822 - Environmental controls',
  },
  {
    id: '3823',
    name: '3823 - Process control instruments',
  },
  {
    id: '3824',
    name: '3824 - Fluid meters and counting devices',
  },
  {
    id: '3825',
    name: '3825 - Instruments to measure electricity',
  },
  {
    id: '3826',
    name: '3826 - Analytical instruments',
  },
  {
    id: '3827',
    name: '3827 - Optical instruments and lenses',
  },
  {
    id: '3829',
    name: '3829 - Measuring/controlling devices nec',
  },
  {
    id: '384',
    name: '384 - Medical Instruments and Supplies',
  },
  {
    id: '3841',
    name: '3841 - Surgical and medical instruments',
  },
  {
    id: '3842',
    name: '3842 - Orthopedic, Prosthetic & Surgical appl. & suppl.',
  },
  {
    id: '3843',
    name: '3843 - Dental equipment and supplies',
  },
  {
    id: '3844',
    name: '3844 - X-ray apparatus and tubes',
  },
  {
    id: '3845',
    name: '3845 - Electromedical equipment',
  },
  { id: '385', name: '385 - Ophthalmic Goods' },
  {
    id: '3851',
    name: '3851 - Ophthalmic goods',
  },
  {
    id: '386',
    name: '386 - Photographic equipment and supplies',
  },
  {
    id: '3861',
    name: '3861 - Photographic equipment & supplies',
  },
  {
    id: '387',
    name: '387 - Watches, Clocks, Watchcases & Parts',
  },
  {
    id: '3873',
    name: '3873 - Watches clocks watchcases & parts',
  },
  { id: '39', name: '39 - MISCELLANEOUS MANUFACTURING INDUSTRIES' },
  {
    id: '391',
    name: '391 - Jewelry, Silverware, and Plated Ware',
  },
  {
    id: '3911',
    name: '3911 - Jewelry, precious metal',
  },
  {
    id: '3914',
    name: '3914 - Silverware and plated ware',
  },
  {
    id: '3915',
    name: "3915 - Jewelers' materials/lapidary work",
  },
  {
    id: '393',
    name: '393 - Musical Instruments',
  },
  {
    id: '3931',
    name: '3931 - Musical instruments',
  },
  {
    id: '394',
    name: '394 - Toys and Sporting Goods',
  },
  {
    id: '3942',
    name: '3942 - Dolls and stuffed toys',
  },
  {
    id: '3944',
    name: "3944 - Games, toys, & children's vehicles",
  },
  {
    id: '3949',
    name: '3949 - Sporting and athletic goods, nec',
  },
  {
    id: '395',
    name: '395 - Pens, Pencils, Office, & Art Supplies',
  },
  {
    id: '3951',
    name: '3951 - Pens and mechanical pencils',
  },
  {
    id: '3952',
    name: '3952 - Lead pencils and art goods',
  },
  {
    id: '3953',
    name: '3953 - Marking devices',
  },
  {
    id: '3955',
    name: '3955 - Carbon paper and inked ribbons',
  },
  {
    id: '396',
    name: '396 - Costume Jewelry and Notions',
  },
  {
    id: '3961',
    name: '3961 - Costume jewelry',
  },
  {
    id: '3965',
    name: '3965 - Fasteners buttons needles & pins',
  },
  {
    id: '399',
    name: '399 - Misc. Manufacturers',
  },
  {
    id: '3991',
    name: '3991 - Brooms and brushes',
  },
  {
    id: '3993',
    name: '3993 - Signs and advertising specialities',
  },
  {
    id: '3995',
    name: '3995 - Burial caskets',
  },
  {
    id: '3996',
    name: '3996 - Hard surface floor coverings, nec',
  },
  {
    id: '3999',
    name: '3999 - Manufacturing industries, nec',
  },
  { id: '40', name: '40 - RAILROAD TRANSPORTATION' },
  { id: '401', name: '401 - Railroads' },
  {
    id: '4011',
    name: '4011 - Railroads, line-haul operating',
  },
  {
    id: '4013',
    name: '4013 - Switching and terminal services',
  },
  { id: '41', name: '41 - LOCAL AND INTERURBAN PASSENGER TRANSIT' },
  {
    id: '411',
    name: '411 - Local and Suburban Transportation',
  },
  {
    id: '4111',
    name: '4111 - Local and suburban transit',
  },
  {
    id: '4119',
    name: '4119 - Local passenger transportation nec',
  },
  { id: '412', name: '412 - Taxicabs' },
  {
    id: '4121',
    name: '4121 - Taxicabs',
  },
  {
    id: '413',
    name: '413 - Intercity and Rural Bus Transportation',
  },
  {
    id: '4131',
    name: '4131 - Intercity & rural bus transport.',
  },
  {
    id: '414',
    name: '414 - Bus Charter Service',
  },
  {
    id: '4141',
    name: '4141 - Local bus charter service',
  },
  {
    id: '4142',
    name: '4142 - Bus charter service, except local',
  },
  { id: '415', name: '415 - School Buses' },
  {
    id: '4151',
    name: '4151 - School buses',
  },
  {
    id: '417',
    name: '417 - Bus Terminal and Service Facilities',
  },
  {
    id: '4173',
    name: '4173 - Bus terminal & service facilities',
  },
  { id: '42', name: '42 - MOTOR FREIGHT TRANSPORTATION AND WAREHOUSING' },
  {
    id: '421',
    name: '421 - Trucking & Courier Service, Ex. Air',
  },
  {
    id: '4212',
    name: '4212 - Local trucking, without storage',
  },
  {
    id: '4213',
    name: '4213 - Trucking, except local',
  },
  {
    id: '4214',
    name: '4214 - Local trucking with storage',
  },
  {
    id: '4215',
    name: '4215 - Courier services, except by air',
  },
  {
    id: '422',
    name: '422 - Public Warehousing and Storage',
  },
  {
    id: '4221',
    name: '4221 - Farm product warehousing & storage',
  },
  {
    id: '4222',
    name: '4222 - Refrigerated warehousing & storage',
  },
  {
    id: '4225',
    name: '4225 - General warehousing and storage',
  },
  {
    id: '4226',
    name: '4226 - Special warehousing & storage, nec',
  },
  {
    id: '423',
    name: '423 - Trucking Terminal Facilities',
  },
  {
    id: '4231',
    name: '4231 - Trucking terminal facilities',
  },
  { id: '43', name: '43 - UNITED STATES POSTAL SERVICE' },
  {
    id: '431',
    name: '431 - U.S. Postal Service',
  },
  {
    id: '4311',
    name: '4311 - U.S. Postal Service',
  },
  { id: '44', name: '44 - WATER TRANSPORTATION' },
  {
    id: '441',
    name: '441 - Deep Sea Foreign Transportation of Freight',
  },
  {
    id: '4412',
    name: '4412 - Deep sea foreign transportation of freight',
  },
  {
    id: '442',
    name: '442 - Deep Sea Domestic Transportation of Freight',
  },
  {
    id: '4424',
    name: '4424 - Deep sea domestic transportation of freight',
  },
  {
    id: '443',
    name: '443 - Freight Transportation on the Great Lakes',
  },
  {
    id: '4432',
    name: '4432 - Freight Trans. on the Great Lakes',
  },
  {
    id: '444',
    name: '444 - Water Transportation of Freight, NEC',
  },
  {
    id: '4449',
    name: '4449 - Water transportation of freight, nec',
  },
  {
    id: '448',
    name: '448 - Water Transportation of Passengers',
  },
  {
    id: '4481',
    name: '4481 - Deep sea passenger transportation, except ferry',
  },
  {
    id: '4482',
    name: '4482 - Ferries',
  },
  {
    id: '4489',
    name: '4489 - Water passenger transportation nec',
  },
  {
    id: '449',
    name: '449 - Water Transportation Services',
  },
  {
    id: '4491',
    name: '4491 - Marine cargo handling',
  },
  {
    id: '4492',
    name: '4492 - Towing and tugboat service',
  },
  {
    id: '4493',
    name: '4493 - Marinas',
  },
  {
    id: '4499',
    name: '4499 - Water transportation services, nec',
  },
  { id: '45', name: '45 - TRANSPORTATION BY AIR' },
  {
    id: '451',
    name: '451 - Air Transportation, Scheduled',
  },
  {
    id: '4512',
    name: '4512 - Air transportation, scheduled',
  },
  {
    id: '4513',
    name: '4513 - Air courier, service',
  },
  {
    id: '452',
    name: '452 - Air Transportation, Nonscheduled',
  },
  {
    id: '4522',
    name: '4522 - Air transportation, nonscheduled',
  },
  {
    id: '458',
    name: '458 - Airports, Flying Fields, & Services',
  },
  {
    id: '4581',
    name: '4581 - Airports flying fields & services',
  },
  { id: '46', name: '46 - PIPELINES, EXCEPT NATURAL GAS' },
  {
    id: '461',
    name: '461 - Pipelines, Except Natural Gas',
  },
  {
    id: '4612',
    name: '4612 - Crude petroleum pipelines',
  },
  {
    id: '4613',
    name: '4613 - Refined petroleum pipelines',
  },
  {
    id: '4619',
    name: '4619 - Pipelines, nec',
  },
  { id: '47', name: '47 - TRANSPORTATION SERVICES' },
  {
    id: '472',
    name: '472 - Passenger Transportation Arrangement',
  },
  {
    id: '4724',
    name: '4724 - Travel agencies',
  },
  {
    id: '4725',
    name: '4725 - Tour operators',
  },
  {
    id: '4729',
    name: '4729 - Passenger transport arrangemnt nec',
  },
  {
    id: '473',
    name: '473 - Freight Transportation Arrangement',
  },
  {
    id: '4731',
    name: '4731 - Freight transportation arrangement',
  },
  {
    id: '474',
    name: '474 - Rental of Railroad Cars',
  },
  {
    id: '4741',
    name: '4741 - Rental of railroad cars',
  },
  {
    id: '478',
    name: '478 - Misc. Transportation Services',
  },
  {
    id: '4783',
    name: '4783 - Packaging and crating',
  },
  {
    id: '4785',
    name: '4785 - Inspection & fixed facilities',
  },
  {
    id: '4789',
    name: '4789 - Transportation services, nec',
  },
  { id: '48', name: '48 - COMMUNICATIONS' },
  {
    id: '481',
    name: '481 - Telephone Communications',
  },
  {
    id: '4812',
    name: '4812 - Radiotelephone communications',
  },
  {
    id: '4813',
    name: '4813 - Telephone communication exc. radio',
  },
  {
    id: '482',
    name: '482 - Telegraph & Other Communications',
  },
  {
    id: '4822',
    name: '4822 - Telegraph & other communications',
  },
  {
    id: '483',
    name: '483 - Radio and Television Broadcasting',
  },
  {
    id: '4832',
    name: '4832 - Radio broadcasting stations',
  },
  {
    id: '4833',
    name: '4833 - Television broadcasting stations',
  },
  {
    id: '484',
    name: '484 - Cable and Other Pay TV Services',
  },
  {
    id: '4841',
    name: '4841 - Cable and other pay TV services',
  },
  {
    id: '489',
    name: '489 - Communications Services, NEC',
  },
  {
    id: '4899',
    name: '4899 - Communications services, nec',
  },
  { id: '49', name: '49 - ELECTRIC, GAS, AND SANITARY SERVICE' },
  { id: '491', name: '491 - Electric Services' },
  {
    id: '4911',
    name: '4911 - Electric services',
  },
  {
    id: '492',
    name: '492 - Gas Production and Distribution',
  },
  {
    id: '4922',
    name: '4922 - Natural gas transmission',
  },
  {
    id: '4923',
    name: '4923 - Gas transmission and distribution',
  },
  {
    id: '4924',
    name: '4924 - Natural gas distribution',
  },
  {
    id: '4925',
    name: '4925 - Gas production and/or distribution',
  },
  {
    id: '493',
    name: '493 - Combination Utility Services',
  },
  {
    id: '4931',
    name: '4931 - Electric & other services combined',
  },
  {
    id: '4932',
    name: '4932 - Gas and other services combined',
  },
  {
    id: '4939',
    name: '4939 - Combination utilities, nec',
  },
  { id: '494', name: '494 - Water Supply' },
  {
    id: '4941',
    name: '4941 - Water supply',
  },
  { id: '495', name: '495 - Sanitary Services' },
  {
    id: '4952',
    name: '4952 - Sewerage systems',
  },
  {
    id: '4953',
    name: '4953 - Refuse systems',
  },
  {
    id: '4959',
    name: '4959 - Sanitary Services',
  },
  {
    id: '496',
    name: '496 - Steam and Air-Conditioning Supply',
  },
  {
    id: '4961',
    name: '4961 - Steam and air-conditioning supply',
  },
  { id: '497', name: '497 - Irrigation Systems' },
  {
    id: '4971',
    name: '4971 - Irrigation systems',
  },
  { id: '50', name: '50 - WHOLESALE TRADE-DURABLE GOODS' },
  {
    id: '501',
    name: '501 - Motor Vehicles, Parts, and Supplies',
  },
  {
    id: '5012',
    name: '5012 - Automobiles & other motor vehicles',
  },
  {
    id: '5013',
    name: '5013 - Motor vehicle supplies & new parts',
  },
  {
    id: '5014',
    name: '5014 - Tires and tubes',
  },
  {
    id: '5015',
    name: '5015 - Motor vehicle parts, used',
  },
  {
    id: '502',
    name: '502 - Furniture and Homefurnishings',
  },
  {
    id: '5021',
    name: '5021 - Furniture',
  },
  {
    id: '5023',
    name: '5023 - Homefurnishings',
  },
  {
    id: '503',
    name: '503 - Lumber and Construction Materials',
  },
  {
    id: '5031',
    name: '5031 - Lumber, plywood, and millwork',
  },
  {
    id: '5032',
    name: '5032 - Brick, stone, & related materials',
  },
  {
    id: '5033',
    name: '5033 - Roofing, siding, & insulation',
  },
  {
    id: '5039',
    name: '5039 - Construction materials, nec',
  },
  {
    id: '504',
    name: '504 - Professional & Commercial Equipment',
  },
  {
    id: '5043',
    name: '5043 - Photographic equipment & supplies',
  },
  {
    id: '5044',
    name: '5044 - Office equipment',
  },
  {
    id: '5045',
    name: '5045 - Computers, peripherals & software',
  },
  {
    id: '5046',
    name: '5046 - Commercial equipment, nec',
  },
  {
    id: '5047',
    name: '5047 - Medical and hospital equipment',
  },
  {
    id: '5048',
    name: '5048 - Ophthalmic Goods',
  },
  {
    id: '5049',
    name: '5049 - Professional equipment, nec',
  },
  {
    id: '505',
    name: '505 - Metals and Minerals, Except Petroleum',
  },
  {
    id: '5051',
    name: '5051 - Metals service centers and offices',
  },
  {
    id: '5052',
    name: '5052 - Coal and other minerals and ores',
  },
  { id: '506', name: '506 - Electrical Goods' },
  {
    id: '5063',
    name: '5063 - Electrical apparatus and equipment',
  },
  {
    id: '5064',
    name: '5064 - Electrical appliances, TV & radios',
  },
  {
    id: '5065',
    name: '5065 - Electronic parts and equipment',
  },
  {
    id: '507',
    name: '507 - Hardware, Plumbing & Heating Equipment',
  },
  {
    id: '5072',
    name: '5072 - Hardware',
  },
  {
    id: '5074',
    name: '5074 - Plumbing & hydronic heating supply',
  },
  {
    id: '5075',
    name: '5075 - Warm air heating/air-conditioning',
  },
  {
    id: '5078',
    name: '5078 - Refrigeration equipment & supplies',
  },
  {
    id: '508',
    name: '508 - Machinery, Equipment, and Supplies',
  },
  {
    id: '5082',
    name: '5082 - Construction and mining machinery',
  },
  {
    id: '5083',
    name: '5083 - Farm and garden machinery',
  },
  {
    id: '5084',
    name: '5084 - Industrial machinery and equipment',
  },
  {
    id: '5085',
    name: '5085 - Industrial supplies',
  },
  {
    id: '5087',
    name: '5087 - Service establishment equipment',
  },
  {
    id: '5088',
    name: '5088 - Transportation equip. & supplies',
  },
  {
    id: '509',
    name: '509 - Misc. Durable Goods',
  },
  {
    id: '5091',
    name: '5091 - Sporting & recreational goods',
  },
  {
    id: '5092',
    name: '5092 - Toys and hobby goods and supplies',
  },
  {
    id: '5093',
    name: '5093 - Scrap and waste materials',
  },
  {
    id: '5094',
    name: '5094 - Jewelry & precious stones',
  },
  {
    id: '5099',
    name: '5099 - Durable goods, nec',
  },
  { id: '51', name: '51 - WHOLESALE TRADE-NON-DURABLE GOODS' },
  {
    id: '511',
    name: '511 - Paper and Paper Products',
  },
  {
    id: '5111',
    name: '5111 - Printing and writing paper',
  },
  {
    id: '5112',
    name: '5112 - Stationery and office supplies',
  },
  {
    id: '5113',
    name: '5113 - Industrial/personal service paper',
  },
  {
    id: '512',
    name: '512 - Drugs, Proprietaries, and Sundries',
  },
  {
    id: '5122',
    name: '5122 - Drugs, proprietaries, and sundries',
  },
  {
    id: '513',
    name: '513 - Apparel, Piece Goods, and Notions',
  },
  {
    id: '5131',
    name: '5131 - Piece goods and notions',
  },
  {
    id: '5136',
    name: "5136 - Men's and boys' clothing",
  },
  {
    id: '5137',
    name: "5137 - Women's and children's clothing",
  },
  {
    id: '5139',
    name: '5139 - Footwear',
  },
  {
    id: '514',
    name: '514 - Groceries and Related Products',
  },
  {
    id: '5141',
    name: '5141 - Groceries, general line',
  },
  {
    id: '5142',
    name: '5142 - Packaged frozen goods',
  },
  {
    id: '5143',
    name: '5143 - Dairy prods. exc. dried or canned',
  },
  {
    id: '5144',
    name: '5144 - Poultry and poultry products',
  },
  {
    id: '5145',
    name: '5145 - Confectionery',
  },
  {
    id: '5146',
    name: '5146 - Fish and seafoods',
  },
  {
    id: '5147',
    name: '5147 - Meats and meat products',
  },
  {
    id: '5148',
    name: '5148 - Fresh fruits and vegetables',
  },
  {
    id: '5149',
    name: '5149 - Groceries & related products, nec',
  },
  {
    id: '515',
    name: '515 - Farm-Product Raw Materials',
  },
  {
    id: '5153',
    name: '5153 - Grain and field beans',
  },
  {
    id: '5154',
    name: '5154 - Livestock',
  },
  {
    id: '5159',
    name: '5159 - Farm products raw materials, nec',
  },
  {
    id: '516',
    name: '516 - Chemical and Allied Products',
  },
  {
    id: '5162',
    name: '5162 - Plastics materials & basic shapes',
  },
  {
    id: '5169',
    name: '5169 - Chemical and allied products, nec',
  },
  {
    id: '517',
    name: '517 - Petroleum and Petroleum Products',
  },
  {
    id: '5171',
    name: '5171 - Petroleum bulk stations/terminals',
  },
  {
    id: '5172',
    name: '5172 - Petroleum products, nec',
  },
  {
    id: '518',
    name: '518 - Beer, Wine, and Distilled Beverages',
  },
  {
    id: '5181',
    name: '5181 - Beer and ale',
  },
  {
    id: '5182',
    name: '5182 - Wine and distilled beverages',
  },
  {
    id: '519',
    name: '519 - Misc. Nondurable Goods',
  },
  {
    id: '5191',
    name: '5191 - Farm supplies',
  },
  {
    id: '5192',
    name: '5192 - Books, periodicals, & newspapers',
  },
  {
    id: '5193',
    name: "5193 - Flowers & florists' supplies",
  },
  {
    id: '5194',
    name: '5194 - Tobacco and tobacco products',
  },
  {
    id: '5198',
    name: '5198 - Paints, varnishes, and supplies',
  },
  {
    id: '5199',
    name: '5199 - Nondurable goods, nec',
  },
  {
    id: '52',
    name: '52 - BUILDING MATERIALS, HARDWARE AND GARDEN SUPPLIES',
  },
  {
    id: '521',
    name: '521 - Lumber and Other Building Materials',
  },
  {
    id: '5211',
    name: '5211 - Lumber & other building materials',
  },
  {
    id: '523',
    name: '523 - Paint, Glass, and Wallpaper Stores',
  },
  {
    id: '5231',
    name: '5231 - Paint, glass, and wallpaper stores',
  },
  { id: '525', name: '525 - Hardware Stores' },
  {
    id: '5251',
    name: '5251 - Hardware stores',
  },
  {
    id: '526',
    name: '526 - Retail Nurseries and Garden Stores',
  },
  {
    id: '5261',
    name: '5261 - Retail nurseries and garden stores',
  },
  {
    id: '527',
    name: '527 - Mobile Home Dealers',
  },
  {
    id: '5271',
    name: '5271 - Mobile home dealers',
  },
  { id: '53', name: '53 - GENERAL MERCHANDISE STORES' },
  { id: '531', name: '531 - Department Stores' },
  {
    id: '5311',
    name: '5311 - Department stores',
  },
  { id: '533', name: '533 - Variety Stores' },
  {
    id: '5331',
    name: '5331 - Variety stores',
  },
  {
    id: '539',
    name: '539 - Misc. General Merchandise Stores',
  },
  {
    id: '5399',
    name: '5399 - Misc. general merchandise stores',
  },
  { id: '54', name: '54 - FOOD STORES' },
  { id: '541', name: '541 - Grocery Stores' },
  {
    id: '5411',
    name: '5411 - Grocery stores',
  },
  {
    id: '542',
    name: '542 - Meat and Fish Markets',
  },
  {
    id: '5421',
    name: '5421 - Meat and fish markets',
  },
  {
    id: '543',
    name: '543 - Fruit and Vegetable Markets',
  },
  {
    id: '5431',
    name: '5431 - Fruit and vegetable markets',
  },
  {
    id: '544',
    name: '544 - Candy, Nut, and Confectionery Stores',
  },
  {
    id: '5441',
    name: '5441 - Candy, nut, & confectionery stores',
  },
  {
    id: '545',
    name: '545 - Dairy Products Stores',
  },
  {
    id: '5451',
    name: '5451 - Dairy products stores',
  },
  { id: '546', name: '546 - Retail Bakeries' },
  {
    id: '5461',
    name: '5461 - Retail bakeries',
  },
  { id: '549', name: '549 - Misc. Food Stores' },
  {
    id: '5499',
    name: '5499 - Misc. food stores',
  },
  { id: '55', name: '55 - AUTOMOTIVE DEALERS & SERVICE STATIONS' },
  {
    id: '551',
    name: '551 - New and Used Car Dealers',
  },
  {
    id: '5511',
    name: '5511 - New and used car dealers',
  },
  { id: '552', name: '552 - Used Car Dealers' },
  {
    id: '5521',
    name: '5521 - Used car dealers',
  },
  {
    id: '553',
    name: '553 - Auto and Home Supply Stores',
  },
  {
    id: '5531',
    name: '5531 - Auto and home supply stores',
  },
  {
    id: '554',
    name: '554 - Gasoline Service Stations',
  },
  {
    id: '5541',
    name: '5541 - Gasoline service stations',
  },
  { id: '555', name: '555 - Boat Dealers' },
  {
    id: '5551',
    name: '5551 - Boat dealers',
  },
  {
    id: '556',
    name: '556 - Recreational Vehicle Dealers',
  },
  {
    id: '5561',
    name: '5561 - Recreational vehicle dealers',
  },
  { id: '557', name: '557 - Motorcycle Dealers' },
  {
    id: '5571',
    name: '5571 - Motorcycle dealers',
  },
  {
    id: '559',
    name: '559 - Automotive Dealers, NEC',
  },
  {
    id: '5599',
    name: '5599 - Automotive dealers, nec',
  },
  { id: '56', name: '56 - APPAREL AND ACCESSORY STORES' },
  {
    id: '561',
    name: "561 - Men's & Boys' Clothing Stores",
  },
  {
    id: '5611',
    name: "5611 - Men's & boys' clothing stores",
  },
  {
    id: '562',
    name: "562 - Women's Clothing Stores",
  },
  {
    id: '5621',
    name: "5621 - Women's clothing stores",
  },
  {
    id: '563',
    name: "563 - Women's Accessory & Specialty Stores",
  },
  {
    id: '5632',
    name: "5632 - Women's accessory/specialty stores",
  },
  {
    id: '564',
    name: "564 - Children's and Infants' Wear Stores",
  },
  {
    id: '5641',
    name: "5641 - Children's & infants' wear stores",
  },
  {
    id: '565',
    name: '565 - Family Clothing Stores',
  },
  {
    id: '5651',
    name: '5651 - Family clothing stores',
  },
  { id: '566', name: '566 - Shoe Stores' },
  {
    id: '5661',
    name: '5661 - Shoe stores',
  },
  {
    id: '569',
    name: '569 - Misc. Apparel & Accessory Stores',
  },
  {
    id: '5699',
    name: '5699 - Misc. apparel & accessory stores',
  },
  { id: '57', name: '57 - FURNITURE AND HOMEFURNISHINGS STORES' },
  {
    id: '571',
    name: '571 - Furniture and Homefurnishings Stores',
  },
  {
    id: '5712',
    name: '5712 - Furniture stores',
  },
  {
    id: '5713',
    name: '5713 - Floor covering stores',
  },
  {
    id: '5714',
    name: '5714 - Drapery upholstery stores',
  },
  {
    id: '5719',
    name: '5719 - Misc. homefurnishings stores',
  },
  {
    id: '572',
    name: '572 - Household Appliance Stores',
  },
  {
    id: '5722',
    name: '5722 - Household appliance stores',
  },
  {
    id: '573',
    name: '573 - Radio, Television & Computer Stores',
  },
  {
    id: '5731',
    name: '5731 - Radio, TV, & electronic stores',
  },
  {
    id: '5734',
    name: '5734 - Computer and software stores',
  },
  {
    id: '5735',
    name: '5735 - Record & prerecorded tape stores',
  },
  {
    id: '5736',
    name: '5736 - Musical instrument stores',
  },
  { id: '58', name: '58 - EATING AND DRINKING PLACES' },
  {
    id: '581',
    name: '581 - Eating and Drinking Places',
  },
  {
    id: '5812',
    name: '5812 - Eating places',
  },
  {
    id: '5813',
    name: '5813 - Drinking places',
  },
  { id: '59', name: '59 - MISCELLANEOUS RETAIL' },
  {
    id: '591',
    name: '591 - Drug Stores and Property Stores',
  },
  {
    id: '5912',
    name: '5912 - Drug stores and property stores',
  },
  { id: '592', name: '592 - Liquor Stores' },
  {
    id: '5921',
    name: '5921 - Liquor stores',
  },
  {
    id: '593',
    name: '593 - Used Merchandise Stores',
  },
  {
    id: '5932',
    name: '5932 - Used merchandise stores',
  },
  {
    id: '594',
    name: '594 - Misc. Shopping Goods Stores',
  },
  {
    id: '5941',
    name: '5941 - Sporting goods and bicycle shops',
  },
  {
    id: '5942',
    name: '5942 - Book stores',
  },
  {
    id: '5943',
    name: '5943 - Stationery stores',
  },
  {
    id: '5944',
    name: '5944 - Jewelry stores',
  },
  {
    id: '5945',
    name: '5945 - Hobby, toy, and game shops',
  },
  {
    id: '5946',
    name: '5946 - Camera & photographic supply shops',
  },
  {
    id: '5947',
    name: '5947 - Gift, novelty, and souvenir shops',
  },
  {
    id: '5948',
    name: '5948 - Luggage and leather goods shops',
  },
  {
    id: '5949',
    name: '5949 - Sewing, needlework, & piece goods',
  },
  { id: '596', name: '596 - Nonstore Retailers' },
  {
    id: '5961',
    name: '5961 - Catalog and mail-order houses',
  },
  {
    id: '5962',
    name: '5962 - Merchandising machine operators',
  },
  {
    id: '5963',
    name: '5963 - Direct selling establishments',
  },
  { id: '598', name: '598 - Fuel Dealers' },
  {
    id: '5983',
    name: '5983 - Fuel oil dealers',
  },
  {
    id: '5984',
    name: '5984 - Liquefied petroleum gas dealers',
  },
  {
    id: '5989',
    name: '5989 - Fuel dealers, nec',
  },
  { id: '599', name: '599 - Retail Stores, NEC' },
  {
    id: '5992',
    name: '5992 - Florists',
  },
  {
    id: '5993',
    name: '5993 - Tobacco stores and stands',
  },
  {
    id: '5994',
    name: '5994 - News dealers and newsstands',
  },
  {
    id: '5995',
    name: '5995 - Optical goods stores',
  },
  {
    id: '5999',
    name: '5999 - Misc. retail stores, nec',
  },
  { id: '60', name: '60 - DEPOSITORY INSTITUTIONS' },
  {
    id: '601',
    name: '601 - Central Reserve Depositories',
  },
  {
    id: '6011',
    name: '6011 - Federal reserve banks',
  },
  {
    id: '6019',
    name: '6019 - Central reserve depository, nec',
  },
  { id: '602', name: '602 - Commercial Banks' },
  {
    id: '6021',
    name: '6021 - National commercial banks',
  },
  {
    id: '6022',
    name: '6022 - State commercial banks',
  },
  {
    id: '6024',
    name: '6024 - Schedule II banks',
  },
  {
    id: '6025',
    name: '6025 - Schedule I banks',
  },
  {
    id: '6029',
    name: '6029 - Commercial banks, nec',
  },
  {
    id: '603',
    name: '603 - Savings Institutions',
  },
  {
    id: '6035',
    name: '6035 - Savings Instit., Fed. Chartered',
  },
  {
    id: '6036',
    name: '6036 - Savings Instit., not Fed. Chartered',
  },
  { id: '606', name: '606 - Credit Unions' },
  {
    id: '6061',
    name: '6061 - Federal credit unions',
  },
  {
    id: '6062',
    name: '6062 - State credit unions',
  },
  {
    id: '608',
    name: '608 - Foreign Bank & Branches & Agencies',
  },
  {
    id: '6081',
    name: '6081 - Branches & agencies of foreign banks',
  },
  {
    id: '6082',
    name: '6082 - Foreign trade & intl. banking institutions',
  },
  {
    id: '609',
    name: '609 - Functions Closely Related to Banking',
  },
  {
    id: '6091',
    name: '6091 - Nondeposit trust facilities',
  },
  {
    id: '6099',
    name: '6099 - Functions related to depository, nec',
  },
  { id: '61', name: '61 - NON-DEPOSITORY CREDIT INSTITUTIONS' },
  {
    id: '611',
    name: '611 - Federal & Fed.-Sponsored Credit',
  },
  {
    id: '6111',
    name: '6111 - Federal & fed.-sponsored credit',
  },
  {
    id: '614',
    name: '614 - Personal Credit Institutions',
  },
  {
    id: '6141',
    name: '6141 - Personal credit institutions',
  },
  {
    id: '615',
    name: '615 - Business Credit Institutions',
  },
  {
    id: '6153',
    name: '6153 - Short-term business credit',
  },
  {
    id: '6159',
    name: '6159 - Misc. business credit institutions',
  },
  {
    id: '616',
    name: '616 - Mortgage Bankers and Brokers',
  },
  {
    id: '6162',
    name: '6162 - Mortgage bankers & correspondents',
  },
  {
    id: '6163',
    name: '6163 - Loan brokers',
  },
  { id: '62', name: '62 - SECURITIES AND COMMODITY BROKERS' },
  {
    id: '621',
    name: '621 - Security Brokers and Dealers',
  },
  {
    id: '6211',
    name: '6211 - Securities brokers and dealers',
  },
  {
    id: '622',
    name: '622 - Commodity Contracts Brokers, Dealers',
  },
  {
    id: '6221',
    name: '6221 - Commod. contracts brokers dealers',
  },
  {
    id: '623',
    name: '623 - Security and Commodity Exchanges',
  },
  {
    id: '6231',
    name: '6231 - Security and commodity exchanges',
  },
  {
    id: '628',
    name: '628 - Security and Commodity Services',
  },
  {
    id: '6282',
    name: '6282 - Investment advice',
  },
  {
    id: '6289',
    name: '6289 - Security & commodity services, nec',
  },
  { id: '63', name: '63 - INSURANCE CARRIERS' },
  { id: '631', name: '631 - Life Insurance' },
  {
    id: '6311',
    name: '6311 - Life insurance',
  },
  {
    id: '632',
    name: '632 - Medical Service and Health Insurance',
  },
  {
    id: '6321',
    name: '6321 - Accident and health insurance',
  },
  {
    id: '6324',
    name: '6324 - Hospital and medical service plans',
  },
  {
    id: '633',
    name: '633 - Fire, Marine, and Casualty Insurance',
  },
  {
    id: '6331',
    name: '6331 - Fire, marine, & casualty insurance',
  },
  { id: '635', name: '635 - Surety Insurance' },
  {
    id: '6351',
    name: '6351 - Surety insurance',
  },
  { id: '636', name: '636 - Title Insurance' },
  {
    id: '6361',
    name: '6361 - Title insurance',
  },
  {
    id: '637',
    name: '637 - Pension, Health and Welfare Funds',
  },
  {
    id: '6371',
    name: '6371 - Pension, health and welfare funds',
  },
  {
    id: '639',
    name: '639 - Insurance Carriers, NEC',
  },
  {
    id: '6399',
    name: '6399 - Insurance carriers, nec',
  },
  { id: '64', name: '64 - INSURANCE AGENTS, BROKERS AND SERVICE' },
  {
    id: '641',
    name: '641 - Insurance Agents, Brokers, & Service',
  },
  {
    id: '6411',
    name: '6411 - Insurance agents brokers & service',
  },
  { id: '65', name: '65 - REAL ESTATE' },
  {
    id: '651',
    name: '651 - Real Estate Operators and Lessors',
  },
  {
    id: '6512',
    name: '6512 - Nonresidential building operators',
  },
  {
    id: '6513',
    name: '6513 - Apartment building operators',
  },
  {
    id: '6514',
    name: '6514 - Dwelling operators exc. apartments',
  },
  {
    id: '6515',
    name: '6515 - Mobile home site operators',
  },
  {
    id: '6517',
    name: '6517 - Railroad property lessors',
  },
  {
    id: '6519',
    name: '6519 - Real property lessors',
  },
  {
    id: '653',
    name: '653 - Real Estate Agents and Managers',
  },
  {
    id: '6531',
    name: '6531 - Real estate agents and managers',
  },
  {
    id: '654',
    name: '654 - Title Abstract Offices',
  },
  {
    id: '6541',
    name: '6541 - Title abstract offices',
  },
  {
    id: '655',
    name: '655 - Subdividers and Developers',
  },
  {
    id: '6552',
    name: '6552 - Subdividers and developers',
  },
  {
    id: '6553',
    name: '6553 - Cemetery subdividers & developers',
  },
  { id: '67', name: '67 - HOLDING AND OTHER INVESTMENT OFFICES' },
  { id: '671', name: '671 - Holding Offices' },
  {
    id: '6712',
    name: '6712 - Bank holding offices',
  },
  {
    id: '6719',
    name: '6719 - Holding companies, nec',
  },
  { id: '672', name: '672 - Investment Offices' },
  {
    id: '6722',
    name: '6722 - Management investment offices, open-end',
  },
  {
    id: '6726',
    name: '6726 - Closed-end management investment offices, nec',
  },
  { id: '673', name: '673 - Trusts' },
  {
    id: '6732',
    name: '6732 - Educational religious etc. trusts',
  },
  {
    id: '6733',
    name: '6733 - Trusts, nec',
  },
  { id: '679', name: '679 - Misc. Investing' },
  {
    id: '6792',
    name: '6792 - Oil royalty traders',
  },
  {
    id: '6794',
    name: '6794 - Patent owners and lessors',
  },
  {
    id: '6798',
    name: '6798 - Real estate investment trusts',
  },
  {
    id: '6799',
    name: '6799 - Investors, nec',
  },
  { id: '70', name: '70 - HOTELS AND OTHER LODGING PLACES' },
  { id: '701', name: '701 - Hotels and Motels' },
  {
    id: '7011',
    name: '7011 - Hotels and motels',
  },
  {
    id: '702',
    name: '702 - Rooming and Boarding Houses',
  },
  {
    id: '7021',
    name: '7021 - Rooming and boarding houses',
  },
  {
    id: '703',
    name: '703 - Camps and Recreational Vehicle Parks',
  },
  {
    id: '7032',
    name: '7032 - Sporting and recreational camps',
  },
  {
    id: '7033',
    name: '7033 - Trailer parks and campsites',
  },
  {
    id: '704',
    name: '704 - Membership-Basis Organisation Hotels',
  },
  {
    id: '7041',
    name: '7041 - Membership-basis organiz. hotels',
  },
  { id: '72', name: '72 - PERSONAL SERVICES' },
  {
    id: '721',
    name: '721 - Laundry, Cleaning, & Garment Services',
  },
  {
    id: '7211',
    name: '7211 - Power laundries family/commercial',
  },
  {
    id: '7212',
    name: "7212 - Garment pressing/cleaners' agents",
  },
  {
    id: '7213',
    name: '7213 - Linen supply',
  },
  {
    id: '7215',
    name: '7215 - Coin-operated laundries & cleaning',
  },
  {
    id: '7216',
    name: '7216 - Drycleaning plants, except rug',
  },
  {
    id: '7217',
    name: '7217 - Carpet and upholstery cleaning',
  },
  {
    id: '7218',
    name: '7218 - Industrial launderers',
  },
  {
    id: '7219',
    name: '7219 - Laundry and garment services, nec',
  },
  {
    id: '722',
    name: '722 - Photographic Studios, Portrait',
  },
  {
    id: '7221',
    name: '7221 - Photographic studios, portrait',
  },
  { id: '723', name: '723 - Beauty Shops' },
  {
    id: '7231',
    name: '7231 - Beauty shops',
  },
  { id: '724', name: '724 - Barber Shops' },
  {
    id: '7241',
    name: '7241 - Barber shops',
  },
  {
    id: '725',
    name: '725 - Shoe Repair and Shoeshine Parlors',
  },
  {
    id: '7251',
    name: '7251 - Shoe repair and shoeshine parlors',
  },
  {
    id: '726',
    name: '726 - Funeral Service and Crematories',
  },
  {
    id: '7261',
    name: '7261 - Funeral service and crematories',
  },
  {
    id: '729',
    name: '729 - Misc. Personal Services',
  },
  {
    id: '7291',
    name: '7291 - Tax return preparation services',
  },
  {
    id: '7299',
    name: '7299 - Misc. personal services nec',
  },
  { id: '73', name: '73 - BUSINESS SERVICES' },
  { id: '731', name: '731 - Advertising' },
  {
    id: '7311',
    name: '7311 - Advertising agencies',
  },
  {
    id: '7312',
    name: '7312 - Outdoor advertising agencies',
  },
  {
    id: '7313',
    name: '7313 - Radio, TV & publisher representatives',
  },
  {
    id: '7319',
    name: '7319 - Advertising, nec',
  },
  {
    id: '732',
    name: '732 - Credit Reporting and Collection',
  },
  {
    id: '7322',
    name: '7322 - Adjustment & collection services',
  },
  {
    id: '7323',
    name: '7323 - Credit reporting services',
  },
  {
    id: '733',
    name: '733 - Mailing, Reproduction, Stenographic',
  },
  {
    id: '7331',
    name: '7331 - Direct mail advertising services',
  },
  {
    id: '7334',
    name: '7334 - Photocopying & duplicat. services',
  },
  {
    id: '7335',
    name: '7335 - Commercial photography',
  },
  {
    id: '7336',
    name: '7336 - Commercial art and graphic design',
  },
  {
    id: '7338',
    name: '7338 - Secretarial & court reporting',
  },
  {
    id: '734',
    name: '734 - Services to Buildings',
  },
  {
    id: '7342',
    name: '7342 - Disinfecting/pest control services',
  },
  {
    id: '7349',
    name: '7349 - Building maintenance services, nec',
  },
  {
    id: '735',
    name: '735 - Misc. Equipment Rental & Leasing',
  },
  {
    id: '7352',
    name: '7352 - Medical equipment rental',
  },
  {
    id: '7353',
    name: '7353 - Heavy construction equip. rental',
  },
  {
    id: '7359',
    name: '7359 - Equipment rental & leasing, nec',
  },
  {
    id: '736',
    name: '736 - Personnel Supply Services',
  },
  {
    id: '7361',
    name: '7361 - Employment agencies',
  },
  {
    id: '7363',
    name: '7363 - Help supply services',
  },
  {
    id: '737',
    name: '737 - Computer and Data Processing Services',
  },
  {
    id: '7371',
    name: '7371 - Computer programming services',
  },
  {
    id: '7372',
    name: '7372 - Prepackaged software',
  },
  {
    id: '7373',
    name: '7373 - Computer integrated systems design',
  },
  {
    id: '7374',
    name: '7374 - Data processing and preparation',
  },
  {
    id: '7375',
    name: '7375 - Information retrieval services',
  },
  {
    id: '7376',
    name: '7376 - Computer facilities management',
  },
  {
    id: '7377',
    name: '7377 - Computer rental & leasing',
  },
  {
    id: '7378',
    name: '7378 - Computer maintenance & repair',
  },
  {
    id: '7379',
    name: '7379 - Computer related services, nec',
  },
  {
    id: '738',
    name: '738 - Misc. Business Services',
  },
  {
    id: '7381',
    name: '7381 - Detective & armored car services',
  },
  {
    id: '7382',
    name: '7382 - Security systems services',
  },
  {
    id: '7383',
    name: '7383 - News syndicates',
  },
  {
    id: '7384',
    name: '7384 - Photofinishing laboratories',
  },
  {
    id: '7389',
    name: '7389 - Business services, nec',
  },
  { id: '75', name: '75 - AUTOMOTIVE REPAIR, SERVICES AND PARKING' },
  {
    id: '751',
    name: '751 - Automotive Rentals, No Drivers',
  },
  {
    id: '7513',
    name: '7513 - Truck rental&leasing, no drivers',
  },
  {
    id: '7514',
    name: '7514 - Passenger car rental',
  },
  {
    id: '7515',
    name: '7515 - Passenger car leasing',
  },
  {
    id: '7519',
    name: '7519 - Utility trailer rental',
  },
  { id: '752', name: '752 - Automotive Parking' },
  {
    id: '7521',
    name: '7521 - Automotive parking',
  },
  {
    id: '753',
    name: '753 - Automotive Repair Shops',
  },
  {
    id: '7532',
    name: '7532 - Top & body repair & paint shops',
  },
  {
    id: '7533',
    name: '7533 - Auto exhaust system repair shops',
  },
  {
    id: '7534',
    name: '7534 - Tire retreading and repair shops',
  },
  {
    id: '7536',
    name: '7536 - Automotive glass replacement shops',
  },
  {
    id: '7537',
    name: '7537 - Auto. transmission repair shops',
  },
  {
    id: '7538',
    name: '7538 - General automotive repair shops',
  },
  {
    id: '7539',
    name: '7539 - Automotive repair shops, nec',
  },
  {
    id: '754',
    name: '754 - Automotive Services, Except Repair',
  },
  {
    id: '7542',
    name: '7542 - Carwashes',
  },
  {
    id: '7549',
    name: '7549 - Automotive services, nec',
  },
  { id: '76', name: '76 - MISCELLANEOUS REPAIR SERVICES' },
  {
    id: '762',
    name: '762 - Electrical Repair Shops',
  },
  {
    id: '7622',
    name: '7622 - Radio and television repair',
  },
  {
    id: '7623',
    name: '7623 - Refrigeration service and repair',
  },
  {
    id: '7629',
    name: '7629 - Electrical repair shops, nec',
  },
  {
    id: '763',
    name: '763 - Watch, Clock, and Jewelry Repair',
  },
  {
    id: '7631',
    name: '7631 - Watch, clock, and jewelry repair',
  },
  {
    id: '764',
    name: '764 - Reupholstery and Furniture repair',
  },
  {
    id: '7641',
    name: '7641 - Reupholstery and furniture repair',
  },
  { id: '769', name: '769 - Misc. Repair Shops' },
  {
    id: '7692',
    name: '7692 - Welding repair',
  },
  {
    id: '7694',
    name: '7694 - Armature rewinding shops',
  },
  {
    id: '7699',
    name: '7699 - Repair services, nec',
  },
  { id: '78', name: '78 - MOTION PICTURES' },
  {
    id: '781',
    name: '781 - Motion Picture Production & Services',
  },
  {
    id: '7812',
    name: '7812 - Motion picture & video production',
  },
  {
    id: '7819',
    name: '7819 - Services allied to motion pictures',
  },
  {
    id: '782',
    name: '782 - Motion Picture Distribution & Services',
  },
  {
    id: '7822',
    name: '7822 - Motion picture & video tape distribution',
  },
  {
    id: '7829',
    name: '7829 - Services allied to motion picture distrib.',
  },
  {
    id: '783',
    name: '783 - Motion Pictures Theaters',
  },
  {
    id: '7832',
    name: '7832 - Motion pict. theaters ex drive-in',
  },
  {
    id: '7833',
    name: '7833 - Drive-in motion picture theaters',
  },
  { id: '784', name: '784 - Video Tape Rental' },
  {
    id: '7841',
    name: '7841 - Video tape rental',
  },
  { id: '79', name: '79 - AMUSEMENT AND RECREATION SERVICES' },
  {
    id: '791',
    name: '791 - Dance Studios, Schools, and Halls',
  },
  {
    id: '7911',
    name: '7911 - Dance studios, schools, and halls',
  },
  {
    id: '792',
    name: '792 - Producers, Orchestras, Entertainers',
  },
  {
    id: '7922',
    name: '7922 - Theatrical producers and services',
  },
  {
    id: '7929',
    name: '7929 - Entertainers & entertainment grps.',
  },
  { id: '793', name: '793 - Bowling Centers' },
  {
    id: '7933',
    name: '7933 - Bowling centers',
  },
  { id: '794', name: '794 - Commercial Sports' },
  {
    id: '7941',
    name: '7941 - Sport clubs, managers, & promoters',
  },
  {
    id: '7948',
    name: '7948 - Racing, including track operation',
  },
  {
    id: '799',
    name: '799 - Misc. Amusement, Recreation Services',
  },
  {
    id: '7991',
    name: '7991 - Physical fitness facilities',
  },
  {
    id: '7992',
    name: '7992 - Public golf courses',
  },
  {
    id: '7993',
    name: '7993 - Coin-operated amusement devices',
  },
  {
    id: '7996',
    name: '7996 - Amusement parks',
  },
  {
    id: '7997',
    name: '7997 - Membership sports/recreation clubs',
  },
  {
    id: '7999',
    name: '7999 - Amusement and recreation, nec',
  },
  { id: '80', name: '80 - HEALTH SERVICES' },
  {
    id: '801',
    name: '801 - Offices & Clinics of Medical Doctors',
  },
  {
    id: '8011',
    name: '8011 - Offices, clinics - medical doctors',
  },
  {
    id: '802',
    name: '802 - Offices and Clinics of Dentists',
  },
  {
    id: '8021',
    name: '8021 - Offices and clinics of dentists',
  },
  {
    id: '803',
    name: '803 - Offices of Osteopathic Physicians',
  },
  {
    id: '8031',
    name: '8031 - Offices of osteopathic physicians',
  },
  {
    id: '804',
    name: '804 - Offices of Other Health Practitioners',
  },
  {
    id: '8041',
    name: '8041 - Offices & clinics of chiropractors',
  },
  {
    id: '8042',
    name: '8042 - Offices & clinics of optometrists',
  },
  {
    id: '8043',
    name: '8043 - Offices and clinics of podiatrists',
  },
  {
    id: '8049',
    name: '8049 - Offices - health practitioners nec',
  },
  {
    id: '805',
    name: '805 - Nursing and Personal Care Facilitie',
  },
  {
    id: '8051',
    name: '8051 - Skilled nursing care facilities',
  },
  {
    id: '8052',
    name: '8052 - Intermediate care facilities',
  },
  {
    id: '8059',
    name: '8059 - Nursing and personal care, nec',
  },
  { id: '806', name: '806 - Hospitals' },
  {
    id: '8062',
    name: '8062 - General medical/surgical hospitals',
  },
  {
    id: '8063',
    name: '8063 - Psychiatric hospitals',
  },
  {
    id: '8069',
    name: '8069 - Specialty hospitals ex psychiatric',
  },
  {
    id: '807',
    name: '807 - Medical and Dental Laboratories',
  },
  {
    id: '8071',
    name: '8071 - Medical laboratories',
  },
  {
    id: '8072',
    name: '8072 - Dental laboratories',
  },
  {
    id: '808',
    name: '808 - Home Health Care Services',
  },
  {
    id: '8082',
    name: '8082 - Home health care services',
  },
  {
    id: '809',
    name: '809 - Health and Allied Services, NEC',
  },
  {
    id: '8092',
    name: '8092 - Kidney dialysis centers',
  },
  {
    id: '8093',
    name: '8093 - Specialty outpatient clinics, nec',
  },
  {
    id: '8099',
    name: '8099 - Health and allied services, nec',
  },
  { id: '81', name: '81 - LEGAL SERVICES' },
  { id: '811', name: '811 - Legal Services' },
  {
    id: '8111',
    name: '8111 - Legal services',
  },
  { id: '82', name: '82 - EDUCATIONAL SERVICES' },
  {
    id: '821',
    name: '821 - Elementary and Secondary Schools',
  },
  {
    id: '8211',
    name: '8211 - Elementary and secondary schools',
  },
  {
    id: '822',
    name: '822 - Colleges and Universities',
  },
  {
    id: '8221',
    name: '8221 - Colleges and universities',
  },
  {
    id: '8222',
    name: '8222 - Junior colleges',
  },
  { id: '823', name: '823 - Libraries' },
  {
    id: '8231',
    name: '8231 - Libraries',
  },
  { id: '824', name: '824 - Vocational Schools' },
  {
    id: '8243',
    name: '8243 - Data processing schools',
  },
  {
    id: '8244',
    name: '8244 - Business and secretarial schools',
  },
  {
    id: '8249',
    name: '8249 - Vocational schools, nec',
  },
  {
    id: '829',
    name: '829 - Schools & Educational Services, NEC',
  },
  {
    id: '8299',
    name: '8299 - Schools & educational services nec',
  },
  { id: '83', name: '83 - SOCIAL SERVICES' },
  {
    id: '832',
    name: '832 - Individual and Family Services',
  },
  {
    id: '8322',
    name: '8322 - Individual and family services',
  },
  {
    id: '833',
    name: '833 - Job Training and Related Services',
  },
  {
    id: '8331',
    name: '8331 - Job training and related services',
  },
  {
    id: '835',
    name: '835 - Child Day Care Services',
  },
  {
    id: '8351',
    name: '8351 - Child day care services',
  },
  { id: '836', name: '836 - Residential Care' },
  {
    id: '8361',
    name: '8361 - Residential care',
  },
  {
    id: '839',
    name: '839 - Social Services, NEC',
  },
  {
    id: '8399',
    name: '8399 - Social services, nec',
  },
  {
    id: '84',
    name: '84 - MUSEUMS, GALLERIES, BOTANICAL, ZOOLOGICAL GARDENS',
  },
  {
    id: '841',
    name: '841 - Museums and Art Galleries',
  },
  {
    id: '8412',
    name: '8412 - Museums and art galleries',
  },
  {
    id: '842',
    name: '842 - Botanical and Zoological Gardens',
  },
  {
    id: '8422',
    name: '8422 - Botanical and zoological gardens',
  },
  { id: '86', name: '86 - MEMBERSHIP ORGANIZATIONS' },
  {
    id: '861',
    name: '861 - Business Associations',
  },
  {
    id: '8611',
    name: '8611 - Business associations',
  },
  {
    id: '862',
    name: '862 - Professional Organizations',
  },
  {
    id: '8621',
    name: '8621 - Professional organizations',
  },
  {
    id: '863',
    name: '863 - Labor Organizations',
  },
  {
    id: '8631',
    name: '8631 - Labor organizations',
  },
  {
    id: '864',
    name: '864 - Civic and Social Associations',
  },
  {
    id: '8641',
    name: '8641 - Civic and social associations',
  },
  {
    id: '865',
    name: '865 - Political Organizations',
  },
  {
    id: '8651',
    name: '8651 - Political organizations',
  },
  {
    id: '866',
    name: '866 - Religious Organizations',
  },
  {
    id: '8661',
    name: '8661 - Religious organizations',
  },
  {
    id: '869',
    name: '869 - Membership Organizations, NEC',
  },
  {
    id: '8699',
    name: '8699 - Membership organizations, nec',
  },
  { id: '87', name: '87 - ENGINEERING & MANAGEMENT SERVICES' },
  {
    id: '871',
    name: '871 - Engineering & Architectural Services',
  },
  {
    id: '8711',
    name: '8711 - Engineering services',
  },
  {
    id: '8712',
    name: '8712 - Architectural services',
  },
  {
    id: '8713',
    name: '8713 - Surveying services',
  },
  {
    id: '872',
    name: '872 - Accounting, Auditing, & Bookkeeping',
  },
  {
    id: '8721',
    name: '8721 - Accounting auditing & bookkeeping',
  },
  {
    id: '873',
    name: '873 - Research and Testing Services',
  },
  {
    id: '8731',
    name: '8731 - Commercial physical & biological research',
  },
  {
    id: '8732',
    name: '8732 - Commercial nonphysical research',
  },
  {
    id: '8733',
    name: '8733 - Noncommerc. research organizations',
  },
  {
    id: '8734',
    name: '8734 - Testing laboratories',
  },
  {
    id: '874',
    name: '874 - Management and Public Relations',
  },
  {
    id: '8741',
    name: '8741 - Management services',
  },
  {
    id: '8742',
    name: '8742 - Management consulting services',
  },
  {
    id: '8743',
    name: '8743 - Public relations services',
  },
  {
    id: '8744',
    name: '8744 - Facilities support services',
  },
  {
    id: '8748',
    name: '8748 - Business consulting, nec',
  },
  { id: '88', name: '88 - PRIVATE HOUSEHOLDS' },
  {
    id: '8811',
    name: '8811 - Private households',
  },
  { id: '89', name: '89 - MISCELLANEOUS SERVICES' },
  { id: '899', name: '899 - Services, NEC' },
  {
    id: '8999',
    name: '8999 - Services, nec',
  },
  {
    id: '91',
    name: '91 - EXECUTIVE, LEGISLATIVE AND GENERAL GOVERNMENT',
  },
  {
    id: '9111',
    name: '9111 - Executive offices',
  },
  {
    id: '9121',
    name: '9121 - Legislative bodies',
  },
  {
    id: '913',
    name: '913 - Executive and Legislative Combined',
  },
  {
    id: '9131',
    name: '9131 - Executive and legislative combined',
  },
  {
    id: '919',
    name: '919 - General Government, NEC',
  },
  {
    id: '9199',
    name: '9199 - General government, nec',
  },
  { id: '92', name: '92 - JUSTICE, PUBLIC ORDER AND SAFETY' },
  {
    id: '9211',
    name: '9211 - Courts',
  },
  {
    id: '9221',
    name: '9221 - Police protection',
  },
  {
    id: '9222',
    name: '9222 - Legal counsel and prosecution',
  },
  {
    id: '9223',
    name: '9223 - Correctional institutions',
  },
  {
    id: '9224',
    name: '9224 - Fire protection',
  },
  {
    id: '9229',
    name: '9229 - Public order and safety, nec',
  },
  { id: '93', name: '93 - PUBLIC FINANCE, TAXATION AND MONETARY POLICY' },
  {
    id: '9311',
    name: '9311 - Finance taxation & monetary policy',
  },
  { id: '94', name: '94 - ADMINISTRATION OF HUMAN RESOURCE PROGRAMS' },
  {
    id: '9411',
    name: '9411 - Admin. of educational programs',
  },
  {
    id: '9431',
    name: '9431 - Admin. of public health programs',
  },
  {
    id: '944',
    name: '944 - Admin. of Social & Manpower Programs',
  },
  {
    id: '9441',
    name: '9441 - Admin. of social/manpower programs',
  },
  {
    id: '945',
    name: "945 - Administration of Veterans' Affairs",
  },
  {
    id: '9451',
    name: "9451 - Administration - veterans' affairs",
  },
  { id: '95', name: '95 - ENVIRONMENTAL QUALITY AND HOUSING PROGRAMS' },
  {
    id: '9511',
    name: '9511 - Air water & solid waste management',
  },
  {
    id: '9512',
    name: '9512 - Land mineral wildlife conservation',
  },
  {
    id: '9531',
    name: '9531 - Housing programs',
  },
  {
    id: '9532',
    name: '9532 - Urban and community development',
  },
  { id: '96', name: '96 - ADMINISTRATION OF ECONOMIC PROGRAMS' },
  {
    id: '961',
    name: '961 - Admin. of General Economic Programs',
  },
  {
    id: '9611',
    name: '9611 - Admin. of general economic programs',
  },
  {
    id: '962',
    name: '962 - Regulation, Admin. of Transport. Programs',
  },
  {
    id: '9621',
    name: '9621 - Regulation, admin. of transport. programs',
  },
  {
    id: '963',
    name: '963 - Regulation, Admin. of Utilities',
  },
  {
    id: '9631',
    name: '9631 - Regulation, admin. of utilities',
  },
  {
    id: '964',
    name: '964 - Regulation of Agricultural Marketing',
  },
  {
    id: '9641',
    name: '9641 - Regulation of agricultural marketing',
  },
  {
    id: '965',
    name: '965 - Regulation Misc. Commercial Sectors',
  },
  {
    id: '9651',
    name: '9651 - Regulation misc. commercial sectors',
  },
  {
    id: '966',
    name: '966 - Space Research and Technology',
  },
  {
    id: '9661',
    name: '9661 - Space research and technology',
  },
  { id: '97', name: '97 - NATIONAL SECURITY AND INTERNATIONAL AFFAIRS' },
  {
    id: '9711',
    name: '9711 - National security',
  },
  {
    id: '9721',
    name: '9721 - International affairs',
  },
  { id: '99', name: '99 - NONCLASSIFIABLE ESTABLISHMENTS' },
  {
    id: '999',
    name: '999 - Nonclassifiable Establishments',
  },
  {
    id: '9999',
    name: '9999 - Nonclassifiable establishments                ',
  },
];

export const gicsLookup: Array<ILookup> = [
  { id: '', name: 'All Industries' },
  { id: '10', name: '10  Energy' },
  { id: '1010', name: '  1010  Energy' },
  {
    id: '101010',
    name: '101010  Energy Equipment & Services',
  },
  {
    id: '10101010',
    name: '  10101010  Oil & Gas Drilling',
  },
  {
    id: '10101020',
    name: '  10101020  Oil & Gas Equipment & Services',
  },
  {
    id: '101020',
    name: '101020  Oil, Gas & Consumable Fuels',
  },
  {
    id: '10102010',
    name: '  10102010  Integrated Oil & Gas',
  },
  {
    id: '10102020',
    name: '  10102020  Oil & Gas Exploration & Production',
  },
  {
    id: '10102030',
    name: '  10102030  Oil & Gas Refining & Marketing',
  },
  {
    id: '10102040',
    name: '  10102040  Oil & Gas Storage & Transportation',
  },
  {
    id: '10102050',
    name: '  10102050  Coal & Consumable Fuels',
  },
  { id: '15', name: '15  Materials' },
  { id: '1510', name: '  1510  Materials' },
  {
    id: '151010',
    name: '151010  Chemicals',
  },
  {
    id: '15101010',
    name: '  15101010  Commodity Chemicals',
  },
  {
    id: '15101020',
    name: '  15101020  Diversified Chemicals',
  },
  {
    id: '15101030',
    name: '  15101030  Fertilizers & Agricultural Chemicals',
  },
  {
    id: '15101040',
    name: '  15101040  Industrial Gases',
  },
  {
    id: '15101050',
    name: '  15101050  Specialty Chemicals',
  },
  {
    id: '151020',
    name: '151020  Construction Materials',
  },
  {
    id: '15102010',
    name: '  15102010  Construction Materials',
  },
  {
    id: '151030',
    name: '151030  Containers & Packaging',
  },
  {
    id: '15103010',
    name: '  15103010  Metal & Glass Containers',
  },
  {
    id: '15103020',
    name: '  15103020  Paper Packaging',
  },
  {
    id: '151040',
    name: '151040  Metals & Mining',
  },
  {
    id: '15104010',
    name: '  15104010  Aluminum',
  },
  {
    id: '15104020',
    name: '  15104020  Diversified Metals & Mining',
  },
  {
    id: '15104025',
    name: '  15104025  Copper',
  },
  {
    id: '15104030',
    name: '  15104030  Gold',
  },
  {
    id: '15104040',
    name: '  15104040  Precious Metals & Minerals',
  },
  {
    id: '15104045',
    name: '  15104045  Silver',
  },
  {
    id: '15104050',
    name: '  15104050  Steel',
  },
  {
    id: '151050',
    name: '151050  Paper & Forest Products',
  },
  {
    id: '15105010',
    name: '  15105010  Forest Products',
  },
  {
    id: '15105020',
    name: '  15105020  Paper Products',
  },
  { id: '20', name: '20  Industrials' },
  { id: '2010', name: '  2010  Capital Goods' },
  {
    id: '201010',
    name: '201010  Aerospace & Defense',
  },
  {
    id: '20101010',
    name: '  20101010  Aerospace & Defense',
  },
  {
    id: '201020',
    name: '201020  Building Products',
  },
  {
    id: '20102010',
    name: '  20102010  Building Products',
  },
  {
    id: '201030',
    name: '201030  Construction & Engineering',
  },
  {
    id: '20103010',
    name: '  20103010  Construction & Engineering',
  },
  {
    id: '201040',
    name: '201040  Electrical Equipment',
  },
  {
    id: '20104010',
    name: '  20104010  Electrical Components & Equipment',
  },
  {
    id: '20104020',
    name: '  20104020  Heavy Electrical Equipment',
  },
  {
    id: '201050',
    name: '201050  Industrial Conglomerates',
  },
  {
    id: '20105010',
    name: '  20105010  Industrial Conglomerates',
  },
  {
    id: '201060',
    name: '201060  Machinery',
  },
  {
    id: '20106010',
    name: '  20106010  Construction Machinery & Heavy Trucks',
  },
  {
    id: '20106015',
    name: '  20106015  Agricultural & Farm Machinery',
  },
  {
    id: '20106020',
    name: '  20106020  Industrial Machinery',
  },
  {
    id: '201070',
    name: '201070  Trading Companies & Distributors',
  },
  {
    id: '20107010',
    name: '  20107010  Trading Companies & Distributors',
  },
  {
    id: '2020',
    name: '  2020  Commercial & Professional Services',
  },
  {
    id: '202010',
    name: '202010  Commercial Services & Supplies',
  },
  {
    id: '20201010',
    name: '  20201010  Commercial Printing',
  },
  {
    id: '20201020',
    name: '  20201020  Data Processing Services - discontinued 04/30/2003',
  },
  {
    id: '20201030',
    name: '  20201030  Diversified Commercial & Professional Services - discontinue',
  },
  {
    id: '20201040',
    name: '  20201040  Human Resource & Employment Services - discontinued 08/31/20',
  },
  {
    id: '20201050',
    name: '  20201050  Environmental & Facilities Services',
  },
  {
    id: '20201060',
    name: '  20201060  Office Services & Supplies',
  },
  {
    id: '20201070',
    name: '  20201070  Diversified Support Services',
  },
  {
    id: '20201080',
    name: '  20201080  Security & Alarm Services',
  },
  {
    id: '202020',
    name: '202020  Professional Services',
  },
  {
    id: '20202010',
    name: '  20202010  Human Resource & Employment Services',
  },
  {
    id: '20202020',
    name: '  20202020  Research & Consulting Services',
  },
  { id: '2030', name: '  2030  Transportation' },
  {
    id: '203010',
    name: '203010  Air Freight & Logistics',
  },
  {
    id: '20301010',
    name: '  20301010  Air Freight & Logistics',
  },
  {
    id: '203020',
    name: '203020  Airlines',
  },
  {
    id: '20302010',
    name: '  20302010  Airlines',
  },
  {
    id: '203030',
    name: '203030  Marine',
  },
  {
    id: '20303010',
    name: '  20303010  Marine',
  },
  {
    id: '203040',
    name: '203040  Road & Rail',
  },
  {
    id: '20304010',
    name: '  20304010  Railroads',
  },
  {
    id: '20304020',
    name: '  20304020  Trucking',
  },
  {
    id: '203050',
    name: '203050  Transportation Infrastructure',
  },
  {
    id: '20305010',
    name: '  20305010  Airport Services',
  },
  {
    id: '20305020',
    name: '  20305020  Highways & Railtracks',
  },
  {
    id: '20305030',
    name: '  20305030  Marine Ports & Services',
  },
  { id: '25', name: '25  Consumer Discretionary' },
  {
    id: '2510',
    name: '  2510  Automobiles & Components',
  },
  {
    id: '251010',
    name: '251010  Auto Components',
  },
  {
    id: '25101010',
    name: '  25101010  Auto Parts & Equipment',
  },
  {
    id: '25101020',
    name: '  25101020  Tires & Rubber',
  },
  {
    id: '251020',
    name: '251020  Automobiles',
  },
  {
    id: '25102010',
    name: '  25102010  Automobile Manufacturers',
  },
  {
    id: '25102020',
    name: '  25102020  Motorcycle Manufacturers',
  },
  {
    id: '2520',
    name: '  2520  Consumer Durables & Apparel',
  },
  {
    id: '252010',
    name: '252010  Household Durables',
  },
  {
    id: '25201010',
    name: '  25201010  Consumer Electronics',
  },
  {
    id: '25201020',
    name: '  25201020  Home Furnishings',
  },
  {
    id: '25201030',
    name: '  25201030  Homebuilding',
  },
  {
    id: '25201040',
    name: '  25201040  Household Appliances',
  },
  {
    id: '25201050',
    name: '  25201050  Housewares & Specialties',
  },
  {
    id: '252020',
    name: '252020  Leisure Products',
  },
  {
    id: '25202010',
    name: '  25202010  Leisure Products',
  },
  {
    id: '25202020',
    name: '  25202020  Photographic Products - discontinued 02/28/2014',
  },
  {
    id: '252030',
    name: '252030  Textiles, Apparel & Luxury Goods',
  },
  {
    id: '25203010',
    name: '  25203010  Apparel, Accessories & Luxury Goods',
  },
  {
    id: '25203020',
    name: '  25203020  Footwear',
  },
  {
    id: '25203030',
    name: '  25203030  Textiles',
  },
  { id: '2530', name: '  2530  Consumer Services' },
  {
    id: '253010',
    name: '253010  Hotels, Restaurants & Leisure',
  },
  {
    id: '25301010',
    name: '  25301010  Casinos & Gaming',
  },
  {
    id: '25301020',
    name: '  25301020  Hotels, Resorts & Cruise Lines',
  },
  {
    id: '25301030',
    name: '  25301030  Leisure Facilities',
  },
  {
    id: '25301040',
    name: '  25301040  Restaurants',
  },
  {
    id: '253020',
    name: '253020  Diversified Consumer Services',
  },
  {
    id: '25302010',
    name: '  25302010  Education Services',
  },
  {
    id: '25302020',
    name: '  25302020  Specialized Consumer Services',
  },
  {
    id: '2540',
    name: '  2540  Media - discontinued 09/30/2018',
  },
  {
    id: '254010',
    name: '254010  Media - discontinued 09/30/2018',
  },
  {
    id: '25401010',
    name: '  25401010  Advertising - discontinued 09/30/2018',
  },
  {
    id: '25401020',
    name: '  25401020  Broadcasting - discontinued 09/30/2018',
  },
  {
    id: '25401025',
    name: '  25401025  Cable & Satellite - discontinued 09/30/2018',
  },
  {
    id: '25401030',
    name: '  25401030  Movies & Entertainment - discontinued 09/30/2018',
  },
  {
    id: '25401040',
    name: '  25401040  Publishing - discontinued 09/30/2018',
  },
  { id: '2550', name: '  2550  Retailing' },
  {
    id: '255010',
    name: '255010  Distributors',
  },
  {
    id: '25501010',
    name: '  25501010  Distributors',
  },
  {
    id: '255020',
    name: '255020  Internet & Catalog Retail',
  },
  {
    id: '25502010',
    name: '  25502010  Catalog Retail - discontinued 08/31/2016',
  },
  {
    id: '25502020',
    name: '  25502020  Internet & Direct Marketing Retail',
  },
  {
    id: '255030',
    name: '255030  Multiline Retail',
  },
  {
    id: '25503010',
    name: '  25503010  Department Stores',
  },
  {
    id: '25503020',
    name: '  25503020  General Merchandise Stores',
  },
  {
    id: '255040',
    name: '255040  Specialty Retail',
  },
  {
    id: '25504010',
    name: '  25504010  Apparel Retail',
  },
  {
    id: '25504020',
    name: '  25504020  Computer & Electronics Retail',
  },
  {
    id: '25504030',
    name: '  25504030  Home Improvement Retail',
  },
  {
    id: '25504040',
    name: '  25504040  Specialty Stores',
  },
  {
    id: '25504050',
    name: '  25504050  Automotive Retail',
  },
  {
    id: '25504060',
    name: '  25504060  Homefurnishing Retail',
  },
  { id: '30', name: '30  Consumer Staples' },
  {
    id: '3010',
    name: '  3010  Food & Staples Retailing',
  },
  {
    id: '301010',
    name: '301010  Food & Staples Retailing',
  },
  {
    id: '30101010',
    name: '  30101010  Drug Retail',
  },
  {
    id: '30101020',
    name: '  30101020  Food Distributors',
  },
  {
    id: '30101030',
    name: '  30101030  Food Retail',
  },
  {
    id: '30101040',
    name: '  30101040  Hypermarkets & Super Centers',
  },
  {
    id: '3020',
    name: '  3020  Food, Beverage & Tobacco',
  },
  {
    id: '302010',
    name: '302010  Beverages',
  },
  {
    id: '30201010',
    name: '  30201010  Brewers',
  },
  {
    id: '30201020',
    name: '  30201020  Distillers & Vintners',
  },
  {
    id: '30201030',
    name: '  30201030  Soft Drinks',
  },
  {
    id: '302020',
    name: '302020  Food Products',
  },
  {
    id: '30202010',
    name: '  30202010  Agricultural Products',
  },
  {
    id: '30202020',
    name: '  30202020  Meat, Poultry & Fish - discontinued 03/28/2002',
  },
  {
    id: '30202030',
    name: '  30202030  Packaged Foods & Meats',
  },
  {
    id: '302030',
    name: '302030  Tobacco',
  },
  {
    id: '30203010',
    name: '  30203010  Tobacco',
  },
  {
    id: '3030',
    name: '  3030  Household & Personal Products',
  },
  {
    id: '303010',
    name: '303010  Household Products',
  },
  {
    id: '30301010',
    name: '  30301010  Household Products',
  },
  {
    id: '303020',
    name: '303020  Personal Products',
  },
  {
    id: '30302010',
    name: '  30302010  Personal Products',
  },
  { id: '35', name: '35  Health Care' },
  {
    id: '3510',
    name: '  3510  Health Care Equipment & Services',
  },
  {
    id: '351010',
    name: '351010  Health Care Equipment & Supplies',
  },
  {
    id: '35101010',
    name: '  35101010  Health Care Equipment',
  },
  {
    id: '35101020',
    name: '  35101020  Health Care Supplies',
  },
  {
    id: '351020',
    name: '351020  Health Care Providers & Services',
  },
  {
    id: '35102010',
    name: '  35102010  Health Care Distributors',
  },
  {
    id: '35102015',
    name: '  35102015  Health Care Services',
  },
  {
    id: '35102020',
    name: '  35102020  Health Care Facilities',
  },
  {
    id: '35102030',
    name: '  35102030  Managed Health Care',
  },
  {
    id: '351030',
    name: '351030  Health Care Technology',
  },
  {
    id: '35103010',
    name: '  35103010  Health Care Technology',
  },
  {
    id: '3520',
    name: '  3520  Pharmaceuticals, Biotechnology & Life Sciences',
  },
  {
    id: '352010',
    name: '352010  Biotechnology',
  },
  {
    id: '35201010',
    name: '  35201010  Biotechnology',
  },
  {
    id: '352020',
    name: '352020  Pharmaceuticals',
  },
  {
    id: '35202010',
    name: '  35202010  Pharmaceuticals',
  },
  {
    id: '352030',
    name: '352030  Life Sciences Tools & Services',
  },
  {
    id: '35203010',
    name: '  35203010  Life Sciences Tools & Services',
  },
  { id: '40', name: '40  Financials' },
  { id: '4010', name: '  4010  Banks' },
  {
    id: '401010',
    name: '401010  Banks',
  },
  {
    id: '40101010',
    name: '  40101010  Diversified Banks',
  },
  {
    id: '40101015',
    name: '  40101015  Regional Banks',
  },
  {
    id: '401020',
    name: '401020  Thrifts & Mortgage Finance',
  },
  {
    id: '40102010',
    name: '  40102010  Thrifts & Mortgage Finance',
  },
  {
    id: '4020',
    name: '  4020  Diversified Financials',
  },
  {
    id: '402010',
    name: '402010  Diversified Financial Services',
  },
  {
    id: '40201010',
    name: '  40201010  Consumer Finance - discontinued 04/30/2003',
  },
  {
    id: '40201020',
    name: '  40201020  Other Diversified Financial Services',
  },
  {
    id: '40201030',
    name: '  40201030  Multi-Sector Holdings',
  },
  {
    id: '40201040',
    name: '  40201040  Specialized Finance',
  },
  {
    id: '402020',
    name: '402020  Consumer Finance',
  },
  {
    id: '40202010',
    name: '  40202010  Consumer Finance',
  },
  {
    id: '402030',
    name: '402030  Capital Markets',
  },
  {
    id: '40203010',
    name: '  40203010  Asset Management & Custody Banks',
  },
  {
    id: '40203020',
    name: '  40203020  Investment Banking & Brokerage',
  },
  {
    id: '40203030',
    name: '  40203030  Diversified Capital Markets',
  },
  {
    id: '40203040',
    name: '  40203040  Financial Exchanges & Data',
  },
  {
    id: '402040',
    name: '402040  Mortgage Real Estate Investment Trusts (REITs)',
  },
  {
    id: '40204010',
    name: '  40204010  Mortgage REITs',
  },
  { id: '4030', name: '  4030  Insurance' },
  {
    id: '403010',
    name: '403010  Insurance',
  },
  {
    id: '40301010',
    name: '  40301010  Insurance Brokers',
  },
  {
    id: '40301020',
    name: '  40301020  Life & Health Insurance',
  },
  {
    id: '40301030',
    name: '  40301030  Multi-line Insurance',
  },
  {
    id: '40301040',
    name: '  40301040  Property & Casualty Insurance',
  },
  {
    id: '40301050',
    name: '  40301050  Reinsurance',
  },
  {
    id: '4040',
    name: '  4040  Real Estate - discontinued 08/31/2016',
  },
  {
    id: '404010',
    name: '404010  Real Estate - discontinued 04/28/2006',
  },
  {
    id: '40401010',
    name: '  40401010  Real Estate Investment Trusts - discontinued 04/28/2006',
  },
  {
    id: '40401020',
    name: '  40401020  Real Estate Management & Development - discontinued 04/28/20',
  },
  {
    id: '404020',
    name: '404020  Real Estate Investment Trusts (REITs) - discontinued 08/31/2',
  },
  {
    id: '40402010',
    name: '  40402010  Diversified REITs - discontinued 08/31/2016',
  },
  {
    id: '40402020',
    name: '  40402020  Industrial REITs - discontinued 08/31/2016',
  },
  {
    id: '40402030',
    name: '  40402030  Mortgage REITs - discontinued 08/31/2016',
  },
  {
    id: '40402040',
    name: '  40402040  Office REITs - discontinued 08/31/2016',
  },
  {
    id: '40402050',
    name: '  40402050  Residential REITs - discontinued 08/31/2016',
  },
  {
    id: '40402060',
    name: '  40402060  Retail REITs - discontinued 08/31/2016',
  },
  {
    id: '40402070',
    name: '  40402070  Specialized REITs - discontinued 08/31/2016',
  },
  {
    id: '404030',
    name: '404030  Real Estate Management & Development - discontinued 08/31/20',
  },
  {
    id: '40403010',
    name: '  40403010  Diversified Real Estate Activities - discontinued 08/31/2016',
  },
  {
    id: '40403020',
    name: '  40403020  Real Estate Operating Companies - discontinued 08/31/2016',
  },
  {
    id: '40403030',
    name: '  40403030  Real Estate Development - discontinued 08/31/2016',
  },
  {
    id: '40403040',
    name: '  40403040  Real Estate Services - discontinued 08/31/2016',
  },
  { id: '45', name: '45  Information Technology' },
  {
    id: '4510',
    name: '  4510  Software & Services',
  },
  {
    id: '451010',
    name: '451010  Internet Software & Services - discontinued 09/30/2018',
  },
  {
    id: '45101010',
    name: '  45101010  Internet Software & Services - discontinued 09/30/2018',
  },
  {
    id: '451020',
    name: '451020  IT Services',
  },
  {
    id: '45102010',
    name: '  45102010  IT Consulting & Other Services',
  },
  {
    id: '45102020',
    name: '  45102020  Data Processing & Outsourced Services',
  },
  {
    id: '45102030',
    name: '  45102030  Internet Services & Infrastructure',
  },
  {
    id: '451030',
    name: '451030  Software',
  },
  {
    id: '45103010',
    name: '  45103010  Application Software',
  },
  {
    id: '45103020',
    name: '  45103020  Systems Software',
  },
  {
    id: '45103030',
    name: '  45103030  Home Entertainment Software - discontinued 09/30/2018',
  },
  {
    id: '4520',
    name: '  4520  Technology Hardware & Equipment',
  },
  {
    id: '452010',
    name: '452010  Communications Equipment',
  },
  {
    id: '45201010',
    name: '  45201010  Networking Equipment - discontinued 04/30/2003',
  },
  {
    id: '45201020',
    name: '  45201020  Communications Equipment',
  },
  {
    id: '452020',
    name: '452020  Technology Hardware, Storage & Peripherals',
  },
  {
    id: '45202010',
    name: '  45202010  Computer Hardware - discontinued 02/28/2014',
  },
  {
    id: '45202020',
    name: '  45202020  Computer Storage & Peripherals - discontinued 02/28/2014',
  },
  {
    id: '45202030',
    name: '  45202030  Technology Hardware, Storage & Peripherals',
  },
  {
    id: '452030',
    name: '452030  Electronic Equipment, Instruments & Components',
  },
  {
    id: '45203010',
    name: '  45203010  Electronic Equipment & Instruments',
  },
  {
    id: '45203015',
    name: '  45203015  Electronic Components',
  },
  {
    id: '45203020',
    name: '  45203020  Electronic Manufacturing Services',
  },
  {
    id: '45203030',
    name: '  45203030  Technology Distributors',
  },
  {
    id: '452040',
    name: '452040  Office Electronics - discontinued 02/28/2014',
  },
  {
    id: '45204010',
    name: '  45204010  Office Electronics - discontinued 02/28/2014',
  },
  {
    id: '452050',
    name: '452050  Semiconductor Equipment & Products - discontinued 04/30/2003',
  },
  {
    id: '45205010',
    name: '  45205010  Semiconductor Equipment - discontinued 04/30/2003',
  },
  {
    id: '45205020',
    name: '  45205020  Semiconductors - discontinued 04/30/2003',
  },
  {
    id: '4530',
    name: '  4530  Semiconductors & Semiconductor Equipment',
  },
  {
    id: '453010',
    name: '453010  Semiconductors & Semiconductor Equipment',
  },
  {
    id: '45301010',
    name: '  45301010  Semiconductor Equipment',
  },
  {
    id: '45301020',
    name: '  45301020  Semiconductors',
  },
  { id: '50', name: '50  Communication Services' },
  {
    id: '5010',
    name: '  5010  Telecommunication Services',
  },
  {
    id: '501010',
    name: '501010  Diversified Telecommunication Services',
  },
  {
    id: '50101010',
    name: '  50101010  Alternative Carriers',
  },
  {
    id: '50101020',
    name: '  50101020  Integrated Telecommunication Services',
  },
  {
    id: '501020',
    name: '501020  Wireless Telecommunication Services',
  },
  {
    id: '50102010',
    name: '  50102010  Wireless Telecommunication Services',
  },
  {
    id: '5020',
    name: '  5020  Media & Entertainment',
  },
  {
    id: '502010',
    name: '502010  Media',
  },
  {
    id: '50201010',
    name: '  50201010  Advertising',
  },
  {
    id: '50201020',
    name: '  50201020  Broadcasting',
  },
  {
    id: '50201030',
    name: '  50201030  Cable & Satellite',
  },
  {
    id: '50201040',
    name: '  50201040  Publishing',
  },
  {
    id: '502020',
    name: '502020  Entertainment',
  },
  {
    id: '50202010',
    name: '  50202010  Movies & Entertainment',
  },
  {
    id: '50202020',
    name: '  50202020  Interactive Home Entertainment',
  },
  {
    id: '502030',
    name: '502030  Interactive Media & Services',
  },
  {
    id: '50203010',
    name: '  50203010  Interactive Media & Services',
  },
  { id: '55', name: '55  Utilities' },
  { id: '5510', name: '  5510  Utilities' },
  {
    id: '551010',
    name: '551010  Electric Utilities',
  },
  {
    id: '55101010',
    name: '  55101010  Electric Utilities',
  },
  {
    id: '551020',
    name: '551020  Gas Utilities',
  },
  {
    id: '55102010',
    name: '  55102010  Gas Utilities',
  },
  {
    id: '551030',
    name: '551030  Multi-Utilities',
  },
  {
    id: '55103010',
    name: '  55103010  Multi-Utilities',
  },
  {
    id: '551040',
    name: '551040  Water Utilities',
  },
  {
    id: '55104010',
    name: '  55104010  Water Utilities',
  },
  {
    id: '551050',
    name: '551050  Independent Power and Renewable Electricity Producers',
  },
  {
    id: '55105010',
    name: '  55105010  Independent Power Producers & Energy Traders',
  },
  {
    id: '55105020',
    name: '  55105020  Renewable Electricity',
  },
  { id: '60', name: '60  Real Estate' },
  { id: '6010', name: '  6010  Real Estate' },
  {
    id: '601010',
    name: '601010  Equity Real Estate Investment Trusts (REITs)',
  },
  {
    id: '60101010',
    name: '  60101010  Diversified REITs',
  },
  {
    id: '60101020',
    name: '  60101020  Industrial REITs',
  },
  {
    id: '60101030',
    name: '  60101030  Hotel & Resort REITs',
  },
  {
    id: '60101040',
    name: '  60101040  Office REITs',
  },
  {
    id: '60101050',
    name: '  60101050  Health Care REITs',
  },
  {
    id: '60101060',
    name: '  60101060  Residential REITs',
  },
  {
    id: '60101070',
    name: '  60101070  Retail REITs',
  },
  {
    id: '60101080',
    name: '  60101080  Specialized REITs',
  },
  {
    id: '601020',
    name: '601020  Real Estate Management & Development',
  },
  {
    id: '60102010',
    name: '  60102010  Diversified Real Estate Activities',
  },
  {
    id: '60102020',
    name: '  60102020  Real Estate Operating Companies',
  },
  {
    id: '60102030',
    name: '  60102030  Real Estate Development',
  },
  {
    id: '60102040',
    name: '  60102040  Real Estate Services',
  },
  { id: '99', name: '99  Non-classifiable' },
  { id: '9999', name: '  9999  Non-classifiable' },
  {
    id: '999999',
    name: '999999  Non-classifiable',
  },
  {
    id: '99999999',
    name: '  99999999  Non-classifiable                ',
  },
];

export const advisorLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '66924', name: 'Acumen Capital Finance Partners Limited' },
  { id: '91680', name: 'AGS Capital Corp.' },
  { id: '95470', name: 'Alexander Capital Group Inc.' },
  { id: '143249', name: 'American Discovery Capital, LLC' },
  { id: '103817', name: 'Arbuthnot Securities Limited' },
  { id: '32652', name: 'ARC Financial Corp.' },
  { id: '15686', name: 'Arthur Andersen LLP' },
  { id: '111693', name: 'ATB Capital Markets Inc.' },
  { id: '16112', name: 'ATB Financial' },
  { id: '103462', name: 'Axemen Resource Capital Ltd.' },
  { id: '151888', name: 'B. Riley FBR, Inc.' },
  { id: '125452', name: 'Bain & Company, Inc.' },
  { id: '87559', name: 'Banc Of America Securities Canada Co.' },
  { id: '66926', name: 'Banc Of America Securities LLC' },
  { id: '109737', name: 'Bank Of America Merrill Lynch' },
  { id: '81243', name: 'Barclays Capital Inc.' },
  { id: '108588', name: 'BayFront Capital Partners Ltd.' },
  { id: '15696', name: 'BDO Canada LLP' },
  { id: '87105', name: 'Beacon Securities Limited' },
  { id: '46892', name: 'Bear, Stearns & Co. Inc.' },
  { id: '86257', name: 'BKD, LLP' },
  { id: '120144', name: 'Black Spruce Merchant Capital Corporation' },
  { id: '55357', name: 'Blackmont Capital Inc.' },
  { id: '61032', name: 'Blair Franklin Capital Partners Inc.' },
  { id: '103899', name: 'Bloom Burton Securities Inc.' },
  { id: '88054', name: 'BMO Capital Markets' },
  { id: '58672', name: 'BMO Nesbitt Burns Inc.' },
  { id: '38824', name: 'BNP Paribas' },
  { id: '77601', name: 'Bolder Investment Partners, Ltd.' },
  { id: '151088', name: 'Bordeaux Capital Inc.' },
  { id: '66933', name: 'Brant Securities Limited' },
  { id: '109731', name: 'Broadband Capital Management LLC' },
  { id: '56735', name: 'Brookfield Financial Corporation' },
  { id: '143872', name: 'BTIG, LLC' },
  { id: '104305', name: 'Burkman Capital Corporation' },
  { id: '104530', name: 'Byron Capital Markets Ltd.' },
  { id: '40073', name: 'C.M. Oliver & Company Limited' },
  { id: '126829', name: 'Cairn Merchant Partners LP' },
  { id: '33648', name: 'Canaccord Genuity Corp.' },
  { id: '81302', name: 'Canaccord Genuity Limited' },
  { id: '120188', name: 'Cantor Fitzgerald Canada Corporation' },
  { id: '38858', name: 'Capital Canada Limited' },
  { id: '39128', name: 'Capital West Partners' },
  { id: '91870', name: 'Casimir Capital L.P.' },
  { id: '92538', name: 'Celtis Capital Inc.' },
  { id: '105951', name: 'Cenkos Securities Plc' },
  { id: '80921', name: 'Champlain Financial Corporation' },
  { id: '66941', name: 'Chase Securities Inc.' },
  { id: '15728', name: 'CIBC World Markets Inc.' },
  { id: '79211', name: 'Citigroup Global Markets Inc.' },
  { id: '151776', name: 'Citizens Capital Markets, Inc.' },
  { id: '85692', name: 'Clarus Securities Inc.' },
  { id: '65774', name: 'Coniston Investment Corp.' },
  { id: '72775', name: 'Coopers & Lybrand' },
  { id: '67013', name: 'Cormark Securities Inc.' },
  { id: '58618', name: 'Cowen And Company, LLC' },
  { id: '101839', name: 'Cowen Securities LLC' },
  { id: '86905', name: 'Credit Suisse Securities (Canada), Inc.' },
  { id: '61858', name: 'Credit Suisse Securities (USA) LLC' },
  { id: '66946', name: 'Crosbie & Company Inc.' },
  { id: '87692', name: 'D.A. Davidson & Co. Incorporated' },
  { id: '87413', name: 'Dain Rauscher Wessels Incorporated' },
  { id: '33723', name: 'Deloitte LLP' },
  { id: '15818', name: 'Desjardins Securities Inc.' },
  { id: '48846', name: 'Deutsche Bank AG' },
  { id: '32805', name: 'Deutsche Bank Securities Inc.' },
  { id: '87022', name: 'Deutsche Bank Securities Limited' },
  {
    id: '14255',
    name: 'Donaldson, Lufkin & Jenrette Securities Corporation',
  },
  { id: '111329', name: 'Dundee Capital Markets Inc.' },
  { id: '111015', name: 'Echelon Wealth Partners Inc.' },
  { id: '111496', name: 'Eight Capital Corp.' },
  { id: '66952', name: 'Emerging Equities Inc.' },
  { id: '76363', name: 'Endeavour Financial Corporation' },
  { id: '38610', name: 'Endeavour Financial Ltd.' },
  { id: '31470', name: 'Ernst & Young LLP' },
  { id: '82689', name: 'Euroz Securities Limited' },
  { id: '156943', name: 'FirePower Capital Corporation' },
  { id: '15868', name: 'First Marathon Securities Limited' },
  { id: '108013', name: 'First Republic Capital Corp.' },
  { id: '7387', name: 'FirstEnergy Capital Corp.' },
  { id: '87759', name: 'Fraser Mackenzie Limited' },
  { id: '155753', name: 'Generational Equity, LLC' },
  { id: '76064', name: 'Genuity Capital Markets' },
  { id: '66961', name: 'Goepel Shields & Partners Inc.' },
  { id: '58986', name: 'Goldman Sachs Canada Inc.' },
  { id: '66962', name: 'Goldman Sachs International Limited' },
  { id: '47464', name: 'Goldman, Sachs & Co.' },
  { id: '66963', name: 'Gordon Capital Corporation' },
  { id: '59515', name: 'Grant Thornton LLP' },
  { id: '106239', name: 'Gravitas Securities Inc.' },
  { id: '129960', name: 'Guggenheim Securities, LLC' },
  { id: '145805', name: 'Hannam & Partners (Advisory) LLP' },
  { id: '57820', name: 'Haywood Securities Inc.' },
  { id: '101331', name: 'Hermitage Canada Finance Inc.' },
  { id: '117918', name: 'Houlihan Lokey Capital, Inc.' },
  { id: '12136', name: 'HSBC Bank Plc' },
  { id: '20944', name: 'HSBC Securities (Canada) Inc.' },
  { id: '59888', name: 'HSBC Securities (USA) Inc.' },
  { id: '66965', name: 'IBK Capital Corp.' },
  { id: '111548', name: 'Imperial Capital, LLC' },
  { id: '61214', name: 'Industrial Alliance Securities Inc.' },
  { id: '133775', name: 'INFOR Financial Group Inc.' },
  { id: '134567', name: 'INFOR Financial Inc.' },
  { id: '88030', name: 'ING Financial Markets LLC' },
  { id: '82775', name: 'J.F. Mackie & Company Ltd.' },
  { id: '41980', name: 'J.P. Morgan Securities Canada Inc.' },
  { id: '79208', name: 'J.P. Morgan Securities LLC' },
  { id: '88966', name: 'Jacob Securities Inc.' },
  { id: '87695', name: 'Janney Montgomery Scott LLC' },
  { id: '84397', name: 'Jefferies & Company, Inc.' },
  { id: '31103', name: 'Jefferies Group, Inc.' },
  { id: '38419', name: 'Jennings Capital Inc.' },
  { id: '76078', name: 'JMP Securities LLC' },
  { id: '66973', name: 'Johnson Rice & Company LLC' },
  { id: '24549', name: 'Jones, Gable & Company Limited' },
  { id: '81445', name: 'JPMorgan Cazenove Limited' },
  { id: '14628', name: 'JPMorgan Chase & Co.' },
  { id: '87504', name: 'Keefe, Bruyette & Woods, Inc.' },
  { id: '127563', name: 'KES 7 Capital Inc.' },
  { id: '31039', name: 'KPMG LLP' },
  { id: '44283', name: 'Laurentian Bank Securities Inc.' },
  { id: '66928', name: 'Lawrence Capital Partners Inc.' },
  { id: '86809', name: 'Lazard Capital Markets LLC' },
  { id: '68807', name: 'Lazard Ltd.' },
  { id: '87808', name: 'Leerink Partners LLC' },
  { id: '46315', name: 'Lehman Brothers Inc.' },
  { id: '87595', name: 'Lightyear Capital Inc.' },
  { id: '137542', name: 'LionTree Advisors, LLC' },
  { id: '15987', name: 'Loewen, Ondaatje, McCutcheon Limited' },
  { id: '94291', name: 'Lytton Financial Inc.' },
  { id: '87864', name: 'M Partners Inc.' },
  { id: '60654', name: 'Mackie Research Capital Corporation' },
  { id: '27809', name: 'Macquarie Capital Markets Canada Ltd.' },
  { id: '60981', name: 'Macquarie North America Ltd.' },
  { id: '82247', name: 'Maison Placements Canada Inc.' },
  { id: '142353', name: 'Mazars LLP' },
  { id: '126214', name: 'Medalist Capital Ltd.' },
  { id: '9374', name: 'Merrill Lynch & Co., Inc.' },
  { id: '16025', name: 'Merrill Lynch Canada Inc.' },
  { id: '86572', name: 'Merrill Lynch International' },
  {
    id: '86585',
    name: 'Merrill Lynch, Pierce, Fenner & Smith Incorporated',
  },
  { id: '57322', name: 'MGI Securities Inc.' },
  { id: '16027', name: 'Midland Walwyn Capital Inc.' },
  { id: '36840', name: 'MNP LLP' },
  { id: '109200', name: 'Moelis & Company' },
  { id: '88134', name: 'Montgomery & Co., LLC' },
  { id: '66982', name: 'Morgan Grenfell & Co. Limited' },
  { id: '80436', name: 'Morgan Keegan & Company, Inc.' },
  { id: '36983', name: 'Morgan Stanley & Co. LLC' },
  { id: '62955', name: 'Morgan Stanley Canada Limited' },
  { id: '61127', name: 'Mustang Capital Partners Inc.' },
  { id: '66983', name: 'N M Rothschild & Sons Limited' },
  { id: '15981', name: 'National Bank Financial Inc.' },
  { id: '66979', name: 'National Bank Financial Ltd.' },
  { id: '87507', name: 'Needham & Company, LLC' },
  { id: '38395', name: 'Network Capital Inc.' },
  { id: '42277', name: 'Newcrest Capital Inc.' },
  { id: '64300', name: 'NewPoint Capital Partners Inc.' },
  { id: '86957', name: 'Nomura Securities International, Inc.' },
  { id: '46978', name: 'Northern Securities Inc.' },
  { id: '87805', name: 'Numis Securities Limited' },
  { id: '87737', name: 'Ocean Equities Ltd.' },
  { id: '61834', name: 'Octagon Capital Corporation' },
  { id: '102320', name: 'Oppenheimer & Co. Inc.' },
  { id: '38814', name: 'Orenda Corporate Finance Ltd.' },
  { id: '123467', name: 'Origin Merchant Partners' },
  { id: '87772', name: 'Osprey Capital Partners Inc.' },
  { id: '87124', name: 'Pacific Crest Securities, Inc.' },
  { id: '101735', name: 'Pannell Kerr Forster Of Texas, P.C.' },
  { id: '82665', name: 'Paradigm Capital Inc.' },
  { id: '87552', name: 'Pareto Securities ASA' },
  { id: '59903', name: 'Peters & Co. Limited' },
  { id: '105636', name: 'Petsky Prunier LLC' },
  { id: '59150', name: 'PI Financial Corporation' },
  { id: '137032', name: 'PillarFour Capital Inc.' },
  { id: '87009', name: 'Piper Sandler & Co.' },
  { id: '38383', name: 'Pollitt & Co., Inc.' },
  { id: '66986', name: 'Pope & Company Limited' },
  { id: '87738', name: 'PowerOne Capital Markets Limited' },
  { id: '16110', name: 'PricewaterhouseCoopers LLP' },
  { id: '87990', name: 'Primary Capital Inc.' },
  { id: '66989', name: 'Prudential Securities Inc.' },
  { id: '162665', name: 'R&D Venture Partners' },
  { id: '66990', name: 'Rampart Securities Inc.' },
  { id: '57640', name: 'Raymond Chabot Grant Thornton LLP' },
  { id: '58671', name: 'Raymond James & Associates, Inc.' },
  { id: '33809', name: 'Raymond James Ltd.' },
  { id: '59731', name: 'RBC Capital Markets' },
  { id: '16117', name: 'RBC Dominion Securities Inc.' },
  { id: '137906', name: 'Red Cloud Klondike Strike Inc.' },
  { id: '78939', name: 'RF Securities Clearing LP' },
  { id: '66993', name: 'Richardson Greenshields Of Canada Limited' },
  { id: '16138', name: 'Richter LLP' },
  { id: '66996', name: 'Robert W. Baird & Co. Incorporated' },
  { id: '87296', name: 'Robertson Stephens, Inc.' },
  { id: '66998', name: 'Rock Capital Partners Ltd.' },
  { id: '87455', name: 'Roth Capital Partners, LLC' },
  { id: '156256', name: 'Rothschild & Co.' },
  { id: '89240', name: 'Rundle Energy Partners Ltd.' },
  { id: '67004', name: 'Salman Partners Inc.' },
  { id: '67005', name: 'Salomon Brothers Inc.' },
  { id: '67007', name: 'Sayer Securities Limited' },
  { id: '67008', name: 'SBC Warburg Dillon Read Inc.' },
  { id: '39319', name: 'Schroder & Co. Inc.' },
  { id: '16169', name: 'Scotia Capital Inc.' },
  { id: '79061', name: 'Scotia Waterous Inc.' },
  { id: '86840', name: 'SG Americas Securities, LLC' },
  { id: '79365', name: 'Simmons & Company International' },
  { id: '67011', name: 'Smith Barney Canada Inc.' },
  { id: '39631', name: 'Smith Barney Inc.' },
  {
    id: '83061',
    name: 'SociéTé GéNéRale Corporate And Investment Banking',
  },
  { id: '86778', name: 'SociéTé GéNéRale S.A.' },
  { id: '67012', name: 'SociéTé GéNéRale Securities Inc.' },
  { id: '139539', name: 'Somers And Partners Pty Ltd.' },
  { id: '110386', name: 'Sprott Capital Partners LP' },
  { id: '88584', name: 'Standard Bank Plc' },
  { id: '99531', name: 'Standard Chartered Bank' },
  { id: '67014', name: 'Standard Securities Capital Corporation' },
  { id: '87611', name: 'Stephens Inc.' },
  { id: '77053', name: 'Stifel Nicolaus Canada Inc.' },
  { id: '87697', name: 'Stifel, Nicolaus & Company, Incorporated' },
  { id: '107342', name: 'Stonecap Securities Inc.' },
  { id: '87121', name: 'Sunrise Securities Corp.' },
  { id: '87593', name: 'SunTrust Robinson Humphrey, Inc.' },
  { id: '158631', name: 'SVB Leerink LLC' },
  { id: '38229', name: 'TD Securities Inc.' },
  { id: '86673', name: 'The Hathaway Corporation' },
  { id: '66923', name: 'The Royal Bank Of Scotland N.V.' },
  { id: '9833', name: 'The Royal Bank Of Scotland Plc' },
  { id: '87653', name: 'ThinkEquity LLC' },
  { id: '67017', name: 'Thomas Weisel Partners LLC' },
  { id: '87679', name: 'Toll Cross Securities Inc.' },
  { id: '60234', name: 'Tristone Capital Inc.' },
  {
    id: '112578',
    name: 'Tudor, Pickering, Holt & Co. Securities, Inc.',
  },
  { id: '86997', name: 'UBS Limited' },
  { id: '39892', name: 'UBS Securities Canada Inc.' },
  { id: '79202', name: 'UBS Securities LLC' },
  { id: '77629', name: 'Veronis Suhler Stevenson Partners LLC' },
  { id: '86988', name: 'Versant Partners Inc.' },
  { id: '60655', name: 'Watershed Capital Partners Inc.' },
  { id: '34188', name: 'Welch LLP' },
  { id: '74853', name: 'Wellington West Capital Markets Inc.' },
  { id: '87573', name: 'Wells Fargo Securities, LLC' },
  { id: '67028', name: 'William Blair & Company, LLC' },
  { id: '80700', name: 'Wolverton Securities Ltd.' },
];

export const legalAdvisorLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '33522', name: 'Aird & Berlis LLP' },
  { id: '88016', name: 'Akin Gump Strauss Hauer Feld LLP' },
  { id: '39059', name: 'Alexander Holburn Beaudin & Lang LLP' },
  { id: '87978', name: 'Allen & Overy LLP' },
  { id: '138606', name: 'Alston & Bird LLP' },
  { id: '102861', name: 'Andrews Kurth Kenyon LLP' },
  { id: '95537', name: 'Arnold & Porter Kaye Scholer LLP' },
  { id: '103990', name: 'Arthur Cox' },
  { id: '88026', name: 'Ashurst LLP' },
  { id: '102328', name: 'Baker & Hostetler LLP' },
  { id: '87318', name: 'Baker & McKenzie LLP' },
  { id: '88031', name: 'Baker Botts LLP' },
  { id: '77033', name: 'BCF LLP' },
  { id: '15707', name: 'Bennett Jones LLP' },
  { id: '15713', name: 'Blake, Cassels & Graydon LLP' },
  { id: '38593', name: 'Blaney McMurtry LLP' },
  { id: '32245', name: 'Borden Ladner Gervais LLP' },
  { id: '87963', name: 'Bracewell LLP' },
  { id: '75678', name: 'Brown Rudnick LLP' },
  { id: '59727', name: 'Bryan & Company LLP' },
  { id: '110261', name: 'Bryan Cave Leighton Paisner LLP' },
  { id: '122216', name: 'Burness Paull LLP' },
  { id: '36585', name: 'Burnet, Duckworth & Palmer LLP' },
  { id: '39196', name: 'Burstall LLP' },
  { id: '87440', name: 'Cahill Gordon & Reindel LLP' },
  { id: '58702', name: 'Carscallen LLP' },
  { id: '33672', name: 'Cassels Brock & Blackwell LLP' },
  { id: '87333', name: 'Chitiz Pathak LLP' },
  { id: '88818', name: 'Choate, Hall & Stewart LLP' },
  { id: '33688', name: 'Clark Wilson LLP' },
  { id: '87910', name: 'Clayton Utz' },
  { id: '87648', name: 'Cleary Gottlieb Steen & Hamilton LLP' },
  { id: '99140', name: 'Conyers Dill & Pearman Limited' },
  { id: '87913', name: 'Cooley LLP' },
  { id: '81316', name: 'Covington & Burling LLP' },
  { id: '38798', name: 'Cox & Palmer LLP' },
  { id: '87129', name: 'Cravath, Swaine & Moore LLP' },
  { id: '33721', name: 'Davies Ward Phillips & Vineberg LLP' },
  { id: '89040', name: 'Davis Graham & Stubbs LLP' },
  { id: '87132', name: 'Davis Polk & Wardwell LLP' },
  { id: '87902', name: 'De Grandpré Chait LLP' },
  { id: '87570', name: 'Debevoise & Plimpton LLP' },
  { id: '117201', name: 'Dechert LLP' },
  { id: '32277', name: 'Dentons Canada LLP' },
  { id: '59605', name: 'Dentons US LLP' },
  { id: '32804', name: 'DLA Piper (Canada) LLP' },
  { id: '103458', name: 'DLA Piper (US) LLP' },
  { id: '87916', name: 'Dorsey & Whitney LLP' },
  { id: '133827', name: 'Drinker Biddle & Reath LLP' },
  { id: '141185', name: 'Duane Morris LLP' },
  { id: '38344', name: 'DuMoulin Black LLP' },
  { id: '87783', name: 'Erwin & Thompson LLP' },
  { id: '95677', name: 'Faegre Baker Daniels LLP' },
  { id: '57648', name: 'Farris LLP' },
  { id: '33775', name: 'Fasken Martineau DuMoulin LLP' },
  { id: '59867', name: 'Felesky Flynn LLP' },
  { id: '87942', name: 'Fenwick & West LLP' },
  { id: '45233', name: 'Fogler, Rubinoff LLP' },
  { id: '88998', name: 'Foley & Lardner LLP' },
  { id: '141530', name: 'Forooghian & Company Law Corporation' },
  { id: '152299', name: 'Fox Rothschild LLP' },
  { id: '143294', name: 'Freshfields Bruckhaus Deringer LLP' },
  {
    id: '88029',
    name: 'Fried, Frank, Harris, Shriver & Jacobson LLP',
  },
  { id: '86797', name: 'Gardiner Roberts LLP' },
  { id: '87311', name: 'Garfinkle Biderman LLP' },
  { id: '94372', name: 'Gibson, Dunn & Crutcher LLP' },
  { id: '117863', name: 'Gilbert & Tobin' },
  { id: '21511', name: 'Goodmans LLP' },
  { id: '87921', name: 'Goodwin Procter LLP' },
  { id: '15901', name: 'Gowling WLG (Canada) LLP' },
  { id: '110267', name: 'Greenberg Traurig LLP' },
  { id: '158527', name: 'Harper Grey LLP' },
  { id: '95114', name: 'Harris + Harris LLP' },
  { id: '47009', name: 'Hartleys Limited' },
  { id: '90281', name: 'Herbert Smith Freehills LLP' },
  { id: '126263', name: 'Hogan Lovells International LLP' },
  { id: '83301', name: 'Holland & Knight LLP' },
  { id: '119961', name: 'HopgoodGanim Lawyers' },
  { id: '141653', name: 'Husch Blackwell LLP' },
  { id: '107021', name: 'Irwin Lowy LLP' },
  { id: '87638', name: 'J.P. Morgan Securities Plc' },
  { id: '88018', name: 'Jones Day LLP' },
  { id: '90167', name: 'K&L Gates LLP' },
  { id: '87928', name: 'Katten Muchin Rosenman LLP' },
  { id: '154861', name: 'Kelley Drye & Warren LLP' },
  { id: '119341', name: 'King & Spalding LLP' },
  { id: '91942', name: 'King & Wood Mallesons LLP' },
  { id: '57653', name: 'Kirkland & Ellis LLP' },
  { id: '39392', name: 'Koffman Kalef LLP' },
  { id: '115295', name: 'Kramer Levin Naftalis & Frankel LLP' },
  { id: '38206', name: 'LaBarge Weinstein LLP' },
  { id: '15974', name: 'Lang Michener LLP' },
  { id: '38612', name: 'Lapointe Rosenstein Marchand MelançOn LLP' },
  { id: '61005', name: 'Latham & Watkins LLP' },
  { id: '27783', name: 'Lavery, De Billy LLP' },
  { id: '33898', name: 'Lawson Lundell LLP' },
  { id: '119239', name: 'Linklaters LLP' },
  { id: '131717', name: 'Locke Lord LLP' },
  { id: '132542', name: 'Loeb & Loeb LLP' },
  { id: '142996', name: 'Loopstra Nixon LLP' },
  { id: '87734', name: 'Maxis Law Corporation' },
  { id: '87882', name: 'Mayer Brown LLP' },
  { id: '16012', name: 'McCarthy TéTrault LLP' },
  { id: '86848', name: "McCullough O'Connor Irwin LLP" },
  { id: '117314', name: 'McDermott Will & Emery LLP' },
  { id: '38748', name: 'McDougall Gauley LLP' },
  { id: '36822', name: 'McInnes Cooper' },
  { id: '39144', name: 'McKercher LLP' },
  { id: '75847', name: 'McLean & Kerr LLP' },
  { id: '49023', name: 'McLennan Ross LLP' },
  { id: '32318', name: 'McMillan LLP' },
  { id: '105786', name: 'Milbank, Tweed, Hadley & McCloy LLP' },
  { id: '32322', name: 'Miller Thomson LLP' },
  { id: '87127', name: 'Minden Gross LLP' },
  { id: '39113', name: 'MLT Aikins LLP' },
  { id: '102054', name: 'Morgan Lewis LLP' },
  { id: '87506', name: 'Morrison & Foerster LLP' },
  { id: '86799', name: 'Morton Law, LLP' },
  { id: '114927', name: 'Mourant Ozannes' },
  { id: '87936', name: 'Neal, Gerber & Eisenberg LLP' },
  { id: '99515', name: 'Nixon Peabody LLP' },
  { id: '30420', name: 'Norton Rose Fulbright Canada LLP' },
  { id: '88024', name: 'Norton Rose Fulbright LLP' },
  { id: '115343', name: "O'Melveny & Myers, LLP" },
  { id: '158353', name: "O'Neill Law LLP" },
  { id: '87410', name: 'Orrick, Herrington & Sutcliffe LLP' },
  { id: '16076', name: 'Osler, Hoskin & Harcourt LLP' },
  { id: '16081', name: 'Owen Bird Law Corporation' },
  { id: '38482', name: 'Parlee McLaws LLP' },
  { id: '93367', name: 'Parsons Behle & Latimer' },
  { id: '111639', name: 'Paul Hastings LLP' },
  {
    id: '87592',
    name: 'Paul, Weiss, Rifkind, Wharton & Garrison LLP',
  },
  { id: '87930', name: 'Perkins Coie LLP' },
  { id: '142108', name: 'Peterson McVicar LLP' },
  { id: '61099', name: 'Pillsbury Winthrop Shaw Pittman LLP' },
  { id: '142987', name: 'Pinsent Masons LLP' },
  { id: '155093', name: 'Piper Alderman' },
  { id: '118264', name: 'Polsinelli PC' },
  { id: '90925', name: 'Proskauer Rose LLP' },
  { id: '122673', name: 'Pryor Cashman LLP' },
  { id: '87982', name: 'Pushor Mitchell LLP' },
  { id: '89965', name: 'Reed Smith LLP' },
  { id: '88103', name: 'Ropes & Gray LLP' },
  { id: '105083', name: 'Rutan & Tucker, LLP' },
  { id: '38651', name: 'Salley Bowes Harwardt Law Corp.' },
  { id: '86757', name: 'Sangra Moller LLP' },
  { id: '61164', name: 'SéGuin Racine Attorneys Ltd.' },
  { id: '39425', name: 'Shearman & Sterling LLP' },
  { id: '87797', name: 'Sidley Austin LLP' },
  { id: '87769', name: 'Simpson Thacher & Bartlett LLP' },
  { id: '39210', name: 'Skadden, Arps, Slate, Meagher & Flom LLP' },
  { id: '60019', name: 'Spiegel Sohmer Inc.' },
  { id: '95044', name: 'Stein Monast LLP' },
  { id: '88025', name: 'Steinepreis Paganin' },
  { id: '32382', name: 'Stewart McKelvey LLP' },
  { id: '32383', name: 'Stikeman Elliott LLP' },
  { id: '154287', name: 'Stubbs Alderton & Markiles, LLP' },
  { id: '87130', name: 'Sullivan & Cromwell LLP' },
  { id: '34146', name: 'Torkin Manes LLP' },
  { id: '16216', name: 'Torys LLP' },
  { id: '87938', name: 'Troutman Pepper Hamilton Sanders LLP' },
  { id: '87558', name: 'Vinson & Elkins LLP' },
  { id: '96011', name: 'Wachtell, Lipton, Rosen & Katz' },
  { id: '98391', name: 'Walkers SPV Limited' },
  { id: '142609', name: 'Watson Farley & Williams LLP' },
  { id: '87522', name: 'Weil, Gotshal & Manges LLP' },
  { id: '36823', name: 'WeirFoulds LLP' },
  { id: '79332', name: 'White & Case LLP' },
  { id: '57774', name: 'Wildeboer Dellelce LLP' },
  { id: '135060', name: 'Willkie Farr & Gallagher LLP' },
  {
    id: '86985',
    name: 'Wilson Sonsini Goodrich & Rosati Professional Corporation',
  },
  { id: '94856', name: 'Wilson Vukelich LLP' },
  { id: '49041', name: 'Winston & Strawn LLP' },
  { id: '87975', name: 'Woods & Company' },
];

export const exchangesLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'TSX' },
  { id: '3', name: 'TSX-VEN' },
  { id: '5', name: 'NEO' },
  { id: '6', name: 'CSE' },
  { id: '7', name: 'NYSE' },
  { id: '9', name: 'NYSE MKT' },
  { id: '10', name: 'NASDAQ' },
];

export const companyTypesExtendedLookup: Array<ILookup> = [
  { id: '0', name: '---' },
  { id: '63', name: 'co-operative' },
  { id: '3', name: 'federal crown corporation' },
  { id: '61', name: 'private corporation' },
  { id: '67', name: 'private limited partnership' },
  { id: '13', name: 'provincial crown corporation' },
  { id: '62', name: 'public corporation' },
  { id: '68', name: 'public limited partnership' },
];

export const companySubTypesExtendedLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'business trust' },
  { id: '10', name: 'capital pool' },
  { id: '2', name: 'flow-through' },
  { id: '3', name: 'miscellaneous trust' },
  { id: '4', name: 'oil & gas royalty trust' },
  { id: '5', name: 'REIT (real estate investment trust)' },
  { id: '11', name: 'special purpose acquisition company' },
  { id: '8', name: 'split share' },
  { id: '6', name: 'structured product' },
  { id: '7', name: 'utility trust (power & pipeline)' },
];

export const auditorsLookup: Array<ILookup> = [
  { id: '', name: '---' },
  {
    id: 'A Chan & Company LLP, C.A.',
    name: 'A Chan & Company LLP, C.A.',
  },
  { id: 'A.R.T. Tax and Audit, C.A.', name: 'A.R.T. Tax and Audit, C.A.' },
  {
    id: 'Accell Audit & Compliance, C.P.A.',
    name: 'Accell Audit & Compliance, C.P.A.',
  },
  { id: 'Adam Sung Kim Ltd., C.A.', name: 'Adam Sung Kim Ltd., C.A.' },
  { id: 'AGT Partners LLP, C.A.', name: 'AGT Partners LLP, C.A.' },
  {
    id: 'Antares Professional Corporation, C.A.',
    name: 'Antares Professional Corporation, C.A.',
  },
  {
    id: 'Arsenault Best Cameron Ellis, C.A.',
    name: 'Arsenault Best Cameron Ellis, C.A.',
  },
  { id: 'Auditor General of Alberta', name: 'Auditor General of Alberta' },
  {
    id: 'Auditor General of British Columbia',
    name: 'Auditor General of British Columbia',
  },
  { id: 'Auditor General of Canada', name: 'Auditor General of Canada' },
  {
    id: 'Auditor General of Manitoba',
    name: 'Auditor General of Manitoba',
  },
  { id: 'Auditor General of Ontario', name: 'Auditor General of Ontario' },
  { id: 'Auditor General of Quebec', name: 'Auditor General of Quebec' },
  { id: 'Baker Tilly HMA LLP, C.A.', name: 'Baker Tilly HMA LLP, C.A.' },
  {
    id: 'Baker Tilly US, LLP, C.P.A.',
    name: 'Baker Tilly US, LLP, C.P.A.',
  },
  { id: 'Baker Tilly WM LLP, C.A.', name: 'Baker Tilly WM LLP, C.A.' },
  { id: 'BDO AG, C.A.', name: 'BDO AG, C.A.' },
  {
    id: 'BDO Audit (WA) Pty Ltd., C.A.',
    name: 'BDO Audit (WA) Pty Ltd., C.A.',
  },
  { id: 'BDO Canada LLP, C.A.', name: 'BDO Canada LLP, C.A.' },
  { id: 'BDO Limited, C.P.A.', name: 'BDO Limited, C.P.A.' },
  { id: 'BDO LLP, C.A.', name: 'BDO LLP, C.A.' },
  { id: 'BDO Unicon AO', name: 'BDO Unicon AO' },
  { id: 'BDO USA LLP, C.P.A.', name: 'BDO USA LLP, C.P.A.' },
  { id: 'BDO Ziv Haft, C.P.A.', name: 'BDO Ziv Haft, C.P.A.' },
  { id: 'Bélanger Dalcourt, C.A.', name: 'Bélanger Dalcourt, C.A.' },
  {
    id: 'Bentleys SA Audit Partnership',
    name: 'Bentleys SA Audit Partnership',
  },
  {
    id: 'Blanchette Vachon et Associés, C.A.',
    name: 'Blanchette Vachon et Associés, C.A.',
  },
  { id: 'Brenham & Co.', name: 'Brenham & Co.' },
  {
    id: 'Brightman Almagor Zohar & Co., C.P.A.',
    name: 'Brightman Almagor Zohar & Co., C.P.A.',
  },
  { id: 'Brunet Roy Dubé LLP, C.A.', name: 'Brunet Roy Dubé LLP, C.A.' },
  { id: 'Charlton & Company, C.A.', name: 'Charlton & Company, C.A.' },
  {
    id: 'Cheng & Cheng Limited, C.P.A.',
    name: 'Cheng & Cheng Limited, C.P.A.',
  },
  { id: 'Clearhouse LLP, C.A.', name: 'Clearhouse LLP, C.A.' },
  {
    id: 'Coulter & Justus, P.C., C.P.A.',
    name: 'Coulter & Justus, P.C., C.P.A.',
  },
  {
    id: 'Crowe Clark Whitehall LLP, C.A.',
    name: 'Crowe Clark Whitehall LLP, C.A.',
  },
  { id: 'Crowe MacKay LLP, C.A.', name: 'Crowe MacKay LLP, C.A.' },
  { id: 'Crowe Soberman LLP, C.A.', name: 'Crowe Soberman LLP, C.A.' },
  { id: 'Culver & Co., C.A.', name: 'Culver & Co., C.A.' },
  { id: 'D & H Group LLP, C.A.', name: 'D & H Group LLP, C.A.' },
  {
    id: 'Dale Matheson Carr-Hilton LaBonte LLP, C.A.',
    name: 'Dale Matheson Carr-Hilton LaBonte LLP, C.A.',
  },
  {
    id: 'Dallaire & Lapointe Inc., C.A.',
    name: 'Dallaire & Lapointe Inc., C.A.',
  },
  {
    id: 'Davidson & Company LLP, C.A.',
    name: 'Davidson & Company LLP, C.A.',
  },
  { id: 'De Visser Gray LLP, C.A.', name: 'De Visser Gray LLP, C.A.' },
  {
    id: 'DeCoria, Maichel & Teague, C.P.A.',
    name: 'DeCoria, Maichel & Teague, C.P.A.',
  },
  { id: 'Deloitte & Associés', name: 'Deloitte & Associés' },
  { id: 'Deloitte AG, C.P.A.', name: 'Deloitte AG, C.P.A.' },
  { id: 'Deloitte Audit S.A.R.L.', name: 'Deloitte Audit S.A.R.L.' },
  { id: 'Deloitte LLP, C.A.', name: 'Deloitte LLP, C.A.' },
  { id: 'Deloitte LLP, C.P.A.', name: 'Deloitte LLP, C.P.A.' },
  { id: 'Deloitte S.A.', name: 'Deloitte S.A.' },
  {
    id: 'Deloitte Touche Tohmatsu, C.A.',
    name: 'Deloitte Touche Tohmatsu, C.A.',
  },
  {
    id: 'Deloitte Touche Tohmatsu, C.P.A.',
    name: 'Deloitte Touche Tohmatsu, C.P.A.',
  },
  { id: 'DNTW Toronto LLP, C.A.', name: 'DNTW Toronto LLP, C.A.' },
  { id: 'EisnerAmper LLP, C.P.A.', name: 'EisnerAmper LLP, C.P.A.' },
  {
    id: 'Ernst & Young Cyprus Ltd.',
    name: 'Ernst & Young Cyprus Ltd.',
  },
  { id: 'Ernst & Young LLP, C.A.', name: 'Ernst & Young LLP, C.A.' },
  {
    id: 'Ernst & Young LLP, C.P.A.',
    name: 'Ernst & Young LLP, C.P.A.',
  },
  {
    id: 'Fazzari & Partners LLP, C.A.',
    name: 'Fazzari & Partners LLP, C.A.',
  },
  {
    id: 'Fernandez Young and Associates, C.G.A.',
    name: 'Fernandez Young and Associates, C.G.A.',
  },
  {
    id: 'Flabbi & Associates LLP, C.A.',
    name: 'Flabbi & Associates LLP, C.A.',
  },
  {
    id: 'Fruci & Associates II, PLLC, C.P.A.',
    name: 'Fruci & Associates II, PLLC, C.P.A.',
  },
  {
    id: 'Geib & Company Professional Corporation, C.A.',
    name: 'Geib & Company Professional Corporation, C.A.',
  },
  {
    id: 'Goodman & Associates LLP, C.A.',
    name: 'Goodman & Associates LLP, C.A.',
  },
  { id: 'Grant Thornton LLP, C.A.', name: 'Grant Thornton LLP, C.A.' },
  { id: 'Grant Thornton LLP, C.P.A.', name: 'Grant Thornton LLP, C.P.A.' },
  {
    id: 'Grant Thornton New Zealand Audit Partnership, C.A.',
    name: 'Grant Thornton New Zealand Audit Partnership, C.A.',
  },
  {
    id: 'Grant Thornton UK LLP, C.A.',
    name: 'Grant Thornton UK LLP, C.A.',
  },
  { id: 'GreenGrowth, C.P.A.', name: 'GreenGrowth, C.P.A.' },
  {
    id: 'Guimond Lavallée Inc., C.A.',
    name: 'Guimond Lavallée Inc., C.A.',
  },
  {
    id: 'Hall & Company Certified Public Accountants & Consultants, Inc., C.P.A.',
    name: 'Hall & Company Certified Public Accountants & Consultants, Inc., C.P.A.',
  },
  { id: 'Harbourside CPA LLP, C.A.', name: 'Harbourside CPA LLP, C.A.' },
  { id: 'Haskell & White LLP', name: 'Haskell & White LLP' },
  { id: 'Hay & Watson, C.A.', name: 'Hay & Watson, C.A.' },
  { id: 'Haynie & Company, C.P.A.', name: 'Haynie & Company, C.P.A.' },
  {
    id: 'HDCPA Professional Corporation, C.A.',
    name: 'HDCPA Professional Corporation, C.A.',
  },
  {
    id: 'HLB Cinnamon, Jang, Willoughby & Company, C.A.',
    name: 'HLB Cinnamon, Jang, Willoughby & Company, C.A.',
  },
  { id: 'HLB Mann Judd, C.A.', name: 'HLB Mann Judd, C.A.' },
  { id: 'HS & Partners LLP, C.A.', name: 'HS & Partners LLP, C.A.' },
  {
    id: 'Jones & O’Connell LLP, C.A.',
    name: 'Jones & O’Connell LLP, C.A.',
  },
  { id: 'JSC KPMG', name: 'JSC KPMG' },
  { id: 'K. R. Margetson Ltd., C.A.', name: 'K. R. Margetson Ltd., C.A.' },
  { id: 'Kanester, Johal, C.A.', name: 'Kanester, Johal, C.A.' },
  {
    id: 'Kenway Mack Slusarchuk Stewart LLP, C.A.',
    name: 'Kenway Mack Slusarchuk Stewart LLP, C.A.',
  },
  {
    id: 'Kingston Ross Pasnak LLP, C.A.',
    name: 'Kingston Ross Pasnak LLP, C.A.',
  },
  {
    id: 'Kost Forer Gabbay & Kasierer, C.A.',
    name: 'Kost Forer Gabbay & Kasierer, C.A.',
  },
  { id: 'KPMG LLP, C.A.', name: 'KPMG LLP, C.A.' },
  { id: 'KPMG LLP, C.P.A.', name: 'KPMG LLP, C.P.A.' },
  { id: 'KPMG, C.A.', name: 'KPMG, C.A.' },
  { id: 'Kreston GTA LLP, C.A.', name: 'Kreston GTA LLP, C.A.' },
  { id: 'KWCO, PC, C.P.A.', name: 'KWCO, PC, C.P.A.' },
  { id: 'Lancaster & David, C.A.', name: 'Lancaster & David, C.A.' },
  { id: 'Lipton LLP, C.A.', name: 'Lipton LLP, C.A.' },
  { id: 'M&K CPAS, PLLC, C.P.A.', name: 'M&K CPAS, PLLC, C.P.A.' },
  {
    id: "Macias Gini & O'Connell LLP, C.P.A.",
    name: "Macias Gini & O'Connell LLP, C.P.A.",
  },
  { id: 'Mallette LLP, C.A.', name: 'Mallette LLP, C.A.' },
  { id: 'MaloneBailey, LLP, C.P.A.', name: 'MaloneBailey, LLP, C.P.A.' },
  { id: 'Manning Elliott LLP, C.A.', name: 'Manning Elliott LLP, C.A.' },
  { id: 'Mao & Ying LLP, C.A.', name: 'Mao & Ying LLP, C.A.' },
  { id: 'Marcum LLP, C.P.A.', name: 'Marcum LLP, C.P.A.' },
  {
    id: 'Mazars Harel Drouin, LLP, C.A.',
    name: 'Mazars Harel Drouin, LLP, C.A.',
  },
  { id: 'McGovern Hurley LLP, C.A.', name: 'McGovern Hurley LLP, C.A.' },
  { id: 'MNP LLP, C.A.', name: 'MNP LLP, C.A.' },
  {
    id: 'Morgan & Company LLP, C.A.',
    name: 'Morgan & Company LLP, C.A.',
  },
  { id: 'MS Partners LLP, C.A.', name: 'MS Partners LLP, C.A.' },
  {
    id: 'Nexia Brisbane Audit Pty Ltd., C.A.',
    name: 'Nexia Brisbane Audit Pty Ltd., C.A.',
  },
  {
    id: 'NVS Chartered Accountants Professional Corporation, C.A.',
    name: 'NVS Chartered Accountants Professional Corporation, C.A.',
  },
  {
    id: 'Pannell Kerr Forster of Texas, P.C., C.P.A.',
    name: 'Pannell Kerr Forster of Texas, P.C., C.P.A.',
  },
  { id: 'Parker Simone LLP, C.A.', name: 'Parker Simone LLP, C.A.' },
  {
    id: 'Personal Finance Consulting Professional Corporation, C.P.A.',
    name: 'Personal Finance Consulting Professional Corporation, C.P.A.',
  },
  { id: 'Petrie Raymond LLP, C.A.', name: 'Petrie Raymond LLP, C.A.' },
  { id: 'Philip Gigan, C.A.', name: 'Philip Gigan, C.A.' },
  { id: 'Pitcher Partners, C.A.', name: 'Pitcher Partners, C.A.' },
  { id: 'Pivotal LLP, C.A.', name: 'Pivotal LLP, C.A.' },
  { id: 'PKF Littlejohn LLP, C.A.', name: 'PKF Littlejohn LLP, C.A.' },
  {
    id: 'Plante & Moran, PLLC, C.P.A.',
    name: 'Plante & Moran, PLLC, C.P.A.',
  },
  { id: 'Prager Metis CPAs, LLC', name: 'Prager Metis CPAs, LLC' },
  { id: 'PricewaterhouseCoopers', name: 'PricewaterhouseCoopers' },
  {
    id: 'PricewaterhouseCoopers Auditores Independentes S.S., A.I.',
    name: 'PricewaterhouseCoopers Auditores Independentes S.S., A.I.',
  },
  {
    id: 'PricewaterhouseCoopers CI LLP, C.A.',
    name: 'PricewaterhouseCoopers CI LLP, C.A.',
  },
  {
    id: 'PricewaterhouseCoopers Inc.',
    name: 'PricewaterhouseCoopers Inc.',
  },
  {
    id: 'PricewaterhouseCoopers LLP, C.A.',
    name: 'PricewaterhouseCoopers LLP, C.A.',
  },
  {
    id: 'PricewaterhouseCoopers LLP, C.P.A.',
    name: 'PricewaterhouseCoopers LLP, C.P.A.',
  },
  {
    id: 'PricewaterhouseCoopers SA, C.A.',
    name: 'PricewaterhouseCoopers SA, C.A.',
  },
  { id: 'PricewaterhouseCoopers SRL', name: 'PricewaterhouseCoopers SRL' },
  {
    id: 'Raymond Chabot Grant Thornton LLP, C.A.',
    name: 'Raymond Chabot Grant Thornton LLP, C.A.',
  },
  { id: 'RBSM LLP, C.P.A.', name: 'RBSM LLP, C.P.A.' },
  { id: 'Richter LLP, C.A.', name: 'Richter LLP, C.A.' },
  {
    id: 'Rosenberg Rich Baker Berman & Company, C.P.A.',
    name: 'Rosenberg Rich Baker Berman & Company, C.P.A.',
  },
  { id: 'RSM Alberta LLP, C.A.', name: 'RSM Alberta LLP, C.A.' },
  {
    id: 'RSM Australia Pty Ltd., C.A.',
    name: 'RSM Australia Pty Ltd., C.A.',
  },
  { id: 'RSM Canada LLP, C.A.', name: 'RSM Canada LLP, C.A.' },
  { id: 'RSM Hong Kong, C.P.A.', name: 'RSM Hong Kong, C.P.A.' },
  { id: 'RSM UK Group LLP', name: 'RSM UK Group LLP' },
  { id: 'RSM US LLP, C.P.A.', name: 'RSM US LLP, C.P.A.' },
  { id: 'S & W LLP, C.A.', name: 'S & W LLP, C.A.' },
  { id: 'Sam S. Mah Inc., C.A.', name: 'Sam S. Mah Inc., C.A.' },
  {
    id: 'Saturna Group Chartered Accountants LLP, C.A.',
    name: 'Saturna Group Chartered Accountants LLP, C.A.',
  },
  {
    id: 'SCS Audit & Corporate Services Pty Ltd., C.A.',
    name: 'SCS Audit & Corporate Services Pty Ltd., C.A.',
  },
  { id: 'Segal LLP, C.A.', name: 'Segal LLP, C.A.' },
  {
    id: 'SHIM & Associates LLP, C.A.',
    name: 'SHIM & Associates LLP, C.A.',
  },
  { id: 'SingerLewak LLP, C.P.A.', name: 'SingerLewak LLP, C.P.A.' },
  { id: 'Smythe LLP, C.A.', name: 'Smythe LLP, C.A.' },
  {
    id: 'SRCO Professional Corporation, C.A.',
    name: 'SRCO Professional Corporation, C.A.',
  },
  {
    id: 'Stern & Lovrics LLP, C.A.',
    name: 'Stern & Lovrics LLP, C.A.',
  },
  { id: 'Urish Popeck & Co., LLC', name: 'Urish Popeck & Co., LLC' },
  { id: 'Virtus Group LLP, C.A.', name: 'Virtus Group LLP, C.A.' },
  { id: 'Wasserman Ramsay, C.A.', name: 'Wasserman Ramsay, C.A.' },
  {
    id: 'WDM Chartered Accountants, C.A.',
    name: 'WDM Chartered Accountants, C.A.',
  },
  { id: 'Welch LLP, C.A.', name: 'Welch LLP, C.A.' },
  { id: 'Zeifmans LLP, C.A.', name: 'Zeifmans LLP, C.A.' },
];

export const bankersLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'Agricultural Bank of China', name: 'Agricultural Bank of China' },
  {
    id: 'Alterna Savings and Credit Union Limited',
    name: 'Alterna Savings and Credit Union Limited',
  },
  { id: 'Amegy Bank N.A.', name: 'Amegy Bank N.A.' },
  { id: 'ANZ Banking Group Limited', name: 'ANZ Banking Group Limited' },
  { id: 'ATB Financial', name: 'ATB Financial' },
  { id: 'Banco BICE S.A.', name: 'Banco BICE S.A.' },
  {
    id: 'Banco de Credito de Colombia S.A.',
    name: 'Banco de Credito de Colombia S.A.',
  },
  { id: 'Banco Santander Uruguay', name: 'Banco Santander Uruguay' },
  { id: 'Bank of America Canada', name: 'Bank of America Canada' },
  {
    id: 'Bank of America Corporation',
    name: 'Bank of America Corporation',
  },
  {
    id: 'Bank of America Merrill Lynch',
    name: 'Bank of America Merrill Lynch',
  },
  {
    id: 'Bank of China (Hong Kong) Limited',
    name: 'Bank of China (Hong Kong) Limited',
  },
  { id: 'Bank of China Limited', name: 'Bank of China Limited' },
  { id: 'Bank of Montreal', name: 'Bank of Montreal' },
  { id: 'Bank of Scotland plc', name: 'Bank of Scotland plc' },
  { id: 'Barclays Bank PLC', name: 'Barclays Bank PLC' },
  {
    id: 'BlueShore Financial Credit Union',
    name: 'BlueShore Financial Credit Union',
  },
  { id: 'BNP Paribas', name: 'BNP Paribas' },
  { id: 'BOK Financial Corporation', name: 'BOK Financial Corporation' },
  {
    id: 'Branch Banking and Trust Company',
    name: 'Branch Banking and Trust Company',
  },
  {
    id: 'Business Development Bank of Canada',
    name: 'Business Development Bank of Canada',
  },
  { id: 'Caisse centrale Desjardins', name: 'Caisse centrale Desjardins' },
  {
    id: "Caisse Desjardins de L'île-d'Orléans",
    name: "Caisse Desjardins de L'île-d'Orléans",
  },
  {
    id: 'Canadian Imperial Bank of Commerce',
    name: 'Canadian Imperial Bank of Commerce',
  },
  { id: 'Canadian Western Bank', name: 'Canadian Western Bank' },
  { id: 'Cidel Financial Group Inc.', name: 'Cidel Financial Group Inc.' },
  { id: 'Citibank Canada', name: 'Citibank Canada' },
  { id: 'Citibank, N.A.', name: 'Citibank, N.A.' },
  { id: 'Comerica Bank, N.A.', name: 'Comerica Bank, N.A.' },
  {
    id: 'Commonwealth Bank of Australia',
    name: 'Commonwealth Bank of Australia',
  },
  {
    id: 'Connect First Credit Union Ltd.',
    name: 'Connect First Credit Union Ltd.',
  },
  { id: 'Credit Suisse AG', name: 'Credit Suisse AG' },
  { id: 'Desjardins Bank, N.A.', name: 'Desjardins Bank, N.A.' },
  { id: 'Export Development Canada', name: 'Export Development Canada' },
  {
    id: 'First Bank of Nigeria Limited',
    name: 'First Bank of Nigeria Limited',
  },
  {
    id: 'Grupo Financiero Scotiabank Inverlat, S.A. de C.V.',
    name: 'Grupo Financiero Scotiabank Inverlat, S.A. de C.V.',
  },
  { id: 'Harris N.A.', name: 'Harris N.A.' },
  { id: 'HSBC Bank Canada', name: 'HSBC Bank Canada' },
  { id: 'HSBC Bank plc', name: 'HSBC Bank plc' },
  { id: 'HSBC Bank USA, N.A.', name: 'HSBC Bank USA, N.A.' },
  { id: 'ICICI Bank Limited', name: 'ICICI Bank Limited' },
  {
    id: 'J.P. Morgan Chase Bank (Canada)',
    name: 'J.P. Morgan Chase Bank (Canada)',
  },
  { id: 'JPMorgan Chase & Co.', name: 'JPMorgan Chase & Co.' },
  { id: 'JS Bank Limited', name: 'JS Bank Limited' },
  { id: 'Laurentian Bank of Canada', name: 'Laurentian Bank of Canada' },
  { id: 'M&T Bank Corporation', name: 'M&T Bank Corporation' },
  { id: 'Macquarie Bank Limited', name: 'Macquarie Bank Limited' },
  {
    id: 'MUFG Bank, Ltd., Canada Branch',
    name: 'MUFG Bank, Ltd., Canada Branch',
  },
  {
    id: 'National Australia Bank Limited',
    name: 'National Australia Bank Limited',
  },
  { id: 'National Bank of Canada', name: 'National Bank of Canada' },
  { id: 'PNC Bank Canada Branch', name: 'PNC Bank Canada Branch' },
  { id: 'Rabobank Canada', name: 'Rabobank Canada' },
  {
    id: 'Raymond James Finance Company of Canada Ltd.',
    name: 'Raymond James Finance Company of Canada Ltd.',
  },
  {
    id: 'Republic National Bank of New York',
    name: 'Republic National Bank of New York',
  },
  { id: 'Royal Bank of Canada', name: 'Royal Bank of Canada' },
  {
    id: 'Salmon Arm Savings and Credit Union',
    name: 'Salmon Arm Savings and Credit Union',
  },
  {
    id: 'Scotiabank Anguilla Limited',
    name: 'Scotiabank Anguilla Limited',
  },
  {
    id: 'Sumitomo Mitsui Banking Corporation Europe Limited',
    name: 'Sumitomo Mitsui Banking Corporation Europe Limited',
  },
  {
    id: 'Sumitomo Mitsui Banking Corporation of Canada',
    name: 'Sumitomo Mitsui Banking Corporation of Canada',
  },
  { id: 'The Bank of Nova Scotia', name: 'The Bank of Nova Scotia' },
  {
    id: 'The Royal Bank of Scotland N.V.',
    name: 'The Royal Bank of Scotland N.V.',
  },
  { id: 'The Toronto-Dominion Bank', name: 'The Toronto-Dominion Bank' },
  {
    id: 'U.S. Bank National Association',
    name: 'U.S. Bank National Association',
  },
  { id: 'Union Bank N.A.', name: 'Union Bank N.A.' },
  {
    id: 'United Overseas Bank Limited',
    name: 'United Overseas Bank Limited',
  },
  {
    id: 'Wells Fargo Bank Northwest, N.A.',
    name: 'Wells Fargo Bank Northwest, N.A.',
  },
  { id: 'Wells Fargo Bank, N.A.', name: 'Wells Fargo Bank, N.A.' },
  {
    id: 'Westpac Banking Corporation Limited',
    name: 'Westpac Banking Corporation Limited',
  },
];

export const lawyersLookup: Array<ILookup> = [
  { id: '', name: '---' },
  {
    id: 'Acuity Corporate Securities Lawyers',
    name: 'Acuity Corporate Securities Lawyers',
  },
  {
    id: 'Adams, Stepner, Woltermann & Dusing, PLLC',
    name: 'Adams, Stepner, Woltermann & Dusing, PLLC',
  },
  { id: 'Adler Silverberg PLLC', name: 'Adler Silverberg PLLC' },
  { id: 'Affinity Law Group', name: 'Affinity Law Group' },
  { id: 'AFG Law LLP', name: 'AFG Law LLP' },
  { id: 'AG Avocat Conseil Inc.', name: 'AG Avocat Conseil Inc.' },
  { id: 'Aird & Berlis LLP', name: 'Aird & Berlis LLP' },
  { id: 'Alan H. Finlayson', name: 'Alan H. Finlayson' },
  {
    id: 'Alexander Holburn Beaudin & Lang LLP',
    name: 'Alexander Holburn Beaudin & Lang LLP',
  },
  { id: 'Alianza W.J. S.A.', name: 'Alianza W.J. S.A.' },
  {
    id: 'Allen & Allen, Barristers & Solicitors',
    name: 'Allen & Allen, Barristers & Solicitors',
  },
  { id: 'Amsterdam & Partners LLP', name: 'Amsterdam & Partners LLP' },
  { id: 'Andrews Kurth Kenyon LLP', name: 'Andrews Kurth Kenyon LLP' },
  {
    id: 'Anfield Sujir Kennedy & Durno LLP',
    name: 'Anfield Sujir Kennedy & Durno LLP',
  },
  { id: 'Anik Gendron S.E.N.C.R.L.', name: 'Anik Gendron S.E.N.C.R.L.' },
  { id: 'Anthony J. Beruschi', name: 'Anthony J. Beruschi' },
  { id: 'Appleby', name: 'Appleby' },
  { id: 'Armstrong Simpson', name: 'Armstrong Simpson' },
  { id: 'Ashbaugh Beal LLP', name: 'Ashbaugh Beal LLP' },
  { id: 'Ashurst LLP', name: 'Ashurst LLP' },
  {
    id: 'Asianajotoimisto Jukka Kallio Oy',
    name: 'Asianajotoimisto Jukka Kallio Oy',
  },
  {
    id: 'Avalos y raya Abogados, S.C.',
    name: 'Avalos y raya Abogados, S.C.',
  },
  { id: 'Azevedo Sette Advogados', name: 'Azevedo Sette Advogados' },
  { id: 'Bacchus Law Corporation', name: 'Bacchus Law Corporation' },
  { id: 'Baird Hanson Williams LLP', name: 'Baird Hanson Williams LLP' },
  { id: 'Baker & McKenzie LLP', name: 'Baker & McKenzie LLP' },
  {
    id: 'Barker, Rosholt & Simpson LLP',
    name: 'Barker, Rosholt & Simpson LLP',
  },
  { id: 'Bartlet & Richardes LLP', name: 'Bartlet & Richardes LLP' },
  { id: 'BCF LLP', name: 'BCF LLP' },
  { id: 'Beach, Hepburn LLP', name: 'Beach, Hepburn LLP' },
  { id: 'Beadle Raven LLP', name: 'Beadle Raven LLP' },
  { id: 'Beard Winter LLP', name: 'Beard Winter LLP' },
  { id: 'Bell Gully', name: 'Bell Gully' },
  { id: 'Bennett Jones LLP', name: 'Bennett Jones LLP' },
  { id: 'Bereskin & Parr', name: 'Bereskin & Parr' },
  { id: 'Bernier Beaudry Inc.', name: 'Bernier Beaudry Inc.' },
  {
    id: 'Blake, Cassels & Graydon LLP',
    name: 'Blake, Cassels & Graydon LLP',
  },
  { id: 'Blaney McMurtry LLP', name: 'Blaney McMurtry LLP' },
  {
    id: 'Boivin Desbiens Senécal Attorneys',
    name: 'Boivin Desbiens Senécal Attorneys',
  },
  {
    id: 'Bond, Schoeneck & King, PLLC',
    name: 'Bond, Schoeneck & King, PLLC',
  },
  { id: 'Borden Ladner Gervais LLP', name: 'Borden Ladner Gervais LLP' },
  { id: 'Boughton Law Corporation', name: 'Boughton Law Corporation' },
  { id: 'Boyle & Co. LLP', name: 'Boyle & Co. LLP' },
  { id: 'Bracewell LLP', name: 'Bracewell LLP' },
  {
    id: 'Brito & Maia Advogados e Consultores',
    name: 'Brito & Maia Advogados e Consultores',
  },
  { id: 'Brouillette & Partners', name: 'Brouillette & Partners' },
  { id: 'Bryan & Company LLP', name: 'Bryan & Company LLP' },
  { id: 'Burness Paull LLP', name: 'Burness Paull LLP' },
  {
    id: 'Burnet, Duckworth & Palmer LLP',
    name: 'Burnet, Duckworth & Palmer LLP',
  },
  { id: 'Burstall LLP', name: 'Burstall LLP' },
  { id: 'Buttonwood Law Corporation', name: 'Buttonwood Law Corporation' },
  {
    id: 'Cain Lamarre Casgrain Wells',
    name: 'Cain Lamarre Casgrain Wells',
  },
  {
    id: 'Caravel Law Professional Corporation',
    name: 'Caravel Law Professional Corporation',
  },
  {
    id: 'Cardenas & Cardenas Abogados Ltda.',
    name: 'Cardenas & Cardenas Abogados Ltda.',
  },
  { id: 'Carscallen LLP', name: 'Carscallen LLP' },
  {
    id: 'Carter Ledyard & Milburn LLP',
    name: 'Carter Ledyard & Milburn LLP',
  },
  {
    id: 'Cassels Brock & Blackwell LLP',
    name: 'Cassels Brock & Blackwell LLP',
  },
  {
    id: 'Cawkell Brodie Glaister LLP',
    name: 'Cawkell Brodie Glaister LLP',
  },
  { id: 'CD Farber Law Corp.', name: 'CD Farber Law Corp.' },
  { id: 'Chamberlain Hutchison', name: 'Chamberlain Hutchison' },
  { id: 'Chitiz Pathak LLP', name: 'Chitiz Pathak LLP' },
  { id: 'Christopher H. Freeman', name: 'Christopher H. Freeman' },
  { id: 'Clark Wilson LLP', name: 'Clark Wilson LLP' },
  {
    id: 'Cleary Gottlieb Steen & Hamilton LLP',
    name: 'Cleary Gottlieb Steen & Hamilton LLP',
  },
  { id: 'CMJC Law Corporation', name: 'CMJC Law Corporation' },
  { id: 'Cobbetts LLP', name: 'Cobbetts LLP' },
  { id: 'Cohen LLP', name: 'Cohen LLP' },
  { id: 'Conrad C. Lysiak', name: 'Conrad C. Lysiak' },
  { id: 'Cooley LLP', name: 'Cooley LLP' },
  { id: 'Corrs Chambers Westgarth', name: 'Corrs Chambers Westgarth' },
  { id: 'Covington & Burling LLP', name: 'Covington & Burling LLP' },
  { id: 'Cox & Palmer LLP', name: 'Cox & Palmer LLP' },
  {
    id: 'Cravath, Swaine & Moore LLP',
    name: 'Cravath, Swaine & Moore LLP',
  },
  {
    id: 'Creel, García-Cuéllar y Müggenburg, S.C.',
    name: 'Creel, García-Cuéllar y Müggenburg, S.C.',
  },
  { id: 'Crowe & Dunlevy', name: 'Crowe & Dunlevy' },
  { id: 'Crowell & Moring LLP', name: 'Crowell & Moring LLP' },
  {
    id: 'David G. Ashby Law Corporation',
    name: 'David G. Ashby Law Corporation',
  },
  {
    id: 'David Smalley Law Corporation',
    name: 'David Smalley Law Corporation',
  },
  {
    id: 'Davies Ward Phillips & Vineberg LLP',
    name: 'Davies Ward Phillips & Vineberg LLP',
  },
  {
    id: 'Davis Graham & Stubbs LLP',
    name: 'Davis Graham & Stubbs LLP',
  },
  {
    id: 'Davis Polk & Wardwell LLP',
    name: 'Davis Polk & Wardwell LLP',
  },
  { id: 'Davis Wright Tremaine LLP', name: 'Davis Wright Tremaine LLP' },
  { id: 'De Grandpré Chait LLP', name: 'De Grandpré Chait LLP' },
  { id: 'DeMarco Allan LLP', name: 'DeMarco Allan LLP' },
  { id: 'Dentons Canada LLP', name: 'Dentons Canada LLP' },
  { id: 'Devry Smith Frank LLP', name: 'Devry Smith Frank LLP' },
  { id: 'DeWitt Sedun', name: 'DeWitt Sedun' },
  { id: 'Dickinson Wright LLP', name: 'Dickinson Wright LLP' },
  { id: 'Dixon Law Firm', name: 'Dixon Law Firm' },
  { id: 'DLA Piper (Canada) LLP', name: 'DLA Piper (Canada) LLP' },
  { id: 'Dorsey & Whitney LLP', name: 'Dorsey & Whitney LLP' },
  {
    id: 'Double Diamond Law Corporation',
    name: 'Double Diamond Law Corporation',
  },
  { id: 'Douglas Eacrett', name: 'Douglas Eacrett' },
  {
    id: 'Dr. K. Chrysostomides & Co LLC',
    name: 'Dr. K. Chrysostomides & Co LLC',
  },
  {
    id: 'Drysdale Bacon McStravick LLP',
    name: 'Drysdale Bacon McStravick LLP',
  },
  { id: 'DS Lawyers Canada LLP', name: 'DS Lawyers Canada LLP' },
  { id: 'DS Welch Bussières', name: 'DS Welch Bussières' },
  { id: 'Dufford & Brown, P.C.', name: 'Dufford & Brown, P.C.' },
  { id: 'DuMoulin Black LLP', name: 'DuMoulin Black LLP' },
  { id: 'Dunton Rainville LLP', name: 'Dunton Rainville LLP' },
  {
    id: 'ECS Law Professional Corporation',
    name: 'ECS Law Professional Corporation',
  },
  {
    id: 'Edwards, Kenny & Bray LLP',
    name: 'Edwards, Kenny & Bray LLP',
  },
  { id: 'Elliott Duffy Garrett', name: 'Elliott Duffy Garrett' },
  {
    id: 'Ellison Law Professional Corporation',
    name: 'Ellison Law Professional Corporation',
  },
  { id: 'Erwin & Thompson LLP', name: 'Erwin & Thompson LLP' },
  {
    id: 'Faegre Drinker Biddle & Reath LLP',
    name: 'Faegre Drinker Biddle & Reath LLP',
  },
  { id: 'Fang & Associates', name: 'Fang & Associates' },
  { id: 'Farris LLP', name: 'Farris LLP' },
  {
    id: 'Fasken Martineau DuMoulin LLP',
    name: 'Fasken Martineau DuMoulin LLP',
  },
  {
    id: 'Field Fisher Waterhouse LLP',
    name: 'Field Fisher Waterhouse LLP',
  },
  { id: 'Field LLP', name: 'Field LLP' },
  { id: 'Fillmore Riley LLP', name: 'Fillmore Riley LLP' },
  { id: 'First West Law LLP', name: 'First West Law LLP' },
  { id: 'Fogler, Rubinoff LLP', name: 'Fogler, Rubinoff LLP' },
  { id: 'Foley & Lardner LLP', name: 'Foley & Lardner LLP' },
  { id: 'Fortun Narvasa & Salazar', name: 'Fortun Narvasa & Salazar' },
  { id: 'Fraser and Company LLP', name: 'Fraser and Company LLP' },
  { id: 'Gardiner Roberts LLP', name: 'Gardiner Roberts LLP' },
  { id: 'Garfinkle Biderman LLP', name: 'Garfinkle Biderman LLP' },
  { id: 'Genesis Law Corporation', name: 'Genesis Law Corporation' },
  {
    id: 'Gerald R. Tuskey, Personal Law Corporation',
    name: 'Gerald R. Tuskey, Personal Law Corporation',
  },
  { id: 'Getz Prince Wells LLP', name: 'Getz Prince Wells LLP' },
  { id: 'Giffen & Partners', name: 'Giffen & Partners' },
  { id: 'Goodland Buckingham', name: 'Goodland Buckingham' },
  { id: 'Goodmans LLP', name: 'Goodmans LLP' },
  { id: 'Goodwin Procter LLP', name: 'Goodwin Procter LLP' },
  {
    id: 'Gordon J. Fretwell Law Corporation',
    name: 'Gordon J. Fretwell Law Corporation',
  },
  { id: 'Gowling WLG (Canada) LLP', name: 'Gowling WLG (Canada) LLP' },
  { id: 'Greenberg Traurig LLP', name: 'Greenberg Traurig LLP' },
  {
    id: 'Gregory S. Yanke Law Corporation',
    name: 'Gregory S. Yanke Law Corporation',
  },
  {
    id: 'Gregory T. Chu, A Law Corporation',
    name: 'Gregory T. Chu, A Law Corporation',
  },
  { id: 'H.D. Bossau & Co.', name: 'H.D. Bossau & Co.' },
  { id: 'Harder & Company', name: 'Harder & Company' },
  { id: 'Harper Grey Easton', name: 'Harper Grey Easton' },
  { id: 'Harper Grey LLP', name: 'Harper Grey LLP' },
  { id: 'Harris + Harris LLP', name: 'Harris + Harris LLP' },
  { id: 'Heighington Law Firm', name: 'Heighington Law Firm' },
  { id: 'Heiras y Asociados, S.C.', name: 'Heiras y Asociados, S.C.' },
  { id: 'Hemsworth Schmidt', name: 'Hemsworth Schmidt' },
  {
    id: 'Hogan Lovells International LLP',
    name: 'Hogan Lovells International LLP',
  },
  { id: 'Hogan Lovells US LLP', name: 'Hogan Lovells US LLP' },
  { id: 'Holland & Knight LLP', name: 'Holland & Knight LLP' },
  { id: 'Holmes & King LLP', name: 'Holmes & King LLP' },
  { id: 'HopgoodGanim Lawyers', name: 'HopgoodGanim Lawyers' },
  { id: 'Irwin Lowy LLP', name: 'Irwin Lowy LLP' },
  { id: 'James L. Harris Law Corp.', name: 'James L. Harris Law Corp.' },
  {
    id: 'Jeffrey T.K. Fraser Law Corporation',
    name: 'Jeffrey T.K. Fraser Law Corporation',
  },
  { id: 'Jessome Law', name: 'Jessome Law' },
  { id: 'Jones Day LLP', name: 'Jones Day LLP' },
  { id: 'Julie Godard', name: 'Julie Godard' },
  { id: 'K MacInnes Law Group', name: 'K MacInnes Law Group' },
  { id: 'K&L Gates LLP', name: 'K&L Gates LLP' },
  { id: 'Kaminsky & Company', name: 'Kaminsky & Company' },
  { id: 'Kavanagh Bateman LLP', name: 'Kavanagh Bateman LLP' },
  {
    id: 'Kerr, Russell & Weber, PLC',
    name: 'Kerr, Russell & Weber, PLC',
  },
  { id: 'Kirkland & Ellis LLP', name: 'Kirkland & Ellis LLP' },
  {
    id: 'Kirsh Securities Law Professional Corporation',
    name: 'Kirsh Securities Law Professional Corporation',
  },
  {
    id: 'Kjeld Werbes Law Corporation',
    name: 'Kjeld Werbes Law Corporation',
  },
  { id: 'Koffman Kalef LLP', name: 'Koffman Kalef LLP' },
  { id: 'Kutkevicius Kirsh, LLP', name: 'Kutkevicius Kirsh, LLP' },
  { id: 'LaBarge Weinstein LLP', name: 'LaBarge Weinstein LLP' },
  {
    id: 'Langlois Kronström Desjardins, LLP',
    name: 'Langlois Kronström Desjardins, LLP',
  },
  {
    id: 'Larios, Rodriguez Del Bosque, De Buen y Comu',
    name: 'Larios, Rodriguez Del Bosque, De Buen y Comu',
  },
  { id: 'Latham & Watkins LLP', name: 'Latham & Watkins LLP' },
  { id: 'Lavery, de Billy LLP', name: 'Lavery, de Billy LLP' },
  { id: 'Lawson Lundell LLP', name: 'Lawson Lundell LLP' },
  {
    id: 'Leschert & Company Law Corporation',
    name: 'Leschert & Company Law Corporation',
  },
  { id: 'Lexas Law Group', name: 'Lexas Law Group' },
  { id: 'Lotz & Company', name: 'Lotz & Company' },
  {
    id: 'LV Conseils Institutionnels Inc.',
    name: 'LV Conseils Institutionnels Inc.',
  },
  { id: 'Lynch & Mahoney', name: 'Lynch & Mahoney' },
  {
    id: 'MacDonald Tuskey Corporate & Securities Lawyers',
    name: 'MacDonald Tuskey Corporate & Securities Lawyers',
  },
  { id: 'MacLeod & Company LLP', name: 'MacLeod & Company LLP' },
  { id: 'MacNeill Law', name: 'MacNeill Law' },
  { id: 'Maitland & Company', name: 'Maitland & Company' },
  {
    id: 'Martin & Associates Law Corporation',
    name: 'Martin & Associates Law Corporation',
  },
  {
    id: 'Max Pinsky Personal Law Corporation',
    name: 'Max Pinsky Personal Law Corporation',
  },
  { id: 'Maxis Law Corporation', name: 'Maxis Law Corporation' },
  { id: 'Mayer Brown LLP', name: 'Mayer Brown LLP' },
  { id: 'McCarthy Tétrault LLP', name: 'McCarthy Tétrault LLP' },
  { id: 'McClusky & Dalling', name: 'McClusky & Dalling' },
  {
    id: "McCullough O'Connor Irwin LLP",
    name: "McCullough O'Connor Irwin LLP",
  },
  { id: 'McDougall Gauley LLP', name: 'McDougall Gauley LLP' },
  { id: 'McInnes Cooper', name: 'McInnes Cooper' },
  { id: 'McKenzie Lake Lawyers LLP', name: 'McKenzie Lake Lawyers LLP' },
  { id: 'McKercher LLP', name: 'McKercher LLP' },
  { id: 'McLennan Ross LLP', name: 'McLennan Ross LLP' },
  { id: 'McLeod Law LLP', name: 'McLeod Law LLP' },
  { id: 'McMillan LLP', name: 'McMillan LLP' },
  { id: 'McPhadden Samac Tuovi LLP', name: 'McPhadden Samac Tuovi LLP' },
  {
    id: 'Ménard Mageau Valiquette S.E.N.C.',
    name: 'Ménard Mageau Valiquette S.E.N.C.',
  },
  { id: 'Meretsky Law Firm', name: 'Meretsky Law Firm' },
  { id: 'Michael Woods Law Corp.', name: 'Michael Woods Law Corp.' },
  { id: 'Miles Davison LLP', name: 'Miles Davison LLP' },
  { id: 'Miller Thomson LLP', name: 'Miller Thomson LLP' },
  { id: 'Minden Gross LLP', name: 'Minden Gross LLP' },
  { id: 'MLT Aikins LLP', name: 'MLT Aikins LLP' },
  { id: 'Moodys Tax Law LLP', name: 'Moodys Tax Law LLP' },
  { id: 'Morgan Lewis LLP', name: 'Morgan Lewis LLP' },
  {
    id: 'Morris McManus Professional Corporation',
    name: 'Morris McManus Professional Corporation',
  },
  { id: 'Morton Law, LLP', name: 'Morton Law, LLP' },
  { id: 'Mourant Ozannes', name: 'Mourant Ozannes' },
  { id: 'Neil J.F. Steenberg', name: 'Neil J.F. Steenberg' },
  { id: 'Nerland Lindsey LLP', name: 'Nerland Lindsey LLP' },
  {
    id: 'Newhouse Strategic Counsel Inc.',
    name: 'Newhouse Strategic Counsel Inc.',
  },
  { id: 'Nixon Peabody LLP', name: 'Nixon Peabody LLP' },
  { id: 'Northwest Law Group', name: 'Northwest Law Group' },
  {
    id: 'Norton Rose Fulbright Canada LLP',
    name: 'Norton Rose Fulbright Canada LLP',
  },
  { id: 'Norton Rose Fulbright LLP', name: 'Norton Rose Fulbright LLP' },
  { id: "O'Neill & Company", name: "O'Neill & Company" },
  { id: 'Ogilvie LLP', name: 'Ogilvie LLP' },
  {
    id: 'Olshan Grundman Frome & Rosenzweig LLP',
    name: 'Olshan Grundman Frome & Rosenzweig LLP',
  },
  { id: 'Ormston List Frawley LLP', name: 'Ormston List Frawley LLP' },
  {
    id: 'Osler, Hoskin & Harcourt LLP',
    name: 'Osler, Hoskin & Harcourt LLP',
  },
  { id: 'Owen Bird Law Corporation', name: 'Owen Bird Law Corporation' },
  { id: 'Owens, Wright LLP', name: 'Owens, Wright LLP' },
  { id: 'Parlee McLaws LLP', name: 'Parlee McLaws LLP' },
  { id: 'Parsons Behle & Latimer', name: 'Parsons Behle & Latimer' },
  { id: 'Partner Law Firm', name: 'Partner Law Firm' },
  {
    id: 'Paul, Weiss, Rifkind, Wharton & Garrison LLP',
    name: 'Paul, Weiss, Rifkind, Wharton & Garrison LLP',
  },
  {
    id: 'Perley-Robertson, Hill & McDougall LLP',
    name: 'Perley-Robertson, Hill & McDougall LLP',
  },
  { id: 'Peterson & Company LLP', name: 'Peterson & Company LLP' },
  {
    id: 'Peterson Law Professional Corporation',
    name: 'Peterson Law Professional Corporation',
  },
  { id: 'Peterson McVicar LLP', name: 'Peterson McVicar LLP' },
  { id: 'Piper Alderman', name: 'Piper Alderman' },
  { id: 'Polsinelli PC', name: 'Polsinelli PC' },
  { id: 'Preston Law', name: 'Preston Law' },
  { id: 'Proskauer Rose LLP', name: 'Proskauer Rose LLP' },
  { id: 'ProVenture Law LLP', name: 'ProVenture Law LLP' },
  {
    id: 'Purdy Law Professional Corporation',
    name: 'Purdy Law Professional Corporation',
  },
  { id: 'Pushor Mitchell LLP', name: 'Pushor Mitchell LLP' },
  { id: 'Quarles & Brady LLP', name: 'Quarles & Brady LLP' },
  {
    id: 'Quebec Mining Law Corporation',
    name: 'Quebec Mining Law Corporation',
  },
  { id: 'RB Abogados', name: 'RB Abogados' },
  { id: 'Renno & Co.', name: 'Renno & Co.' },
  { id: 'Rhoades McKee PC', name: 'Rhoades McKee PC' },
  {
    id: 'Richard JS Rainey Law Corporation',
    name: 'Richard JS Rainey Law Corporation',
  },
  { id: 'Richards Buell Sutton LLP', name: 'Richards Buell Sutton LLP' },
  { id: 'Ricketts, Harris LLP', name: 'Ricketts, Harris LLP' },
  { id: 'Robert D. Matheson', name: 'Robert D. Matheson' },
  { id: 'Robert M. Isles', name: 'Robert M. Isles' },
  { id: 'Robertson Neil, LLP', name: 'Robertson Neil, LLP' },
  { id: 'ROBIC, LLP', name: 'ROBIC, LLP' },
  { id: 'Ropes & Gray LLP', name: 'Ropes & Gray LLP' },
  {
    id: 'Ryan, Swanson & Cleveland, PLLC',
    name: 'Ryan, Swanson & Cleveland, PLLC',
  },
  {
    id: 'S. Paul Simpson Law Corporation',
    name: 'S. Paul Simpson Law Corporation',
  },
  { id: 'Salans LLP', name: 'Salans LLP' },
  {
    id: 'Salazar & Asociados Abogados S.A.',
    name: 'Salazar & Asociados Abogados S.A.',
  },
  {
    id: 'Salley Bowes Harwardt Law Corp.',
    name: 'Salley Bowes Harwardt Law Corp.',
  },
  { id: 'Sangra Moller LLP', name: 'Sangra Moller LLP' },
  {
    id: 'Saravia Frias & Cornejo Abogados',
    name: 'Saravia Frias & Cornejo Abogados',
  },
  { id: 'Schiff Hardin & Waite', name: 'Schiff Hardin & Waite' },
  {
    id: 'Schmidt, Valois, Miranda, Ferreira e Agel',
    name: 'Schmidt, Valois, Miranda, Ferreira e Agel',
  },
  {
    id: 'Seed Intellectual Property Law Group PLLC',
    name: 'Seed Intellectual Property Law Group PLLC',
  },
  { id: 'Sefton Fross', name: 'Sefton Fross' },
  { id: 'Segev LLP', name: 'Segev LLP' },
  {
    id: 'Séguin Racine Attorneys Ltd.',
    name: 'Séguin Racine Attorneys Ltd.',
  },
  { id: 'Seward & Kissel LLP', name: 'Seward & Kissel LLP' },
  { id: 'Shearman & Sterling LLP', name: 'Shearman & Sterling LLP' },
  {
    id: 'Sheldon Huxtable Professional Corporation',
    name: 'Sheldon Huxtable Professional Corporation',
  },
  { id: 'Shelley McDonald Paralegal', name: 'Shelley McDonald Paralegal' },
  {
    id: 'Sichenzia Ross Friedman Ference LLP',
    name: 'Sichenzia Ross Friedman Ference LLP',
  },
  { id: 'Sidley Austin LLP', name: 'Sidley Austin LLP' },
  {
    id: 'Silva Martins, Vilas Boas, Lopes e Frattari Advogados',
    name: 'Silva Martins, Vilas Boas, Lopes e Frattari Advogados',
  },
  {
    id: 'Skadden, Arps, Slate, Meagher & Flom LLP',
    name: 'Skadden, Arps, Slate, Meagher & Flom LLP',
  },
  { id: 'Socium Law', name: 'Socium Law' },
  {
    id: 'Steenberglaw Professional Corporation',
    name: 'Steenberglaw Professional Corporation',
  },
  { id: 'Stein Monast LLP', name: 'Stein Monast LLP' },
  {
    id: 'Steve Veitch Law Corporation',
    name: 'Steve Veitch Law Corporation',
  },
  { id: 'Stewart McKelvey LLP', name: 'Stewart McKelvey LLP' },
  { id: 'Stikeman Elliott LLP', name: 'Stikeman Elliott LLP' },
  {
    id: 'Stikeman Keeley Spiegel LLP',
    name: 'Stikeman Keeley Spiegel LLP',
  },
  { id: 'Stoel Rives LLP', name: 'Stoel Rives LLP' },
  { id: 'Sui & Company', name: 'Sui & Company' },
  { id: 'Sullivan & Cromwell LLP', name: 'Sullivan & Cromwell LLP' },
  {
    id: 'Sylvestre & Associés Avocats S.E.N.C.',
    name: 'Sylvestre & Associés Avocats S.E.N.C.',
  },
  { id: 'Taylor McCaffrey LLP', name: 'Taylor McCaffrey LLP' },
  { id: 'Taylor Veinotte Sullivan', name: 'Taylor Veinotte Sullivan' },
  { id: 'Thibeault Joyal', name: 'Thibeault Joyal' },
  { id: 'Thomas J. Kennedy', name: 'Thomas J. Kennedy' },
  { id: 'Thomas, Rondeau LLP', name: 'Thomas, Rondeau LLP' },
  {
    id: 'Thompson Dorfman Sweatman LLP',
    name: 'Thompson Dorfman Sweatman LLP',
  },
  { id: 'Thorsteinssons LLP', name: 'Thorsteinssons LLP' },
  {
    id: 'Tiffany & Company Law Corp.',
    name: 'Tiffany & Company Law Corp.',
  },
  { id: 'TingleMerrett LLP', name: 'TingleMerrett LLP' },
  { id: 'Torkin Manes LLP', name: 'Torkin Manes LLP' },
  { id: 'Torys LLP', name: 'Torys LLP' },
  { id: 'Travers Smith LLP', name: 'Travers Smith LLP' },
  { id: 'Tripp Business Law LLP', name: 'Tripp Business Law LLP' },
  { id: 'TRL Law Corporation', name: 'TRL Law Corporation' },
  {
    id: 'Troutman Pepper Hamilton Sanders LLP',
    name: 'Troutman Pepper Hamilton Sanders LLP',
  },
  { id: 'Trowers & Hamlins LLP', name: 'Trowers & Hamlins LLP' },
  { id: 'TroyGould PC', name: 'TroyGould PC' },
  { id: 'Tupper Jonsson & Yeadon', name: 'Tupper Jonsson & Yeadon' },
  { id: 'Vantage Law Corporation', name: 'Vantage Law Corporation' },
  {
    id: 'Vector Corporate Finance Lawyers',
    name: 'Vector Corporate Finance Lawyers',
  },
  { id: 'Venex Law Corporation', name: 'Venex Law Corporation' },
  { id: 'Venture Law Corporation', name: 'Venture Law Corporation' },
  { id: 'Vinson & Elkins LLP', name: 'Vinson & Elkins LLP' },
  {
    id: 'W.L. Macdonald Law Corporation',
    name: 'W.L. Macdonald Law Corporation',
  },
  { id: 'Warren Benson Amantea LLP', name: 'Warren Benson Amantea LLP' },
  { id: 'Warshaw Burstein, LLP', name: 'Warshaw Burstein, LLP' },
  { id: 'Watson Goepel LLP', name: 'Watson Goepel LLP' },
  {
    id: 'Weil, Gotshal & Manges LLP',
    name: 'Weil, Gotshal & Manges LLP',
  },
  { id: 'WeirFoulds LLP', name: 'WeirFoulds LLP' },
  { id: 'Wildeboer Dellelce LLP', name: 'Wildeboer Dellelce LLP' },
  { id: 'William Freire Advogados', name: 'William Freire Advogados' },
  { id: 'Wilson Laycraft', name: 'Wilson Laycraft' },
  {
    id: 'Wilson Sonsini Goodrich & Rosati Professional Corporation',
    name: 'Wilson Sonsini Goodrich & Rosati Professional Corporation',
  },
  { id: 'Wolf Theiss', name: 'Wolf Theiss' },
  { id: 'Wolff Leia', name: 'Wolff Leia' },
  { id: 'Woods & Company', name: 'Woods & Company' },
  { id: 'XploraMines S.A.', name: 'XploraMines S.A.' },
];

export const transferAgentLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'Alliance Trust Company', name: 'Alliance Trust Company' },
  {
    id: 'American Stock Transfer & Trust Company, LLC',
    name: 'American Stock Transfer & Trust Company, LLC',
  },
  { id: 'AST Trust Company (Canada)', name: 'AST Trust Company (Canada)' },
  {
    id: 'Bermuda Trust Company (Cook Islands) Limited',
    name: 'Bermuda Trust Company (Cook Islands) Limited',
  },
  {
    id: 'Capital Transfer Agency Inc.',
    name: 'Capital Transfer Agency Inc.',
  },
  { id: 'CAVALI ICLV S.A.', name: 'CAVALI ICLV S.A.' },
  { id: 'CIBC Mellon Trust Company', name: 'CIBC Mellon Trust Company' },
  { id: 'ClearTrust LLC', name: 'ClearTrust LLC' },
  { id: 'Computershare AB', name: 'Computershare AB' },
  {
    id: 'Computershare Hong Kong Investor Services Limited',
    name: 'Computershare Hong Kong Investor Services Limited',
  },
  {
    id: 'Computershare Investor Services (Ireland) Ltd.',
    name: 'Computershare Investor Services (Ireland) Ltd.',
  },
  {
    id: 'Computershare Investor Services (Jersey) Limited',
    name: 'Computershare Investor Services (Jersey) Limited',
  },
  {
    id: 'Computershare Investor Services 2004 (Proprietary) Ltd.',
    name: 'Computershare Investor Services 2004 (Proprietary) Ltd.',
  },
  {
    id: 'Computershare Investor Services Inc.',
    name: 'Computershare Investor Services Inc.',
  },
  {
    id: 'Computershare Investor Services plc',
    name: 'Computershare Investor Services plc',
  },
  {
    id: 'Computershare Investor Services Pty Limited',
    name: 'Computershare Investor Services Pty Limited',
  },
  {
    id: 'Computershare Investor Services, LLC',
    name: 'Computershare Investor Services, LLC',
  },
  {
    id: 'Computershare Shareholder Services, Inc.',
    name: 'Computershare Shareholder Services, Inc.',
  },
  {
    id: 'Computershare Shareowner Services LLC',
    name: 'Computershare Shareowner Services LLC',
  },
  {
    id: 'Computershare Trust Company of Canada Inc.',
    name: 'Computershare Trust Company of Canada Inc.',
  },
  {
    id: 'Computershare Trust Company, Inc.',
    name: 'Computershare Trust Company, Inc.',
  },
  {
    id: 'Computershare Trust Company, N.A.',
    name: 'Computershare Trust Company, N.A.',
  },
  {
    id: 'Continental Stock Transfer & Trust Company',
    name: 'Continental Stock Transfer & Trust Company',
  },
  {
    id: 'Corporate Stock Transfer, Inc.',
    name: 'Corporate Stock Transfer, Inc.',
  },
  { id: 'DnB NOR Bank ASA', name: 'DnB NOR Bank ASA' },
  { id: 'Equiniti Limited', name: 'Equiniti Limited' },
  { id: 'Euroclear Sweden AB', name: 'Euroclear Sweden AB' },
  { id: 'Globex Transfer, LLC', name: 'Globex Transfer, LLC' },
  { id: 'Heritage Trust Company', name: 'Heritage Trust Company' },
  { id: 'Integral Transfer Agency', name: 'Integral Transfer Agency' },
  { id: 'Island Stock Transfer LLC', name: 'Island Stock Transfer LLC' },
  { id: 'JPMorgan Chase Bank, N.A.', name: 'JPMorgan Chase Bank, N.A.' },
  { id: 'Link Asset Services', name: 'Link Asset Services' },
  {
    id: 'Link Market Services Limited',
    name: 'Link Market Services Limited',
  },
  {
    id: 'Mackenzie Financial Corporation',
    name: 'Mackenzie Financial Corporation',
  },
  {
    id: 'Marrelli Transfer Services Corp.',
    name: 'Marrelli Transfer Services Corp.',
  },
  {
    id: 'Marrelli Trust Company Limited',
    name: 'Marrelli Trust Company Limited',
  },
  {
    id: 'Middlefield Capital Corporation',
    name: 'Middlefield Capital Corporation',
  },
  {
    id: 'Mountain Share Transfer, LLC',
    name: 'Mountain Share Transfer, LLC',
  },
  {
    id: 'National Securities Administrators Ltd.',
    name: 'National Securities Administrators Ltd.',
  },
  { id: 'Odyssey Trust Company', name: 'Odyssey Trust Company' },
  {
    id: 'Olde Monmouth Stock Transfer Co., Inc.',
    name: 'Olde Monmouth Stock Transfer Co., Inc.',
  },
  { id: 'Olympia Trust Company', name: 'Olympia Trust Company' },
  {
    id: 'Pacific Stock Transfer Company',
    name: 'Pacific Stock Transfer Company',
  },
  {
    id: 'Philadelphia Stock Transfer, Inc.',
    name: 'Philadelphia Stock Transfer, Inc.',
  },
  {
    id: 'Reliable Stock Transfer Inc.',
    name: 'Reliable Stock Transfer Inc.',
  },
  {
    id: 'Rizal Commercial Banking Corporation',
    name: 'Rizal Commercial Banking Corporation',
  },
  {
    id: 'Securities Transfer Corporation',
    name: 'Securities Transfer Corporation',
  },
  {
    id: 'Security Transfer Registrars Pty Ltd.',
    name: 'Security Transfer Registrars Pty Ltd.',
  },
  {
    id: 'Signature Stock Transfer, Inc.',
    name: 'Signature Stock Transfer, Inc.',
  },
  {
    id: 'The Canadian Depository for Securities Limited',
    name: 'The Canadian Depository for Securities Limited',
  },
  { id: 'Trans Canada Transfer Inc.', name: 'Trans Canada Transfer Inc.' },
  { id: 'Transhare Corporation', name: 'Transhare Corporation' },
  {
    id: 'Tricor Investor Services Limited',
    name: 'Tricor Investor Services Limited',
  },
  { id: 'TSX Trust Company', name: 'TSX Trust Company' },
  { id: 'Valiant Trust Company', name: 'Valiant Trust Company' },
  { id: 'VStock Transfer. LLC', name: 'VStock Transfer. LLC' },
  { id: 'Wells Fargo Bank, N.A.', name: 'Wells Fargo Bank, N.A.' },
];

export const legalRecordTypeLookup: Array<ILookup> = [
  { id: 'LBM', name: 'All' },
  { id: 'L', name: 'Legal' },
  { id: 'B', name: 'Bankruptcy' },
  { id: 'M', name: 'Miescellaneous' },
];

export const sicCodeTypeLookup: Array<OLookup> = [
  { value: '1',	label: 'Primary SIC of company' },
  { value: '0',	label: 'All SICs of company' },
];
export const naicsCodeTypeLookup: Array<OLookup> = [
  { value: '1',	label: 'Primary NAICS of company' },
  { value: '0',	label: 'All NAICS of company' },
];

export const contactTypeSearchLookup: Array<OLookup> = [
  { value: '1',	label: 'Head Office' },
  { value: '2',	label: 'Executive Office' },
  { value: '3',	label: 'Investor Relations' },
  { value: '4',	label: 'FP 500' },
];

export const searchTypePredecessorLookup: Array<OLookup> = [
  { value: 'phrase',	label: 'Exact Phrase' },
  { value: 'all',	label: 'All Words' },
];

export const searchTypeMnaLookup: Array<OLookup> = [
  { value: 'E',	label: 'Exact Phrase' },
  { value: 'A',	label: 'All Words' },
];

export const historyMatchTypeLookup: Array<OLookup> = [
  { value: 'exactphrase',	label: 'Exact Phrase' },
  { value: 'allwords',	label: 'All Words' },
];

export const legalTypeLookup: Array<OLookup> = [
  { value: 'plaintiffs',	label: 'Plaintiffs' },
  { value: 'defendants',	label: 'Defendants' },
  { value: 'both',	label: 'Both' },
];
export const sortOptionLookup: Array<OLookup> = [
  { value: 'p',	label: 'Sort by Plaintiff' },
  { value: 'd',	label: 'Sort by Defendant' },
  { value: 'f',	label: 'Sort by Filed Date' },
];

export const leagueTablesLookup: Array<OLookup> = [
  { value: 'y',	label: 'Included' },
  { value: 'n',	label: 'Excluded' },
  { value: 'b',	label: 'Both' },
];

export const leagueCreditTypeLookup: Array<OLookup> = [
  { value: 'fc',	label: 'Full credit bookrunner' },
  { value: 'bc',	label: 'Bonus credit bookrunner' },
  { value: 'li',	label: 'Legal counsel to issuer' },
  { value: 'lu',	label: 'Legal counsel to underwriter' },
];

export const leagueCountByLookup: Array<OLookup> = [
  { value: 'd',	label: 'Deals' },
  { value: 't',	label: 'Tranches' },
];

export const statusDatesTypeLookup: Array<OLookup> = [
  { value: 'N',	label: 'Announced' },
  { value: 'R',	label: 'Created' },
  { value: 'U',	label: 'Updated' },
  { value: 'P',	label: 'Preliminary' },
  { value: 'C',	label: 'Completed' },
  { value: 'W',	label: 'Withdrawn' },
  { value: 'A',	label: 'All' },
];

export const distributionTypeLookup: Array<OLookup> = [
  { value: '1',	label: 'Treasury / Primary' },
  { value: '2',	label: 'Secondary Shareholder' },
  { value: 'b',	label: 'Both' },
];

export const securityTypesLookup: Array<OLookup> = [
  { value: 'all',	label: 'All (Equity + Debt)' },
  { value: 'choose',	label: 'Show me a list of security types' },
];

export const securityConvertibleDebtLookup: Array<OLookup> = [
  { value: 'exclude',	label: 'Trust Unit only' },
  { value: 'include',	label: 'Convertible Debt only' },
  { value: 'both',	label: 'Both' },
];

export const organizationTypesLookup: Array<ILookup> = [
  { id: '', name: 'All' },
  { id: '1', name: 'Athletic and Sports' },
  { id: '2', name: 'Cultural' },
  { id: '3', name: 'Educational' },
  {
    id: '4',
    name: 'Engineering, Technological, and Natural and Social Sciences',
  },
  { id: '5', name: 'Environmental and Agricultural' },
  { id: '6', name: 'Fraternal, Nationality, and Ethnic' },
  { id: '7', name: 'Health and Medical' },
  { id: '8', name: 'Hobby and Avocational' },
  { id: '9', name: 'Labour Unions, Associations, and Federations' },
  {
    id: '10',
    name: 'Legal, Governmental, Public Administration, and Military',
  },
  { id: '11', name: 'Public Affairs' },
  { id: '12', name: 'Religious' },
  { id: '13', name: 'Social Welfare' },
  { id: '14', name: 'Trade, Business, and Commercial' },
  { id: '15', name: 'Veterans, Hereditary, and Patriotic' },
];

export const corporateGovernmentLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'gov_no', name: 'All Corporate' },
  { id: 'gov_all', name: "All Gov't Related" },
  {
    id: 'gov__1',
    name: 'Federal Government',
  },
  {
    id: 'gov__2',
    name: 'Federal Agency',
  },
  {
    id: 'gov__3',
    name: 'Federal Crown Corporation',
  },
  {
    id: 'gov__11',
    name: 'Provincial Government',
  },
  {
    id: 'gov__12',
    name: 'Provincial Agency',
  },
  {
    id: 'gov__13',
    name: 'Provincial Crown Corporation',
  },
  {
    id: 'gov__21',
    name: 'Municipal Government',
  },
];

export const offeringProcedureLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'public', name: 'Public Offerings Only' },
  { id: 'ipo', name: 'Initial Public Offering Only' },
  {
    id: 'flw_on',
    name: 'Public Offering Follow On / Secondary Only',
  },
  { id: 'priv', name: 'Private Placement Only' },
  { id: 'special', name: 'Special Warrants Only' },
  { id: 'shelf', name: 'Base Shelf Offerings Only' },
];

export const termsOfIssueLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'pi', name: 'Public Issue' },
  { id: 'pp', name: 'Private Placement' },
];

export const termsLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'U', name: 'Underwritten' },
  { id: 'D', name: 'Underwritten Bought only' },
  {
    id: 'F',
    name: 'Underwritten Marketed including Overnight marketed',
  },
  {
    id: 'G',
    name: 'Underwritten Marketed only',
  },
  {
    id: 'O',
    name: 'Underwritten Overnight marketed only',
  },
  { id: 'B', name: 'Agency - Best efforts' },
];

export const marketLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'C', name: 'Canada' },
  { id: 'U', name: 'United States' },
  { id: 'N', name: 'Cross Border (Canada and U.S.)' },
  { id: 'C|N', name: 'Canada or Cross Border (Canada and U.S.)' },
  { id: 'K', name: 'United Kingdom' },
  { id: 'E', name: 'Europe' },
  { id: 'G', name: 'Global (Canada, U.S. and Europe)' },
];

export const currencyLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'Australian dollar' },
  { id: '2', name: 'Bahamian dollar' },
  { id: '32', name: 'Brazilian real' },
  { id: '4', name: 'British pound' },
  { id: '5', name: 'Canadian dollar' },
  { id: '6', name: 'Cayman Islands dollar' },
  { id: '35', name: 'Chinese yuan (offshore)' },
  { id: '28', name: 'Chinese yuan (onshore)' },
  { id: '31', name: 'Colombian peso' },
  { id: '7', name: 'Czech koruna' },
  { id: '8', name: 'Danish krone' },
  { id: '10', name: 'Euro' },
  { id: '13', name: 'Hong Kong dollar' },
  { id: '29', name: 'Icelandic krona' },
  { id: '37', name: 'Indian rupee' },
  { id: '33', name: 'Israeli new shekel' },
  { id: '16', name: 'Japanese yen' },
  { id: '27', name: 'Mexican peso' },
  { id: '18', name: 'New Zealand dollar' },
  { id: '22', name: 'Norwegian krone' },
  { id: '34', name: 'Polish zloty' },
  { id: '36', name: 'Singapore dollar' },
  { id: '26', name: 'South African rand' },
  { id: '30', name: 'South Korean won' },
  { id: '19', name: 'Swedish krona' },
  { id: '20', name: 'Swiss franc' },
  { id: '25', name: 'Turkish lira' },
  { id: '21', name: 'United States dollar' },
];

export const exchangesNewIssuesLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '99', name: 'All Canadian Exchanges' },
  { id: '999', name: 'All US Exchanges' },
  { id: '1', name: 'Toronto Stock Exchange' },
  { id: '3', name: 'TSX Venture Exchange' },
  { id: '5', name: 'Aequitas NEO Exchange' },
  { id: '6', name: 'Canadian Securities Exchange' },
  { id: '8', name: 'New York Stock Exchange' },
  { id: '9', name: 'NYSE MKT' },
  { id: '10', name: 'NASDAQ Stock Market' },
];

export const incorporationLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '999', name: 'All foreign' },
  { id: '99', name: 'Canada (Federal+Provincial)' },
  { id: '1', name: 'Canada (Federal)' },
  { id: '3', name: 'Alberta' },
  { id: '4', name: 'British Columbia' },
  { id: '5', name: 'Manitoba' },
  { id: '6', name: 'New Brunswick' },
  { id: '7', name: 'Newfoundland and Labrador' },
  { id: '8', name: 'Northwest Territories' },
  { id: '9', name: 'Nova Scotia' },
  { id: '128', name: 'Nunavut' },
  { id: '10', name: 'Ontario' },
  { id: '11', name: 'Prince Edward Island' },
  { id: '12', name: 'Quebec' },
  { id: '13', name: 'Saskatchewan' },
  { id: '14', name: 'Yukon' },
];
export const jurisdictionLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'Canada' },
  { id: '2', name: 'Canada - unspecified' },
  { id: '3', name: 'Alberta' },
  { id: '4', name: 'British Columbia' },
  { id: '5', name: 'Manitoba' },
  { id: '6', name: 'New Brunswick' },
  { id: '7', name: 'Newfoundland and Labrador' },
  { id: '9', name: 'Nova Scotia' },
  { id: '10', name: 'Ontario' },
  { id: '12', name: 'Quebec' },
  { id: '13', name: 'Saskatchewan' },
  { id: '14', name: 'Yukon' },
  { id: '21', name: 'California' },
  { id: '22', name: 'Colorado' },
  { id: '24', name: 'Delaware' },
  { id: '26', name: 'Florida' },
  { id: '46', name: 'Nevada' },
  { id: '55', name: 'Pennsylvania' },
  { id: '65', name: 'Washington' },
  { id: '68', name: 'Wyoming' },
  { id: '139', name: 'Anguilla' },
  { id: '70', name: 'Australia' },
  { id: '75', name: 'Bahamas' },
  { id: '117', name: 'Barbados' },
  { id: '77', name: 'Bermuda' },
  { id: '79', name: 'British Virgin Islands' },
  { id: '81', name: 'Cayman Islands' },
  { id: '83', name: 'Colombia' },
  { id: '144', name: 'Cyprus' },
  { id: '88', name: 'France' },
  { id: '91', name: 'Hong Kong' },
  { id: '145', name: 'Isle of Man' },
  { id: '93', name: 'Israel' },
  { id: '127', name: 'Jersey' },
  { id: '100', name: 'Netherlands' },
  { id: '122', name: 'Singapore' },
  { id: '113', name: 'United Kingdom' },
  { id: '116', name: 'unknown' },
];
export const incorporationLocationLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '1', name: 'Canada' },
  { id: '2', name: 'Canada - unspecified' },
  { id: '3', name: 'Alberta' },
  { id: '4', name: 'British Columbia' },
  { id: '5', name: 'Manitoba' },
  { id: '6', name: 'New Brunswick' },
  { id: '7', name: 'Newfoundland and Labrador' },
  { id: '9', name: 'Nova Scotia' },
  { id: '10', name: 'Ontario' },
  { id: '12', name: 'Quebec' },
  { id: '13', name: 'Saskatchewan' },
  { id: '14', name: 'Yukon' },
  { id: '21', name: 'California' },
  { id: '22', name: 'Colorado' },
  { id: '24', name: 'Delaware' },
  { id: '26', name: 'Florida' },
  { id: '46', name: 'Nevada' },
  { id: '55', name: 'Pennsylvania' },
  { id: '65', name: 'Washington' },
  { id: '68', name: 'Wyoming' },
  { id: '139', name: 'Anguilla' },
  { id: '70', name: 'Australia' },
  { id: '75', name: 'Bahamas' },
  { id: '117', name: 'Barbados' },
  { id: '77', name: 'Bermuda' },
  { id: '79', name: 'British Virgin Islands' },
  { id: '81', name: 'Cayman Islands' },
  { id: '84', name: 'Cook Islands' },
  { id: '144', name: 'Cyprus' },
  { id: '88', name: 'France' },
  { id: '91', name: 'Hong Kong' },
  { id: '145', name: 'Isle of Man' },
  { id: '93', name: 'Israel' },
  { id: '127', name: 'Jersey' },
  { id: '98', name: 'Luxembourg' },
  { id: '100', name: 'Netherlands' },
  { id: '113', name: 'United Kingdom' },
  { id: '116', name: 'unknown' },
];
export const typeLookup: Array<ILookup> = [
  { id: '', name: 'Company Type' },
  { id: 'all', name: 'All Trusts' },
  { id: '1', name: 'Business Trust' },
  { id: '10', name: 'Capital Pool' },
  { id: '11', name: 'Special Purpose Acquisition Company' },
  { id: '2', name: 'Flow-Through' },
  { id: '3', name: 'Miscellaneous Trust' },
  { id: '4', name: 'Oil & Gas Royalty Trust' },
  { id: '5', name: 'REIT (Real Estate Investment Trust)' },
  { id: '6', name: 'Structured Product' },
  { id: '7', name: 'Utility Trust (Power & Pipeline)' },
  { id: '8', name: 'Split Share' },
];
export const targetTypeLookup: Array<ILookup> = [
  { id: '', name: 'Company Type' },
  { id: 'all', name: 'All Trusts' },
  { id: '1', name: 'Business Trust' },
  { id: '10', name: 'Capital Pool' },
  { id: '11', name: 'Special Purpose Acquisition Company' },
  { id: '2', name: 'Flow-Through' },
  { id: '3', name: 'Miscellaneous Trust' },
  { id: '4', name: 'Oil & Gas Royalty Trust' },
  { id: '5', name: 'REIT (Real Estate Investment Trust)' },
  { id: '6', name: 'Structured Product' },
  { id: '7', name: 'Utility Trust (Power & Pipeline)' },
  { id: '8', name: 'Split Share' },
];
export const fpIndustryLookup: Array<ILookup> = [
  { id: '', name: 'FP Industry' },
  { id: '11', name: 'Automobiles & Components' },
  { id: '21', name: 'Banks' },
  { id: '19', name: 'Biotechnology' },
  { id: '8', name: 'Capital Goods' },
  { id: '3', name: 'Chemicals' },
  { id: '9', name: 'Commercial Services & Supplies' },
  { id: '27', name: 'Communications Equipment' },
  { id: '28', name: 'Computers & Electronic Equipment' },
  { id: '12', name: 'Consumer Durables & Apparel' },
  { id: '13', name: 'Consumer Services' },
  { id: '6', name: 'Containers & Packaging' },
  { id: '22', name: 'Diversified Financials' },
  { id: '1', name: 'Energy Equipment & Services' },
  { id: '16', name: 'Food & Staples Retailing' },
  { id: '17', name: 'Food, Beverage & Tobacco' },
  { id: '4', name: 'Gold' },
  { id: '18', name: 'Health Care Equipment & Services' },
  { id: '23', name: 'Insurance' },
  { id: '26', name: 'IT Services' },
  { id: '14', name: 'Media' },
  { id: '5', name: 'Metals & Mining' },
  { id: '2', name: 'Oil, Gas & Consumable Fuels' },
  { id: '7', name: 'Paper & Forest Products' },
  { id: '20', name: 'Pharmaceuticals' },
  { id: '32', name: 'Real Estate Investment Trusts' },
  { id: '24', name: 'Real Estate Management & Development' },
  { id: '15', name: 'Retailing' },
  { id: '25', name: 'Software' },
  { id: '29', name: 'Telecommunication Services' },
  { id: '10', name: 'Transportation' },
  { id: '30', name: 'Utilities' },
  { id: '999', name: 'Other' },
];
export const dealValueGTLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '5000000', name: '=> 5,000,000' },
  { id: '10000000', name: '=> 10,000,000' },
  { id: '25000000', name: '=> 25,000,000' },
  { id: '50000000', name: '=> 50,000,000' },
  { id: '100000000', name: '=> 100,000,000' },
  { id: '250000000', name: '=> 250,000,000' },
  { id: '500000000', name: '=> 500,000,000' },
  { id: '1000000000', name: '=> 1,000,000,000' },
];
export const dealValueLTLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: '5000000', name: '< 5,000,000' },
  { id: '10000000', name: '< 10,000,000' },
  { id: '25000000', name: '< 25,000,000' },
  { id: '50000000', name: '< 50,000,000' },
  { id: '100000000', name: '< 100,000,000' },
  { id: '250000000', name: '< 250,000,000' },
  { id: '500000000', name: '< 500,000,000' },
  { id: '1000000000', name: '< 1,000,000,000' },
];
export const percentageBoughtGTLookup: Array<ILookup> = [
	{ id: '', name: '---' },
	{ id: '10', name: '=> 10' },
	{ id: '25', name: '=> 25' },
	{ id: '50', name: '=> 50' },
	{ id: '75', name: '=> 75' },
];
export const percentageBoughtLTLookup: Array<ILookup> = [
	{ id: '', name: '---' },
	{ id: '10', name: '< 10' },
	{ id: '25', name: '< 25' },
	{ id: '50', name: '< 50' },
	{ id: '75', name: '< 75' },
];
export const grossProceedsGTLookup: Array<ILookup> = [
	{ id: '', name: '---' },
	{ id: '1500000', name: ' => 1,500,000' },
	{ id: '10000000', name: ' => 10,000,000' },
	{ id: '25000000', name: ' => 25,000,000' },
	{ id: '50000000', name: ' => 50,000,000' },
	{ id: '100000000', name: ' => 100,000,000' },
	{ id: '250000000', name: ' => 250,000,000' },
	{ id: '500000000', name: ' => 500,000,000' },
	{ id: '1000000000', name: '=> 1,000,000,000' },
];
export const grossProceedsLTLookup: Array<ILookup> = [
	{ id: '', name: '---' },
	{ id: '10000000', name: '< 10,000,000' },
	{ id: '25000000', name: '< 25,000,000' },
	{ id: '50000000', name: '< 50,000,000' },
	{ id: '100000000', name: '< 100,000,000' },
	{ id: '250000000', name: '< 250,000,000' },
	{ id: '500000000', name: '< 500,000,000' },
	{ id: '1000000000', name: '< 1,000,000,000' },
];
export const underwriterLookup: Array<ILookup> = [
	{ id: '', name: '---' },
	{ id: '86786', name: 'A.G. Edwards & Sons, Inc.' },
	{ id: '122677', name: 'Aberdeen Gould Capital Markets Ltd.' },
	{ id: '87862', name: 'ABG Sundal Collier Norge ASA' },
	{ id: '87180', name: 'ABN AMRO Incorporated' },
	{ id: '145750', name: 'ABN AMRO Securities (USA) LLC' },
	{ id: '146978', name: 'ABSA Group Limited' },
	{ id: '136973', name: 'Academy Securities, Inc.' },
	{ id: '38729', name: 'Acadian Securities Incorporated' },
	{ id: '66924', name: 'Acumen Capital Finance Partners Limited' },
	{ id: '101296', name: 'Adams, Harkness & Hill, Inc.' },
	{ id: '87116', name: 'Advest, Inc.' },
	{ id: '151347', name: 'Aegis Capital Corp.' },
	{ id: '140735', name: 'AfrAsia Bank Limited' },
	{ id: '162736', name: 'Agentis Capital Markets Canada Ltd.' },
	{ id: '140584', name: 'Agricultural Bank of China Limited' },
	{ id: '86732', name: 'AIC Securities Inc.' },
	{ id: '86817', name: 'Alcom Securities AG' },
	{ id: '86743', name: 'Alex. Brown & Sons Incorporated' },
	{ id: '95470', name: 'Alexander Capital Group Inc.' },
	{ id: '87314', name: 'Alfred Berg Norge A/S' },
	{ id: '163927', name: 'Aligned Capital Partners Inc.' },
	{ id: '111290', name: 'All Group Financial Services Inc.' },
	{ id: '87057', name: 'Allen & Company Incorporated' },
	{ id: '153947', name: 'Alliance Global Partners' },
	{ id: '86605', name: 'Allied Canadian Equities Corporation' },
	{ id: '87112', name: 'Allied Corporate Services Inc.' },
	{ id: '86867', name: 'Alpha Capital Inc.' },
	{ id: '86600', name: 'Alpha Securities AG' },
	{ id: '109478', name: 'Alternative Investment Partners' },
	{ id: '87962', name: 'Ambrian Partners Ltd.' },
	{ id: '166709', name: 'AmeriVet Securities, Inc.' },
	{ id: '91506', name: 'Amsterdams Effectenkantoor B.V.' },
	{ id: '173553', name: 'Amuka Capital Corp.' },
	{ id: '162971', name: 'Amvest Capital Inc.' },
	{ id: '86578', name: 'Anchor Securities Ltd.' },
  { id: '160986', name: 'Apollo Capital Management, LLC' },
  { id: '172333', name: 'Apto Partners, LLC' },
	{ id: '103817', name: 'Arbuthnot Securities Limited' },
	{ id: '87113', name: 'ARC Capital Corporation' },
	{ id: '32652', name: 'ARC Financial Corp.' },
	{ id: '87236', name: 'ARCA Banca di Investimento Mobiliare S.p.A.' },
	{ id: '99280', name: 'Arctic Securities ASA' },
	{ id: '170628', name: 'Arden Partners Plc' },
	{ id: '103369', name: 'Argonaut Securities Pty Ltd' },
	{ id: '87795', name: 'Argosy Securities Inc.' },
	{ id: '133413', name: 'Arlington Group Asset Management Limited' },
	{ id: '87193', name: 'Arlington Securities Inc.' },
	{ id: '87328', name: 'Artesia Bank N.V.' },
	{ id: '90070', name: 'ASB Bank' },
	{ id: '121028', name: 'Ascendant Securities Inc.' },
	{ id: '89011', name: 'Ascenta Finance Corp.' },
	{ id: '87053', name: 'ASLK-CGER Bank' },
	{ id: '86612', name: 'Assante Capital Management Ltd.' },
	{ id: '95229', name: 'Aston Hill Securities Inc.' },
	{ id: '111693', name: 'ATB Capital Markets Inc.' },
	{ id: '162983', name: 'Auctus Advisors' },
	{ id: '87036', name: 'Auerbach, Pollak & Richardson, Inc.' },
	{ id: '92431', name: 'Austock Corporate Finance Limited' },
	{ id: '11540', name: 'Australia and New Zealand Banking Group Limited' },
	{ id: '87700', name: 'Avondale Partners, LLC' },
	{ id: '87505', name: 'AXA Bank' },
	{ id: '103462', name: 'Axemen Resource Capital Ltd.' },
	{ id: '151888', name: 'B. Riley FBR, Inc.' },
	{ id: '87392', name: 'Ballantrae Capital Corp.' },
	{ id: '66926', name: 'Banc of America Securities LLC' },
	{ id: '87380', name: 'Banc One Capital Markets, Inc.' },
	{ id: '87237', name: 'Banca Commerciale Italiana' },
	{ id: '86695', name: 'Banca del Gottardo' },
	{ id: '87293', name: 'Banca di Roma S.p.A.' },
	{ id: '87518', name: 'Banca IMI S.p.A.' },
	{ id: '87276', name: 'Banca Nazionale del Lavoro S.p.A.' },
	{ id: '87310', name: 'Banca Popolare di Milano' },
	{ id: '8182', name: 'Banco Bilbao Vizcaya Argentaria, S.A.' },
	{ id: '110483', name: 'Banco Bradesco BBI S.A.' },
	{ id: '145749', name: 'Banco do Brasil Securities LLC' },
	{ id: '87313', name: 'Banco Mello Inv S.A.' },
	{ id: '87604', name: 'Banco Popular Español S.A.' },
	{ id: '8183', name: 'Banco Santander, S.A.' },
	{ id: '140578', name: 'Banco Votorantim SA' },
	{ id: '172801', name: 'Bancroft Capital, LLC' },
	{ id: '87605', name: 'Banesto' },
	{ id: '87354', name: 'Bank Austria Creditanstalt AG' },
	{ id: '86693', name: 'Bank Heusser & Cie AG' },
	{ id: '87143', name: 'Bank in Liechtenstein' },
	{ id: '87967', name: 'Bank Insinger de Beaufort N.V.' },
	{ id: '87222', name: 'Bank Julius Baer & Co. AG' },
	{ id: '87153', name: 'Bank Leu AG' },
	{ id: '87297', name: 'Bank Leumi (UK) PLC' },
	{ id: '76976', name: 'Bank of China Limited' },
	{ id: '96190', name: 'Bank of New Zealand' },
	{ id: '87145', name: 'Bank Sarasin & Cie AG' },
	{ id: '86694', name: 'Bank von Ernst & Cie AG' },
	{ id: '87144', name: 'Bank Vontobel AG' },
	{ id: '86975', name: 'Bankers Trust International' },
	{ id: '87589', name: 'Bankgesellschaft Berlin AG' },
	{ id: '86562', name: 'Banque Bruxelles Lambert (Suisse) S.A.' },
	{ id: '87150', name: 'Banque et Caisse d&apos;Epargne de l&apos;Etat' },
	{ id: '86952', name: 'Banque Indosuez' },
	{ id: '86586', name: 'Banque Internationale a Luxembourg S.A.' },
	{ id: '87106', name: 'Banque UCL/ASLK-CGER' },
	{ id: '8197', name: 'Barclays Bank PLC' },
	{ id: '87895', name: 'Barnard Jacobs Mellet (USA) LLC' },
	{ id: '86986', name: 'Barnet Magrill Investments' },
	{ id: '63009', name: 'Bayerische Hypo- und Vereinsbank AG' },
	{ id: '87075', name: 'Bayerische Hypotheken- und Wechsel-Bank AG' },
	{ id: '86569', name: 'Bayerische Landesbank Girozentrale' },
	{ id: '108588', name: 'BayFront Capital Partners Ltd.' },
	{ id: '116060', name: 'BB&T Capital Markets' },
	{ id: '121689', name: 'BBY Ltd.' },
	{ id: '159391', name: 'BC Partners Securities LLC' },
	{ id: '166191', name: 'BCP Securities, LLC' },
	{ id: '87105', name: 'Beacon Securities Limited' },
	{ id: '46892', name: 'Bear, Stearns & Co. Inc.' },
	{ id: '88006', name: 'Bearbeech Capital Partners Corporation' },
	{ id: '117088', name: 'Beaufort International Associates Limited' },
	{ id: '89900', name: 'Becher McMahon Capital Markets Inc.' },
	{ id: '42327', name: 'Bedford Capital Corporation' },
	{ id: '87965', name: 'Bell Lawrie' },
	{ id: '121321', name: 'Bell Potter Securities Limited' },
	{ id: '116841', name: 'Bellotti Goodman Capital Inc.' },
	{ id: '99742', name: 'Belweather Capital Partners Inc.' },
	{ id: '86593', name: 'Benitz & Partners Ltd.' },
	{ id: '156821', name: 'Berenberg Capital Markets LLC' },
	{ id: '106157', name: 'BGF Capital Group' },
	{ id: '87164', name: 'Bieber Securities Inc.' },
	{ id: '55357', name: 'Blackmont Capital Inc.' },
	{ id: '149062', name: 'Blackstone Capital Markets' },
	{ id: '119510', name: 'Blackswan Equities' },
	{ id: '87442', name: 'Blaylock Van, LLC' },
	{ id: '103899', name: 'Bloom Burton Securities Inc.' },
	{ id: '88054', name: 'BMO Capital Markets' },
	{ id: '87606', name: 'BNL Investment Bank plc' },
	{ id: '38824', name: 'BNP Paribas' },
	{ id: '87594', name: 'BNY Mellon Capital Markets, LLC' },
	{ id: '110592', name: 'BOC International Holdings Limited' },
	{ id: '106276', name: 'Boenning & Scattergood, Inc.' },
	{ id: '109737', name: 'BofA Securities, Inc.' },
	{ id: '77601', name: 'Bolder Investment Partners, Ltd.' },
	{ id: '146417', name: 'Boustead Securities, LLC' },
	{ id: '87300', name: 'Brad Peery Inc.' },
	{ id: '141355', name: 'Brandon Hill Capital Limited' },
	{ id: '66933', name: 'Brant Securities Limited' },
	{ id: '125247', name: 'Brean Capital, LLC' },
	{ id: '162101', name: 'BRED Banque populaire' },
	{ id: '86992', name: 'Brenark Securities Ltd.' },
	{ id: '86687', name: 'Brenner Securities Corp.' },
	{ id: '168156', name: 'Bridge Street Capital Partners Pty Ltd' },
	{ id: '87755', name: 'Bridgewell Securities Limited' },
	{ id: '72323', name: 'Brink, Hudson & Lefever Ltd.' },
	{ id: '109731', name: 'Broadband Capital Management LLC' },
	{ id: '87244', name: 'Broadpoint Capital, Inc.' },
	{ id: '87578', name: 'Brompton Securities Limited' },
	{ id: '87020', name: 'Brooke Capital Corp.' },
	{ id: '56735', name: 'Brookfield Financial Corporation' },
	{ id: '160494', name: 'Brookline Capital Markets' },
	{ id: '149038', name: 'Bryan, Garnier & Co.' },
	{ id: '145808', name: 'BSM Capital Corporation' },
	{ id: '143872', name: 'BTIG, LLC' },
	{ id: '70541', name: 'Burgeonvest Bick Securities Limited' },
	{ id: '75457', name: 'Burns Fry Limited' },
	{ id: '124262', name: 'Burrill Securities LLC' },
	{ id: '87176', name: 'BVV LatinVest Securities' },
	{ id: '87544', name: 'BW Bank Ireland PLC' },
	{ id: '86601', name: 'Byrne & Co. Ltd.' },
	{ id: '104530', name: 'Byron Capital Markets Ltd.' },
	{ id: '87177', name: 'BZW Deutschland GmbH' },
	{ id: '87422', name: 'C.J. Elbourne Securities Inc.' },
	{ id: '110474', name: 'C.L. King & Associates, Inc.' },
	{ id: '40073', name: 'C.M. Oliver & Company Limited' },
	{ id: '87277', name: 'CAB S.p.A.' },
	{ id: '87238', name: 'Caboto IntesaBci SIM S.p.A.' },
	{ id: '110471', name: 'Cabrera Capital Markets, LLC' },
	{ id: '128964', name: 'Cairn Financial Advisors LLP' },
	{ id: '87166', name: 'Caisse Centrale des Banques Populaires' },
	{ id: '5185', name: 'Caisse centrale Desjardins' },
	{ id: '87093', name: 'Caisse des Depots et Consignations Marches' },
	{ id: '87094', name: 'Caisse Nationale de Credit Agricole' },
	{ id: '88015', name: 'Caldwell Securities Ltd.' },
	{ id: '86807', name: 'Calibre Funding Corporation' },
	{ id: '94362', name: 'Cambria Capital LLC' },
	{ id: '33648', name: 'Canaccord Genuity Corp.' },
	{ id: '106848', name: 'Canaccord Genuity Financial Limited' },
	{ id: '66981', name: 'Canadian Western Capital Limited' },
	{ id: '87155', name: 'Cantonalbanks of Switzerland' },
	{ id: '128473', name: 'Cantor Fitzgerald, L.P.' },
	{ id: '160806', name: 'Capital Institutional Services, Inc.' },
	{ id: '88234', name: 'Capital One Securities, Inc.' },
	{ id: '141128', name: 'Capital Securities Corp.' },
	{ id: '39128', name: 'Capital West Partners' },
	{ id: '88122', name: 'Capstone Investments' },
	{ id: '105969', name: 'Captus Partners Ltd.' },
	{ id: '87267', name: 'Cariplo Bank S.p.A.' },
	{ id: '87206', name: 'Carl P. Sherr & Company' },
	{ id: '86688', name: 'Carr Kitkat & Aitken' },
	{ id: '86691', name: 'Casgrain & Company Limited' },
	{ id: '91870', name: 'Casimir Capital L.P.' },
	{ id: '87248', name: 'Caspian Securities Inc.' },
	{ id: '87239', name: 'Cassa di Risparmio in Bologna S.p.A.' },
	{ id: '110291', name: 'Cassis Capital Corp.' },
	{ id: '110472', name: 'astleOak Securities, L.P.' },
	{ id: '139198', name: 'Cathay United Bank Co. Ltd.' },
	{ id: '87652', name: 'Cazenove Inc.' },
	{ id: '141830', name: 'CCB International Capital Limited' },
	{ id: '114949', name: 'CCFL Capital' },
	{ id: '114972', name: 'Celfin Capital S.A. SAB' },
	{ id: '105951', name: 'Cenkos Securities plc' },
	{ id: '87196', name: 'Cera Bank' },
	{ id: '87151', name: 'Cerabank Luxembourg S.A.' },
	{ id: '110473', name: 'CF Global Trading LLC' },
	{ id: '141829', name: 'Changjiang Securities Brokerage (HK) Limited' },
	{ id: '106256', name: 'Chardan Capital Markets, LLC' },
	{ id: '87689', name: 'Charles Schwab & Co., Inc.' },
	{ id: '86609', name: 'Charlton Capital Corp.' },
	{ id: '73858', name: 'Charlton Securities Ltd.' },
	{ id: '87357', name: 'Chartwell Securities Inc.' },
	{ id: '87160', name: 'Chase Manhattan International Limited' },
	{ id: '66941', name: 'Chase Securities Inc.' },
	{ id: '86667', name: 'Chemical Bank' },
	{ id: '86906', name: 'Chicago Corp.' },
	{ id: '160577', name: 'China Construction Bank (Asia) Corporation Limited' },
	{ id: '110467', name: 'China International Capital Corporation Hong Kong Securities Limited' },
	{ id: '140585', name: 'China Merchants Securities Co. Limited' },
	{ id: '133083', name: 'Chippingham Investment International Ltd.' },
	{ id: '87553', name: 'Christows Limited' },
	{ id: '127986', name: 'CI Investment Services Inc.' },
	{ id: '15728', name: 'CIBC World Markets Inc.' },
	{ id: '141831', name: 'CIMB Securities Limited' },
	{ id: '87337', name: 'CIT Group Securities (Canada) Inc.' },
	{ id: '79211', name: 'Citigroup Global Markets Inc.' },
	{ id: '151776', name: 'Citizens Capital Markets, Inc.' },
	{ id: '104336', name: 'CK Cooper & Company' },
	{ id: '87897', name: 'Clarendon Capital' },
	{ id: '87154', name: 'Clariden Bank' },
	{ id: '148239', name: 'Clarksons Platou Securities AS' },
	{ id: '85692', name: 'Clarus Securities Inc.' },
	{ id: '106907', name: 'CLSA Limited' },
	{ id: '86982', name: 'Clubb Capital Ltd.' },
	{ id: '87609', name: 'Cochran Caronia Waller Securities LLC' },
	{ id: '105041', name: 'Colliers Securities LLC' },
	{ id: '116791', name: 'Collins Stewart LLC' },
	{ id: '87656', name: 'Comerica Securities, Inc.' },
	{ id: '115517', name: 'Commercial Bank of Korea' },
	{ id: '110464', name: 'Commerz Markets LLC' },
	{ id: '86563', name: 'Commerzbank Aktiengesellschaft' },
	{ id: '87581', name: 'Commerzbank Capital Markets Corp.' },
	{ id: '87207', name: 'Commonwealth Associates L.P.' },
	{ id: '67880', name: 'Commonwealth Bank of Australia' },
	{ id: '87968', name: 'Compania International Financiera SA' },
	{ id: '86704', name: 'ompass Capital Ltd.' },
	{ id: '164866', name: 'Compass Point Research & Trading, LLC' },
	{ id: '87492', name: 'Concept Technique Institutionnel' },
	{ id: '65774', name: 'Coniston Investment Corp.' },
	{ id: '87439', name: 'Conning & Company' },
	{ id: '118422', name: 'Continental Bolsa SAB SA' },
	{ id: '87635', name: 'Coop Bank Schweiz' },
	{ id: '88264', name: 'Corinthian Partners LLC' },
	{ id: '67013', name: 'Cormark Securities Inc.' },
	{ id: '112934', name: 'Cornerstone Asset Management L.P.' },
	{ id: '86610', name: 'Corporate Securities Group, Inc.' },
	{ id: '87796', name: 'Corporate Synergy PLC' },
	{ id: '58618', name: 'Cowen and Company, LLC' },
	{ id: '86608', name: 'Coxe Capital Inc.' },
	{ id: '87125', name: 'Coxswain Row Capital Corporation' },
	{ id: '87163', name: 'CR Capital Corporation' },
	{ id: '105968', name: 'Craig-Hallum Capital Group LLC' },
	{ id: '139949', name: 'Cranson Capital Securities Inc.' },
	{ id: '91281', name: 'Credibolsa S.A.B. S.A.' },
	{ id: '145337', name: 'Credicorp Capital Sociedad Agente de Bolsa S.A.' },
	{ id: '66945', name: 'Credifinance Securities Limited' },
	{ id: '87886', name: 'Crédit Agricole Corporate and Investment Bank' },
	{ id: '87233', name: 'Crédit Agricole Indosuez' },
	{ id: '87095', name: 'Credit Commercial de France' },
	{ id: '86801', name: 'Credit Communal de Belgique S.A.' },
	{ id: '86943', name: 'Credit Europeen S.A.' },
	{ id: '86967', name: 'Credit Lyonnais Euro-Securities Ltd.' },
	{ id: '87370', name: 'Credit Lyonnais Luxembourg S.A.' },
	{ id: '86599', name: 'Credit Lyonnais S.A.' },
	{ id: '35447', name: 'Credit Suisse Group AG' },
	{ id: '87073', name: 'Creditanstalt-Bankverein' },
	{ id: '115998', name: 'Cross Point Capital LLC' },
	{ id: '110475', name: 'CRT Investment Banking LLC' },
	{ id: '86733', name: 'Cruttenden & Co.' },
	{ id: '87335', name: 'Cruttenden Roth Incorporated' },
	{ id: '87340', name: 'CT Securities Services Inc.' },
	{ id: '61853', name: 'CTI Capital Securities Inc.' },
	{ id: '150331', name: 'Cuttone & Co., LLC' },
	{ id: '122583', name: 'CWB McLean & Partners Wealth Management Ltd.' },
	{ id: '115772', name: 'Cyan Capital Markets LLC' },
	{ id: '87120', name: 'D & B Internat Securities Inc.' },
	{ id: '87692', name: 'D.A. Davidson & Co. Incorporated' },
	{ id: '87157', name: 'Dai-Ichi Kangyo Bank' },
	{ id: '87413', name: 'Dain Rauscher Wessels Incorporated' },
	{ id: '86568', name: 'Daiwa Securities Co. Ltd.' },
	{ id: '86973', name: 'Dakin Securities Corporation' },
	{ id: '132836', name: 'DaKoy Capital Markets, LLC' },
	{ id: '87122', name: 'Dallas Research and Trading, Inc.' },
	{ id: '86931', name: 'Daly Gordon Securities' },
	{ id: '152058', name: 'Daniel Stewart & Company plc' },
	{ id: '87680', name: 'Danske Bank' },
	{ id: '87693', name: 'Davenport & Company LLC' },
	{ id: '86900', name: 'David Williamson Associates' },
	{ id: '138607', name: 'Dawson James Securities, Inc.' },
	{ id: '137469', name: 'DBS Bank Ltd.' },
	{ id: '99166', name: 'Deacon & Company Capital Markets Inc.' },
	{ id: '43643', name: 'Deacon Barclays de Zoete Wedd Limited' },
	{ id: '72410', name: 'Deacon Capital Corporation' },
	{ id: '87000', name: 'Dean Witter Reynolds Inc.' },
	{ id: '101842', name: 'DekaBank Deutsche Gironzentrale' },
	{ id: '87905', name: 'Delafield Hambrecht, Inc.' },
	{ id: '101844', name: 'Delano Capital Corp.' },
	{ id: '64456', name: 'Demers Securities Inc.' },
	{ id: '87865', name: 'Depfa Bank PLC' },
	{ id: '3473', name: 'Desjardins Deragon Langlois Ltd.' },
	{ id: '15818', name: 'Desjardins Securities Inc.' },
	{ id: '87365', name: 'Deutsche Apotheker-und Aerztebank' },
	{ id: '48846', name: 'Deutsche Bank AG' },
	{ id: '87527', name: 'Deutsche Postbank AG' },
	{ id: '87348', name: 'Dexia Capital Markets' },
	{ id: '87331', name: 'DGZ Bank-Deutsche Girozentrale' },
	{ id: '86749', name: 'Dillon, Read & Co. Inc.' },
	{ id: '115819', name: 'DJ Carmichael Pty Limited' },
	{ id: '87829', name: 'DnB NOR Markets, Inc.' },
	{ id: '87190', name: 'Dominick Inc.' },
	{ id: '66950', name: 'Dominion Securities Inc.' },
	{ id: '14255', name: 'Donaldson, Lufkin & Jenrette Securities Corporation' },
	{ id: '104126', name: 'Dowling & Partners Securities, LLC' },
	{ id: '86703', name: 'Dresdner Bank Aktiengesellschaft' },
	{ id: '131249', name: 'Drexel Hamilton, LLC' },
	{ id: '115907', name: 'DS Apex Mergers & Acquisitions Ltd.' },
	{ id: '87344', name: 'DSL Bank Deutsche Siedlungs- und Landesrentenbank' },
	{ id: '111593', name: 'Du Musée Investments Inc.' },
	{ id: '87047', name: 'Dubeau Securities Ltd.' },
	{ id: '167017', name: 'Dundee Goodman Merchant Partners' },
	{ id: '87597', name: 'DZ Bank AG Deutsche Zentral-Genossenschaftsbank' },
	{ id: '110249', name: 'E.L. & C. Baillieu Stockbroking Ltd.' },
	{ id: '76061', name: 'E.Ohman J:or Fondkommission AB' },
	{ id: '132120', name: 'E.Sun Commercial Bank, Ltd.' },
	{ id: '121301', name: 'EarlyBirdCapital, Inc.' },
	{ id: '111917', name: 'EAS Advisors, LLC' },
	{ id: '111015', name: 'Echelon Wealth Partners Inc.' },
	{ id: '131254', name: 'Ecoban Securities Corporation' },
	{ id: '126053', name: 'Edgecrest Capital Corporation' },
	{ id: '87404', name: 'Edward Jones' },
	{ id: '111496', name: 'Eight Capital Corp.' },
	{ id: '87908', name: 'Element & Associates Corporate & Project Finance Limited' },
	{ id: '140512', name: 'Elemental Capital Partners LLP' },
	{ id: '87515', name: 'Ellis & Partners Ltd.' },
	{ id: '116751', name: 'EMD Financial Inc.' },
	{ id: '66952', name: 'Emerging Equities Inc.' },
	{ id: '76363', name: 'Endeavour Financial Corporation' },
	{ id: '87368', name: 'Enskilda Debit Capital Markets' },
	{ id: '100014', name: 'Equest Partners Ltd.' },
	{ id: '86705', name: 'Equity Capital Group' },
	{ id: '87607', name: 'Erste Bank der oesterreichischen Sparkassen AG' },
	{ id: '122437', name: 'Essence International Capital Limited' },
	{ id: '139167', name: 'Euro Pacific Capital Inc.' },
	{ id: '86607', name: 'Euro Scotia Group Limited' },
	{ id: '99880', name: 'Euroglobal Capital Partners Inc.' },
	{ id: '86962', name: 'Europa Securities Inc.' },
	{ id: '47009', name: 'Euroz Hartleys Limited' },
	{ id: '82689', name: 'Euroz Securities Limited' },
	{ id: '138594', name: 'Eventus Capital Corp.' },
	{ id: '124607', name: 'Evercore Group LLC' },
	{ id: '87185', name: 'EVEREN Securities, Inc.' },
	{ id: '87993', name: 'Evergreen Capital Partners Inc.' },
	{ id: '87813', name: 'Evolution Securities Limited' },
	{ id: '86591', name: 'F.C.G. Securities Corporation' },
	{ id: '110476', name: 'FBR Capital Markets & Co.' },
	{ id: '164806', name: 'Fearnley Securities AS' },
	{ id: '87610', name: 'Ferris, Baker Watts, Incorporated' },
	{ id: '87831', name: 'Fifth Third Securities, Inc.' },
	{ id: '44638', name: 'FIN-XO Securities Inc.' },
	{ id: '127366', name: 'finnCap Ltd' },
	{ id: '162526', name: 'First Abu Dhabi Bank' },
	{ id: '86999', name: 'First Analysis Securities Corporation' },
	{ id: '98070', name: 'First Canada Capital Partners Inc.' },
	{ id: '87838', name: 'First Canadian Capital Markets Ltd.' },
	{ id: '90472', name: 'First Canadian Securities' },
	{ id: '87219', name: 'First Delta Securities Inc.' },
	{ id: '86602', name: 'First Hanover Securities, Inc.' },
	{ id: '15868', name: 'First Marathon Securities Limited' },
	{ id: '108013', name: 'First Republic Capital Corporation' },
	{ id: '57632', name: 'First Reserve Corporation' },
	{ id: '99279', name: 'First Securities ASA' },
	{ id: '116818', name: 'First Shanghai Securities Limited' },
	{ id: '87584', name: 'First Union Securities, Inc.' },
	{ id: '7387', name: 'FirstEnergy Capital Corp.' },
	{ id: '87810', name: 'Fiske plc' },
	{ id: '86740', name: 'Fleet Securities, Inc.' },
	{ id: '168748', name: 'Florence Wealth Management Inc.' },
	{ id: '88014', name: 'Fondsfinans ASA' },
	{ id: '168996', name: 'Fort Capital Securities Ltd.' },
	{ id: '87673', name: 'Fort House Inc.' },
	{ id: '87407', name: 'Fortis Bank' },
	{ id: '86598', name: 'Fortune Financial Corporation' },
	{ id: '87748', name: 'Foster & Associates Financial Services Inc.' },
	{ id: '97783', name: 'Foundation Markets Inc.' },
	{ id: '112607', name: 'Fox Collins Securities Inc.' },
	{ id: '87809', name: 'Fox-Davies Capital Limited' },
	{ id: '87429', name: 'Fox-Pitt Kelton Cochran Caronia Waller' },
	{ id: '87759', name: 'Fraser Mackenzie Limited' },
	{ id: '87839', name: 'FTN Financial' },
	{ id: '86938', name: 'Fuji International Finance PLC' },
	{ id: '86589', name: 'Furman Selz Inc.' },
	{ id: '87336', name: 'Gabelli & Company, Inc.' },
	{ id: '86825', name: 'Gaines Berland Inc.' },
	{ id: '110477', name: 'Gardner Rich, LLC' },
	{ id: '86861', name: 'Garwood Financial' },
	{ id: '89357', name: 'Gateway Securities Inc.' },
	{ id: '86590', name: 'Generale Bank' },
	{ id: '158612', name: 'Generic Capital Corporation' },
	{ id: '76064', name: 'Genuity Capital Markets' },
	{ id: '86690', name: 'George E. Dulling and Co.' },
	{ id: '87049', name: 'George K. Baum & Company' },
	{ id: '86557', name: 'Georgia Pacific Securities Corporation' },
	{ id: '68089', name: 'Gerard Klauer Mattison & Co., Inc.' },
	{ id: '87739', name: 'Gilford Securities Incorporated' },
	{ id: '106410', name: 'Gillford Capital Inc.' },
	{ id: '86893', name: 'Gillon Securities' },
	{ id: '86611', name: 'GKN Securities Corporation' },
	{ id: '110871', name: 'Gleacher & Company Securities, Inc.' },
	{ id: '86972', name: 'Glendale Securities Inc.' },
	{ id: '86860', name: 'Global Equities S.A.' },
	{ id: '107140', name: 'Global Hunter Securities, LLC' },
	{ id: '87885', name: 'Global Maxfin Capital Inc.' },
	{ id: '86845', name: 'Global Securities Corporation' },
	{ id: '86886', name: 'Global Strategy Securities Inc.' },
	{ id: '174302', name: 'GloRes Securities Inc.' },
	{ id: '66961', name: 'Goepel Shields & Partners Inc.' },
	{ id: '86606', name: 'Golden Capital Securities Ltd.' },
	{ id: '47464', name: 'Goldman, Sachs & Co.' },
	{ id: '148786', name: 'Goodbody Stockbrokers UC' },
	{ id: '66963', name: 'Gordon Capital Corporation' },
	{ id: '87643', name: 'Gordon Capital, Inc.' },
	{ id: '27655', name: 'Gordon-Daly Grenadier Securities' },
	{ id: '125046', name: 'GPI Valores S.A.B.' },
	{ id: '106239', name: 'Gravitas Securities Inc.' },
	{ id: '87760', name: 'Graydon Elliot Capital Corporation' },
	{ id: '86959', name: 'Great Lakes Group Inc.' },
	{ id: '86613', name: 'Great Pacific Management Co. Ltd.' },
	{ id: '174603', name: 'Great Pacific Securities' },
	{ id: '86859', name: 'Great Western Securities, Inc.' },
	{ id: '117205', name: 'Greenrock Capital Partners Inc.' },
	{ id: '87766', name: 'Greenwich Capital Markets, Inc.' },
	{ id: '89171', name: 'Griffin Securities, Inc.' },
	{ id: '86616', name: 'Griffiths & Lamb' },
	{ id: '86885', name: 'Griffiths Capital Corporation' },
	{ id: '102266', name: 'Griffiths Energy and Resources Inc.' },
	{ id: '87108', name: 'Groome Capital.com Inc.' },
	{ id: '87142', name: 'Groupement des Banquiers Prives Genevois' },
	{ id: '27498', name: 'GSC Global Securities Corporation' },
	{ id: '86736', name: 'GTL Securities Inc.' },
	{ id: '110815', name: 'Guangdong Securities Limited' },
	{ id: '129960', name: 'Guggenheim Securities, LLC' },
	{ id: '87252', name: 'Gundy and Associates, Limited' },
	{ id: '125992', name: 'GVC Capital LLC' },
	{ id: '87690', name: 'H&R Block Financial Advisors, Inc.' },
	{ id: '125375', name: 'H.C. Wainwright & Co., LLC' },
	{ id: '86790', name: 'Hambro Clearing Ltd.' },
	{ id: '86561', name: 'Hambros Bank Limited' },
	{ id: '64372', name: 'Hampton Securities Limited' },
	{ id: '87377', name: 'Hang Seng Bank Ltd.' },
	{ id: '86701', name: 'Hanifen, Imhoff Inc.' },
	{ id: '145805', name: 'Hannam & Partners (Advisory) LLP' },
	{ id: '87894', name: 'Hargreave Hale Limited' },
	{ id: '111637', name: 'Harris Brown & Partners Limited' },
	{ id: '87449', name: 'Harrogate Partners Inc.' },
	{ id: '87353', name: 'Hawk Capital Corporation' },
	{ id: '86849', name: 'Hayes Bustin Securities Inc.' },
	{ id: '57820', name: 'Haywood Securities Inc.' },
	{ id: '128963', name: 'HD Capital Partners LLP' },
	{ id: '48546', name: 'HDL Capital Corporation' },
	{ id: '87260', name: 'Helmsdale Financial' },
	{ id: '121690', name: 'Helmsec Global Capital Limited' },
	{ id: '86871', name: 'Henderson Crosthwaite Corporate Finance Ltd.' },
	{ id: '87872', name: 'Hibernia Southcoast Capital, Inc.' },
	{ id: '117918', name: 'Houlihan Lokey Capital, Inc.' },
	{ id: '87032', name: 'Howard, Weil, Labouisse, Friedrichs Inc.' },
	{ id: '88020', name: 'HPC Capital Management Corporation' },
	{ id: '20944', name: 'HSBC Securities (Canada) Inc.' },
	{ id: '162816', name: 'Huntington Securities, Inc.' },
	{ id: '167148', name: 'Hyperion Capital Inc.' },
	{ id: '88677', name: 'Hythe Securities Ltd.' },
	{ id: '61214', name: 'iA Private Wealth Inc.' },
	{ id: '66965', name: 'IBK Capital Corp.' },
	{ id: '114463', name: 'IBS Capital S.E.N.C.' },
	{ id: '106908', name: 'ICBC International Securities Limited' },
	{ id: '55837', name: 'iForum Securities Inc.' },
	{ id: '87292', name: 'IMI Bank (Lux) S.A.' },
	{ id: '111548', name: 'Imperial Capital, LLC' },
	{ id: '107748', name: 'Industrial and Commercial Bank of China Limited' },
	{ id: '134567', name: 'INFOR Financial Inc.' },
	{ id: '87170', name: 'ING Bank NV' },
	{ id: '88030', name: 'ING Financial Markets LLC' },
	{ id: '94348', name: 'Initial Capital Partners Ltd.' },
	{ id: '87445', name: 'Institutional Equity Corp.' },
	{ id: '87278', name: 'Instituto Bancario San Paolo di Torino' },
	{ id: '68182', name: 'Integral Wealth Securities Limited' },
	{ id: '128322', name: 'Integrated Equity Pty. Ltd.' },
	{ id: '87446', name: 'Intercoastal Financial Services Corp.' },
	{ id: '87229', name: 'Intercontinental Capital Partners, Inc.' },
	{ id: '87881', name: 'InterFinancial Limited' },
	{ id: '9118', name: 'Interstate/Johnson Lane, Inc.' },
	{ id: '79798', name: 'Intesa Sanpaolo S.p.A.' },
	{ id: '113591', name: 'Intrynsyc Capital Corporation' },
	{ id: '87169', name: 'Inverlat International' },
	{ id: '87051', name: 'Investec Merchant Bank Limited' },
	{ id: '99242', name: 'Ionic Securities Ltd.' },
	{ id: '49238', name: 'IPC Securities Corporation' },
	{ id: '57470', name: 'IPO Capital Corp.' },
	{ id: '87329', name: 'Ippa Bank N.V.' },
	{ id: '117132', name: 'ISM Capital LLP' },
	{ id: '110466', name: 'Itau BBA USA Securities, Inc.' },
	{ id: '121783', name: 'J & E Davy' },
	{ id: '145860', name: 'J. Streicher Capital LLC' },
	{ id: '87231', name: 'J.B. Were & Son' },
	{ id: '82775', name: 'J.F. Mackie & Company Ltd.' },
	{ id: '87694', name: 'J.J.B. Hilliard, W.L. Lyons, Inc.' },
	{ id: '87327', name: 'J.M. Charter Securities Corp.' },
	{ id: '79208', name: 'J.P. Morgan Securities LLC' },
	{ id: '86940', name: 'J.W. Charles Securities Inc.' },
	{ id: '88966', name: 'Jacob Securities Inc.' },
	{ id: '87815', name: 'James Edward Capital Corporation' },
	{ id: '87695', name: 'Janney Montgomery Scott LLC' },
	{ id: '87228', name: 'Jardine Fleming' },
  { id: '84397', name: 'Jefferies & Company, Inc.' },
	{ id: '38419', name: 'Jennings Capital Inc.' },
	{ id: '138844', name: 'Jennings Capital Inc.' },
	{ id: '87843', name: 'JitneyTrade Inc.' },
	{ id: '76078', name: 'JMP Securities LLC' },
	{ id: '154227', name: 'Joh. Berenberg, Gossler & Co. KG' },
	{ id: '66973', name: 'Johnson Rice & Company LLC' },
	{ id: '24549', name: 'Jones, Gable & Company Limited' },
	{ id: '155503', name: 'JonesTrading Institutional Services LLC' },
	{ id: '102133', name: 'Jordan Capital Markets Inc.' },
  { id: '87501', name: 'Jory Capital Inc.' },
	{ id: '87204', name: 'Joseph Dillon & Co.' },
	{ id: '87486', name: 'Joseph Stevens & Co. Inc.' },
	{ id: '86686', name: 'Josephthal Lyon & Ross Incorporation' },
	{ id: '57324', name: 'JovFunds Inc.' },
	{ id: '86787', name: 'JP Colin Securities Inc.' },
	{ id: '87487', name: 'JP Turner & Co. LLC' },
	{ id: '138879', name: 'Jub Capital Ltd.' },
	{ id: '105903', name: 'Kallpa Securities S.A.B.' },
	{ id: '87270', name: 'Kankaku Europe Ltd.' },
  { id: '87274', name: 'Kantonalbank' },
	{ id: '87325', name: 'KBC Bank N.V.' },
	{ id: '87504', name: 'Keefe, Bruyette & Woods, Inc.' },
	{ id: '86617', name: 'Kemper Securities, Inc.' },
	{ id: '87334', name: 'Kensington Securities Inc.' },
	{ id: '130655', name: 'Kernaghan & Partners Ltd.' },
	{ id: '127563', name: 'KES 7 Capital Inc.' },
	{ id: '88027', name: 'KeyBank Capital Markets' },
	{ id: '135820', name: 'KGI Securities' },
	{ id: '86567', name: 'Kidder, Peabody & Co. Incorporated' },
  { id: '112284', name: 'KKR Capital Markets LLC' },
  { id: '87899', name: 'Knight Capital Markets LLC' },
  { id: '87111', name: 'Kokusai Securities Co.' },
  { id: '87338', name: 'Kredietbank NV' },
  { id: '86727', name: 'L. P. Charles and Goings Inc.' },
  { id: '87205', name: 'Ladenburg, Thalmann & Co. Inc.' },
  { id: '87044', name: 'Laidlaw Equities, Inc.' },
  { id: '125171', name: 'Lake Street Capital Markets, LLC' },
  { id: '87443', name: 'Landesbank Baden-Wurttemberg' },
  { id: '87175', name: 'Landesbank Berlin Girozentrale' },
  { id: '87603', name: 'Landesbank Hessen-Thuringen Girozentrale' },
  { id: '87202', name: 'Landesbank Rheinland-Pfalz - Girozentrale' },
  { id: '87343', name: 'Landesbank Schleswig-Holstein Girozentrale' },
  { id: '87103', name: 'Latinvest Securities Limited' },
  { id: '44283', name: 'Laurentian Bank Securities Inc.' },
  { id: '66928', name: 'Lawrence Capital Partners Inc.' },
  { id: '86809', name: 'Lazard Capital Markets LLC' },
  { id: '110478', name: 'Lebenthal & Co., LLC' },
  { id: '86588', name: 'Leede Jones Gable Inc.' },
  { id: '87808', name: 'Leerink Partners LLC' },
  { id: '158905', name: 'Legacy Hill Capital Ltd.' },
  { id: '87182', name: 'Legg Mason Wood Walker, Incorporated' },
  { id: '46315', name: 'Lehman Brothers Inc.' },
  { id: '87345', name: 'Liechtensteinische Landesbank' },
  { id: '87817', name: 'Life Science Group Inc.' },
  { id: '131353', name: 'LifeSci Capital LLC' },
  { id: '87595', name: 'Lightyear Capital Inc.' },
  { id: '88133', name: 'Limited Market Dealer Inc.' },
  { id: '87441', name: 'Lines Overseas Management Limited' },
  { id: '86810', name: 'Lion Management Finance Ltd.' },
  { id: '137542', name: 'LionTree Advisors, LLC' },
  { id: '86636', name: 'Lloyds Bank PLC' },
  { id: '87925', name: 'Lloyds TSB Bank plc' },
  { id: '87814', name: 'Loeb Aron & Company Ltd.' },
  { id: '87866', name: 'Loewen & Partners Inc.' },
  { id: '15987', name: 'Loewen, Ondaatje, McCutcheon Limited' },
  { id: '38335', name: 'Lombard Odier Darier Hentsch' },
  { id: '110468', name: 'Loop Capital Markets LLC' },
  { id: '87849', name: 'Lorian Group Inc.' },
  { id: '87065', name: 'LTCB International Limited' },
  { id: '87740', name: 'Ludgate Investments Limited' },
  { id: '86792', name: 'LVM Canada Ltée' },
  { id: '87341', name: 'Lynch Investments Limited' },
  { id: '87864', name: 'M Partners Inc.' },
  { id: '110479', name: 'M. R. Beal & Company' },
  { id: '38592', name: 'MacDougall, MacDougall & MacTier Inc.' },
  { id: '86961', name: 'MacDougall, Meyer Inc.' },
  { id: '27809', name: 'Macquarie Capital Markets Canada Ltd.' },
  { id: '60981', name: 'Macquarie North America Ltd.' },
  { id: '86984', name: 'Madison Securities, Inc.' },
  { id: '110503', name: 'Madison Williams and Company LLC' },
  { id: '82247', name: 'Maison Placements Canada Inc.' },
  { id: '73859', name: 'Majendie Securities Ltd.' },
  { id: '149741', name: 'Mann Mann Jensen Partners LP' },
  { id: '87054', name: 'Manulife Securities Incorporated' },
  { id: '86660', name: 'Marathon Funding Inc.' },
  { id: '86582', name: 'Marleau, Lemire Securities Inc.' },
  { id: '120381', name: 'Marquest Asset Management Inc.' },
  { id: '87875', name: 'Martin Place Securities Pty. Ltd.' },
  { id: '70279', name: 'Matrix Financial Corporation' },
  { id: '86700', name: 'Matthews Brieger Securities Inc.' },
  { id: '87804', name: 'Max Capital Markets Ltd.' },
  { id: '125246', name: 'Maxim Group LLC' },
  { id: '86987', name: 'MB Capital Corp.' },
  { id: '87146', name: 'MC Securities Ltd.' },
  { id: '87750', name: 'MCA Securities Inc.' },
  { id: '86580', name: 'McDermid St. Lawrence Securities Ltd.' },
  { id: '87288', name: 'McDonald & Company Securities, Inc.' },
  { id: '87421', name: 'McDonald Investments, Inc.' },
  { id: '87992', name: 'McDouall Stuart Securities Limited' },
  { id: '40560', name: 'McLean McCarthy Inc.' },
  { id: '86930', name: 'McLeod Young Weir Limited' },
  { id: '126214', name: 'Medalist Capital Ltd.' },
  { id: '87545', name: 'Mediobanca - Banca di Credito Finanziario S.p.A.' },
  { id: '72735', name: 'Mellon Financial Markets, LLC' },
  { id: '117020', name: 'Merchant Securities Limited' },
  { id: '136758', name: 'Meritz Securities Co., Ltd.' },
  { id: '86579', name: 'Merrill Lynch & Co.' },
  { id: '63139', name: 'Merriman Curhan Ford & Co.' },
  { id: '87117', name: 'Mesirow Financial, Inc.' },
  { id: '87255', name: 'MFC Merchant Bank' },
  { id: '167501', name: 'MFR Securities, Inc.' },
  { id: '57322', name: 'MGI Securities Inc.' },
  { id: '37798', name: 'Middlefield Capital Corporation' },
  { id: '86844', name: 'Middlefield International Limited' },
  { id: '16027', name: 'Midland Walwyn Capital Inc.' },
  { id: '87346', name: 'Migrosbank' },
  { id: '87677', name: 'Miller, Johnson, Steichen, Kinnard, Inc.' },
  { id: '87879', name: 'Mirabaud Securities Limited' },
  { id: '136841', name: 'Mischler Financial Group, Inc.' },
  { id: '87147', name: 'Mitsubishi Bank (Schweiz) AG' },
  { id: '86833', name: 'Mitsubishi Trust International Limited' },
  { id: '87563', name: 'Mizuho International plc' },
  { id: '156820', name: 'MKM Partners' },
  { id: '115935', name: 'MLV & Co.' },
  { id: '109200', name: 'Moelis & Company' },
  { id: '115771', name: 'Mongolia International Capital Corp.' },
  { id: '87591', name: 'Monness, Crespi, Hardt & Co. Inc.' },
  { id: '87279', name: 'Monte Dei Paschi di Siena' },
  { id: '88134', name: 'Montgomery & Co., LLC' },
  { id: '86839', name: 'Montgomery Securities' },
  { id: '87257', name: 'Montreal Bond Inc.' },
  { id: '66982', name: 'Morgan Grenfell & Co. Limited' },
  { id: '111885', name: 'Morgan Joseph TriArtisan Group Inc.' },
  { id: '80436', name: 'Morgan Keegan & Company, Inc.' },
  { id: '36983', name: 'Morgan Stanley & Co. LLC' },
  { id: '87453', name: 'Morgan Trust Company' },
  { id: '168155', name: 'Morgans Financial Limited' },
  { id: '71986', name: 'Moss, Lawson & Co. Limited' },
  { id: '58665', name: 'MUFG Bank, Ltd.' },
  { id: '110480', name: 'Muriel Siebert & Co., Inc.' },
  { id: '93922', name: 'Murphy and Durieu LP' },
  { id: '61127', name: 'Mustang Capital Partners Inc.' },
  { id: '86878', name: 'Muzinich & Co., Inc.' },
  { id: '88022', name: 'NAB Capital Markets' },
  { id: '110131', name: 'nabSecurities, LLC' },
  { id: '90113', name: 'NatCity Investments, Inc.' },
  { id: '119492', name: 'National Australia Bank Ltd.' },
  { id: '15981', name: 'National Bank Financial Inc.' },
  { id: '68510', name: 'National Commercial Bank Jamaica Limited' },
  { id: '125660', name: 'National Securities Corporation' },
  { id: '87608', name: 'Natixis' },
  { id: '86692', name: 'NatWest Capital Markets Ltd.' },
  { id: '162527', name: 'NBK Capital Limited' },
  { id: '94522', name: 'NCB Stockbrokers Ltd.' },
  { id: '101528', name: 'NCP Northland Capital Partners Inc.' },
  { id: '87507', name: 'Needham & Company, LLC' },
  { id: '86581', name: 'Nesbitt Thomson Inc.' },
  { id: '87489', name: 'Network 1 Financial Securities Inc.' },
  { id: '87269', name: 'New Japan Securities Europe Limited' },
  { id: '42277', name: 'Newcrest Capital Inc.' },
  { id: '141489', name: 'NewGen Asset Management Limited' },
  { id: '124742', name: 'Newport Coast Securities Inc.' },
  { id: '116493', name: 'Newport Private Wealth Inc.' },
  { id: '87631', name: 'Newport Securities Inc.' },
  { id: '87024', name: 'Nikko Securities Co. Ltd.' },
  { id: '113759', name: 'Noble Financial Capital Markets' },
  { id: '72820', name: 'Nomura International plc' },
  { id: '86689', name: 'Nordberg Capital Inc.' },
  { id: '92228', name: 'Nordea Bank Danmark A/S' },
  { id: '87551', name: 'Nordea Securities' },
  { id: '87546', name: 'Nordlandsbanken A/S' },
  { id: '87149', name: 'NordLB - Norddeutsche Landesbank Girozentrale' },
  { id: '96994', name: 'Norstar Securities LP' },
  { id: '129625', name: 'Northcrest Capital Limited' },
  { id: '46978', name: 'Northern Securities Inc.' },
  { id: '128862', name: 'Northland Capital Partners Limited' },
  { id: '89914', name: 'Northland Securities, Inc.' },
  { id: '86669', name: 'Norwich Securities Ltd.' },
  { id: '83335', name: 'Notre-Dame Capital Inc.' },
  { id: '87844', name: 'Nova Bancorp Securities Ltd.' },
  { id: '87805', name: 'Numis Securities Limited' },
  { id: '87415', name: 'Nutmeg Securities Ltd.' },
  { id: '114683', name: 'Oberon Capital Corporation' },
  { id: '87737', name: 'Ocean Equities Ltd.' },
  { id: '87876', name: 'Ocean Financial Group Pty. Ltd.' },
  { id: '61834', name: 'Octagon Capital Corporation' },
  { id: '83191', name: 'ODL Securities Limited' },
  { id: '32628', name: 'Odlum Brown Limited' },
  { id: '88004', name: 'Olympus Securities, LLC' },
  { id: '87258', name: 'Omni Capital Inc.' },
  { id: '102320', name: 'Oppenheimer & Co. Inc.' },
  { id: '86619', name: 'Optimax Securities Corporation' },
  { id: '133796', name: 'Optiva Securities Ltd.' },
  { id: '38814', name: 'Orenda Corporate Finance Ltd.' },
  { id: '112265', name: 'Oriel Securities Limited' },
  { id: '149208', name: 'Origin Merchant Securities Inc.' },
  { id: '86936', name: 'Orion Royal Bank Ltd.' },
  { id: '164345', name: 'Orion Underwriting and Issuances Ltd.' },
  { id: '87772', name: 'Osprey Capital Partners Inc.' },
  { id: '87124', name: 'Pacific Crest Securities, Inc.' },
  { id: '86834', name: 'Pacific Group Financial Corp.' },
  { id: '87381', name: 'Pacific Growth Equities, LLC' },
  { id: '86835', name: 'PaineWebber Incorporated' },
  { id: '99761', name: 'Paladin Capital Markets Inc.' },
  { id: '172540', name: 'Palos Wealth Management' },
  { id: '82665', name: 'Paradigm Capital Inc.' },
  { id: '87552', name: 'Pareto Securities ASA' },
  { id: '64327', name: 'Patica Securities Limited' },
  { id: '86870', name: 'Paulson Investments' },
  { id: '139754', name: 'Peel Hunt LLP' },
  { id: '86951', name: 'Pemberton Securities Inc.' },
  { id: '93535', name: 'Pennaluna & Company' },
  { id: '87893', name: 'Pension Financial Services Canada' },
  { id: '87969', name: 'Perimeter Financial Group' },
  { id: '141354', name: 'Peterhouse Corporate Finance Limited' },
  { id: '59903', name: 'Peters & Co. Limited' },
  { id: '88233', name: 'Petrie Parkman & Co.' },
  { id: '59150', name: 'PI Financial Corporation' },
  { id: '88235', name: 'Pickering Energy Partners, Inc.' },
  { id: '87850', name: 'Pictet & Cie' },
  { id: '139203', name: 'PillarFour Securities Inc.' },
  { id: '109505', name: 'Pinnacle Capital Ltd.' },
  { id: '87009', name: 'Piper Sandler & Co.' },
  { id: '87048', name: 'Planvest Pacific Financial Corporation' },
  { id: '106909', name: 'Platinum Securities Company Limited' },
  { id: '124609', name: 'PNC Capital Markets LLC' },
  { id: '86584', name: 'Polar Asset Management Partners Inc.' },
  { id: '38383', name: 'Pollitt & Co., Inc.' },
  { id: '66986', name: 'Pope & Company Limited' },
  { id: '72457', name: 'Porthmeor Securities Inc.' },
  { id: '87738', name: 'PowerOne Capital Markets Limited' },
  { id: '66987', name: 'PowerWest Financial Ltd.' },
  { id: '87630', name: 'Precedent Capital Corporation' },
  { id: '87306', name: 'Price Warner Securities Ltd.' },
  { id: '87990', name: 'Primary Capital Inc.' },
  { id: '87732', name: 'Pritchard Capital Partners LLC' },
  { id: '86998', name: 'Private Capital Markets Corp.' },
  { id: '71486', name: 'Pro-Genesis Securities Inc.' },
  { id: '87192', name: 'Promittere Securities Limited' },
  { id: '83544', name: 'Provincial Securities Limited' },
  { id: '87644', name: 'Prudential Bache Securities (U.K.) Limited' },
  { id: '86958', name: 'Prudential Bache Securities Inc.' },
  { id: '66989', name: 'Prudential Securities Inc.' },
  { id: '86830', name: 'Puccetti Farrell Capital Corp.' },
  { id: '86841', name: 'Punk, Ziegel & Company, L.P.' },
  { id: '87898', name: 'Puplava Securities Inc.' },
  { id: '87140', name: 'Queensbury Securities Inc.' },
  { id: '63357', name: 'Quest Securities Corporation' },
  { id: '87197', name: 'Quest Stockbrokers (HK) Ltd.' },
  { id: '87691', name: 'Quick & Reilly, Inc.' },
  { id: '86813', name: 'R. Brant Securities Ltd.' },
  { id: '145511', name: 'R. Seelaus & Co., Inc.' },
  { id: '154757', name: 'R.F. Lafferty & Co., Inc.' },
  { id: '87141', name: 'Rabobank International' },
  { id: '88528', name: 'Raiffeisen Switzerland' },
  { id: '139012', name: 'Raintree Financial Solutions Inc' },
  { id: '66990', name: 'Rampart Securities Inc.' },
  { id: '87268', name: 'RASFIN SIM S.p.A.' },
  { id: '87944', name: 'Rathlin Capital International Inc.' },
  { id: '58671', name: 'Raymond James & Associates, Inc.' },
  { id: '128242', name: 'RB International Markets (USA) LLC' },
  { id: '59731', name: 'RBC Capital Markets' },
  { id: '86989', name: 'RBC Daniels, L.P.' },
  { id: '87250', name: 'RC Securities Inc.' },
  { id: '137906', name: 'Red Cloud Klondike Strike Inc.' },
  { id: '167641', name: 'REDPLUG Inc.' },
  { id: '87577', name: 'Refco Futures (Canada) Ltd.' },
  { id: '87602', name: 'Regent Capital Partners Inc.' },
  { id: '87685', name: 'Regent Mercantile Bancorp, Inc.' },
  { id: '91872', name: 'Regent Securities Capital Corporation' },
  { id: '145680', name: 'Regions Securities LLC' },
  { id: '111938', name: 'Renaissance Capital LLC' },
  { id: '86791', name: 'Renaissance Securities Inc.' },
  { id: '60654', name: 'Research Capital Corporation' },
  { id: '78939', name: 'RF Securities Clearing LP' },
  { id: '86970', name: 'Richardson Greenshields of Canada (U.K.) Ltd.' },
  { id: '66993', name: 'Richardson Greenshields of Canada Limited' },
  { id: '76276', name: 'Richardson Partners Financial Limited' },
  { id: '78940', name: 'Richardson Wealth Limited' },
  { id: '55419', name: 'Robert Caldwell Capital Corporation' },
  { id: '87230', name: 'Robert Fleming & Co. Limited' },
  { id: '66996', name: 'Robert W. Baird & Co. Incorporated' },
  { id: '169817', name: 'Roberts & Ryan Investments, Inc.' },
  { id: '87296', name: 'Robertson Stephens, Inc.' },
  { id: '87002', name: 'Roche Securities Limited' },
  { id: '66998', name: 'Rock Capital Partners Ltd.' },
  { id: '87455', name: 'Roth Capital Partners, LLC' },
  { id: '87806', name: 'Rothenberg Capital Management Inc.' },
  { id: '87070', name: 'Roundhill Securities Inc.' },
  { id: '59552', name: 'Roynat Capital Inc.' },
  { id: '125602', name: 'RS Platou Market AS' },
  { id: '87977', name: 'RSEG Trading Group Ltd.' },
  { id: '87636', name: 'Rued Blass et Cie' },
  { id: '87620', name: 'Ryan, Beck & Co., LLC' },
  { id: '86560', name: 'S.G. Warburg & Co. Inc.' },
  { id: '87148', name: 'Sakura Bank (Deutschland) GmbH' },
  { id: '67004', name: 'Salman Partners Inc.' },
  { id: '67005', name: 'Salomon Brothers Inc.' },
  { id: '110481', name: 'Samuel A. Ramirez & Company, Inc.' },
  { id: '86954', name: 'Samuel Montagu & Co., Limited' },
  { id: '87406', name: 'Sanders Morris Harris Inc.' },
  { id: '88236', name: 'Sanders Wealth Management Group Ltd.' },
  { id: '87319', name: 'Sanderson Securities Ltd.' },
  { id: '121299', name: 'Sandler O&apos;Neill & Partners, L.P.' },
  { id: '110470', name: 'Sanford C. Bernstein & Co., LLC' },
  { id: '86863', name: 'Sanwa International plc' },
  { id: '74697', name: 'Sanwa McCarthy Securities Ltd.' },
  { id: '114542', name: 'Saratoga Finance Inc.' },
  { id: '67007', name: 'Sayer Securities Limited' },
  { id: '125782', name: 'Sberbank CIB' },
  { id: '106054', name: 'Scarsdale Equities LLC' },
  { id: '39319', name: 'Schroder & Co. Inc.' },
  { id: '87574', name: 'Schroder Salomon Smith Barney' },
  { id: '86595', name: 'Schroder Securities Limited' },
  { id: '67009', name: 'Schroder Wertheim & Co. Inc.' },
  { id: '87347', name: 'Schweizer Kantonalbanken' },
  { id: '87669', name: 'Schweizer Verband der Raiffeisenbanken' },
  { id: '87201', name: 'Schweizerische Bankgesellschaft (Deutschland) AG' },
  { id: '86866', name: 'Schweizerischer Bankverein (Deutschland) AG' },
  { id: '16169', name: 'Scotia Capital Inc.' },
  { id: '87187', name: 'Scott & Stringfellow, LLC' },
  { id: '141357', name: 'Seaport Global Securities LLC' },
  { id: '87232', name: 'SEB Enskilda AS' },
  { id: '86728', name: 'Security Trading Inc.' },
  { id: '87857', name: 'Secutor Capital Management Corporation' },
  { id: '86771', name: 'Select Capital Management Inc.' },
  { id: '168026', name: 'Sera Global Securities Canada LP' },
  { id: '122678', name: 'Seton Securities Group, Inc.' },
  { id: '87172', name: 'SGZ-Bank Sudwestdeutsche Genossenschafts-Zentralbank AG' },
  { id: '87488', name: 'Sharpe Capital Inc.' },
  { id: '128321', name: 'Shaw ICS Advisory Pty. Limited' },
  { id: '86956', name: 'Shearson Lehman Hutton Inc.' },
  { id: '116819', name: 'Shenyin Wanguo Capital (H.K.) Limited' },
  { id: '106551', name: 'Sherbrooke Street Capital (SSC) Inc.' },
  { id: '87613', name: 'Shinkin International Limited' },
  { id: '143124', name: 'Shore Capital Markets Limited' },
  { id: '88000', name: 'Shoreline Pacific, LLC' },
  { id: '121302', name: 'Sidoti & Company, LLC' },
  { id: '160805', name: 'Siebert Williams Shank & Co., LLC' },
  { id: '93947', name: 'Silverbridge Capital Inc.' },
  { id: '79365', name: 'Simmons & Company International' },
  { id: '87052', name: 'Simpson McKie James Capel' },
  { id: '136940', name: 'Sinopac Securities Corp.' },
  { id: '126978', name: 'SMBC Nikko Securities Inc.' },
  { id: '97675', name: 'SMH Capital Inc.' },
  { id: '39631', name: 'Smith Barney Inc.' },
  { id: '86778', name: 'Société Générale S.A.' },
  { id: '110469', name: 'Soleil Securities Corporation' },
  { id: '139539', name: 'Somers and Partners Pty Ltd.' },
  { id: '87914', name: 'Sora Group Wealth Advisors Inc.' },
  { id: '86974', name: 'Southcoast Capital Corporation' },
  { id: '87696', name: 'Southwest Securities, Inc.' },
  { id: '167198', name: 'SP Angel Corporate Finance LLP' },
  { id: '145487', name: 'SpareBank 1 Markets AS' },
  { id: '173184', name: 'Spartan Capital Securities, LLC' },
  { id: '87480', name: 'Spencer Edwards, Inc.' },
  { id: '110386', name: 'Sprott Capital Partners LP' },
  { id: '87119', name: 'Sprott Global Resources Investments, Ltd.' },
  { id: '87102', name: 'St. James Securities Inc.' },
  { id: '88584', name: 'Standard Bank plc' },
  { id: '99531', name: 'Standard Chartered Bank' },
  { id: '87590', name: 'Standard Mercantile Bancorp' },
  { id: '67014', name: 'Standard Securities Capital Corporation' },
  { id: '86921', name: 'Stephen Avenue Securities Inc.' },
  { id: '87611', name: 'Stephens Inc.' },
  { id: '174604', name: 'Stern Brothers & Co.' },
  { id: '121300', name: 'Sterne, Agee & Leach, Inc.' },
  { id: '87697', name: 'Stifel, Nicolaus & Company, Incorporated' },
  { id: '107342', name: 'Stonecap Securities Inc.' },
  { id: '103669', name: 'Stonegate Securities, Incorporated' },
  { id: '131973', name: 'Stormcrow Capital Ltd.' },
  { id: '87391', name: 'Strand Securities Corporation' },
  { id: '87416', name: 'Strasbourger Pearson Tulcin Wolff Incorporated' },
  { id: '88005', name: 'Strata Partners LLC' },
  { id: '109773', name: 'Strategic Energy Research and Capital, LLC' },
  { id: '115626', name: 'Stuart Investment Management Limited' },
  { id: '87339', name: 'Sudwestdeutsche Landesbank GZ' },
  { id: '86800', name: 'Sumitomo Finance International plc' },
  { id: '86832', name: 'Sumitomo Trust International plc' },
  { id: '106606', name: 'Summer Street Research Partners' },
  { id: '110654', name: 'Sunel Securities Inc.' },
  { id: '87121', name: 'Sunrise Securities Corp.' },
  { id: '87593', name: 'SunTrust Robinson Humphrey, Inc.' },
  { id: '88032', name: 'Susquehanna Financial Group, LLLP' },
  { id: '87091', name: 'Sutro & Co. Incorporated' },
  { id: '158631', name: 'SVB Leerink LLC' },
  { id: '125603', name: 'Swedbank AB' },
  { id: '86566', name: 'Swiss Bank Corporation' },
  { id: '87156', name: 'Swiss Volksbank' },
  { id: '87171', name: 'Swissca Securities Limited' },
  { id: '87683', name: 'Synerglobe Capital Ltd.' },
  { id: '87162', name: 'Taiheiyo Europe Ltd.' },
  { id: '135822', name: 'Taipei Fubon Commercial Bank Co., Ltd.' },
  { id: '87007', name: 'Takugin Finance International' },
  { id: '143331', name: 'Tamesis Partners LLP' },
  { id: '67015', name: 'Tassé & Associés, Limitée' },
  { id: '67016', name: 'Taurus Capital Markets Ltd.' },
  { id: '110661', name: 'Taylor Collison Limited' },
  { id: '38229', name: 'TD Securities Inc.' },
  { id: '123875', name: 'Tempest Capital Corp.' },
  { id: '167930', name: 'Tennyson Securities' },
  { id: '92051', name: 'Tera Capital Corporation' },
  { id: '86910', name: 'The Argosy Securities Group L.P.' },
  { id: '145950', name: 'The Benchmark Company, LLC' },
  { id: '86571', name: 'The First Boston Corporation' },
  { id: '86673', name: 'The Hathaway Corporation' },
  { id: '145681', name: 'The Huntington Investment Company' },
  { id: '86603', name: 'The Industrial Bank of Japan, Ltd.' },
  { id: '87295', name: 'The Robinson-Humphrey Company, LLC' },
  { id: '66923', name: 'The Royal Bank of Scotland N.V.' },
  { id: '9833', name: 'The Royal Bank of Scotland plc' },
  { id: '87437', name: 'The Seidler Companies Incorporated' },
  { id: '87533', name: 'The Williams Capital Group, L.P.' },
  { id: '87653', name: 'ThinkEquity LLC' },
  { id: '87937', name: 'Third Point LLC' },
  { id: '67017', name: 'Thomas Weisel Partners LLC' },
  { id: '30884', name: 'Thomson Kernaghan & Co. Ltd.' },
  { id: '87212', name: 'Tokai Bank Europe plc' },
  { id: '87050', name: 'Tokyo Securities Co. (Europe) Ltd.' },
  { id: '87679', name: 'Toll Cross Securities Inc.' },
  { id: '87098', name: 'Torrey Pines Securities, Inc.' },
  { id: '81093', name: 'Trapeze Capital Corp.' },
  { id: '86681', name: 'Trinity Capital Securities Limited' },
  { id: '87494', name: 'Trinity Wood Capital Corporation' },
  { id: '60234', name: 'Tristone Capital Inc.' },
  { id: '164498', name: 'Truist Securities, Inc.' },
  { id: '91622', name: 'Trump Securities, LLC' },
  { id: '112578', name: 'Tudor, Pickering, Holt & Co. Securities, Inc.' },
  { id: '174109', name: 'Tuohy Brothers Investment Research Inc.' },
  { id: '35084', name: 'Tuscarora Capital Inc.' },
  { id: '87702', name: 'TWC Securities Inc.' },
  { id: '110146', name: 'U.S. Bancorp Investments, Inc.' },
  { id: '32705', name: 'UBS AG' },
  { id: '87408', name: 'Unibank A/S' },
  { id: '88011', name: 'Unicredit (HVB) Zuerich' },
  { id: '87547', name: 'UniCredit Banca Mobiliare S.p.A.' },
  { id: '87240', name: 'UniCredit Bank AG' },
  { id: '87223', name: 'Union Bancaire Privée Bahamas Ltd.' },
  { id: '86907', name: 'Union Capital Markets (UK) Ltd.' },
  { id: '149064', name: 'Union Gaming Securities, LLC' },
  { id: '86564', name: 'Union Securities Ltd.' },
  { id: '87035', name: 'United Capital Securities Inc.' },
  { id: '87271', name: 'United Services Advisors Inc.' },
  { id: '87068', name: 'Unterberg Harris' },
  { id: '169695', name: 'US Tiger Securities, Inc.' },
  { id: '87658', name: 'Utendahl Capital Partners, L.P.' },
  { id: '87256', name: 'ValorInvest' },
  { id: '87188', name: 'Van Kasper & Company' },
  { id: '87021', name: 'Vector Securities International Inc.' },
  { id: '82993', name: 'Velocity Trade Capital Ltd.' },
  { id: '61897', name: 'Vengate Capital Partners Company' },
  { id: '87040', name: 'Venture Guarantee Ltd.' },
  { id: '87330', name: 'Vereins- Und Westbank Aktiengesellschaft' },
  { id: '86988', name: 'Versant Partners Inc.' },
  { id: '87670', name: 'Verwaltungs- und Privat-Bank AG' },
  { id: '105601', name: 'Vicarage Capital Limited' },
  { id: '160966', name: 'Virtu Americas LLC' },
  { id: '87645', name: 'Vision Capital Corporation' },
  { id: '87243', name: 'Volpe Brown Whelan & Company, LLC' },
  { id: '86597', name: 'Volpe, Welty & Company' },
  { id: '89428', name: 'VSA Resources Ltd.' },
  { id: '86831', name: 'W.D. Latimer Co. Limited' },
  { id: '87452', name: 'W.H. Ireland Limited' },
  { id: '87579', name: 'Wachovia Capital Markets, LLC' },
  { id: '87092', name: 'Wako International (Europe) Limited' },
  { id: '73934', name: 'Wallace Dewan & Partners Inc.' },
  { id: '86932', name: 'Walwyn Stodgell Cochrane Murray Limited' },
  { id: '86618', name: 'Wardley Investment Services Ltd.' },
  { id: '87097', name: 'Wasserstein Perella Securities, Inc.' },
  { id: '60655', name: 'Watershed Capital Partners Inc.' },
  { id: '60248', name: 'Watt Carmichael Inc.' },
  { id: '125172', name: 'WD Capital Markets Inc.' },
  { id: '87186', name: 'Wedbush Securities Inc.' },
  { id: '74853', name: 'Wellington West Capital Markets Inc.' },
  { id: '68100', name: 'Wellington-Altus Private Wealth Inc.' },
  { id: '87573', name: 'Wells Fargo Securities, LLC' },
  { id: '89068', name: 'Weslosky & Cowans Ltd.' },
  { id: '86576', name: 'Wessels, Arnold & Henderson' },
  { id: '87994', name: 'West Oak Capital Partners Inc.' },
  { id: '87654', name: 'Westdeutsche Genossenschafts-Zentralbank' },
  { id: '86565', name: 'Westdeutsche Landesbank Girozentrale' },
  { id: '106154', name: 'Westech International Pty. Ltd.' },
  { id: '110425', name: 'Westpac Banking Corporation' },
  { id: '86980', name: 'Whale Securities Co., L.P.' },
  { id: '86594', name: 'Wheat, First Securities, Inc.' },
  { id: '158469', name: 'Whitman Howard Ltd.' },
  { id: '67028', name: 'William Blair & Company, LLC' },
  { id: '86993', name: 'Williams de Broe PLC' },
  { id: '87194', name: 'Wilson HTM Corporate Services Ltd.' },
  { id: '87417', name: 'Win Capital Corp.' },
  { id: '110546', name: 'Windermere Capital (Canada) Inc.' },
  { id: '87042', name: 'Wirtschafts' },
  { id: '87349', name: 'Wit SoundView Corporation' },
  { id: '161014', name: 'Wolfe Capital Markets and Advisory' },
  { id: '80700', name: 'Wolverton Securities Ltd.' },
  { id: '67029', name: 'Wood Gundy Inc.' },
  { id: '87181', name: 'Woodcommerz a.s.' },
  { id: '87537', name: 'Woodstone Capital Inc.' },
  { id: '56572', name: 'Worldsource Securities Inc.' },
  { id: '132587', name: 'WR Hambrecht + Co.' },
  { id: '103619', name: 'Wunderlich Securities, Inc.' },
  { id: '87981', name: 'Y.B. Investment Corporation' },
  { id: '86570', name: 'Yamaichi International' },
  { id: '87174', name: 'Yamatane Securities (Europe) Ltd.' },
  { id: '139199', name: 'Yuanta Securities Co., Ltd.' },
  { id: '87672', name: 'ZED Financial Partners' },
  { id: '166455', name: 'Zelman Partners LLC' },
  { id: '87454', name: 'Zenshinren International Limited' },
  { id: '172313', name: 'Zions Direct, Inc.' },
  { id: '87773', name: 'Zuercher Kantonalbank AG' },
];
export const salesVolumeGTLookup: Array<ILookup> = [
  { id: '', name: '--- More Than ---' },
  { id: '1000', name: '> 1' },
  { id: '500000', name: '> 500' },
  { id: '1000000', name: '> 1,000' },
  { id: '2500000', name: '> 2,500' },
  { id: '5000000', name: '> 5,000' },
  { id: '10000000', name: '> 10,000' },
  { id: '20000000', name: '> 20,000' },
  { id: '50000000', name: '> 50,000' },
  { id: '100000000', name: '> 100,000' },
  { id: '500000000', name: '> 500,000' },
  { id: '1000000000', name: '> 1,000,000' },
];
export const salesVolumeLTLookup: Array<ILookup> = [
  { id: '', name: '--- Less Than ---' },
  { id: '500000', name: '< 500' },
  { id: '1000000', name: '< 1,000' },
  { id: '2500000', name: '< 2,500' },
  { id: '5000000', name: '< 5,000' },
  { id: '10000000', name: '< 10,000' },
  { id: '20000000', name: '< 20,000' },
  { id: '50000000', name: '< 50,000' },
  { id: '100000000', name: '< 100,000' },
  { id: '500000000', name: '< 500,000' },
  { id: '1000000000', name: '< 1,000,000' },
];

export const dbrsRatingsLookup: Array<ILookup> = [
  { id: '', name: 'Input' },
	{ id: '2', name: 'AAA' },
	{ id: '3', name: 'AAA low' },
	{ id: '4', name: 'AA high' },
	{ id: '5', name: 'AA' },
	{ id: '6', name: 'AA low' },
	{ id: '7', name: 'A high' },
	{ id: '8', name: 'A' },
	{ id: '9', name: 'A low' },
	{ id: '10', name: 'BBB high' },
	{ id: '11', name: 'BBB' },
	{ id: '12', name: 'BBB low' },
	{ id: '13', name: 'BB high' },
	{ id: '14', name: 'BB' },
	{ id: '15', name: 'BB low' },
	{ id: '16', name: 'B high' },
	{ id: '17', name: 'B' },
	{ id: '18', name: 'B low' },
	{ id: '19', name: 'CCC high' },
	{ id: '20', name: 'CCC' },
	{ id: '21', name: 'CCC low' },
	{ id: '22', name: 'CC high' },
	{ id: '23', name: 'CC' },
	{ id: '24', name: 'CC low' },
	{ id: '25', name: 'C high' },
	{ id: '26', name: 'C' },
	{ id: '27', name: 'C low' },
	{ id: '44', name: 'D' },
];

export const copuponFeatureLookup: Array<ILookup> = [
  { id: '', name: '---' },
  { id: 'f', name: 'Fixed' },
  { id: 'fl', name: 'Floating' },
  { id: 'flf', name: 'Fixed/Floating' },
];
