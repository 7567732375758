/* eslint-disable*/
import { useHistory } from 'react-router';
import { Accordion } from "components/Accordion";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Text } from "components/Text";
import { Link } from "components/Link";
import { Tiles } from "components/Tiles";
import * as React from "react";
import styled from "styled-components";
import { Heading } from "../../../components/Heading";
import { Page } from "../../../components/Page";
import { useId } from "../../../hooks";
import "./index.scss";

const TitleDesc = styled.div`
  font-size: 18px;
  vertical-align: middle;
  color: #333333;
  margin: 15px 0 10px;
`;
const SectionTitle = styled.div`
  font-size: 20px;
  vertical-align: middle;
  color: #000000;
  font-weight: 600;
  margin: 15px 0 10px;
`;
export function HelpGlossaryPage() {
  const headingId = useId();
	const history = useHistory();

  const AlphabeticalData = [
    "#",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Page aria-labelledby={headingId}>
      <div className="help--center--back--text">
				<a href="#" onClick={() => { history.goBack() }}>
						&lt; Back to Help Centre
				</a>
      </div>

      <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
        Glossary
      </Heading>
      <TitleDesc>
        Find definitions for commonly used words in FP Advisor. Definitions are
        tagged accordingly to the sections they belong in.
      </TitleDesc>

      <SectionTitle>Browse Defintions Alphabetically</SectionTitle>
      <div className="alphabeticcally--btn">
        {AlphabeticalData.map((item) => (
          <Button
            noBorder={true}
            mt="sm"
            label={item}
            design="primary"
            size="md"
          />
        ))}
      </div>
      <Heading mt="lg" size="lg" level="3" mb="sm">
        #
      </Heading>
      {/* start */}
			<Card mb="lg">
      <Accordion openOnLoad>
        <Accordion.Heading size="xl" level="2">
				<span style={{ fontSize: "20px", fontWeight: 600, color: '#333333' }}>52 Week High</span>
        </Accordion.Heading>
        <Accordion.Content>
          <Tiles columns={[1, 1]}>
					
          </Tiles>
        </Accordion.Content>
        <Accordion.Footer size="xl" level="2" weight="semi-bold">
          <div></div>
        </Accordion.Footer>
      </Accordion>
			</Card>
      {/* end */}
    </Page>
  );
}
