import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';

export function LoadingSpinner () {
  return (
    <Root aria-hidden>
      <Circular role="img">
        <Circle
          fill="none"
          r="20"
          cx="50"
          cy="50"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </Circular>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const rotateAnimation = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;

const Circular = styled.svg`
  animation: ${rotateAnimation} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const dashAnimation = keyframes`
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
`;

const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: ${(props) => props.theme.palette.primary.main};
  animation: ${dashAnimation} 1.5s ease-in-out infinite;
  stroke-linecap: round;
`;
