import React from 'react';
import { StarIcon } from '../../icons/StarIcon';
import { TrashIcon } from '../../icons/TrashIcon';
import { Heading } from '../Heading';
import { Row } from '../Row';
import { ISavedSearchSection } from '../../containers/AuthProvider';
// import './index.css';

interface SavedSearch {
  savedSearches: ISavedSearchSection[];
  selectedSavedSearch?: string;
  setSelectedSavedSearch: React.Dispatch<React.SetStateAction<string>>;
  deleteSavedSearch: (searchName:string, searchType: string) => void;
  populateFromSavedSearch: (target:string, searchType: string) => void;
}

export const SavedSearch: React.FC<SavedSearch> = ({ savedSearches, selectedSavedSearch, deleteSavedSearch, populateFromSavedSearch, setSelectedSavedSearch }) => {
  return (
    <div>
      <Heading level="2" size="lg" mb="sm">
          <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
          My Saved Searches
      </Heading>

      <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches_adv">Saved Searches</label>
      <Row>
        <select id="select_saved_searches_adv"
          value={selectedSavedSearch}
          onChange={(e) => {
            setSelectedSavedSearch(e.target.value);
            populateFromSavedSearch(e.target.value, 'advanced');
          }}
        >
          <option>No Search Selected</option>
          { savedSearches.map(search => (
              <option value={search.searchId}>{search.searchName}</option>
          ))}
        </select>

        {selectedSavedSearch && (
            <button type="button" style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch, 'advanced') }}>
              <TrashIcon size='sm' />
            </button>
          )
        }
      </Row>
    </div>
  );
};
