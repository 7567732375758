/* eslint-disable */
import * as React from "react";
import { useHistory } from "react-router";
import { Page } from "../../../components/Page";
import { Heading } from "../../../components/Heading";
import { RadioList } from "../../../components/RadioList";
import { Tiles } from "../../../components/Tiles";
import { Tabs, Tab } from "../../../components/Tabs";
import { Row } from "../../../components/Row";
import { CorporateSurveysAdvanceTab, ICorpSurveysAdvProps } from '../../../components/CorporateSurveysAdvanceTab';
import { useId, usePreviousStorageData } from "../../../hooks";
import { TextField } from "../../../components/TextField";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text";
import { MultiSelectField } from "../../../components/MultiSelectField";
import toast, { Toaster } from 'react-hot-toast';
import { TrashIcon } from '../../../icons/TrashIcon';
import { StarIcon } from '../../../icons/StarIcon';
import { useSavedSearches, useRefreshSavedSearches, useGetAccessToken } from '../../../containers/AuthProvider';
import { getCorporateSurveysSearchResults, deleteSearch } from '../../../data';
import { CheckIcon } from '../../../icons/CheckIcon';
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';

import {
  countriesLookup,
  provincesLookupbyAlphaCode,
  searchTypePredecessorLookup,
} from "../../../data/lookups";
import moment from "moment";
import ClearButton from "components/ClearButton";
import "./index.scss";

export function CorporateSurveysSearchPage() {
  const history = useHistory();
  const headingId = useId();
  const headingIdAdvanced = useId();
  const [companyName, setCompanyName] = React.useState("");
  const [stockSymbol, setStockSymbol] = React.useState("");
  const [searchPredecessor, setSearchPredecessor] = React.useState(true);
  const [searchType, setSearchType] = React.useState(
    localStorage.getItem('basicSearchRadio') || 'exact-phrase'
    );
  const [city, setCity] = React.useState("");
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [country, setCountry] = React.useState<string[]>([]);
  const [currentTime, setCurrentTime] = React.useState(
    new Date().toLocaleString()
  );

  const [ClearValue, setClearValue] = React.useState(true);

  const [exchange, setExchange] = React.useState<string[]>([]);

  const [exchangeListed, setExchangeListed] = React.useState(
    localStorage.getItem('chk_exchange_listed') === 'true'
  );
  const [exchangeSuspended, setExchangeSuspended] = React.useState(
    localStorage.getItem('chk_exchange_suspended') === 'true'
  );

  const [contacttype, setContacttype] = React.useState(
    localStorage.getItem('contactTypeRadio') || '1'
  );

  const [companyType, setCompanyType] = React.useState<string[]>([]);
  const [companySubType, setCompanySubType] = React.useState<string[]>([]);
  const [incorporationYear, setIncorporationYear] =  React.useState('');
  const [jurisdiction, setJurisdiction] = React.useState<string>('');
  const [majorShareholder, setMajorShareholder] = React.useState('');
  const [optShareholderInterest10Prcnt, setOptShareholderInterest10Prcnt] = React.useState<boolean>(false);
  const [optForeignOwnership10Prcnt, setOptForeignOwnership10Prcnt] = React.useState<boolean>(false);
  const [subsidiary, setSubsidiary] = React.useState('');
  const [directorOfficerName, setDirectorOfficerName] = React.useState('');
  const [auditor, setAuditor] = React.useState<string>('');
  const [banker, setBanker] = React.useState<string>('');
  const [lawyer, setLawyer] = React.useState<string>('');
  const [transferAgent, setTransferAgent] = React.useState<string>('');
  const [idxSPTSXComp, setIdxSPTSXComp] = React.useState<boolean>(false);
  const [idxSPTSX60, setIdxSPTSX60] = React.useState<boolean>(false);
  const [idxTSX30, setIdxTSX30] = React.useState<boolean>(false);
  const [idxTSXVenTier1, setIdxTSXVenTier1] = React.useState<boolean>(false);
  const [idxTSXVenTier2, setIdxTSXVenTier2] = React.useState<boolean>(false);
  const [idxTSXVenTier3, setIdxTSXVenTier3] = React.useState<boolean>(false);
  const [idxFP500, setIdxFP500] = React.useState<boolean>(false);
  const [idxCleantech, setIdxCleantech] = React.useState<boolean>(false);
  const [idxBlockchain, setIdxBlockchain] = React.useState<boolean>(false);
  const [idxCannabis, setIdxCannabis] = React.useState<boolean>(false);

  const [gicCodes, setGicCodes] = React.useState<string[]>([]);
  const [naicsCodes, setNaicsCodes] = React.useState<string[]>([]);
  const [naicsCodeType, setNaicsCodeType] = React.useState(
    localStorage.getItem('naicsCodeRadio') || '1'
  );
  const [sicCodes, setSicCodes] = React.useState<string[]>([]);
  const [sicCodeType, setSicCodeType] = React.useState(
    localStorage.getItem('sicCodeRadio') || '1'
  );

  const [normalCourseIssuerBid, setNormalCourseIssuerBid] = React.useState<boolean>(false);
  const [substantialIssuerBid, setSubstantialIssuerBid] = React.useState<boolean>(false);
  const [dividendReinvestmentPlan, setDividendReinvestmentPlan] = React.useState<boolean>(false);

  /* For Financial Search */
  const [ textSearch, setTextSearch ] = React.useState<string>('');
  const [ finSearchSortOpt, setFinSearchSortOpt ] = React.useState<string>('');
  const [ finSearchSortDirection, setFinSearchSortDirection ] = React.useState<string>('0');

  const [ category1, setCategory1 ] = React.useState<string>('');
  const [ dataItem1, setDataItem1 ] = React.useState<string>('');
  const [ year1, setYear1 ] = React.useState<string>('');
  const [ boolOp1, setBoolOp1 ] = React.useState<string>('And');
  const [ operation1, setOperation1 ] = React.useState<string>('');
  const [ value1, setValue1 ] = React.useState<string>('');

  const [ active2, setActive2 ] = React.useState<boolean>(true);
  const [ category2, setCategory2 ] = React.useState<string>('');
  const [ dataItem2, setDataItem2 ] = React.useState<string>('');
  const [ year2, setYear2 ] = React.useState<string>('');
  const [ boolOp2, setBoolOp2 ] = React.useState<string>('And');
  const [ operation2, setOperation2 ] = React.useState<string>('');
  const [ value2, setValue2 ] = React.useState<string>('');

  const [ active3, setActive3 ] = React.useState<boolean>(true);
  const [ category3, setCategory3 ] = React.useState<string>('');
  const [ dataItem3, setDataItem3 ] = React.useState<string>('');
  const [ year3, setYear3 ] = React.useState<string>('');
  const [ boolOp3, setBoolOp3 ] = React.useState<string>('And');
  const [ operation3, setOperation3 ] = React.useState<string>('');
  const [ value3, setValue3 ] = React.useState<string>('');

  const [ active4, setActive4 ] = React.useState<boolean>(true);
  const [ category4, setCategory4 ] = React.useState<string>('');
  const [ dataItem4, setDataItem4 ] = React.useState<string>('');
  const [ year4, setYear4 ] = React.useState<string>('');
  const [ boolOp4, setBoolOp4 ] = React.useState<string>('And');
  const [ operation4, setOperation4 ] = React.useState<string>('');
  const [ value4, setValue4 ] = React.useState<string>('');

  const [ active5, setActive5 ] = React.useState<boolean>(true);
  const [ category5, setCategory5 ] = React.useState<string>('');
  const [ dataItem5, setDataItem5 ] = React.useState<string>('');
  const [ year5, setYear5 ] = React.useState<string>('');
  const [ boolOp5, setBoolOp5 ] = React.useState<string>('And');
  const [ operation5, setOperation5 ] = React.useState<string>('');
  const [ value5, setValue5 ] = React.useState<string>('');

  const [ rowsToShow, setRowsToShow ] = React.useState<number>(1);

  // BEGIN saved search
  const [selectedSavedSearch, setSelectedSavedSearch] = React.useState('');
  const [newSavedSearchName, setNewSavedSearchName] = React.useState('');
  const { searches: savedSearches } = useSavedSearches();
  const { refreshSavedSearches } = useRefreshSavedSearches();

  const getAccessToken = useGetAccessToken();

  const newDate = new Date();
  const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

  const saveSearch = async () => {
    // saved search must have a name set...
    if (!newSavedSearchName || newSavedSearchName.toString().trim().length < 1) {
      return;
    }
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await getCorporateSurveysSearchResults({
      searchForm: 'advanced',
      searchType: searchType,
      exchange: exchange.join(','),
      exchangeListed: exchangeListed ? 'true' : '',
      exchangeSuspended: exchangeSuspended ? 'true' : '',
      companyType: companyType.join(','),
      companySubType: companySubType.join(','),
      jurisdiction: jurisdiction,
      incorporationYear: incorporationYear,
      majorShareholder: majorShareholder,
      optShareholderInterest10Prcnt: optShareholderInterest10Prcnt ? 'true' : '',
      optForeignOwnership10Prcnt: optForeignOwnership10Prcnt ? 'true' : '',
      subsidiary: subsidiary,
      directorOfficerName: directorOfficerName,
      auditor: auditor,
      banker: banker,
      lawyer: lawyer,
      transferAgent: transferAgent,
      idxSPTSXComp: idxSPTSXComp ? 'true' : '',
      idxSPTSX60: idxSPTSX60 ? 'true' : '',
      idxTSX30: idxTSX30 ? 'true' : '',
      idxTSXVenTier1: idxTSXVenTier1 ? 'true' : '',
      idxTSXVenTier2: idxTSXVenTier2 ? 'true' : '',
      idxTSXVenTier3: idxTSXVenTier3 ? 'true' : '',
      idxFP500: idxFP500 ? 'true' : '',
      idxCleantech: idxCleantech ? 'true' : '',
      idxBlockchain: idxBlockchain ? 'true' : '',
      idxCannabis: idxCannabis ? 'true' : '',
      sicCodes: sicCodes.join(','),
      sicCodeType: sicCodeType,
      naicsCodes: naicsCodes.join(','),
      naicsCodeType: naicsCodeType,
      gicCodes: gicCodes.join(','),
      normalCourseIssuerBid: normalCourseIssuerBid ? 'true' : '',
      substantialIssuerBid: substantialIssuerBid ? 'true' : '',
      dividendReinvestmentPlan: dividendReinvestmentPlan ? 'true' : '',
      dateInfo: dateInfo,
      // db?: db;
      savedSearchName: newSavedSearchName || undefined,
      page: 1,
      numberOfRows: 1,
    }, getAccessToken);

    await refreshSavedSearches();

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#0E643D', width: '280px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <CheckIcon fill="white" />
        <div>Search form saved</div>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#0E643D', color: 'white' } });
  }

  const deleteSavedSearch = async (savedSearchId: string, searchType: string) => {
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await deleteSearch({
      id: savedSearchId
    }, getAccessToken);

    await refreshSavedSearches();

    const deletedSearchName = savedSearches?.corporateSurveysAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    )?.searchName;

    setNewSavedSearchName('');

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#333333', width: '320px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <div>Search form removed</div>
        <button style={{ margin: 0, padding: 0, fontWeight: 'bold', background: 0, border: 0, color: 'white', textDecoration: 'underline' }} onClick={async () => {
          if (deletedSearchName) {
            toast.dismiss(t.id);
            await setNewSavedSearchName(deletedSearchName);
            await saveSearch();
            await refreshSavedSearches();
          }
        }}>
          Undo
        </button>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#333333', color: 'white' } });
  };

  const populateFromSavedSearch = (selectedSavedSearch: string, type: string) => {
    clearCheck(); // clear form values
    setSelectedSavedSearch(selectedSavedSearch);

    let savedSearch: any;
    savedSearch = savedSearches?.corporateSurveysAdvanced.find(
      s => (s.searchId === parseInt(selectedSavedSearch, 10))
    );

    if (!savedSearch) {
      return;
    }

    const savedSearchValues = savedSearch.searchValues;

    if (savedSearchValues.auditor) {
      setAuditor(savedSearchValues.auditor);
    }
    if (savedSearchValues.banker) {
      setAuditor(savedSearchValues.banker);
    }
    if (savedSearchValues.blockchain) {
      setIdxBlockchain(true);
    }
    if (savedSearchValues.cannabis) {
      setIdxCannabis(true);
    }
    if (savedSearchValues.cleantech) {
      setIdxCleantech(true);
    }
    if(savedSearchValues.csubtype) {
      setCompanySubType(savedSearchValues.csubtype);
    }
    if(savedSearchValues.ctype) {
      setCompanyType(savedSearchValues.ctype);
    }
    if (savedSearchValues.exch) {
      setExchange(savedSearchValues.exch);
    }
    if (savedSearchValues.exch_listed) {
      setExchangeListed(true);
    }
    if (savedSearchValues.exch_suspended) {
      setExchangeSuspended(true);
    }
    if (savedSearchValues.exch_listed) {
      setExchangeListed(true);
    }
    if(savedSearchValues.sp_60) {
      setIdxSPTSX60(true);
    }
    if(savedSearchValues.tsx_30) {
      setIdxTSX30(true);
    }
    if(savedSearchValues.sp_composite) {
      setIdxSPTSXComp(true);
    }
    if(savedSearchValues.ventier1) {
      setIdxTSXVenTier1(true);
    }
    if(savedSearchValues.ventier2) {
      setIdxTSXVenTier2(true);
    }
    if(savedSearchValues.ventier3) {
      setIdxTSXVenTier3(true);
    }
    if(savedSearchValues.fp500) {
      setIdxFP500(savedSearchValues.fp500);
    }
    if(savedSearchValues.incorpjuris) {
      setJurisdiction(savedSearchValues.incorpjuris);
    }
    if(savedSearchValues.person) {
      setDirectorOfficerName(savedSearchValues.person);
    }
    if(savedSearchValues.lawyer) {
      setLawyer(savedSearchValues.lawyer);
    }
    if(savedSearchValues.auditor) {
      setAuditor(savedSearchValues.auditor);
    }
    if(savedSearchValues.banker) {
      setBanker(savedSearchValues.banker);
    }
    if(savedSearchValues.transferagent) {
      setTransferAgent(savedSearchValues.transferagent);
    }
    if(savedSearchValues.incorpyear) {
      setIncorporationYear(savedSearchValues.incorpyear);
    }
    if(savedSearchValues.subsid) {
      setSubsidiary(savedSearchValues.subsid);
    }
    if(savedSearchValues.officerinterest) {
      setOptShareholderInterest10Prcnt(true);
    }
    if(savedSearchValues.foreignowned) {
      setOptForeignOwnership10Prcnt(true);
    }
    if(savedSearchValues.shareholder) {
      setMajorShareholder(savedSearchValues.shareholder);
    }
    if(savedSearchValues.sic) {
      setSicCodes(savedSearchValues.sic);
    }
    if(savedSearchValues.sictype) {
      setSicCodeType(savedSearchValues.sictype);
    }
    if(savedSearchValues.gics) {
      setGicCodes(savedSearchValues.gics);
    }
    if(savedSearchValues.naics) {
      setNaicsCodes(savedSearchValues.naics);
    }
    if(savedSearchValues.naicstype) {
      setNaicsCodeType(savedSearchValues.naicstype);
    }
  }

  React.useEffect(() => {
    if (history.action === "POP") {
      loadLatestEntries();
    } else {
      clearCheck();
    }
    refreshSavedSearches();
  }, []);
  // END saved search

  const loadLatestEntries = () => {
    const companyNameCS = localStorage.getItem("companyNameCS");
    if (companyNameCS) {
      setCompanyName(JSON.parse(companyNameCS));
    }
    const stockSymbol = localStorage.getItem("stockSymbol");
    if (stockSymbol) {
      setStockSymbol(JSON.parse(stockSymbol));
    }
    const city = localStorage.getItem("city");
    if (city) {
      setCity(JSON.parse(city));
    }
    const provinces = localStorage.getItem("provinces");
    if (provinces) {
      setProvinces(JSON.parse(provinces));
    }
    const country = localStorage.getItem("country");
    if (country) {
      setCountry(JSON.parse(country));
    }
  }

  const clearCheck = () => {
    setCompanyName("");
    setStockSymbol("");
    setSearchType("exact-phrase");
    setCity("");
    setProvinces([]);
    setCountry([]);
    setCompanyType([]);
    setCompanySubType([]);
    setIncorporationYear('');
    setJurisdiction('');
    setMajorShareholder('');
    setOptShareholderInterest10Prcnt(false);
    setOptForeignOwnership10Prcnt(false);
    setSubsidiary('');
    setDirectorOfficerName('');
    setAuditor('');
    setBanker('');
    setLawyer('');
    setTransferAgent('');
    setIdxSPTSXComp(false);
    setIdxSPTSX60(false);
    setIdxTSX30(false);
    setIdxTSXVenTier1(false);
    setIdxTSXVenTier2(false);
    setIdxTSXVenTier3(false);
    setIdxFP500(false);
    setIdxCleantech(false);
    setIdxBlockchain(false);
    setIdxCannabis(false);
    setGicCodes([]);
    setNaicsCodes([]);
    setNaicsCodeType('1');
    setSicCodes([]);
    setSicCodeType('1');
    setNormalCourseIssuerBid(false);
    setSubstantialIssuerBid(false);
    setDividendReinvestmentPlan(false);

    setFinSearchSortOpt('');
    setFinSearchSortDirection('0');

    localStorage.removeItem('basicSearchRadio');
  };

  // start
  /* companyName */
  const company = usePreviousStorageData(companyName) || "";
  React.useEffect(() => {
    if (company?.length !== companyName.length) {
      localStorage.setItem("companyNameCS", JSON.stringify(companyName));
    }
  }, [companyName]);

  /* stockSymbol */
  const stock = usePreviousStorageData(stockSymbol) || "";
  React.useEffect(() => {
    if (stock?.length !== stockSymbol.length) {
      localStorage.setItem("stockSymbol", JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  /* city */
  const cityName = usePreviousStorageData(city) || "";
  React.useEffect(() => {
    if (cityName?.length !== city.length) {
      localStorage.setItem("city", JSON.stringify(city));
    }
  }, [city]);

  /* provinces */
  const provinceName = usePreviousStorageData(provinces) || "";
  React.useEffect(() => {
    if (provinceName?.length !== provinces.length) {
      localStorage.setItem("provinces", JSON.stringify(provinces));
    }
  }, [provinces]);

  /* country */
  const countryName = usePreviousStorageData(country) || "";
  React.useEffect(() => {
    if (countryName?.length !== country.length) {
      localStorage.setItem("country", JSON.stringify(country));
    }
  }, [country]);
  //  end

  const surveySubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL("/corporate-surveys/results", baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);
    
    resultsUrl.searchParams.set('searchForm', 'basic');

    if (companyName) {
      resultsUrl.searchParams.set("companyName", companyName);
    }
    if (searchPredecessor) {
      resultsUrl.searchParams.set("searchPredecessor", `${searchPredecessor}`);
    }
    if (searchType) {
      resultsUrl.searchParams.set("searchType", searchType);
    }
    if (stockSymbol) {
      resultsUrl.searchParams.set("stockSymbol", stockSymbol);
    }
    if (city) {
      resultsUrl.searchParams.set("city", city);
    }
    resultsUrl.searchParams.set('provinces[]', provinces.join(','));

    const countries = countriesLookup
      .filter((p) => country.includes(p.id))
      .map((p) => p.name);
    resultsUrl.searchParams.set("country[]", countries.join(","));

    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  const surveyAdvancedSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL("/corporate-surveys/results", baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);

    resultsUrl.searchParams.set('searchForm', 'advanced');

    if (exchange) {
      resultsUrl.searchParams.set('exchange', exchange.join(','));
    }
    if (exchangeListed) {
      resultsUrl.searchParams.set('exchangeListed', exchangeListed ? 'true' : '');
    }
    if (exchangeSuspended) {
      resultsUrl.searchParams.set('exchangeSuspended', exchangeSuspended ? 'true' : '');
    }
    if (companyType) {
      resultsUrl.searchParams.set('companyType', companyType.join(','));
    }
    if (companySubType) {
      resultsUrl.searchParams.set('companySubType', companySubType.join(','));
    }
    if (jurisdiction) {
      resultsUrl.searchParams.set('jurisdiction', jurisdiction);
    }
    if (majorShareholder) {
      resultsUrl.searchParams.set('majorShareholder', majorShareholder);
    }
    if(incorporationYear) {
      resultsUrl.searchParams.set('incorporationYear', incorporationYear);
    }

    if (optShareholderInterest10Prcnt) {
      resultsUrl.searchParams.set('optShareholderInterest10Prcnt', optShareholderInterest10Prcnt ? 'true' : '');
    }

    if (optForeignOwnership10Prcnt) {
      resultsUrl.searchParams.set('optForeignOwnership10Prcnt', optForeignOwnership10Prcnt ? 'true' : '');
    }

    if(incorporationYear) {
      resultsUrl.searchParams.set('subsidiary', subsidiary);
    }

    if(directorOfficerName) {
      resultsUrl.searchParams.set('directorOfficerName', directorOfficerName);
    }

    if (auditor) {
      resultsUrl.searchParams.set('auditor', auditor);
    }
    if (banker) {
      resultsUrl.searchParams.set('banker', banker);
    }
    if (lawyer) {
      resultsUrl.searchParams.set('lawyer', lawyer);
    }
    if (transferAgent) {
      resultsUrl.searchParams.set('transferAgent', transferAgent);
    }
    if (idxSPTSXComp) {
      resultsUrl.searchParams.set('idxSPTSXComp', idxSPTSXComp ? 'true' : '');
    }
    if (idxSPTSX60) {
      resultsUrl.searchParams.set('idxSPTSX60', idxSPTSX60 ? 'true' : '');
    }
    if (idxTSX30) {
      resultsUrl.searchParams.set('idxTSX30', idxTSX30 ? 'true' : '');
    }
    if (idxTSXVenTier1) {
      resultsUrl.searchParams.set('idxTSXVenTier1', idxTSXVenTier1 ? 'true' : '');
    }
    if (idxTSXVenTier2) {
      resultsUrl.searchParams.set('idxTSXVenTier2', idxTSXVenTier2 ? 'true' : '');
    }
    if (idxTSXVenTier3) {
      resultsUrl.searchParams.set('idxTSXVenTier3', idxTSXVenTier3 ? 'true' : '');
    }
    if (idxFP500) {
      resultsUrl.searchParams.set('idxFP500', idxFP500 ? 'true' : '');
    }
    if (idxCleantech) {
      resultsUrl.searchParams.set('idxCleantech', idxCleantech ? 'true' : '');
    }
    if (idxCannabis) {
      resultsUrl.searchParams.set('idxCannabis', idxCannabis ? 'true' : '');
    }
    if (idxBlockchain) {
      resultsUrl.searchParams.set('idxBlockchain', idxBlockchain ? 'true' : '');
    }
    if (sicCodes) {
      resultsUrl.searchParams.set('sicCodes', sicCodes.join(','));
    }
    if (gicCodes) {
      resultsUrl.searchParams.set('gicCodes', gicCodes.join(','));
    }
    if(naicsCodes) {
      resultsUrl.searchParams.set('naicsCodes', naicsCodes.join(','));
    }
    if(naicsCodeType) {
      resultsUrl.searchParams.set('naicsCodeType', naicsCodeType);
    }
    if (sicCodeType) {
      resultsUrl.searchParams.set('sicCodeType', sicCodeType);
    }
    if (normalCourseIssuerBid) {
      resultsUrl.searchParams.set('normalCourseIssuerBid', normalCourseIssuerBid ? 'true' : '');
    }
    if (substantialIssuerBid) {
      resultsUrl.searchParams.set('substantialIssuerBid', substantialIssuerBid ? 'true' : '');
    }
    if (dividendReinvestmentPlan) {
      resultsUrl.searchParams.set('dividendReinvestmentPlan', dividendReinvestmentPlan ? 'true' : '');
    }

    // being financial search
    if (textSearch) {
      resultsUrl.searchParams.set('textSearch', textSearch);
    }
    
    resultsUrl.searchParams.set('finSearchSortDirection', finSearchSortDirection);
    resultsUrl.searchParams.set('finSearchSortOpt', finSearchSortOpt);

    resultsUrl.searchParams.set('category1', category1);
    resultsUrl.searchParams.set('dataItem1', dataItem1);
    resultsUrl.searchParams.set('year1', year1);
    resultsUrl.searchParams.set('boolOp1', boolOp1);
    resultsUrl.searchParams.set('operation1', operation1);
    resultsUrl.searchParams.set('value1', value1);

    if (rowsToShow >= 2 && active2) {
      resultsUrl.searchParams.set('category2', category2);
      resultsUrl.searchParams.set('dataItem2', dataItem2);
      resultsUrl.searchParams.set('year2', year2);
      resultsUrl.searchParams.set('boolOp2', boolOp2);
      resultsUrl.searchParams.set('operation2', operation2);
      resultsUrl.searchParams.set('value2', value2);
    }

    if (rowsToShow >= 3 && active3) {
      resultsUrl.searchParams.set('category3', category3);
      resultsUrl.searchParams.set('dataItem3', dataItem3);
      resultsUrl.searchParams.set('year3', year3);
      resultsUrl.searchParams.set('boolOp3', boolOp3);
      resultsUrl.searchParams.set('operation3', operation3);
      resultsUrl.searchParams.set('value3', value3);
    }

    if (rowsToShow >= 4 && active4) {
      resultsUrl.searchParams.set('category4', category4);
      resultsUrl.searchParams.set('dataItem4', dataItem4);
      resultsUrl.searchParams.set('year4', year4);
      resultsUrl.searchParams.set('boolOp4', boolOp4);
      resultsUrl.searchParams.set('operation4', operation4);
      resultsUrl.searchParams.set('value4', value4);
    }

    if (rowsToShow >= 5 && active5) {
      resultsUrl.searchParams.set('category5', category5);
      resultsUrl.searchParams.set('dataItem5', dataItem5);
      resultsUrl.searchParams.set('year5', year5);
      resultsUrl.searchParams.set('boolOp5', boolOp5);
      resultsUrl.searchParams.set('operation5', operation5);
      resultsUrl.searchParams.set('value5', value5);
    }
    // end financial search

    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  const advancedFormProps: ICorpSurveysAdvProps = {
    companyName,
    setCompanyName,
    exchange,
    setExchange,
    exchangeListed,
    setExchangeListed,
    exchangeSuspended,
    setExchangeSuspended,
    companyType,
    setCompanyType,
    incorporationYear,
    setIncorporationYear,
    companySubType,
    setCompanySubType,
    jurisdiction,
    setJurisdiction,
    majorShareholder,
    setMajorShareholder,
    optShareholderInterest10Prcnt,
    setOptShareholderInterest10Prcnt,
    optForeignOwnership10Prcnt,
    setOptForeignOwnership10Prcnt,
    subsidiary,
    setSubsidiary,
    directorOfficerName,
    setDirectorOfficerName,
    auditor,
    setAuditor,
    banker,
    setBanker,
    lawyer,
    setLawyer,
    transferAgent,
    setTransferAgent,
    idxSPTSXComp,
    setIdxSPTSXComp,
    idxSPTSX60,
    setIdxSPTSX60,
    idxTSX30,
    setIdxTSX30,
    idxTSXVenTier1,
    setIdxTSXVenTier1,
    idxTSXVenTier2,
    setIdxTSXVenTier2,
    idxTSXVenTier3,
    setIdxTSXVenTier3,
    idxFP500,
    setIdxFP500,
    idxCleantech,
    setIdxCleantech,
    idxCannabis,
    setIdxCannabis,
    idxBlockchain,
    setIdxBlockchain,
    gicCodes,
    setGicCodes,
    naicsCodes,
    setNaicsCodes,
    naicsCodeType,
    setNaicsCodeType,
    sicCodes,
    setSicCodes,
    sicCodeType,
    setSicCodeType,
    normalCourseIssuerBid,
    setNormalCourseIssuerBid,
    substantialIssuerBid,
    setSubstantialIssuerBid,
    dividendReinvestmentPlan,
    setDividendReinvestmentPlan,

    // begin financial search
    finSearchSortOpt,
    setFinSearchSortOpt,
    finSearchSortDirection,
    setFinSearchSortDirection,
    textSearch,
    setTextSearch,
    category1,
    setCategory1,
    dataItem1,
    setDataItem1,
    year1,
    setYear1,
    boolOp1,
    setBoolOp1,
    operation1,
    setOperation1,
    value1,
    setValue1,
    active2,
    setActive2,
    category2,
    setCategory2,
    dataItem2,
    setDataItem2,
    year2,
    setYear2,
    boolOp2,
    setBoolOp2,
    operation2,
    setOperation2,
    value2,
    setValue2,
    active3,
    setActive3,
    category3,
    setCategory3,
    dataItem3,
    setDataItem3,
    year3,
    setYear3,
    boolOp3,
    setBoolOp3,
    operation3,
    setOperation3,
    value3,
    setValue3,
    active4,
    setActive4,
    category4,
    setCategory4,
    dataItem4,
    setDataItem4,
    year4,
    setYear4,
    boolOp4,
    setBoolOp4,
    operation4,
    setOperation4,
    value4,
    setValue4,
    active5,
    setActive5,
    category5,
    setCategory5,
    dataItem5,
    setDataItem5,
    year5,
    setYear5,
    boolOp5,
    setBoolOp5,
    operation5,
    setOperation5,
    value5,
    setValue5,
    rowsToShow,
    setRowsToShow,
    // end financial search

    formSubmit: surveyAdvancedSubmitData,
    savedSearches: savedSearches,
    selectedSavedSearch: selectedSavedSearch,
    setSelectedSavedSearch: setSelectedSavedSearch,
    deleteSavedSearch: deleteSavedSearch,
    populateFromSavedSearch: populateFromSavedSearch,
    newSavedSearchName: newSavedSearchName,
    setNewSavedSearchName: setNewSavedSearchName,
    saveSearch: saveSearch,
  }

  return (

    <Page aria-labelledby={headingId}>
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
            Corporate Surveys - Basic Search
          </Heading>

          <Text mt="lg" lineHeight="md">
            Detailed corporate and investment information on all current and
            previously North American-listed active Canadian companies, FP 500
            companies and select Crown corporations.
          </Text>

          <Text mt="md" mb="md" color="light" lineHeight="md">
            Use only the search options that you need. Providing more details will
            narrow your search results.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              surveySubmitData();
            }}>
            <Tiles columns={[1, 2]}>
              <div>
                <TextField
                  mt="lg"
                  label="Company Name"
                  value={companyName}
                  onChange={setCompanyName}
                />
                <input
                  type="checkbox"
                  id="chk_search_predecessor"
                  defaultChecked={true}
                  onChange={(e) => {
                    setSearchPredecessor(e.target.checked);
                    }}
                  >
                      </input>
                <label htmlFor="chk_search_predecessor">
                  Include predecessor names in Search
                </label>
              </div>
              <div className="searchpage-searchtype">
                <RadioList
                  aria-label="Search Type"
                  value={searchType}
                  // onChange={setSearchType}
                  onChange={(e) => {
                    localStorage.setItem('basicSearchRadio', `${e}`);
                    setSearchType(e);
                          }}
                  options={searchTypePredecessorLookup}
                />
              </div>
            </Tiles>

            <TextField
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />

            <TextField
              mt="lg"
              mb="lg"
              label="City"
              value={city}
              onChange={setCity}
            />

            <MultiSelectField
              label="Province"
              items={provincesLookupbyAlphaCode}
              value={provinces}
              onChange={setProvinces}
            />

            {/* TODO -- we should add the styled component props to the MultiSelectField so that we can just apply mb='lg' to avoid this clearfix div  */}
            <div
              style={{ clear: "both", width: "100%", marginBottom: "20px" }}
            ></div>

            <MultiSelectField
              label="Countries"
              items={countriesLookup}
              value={country}
              onChange={setCountry}
            />

            <Button
              mt="lg"
              label="Search"
              mb={"lg"}
              type="submit"
            />
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>

        <Tab label="Advanced">
          <Heading id={headingIdAdvanced} level="1" size={["xxl", "xxl", "xxxl"]}>
            Corporate Surveys - Advanced Search
          </Heading>
          <CorporateSurveysAdvanceTab {...advancedFormProps} />
        </Tab>
      </Tabs>

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />

    </Page>
  );
}
