import React from 'react';
import { TextField } from '../TextField';
import { Button } from '../Button';
import styled from 'styled-components';
import passwordSchema from './schema';
import { Formik, Form } from 'formik';

import { FPRow } from '../FPRow/FPRow';
import { FPCol } from '../FPCol/FPCol';

import toast, { Toaster } from 'react-hot-toast';

const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink'
})``;

/**
 * Check Icon toggle for Edit password reference
 */
export const CheckIcon = ({ checked = true }: { checked?: boolean }) => (
  <Svg viewBox="0 0 10 10" height="12" width="12">
    {checked
? (
      <>
        <path
          d="M4.28571 9L0 4.68138L1.20857 3.46353L4.28571 6.55566L10.7914 0L12 1.22649L4.28571 9Z"
          fill="#00754E"
        />
      </>
    )
: (
      <>
        <path
          d="M9.66671 1.27334L8.72671 0.333344L5.00004 4.06001L1.27337 0.333344L0.333374 1.27334L4.06004 5.00001L0.333374 8.72668L1.27337 9.66668L5.00004 5.94001L8.72671 9.66668L9.66671 8.72668L5.94004 5.00001L9.66671 1.27334Z"
          fill="#950000"
        />
      </>
    )}
  </Svg>
);

const PassReference = styled.div`
  position: inherit;
`;

interface IProps {
  changePassword: (props: any) => Promise<boolean | undefined>;
  changePasswordError: any;
  clearChangePasswordError: () => void;
  isChangingPassword: boolean;
  onCancel: () => void;
}

const EditPassword: React.FC<IProps> = ({
  changePassword,
  changePasswordError,
  clearChangePasswordError,
  isChangingPassword,
  onCancel
}) => {
  const handleSubmit = async (values: any) => {
    const success = await changePassword({
      old_password: values.password,
      new_password1: values.newPassword,
      new_password2: values.confirmPassword
    });

    if (success) {
      toast.success('Account password updated.');
    } else {
      toast.error('Error: The Current Password is not correct.');
    }
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ password: '', newPassword: '', confirmPassword: '' }}
        validationSchema={passwordSchema}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          setErrors,
          setFieldTouched
        }) => (
          <Form>
            <FPRow>
              <FPCol xs={12} lg={7} xl={5} xxl={6}>
                <TextField
                  mt="lg"
                  onBlur={() => {
                    setFieldTouched('password');
                  }}
                  value={values.password}
                  onChange={(value) => {
                    setFieldValue('password', value);
                  }}
                  label="Current Password"
                  type="password"
                  error={
                    errors.password && touched.password
                      ? errors.password
                      : undefined
                  }
                />
                <TextField
                  mt="lg"
                  onBlur={() => {
                    setFieldTouched('newPassword');
                  }}
                  value={values.newPassword}
                  onChange={(value) => {
                    setFieldValue('newPassword', value);
                  }}
                  label="New Password"
                  type="password"
                  error={
                    errors.newPassword && touched.newPassword
                      ? errors.newPassword
                      : undefined
                  }
                />
              </FPCol>
              <FPCol xs={12} lg={7} xl={5} xxl={6}>
                <PassReference>
                  <h4>Your password must:</h4>
                  <p>
                    <CheckIcon checked={!(values.newPassword.length < 4)} />{' '}
                    Contain a minimum of 4 characters
                  </p>
                  <p>
                    <CheckIcon
                      checked={Boolean(
                        values.newPassword.match(/^(?=.*[a-z]).*$/)
                      )}
                    />{' '}
                    Contain lowercase letters
                  </p>
                  <p>
                    <CheckIcon
                      checked={Boolean(
                        values.newPassword.match(/^(?=.*[0-9]).*$/)
                      )}
                    />{' '}
                    Contain a number
                  </p>
                  <p>
                    <CheckIcon
                      checked={Boolean(values.newPassword.match(/^(?=.*_).*$/))}
                    />{' '}
                    Contain a underscore
                  </p>
                </PassReference>
              </FPCol>
            </FPRow>
            <FPRow>
              <FPCol xs={12} lg={7} xl={5} xxl={6}>
                <TextField
                  mt="lg"
                  onBlur={() => {
                    setFieldTouched('confirmPassword');
                  }}
                  value={values.confirmPassword}
                  onChange={(value) => {
                    setFieldValue('confirmPassword', value);
                  }}
                  label="Confirm Password"
                  type="password"
                  error={
                    errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : undefined
                  }
                />
                <Button label="Save" size="md" type="submit" />
                <Button
                  noBorder={true}
                  mt="lg"
                  ml="lg"
                  label="Cancel"
                  design="secondary"
                  size="md"
                  onClick={() => onCancel()}
                />
              </FPCol>
            </FPRow>
          </Form>
        )}
      </Formik>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />
    </div>
  );
};

export default EditPassword;
