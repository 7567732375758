import * as React from 'react';
import { Row } from '../Row';
import { useId } from '../../hooks';
import { useTheme } from 'styled-components/macro';
import { Text } from '../../components/Text';
import { Tiles } from '../../components/Tiles';

export function SelectFieldLtGt (props: {
	title: string;
	placeholder?: string;

	optionsLt: Array<{ id: string, name: string }>;
	valueLt?: string;
	setValueLt: React.Dispatch<React.SetStateAction<string>>
  ariaLabelLt?: string;

	optionsGt: Array<{ id: string, name: string }>;
	valueGt?: string;
	setValueGt: React.Dispatch<React.SetStateAction<string>>
  ariaLabelGt?: string;
}) {
	const idGt = useId();
  const idLt = useId();
	const theme = useTheme();

	const {
		title,
		placeholder,
		optionsLt,
		valueLt,
		setValueLt,
		optionsGt,
		valueGt,
		setValueGt,
    ariaLabelLt,
    ariaLabelGt
	} = props;

  return (
		<div style={{ marginTop: theme.spacing.md }}>
      {title.length > 0
      ? <label htmlFor={idGt} style={{ display: 'block', marginTop: '20px', fontWeight: 500 }}>
        {title}
      </label>
      : ''}
      <Tiles columns={[1, 2]} aria-label={title}>
        <div>
          <Text color="light" size="sm" mt="xxs" id={idGt}>
          Equal or Greater Than...
          </Text>
          <select
            aria-labelledby={idGt}
            value={valueGt}
            onChange={(e) => { setValueGt(e.target.value) }}
            style={{ marginRight: theme.spacing.md }}
            placeholder="Input"
          >
            { optionsGt.map(o => (
              <option value={ o.id }>{ o.name }</option>
            )) }
          </select>
        </div>
        <div>
          <Text color="light" size="sm" mt="xxs" id={idLt}>
            Less Than...
          </Text>
          <select
            aria-labelledby={idLt}
            value={valueLt}
            onChange={(e) => { setValueLt(e.target.value) }}
            style={{ marginRight: theme.spacing.md }}
          >
            { optionsLt.map(o => (
              <option value={ o.id }>{ o.name }</option>
            )) }
          </select>
        </div>
      </Tiles>
		</div>
  );
}
/*
SelectFieldLtGt.defaultProps = {
	placeholder: '--',
}
*/
