import React from 'react';
import { StarIcon } from '../../icons/StarIcon';
import { TextField } from '../../components/TextField';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Row } from '../../components/Row';
import { ScreenReaderOnly } from '../../components/ScreenReaderOnly';
import { ISavedSearchSection } from '../../containers/AuthProvider';
// import './index.css';

interface SavedSearchBottom {
  savedSearches: ISavedSearchSection[];
  selectedSavedSearch?: string;
  newSavedSearchName?: string;
  setNewSavedSearchName: React.Dispatch<React.SetStateAction<string>>;
  saveSearch: () => void;
  deleteSavedSearch: (searchName:string, searchType: string) => void;
}

export const SavedSearchBottom: React.FC<SavedSearchBottom> = ({ savedSearches, selectedSavedSearch, setNewSavedSearchName, newSavedSearchName, saveSearch, deleteSavedSearch }) => {
  return (
    <div>
      <Heading level="2" size="lg" mb="sm" mt="lg">
        Save Search
      </Heading>
      <Row>
        <div style={{ maxWidth: '90%' }}>
          <TextField
            label="Name of search form"
            value={newSavedSearchName || ''}
            onChange={setNewSavedSearchName}
          />
          <Text color="light" size="sm" mt="xxs">
            Give your search form a meaningful name
          </Text>
        </div>
        <div>
          { ((savedSearches.find(s => s.searchName === newSavedSearchName)
            ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch || '', 'advanced') } }>
              <StarIcon size='sm' />
              <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
            </button>)
            : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={saveSearch}>
              <StarIcon size='sm' color='clear' />
              <ScreenReaderOnly>Save search</ScreenReaderOnly>
            </button>
          )))}
        </div>
      </Row>
    </div>
  )
};
