import * as React from 'react';
import { useParams, useHistory } from 'react-router';
import { Page } from '../../../../components/Page';
import { Heading } from '../../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../../hooks';
import { TextField } from '../../../../components/TextField';
import { Button } from '../../../../components/Button';
import ClearButton from '../../../../components/ClearButton';
import { Text } from '../../../../components/Text';
import { RadioList } from '../../../../components/RadioList';
import { MultiSelectField } from '../../../../components/MultiSelectField';
import {
  positionsLookup,
  provincesLookup,
  schoolsLookup,
  specialtiesLookup,
  fellowshipsLookup,
  degreesLookup,
} from '../../../../data/lookups';
import moment from 'moment';
import { setISODay } from 'date-fns';

export function LeadListGeneratorSearchDirectoryOfDirectorsPage () {
  const history = useHistory();
  const headingId = useId();

  const [companyName, setCompanyName] = React.useState('');
  const [city, setCity] = React.useState('');
  const [companyNameType, setCompanyNameType] =
    React.useState('current-position');
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [position, setPosition] = React.useState<string[]>([]);
  const [gender, setGender] = React.useState('either');

  const [school, setSchool] = React.useState<string[]>([]);
  const [degree, setDegree] = React.useState<string[]>([]);
  const [speciality, setSpeciality] = React.useState<string[]>([]);
  const [fellowship, setFellowship] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  /* companyName */
  const companyPrev = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (companyPrev !== companyName) {
      localStorage.setItem('companyNameLGDD', JSON.stringify(companyName));
    }
  }, [companyName]);

  /* companyNameType */
  const companyTypePrev = usePreviousStorageData(companyNameType) || '';
  React.useEffect(() => {
    if (companyTypePrev !== companyNameType) {
      localStorage.setItem('companyNameTypeLGDD', JSON.stringify(companyNameType));
    }
  }, [companyNameType]);

  /* city */
  const cityPrev = usePreviousStorageData(city) || '';
  React.useEffect(() => {
    if (cityPrev !== city) {
      localStorage.setItem('cityLGDD', JSON.stringify(city));
    }
  }, [city]);

  /* provinces */
  const provincesPrev = usePreviousStorageData(provinces) || '';
  React.useEffect(() => {
    localStorage.setItem('provincesLGDD', JSON.stringify(provinces));
  }, [provinces]);

  /* position */
  const positionPrev = usePreviousStorageData(position) || '';
  React.useEffect(() => {
    localStorage.setItem('positionLGDD', JSON.stringify(position));
  }, [position]);

  /* gender */
  const genderPrev = usePreviousStorageData(gender) || '';
  React.useEffect(() => {
    if (genderPrev !== gender) {
      localStorage.setItem('genderLGDD', JSON.stringify(gender));
    }
  }, [gender]);
//
  /* school */
  const schoolPrev = usePreviousStorageData(school) || '';
  React.useEffect(() => {
    localStorage.setItem('schoolLGDD', JSON.stringify(school));
  }, [school]);

  /* degree */
  const degreePrev = usePreviousStorageData(degree) || '';
  React.useEffect(() => {
    localStorage.setItem('degreeLGDD', JSON.stringify(degree));
  }, [degree]);

  /* specialty */
  const specialityPrev = usePreviousStorageData(speciality) || '';
  React.useEffect(() => {
    localStorage.setItem('specialityLGDD', JSON.stringify(speciality));
  }, [speciality]);

  /* fellowship */
  const fellowshipPrev = usePreviousStorageData(fellowship) || '';
  React.useEffect(() => {
    localStorage.setItem('fellowshipLGDD', JSON.stringify(fellowship));
  }, [fellowship]);

  const loadLatestEntries = () => {
    const companyName = localStorage.getItem('companyNameLGDD');
    if (companyName) {
      setCompanyName(JSON.parse(companyName));
    }
    const companyNameType = localStorage.getItem('companyNameTypeLGDD');
    if (companyNameType) {
      setCompanyNameType(JSON.parse(companyNameType));
    }
    const city = localStorage.getItem('cityLGDD');
    if (city) {
      setCity(JSON.parse(city));
    }
    const provinces = localStorage.getItem('provincesLGDD');
    if (provinces) {
      setProvinces(JSON.parse(provinces));
    }
    const position = localStorage.getItem('positionLGDD');
    if (position) {
      setPosition(JSON.parse(position));
    }
    const gender = localStorage.getItem('genderLGDD');
    if (gender) {
      setGender(JSON.parse(gender));
    }
    const school = localStorage.getItem('schoolLGDD');
    if (school) {
      setSchool(JSON.parse(school));
    }
    const degree = localStorage.getItem('degreeLGDD');
    if (degree) {
      setDegree(JSON.parse(degree));
    }
    const speciality = localStorage.getItem('specialityLGDD');
    if (speciality) {
      setSpeciality(JSON.parse(speciality));
    }
    const fellowship = localStorage.getItem('fellowshipLGDD');
    if (fellowship) {
      setFellowship(JSON.parse(fellowship));
    }
  }

  const clearCheck = () => {
    setCompanyName('');
    setCompanyNameType('current-position');
    setCity('');
    setProvinces([]);
    setPosition([]);
    setGender('either');
    setSchool([]);
    setDegree([]);
    setSpeciality([]);
    setFellowship([]);
  }

  const leadListDirectorySubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL(
      '/lead-list-generator/results/fp-directory-of-directors',
      baseUrl
    );
    const newDate = new Date()
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set('companyName', companyName);
    }
    if (city) {
      resultsUrl.searchParams.set('city', city);
    }
    const province = provincesLookup.filter((p) => provinces.includes(p.name)).map(p => p.id)
    resultsUrl.searchParams.set('provinces[]', province.join(','))

    if (companyNameType) {
      resultsUrl.searchParams.set('companyNameType', companyNameType);
    }
    if (position && position.length > 0) {
      resultsUrl.searchParams.set('position', position.join(', '));
    }
    if (gender) {
      resultsUrl.searchParams.set('gender', gender);
    }
    if (school && school.length > 0) {
      resultsUrl.searchParams.set('school', school.join(', '));
    }
    if (degree && degree.length > 0) {
      resultsUrl.searchParams.set('degree', degree.join(', '));
    }
    if (speciality && speciality.length > 0) {
      resultsUrl.searchParams.set('speciality', speciality.join(', '));
    }
    if (fellowship && fellowship.length > 0) {
      resultsUrl.searchParams.set('fellowship', fellowship.join(', '));
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), { data: true });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
        Lead List Generator - Directory of Directors - Search
      </Heading>

      <Text mt="sm" lineHeight="md">
        Name and address information on 28,000 Canadian directors and executives
        of Canadian companies.
      </Text>

      <form
        aria-labelledby={headingId}
        onSubmit={(e) => {
          e.preventDefault();
          leadListDirectorySubmitData();
        }}>
        <TextField
          mt="lg"
          mb="sm"
          label="Company Name"
          value={companyName}
          onChange={setCompanyName}
        />
        <RadioList
          aria-label="Search Type"
          flex
          value={companyNameType}
          onChange={setCompanyNameType}
          options={[
            {
              value: 'current-position',
              label: 'Current Position',
            },
            {
              value: 'previous-position',
              label: 'Previous Position',
            },
            {
              value: 'either',
              label: 'Either',
            },
          ]}
        />

        <TextField mt="lg" label="City" value={city} onChange={setCity} />

        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Province"
            items={provincesLookup}
            value={provinces}
            onChange={setProvinces}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Position"
            items={positionsLookup}
            value={position}
            onChange={setPosition}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <label htmlFor="gender">Gender</label>
          <br /> <br />
          <RadioList
            flex
            aria-label="Search Type"
            value={gender}
            onChange={setGender}
            options={[
              {
                value: 'Male',
                label: 'Male',
              },
              {
                value: 'Female',
                label: 'Female',
              },
              {
                value: 'either',
                label: 'Either',
              },
            ]}
          />
        </div>

        <Heading level="3">Education</Heading>
        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="School"
            items={schoolsLookup}
            value={school}
            onChange={setSchool}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Degree"
            items={degreesLookup}
            value={degree}
            onChange={setDegree}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Specialty"
            items={specialtiesLookup}
            value={speciality}
            onChange={setSpeciality}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Fellowship/Association"
            items={fellowshipsLookup}
            value={fellowship}
            onChange={setFellowship}
          />
        </div>
        <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
     </form>
      <ClearButton onClick={clearCheck} className="clearstatus" />
    </Page>
  );
}
