import { DefaultTheme } from 'styled-components/macro';
import { theme } from '../theme';

export interface ResponsiveVisibilityProps {
  hideAbove?: 'tablet' | 'mobile';
  hideBelow?: 'desktop';
}

export function getResponsiveVisibility (
  props: ResponsiveVisibilityProps & { theme: DefaultTheme }
) {
  if (props.hideBelow === 'desktop') {
    return {
      [`@media(max-width: calc(${props.theme.breakpoints.md} - 1px))`]: {
        display: 'none',
      },
    };
  }

  if (props.hideAbove === 'mobile') {
    return {
      [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
        display: 'none',
      },
    };
  }

  if (props.hideAbove === 'tablet') {
    return {
      [`@media(min-width: ${props.theme.breakpoints.md})`]: {
        display: 'none',
      },
    };
  }

  return {};
}
export interface MarginProps {
  ml?: keyof DefaultTheme['spacing'] | Array<keyof DefaultTheme['spacing']>;
  mr?: keyof DefaultTheme['spacing'] | Array<keyof DefaultTheme['spacing']>;
  mt?: keyof DefaultTheme['spacing'] | Array<keyof DefaultTheme['spacing']>;
  mb?: keyof DefaultTheme['spacing'] | Array<keyof DefaultTheme['spacing']>;
}

export function getMarginStyles (props: MarginProps & { theme: DefaultTheme }) {
  const marginObj: React.CSSProperties = {};
  if (typeof props.ml === 'string') {
    marginObj.marginLeft = theme.spacing[props.ml];
  }

  if (typeof props.mr === 'string') {
    marginObj.marginRight = theme.spacing[props.mr];
  }

  if (typeof props.mb === 'string') {
    marginObj.marginBottom = theme.spacing[props.mb];
  }

  if (typeof props.mt === 'string') {
    marginObj.marginTop = theme.spacing[props.mt];
  }

  return marginObj;
}

export const clearButtonStyles: React.CSSProperties = {
  fontSize: 'inherit',
  cursor: 'pointer',
  padding: 0,
  border: 'none',
  color: 'inherit',
  background: 'transparent',
};

// https://stackoverflow.com/a/63715429
// type CamelizeString<T extends PropertyKey> =
//		 T extends string ? string extends T ? string :
//		 T extends `${infer F}_${infer R}` ? `${F}${Capitalize<CamelizeString<R>>}` : T : T;

// type Camelize<T> = { [K in keyof T as CamelizeString<K>]: T[K] }
