import * as React from 'react';
import { Company, IAddress } from '../../data';
import { Accordion } from '../../components/Accordion';
import { Text } from '../../components/Text';
import { Heading } from '../../components/Heading';
import { useId } from '../../hooks';
import { ScreenReaderAvoidSplitting } from '../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../components/Row';
import { PeopleIcon } from '../../icons/PeopleIcon';
import { ApartmentIcon } from '../../icons/ApartmentIcon';
import { TrophyIcon } from '../../icons/TrophyIcon';
import { Box, BoxProps } from '../../components/Box';
import { Tiles } from '../../components/Tiles';
import { Link } from 'react-router-dom';
import { CompanyIndustryIcon } from 'icons/CompanyIndustryIcon';

export function CompanyInformation (props: {
  isLoading: boolean;
  company?: Company;
  hideCorporateDescription?: boolean;
  expand?: boolean;
}) {
  const { isLoading, company, hideCorporateDescription, expand } = props;

  const renderAddresses = (address: IAddress) => {
    return (
      <>
        <Text loading={isLoading} loadingWidth={150} mb="xxs">
          {address?.line1}
        </Text>
        <Text loading={isLoading} mb="xxs">
          {address?.city}, {address?.province}
        </Text>
        <Text loading={isLoading} mb="xxs">
          {address?.postalCode}
        </Text>
      </>
    );
  };

  return (
    <Accordion openOnLoad={expand || true}>
      <Accordion.Heading size="xl" level="2" weight="semi-bold">
        Company Information
      </Accordion.Heading>
      <Accordion.Content>
        <Tiles columns={[1, 2]}>
          <CompanyInfoSection title="Company" icon={<ApartmentIcon />}>
            <Text weight="semi-bold">Head Office</Text>
            <Text loading={isLoading} loadingWidth={200} mb="xxs">
              {company?.name}
            </Text>
            {company?.address && renderAddresses(company?.address)}
            {(isLoading || company?.contact.phone) && (
              <Text loading={isLoading} mb="xxs">
                Tel: {company?.contact.phone}
              </Text>
            )}
            {company?.contact.tollfree && (
              <Text loading={isLoading} mb="xxs">
                {' '}
                Toll-Free:{company?.contact.tollfree}
              </Text>
            )}
            {(isLoading || company?.contact.fax) && (
              <Text loading={isLoading} mb="xxs">
                Fax: {company?.contact.fax}
              </Text>
            )}
            <Text loading={isLoading} loadingWidth={200} mb="xxs">
              {company?.contact?.email}
            </Text>
            {company?.contact.webaddress && (
              <Text loading={isLoading} mb="xxs">
                {company?.contact?.webaddress}
              </Text>
            )}{' '}
            {/* URL */}
            <Text loading={isLoading} mb="xxs" weight="semi-bold">
              {' '}
              Investor relations Contact:
              <br />
            </Text>
            {(isLoading || company?.contact.contactName) && (
              <Text loading={isLoading} mb="xxs">
                {company?.contact.contactName}
              </Text>
            )}
            {(isLoading || company?.contact.contact) && (
              <Text loading={isLoading} mb="xxs">
                Tel:{company?.contact.contact}
              </Text>
            )}
            {company?.contact.email && (
              <Text loading={isLoading} mb="xxs">
                {' '}
                {company?.contact.email}
              </Text>
            )}
          </CompanyInfoSection>
          <div>
            <CompanyInfoSection title="Key Executives" icon={<PeopleIcon />}>
              {isLoading && (
                <>
                  <Text loading loadingWidth={150} mb="xxs">
                    {' '}
                  </Text>
                  <Text loading loadingWidth={120} mb="xxs">
                    {' '}
                  </Text>
                </>
              )}
              {!isLoading &&
                company?.keyExecutives?.map((executive) => (
                  <Link
                    to={`/person-search/results?name=${encodeURIComponent(
                      executive.name
                    )}&dbs=fpsu,fpdd,dcfc,tmga,tmgw`}
                    className="black-text"
                  >
                    <Text
                      mt="md"
                      color="main"
                      textDecoration="none"
                      loading={isLoading}
                      loadingWidth={200}
                      size="md"
                      weight="medium"
                    >
                      {executive.name}
                    </Text>
                  </Link>
                ))}
              {company?.executiveOffice && (
                <>
                  <Text weight="semi-bold" mt="md">
                    Executive Office:
                  </Text>
                  <Text loading={isLoading} mb="xxs">
                    {company?.executiveOffice?.line1}
                  </Text>
                  <Text loading={isLoading} mb="xxs">
                    {company?.executiveOffice?.city},
                    {company?.executiveOffice?.province}
                  </Text>
                  <Text loading={isLoading} mb="xxs">
                    {company?.executiveOffice?.postalCode}
                  </Text>
                </>
              )}
            </CompanyInfoSection>
            <div className="industry-info">
              <CompanyInfoSection
                mt="lg"
                title="Industry"
                icon={<CompanyIndustryIcon />}
              >
                <Text loading={isLoading} mb="xxs">
                  <ScreenReaderAvoidSplitting>
                    {company?.industry.name
                      ? `${company.industry.id} - ${company.industry.name}`
                      : 'No industry on file'}
                  </ScreenReaderAvoidSplitting>
                </Text>
              </CompanyInfoSection>
            </div>
          </div>
          {(company?.fp500.year || isLoading) && (
            <CompanyInfoSection
              title={
                <ScreenReaderAvoidSplitting>
                  FP 500
                  {company?.fp500.year
                    ? `, ${company.fp500.year} Ranking`
                    : null}
                </ScreenReaderAvoidSplitting>
              }
              icon={<TrophyIcon />}
            >
              <Text loading={isLoading} mb="xxs">
                Sales: {company?.fp500.salesRank}; Assets:{' '}
                {company?.fp500.assetsRank}; Income: {company?.fp500.incomeRank}
              </Text>
            </CompanyInfoSection>
          )}
        </Tiles>
        <Tiles columns={[1, 1]}>
          {!hideCorporateDescription && (company?.description || isLoading) && (
            <Text loading={isLoading} mb="xxs" mt="lg">
              {company?.description}
            </Text>
          )}
        </Tiles>
      </Accordion.Content>
      <Accordion.Footer size="xl" level="2" weight="semi-bold">
        <div></div>
      </Accordion.Footer>
    </Accordion>
  );
}

function CompanyInfoSection (props: {
  mt?: BoxProps['mt'];
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
}) {
  const headingId = useId();

  return (
    <Row alignItems="flex-start" mt={props.mt}>
      <div aria-hidden style={{ minWidth: '24px', paddingTop: '5px' }}>
        {props.icon}
      </div>
      <Box as="section" aria-labelledby={headingId} ml="sm">
        <Heading mt="xxs" id={headingId} size="lg" level="3" mb="sm">
          {props.title}
        </Heading>
        {props.children}
      </Box>
    </Row>
  );
}
