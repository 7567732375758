import { kMaxLength } from 'buffer';
import * as React from 'react';
import styled, { keyframes, css, DefaultTheme } from 'styled-components/macro';
import { MarginProps, getMarginStyles } from '../../utils/style';
import { useCurrentBackground } from '../BackgroundProvider';
import { LoadingScreenReaderMessage } from '../LoadingScreenReaderMessage';

import { createMediaQueries } from '../../utils/create-media-queries';
import { isArray } from 'util';

interface TextProps extends MarginProps {
  id?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  loading?: boolean;
  loadingWidth?: number;
  weight?: 'regular' | 'medium' | 'semi-bold' | 'bold';
  children: React.ReactNode;
  size?:
    | keyof DefaultTheme['typography']['textSizes']
    | Array<keyof DefaultTheme['typography']['textSizes']>;
  alignX?: 'center' | 'right';
  alignY?: 'top' | 'middle' | 'bottom';
  padding?: string;
  color?: 'primary' | 'heading' | 'main' | 'light' | 'error';
  lineHeight?: 'sm' | 'md';
  display?: 'inline';
  textDecoration?: 'none' | 'underline';
  maxLength?: number | undefined;
}

const StyledText = styled.span<
  TextProps & { currentBackground: 'dark' | 'light' }
>((props) => ({
  display: props.display === 'inline' ? 'inline' : 'block',
  fontWeight:
    props.weight === 'bold'
      ? 700
      : props.weight === 'semi-bold'
      ? 600
      : props.weight === 'medium'
      ? 500
      : 400,
  textDecoration: props.textDecoration,
  lineHeight: props.lineHeight === 'md' ? '1.5em' : '1.33em',
  // fontSize: props.theme.typography.textSizes[props.size || "md"],
  margin: 0,
  padding: props.padding,
  textAlign: props.alignX || 'left',
  verticalAlign: props.alignY || 'middle',
  color:
    props.currentBackground === 'dark'
      ? 'white'
      : props.color === 'primary'
      ? props.theme.palette.primary.main
      : props.color === 'heading'
      ? '#333333'
      : props.color === 'light'
      ? props.theme.palette.text.light
      : props.color === 'error'
      ? props.theme.palette.error.main
      : props.theme.palette.text.main,
  ...getMarginStyles(props),
  ...createMediaQueries([
    {
      property: 'fontSize',
      values: Array.isArray(props.size)
        ? props.size.map((s) => props.theme.typography.textSizes[s])
        : [props.theme.typography.textSizes[props.size || 'md']],
    },
  ]),
}));

const gradientAnimation = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`;

const LoadingIndicator = styled.div<{
  loadingFontSize?: keyof DefaultTheme['typography']['textSizes'];
  loadingWidth?: number;
}>`
  font-size: ${(props) =>
    props.theme.typography.textSizes[props.loadingFontSize || 'md']};
  height: 1em;
  width: ${(props) => (props.loadingWidth || 100) / 16}em;
  max-width: 100%;
  border-radius: 2px;

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${gradientAnimation};
  animation-timing-function: linear;
  background: #f4f4f4;
  background: linear-gradient(to right, #f4f4f4 8%, #e3e3e3 38%, #f4f4f4 54%);
  background-size: 1000px 640px;
  position: relative;
`;

const Spacer = styled.div<TextProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: props.alignX === 'center' ? 'center' : 'flex-start',
  ...getMarginStyles(props),
}));

export function Text (props: TextProps) {
  const currentBackground = useCurrentBackground();
  const { loading, ...otherProps } = props;
  if (loading) {
    return (
      <Spacer
        alignX={props.alignX}
        mt={props.mt}
        mb={props.mb}
        ml={props.ml}
        mr={props.mr}
      >
        <LoadingIndicator
          id={props.id}
          loadingFontSize={
            Array.isArray(props.size) ? props.size[0] : props.size
          }
          loadingWidth={props.loadingWidth}
        >
          <LoadingScreenReaderMessage loading={loading} />
        </LoadingIndicator>
      </Spacer>
    );
  }

  if (
    otherProps.maxLength &&
    otherProps.children &&
    typeof otherProps.children === 'string' &&
    otherProps.children.length > otherProps.maxLength
  ) {
    otherProps.children = `${otherProps.children.substr(
      0,
      otherProps.maxLength
    )}...`;
  }

  return (
    <StyledText
      id={props.id}
      {...otherProps}
      currentBackground={currentBackground}
    />
  );
}
