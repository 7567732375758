import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { config } from '../../../config';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { useLeadListGeneratorSearchResults } from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { Link } from 'react-router-dom';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import queryString from 'query-string';
import './index.scss';
import { companySubTypesExtendedLookup, companyTypesExtendedLookup, contactTypeSearchLookup, countriesLookup, exchangesLookup, gicsLookup, jurisdictionLookup, provincesLookup, sicCodeTypeLookup, sicLookup, titleValues, transferAgentLookup } from 'data/lookups';
import { useSearchParams } from 'components/UseSearchParams';

export function LeadListGeneratorSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();

  const params = useParams<{ dbCode: string }>();

  const queryParams = useQueryParams(
    [
      'country[]',
      'contacttype',
      'sicCodeType',
      'companyName',
      'street',
      'city',
      'address',
      'provinces[]',
      'exchange[]',
      'areaCode',
      'locationType',
      'companyType[]',
      'companySubType[]',
      'jurisdiction',
      'sicCodes[]',
      'gicCodes[]',
      'employeesGT',
      'employeesLT',
      'companyNameType',
      'position',
      'gender',
      'school',
      'degree',
      'speciality',
      'fellowship',
      'majorShareholder',
      'idxSPTSXComp',
      'idxSPTSX60',
      'idxTSX30',
      'idxTSXVenTier1',
      'idxTSXVenTier2',
      'idxTSXVenTier3',
      'idxFP500',
      'idxCleantech',
      'idxCannabis',
      'idxBlockchain',
      'banker',
      'auditor',
      'normalCourseIssuerBid',
      'substantialIssuerBid',
      'dividendReinvestmentPlan',
      'exchangeListed',
      'exchangeSuspended',
      'lawyer',
      'transferAgent',
      'dateInfo',
      'companyTypes',
      'sicCode',
      // 'provinces'
    ],
    { onlyStrings: true }
  );

  const [page, setPage] = React.useState(1);
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const setNumberOfRowsInContext = useSetNumberOfRows();

  const searchResults = useLeadListGeneratorSearchResults({
    country: queryParams['country[]'] || '',
    companyType: queryParams['companyType[]'] || '',
    companySubType: queryParams['companySubType[]'] || '',
    companyTypes: queryParams.companyTypes || '',
    jurisdiction: queryParams.jurisdiction || '',
    contacttype: queryParams.contacttype || '',
    sicCodeType: queryParams.sicCodeType || '',
    majorShareholder: queryParams.majorShareholder || '',
    idxSPTSXComp: queryParams.idxSPTSXComp ? '1' : '',
    idxSPTSX60: queryParams.idxSPTSX60 ? '1' : '',
    idxTSX30: queryParams.idxTSX30 ? '1' : '',
    idxTSXVenTier1: queryParams.idxTSXVenTier1 ? '1' : '',
    idxTSXVenTier2: queryParams.idxTSXVenTier2 ? '1' : '',
    idxTSXVenTier3: queryParams.idxTSXVenTier3 ? '1' : '',
    idxFP500: queryParams.idxFP500 ? '1' : '',
    idxCleantech: queryParams.idxCleantech ? '1' : '',
    // idxCannabis: queryParams.idxCannabis ? '1' : '',
    // idxBlockchain: queryParams.idxBlockchain ? '1' : '',
    exchangeListed: queryParams.exchangeListed ? 'y' : '',
    exchangeSuspended: queryParams.exchangeSuspended ? 'y' : '',
    normalCourseIssuerBid: queryParams.normalCourseIssuerBid ? '1' : '',
    substantialIssuerBid: queryParams.substantialIssuerBid ? '1' : '',
    dividendReinvestmentPlan: queryParams.dividendReinvestmentPlan ? '1' : '',
    banker: queryParams.banker || '',
    auditor: queryParams.auditor || '',
    lawyer: queryParams.lawyer || '',
    transferAgent: queryParams.transferAgent || '',
    dbCode: params.dbCode || '',
    companyName: queryParams.companyName || '',
    street: queryParams.street || '',
    city: queryParams.city || '',
    address: queryParams.address || '',
    provinces: queryParams['provinces[]'] || '',
    exchange: queryParams['exchange[]'] || '',
    areaCode: queryParams.areaCode || '',
    locationType: queryParams.locationType || undefined,
    sicCodes: queryParams['sicCodes[]'] || '',
    gicCodes: queryParams['gicCodes[]'] || '',
    employeesGT: queryParams.employeesGT || undefined,
    employeesLT: queryParams.employeesLT || undefined,
    companyNameType: queryParams.companyNameType || undefined,
    position: queryParams.position || undefined,
    gender: queryParams.gender || undefined,
    school: queryParams.school || undefined,
    degree: queryParams.degree || undefined,
    speciality: queryParams.speciality || undefined,
    fellowship: queryParams.fellowship || undefined,
    sicCode: queryParams.sicCode || undefined,
    // provinces: queryParams.provinces || undefined,
    dateInfo: queryParams.dateInfo || undefined,
    page,
    numberOfRows,
  });

  let showPersonColumn = false;

  let dbLongName = 'External Database';
  switch (params.dbCode) {
    case 'fp-corporate-surveys':
      dbLongName = 'Financial Post Corporate Surveys';
      break;
    case 'fp-directory-of-directors':
      dbLongName = 'Financial Post Directory of Directors';
      showPersonColumn = true;
      break;
    case 'pro-file-canada':
      dbLongName = 'Owen Media Partners - ProFile Canada';
      break;
    case 'encyclopedia-of-associations':
      dbLongName = 'Cengage Learning Gale - Encyclopedia of Associations';
      break;
    case 'wards-business-directory':
      dbLongName = "Cengage Learning Gale - Ward's Business Directory";
      break;
  }

  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.leads.length === 0;

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const searchParams = useSearchParams()

  const switchCase = (param: any, value: any) => {
    switch (param) {
      case 'contacttype':
        return contactTypeSearchLookup.find((item) => item.value === value)?.label
      case 'sicCodeType':
        return sicCodeTypeLookup.find((item) => item.value === value)?.label
      case 'exchange[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return exchangesLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'provinces[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return provincesLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'country[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return countriesLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'companyName': {
        return queryParams.companyName
      }
      case 'street': {
        return queryParams.street
      }
      case 'address': {
        return queryParams.address
      }
      case 'city': {
        return queryParams.city
      }
      case 'areaCode': {
        return queryParams.areaCode
      }
      case 'banker': {
        return queryParams.banker
      }
      case 'auditor': {
        return queryParams.auditor
      }
      case 'majorShareholder': {
        return queryParams.majorShareholder
      }
      case 'lawyer': {
        return queryParams.lawyer
      }
      case 'companyTypes': {
        return queryParams.companyTypes
      }
      case 'sicCode': {
        return queryParams.sicCode
      }
      case 'companyNameType': {
        return queryParams.companyNameType
      }
      case 'employeesGT': {
        return queryParams.employeesGT
      }
      case 'employeesLT': {
        return queryParams.employeesLT
      }
      case 'locationType': {
        return queryParams.locationType
      }
      case 'degree': {
        return queryParams.degree
      }
      case 'fellowship': {
        return queryParams.fellowship
      }
      case 'gender': {
        return queryParams.gender
      }
      case 'position': {
        return queryParams.position
      }
      case 'school': {
        return queryParams.school
      }
      case 'speciality': {
        return queryParams.speciality
      }
      case 'companyType[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return companyTypesExtendedLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'companySubType[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return companySubTypesExtendedLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'jurisdiction': {
        /* @ts-ignore */
        const data = value?.split(',')
        return jurisdictionLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'sicCodes[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return sicLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'gicCodes[]': {
        /* @ts-ignore */
        const data = value?.split(',')
        return gicsLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'transferAgent': {
        /* @ts-ignore */
        const data = value?.split(',')
        return transferAgentLookup.filter((item) => data?.includes(item.id)).map((item) => item.name).join(', ')
      }
      case 'dateInfo': {
        return queryParams.dateInfo
      }
      default:
        return [];
    }
  }

  const exportCsvResults = () => {
    const csv_url = searchResults?.resolvedData?.csv_url;
    if (csv_url !== undefined || csv_url !== '') {
      window.location.href = config.apiUrl + csv_url;
    }
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Lead List Generator - {dbLongName} - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <Button
        label="New Search"
        mb={noResults ? 'xxl' : 'lg'}
        link={`/lead-list-generator/search/${params.dbCode}`}
      />
      {searchResults?.resolvedData?.leads.length === 2000
        ? (
          <Text size="lg" mb="lg" weight="medium">
            {searchResults?.resolvedData?.leads.length} hits. Your search returned
            more than the maximum of 2000 hits. Only the first 2000 were returned.
          </Text>
        )
        : (
          <></>
        )}
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />

          <Text size="xl" mb="lg" weight="medium">
            0 results found
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found.
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            loading={loading}
            expectedColumns={showPersonColumn ? 6 : 5}
            isEmpty={!searchResults.resolvedData}
            queryParams={queryParams}
            print={searchResults?.resolvedData?.csv_url !== undefined }
            export={searchResults?.resolvedData?.csv_url !== undefined ? exportCsvResults : undefined}
            dataLength={searchResults?.resolvedData?.leads.length}
            expectedRows={searchResults?.resolvedData?.leads.length}
            history={props.history.location.state}
            switchCase={switchCase}
          >
            <Table.Head>
              <Table.Row>
                {showPersonColumn && (
                  <Table.HeaderCell>
                    <Text>Person Name</Text>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Text>Company Name</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Address</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Phone</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Fax</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Email</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.leads.map((l) => (
                <Table.Row>
                  {showPersonColumn && (
                    <>
                      <Table.Cell minWidth="md">
                        <Link
                          to={`/directory-of-directors/details/${l.unique_key}`}
                          className="black-text"
                        >
                          <Text>{l.data.FullName}</Text>
                        </Link>
                      </Table.Cell>

                      <Table.Cell minWidth="md">
                        <Text>
                          {l.data.CoName ||
                            l.data.CompanyName ||
                            l.data.OrganizationName ||
                            l.data.Company}
                        </Text>
                      </Table.Cell>
                    </>
                  )}

                  {!showPersonColumn && (
                    <Table.Cell minWidth="md">
                      <Link
                        to={`/historical-reports/details/${l.unique_key}`}
                        className="black-text"
                      >
                        <Text>
                          {l.data.CoName ||
                            l.data.CompanyName ||
                            l.data.OrganizationName ||
                            l.data.Company}
                        </Text>
                      </Link>
                    </Table.Cell>
                  )}

                  <Table.Cell minWidth="md">
                    <Text>
                      {l.data.Address ||
                        l.data.MailingAddress ||
                        l.data.AddressLine1}{' '}
                      <br />
                      {l.data.Street} {l.data.City} {l.data.Province}{' '}
                      {l.data.Postal || l.data.Zip}
                    </Text>
                  </Table.Cell>
                  <Table.Cell minWidth="md" align="center">
                    {l.data.Phone && (
                      <a
                        href={`tel:+1${l.data.Phone?.replace(/[^0-9]+/g, '')}`}
                      >
                        <Text>{l.data.Phone}</Text>
                      </a>
                    )}

                    {l.data.PhoneNumber && (
                      <a
                        href={`tel:+1${l.data.PhoneNumber?.replace(
                          /[^0-9]+/g,
                          ''
                        )}`}
                      >
                        <Text>{l.data.PhoneNumber}</Text>
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell minWidth="md" align="center">
                    {l.data.Fax && (
                      <a href={`fax:+1${l.data.Fax?.replace(/[^0-9]+/g, '')}`}>
                        <Text>{l.data.Fax}</Text>
                      </a>
                    )}

                    {l.data.FaxNumber && (
                      <a
                        href={`fax:+1${l.data.FaxNumber?.replace(
                          /[^0-9]+/g,
                          ''
                        )}`}
                      >
                        <Text>{l.data.FaxNumber}</Text>
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    <a href={`mailto:${l.data.Email}`}>
                      {l.data.Email
                      ? <Text>{l.data.Email} </Text>
                      : <span className="no--email--block">.</span>
                      }
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
