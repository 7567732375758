/* eslint-disable */
import * as React from 'react';
import { useHistory } from 'react-router';
import { useId, usePreviousStorageData } from '../../hooks';
import { TextField } from '../../components/TextField';
import { ScreenReaderOnly } from '../../components/ScreenReaderOnly';
import { FPCol as Col } from '../../components/FPCol/FPCol';
import { Button } from '../../components/Button';
import { Text } from '../../components/Text';
import { Heading } from '../../components/Heading';
import { RadioList } from '../../components/RadioList';
import { MultiSelectField } from '../../components/MultiSelectField';
import ClearButton from '../../components/ClearButton/index';
import { SavedSearch } from '../../components/SavedSearch';
import { SavedSearchBottom } from '../../components/SavedSearchBottom';
import { ISavedSearches } from '../../containers/AuthProvider';
import { useNaicsCodes } from '../../data';
import toast, { Toaster } from 'react-hot-toast';
import '../../pages/MergersAcquisitions/SearchPage/searchMna.scss';
import '../../pages/LeadListGenerator/SearchPages/CorporateSurveys/index.css';
import {
  gicsLookup,
  sicLookup,
  exchangesLookup,
  countriesLookup,
  provincesLookup,
  companyTypesExtendedLookup,
  companySubTypesExtendedLookup,
  bankersLookup,
  auditorsLookup,
  transferAgentLookup,
  lawyersLookup,
  jurisdictionLookup,
  contactTypeSearchLookup,
  sicCodeTypeLookup,
  naicsCodeTypeLookup,
} from '../../data/lookups';
import { Row } from 'react-grid-system';
import { preProcessFile } from 'typescript';
import CheckOptions from 'components/CheckOptions';
import { SelectField } from 'components/SelectField';
import { CategoryArray, DataItemArray, YearArray, QYearArray, AuditorsArray } from '../../data/financialSearchLookup';

export interface ICorpSurveysAdvProps {
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  city?: string;
  setCity?: React.Dispatch<React.SetStateAction<string>>;
  provinces?: Array<string>;
  setProvinces?: React.Dispatch<React.SetStateAction<Array<string>>>;
  country?: Array<string>;
  setCountry?: React.Dispatch<React.SetStateAction<Array<string>>>;
  areaCode?: string;
  setAreaCode?: React.Dispatch<React.SetStateAction<string>>;
  exchange: Array<string>;
  setExchange: React.Dispatch<React.SetStateAction<Array<string>>>;
  exchangeListed: boolean;
  setExchangeListed: React.Dispatch<React.SetStateAction<boolean>>;
  exchangeSuspended: boolean;
  setExchangeSuspended: React.Dispatch<React.SetStateAction<boolean>>;
  contacttype?: string;
  setContacttype?: React.Dispatch<React.SetStateAction<string>>;
  companyType: Array<string>;
  setCompanyType: React.Dispatch<React.SetStateAction<Array<string>>>;
  incorporationYear?: string;
  setIncorporationYear: React.Dispatch<React.SetStateAction<string>>;
  companySubType: Array<string>;
  setCompanySubType: React.Dispatch<React.SetStateAction<Array<string>>>;
  jurisdiction: string;
  setJurisdiction: React.Dispatch<React.SetStateAction<string>>;
  majorShareholder: string;
  setMajorShareholder: React.Dispatch<React.SetStateAction<string>>;
  optShareholderInterest10Prcnt: boolean;
  setOptShareholderInterest10Prcnt: React.Dispatch<React.SetStateAction<boolean>>;
  optForeignOwnership10Prcnt: boolean;
  setOptForeignOwnership10Prcnt: React.Dispatch<React.SetStateAction<boolean>>;
  subsidiary?: string;
  setSubsidiary: React.Dispatch<React.SetStateAction<string>>;
  directorOfficerName?: string;
  setDirectorOfficerName: React.Dispatch<React.SetStateAction<string>>;
  auditor: string;
  setAuditor: React.Dispatch<React.SetStateAction<string>>;
  banker: string;
  setBanker: React.Dispatch<React.SetStateAction<string>>;
  lawyer: string;
  setLawyer: React.Dispatch<React.SetStateAction<string>>;
  transferAgent: string;
  setTransferAgent: React.Dispatch<React.SetStateAction<string>>;
  idxSPTSXComp: boolean;
  setIdxSPTSXComp: React.Dispatch<React.SetStateAction<boolean>>;
  idxSPTSX60: boolean;
  setIdxSPTSX60: React.Dispatch<React.SetStateAction<boolean>>;
  idxTSX30: boolean;
  setIdxTSX30: React.Dispatch<React.SetStateAction<boolean>>;
  idxTSXVenTier1: boolean;
  setIdxTSXVenTier1: React.Dispatch<React.SetStateAction<boolean>>;
  idxTSXVenTier2: boolean;
  setIdxTSXVenTier2: React.Dispatch<React.SetStateAction<boolean>>;
  idxTSXVenTier3: boolean;
  setIdxTSXVenTier3: React.Dispatch<React.SetStateAction<boolean>>;
  idxFP500: boolean;
  setIdxFP500: React.Dispatch<React.SetStateAction<boolean>>;
  idxCleantech: boolean;
  setIdxCleantech: React.Dispatch<React.SetStateAction<boolean>>;
  idxCannabis: boolean;
  setIdxCannabis: React.Dispatch<React.SetStateAction<boolean>>;
  idxBlockchain: boolean;
  setIdxBlockchain: React.Dispatch<React.SetStateAction<boolean>>;
  gicCodes: Array<string>;
  setGicCodes: React.Dispatch<React.SetStateAction<Array<string>>>;
  naicsCodes: Array<string>;
  setNaicsCodes: React.Dispatch<React.SetStateAction<Array<string>>>;
  naicsCodeType: string;
  setNaicsCodeType: React.Dispatch<React.SetStateAction<string>>;
  sicCodes: Array<string>;
  setSicCodes: React.Dispatch<React.SetStateAction<Array<string>>>;
  sicCodeType: string;
  setSicCodeType: React.Dispatch<React.SetStateAction<string>>;
  normalCourseIssuerBid: boolean;
  setNormalCourseIssuerBid: React.Dispatch<React.SetStateAction<boolean>>;
  substantialIssuerBid: boolean;
  setSubstantialIssuerBid: React.Dispatch<React.SetStateAction<boolean>>;
  dividendReinvestmentPlan: boolean;
  setDividendReinvestmentPlan: React.Dispatch<React.SetStateAction<boolean>>;

  // begin financial search
  textSearch?: string,
  setTextSearch?: React.Dispatch<React.SetStateAction<string>>;

  finSearchSortOpt?: string;
  setFinSearchSortOpt?: React.Dispatch<React.SetStateAction<string>>;
  finSearchSortDirection?: string;
  setFinSearchSortDirection?: React.Dispatch<React.SetStateAction<string>>;

  category1?: string,
  setCategory1?: React.Dispatch<React.SetStateAction<string>>;
  dataItem1?: string,
  setDataItem1?: React.Dispatch<React.SetStateAction<string>>;
  year1?: string,
  setYear1?: React.Dispatch<React.SetStateAction<string>>;
  boolOp1?: string,
  setBoolOp1?: React.Dispatch<React.SetStateAction<string>>;
  operation1?: string,
  setOperation1?: React.Dispatch<React.SetStateAction<string>>;
  value1?: string,
  setValue1?: React.Dispatch<React.SetStateAction<string>>;

  active2?: boolean,
  setActive2?: React.Dispatch<React.SetStateAction<boolean>>;
  category2?: string,
  setCategory2?: React.Dispatch<React.SetStateAction<string>>;
  dataItem2?: string,
  setDataItem2?: React.Dispatch<React.SetStateAction<string>>;
  year2?: string,
  setYear2?: React.Dispatch<React.SetStateAction<string>>;
  boolOp2?: string,
  setBoolOp2?: React.Dispatch<React.SetStateAction<string>>;
  operation2?: string,
  setOperation2?: React.Dispatch<React.SetStateAction<string>>;
  value2?: string,
  setValue2?: React.Dispatch<React.SetStateAction<string>>;

  active3?: boolean,
  setActive3?: React.Dispatch<React.SetStateAction<boolean>>;
  category3?: string,
  setCategory3?: React.Dispatch<React.SetStateAction<string>>;
  dataItem3?: string,
  setDataItem3?: React.Dispatch<React.SetStateAction<string>>;
  year3?: string,
  setYear3?: React.Dispatch<React.SetStateAction<string>>;
  boolOp3?: string,
  setBoolOp3?: React.Dispatch<React.SetStateAction<string>>;
  operation3?: string,
  setOperation3?: React.Dispatch<React.SetStateAction<string>>;
  value3?: string,
  setValue3?: React.Dispatch<React.SetStateAction<string>>;

  active4?: boolean,
  setActive4?: React.Dispatch<React.SetStateAction<boolean>>;
  category4?: string,
  setCategory4?: React.Dispatch<React.SetStateAction<string>>;
  dataItem4?: string,
  setDataItem4?: React.Dispatch<React.SetStateAction<string>>;
  year4?: string,
  setYear4?: React.Dispatch<React.SetStateAction<string>>;
  boolOp4?: string,
  setBoolOp4?: React.Dispatch<React.SetStateAction<string>>;
  operation4?: string,
  setOperation4?: React.Dispatch<React.SetStateAction<string>>;
  value4?: string,
  setValue4?: React.Dispatch<React.SetStateAction<string>>;

  active5?: boolean,
  setActive5?: React.Dispatch<React.SetStateAction<boolean>>;
  category5?: string,
  setCategory5?: React.Dispatch<React.SetStateAction<string>>;
  dataItem5?: string,
  setDataItem5?: React.Dispatch<React.SetStateAction<string>>;
  year5?: string,
  setYear5?: React.Dispatch<React.SetStateAction<string>>;
  boolOp5?: string,
  setBoolOp5?: React.Dispatch<React.SetStateAction<string>>;
  operation5?: string,
  setOperation5?: React.Dispatch<React.SetStateAction<string>>;
  value5?: string,
  setValue5?: React.Dispatch<React.SetStateAction<string>>;

  rowsToShow?: number,
  setRowsToShow?: React.Dispatch<React.SetStateAction<number>>;
  // end financial search

  formSubmit: () => void;
  savedSearches?: ISavedSearches;
  selectedSavedSearch?: string;
  setSelectedSavedSearch?: React.Dispatch<React.SetStateAction<string>>;
  deleteSavedSearch?: (searchName:string, searchType: string) => void; 
  populateFromSavedSearch?: (target:string, searchType: string) => void; 
  newSavedSearchName?: string;
  setNewSavedSearchName?: React.Dispatch<React.SetStateAction<string>>;
  saveSearch?: () => void;
};

export function CorporateSurveysAdvanceTab(props: ICorpSurveysAdvProps) {
  const history = useHistory();
  const headingId = useId();
  const [naicsLookupCodes, setNaicsLookupCodes] = React.useState<any>([{ id: '', name: 'NAICS Codes' }]);
  
  const { data } = useNaicsCodes();
  
  React.useEffect(() => {
    if (history.action === "POP") {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  const loadLatestEntries = () => {
    const exchange = localStorage.getItem('exchangeCS');
    if (exchange) {
      props.setExchange(JSON.parse(exchange));
    }
    const companyTypeCS = localStorage.getItem('companyTypeCS');
    if (companyTypeCS) {
      props.setCompanyType(JSON.parse(companyTypeCS));
    }
    const companySubTypeCS = localStorage.getItem('companySubTypeCS');
    if (companySubTypeCS) {
      props.setCompanySubType(JSON.parse(companySubTypeCS));
    }
    const jurisdictionCS = localStorage.getItem('jurisdictionCS');
    if (jurisdictionCS) {
      props.setJurisdiction(JSON.parse(jurisdictionCS));
    }
    const auditorCS = localStorage.getItem('auditorCS');
    if (auditorCS) {
      props.setAuditor(JSON.parse(auditorCS));
    }
    const bankerCS = localStorage.getItem('bankerCS');
    if (bankerCS) {
      props.setBanker(JSON.parse(bankerCS));
    }
    const lawyerCS = localStorage.getItem('lawyerCS');
    if (lawyerCS) {
      props.setLawyer(JSON.parse(lawyerCS));
    }
    const transferAgentCS = localStorage.getItem('transferAgentCS');
    if (transferAgentCS) {
      props.setTransferAgent(JSON.parse(transferAgentCS));
    }
    const majorShareholderCS = localStorage.getItem('majorShareholderCS');
    if (majorShareholderCS) {
      props.setMajorShareholder(JSON.parse(majorShareholderCS));
    }
    const sicCodesCS = localStorage.getItem('sicCodesCS');
    if (sicCodesCS) {
      props.setSicCodes(JSON.parse(sicCodesCS));
    }
    const gicCodesCS = localStorage.getItem('gicCodesCS');
    if (gicCodesCS) {
      props.setGicCodes(JSON.parse(gicCodesCS));
    }
    const naicsCodesCS = localStorage.getItem('naicsCodesCS');
    if (naicsCodesCS) {
      props.setNaicsCodes(JSON.parse(naicsCodesCS));
    }
    const naicCodeRadioCS = localStorage.getItem('naicCodeRadioCS');
    if (naicCodeRadioCS) {
      props.setNaicsCodeType(naicCodeRadioCS);
    }
    const sicCodeRadioCS = localStorage.getItem('sicCodeRadioCS');
    if (sicCodeRadioCS) {
      props.setSicCodeType(sicCodeRadioCS);
    }
    const spTsxComposite = localStorage.getItem('chk_exchange_sp_tsx_composit');
    if (spTsxComposite) {
      props.setIdxSPTSXComp(JSON.parse(spTsxComposite));
    }
    const spTsx60 = localStorage.getItem('chk_exchange_sp_tsx_60');
    if (spTsx60) {
      props.setIdxSPTSX60(JSON.parse(spTsx60));
    }
    const spTsx30 = localStorage.getItem('chk_exchange_sp_tsx_30');
    if (spTsx30) {
      props.setIdxTSX30(JSON.parse(spTsx30));
    }
    const spTsxTier1 = localStorage.getItem('chk_exchange_sp_tsx_tier_1');
    if (spTsxTier1) {
      props.setIdxTSXVenTier1(JSON.parse(spTsxTier1));
    }
    const spTsxTier2 = localStorage.getItem('chk_exchange_sp_tsx_tier_2');
    if (spTsxTier2) {
      props.setIdxTSXVenTier2(JSON.parse(spTsxTier2));
    }
    const spTsxTier3 = localStorage.getItem('chk_exchange_sp_tsx_tier_3');
    if (spTsxTier3) {
      props.setIdxTSXVenTier3(JSON.parse(spTsxTier3));
    }
    const fp500 = localStorage.getItem('chk_exchange_fp500');
    if (fp500) {
      props.setIdxFP500(JSON.parse(fp500));
    }
    const blockchain = localStorage.getItem('chk_exchange_blockchain');
    if (blockchain) {
      props.setIdxBlockchain(JSON.parse(blockchain));
    }
    const cannabis = localStorage.getItem('chk_exchange_cannabis');
    if (cannabis) {
      props.setIdxCannabis(JSON.parse(cannabis));
    }
    const cleantech = localStorage.getItem('chk_exchange_cleantech');
    if (cleantech) {
      props.setIdxCleantech(JSON.parse(cleantech));
    }
    const optShareholderInterest10Prcnt = localStorage.getItem('optShareholderInterest10PrcntCS');
    if (optShareholderInterest10Prcnt) {
      props.setOptShareholderInterest10Prcnt(JSON.parse(optShareholderInterest10Prcnt));
    }
    const optForeignOwnership10Prcnt = localStorage.getItem('optForeignOwnership10PrcntCS');
    if (optForeignOwnership10Prcnt) {
      props.setOptForeignOwnership10Prcnt(JSON.parse(optForeignOwnership10Prcnt));
    } 
    const incorporationYear = localStorage.getItem('incorporationYearCS');
    if (incorporationYear) {
      props.setIncorporationYear(JSON.parse(incorporationYear));
    }
    const subsidiary = localStorage.getItem('subsidiaryCS');
    if (subsidiary) {
      props.setSubsidiary(JSON.parse(subsidiary));
    }
    const directorOfficerName = localStorage.getItem('directorOfficerNameCS');
    if (directorOfficerName) {
      props.setDirectorOfficerName(JSON.parse(directorOfficerName));
    }
    const substantialIssuer = localStorage.getItem('substantialIssuer');
    if (substantialIssuer) {
      props.setSubstantialIssuerBid(JSON.parse(substantialIssuer));
    }
    const normalCourse = localStorage.getItem('normalCourse');
    if (normalCourse) {
      props.setNormalCourseIssuerBid(JSON.parse(normalCourse));
    }
    const reinvestmentPlan = localStorage.getItem('reinvestmentPlan');
    if (reinvestmentPlan) {
      props.setDividendReinvestmentPlan(JSON.parse(reinvestmentPlan));
    }
    const textSearch = localStorage.getItem('textSearchCS');
    if (textSearch) {
      props.setTextSearch?.(JSON.parse(textSearch));
    }
    const city = localStorage.getItem('cityCS');
    if (city) {
      props.setCity?.(JSON.parse(city));
    }
    const areaCode = localStorage.getItem('areaCodeCS');
    if (areaCode) {
      props.setAreaCode?.(JSON.parse(areaCode));
    }
    const provinces = localStorage.getItem('provincesCS');
    if (provinces) {
      props.setProvinces?.(JSON.parse(provinces));
    }
    const countries = localStorage.getItem('countriesCS');
    if (countries) {
      props.setCountry?.(JSON.parse(countries));
    }
  }
  /* exchange */
  const exchangeName = usePreviousStorageData(props.exchange) || [];
  React.useEffect(() => {
    if (exchangeName?.length !== props.exchange.length) {
      localStorage.setItem('exchangeCS', JSON.stringify(props.exchange));
    }
  }, [props.exchange]);

  /* exchangeListed */ 
  const exchangeListedChk = usePreviousStorageData(props.exchangeListed) || '';
  React.useEffect(() => {
    if (exchangeListedChk !== props.exchangeListed.toString()) {
      localStorage.setItem('chk_exchange_listed', JSON.stringify(props.exchangeListed));
    }
  }, [props.exchangeListed]);

  /* exchangeSuspended */ 
  const exchangeSuspendedChk = usePreviousStorageData(props.exchangeSuspended) || '';
  React.useEffect(() => {
    if (exchangeSuspendedChk !== props.exchangeSuspended.toString()) {
      localStorage.setItem('chk_exchange_suspended', JSON.stringify(props.exchangeSuspended));
    }
  }, [props.exchangeSuspended]);

  /* idxSPTSXComp */ 
  const idxSPTSXCompChk = usePreviousStorageData(props.idxSPTSXComp) || '';
  React.useEffect(() => {
    if (idxSPTSXCompChk !== props.idxSPTSXComp.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_composit', JSON.stringify(props.idxSPTSXComp));
    }
  }, [props.idxSPTSXComp]);

  /* idxSPTSX60 */ 
  const idxSPTSX60Chk = usePreviousStorageData(props.idxSPTSX60) || '';
  React.useEffect(() => {
    if (idxSPTSX60Chk !== props.idxSPTSX60.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_60', JSON.stringify(props.idxSPTSX60));
    }
  }, [props.idxSPTSX60]);

  /* idxTSX30 */ 
  const idxTSX30Chk = usePreviousStorageData(props.idxTSX30) || '';
  React.useEffect(() => {
    if (idxTSX30Chk !== props.idxTSX30.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_30', JSON.stringify(props.idxTSX30));
    }
  }, [props.idxTSX30]);


  /* idxTSXVenTier1 */ 
  const idxTSXVenTier1Chk = usePreviousStorageData(props.idxTSXVenTier1) || '';
  React.useEffect(() => {
    if (idxTSXVenTier1Chk !== props.idxTSXVenTier1.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_tier_1', JSON.stringify(props.idxTSXVenTier1));
    }
  }, [props.idxTSXVenTier1]);

  /* idxTSXVenTier2 */ 
  const idxTSXVenTier2Chk = usePreviousStorageData(props.idxTSXVenTier2) || '';
  React.useEffect(() => {
    if (idxTSXVenTier2Chk !== props.idxTSXVenTier2.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_tier_2', JSON.stringify(props.idxTSXVenTier2));
    }
  }, [props.idxTSXVenTier2]);

  /* idxTSXVenTier3 */ 
  const idxTSXVenTier3Chk = usePreviousStorageData(props.idxTSXVenTier3) || '';
  React.useEffect(() => {
    if (idxTSXVenTier3Chk !== props.idxTSXVenTier3.toString()) {
      localStorage.setItem('chk_exchange_sp_tsx_tier_3', JSON.stringify(props.idxTSXVenTier3));
    }
  }, [props.idxTSXVenTier3]);

  /* idxFP500 */ 
  const idxFP500Chk = usePreviousStorageData(props.idxFP500) || '';
  React.useEffect(() => {
    if (idxFP500Chk !== props.idxFP500.toString()) {
      localStorage.setItem('chk_exchange_fp500', JSON.stringify(props.idxFP500));
    }
  }, [props.idxFP500]);

  /* idxBlockchain */ 
  const idxBlockchainChk = usePreviousStorageData(props.idxBlockchain) || '';
  React.useEffect(() => {
    if (idxBlockchainChk !== props.idxBlockchain.toString()) {
      localStorage.setItem('chk_exchange_blockchain', JSON.stringify(props.idxBlockchain));
    }
  }, [props.idxBlockchain]);

  /* idxCannabis */ 
  const idxCannabisChk = usePreviousStorageData(props.idxCannabis) || '';
  React.useEffect(() => {
    if (idxCannabisChk !== props.idxCannabis.toString()) {
      localStorage.setItem('chk_exchange_cannabis', JSON.stringify(props.idxCannabis));
    }
  }, [props.idxCannabis]);

  /* idxCleantech */ 
  const idxCleantechChk = usePreviousStorageData(props.idxCleantech) || '';
  React.useEffect(() => {
    if (idxCleantechChk !== props.idxCleantech.toString()) {
      localStorage.setItem('chk_exchange_cleantech', JSON.stringify(props.idxCleantech));
    }
  }, [props.idxCleantech]);

  /* companyType */
  const companyTypeName = usePreviousStorageData(props.companyType) || '';
  React.useEffect(() => {
    if (companyTypeName?.length !== props.companyType.length) {
      localStorage.setItem('companyTypeCS', JSON.stringify(props.companyType));
    }
  }, [props.companyType]);

  /* companySubType */
  const companySubName = usePreviousStorageData(props.companySubType) || '';
  React.useEffect(() => {
    if (companySubName?.length !== props.companySubType.length) {
      localStorage.setItem('companySubTypeCS', JSON.stringify(props.companySubType));
    }
  }, [props.companySubType]);

  /* jurisdiction */
  const jurisdictionName = usePreviousStorageData(props.jurisdiction) || '';
  React.useEffect(() => {
    if (jurisdictionName?.length !== props.jurisdiction.length) {
      localStorage.setItem('jurisdictionCS', JSON.stringify(props.jurisdiction));
    }
  }, [props.jurisdiction]);

  /* auditor */
  const auditorName = usePreviousStorageData(props.auditor) || '';
  React.useEffect(() => {
    if (auditorName?.length !== props.auditor.length) {
      localStorage.setItem('auditorCS', JSON.stringify(props.auditor));
    }
  }, [props.auditor]);

  /* banker */
  const bankerName = usePreviousStorageData(props.banker) || '';
  React.useEffect(() => {
    if (bankerName?.length !== props.banker.length) {
      localStorage.setItem('bankerCS', JSON.stringify(props.banker));
    }
  }, [props.banker]);

  /* lawyer */
  const lawyerName = usePreviousStorageData(props.lawyer) || '';
  React.useEffect(() => {
    if (lawyerName?.length !== props.lawyer.length) {
      localStorage.setItem('lawyerCS', JSON.stringify(props.lawyer));
    }
  }, [props.lawyer]);

  /* transferAgent */
  const transferAgentName = usePreviousStorageData(props.transferAgent) || '';
  React.useEffect(() => {
    if (transferAgentName?.length !== props.transferAgent.length) {
      localStorage.setItem('transferAgentCS', JSON.stringify(props.transferAgent));
    }
  }, [props.transferAgent]);

  /* majorShareholder */
  const majorShareholderName = usePreviousStorageData(props.majorShareholder) || '';
  React.useEffect(() => {
    if (majorShareholderName?.length !== props.majorShareholder.length) {
      localStorage.setItem(
        'majorShareholderCS',
        JSON.stringify(props.majorShareholder)
      );
    }
  }, [props.majorShareholder]);

  /* sicCodes */
  const sicCodeName = usePreviousStorageData(props.sicCodes) || '';
  React.useEffect(() => {
    if (sicCodeName?.length !== props.sicCodes.length) {
      localStorage.setItem('sicCodesCS', JSON.stringify(props.sicCodes));
    }
  }, [props.sicCodes]);

  /* gicCodes */
  const gicCodesName = usePreviousStorageData(props.gicCodes) || '';
  React.useEffect(() => {
    if (gicCodesName?.length !== props.gicCodes.length) {
      localStorage.setItem('gicCodesCS', JSON.stringify(props.gicCodes));
    }
  }, [props.gicCodes]);

  /* naicsCodes */ 
  const naicsCodesName = usePreviousStorageData(props.naicsCodes) || '';
  React.useEffect(() => {
    if (naicsCodesName?.length !== props.naicsCodes.length) {
      localStorage.setItem('naicsCodesCS', JSON.stringify(props.naicsCodes));
    }
  }, [props.naicsCodes]);

  /* optShareholderInterest10Prcnt */ 
  const optShareholderInterest10PrcntName = usePreviousStorageData(props.optShareholderInterest10Prcnt) || '';
  React.useEffect(() => {
    if (optShareholderInterest10PrcntName !== props.optShareholderInterest10Prcnt.toString()) {
      localStorage.setItem('optShareholderInterest10PrcntCS', JSON.stringify(props.optShareholderInterest10Prcnt));
    }
  }, [props.optShareholderInterest10Prcnt]);

  /* optShareholderInterest10Prcnt */ 
  const optForeignOwnership10Prcnt = usePreviousStorageData(props.optForeignOwnership10Prcnt) || '';
  React.useEffect(() => {
    if (optForeignOwnership10Prcnt !== props.optForeignOwnership10Prcnt.toString()) {
      localStorage.setItem('optForeignOwnership10PrcntCS', JSON.stringify(props.optForeignOwnership10Prcnt));
    }
  }, [props.optForeignOwnership10Prcnt]);

  /* incorporationYear */ 
  const incorporationYear = usePreviousStorageData(props.incorporationYear) || '';
  React.useEffect(() => {
    if (incorporationYear?.length !== props.incorporationYear?.length) {
      localStorage.setItem('incorporationYearCS', JSON.stringify(props.incorporationYear));
    }
  }, [props.incorporationYear]);

  /* subsidiary */ 
  const subsidiary = usePreviousStorageData(props.subsidiary) || '';
  React.useEffect(() => {
    if (subsidiary?.length !== props.subsidiary?.length) {
      localStorage.setItem('subsidiaryCS', JSON.stringify(props.subsidiary));
    }
  }, [props.subsidiary]);

  /* directorOfficerName */ 
  const directorOfficerName = usePreviousStorageData(props.directorOfficerName) || '';
  React.useEffect(() => {
    if (directorOfficerName?.length !== props.directorOfficerName?.length) {
      localStorage.setItem('directorOfficerNameCS', JSON.stringify(props.directorOfficerName));
    }
  }, [props.directorOfficerName]);

  /* normalCourseIssuerBid */ 
  const normalCourseIssuerBid = usePreviousStorageData(props.normalCourseIssuerBid) || '';
  React.useEffect(() => {
    if (normalCourseIssuerBid !== props.normalCourseIssuerBid.toString()) {
      localStorage.setItem('normalCourse', JSON.stringify(props.normalCourseIssuerBid));
    }
  }, [props.normalCourseIssuerBid]);

  /* substantialIssuerBid */ 
  const substantialIssuerBid = usePreviousStorageData(props.substantialIssuerBid) || '';
  React.useEffect(() => {
    if (substantialIssuerBid !== props.substantialIssuerBid.toString()) {
      localStorage.setItem('substantialIssuer', JSON.stringify(props.substantialIssuerBid));
    }
  }, [props.substantialIssuerBid]);

  /* dividendReinvestmentPlan */ 
  const dividendReinvestmentPlan = usePreviousStorageData(props.dividendReinvestmentPlan) || '';
  React.useEffect(() => {
    if (dividendReinvestmentPlan !== props.dividendReinvestmentPlan.toString()) {
      localStorage.setItem('reinvestmentPlan', JSON.stringify(props.dividendReinvestmentPlan));
    }
  }, [props.dividendReinvestmentPlan]);
  
  /* textSearch */ 
  const textSearch = usePreviousStorageData(props.textSearch) || '';
  React.useEffect(() => {
    if (textSearch?.length !== props.textSearch?.length) {
      localStorage.setItem('textSearchCS', JSON.stringify(props.textSearch));
    }
  }, [props.textSearch]);

  /* city */ 
  const city = usePreviousStorageData(props.city) || '';
  React.useEffect(() => {
    if (city !== props.city) {
      localStorage.setItem('cityCS', JSON.stringify(props.city));
    }
  }, [props.city]);

  /* country */ 
  const country = usePreviousStorageData(props.country) || '';
  React.useEffect(() => {
    localStorage.setItem('countriesCS', JSON.stringify(props.country));
  }, [props.country]);

  /* provinces */ 
  const provinces = usePreviousStorageData(props.provinces) || '';
  React.useEffect(() => {
    localStorage.setItem('provincesCS', JSON.stringify(props.provinces));
  }, [props.provinces]);

  /* Area code */ 
  const areaCode = usePreviousStorageData(props.areaCode) || '';
  React.useEffect(() => {
    if (areaCode !== props.areaCode) {
      localStorage.setItem('areaCodeCS', JSON.stringify(props.areaCode));
    }
  }, [props.areaCode]);

  const clearCheck = () => {
    props.setIdxSPTSXComp(false);
    props.setIdxSPTSX60(false);
    props.setIdxTSX30(false);
    props.setIdxTSXVenTier1(false);
    props.setIdxTSXVenTier2(false);
    props.setIdxTSXVenTier3(false);
    props.setExchange([]);
    props.setIdxFP500(false);
    props.setIdxBlockchain(false);
    props.setIdxCannabis(false);
    props.setIdxCleantech(false);
    props.setExchangeListed(false);
    props.setExchangeSuspended(false);
    props.setCity && props.setCity('');
    props.setProvinces && props.setProvinces([]);
    props.setCountry && props.setCountry([]);
    props.setAreaCode && props.setAreaCode('');
    props.setCompanyName('');
    props.setCompanySubType([]);
    props.setJurisdiction('');
    props.setOptShareholderInterest10Prcnt(false);
    props.setOptForeignOwnership10Prcnt(false);
    props.setIncorporationYear('');
    props.setSubsidiary('');
    props.setDirectorOfficerName('');
    props.setAuditor('');
    props.setBanker('');
    props.setLawyer('');
    props.setTransferAgent('');
    props.setMajorShareholder('');
    props.setSicCodeType('1');
    props.setNaicsCodeType('1');
    props.setGicCodes([]);
    props.setNaicsCodes([]);
    props.setSicCodes([]);
    props.setCompanyType([]);
    props.setNormalCourseIssuerBid(false);
    props.setSubstantialIssuerBid(false);
    props.setDividendReinvestmentPlan(false);
    props.setTextSearch?.('');

    props.setFinSearchSortDirection && props.setFinSearchSortDirection('0');
    props.setFinSearchSortOpt && props.setFinSearchSortOpt('');
    props.setRowsToShow && props.setRowsToShow(1);
    
    clearFinSearchRow(1);
    clearFinSearchRow(2);
    clearFinSearchRow(3);
    clearFinSearchRow(4);
    clearFinSearchRow(5);

    props.setSelectedSavedSearch?.('');
    props.setNewSavedSearchName?.('');
    
    localStorage.removeItem('chk_exchange_sp_tsx_composit');
    localStorage.removeItem('chk_exchange_sp_tsx_60');
    localStorage.removeItem('chk_exchange_sp_tsx_30');
    localStorage.removeItem('chk_exchange_sp_tsx_tier_1');
    localStorage.removeItem('chk_exchange_sp_tsx_tier_2');
    localStorage.removeItem('chk_exchange_sp_tsx_tier_3');
    localStorage.removeItem('chk_exchange_fp500');
    localStorage.removeItem('chk_exchange_blockchain');
    localStorage.removeItem('chk_exchange_cannabis');
    localStorage.removeItem('chk_exchange_cleantech');
    localStorage.removeItem('chk_exchange_all');
    localStorage.removeItem('chk_exchange_listed');
    localStorage.removeItem('chk_exchange_suspended');
  
    localStorage.removeItem('contactTypeRadio');
    localStorage.removeItem('sicCodeRadio');
    localStorage.removeItem('naicsCodeRadio');
    localStorage.removeItem('normalCourse');
    localStorage.removeItem('substantialIssuer');
    localStorage.removeItem('reinvestmentPlan');
  };

  const finSearchOperations = [
    { id: '', name: '' },
    { id: '=', name: '=' },
    { id: '>', name: '>' },
    { id: '>=', name: '>=' },
    { id: '<', name: '<' },
    { id: '<=', name: '<=' },
    { id: 'Top', name: 'Top #' },
    { id: 'Bottom', name: 'Bottom #' },
    { id: 'Top %', name: 'Top %' },
    { id: 'Bottom %', name: 'Bottom %' },
  ];

  const clearFinSearchRow = (rowNumber: number): void => {
    switch(rowNumber) {
      case 1:
        props.setCategory1 && props.setCategory1('');
        props.setDataItem1 && props.setDataItem1('');
        props.setYear1 && props.setYear1('');
        props.setBoolOp1 && props.setBoolOp1('And');
        props.setOperation1 && props.setOperation1('');
        props.setValue1 && props.setValue1('');
        break;
      case 2:
        props.setActive2 && props.setActive2(true);
        props.setCategory2 && props.setCategory2('');
        props.setDataItem2 && props.setDataItem2('');
        props.setYear2 && props.setYear2('');
        props.setBoolOp2 && props.setBoolOp2('And');
        props.setOperation2 && props.setOperation2('');
        props.setValue2 && props.setValue2('');
        break;
      case 3:
        props.setActive3 && props.setActive3(true);
        props.setCategory3 && props.setCategory3('');
        props.setDataItem3 && props.setDataItem3('');
        props.setYear3 && props.setYear3('');
        props.setBoolOp3 && props.setBoolOp3('And');
        props.setOperation3 && props.setOperation3('');
        props.setValue3 && props.setValue3('');
        break;
      case 4:
        props.setActive4 && props.setActive4(true);
        props.setCategory4 && props.setCategory4('');
        props.setDataItem4 && props.setDataItem4('');
        props.setYear4 && props.setYear4('');
        props.setBoolOp4 && props.setBoolOp4('And');
        props.setOperation4 && props.setOperation4('');
        props.setValue4 && props.setValue4('');
        break;
      case 5:
        props.setActive5 && props.setActive5(true);
        props.setCategory5 && props.setCategory5('');
        props.setDataItem5 && props.setDataItem5('');
        props.setYear5 && props.setYear5('');
        props.setBoolOp5 && props.setBoolOp5('And');
        props.setOperation5 && props.setOperation5('');
        props.setValue5 && props.setValue5('');
        break;
    }

    props.setFinSearchSortOpt && props.setFinSearchSortOpt('1');
  }

  const validateFinSearch = (): boolean => {
    var error: string = '';

    if (!props.rowsToShow) {
      return true;
    }

    if (props.rowsToShow >= 1 && props.category1) {
      if (!props.boolOp1 || !props.dataItem1 || !props.value1) {
        error += '\nPlease ensure all fields of Financial Search Line 1 are correct filled out.';
      }
    }

    if (props.rowsToShow >= 2 && props.active2) {
      if (!props.boolOp2 || !props.category2 || !props.dataItem2 || !props.value2) {
        error += '\nPlease ensure all fields of Financial Search Line 2 are correct filled out.';
      }
    }

    if (props.rowsToShow >= 3 && props.active3) {
      if (!props.boolOp3 || !props.category3 || !props.dataItem3 || !props.value3) {
        error += '\nPlease ensure all fields of Financial Search Line 3 are correct filled out.';
      }
    }

    if (props.rowsToShow >= 4 && props.active3) {
      if (!props.boolOp4 || !props.category4 || !props.dataItem4 || !props.value4) {
        error += '\nPlease ensure all fields of Financial Search Line 4 are correct filled out.';
      }
    }

    if (props.rowsToShow >= 5 && props.active3) {
      if (!props.boolOp5 || !props.category5 || !props.dataItem5 || !props.value5) {
        error += '\nPlease ensure all fields of Financial Search Line 5 are correct filled out.';
      }
    }

    if (error) {
      toast.error(error);
      return false;
    }

    return true;
  }

  return (
    <>
      <div id={headingId}>
        <Text mt='sm' lineHeight='md'>
          Advanced search options help better narrow your search results.
        </Text>
        {
          <form
          aria-labelledby={headingId}
          onSubmit={(e) => {
            e.preventDefault();
            if (validateFinSearch()) {
              props.formSubmit();
            }
          }}
          >

            { props.savedSearches&& props.deleteSavedSearch && props.populateFromSavedSearch && props.setSelectedSavedSearch && (
              <SavedSearch
                savedSearches={props.savedSearches.corporateSurveysAdvanced}
                selectedSavedSearch={props.selectedSavedSearch}
                setSelectedSavedSearch={props.setSelectedSavedSearch}
                deleteSavedSearch={props.deleteSavedSearch}
                populateFromSavedSearch={props.populateFromSavedSearch}
              />
            )}

            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <MultiSelectField
                    label='Exchange'
                    items={exchangesLookup}
                    value={props.exchange}
                    onChange={props.setExchange}
                    width='full'
                  />
                </div>
              </Col>
            </Row>
            <br />

            <Text size='md' mb='md' weight='medium'>
              Exchange Listing Status
            </Text>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_listed'
                  checked={props.exchangeListed}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_listed',
                      `${e.target.checked}`
                    );
                    props.setExchangeListed(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_listed'
                >
                  Listed
                </label>
              </Col>
            </Col>
            <Col>
              <Col>
                <input
                  type='checkbox'
                  id='chk_exchange_suspended'
                  checked={props.exchangeSuspended}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_suspended',
                      `${e.target.checked}`
                    );
                    props.setExchangeSuspended(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_suspended'
                >
                  Suspended
                </label>
              </Col>
            </Col>

            <Text size='md' mb='md' mt='lg' weight='medium'>
              Stock Indices
            </Text>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_sp_tsx_composit'
                  checked={props.idxSPTSXComp}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_composit',
                      `${e.target.checked}`
                    );
                    props.setIdxSPTSXComp(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_sp_tsx_composit'
                >
                  S&amp;P/TSX Composite
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_sp_tsx_60'
                  checked={props.idxSPTSX60}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_60',
                      `${e.target.checked}`
                    );
                    props.setIdxSPTSX60(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_sp_tsx_60'
                >
                  S&amp;P/TSX 60
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_sp_tsx_30'
                  checked={props.idxTSX30}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_30',
                      `${e.target.checked}`
                    );
                    props.setIdxTSX30(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_sp_tsx_30'
                >
                  TSX 30
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_sp_tsx_tier_1'
                  checked={props.idxTSXVenTier1}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_tier_1',
                      `${e.target.checked}`
                    );
                    props.setIdxTSXVenTier1(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_sp_tsx_tier_1'
                >
                  TSX-VEN Tier 1
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_tier_2'
                  checked={props.idxTSXVenTier2}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_tier_2',
                      `${e.target.checked}`
                    );
                    props.setIdxTSXVenTier2(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_tier_2'
                >
                  TSX-VEN Tier 2
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_tier_3'
                  checked={props.idxTSXVenTier3}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_sp_tsx_tier_3',
                      `${e.target.checked}`
                    );
                    props.setIdxTSXVenTier3(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_tier_3'
                >
                  TSX-VEN Tier 3
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_fp500'
                  checked={props.idxFP500}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_fp500',
                      `${e.target.checked}`
                    );
                    props.setIdxFP500(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_fp500'
                >
                  FP500
                </label>
              </Col>
            </Col>

            <br />
            <Text size='md' mb='md' weight='medium'>
              Company Lists
            </Text>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_blockchain'
                  checked={props.idxBlockchain}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_blockchain',
                      `${e.target.checked}`
                    );
                    props.setIdxBlockchain(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_blockchain'
                >
                  Blockchain
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_cannabis'
                  checked={props.idxCannabis}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_cannabis',
                      `${e.target.checked}`
                    );
                    props.setIdxCannabis(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_cannabis'
                >
                  Cannabis
                </label>
              </Col>
            </Col>
            <Col>
              <Col className='stock-indices'>
                <input
                  type='checkbox'
                  id='chk_exchange_cleantech'
                  checked={props.idxCleantech}
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_exchange_cleantech',
                      `${e.target.checked}`
                    );
                    props.setIdxCleantech(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_exchange_cleantech'
                >
                  Cleantech
                </label>
              </Col>
            </Col>

            { props.setContacttype && (
            <div
              style={{ marginTop: '24px' }}
              className='contact--type--search'
            >
              <br />
              <Text size='md' mb='md' weight='medium'>
                Contact Type
              </Text>
              <RadioList
                aria-label='Contact Type Search'
                value={props.contacttype || ''}
                direction='column'
                // onChange={setContacttype}
                onChange={(e) => {
                  localStorage.setItem('contactTypeRadio', `${e}`);
                  props.setContacttype && props.setContacttype(e);
                }}
                options={contactTypeSearchLookup}
              />
            </div>
            )}

            <br />

            { (props.setCity || props.setAreaCode || props.setCountry || props.setProvinces) && (
              <>
                <Text size='md' mb='sm' weight='medium'>
                  Locations
                </Text>
                <Row>
                  { props.setCity && (
                  <Col xs={12} sm={6} md={6} lg={5}>
                    <TextField
                      mt='lg'
                      label='City'
                      value={props.city || ''}
                      onChange={props.setCity}
                      width='full'
                      fullWidth
                    />
                  </Col>
                  )}
                  { props.setAreaCode && (
                  <Col xs={12} sm={6} md={6} lg={5}>
                    <TextField
                      mt='lg'
                      mb='sm'
                      label='Area code'
                      value={props.areaCode || ''}
                      onChange={props.setAreaCode}
                      width='full'
                      fullWidth
                    />
                  </Col>
                  )}
                  <Col xs={12} sm={2}>
                  </Col>
                </Row>
                <Row>
                  { props.setCountry && (
                  <Col xs={12} sm={6} md={6} lg={5}>
                    <div style={{ marginTop: '24px' }}>
                      <MultiSelectField
                        label='Country'
                        items={countriesLookup}
                        value={props.country || []}
                        onChange={props.setCountry}
                        width='full'
                      />
                    </div>
                  </Col>
                  )}
                  { props.setProvinces && (
                  <Col xs={12} sm={6} md={6} lg={5}>
                    <div style={{ marginTop: '24px'}}>
                      <MultiSelectField
                        label='Province'
                        items={provincesLookup}
                        value={props.provinces || []}
                        onChange={props.setProvinces || undefined}
                        width='full'
                      />
                    </div>
                  </Col>
                  )}
                  <Col xs={12} sm={2}>
                  </Col>
                </Row>
            </>
            )}
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <MultiSelectField
                    label='Company Type'
                    items={companyTypesExtendedLookup}
                    value={props.companyType}
                    onChange={props.setCompanyType}
                    width='full'
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <MultiSelectField
                    label='Company Sub-Type'
                    items={companySubTypesExtendedLookup}
                    value={props.companySubType}
                    onChange={props.setCompanySubType}
                    width='full'
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <TextField
                    mt='lg'
                    mb='sm'
                    label='Major Shareholder'
                    value={props.majorShareholder}
                    onChange={props.setMajorShareholder}
                    width='full'
                    fullWidth
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <SelectField
                    title='Current Incorporation Jurisdiction'
                    options={jurisdictionLookup}
                    value={props.jurisdiction}
                    setValue={props.setJurisdiction}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div style={{ marginTop: '24px' }}>
                  <CheckOptions vertical={false} options={[
                    {
                      value: props.optShareholderInterest10Prcnt,
                      setValue: props.setOptShareholderInterest10Prcnt,
                      label: 'Officer/Director Interest (>=10%)'
                    },
                    {
                      value: props.optForeignOwnership10Prcnt,
                      setValue: props.setOptForeignOwnership10Prcnt,
                      label: 'Foreign Ownership (>=10%)'
                    },
                    ]} 
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div>
                  <TextField
                    type='number'
                    mt='lg'
                    mb='sm'
                    placeholder='year'
                    label='Incorporation Year'
                    value={props.incorporationYear || ''}
                    onChange={props.setIncorporationYear}
                    width='full'
                    fullWidth
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <TextField
                  mt='lg'
                  mb='sm'
                  label='Subsidiary'
                  value={props.subsidiary || ''}
                  onChange={props.setSubsidiary}
                  width='full'
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <TextField
                  mt='lg'
                  mb='sm'
                  label='Director/Officer Name'
                  value={props.directorOfficerName || ''}
                  onChange={props.setDirectorOfficerName}
                  width='full'
                  fullWidth
                />
              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
            <div style={{ marginTop: '24px' }}>
              <SelectField
                title='Auditor'
                options={auditorsLookup}
                value={props.auditor}
                setValue={props.setAuditor}
              />
            </div>
            </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
            <div style={{ marginTop: '24px' }}>
              <SelectField
                title='Banker'
                options={bankersLookup}
                value={props.banker}
                setValue={props.setBanker}
              />
            </div>
            </Col></Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
            <div style={{ marginTop: '24px' }}>
              <SelectField
                title='Lawyer'
                options={lawyersLookup}
                value={props.lawyer}
                setValue={props.setLawyer}
              />
            </div>
            </Col></Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
            <div style={{ marginTop: '24px' }}>
              <SelectField
                title='Transfer Agent'
                options={transferAgentLookup}
                value={props.transferAgent}
                setValue={props.setTransferAgent}
              />
            </div>
            </Col></Row>

            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <MultiSelectField
                    label='S&amp;P GICS Codes'
                    items={gicsLookup}
                    value={props.gicCodes}
                    onChange={props.setGicCodes}
                    width='full'
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '40px' }}>
                  <MultiSelectField
                    label='NAICS Codes'
                    items={naicsLookupCodes}
                    value={props.naicsCodes}
                    onChange={props.setNaicsCodes}
                    width='full'
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '40px', paddingTop: '10px' }}>
                  <RadioList
                      direction='column'
                      aria-label='NAICS Specification'
                      value={props.naicsCodeType}
                      onChange={(e) => {
                        localStorage.setItem('naicCodeRadioCS', `${e}`);
                        props.setNaicsCodeType(e);
                      }}
                      options={naicsCodeTypeLookup}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px' }}>
                  <MultiSelectField
                    label='SIC Codes'
                    items={sicLookup}
                    value={props.sicCodes}
                    onChange={props.setSicCodes}
                    width='full'
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={5}>
                <div style={{ marginTop: '24px', paddingTop: '10px' }}>
                  <RadioList
                    direction='column'
                    aria-label='SIC Codes'
                    value={props.sicCodeType}
                    onChange={(e) => {
                      localStorage.setItem('sicCodeRadioCS', `${e}`);
                      props.setSicCodeType(e);
                    }}
                    // onChange={setSicCodeType}
                    options={sicCodeTypeLookup}
                  />
                </div>
              </Col>
            </Row>

            <div style={{ marginTop: '24px' }}>
              <Col>
                <Text size='md' mb='md' weight='medium'>
                  Issue Bids &amp; DRIPs
                </Text>
                <Col className='stock-indices'>
                    <input
                      type='checkbox'
                      id='normalCourse'
                      checked={props.normalCourseIssuerBid}
                      onChange={(e) => {
                        localStorage.setItem(
                          'normalCourse',
                          `${e.target.checked}`
                        );
                        props.setNormalCourseIssuerBid(e.target.checked);
                      }}
                    ></input>
                    <label
                      style={{ marginRight: '10px' }}
                      htmlFor='normalCourse'
                    >
                      {' '}
                      Normal Course Issuer Bid
                    </label>
                </Col>
                <Col className='stock-indices'>
                    <input
                      type='checkbox'
                      id='substantialIssuer'
                      checked={props.substantialIssuerBid}
                      onChange={(e) => {
                        localStorage.setItem(
                          'substantialIssuer',
                          `${e.target.checked}`
                        );
                        props.setSubstantialIssuerBid(e.target.checked);
                      }}
                    ></input>
                    <label
                      style={{ marginRight: '10px' }}
                      htmlFor='substantialIssuer'
                    >
                      {' '}
                      Substantial Issuer Bid
                    </label>
                </Col>
                <Col className='stock-indices'>
                    <input
                      type='checkbox'
                      id='reinvestmentPlan'
                      checked={props.dividendReinvestmentPlan}
                      onChange={(e) => {
                        localStorage.setItem(
                          'reinvestmentPlan',
                          `${e.target.checked}`
                        );
                        props.setDividendReinvestmentPlan(e.target.checked);
                      }}
                    ></input>
                    <label
                      style={{ marginRight: '10px' }}
                      htmlFor='reinvestmentPlan'
                    >
                      {' '}
                      Dividend Reinvestment Plan
                    </label>
                </Col>
              </Col>
            </div>

            { props.setTextSearch && (
              <Row>
                <Col xs={12} sm={6} md={6} lg={5}>
                  <TextField
                    mt='lg'
                    mb='sm'
                    label='Text Search - Operations or Capital Stock '
                    value={props.textSearch || ''}
                    onChange={props.setTextSearch}
                    width='full'
                    fullWidth
                  />
                </Col>
              </Row>            
            )}

            {(
              props.setActive2 &&
              props.setActive3 &&
              props.setActive4 &&
              props.setActive5 &&
              props.setFinSearchSortOpt &&
              props.setDataItem1 &&
              props.setDataItem2 &&
              props.setDataItem3 &&
              props.setDataItem4 &&
              props.setDataItem5 &&
              props.setYear1 &&
              props.setYear2 &&
              props.setYear3 &&
              props.setYear4 &&
              props.setYear5 &&
              props.setCategory1 &&
              props.setCategory2 &&
              props.setCategory3 &&
              props.setCategory4 &&
              props.setCategory5 &&
              props.setBoolOp1 &&
              props.setBoolOp2 &&
              props.setBoolOp3 &&
              props.setBoolOp4 &&
              props.setBoolOp5 &&
              props.setOperation1 &&
              props.setOperation2 &&
              props.setOperation3 &&
              props.setOperation4 &&
              props.setOperation5 &&
              props.setValue1 &&
              props.setValue2 &&
              props.setValue3 &&
              props.setValue4 &&
              props.setValue5 &&
              props.setRowsToShow
            ) && (
              <Row>
                <Col xs={12}>
                  <Heading level="2" size='lg' mt='lg' mb='md'>
                    Financial Search
                  </Heading>
                  
                  <Text color='light' mb='md'>
                    Check boxes below to include in search criteria (not necessary for displaying data item or saving searches)
                  </Text>

                  <table className='fin-search'>
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          <ScreenReaderOnly>
                            Enabled
                          </ScreenReaderOnly>
                        </th>
                        <th className="input-cell">Financial<br></br>Category</th>
                        <th className="input-cell">Data<br></br>Item</th>
                        <th className="input-cell">Databank<br></br>Year</th>
                        <th className="input-cell sm">Operation</th>
                        <th className="input-cell">Value</th>
                        <th className="input-cell sm">Sort</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="input-cell sm">
                          <ScreenReaderOnly>
                            <label htmlFor="active1">Active</label>
                          </ScreenReaderOnly>
                          <input type="checkbox" id="active1" value="1" disabled style={{ marginTop: '24px' }} checked></input>
                        </td>
                        <td style={{ paddingTop: '24px' }} className="input-cell sm">
                          Where
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Category 1'
                            value={props.category1}
                            setValue={props.setCategory1}
                            options={CategoryArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Data Item 1'
                            value={props.dataItem1}
                            setValue={props.setDataItem1}
                            options={ [{ id: '', name: '' },].concat(DataItemArray.filter(item => item[0] === props.category1).map(item => ({
                              id: item[1],
                              name: item[2]
                            })))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Year 1'
                            value={props.year1}
                            setValue={props.setYear1}
                            options={ props.category1 === '22' ? [] : YearArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Operation 1'
                            value={props.operation1}
                            setValue={props.setOperation1}
                            options={finSearchOperations}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <TextField
                            mt='lg'
                            label='Value 1'
                            value={props.value1 || ''}
                            onChange={props.setValue1} 
                            width='full'
                            hideLabel />
                        </td>
                        <td>
                          <ScreenReaderOnly>
                            <label htmlFor="finSearchSortOpt1">Search Sort Option 1</label>
                          </ScreenReaderOnly>
                          <input 
                            type="radio" 
                            id="finSearchSortOpt1" 
                            name="finSearchSortOpt" 
                            value='0' 
                            checked={props.finSearchSortOpt === '0'}
                            onChange={(e) => { 
                              props.setFinSearchSortOpt && 
                              props.setFinSearchSortOpt(e.target.value) }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                      </tr>

                      { (props.rowsToShow || 0) >= 2 && (
                      <tr>
                        <td>
                          <ScreenReaderOnly>
                            <label htmlFor="active2">Active</label>
                          </ScreenReaderOnly>
                          <input 
                            id="active2"
                            type="checkbox" 
                            checked={props.active2} 
                            onChange={(e) => {
                              props.setActive2 && props.setActive2(e.target.checked);
                            }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Boolean Operation 2'
                            value={props.boolOp2}
                            setValue={props.setBoolOp2}
                            options={[
                              { id: 'Or', name: 'Or' },
                              { id: 'And', name: 'And' },
                            ]}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Category 2'
                            value={props.category2}
                            setValue={props.setCategory2}
                            options={CategoryArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Data Item 2'
                            value={props.dataItem2}
                            setValue={props.setDataItem2}
                            options={[{ id: '', name: '' },].concat(DataItemArray.filter(item => item[0] === props.category2).map(item => ({
                              id: item[1],
                              name: item[2]
                            })))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Year 2'
                            value={props.year2}
                            setValue={props.setYear2}
                            options={ props.category2 === '22' ? [] : YearArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Operation 2'
                            value={props.operation2}
                            setValue={props.setOperation2}
                            options={finSearchOperations}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <TextField
                            mt='lg'
                            label='Value 2'
                            value={props.value2 || ''}
                            onChange={props.setValue2} 
                            width='full'
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="finSearchSortOpt2">Search Sort Option 2</label>
                          </ScreenReaderOnly>
                          <input 
                            type="radio" 
                            id="finSearchSortOpt2" 
                            name="finSearchSortOpt" 
                            value='1' 
                            checked={props.finSearchSortOpt === '1'}
                            onChange={(e) => { 
                              props.setFinSearchSortOpt && 
                              props.setFinSearchSortOpt(e.target.value) }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                      </tr>
                      )}
                      { (props.rowsToShow || 0) >= 3 && (
                      <tr>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="active3">Active</label>
                          </ScreenReaderOnly>
                          <input 
                            id="active3"
                            type="checkbox" 
                            checked={props.active3} 
                            onChange={(e) => {
                              props.setActive3 && props.setActive3(e.target.checked);
                            }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Boolean Operation 3'
                            value={props.boolOp3}
                            setValue={props.setBoolOp3}
                            options={[
                              { id: 'Or', name: 'Or' },
                              { id: 'And', name: 'And' },
                            ]}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Category 3'
                            value={props.category3}
                            setValue={props.setCategory3}
                            options={CategoryArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Data Item 3'
                            value={props.dataItem3}
                            setValue={props.setDataItem3}
                            options={[{ id: '', name: '' },].concat(DataItemArray.filter(item => item[0] === props.category3).map(item => ({
                              id: item[1],
                              name: item[2]
                            })))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Year 3'
                            value={props.year3}
                            setValue={props.setYear3}
                            options={ props.category3 === '22' ? [] : YearArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Operation 3'
                            value={props.operation3}
                            setValue={props.setOperation3}
                            options={finSearchOperations}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <TextField
                            mt='lg'
                            label='Value 3'
                            value={props.value3 || ''}
                            onChange={props.setValue3} 
                            width='full'
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="finSearchSortOpt3">Search Sort Option 3</label>
                          </ScreenReaderOnly>
                          <input 
                            type="radio" 
                            id="finSearchSortOpt3" 
                            name="finSearchSortOpt" 
                            value='2' 
                            checked={props.finSearchSortOpt === '2'}
                            onChange={(e) => { 
                              props.setFinSearchSortOpt && 
                              props.setFinSearchSortOpt(e.target.value) }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                      </tr>
                      )}
                      { (props.rowsToShow || 0) >= 4 && (
                      <tr>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="active4">Active</label>
                          </ScreenReaderOnly>
                          <input 
                            id="active4"
                            type="checkbox" 
                            checked={props.active4} 
                            onChange={(e) => {
                              props.setActive4 && props.setActive4(e.target.checked);
                            }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Boolean Operation 4'
                            value={props.boolOp4}
                            setValue={props.setBoolOp4}
                            options={[
                              { id: 'Or', name: 'Or' },
                              { id: 'And', name: 'And' },
                            ]}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Category 4'
                            value={props.category4}
                            setValue={props.setCategory4}
                            options={CategoryArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Data Item 4'
                            value={props.dataItem4}
                            setValue={props.setDataItem4}
                            options={[{ id: '', name: '' },].concat(DataItemArray.filter(item => item[0] === props.category4).map(item => ({
                              id: item[1],
                              name: item[2]
                            })))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Year 4'
                            value={props.year4}
                            setValue={props.setYear4}
                            options={props.category4 === '22' ? [] :YearArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Operation 4'
                            value={props.operation4}
                            setValue={props.setOperation4}
                            options={finSearchOperations}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <TextField
                            mt='lg'
                            label='Value 4'
                            value={props.value4 || ''}
                            onChange={props.setValue4}
                            width='full'
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="finSearchSortOpt4">Search Sort Option 4</label>
                          </ScreenReaderOnly>
                          <input 
                            type="radio" 
                            id="finSearchSortOpt4" 
                            name="finSearchSortOpt" 
                            value='3' 
                            checked={props.finSearchSortOpt === '3'}
                            onChange={(e) => { 
                              props.setFinSearchSortOpt && 
                              props.setFinSearchSortOpt(e.target.value) }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                      </tr>
                      )}
                      { ((props.rowsToShow || 0 ) >= 5) && (
                      <tr>
                        <td>
                          <ScreenReaderOnly>
                            <label htmlFor="active5">Active</label>
                          </ScreenReaderOnly>
                          <input 
                            id="active5"
                            type="checkbox" 
                            checked={props.active5} 
                            onChange={(e) => {
                              props.setActive5 && props.setActive5(e.target.checked);
                            }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Boolean Operation 5'
                            value={props.boolOp5}
                            setValue={props.setBoolOp5}
                            options={[
                              { id: 'Or', name: 'Or' },
                              { id: 'And', name: 'And' },
                            ]}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Category 5'
                            value={props.category5}
                            setValue={props.setCategory5}
                            options={CategoryArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Data Item 5'
                            value={props.dataItem5}
                            setValue={props.setDataItem5}
                            options={[{ id: '', name: '' },].concat(DataItemArray.filter(item => item[0] === props.category5).map(item => ({
                              id: item[1],
                              name: item[2]
                            })))}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <SelectField
                            title='Year 5'
                            value={props.year5}
                            setValue={props.setYear5}
                            options={props.category5 === '22' ? [] : YearArray.map(item => ({
                              id: item[0],
                              name: item[1]
                            }))}
                            hideLabel />
                        </td>
                        <td className="input-cell sm">
                          <SelectField
                            title='Operation 5'
                            value={props.operation5}
                            setValue={props.setOperation5}
                            options={finSearchOperations}
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <TextField
                            mt='lg'
                            label='Value 5'
                            value={props.value5 || ''}
                            onChange={props.setValue5}
                            width='full'
                            hideLabel />
                        </td>
                        <td className="input-cell">
                          <ScreenReaderOnly>
                            <label htmlFor="finSearchSortOpt5">Search Sort Option 5</label>
                          </ScreenReaderOnly>
                          <input 
                            type="radio" 
                            id="finSearchSortOpt5" 
                            name="finSearchSortOpt" 
                            value='4' 
                            checked={props.finSearchSortOpt === '4'}
                            onChange={(e) => { 
                              props.setFinSearchSortOpt && 
                              props.setFinSearchSortOpt(e.target.value) }} 
                            style={{ marginTop: '24px' }} />
                        </td>
                      </tr>
                      )}
                    </tbody>
                  </table>

                  <div style={{ marginTop: '24px', textAlign: 'center' }}>
                  { (props.rowsToShow || 0) < 5 && (
                    <button type='button' onClick={() => { props.setRowsToShow && props.setRowsToShow((props.rowsToShow || 0) + 1) }} style={{ marginRight: '24px' }}>
                      Add Another Line
                    </button>
                  )}
                  { (props.rowsToShow || 0) >= 2 && (
                    <button type='button' onClick={() => { 
                        clearFinSearchRow(props.rowsToShow || 0); 
                        props.setRowsToShow && props.setRowsToShow((props.rowsToShow || 0) - 1);  
                      }}>
                      Remove Last Line
                    </button>
                  )}
                  </div>

                  <div style={{ marginTop: '24px', textAlign: 'center' }}>
                    <RadioList
                      direction='row'
                      aria-label=''
                      value={props.finSearchSortDirection || ''}
                      onChange={(e) => {
                        props.setFinSearchSortDirection && props.setFinSearchSortDirection(e);
                      }}
                      options={[
                        {
                          value: '0',
                          label: 'Sort Ascending'
                        },
                        {
                          value: '1',
                          label: 'Sort Descending'
                        },
                        {
                          value: '2',
                          label: 'Sort by Company'
                        },
                      ]}
                    />
                  </div>

                  <ul>
                    <li>
                      Databank Year – Year of (Fiscal Period Date where year end is between July 1 and December 31; otherwise it is Year minus 1)
                    </li>
                    <li>
                      MRA – Most Recent Annual
                    </li>
                  </ul>
                </Col>
              </Row>
            )}

            { (props.savedSearches && props.setNewSavedSearchName && props.saveSearch && props.deleteSavedSearch) && (
              <div>
                  <SavedSearchBottom
                    savedSearches={props.savedSearches.corporateSurveysAdvanced}
                    selectedSavedSearch={props.selectedSavedSearch}
                    setNewSavedSearchName={props.setNewSavedSearchName}
                    newSavedSearchName={props.newSavedSearchName}
                    saveSearch={props.saveSearch}
                    deleteSavedSearch={props.deleteSavedSearch}
                  />
              </div>
            )}

            <Row>
              <div>
                <Button
                  mt='md'
                  mb={'lg'}
                  mr={'lg'}
                  label='Search'
                  type='submit'
                />
              </div>
              <div>
              </div>
            </Row>
            <div>
              <ClearButton onClick={clearCheck} className="clearstatus" />
            </div>
          </form>
        }
        <div>

        </div>
      </div>

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff',
              width: '450px',
            }
          }
        }}
      />
    </>
  );
}
