/* eslint-disable */
import * as React from "react";
import { useParams } from "react-router";
import { useCompany } from "../../../data";
import { Page } from "../../../components/Page";
import { Card } from "../../../components/Card";
import { Accordion } from "../../../components/Accordion";
import { Text } from "../../../components/Text";
import { Heading } from "../../../components/Heading";
import { useId } from "../../../hooks";
import { ScreenReaderOnly } from "../../../components/ScreenReaderOnly";
import { ScreenReaderAvoidSplitting } from "../../../components/ScreenReaderAvoidSplitting";
import { Row } from "../../../components/Row";
import { Tiles } from "../../../components/Tiles";
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { Link } from "react-router-dom";
import { CompanyInformation } from "../../../containers/CompanyInformation";
import { FpCoverageSideNav } from "../../../containers/FpCoverageSideNav";
import { FpCoverageTopNav } from "../../../containers/FpCoverageTopNav";
import ExpandAll from "components/ExpandSettings";
import { Col } from "react-grid-system";
import { useAuthContext, useExpandSettings } from "containers/AuthProvider";
import "./index.scss";

export function CorporateSnapshotDetailsPage() {
  const params = useParams<{ companyKey: string }>();
  const authContext = useAuthContext();
  const defaultExpand =
    authContext?.expand_collapse_settings["corporate-snapshots"] ??
    authContext?.expand_collapse_settings.default;
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));
  const { data: company, isLoading } = useCompany(params.companyKey);
  const headingId = useId();
  // const [disable, setDisable] = React.useState<boolean>(false);

  const numberFormatter = new Intl.NumberFormat("en-CA");

  const currencyFormatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });

  const percentFormatter = new Intl.NumberFormat("en-CA", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const dateFormatter = (date?: string | number): string | null => {
    const dateStr = date?.toString() || "";

    if (dateStr.length < 8) {
      return null;
    }

    const theDate = new Date(
      Date.parse(
        `${dateStr.substr(0, 4)}-${dateStr.substr(4, 2)}-${dateStr.substr(
          6,
          2
        )}`
      )
    );

    // timezone adjustment UTC to EST
    theDate.setHours(theDate.getHours() + 12);
    
    return theDate.toLocaleDateString();
  };

  let earningsCurrency = company?.dailysnap?.eps["@attributes"]?.currencysymbol;

  // if currency value specified that is not Canadian dollars, use that
  earningsCurrency = !earningsCurrency || earningsCurrency == 'CAD' ? '$' : earningsCurrency;

  return (
    <Page aria-labelledby={headingId} padding="none" width="full">
      <FpCoverageTopNav
        isLoading={isLoading}
        company={company}
        hideCorpSnapshots={true}
      />

      <Page aria-labelledby={headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={["xxl", "xxl", "xxxl"]}
              weight="semi-bold"
            >
              Corporate Snapshots<ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={isLoading}
              loadingWidth={200}
              size={["xl", "xl", "xxl"]}
              weight="semi-bold"
            >
              {company?.name}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className="detail--page--card">
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll
                  setExpand={setExpand}
                  expand={expand}
                  modalname="corporate-snapshots"
                />
              </div>
            </HideOnPrint>

            <Card mb="lg">
              <CompanyInformation isLoading={isLoading} company={company} />
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Top Industry Players
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <Tiles columns={[1, 1]} isTileOverflowStyling>
                    <table className="normal-table">
                      <thead>
                        <th>Company Name</th>
                        <th className="text-right">Revenue ($000)</th>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          company?.players?.companies.map((player) => (
                            <tr>
                              <td>
                                <Link
                                  to={
                                    !player.fpid
                                      ? "#"
                                      : `/corporate-snapshots/details/fp%7Cfpsn%7C${player.fpid}`
                                  }
                                  className="black-text"
                                  style={{
                                    textDecoration: !player.fpid
                                      ? "none"
                                      : "underline",
                                  }}
                                >
                                  <Text
                                    color={player.fpid ? "main" : "light"}
                                    textDecoration="none"
                                    loading={isLoading}
                                    loadingWidth={200}
                                    size="md"
                                    weight="medium"
                                  >
                                    {player.name}
                                  </Text>
                                </Link>
                              </td>
                              <td className="text-right">
                                {numberFormatter.format(player.revenue)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Financial Ratios as of{" "}
                  {dateFormatter(company?.dailysnap?.closings.closingdate)}
                </Accordion.Heading>
                <Accordion.Content
                 isAccordianOverflowStyling
                >
                  <Tiles
                    columns={[1, 1]}
										isTileOverflowStyling
                  >
                    <table className="grouped-table">
                      <thead>
                        <tr>
                          <th>Listed</th>
                          <th>Close</th>
                          <th>
                            52-wk
                            <br />
                            High
                          </th>
                          <th>
                            52-wk
                            <br />
                            Low
                          </th>
                          <th>IAD</th>
                          <th>
                            Latest
                            <br />
                            Dividend
                          </th>
                          <th>Yield</th>
                          <th>
                            60-mo
                            <br />
                            Beta
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {company?.securityexchangelead?.securityexchange?.map(
                              (exchange) => (
                                <span>
                                  {exchange.exchange}/{exchange.symbol}
                                </span>
                              )
                            )}
                          </td>
                          <td>
                            {currencyFormatter.format(
                              company?.dailysnap?.closings?.closing || 0
                            )}
                          </td>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.closings?.high52wk || 0
                            )}{" "}
                            <br />
                            {dateFormatter(
                              company?.dailysnap?.closings?.high52wkdate
                            )}
                          </td>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.closings?.low52wk || 0
                            )}{" "}
                            <br />
                            {dateFormatter(
                              company?.dailysnap?.closings?.low52wkdate
                            )}
                          </td>
                          <td>
                            { earningsCurrency }
                            {currencyFormatter.format(
                              company?.dailysnap?.dividend?.iad || 0
                            )}
                          </td>
                          <td>
                            {currencyFormatter.format(
                              company?.dailysnap?.dividend?.latestdividend || 0
                            )}
                          </td>
                          <td>
                            {percentFormatter.format(
                              (company?.dailysnap?.priceratio?.dividendyield ||
                                0) / 100.0
                            )}
                          </td>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.beta?.beta60mo || 0
                            )}
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>
                            Avg 20-day
                            <br />
                            Volume
                          </th>
                          <th>P/E</th>
                          <th>
                            12-mo
                            <br />
                            EPS
                          </th>
                          <th>EPS Date</th>
                          <th>
                            1-yr
                            <br />
                            Return
                          </th>
                          <th>
                            3-yr
                            <br />
                            Return
                          </th>
                          <th>
                            5-yr
                            <br />
                            Return
                          </th>
                          <th>
                            Market
                            <br />
                            Cap (000s)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.volumes?.volume4wkavg || 0
                            )}
                          </td>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.priceratio?.peratio || 0
                            )}
                          </td>
                          <td>
                            {numberFormatter.format(
                              company?.dailysnap?.eps?.interim || 0
                            )}
                          </td>
                          <td>
                            {dateFormatter(company?.dailysnap?.eps?.epsdate)}
                          </td>
                          <td>
                            {percentFormatter.format(
                              (company?.dailysnap?.return?.return1yr || 0) /
                                100.0
                            )}
                          </td>
                          <td>
                            {percentFormatter.format(
                              (company?.dailysnap?.return?.return3yr || 0) /
                                100.0
                            )}
                          </td>
                          <td>
                            {percentFormatter.format(
                              (company?.dailysnap?.return?.return5yr || 0) /
                                100.0
                            )}
                          </td>
                          <td>
                            $
                            {numberFormatter.format(
                              company?.dailysnap?.marketcap || 0
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Annual Financials
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <Tiles columns={[1, 1]} isTileOverflowStyling>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          company?.htmlFinancialTables?.body.innerHTML || "",
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          </div>

          <FpCoverageSideNav
            isLoading={isLoading}
            company={company}
            hideCorpSnapshots={true}
          />
        </Row>
      </Page>
    </Page>
  );
}
