import * as React from 'react';
import { useId } from '../../hooks';

export function CompanyLogo () {
  const id = `logo_${useId()}`;
  return (
    <svg
      height={15}
      viewBox="0 0 672 108"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label="Postmedia Logo"
    >
      <defs>
        <path id={`${id}__a`} d="M110.884 107.94H0V.681h110.884V107.94z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`${id}__b`} fill="#fff">
          <use xlinkHref={`#${id}__a`} />
        </mask>
        <path
          d="M46.665 44.72h10.528c6.821 0 11.064 3.278 11.064 9.381v.175c0 5.317-3.986 9.385-10.793 9.385H46.665V44.72zM100.092.681H0V108h46.665V75.788h10.35c13.899 0 25.054-7.44 25.054-21.778v-.175c0-12.651-8.942-21.411-23.726-21.411H33.031V94.37H13.627V14.319h83.63V94.37H67.483L56.83 108h54.054V.681h-10.792z"
          fill="#fff"
          mask={`url(#${id}__b)`}
        />
        <g fill="#fff">
          <path d="M168.435 63.676c6.84 0 10.832-4.083 10.832-9.4v-.185c0-6.13-4.263-9.411-11.103-9.411h-10.561v18.996h10.832zm-24.513-31.338h25.405c14.824 0 23.798 8.79 23.798 21.487v.185c0 14.383-11.195 21.838-25.133 21.838h-10.389v18.66h-13.681v-62.17zM250.59 63.601v-.185c0-10.651-7.818-19.531-18.822-19.531-11.011 0-18.65 8.704-18.65 19.351v.18c0 10.656 7.811 19.539 18.822 19.539 11.017 0 18.65-8.699 18.65-19.354zm-51.774 0v-.185c0-17.665 13.945-32.144 33.124-32.144 19.185 0 32.952 14.304 32.952 31.964v.18c0 17.674-13.945 32.15-33.124 32.15-19.179 0-32.952-14.301-32.952-31.965zM269.664 85.442l8.082-9.685c5.591 4.628 11.46 7.554 18.558 7.554 5.598 0 8.975-2.221 8.975-5.862v-.175c0-3.468-2.141-5.233-12.524-7.9-12.524-3.202-20.607-6.668-20.607-19.006v-.179c0-11.277 9.061-18.737 21.757-18.737 9.061 0 16.786 2.842 23.092 7.895l-7.099 10.311c-5.511-3.816-10.931-6.128-16.165-6.128-5.241 0-7.997 2.396-7.997 5.413v.18c0 4.087 2.664 5.417 13.41 8.168 12.616 3.288 19.721 7.816 19.721 18.651v.17c0 12.347-9.411 19.271-22.827 19.271-9.418 0-18.915-3.281-26.376-9.941M342.785 44.946H323.87V32.338h51.503v12.608h-18.915v49.562h-13.673V44.946M382.802 32.338h14.744l16.337 26.295 16.344-26.295h14.738v62.17h-13.588V53.921l-17.494 26.55h-.35l-17.315-26.29v40.327h-13.416v-62.17M458.453 32.338h46.884v12.167h-33.296v12.612h29.298v12.167h-29.298v13.047h33.746v12.177h-47.334v-62.17M541.104 82.16c11.183 0 18.73-7.553 18.73-18.559v-.185c0-11.007-7.547-18.736-18.73-18.736H530.53v37.48h10.574zM516.85 32.338h24.254c19.53 0 33.025 13.413 33.025 30.898v.18c0 17.499-13.495 31.092-33.025 31.092H516.85v-62.17zM584.71 94.508h13.674v-62.17H584.71zM646.557 68.479l-8.255-20.157-8.261 20.157h16.516zm-14.388-36.586h12.616l26.641 62.615h-14.295l-5.684-13.948h-26.291l-5.677 13.948h-13.944l26.634-62.615z" />
        </g>
      </g>
    </svg>
  );
}
