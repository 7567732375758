import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function EducationIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={[
        'M3.99986 9.01142V12.44L9.99986 15.7143L15.9999 12.44V9.01142L9.99986 12.2857L3.99986 9.01142ZM9.99986 0.285706L0.571289 5.42856L9.99986 10.5714L17.7141 6.36285V12.2857H19.4284V5.42856L9.99986 0.285706Z',
      ]}
    />
  );
}
