import * as React from 'react';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { useId, useQueryParams } from '../../../hooks';
import {
  getCorporateSurveysSearchResults,
  useDirectorsSearchResults,
} from '../../../data';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { Link } from 'react-router-dom';
import {
  useGetAccessToken,
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { useHistory } from 'react-router';
import { useSearchParams } from 'components/UseSearchParams';
import { CommonSearchSelectedResult } from 'components/CommonSearchSelectedResult';
import { HideOnPrint } from 'components/HideOnMobile';
import { stringify } from 'query-string';

export function DirectorsSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(
    ['personName', 'companyName', 'companySearchType', 'position', 'gender', 'companySearchType', 'companyTypePublic', 'companyTypePrivate', 'companyTypeFP500', 'companyTypeNotForProfit', 'targetFPIndustry', 'deptHeads', 'gicCodes', 'sicCodes', 'sicPrimary', 'naicsCodes', 'naicsPrimary', 'city', 'provinces', 'countries', 'school', 'degree', 'speciality', 'fellowship', 'dateInfo', 'page', 'searchType'],
    { onlyStrings: true }
  );
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [load, onLoad] = React.useState();
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();

  const sortOptions = [
    {
      id: 'SortName',
      name: 'Person',
    },
    {
      id: 'CompanyName',
      name: 'Company',
    },
    {
      id: 'Position',
      name: 'Position',
    },
  ];

  const [sortOption, setSortOption] = React.useState('SortName');

  const searchResults = useDirectorsSearchResults({
    personName: queryParams.personName || '',
    companyName: queryParams.companyName || '',
    companySearchType: queryParams.companySearchType || '',
    position: queryParams.position || undefined,
    gender: queryParams.gender || undefined,
    companyTypePublic: queryParams.companyTypePublic || undefined,
    companyTypePrivate: queryParams.companyTypePrivate || undefined,
    companyTypeFP500: queryParams.companyTypeFP500 || undefined,
    companyTypeNotForProfit: queryParams.companyTypeNotForProfit || undefined,
    targetFPIndustry: queryParams.targetFPIndustry || undefined,
    deptHeads: queryParams.deptHeads || undefined,
    gicCodes: queryParams.gicCodes || '',
    sicCodes: queryParams.sicCodes || '',
    sicPrimary: queryParams.sicPrimary || undefined,
    naicsCodes: queryParams.naicsCodes || '',
    naicsPrimary: queryParams.naicsPrimary || undefined,
    city: queryParams.city || undefined,
    provinces: queryParams.provinces
    ? queryParams.provinces.split(',')
    : undefined,
    countries: queryParams.countries
      ? queryParams.countries.split(',')
      : undefined,
    school: queryParams.school
      ? queryParams.school.split(',')
      : undefined,
    degree: queryParams.degree
      ? queryParams.degree.split(',')
      : undefined,
    speciality: queryParams.speciality
      ? queryParams.speciality.split(',')
      : undefined,
    fellowship: queryParams.fellowship
      ? queryParams.fellowship.split(',')
      : undefined,
    dateInfo: queryParams.dateInfo || undefined,
    sort: sortOption || undefined,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.numResults));
  const [loadingCompany, setLoadingCompany] = React.useState<boolean>(false);
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.numResults === 0;
  const getAccessToken = useGetAccessToken();
  const handleGetCompany = async (companyKey: string) => {
    setLoadingCompany(true);
    const getCompanySurvey = await getCorporateSurveysSearchResults(
      { searchForm: 'basic', companyName: companyKey, page: 0, numberOfRows: 1 },
      getAccessToken
    );
    setLoadingCompany(false);
    history.push(
      `/corporate-surveys/details/${getCompanySurvey.results[0].key}`
    );
  };
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/directory-of-directors/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: 'details/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  const exportCsvResults = () => {
    props.history.push({
      pathname: 'csv-export/',
      state: {
        keys: selectedKeys,
      },
    });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        Directory of Directors - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link={`/directory-of-directors/search/#${queryParams.searchType}`}
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results for{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{' '}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.companyName}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            numTotalRows={searchResults.resolvedData?.numResults}
            numRowsPerPage={numberOfRows}
            loading={loading || loadingCompany}
            expectedColumns={3}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            data={searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            sortOptions={sortOptions}
            sortOption={sortOption}
            setSortOption={setSortOption}
            history={props.history.location.state}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.results.map((r) => r.key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Person</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Company</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Position</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.results.map((r, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === r.key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={r.key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/directory-of-directors/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.name}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        r.lead && handleGetCompany(r.lead);
                      }}
                      to={`/corporate-surveys/details/${r.key}`}
                      className="black-text"
                    >
                      <Text>{r.lead}</Text>
                    </Link>
                  </Table.Cell>
                  <Table.Cell minWidth="md">
                    <Text>{r.position}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
