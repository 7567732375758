import * as React from 'react';

import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { Text } from '../../../components/Text';
import { FPRow } from '../../../components/FPRow/FPRow';
import { FPCol } from '../../../components/FPCol/FPCol';
import { Link } from '../../../components/Link';

import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 2px 5px 2px rgba(215, 215, 215, 0.5);
  border-radius: 8px;
  padding: 1rem;
`;
const P = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  margin: 0;
`;

export function ExternalDatabasesIndexPage () {
  const headingId = useId();

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
        External Databases
      </Heading>

      <Text mt="md" mb="xl" lineHeight="md">
        Choose a data source to search.
      </Text>
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
              Equifax Commercial Law Record
            </Heading>
            <Text lineHeight="md">
              <P>
                Provides public information on over 410,000 commercial legal
                suits and judgements from courts across Canada.
              </P>
            </Text>
            <Link to="/external-databases/search/equifax-commerical-law-record" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
              Cengage Learning Gale - Encyclopedia of Associations
            </Heading>
            <Text lineHeight="md" maxLength={200}>
              {/* Includes profiles of 3,000 Canadian associations extracted from the Gale database the <em>Encyclopedia of Associations: International Organizations</em>. The profiles provide addresses and descriptions of professional societies, trade associations, labor unions, cultural and religious organizations, fan clubs, and other groups of all types */}
              <P>
                Includes profiles of 3,000 Canadian associations extracted
                from the Gale database...
              </P>
            </Text>
            <Link to="/external-databases/search/encyclopedia-of-associations" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
              Industry Canada - Canadian Corporate Names
            </Heading>
            <Text lineHeight="md">
              <P>
                Provides summary information relating to federally
                incorporated companies included in the Canadian Federal
                Corporations and Directors database.
              </P>
            </Text>
            <Link to="/external-databases/search/candian-corporate-names" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
              Cengage Learning Gale - Ward's Business Directory
            </Heading>
            <Text lineHeight="md">
              <P>
                Includes profiles of 9,000 Canadian companies extracted from
                the Gale database{' '}
                <em>
                  Ward's Business Directory of Private and Public Companies in
                  Canada and Mexico
                </em>
                . The profiles provide current company information of Canadian
                firms, most of which are private companies.
              </P>
            </Text>
            <Link to="/external-databases/search/wards-business-directory" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
              Industry Canada - Canadian Federal Corporations and Directors
            </Heading>
            <Text lineHeight="md">
              <P>
                Provides information on over 630,000 federally incorporated
                companies.
              </P>
            </Text>
            <Link to="/external-databases/search/canadian-federal-coporations-and-directors" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
    </Page>
  );
}
