import * as React from 'react';
import { Box } from '../../components/Box';
import { Heading } from '../../components/Heading';
import { Page } from '../../components/Page';
import { Text } from '../../components/Text';
import { useId } from '../../hooks';

export function AboutUsPage () {
  const headingId = useId();

  return (
    <Page aria-labelledby={headingId}>
      <Box as="header" mb="md" flexDirection="column">
        <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
          Financial Post Data
        </Heading>
        <Text size="lg" color="light" weight="medium" lineHeight="md">
          Canada's Financial Information Source
        </Text>
      </Box>

      <Heading level="2" mb="md">
        Who We Are
      </Heading>
      <Text lineHeight="md" as="p" mb="md">
        Since 1927, Financial Post Data is Canada’s leading source for corporate
        information offering the country’s most trusted company database, with
        detailed information on Canadian public and private companies.
      </Text>
      <Text lineHeight="md" as="p" mb="md">
        We provide searchable access to detailed financial statements and
        ratios, dividend and M&A information, Canadian director profiles,
        investor information and more.
      </Text>

      <Heading level="2" mb="md">
        What We Offer Clients
      </Heading>
      <Heading level="3">Company Data</Heading>
      <Text lineHeight="md" as="p" mb="md">
        Financial and operational information on publicly traded Canadian
        companies, including screenable fundamental data on Canadian mid-cap and
        large cap companies, as well as historical, investor and industry
        reports on Canada’s top 500 publicly traded companies.
      </Text>

      <Heading level="3">Fixed Income Data</Heading>
      <Text lineHeight="md" as="p" mb="md">
        Comprehensive information on Canadian corporate debt and preferreds,
        with details on amounts outstanding, coupon and yield, features,
        maturity dates and more.
      </Text>

      <Heading level="3">Dividend &amp; Pricing Data</Heading>
      <Text lineHeight="md" as="p" mb="md">
        Dividend and pricing information on all Canadian public and foreign
        inter-listed companies, trusts and mutual funds, including corporate
        actions and name changes, rights offerings, foreign currency dividends,
        dividend reinvestment plans, dividend changes, dividend payment records
        and historical price highs, lows and close.
      </Text>

      <Heading level="3">Predecessor &amp; Defunct Companies</Heading>
      <Text lineHeight="md" as="p" mb="md">
        The definitive record of changes to public Canadian companies, active
        companies trading, reporting or in receivership, and those no longer in
        existence, with details of name and incorporation changes,
        amalgamations, takeovers, acquisitions and dissolutions dating back to
        1929.
      </Text>

      <Heading level="3">Mergers &amp; Acquisitions</Heading>
      <Text lineHeight="md" as="p" mb="md">
        Detailed information on mergers, acquisitions and divestitures involving
        Canadian companies since 1994, including transaction values, control
        changes, acquirors, vendors, targets, and financial and legal advisors.
      </Text>

      <Heading level="3">New Issues</Heading>
      <Text lineHeight="md" as="p" mb="md">
        A thorough, up-to-date, detailed and structured searchable database of
        all public and private offerings by Canadian publicly traded companies,
        governments and agencies, showing offering type, security type, market,
        currency, exchange, status, accountants, lawyers and underwriters.
      </Text>

      <Heading level="3">Directory of Directors</Heading>
      <Text lineHeight="md" as="p" mb="md">
        Published since 1931, the Directory of Directors contains information on
        over 16,000 directors and executives of Canadian companies, showing
        directorships and offices held, degrees held, schools attended and
        professional associations.
      </Text>

      <Heading level="2" mb="md">
        Contact
      </Heading>
      <Text lineHeight="md" as="p">
        Financial Post Data
        <br />
        Postmedia Network Inc.
        <br />
        365 Bloor Street East, 6th Floor
        <br />
        Toronto, Ontario M4W 3L4
        <br />
        <a href="mailto:fpadvisor@postmedia.com">fpadvisor@postmedia.com</a>
        <br />
      </Text>
    </Page>
  );
}
