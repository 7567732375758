import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { RadioList } from '../../../components/RadioList';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function FixedIncomeCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();

  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);

  const [exportValues, setExportValues] = React.useState(true);
  const [exportFeatures, setExportFeatures] = React.useState(true);
  const [exportAgents, setExportAgents] = React.useState(true);

  const getAccessToken = useGetAccessToken();
  const generateCsvFields = () => {
    const fields = [];
    if (exportValues) {
      fields.push('values');
    }
    if (exportFeatures) {
      fields.push('features');
    }
    if (exportAgents) {
      fields.push('agents');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/fpfi/fi_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Post Fixed Income in Canada - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Fixed Income record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            setExportSelectDeselect(e.target.checked);
                            setExportValues(e.target.checked);
                            setExportFeatures(e.target.checked);
                            setExportAgents(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_values"
                          checked={exportValues}
                          onChange={(e) => {
                            setExportValues(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_values"
                        >
                          Values
                        </label><ul>
                          <li>DBRS Rating / Date</li>
                          <li>Issued / Price / Date</li>
                          <li>Outstanding / Date</li>
                          <li>Dividend/Interest / Frequency / Dates</li>
                          <li>Dividend Yield</li>
                          <li>Interest Details</li>
                          <li>Private Placement</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_features"
                          checked={exportFeatures}
                          onChange={(e) => {
                            setExportFeatures(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_features"
                        >
                          Features
                        </label>
                        <ul>
                          <li>Redemption</li>
                          <li>Retraction</li>
                          <li>Shareholder Convertible</li>
                          <li>Company Convertible</li>
                          <li>Exchangeable</li>
                          <li>Extendible</li>
                          <li>Sinking Fund</li>
                          <li>Cumulative</li>
                          <li>Secured</li>
                          <li>Change of Control</li>
                          <li>Market</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_agents"
                          checked={exportAgents}
                          onChange={(e) => {
                            setExportAgents(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_agents"
                        >
                          Agents
                        </label>
                        <ul>
                          <li>Lead Underwriter(s)</li>
                          <li>Transfer Agent (preferred only)</li>
                          <li>Registrar (preferred only)</li>
                          <li>Trustee (debt only)</li>
                          <li>Fiscal/Paying Agent (debt only)</li>
                          <li>Guarantor</li>
                        </ul>
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
