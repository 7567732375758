import * as React from 'react';
import { Text } from 'components/Text';
import { ReadMoreLess } from 'components/ReadMoreLess';
import './index.scss'
import {
  titleValues,
  countriesLookup,
  locationLookup,
  provincesLookupbyAlphaCode,
  searchTypePredecessorLookup,
  leagueTablesLookup,
  statusDatesTypeLookup,
  securityTypesLookup,
  distributionTypeLookup,
  corporateGovernmentLookup,
  gicsLookup,
  offeringProcedureLookup,
  termsLookup,
  marketLookup,
  currencyLookup,
  exchangesNewIssuesLookup,
  incorporationLookup,
  incorporationLocationLookup,
  sicLookup,
  positionsLookup,
  fpIndustryLookup,
  schoolsLookup,
  degreesLookup,
  specialtiesLookup,
  fellowshipsLookup,
  typeLookup,
  dealValueGTLookup,
  dealValueLTLookup,
  percentageBoughtGTLookup,
  percentageBoughtLTLookup,
  legalAdvisorLookup,
  advisorLookup,
  termsOfIssueLookup,
  copuponFeatureLookup,
  dbrsRatingsLookup,
  leagueCreditTypeLookup,
  leagueCountByLookup,
  grossProceedsGTLookup,
  grossProceedsLTLookup,
  underwriterLookup,
  jurisdictionLookup,
  companyTypesLookup,
  companySubTypesExtendedLookup,
  companyTypesExtendedLookup
} from 'data/lookups';

export interface IQueryParam {
  companyName?: string;
  stockSymbol?: string;
  searchType?: string;
  symbol?: string;
  'provinces[]'?: string;
  'country[]'?: string;
  'countrySnapshot[]'?: string;
  searchLocationTarget?: string;
  searchLocationVendor?: string;
  searchLocationAcquiror?: string;
  city?: string;
  searchPredecessor?: string;
  issuerName?: string;
  startDate?: string;
  endDate?: string;
  startDatePd?: string;
  endDatePd?: string;
  leagueTables?: string;
  creditType?: string;
  countBy?: string;
  statusDatesType?: string;
  securityTypes?: string;
  distributionType?: string;
  corporateGovernment?: string;
  searchTargetGICS?: string;
  offeringProcedure?: string;
  terms?: string;
  market?: string;
  currency?: string;
  exchange?: string;
  incorporation?: string;
  issueType?: string;
  termsOfIssue?: string;
  gics?: string;
  naic?: string;
  sic?: string;
  amountIssuedGT?: string;
  amountIssuedLT?: string;
  debtMaturity?: string;
  dbrsRatingLT?: string;
  dbrsRatingGT?: string;
  couponFeature?: string;
  couponPrcntMin?: string;
  couponPrcntMax?: string;
  optionCallable?: string;
  optionConvertible?: string;
  optionChangeControl?: string;

  personName?: string;
  companySearchType?: string;
  position?: string;
  gender?: string;
  companyTypePublic?: string;
  companyTypePrivate?: string;
  companyTypeFP500?: string;
  companyTypeNotForProfit?: string;
  targetFPIndustry?: string;
  deptHeads?: string;
  gicCodes?: string;
  sicCodes?: string;
  sicPrimary?: string;
  naicsCodes?: string;
  naicsPrimary?: string;
  provinces?: string;
  countries?: string;
  school?: string;
  degree?: string;
  speciality?: string;
  fellowship?: string;
  searchRestrictTarget?: boolean;
  searchRestrictVendor?: boolean;
  searchRestrictAcquiror?: boolean;
  searchTargetType?: string;
  searchVendorType?: string;
  searchAcquirorType?: string;
  searchTargetFPIndustry?: string;
  searchVendorFPIndustry?: string;
  searchAcquirorFPIndustry?: string;
  searchTargetNAICS?: string;
  searchVendorNAICS?: string;
  searchAcquirorNAICS?: string;
  searchTargetSIC?: string;
  searchVendorSIC?: string;
  searchAcquirorSIC?: string;
  searchVendorGICS?: string;
  searchAcquirorGICS?: string;
  searchFinancialAdvisor?: string;
  searchLegalAdvisor?: string;
  searchPubliclyTraded?: boolean;
  searchDivestiture?: boolean;
  searchPrivatelyOwned?: boolean;
  searchForeignTarget?: boolean;
  searchDealValueGt?: string;
  searchDealValueLt?: string;
  searchPercentageBoughtGt?: string;
  searchPercentageBoughtLt?: string;
  keywords?: string;
  searchDealDescription?: boolean;
  searchTargetBusinessDescription?: boolean;
  searchVendorBusinessDescription?: boolean;
  searchAcquirorBusinessDescription?: boolean;
  searchPaymentCash?: boolean;
  searchPaymentStock?: boolean;
  searchPaymentAssumptionDebt?: boolean;
  searchPaymentOther?: boolean;
  searchDatesAnnounced?: boolean;
  searchDatesUpdated?: boolean;
  searchDealStatusCompleted?: boolean;
  searchDealStatusPending?: boolean;
  searchDealStatusTerminated?: boolean;
  statusActive?: boolean;
  statusInactive?: boolean;
  searchPdFPIndustry?: string;
  searchPdGics?: string;
  searchPdNaics?: string;
  searchPdSic?: string;
  searchPdNameChange?: boolean;
  searchPdMergedNewName?: boolean;
  searchPdMergedSameName?: boolean;
  searchPdMergedAmalgamated?: boolean;
  searchPdPrivatizedNewName?: boolean;
  searchPdPrivatizedAcquired?: boolean;
  searchPdSucceeded?: boolean;
  searchPdBankruptLiquidated?: boolean;
  searchPdCharterCancelled?: boolean;
  searchPdCharterRevived?: boolean;
  searchPdCharterSurrendered?: boolean;
  searchPdInBankruptcy?: boolean;
  searchPdInReceivership?: boolean;
  searchPdRelisted?: boolean;
  searchPdStruckFromRegister?: boolean;
  searchPdUnderCcaa?: boolean;
  searchPdWindingUp?: boolean;
  searchPdWoundUpLiquidated?: boolean;
  searchPdContinuance?: boolean;
  searchPdFormedByAmalgamation?: boolean;
  searchPdSuccessor?: boolean;
  searchPdIncorporationFrom?: string;
  searchPdIncorporationTo?: string;
  searchPdInitial?: boolean;
  searchPdCurrent?: boolean;
  naicsCodeType?: string;
  sicCodeType?: string;
  companyHistoryText?: string;
  historyMatchType?: string;
  searchNewIssuesGICS?: string;
  grossProceedsGt?: string;
  grossProceedsLt?: string;
  searchUnderwriter?: string;
  optionAsBookrunner?: boolean;
  optionAsLead?: boolean;
  exchangeListed?: string;
  exchangeSuspended?: string;
  areaCode?: string;
  companyType?: string;
  companySubType?: string;
  jurisdiction?: string;
  majorShareholder?: string;
  auditor?: string;
  banker?: string;
  lawyer?: string;
  transferAgent?: string;
  idxSPTSXComp?: string;
  idxSPTSX60?: string;
  idxTSX30?: string;
  idxTSXVenTier1?: string;
  idxTSXVenTier2?: string;
  idxTSXVenTier3?: string;
  idxFP500?: string;
  idxCleantech?: string;
  idxBlockchain?: string;
  idxCannabis?: string;
  normalCourseIssuerBid?: string;
  substantialIssuerBid?: string;
  dividendReinvestmentPlan?: string;
  incorporationYear?: string;
  optShareholderInterest10Prcnt?: string;
  optForeignOwnership10Prcnt?: string;
  subsidiary?: string;
  directorOfficerName?: string;
  stCommon?: boolean;
  stFlowThrough?: boolean;
  stConvertibleDebt?: boolean;
  stPreferredShares?: boolean;
  stPreferredSecurity?: boolean;
  stSplitShares?: boolean;
  stInvestmentTrust?: boolean;
  stFlowThroughLtdPartnership?: boolean;
  stBondDebentureNote?: boolean;
  stMediumTermNote?: boolean;
  stMortgageBond?: boolean;
  stDepositNote?: boolean;
  stSerialDebt?: boolean;
  stAssetBackedSecurities?: boolean;
  stMortgageBackedSecurities?: boolean;
  stCapitalSecurities?: boolean;
  stMapleBond?: boolean;
  stBusinessTrust?: boolean;
  stOilGasRoyaltyTrust?: boolean;
  stREIT?: boolean;
  stPowerPipelineTrust?: boolean;
  stOtherTrust?: boolean;
  stConvertibleTypes?: string;
}

export interface CommonSearchSelectedResultProps {
	searchCriteria: any
	history: any
	switchCase?: (param: any, value: any) => string | string[] | null | undefined
}

const defaultSwitchCase = (queryParams: IQueryParam) : Array<{ titleValue: string, criteriaValue?: string }> => {
  const formattedCriteria = [];
  const naicsLookupString = localStorage.getItem('naicsLookupCodes') || '[{ "id": "", "name": "NAICS Codes" }]]';
  const naicsLookupCodes = JSON.parse(naicsLookupString);

  if (queryParams.companyName) {
    let type = ''
    if (queryParams.searchType === 'all') {
      type = ' (All words)'
    }
    if (queryParams.searchType === 'phrase') {
      type = ' (Exact phrase)'
    }
    formattedCriteria.push({
      titleValue: 'Company',
      criteriaValue: queryParams.companyName + type,
    });
    if (queryParams.searchRestrictTarget) {
      formattedCriteria.push({
        titleValue: 'Restrict to',
        criteriaValue: 'Target',
      });
    }
    if (queryParams.searchRestrictVendor) {
      formattedCriteria.push({
        titleValue: 'Restrict to',
        criteriaValue: 'Vendor',
      });
    }
    if (queryParams.searchRestrictAcquiror) {
      formattedCriteria.push({
        titleValue: 'Restrict to',
        criteriaValue: 'Acquiror',
      });
    }
  }

  if (queryParams.stockSymbol) {
    formattedCriteria.push({
      titleValue: 'Stock Symbol',
      criteriaValue: queryParams.stockSymbol,
    });
  }
/*
  if (queryParams.searchType) {
    let value;
    switch (queryParams.searchType) {
      case 'all-words':
        value = 'All Words'; break;
      case 'exact-phrase':
        value = 'Exact Phrase'; break;
      case 'E':
        value = 'Exact Phrase'; break;
      case 'A':
        value = 'All Words'; break;
      case 'basic':
        value = 'Basic'; break;
      case 'advanced':
        value = 'Advanced'; break;
    }
    formattedCriteria.push({
      titleValue: 'Search type',
      criteriaValue: value,
    });
  }
*/
  if (queryParams.city) {
    formattedCriteria.push({
      titleValue: 'City',
      criteriaValue: queryParams.city,
    });
  }

  if (queryParams.searchPredecessor) {
    formattedCriteria.push({
      titleValue: 'Search predecessor names',
      criteriaValue: queryParams.searchPredecessor === 'true' ? 'Yes' : 'No',
    })
  }

  if (queryParams['provinces[]']) {
    const data = queryParams['provinces[]'].split(',');
    formattedCriteria.push({
      titleValue: 'Provinces',
      criteriaValue: provincesLookupbyAlphaCode
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.provinces) {
    const data = queryParams.provinces.split(',');
    formattedCriteria.push({
      titleValue: 'Provinces',
      criteriaValue: provincesLookupbyAlphaCode
        .filter((item) => data.includes(item.name))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams['country[]']) {
    const data = queryParams['country[]'].split(',');
    formattedCriteria.push({
      titleValue: 'Countries',
      criteriaValue: data.join(', ')
    });
  }

  if (queryParams['countrySnapshot[]']) {
    const data = queryParams['countrySnapshot[]'].split(',');
    formattedCriteria.push({
      titleValue: 'Countries',
      criteriaValue: countriesLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.searchLocationTarget) {
    formattedCriteria.push({
      titleValue: 'Target Location',
      criteriaValue: queryParams.searchLocationTarget,
    });
  }

  if (queryParams.searchLocationVendor) {
    formattedCriteria.push({
      titleValue: 'Vendor Location',
      criteriaValue: queryParams.searchLocationVendor
    });
  }

  if (queryParams.searchLocationAcquiror) {
    const data = queryParams.searchLocationAcquiror.split(',');
    formattedCriteria.push({
      titleValue: 'Acquiror Location',
      criteriaValue: queryParams.searchLocationAcquiror
    });
  }

  if (queryParams.countries) {
    const data = queryParams.countries.split(',');
    formattedCriteria.push({
      titleValue: 'Countries',
      criteriaValue: countriesLookup
        .filter((item) => data.includes(item.name))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.issuerName) {
    formattedCriteria.push({
      titleValue: 'Issuer',
      criteriaValue: queryParams.issuerName,
    });
  }

  if (queryParams.startDatePd) {
    formattedCriteria.push({
      titleValue: 'Start Date',
      criteriaValue: queryParams.startDatePd,
    });
  }

  if (queryParams.endDatePd) {
    formattedCriteria.push({
      titleValue: 'End Date',
      criteriaValue: queryParams.endDatePd,
    });
  }

  if (queryParams.leagueTables) {
    const data = queryParams.leagueTables.split(',');
    formattedCriteria.push({
      titleValue: 'League Tables',
      criteriaValue: leagueTablesLookup
      .filter((item) => data.includes(item.value))
      .map((item) => item.label)
      .join(', ')
    });
  }

  if (queryParams.creditType) {
    const data = queryParams.creditType.split(',');
    formattedCriteria.push({
      titleValue: 'Credit Type',
      criteriaValue: leagueCreditTypeLookup
      .filter((item) => data.includes(item.value))
      .map((item) => item.label)
      .join(', ')
    });
  }

  if (queryParams.countBy) {
    const data = queryParams.countBy.split(',');
    formattedCriteria.push({
      titleValue: 'Count By',
      criteriaValue: leagueCountByLookup
      .filter((item) => data.includes(item.value))
      .map((item) => item.label)
      .join(', ')
    });
  }

  if (queryParams.statusDatesType) {
    let dates = ''
    if (queryParams.startDate) {
      dates = ' From ' + queryParams.startDate;
    }
    if (queryParams.endDate) {
      dates += ' to ' + queryParams.endDate;
    }
    const data = queryParams.statusDatesType.split(',');
    formattedCriteria.push({
      titleValue: 'Status dates',
      criteriaValue: statusDatesTypeLookup
      .filter((item) => data.includes(item.value))
      .map((item) => item.label)
      .join(', ') + dates
    });
  }

  if (queryParams.securityTypes === 'choose') {
    const data = [];
    if (queryParams.stCommon) {
      data.push('Common & Common Units');
    }
    if (queryParams.stFlowThrough) {
      data.push('Flow-Through');
    }
    if (queryParams.stConvertibleDebt) {
      data.push('Convertible Debt');
    }
    if (queryParams.stPreferredShares) {
      data.push('Preferred Shares');
    }
    if (queryParams.stPreferredSecurity) {
      data.push('Preferred Security');
    }
    if (queryParams.stSplitShares) {
      data.push('Split Shares');
    }
    if (queryParams.stInvestmentTrust) {
      data.push('Investment Trust');
    }
    if (queryParams.stFlowThroughLtdPartnership) {
      data.push('Flow-through Limited Partnership');
    }
    if (queryParams.stBondDebentureNote) {
      data.push('Bond, Debenture and Note');
    }
    if (queryParams.stMediumTermNote) {
      data.push('Medium Term Note');
    }
    if (queryParams.stMortgageBond) {
      data.push('Mortgage Bond');
    }
    if (queryParams.stDepositNote) {
      data.push('Deposit Note');
    }
    if (queryParams.stSerialDebt) {
      data.push('Serial Debt');
    }
    if (queryParams.stAssetBackedSecurities) {
      data.push('Asset-backed Securities');
    }
    if (queryParams.stMortgageBackedSecurities) {
      data.push('Mortgage-backed Securities');
    }
    if (queryParams.stCapitalSecurities) {
      data.push('Capital Securities');
    }
    if (queryParams.stMapleBond) {
      data.push('Maple Bond');
    }
    if (queryParams.stConvertibleTypes && queryParams.stConvertibleTypes === 'exclude') {
      data.push('Trust Unit only');
    } else if (queryParams.stConvertibleTypes && queryParams.stConvertibleTypes === 'include') {
      data.push('Convertible Debt only');
    }
    if (queryParams.stBusinessTrust) {
      data.push('Business Trust');
    }
    if (queryParams.stOilGasRoyaltyTrust) {
      data.push('Oil & Gas Royalty Trust');
    }
    if (queryParams.stREIT) {
      data.push('REITs (Real Estate Investment Trust)');
    }
    if (queryParams.stPowerPipelineTrust) {
      data.push('Power & Pipeline Trust');
    }
    if (queryParams.stOtherTrust) {
      data.push('Other Trust');
    }
    formattedCriteria.push({
      titleValue: 'Security types',
      criteriaValue: data.join(' + ')
    });
  }

  if (queryParams.distributionType) {
    const data = queryParams.distributionType.split(',');
    formattedCriteria.push({
      titleValue: 'Distribution type',
      criteriaValue: distributionTypeLookup
      .filter((item) => data.includes(item.value))
      .map((item) => item.label)
      .join(', ')
    });
  }

  if (queryParams.corporateGovernment) {
    const data = queryParams.corporateGovernment.split(',');
    formattedCriteria.push({
      titleValue: 'Instution type',
      criteriaValue: corporateGovernmentLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.offeringProcedure) {
    const data = queryParams.offeringProcedure.split(',');
    formattedCriteria.push({
      titleValue: 'Offering procedure',
      criteriaValue: offeringProcedureLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.terms) {
    const data = queryParams.terms.split(',');
    formattedCriteria.push({
      titleValue: 'Terms',
      criteriaValue: termsLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.market) {
    const data = queryParams.market.split(',');
    formattedCriteria.push({
      titleValue: 'Market',
      criteriaValue: marketLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.currency) {
    const data = queryParams.currency.split(',');
    formattedCriteria.push({
      titleValue: 'Currency',
      criteriaValue: currencyLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.exchange) {
    const data = queryParams.exchange.split(',');
    formattedCriteria.push({
      titleValue: 'Exchanges',
      criteriaValue: exchangesNewIssuesLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.incorporation) {
    const data = queryParams.incorporation.split(',');
    formattedCriteria.push({
      titleValue: 'Incorporation',
      criteriaValue: incorporationLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.issueType) {
    let value;
    switch (queryParams.issueType) {
      case 'both':
        value = 'Both'; break;
      case 'debt':
        value = 'Debt'; break;
      case 'preferred':
        value = 'Preferred'; break;
      default:
        value = 'Both';
    }
    formattedCriteria.push({
      titleValue: 'Issue type',
      criteriaValue: value,
    });
  }

  if (queryParams.termsOfIssue) {
    const data = queryParams.termsOfIssue.split(',');
    formattedCriteria.push({
      titleValue: 'Terms of issue',
      criteriaValue: termsOfIssueLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.gics) {
    const data = queryParams.gics.split(',');
    formattedCriteria.push({
      titleValue: 'S&P GICs',
      criteriaValue: gicsLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.gicCodes) {
    const data = queryParams.gicCodes.split(',');
    formattedCriteria.push({
      titleValue: 'S&P GICs',
      criteriaValue: gicsLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.naic) {
    const data = queryParams.naic.split(',');
    formattedCriteria.push({
      titleValue: 'NAICs',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => data.includes(item.id))
        .map((item: { name: string; }) => item.name)
        .join(', ')
    });
  }

  if (queryParams.naicsCodes) {
    const data = queryParams.naicsCodes.split(',');
    formattedCriteria.push({
      titleValue: 'NAICs',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => data.includes(item.id))
        .map((item: { name: string; }) => item.name)
        .join(', ')
    });
  }
  if (queryParams.sic) {
    const data = queryParams.sic.split(',');
    formattedCriteria.push({
      titleValue: 'SIC',
      criteriaValue: sicLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }
  if (queryParams.sicCodes) {
    const data = queryParams.sicCodes.split(',');
    formattedCriteria.push({
      titleValue: 'SIC',
      criteriaValue: sicLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.amountIssuedGT) {
    formattedCriteria.push({
      titleValue: 'Amount issued',
      criteriaValue: queryParams.amountIssuedGT
    });
  }

  if (queryParams.amountIssuedLT) {
    formattedCriteria.push({
      titleValue: 'Amount issued',
      criteriaValue: queryParams.amountIssuedLT
    });
  }

  if (queryParams.debtMaturity) {
    formattedCriteria.push({
      titleValue: 'Debt maturity',
      criteriaValue: queryParams.debtMaturity
    });
  }

  if (queryParams.dbrsRatingGT) {
    const data = queryParams.dbrsRatingGT.split(',');
    formattedCriteria.push({
      titleValue: 'DBRS Rating',
      criteriaValue: dbrsRatingsLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.dbrsRatingLT) {
    const data = queryParams.dbrsRatingLT.split(',');
    formattedCriteria.push({
      titleValue: 'DBRS Rating',
      criteriaValue: dbrsRatingsLookup
      .filter((item) => data.includes(item.id))
      .map((item) => item.name)
      .join(', ')
    });
  }

  if (queryParams.couponFeature) {
    const data = queryParams.couponFeature.split(',');
    formattedCriteria.push({
      titleValue: 'Coupon feature',
      criteriaValue: copuponFeatureLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.couponPrcntMin) {
    formattedCriteria.push({
      titleValue: 'Coupon % Min',
      criteriaValue: queryParams.couponPrcntMin
    });
  }

  if (queryParams.couponPrcntMax) {
    formattedCriteria.push({
      titleValue: 'Coupon % Max',
      criteriaValue: queryParams.couponPrcntMax
    });
  }

  if (queryParams.optionCallable) {
    formattedCriteria.push({
      titleValue: 'Option callable',
      criteriaValue: queryParams.optionCallable === 'true' ? 'Yes' : 'No'
    });
  }

  if (queryParams.optionConvertible) {
    formattedCriteria.push({
      titleValue: 'Option convertible',
      criteriaValue: queryParams.optionConvertible === 'true' ? 'Yes' : 'No'
    });
  }

  if (queryParams.optionChangeControl) {
    formattedCriteria.push({
      titleValue: 'Option change control',
      criteriaValue: queryParams.optionChangeControl === 'true' ? 'Yes' : 'No'
    });
  }

  if (queryParams.personName) {
    formattedCriteria.push({
      titleValue: 'Person name',
      criteriaValue: queryParams.personName
    });
  }

  if (queryParams.companySearchType) {
    let value;
    switch (queryParams.companySearchType) {
      case 'lead-company':
        value = 'Lead Company'; break;
      case 'current-company':
        value = 'Current Company(s)'; break;
      case 'previous-company':
        value = 'Previous Company(s)'; break;
      case 'all':
        value = 'All'; break;
    }
    formattedCriteria.push({
      titleValue: 'Company position type',
      criteriaValue: value,
    });
  }

  if (queryParams.position) {
    const data = queryParams.position.split(',');
    formattedCriteria.push({
      titleValue: 'Position',
      criteriaValue: positionsLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.gender) {
    let value;
    switch (queryParams.gender) {
      case 'M':
        value = 'Male'; break;
      case 'F':
        value = 'Female'; break;
      case '':
        value = 'Either'; break;
    }
    formattedCriteria.push({
      titleValue: 'Gender',
      criteriaValue: value,
    });
  }

  if (queryParams.companyTypePublic) {
    formattedCriteria.push({
      titleValue: 'Company public',
      criteriaValue: queryParams.companyTypePublic === 'true' ? 'Yes' : 'No',
    });
  }

  if (queryParams.companyTypePrivate) {
    formattedCriteria.push({
      titleValue: 'Company private',
      criteriaValue: queryParams.companyTypePrivate === 'true' ? 'Yes' : 'No',
    });
  }

  if (queryParams.companyTypeFP500) {
    formattedCriteria.push({
      titleValue: 'Company FP500',
      criteriaValue: queryParams.companyTypeFP500 === 'true' ? 'Yes' : 'No',
    });
  }

  if (queryParams.companyTypeNotForProfit) {
    formattedCriteria.push({
      titleValue: 'Company NHP',
      criteriaValue: queryParams.companyTypeNotForProfit === 'true' ? 'Yes' : 'No',
    });
  }

  if (queryParams.targetFPIndustry) {
    const data = queryParams.targetFPIndustry.split(',');
    formattedCriteria.push({
      titleValue: 'FP Industry',
      criteriaValue: fpIndustryLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.deptHeads) {
    let value;
    switch (queryParams.deptHeads) {
      case '33':
        value = 'human resources'; break;
      case '35':
        value = 'information technology'; break;
      case '66':
        value = 'operations'; break;
      case '67':
        value = 'purchasing'; break;
      case '34':
          value = 'sales & marketing'; break;
    }
    formattedCriteria.push({
      titleValue: 'Department/Functional Heads',
      criteriaValue: value,
    });
  }

  if (queryParams.school) {
    const data = queryParams.school.split(',');
    formattedCriteria.push({
      titleValue: 'School',
      criteriaValue: queryParams.school.replaceAll(',', '; ').replaceAll('&#44;', ',')
    });
  }

  if (queryParams.degree) {
    formattedCriteria.push({
      titleValue: 'Degree',
      criteriaValue: queryParams.degree.replaceAll(',', '; ').replaceAll('&#44;', ',')
    });
  }

  if (queryParams.speciality) {
    formattedCriteria.push({
      titleValue: 'Specialty',
      criteriaValue: queryParams.speciality.replaceAll(',', '; ').replaceAll('&#44;', ',')
    });
  }

  if (queryParams.fellowship) {
    formattedCriteria.push({
      titleValue: 'Fellowship',
      criteriaValue: queryParams.fellowship.replaceAll(',', '; ').replaceAll('&#44;', ',')
    });
  }

  if (queryParams.searchTargetType) {
    const data = queryParams.searchTargetType;
    formattedCriteria.push({
      titleValue: 'Target Type',
      criteriaValue: typeLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchVendorType) {
    const data = queryParams.searchVendorType;
    formattedCriteria.push({
      titleValue: 'Vendor Type',
      criteriaValue: typeLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchAcquirorType) {
    const data = queryParams.searchAcquirorType;
    formattedCriteria.push({
      titleValue: 'Acquiror Type',
      criteriaValue: typeLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchTargetFPIndustry) {
    const data = queryParams.searchTargetFPIndustry;
    formattedCriteria.push({
      titleValue: 'Target FP Industry',
      criteriaValue: fpIndustryLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchVendorFPIndustry) {
    const data = queryParams.searchVendorFPIndustry;
    formattedCriteria.push({
      titleValue: 'Vendor FP Industry',
      criteriaValue: fpIndustryLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchAcquirorFPIndustry) {
    const data = queryParams.searchAcquirorFPIndustry;
    formattedCriteria.push({
      titleValue: 'Acquiror FP Industry',
      criteriaValue: fpIndustryLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchTargetNAICS) {
    const data = queryParams.searchTargetNAICS;
    formattedCriteria.push({
      titleValue: 'Target NAICS',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => item.id === data)
        .map((item: { name: string; }) => item.name).toString()
    });
  }
  if (queryParams.searchVendorNAICS) {
    const data = queryParams.searchVendorNAICS;
    formattedCriteria.push({
      titleValue: 'Vendor NAICS',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => item.id === data)
        .map((item: { name: string; }) => item.name).toString()
    });
  }
  if (queryParams.searchAcquirorNAICS) {
    const data = queryParams.searchAcquirorNAICS;
    formattedCriteria.push({
      titleValue: 'Acquiror NAICS',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => item.id === data)
        .map((item: { name: string; }) => item.name).toString()
    });
  }

  if (queryParams.searchTargetSIC) {
    const data = queryParams.searchTargetSIC;
    formattedCriteria.push({
      titleValue: 'Target SIC',
      criteriaValue: sicLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchUnderwriter) {
    const data = queryParams.searchUnderwriter.split(',');
    formattedCriteria.push({
      titleValue: 'Underwriter',
      criteriaValue: underwriterLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.optionAsBookrunner) {
    formattedCriteria.push({
      titleValue: 'Underwriter',
      criteriaValue: 'As Bookrunner',
    });
  }

  if (queryParams.optionAsLead) {
    formattedCriteria.push({
      titleValue: 'Underwriter',
      criteriaValue: 'As Lead',
    });
  }

  if (queryParams.searchVendorSIC) {
    const data = queryParams.searchVendorSIC;
    formattedCriteria.push({
      titleValue: 'Vendor SIC',
      criteriaValue: sicLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchAcquirorSIC) {
    const data = queryParams.searchAcquirorSIC;
    formattedCriteria.push({
      titleValue: 'Acquiror SIC',
      criteriaValue: sicLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchTargetGICS) {
    const data = queryParams.searchTargetGICS;
    formattedCriteria.push({
      titleValue: 'Target GICS',
      criteriaValue: gicsLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchVendorGICS) {
    const data = queryParams.searchVendorGICS;
    formattedCriteria.push({
      titleValue: 'Vendor GICS',
      criteriaValue: gicsLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchAcquirorGICS) {
    const data = queryParams.searchAcquirorGICS;
    formattedCriteria.push({
      titleValue: 'Acquiror GICS',
      criteriaValue: gicsLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchFinancialAdvisor) {
    const data = queryParams.searchFinancialAdvisor;
    formattedCriteria.push({
      titleValue: 'Financial Advisor',
      criteriaValue: advisorLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchLegalAdvisor) {
    const data = queryParams.searchLegalAdvisor;
    formattedCriteria.push({
      titleValue: 'Legal Advisor',
      criteriaValue: legalAdvisorLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchPubliclyTraded) {
    formattedCriteria.push({
      titleValue: 'Deal Type',
      criteriaValue: 'Publicly Traded',
    });
  }
  if (queryParams.searchDivestiture) {
    formattedCriteria.push({
      titleValue: 'Deal Type',
      criteriaValue: 'Divestiture',
    });
  }
  if (queryParams.searchPrivatelyOwned) {
    formattedCriteria.push({
      titleValue: 'Deal Type',
      criteriaValue: 'Privately Owned',
    });
  }
  if (queryParams.searchForeignTarget) {
    formattedCriteria.push({
      titleValue: 'Deal Type',
      criteriaValue: 'Foreign Target',
    });
  }

  if (queryParams.searchDealValueGt) {
    const data = queryParams.searchDealValueGt;
    formattedCriteria.push({
      titleValue: 'Deal Value',
      criteriaValue: dealValueGTLookup
      .filter((item) => item.id === data)
      .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchDealValueLt) {
    const data = queryParams.searchDealValueLt;
    formattedCriteria.push({
      titleValue: 'Deal Value',
      criteriaValue: dealValueLTLookup
      .filter((item) => item.id === data)
      .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchPercentageBoughtGt) {
    const data = queryParams.searchPercentageBoughtGt;
    formattedCriteria.push({
      titleValue: '% Bought',
      criteriaValue: percentageBoughtGTLookup
      .filter((item) => item.id === data)
      .map((item) => item.name).toString()
    });
  }
  if (queryParams.searchPercentageBoughtLt) {
    const data = queryParams.searchPercentageBoughtLt;
    formattedCriteria.push({
      titleValue: '% Bought',
      criteriaValue: percentageBoughtLTLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }

  if (queryParams.searchPaymentCash) {
    formattedCriteria.push({
      titleValue: 'Payment',
      criteriaValue: 'Cash',
    });
  }
  if (queryParams.searchPaymentStock) {
    formattedCriteria.push({
      titleValue: 'Payment',
      criteriaValue: 'Stock',
    });
  }
  if (queryParams.searchPaymentAssumptionDebt) {
    formattedCriteria.push({
      titleValue: 'Payment',
      criteriaValue: 'Assumption of Debt',
    });
  }
  if (queryParams.searchPaymentOther) {
    formattedCriteria.push({
      titleValue: 'Payment',
      criteriaValue: 'Other',
    });
  }

  if (queryParams.keywords) {
    let type = ' (All words)';
    if (queryParams.searchType === 'E') {
      type = ' (Exact Phrase)'
    }
    formattedCriteria.push({
      titleValue: 'Keywords',
      criteriaValue: queryParams.keywords + type,
    });
  }

  if (queryParams.searchDealDescription) {
    formattedCriteria.push({
      titleValue: 'Description',
      criteriaValue: 'Deal',
    });
  }
  if (queryParams.searchTargetBusinessDescription) {
    formattedCriteria.push({
      titleValue: 'Description',
      criteriaValue: 'Target Business',
    });
  }
  if (queryParams.searchVendorBusinessDescription) {
    formattedCriteria.push({
      titleValue: 'Description',
      criteriaValue: 'Vendor Business',
    });
  }
  if (queryParams.searchAcquirorBusinessDescription) {
    formattedCriteria.push({
      titleValue: 'Description',
      criteriaValue: 'Acquiror Business',
    });
  }

  if (queryParams.searchDatesAnnounced) {
    let dates = ''
    if (queryParams.startDate) {
      dates = ' From ' + queryParams.startDate;
    }
    if (queryParams.endDate) {
      dates += ' to ' + queryParams.endDate;
    }
    formattedCriteria.push({
      titleValue: 'Announced',
      criteriaValue: dates
    });
  }
  if (queryParams.searchDatesUpdated) {
    let dates = ''
    if (queryParams.startDate) {
      dates = ' From ' + queryParams.startDate;
    }
    if (queryParams.endDate) {
      dates += ' to ' + queryParams.endDate;
    }
    formattedCriteria.push({
      titleValue: 'Updated',
      criteriaValue: dates,
    });
  }

  if (queryParams.searchDealStatusCompleted) {
    formattedCriteria.push({
      titleValue: 'Deal Status',
      criteriaValue: 'Completed',
    });
  }
  if (queryParams.searchDealStatusPending) {
    formattedCriteria.push({
      titleValue: 'Deal Status',
      criteriaValue: 'Pending',
    });
  }
  if (queryParams.searchDealStatusTerminated) {
    formattedCriteria.push({
      titleValue: 'Deal Status',
      criteriaValue: 'Terminated',
    });
  }
  if (queryParams.statusActive) {
    formattedCriteria.push({
      titleValue: 'Company Status',
      criteriaValue: 'Active',
    });
  }
  if (queryParams.statusInactive) {
    formattedCriteria.push({
      titleValue: 'Company Status',
      criteriaValue: 'Inactive',
    });
  }

  if (queryParams.searchPdNameChange) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Name change',
    });
  }
  if (queryParams.searchPdMergedNewName) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Merged/Amalgamated - continued with new name',
    });
  }
  if (queryParams.searchPdMergedSameName) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Merged/Amalgamated - continued with same name',
    });
  }
  if (queryParams.searchPdMergedAmalgamated) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Merged/Amalgamated',
    });
  }
  if (queryParams.searchPdPrivatizedNewName) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Privatized/Acquired',
    });
  }
  if (queryParams.searchPdSucceeded) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Succeeded - continued with new name',
    });
  }
  if (queryParams.searchPdBankruptLiquidated) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Bankrupt, liquidated',
    });
  }
  if (queryParams.searchPdCharterCancelled) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Charter cancelled',
    });
  }
  if (queryParams.searchPdCharterRevived) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Charter revived',
    });
  }
  if (queryParams.searchPdCharterSurrendered) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Charter surrendered',
    });
  }
  if (queryParams.searchPdInBankruptcy) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'In bankruptcy',
    });
  }
  if (queryParams.searchPdInReceivership) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'In receivership',
    });
  }
  if (queryParams.searchPdRelisted) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Relisted',
    });
  }
  if (queryParams.searchPdStruckFromRegister) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Struck from register',
    });
  }
  if (queryParams.searchPdUnderCcaa) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Under CCAA',
    });
  }
  if (queryParams.searchPdWindingUp) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Winding up',
    });
  }
  if (queryParams.searchPdWoundUpLiquidated) {
    formattedCriteria.push({
      titleValue: 'Event',
      criteriaValue: 'Wound up, liquidated',
    });
  }
  // Incorporation History Type Criteria
  let incHistType = '';
  if (queryParams.searchPdContinuance) {
    incHistType += 'Continuance';
  }
  if (queryParams.searchPdFormedByAmalgamation) {
    if (incHistType.length) {
      incHistType += ', ';
    }
    incHistType += 'Formed By Amalgamation';
  }
  if (queryParams.searchPdSuccessor) {
    if (incHistType.length) {
      incHistType += ', ';
    }
    incHistType += 'Successor';
  }
  if (incHistType.length) {
    formattedCriteria.push({
      titleValue: 'Incorporation History Type',
      criteriaValue: incHistType,
    });
  }
  if (queryParams.exchangeListed) {
    formattedCriteria.push({
      titleValue: 'Exchange listed',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.exchangeSuspended) {
    formattedCriteria.push({
      titleValue: 'Exchange suspended',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.areaCode) {
    formattedCriteria.push({
      titleValue: 'Area code',
      criteriaValue: queryParams.areaCode
    });
  }
  if (queryParams.companyType) {
    const data = queryParams.companyType;
    formattedCriteria.push({
      titleValue: 'Company type',
      criteriaValue: companyTypesExtendedLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.companySubType) {
    const data = queryParams.companySubType;
    formattedCriteria.push({
      titleValue: 'Company Sub-type',
      criteriaValue: companySubTypesExtendedLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.jurisdiction) {
    const data = queryParams.jurisdiction;
    formattedCriteria.push({
      titleValue: 'Jurisdiction',
      criteriaValue: jurisdictionLookup
        .filter((item) => data === item.id)
        .map((item) => item.name.replace('---, ', ''))
        .join(', ')
    });
  }
  if (queryParams.majorShareholder) {
    formattedCriteria.push({
      titleValue: 'Major shareholder',
      criteriaValue: queryParams.majorShareholder
    });
  }
  if (queryParams.auditor) {
    formattedCriteria.push({
      titleValue: 'Auditor',
      criteriaValue: queryParams.auditor
    });
  }
  if (queryParams.banker) {
    formattedCriteria.push({
      titleValue: 'Banker',
      criteriaValue: queryParams.banker
    });
  }
  if (queryParams.lawyer) {
    formattedCriteria.push({
      titleValue: 'Lawyer',
      criteriaValue: queryParams.lawyer
    });
  }
  if (queryParams.transferAgent) {
    formattedCriteria.push({
      titleValue: 'Transfer agent',
      criteriaValue: queryParams.transferAgent
    });
  }
  if (queryParams.idxSPTSXComp) {
    formattedCriteria.push({
      titleValue: 'S&P/TSX Composite',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxSPTSX60) {
    formattedCriteria.push({
      titleValue: 'S&P/TSX 60',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxTSX30) {
    formattedCriteria.push({
      titleValue: 'S&P/TSX 30',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxTSXVenTier1) {
    formattedCriteria.push({
      titleValue: 'TSX-VEN Tier 1',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxTSXVenTier2) {
    formattedCriteria.push({
      titleValue: 'TSX-VEN Tier 2',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxTSXVenTier3) {
    formattedCriteria.push({
      titleValue: 'TSX-VEN Tier 3',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxFP500) {
    formattedCriteria.push({
      titleValue: 'FP500',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxCleantech) {
    formattedCriteria.push({
      titleValue: 'Cleantech',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxCannabis) {
    formattedCriteria.push({
      titleValue: 'Cannabis',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.idxBlockchain) {
    formattedCriteria.push({
      titleValue: 'Blockchain',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.normalCourseIssuerBid) {
    formattedCriteria.push({
      titleValue: 'Normal Course Issuer Bid',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.substantialIssuerBid) {
    formattedCriteria.push({
      titleValue: 'Substantial Issuer Bid',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.dividendReinvestmentPlan) {
    formattedCriteria.push({
      titleValue: 'Dividend Reinvestment Plan',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.searchPdFPIndustry) {
    const data = queryParams.searchPdFPIndustry.split(',');
    formattedCriteria.push({
      titleValue: 'FP Industry',
      criteriaValue: fpIndustryLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }

  if (queryParams.searchPdGics) {
    const data = queryParams.searchPdGics.split(',');
    formattedCriteria.push({
      titleValue: 'S&P GICS',
      criteriaValue: gicsLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }
  if (queryParams.searchPdNaics) {
    const data = queryParams.searchPdNaics.split(',');
    formattedCriteria.push({
      titleValue: 'NAICS Codes',
      criteriaValue: naicsLookupCodes
        .filter((item: { id: string; }) => data.includes(item.id))
        .map((item: { name: string; }) => item.name)
        .join(', ')
    });
  }
  if (queryParams.searchPdSic) {
    const data = queryParams.searchPdSic.split(',');
    formattedCriteria.push({
      titleValue: 'SIC Codes',
      criteriaValue: sicLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }
  if (queryParams.searchPdNaics && queryParams.naicsCodeType) {
    formattedCriteria.push({
      titleValue: 'NAICS Code Type',
      criteriaValue: queryParams.naicsCodeType === '1' ? 'Primary NAICS' : 'All NAICS',
    });
  }
  if (queryParams.searchPdSic && queryParams.sicCodeType) {
    formattedCriteria.push({
      titleValue: 'Sic Code Type',
      criteriaValue: queryParams.sicCodeType === '1' ? 'Primary SIC' : 'All SICs',
    });
  }
  if (queryParams.searchPdIncorporationFrom) {
    const data = queryParams.searchPdIncorporationFrom.split(',');
    let value = incorporationLocationLookup
                  .filter((item) => data.includes(item.id))
                  .map((item) => item.name)
                  .join(', ');
    if (queryParams.searchPdInitial) {
      value += (' (' + 'Initial' + ')')
    }
    formattedCriteria.push({
      titleValue: 'Incorporation Jurisdiction From',
      criteriaValue: value
    });
  }
  if (queryParams.searchPdIncorporationTo) {
    const data = queryParams.searchPdIncorporationTo.split(',');
    let value = incorporationLocationLookup
                  .filter((item) => data.includes(item.id))
                  .map((item) => item.name)
                  .join(', ');
    if (queryParams.searchPdCurrent) {
      value += (' (' + 'Current' + ')')
    }
    formattedCriteria.push({
      titleValue: 'Incorporation Jurisdiction To',
      criteriaValue: value
    });
  }
  if (queryParams.companyHistoryText) {
    formattedCriteria.push({
      titleValue: 'Company History Text',
      criteriaValue: queryParams.companyHistoryText + ' (' + (queryParams.historyMatchType === 'exactphrase' ? 'Exact phrase' : 'All words') + ')',
    });
  }
  if (queryParams.searchNewIssuesGICS) {
    const data = queryParams.searchNewIssuesGICS.split(',');
    formattedCriteria.push({
      titleValue: 'S&P GICS',
      criteriaValue: gicsLookup
        .filter((item) => data.includes(item.id))
        .map((item) => item.name)
        .join(', ')
    });
  }
  if (queryParams.grossProceedsGt) {
    const data = queryParams.grossProceedsGt;
    formattedCriteria.push({
      titleValue: 'Gross Proceeds',
      criteriaValue: grossProceedsGTLookup
      .filter((item) => item.id === data)
      .map((item) => item.name).toString()
    });
  }
  if (queryParams.grossProceedsLt) {
    const data = queryParams.grossProceedsLt;
    formattedCriteria.push({
      titleValue: 'Gross Proceeds',
      criteriaValue: grossProceedsLTLookup
        .filter((item) => item.id === data)
        .map((item) => item.name).toString()
    });
  }
  if (queryParams.incorporationYear) {
    formattedCriteria.push({
      titleValue: 'Incorporation Year',
      criteriaValue: queryParams.incorporationYear
    });
  }
  if (queryParams.optShareholderInterest10Prcnt) {
    formattedCriteria.push({
      titleValue: 'Officer/Director Interest (>=10%)',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.optForeignOwnership10Prcnt) {
    formattedCriteria.push({
      titleValue: 'Foreign Ownership (>=10%)',
      criteriaValue: 'Yes'
    });
  }
  if (queryParams.subsidiary) {
    formattedCriteria.push({
      titleValue: 'Subsidiary',
      criteriaValue: queryParams.subsidiary
    });
  }
  if (queryParams.directorOfficerName) {
    formattedCriteria.push({
      titleValue: 'Director/Officer Name',
      criteriaValue: queryParams.directorOfficerName
    });
  }

  return formattedCriteria;
}

export function CommonSearchSelectedResult (props: CommonSearchSelectedResultProps) {
	const { searchCriteria, history, switchCase } = props;

	const criteriaToIngore = ['page'];

	const populatedCriteria = Object.keys(searchCriteria).filter(c => searchCriteria[c] && !criteriaToIngore.includes(c));

  const numCriteria = switchCase !== undefined
    ? Object.keys(populatedCriteria).length
    : defaultSwitchCase(searchCriteria).length;

  return (
		history
		? <>
			<div className="selected--criteria--sec">
					<div className="selected--criteria--title">
						<span>
							<strong>Selected Criteria ({ numCriteria })</strong>
						</span>
					</div>
					<div className="selected--criteria--div">

						<ReadMoreLess
							readLessBtnClass='search--result--read--less--btn'
							readMoreBtnClass='search--result--read--more--btn'
							readMoreLink='Show All Criteria'
							readLessLink='Show Less Criteria'
							objectLength={searchCriteria.length}
						>
							{switchCase && populatedCriteria.map((item) => {
								const criteriaValue = `${switchCase(item, searchCriteria[item])}`;
								return (
									<>
										<div className='selected--criteria'>
											<span className="selected--criteria--info">
												<span className="selected--criteria--title">
													{/* @ts-ignore */}
													{titleValues[item]}
												</span>
												{ criteriaValue && `: ${criteriaValue}`}
											</span>
										</div>
									</>
								)
							}
							)}

              {!switchCase && defaultSwitchCase(searchCriteria).map(p => (
                  <div className='selected--criteria'>
                    <span className="selected--criteria--info">
                      <span className="selected--criteria--title">
                        {p.titleValue}
                      </span>
                      {p.criteriaValue && (
                        <span>
                        : {p.criteriaValue}
                        </span>
                      )}
                    </span>
                  </div>
              ))}
						</ReadMoreLess>

					</div>
				</div>
			</>
			: <></>
	);
}
