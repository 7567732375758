import * as React from 'react';
import styled from 'styled-components/macro';

import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import { getMarginStyles, MarginProps } from '../../utils/style';
import { HideOnMobile } from '../../components/HideOnMobile';
import { Row } from '../../components/Row';
import { ShowOnMobile } from '../../components/ShowOnMobile';
import { useSearchResults } from '../../data';
import { useDebounce } from '../../hooks';
import { Card } from '../../components/Card';
import { List } from '../../components/List';
import { ListItem } from '../../components/ListItem';
import { Link } from 'react-router-dom';

const IsShowingContext = React.createContext(false);
const SetIsShowingContext = React.createContext((newValue: boolean) => {});
const SearchTextContext = React.createContext('');
const SetSearchTextContext = React.createContext((newValue: string) => {});

export function GlobalSearchProvider (props: { children: React.ReactNode }) {
  const [showingSearchResults, setIsShowingSearchResults] =
    React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  return (
    <IsShowingContext.Provider value={showingSearchResults}>
      <SetIsShowingContext.Provider value={setIsShowingSearchResults}>
        <SearchTextContext.Provider value={searchText}>
          <SetSearchTextContext.Provider value={setSearchText}>
            {props.children}
          </SetSearchTextContext.Provider>
        </SearchTextContext.Provider>
      </SetIsShowingContext.Provider>
    </IsShowingContext.Provider>
  );
}
export function GlobalSearchField () {
  const setShowingSearchResults = React.useContext(SetIsShowingContext);
  const showingSearchResults = React.useContext(IsShowingContext);
  const [value, setValue] = React.useState('');
  const debouncedValue = useDebounce(value, 300);
  const searchText = React.useContext(SearchTextContext);
  const setSearchText = React.useContext(SetSearchTextContext);

  React.useEffect(() => {
    setSearchText(debouncedValue);
  }, [debouncedValue, setSearchText]);

  React.useEffect(() => {
    setValue(searchText);
  }, [searchText, setValue]);

  return (
    <Row alignItems="center" height="full" flexBasis={0} flexGrow={1}>
      <ShowOnMobile when={showingSearchResults} mr="md">
        <TextButton
          onClick={() => {
            setShowingSearchResults(false);
          }}
        >
          <ArrowLeftIcon color="primary" removeWhitespace mr="xs" />
          Back
        </TextButton>
      </ShowOnMobile>
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <div
          aria-hidden
          style={{ position: 'absolute', top: '6px', left: '4px' }}
        >
          <SearchIcon size="sm" color="subdued" />
        </div>
        <SearchInput
          placeholder="Search"
          aria-label="Search"
          type="text"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);

            if (e.target.value.length > 0) {
              setShowingSearchResults(true);
            }
          }}
        />
      </div>
    </Row>
  );
}

const TextButton = styled.button(({ theme }) => ({
  padding: 0,
  margin: 0,
  color: theme.palette.primary.main,
  background: 'none',
  border: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 500,
}));

const SearchInput = styled.input<MarginProps>((props) => ({
  flexBasis: 0,
  flexGrow: 1,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  border: '1px solid #767676',
  textIndent: '28px',
  maxWidth: '697px', // weird value but taken from mock
  ...getMarginStyles(props),
}));

export function GlobalSearchResults () {
  const isShowingSearchResults = useIsShowingSearchResults();
  const setIsShowing = React.useContext(SetIsShowingContext);
  const setSearchText = React.useContext(SetSearchTextContext);
  const { data } = useSearchResults({
    companyName: React.useContext(SearchTextContext),
    page: 1,
  });

  return (
    <ShowOnMobile when={isShowingSearchResults}>
      <Card ml="md" mr="md" mb="sm" mt="sm">
        <List>
          {data?.results.map((result) => (
            <ListItem key={result.id} weight="bold" gap="md">
              <Link
                to={`/corporate-snapshots/${result.key}`}
                onClick={() => {
                  setIsShowing(false);
                  setSearchText('');
                }}
              >
                {result.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Card>
    </ShowOnMobile>
  );
}

export function HideWhenGlobalSearchResultsOpen (props: {
  children: React.ReactNode;
}) {
  const isShowingSearchResults = useIsShowingSearchResults();

  return (
    <HideOnMobile when={isShowingSearchResults}>{props.children}</HideOnMobile>
  );
}

export function GlobalSearchBackLink () {
  const isGlobalSearchFieldFocused = useIsShowingSearchResults();
  const setIsFocused = React.useContext(SetIsShowingContext);

  return (
    <ShowOnMobile
      when={isGlobalSearchFieldFocused}
      as="button"
      onClick={() => {
        setIsFocused(false);
      }}
    >
      Back
    </ShowOnMobile>
  );
}

export function useIsShowingSearchResults () {
  return React.useContext(IsShowingContext);
}
