import * as React from 'react';
import { Heading } from '../../components/Heading';
import { Page } from '../../components/Page';
import { Text } from '../../components/Text';
import { Accordion } from '../../components/Accordion';
import { Card } from '../../components/Card';
import { useId } from '../../hooks';

export function CorporateDataPage () {
  const headingId = useId();

  return (
    <Page aria-labelledby={headingId}>
      <div style={{ maxWidth: '800px' }}>
        <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
          Corporate Data
        </Heading>

        <Text lineHeight="md" mt="md" mb="md">
          Our FP Advisor module provides access to detailed information about
          Canadian public and private companies, company directors, archival
          financial information, special analytical tools, and a lead list
          generator.
        </Text>

        <Text lineHeight="md" mt="md" mb="md">
          Full subscribers have access to 21 corporate databases.
        </Text>

        <Heading level="2" mt="xl" mb="lg" size="xl">
          FP Advisor Databases
        </Heading>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Cengage Learning Gale - Encyclopedia of Associations
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Semi-annually <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Cengage Learning Gale - Encyclopedia of Associations
                  database includes profiles of Canadian associations extracted
                  from the Gale database the Encyclopedia of Associations:
                  International Organizations. The Encyclopedia of Associations
                  provides addresses and descriptions of professional societies,
                  trade associations, labor unions, cultural and religious
                  organizations, fan clubs, and other groups of all types.
                </p>
                <p>
                  Information is provided on over 3,000 Canadian associations.
                </p>
                <p>
                  Search criteria include: organization name, contact name,
                  contact title, location (city, province, postal code, area
                  code), organization type (e.g. Cultural; or Religious),
                  organization classification (SIC), number of members, and
                  number of employees.
                </p>
                <p>
                  In addition records may include the following data elements:
                  e-mail, website URL, descriptive keywords, and founding year.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Cengage Learning Gale - Ward's Business Directory
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Semi-annually <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Cengage Learning Gale - Ward's Business Directory database
                  Includes profiles of Canadian companies extracted from the
                  Gale database Ward’s Business Directory of Private and Public
                  Companies in Canada and Mexico.
                </p>
                <p>
                  Information is provided on more than 6,500 Canadian firms, of
                  which almost 6,000 are private companies.
                </p>
                <p>
                  Search criteria include: company name, contact name, contact
                  title, location (city, province, postal code, area code),
                  incorporation type, industry classification (SIC, NAICS),
                  sales, and number of employees.
                </p>
                <p>
                  In addition records may include the following data elements:
                  e-mail, website URL, fiscal year-end, 5 years of sales data,
                  parent company, founding year, ticker and exchange info.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Equifax Commercial Law Record
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Monthly <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Equifax Commercial Law Record database contains
                  information about commercial legal lawsuits and judgements
                  from courts across Canada. The database has over 400,000
                  records, which are sorted into 4 searchable types: bankruptcy,
                  receivership, legal and miscellaneous.
                </p>
                <p>
                  The information provided for each action includes the
                  defendant's name, city and province, the plaintiff's name, the
                  type of action, the amount involved, the filing date of the
                  action, the court and its location. The court file number
                  almost always appears in the legal report, which makes it
                  possible to obtain copies of the complete writs from the
                  courthouses.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Corporate Analyzer
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Corporate Analyzer database provides
                  textual and numerical data on the top 1,400 companies publicly
                  traded in Canada. Data includes recent developments, spot data
                  (e.g., market capitalization, beta, % price change, dividend
                  yield), up to 10 years of annual results and up to 10 quarters
                  of quarterly results. Numerical data is exportable into
                  Microsoft<sup>&reg;</sup> Excel for further analyses.
                </p>
                <p>
                  The Portfolios feature is available to keep track of your
                  favourite companies' performance.
                </p>
                <p>
                  The Fundamentals feature further assist you with company
                  analyses by allowing you to select a number of companies and
                  compare their performance on a specific criterion (e.g., Total
                  Assets, Net Income or EPS) during a period of time (e.g., 2010
                  or from 2004 to 2013).
                </p>
                <p>
                  The Screens feature enables you to screen FP Analyzer’s
                  database for companies that satisfy selected criteria (e.g.,
                  Dividend Yield greater or equal to 2%).
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Corporate Snapshots
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Corporate Snapshots database provides a
                  quick overview on all publicly traded companies in Canada.
                  Information includes company descriptions, directors,
                  subsidiaries, top industry players and related industry news.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Corporate Surveys
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Corporate Surveys database combines the
                  contents of The Financial Post Survey of Mines and Energy
                  Resources and The Financial Post Survey of Industrials. The
                  database provides detailed corporate and investment
                  information on all companies publicly traded in Canada, as
                  well as information on close to 8,000 subsidiaries and
                  affiliates. Data is prepared and verified by The Financial
                  Post's team of financial analysts.
                </p>
                <p>
                  Financial Post Corporate Surveys provides you with names,
                  addresses, telephone numbers, websites, email addresses plus
                  details of operations, management, key personnel, and
                  financial information on 3,500 resource companies involved in
                  precious/base metals exploration, development and production,
                  industrial minerals, oil and gas, as well as hydroelectric
                  power generation, and on 2,500 industrial companies involved
                  in real estate development, manufacturing, forestry,
                  investment holding, financial management, communications,
                  transportation, banking, retailing and other service
                  industries.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Directory of Directors
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Directory of Directors database provides
                  information on over 28,000 business men and women; directors
                  and executives of companies incorporated in Canada.
                  Information is provided to the editors of The Financial Post
                  Directory of Directors from the individuals themselves or from
                  their companies.
                </p>
                <p>
                  The database shows each director's and executive's main
                  business address, positions held, degrees held and schools
                  attended, associations, gender, birth year and previous
                  positions when provided as well as start and end dates. When a
                  listing includes the names of more than one company, as far as
                  possible, the person's principal occupation is shown first.
                  Internet and email addresses are included, when available.
                </p>
                <p>
                  The database includes the full text of the information on
                  17,000 business executives from the "Personal Listings"
                  section of the annual print edition of The Financial Post
                  Directory of Directors.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Dividends
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Dividends database contains dividend
                  payment information on all publicly traded companies in
                  Canada. In addition, the database’s Dividend Record Archive
                  provides a record of other corporate events such as name
                  changes, company meetings, purchase offers and redemptions.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Fixed Income
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Fixed Income database allows searching for
                  Preferred Shares and Corporate Debt belonging to Canadian
                  companies. Data items include amounts outstanding, coupon and
                  yield, detailed descriptions of features, maturity dates and
                  more. Results are exportable into Excel&trade; for further
                  analyses.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Historical Reports
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Historical Reports, a.k.a. the Yellow
                  Cards, provide investors with a complete description and
                  history of the top 450 publicly traded companies in Canada.
                  Information such as mergers, acquisitions, spin-offs, stock
                  issues and redemptions can go back as far as the company’s
                  incorporation.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Industry Reports
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: 5 to 6 times yearly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Industry Reports cover 30 industries with
                  current and archived information regarding top players,
                  industry recommendations and industry data bank. The industry
                  data bank consists of seven-year financial ratios divided into
                  five groups: Screens (Dividend Yield), Safety (Current Ratio),
                  Earning Quality (Asset Turnover), Profitability and Efficiency
                  (Operating Margin), and Other Ratios. The database universe is
                  the top 450 publicly traded companies in Canada.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Investor Reports
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Investor Reports provide investment
                  recommendations, recent developments, short positions and
                  industry standard ratios for the top 450 publicly traded
                  companies in Canada. Investment recommendations include
                  I/B/E/S consensus estimates and Value Sciences recommendation.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Mergers &amp; Acquisitions
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Mergers &amp; Acquisitions database
                  provides daily updated information on mergers, acquisitions
                  and divestitures involving Canadian companies. Data goes back
                  to 1994.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post New Issues
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post New Issues database allows searching for
                  public and private offerings issued by Canadian publicly
                  traded companies, governments and agencies. Each record has
                  over 30 fields including type of offerings, security type,
                  market, currency, exchange, status, accountants, lawyers and
                  underwriters. Users can query those fields. Results are
                  exportable into Excel™ for further analyses.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Normal Course Issuer Bid
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>The financial Post Normal Course Issuer Bid</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Predecessor &amp; Defunct
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Financial Post Predecessor &amp; Defunct database gives
                  you access to information of over 28,000 formerly traded
                  companies in Canada. The information is sorted chronologically
                  and includes name changes, amalgamations, takeovers,
                  acquisitions and dissolutions.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Financial Post Substantial Issuer Bid
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Hourly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>The Financial Post Substantial Issuer Bid</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Industry Canada - Canadian Corporate Names
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Monthly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Industry Canada - Canadian Corporate Names database
                  provides summary information on over 400,000 federal
                  incorporations and business names. The information is compiled
                  by Industry Canada.
                </p>
                <p>
                  With the information in this database you can identify the
                  existence in Canada of a corporation or business name. Other
                  uses for the data include making a comparison of a newly
                  planned corporate name to existing registered names and
                  identifying where a company can operate, based upon the
                  jurisdiction in which it is registered.
                </p>
                <p>
                  The database documents contain the company name, the
                  jurisdiction in which the company has been registered, the
                  date of incorporation, and various status indicators.
                </p>
                <p>
                  Noteworthy: documents are not deleted from the database.
                  Instead, when something about a company changes, the document
                  is altered to reflect the change. Example: when a company is
                  dissolved, the status listed changes from active to dissolved.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        <Card mb="lg">
          <Accordion>
            <Accordion.Heading size="xl" level="2" weight="semi-bold">
              Industry Canada - Canadian Federal Corporations and Directors
            </Accordion.Heading>
            <Accordion.Content>
              <Text size="sm" mb="md" mt="md">
                Update Frequency: Monthly
                <br />
                Category: <a href="#">FP Advisor</a>
              </Text>
              <Text lineHeight="md" mb="md" mt="md">
                <p>
                  The Industry Canada - Canadian Federal Corporations and
                  Directors database contains over 400,000 federally
                  incorporated companies. The information provided includes
                  name, location, details of incorporation, reported revenues,
                  assets and earnings, and names of all directors. The
                  information is supplied by Industry Canada.
                </p>
                <p>
                  Noteworthy: documents are not deleted from the database.
                  Instead, when something about a company changes, the document
                  is altered to reflect the change. Example: when a company is
                  dissolved, the status listed changes from active to dissolved.
                </p>
                <p>Available in FP Advisor module only.</p>
              </Text>
            </Accordion.Content>
            <Accordion.Footer size="xl" level="2" weight="semi-bold">
              <div></div>
            </Accordion.Footer>
          </Accordion>
        </Card>

        {/* <Card mb="lg">
					<Accordion>
						<Accordion.Heading size="xl" level="2" weight="semi-bold">
							Owen Media Partners - Canadian Trade Index
						</Accordion.Heading>
						<Accordion.Content>
							<Text size="sm" mb="md" mt="md">
								Update Frequency: Annually<br/>
								Category: <a href="#">FP Advisor</a>
							</Text>
							<Text lineHeight="md" mb="md" mt="md">
								<p>
									The Owen Media Partners - Canadian Trade Index provides marketing and business intelligence on Canada's largest 25,000 manufacturers.
								</p>
								<p>
									Owen Media Partners also uses this data to publish the Canadian Trade Index, Canada's premier industrial purchasing directory and the flagship publication of Canadian Manufacturers &amp; Exporters, Canada's national manufacturers' association.
								</p>
								<p>
									The personnel section of the database contains up to 21 functional contacts per company including an average of four unique executives.
								</p>
								<p>
									The information is supplied by Owen Media Partners, Inc. It is updated on a 12 to 18 month target period, via direct input from the companies.
								</p>
								<p>
									The database is one of the largest Canadian manufacturing datasets available based on contact information for private companies.
								</p>
								<p>
									Available in FP Advisor module only.
								</p>
							</Text>
						</Accordion.Content>
						<Accordion.Footer size="xl" level="2" weight="semi-bold">
							<div></div>
						</Accordion.Footer>
					</Accordion>
				</Card> */}

        {/* <Card mb="lg">
					<Accordion>
						<Accordion.Heading size="xl" level="2" weight="semi-bold">
							Owen Media Partners - ProFile Canada
						</Accordion.Heading>
						<Accordion.Content>
							<Text size="sm" mb="md" mt="md">
								Update Frequency: Annually<br/>
								Category: <a href="#">FP Advisor</a>
							</Text>
							<Text lineHeight="md" mb="md" mt="md">
								<p>
									The Owen Media Partners - ProFile Canada database introduced in 1990, provides marketing and business intelligence on Canada's largest 35,000 companies with a focus on privately owned businesses.
								</p>
								<p>
									Each record has up to 21 functional contacts, averaging 5 unique executives. The database is one of the largest Canadian datasets available based on contact information for private companies.
								</p>
								<p>
									Information is supplied by Owen Media Partners, Inc.
								</p>
								<p>
									Owen Media Partners updates the records based on a 12-month target refresh period, via direct input from the companies.
								</p>
								<p>
									Available in FP Advisor module only.
								</p>
							</Text>
						</Accordion.Content>
						<Accordion.Footer size="xl" level="2" weight="semi-bold">
							<div></div>
						</Accordion.Footer>
					</Accordion>
				</Card> */}
      </div>
    </Page>
  );
}
