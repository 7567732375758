/* eslint-disable */
import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { MultiSelectField } from '../../../components/MultiSelectField';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Tabs, Tab } from '../../../components/Tabs';
import { countriesLookup, provincesLookupbyAlphaCode } from '../../../data/lookups';
import moment from 'moment';
import ClearButton from 'components/ClearButton/index';
import '../../../pages/MergersAcquisitions/SearchPage/searchMna.scss';

export function CorporateSnapshotSearchPage () {
  const history = useHistory();
  const headingId = useId();
  const [companyName, setCompanyName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');
  const [city, setCity] = React.useState('');
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [countrySnapshot, setCountrySnapshot] = React.useState<string[]>([]);

  const clearCheck = () => {
    setCompanyName('');
    setStockSymbol('');
    setCity('');
    setProvinces([]);
    setCountrySnapshot([]);
  };

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  const loadLatestEntries = () => {
    const companyNameSn = localStorage.getItem('companyNameSn');
    if (companyNameSn) {
      setCompanyName(JSON.parse(companyNameSn));
    }
    const stockSymbolSn = localStorage.getItem('stockSymbolSn');
    if (stockSymbolSn) {
      setStockSymbol(JSON.parse(stockSymbolSn));
    }
    const citySn = localStorage.getItem("citySn");
    if (citySn) {
      setCity(JSON.parse(citySn));
    }
    const provincesSn = localStorage.getItem("provincesSn");
    if (provincesSn) {
      setProvinces(JSON.parse(provincesSn));
    }
    const countrySn = localStorage.getItem("countrySn");
    if (countrySn) {
      setCountrySnapshot(JSON.parse(countrySn));
    }
  }
  // start
  /* companyName */
  const company = usePreviousStorageData(companyName) || "";
  React.useEffect(() => {
    if (company?.length !== companyName.length) {
      localStorage.setItem("companyNameSn", JSON.stringify(companyName));
    }
  }, [companyName]);

  /* stockSymbol */
  const stock = usePreviousStorageData(stockSymbol) || "";
  React.useEffect(() => {
    if (stock?.length !== stockSymbol.length) {
      localStorage.setItem("stockSymbolSn", JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  /* city */
  const cityName = usePreviousStorageData(city) || "";
  React.useEffect(() => {
    if (cityName?.length !== city.length) {
      localStorage.setItem("citySn", JSON.stringify(city));
    }
  }, [city]);

  /* provinces */
  const provinceName = usePreviousStorageData(provinces) || "";
  React.useEffect(() => {
    if (provinceName?.length !== provinces.length) {
      localStorage.setItem("provincesSn", JSON.stringify(provinces));
    }
  }, [provinces]);

  /* country */
  const countryName = usePreviousStorageData(countrySnapshot) || "";
  React.useEffect(() => {
    if (countryName?.length !== countrySnapshot.length) {
      localStorage.setItem("countrySn", JSON.stringify(countrySnapshot));
    }
  }, [countrySnapshot]);
  //  end

  const snapshotSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL('/corporate-snapshots/results', baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set('dateInfo', dateInfo);

    if (companyName) {
      resultsUrl.searchParams.set('companyName', companyName);
    }
    if (stockSymbol) {
      resultsUrl.searchParams.set('stockSymbol', stockSymbol);
    }
    if (city) {
      resultsUrl.searchParams.set('city', city);
    }
    
    resultsUrl.searchParams.set('provinces[]', provinces.join(','));

    resultsUrl.searchParams.set('countrySnapshot[]', countrySnapshot.join(','));
    history.push(resultsUrl.href.replace(baseUrl, ''), { data: true });
  };

  return (
    <Page aria-label="Corporate Snapshot">
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Corporate Snapshot - Basic Search
          </Heading>

          <Text mt="md" lineHeight="md">
            A concise report on all current and previously North American-listed
            active Canadian companies, FP 500 companies and select Crown
            corporations.
          </Text>

          <Text mt="sm" color="light" lineHeight="md">
            Use only the search options that you need. Providing more details will
            narrow your search results.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              snapshotSubmitData();
            }}
          >
            <TextField
              mt="lg"
              label="Company Name"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />

            <TextField
              mt="lg"
              mb="lg"
              label="City"
              value={city}
              onChange={setCity}
            />

            <MultiSelectField
              label="Province"
              items={provincesLookupbyAlphaCode}
              value={provinces}
              onChange={setProvinces}
            />

            {/* TODO -- we should add the styled component props to the MultiSelectField so that we can just apply mb='lg' to avoid this clearfix div  */}
            <div
              style={{ clear: 'both', width: '100%', marginBottom: '20px' }}
            ></div>

            <MultiSelectField
              label="Countries"
              items={countriesLookup}
              value={countrySnapshot}
              onChange={setCountrySnapshot}
            />

            <Button mt="lg" label="Search" mb="lg" type="submit" />
            {/* <Button mt='lg' label="Clear" ml='xxl' mb='lg' type='reset' /> */}
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>
      </Tabs>
    </Page>
  );
}
