/* eslint-disable */
import * as React from "react";
import { Page } from "../../../components/Page";
import { Card } from "../../../components/Card";
import { Accordion } from "../../../components/Accordion";
import { Text } from "../../../components/Text";
import { Heading } from "../../../components/Heading";
import { useId } from "../../../hooks";
import { ScreenReaderOnly } from "../../../components/ScreenReaderOnly";
import { ScreenReaderAvoidSplitting } from "../../../components/ScreenReaderAvoidSplitting";
import { Row } from "../../../components/Row";
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import ExpandAll from "components/ExpandSettings";
import { useAuthContext, useExpandSettings } from "containers/AuthProvider";

export function HelpAbbreviationsPage() {
  const authContext = useAuthContext();
  const defaultExpand =
    authContext?.expand_collapse_settings["corporate-snapshots"] ??
    authContext?.expand_collapse_settings.default;
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));
  const headingId = useId();
  
  return (
    <Page aria-labelledby={headingId} padding="none" width="full">

      <Page aria-labelledby={headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={["xxl", "xxl", "xxxl"]}
              weight="semi-bold"
            >
              FP Advisor - Abbreviations<ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className="detail--page--card">
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll
                  setExpand={setExpand}
                  expand={expand}
                  modalname="abbreviations-page"
                />
              </div>
            </HideOnPrint>

            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Currency
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <p>€ - Euro (EUR)</p>
                  <p>¢ - cent(s)</p>
                  <p>£ - British Pound (GBP)</p>
                  <p>A$ - Australian Dollar (AUD)</p>
                  <p>B$ - Bahamian Dollar (BSD)</p>
                  <p>Baht - Thai Baht (THB)</p>
                  <p>Bds$ - Barbadian Dollar (BBD)</p>
                  <p>BFr - Belgian Franc (BEF)*</p>
                  <p>BZ$ - Belize Dollar (BZD)</p>
                  <p>Cdn$ - Canadian Dollar (CAD)</p>
                  <p>CI$ - Cayman Islands Dollar (KYD)</p>
                  <p>CKr - Czech Koruna (CZK)</p>
                  <p>Cn¥ - Chinese Yuan (CNY)</p>
                  <p>COL$ - Colombian Peso (COP)</p>
                  <p>DGu - Dutch Guilder (NLG)*</p>
                  <p>Dhs. - U.A.E. Dirham (AED)</p>
                  <p>DKr - Danish Kroner (DKK)</p>
                  <p>DM - Deutschemark (DEM)*</p>
                  <p>FFr - French Franc (FRF)*</p>
                  <p>FJ$ - Fiji Dollar (FJD)</p>
                  <p>Ft - Hungarian Forint (HUF)</p>
                  <p>GDr - Greek Drachma (GRD)*</p>
                  <p>GH¢ - Ghanaian Cedi (GHS)</p>
                  <p>HK$ - Hong Kong Dollar (HKD)</p>
                  <p>IKr - Icelandic Króna (ISK)</p>
                  <p>IR£ - Irish Punt (IEP)*</p>
                  <p>JA$ - Jamaican Dollar (JMD)</p>
                  <p>Jp¥ - Japanese Yen (JPY)</p>
                  <p>L - Italian Lira (ITL)*</p>
                  <p>N$ - Namibian Dollar (NAD)</p>
                  <p>NKr - Norwegian Krone (NOK)</p>
                  <p>NT$ - New Taiwan Dollar (TWD)</p>
                  <p>NZ$ - New Zealand Dollars (NZD)</p>
                  <p>PEs - Portuguese Escudo (PTE)*</p>
                  <p>Pts - Spanish Peseta (ESP)*</p>
                  <p>R - South African Rand (ZAR)</p>
                  <p>R$ - Brazilian Real (BRL)</p>
                  <p>RD$ - Dominican Rep. Peso (DOP)</p>
                  <p>Rs - Indian Rupee (INR)</p>
                  <p>SFr - Swiss Franc (CHF)</p>
                  <p>SKr - Swedish Krona (SEK)</p>
                  <p>TT$ - Trinidad & Tobago Dollar (TTD)</p>
                  <p>US$ - United States Dollar (USD)</p>
                  <p>Won - Korean Won (KRW)</p>
                  <p>zl - Polish Zloty (PLN)</p>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Dividend
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                  <p>♦ - special</p>
                  <p>** - Reinvestment Option</p>
                  <p>† - extra</p>
                  <p>A - annually</p>
                  <p>a - other income</p>
                  <p>accr. - accrued</p>
                  <p>Adj. - adjustable</p>
                  <p>auc. - auction</p>
                  <p>b - base cost adjustment</p>
                  <p>c - interest income</p>
                  <p>cap - capital</p>
                  <p>cl - class</p>
                  <p>cum. - cumulative</p>
                  <p>cv - convertible</p>
                  <p>e - estimate</p>
                  <p>ea - each</p>
                  <p>eff. - effective</p>
                  <p>est - established</p>
                  <p>exch. - exchangeable</p>
                  <p>f - final dividend</p>
                  <p>Fltg. - floating</p>
                  <p>foll. - following</p>
                  <p>g - capital gain</p>
                  <p>i - initial payment</p>
                  <p>instlmt rcpt - instalment receipt</p>
                  <p>ltd ptnrshp - limited partnership</p>
                  <p>M - monthly</p>
                  <p>M.V. - multiple voting</p>
                  <p>N.V. - non-voting</p>
                  <p>pa - per annum</p>
                  <p>pd - paid</p>
                  <p>pfd/pref - preferred</p>
                  <p>Prev - previous</p>
                  <p>pt - participating</p>
                  <p>Q - quarterly</p>
                  <p>r - return of capital</p>
                  <p>Ra - rate</p>
                  <p>red. - redeemable</p>
                  <p>ret. - retractable</p>
                  <p>rt. - right</p>
                  <p>S - semiannually</p>
                  <p>S.V. - subordinate voting</p>
                  <p>stk - stock</p>
                  <p>t - special tax status</p>
                  <p>Tr unit - trust unit</p>
                  <p>Var. - variable</p>
                  <p>vt - special voting rights</p>
                  <p>wt. - warrant</p>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  General
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                    <p>A.C.T. - Australian Capital Territory</p>
                    <p>A.I. - Auditores Independentes</p>
                    <p>A.P.A. - Authorized Public Accountant</p>
                    <p>Aberdeen. - Aberdeenshire</p>
                    <p>ABR - Alternate Base Rate</p>
                    <p>acctg. - accounting</p>
                    <p>accum. - accumulated</p>
                    <p>acq(s). - acquisition(s)</p>
                    <p>admin. - administration</p>
                    <p>ADR - American Depository Receipt</p>
                    <p>advtg. - advertising</p>
                    <p>agri. - agriculture; agricultural</p>
                    <p>Ala. - Alabama</p>
                    <p>Alta. - Alberta</p>
                    <p>amalg. - amalgamation</p>
                    <p>AMEX - American Stock Exchange</p>
                    <p>Apr. - April</p>
                    <p>Argyll. - Argyllshire</p>
                    <p>Ariz. - Arizona</p>
                    <p>Ark. - Arkansas</p>
                    <p>ASC - Alberta Securities Commission</p>
                    <p>ASE - Alberta Stock Exchange</p>
                    <p>Assn. - Association</p>
                    <p>assoc. - associate</p>
                    <p>asst. - assistant</p>
                    <p>Aug. - August</p>
                    <p>avg. - average</p>
                    <p>Ayr. - Ayrshire</p>
                    <p>B.C. - British Columbia</p>
                    <p>Banff. - Banffshire</p>
                    <p>bbl - barrel</p>
                    <p>bcf - billion cubic feet</p>
                    <p>BCSC - British Columbia Securities Commission</p>
                    <p>bdu - bone dry unit</p>
                    <p>Beds. - Bedfordshire</p>
                    <p>Berks. - Berkshire</p>
                    <p>Berwick. - Berwickshire</p>
                    <p>bldg. - building(s)</p>
                    <p>BOE - barrels of oil equivalent</p>
                    <p>BOPD - barrels oil produced per day</p>
                    <p>Brecon. - Breconshire</p>
                    <p>Bucks. - Buckinghamshire</p>
                    <p>bus. - business</p>
                    <p>Bute. - Buteshire</p>
                    <p>C.A. - Chartered Accountant</p>
                    <p>C.G.A. - Certified General Accountant</p>
                    <p>C.P.A. - Certified Public Accountant</p>
                    <p>Caernarvon. - Caernarvonshire</p>
                    <p>Calif. - California</p>
                    <p>Cambs. - Cambridgeshire</p>
                    <p>Cardigan. - Cardiganshire</p>
                    <p>Carmarthen. - Carmarthenshire</p>
                    <p>CBCA - Canada Business Corporation Act</p>
                    <p>Cdn. - Canadian</p>
                    <p>CDNX - Canadian Venture Exchange</p>
                    <p>CDOR - Canadian Dollar Offer Rate</p>
                    <p>CICA - Canadian Institute of Chartered Accountants</p>
                    <p>Cl.A - Class A</p>
                    <p>Clack. - Clackmannanshire</p>
                    <p>CNQ - Canadian Trading & Quotation System</p>
                    <p>CNSX - Canadian National Stock Exchange</p>
                    <p>Colo. - Colorado</p>
                    <p>com. - common</p>
                    <p>Comm. - Commission</p>
                    <p>comml. - Commercial</p>
                    <p>commun. - communications</p>
                    <p>Conn. - Connecticut</p>
                    <p>cons. - consulting</p>
                    <p>consol. - consolidated</p>
                    <p>const. - construction</p>
                    <p>conv. - convertible</p>
                    <p>corp. - corporate</p>
                    <p>CRTC - Canadian Radio-Television Commission</p>
                    <p>CSE - Canadian Securities Exchange</p>
                    <p>CTO - Cease Trade Order</p>
                    <p>ctrl. - control</p>
                    <p>cty. - county</p>
                    <p>cum. - cumulative</p>
                    <p>Cumbs. - Cumberland</p>
                    <p>cust. - customer</p>
                    <p>D.C. - District of C</p>olumbia
                    <p>dba - doing business as</p>
                    <p>deb(s). - debenture(s)</p>
                    <p>Dec. - December</p>
                    <p>def. - deferred</p>
                    <p>Del. - Delaware</p>
                    <p>Denbigh. - Denbighshire</p>
                    <p>depr. - depreciation</p>
                    <p>dept. - department</p>
                    <p>Derbys. - Derbyshire</p>
                    <p>Derry - Co. Londonderry</p>
                    <p>devel. - development</p>
                    <p>dist. - district</p>
                    <p>distrib(s). - distribution(s)</p>
                    <p>div. - division</p>
                    <p>divd(s). - dividend(s)</p>
                    <p>DPSP - Deferred Profit Sharing Plan</p>
                    <p>Dumfries. - Dumfriesshire</p>
                    <p>Dunbarton. - Dunbartonshire</p>
                    <p>EBITDA - Earns. bef. int., taxes, deprec. & amort.</p>
                    <p>eng. - engineering</p>
                    <p>envir. - environment</p>
                    <p>envirl. - environmental</p>
                    <p>EONIA - Euro OverNight Index Average</p>
                    <p>equip. - equipment</p>
                    <p>equiv. - equivalent</p>
                    <p>etc. - et cetera</p>
                    <p>EURIBOR - European Inter-Bank Offered Rate</p>
                    <p>eval. - evaluation</p>
                    <p>exch. - exchangeable</p>
                    <p>exec. - executive</p>
                    <p>explor. - exploration</p>
                    <p>f. - female</p>
                    <p>fbm - foot board measure</p>
                    <p>FDA - Food and Drug Administration</p>
                    <p>Feb. - February</p>
                    <p>fin. - finance</p>
                    <p>finl. - financial</p>
                    <p>Fla. - Florida</p>
                    <p>Flint. - Flintshire</p>
                    <p>fltg. - floating</p>
                    <p>ft. - feet</p>
                    <p>g/t - grams per tonne</p>
                    <p>Ga. - Georgia</p>
                    <p>GAAP - Generally Accepted Accounting Principles</p>
                    <p>gen. - general</p>
                    <p>geol. - geology</p>
                    <p>GJ - gigajoules</p>
                    <p>Gloucs. - Gloucestershire</p>
                    <p>govt. - government</p>
                    <p>GP - General Partnership</p>
                    <p>grp. - group</p>
                    <p>GW - gigawatt(s)</p>
                    <p>GWh - gigawatt hour(s)</p>
                    <p>Hants. - Hampshire</p>
                    <p>Hereford. - Herefordshire</p>
                    <p>Herts. - Hertfordshire</p>
                    <p>honry. - honorary</p>
                    <p>HR - human resources</p>
                    <p>hr. - hour</p>
                    <p>hrs. - hours</p>
                    <p>Hunts. - Huntingdonshire</p>
                    <p>Ill. - Illinois</p>
                    <p>Ind. - Indiana</p>
                    <p>ind. - industrial</p>
                    <p>info. - information</p>
                    <p>insce. - insurance</p>
                    <p>instr. - instrument</p>
                    <p>int. - interest</p>
                    <p>intl. - international</p>
                    <p>Inverness. - Inverness-shire</p>
                    <p>invest(s). - investment(s)</p>
                    <p>IR - investor relations</p>
                    <p>IT - Information Technology</p>
                    <p>Jan. - January</p>
                    <p>JIBAR - Johannesburg Inter-Bank Agreed Rate</p>
                    <p>jr. - junior</p>
                    <p>JV - Joint Venture</p>
                    <p>Kan. - Kansas</p>
                    <p>kgU - kilogram uranium</p>
                    <p>Kincard. - Kincardineshire</p>
                    <p>Kinross. - Kinrossshire</p>
                    <p>Kirkcud. - Kirkcudbrightshire</p>
                    <p>km - kilometre(s)</p>
                    <p>km² - square kilometre(s)</p>
                    <p>kV - kilovolt(s)</p>
                    <p>KW - kilowatt(s)</p>
                    <p>KWh - kilowatt hour(s)</p>
                    <p>Ky. - Kentucky</p>
                    <p>l-t - long-term</p>
                    <p>La. - Louisiana</p>
                    <p>Lanark. - Lanarkshire</p>
                    <p>Lancs. - Lancashire</p>
                    <p>lb. - pound(s)</p>
                    <p>Leics. - Leicestershire</p>
                    <p>LIBID - London Inter-Bank Bid Rate</p>
                    <p>LIBOR - London Inter-Bank Offered Rate</p>
                    <p>LIF - Life Income Fund</p>
                    <p>LIMEAN - London Inter-Bank Mean Rate</p>
                    <p>Lincs. - Lincolnshire</p>
                    <p>LISBOR - Lisbon Inter-Bank Offered Rate</p>
                    <p>LLP - Limited Liability Partnership</p>
                    <p>LP - Limited Partnership</p>
                    <p>m - metre(s)</p>
                    <p>M&A - mergers & acquisitions</p>
                    <p>m. - male</p>
                    <p>m² - square metre(s)</p>
                    <p>m³ - cubic metre(s)</p>
                    <p>maint. - maintenance</p>
                    <p>Man. - Manitoba</p>
                    <p>man. - managing</p>
                    <p>Mar. - March</p>
                    <p>Mass. - Massachusetts</p>
                    <p>mbbl - thousand barrels</p>
                    <p>mbr. - member</p>
                    <p>mcf - thousand cubic feet</p>
                    <p>Md. - Maryland</p>
                    <p>mdse. - merchandise</p>
                    <p>mdsg. - merchandising</p>
                    <p>ME - Montreal Exchange</p>
                    <p>Me. - Maine</p>
                    <p>mech. - mechanical</p>
                    <p>Merioneth. - Merionethshire</p>
                    <p>mfbm - thousand board feet</p>
                    <p>mfg. - manufacturing</p>
                    <p>mfr. - manufacture</p>
                    <p>mgt. - management</p>
                    <p>Mich. - Michigan</p>
                    <p>Middx. - Middlesex</p>
                    <p>min. - mining</p>
                    <p>Minn. - Minnesota</p>
                    <p>MIS - management information systems</p>
                    <p>Miss. - Mississippi</p>
                    <p>mktg. - marketing</p>
                    <p>mmcf - million cubic feet</p>
                    <p>mmfbm - million board feet</p>
                    <p>mmsf - million square feet</p>
                    <p>Mo. - Missouri</p>
                    <p>Monmouth. - Monmouthshire</p>
                    <p>Mont. - Montana</p>
                    <p>Montgomery. - Montgomeryshire</p>
                    <p>Moray. - Morayshire</p>
                    <p>msf - thousand square feet</p>
                    <p>mtge(s). - mortgage(s)</p>
                    <p>MW - megawatt(s)</p>
                    <p>MWh - megawatt hour(s)</p>
                    <p>N.B. - New Brunswick</p>
                    <p>N.C. - North Carolina</p>
                    <p>N.D. - North Dakota</p>
                    <p>N.H. - New Hampshire</p>
                    <p>N.J. - New Jersey</p>
                    <p>N.L. - Newfoundland & Labrador</p>
                    <p>N.M. - New Mexico</p>
                    <p>n.p.v. - no par value</p>
                    <p>N.S. - Nova Scotia</p>
                    <p>N.S.W. - New South Wales</p>
                    <p>N.T. - Northern Territory</p>
                    <p>N.W.T. - Northwest Territories</p>
                    <p>N.Y. - New York</p>
                    <p>Nairn. - Nairnshire</p>
                    <p>NASDAQ - National Association of Securities Dealers Automated Quotation (U.S.)</p>
                    <p>natl. - national</p>
                    <p>NEB - National Energy Board</p>
                    <p>Neb. - Nebraska</p>
                    <p>Nev. - Nevada</p>
                    <p>Nfld. - Newfoundland</p>
                    <p>NGL - natural gas liquids</p>
                    <p>Northants. - Northamptonshire</p>
                    <p>Northumbs. - Northumberland</p>
                    <p>Notts. - Nottinghamshire</p>
                    <p>Nov. - November</p>
                    <p>NSR - Net Smelter Royalty</p>
                    <p>NYSE - New York Stock Exchange</p>
                    <p>o/s - outstanding</p>
                    <p>obligs. - obligations</p>
                    <p>Oct. - October</p>
                    <p>OEM - original equipment manufacturer</p>
                    <p>Okla. - Oklahoma</p>
                    <p>Ont. - Ontario</p>
                    <p>oper. - operating</p>
                    <p>opers. - operations</p>
                    <p>opt - ounces per ton</p>
                    <p>ord. - ordinary</p>
                    <p>Ore. - Oregon</p>
                    <p>org. - organizational</p>
                    <p>orig. - originally</p>
                    <p>OSB - oriented strand board</p>
                    <p>OSC - Ontario Securities Commission</p>
                    <p>OTC BB - Over-the-counter Bulletin Board</p>
                    <p>Oxon. - Oxfordshire</p>
                    <p>oz. - ounce(s)</p>
                    <p>p.a. - per annum</p>
                    <p>P.E.I. - Prince Edward Island</p>
                    <p>P.R. - Puerto Rico</p>
                    <p>Pa. - Pennsylvania</p>
                    <p>pay. - payable</p>
                    <p>Peeble. - Peeblesshire</p>
                    <p>Pembroke. - Pembrokeshire</p>
                    <p>Perth. - Perthshire</p>
                    <p>pfce. - preference</p>
                    <p>PIP - Petroleum Incentive Payment</p>
                    <p>PJ - petajoules</p>
                    <p>ppm - parts per million</p>
                    <p>PR - public relations</p>
                    <p>pref. - preferred</p>
                    <p>proc. - process</p>
                    <p>prod. - production</p>
                    <p>prof. - professional</p>
                    <p>prop. - property</p>
                    <p>purch. - purchasing</p>
                    <p>Qld. - Queensland</p>
                    <p>Que. - Quebec</p>
                    <p>R&D - research & development</p>
                    <p>R.I. - Rhode Island</p>
                    <p>Radnor. - Radnorshire</p>
                    <p>redeem. - redeemable</p>
                    <p>regl. - regional</p>
                    <p>rel. - relations (except PR)</p>
                    <p>Renfrew. - Renfrewshire</p>
                    <p>rep. - representative</p>
                    <p>retract. - retractable</p>
                    <p>rev. - revenue</p>
                    <p>revolv. - revolving</p>
                    <p>RIF - Retirement Income Fund</p>
                    <p>Roxburgh. - Roxburghshire</p>
                    <p>RRSP - Registered Retirement Savings Plan</p>
                    <p>rt. - right</p>
                    <p>S&P - Standard & Poor’s</p>
                    <p>s-t - short-term</p>
                    <p>S.A. - South Australia</p>
                    <p>s.a. - semiannually</p>
                    <p>S.C. - South Carolina</p>
                    <p>S.D. - South Dakota</p>
                    <p>s.f. - sinking fund</p>
                    <p>Sask. - Saskatchewan</p>
                    <p>SEC - Securities and Exchange Commission (U.S.)</p>
                    <p>sec. - secretary</p>
                    <p>secs. - securities</p>
                    <p>Selkirk. - Selkirkshire</p>
                    <p>Sept. - September</p>
                    <p>Ser.A - Series A</p>
                    <p>sh. - share</p>
                    <p>Shrops. - Shropshire</p>
                    <p>Somt. - Somerset</p>
                    <p>sq. - square</p>
                    <p>sr. - senior</p>
                    <p>srvc(s). - service(s)</p>
                    <p>Staffs. - Staffordshire</p>
                    <p>Stirling. - Stirlingshire</p>
                    <p>subord. - subordinate</p>
                    <p>subsid. - subsidiary</p>
                    <p>supt. - superintendent</p>
                    <p>supvr. - supervisor</p>
                    <p>sys. - system(s)</p>
                    <p>Tas. - Tasmania</p>
                    <p>tax. - taxation</p>
                    <p>tcf - trillion cubic feet</p>
                    <p>tech. - technology</p>
                    <p>telecom. - telecommunication(s)</p>
                    <p>Tenn. - Tennessee</p>
                    <p>Tex. - Texas</p>
                    <p>TJ - terajoule(s)</p>
                    <p>tpd - tons per day</p>
                    <p>tr. - trust</p>
                    <p>trans. - transportation</p>
                    <p>TSE, TSX - Toronto Stock Exchange</p>
                    <p>TSX-VEN - TSX Venture Exchange</p>
                    <p>TWh - terawatt hour(s)</p>
                    <p>twp. - township</p>
                    <p>U.A.E. - United Arab Emirates</p>
                    <p>U.K. - United Kingdom</p>
                    <p>U.S. - United States (of America)</p>
                    <p>Va. - Virginia</p>
                    <p>Vic. - Victoria</p>
                    <p>VSE - Vancouver Stock Exchange</p>
                    <p>Vt. - Vermont</p>
                    <p>vtg. - voting</p>
                    <p>W.A. - Western Australia</p>
                    <p>W.Va. - West Virginia</p>
                    <p>w/d - write-down(s)</p>
                    <p>w/o - write-off(s)</p>
                    <p>Warks. - Warwickshire</p>
                    <p>Wash. - Washington</p>
                    <p>Westmlnd. - Westmorland</p>
                    <p>Wigtown. - Wigtownshire</p>
                    <p>Wilts. - Wiltshire</p>
                    <p>Wisc. - Wisconsin</p>
                    <p>Worcs. - Worcestershire</p>
                    <p>WSE - Winnipeg Stock Exchange</p>
                    <p>wt. - warrant</p>
                    <p>Wyo. - Wyoming</p>
                    <p>Yorks. - Yorkshire</p>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  International Corporate
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                    <p>(Pty) Ltd. - Proprietary Limited (South Africa)</p>
                    <p>(Pvt.) Ltd. - Private Limited (Sri Lanka)</p>
                    <p>a.d. - akcionarsko društvo (Bosnia & Herzegovina, Croatia, Serbia – joint stock company)</p>
                    <p>A.E. - Anonimos Eteria (Greece – public limited company)</p>
                    <p>A.O. - Anonim Ortaklari/Ortakligi (Turkey)</p>
                    <p>A.S. - Aktsiaselts (Estonia); Anonim Sirketi (Turkey – joint stock company)</p>
                    <p>a.s. - akciová spolecnost (Czech Republic, Slovakia – public limited company)</p>
                    <p>A.V.V. - Aruba Vrijgestelde Vennootschap (Aruba)</p>
                    <p>A/S - Aktieselskap (Denmark – public limited company)</p>
                    <p>AB - Aktiebolag (Sweden – private stock company)</p>
                    <p>Ab - Aktiebolag (Finland – private company)</p>
                    <p>AB (publ) - Publikt Aktiebolag (Sweden - public limited company)</p>
                    <p>AD - Aktsionerno drujestvo (Bulgaria – public limited company)</p>
                    <p>AG - Aktiengesellschaft (Austria, Germany, Switzerland – public limited company)</p>
                    <p>AG mvK - Aktiengesellschaft mit veränderlichem Kapital (Liechtenstein – variable capital investment company)</p>
                    <p>AL - Andelslag (Norway – cooperative; formerly A.L. and A/L)</p>
                    <p>AO - Aktsionernoye Obshchestvo (Russia – joint stock company); precedes name of company</p>
                    <p>ApS - Anpartsselskab (Denmark – limited liability company)</p>
                    <p>AS - Aksjeselskap (Norway – private stock company)</p>
                    <p>ASA - Allmennaksjeselskap (Norway – public limited company)</p>
                    <p>Ay - Avoinyhtio (Finland – general partnership)</p>
                    <p>B.M. - Be'eravon Mugbal (Israel – limited liability company</p>
                    <p>B.V. - Besloten Vennootschap (Belgium, Netherlands, Netherlands Antilles – limited liability company)</p>
                    <p>B.V.B.A. - Besloten Vennootschap met Beperkte Aansprakelijkheid (Belgium – private limited company)</p>
                    <p>BH - Bosnia & Herzegovina</p>
                    <p>Bpk - Beperk (South Africa – limited liability company)</p>
                    <p>C. de R.L. - Compania de Responsabilidad Limitada (Spain)</p>
                    <p>C.A. - Compania Anonima (Ecuador, Venezuela)</p>
                    <p>C.V. - Commanditaire Vennootschap (Netherlands – limited partnership)</p>
                    <p>C.V.B.A. - Coöperatieve Vennootschap met Beperkte Aansprakelijkheid (Belgium – limited liability cooperative)</p>
                    <p>CJSC - Closed Joint Stock Company (Russia; English equivalent of ZAO); precedes name of company</p>
                    <p>Co. Ltd. - Company Limited (South Korea)</p>
                    <p>CRL - Cooperativa de Responsabilidade Limitida (Portugal – limited liability cooperative)</p>
                    <p>d.d. - dionicko društvo (Bosnia & Herzegovina, Croatia, Serbia – joint stock company); delniška družba (Slovenia – public limited company)</p>
                    <p>d.o.o. - društvo sa ogranicenom odgovornošcu (Bosnia & Herzegovina, Croatia, Serbia – private limited liability company); družba z omejeno odgovornostjo (Slovenia – limited liability company)</p>
                    <p>d.o.o.e.l. - društvo sa ogranicenom odgovornošcu od edno lice (Bosnia & Herzegovina, Croatia, Macedonia, Serbia – private limited liability company with single member)</p>
                    <p>DAC - Designated Activity Company (Ireland - private limited company)</p>
                    <p>DMCC - Dubai Multi Commodities Centre</p>
                    <p>E.P.E. - Eteria Periorismenis Euthinis (Greece – limited liability company)</p>
                    <p>EAD - Ednolichno Aktsionerno Drujestvo (Bulgaria – joint stock company)</p>
                    <p>ehf. - Einkahlutafelag (Iceland – private limited liability company)</p>
                    <p>EmbH - Experten mit beschränkter Haftung (Germany)</p>
                    <p>EOOD - Ednolichno Drujestvo s Ogranichena Otgovornost (Bulgaria – limited liability company)</p>
                    <p>Est. - Establishment (Abu Dhabi, U.A.E.)</p>
                    <p>EURL - Entreprise unipersonnelle à responsabilité limitée (Algeria, France – sole proprietorship with limited liability)</p>
                    <p>FZCO - Free Zone Company Middle East</p>
                    <p>FZE - Free Zone Establishment (Qatar, U.A.E.)</p>
                    <p>GK - Godo Kaisha (Japan - limited liability company)</p>
                    <p>GmbH - Gesellschaft mit beschränkter Haftung (Austria, Bahamas, Germany, Switzerland – limited liability company)</p>
                    <p>GmbH & Co. KG - Gesellschaft mit beschränkter Haftung & Company Kommanditgesellschaft (Germany – limited partnership)</p>
                    <p>HB - Handelsbolag (Sweden – general partnership)</p>
                    <p>hf. - Hlutafelag (Iceland – limited liability company)</p>
                    <p>I/S - Interessentselskab (Denmark – general partnership); Interesentselskap (Norway – general partnership)</p>
                    <p>JSC - Joint Stock Company (Russia; English equivalent of AO); precedes name of company</p>
                    <p>Jusik Hoesa - (Korea – stock company)</p>
                    <p>k.d. - komanditno društvo (Bosnia & Herzegovina, Croatia & Serbia – limited partnership)</p>
                    <p>K/S - Kommanditselskap (Denmark – limited partnership)</p>
                    <p>KB - Kommanditbolag (Sweden – limited partnership)</p>
                    <p>KD - Komanditno Drugestvo (Bulgaria – limited partnership)</p>
                    <p>Kft. - Korlátolt felelosségu társaság (Hungary – limited liability company)</p>
                    <p>KG - Kommanditgesellschaft (Austria, Germany & Switzerland – limited partnership)</p>
                    <p>KgaA - Kommanditgesellschaft auf Aktien (Germany – limited partnership)</p>
                    <p>KK - Kabushiki Kaishi (Japan – stock company)</p>
                    <p>Lda. - Sociedade por Quotas Limitada (Portugal)</p>
                    <p>LDC - Limited Duration Company (Bahamas)</p>
                    <p>LLC or LC - Limited Liability Company (U.S.)</p>
                    <p>LLLP - Limited Liability Limited Partnership (U.S.)</p>
                    <p>LLP - Limited Liability Partnership (U.S.)</p>
                    <p>Ltd. Sti. - Limited Sirketi (Turkey – limited company)</p>
                    <p>Ltda. - Sociedade por Quotax de Responsabiliadade Limitada (Brazil)</p>
                    <p>mbH - mit beschränkter Haftung (Germany – with limited liability)</p>
                    <p>N.A. - National Association (U.S. – national bank)</p>
                    <p>N.P.L. - No Personal Liability (Canada)</p>
                    <p>N.V. - Naamloze Vennootschap (Belgium, Netherlands, Netherlands Antilles – public/private stock company)</p>
                    <p>NL - No Liability (Australia – restricted to mining companies)</p>
                    <p>Nyrt. - Nyilvánosan muködo részvénytársaság (Hungary – public limited company)</p>
                    <p>o.d. - ortacko društvo (Bosnia & Herzegovina, Croatia, Serbia – general partnership)</p>
                    <p>OAO - Otkrytoye aktsionernoye obshchestvo (Russia – open joint stock company); precedes name of company</p>
                    <p>OJSC - Open Joint Stock Company (Russia; English equivalent of OAO); precedes or after name of company</p>
                    <p>OOD - Drujestvo s ogranichena otgovornost (Bulgaria – limited company)</p>
                    <p>OOO - Obshchestvo s ogranichennoy otvetstvennostyu (Russia – limited liability company); precedes name of company</p>
                    <p>OÜ - Osaühing (Estonia – private limited company)</p>
                    <p>Oy - Osakeyhtiö (Finland – stock company)</p>
                    <p>Oyj - Julkinen Osakeyhtiö (Finland – public limited company)</p>
                    <p>P.C. or PC - Professional Corporation (U.S.)</p>
                    <p>P.S.C. - Professional Service Corporation</p>
                    <p>P/F - Partafelag (Faroe Islands – public company)</p>
                    <p>PBC - Public Benefit Company (U.S.)</p>
                    <p>PJSC - Public Joint Stock Company (Middle East)</p>
                    <p>plc - private limited company (U.K.)</p>
                    <p>PLLC - Professional Limited Liability Company (U.S.)</p>
                    <p>PT - Perseroan Terbatas (Indonesia – limited company); precedes name of company</p>
                    <p>PT Tbk. - Perseroan Terbatas, Terbuka (Indonesia – public limited company)</p>
                    <p>Pte. Ltd. - Private Limited (Singapore)</p>
                    <p>Pty Ltd. - Proprietary Limited (Australia – small to medium-sized companies)</p>
                    <p>Pvt. Ltd. - Private Limited (India, Pakistan)</p>
                    <p>RAS - Riiklik Aktsiaselts (Estonia – state-owned joint stock company)</p>
                    <p>Rt. - Részvénytársaság (Hungary – public/private stock company)</p>
                    <p>S. Com. por A. - Sociedad Commanditaria por Acciones (Spain – incorporated partnership)</p>
                    <p>S. de R.L. - Sociedad de Responsabilidad Limitada (Mexico – limited partnership)</p>
                    <p>S. de R.L. de C.V. - Sociedad de Responsabilidad Limitada de Capital Variable (Mexico – limited liability company)</p>
                    <p>S.A. - Sociedade Anónima (Portugal); Société Anonyme (Bahamas, Belgium, Côte d’Ivoire, France, Greece, Luxembourg, Morocco, Switzerland); Sociedad Anónima (Argentina, Chile, Colombia, Mexico, Peru, Spain); Spólka Akcyjna (Poland); Societate pe Actiuni (Romania)</p>
                    <p>S.A. de C.V. - Sociedad Anónima de Capital Variable (Mexico - corporation)</p>
                    <p>S.A. E.S.P. - Sociedad Anónima Empresa de Servicios Públicos (Argentina, Chile, Colombia, Peru, Spain)</p>
                    <p>S.A.A. - Sociedad Anónima Abierta (Peru – open company)</p>
                    <p>S.A.B. - Sociedad Agente de Bolsa (Peru - private company)</p>
                    <p>S.A.B. de C.V. - Sociedad Anónima Bursátil de Capital Variable (Mexico - public limited company)</p>
                    <p>S.A.C. - Sociedad Anónima Cerrada (Peru – closed company)</p>
                    <p>S.A.E. - Sharikat al-Mossahamah (Egypt – public limited company)</p>
                    <p>S.A.L. - Société Anonyme Libanaise (France)</p>
                    <p>S.A.M. - Société Anonyme Monegasque (Monaco – general business company)</p>
                    <p>S.A.P.I. de C.V. - Sociedad Anónima Promotora de Inversiones de Capital Variable (Mexico)</p>
                    <p>S.A.R.L. - Société à Responsabilité Limitée (France, Luxembourg, Switzerland); Sociedad Anonima de Responsibilidade Limitada (Portugal)</p>
                    <p>S.A.R.L.U. - Société à Responsabilité Limitée Uni-personnelle (France - limited liability joint limited company)</p>
                    <p>S.A.S. - Société par Actions Simplifiée (France – simplified joint-stock company); Sociedad por Acciones Simplificada (Colombia – simplified share company)</p>
                    <p>S.a.s. - Società in accomandita semplice (Italy – limited partnership)</p>
                    <p>S.A.S.U. - Société par Actions Simplifiée Unipersonnelle (France)</p>
                    <p>S.A.U. - Sociedad Anónima Unipersonal (Argentina, Spain - single shareholder company)</p>
                    <p>S.C. - Sociedad en Commandita (Mexico, Spain – general partnership); Share Company (Eritrea, Ethiopia)</p>
                    <p>S.C.A. - Société en Commandite par Actions (France – share-based limited partnership); Sociedad en Comandita por Acciones (Colombia – share-based limited partnership)</p>
                    <p>S.C.C. - Sociedad Civil y Comercial (Ecuador)</p>
                    <p>S.C.I. - Société Civile Immobilière (France - property company)</p>
                    <p>S.C.M. - Sociedad Contractual Minera (Chile)</p>
                    <p>S.C.R.L. - Société Coopérative à Responsabilité Limitée (France – limited liability cooperative)</p>
                    <p>S.C.S. - Société en Commandite par Simple (France – limited partnership)</p>
                    <p>S.E. - Societas Europaea (EU – public company)</p>
                    <p>S.E.C. - Société en Commandite (France – limited partnership)</p>
                    <p>S.E.C.S. - Société en Commandite par Simple (Luxembourg – limited partnership)</p>
                    <p>S.E.N.C.R.L. - Société en Nom Collectif à Responsabilité Limitée (France – limited liability general partnership)</p>
                    <p>S.L. - Sociedad Limitada (Latin America, Portugal, Spain – limited company)</p>
                    <p>S.L.P. - Sociedad Limitada Profesional (Spain – professional limited liability company)</p>
                    <p>S.L.U. - Sociedad Limitada Unipersonal (Spain)</p>
                    <p>S.M.R.L. - Sociedad Minera de Responsabilidad Limitada (Peru); precedes or follows name of company</p>
                    <p>S.n.c. - Società in nome collettivo (Italy – general partnership)</p>
                    <p>S.N.C./S.E.N.C. - Société en Nom Collectif (France – general partnership)</p>
                    <p>S.p.A. - Sociedad por Acciones (Chile – limited partnership with shares); Società per Azioni (Italy – stock company)</p>
                    <p>S.P.R.L. - Société Privée à Responsabilité Limitée (Belgium, France – private limited company)</p>
                    <p>S.R.L. - Sociedad de Responsabilidad Limitada (Argentina, Bolivia, Peru, Spain – limited liability company); Societate cu Raspundere Limitata (Romania – limited liability company)</p>
                    <p>S.r.l. - Sociedad de responsabilidad limitada (Chile, Mexico); Società a Responsabilità Limitata (Italy – limited liability company)</p>
                    <p>s.r.o. - spolecnost s rucením omezeným (Czech Republic, Slovakia – limited liability company)</p>
                    <p>S.U.A.R.L. - Société Unipersonnelle à Responsabilité Limitée (Senegal – one-man limited liability company)</p>
                    <p>S.U.R.L. - Société Unipersonnelle à Responsabilité Limitée (France – one-man limited liability company)</p>
                    <p>S/A - Sociedade por Ações (Brazil)</p>
                    <p>SCA - Société en Commandite par Actions (Belgium – limited partnership); Societe in Còmandita pe Actiuni (Romania – limited partnership)</p>
                    <p>Sdn. Bhd. - Sendirian Berhad (Malaysia – limited liability company)</p>
                    <p>SE - Societas Europaea (EU - public company)</p>
                    <p>SEZC - Special Economic Zone Company (Cayman Islands)</p>
                    <p>sf. - sameignarfélag (Iceland – general partnership)</p>
                    <p>Sh.a. - Shoqëri Aksionare (Albania – public limited company)</p>
                    <p>Sh.p.k. - Shoqëri me Përgjegjësi të Kufizuar (Albania – limited company)</p>
                    <p>SIA - Sabiedriba ar ierobežotu atbildibu (Latvia – limited liability company)</p>
                    <p>SK - Spólka komandytowa (Poland – limited partnership)</p>
                    <p>slhf. - samlagshlutafélag (Iceland - limited parternship)</p>
                    <p>Sp.zo.o - Spólka z ograniczona odpowiedzialnoscia (Poland – private limited company)</p>
                    <p>SRL - Society with Restricted Liability (Barbados)</p>
                    <p>U.A. - Uitgesloten Aansprakelijkheid (Netherlands – cooperative with excluded liability)</p>
                    <p>UAB - Uždaroji Akcine Bendrove (Ukraine – private limited company)</p>
                    <p>UG - Unternehmergesellschaft (Germany – private limited company)</p>
                    <p>ULC - Unlimited Liability Company</p>
                    <p>UÜ - Usaldusühing (Estonia – limited partnership)</p>
                    <p>V.O.F./S.N.C. - Vennootschap Onder Firma / Société en Nom Collectif (Belgium/France - general partnership)</p>
                    <p>W.L.L. - With Limited Liability (Bahrain, Kuwait, Qatar, Saudi Arabia)</p>
                    <p>XK - Joint Stock Company (Mongolia)</p>
                    <p>XXK - Limited Liability Company (Mongolia)</p>
                    <p>ZAO - Zakrytoe aktionernoye obshchestvo (Russia – closed joint stock company); precedes name of company</p>
                    <p>Zrt. - Zártköruen muködo részvénytársaság (Hungary – closely held company)</p>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Miscellaneous
                </Accordion.Heading>
                <Accordion.Content isAccordianOverflowStyling>
                    <p>™ - Trademark</p>
                    <p>© - Copyright</p>
                    <p>® - Registered</p>
                    <p>SM - Special Mark</p>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
