import React, { useEffect, useState } from 'react';
import {
  queryCache,
  useMutation,
  usePaginatedQuery,
  useQuery
} from 'react-query';
import { config } from '../../config';
import * as EmailValidator from 'email-validator';
import { number } from 'yup';

const PromiseContext =
  React.createContext<React.MutableRefObject<Promise<AuthInfo> | null> | null>(
    null
  );
export const AuthContext = React.createContext<AuthInfo | null>(null);
/* eslint-disable*/
const SetAuthInfoContext = React.createContext<
  (newAuthInfo: AuthInfo | null) => void
>(() => null);
/* eslint-enable */
export interface IAuthRolesNice {
  corporate_snapshots: boolean;
  corporate_surveys: boolean;
  corporate_analyzer: boolean;
  investor_reports: boolean;
  historical_reports: boolean;
  industry_reports: boolean;
  mergers_acquisitions: boolean;
  new_issues: boolean;
  dividends: boolean;
  fixed_income: boolean;
  predecessor_defunct: boolean;
  lead_list_generator: boolean;
  directory_directors: boolean;
  person_search: boolean;
  external_databases: boolean;
}
export interface IAuthRolesLegacy {
  fpsu: boolean;
  fpan: boolean;
  fpan_tabular: boolean;
  fpan_portfolio: boolean;
  fpdv: boolean;
  fpni: boolean;
  fpfi: boolean;
  fpni_tabular: boolean;
  fpma: boolean;
  fpir: boolean;
  fphr: boolean;
  fpcr: boolean;
  fpdd_tabular: boolean;
  fpdd: boolean;
  fppd: boolean;
  doss: boolean;
  fpadd: boolean;
}

export interface ISavedSearchResponseRaw {
	saved_searches: Array<{
		QUERYID: number;
		ProductSearch: 'NewIssueAdvanced' | 'MergerAdvanced' | 'FixedIncomeAdvanced' | 'PredecessorAdvanced' | 'NewIssuesLeague' | 'FPSurveyAdvanced' | 'Other';
		UserId: string;
		SearchName: string;
		SearchString: { [key: string]: string };
	}>
}

export interface ISavedSearchSection {
  searchId: number;
  searchName: string;
  searchValues: { [key: string]: string } // key-value obj (string, string)
}

export interface ISavedSearches {
  newIssuesAdvanced: Array<ISavedSearchSection>
  newIssuesLeague: Array<ISavedSearchSection>;
  mergersAcquisitionsAdvanced: Array<ISavedSearchSection>;
  fixedIncomeAdvanced: Array<ISavedSearchSection>;
  predecssorAndDefunctAdvanced: Array<ISavedSearchSection>;
  predecessorAdvanced: Array<ISavedSearchSection>;
  corporateSurveysAdvanced: Array<ISavedSearchSection>;
}

export interface UserInfo {
  UserID: string;
  UserName: string;
  firstName?: string;
  lastName?: string;
}

export interface AuthInfo {
  AUTH_TOKEN: string;
  IC_User: UserInfo;
  auth_roles: IAuthRolesLegacy;
  AuthRolesNice?: IAuthRolesNice;
	SavedSearches?: ISavedSearches;
  DefaultNumberRows: number;
  expand_collapse_settings: {
    default?: boolean;
    [x: string]: boolean | undefined;
  };
  ip_based_login: boolean;
  pref_delta?: number;
}

export function useNumberOfRows () {
  const authInfo = React.useContext(AuthContext);
  if (!authInfo) {
    return 10;
  } else {
    return authInfo.DefaultNumberRows || 10;
  }
}

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export async function fetchSavedSearches (authInfo: AuthInfo|null): Promise<ISavedSearches|undefined> {
  const res = await fetch(`${config.apiUrl}/prefs/prefs_saved_searches_api.php`, {
		method: 'GET',
		headers: authInfo?.AUTH_TOKEN
			? {
					AUTHTOKEN: authInfo?.AUTH_TOKEN
				}
			: {}
	});

	if (!res.ok) {
		throw new Error('A server error occurred');
	}

	const json = await res.text();
	const data: any = parseJSON(json);

	if (!data || !data.saved_searches) {
		throw new Error(`Error: ${data?.message}`);
	}

	const savedSearches = {} as ISavedSearches;
	if (isSavedSearchResponseRaw(data)) {
		savedSearches.newIssuesAdvanced = data.saved_searches.filter(s => s.ProductSearch === 'NewIssueAdvanced').map(s => ({
			searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
    savedSearches.mergersAcquisitionsAdvanced = data.saved_searches.filter(s => s.ProductSearch === 'MergerAdvanced').map(s => ({
			searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
    savedSearches.fixedIncomeAdvanced = data.saved_searches.filter(s => s.ProductSearch === 'FixedIncomeAdvanced').map(s => ({
      searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
    savedSearches.predecessorAdvanced = data.saved_searches.filter(s => s.ProductSearch === 'PredecessorAdvanced').map(s => ({
			searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
    savedSearches.newIssuesLeague = data.saved_searches.filter(s => s.ProductSearch === 'NewIssuesLeague').map(s => ({
			searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
    savedSearches.corporateSurveysAdvanced = data.saved_searches.filter(s => s.ProductSearch === 'FPSurveyAdvanced').map(s => ({
			searchId: s.QUERYID,
			searchName: s.SearchName,
			searchValues: s.SearchString
		}));
	}

	return savedSearches;
}

export function useRefreshSavedSearches () {
  const authInfo = React.useContext(AuthContext);
	const setAuthInfo = useSetAuthInfo();
  const [mutate] = useMutation(
    async (): Promise<ISavedSearches|undefined> => {
			const savedSearches = await fetchSavedSearches(authInfo);
			if (authInfo && savedSearches) {
				authInfo.SavedSearches = savedSearches;
				setAuthInfo(authInfo);
			}

      return authInfo?.SavedSearches;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('user');
      }
    }
  );

  return {
    refreshSavedSearches: mutate,
  };
}

export function useSavedSearches () {
  const authInfo = React.useContext(AuthContext);
  let theSearches = authInfo?.SavedSearches;
  useEffect(() => {
		theSearches = authInfo?.SavedSearches
  }, [authInfo]);

  return {
    searches: theSearches
  };
}

export function useSetNumberOfRows () {
  const setAuthInfo = useSetAuthInfo();
  const authInfo = React.useContext(AuthContext);
  // updating number of rows inside AuthProvider so it can look same on each page.
  return React.useCallback(
    (numberOfRows: number) => {
      if (authInfo && authInfo.DefaultNumberRows) {
        authInfo.DefaultNumberRows = numberOfRows;
      }
      setAuthInfo(authInfo);
      return authInfo;
    },
    [setAuthInfo]
  );
}

export function useExpandSettings () {
  const setAuthInfo = useSetAuthInfo();
  const authInfo = React.useContext(AuthContext);
  return React.useCallback(
    (moduleName?: string, expand?: boolean, isRemove?: boolean) => {
      if (!isRemove) {
        if (authInfo) {
          if (moduleName) {
            authInfo.expand_collapse_settings[moduleName] = expand;
          } else {
            authInfo.expand_collapse_settings.default = expand;
          }
        }
      } else {
        if (authInfo && !moduleName) {
          authInfo.expand_collapse_settings.default = false;
        }
        if (
          moduleName &&
          Object.prototype.hasOwnProperty.call(
            authInfo?.expand_collapse_settings,
            moduleName
          )
        ) {
          delete authInfo?.expand_collapse_settings[moduleName];
        }
      }
      setAuthInfo(authInfo);
      return authInfo;
    },
    [setAuthInfo]
  );
}

export function useSetUserSettings () {
  const authInfo = React.useContext(AuthContext);
  const setAuthInfo = useSetAuthInfo();
  const accessToken: string | undefined = authInfo?.AUTH_TOKEN;

  const [mutate, { isLoading, error, reset }] = useMutation(
    async (params: {
      numberRows: number;
      expandCollapse: boolean;
    }): Promise<boolean> => {
      if (authInfo) {
        authInfo.expand_collapse_settings.default = params.expandCollapse;
      }
      const res = await fetch(`${config.apiUrl}/prefs/prefs_display_api.php`, {
        method: 'POST',
        body: JSON.stringify({
          pref_delta: params.numberRows,
          expand_collapse_settings: authInfo?.expand_collapse_settings,
        }),
        headers: accessToken
        ? {
            AUTHTOKEN: accessToken
          }
        : {}
      });

      if (!res.ok) {
        throw new Error('An error occurred');
      }

      const json = await res.text();
      const data: any = parseJSON(json);
      if (!data || !data.success) {
        throw new Error(`Error: ${data?.message}`);
      }

      if (authInfo) {
        authInfo.DefaultNumberRows = Number(data.pref_delta);
        authInfo.expand_collapse_settings.default = data.expand_collapse_settings;
        await setAuthInfo({ ...authInfo });
      }

      return true;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('user');
      }
    }
  );

  return {
    updateUserSettings: mutate,
    isUpdatingUserSettings: isLoading,
    updatingUserSettingsError: error as
      | null
      | undefined
      | (Error & { code?: 'error-updating-usr-settings' }),
    clearUpdatinguserSettingsError: reset
  };
}

export function useIsLoggedIn () {
  return Boolean(React.useContext(AuthContext));
}

export function useAuthRoles () {
  const authInfo = React.useContext(AuthContext);
  if (!authInfo) {
    return {} as IAuthRolesNice;
  } else {
    return authInfo.AuthRolesNice;
  }
}

export function useLogout () {
  const setAuthInfo = useSetAuthInfo();

  return React.useCallback(() => {
    setAuthInfo(null);
    queryCache.clear();
  }, [setAuthInfo]);
}

export function useLogin () {
  const setAuthInfo = useSetAuthInfo();
  const [mutate, { isLoading, error, reset }] = useMutation(
    async (params: {
      username: string;
      password: string;
    }): Promise<boolean> => {
      const res = await fetch(`${config.apiUrl}/login/login_api.php`, {
        method: 'POST',
        body: JSON.stringify({
          login_user: params.username,
          login_pass: params.password
        })
      });

      if (!res.ok) {
        // const json = await res.text();
        // const code = await getErrorCode(res as any);
        // const err = new Error("An error occurred");
        // (err as any).code = code;
        throw new Error('An error occurred');
      }

      const json = await res.text();
      const data = parseJSON(json);
      if (!isAuthInfo(data)) {
        if (json.includes('badpassword') || json.includes('nosuchuser')) {
          const err = new Error('Invalid password error');
          (err as any).code = 'invalid-password';
          throw err;
        }

        throw new Error('Unexpected response');
      }

      data.AuthRolesNice = {} as IAuthRolesNice;
      data.AuthRolesNice.corporate_snapshots = true;
      data.AuthRolesNice.corporate_surveys = data.auth_roles.fpsu;
      data.AuthRolesNice.corporate_analyzer = data.auth_roles.fpan;
      data.AuthRolesNice.investor_reports = data.auth_roles.fpcr;
      data.AuthRolesNice.historical_reports = data.auth_roles.fphr;
      data.AuthRolesNice.industry_reports = data.auth_roles.fpir;
      data.AuthRolesNice.mergers_acquisitions = data.auth_roles.fpma;
      data.AuthRolesNice.new_issues = data.auth_roles.fpni;
      data.AuthRolesNice.dividends = data.auth_roles.fpdv;
      data.AuthRolesNice.fixed_income = data.auth_roles.fpfi;
      data.AuthRolesNice.predecessor_defunct = data.auth_roles.fppd;
      data.AuthRolesNice.directory_directors = data.auth_roles.fpdd;
      data.AuthRolesNice.lead_list_generator = data.auth_roles.doss;
      data.AuthRolesNice.person_search = data.auth_roles.doss;
      data.AuthRolesNice.external_databases = data.auth_roles.fpadd;

			// instantiate the card expand/collapse settings if not
			// provided by the server on login
			if (!data.expand_collapse_settings) {
				data.expand_collapse_settings = {
					default: true,
				}
			}

      data.ip_based_login = false;

      data.DefaultNumberRows = data.pref_delta || 10;

      setAuthInfo(data);
      return true;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('user');
      }
    }
  );

  return {
    login: mutate,
    isLoggingIn: isLoading,
    loginError: error as
      | null
      | undefined
      | (Error & { code?: 'invalid-password' }),
    clearLoginError: reset
  };
}

export function useLoginByIp () {
  const setAuthInfo = useSetAuthInfo();
  const [mutate, { isLoading, error, reset }] = useMutation(
    async (params: {
      username: string;
    }): Promise<boolean> => {
      const res = await fetch(`${config.apiUrl}/login/login_ip_api.php`, {
        method: 'POST',
        body: JSON.stringify({
          login_user: params.username,
        })
      });

      if (!res.ok) {
        // const json = await res.text();
        // const code = await getErrorCode(res as any);
        // const err = new Error("An error occurred");
        // (err as any).code = code;
        throw new Error('An error occurred');
      }

      const json = await res.text();
      const data = parseJSON(json);
      if (!isAuthInfo(data)) {
        if (json.includes('badpassword') || json.includes('nosuchuser')) {
          const err = new Error('Invalid password error');
          (err as any).code = 'invalid-password';
          throw err;
        }

        throw new Error('Unexpected response');
      }

      data.AuthRolesNice = {} as IAuthRolesNice;
      data.AuthRolesNice.corporate_snapshots = true;
      data.AuthRolesNice.corporate_surveys = data.auth_roles.fpsu;
      data.AuthRolesNice.corporate_analyzer = data.auth_roles.fpan;
      data.AuthRolesNice.investor_reports = data.auth_roles.fpcr;
      data.AuthRolesNice.historical_reports = data.auth_roles.fphr;
      data.AuthRolesNice.industry_reports = data.auth_roles.fpir;
      data.AuthRolesNice.mergers_acquisitions = data.auth_roles.fpma;
      data.AuthRolesNice.new_issues = data.auth_roles.fpni;
      data.AuthRolesNice.dividends = data.auth_roles.fpdv;
      data.AuthRolesNice.fixed_income = data.auth_roles.fpfi;
      data.AuthRolesNice.predecessor_defunct = data.auth_roles.fppd;
      data.AuthRolesNice.directory_directors = data.auth_roles.fpdd;
      data.AuthRolesNice.lead_list_generator = data.auth_roles.doss;
      data.AuthRolesNice.person_search = data.auth_roles.doss;
      data.AuthRolesNice.external_databases = data.auth_roles.fpadd;

			// instantiate the card expand/collapse settings if not
			// provided by the server on login
			if (!data.expand_collapse_settings) {
				data.expand_collapse_settings = {
					default: true,
				}
			}

      data.DefaultNumberRows = data.pref_delta || 10;

      data.ip_based_login = true;

      setAuthInfo(data);
      return true;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('user');
      }
    }
  );

  return {
    loginByIp: mutate,
    isLoggingInByIp: isLoading,
    loginByIpError: error as
      | null
      | undefined
      | (Error & { code?: 'invalid-ip-address' }),
    clearLoginByIpError: reset
  };
}

export function useChangePassword () {
  const authInfo = React.useContext(AuthContext);
  const accessToken: string | undefined = authInfo?.AUTH_TOKEN;

  const [mutate, { isLoading, error, reset }] = useMutation(
    async (params: {
      old_password: string;
      new_password1: string;
      new_password2: string;
    }): Promise<boolean> => {
      const res = await fetch(`${config.apiUrl}/prefs/prefs_password_api.php`, {
        method: 'POST',
        body: JSON.stringify({
          old_password: params.old_password,
          new_password1: params.new_password1,
          new_password2: params.new_password2
        }),
        headers: accessToken
          ? {
              AUTHTOKEN: accessToken
            }
          : {}
      });

      if (!res.ok) {
        throw new Error('A server error occurred');
      }

      const json = await res.text();
      const data: any = parseJSON(json);
      if (!data || !data.success) {
        throw new Error(`Error: ${data?.message}`);
      }
      return true;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('user');
      }
    }
  );

  return {
    changePassword: mutate,
    isChangingPassword: isLoading,
    changePasswordError: error as
      | null
      | undefined
      | (Error & { code?: 'invalid-password' }),
    clearChangePasswordError: reset
  };
}

export function usePasswordReminder () {
  const [mutate, { isLoading, isSuccess, error, reset }] = useMutation(
    async (params: { email: string }): Promise<boolean> => {
      if (!EmailValidator.validate(params.email)) {
        throw new Error(
          'There was an error. Please provide a valid email address.'
        );
        return false;
      }

      const formData = new FormData();
      formData.append('forget_email', params.email);

      const res = await fetch(
        `${config.apiUrl}/login/login_forget_action.php`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!res.ok) {
        throw new Error('An error occurred');
      }

      return true;
    },
    {
      onSuccess: () => {}
    }
  );

  return {
    sendPasswordReminder: mutate,
    isSendingPasswordReminder: isLoading,
    sendPasswordReminderError: error as
      | null
      | undefined
      | (Error & { code?: 'invalid-email' }),
    sendPasswordReminderSuccess: isSuccess,
    clearSendPasswordReminderError: reset
  };
}

function parseJSON (json: string): unknown {
  try {
    return JSON.parse(json);
  } catch (err) {
    return null;
  }
}

export function isAuthInfo (res: unknown): res is AuthInfo {
  return Boolean(
    typeof res === 'object' &&
      res &&
      typeof (res as any).AUTH_TOKEN === 'string'
  );
}

export function isSavedSearchResponseRaw (res: unknown): res is ISavedSearchResponseRaw {
  return Boolean(
    typeof res === 'object' &&
      res &&
      typeof (res as any).saved_searches === 'object'
  );
}

export function useSetAuthInfo () {
  return React.useContext(SetAuthInfoContext);
}

export function AuthProvider (props: { children: React.ReactNode }) {
  const initialAuthInfo = React.useMemo(() => {
    const authInfoStr = localStorage.getItem('authInfo');
    if (authInfoStr) {
      const authInfo = JSON.parse(authInfoStr) as AuthInfo;
      return { ...authInfo, DefaultNumberRows: authInfo.DefaultNumberRows || 10 };
    }

    return null;
  }, []);

  const authPromiseRef = React.useRef<Promise<AuthInfo> | null>(null);
  const [authInfo, setAuthInfoState] = React.useState<AuthInfo | null>(
    initialAuthInfo
  );
  const setAuthInfo = React.useMemo(
    () => (newAuthInfo: AuthInfo | null) => {
      if (!newAuthInfo) {
        localStorage.removeItem('authInfo');
      } else {
        localStorage.setItem('authInfo', JSON.stringify(newAuthInfo));
      }
      setAuthInfoState(newAuthInfo);
    },
    [setAuthInfoState]
  );

  return (
    <PromiseContext.Provider value={authPromiseRef}>
      <AuthContext.Provider value={authInfo}>
        <SetAuthInfoContext.Provider value={setAuthInfo}>
          {props.children}
        </SetAuthInfoContext.Provider>
      </AuthContext.Provider>
    </PromiseContext.Provider>
  );
}

export function useGetAccessToken (): () => Promise<string | null> {
  const authInfo = React.useContext(AuthContext);

  // Returns an async function incase in the future the auth system changes and the
  // token needs to be refreshed every so often before being used
  return React.useCallback(async () => {
    if (!authInfo) {
      return null;
    }

    return authInfo.AUTH_TOKEN;
  }, [authInfo]);
}

export function useGetUserInfo (): UserInfo | undefined {
  const authInfo = React.useContext(AuthContext);

  const names = {
    firstName: authInfo?.IC_User.UserName.split(' ')[0],
    lastName: authInfo?.IC_User.UserName.split(' ')[1]
  };

  return Object.assign(names, authInfo?.IC_User);
}

export function useAuthenticatedQuery<T extends unknown, R extends unknown> (
  queryKey: [string, T],
  queryFunction: (
    params: T,
    getAccessToken: () => Promise<string | null>
  ) => Promise<R>
) {
  const getAccessToken = useGetAccessToken();
  return useQuery(queryKey, () => queryFunction(queryKey[1], getAccessToken));
}

export function useAuthenticatedPaginatedQuery<
  T extends unknown,
  R extends unknown
> (
  queryKey: [string, T],
  queryFunction: (
    params: T,
    getAccessToken: () => Promise<string | null>
  ) => Promise<R>
) {
  const getAccessToken = useGetAccessToken();

  return usePaginatedQuery(queryKey, () =>
    queryFunction(queryKey[1], getAccessToken)
  );
}
