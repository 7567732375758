/* eslint-disable */
import * as React from 'react';
import { useParams, useHistory } from 'react-router';
import { Page } from '../../../../components/Page';
import { Heading } from '../../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../../hooks';
import { TextField } from '../../../../components/TextField';
import { Button } from '../../../../components/Button';
import { Text } from '../../../../components/Text';
import { MultiSelectField } from '../../../../components/MultiSelectField';
import { SelectField } from '../../../../components/SelectField';
import ClearButton from 'components/ClearButton';
import {
  locationTypesLookup,
  companyTypesLookup,
  provincesLookup,
} from '../../../../data/lookups';
import styled from 'styled-components';
import moment from 'moment';

export function LeadListGeneratorSearchExternalPage () {
  const history = useHistory();
  const headingId = useId();
  const [companyName, setCompanyName] = React.useState('');

  const [city, setCity] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [areaCode, setAreaCode] = React.useState('');
  const [locationType, setLocationType] = React.useState('');
  const [companyTypes, setCompanyTypes] = React.useState('');
  const [sicCode, setSicCode] = React.useState('');
  const [employeesGT, setEmployeesGT] = React.useState('');
  const [employeesLT, setEmployeesLT] = React.useState('');

  const params = useParams<{ dbCode: string }>();

  const P = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    margin: 0;
  `;

  const LessThanField = styled.div`
  margin-top: 10px;
  .Text__StyledText-sc-el53o5-0.eBVmcO{
    display: none;
  }
`;

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  /* city */
  const cityPrev = usePreviousStorageData(city) || '';
  React.useEffect(() => {
    if (cityPrev !== city) {
      localStorage.setItem('cityED', JSON.stringify(city));
    }
  }, [city]);

  /* Address */
  const addressPrev = usePreviousStorageData(address) || '';
  React.useEffect(() => {
    if (addressPrev !== address) {
      localStorage.setItem('addressED', JSON.stringify(address));
    }
  }, [address]);

  /* Provinces */
  const provincesPrev = usePreviousStorageData(provinces) || '';
  React.useEffect(() => {
    localStorage.setItem('provincesED', JSON.stringify(provinces));
  }, [provinces]);

  /* locationType */
  const locationTypePrev = usePreviousStorageData(locationType) || '';
  React.useEffect(() => {
    if (locationTypePrev !== locationType) {
      localStorage.setItem('locationTypeED', JSON.stringify(locationType));
    }
  }, [locationType]);

  /* companyTypes */
  const companyTypesPrev = usePreviousStorageData(companyTypes) || '';
  React.useEffect(() => {
    if (companyTypesPrev !== companyTypes) {
      localStorage.setItem('companyTypesED', JSON.stringify(companyTypes));
    }
  }, [companyTypes]);

  /* areaCode */
  const areaCodePrev = usePreviousStorageData(areaCode) || '';
  React.useEffect(() => {
    if (areaCodePrev !== areaCode) {
      localStorage.setItem('areaCodeED', JSON.stringify(areaCode));
    }
  }, [areaCode]);

  /* sicCode */
  const sicCodePrev = usePreviousStorageData(sicCode) || '';
  React.useEffect(() => {
    if (sicCodePrev !== sicCode) {
      localStorage.setItem('sicCodeED', JSON.stringify(sicCode));
    }
  }, [sicCode]);

  /* employeesGT */
  const employeesGTPrev = usePreviousStorageData(employeesGT) || '';
  React.useEffect(() => {
    if (employeesGTPrev !== employeesGT) {
      localStorage.setItem('employeesGTED', JSON.stringify(employeesGT));
    }
  }, [employeesGT]);

  /* employeesGT */
  const employeesLTPrev = usePreviousStorageData(employeesLT) || '';
  React.useEffect(() => {
    if (employeesLTPrev !== employeesLT) {
      localStorage.setItem('employeesLTED', JSON.stringify(employeesLT));
    }
  }, [employeesLT]);


  const loadLatestEntries = () => {
    const city = localStorage.getItem('cityED');
    if (city) {
      setCity(JSON.parse(city));
    }
    const address = localStorage.getItem('addressED');
    if (address) {
      setAddress(JSON.parse(address));
    }
    const provinces = localStorage.getItem('provincesED');
    if (provinces) {
      setProvinces(JSON.parse(provinces));
    }
    const locationType = localStorage.getItem('locationTypeED');
    if (locationType) {
      setLocationType(JSON.parse(locationType));
    }
    const companyType = localStorage.getItem('companyTypesED');
    if (companyType) {
      setCompanyTypes(JSON.parse(companyType));
    }
    const areaCode = localStorage.getItem('areaCodeED');
    if (areaCode) {
      setAreaCode(JSON.parse(areaCode));
    }
    const sicCode = localStorage.getItem('sicCodeED');
    if (sicCode) {
      setSicCode(JSON.parse(sicCode));
    }
    const employeesGT = localStorage.getItem('employeesGTED');
    if (employeesGT) {
      setEmployeesGT(JSON.parse(employeesGT));
    }
    const employeesLT = localStorage.getItem('employeesLTED');
    if (employeesLT) {
      setEmployeesLT(JSON.parse(employeesLT));
    }

  }

  const clearCheck = () => {
    setCity('');
    setAddress('');
    setProvinces([]);
    setLocationType('');
    setCompanyTypes('');
    setAreaCode('');
    setSicCode('');
    setEmployeesGT('');
    setEmployeesLT('');
  }

  let dbLongName = 'External Database';
  let dbText = <></>;
  switch (params.dbCode) {
    case 'pro-file-canada':
      dbLongName = 'Owen Media Partners - ProFile Canada';
      dbText = (
        <>
          Contains marketing and business intelligence on Canada's largest
          92,000 companies with a focus on privately owned businesses. Each
          record includes up to 21 functional contacts.
        </>
      );

      break;
    case 'encyclopedia-of-associations':
      dbLongName = 'Cengage Learning Gale - Encyclopedia of Associations';
      dbText = (
        <>
          Includes profiles of 3,000 Canadian associations extracted from the
          Gale database the{' '}
          <em>Encyclopedia of Associations: International Organizations</em>.
          The profiles provide addresses and descriptions of professional
          societies, trade associations, labor unions, cultural and religious
          organizations, fan clubs, and other groups of all types.
        </>
      );
      break;
    case 'wards-business-directory':
      dbLongName = "Cengage Learning Gale - Ward's Business Directory";
      dbText = (
        <>
          Includes profiles of 9,000 Canadian companies extracted from the Gale
          database{' '}
          <em>
            Ward's Business Directory of Private and Public Companies in Canada
            and Mexico
          </em>
          . The profiles provide current company information of Canadian firms,
          most of which are private companies.
        </>
      );
      break;
  }
  const leadListCengageSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL(
      `/lead-list-generator/results/${params.dbCode}`,
      baseUrl
    );
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);
    if (city) {
      resultsUrl.searchParams.set('city', city);
    }
    if (address) {
      resultsUrl.searchParams.set('address', address);
    }
    // if (provinces && provinces.length > 0) {
    // 	resultsUrl.searchParams.set('provinces', provinces.join(','));
    // }
    const province = provincesLookup
      .filter((p) => provinces.includes(p.name))
      .map((p) => p.id);
    resultsUrl.searchParams.set('provinces[]', province.join(','));
    if (areaCode) {
      resultsUrl.searchParams.set('areaCode', areaCode);
    }
    if (locationType) {
      resultsUrl.searchParams.set('locationType', locationType);
    }
    if (companyTypes) {
      resultsUrl.searchParams.set('companyTypes', companyTypes);
    }
    if (sicCode) {
      resultsUrl.searchParams.set('sicCode', sicCode);
    }
    if (employeesGT) {
      resultsUrl.searchParams.set('employeesGT', employeesGT);
    }
    if (employeesLT) {
      resultsUrl.searchParams.set('employeesLT', employeesLT);
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), { data: true });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
        Lead List Generator - {dbLongName} - Search
      </Heading>

      <Text mt="sm" lineHeight="md">
        {dbText}
        {/* Includes profiles of 3,000 Canadian associations extracted from the Gale
        database the{' '}
        <em>Encyclopedia of Associations: International Organizations</em>. The
        profiles provide addresses and descriptions of professional societies,
        trade associations, labor unions, cultural and religious organizations,
        fan clubs, and other groups of all types. */}
      </Text>

      <form
        aria-labelledby={headingId}
        onSubmit={(e) => {
          e.preventDefault();
          leadListCengageSubmitData();
        }}>
        <TextField mt="lg" label="City" value={city} onChange={setCity} />

        <TextField
          mt="lg"
          label="Address"
          value={address}
          onChange={setAddress}
        />

        <div style={{ marginTop: '24px' }}>
          <MultiSelectField
            label="Province"
            items={provincesLookup}
            value={provinces}
            onChange={setProvinces}
          />
        </div>

        <SelectField
          title='Location Type'
          value={locationType}
          setValue={setLocationType}
          options={locationTypesLookup}
        />

        <SelectField
          title='Company Type'
          value={companyTypes}
          setValue={setCompanyTypes}
          options={companyTypesLookup}
        />

        <TextField
          mt="lg"
          label="Area Code"
          value={areaCode}
          onChange={setAreaCode}
        />
        <Text color="light">
          (to search more than one, separate with spaces)
        </Text>

        <TextField
          mt="lg"
          label="SIC Code"
          value={sicCode}
          onChange={setSicCode}
        />
        <Text color="light">
          (to search more than one, separate with spaces)
        </Text>

        <TextField
          mt="lg"
          label="Employees (greater than)"
          placeholder="Greater than"
          value={employeesGT}
          onChange={setEmployeesGT}
        />
        <LessThanField>
        <TextField
          label="Employees (less than)"
          placeholder="Less than"
          value={employeesLT}
          onChange={setEmployeesLT}
        />
        </LessThanField>

        <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
      </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
    </Page>
  );
}
