import * as React from 'react';
import { useId } from '../../hooks';

import { IconBase, IconBaseProps } from '../IconBase';

export function ExternalLinkIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={[
        'M0.06,14.6815972 L0.06,1.31840281 C0.230326322,0.680046133 0.747057681,0.180860391 1.40055279,0.04 L5.02,0.04 L5.02,1.778 L1.778,1.778 L1.778,14.222 L14.222,14.222 L14.222,11.027 L16,11.027 L16,14.222 C16,15.2 15.2,16 14.222,16 L1.778,16 C0.951239027,16 0.262003068,15.4386734 0.06,14.6815972 L0.06,14.6815972 Z M9.778,0.04 L16,0.04 L16,6.222 L14.222,6.222 L14.222,3.031 L5.484,11.769 L4.231,10.516 L12.969,1.778 L9.778,1.778 L9.778,0.04 L9.778,0.04 Z',
      ]}
    />
  );
}
