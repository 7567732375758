import * as React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Row } from '../../../components/Row';
import { MultiSelectFieldChips } from '../../../components/MultiSelectFieldChips';
import ClearButton from '../../../components/ClearButton/index';
import { Tabs, Tab } from '../../../components/Tabs';
import { Tiles } from '../../../components/Tiles';
import { useNaicsCodes } from '../../../data';

import {
  positionsLookup,
  provincesLookup,
  countriesLookup,
  schoolsLookup,
  specialtiesLookup,
  fellowshipsLookup,
  degreesLookup,
  gicsLookup,
  sicLookup,
  fpIndustryLookup,
} from '../../../data/lookups';

export function DirectorsSearchPage () {
  const history = useHistory();
  const headingId = useId();
  const headingIdAdvanced = useId();

  let tabVar = 0;
  switch (history.location.hash) {
    case '#advanced':
      tabVar = 1;
      break;
    case '#connectors':
      tabVar = 2;
      break;
    default:
      tabVar = 0;
      break;
  }

  // basic
  const [personName, setPersonName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [companySearchType, setCompanySearchType] = React.useState('');

  // advanced
  const [position, setPosition] = React.useState<string[]>([]);
  const [gender, setGender] = React.useState('');

  const [companyTypePublic, setCompanyTypePublic] = React.useState(false);
  const [companyTypePrivate, setCompanyTypePrivate] = React.useState(false);
  const [companyTypeFP500, setCompanyTypeFP500] = React.useState(false);
  const [companyTypeNotForProfit, setCompanyTypeNotForProfit] = React.useState(false);
  const [targetFPIndustry, setTargetFPIndustry] = React.useState('');

  const [deptHeads, setDeptHeads] = React.useState('');

  const [gicCodes, setGicCodes] = React.useState<string[]>([]);
  const [sicCodes, setSicCodes] = React.useState<string[]>([]);
  const [sicPrimary, setSicPrimary] = React.useState('primary');
  const [naicsCodes, setNaicsCodes] = React.useState<string[]>([]);
  const [naicsPrimary, setNaicsPrimary] = React.useState('primary');

  const [city, setCity] = React.useState('');
  const [provinces, setProvinces] = React.useState<string[]>([]);
  const [countries, setCountries] = React.useState<string[]>([]);

  const [school, setSchool] = React.useState<string[]>([]);
  const [degree, setDegree] = React.useState<string[]>([]);
  const [speciality, setSpeciality] = React.useState<string[]>([]);
  const [fellowship, setFellowship] = React.useState<string[]>([]);
  const [naicsLookupCodes, setNaicsLookupCodes] = React.useState<any>([{ id: '', name: 'NAICS Codes' }]);

  const { data } = useNaicsCodes();

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  /* companyName */
  const personNamePrev = usePreviousStorageData(personName) || '';
  React.useEffect(() => {
    if (personNamePrev !== personName) {
      localStorage.setItem('personNameDD', JSON.stringify(personName));
    }
  }, [personName]);

  /* companyName */
  const companyNamePrev = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (companyNamePrev !== companyName) {
      localStorage.setItem('companyNameDD', JSON.stringify(companyName));
    }
  }, [companyName]);

  /* companySearchType */
  const companySearchTypePrev = usePreviousStorageData(companySearchType) || '';
  React.useEffect(() => {
    if (companyNamePrev !== companySearchType) {
      localStorage.setItem('companySearchTypeDD', JSON.stringify(companySearchType));
    }
  }, [companySearchType]);

  /* position */
  React.useEffect(() => {
      localStorage.setItem('positionDD', JSON.stringify(position));
  }, [position]);

  /* gender */
  const genderPrev = usePreviousStorageData(gender) || '';
  React.useEffect(() => {
    if (genderPrev !== gender) {
      localStorage.setItem('genderDD', JSON.stringify(gender));
    }
  }, [gender]);

  /* companyTypePublic */
  const companyTypePublicPrev = usePreviousStorageData(companyTypePublic) || '';
  React.useEffect(() => {
    if (companyTypePublicPrev !== companyTypePublic.toString()) {
      localStorage.setItem('companyTypePublicDD', JSON.stringify(companyTypePublic));
    }
  }, [companyTypePublic]);

  /* companyTypePrivate */
  const companyTypePrivatePrev = usePreviousStorageData(companyTypePrivate) || '';
  React.useEffect(() => {
    if (companyTypePrivatePrev !== companyTypePrivate.toString()) {
      localStorage.setItem('companyTypePrivateDD', JSON.stringify(companyTypePrivate));
    }
  }, [companyTypePrivate]);

  /* companyTypeFP500 */
  const companyTypeFP500Prev = usePreviousStorageData(companyTypeFP500) || '';
  React.useEffect(() => {
    if (companyTypeFP500Prev !== companyTypeFP500.toString()) {
      localStorage.setItem('companyTypeFP500DD', JSON.stringify(companyTypeFP500));
    }
  }, [companyTypeFP500]);

  /* companyTypeNotForProfit */
  const companyTypeNotForProfitPrev = usePreviousStorageData(companyTypeNotForProfit) || '';
  React.useEffect(() => {
    if (companyTypeNotForProfitPrev !== companyTypeNotForProfit.toString()) {
      localStorage.setItem('companyTypeNotForProfitDD', JSON.stringify(companyTypeNotForProfit));
    }
  }, [companyTypeNotForProfit]);

  /* targetFPIndustry */
  const targetFPIndustryPrev = usePreviousStorageData(targetFPIndustry) || '';
  React.useEffect(() => {
    if (targetFPIndustryPrev !== targetFPIndustry) {
      localStorage.setItem('targetFPIndustryDD', JSON.stringify(targetFPIndustry));
    }
  }, [targetFPIndustry]);

  /* deptHeads */
  const deptHeadsPrev = usePreviousStorageData(deptHeads) || '';
  React.useEffect(() => {
    if (deptHeadsPrev !== deptHeads) {
      localStorage.setItem('deptHeadsDD', JSON.stringify(deptHeads));
    }
  }, [deptHeads]);

  /* gicCodes */
  React.useEffect(() => {
    localStorage.setItem('gicCodesDD', JSON.stringify(gicCodes));
  }, [gicCodes]);

  /* sicCodes */
  React.useEffect(() => {
    localStorage.setItem('sicCodesDD', JSON.stringify(sicCodes));
  }, [sicCodes]);

  /* sicPrimary */
  const sicPrimaryPrev = usePreviousStorageData(sicPrimary) || '';
  React.useEffect(() => {
    if (sicPrimaryPrev !== sicPrimary) {
      localStorage.setItem('sicPrimaryDD', JSON.stringify(sicPrimary));
    }
  }, [sicPrimary]);

  /* naicsCodes */
  React.useEffect(() => {
    localStorage.setItem('naicsCodesDD', JSON.stringify(naicsCodes));
  }, [naicsCodes]);

  /* naicsPrimary */
  const naicsPrimaryPrev = usePreviousStorageData(naicsPrimary) || '';
  React.useEffect(() => {
    if (naicsPrimaryPrev !== naicsPrimary) {
      localStorage.setItem('naicsPrimaryDD', JSON.stringify(naicsPrimary));
    }
  }, [naicsPrimary]);

  /* city */
  const cityPrev = usePreviousStorageData(city) || '';
  React.useEffect(() => {
    if (cityPrev !== city) {
      localStorage.setItem('cityDD', JSON.stringify(city));
    }
  }, [city]);

  /* provinces */
  React.useEffect(() => {
    localStorage.setItem('provincesDD', JSON.stringify(provinces));
  }, [provinces]);

  /* countries */
  React.useEffect(() => {
    localStorage.setItem('countriesDD', JSON.stringify(countries));
  }, [countries]);

  /* school */
  React.useEffect(() => {
    localStorage.setItem('schoolDD', JSON.stringify(school));
  }, [school]);

  /* degree */
  React.useEffect(() => {
    localStorage.setItem('degreeDD', JSON.stringify(degree));
  }, [degree]);

  /* speciality */
  React.useEffect(() => {
    localStorage.setItem('specialityDD', JSON.stringify(speciality));
  }, [speciality]);

  /* fellowship */
  React.useEffect(() => {
    localStorage.setItem('fellowshipDD', JSON.stringify(fellowship));
  }, [fellowship]);

  const loadLatestEntries = () => {
    const personNameDD = localStorage.getItem('personNameDD');
    if (personNameDD) {
      setPersonName(JSON.parse(personNameDD));
    }
    const companyNameDD = localStorage.getItem('companyNameDD');
    if (companyNameDD) {
      setCompanyName(JSON.parse(companyNameDD));
    }
    const companySearchTypeDD = localStorage.getItem('companySearchTypeDD');
    if (companySearchTypeDD) {
      setCompanySearchType(JSON.parse(companySearchTypeDD));
    }
    const positionDD = localStorage.getItem('positionDD');
    if (positionDD) {
      setPosition(JSON.parse(positionDD));
    }
    const genderDD = localStorage.getItem('genderDD');
    if (genderDD) {
      setGender(JSON.parse(genderDD));
    }
    const companyTypePublicDD = localStorage.getItem('companyTypePublicDD');
    if (companyTypePublicDD) {
      setCompanyTypePublic(JSON.parse(companyTypePublicDD));
    }
    const companyTypePrivateDD = localStorage.getItem('companyTypePrivateDD');
    if (companyTypePrivateDD) {
      setCompanyTypePrivate(JSON.parse(companyTypePrivateDD));
    }
    const companyTypeFP500DD = localStorage.getItem('companyTypeFP500DD');
    if (companyTypeFP500DD) {
      setCompanyTypeFP500(JSON.parse(companyTypeFP500DD));
    }
    const companyTypeNotForProfitDD = localStorage.getItem('companyTypeNotForProfitDD');
    if (companyTypeNotForProfitDD) {
      setCompanyTypeNotForProfit(JSON.parse(companyTypeNotForProfitDD));
    }
    const targetFPIndustryDD = localStorage.getItem('targetFPIndustryDD');
    if (targetFPIndustryDD) {
      setTargetFPIndustry(JSON.parse(targetFPIndustryDD));
    }
    const deptHeadsDD = localStorage.getItem('deptHeadsDD');
    if (deptHeadsDD) {
      setDeptHeads(JSON.parse(deptHeadsDD));
    }
    const gicCodesDD = localStorage.getItem('gicCodesDD');
    if (gicCodesDD) {
      setGicCodes(JSON.parse(gicCodesDD));
    }
    const sicCodesDD = localStorage.getItem('sicCodesDD');
    if (sicCodesDD) {
      setSicCodes(JSON.parse(sicCodesDD));
    }
    const sicPrimaryDD = localStorage.getItem('sicPrimaryDD');
    if (sicPrimaryDD) {
      setSicPrimary(JSON.parse(sicPrimaryDD));
    }
    const naicsCodesDD = localStorage.getItem('naicsCodesDD');
    if (naicsCodesDD) {
      setNaicsCodes(JSON.parse(naicsCodesDD));
    }
    const naicsPrimaryDD = localStorage.getItem('naicsPrimaryDD');
    if (naicsPrimaryDD) {
      setNaicsPrimary(JSON.parse(naicsPrimaryDD));
    }
    const cityDD = localStorage.getItem('cityDD');
    if (cityDD) {
      setCity(JSON.parse(cityDD));
    }
    const provincesDD = localStorage.getItem('provincesDD');
    if (provincesDD) {
      setProvinces(JSON.parse(provincesDD));
    }
    const countriesDD = localStorage.getItem('countriesDD');
    if (countriesDD) {
      setCountries(JSON.parse(countriesDD));
    }
    const schoolDD = localStorage.getItem('schoolDD');
    if (schoolDD) {
      setSchool(JSON.parse(schoolDD));
    }
    const degreeDD = localStorage.getItem('degreeDD');
    if (degreeDD) {
      setDegree(JSON.parse(degreeDD));
    }
    const specialityDD = localStorage.getItem('specialityDD');
    if (specialityDD) {
      setSpeciality(JSON.parse(specialityDD));
    }
    const fellowshipDD = localStorage.getItem('fellowshipDD');
    if (fellowshipDD) {
      setFellowship(JSON.parse(fellowshipDD));
    }
  }

  const financialDirectorySubmitData = (formType: string) => {
    const newDate = new Date()
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;
    const resultsUrl = new URL(
      '/directory-of-directors/results',
      baseUrl
    );

    resultsUrl.searchParams.set('searchType', formType);
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set('companyName', companyName);
    }
    if (personName) {
      resultsUrl.searchParams.set('personName', personName);
    }
    if (companySearchType) {
      resultsUrl.searchParams.set('companySearchType', companySearchType);
    }

    // advanced search
    if (position) {
      resultsUrl.searchParams.set('position', position.toString());
    }
    if (gender) {
      resultsUrl.searchParams.set('gender', gender);
    }
    if (companyTypePublic) {
      resultsUrl.searchParams.set('companyTypePublic', companyTypePublic.toString());
    }
    if (companyTypePrivate) {
      resultsUrl.searchParams.set('companyTypePrivate', companyTypePrivate.toString());
    }
    if (companyTypeFP500) {
      resultsUrl.searchParams.set('companyTypeFP500', companyTypeFP500.toString());
    }
    if (companyTypeNotForProfit) {
      resultsUrl.searchParams.set('companyTypeNotForProfit', companyTypeNotForProfit.toString());
    }
    if (targetFPIndustry) {
      resultsUrl.searchParams.set('targetFPIndustry', targetFPIndustry);
    }
    if (deptHeads) {
      resultsUrl.searchParams.set('deptHeads', deptHeads);
    }
    if (gicCodes) {
      resultsUrl.searchParams.set('gicCodes', gicCodes.toString());
    }
    if (sicCodes) {
      resultsUrl.searchParams.set('sicCodes', sicCodes.toString());
    }
    if (naicsCodes) {
      resultsUrl.searchParams.set('naicsCodes', naicsCodes.toString());
    }
    if (sicPrimary) {
      resultsUrl.searchParams.set('sicPrimary', sicPrimary);
    }
    if (naicsPrimary) {
      resultsUrl.searchParams.set('naicsPrimary', naicsPrimary);
    }
    if (city) {
      resultsUrl.searchParams.set('city', city);
    }
    if (provinces) {
      resultsUrl.searchParams.set('provinces', provinces.toString());
    }
    if (countries) {
      resultsUrl.searchParams.set('countries', countries.toString());
    }
    if (school) {
      resultsUrl.searchParams.set('school', school.toString());
    }
    if (degree) {
      resultsUrl.searchParams.set('degree', degree.toString());
    }
    if (speciality) {
      resultsUrl.searchParams.set('speciality', speciality.toString());
    }
    if (fellowship) {
      resultsUrl.searchParams.set('fellowship', fellowship.toString());
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true,
    });
  }

  const ConnectorssubmitData = () => {
    const newDate = new Date()
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;
    const resultsUrl = new URL(
      '/directory-of-directors/connectors/results',
      baseUrl
    );

    // basic search
    if (personName) {
      resultsUrl.searchParams.set('personName', personName);
    }

    history.push(resultsUrl.href.replace(baseUrl, ''), {
      data: true,
    });
  }

  const clearCheck = () => {
    setCompanyName('');
    setCompanySearchType('');
    setPersonName('');
    setCity('');
    setCompanyTypeFP500(false);
    setCompanyTypeNotForProfit(false);
    setCompanyTypePrivate(false);
    setCompanyTypePublic(false);
    setCountries([]);
    setDegree([]);
    setDeptHeads('');
    setFellowship([]);
    setGender('');
    setGicCodes([]);
    setNaicsCodes([]);
    setSicCodes([]);
    setNaicsPrimary('primary');
    setPosition([]);
    setProvinces([]);
    setSchool([]);
    setSicPrimary('primary');
    setSpeciality([]);
    setTargetFPIndustry('');
  }

  return (
    <Page aria-label='Financial Post Directory of Directors'>
      <Tabs aria-label="Search Options" selectedTab={tabVar}>
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Directory of Directors - Basic Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on 28,000 directors and executives of Canadian public and
            private companies from the Financial Post Directory of Directors
            publication and Financial Post Corporate Surveys, listing Canadian
            business people and the directorships and offices they hold past and
            present, primary business address and contact information, education and
            associations.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              financialDirectorySubmitData('basic');
            }}
          >
            <Tiles columns={[1, 1]}>
              <div>
                <TextField
                  mt="lg"
                  label="Person Name"
                  value={personName}
                  onChange={setPersonName}
                />
                <Text mt="xxs" color="light">
                  (a search for 'John Smith' will also retrieve 'John F. Smith' or
                  'John Frederick Smith')
                </Text>

                <TextField
                  mt="lg"
                  label="Company Name"
                  value={companyName}
                  onBlur={() => !companyName && setCompanySearchType('')}
                  onFocus={() =>
                    !companySearchType && setCompanySearchType('lead-company')
                  }
                  onChange={setCompanyName}
                />

                <Text mt="xxs" color="light" mb="xl">
                  Type a company name to enable radio buttons
                </Text>

                <RadioList
                  aria-label="Company search type"
                  value={companySearchType}
                  onChange={setCompanySearchType}
                  disabled={companyName === ''}
                  options={[
                    {
                      value: 'lead-company',
                      label: 'Lead Company',
                    },
                    {
                      value: 'current-company',
                      label: 'Current Company(s)',
                    },
                    {
                      value: 'previous-company',
                      label: 'Previous Company(s)',
                    },
                    {
                      value: 'all',
                      label: 'All',
                    },
                  ]}
                />

                <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
              </div>
            </Tiles>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>

        <Tab label="Advanced">
          <Heading id={headingIdAdvanced} level="1" size={['xxl', 'xxl', 'xxxl']}>
          Financial Post Directory of Directors - Advanced Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on 28,000 directors and executives of Canadian public and private companies from the Financial Post Directory of Directors publication and Financial Post Corporate Surveys, listing Canadian business people and the directorships and offices they hold past and present, primary business address and contact information, education and associations.
          </Text>

          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details will narrow your search results.
          </Text>

          <form
            aria-labelledby={headingIdAdvanced}
            onSubmit={(e) => {
              e.preventDefault();
              financialDirectorySubmitData('advanced');
            }}>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Position"
                items={positionsLookup}
                value={position}
                onChange={(e) => {
                  setPosition(e);
                  !e.length ? setCompanySearchType('') : !companySearchType && setCompanySearchType('all');
                }}
              />
            </div>

            <div style={{ marginTop: '24px' }}>
            <RadioList
                  aria-label="Company search type"
                  value={companySearchType}
                  direction='row'
                  onChange={setCompanySearchType}
                  disabled={position.length < 1}
                  options={[
                    {
                      value: 'lead-company',
                      label: 'Lead Company',
                    },
                    {
                      value: 'current-company',
                      label: 'Current Company(s)',
                    },
                    {
                      value: 'previous-company',
                      label: 'Previous Company(s)',
                    },
                    {
                      value: 'all',
                      label: 'All',
                    },
                  ]}
                />
            </div>

            <div style={{ marginTop: '32px' }}>
              <label htmlFor="Department/Functional Heads" style={{ fontWeight: 500 }}>Department/Functional Heads</label>

              <div style={{ marginTop: '10px' }}>
                <RadioList
                  flex
                  aria-label="Department/Functional Heads"
                  value={deptHeads}
                  onChange={setDeptHeads}
                  options={[
                    {
                      value: '33',
                      label: 'human resources',
                    },
                    {
                      value: '35',
                      label: 'information technology',
                    },
                    {
                      value: '66',
                      label: 'operations',
                    },
                    {
                      value: '67',
                      label: 'purchasing',
                    },
                    {
                      value: '34',
                      label: 'sales & marketing ',
                    },
                  ]}
                />
                <Text color="light" size="sm">
                  (FP Directory of Directors companies only)
                </Text>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label htmlFor="gender" style={{ fontWeight: 500 }}>Gender</label>
              <div style={{ marginTop: '10px' }}>
                <RadioList
                  flex
                  aria-label="Gender"
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      value: 'M',
                      label: 'Male',
                    },
                    {
                      value: 'F',
                      label: 'Female',
                    },
                    {
                      value: '',
                      label: 'Either',
                    },
                  ]}
                />
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label style={{ fontWeight: 500 }}>Company Type</label>

              <div style={{ marginTop: '10px' }}>
                <input
                  type='checkbox'
                  id='chk_company_type_public'
                  checked={companyTypePublic}
                  value='public'
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_company_type_public',
                      `${e.target.checked}`
                    );
                    setCompanyTypePublic(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_company_type_public'
                >
                  Public
                </label>

                <input
                  type='checkbox'
                  id='chk_company_type_private'
                  checked={companyTypePrivate}
                  value='private'
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_company_type_private',
                      `${e.target.checked}`
                    );
                    setCompanyTypePrivate(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_company_type_private'
                >
                  Private
                </label>

                <input
                  type='checkbox'
                  id='chk_company_type_fp500'
                  checked={companyTypeFP500}
                  value='fp500'
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_company_type_fp500',
                      `${e.target.checked}`
                    );
                    setCompanyTypeFP500(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_company_type_fp500'
                >
                  FP&nbsp;500
                </label>

                <input
                  type='checkbox'
                  id='chk_company_type_nfp'
                  checked={companyTypeNotForProfit}
                  value='np'
                  onChange={(e) => {
                    localStorage.setItem(
                      'chk_company_type_nfp',
                      `${e.target.checked}`
                    );
                    setCompanyTypeNotForProfit(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor='chk_company_type_nfp'
                >
                  Not-for-Profit
                </label>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <label style={{ fontWeight: 500 }} htmlFor="target_fp_industry">FP Industry</label>
              <div>
                <select
                  id="target_fp_industry"
                  name="target_fp_industry"
                  value={targetFPIndustry}
                  onChange={(e) => {
                    setTargetFPIndustry(e.target.value);
                  }}
                  style={{ marginTop: '10px' }}
                >
                  {fpIndustryLookup.map((l) => (
                    <option value={l.id}>{l.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label='S&amp;P GICS'
                items={gicsLookup}
                value={gicCodes}
                onChange={setGicCodes}
                width='full'
              />
            </div>

            <div style={{ marginTop: '24px' }}>
              <Row>
                <MultiSelectFieldChips
                  label='NAICS Codes'
                  items={naicsLookupCodes}
                  value={naicsCodes}
                  onChange={setNaicsCodes}
                  width='full'
                />

                <div style={{ paddingTop: '30px', paddingLeft: '20px' }}>
                  <RadioList
                  flex
                  aria-label="NAICS Type"
                  value={naicsPrimary}
                  onChange={setNaicsPrimary}
                  options={[
                      {
                        value: 'primary',
                        label: 'Primary NAICS of company',
                      },
                      {
                        value: 'all',
                        label: 'All NAICS of company',
                      }
                    ]}
                  />
                </div>
              </Row>
            </div>

            <div style={{ marginTop: '24px' }}>
              <Row>
                <MultiSelectFieldChips
                  label='SIC Codes'
                  items={sicLookup}
                  value={sicCodes}
                  onChange={setSicCodes}
                  width='full'
                />
                <div style={{ paddingTop: '30px', paddingLeft: '20px' }}>
                  <RadioList
                    flex
                    aria-label="NAICS Type"
                    value={sicPrimary}
                    onChange={setSicPrimary}
                    options={[
                      {
                        value: 'primary',
                        label: 'Primary NAICS of company',
                      },
                      {
                        value: 'all',
                        label: 'All NAICS of company',
                      }
                    ]}
                  />
                </div>
              </Row>
            </div>

            <TextField mt="lg" label="City" value={city} onChange={setCity} />

            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Province"
                items={provincesLookup.map(g => g.name)}
                value={provinces}
                onChange={setProvinces}
              />
            </div>

            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Country*"
                items={countriesLookup.map(g => g.name)}
                value={countries}
                onChange={setCountries}
              />
              <Text color="light" mt="xxs">
                *  Primary business address
              </Text>
            </div>

            <Heading level="3" mt="lg" size="lg" mb="md">Education</Heading>
            <div>
              <MultiSelectFieldChips
                label="School"
                items={schoolsLookup.map(g => g.name)}
                value={school}
                onChange={(e) => {
                  setSchool(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Degree"
                items={degreesLookup.map(g => g.name)}
                value={degree}
                onChange={(e) => {
                  setDegree(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
            </div>

            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Specialty"
                items={specialtiesLookup.map(g => g.name)}
                value={speciality}
                onChange={(e) => {
                  setSpeciality(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Fellowship/Association"
                items={fellowshipsLookup.map(g => g.name)}
                value={fellowship}
                onChange={(e) => {
                  setFellowship(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
            </div>

            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>
        <Tab label="Connectors">
          <Heading id="connectors" level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Directory of Directors - Connectors for Person
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Information on 28,000 directors and executives of Canadian public and private companies from the Financial Post Directory of Directors publication and Financial Post Corporate Surveys, listing Canadian business people and the directorships and offices they hold past and present, primary business address and contact information, education and associations.
          </Text>

          <form
            aria-labelledby="connectors"
            onSubmit={(e) => e.preventDefault()}
          >
            <Tiles columns={[1, 1]}>
              <div>
                <TextField
                  mt="lg"
                  label="Person Name"
                  value={personName}
                  onChange={setPersonName}
                />
                <Text mt="xxs" color="light">
                  (a search for 'John Smith' will also retrieve 'John F. Smith' or
                  'John Frederick Smith')
                </Text>

                <Button mt='lg' label='Search' mb={'lg'} type='submit' onClick={() => ConnectorssubmitData()} />
              </div>
            </Tiles>
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>
      </Tabs>
    </Page>
  );
}
