/* eslint-disable */
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { Table } from '../../../components/Table';
import { Card } from '../../../components/Card';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { CommonSearchSelectedResult } from '../../../components/CommonSearchSelectedResult';
import { useId, useQueryParams } from '../../../hooks';
import { useExternalDatabasesSearchResults } from '../../../data';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { LoadingScreenReaderMessage } from '../../../components/LoadingScreenReaderMessage';
import { BulletedList } from '../../../components/BulletedList';
import { ListItem } from '../../../components/ListItem';
import { HideOnPrint } from 'components/HideOnMobile';
import { Link } from 'react-router-dom';
import {
  useNumberOfRows,
  useSetNumberOfRows,
} from '../../../containers/AuthProvider';
import { externalDbBySlug } from '../../../data/searchDatabases';
import { stringify } from 'query-string';
import { legalTypeLookup, searchTypePredecessorLookup, sortOptionLookup } from 'data/lookups';

export function ExternalDatabasesSearchResultsPage (props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();

  const params = useParams<{ dbCode: string }>();

  const queryParams = useQueryParams(
    [
      'companyName',
      'searchType',
      'legalType',
      'legalRecordType',
      'city',
      'address',
      'provinces',
      'directorName',
      'organizationType',
      'areaCode',
      'sicCode',
      'naicsCode',
      'employeesGT',
      'employeesLT',
      'membersGT',
      'membersLT',
      'sortOption',
      'locationType',
      'companyType',
      'salesVolumeLT',
      'salesVolumeGT',
      'dateInfo',
      'page'
    ],
    { onlyStrings: true }
  );

  const dbFullName =
    externalDbBySlug(params.dbCode)?.name ||
    `External Database ${params.dbCode}`;

  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const getAccessToken = useGetAccessToken();
  const searchResults = useExternalDatabasesSearchResults({
    dbCode: params.dbCode || '',
    companyName: queryParams.companyName || undefined,
    searchType: queryParams.searchType || undefined,
    legalType: queryParams.legalType || undefined,
    legalRecordType: queryParams.legalRecordType || undefined,
    city: queryParams.city || undefined,
    address: queryParams.address || undefined,
    provinces: queryParams.provinces || undefined,
    directorName: queryParams.directorName || undefined,
    organizationType: queryParams.organizationType || undefined,
    areaCode: queryParams.areaCode || undefined,
    sicCode: queryParams.sicCode || undefined,
    naicsCode: queryParams.naicsCode || undefined,
    employeesGT: queryParams.employeesGT || undefined,
    employeesLT: queryParams.employeesLT || undefined,
    membersGT: queryParams.membersGT || undefined,
    membersLT: queryParams.membersLT || undefined,
    sortOption: queryParams.sortOption || undefined,
    locationType: queryParams.locationType || undefined,
    companyType: queryParams.companyType || undefined,
    salesVolumeLT: queryParams.salesVolumeLT || undefined,
    salesVolumeGT: queryParams.salesVolumeGT || undefined,
    dateInfo: queryParams.dateInfo || undefined,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.peeks.length));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const noResults =
    searchResults.resolvedData && searchResults.resolvedData.peeks.length === 0;
    
    const switchCase = (param: any, value: any) => {
      switch (param) {
        case 'companyName':
          return queryParams.companyName
        // case 'searchType': {
        // 	return queryParams.searchType
        // }
        case 'searchType': {
          /* @ts-ignore */
          const data = value?.split(',');
          return searchTypePredecessorLookup
            .filter((item) => data.includes(item.value))
            .map((item) => item.label)
            .join(', ');
        }
        // case 'legalType': {
        // 	return queryParams.legalType
        // }
        case 'legalType': {
          /* @ts-ignore */
          const data = value?.split(',');
          return legalTypeLookup
            .filter((item) => data.includes(item.value))
            .map((item) => item.label)
            .join(', ');
        }
        case 'legalRecordType': {
          return queryParams.legalRecordType
        }
        case 'city': {
          return queryParams.city
        }
        case 'address': {
          return queryParams.address
        }
        case 'provinces': {
          return queryParams.provinces
        }
        case 'directorName': {
          return queryParams.directorName
        }
        case 'organizationType': {
          return queryParams.organizationType
        }
        case 'areaCode': {
          return queryParams.areaCode
        }
        case 'sicCode': {
          return queryParams.sicCode
        }
        case 'naicsCode': {
          return queryParams.naicsCode
        }
        case 'employeesGT': {
          return queryParams.employeesGT
        }
        case 'employeesLT': {
          return queryParams.employeesLT
        }
        case 'membersGT': {
          return queryParams.membersGT
        }
        case 'membersLT': {
          return queryParams.membersLT
        }
        // case 'sortOption': {
        // 	return queryParams.sortOption
        // }
        case 'sortOption': {
          /* @ts-ignore */
          const data = value?.split(',');
          return sortOptionLookup
            .filter((item) => data.includes(item.value))
            .map((item) => item.label)
            .join(', ');
        }
        case 'locationType': {
          return queryParams.locationType
        }
        case 'companyType': {
          return queryParams.companyType
        }
        case 'salesVolumeLT': {
          return queryParams.salesVolumeLT
        }
        case 'salesVolumeGT': {
          return queryParams.salesVolumeGT
        }
        case 'dateInfo': {
          return queryParams.dateInfo;
        }
        default:
          return [];
      }
    }

    const handleSetPage = (page: number) => {
      const newPage = page + 1;
      const replaceUrl = `/external-databases/results/${params.dbCode}`;
      const qs = {
        ...queryParams,
        page: newPage,
      };
      history.replace(`${replaceUrl}?${stringify(qs)}`, {
        ...(history.location.state as object),
      });
      setPage(newPage);
    };
    
    const previewSelectedResults = () => {
      props.history.push({
        pathname: `/external-databases/details/${params.dbCode}/`,
        state: {
          keys: selectedKeys,
        },
      });
    }
    async function exportCsvResults () {
      if (params.dbCode === 'equifax-commerical-law-record') {
        props.history.push({
          pathname:  '/external-databases/csv-export/',
          state: {
            keys: selectedKeys,
          },
        });
      } else {
        const paramsCSV = {
          keys: selectedKeys
        }
        let urlApiEndPoint = '';
        switch (params.dbCode) {
          case 'encyclopedia-of-associations':
            urlApiEndPoint = '/doss/tmga_export_api.php';
            break;
          case 'wards-business-directory':
            urlApiEndPoint = '/doss/tmgw_export_api.php';
            break; 
          case 'candian-corporate-names':
            urlApiEndPoint = '/doss/cccn_export_api.php';
            break;
          case 'canadian-federal-coporations-and-directors':
            urlApiEndPoint = '/doss/dcfc_export_api.php';
            break;
        }
        const url = await getExportCSVurl(paramsCSV, urlApiEndPoint, getAccessToken);
        if (url.length > 0) {
          window.location.href = config.apiUrl + url;
        }
      }
    }
    return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="lg">
        External Databases - {dbFullName} - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? 'xxl' : 'lg'}
          link={`/external-databases/search/${params.dbCode}`}
        />
      </HideOnPrint>
      {noResults && (
        <>
          <CommonSearchSelectedResult
            searchCriteria={queryParams}
            history={props.history.location.state}
          />
          <Text size="xl" mb="lg" weight="medium">
            0 results found
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found.
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}			
      
      {!noResults && (
        <Card padding="none">
          <Table
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            onPageChange={handleSetPage}
            page={page}
            // numTotalRows={searchResults.data?.hit_count}
            numTotalRows={searchResults.resolvedData?.hit_count}
            numRowsPerPage={numberOfRows}
            loading={loading}
            expectedColumns={2}
            expectedRows={numberOfRows}
            isEmpty={!searchResults.resolvedData}
            queryParams={queryParams}
            nbSelectedRows={selectedKeys.length}
            print={true}
            preview={previewSelectedResults}
            export={exportCsvResults}
            onNumberOfRowChange={(latestNumberOfRows) => {
              setNumberOfRows(latestNumberOfRows);
              setNumberOfRowsInContext(latestNumberOfRows);
            }}
            history={props.history.location.state}
            switchCase={switchCase}
          >
            <Table.Head>
              <Table.Row>
                <Table.SelectAllHeaderCell selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} companyKeys={searchResults.resolvedData?.peeks.map((r) => r.unique_key)}></Table.SelectAllHeaderCell>
                <Table.HeaderCell>
                  <Text>Company</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text>Details</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults.resolvedData?.peeks.map((p, i) => (
                <Table.Row hidePrint={selectedKeys.filter((key) => key === p.unique_key).length === 0}>
                  <Table.CellSelect index={(page - 1) * numberOfRows + i} companyKey={p.unique_key} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></Table.CellSelect>
                  <Table.Cell minWidth="md">
                    <Link
                      to={`/external-databases/details/${params.dbCode}/${p.unique_key}`}
                      className="black-text"
                    >
                      <Text>{p.headline}</Text>
                    </Link>
                  </Table.Cell>

                  <Table.Cell minWidth="md">
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{ __html: p.lead || '' }}
                      ></span>
                    </Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      )}
    </Page>
  );
}
