import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useFixedIncomeInfo, useCompany } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { ApartmentIcon } from '../../../icons/ApartmentIcon';
import { Box, BoxProps } from '../../../components/Box';
import { Tiles } from '../../../components/Tiles';
import ExpandAll from 'components/ExpandSettings';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';

import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';
import { FpCoverageSideNav } from '../../../containers/FpCoverageSideNav';
import { FpCoverageTopNav } from '../../../containers/FpCoverageTopNav';

export function FixedIncomeDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data: theCompany } = useCompany(key);
    const { data: fiInfo, isLoading } = useFixedIncomeInfo(key);
    const company = fiInfo?.data.fpsecurity.fpcompany;
    const contactAddress =
      company?.contactaddresses?.contactaddress &&
      company.contactaddresses?.contactaddress.length >
        0
        ? company.contactaddresses.contactaddress[0]
        : undefined;
    const headingId = useId();

    companies.push({ comp: theCompany, fiInfo: fiInfo, isLoading: isLoading, contactAddress: contactAddress, headingId: headingId });
  });
  const authContext = useAuthContext();
  const defaultExpand = authContext?.expand_collapse_settings['fixed-income'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));
  /*
  const { data: theCompany, isLoading: isLoadingCompany } = useCompany(
    params.docKey
  );
  const { data: fiInfo, isLoading } = useFixedIncomeInfo(params.docKey);
  const headingId = useId();
  const company = fiInfo?.data.fpsecurity.fpcompany;
  const contactAddress =
    company?.contactaddresses?.contactaddress &&
    company.contactaddresses?.contactaddress.length >
      0
      ? company.contactaddresses.contactaddress[0]
      : undefined;
  */
  return (
    <Page aria-label="Financial Post Fixed Income" padding="none" width="full">
      {companies.length === 1 &&
      <FpCoverageTopNav
        isLoading={companies[0].isLoading}
        company={companies[0].comp}
        hideFixedIncome={true}
      />
      }
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="lg" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Financial Post Fixed Income
            </Text>
            <Text
              mt="sm"
              color="heading"
              loading={element.isLoading}
              loadingWidth={200}
              size={['xl', 'xl', 'xxl']}
              weight="semi-bold"
            >
              {element.fiInfo?.data.fpsecurity.fpcompany.names.legalname}
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div style={{ flexGrow: 1 }}>
            {i === 0 &&
            <HideOnPrint>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
                  <PrintFriendly></PrintFriendly>
                </div>
                <ExpandAll setExpand={setExpand} expand={expand} modalname='fixed-income' />
              </div>
            </HideOnPrint>
            }
            {(element.contactAddress || element.isLoading) && (
              <Card mb="lg">
                <Accordion openOnLoad={expand}>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    Company Information
                  </Accordion.Heading>
                  <Accordion.Content>
                    {/* <Tiles columns={[1,2]}> */}
                    <div style={{ minWidth: '550px' }}>
                      <CompanyInfoSection
                        title="Company"
                        icon={<ApartmentIcon />}
                      >
                        <Text loading={element.isLoading} loadingWidth={200} mb="xxs">
                          {element.fiInfo?.data.fpsecurity.fpcompany.names.legalname}
                        </Text>
                        <Text loading={element.isLoading} loadingWidth={150} mb="xxs">
                          {element.contactAddress?.address.line1},&nbsp;
                          {element.contactAddress?.address.city},&nbsp;
                          {element.contactAddress?.address.province} &nbsp;
                          {element.contactAddress?.address.postalcode}
                        </Text>
                        <Text loading={element.isLoading} mb="xxs">
                          {element.contactAddress?.address.city},{' '}
                          {element.contactAddress?.address.province}
                        </Text>
                        <Text loading={element.isLoading} mb="xxs">
                          {element.contactAddress?.address.postalcode}
                        </Text>
                        {/* {(isLoading || contactAddress?.contact?.phone) && <Text loading={isLoading} mb="xxs">Tel: {contactAddress?.contact?.phone}</Text>} */}
                      </CompanyInfoSection>
                    </div>
                    {/* </Tiles> */}
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            )}

            {element.fiInfo?.htmlFixedIncomeDetails &&
              element.fiInfo?.htmlFixedIncomeDetails?.body.innerHTML.length > 0 && (
                <Card mb="lg">
                  <Accordion openOnLoad={expand}>
                    <Accordion.Heading size="xl" level="2" weight="semi-bold">
                      Security Information
                    </Accordion.Heading>
                    <Accordion.Content>
                      <Tiles columns={[1, 1]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                            element.fiInfo?.htmlFixedIncomeDetails?.body.innerHTML ||
                              '',
                          }}
                        />
                      </Tiles>
                    </Accordion.Content>
                    <Accordion.Footer size="xl" level="2" weight="semi-bold">
                      <div></div>
                    </Accordion.Footer>
                  </Accordion>
                </Card>
              )}
          </div>
          {companies.length === 1 &&
          <FpCoverageSideNav
            isLoading={element.isLoading}
            company={element.comp}
            hideFixedIncome={true}
          />
          }
        </Row>
      </Page>
      )}
    </Page>
  );
}

function CompanyInfoSection (props: {
  mt?: BoxProps['mt'];
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
}) {
  const headingId = useId();

  return (
    <Row alignItems="flex-start" mt={props.mt}>
      <div aria-hidden style={{ minWidth: '24px' }}>
        {props.icon}
      </div>
      <Box as="section" aria-labelledby={headingId} ml="sm">
        <Heading mt="xxs" id={headingId} size="lg" level="3" mb="sm">
          {props.title}
        </Heading>
        {props.children}
      </Box>
    </Row>
  );
}
