import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function ArrowRightIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <IconBase
      {...props}
      paths={['M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z']}
    />
  );
}
