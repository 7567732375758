import React from 'react';
import './index.css';

interface IProps {
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const ToggleSwitch: React.FC<IProps> = ({ checked, onChange }) => {
  return (
    <div className="switch switch--default d-flex">
      <div
        className={`switch-toggle switch-toggle--${checked ? 'on' : 'off'}`}
        onClick={() => onChange(!checked)}
      ></div>
    </div>
  );
};
