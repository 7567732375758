import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { Optional } from 'utility-types';
import queryString from 'query-string';

/**
 * @see https://usehooks.com/useDebounce/
 */
export function useDebounce<T extends any> (value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

let idCounter = 1;
export function useId (): string {
  const idRef = useRef<null | number>(null);
  if (!idRef.current) {
    idCounter++;
    idRef.current = idCounter;
  }

  return `id_${idRef.current}`;
}

/**
 * @see https://stackoverflow.com/a/64056896
 */
export function useQueryParams<
  T extends string[],
  O extends { onlyStrings: boolean }
> (
  keys: [...T],
  options: O
): {
  [key in T[number]]: true extends O['onlyStrings']
    ? string | null | undefined
    : string | string[] | null | undefined;
} {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  return keys.reduce((acc, curr) => {
    if (options.onlyStrings) {
      acc[curr] = Array.isArray(parsed[curr]) ? null : parsed[curr];
    } else {
      acc[curr] = parsed[curr];
    }

    return acc;
  }, {} as { [key: string]: unknown }) as any;
}

export function usePrevious<T> (value: T): T {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useOnFocusChange (callback: () => void) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    function onFocusChange () {
      callbackRef.current();
    }
    window.document.addEventListener('focus', onFocusChange, true);

    return () => {
      window.document.removeEventListener('focus', onFocusChange, true);
    };
  }, []);
}

export function useOnClickAway (
  outsideEl: HTMLElement | null,
  callback: () => void
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    function onClickHandler (e: MouseEvent) {
      let clickIsOutside = false;
      try {
        clickIsOutside = Boolean(
          outsideEl?.contains && !outsideEl.contains(e.target as any)
        );
      } catch (err) {}

      if (clickIsOutside) {
        callbackRef.current();
      }
    }
    window.document.addEventListener('click', onClickHandler, true);

    return () => {
      window.document.removeEventListener('click', onClickHandler, true);
    };
  }, [outsideEl]);
}

export const usePreviousStorageData = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
