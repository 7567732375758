/**
 * Accessible Tabs and Tab components.
 * TODO: Still need to style these to look like the mock
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useId } from '../../hooks';
import { clearButtonStyles } from '../../utils/style';
import { Text } from '../Text';
import { lighten } from 'polished';
import { useEffect } from 'react';

const TabList = styled.div({
	position: 'relative',
	borderBottom: '1px solid #EAEAEA',
});

const TabButton = styled.button((props) => ({
	...clearButtonStyles,
	outline: 'none',
	paddingLeft: props.theme.spacing.xl,
	paddingRight: props.theme.spacing.xl,
	paddingTop: props.theme.spacing.md,
	paddingBottom: props.theme.spacing.md,
	position: 'relative',
	overflow: 'hidden',
	zIndex: 0,
	[`@media(max-width: ${props.theme.breakpoints.sm})`]: {
		paddingLeft: props.theme.spacing.sm,
		paddingRight: props.theme.spacing.sm,
		paddingTop: props.theme.spacing.sm,
		paddingBottom: props.theme.spacing.sm,
	},
	'&:before': {
		zIndex: -1,
		position: 'absolute',
		left: '15%',
		content: "''",
		backgroundColor: lighten(0.45, props.theme.palette.primary.main),
		width: '70%',
		height: 0,
		marginTop: '-30%',
		paddingBottom: '70%',
		borderRadius: '50%',
		transform: 'scale(0)',
		transition: 'all 0s',
		opacity: 0,
	},
	'&.focus-visible:before': {
		transform: 'scale(1)',
		opacity: 1,
		transition: 'all 0.2s',
	},
}));

const TabUnderline = styled.div((props) => ({
	position: 'absolute',
	height: '2px',
	left: '16px',
	backgroundColor: props.theme.palette.primary.main,
	transition: 'all 0.3s',
	marginTop: -1,
}));

const TabPanel = styled.div((props) => ({
	outline: 'none',
	border: '2px solid transparent',
	borderRadius: '4px',
	'&.focus-visible': {
		// mdn suggests making the tab panel focusable when pressing
		// keyboard tab button.  This border is added to make it obvious
		// where the focus is
		border: `2px solid ${props.theme.palette.primary.main}`,
	},
}));

const TabPanelsWrapper = styled.div((props) => ({
	padding: `calc(${props.theme.spacing.md} - 2px)`, // subtract border size
}));

export function Tabs (props: {
	'aria-label'?: string;
	selectedTab?: number;
	children: React.ReactNode;
	tabsClassName?: string;
}) {
	const [currentTab, setCurrentTab] = React.useState<any>(0);
	const labels = getLabelsFromChildren(props.children);
	const tabsId = useId();
	const tabButtonRefs = useMultipleRefs(labels.length);
	const underlineRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!props?.selectedTab) return;
		setCurrentTab(props.selectedTab);
	}, [props.selectedTab]);

	useUnderlineCurrentTab({
		underlineRef,
		tabButtonRefs,
		currentTab,
		labels,
	});

	return (
		<div className={props.tabsClassName}>
			<TabList role="tablist" aria-label={props['aria-label']} className="d-flex">
				{labels.map((label, idx) => (
					<>
						<TabButton
							role="tab"
							defaultValue={currentTab}
							aria-selected={currentTab === idx}
							id={`${tabsId}_tab_${idx}`}
							aria-controls={`${tabsId}_tab_panel_${idx}`}
							tabIndex={currentTab === idx ? 0 : -1}
							ref={tabButtonRefs[idx]}
							onClick={() => {
								setCurrentTab(idx);
							}}
							onKeyDown={(e) => {
								if (e.key === 'ArrowRight') {
									if (idx + 1 > labels.length - 1) {
										tabButtonRefs[0].current.focus();
										tabButtonRefs[0].current.click();
									} else {
										tabButtonRefs[idx + 1].current.focus();
										tabButtonRefs[idx + 1].current.click();
									}
								}

								if (e.key === 'ArrowLeft') {
									if (idx - 1 < 0) {
										tabButtonRefs[labels.length - 1].current.focus();
										tabButtonRefs[labels.length - 1].current.click();
									} else {
										tabButtonRefs[idx - 1].current.focus();
										tabButtonRefs[idx - 1].current.click();
									}
								}
							}}
						>
							<Text
								size="lg"
								weight="semi-bold"
								color={currentTab === idx ? 'primary' : 'main'}
							>
								{label}
							</Text>
						</TabButton>
						<TabUnderline ref={underlineRef} />
					</>
				))}
			</TabList>
			<TabPanelsWrapper>
				{React.Children.toArray(props.children)
					.filter(Boolean)
					.map((child, idx) => (
						<TabPanel
							id={`${tabsId}_tab_panel_${idx}`}
							role="tabpanel"
							tabIndex={0}
							aria-labelledby={`${tabsId}_tab_${idx}`}
							hidden={currentTab !== idx}
						>
							{child}
						</TabPanel>
					))}
			</TabPanelsWrapper>
		</div>
	);
}

export function Tab (props: { label: string; children: React.ReactNode }) {
	return <>{props.children}</>;
}

Tab.__internalName = 'Tab';

function getLabelsFromChildren (children: React.ReactNode): string[] {
	return React.Children.toArray(children)
		.map((child) => {
			if (React.isValidElement(child)) {
				if (
					process.env.NODE_ENV === 'development' &&
					(child.type as any).__internalName !== 'Tab'
				) {
					throw new Error('All children of Tabs must be Tab');
				}

				if (!child.props.label) {
					throw new Error('Tab missing label');
				}

				return child.props.label.toString() as string;
			}
		})
		.filter(Boolean) as string[];
}

function useUnderlineCurrentTab (params: {
	currentTab: number;
	labels: Array<string | undefined>;
	underlineRef: React.MutableRefObject<HTMLDivElement | null>;
	tabButtonRefs: React.MutableRefObject<HTMLButtonElement>[];
}) {
	const { currentTab, underlineRef, tabButtonRefs, labels } = params;

	React.useLayoutEffect(() => {
		const offsetLeft = tabButtonRefs
			.slice(0, currentTab)
			.reduce((acc, curr) => acc + curr.current.clientWidth, 0);

		if (underlineRef.current) {
			const horizontalPadding = 16;
			const minWidth = 10;
			if (tabButtonRefs[currentTab]?.current) {
				const currentButtonWidth =
					tabButtonRefs[currentTab].current.clientWidth || 0;
				const newLineWidth = Math.max(
					currentButtonWidth - horizontalPadding * 2,
					minWidth
				);
				underlineRef.current.style.width = `${newLineWidth}px`;
				underlineRef.current.style.left = `${offsetLeft + horizontalPadding}px`;
			}
		}
	}, [currentTab, labels.join(','), tabButtonRefs]);
}

function useMultipleRefs (numRefs: number) {
	const [elRefs, setElRefs] = React.useState<
		React.MutableRefObject<HTMLButtonElement>[]
	>([]);

	React.useLayoutEffect(() => {
		setElRefs((elRefs) =>
			Array(numRefs)
				.fill(null)
				.map((_, i) => elRefs[i] || React.createRef())
		);
	}, [numRefs]);

	return elRefs;
}
