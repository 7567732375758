import * as React from 'react';
import styled from 'styled-components';

import { FPRow } from '../../../components/FPRow/FPRow';
import { FPCol } from '../../../components/FPCol/FPCol';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { Text } from '../../../components/Text';
import { Link } from '../../../components/Link';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 2px 5px 2px rgba(215, 215, 215, 0.5);
  border-radius: 8px;
  padding: 1rem;
`;

const P = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  margin: 0;
`;

export function LeadListGeneratorIndexPage () {
  const headingId = useId();

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
        Lead List Generator
      </Heading>
      <Text mt="md" lineHeight="md">
        Lead List Generator is a powerful user-defined search tool for
        organizing and targeting direct mail campaigns, prospecting and managing
        sales territories quickly and easily. Search our sources for contact
        information and download your results to Excel (CSV format).
      </Text>
      <Text mt="md" mb="md">
        Choose a data source to search on. Search results are limited to 1,000
        results per search.
      </Text>
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <div>
              <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
                Financial Post Corporate Surveys
              </Heading>
              <Text lineHeight="md">
                <P>
                  Corporate address information for all publicly traded
                  Canadian companies.
                </P>
              </Text>
            </div>
            <Link to="/lead-list-generator/search/fp-corporate-surveys" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <div>
              <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
                Cengage Learning Gale - Encyclopedia of Associations
              </Heading>
              <Text lineHeight="md">
                <P>
                  Includes profiles of 3,000 Canadian associations extracted
                  from the Gale database the{' '}
                  <em>
                    Encyclopedia of Associations: International Organizations
                  </em>
                  . The profiles provide addresses and descriptions of
                  professional societies, trade associations, labor unions,
                  cultural and religious organizations, fan clubs, and other
                  groups of all types.
                </P>
              </Text>
            </div>
            <Link to="/lead-list-generator/search/encyclopedia-of-associations" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <div>
              <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
                Financial Post Directory of Directors
              </Heading>
              <Text lineHeight="md">
                <P>
                  Name and address information on 28,000 Canadian directors
                  and executives of Canadian companies.
                </P>
              </Text>
            </div>
            <Link to="/lead-list-generator/search/fp-directory-of-directors" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <div>
              <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
                Cengage Learning Gale - Ward's Business Directory
              </Heading>
              <Text lineHeight="md">
                <P>
                  Includes profiles of 9,000 Canadian companies extracted from
                  the Gale database{' '}
                  <em>
                    Ward's Business Directory of Private and Public Companies
                    in Canada and Mexico
                  </em>
                  . The profiles provide current company information of
                  Canadian firms, most of which are private companies.
                </P>
              </Text>
            </div>
            <Link to="/lead-list-generator/search/wards-business-directory" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
      {/*
      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
          <Card>
            <div>
              <Heading size={['lg', 'lg', 'lg']} level="2" mb="md">
                Owen Media Partners - ProFile Canada
              </Heading>
              <Text lineHeight="md">
                <P>
                  Contains marketing and business intelligence on Canada's
                  largest 92,000 companies with a focus on privately owned
                  businesses. Each record includes up to 21 functional
                  contacts.
                </P>
              </Text>
            </div>
            <Link to="/lead-list-generator/search/pro-file-canada" className="linkSearchCard">
              <Text size="lg" alignX="right" lineHeight="md">
                <span className="linkSearch">Search</span>
                <span className="linkArrow">&nbsp;&rsaquo;</span>
              </Text>
            </Link>
          </Card>
        </FPCol>
      </FPRow>
      */}
    </Page>
  );
}
