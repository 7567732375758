import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { MultiSelectField } from '../../../components/MultiSelectField';
import { MultiSelectFieldChips } from '../../../components/MultiSelectFieldChips';
import { Row } from '../../../components/Row';
import { Tabs, Tab } from '../../../components/Tabs';
import { CheckOptions } from '../../../components/CheckOptions';
import { DateRangePicker } from '../../../components/DateRangePicker';
import { getPredecessorDefunctSearchResults, deleteSearch, useNaicsCodes } from '../../../data';
import { dateFilter } from '../../../utils';
import ClearButton from 'components/ClearButton';
import { useSavedSearches, useRefreshSavedSearches, useGetAccessToken } from '../../../containers/AuthProvider';
import { TrashIcon } from '../../../icons/TrashIcon';
import { StarIcon } from '../../../icons/StarIcon';
import toast, { Toaster } from 'react-hot-toast';
import { CheckIcon } from '../../../icons/CheckIcon';
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';
import moment from 'moment';
import {
  gicsLookup,
  sicLookup,
  fpIndustryLookup,
  incorporationLocationLookup,
  naicsCodeTypeLookup,
  sicCodeTypeLookup,
  historyMatchTypeLookup
} from '../../../data/lookups';
import { formatRelativeWithOptions } from 'date-fns/esm/fp';

export function PredecessorDefunctSearchPage () {
  const history = useHistory();
  const headingId = useId();
  const minDate = new Date(1822, 0, 1);
  const [companyName, setCompanyName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');
  const [searchPdFPIndustry, setSearchPdFPIndustry] = React.useState('');
  const [searchPdGics, setSearchPdGics] = React.useState<string[]>([]);
  const [searchPdNaics, setSearchPdNaics] = React.useState<string[]>([]);
  const [searchPdSic, setSearchPdSic] = React.useState<string[]>([]);
  const [selectedSavedSearch, setSelectedSavedSearch] = React.useState('');
  const [newSavedSearchName, setNewSavedSearchName] = React.useState('');
  const [statusActive, setStatusActive] = React.useState(false);
  const [statusInactive, setStatusInactive] = React.useState(false);
  const [searchPdNameChange, setSearchPdNameChange] = React.useState(false);
  const [searchPdMergedNewName, setSearchPdMergedNewName] = React.useState(false);
  const [searchPdMergedSameName, setSearchPdMergedSameName] = React.useState(false);
  const [searchPdMergedAmalgamated, setSearchPdMergedAmalgamated] = React.useState(false);
  const [searchPdPrivatizedNewName, setSearchPdPrivatizedNewName] = React.useState(false);
  const [searchPdPrivatizedAcquired, setSearchPdPrivatizedacquired] = React.useState(false);
  const [searchPdSucceeded, setSearchPdSucceeded] = React.useState(false);
  const [searchPdBankruptLiquidated, setSearchPdBankruptLiquidated] = React.useState(false);
  const [searchPdCharterCancelled, setSearchPdCharterCancelled] = React.useState(false);
  const [searchPdCharterRevived, setSearchPdCharterRevived] = React.useState(false);
  const [searchPdCharterSurrendered, setSearchPdCharterSurrendered] = React.useState(false);
  const [searchPdInBankruptcy, setSearchPdInBankruptcy] = React.useState(false);
  const [searchPdInReceivership, setSearchPdInReceivership] = React.useState(false);
  const [searchPdRelisted, setSearchPdRelisted] = React.useState(false);
  const [searchPdStruckFromRegister, setSearchPdStruckFromRegister] = React.useState(false);
  const [searchPdUnderCcaa, setSearchPdUnderCcaa] = React.useState(false);
  const [searchPdWindingUp, setSearchPdWindingUp] = React.useState(false);
  const [searchPdWoundUpLiquidated, setSearchPdWoundUpLiquidated] = React.useState(false);
  const [searchPdContinuance, setSearchPdContinuance] = React.useState(false);
  const [searchPdFormedByAmalgamation, setSearchPdFormedByAmalgamation] = React.useState(false);
  const [searchPdSuccessor, setSearchPdSuccessor] = React.useState(false);
  const [searchPdIncorporationFrom, setSearchPdIncorporationFrom] = React.useState<string[]>([]);
  const [searchPdIncorporationTo, setSearchPdIncorporationTo] = React.useState<string[]>([]);
  const [searchPdInitial, setSearchPdInitial] = React.useState(false);
  const [searchPdCurrent, setSearchPdCurrent] = React.useState(false);
  const [naicsCodeType, setNaicsCodeType] = React.useState('1');
  const [sicCodeType, setSicCodeType] = React.useState('1');
  const [companyHistoryText, setCompanyHistoryText] = React.useState('');
  const [historyMatchType, setHistoryMatchType] = React.useState('exactphrase');

  const [endDatePd, setEndDatePd] = React.useState<Date|undefined>(
    undefined
  );
  const [startDatePd, setStartDatePd] = React.useState<Date|undefined>(
    undefined
  );
  const [naicsLookupCodes, setNaicsLookupCodes] = React.useState<any>([{ id: '', name: 'NAICS Codes' }]);

  const { data } = useNaicsCodes();

  const { searches: savedSearches } = useSavedSearches();
  const { refreshSavedSearches } = useRefreshSavedSearches();

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
    refreshSavedSearches();
  }, []);

  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  /* selectedSavedSearch */
  const prevSelectedSavedSearch = usePreviousStorageData(selectedSavedSearch) || '';
  React.useEffect(() => {
    if (prevSelectedSavedSearch?.length !== selectedSavedSearch.length) {
      localStorage.setItem('selectedSavedSearchPD', JSON.stringify(selectedSavedSearch));
    }
  }, [selectedSavedSearch]);

  /* companyName */
  const company = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (company !== companyName) {
      localStorage.setItem('companyNamePD', JSON.stringify(companyName));
    }
  }, [companyName]);

  /* stockSymbol */
  const stock = usePreviousStorageData(stockSymbol) || '';
  React.useEffect(() => {
    if (stock !== stockSymbol) {
      localStorage.setItem('stockSymbolPD', JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  /* searchPdFPIndustry */
  const searchPdFPIndustryPrev = usePreviousStorageData(searchPdFPIndustry) || '';
  React.useEffect(() => {
    if (searchPdFPIndustryPrev !== searchPdFPIndustry) {
      localStorage.setItem('searchPdFPIndustryPD', JSON.stringify(searchPdFPIndustry));
    }
  }, [searchPdFPIndustry]);

  /* searchPdGics */
  React.useEffect(() => {
    localStorage.setItem('searchPdGicsPD', JSON.stringify(searchPdGics));
  }, [searchPdGics]);

  /* searchPdNaics */
  React.useEffect(() => {
    localStorage.setItem('searchPdNaicsPD', JSON.stringify(searchPdNaics));
  }, [searchPdNaics]);

  /* searchPdSic */
  React.useEffect(() => {
    localStorage.setItem('searchPdSicPD', JSON.stringify(searchPdSic));
  }, [searchPdSic]);

  /* newSavedSearchName */
  const newSavedSearchNamePrev = usePreviousStorageData(newSavedSearchName) || '';
  React.useEffect(() => {
    if (newSavedSearchNamePrev !== newSavedSearchName) {
      localStorage.setItem('newSavedSearchNamePD', JSON.stringify(newSavedSearchName));
    }
  }, [newSavedSearchName]);

  /* newSavedSearchName */
  const statusActivePrev = usePreviousStorageData(statusActive) || '';
  React.useEffect(() => {
    if (statusActivePrev !== statusActive.toString()) {
      localStorage.setItem('statusActivePD', JSON.stringify(statusActive));
    }
  }, [statusActive]);

  /* statusInactive */
  const statusInactivePrev = usePreviousStorageData(statusInactive) || '';
  React.useEffect(() => {
    if (statusInactivePrev !== statusInactive.toString()) {
      localStorage.setItem('statusInactivePD', JSON.stringify(statusInactive));
    }
  }, [statusInactive]);

  /* searchPdNameChange */
  const searchPdNameChangePrev = usePreviousStorageData(searchPdNameChange) || '';
  React.useEffect(() => {
    if (searchPdNameChangePrev !== searchPdNameChange.toString()) {
      localStorage.setItem('searchPdNameChangePD', JSON.stringify(searchPdNameChange));
    }
  }, [searchPdNameChange]);

  /* searchPdMergedNewName */
  const searchPdMergedNewNamePrev = usePreviousStorageData(searchPdMergedNewName) || '';
  React.useEffect(() => {
    if (searchPdMergedNewNamePrev !== searchPdMergedNewName.toString()) {
      localStorage.setItem('searchPdMergedNewNamePD', JSON.stringify(searchPdMergedNewName));
    }
  }, [searchPdMergedNewName]);

  /* searchPdMergedSameName */
  const searchPdMergedSameNamePrev = usePreviousStorageData(searchPdMergedSameName) || '';
  React.useEffect(() => {
    if (searchPdMergedSameNamePrev !== searchPdMergedSameName.toString()) {
      localStorage.setItem('searchPdMergedSameNamePD', JSON.stringify(searchPdMergedSameName));
    }
  }, [searchPdMergedSameName]);

  /* searchPdMergedAmalgamated */
  const searchPdMergedAmalgamatedPrev = usePreviousStorageData(searchPdMergedAmalgamated) || '';
  React.useEffect(() => {
    if (searchPdMergedAmalgamatedPrev !== searchPdMergedAmalgamated.toString()) {
      localStorage.setItem('searchPdMergedAmalgamatedPD', JSON.stringify(searchPdMergedAmalgamated));
    }
  }, [searchPdMergedAmalgamated]);

  /* searchPdPrivatizedNewName */
  const searchPdPrivatizedNewNamePrev = usePreviousStorageData(searchPdPrivatizedNewName) || '';
  React.useEffect(() => {
    if (searchPdPrivatizedNewNamePrev !== searchPdPrivatizedNewName.toString()) {
      localStorage.setItem('searchPdPrivatizedNewNamePD', JSON.stringify(searchPdPrivatizedNewName));
    }
  }, [searchPdPrivatizedNewName]);

  /* searchPdPrivatizedAcquired */
  const searchPdPrivatizedAcquiredPrev = usePreviousStorageData(searchPdPrivatizedAcquired) || '';
  React.useEffect(() => {
    if (searchPdPrivatizedAcquiredPrev !== searchPdPrivatizedAcquired.toString()) {
      localStorage.setItem('searchPdPrivatizedAcquiredPD', JSON.stringify(searchPdPrivatizedAcquired));
    }
  }, [searchPdPrivatizedAcquired]);

  /* searchPdSucceeded */
  const searchPdSucceededPrev = usePreviousStorageData(searchPdSucceeded) || '';
  React.useEffect(() => {
    if (searchPdSucceededPrev !== searchPdSucceeded.toString()) {
      localStorage.setItem('searchPdSucceededPD', JSON.stringify(searchPdSucceeded));
    }
  }, [searchPdSucceeded]);

  /* searchPdBankruptLiquidated */
  const searchPdBankruptLiquidatedPrev = usePreviousStorageData(searchPdBankruptLiquidated) || '';
  React.useEffect(() => {
    if (searchPdBankruptLiquidatedPrev !== searchPdBankruptLiquidated.toString()) {
      localStorage.setItem('searchPdBankruptLiquidatedPD', JSON.stringify(searchPdBankruptLiquidated));
    }
  }, [searchPdBankruptLiquidated]);

  /* searchPdCharterCancelled */
  const searchPdCharterCancelledPrev = usePreviousStorageData(searchPdCharterCancelled) || '';
  React.useEffect(() => {
    if (searchPdCharterCancelledPrev !== searchPdBankruptLiquidated.toString()) {
      localStorage.setItem('searchPdCharterCancelledPD', JSON.stringify(searchPdCharterCancelled));
    }
  }, [searchPdCharterCancelled]);

  /* searchPdCharterRevived */
  const searchPdCharterRevivedPrev = usePreviousStorageData(searchPdCharterRevived) || '';
  React.useEffect(() => {
    if (searchPdCharterRevivedPrev !== searchPdCharterRevived.toString()) {
      localStorage.setItem('searchPdCharterRevivedPD', JSON.stringify(searchPdCharterRevived));
    }
  }, [searchPdCharterRevived]);

  /* searchPdCharterSurrendered */
  const searchPdCharterSurrenderedPrev = usePreviousStorageData(searchPdCharterSurrendered) || '';
  React.useEffect(() => {
    if (searchPdCharterSurrenderedPrev !== searchPdCharterSurrendered.toString()) {
      localStorage.setItem('searchPdCharterSurrenderedPD', JSON.stringify(searchPdCharterSurrendered));
    }
  }, [searchPdCharterSurrendered]);

  /* searchPdInBankruptcy */
  const searchPdInBankruptcyPrev = usePreviousStorageData(searchPdInBankruptcy) || '';
  React.useEffect(() => {
    if (searchPdInBankruptcyPrev !== searchPdInBankruptcy.toString()) {
      localStorage.setItem('searchPdInBankruptcyPD', JSON.stringify(searchPdInBankruptcy));
    }
  }, [searchPdInBankruptcy]);

  /* searchPdInReceivership */
  const searchPdInReceivershipPrev = usePreviousStorageData(searchPdInReceivership) || '';
  React.useEffect(() => {
    if (searchPdInReceivershipPrev !== searchPdInReceivership.toString()) {
      localStorage.setItem('searchPdInReceivershipPD', JSON.stringify(searchPdInReceivership));
    }
  }, [searchPdInReceivership]);

  /* searchPdRelisted */
  const searchPdRelistedPrev = usePreviousStorageData(searchPdRelisted) || '';
  React.useEffect(() => {
    if (searchPdRelistedPrev !== searchPdRelisted.toString()) {
      localStorage.setItem('searchPdRelistedPD', JSON.stringify(searchPdRelisted));
    }
  }, [searchPdRelisted]);

  /* searchPdStruckFromRegister */
  const searchPdStruckFromRegisterPrev = usePreviousStorageData(searchPdStruckFromRegister) || '';
  React.useEffect(() => {
    if (searchPdStruckFromRegisterPrev !== searchPdStruckFromRegister.toString()) {
      localStorage.setItem('searchPdStruckFromRegisterPD', JSON.stringify(searchPdStruckFromRegister));
    }
  }, [searchPdStruckFromRegister]);

  /* searchPdUnderCcaa */
  const searchPdUnderCcaaPrev = usePreviousStorageData(searchPdUnderCcaa) || '';
  React.useEffect(() => {
    if (searchPdUnderCcaaPrev !== searchPdUnderCcaa.toString()) {
      localStorage.setItem('searchPdUnderCcaaPD', JSON.stringify(searchPdUnderCcaa));
    }
  }, [searchPdUnderCcaa]);

  /* searchPdWindingUp */
  const searchPdWindingUpPrev = usePreviousStorageData(searchPdWindingUp) || '';
  React.useEffect(() => {
    if (searchPdWindingUpPrev !== searchPdWindingUp.toString()) {
      localStorage.setItem('searchPdWindingUpPD', JSON.stringify(searchPdWindingUp));
    }
  }, [searchPdWindingUp]);

  /* searchPdWoundUpLiquidated */
  const searchPdWoundUpLiquidatedPrev = usePreviousStorageData(searchPdWoundUpLiquidated) || '';
  React.useEffect(() => {
    if (searchPdWoundUpLiquidatedPrev !== searchPdWoundUpLiquidated.toString()) {
      localStorage.setItem('searchPdWoundUpLiquidatedPD', JSON.stringify(searchPdWoundUpLiquidated));
    }
  }, [searchPdWoundUpLiquidated]);

  /* searchPdContinuance */
  const searchPdContinuancePrev = usePreviousStorageData(searchPdContinuance) || '';
  React.useEffect(() => {
    if (searchPdContinuancePrev !== searchPdContinuance.toString()) {
      localStorage.setItem('searchPdContinuancePD', JSON.stringify(searchPdContinuance));
    }
  }, [searchPdContinuance]);

  /* searchPdFormedByAmalgamation */
  const searchPdFormedByAmalgamationPrev = usePreviousStorageData(searchPdFormedByAmalgamation) || '';
  React.useEffect(() => {
    if (searchPdFormedByAmalgamationPrev !== searchPdFormedByAmalgamation.toString()) {
      localStorage.setItem('searchPdFormedByAmalgamationPD', JSON.stringify(searchPdFormedByAmalgamation));
    }
  }, [searchPdFormedByAmalgamation]);

  /* searchPdSuccessor */
  const searchPdSuccessorPrev = usePreviousStorageData(searchPdSuccessor) || '';
  React.useEffect(() => {
    if (searchPdSuccessorPrev !== searchPdSuccessor.toString()) {
      localStorage.setItem('searchPdSuccessorPD', JSON.stringify(searchPdSuccessor));
    }
  }, [searchPdSuccessor]);

  /* searchPdIncorporationFrom */
  React.useEffect(() => {
    localStorage.setItem('searchPdIncorporationFromPD', JSON.stringify(searchPdIncorporationFrom));
  }, [searchPdIncorporationFrom]);

  /* searchPdIncorporationTo */
  React.useEffect(() => {
    localStorage.setItem('searchPdIncorporationToPD', JSON.stringify(searchPdIncorporationTo));
  }, [searchPdIncorporationTo]);

  /* searchPdInitial */
  const searchPdInitialPrev = usePreviousStorageData(searchPdInitial) || '';
  React.useEffect(() => {
    if (searchPdInitialPrev !== searchPdInitial.toString()) {
      localStorage.setItem('searchPdInitialPD', JSON.stringify(searchPdInitial));
    }
  }, [searchPdInitial]);

  /* searchPdCurrent */
  const searchPdCurrentPrev = usePreviousStorageData(searchPdCurrent) || '';
  React.useEffect(() => {
    if (searchPdCurrentPrev !== searchPdCurrent.toString()) {
      localStorage.setItem('searchPdCurrentPD', JSON.stringify(searchPdCurrent));
    }
  }, [searchPdCurrent]);

  /* naicsCodeType */
  const naicsCodeTypePrev = usePreviousStorageData(naicsCodeType) || '';
  React.useEffect(() => {
    if (naicsCodeTypePrev !== naicsCodeType) {
      localStorage.setItem('naicsCodeTypePD', JSON.stringify(naicsCodeType));
    }
  }, [naicsCodeType]);

  /* sicCodeType */
  const sicCodeTypePrev = usePreviousStorageData(sicCodeType) || '';
  React.useEffect(() => {
    if (sicCodeTypePrev !== sicCodeType) {
      localStorage.setItem('sicCodeTypePD', JSON.stringify(sicCodeType));
    }
  }, [sicCodeType]);

  /* companyHistoryText */
  const companyHistoryTextPrev = usePreviousStorageData(companyHistoryText) || '';
  React.useEffect(() => {
    if (companyHistoryTextPrev !== companyHistoryText) {
      localStorage.setItem('companyHistoryTextPD', JSON.stringify(companyHistoryText));
    }
  }, [companyHistoryText]);

  /* historyMatchType */
  const historyMatchTypePrev = usePreviousStorageData(historyMatchType) || '';
  React.useEffect(() => {
    if (historyMatchTypePrev !== historyMatchType) {
      localStorage.setItem('historyMatchTypePD', JSON.stringify(historyMatchType));
    }
  }, [historyMatchType]);

  /* endDatePd */
  const endDatePdPrev = usePreviousStorageData(endDatePd) || '';
  React.useEffect(() => {
    if (endDatePd && endDatePdPrev !== endDatePd.getTime().toString()) {
      localStorage.setItem('endDatePD', endDatePd.getTime().toString());
    }
  }, [endDatePd]);

  /* startDatePd */
  const startDatePdPrev = usePreviousStorageData(startDatePd) || '0';
  React.useEffect(() => {
    if (startDatePd && startDatePdPrev !== startDatePd.getTime().toString()) {
      localStorage.setItem('startDatePD', startDatePd.getTime().toString());
    }
  }, [startDatePd]);

  const loadLatestEntries = () => {
    const selectedSavedSearchPD = localStorage.getItem('selectedSavedSearchPD');
    if (selectedSavedSearchPD) {
      setSelectedSavedSearch(JSON.parse(selectedSavedSearchPD));
    }
    const companyNamePD = localStorage.getItem('companyNamePD');
    if (companyNamePD) {
      setCompanyName(JSON.parse(companyNamePD));
    }
    const stockSymbolPD = localStorage.getItem('stockSymbolPD');
    if (stockSymbolPD) {
      setStockSymbol(JSON.parse(stockSymbolPD));
    }
    const searchPdFPIndustry = localStorage.getItem('searchPdFPIndustryPD');
    if (searchPdFPIndustry) {
      setStockSymbol(JSON.parse(searchPdFPIndustry));
    }
    const searchPdGicsPD = localStorage.getItem('searchPdGicsPD');
    if (searchPdGicsPD) {
      setSearchPdGics(JSON.parse(searchPdGicsPD));
    }
    const searchPdNaicsPD = localStorage.getItem('searchPdNaicsPD');
    if (searchPdNaicsPD) {
      setSearchPdNaics(JSON.parse(searchPdNaicsPD));
    }
    const searchPdSicPD = localStorage.getItem('searchPdSicPD');
    if (searchPdSicPD) {
      setSearchPdSic(JSON.parse(searchPdSicPD));
    }
    const newSavedSearchNamePD = localStorage.getItem('newSavedSearchNamePD');
    if (newSavedSearchNamePD) {
      setNewSavedSearchName(JSON.parse(newSavedSearchNamePD));
    }
    const statusActivePD = localStorage.getItem('statusActivePD');
    if (statusActivePD) {
      setStatusActive(JSON.parse(statusActivePD));
    }
    const statusInactivePD = localStorage.getItem('statusInactivePD');
    if (statusInactivePD) {
      setStatusInactive(JSON.parse(statusInactivePD));
    }
    const searchPdNameChangePD = localStorage.getItem('searchPdNameChangePD');
    if (searchPdNameChangePD) {
      setSearchPdNameChange(JSON.parse(searchPdNameChangePD));
    }
    const searchPdMergedNewNamePD = localStorage.getItem('searchPdMergedNewNamePD');
    if (searchPdMergedNewNamePD) {
      setSearchPdMergedNewName(JSON.parse(searchPdMergedNewNamePD));
    }
    const searchPdMergedSameNamePD = localStorage.getItem('searchPdMergedSameNamePD');
    if (searchPdMergedSameNamePD) {
      setSearchPdMergedSameName(JSON.parse(searchPdMergedSameNamePD));
    }
    const searchPdMergedAmalgamatedPD = localStorage.getItem('searchPdMergedAmalgamatedPD');
    if (searchPdMergedAmalgamatedPD) {
      setSearchPdMergedAmalgamated(JSON.parse(searchPdMergedAmalgamatedPD));
    }
    const searchPdPrivatizedNewNamePD = localStorage.getItem('searchPdPrivatizedNewNamePD');
    if (searchPdPrivatizedNewNamePD) {
      setSearchPdPrivatizedNewName(JSON.parse(searchPdPrivatizedNewNamePD));
    }
    const searchPdPrivatizedAcquiredPD = localStorage.getItem('searchPdPrivatizedAcquiredPD');
    if (searchPdPrivatizedAcquiredPD) {
      setSearchPdPrivatizedacquired(JSON.parse(searchPdPrivatizedAcquiredPD));
    }
    const searchPdSucceededPD = localStorage.getItem('searchPdSucceededPD');
    if (searchPdSucceededPD) {
      setSearchPdSucceeded(JSON.parse(searchPdSucceededPD));
    }
    const searchPdBankruptLiquidatedPD = localStorage.getItem('searchPdBankruptLiquidatedPD');
    if (searchPdBankruptLiquidatedPD) {
      setSearchPdBankruptLiquidated(JSON.parse(searchPdBankruptLiquidatedPD));
    }
    const searchPdCharterCancelledPD = localStorage.getItem('searchPdCharterCancelledPD');
    if (searchPdCharterCancelledPD) {
      setSearchPdCharterCancelled(JSON.parse(searchPdCharterCancelledPD));
    }
    const searchPdCharterRevivedPD = localStorage.getItem('searchPdCharterRevivedPD');
    if (searchPdCharterRevivedPD) {
      setSearchPdCharterRevived(JSON.parse(searchPdCharterRevivedPD));
    }
    const searchPdCharterSurrenderedPD = localStorage.getItem('searchPdCharterSurrenderedPD');
    if (searchPdCharterSurrenderedPD) {
      setSearchPdCharterSurrendered(JSON.parse(searchPdCharterSurrenderedPD));
    }
    const searchPdInBankruptcyPD = localStorage.getItem('searchPdInBankruptcyPD');
    if (searchPdInBankruptcyPD) {
      setSearchPdInBankruptcy(JSON.parse(searchPdInBankruptcyPD));
    }
    const searchPdInReceivershipPD = localStorage.getItem('searchPdInReceivershipPD');
    if (searchPdInReceivershipPD) {
      setSearchPdInReceivership(JSON.parse(searchPdInReceivershipPD));
    }
    const searchPdRelistedPD = localStorage.getItem('searchPdRelistedPD');
    if (searchPdRelistedPD) {
      setSearchPdRelisted(JSON.parse(searchPdRelistedPD));
    }
    const searchPdStruckFromRegisterPD = localStorage.getItem('searchPdStruckFromRegisterPD');
    if (searchPdStruckFromRegisterPD) {
      setSearchPdStruckFromRegister(JSON.parse(searchPdStruckFromRegisterPD));
    }
    const searchPdUnderCcaaPD = localStorage.getItem('searchPdUnderCcaaPD');
    if (searchPdUnderCcaaPD) {
      setSearchPdUnderCcaa(JSON.parse(searchPdUnderCcaaPD));
    }
    const searchPdWindingUpPD = localStorage.getItem('searchPdWindingUpPD');
    if (searchPdWindingUpPD) {
      setSearchPdWindingUp(JSON.parse(searchPdWindingUpPD));
    }
    const searchPdWoundUpLiquidatedPD = localStorage.getItem('searchPdWoundUpLiquidatedPD');
    if (searchPdWoundUpLiquidatedPD) {
      setSearchPdWoundUpLiquidated(JSON.parse(searchPdWoundUpLiquidatedPD));
    }
    const searchPdContinuancePD = localStorage.getItem('searchPdContinuancePD');
    if (searchPdContinuancePD) {
      setSearchPdContinuance(JSON.parse(searchPdContinuancePD));
    }
    const searchPdFormedByAmalgamationPD = localStorage.getItem('searchPdFormedByAmalgamationPD');
    if (searchPdFormedByAmalgamationPD) {
      setSearchPdFormedByAmalgamation(JSON.parse(searchPdFormedByAmalgamationPD));
    }
    const searchPdSuccessorPD = localStorage.getItem('searchPdSuccessorPD');
    if (searchPdSuccessorPD) {
      setSearchPdSuccessor(JSON.parse(searchPdSuccessorPD));
    }
    const searchPdIncorporationFromPD = localStorage.getItem('searchPdIncorporationFromPD');
    if (searchPdIncorporationFromPD) {
      setSearchPdIncorporationFrom(JSON.parse(searchPdIncorporationFromPD));
    }
    const searchPdIncorporationToPD = localStorage.getItem('searchPdIncorporationToPD');
    if (searchPdIncorporationToPD) {
      setSearchPdIncorporationTo(JSON.parse(searchPdIncorporationToPD));
    }
    const searchPdInitialPD = localStorage.getItem('searchPdInitialPD');
    if (searchPdInitialPD) {
      setSearchPdInitial(JSON.parse(searchPdInitialPD));
    }
    const searchPdCurrentPD = localStorage.getItem('searchPdCurrentPD');
    if (searchPdCurrentPD) {
      setSearchPdCurrent(JSON.parse(searchPdCurrentPD));
    }
    const naicsCodeTypePD = localStorage.getItem('naicsCodeTypePD');
    if (naicsCodeTypePD) {
      setNaicsCodeType(JSON.parse(naicsCodeTypePD));
    }
    const sicCodeTypePD = localStorage.getItem('sicCodeTypePD');
    if (sicCodeTypePD) {
      setSicCodeType(JSON.parse(sicCodeTypePD));
    }
    const companyHistoryTextPD = localStorage.getItem('companyHistoryTextPD');
    if (companyHistoryTextPD) {
      setCompanyHistoryText(JSON.parse(companyHistoryTextPD));
    }
    const historyMatchTypePD = localStorage.getItem('historyMatchTypePD');
    if (historyMatchTypePD) {
      setHistoryMatchType(JSON.parse(historyMatchTypePD));
    }
    const endDatePD = localStorage.getItem('endDatePD');
    if (endDatePD) {
      setEndDatePd(new Date(JSON.parse(endDatePD)));
    }
    const startDatePD = localStorage.getItem('startDatePD');
    if (startDatePD) {
      setStartDatePd(new Date(JSON.parse(startDatePD)));
    }
  }

  const clearCheck = () => {
    setSelectedSavedSearch('');
    setCompanyName('');
    setStockSymbol('');
    setStatusActive(false);
    setStatusInactive(false);
    setSearchPdFPIndustry('');
    setSearchPdGics([]);
    setSearchPdNaics([]);
    setSearchPdSic([]);
    setSearchPdNameChange(false);
    setSearchPdPrivatizedNewName(false);
    setSearchPdPrivatizedacquired(false);
    setSearchPdRelisted(false);
    setSearchPdSucceeded(false);
    setSearchPdSuccessor(false);
    setSearchPdUnderCcaa(false);
    setSearchPdInitial(false);
    setSearchPdCurrent(false);
    setSearchPdCharterCancelled(false);
    setSearchPdCharterRevived(false);
    setSearchPdCharterSurrendered(false);
    setNaicsCodeType('1');
    setSicCodeType('1');
    setSearchPdContinuance(false);
    setSearchPdMergedAmalgamated(false);
    setSearchPdMergedNewName(false);
    setSearchPdMergedSameName(false);
    setSearchPdBankruptLiquidated(false);
    setSearchPdFormedByAmalgamation(false);
    setSearchPdWindingUp(false);
    setSearchPdWoundUpLiquidated(false);
    setSearchPdInBankruptcy(false);
    setSearchPdInReceivership(false);
    setSearchPdIncorporationFrom([]);
    setSearchPdIncorporationTo([]);
    setSearchPdStruckFromRegister(false);
    setCompanyHistoryText('');
    setHistoryMatchType('exactphrase');
    setStartDatePd(new Date(new Date().getFullYear() - 1, 0, 1));
    setEndDatePd(new Date(new Date().getFullYear(), 11, 31));
    setNewSavedSearchName('');
  };

  const populateFromSavedSearch = (savedSearchId: string) => {
    clearCheck(); // clear form values

    setSelectedSavedSearch(savedSearchId);

    const savedSearch = savedSearches?.predecessorAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    );

    if (!savedSearch) {
      return;
    }

    const savedSearchValues = savedSearch.searchValues;

    if (savedSearchValues.name) {
      setCompanyName(savedSearchValues.name);
    }
    if (savedSearchValues.symbol) {
      setStockSymbol(savedSearchValues.symbol);
    }
    if (savedSearchValues.active) {
      setStatusActive(true);
    }
    if (savedSearchValues.inactive) {
      setStatusInactive(true);
    }
    if (savedSearchValues.fpindustry) {
      setSearchPdFPIndustry(savedSearchValues.fpindustry);
    }
    if (savedSearchValues.gics) {
      // change the array of ids to an array of names
      const gicsIds = savedSearchValues.gics;
      const gics = gicsIds.toString().split(',').map(id => gicsLookup.filter(f => f.id === id));
      const gicsNames = gics.map(gic => gic[0]?.name);
      setSearchPdGics(gicsNames);
    }
    if (savedSearchValues.naics) {
      // change the array of ids to an array of names
      const naicsIds = savedSearchValues.naics;
      const naics = naicsIds.toString().split(',').map(id => naicsLookupCodes.filter((f: { id: string; }) => f.id === id));
      const naicsNames = naics.map(naic => naic[0]?.name);
      setSearchPdNaics(naicsNames);
    }
    if (savedSearchValues.naicstype) {
      setNaicsCodeType(savedSearchValues.naicstype);
    }
    if (savedSearchValues.sic) {
      // change the array of ids to an array of names
      const sicIds = savedSearchValues.sic;
      const sics = sicIds.toString().split(',').map(id => sicLookup.filter(f => f.id === id));
      const sicNames = sics.map(sic => sic[0]?.name);
      setSearchPdSic(sicNames);
    }
    if (savedSearchValues.sictype) {
      setSicCodeType(savedSearchValues.sictype);
    }
    if (savedSearchValues.namechange) {
      setSearchPdNameChange(true);
    }
    if (savedSearchValues.mergednewname) {
      setSearchPdMergedNewName(true);
    }
    if (savedSearchValues.mergedsamename) {
      setSearchPdMergedSameName(true);
    }
    if (savedSearchValues.mergedamalgamated) {
      setSearchPdMergedAmalgamated(true);
    }
    if (savedSearchValues.privatizednewname) {
      setSearchPdPrivatizedNewName(true);
    }
    if (savedSearchValues.privatizedacquired) {
      setSearchPdPrivatizedacquired(true);
    }
    if (savedSearchValues.succeeded) {
      setSearchPdSucceeded(true);
    }
    if (savedSearchValues.bankruptliquidated) {
      setSearchPdBankruptLiquidated(true);
    }
    if (savedSearchValues.chartercancelled) {
      setSearchPdCharterCancelled(true)
    }
    if (savedSearchValues.bankruptliquidated) {
      setSearchPdCharterRevived(true);
    }
    if (savedSearchValues.chartersurrendered) {
      setSearchPdCharterSurrendered(true);
    }
    if (savedSearchValues.inbankruptcy) {
      setSearchPdInBankruptcy(true);
    }
    if (savedSearchValues.inreceivership) {
      setSearchPdInReceivership(true);
    }
    if (savedSearchValues.relisted) {
      setSearchPdRelisted(true);
    }
    if (savedSearchValues.struckfromregister) {
      setSearchPdStruckFromRegister(true);
    }
    if (savedSearchValues.underccaa) {
      setSearchPdUnderCcaa(true);
    }
    if (savedSearchValues.windingup) {
      setSearchPdWindingUp(true);
    }
    if (savedSearchValues.woundupliquidated) {
      setSearchPdWoundUpLiquidated(true);
    }
    if (savedSearchValues.change) {
      setSearchPdContinuance(true);
    }
    if (savedSearchValues.amalgamation) {
      setSearchPdFormedByAmalgamation(true);
    }
    if (savedSearchValues.successor) {
      setSearchPdSuccessor(true);
    }
    // change the array of ids to an array of names
    const gicsIds = savedSearchValues.gics;
    const gics = gicsIds.toString().split(',').map(id => gicsLookup.filter(f => f.id === id));
    const gicsNames = gics.map(gic => gic[0]?.name);
    setSearchPdGics(gicsNames);
    if (savedSearchValues.incorpjurisdictionfrom) {
      // change the array of ids to an array of names
      const jurIds = savedSearchValues.incorpjurisdictionfrom;
      const jurs = jurIds.toString().split(',').map(id => incorporationLocationLookup.filter(f => f.id === id));
      const jursNames = jurs.map(jur => jur[0]?.name);
      setSearchPdIncorporationFrom(jursNames);
    }
    if (savedSearchValues.incorpjurisdictionto) {
      // change the array of ids to an array of names
      const jurIds = savedSearchValues.incorpjurisdictionto;
      const jurs = jurIds.toString().split(',').map(id => incorporationLocationLookup.filter(f => f.id === id));
      const jursNames = jurs.map(jur => jur[0]?.name);
      setSearchPdIncorporationTo(jursNames);
    }
    if (savedSearchValues.initial) {
      setSearchPdInitial(true);
    }
    if (savedSearchValues.current) {
      setSearchPdCurrent(true);
    }
    if (savedSearchValues.companyhistorytext) {
      setCompanyHistoryText(savedSearchValues.companyhistorytext);
    }
    if (savedSearchValues.historymatchtype) {
      setHistoryMatchType(savedSearchValues.historymatchtype);
    }
    if (savedSearchValues.start_year && savedSearchValues.start_month && savedSearchValues.start_day) {
      setStartDatePd(new Date(parseInt(savedSearchValues.start_year, 10), parseInt(savedSearchValues.start_month, 10) - 1, parseInt(savedSearchValues.start_day, 10)));
    }
    if (savedSearchValues.end_year && savedSearchValues.end_month && savedSearchValues.end_day) {
      setEndDatePd(new Date(parseInt(savedSearchValues.end_year, 10), parseInt(savedSearchValues.end_month, 10) - 1, parseInt(savedSearchValues.end_day, 10)));
    }
  }
  const getAccessToken = useGetAccessToken();

  const saveSearch = async () => {
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    // saved search must have a name set...
    if (!newSavedSearchName || newSavedSearchName.toString().trim().length < 1) {
      return;
    }
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await getPredecessorDefunctSearchResults({
      companyName: companyName || '',
      stockSymbol: stockSymbol,
      statusActive: statusActive || false,
      statusInactive: statusInactive || false,
      searchPdFPIndustry: searchPdFPIndustry || '',
      searchPdGics: searchPdGics || [],
      searchPdNaics: searchPdNaics || [],
      searchPdSic: searchPdSic || [],
      searchPdNameChange: searchPdNameChange || false,
      searchPdMergedNewName: searchPdMergedNewName || false,
      searchPdMergedSameName: searchPdMergedSameName || false,
      searchPdMergedAmalgamated: searchPdMergedAmalgamated || false,
      searchPdPrivatizedNewName: searchPdPrivatizedNewName || false,
      searchPdPrivatizedAcquired: searchPdPrivatizedAcquired || false,
      searchPdSucceeded: searchPdSucceeded || false,
      searchPdBankruptLiquidated: searchPdBankruptLiquidated || false,
      searchPdCharterCancelled: searchPdCharterCancelled || false,
      searchPdCharterRevived: searchPdCharterRevived || false,
      searchPdCharterSurrendered: searchPdCharterSurrendered || false,
      searchPdInBankruptcy: searchPdInBankruptcy || false,
      searchPdInReceivership: searchPdInReceivership || false,
      searchPdRelisted: searchPdRelisted || false,
      searchPdStruckFromRegister: searchPdStruckFromRegister || false,
      searchPdUnderCcaa: searchPdUnderCcaa || false,
      searchPdWindingUp: searchPdWindingUp || false,
      searchPdWoundUpLiquidated: searchPdWoundUpLiquidated || false,
      searchPdContinuance: searchPdContinuance || false,
      searchPdFormedByAmalgamation: searchPdFormedByAmalgamation || false,
      searchPdSuccessor: searchPdSuccessor || false,
      searchPdIncorporationFrom: searchPdIncorporationFrom || [],
      searchPdIncorporationTo: searchPdIncorporationTo || [],
      searchPdInitial: searchPdInitial || false,
      searchPdCurrent: searchPdCurrent || false,
      naicsCodeType: naicsCodeType || '',
      sicCodeType: sicCodeType || '',
      companyHistoryText: companyHistoryText || '',
      historyMatchType: historyMatchType || 'exactphrase',
      startDatePd: dateFilter(startDatePd),
      endDatePd: dateFilter(endDatePd),
      // dateInfo: dateInfo,
      savedSearchName: newSavedSearchName || undefined,
      page: 1,
      numberOfRows: 1,
    }, getAccessToken)
    .catch(err => {
      console.log(err);
    });

    refreshSavedSearches();

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#0E643D', width: '280px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <CheckIcon fill="white" />
        <div>Search form saved</div>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#0E643D', color: 'white' } });
  }

  const deleteSavedSearch = async (savedSearchId: string) => {
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await deleteSearch({
      id: savedSearchId
    }, getAccessToken);

    refreshSavedSearches();

    const deletedSearchName = savedSearches?.predecessorAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    )?.searchName;

    setNewSavedSearchName('');

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#333333', width: '320px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <div>Search form removed</div>
        <button style={{ margin: 0, padding: 0, fontWeight: 'bold', background: 0, border: 0, color: 'white', textDecoration: 'underline' }} onClick={async () => {
          if (deletedSearchName) {
            toast.dismiss(t.id);
            await setNewSavedSearchName(deletedSearchName);
            await saveSearch();
            await refreshSavedSearches();
          }
        }}>
          Undo
        </button>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#333333', color: 'white' } });
  };

  const predecessorSubmitData = () => {
    const newDate = new Date()
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');
    history.push(
      `/predecessor-and-defunct/results?dateInfo=${dateInfo}&companyName=${companyName}&stockSymbol=${stockSymbol}`, { data: true });
  }

  const predecessorAdvancedSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;
    const resultsUrl = new URL('/predecessor-and-defunct/results', baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, h:mm A');
    resultsUrl.searchParams.set('dateInfo', dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set('companyName', companyName);
    }
    if (stockSymbol) {
      resultsUrl.searchParams.set('stockSymbol', stockSymbol);
    }
    if (statusActive) {
      resultsUrl.searchParams.set(
        'statusActive',
        `${statusActive}`
      );
    }
    if (statusInactive) {
      resultsUrl.searchParams.set(
        'statusInactive',
        `${statusInactive}`
      );
    }
    if (searchPdFPIndustry) {
      resultsUrl.searchParams.set(
        'searchPdFPIndustry',
        searchPdFPIndustry
      );
    }
    if (searchPdGics) {
      resultsUrl.searchParams.set('searchPdGics', searchPdGics.toString());
    }
    if (searchPdNaics) {
      resultsUrl.searchParams.set('searchPdNaics', searchPdNaics.toString());
    }
    if (searchPdSic) {
      resultsUrl.searchParams.set('searchPdSic', searchPdSic.toString());
    }
    if (searchPdNameChange) {
      resultsUrl.searchParams.set(
        'searchPdNameChange',
        `${searchPdNameChange}`
      );
    }
    if (searchPdMergedNewName) {
      resultsUrl.searchParams.set(
        'searchPdMergedNewName',
        `${searchPdMergedNewName}`
      );
    }
    if (searchPdMergedSameName) {
      resultsUrl.searchParams.set(
        'searchPdMergedSameName',
        `${searchPdMergedSameName}`
      );
    }
    if (searchPdMergedAmalgamated) {
      resultsUrl.searchParams.set(
        'searchPdMergedAmalgamated',
        `${searchPdMergedAmalgamated}`
      );
    }
    if (searchPdPrivatizedNewName) {
      resultsUrl.searchParams.set(
        'searchPdPrivatizedNewName',
        `${searchPdPrivatizedNewName}`
      );
    }
    if (searchPdPrivatizedAcquired) {
      resultsUrl.searchParams.set(
        'searchPdPrivatizedAcquired',
        `${searchPdPrivatizedAcquired}`
      );
    }
    if (searchPdSucceeded) {
      resultsUrl.searchParams.set(
        'searchPdSucceeded',
        `${searchPdSucceeded}`
      );
    }
    if (searchPdBankruptLiquidated) {
      resultsUrl.searchParams.set(
        'searchPdBankruptLiquidated',
        `${searchPdBankruptLiquidated}`
      );
    }
    if (searchPdCharterCancelled) {
      resultsUrl.searchParams.set(
        'searchPdCharterCancelled',
        `${searchPdCharterCancelled}`
      );
    }
    if (searchPdCharterRevived) {
      resultsUrl.searchParams.set(
        'searchPdCharterRevived',
        `${searchPdCharterRevived}`
      );
    }
    if (searchPdCharterSurrendered) {
      resultsUrl.searchParams.set(
        'searchPdCharterSurrendered',
        `${searchPdCharterSurrendered}`
      );
    }
    if (searchPdInBankruptcy) {
      resultsUrl.searchParams.set(
        'searchPdInBankruptcy',
        `${searchPdInBankruptcy}`
      );
    }
    if (searchPdInReceivership) {
      resultsUrl.searchParams.set(
        'searchPdInReceivership',
        `${searchPdInReceivership}`
      );
    }
    if (searchPdRelisted) {
      resultsUrl.searchParams.set(
        'searchPdRelisted',
        `${searchPdRelisted}`
      );
    }
    if (searchPdStruckFromRegister) {
      resultsUrl.searchParams.set(
        'searchPdStruckFromRegister',
        `${searchPdStruckFromRegister}`
      );
    }
    if (searchPdUnderCcaa) {
      resultsUrl.searchParams.set(
        'searchPdUnderCcaa',
        `${searchPdUnderCcaa}`
      );
    }
    if (searchPdWindingUp) {
      resultsUrl.searchParams.set(
        'searchPdWindingUp',
        `${searchPdWindingUp}`
      );
    }
    if (searchPdWoundUpLiquidated) {
      resultsUrl.searchParams.set(
        'searchPdWoundUpLiquidated',
        `${searchPdWoundUpLiquidated}`
      );
    }
    if (searchPdWindingUp) {
      resultsUrl.searchParams.set(
        'searchPdWindingUp',
        `${searchPdWindingUp}`
      );
    }
    if (searchPdContinuance) {
      resultsUrl.searchParams.set(
        'searchPdContinuance',
        `${searchPdContinuance}`
      );
    }
    if (searchPdFormedByAmalgamation) {
      resultsUrl.searchParams.set(
        'searchPdFormedByAmalgamation',
        `${searchPdFormedByAmalgamation}`
      );
    }
    if (searchPdSuccessor) {
      resultsUrl.searchParams.set(
        'searchPdSuccessor',
        `${searchPdSuccessor}`
      );
    }
    if (searchPdIncorporationFrom) {
      resultsUrl.searchParams.set('searchPdIncorporationFrom', searchPdIncorporationFrom.toString());
    }
    if (searchPdIncorporationTo) {
      resultsUrl.searchParams.set('searchPdIncorporationTo', searchPdIncorporationTo.toString());
    }
    if (searchPdInitial) {
      resultsUrl.searchParams.set(
        'searchPdInitial',
        `${searchPdInitial}`
      );
    }
    if (searchPdCurrent) {
      resultsUrl.searchParams.set(
        'searchPdCurrent',
        `${searchPdCurrent}`
      );
    }
    if (naicsCodeType) {
      resultsUrl.searchParams.set(
        'naicsCodeType',
        `${naicsCodeType}`
      );
    }
    if (sicCodeType) {
      resultsUrl.searchParams.set(
        'sicCodeType',
        `${sicCodeType}`
      );
    }
    if (companyHistoryText) {
      resultsUrl.searchParams.set(
        'companyHistoryText',
        `${companyHistoryText}`
      );
    }
    if (historyMatchType) {
      resultsUrl.searchParams.set(
        'historyMatchType',
        `${historyMatchType}`
      );
    }
    if (startDatePd) {
      resultsUrl.searchParams.set('startDatePd', dateFilter(startDatePd));
    }

    if (endDatePd) {
      resultsUrl.searchParams.set('endDatePd', dateFilter(endDatePd));
    }

    if (newSavedSearchName) {
      resultsUrl.searchParams.set('savedSearchName', newSavedSearchName);
      resultsUrl.searchParams.set('save_search', 'Save');
    }
    history.push(resultsUrl.href.replace(baseUrl, ''), { data: true });
  }

  return (
    <Page aria-labelledby={headingId}>
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Predecessor &amp; Defunct - Basic Search
          </Heading>

          <Text mt="md" mb="lg" lineHeight="md">
            A comprehensive record of changes to former publicly traded Canadian
            companies since 1929 with details of name changes, amalgamations,
            takeovers and acquisitions affecting over 28,000 corporate entities.
            Also includes companies being wound up, dissolved or companies whose
            charters have been canceled or struck from the provincial registers.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              predecessorSubmitData();
            }}
          >
            <TextField
              mt="lg"
              label="Company Name"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />
            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>
        <Tab label="Advanced">
          <Heading level="1" size="xxxl">
            Financial Post Predecessor &amp; Defunct - Advanced Search
          </Heading>
          <Text mt="md" mb="lg" lineHeight="md">
            A comprehensive record of changes to former publicly traded Canadian
            companies since 1929 with details of name changes, amalgamations,
            takeovers and acquisitions affecting over 28,000 corporate entities.
            Also includes companies being wound up, dissolved or companies whose
            charters have been canceled or struck from the provincial registers.
          </Text>
          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              predecessorAdvancedSubmitData();
            }}
          >
            <div style={{ marginBottom: '32px' }}>
              <Heading level="2" size="lg" mb="sm">
                  <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
                  My Saved Searches
              </Heading>

              <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches">Saved Searches</label>
              <Row>
                <select id="select_saved_searches"
                  value={selectedSavedSearch}
                  onChange={(e) => {
                        populateFromSavedSearch(e.target.value);
                  }}>
                  <option>No Search Selected</option>
                  { savedSearches?.predecessorAdvanced.map(search => (
                      <option value={search.searchId}>{search.searchName}</option>
                  ))}
                </select>

                {selectedSavedSearch && (
                    <button type="button" style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) }}>
                      <TrashIcon size='sm' />
                    </button>
                  )
                }
              </Row>
            </div>
            <TextField
              mt="lg"
              label="Company Name"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />
            <Heading id="companyStatus" level="3" size={['sm', 'sm', 'md']}>
              Company Status
            </Heading>
            <div aria-labelledby="companyStatus" style={{ marginTop: '16px' }}>
              <input
                type="checkbox"
                id="chk_company_status_active"
                checked={statusActive}
                onChange={(e) => {
                  /*
                  localStorage.setItem(
                    "chk_company_status",
                    `${e.target.checked}`
                  ); */
                  setStatusActive(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: '10px' }}
                htmlFor="chk_company_status_active"
              >
                Active
              </label>
              <input
                type="checkbox"
                id="chk_company_status_inactive"
                checked={statusInactive}
                onChange={(e) => {
                  /*
                  localStorage.setItem(
                    "chk_company_status_inactive",
                    `${e.target.checked}`
                  ); */
                  setStatusInactive(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: '10px' }}
                htmlFor="chk_company_status_inactive"
              >
                Inactive
              </label>
            </div>
            <div style={{ marginTop: '24px' }}>
              <label htmlFor="pd_fp_industry">FP Industry</label>
              <br></br>
              <select
                id="pd_fp_industry"
                name="pd_fp_industry"
                value={searchPdFPIndustry}
                onChange={(e) => {
                  setSearchPdFPIndustry(e.target.value);
                }}
              >
                {fpIndustryLookup.map((l) => (
                  <option value={l.id}>{l.name}</option>
                ))}
              </select>
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label='S&amp;P GICS'
                items={gicsLookup}
                value={searchPdGics}
                onChange={(e) => {
                  setSearchPdGics(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="NAICS Codes"
                items={naicsLookupCodes}
                value={searchPdNaics}
                onChange={(e) => {
                  setSearchPdNaics(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
              <RadioList
                direction='row'
                aria-label='NAICS Code Type'
                value={naicsCodeType}
                /*
                onChange={(e) => {
                  localStorage.setItem('sicCodeRadio', `${e}`);
                  setSicCodeType(e);
                }} */
                onChange={setNaicsCodeType}
                options={naicsCodeTypeLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="SIC Code Type"
                items={sicLookup}
                value={searchPdSic}
                onChange={(e) => {
                  setSearchPdSic(e.map(name => name.replaceAll(',', '&#44;')))
                }}
              />
              <RadioList
                direction='row'
                aria-label='Sic Codes'
                value={sicCodeType}
                /*
                onChange={(e) => {
                  localStorage.setItem('sicCodeRadio', `${e}`);
                  setSicCodeType(e);
                }} */
                onChange={setSicCodeType}
                options={sicCodeTypeLookup}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <Heading id="event_chk" level="3" size={['sm', 'sm', 'md']}>
                Event
              </Heading>
              <CheckOptions
                options={[
                {
                  value: searchPdNameChange,
                  setValue: setSearchPdNameChange,
                  label: 'Name change'
                },
                {
                  value: searchPdMergedNewName,
                  setValue: setSearchPdMergedNewName,
                  label: 'Merged/Amalgamated - continued with new name'
                },
                {
                  value: searchPdMergedSameName,
                  setValue: setSearchPdMergedSameName,
                  label: 'Merged/Amalgamated - continued with same name'
                },
                {
                  value: searchPdMergedAmalgamated,
                  setValue: setSearchPdMergedAmalgamated,
                  label: 'Merged/Amalgamated'
                },
                {
                  value: searchPdPrivatizedNewName,
                  setValue: setSearchPdPrivatizedNewName,
                  label: 'Privatized/Acquired - continued with new name'
                },
                {
                  value: searchPdPrivatizedAcquired,
                  setValue: setSearchPdPrivatizedacquired,
                  label: 'Privatized/Acquired'
                },
                {
                  value: searchPdSucceeded,
                  setValue: setSearchPdSucceeded,
                  label: 'Succeeded - continued with new name'
                },
                {
                  value: searchPdBankruptLiquidated,
                  setValue: setSearchPdBankruptLiquidated,
                  label: 'Bankrupt, liquidated'
                },
                {
                  value: searchPdCharterCancelled,
                  setValue: setSearchPdCharterCancelled,
                  label: 'Charter cancelled'
                },
                {
                  value: searchPdCharterRevived,
                  setValue: setSearchPdCharterRevived,
                  label: 'Charter revived'
                },
                {
                  value: searchPdCharterSurrendered,
                  setValue: setSearchPdCharterSurrendered,
                  label: 'Charter surrendered'
                },
                {
                  value: searchPdInBankruptcy,
                  setValue: setSearchPdInBankruptcy,
                  label: 'In bankruptcy'
                },
                {
                  value: searchPdInReceivership,
                  setValue: setSearchPdInReceivership,
                  label: 'In receivership'
                },
                {
                  value: searchPdRelisted,
                  setValue: setSearchPdRelisted,
                  label: 'Relisted'
                },
                {
                  value: searchPdStruckFromRegister,
                  setValue: setSearchPdStruckFromRegister,
                  label: 'Struck from register'
                },
                {
                  value: searchPdUnderCcaa,
                  setValue: setSearchPdUnderCcaa,
                  label: 'Under CCAA'
                },
                {
                  value: searchPdWindingUp,
                  setValue: setSearchPdWindingUp,
                  label: 'Winding up'
                },
                {
                  value: searchPdWoundUpLiquidated,
                  setValue: setSearchPdWoundUpLiquidated,
                  label: 'Wound up, liquidated'
                },
              ]}
              labelledBy='event_chk'
              vertical={true}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <Heading id="incorporation_istory_type_chk" level="3" size={['sm', 'sm', 'md']}>
                Incorporation History Type
              </Heading>
              <CheckOptions
                options={[
                {
                  value: searchPdContinuance,
                  setValue: setSearchPdContinuance,
                  label: 'Continuance'
                },
                {
                  value: searchPdFormedByAmalgamation,
                  setValue: setSearchPdFormedByAmalgamation,
                  label: 'Formed by Amalgamation'
                },
                {
                  value: searchPdSuccessor,
                  setValue: setSearchPdSuccessor,
                  label: 'Successor'
                },
              ]}
              labelledBy='incorporation_istory_type_chk'
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Incorporation From"
                items={incorporationLocationLookup}
                value={searchPdIncorporationFrom}
                onChange={setSearchPdIncorporationFrom}
              />
              <CheckOptions
                  options={[
                  {
                    value: searchPdInitial,
                    setValue: setSearchPdInitial,
                    label: 'Initial'
                  }
                ]}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <MultiSelectFieldChips
                label="Incorporation To"
                items={incorporationLocationLookup}
                value={searchPdIncorporationTo}
                onChange={setSearchPdIncorporationTo}
              />
              <CheckOptions
                options={[
                {
                  value: searchPdCurrent,
                  setValue: setSearchPdCurrent,
                  label: 'Current'
                }
              ]}
            />
            </div>
            <div>
              <TextField
                mt="lg"
                label="Name Change &amp; Company History Text Search"
                value={companyHistoryText}
                onChange={setCompanyHistoryText}
              />
              <br></br>
              <RadioList
                direction='row'
                aria-label='History Match Type'
                value={historyMatchType}
                /*
                onChange={(e) => {
                  localStorage.setItem('sicCodeRadio', `${e}`);
                  setSicCodeType(e);
                }} */
                onChange={setHistoryMatchType}
                options={historyMatchTypeLookup}
              />
            </div>
            <label id="rangeDatesId">
              Date Range
            </label>
            <div aria-labelledby="rangeDatesId" style={{ marginTop: '16px', marginBottom: '36px' }}>
              <DateRangePicker
                startDate={startDatePd}
                retainEndDateOnFirstSelection={true}
                moveRangeOnFirstSelection={false}
                minDate={minDate}
                endDate={endDatePd}
                setStartDate={setStartDatePd}
                setEndDate={setEndDatePd}
              />
            </div>
            <div>
              <Heading level="2" size="lg" mb="sm" mt="lg">
                Save Search
              </Heading>
              <Row>
                <div style={{ maxWidth: '90%' }}>
                  <TextField
                    label="Name of search form"
                    value={newSavedSearchName}
                    onChange={setNewSavedSearchName}
                  />
                  <Text color="light" size="sm" mt="xxs">
                    Give your search form a meaningful name
                  </Text>
                </div>

                <div>
                  { ((savedSearches?.predecessorAdvanced.find(s => s.searchName === newSavedSearchName)
                    ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) } }>
                      <StarIcon size='sm' />
                      <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
                    </button>)
                    : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={saveSearch}>
                      <StarIcon size='sm' color='clear' />
                      <ScreenReaderOnly>Save search</ScreenReaderOnly>
                    </button>
                  )))}
                </div>
              </Row>
            </div>
            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>
      </Tabs>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />
    </Page>
  );
}
