import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { useIsShowingSearchResults } from '../GlobalSearch';
import { ShowOnMobile } from '../../components/ShowOnMobile';
import { HideOnMobile } from '../../components/HideOnMobile';
import { MarginProps } from '../../utils/style';
import { SideNavToggleButton } from '../SideNav';
import { useIsLoggedIn, useLogout } from '../AuthProvider';
import { useUser } from '../../data';
import { BackgroundProvider } from '../../components/BackgroundProvider';
import { footerHeight } from '../Footer';
import { Button } from '../../components/Button';
import { Row } from '../../components/Row';
import { Text } from '../../components/Text';
import { HelpIcon } from '../../icons/HelpIcon';
import { DropDownMenu } from '../../components/DropDownMenu';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { Link } from 'react-router-dom';

export function TopNav () {
  const isShowingSearchResults = useIsShowingSearchResults();
  const isLoggedIn = useIsLoggedIn();
  const logout = useLogout();
  const userInfo = useUser();

  const history = useHistory();

  const logoutUser = (): void => {
    logout();
    history.push('/');
  };

  const gotoUserProfile = (): void => {
    history.push('/settings#profile');
  };

  const gotoUserSettings = (): void => {
    history.push('/settings#settings');
  };

  const ResponsiveButton = styled(Button)`
    padding: 0.5rem;
    @media only screen and (min-width: 992px) {
      padding-right: 1.75rem;
      padding-left: 1.75rem;
    }
  `;
  return (
    <div>
      <StyledNav
        aria-label="Top bar"
        isShowingSearchResults={isShowingSearchResults}
      >
        <BackgroundProvider type={isShowingSearchResults ? 'light' : 'dark'}>
          <div style={{ display: isShowingSearchResults ? 'none' : 'block' }}>
            <SideNavToggleButton mr="md" hideAbove="tablet" />
          </div>

          <div
            style={{
              height: '100%',
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <Logo mr="md" />
          </div>

          <Row
            flexBasis={0}
            flexGrow={1}
            justifyContent="center"
            hideBelow="desktop"
          >
            <ResponsiveButton
              label="About Us"
              link="/about-us"
              weight="semi-bold"
            />

            {/* <ResponsiveButton link="https://legacy-fpadvisor.financialpost.com/prod/prod_list.php" target="_blank" label="Corporate Data" weight="semi-bold" /> */}
            <ResponsiveButton
              label="Corporate Data"
              link="/corporate-data"
              weight="semi-bold"
            />

            <ResponsiveButton
              label="Ordering FP Products"
              link="https://legacy-fpadvisor.financialpost.com/prod/prod_list.php"
              target="_blank"
              weight="semi-bold"
            />
            <ResponsiveButton
              label="Help"
              link="/help"
              weight="semi-bold"
            />
            {/* <ResponsiveButton label="Ordering FP Products" link="/ordering-products" weight="semi-bold" /> */}
          </Row>

          <Row
            flexBasis={0}
            flexGrow={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              weight="semi-bold"
              link="/help"
              aria-label="Help"
              label={<HelpIcon />}
              size="sm"
            />
            {isLoggedIn
? (
              <DropDownMenu
                label={
                  <Row alignItems="center">
                    <PersonIcon />
                    <HideOnMobile>
                      <Text
                        weight="semi-bold"
                        ml="xs"
                        mr="xxs"
                      >{`Hi, ${userInfo?.firstName}`}</Text>
                    </HideOnMobile>
                    <ArrowDownIcon color="white" />
                  </Row>
                }
                options={[
                  {
                    text: 'Profile',
                    onClick: gotoUserProfile,
                  },
                  {
                    text: 'Settings',
                    onClick: gotoUserSettings,
                  },
                  {
                    text: 'Logout',
                    onClick: logoutUser,
                  },
                ]}
              />
            )
: (
              <ResponsiveButton
                weight="semi-bold"
                link="/login"
                label="SIGN IN"
              />
            )}
          </Row>
        </BackgroundProvider>
      </StyledNav>
      <FakeHeightPusher />
    </div>
  );
}

function PersonIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={32}
      viewBox="0 0 24 24"
      width={32}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="9" fill="white" />
      <path
        fill="rgb(63,63,63)"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z"
      />
    </svg>
  );
}

const mobileNavHeight = '48px';
const desktopNavHeight = '64px';
const StyledNav = styled.nav<{ isShowingSearchResults: boolean }>((props) => ({
  backgroundColor: props.isShowingSearchResults
    ? 'white'
    : props.theme.palette.primary.main,
  height: mobileNavHeight,
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
  position: 'fixed',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: props.theme.zIndex.topNav,
  [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
    backgroundColor: props.theme.palette.primary.main,
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    height: desktopNavHeight,
  },
}));

const FakeHeightPusher = styled.div(({ theme }) => ({
  height: mobileNavHeight,
  [`@media(min-width: ${theme.breakpoints.sm})`]: {
    height: desktopNavHeight,
  },
}));

export const ContentUnderTopNav = styled.div<{ enableStickyFooter?: boolean }>(
  (props) => ({
    minHeight: `calc(100% - ${mobileNavHeight})`,
    [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
      minHeight: `calc(100% - ${desktopNavHeight})`,
    },

    marginBottom: props.enableStickyFooter ? `-${footerHeight}` : undefined,
    ...(props.enableStickyFooter
      ? {
          '&:after': {
            content: "''",
            display: 'block',
            height: footerHeight,
          },
        }
      : {}),
  })
);

function Logo (props: Pick<MarginProps, 'mr'> & { beta?: boolean }) {
  const isShowingSearchResults = useIsShowingSearchResults();
  return (
    <Link to="/" aria-label="Home">
      <ShowOnMobile
        when={!isShowingSearchResults}
        as="svg"
        height="100%"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`0, 0, ${props.beta ? 100 : 38}, 32`}
        mr={props.mr}
        aria-hidden
      >
        <defs>
          <path id="1100d1353dc94474977515b50982c436" d="M0 0h38.5v32H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <mask id="a2caa69f59a44354b40cee1d3470fabb" fill="#fff">
              <use xlinkHref="#1100d1353dc94474977515b50982c436" />
            </mask>
            <use fill="#FFF" xlinkHref="#1100d1353dc94474977515b50982c436" />
            <g mask="url(#a2caa69f59a44354b40cee1d3470fabb)" fill="#2E4EBF">
              <path d="M26.52 8c3.387 0 5.335 1.867 5.335 4.43 0 3.385-2.936 4.78-5.843 4.78h-1.678v5.997c0 .548.21.572.69.821.389.2 1.617.622 2.187.722v.248h-7.852v-.248c.45-.074 1.738-.523 2.188-.722.45-.199.69-.273.69-.821V9.792c0-.547-.21-.622-.69-.821-.419-.174-1.68-.623-2.188-.723V8h7.162zm-.988.448h-1.198v8.288h1.17c2.755 0 3.955-1.99 3.955-4.082 0-2.116-.93-4.206-3.927-4.206zM17.14 8v4.032h-.331c-.21-1.095-1.408-3.36-2.217-3.51-.449-.074-.93-.099-1.498-.099h-2.099v7.616h1.469c1.02 0 1.469-.473 1.708-.772.27-.373.6-1.766.66-2.414h.42v6.944h-.42c-.03-.722-.45-2.214-.66-2.538-.24-.349-.719-.771-1.708-.771h-1.469v6.719c0 .548.21.572.69.821.39.2 1.618.622 2.188.722v.248H6.02v-.248c.45-.073 1.739-.523 2.188-.722.45-.199.69-.273.69-.821V9.792c0-.547-.21-.622-.69-.821-.42-.174-1.677-.623-2.188-.723V8h11.12z" />
            </g>
          </g>
        </g>
        {props.beta && (
          <g
            transform="translate(50,8)"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <rect
              fill="#E9163C"
              x="0"
              y="0"
              width="45"
              height="18"
              rx="2"
            ></rect>
            <text
              fontFamily="Figtree-Regular, Figtree"
              fontSize="12"
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x="8" y="13">
                BETA
              </tspan>
            </text>
          </g>
        )}
      </ShowOnMobile>
      <HideOnMobile
        as="svg"
        height="100%"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`0, 0, ${props.beta ? 179 : 197}, 55`}
        aria-hidden
      >
        <path d="M55 0H0V55H55V0Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5 13.8V20.7001H24C23.7 18.8001 22 14.9001 20.8 14.7001C20.2 14.6001 19.5 14.5 18.7 14.5H15.7V27.6H17.8C19.3 27.6 19.9 26.8 20.2 26.3C20.6 25.7 21.1 23.3001 21.1 22.2001H21.7V34H21.1C21.1 32.8 20.5 30.2 20.2 29.6C19.9 29 19.2 28.3 17.8 28.3H15.7V39.8C15.7 40.7 16 40.8001 16.7 41.2001C17.3 41.5001 19 42.3001 19.8 42.4001V43H8.59998V42.6C9.19998 42.5 11.1 41.7001 11.7 41.4001C12.3 41.1001 12.7 40.9 12.7 40V16.8C12.7 15.9 12.4 15.7 11.7 15.4C11.1 15.1 9.29998 14.3001 8.59998 14.2001V13.8H24.5V13.8Z" fill="#2E4EBF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.1 13.8C42.9 13.8 45.7 17 45.7 21.4C45.7 27.2 41.5 29.6 37.4 29.6H35V39.9001C35 40.8001 35.3 40.9 36 41.3C36.6 41.6 38.3 42.4 39.1 42.5V43H27.9V42.6C28.5 42.5 30.4 41.7001 31 41.4001C31.6 41.1001 32 40.9 32 40V16.8C32 15.9 31.7 15.7 31 15.4C30.4 15.1 28.6 14.3001 27.9 14.2001V13.8H38.1V13.8ZM36.7 14.5H35V28.7001H36.7C40.6 28.7001 42.3 25.3001 42.3 21.7001C42.3 18.1001 41 14.5 36.7 14.5Z" fill="#2E4EBF"/>
        <path d="M87.1 42.5V42C86.4 41.8 85 41 84.6 40.7C83.7 40 83.5 39.9999 83.3 38.7999C82.3 34.8999 79.7 24.4 77 13.7H75.7C73.8 21.6 71.6 30.4 69.7 37.5C69.3 38.9 68.6 39.9 68.1 40.5C67.5 41 65.7 42 65.3 42V42.5H74V42C73.2 41.9 70.5 40.6999 70.5 39.5999C70.5 38.5999 70.6 37.9999 70.8 37.0999C71.1 35.8999 71.2 35.4 71.7 33.5H78.8L80.1 39C80.2 39.3 80.3 40 80.3 40.2C80.3 40.9 77.6 41.8999 76.7 42.0999V42.5999H87.1V42.5ZM78.7 32.5999H72L75.3 18.7999L78.7 32.5999Z" fill="white"/>
        <path d="M102.7 28.5C102.7 36 101.4 41.5999 97.3 41.5999C95.1 41.5999 94.9 40.4 94.8 38.4V15C95.2 15 96.1 15 96.6 15C100.8 15.2 102.7 21 102.7 28.5ZM87.8 42.5H96.3C102.6 42.5 106.2 38.2 106.2 28.5C106.2 17.2 101.1 14.3 96.8 14.2H87.8V14.6C88.5 14.8 90.3 15.4999 90.9 15.7999C91.6 16.0999 91.9 16.3 91.9 17.2V39.5C91.9 40.4 91.6 40.5 90.9 40.9C90.3 41.2 88.4 41.9999 87.8 42.0999V42.5Z" fill="white"/>
        <path d="M122.4 19.5C121.6 22.7 119.6 30.6 118 36.8C116.4 30.8 113.4 19.5001 113 17.7001C112.9 17.2001 112.8 16.7 112.8 16.5C112.8 15.8 115.5 15 116.4 14.8V14.3H106V14.8C106.7 15 108 15.5 108.5 15.9C109.4 16.6 109.6 16.7 109.8 17.8C110.9 21.6 113.7 32.1001 116.5 42.9001H117.3C119.2 35.2001 121.6 26.1 123.5 19C123.9 17.6 124.5 16.6 125.1 16C125.6 15.6 127.3 14.8 127.8 14.8V14.3H119.1V14.8C119.9 14.9 122.5 15.8 122.6 17C122.8 17.3 122.9 17.9 122.4 19.5Z" fill="white"/>
        <path d="M132.9 17.2V39.5C132.9 40.4 132.6 40.5 131.9 40.9C131.3 41.2 129.4 41.9999 128.8 42.0999V42.5H139.9V42.0999C139.2 41.8999 137.4 41.2 136.8 40.9C136.2 40.5 135.8 40.4 135.8 39.5V17.2C135.8 16.3 136.1 16.1999 136.8 15.7999C137.4 15.4999 139.3 14.7 139.9 14.6V14.2H128.8V14.6C129.5 14.8 131.3 15.4999 131.9 15.7999C132.6 16.1999 132.9 16.3 132.9 17.2Z" fill="white"/>
        <path d="M154.9 36.0999C154.9 31.9999 152.4 29.8 150.3 27.7C149.3 26.7 147.9 25.3999 146.8 24.2999C145.5 23.0999 144.4 21.1 144.4 18.9C144.4 15.9999 145.8 14.2999 147.8 14.2999C150.5 14.2999 151.7 17.7 152.9 22.6H153.4V14.2H153L151.8 15.7C150.7 14.4 149.2 13.7 147.8 13.7C144.9 13.7 141.9 15.4999 141.9 19.9C141.9 23.6 144 26.2 145.9 28C147 29 148.3 30.2 149.5 31.4C150.8 32.8 152.2 35 152.2 37.2C152.2 40.4 150.8 42.4 148.5 42.4C145.8 42.4 143.7 39.5 142.4 33.2H141.9L142.2 42.5H142.7L143.9 40.7999C144.7 41.7999 146.2 43 148.5 43C151.6 43.1 154.9 41.1999 154.9 36.0999Z" fill="white"/>
        <path d="M174.5 28.2C174.5 24.1 173.5 19.7 172.1 17.3C170.6 14.8 168.7 13.6 166.4 13.6C163.9 13.6 162.1 15 160.7 17.3C159.1 20 158.2 24.2 158.2 28.7C158.2 33.2 159.2 37.5 160.9 40.1C162.2 42 164.1 43.2 166.3 43.2C168.9 43.2 170.7 42 172 40C173.7 37.1 174.5 32.5 174.5 28.2ZM171 28.3C171 37.5 169.9 42.5 166.3 42.5C162.5 42.5 161.5 37.7 161.5 28.5C161.5 19.1 162.7 14.3 166.3 14.3C170 14.3 171 19.1 171 28.3Z" fill="white"/>
        <path d="M183.9 15C186.4 15 188.6 16.7 188.6 21.5C188.6 26.3 186.5 27.8 184.3 27.8H182.3V15H183.9ZM185.2 14.3H175.2V14.7001C175.9 14.9001 177.7 15.6 178.3 15.9C179 16.2 179.3 16.4 179.3 17.3V39.6C179.3 40.5 179 40.6 178.3 41C177.6 41.4 175.9 42.1001 175.2 42.2001V42.6H186.3V42.2001C185.5 42.0001 183.8 41.3 183.2 41C182.5 40.6 182.2 40.5 182.2 39.6V28.6H184.2C187.7 28.6 187.8 32.8 188.1 36.1C188.6 41.2 189.6 43.2 191.9 43.1C194 43 194.8 40.8 194.8 37V36.4001H194.1V37C194.1 39.8 193.4 40.9001 192.7 40.9001C192.2 40.9001 191.7 40.7 191.4 39.1C191.1 37.2 190.9 34.9 190.6 32.8C190.1 29.8 187.9 28.6 186.3 28.3V28C188.9 27.1 191.8 25.7001 191.8 21.2001C191.8 16.7001 188.4 14.3 185.2 14.3Z" fill="white"/>
        {props.beta && (
          <g
            transform="translate(126,10)"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <rect
              fill="#E9163C"
              x="0"
              y="0"
              width="45"
              height="18"
              rx="2"
            ></rect>
            <text
              fontFamily="Figtree-Regular, Figtree"
              fontSize="12"
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x="8" y="13">
                BETA
              </tspan>
            </text>
          </g>
        )}
      </HideOnMobile>
    </Link>
  );
}
