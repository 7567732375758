import React, { useEffect } from 'react';
import { Col } from 'react-grid-system';
import styled from 'styled-components';
import { isPropertySignature } from 'typescript';

interface StyledAlertProps {
  design?: 'success' | 'warning';
}

interface AlertProps extends StyledAlertProps {
  show: boolean;
  message: string | React.ReactNode;
  onDismiss?: () => void;
  autoHide?: boolean;
  autoHideDelay?: number;
  dismissible?: boolean;
	alertClassName?: string;
}
const Csvg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  width: '20',
  height: '15',
  viewBox: '0 -3 20 20',
  fill: 'none',
  hover: 'pointer'
})`
  position: relative;
  top: 2px;
  margin-left: 7px;
  cursor: pointer;
`;
const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  width: '20',
  height: '15',
  viewBox: '0 0 20 20',
  '&:hover': {
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  '&.focus-visible': {
    backgroundColor: 'black'
  }
})`
  position: relative;
  top: 2px;
  margin-right: 7px;
`;
const renderCloseIcon = ({ onDismiss }: { onDismiss?: () => void }) => (
  <Csvg
    onClick={() => {
      onDismiss && onDismiss();
    }}
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="white"
    />
  </Csvg>
);
const renderAlertIcon = ({ design }: Pick<AlertProps, 'design'>) => (
  <>
    <Svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000366211 10C-0.000366211 4.48 4.47963 0 9.99963 0C15.5196 0 19.9996 4.48 19.9996 10C19.9996 15.52 15.5196 20 9.99963 20C4.47963 20 -0.000366211 15.52 -0.000366211 10ZM2.99988 9.99996L7.99988 15L16.9999 5.99996L15.5899 4.57996L7.99988 12.17L4.40988 8.58996L2.99988 9.99996Z"
        fill="white"
      />
    </Svg>
  </>
);

const StyledAlert = styled.div<StyledAlertProps>((props) => ({
  textDecoration: 'none',
  color: 'white',
  backgroundColor: props.design === 'success' ? '#00754E' : 'red',
  fontWeight: 500,
  outline: 'none',
  alignItems: 'center',
  position: 'absolute',
  transition: 'all 0.3s',
  overflow: 'hidden',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  padding: '8px 10px',
  blend: 'Pass through'
}));

const Alert: React.FC<AlertProps> = ({
  message,
  design,
  show,
  autoHide,
  autoHideDelay,
  onDismiss,
  dismissible,
	alertClassName
}) => {
  const [display, setDisplay] = React.useState<boolean>(show);

  const handleDismiss = () => {
    setDisplay(false);
    onDismiss && onDismiss();
  };

  useEffect(() => {
    setDisplay(show);
    const autoHideTime = autoHideDelay || 3000;
    if (show && autoHide) {
      setTimeout(() => {
        setDisplay(false);
        onDismiss && onDismiss();
      }, autoHideTime);
    }
  }, [show]);

  return display
? (
	<div className={alertClassName}>
    <Col offset={{ xs: 3 }}>
      <StyledAlert design={design}>
        <span>
          {design && renderAlertIcon({ design })}
          {message}
          {dismissible && renderCloseIcon({ onDismiss: () => handleDismiss() })}
        </span>
      </StyledAlert>
    </Col>
		</div>
  )
: (
    <></>
  );
};

export default Alert;
