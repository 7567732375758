import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { Page } from '../../components/Page';
import { Heading } from '../../components/Heading';
import { useId } from '../../hooks';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { useIsLoggedIn, useLogin, useLoginByIp } from '../../containers/AuthProvider';
import { parse } from 'query-string';
import { Status } from '../../components/Status';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { RadioList } from '../../components/RadioList';
import { Text } from '../../components/Text';
import {
  useBetaModalOpen,
  useSetBetaModalOpen,
  useBetaModalDidAccept,
} from '../../containers/BetaModal';

export function LoginPage () {
  const history = useHistory();
  const headingId = useId();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [sessionSetting, setSessionSetting] = React.useState(
    'save-on-this-computer'
  );

  const betaModalOpen = useBetaModalOpen();
  const setBetaModalOpen = useSetBetaModalOpen();
  const betaModalDidAccept = useBetaModalDidAccept();

  const isLoggedIn = useIsLoggedIn();
  const { login, loginError, clearLoginError, isLoggingIn } = useLogin();

  const { loginByIp, loginByIpError, clearLoginByIpError, isLoggingInByIp } = useLoginByIp();

  const params = useParams<{ ipLoginUserName?: string }>();

  React.useEffect(() => {
    if (params.ipLoginUserName !== undefined && !loginByIpError && !isLoggingInByIp) {
      setUsername(params.ipLoginUserName);
      loginByIp({ username: params.ipLoginUserName });
    }
  });

  React.useEffect(() => {
    if (isLoggedIn) {
      const queryParams = parse(history.location.search);
      if (typeof queryParams.redirect === 'string') {
        history.push(queryParams.redirect);
      } else {
        history.push('/');
      }

      return;
    }

    if (
      !loginError &&
      !isLoggingIn &&
      !betaModalOpen &&
      betaModalDidAccept &&
      username &&
      password
    ) {
      login({
        username,
        password,
      });
    }
  }, [
    history,
    loginError,
    isLoggedIn,
    betaModalOpen,
    betaModalDidAccept,
    isLoggingIn,
  ]);

  const submitLogin = () => {
    clearLoginError();

    if (betaModalDidAccept) {
      login({
        username,
        password,
      });
    } else {
      setBetaModalOpen(true);
    }
  };

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']} mb="xxl">
        Sign In
      </Heading>

      <form
        aria-labelledby={headingId}
        onSubmit={(e) => {
          e.preventDefault();
          submitLogin();
        }}
      >
        <TextField
          mb="md"
          label="Username"
          value={username}
          onChange={setUsername}
          autocorrect={false}
          autocapitalize={false}
        />
        <TextField
          error={
            loginError?.code === 'invalid-password'
              ? 'Incorrect password. Try Again'
              : undefined
          }
          mb="lg"
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <RadioList
          aria-label="Login session length"
          value={sessionSetting}
          onChange={setSessionSetting}
          options={[
            {
              value: 'save-on-this-computer',
              label: 'Save my Username and Password on this device',
            },
            {
              value: 'logout-after-15-min',
              label: 'Automatically log me out after 15 minutes of inactivity',
            },
            {
              value: 'save-until-browser-shutdown',
              label: 'Stay logged in until I shut down my browser',
            },
          ]}
        />
        <div>
          <Button
            mt="md"
            label={(isLoggingIn || isLoggingInByIp) ? 'Signing In...' : 'Sign In'}
            type="submit"
          />
        </div>
        <Status visuallyHidden message={(isLoggingIn || isLoggingInByIp) ? 'Signing in...' : ''} />
        <Status
          message={
            loginError && loginError?.code !== 'invalid-password'
              ? 'An error occurred while trying to sign in.  Please try again later'
              : ''
          }
          onRender={(message) => (
            <Text color="error" mt="sm">
              {message}
            </Text>
          )}
        />
        <ForgotPasswordLink to="/forgot-password">
          Forgot your password?
        </ForgotPasswordLink>
      </form>
    </Page>
  );
}

const ForgotPasswordLink = styled(Link)((props) => ({
  display: 'block',
  color: '#333333',
  marginTop: props.theme.spacing.md,
}));
