import styled, { DefaultTheme } from 'styled-components/macro';
import { MarginProps, getMarginStyles } from '../../utils/style';

interface ListItemProps {
  weight?: 'regular' | 'bold';
  gap?: keyof DefaultTheme['spacing'];
}

export const ListItem = styled.li<ListItemProps>((props) => ({
  fontWeight: props.weight === 'bold' ? 700 : 400,
  marginBottom: props.gap ? props.theme.spacing[props.gap] : 0,
  '&:last-child': {
    marginBottom: 0,
  },
}));
