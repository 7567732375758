import * as React from 'react';
import { ScreenReaderOnly } from '../ScreenReaderOnly';

export function LoadingScreenReaderMessage (props: {
  message?: string;
  loading: boolean | undefined;
}) {
  const message = props.loading ? props.message || 'Loading data' : '';

  return <ScreenReaderOnly>{message}</ScreenReaderOnly>;
}
