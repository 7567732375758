/**
 * Accessible accordion component
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 */
/* eslint-disable */
import * as React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useId } from '../../hooks';
import { ScreenReaderOnly } from '../ScreenReaderOnly';

import { Heading, HeadingProps } from '../Heading';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { ArrowUpIcon } from '../../icons/ArrowUpIcon';
import { Text } from '../Text';
import { Row } from '../Row';
import { HideOnMobile, HideOnPrint, HideOnTablet } from '../HideOnMobile';
import { useEffect } from 'react';
import { Hidden } from 'react-grid-system';
import "./index.scss";

const AccordionIdContext = React.createContext<{
  headingId: string;
  contentId: string;
}>({ headingId: '', contentId: '' });
const AccordionOpenContext = React.createContext<boolean>(false);
const AccordionSetIsOpenContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export function Accordion(props: {
  children: React.ReactNode;
  openOnLoad?: boolean;
}) {
  const headingId = useId();
  const contentId = useId();
  const ids = React.useRef({ headingId, contentId }).current;
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  useEffect(() => setIsOpen(!!props.openOnLoad), [props.openOnLoad]);

  return (
    <AccordionIdContext.Provider value={ids}>
      <AccordionOpenContext.Provider value={isOpen}>
        <AccordionSetIsOpenContext.Provider value={setIsOpen}>
          {props.children}
        </AccordionSetIsOpenContext.Provider>
      </AccordionOpenContext.Provider>
    </AccordionIdContext.Provider>
  );
}

export interface AccordionHeadingProps {
  expand?: boolean;
  setExpand?: any;
  level: '1' | '2' | '3' | '4' | '5' | '6';
  size?: HeadingProps['size'];
  weight?: HeadingProps['weight'];
  showArrowText?: '0' | '1';
  children: React.ReactNode;
  // isDisable?: boolean;
}

Accordion.Heading = function AccordionHeading(props: AccordionHeadingProps) {
  const ids = React.useContext(AccordionIdContext);
  const isOpen = React.useContext(AccordionOpenContext);
  const setIsOpen = React.useContext(AccordionSetIsOpenContext);

  return (
    <Heading level={props.level} size={props.size} weight={props.weight} showArrowText={props.showArrowText}>
      <StyledButton
        // disabled={props.isDisable}
        type='button'
        id={ids.headingId}
        aria-controls={ids.contentId}
        aria-expanded={isOpen}
        onClick={() => {
          setIsOpen((value) => !value);
        }}
      >
        {props.children}
        {isOpen ? null : (
          <Row>
            <HideOnPrint>
              <HideOnTablet>
                <HideOnMobile>
                  {props.showArrowText == '0' ?  null : (
                  <Text mt='xs' mr='sm' weight='medium'>
                    <span style={{ whiteSpace: 'nowrap' }}>Show More</span>
                  </Text>
                  )}
                </HideOnMobile>
              </HideOnTablet>
            </HideOnPrint>
            <HideOnPrint>
              <ArrowDownIcon />
            </HideOnPrint>
          </Row>
        )}
      </StyledButton>
    </Heading>
  );
};

const StyledButton = styled.button({
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'inherit',
});

Accordion.Footer = function AccordionContent(props: AccordionHeadingProps) {
  const ids = React.useContext(AccordionIdContext);
  const isOpen = React.useContext(AccordionOpenContext);
  const setIsOpen = React.useContext(AccordionSetIsOpenContext);
  return (
    <Heading level={props.level} size={props.size} weight={props.weight} showArrowText={props.showArrowText}>
      <StyledButton
        type='button'
        id={`footer_${ids.headingId}`}
        // disabled={props.isDisable}
        aria-controls={ids.contentId}
        aria-expanded={isOpen}
        onClick={() => {
          setIsOpen((value) => !value);
        }}
      >
        {props.children}
        {isOpen ? (
          <Row>
            <HideOnPrint>
              {props.showArrowText == '0' ? null : (
              <Text mt='xs' mr='sm' weight='medium'>
                Show Less{props.showArrowText}
              </Text>
              )}
            </HideOnPrint>
            <HideOnPrint>
              <ArrowUpIcon />
            </HideOnPrint>
          </Row>
        ) : (
          <ScreenReaderOnly>Show More</ScreenReaderOnly>
        )}
      </StyledButton>
    </Heading>
  );
};

Accordion.Content = function AccordionContent(props: {
  children: React.ReactNode;
  isAccordianOverflowStyling?: boolean;
  isOverflowScrollStyling?: boolean;
}) {
  const ids = React.useContext(AccordionIdContext);
  const isOpen = React.useContext(AccordionOpenContext);

  return (
    <div id={ids.contentId} role='region' aria-labelledby={ids.headingId}>
      <StyledAccordionContent
			className={`${props.isOverflowScrollStyling ? 'accordian--scroll--styling' : ''}`}
        style={{ 
					display: isOpen ? 'block' : 'none',
			    overflowX: props.isAccordianOverflowStyling ? 'hidden' : props.isOverflowScrollStyling ? 'auto' : 'unset' 
			}}
      >
        {props.children}
      </StyledAccordionContent>
    </div>
  );
};

const StyledAccordionContent = styled.div((props) => ({
  paddingTop: props.theme.spacing.md,
}));
