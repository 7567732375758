/* eslint-disable */
import * as React from "react";
import { useHistory } from "react-router";
import { Page } from "../../../components/Page";
import { Heading } from "../../../components/Heading";
import { useId, usePreviousStorageData } from "../../../hooks";
import { TextField } from "../../../components/TextField";
import { RadioList } from "../../../components/RadioList";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text";
import { Row } from '../../../components/Row';
import { Tabs, Tab } from '../../../components/Tabs';
import { Accordion } from '../../../components/Accordion';
import { Card } from '../../../components/Card';
import { DateRangePicker } from "../../../components/DateRangePicker";
import { SelectField } from '../../../components/SelectField';
import { SelectFieldLtGt } from '../../../components/SelectFieldLtGt';
import { MultiSelectField } from '../../../components/MultiSelectField';
import { MultiSelectFieldChips } from '../../../components/MultiSelectFieldChips';
import ClearButton from "components/ClearButton/index";
import { useSavedSearches, useRefreshSavedSearches, useGetAccessToken } from '../../../containers/AuthProvider';
import { getMergersAcquisitionsSearchResults, deleteSearch, useNaicsCodes } from '../../../data';
import { dateFilter } from "../../../utils";
import "components/ClearButton/index.scss";
import "./searchMna.scss";
import { TrashIcon } from '../../../icons/TrashIcon';
import { StarIcon } from '../../../icons/StarIcon';
import toast, { Toaster } from 'react-hot-toast';
import { CheckIcon } from '../../../icons/CheckIcon';
import { CommonSearchSelectedResult } from "components/CommonSearchSelectedResult";
import { ScreenReaderOnly } from 'components/ScreenReaderOnly';

import {
  gicsLookup,
  locationLookup,
  sicLookup,
  advisorLookup,
  legalAdvisorLookup,
  typeLookup,
  targetTypeLookup,
  fpIndustryLookup,
  dealValueGTLookup,
  dealValueLTLookup,
	percentageBoughtGTLookup,
	percentageBoughtLTLookup,
  searchTypeMnaLookup
} from "../../../data/lookups";
import moment from "moment";

import {
  NaicsSearchResults
} from "../../../data";

export function MergersAcquisitionsSearchPage() {
  const history = useHistory();
  const headingId = useId();
  const headingAdvId = useId();
  const [selectedSavedSearch, setSelectedSavedSearch] = React.useState('');
  const [newSavedSearchName, setNewSavedSearchName] = React.useState('');
  
  const [companyName, setCompmanyName] = React.useState("");

  const [searchRestrictTarget, setSearchRestrictTarget] = React.useState(
    localStorage.getItem("chk_search_restrict_target_MA") === "true"
  );
  const [searchRestrictVendor, setSearchRestrictVendor] = React.useState(
    localStorage.getItem("chk_search_restrict_vendor_MA") === "true"
  );
  const [searchRestrictAcquiror, setSearchRestrictAcquiror] = React.useState(
    localStorage.getItem("chk_search_restrict_acquiror_MA") === "true"
  );

  const [searchTargetType, setSearchTargetType] = React.useState("");
	const [searchVendorType, setSearchVendorType] = React.useState("");
	const [searchAcquirorType, setSearchAcquirorType] = React.useState("");
  const [searchTargetFPIndustry, setSearchTargetFPIndustry] =
    React.useState("");
	const [searchVendorFPIndustry, setSearchVendorFPIndustry] =
		React.useState("");
	const [searchAcquirorFPIndustry, setSearchAcquirorFPIndustry] =
		React.useState("");
  const [naicsLookupCodes, setNaicsLookupCodes] =  React.useState<any>([{ id: '', name: 'NAICS Codes' }]);
  const [searchTargetNAICS, setSearchTargetNAICS] = React.useState("");
  const [searchVendorNAICS, setSearchVendorNAICS] = React.useState("");
  const [searchAcquirorNAICS, setSearchAcquirorNAICS] = React.useState("");
  const [searchTargetSIC, setSearchTargetSIC] = React.useState("");
  const [searchVendorSIC, setSearchVendorSIC] = React.useState("");
  const [searchAcquirorSIC, setSearchAcquirorSIC] = React.useState("");
  const [searchTargetGICS, setSearchTargetGICS] = React.useState("");
  const [searchVendorGICS, setSearchVendorGICS] = React.useState("");
  const [searchAcquirorGICS, setSearchAcquirorGICS] = React.useState("");
	const [searchLocationTarget, setSearchLocationTarget] = React.useState<string[]>([]);
	const [searchLocationVendor, setSearchLocationVendor] = React.useState<string[]>([]);
	const [searchLocationAcquiror, setSearchLocationAcquiror] = React.useState<string[]>([]);
  const [searchFinancialAdvisor, setSearchFinancialAdvisor] =
    React.useState("");
  const [searchLegalAdvisor, setSearchLegalAdvisor] = React.useState("");

  const [searchDealValueGt, setSearchDealValueGt] = React.useState("");
  const [searchDealValueLt, setSearchDealValueLt] = React.useState("");
  const [searchPercentageBoughtGt,setSearchPercentageBoughtGt] = React.useState("");
  const [searchPercentageBoughtLt, setSearchPercentageBoughtLt] = React.useState("");
  
  const [searchPubliclyTraded, setSearchPubliclyTraded] =
    React.useState(
      localStorage.getItem("chk_publicly_traded_MA") === "true"
    );
	const [searchDivestiture, setSearchDivestiture] =
    React.useState(
      localStorage.getItem("chk_divestiture_MA") === "true"
    );
	const [searchPrivatelyOwned, setSearchPrivatelyOwned] =
		React.useState(
			localStorage.getItem("chk_privately_owned_MA") === "true"
		);
	const [searchForeignTarget, setSearchForeignTarget] =
		React.useState(
			localStorage.getItem("chk_foreign_target_MA") === "true"
		);
	const [searchPaymentCash, setSearchPaymentCash] =
		React.useState(
			localStorage.getItem("chk_payment_cash_MA") === "true"
		);
	const [searchPaymentStock, setSearchPaymentStock] =
		React.useState(
			localStorage.getItem("chk_payment_stock_MA") === "true"
		);
	const [searchPaymentAssumptionDebt, setSearchPaymentAssumptionDebt] =
		React.useState(
			localStorage.getItem("chk_payment_assumption_debt_MA") === "true"
		);
	const [searchPaymentOther, setSearchPaymentOther] =
		React.useState(
			localStorage.getItem("chk_payment_other_MA") === "true"
		);
	const [searchDealDescription, setSearchDealDescription] =
		React.useState(
			localStorage.getItem("chk_deal_description_MA") === "true"
		);
	const [searchTargetBusinessDescription, setSearchTargetBusinessDescription] =
		React.useState(
			localStorage.getItem("chk_search_target_business_description_MA") === "true"
		);
	const [searchVendorBusinessDescription, setSearchVendorBusinessDescription] =
		React.useState(
			localStorage.getItem("chk_search_vendor_business_description_MA") === "true"
		);
	const [searchAcquirorBusinessDescription, setSearchAcquirorBusinessDescription] =
		React.useState(
			localStorage.getItem("chk_search_acquiror_business_description_MA") === "true"
		);

  const [searchDealStatusCompleted, setSearchDealStatusCompleted] =
    React.useState(
      localStorage.getItem("chk_deal_status_completed_MA") === "true"
    );

  const [searchDealStatusPending, setSearchDealStatusPending] = React.useState(
    localStorage.getItem("chk_deal_status_pending_MA") === "true"
  );

  const [searchDealStatusTerminated, setSearchDealStatusTerminated] =
    React.useState(
      localStorage.getItem("chk_deal_status_terminated_MA") === "true"
    );

  const [searchDatesAnnounced, setSearchDatesAnnounced] = React.useState(
    localStorage.getItem("chk_status_dates_announced_MA") === "true"
  );

  const [searchDatesUpdated, setSearchDatesUpdated] = React.useState(
    localStorage.getItem("chk_status_dates_updated_MA") === "true"
  );

  const [keywords, setKeywords] = React.useState("");
  const [searchType, setSearchType] = React.useState(
    localStorage.getItem('basicSearchRadioMnaMA') || 'E'
  );

  const [endDate, setEndDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getFullYear() - 1, 0, 1)
  );

  const [dateClear, setDateClear] = React.useState(false);

  const { data } = useNaicsCodes();
  const { searches: savedSearches } = useSavedSearches();
  const { refreshSavedSearches } = useRefreshSavedSearches();
  
  React.useEffect(() => {
    if (history.action === "POP") {
      loadLatestEntries();
    } else {
      clearCheck();
    }
    refreshSavedSearches();
  }, []);

  const loadLatestEntries = () => {
    const selectedSavedSearchMA = localStorage.getItem('selectedSavedSearchMA');
    if (selectedSavedSearchMA) {
      setSelectedSavedSearch(JSON.parse(selectedSavedSearchMA));
    }
    const companyNameMA = localStorage.getItem('companyNameMA');
    if (companyNameMA) {
      setCompmanyName(JSON.parse(companyNameMA));
    }
    const chkSearchRestrictTargetMA = localStorage.getItem('chk_search_restrict_target_MA');
    if (chkSearchRestrictTargetMA) {
      setSearchRestrictTarget(JSON.parse(chkSearchRestrictTargetMA));
    }
    const chkSearchRestrictVendorMA = localStorage.getItem('chk_search_restrict_vendor_MA');
    if (chkSearchRestrictVendorMA) {
      setSearchRestrictVendor(JSON.parse(chkSearchRestrictVendorMA));
    }
    const chkSearchRestrictAcquirorMA = localStorage.getItem('chk_search_restrict_acquiror_MA');
    if (chkSearchRestrictAcquirorMA) {
      setSearchRestrictAcquiror(JSON.parse(chkSearchRestrictAcquirorMA));
    }
    const searchTargetTypeMA = localStorage.getItem('searchTargetTypeMA');
    if (searchTargetTypeMA) {
      setSearchTargetType(JSON.parse(searchTargetTypeMA));
    }
    const searchVendorTypeMA = localStorage.getItem('searchVendorTypeMA');
    if (searchVendorTypeMA) {
      setSearchVendorType(JSON.parse(searchVendorTypeMA));
    }
    const searchAcquirorTypeMA = localStorage.getItem('searchAcquirorTypeMA');
    if (searchAcquirorTypeMA) {
      setSearchAcquirorType(JSON.parse(searchAcquirorTypeMA));
    }
    const searchTargetFPIndustryMA = localStorage.getItem('searchTargetFPIndustryMA');
    if (searchTargetFPIndustryMA) {
      setSearchTargetFPIndustry(JSON.parse(searchTargetFPIndustryMA));
    }
    const searchVendorFPIndustryMA = localStorage.getItem('searchVendorFPIndustryMA');
    if (searchVendorFPIndustryMA) {
      setSearchVendorFPIndustry(JSON.parse(searchVendorFPIndustryMA));
    }
    const searchAcquirorFPIndustryMA = localStorage.getItem('searchAcquirorFPIndustryMA');
    if (searchAcquirorFPIndustryMA) {
      setSearchAcquirorFPIndustry(JSON.parse(searchAcquirorFPIndustryMA));
    }
    const searchTargetNAICSMA = localStorage.getItem('searchTargetNAICSMA');
    if (searchTargetNAICSMA) {
      setSearchTargetNAICS(JSON.parse(searchTargetNAICSMA));
    }
    const searchVendorNAICSMA = localStorage.getItem('searchVendorNAICSMA');
    if (searchVendorNAICSMA) {
      setSearchVendorNAICS(JSON.parse(searchVendorNAICSMA));
    }
    const searchAcquirorNAICSMA = localStorage.getItem('searchAcquirorNAICSMA');
    if (searchAcquirorNAICSMA) {
      setSearchAcquirorNAICS(JSON.parse(searchAcquirorNAICSMA));
    }
    const searchTargetSICMA = localStorage.getItem('searchTargetSICMA');
    if (searchTargetSICMA) {
      setSearchTargetSIC(JSON.parse(searchTargetSICMA));
    }
    const searchVendorSICMA = localStorage.getItem('searchVendorSICMA');
    if (searchVendorSICMA) {
      setSearchVendorSIC(JSON.parse(searchVendorSICMA));
    }
    const searchAcquirorSICMA = localStorage.getItem('searchAcquirorSICMA');
    if (searchAcquirorSICMA) {
      setSearchAcquirorSIC(JSON.parse(searchAcquirorSICMA));
    }
    const searchTargetGICSMA = localStorage.getItem('searchTargetGICSMA');
    if (searchTargetGICSMA) {
      setSearchTargetGICS(JSON.parse(searchTargetGICSMA));
    }
    const searchVendorGICSMA = localStorage.getItem('searchVendorGICSMA');
    if (searchVendorGICSMA) {
      setSearchVendorGICS(JSON.parse(searchVendorGICSMA));
    }
    const searchAcquirorGICSMA = localStorage.getItem('searchAcquirorGICSMA');
    if (searchAcquirorGICSMA) {
      setSearchAcquirorGICS(JSON.parse(searchAcquirorGICSMA));
    }
    const searchLocationTargetMA = localStorage.getItem('searchLocationTargetMA');
    if (searchLocationTargetMA) {
      setSearchLocationTarget(JSON.parse(searchLocationTargetMA));
    }
    const searchLocationVendorMA = localStorage.getItem('searchLocationVendorMA');
    if (searchLocationVendorMA) {
      setSearchLocationVendor(JSON.parse(searchLocationVendorMA));
    }
    const searchLocationAcquirorMA = localStorage.getItem('searchLocationAcquirorMA');
    if (searchLocationAcquirorMA) {
      setSearchLocationAcquiror(JSON.parse(searchLocationAcquirorMA));
    }
    const chkPubliclyTradedMA = localStorage.getItem('chk_publicly_traded_MA');
    if (chkPubliclyTradedMA) {
      setSearchPubliclyTraded(JSON.parse(chkPubliclyTradedMA));
    }
    const chkDivestitureMA = localStorage.getItem('chk_divestiture_MA');
    if (chkDivestitureMA) {
      setSearchDivestiture(JSON.parse(chkDivestitureMA));
    }
    const chkPrivatelyOwnedMA = localStorage.getItem('chk_privately_owned_MA');
    if (chkPrivatelyOwnedMA) {
      setSearchPrivatelyOwned(JSON.parse(chkPrivatelyOwnedMA));
    }
    const chkForeignTargetMA = localStorage.getItem('chk_foreign_target_MA');
    if (chkForeignTargetMA) {
      setSearchForeignTarget(JSON.parse(chkForeignTargetMA));
    }
    const searchFinancialAdvisorMA = localStorage.getItem('searchFinancialAdvisorMA');
    if (searchFinancialAdvisorMA) {
      setSearchFinancialAdvisor(JSON.parse(searchFinancialAdvisorMA));
    }
    const searchLegalAdvisorMA = localStorage.getItem('searchLegalAdvisorMA');
    if (searchLegalAdvisorMA) {
      setSearchLegalAdvisor(JSON.parse(searchLegalAdvisorMA));
    }
    const searchDealValueGtMA = localStorage.getItem('searchDealValueGtMA');
    if (searchDealValueGtMA) {
      setSearchDealValueGt(JSON.parse(searchDealValueGtMA));
    }
    const searchDealValueLtMA = localStorage.getItem('searchDealValueLtMA');
    if (searchDealValueLtMA) {
      setSearchDealValueLt(JSON.parse(searchDealValueLtMA));
    }
    const searchPercentageBoughtGtMA = localStorage.getItem('searchPercentageBoughtGtMA');
    if (searchPercentageBoughtGtMA) {
      setSearchPercentageBoughtGt(JSON.parse(searchPercentageBoughtGtMA));
    }
    const searchPercentageBoughtLtMA = localStorage.getItem('searchPercentageBoughtLtMA');
    if (searchPercentageBoughtLtMA) {
      setSearchPercentageBoughtLt(JSON.parse(searchPercentageBoughtLtMA));
    }
    const chkPaymentCashMA = localStorage.getItem('chk_payment_cash_MA');
    if (chkPaymentCashMA) {
      setSearchPaymentCash(JSON.parse(chkPaymentCashMA));
    }
    const chkPaymentStockMA = localStorage.getItem('chk_payment_stock_MA');
    if (chkPaymentStockMA) {
      setSearchPaymentStock(JSON.parse(chkPaymentStockMA));
    }
    const chkPaymentAssumptionDebtMA = localStorage.getItem('chk_payment_assumption_debt_MA');
    if (chkPaymentAssumptionDebtMA) {
      setSearchPaymentAssumptionDebt(JSON.parse(chkPaymentAssumptionDebtMA));
    }
    const chkPaymentOtherMA = localStorage.getItem('chk_payment_other_MA');
    if (chkPaymentOtherMA) {
      setSearchPaymentOther(JSON.parse(chkPaymentOtherMA));
    }
    const keywordsMA = localStorage.getItem('keywordsMA');
    if (keywordsMA) {
      setKeywords(JSON.parse(keywordsMA));
    }
    const chkDealDescriptionMA = localStorage.getItem('chk_deal_description_MA');
    if (chkDealDescriptionMA) {
      setSearchDealDescription(JSON.parse(chkDealDescriptionMA));
    }
    const chkSearchTargetBusinessDescriptionMA = localStorage.getItem('chk_search_target_business_description_MA');
    if (chkSearchTargetBusinessDescriptionMA) {
      setSearchTargetBusinessDescription(JSON.parse(chkSearchTargetBusinessDescriptionMA));
    }
    const chkSearchVendorBusinessDescriptionMA = localStorage.getItem('chk_search_vendor_business_description_MA');
    if (chkSearchVendorBusinessDescriptionMA) {
      setSearchVendorBusinessDescription(JSON.parse(chkSearchVendorBusinessDescriptionMA));
    }
    const chkSearchAcquirorBusinessDescriptionMA = localStorage.getItem('chk_search_acquiror_business_description_MA');
    if (chkSearchAcquirorBusinessDescriptionMA) {
      setSearchAcquirorBusinessDescription(JSON.parse(chkSearchAcquirorBusinessDescriptionMA));
    }
    const chkStatusDatesAnnouncedMA = localStorage.getItem('chk_status_dates_announced_MA');
    if (chkStatusDatesAnnouncedMA) {
      setSearchDatesAnnounced(JSON.parse(chkStatusDatesAnnouncedMA));
    }
    const chkStatusDatesUpdatedMA = localStorage.getItem('chk_status_dates_updated_MA');
    if (chkStatusDatesUpdatedMA) {
      setSearchDatesUpdated(JSON.parse(chkStatusDatesUpdatedMA));
    }
    const chkDealStatusCompletedMA = localStorage.getItem('chk_deal_status_completed_MA');
    if (chkDealStatusCompletedMA) {
      setSearchDealStatusCompleted(JSON.parse(chkDealStatusCompletedMA));
    }
    const chkDealStatusPendingMA = localStorage.getItem('chk_deal_status_pending_MA');
    if (chkDealStatusPendingMA) {
      setSearchDealStatusPending(JSON.parse(chkDealStatusPendingMA));
    }
    const chkDealStatusTerminatedMA = localStorage.getItem('chk_deal_status_terminated_MA');
    if (chkDealStatusTerminatedMA) {
      setSearchDealStatusTerminated(JSON.parse(chkDealStatusTerminatedMA));
    }
    const startDateMA = localStorage.getItem('startDateMA');
    if (startDateMA) {
      setStartDate(new Date(JSON.parse(startDateMA)));
    }
    const endDateMA = localStorage.getItem('endDateMA');
    if (endDateMA) {
      setEndDate(new Date(JSON.parse(endDateMA)));
    }
  }
  
  React.useEffect(() => {
    if (data) {
      setNaicsLookupCodes(data);
      localStorage.setItem('naicsLookupCodes', JSON.stringify(data));
    }
  }, [data]);

  {
    /* selectedSavedSearch*/
  }
  const prevSelectedSavedSearch = usePreviousStorageData(selectedSavedSearch) || '';
  React.useEffect(() => {
    if (prevSelectedSavedSearch?.length !== selectedSavedSearch.length) {
      localStorage.setItem('selectedSavedSearchMA', JSON.stringify(selectedSavedSearch));
    }
  }, [selectedSavedSearch]);

  {
  /* CompanyName*/
  }
  const prevCompanyName = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (prevCompanyName?.length !== companyName.length) {
      localStorage.setItem('companyNameMA', JSON.stringify(companyName));
    }
  }, [companyName]);

  {
    /* searchRestrictTarget*/
  }
  const prevRestrictTarget = usePreviousStorageData(searchRestrictTarget) || '';
  React.useEffect(() => {
    if (prevRestrictTarget?.length !== searchRestrictTarget.toString().length) {
      localStorage.setItem('chk_search_restrict_target_MA', JSON.stringify(searchRestrictTarget));
    }
  }, [searchRestrictTarget]);

  {
    /* searchRestrictVendor*/
  }
  const prevRestrictVendor = usePreviousStorageData(searchRestrictVendor) || '';
  React.useEffect(() => {
    if (prevRestrictVendor?.length !== searchRestrictVendor.toString().length) {
      localStorage.setItem('chk_search_restrict_vendor_MA', JSON.stringify(searchRestrictVendor));
    }
  }, [searchRestrictVendor]);

  {
    /* searchRestrictAcquiror*/
  }
  const prevRestrictAcquiror = usePreviousStorageData(searchRestrictAcquiror) || '';
  React.useEffect(() => {
    if (prevRestrictAcquiror?.length !== searchRestrictAcquiror.toString().length) {
      localStorage.setItem('chk_search_restrict_acquiror_MA', JSON.stringify(searchRestrictAcquiror));
    }
  }, [searchRestrictAcquiror]);

  {
    /* searchTargetType*/
  }
  const searchTarget = usePreviousStorageData(searchTargetType) || '';
  React.useEffect(() => {
    if (searchTarget?.length !== searchTargetType.length) {
      localStorage.setItem('searchTargetTypeMA', JSON.stringify(searchTargetType));
    }
  }, [searchTargetType]);

	{
    /* searchVendorType*/
  }
  const searchVendor = usePreviousStorageData(searchVendorType) || '';
  React.useEffect(() => {
    if (searchVendor?.length !== searchVendorType.length) {
      localStorage.setItem(
        'searchVendorTypeMA',
        JSON.stringify(searchVendorType)
      );
    }
  }, [searchVendorType]);

	{
    /* searchAcquirorType*/
  }
  const searchAcquiror = usePreviousStorageData(searchAcquirorType) || '';
  React.useEffect(() => {
    if (searchAcquiror?.length !== searchAcquirorType.length) {
      localStorage.setItem(
        'searchAcquirorTypeMA',
        JSON.stringify(searchAcquirorType)
      );
    }
  }, [searchAcquirorType]);

  {
    /* searchTargetFPIndustry */
  }
  const searchTargetFPI = usePreviousStorageData(searchTargetFPIndustry) || '';
  React.useEffect(() => {
    if (searchTargetFPI?.length !== searchTargetFPIndustry.length) {
      localStorage.setItem(
        'searchTargetFPIndustryMA',
        JSON.stringify(searchTargetFPIndustry)
      );
    }
  }, [searchTargetFPIndustry]);

	{
    /* searchVendorFPIndustry */
  }
  const searchVendorFPI = usePreviousStorageData(searchVendorFPIndustry) || '';
  React.useEffect(() => {
    if (searchVendorFPI?.length !== searchVendorFPIndustry.length) {
      localStorage.setItem(
        'searchVendorFPIndustryMA',
        JSON.stringify(searchVendorFPIndustry)
      );
    }
  }, [searchVendorFPIndustry]);
  
	{
    /* searchAcquirorFPIndustry */
  }
  const searchAcquirorFPI = usePreviousStorageData(searchAcquirorFPIndustry) || '';
  React.useEffect(() => {
    if (searchAcquirorFPI?.length !== searchAcquirorFPIndustry.length) {
      localStorage.setItem(
        'searchAcquirorFPIndustryMA',
        JSON.stringify(searchAcquirorFPIndustry)
      );
    }
  }, [searchAcquirorFPIndustry]);

  {
    /*searchTargetNAICS*/
  }
  const searchTargetNA = usePreviousStorageData(searchTargetNAICS) || '';
  React.useEffect(() => {
    if (searchTargetNA?.length !== searchTargetNAICS.length) {
      localStorage.setItem(
        'searchTargetNAICSMA',
        JSON.stringify(searchTargetNAICS)
      );
    }
  }, [searchTargetNAICS]);
  
  {
    /*searchVendorNAICS*/
  }
  const searchVendorNA = usePreviousStorageData(searchVendorNAICS) || '';
  React.useEffect(() => {
    if (searchVendorNA?.length !== searchVendorNAICS.length) {
      localStorage.setItem(
        'searchVendorNAICSMA',
        JSON.stringify(searchVendorNAICS)
      );
    }
  }, [searchVendorNAICS]);

	{
    /*searchAcquirorNAICS*/
  }
  const searchAcquirorNA = usePreviousStorageData(searchAcquirorNAICS) || '';
  React.useEffect(() => {
    if (searchAcquirorNA?.length !== searchAcquirorNAICS.length) {
      localStorage.setItem(
        'searchAcquirorNAICSMA',
        JSON.stringify(searchAcquirorNAICS)
      );
    }
  }, [searchAcquirorNAICS]);

  {
    /* searchTargetSIC*/
  }
  const searchTargetSI = usePreviousStorageData(searchTargetSIC) || '';
  React.useEffect(() => {
    if (searchTargetSI?.length !== searchTargetSIC.length) {
      localStorage.setItem(
        'searchTargetSICMA',
        JSON.stringify(searchTargetSIC)
      );
    }
  }, [searchTargetSIC]);
  
	{
    /* searchVendorSIC*/
  }
  const searchVendorSI = usePreviousStorageData(searchVendorSIC) || '';
  React.useEffect(() => {
    if (searchVendorSI?.length !== searchVendorSIC.length) {
      localStorage.setItem(
        'searchVendorSICMA',
        JSON.stringify(searchVendorSIC)
      );
    }
  }, [searchVendorSIC]);

	{
    /* searchAcquirorSIC*/
  }
  const searchAcquirorSI = usePreviousStorageData(searchAcquirorSIC) || '';
  React.useEffect(() => {
    if (searchAcquirorSI?.length !== searchAcquirorSIC.length) {
      localStorage.setItem(
        'searchAcquirorSICMA',
        JSON.stringify(searchAcquirorSIC)
      );
    }
  }, [searchAcquirorSIC]);

  {
    /*searchTargetGICS*/
  }
  const searchTargetGICSName = usePreviousStorageData(searchTargetGICS) || '';
  React.useEffect(() => {
    if (searchTargetGICSName?.length !== searchTargetGICS.length) {
      localStorage.setItem(
        'searchTargetGICSMA',
        JSON.stringify(searchTargetGICS)
      );
    }
  }, [searchTargetGICS]);
  
  {
    /*searchVendorGICS*/
  }
  const searchVendorGICSName = usePreviousStorageData(searchVendorGICS) || '';
  React.useEffect(() => {
    if (searchVendorGICSName?.length !== searchVendorGICS.length) {
      localStorage.setItem(
        'searchVendorGICSMA',
        JSON.stringify(searchVendorGICS)
      );
    }
  }, [searchVendorGICS]);
	
	{
    /*searchAcquirorGICS*/
  }
  const searchAcquirorGICSName = usePreviousStorageData(searchAcquirorGICS) || '';
  React.useEffect(() => {
    if (searchAcquirorGICSName?.length !== searchAcquirorGICS.length) {
      localStorage.setItem(
        'searchAcquirorGICSMA',
        JSON.stringify(searchAcquirorGICS)
      );
    }
  }, [searchAcquirorGICS]);

	{
    /*searchLocationTarget*/
  }
  const searchTargetLocation = usePreviousStorageData(searchLocationTarget) || [];
  React.useEffect(() => {
    if (searchTargetLocation?.length !== searchLocationTarget.length) {
      localStorage.setItem(
        'searchLocationTargetMA',
        JSON.stringify(searchLocationTarget)
      );
    }
  }, [searchLocationTarget]);

	{
    /*searchLocationVendor*/
  }
  const searchVendorLocation = usePreviousStorageData(searchLocationVendor) || [];
  React.useEffect(() => {
    if (searchVendorLocation?.length !== searchLocationVendor.length) {
      localStorage.setItem(
        'searchLocationVendor',
        JSON.stringify(searchLocationVendor)
      );
    }
  }, [searchLocationVendor]);
  
	{
    /*searchLocationAcquiror*/
  }
  const searchAcquirorLocation = usePreviousStorageData(searchLocationAcquiror) || [];
  React.useEffect(() => {
    if (searchAcquirorLocation?.length !== searchLocationAcquiror.length) {
      localStorage.setItem(
        'searchLocationAcquirorMA',
        JSON.stringify(searchLocationAcquiror)
      );
    }
  }, [searchLocationAcquiror]);

  {
    /* searchPubliclyTraded*/
  }
  const prevPubliclyTraded = usePreviousStorageData(searchPubliclyTraded) || '';
  React.useEffect(() => {
    if (prevPubliclyTraded?.length !== searchPubliclyTraded.toString().length) {
      localStorage.setItem('chk_publicly_traded_MA', JSON.stringify(searchPubliclyTraded));
    }
  }, [searchPubliclyTraded]);

  {
    /* searchDivestiture*/
  }
  const prevDivestiture = usePreviousStorageData(searchDivestiture) || '';
  React.useEffect(() => {
    if (prevDivestiture?.length !== searchDivestiture.toString().length) {
      localStorage.setItem('chk_divestiture_MA', JSON.stringify(searchDivestiture));
    }
  }, [searchDivestiture]);

  {
    /* searchPrivatelyOwned*/
  }
  const prevPrivatelyOwned = usePreviousStorageData(searchPrivatelyOwned) || '';
  React.useEffect(() => {
    if (prevPrivatelyOwned?.length !== searchPrivatelyOwned.toString().length) {
      localStorage.setItem('chk_privately_owned_MA', JSON.stringify(searchPrivatelyOwned));
    }
  }, [searchPrivatelyOwned]);

  {
    /* searchForeignTarget*/
  }
  const prevForeignTarget = usePreviousStorageData(searchForeignTarget) || '';
  React.useEffect(() => {
    if (prevForeignTarget?.length !== searchForeignTarget.toString().length) {
      localStorage.setItem('chk_foreign_target_MA', JSON.stringify(searchForeignTarget));
    }
  }, [searchForeignTarget]);

  {
    /*searchFinancialAdvisor*/
  }
  const searchTargetFA = usePreviousStorageData(searchFinancialAdvisor) || '';
  React.useEffect(() => {
    if (searchTargetFA?.length !== searchFinancialAdvisor.length) {
      localStorage.setItem(
        'searchFinancialAdvisorMA',
        JSON.stringify(searchFinancialAdvisor)
      );
    }
  }, [searchFinancialAdvisor]);

  {
    /* searchLegalAdvisor*/
  }
  const searchTargetAD = usePreviousStorageData(searchLegalAdvisor) || '';
  React.useEffect(() => {
    if (searchTargetAD?.length !== searchLegalAdvisor.length) {
      localStorage.setItem(
        'searchLegalAdvisorMA',
        JSON.stringify(searchLegalAdvisor)
      );
    }
  }, [searchLegalAdvisor]);

  {
    /*searchDealValueGt*/
  }
  const searchGT = usePreviousStorageData(searchDealValueGt) || '';
  React.useEffect(() => {
    if (searchGT !== searchDealValueGt) {
      localStorage.setItem(
        'searchDealValueGtMA',
        JSON.stringify(searchDealValueGt)
      );
    }
  }, [searchDealValueGt]);

  {
    /*searchDealValueLt*/
  }
  const searchLT = usePreviousStorageData(searchDealValueLt) || '';
  React.useEffect(() => {
    if (searchLT !== searchDealValueLt) {
      localStorage.setItem(
        'searchDealValueLtMA',
        JSON.stringify(searchDealValueLt)
      );
    }
  }, [searchDealValueLt]);

	{
    /*searchPercentageBoughtGt*/
  }
  const searchPercentagGT = usePreviousStorageData(searchPercentageBoughtGt) || '';
  React.useEffect(() => {
    if (searchPercentagGT !== searchPercentageBoughtGt) {
      localStorage.setItem(
        'searchPercentageBoughtGtMA',
        JSON.stringify(searchPercentageBoughtGt)
      );
    }
  }, [searchPercentageBoughtGt]);

	{
    /*searchPercentageBoughtLt*/
  }
  const searchPercentagLT = usePreviousStorageData(searchPercentageBoughtLt) || '';
  React.useEffect(() => {
    if (searchPercentagLT !== searchPercentageBoughtLt) {
      localStorage.setItem(
        'searchPercentageBoughtLtMA',
        JSON.stringify(searchPercentageBoughtLt)
      );
    }
  }, [searchPercentageBoughtLt]);

  {
    /* searchPaymentCash*/
  }
  const prevPaymentCash = usePreviousStorageData(searchPaymentCash) || '';
  React.useEffect(() => {
    if (prevPaymentCash?.length !== searchPaymentCash.toString().length) {
      localStorage.setItem('chk_payment_cash_MA', JSON.stringify(searchPaymentCash));
    }
  }, [searchPaymentCash]);

  {
    /* searchPaymentStock */
  }
  const prevPaymentStock = usePreviousStorageData(searchPaymentStock) || '';
  React.useEffect(() => {
    if (prevPaymentStock?.length !== searchPaymentStock.toString().length) {
      localStorage.setItem('chk_payment_stock_MA', JSON.stringify(searchPaymentStock));
    }
  }, [searchPaymentStock]);

  {
    /* searchPaymentAssumptionDebt */
  }
  const prevPaymentAssumptionDebt = usePreviousStorageData(searchPaymentAssumptionDebt) || '';
  React.useEffect(() => {
    if (prevPaymentAssumptionDebt?.length !== searchPaymentAssumptionDebt.toString().length) {
      localStorage.setItem('chk_payment_assumption_debt_MA', JSON.stringify(searchPaymentAssumptionDebt));
    }
  }, [searchPaymentAssumptionDebt]);

  {
    /* searchPaymentOther */
  }
  const prevPaymentOther = usePreviousStorageData(searchPaymentOther) || '';
  React.useEffect(() => {
    if (prevPaymentOther?.length !== searchPaymentOther.toString().length) {
      localStorage.setItem('chk_payment_other_MA', JSON.stringify(searchPaymentOther));
    }
  }, [searchPaymentOther]);

  {
    /* keywords*/
  }
  const prevKeywords = usePreviousStorageData(keywords) || '';
  React.useEffect(() => {
    if (prevKeywords?.length !== keywords.length) {
      localStorage.setItem('keywordsMA', JSON.stringify(keywords));
    }
  }, [keywords]);

  {
    /* searchDealDescription */
  }
  const prevDealDescription = usePreviousStorageData(searchDealDescription) || '';
  React.useEffect(() => {
    if (prevDealDescription?.length !== searchDealDescription.toString().length) {
      localStorage.setItem('chk_deal_description_MA', JSON.stringify(searchDealDescription));
    }
  }, [searchDealDescription]);

  {
    /* searchTargetBusinessDescription */
  }
  const prevTargetBusinessDescription = usePreviousStorageData(searchTargetBusinessDescription) || '';
  React.useEffect(() => {
    if (prevTargetBusinessDescription?.length !== searchTargetBusinessDescription.toString().length) {
      localStorage.setItem('chk_search_target_business_description_MA', JSON.stringify(searchTargetBusinessDescription));
    }
  }, [searchTargetBusinessDescription]);

  {
    /* searchVendorBusinessDescription */
  }
  const prevVendorBusinessDescription = usePreviousStorageData(searchVendorBusinessDescription) || '';
  React.useEffect(() => {
    if (prevVendorBusinessDescription?.length !== searchVendorBusinessDescription.toString().length) {
      localStorage.setItem('chk_search_vendor_business_description_MA', JSON.stringify(searchVendorBusinessDescription));
    }
  }, [searchVendorBusinessDescription]);

  {
    /* searchAcquirorBusinessDescription */
  }
  const prevAcquirorBusinessDescription = usePreviousStorageData(searchAcquirorBusinessDescription) || '';
  React.useEffect(() => {
    if (prevAcquirorBusinessDescription?.length !== searchAcquirorBusinessDescription.toString().length) {
      localStorage.setItem('chk_search_acquiror_business_description_MA', JSON.stringify(searchAcquirorBusinessDescription));
    }
  }, [searchAcquirorBusinessDescription]);

  {
    /* searchDatesAnnounced */
  }
  const prevDatesAnnounced = usePreviousStorageData(searchDatesAnnounced) || '';
  React.useEffect(() => {
    if (prevDatesAnnounced?.length !== searchDatesAnnounced.toString().length) {
      localStorage.setItem('chk_status_dates_announced_MA', JSON.stringify(searchDatesAnnounced));
    }
  }, [searchDatesAnnounced]);

  {
    /* searchDatesUpdated */
  }
  const prevDatesUpdated = usePreviousStorageData(searchDatesUpdated) || '';
  React.useEffect(() => {
    if (prevDatesUpdated?.length !== searchDatesUpdated.toString().length) {
      localStorage.setItem('chk_status_dates_updated_MA', JSON.stringify(searchDatesUpdated));
    }
  }, [searchDatesUpdated]);

  {
    /* searchDealStatusCompleted */
  }
  const prevDealStatusCompleted = usePreviousStorageData(searchDealStatusCompleted) || '';
  React.useEffect(() => {
    if (prevDealStatusCompleted?.length !== searchDealStatusCompleted.toString().length) {
      localStorage.setItem('chk_deal_status_completed_MA', JSON.stringify(searchDealStatusCompleted));
    }
  }, [searchDealStatusCompleted]);
  
  {
    /* searchDealStatusPending */
  }
  const prevDealStatusPending = usePreviousStorageData(searchDealStatusPending) || '';
  React.useEffect(() => {
    if (prevDealStatusPending?.length !== searchDealStatusPending.toString().length) {
      localStorage.setItem('chk_deal_status_pending_MA', JSON.stringify(searchDealStatusPending));
    }
  }, [searchDealStatusPending]);

  {
    /* searchDealStatusTerminated */
  }
  const prevDealStatusTerminated = usePreviousStorageData(searchDealStatusTerminated) || '';
  React.useEffect(() => {
    if (prevDealStatusTerminated?.length !== searchDealStatusTerminated.toString().length) {
      localStorage.setItem('chk_deal_status_terminated_MA', JSON.stringify(searchDealStatusTerminated));
    }
  }, [searchDealStatusTerminated]);

  {
    /*startDate*/
  }
  const searchStartDate = usePreviousStorageData(startDate) || '0';
  React.useEffect(() => {
    if (searchStartDate !== startDate.getTime().toString()) {
      localStorage.setItem(
        'startDateMA',
        startDate.getTime().toString()
      );
    }
  }, [startDate]);

  {
    /*endDate*/
  }
  const searchEndDate = usePreviousStorageData(endDate) || '0';
  React.useEffect(() => {
    if (searchEndDate !== endDate.getTime().toString()) {
      localStorage.setItem(
        'endDateMA',
        endDate.getTime().toString()
      );
    }
  }, [endDate]);
  
  const populateFromSavedSearch = (savedSearchId: string) => {
    clearCheck(); // clear form values

    const savedSearch = savedSearches?.mergersAcquisitionsAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    );

    if (!savedSearch) { 
      return;
    }

    setSelectedSavedSearch(savedSearchId);

    const savedSearchValues = savedSearch.searchValues;

    if (savedSearchValues.cname) {
      setCompmanyName(savedSearchValues.cname);
    }

    if (savedSearchValues.trest) {
      setSearchRestrictTarget(true);
    }
    if (savedSearchValues.vrest) {
      setSearchRestrictVendor(true);
    }
    if (savedSearchValues.arest) {
      setSearchRestrictAcquiror(true);
    }

    if (savedSearchValues.tinct) {
      setSearchTargetType(savedSearchValues.tinct);
    }
    if (savedSearchValues.vinct) {
      setSearchVendorType(savedSearchValues.vinct);
    }
    if (savedSearchValues.ainct) {
      setSearchAcquirorType(savedSearchValues.ainct);
    }

    if (savedSearchValues.tfpics) {
      setSearchTargetFPIndustry(savedSearchValues.tfpics);
    }
    if (savedSearchValues.vfpics) {
      setSearchVendorFPIndustry(savedSearchValues.vfpics);
    }
    if (savedSearchValues.afpics) {
      setSearchAcquirorFPIndustry(savedSearchValues.afpics);
    }

    if (savedSearchValues.tnaics) {
      setSearchTargetNAICS(savedSearchValues.tnaics);
    }
    if (savedSearchValues.vnaics) {
      setSearchVendorNAICS(savedSearchValues.vnaics);
    }
    if (savedSearchValues.anaics) {
      setSearchAcquirorNAICS(savedSearchValues.anaics);
    }

    if (savedSearchValues.tsic) {
      setSearchTargetSIC(savedSearchValues.tsic);
    }
    if (savedSearchValues.vsic) {
      setSearchVendorSIC(savedSearchValues.vsic);
    }
    if (savedSearchValues.asic) {
      setSearchAcquirorSIC(savedSearchValues.asic);
    }

    if (savedSearchValues.tgics) {
      setSearchTargetGICS(savedSearchValues.tgics);
    }
    if (savedSearchValues.vgics) {
      setSearchVendorGICS(savedSearchValues.vgics);
    }
    if (savedSearchValues.agics) {
      setSearchAcquirorGICS(savedSearchValues.agics);
    }
    
    if (savedSearchValues.tcountry) {
      // change the array of ids to an array of names
      const locationIds = savedSearchValues.tcountry;
      const locations = locationIds.toString().split(',').map(id => locationLookup.filter(f => f.id === id));
      const locationNames = locations.map(loc => loc[0].name);
      setSearchLocationTarget(locationNames);
    }
    if (savedSearchValues.vcountry) {
      // change the array of ids to an array of names
      const locationIds = savedSearchValues.vcountry;
      const locations = locationIds.toString().split(',').map(id => locationLookup.filter(f => f.id === id));
      const locationNames = locations.map(loc => loc[0].name);
      setSearchLocationVendor(locationNames);
    }
    if (savedSearchValues.acountry) {
      // change the array of ids to an array of names
      const locationIds = savedSearchValues.acountry;
      const locations = locationIds.toString().split(',').map(id => locationLookup.filter(f => f.id === id));
      const locationNames = locations.map(loc => loc[0].name);
      setSearchLocationAcquiror(locationNames);
    }

    if (savedSearchValues.tincpub === '1') {
      setSearchPubliclyTraded(true);
    }
    if (savedSearchValues.tincdiv === '2') {
      setSearchDivestiture(true);
    }
    if (savedSearchValues.tincpri === '3') {
      setSearchPrivatelyOwned(true);
    }
    if (savedSearchValues.tincfor === '4') {
      setSearchForeignTarget(true);
    }

    if (savedSearchValues.deal_gt) {
      setSearchDealValueGt(savedSearchValues.deal_gt);
    }
    if (savedSearchValues.deal_lt) {
      setSearchDealValueLt(savedSearchValues.deal_lt);
    }

    if (savedSearchValues.bought_gt) {
      setSearchPercentageBoughtGt(savedSearchValues.bought_gt);
    }
    if (savedSearchValues.bought_lt) {
      setSearchPercentageBoughtLt(savedSearchValues.bought_lt);
    }

    if (savedSearchValues.pcash) {
      setSearchPaymentCash(true);
    }
    if (savedSearchValues.pstock) {
      setSearchPaymentStock(true);
    }
    if (savedSearchValues.pdebt) {
      setSearchPaymentAssumptionDebt(true);
    }
    if (savedSearchValues.pother) {
      setSearchPaymentOther(true);
    }

    if (savedSearchValues.keyword) {
      setKeywords(savedSearchValues.keyword);
    }
    if (savedSearchValues.kexact) {
      setSearchType(savedSearchValues.kexact);
    }

    if (savedSearchValues.ddesc) {
      setSearchDealDescription(true);
    }
    if (savedSearchValues.tdesc) {
      setSearchTargetBusinessDescription(true);
    }
    if (savedSearchValues.vdesc) {
      setSearchVendorBusinessDescription(true);
    }
    if (savedSearchValues.adesc) {
      setSearchAcquirorBusinessDescription(true);
    }

    if (savedSearchValues.announced) {
      setSearchDatesAnnounced(true);
    }
    if (savedSearchValues.updated) {
      setSearchDatesUpdated(true);
    }

    if (savedSearchValues.sy && savedSearchValues.sm && savedSearchValues.sd) {
      setStartDate(new Date(parseInt(savedSearchValues.sy, 10), parseInt(savedSearchValues.sm, 10) - 1, parseInt(savedSearchValues.sd, 10)));
    }

    if (savedSearchValues.ey && savedSearchValues.em && savedSearchValues.ed) {
      setEndDate(new Date(parseInt(savedSearchValues.ey, 10), parseInt(savedSearchValues.em, 10) - 1, parseInt(savedSearchValues.ed, 10)));
    }

    if (savedSearchValues.pending) {
      setSearchDealStatusPending(true);
    }
    if (savedSearchValues.completed) {
      setSearchDealStatusCompleted(true);
    }
    if (savedSearchValues.terminated) {
      setSearchDealStatusTerminated(true);
    }
  }
  
  const getAccessToken = useGetAccessToken();

  const saveSearch = async () => {
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, hh:mm A');

    // saved search must have a name set...
    if (!newSavedSearchName || newSavedSearchName.toString().trim().length < 1) {
      return;
    }
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await getMergersAcquisitionsSearchResults({
      companyName: companyName || '',
      searchRestrictTarget: searchRestrictTarget || false,
      searchRestrictVendor: searchRestrictVendor || false,
      searchRestrictAcquiror: searchRestrictAcquiror || false,
      searchTargetType: searchTargetType || '',
      searchVendorType: searchVendorType || '',
      searchAcquirorType: searchAcquirorType || '',
      searchTargetFPIndustry: searchTargetFPIndustry || '',
      searchVendorFPIndustry: searchVendorFPIndustry || '',
      searchAcquirorFPIndustry: searchAcquirorFPIndustry || '',
      searchTargetNAICS: searchTargetNAICS || '',
      searchVendorNAICS: searchVendorNAICS || '',
      searchAcquirorNAICS: searchAcquirorNAICS || '',
      searchTargetSIC: searchTargetSIC || '',
      searchVendorSIC: searchVendorSIC || '',
      searchAcquirorSIC: searchAcquirorSIC || '',
      searchTargetGICS: searchTargetGICS || '',
      searchVendorGICS: searchVendorGICS || '',
      searchAcquirorGICS: searchAcquirorGICS || '',
      searchLocationTarget: searchLocationTarget || [],
      searchLocationVendor: searchLocationVendor || [],
      searchLocationAcquiror: searchLocationAcquiror || [],
      searchPubliclyTraded: searchPubliclyTraded || false,
      searchDivestiture: searchDivestiture || false,
      searchPrivatelyOwned: searchPrivatelyOwned || false,
      searchForeignTarget: searchForeignTarget || false,
      searchFinancialAdvisor: searchFinancialAdvisor || '',
      searchLegalAdvisor: searchLegalAdvisor || '',
      searchDealValueGt: searchDealValueGt || '',
      searchDealValueLt: searchDealValueLt || '',
      searchPercentageBoughtGt: searchPercentageBoughtGt || '',
      searchPercentageBoughtLt: searchPercentageBoughtLt || '',
      keywords: keywords || '',
      searchType: searchType || '',
      searchDealDescription: searchDealDescription || false,
      searchTargetBusinessDescription: searchTargetBusinessDescription || false,
      searchVendorBusinessDescription: searchVendorBusinessDescription || false,
      searchAcquirorBusinessDescription: searchAcquirorBusinessDescription || false,
      searchPaymentCash: searchPaymentCash || false,
      searchPaymentStock: searchPaymentStock || false,
      searchPaymentAssumptionDebt: searchPaymentAssumptionDebt || false,
      searchPaymentOther: searchPaymentOther || false,
      startDate: dateFilter(startDate),
      endDate: dateFilter(endDate),
      searchDatesAnnounced: searchDatesAnnounced || false,
      searchDatesUpdated: searchDatesUpdated || false,
      searchDealStatusCompleted: searchDealStatusCompleted || false,
      searchDealStatusPending: searchDealStatusPending || false,
      searchDealStatusTerminated: searchDealStatusTerminated || false,
      savedSearchName: newSavedSearchName || undefined,
      page: 1,
      numberOfRows: 1,
    }, getAccessToken);
    refreshSavedSearches();

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#0E643D', width: '280px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <CheckIcon fill="white" />
        <div>Search form saved</div>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#0E643D', color: 'white' } });
  }

  // const undoDeleteSearch  = async (savedSearchId: string) => {

  // };

  const deleteSavedSearch = async (savedSearchId: string) => {
    // makeing a request for results with 'savedSearchName'
    // set is what saves it in the PHP backend...
    await deleteSearch({
      id: savedSearchId
    }, getAccessToken);

    refreshSavedSearches();

    const deletedSearchName = savedSearches?.mergersAcquisitionsAdvanced.find(
      s => (s.searchId === parseInt(savedSearchId, 10))
    )?.searchName;

    setNewSavedSearchName('');

    toast((t) => (
      <div style={{ lineHeight: '20px', padding: '10px', background: '#333333', width: '320px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <div>Search form removed</div>
        <button style={{ margin: 0, padding: 0, fontWeight: 'bold', background: 0, border: 0, color: 'white', textDecoration: 'underline' }} onClick={async () => {
          if (deletedSearchName) {
            toast.dismiss(t.id);
            await setNewSavedSearchName(deletedSearchName);
            await saveSearch();
            await refreshSavedSearches();
          }
        }}>
          Undo
        </button>
        <button style={{ margin: 0, padding: 0, lineHeight: '20px', fontSize: '1.4em', fontWeight: 'bold', background: 0, border: 0, color: 'white' }} onClick={() => toast.dismiss(t.id)}>
          &#65794;
          <ScreenReaderOnly>Dismiss message</ScreenReaderOnly>
        </button>
      </div>
    ), { style: { fontSize: '1.2em', background: '#333333', color: 'white' } });
  };

  const clearCheck = () => {
    setCompmanyName("");
    setSearchRestrictAcquiror(false);
    setSearchRestrictTarget(false);
    setSearchRestrictVendor(false);
    setSearchTargetType("");
    setSearchVendorType("");
    setSearchAcquirorType("");
    setSearchTargetFPIndustry("");
    setSearchVendorFPIndustry("");
    setSearchAcquirorFPIndustry("");
    setSearchTargetNAICS("");
    setSearchVendorNAICS("");
    setSearchAcquirorNAICS("");
    setSearchTargetSIC("");
    setSearchVendorSIC("");
    setSearchAcquirorSIC("");
    setSearchTargetGICS("");
    setSearchVendorGICS("");
    setSearchAcquirorGICS("");
    setSearchLocationTarget([]);
    setSearchLocationVendor([]);
    setSearchLocationAcquiror([]);
    setSearchPubliclyTraded(false);
    setSearchDivestiture(false);
    setSearchPrivatelyOwned(false);
    setSearchForeignTarget(false);
    setSearchFinancialAdvisor("");
    setSearchLegalAdvisor("");
    setSearchDealValueGt("");
    setSearchDealValueLt("");
    setSearchPercentageBoughtGt("");
    setSearchPercentageBoughtLt("");
    setSearchPaymentCash(false);
    setSearchPaymentStock(false);
    setSearchPaymentAssumptionDebt(false);
    setSearchPaymentOther(false);
    setSearchDealStatusCompleted(false);
    setSearchDealStatusPending(false);
    setSearchDealStatusTerminated(false);
    setSearchType("E");
    setSearchDealDescription(false);
    setSearchTargetBusinessDescription(false);
    setSearchVendorBusinessDescription(false);
    setSearchAcquirorBusinessDescription(false);
    setSearchDatesAnnounced(false);
    setSearchDatesUpdated(false);
    setKeywords('');
    setStartDate(new Date(new Date().getFullYear() - 1, 0, 1));
    setEndDate(new Date(new Date().getFullYear(), 11, 31));
    setDateClear(false);
    setSelectedSavedSearch('');
    setNewSavedSearchName('');
    localStorage.removeItem("chk_search_restrict_target");
    localStorage.removeItem("chk_search_restrict_vendor");
    localStorage.removeItem("chk_search_restrict_acquiror");
    localStorage.removeItem("chk_deal_status_completed");
    localStorage.removeItem("chk_status_dates_updated");
    localStorage.removeItem("chk_deal_status_pending");
    localStorage.removeItem("chk_deal_status_terminated");
    localStorage.removeItem("chk_status_dates_announced");
    localStorage.removeItem('basicSearchRadioMna');
    localStorage.removeItem('chk_publicly_traded');
    localStorage.removeItem("chk_divestiture");
    localStorage.removeItem("chk_privately_owned");
    localStorage.removeItem("chk_foreign_target");
    localStorage.removeItem("chk_payment_cash");
    localStorage.removeItem("chk_payment_stock");
    localStorage.removeItem("chk_payment_assumption_debt");
    localStorage.removeItem("chk_payment_other");
    localStorage.removeItem("chk_deal_description");
    localStorage.removeItem("chk_search_target_business_description");
    localStorage.removeItem("chk_search_vendor_business_description");
    localStorage.removeItem("chk_search_acquiror_business_description");
    localStorage.removeItem("companyName");
    localStorage.removeItem("searchTargetType");
    localStorage.removeItem("searchVendorType");
    localStorage.removeItem("searchAcquirorType");
    localStorage.removeItem("searchTargetFPIndustry");
    localStorage.removeItem("searchVendorFPIndustry");
    localStorage.removeItem("searchAcquirorFPIndustry");
    localStorage.removeItem("searchTargetNAICS");
    localStorage.removeItem("searchVendorNAICS");
    localStorage.removeItem("searchAcquirorNAICS");
    localStorage.removeItem("searchTargetSIC");
    localStorage.removeItem("searchVendorSIC");
    localStorage.removeItem("searchAcquirorSIC");
    localStorage.removeItem("searchTargetGICS");
    localStorage.removeItem("searchVendorGICS");
    localStorage.removeItem("searchAcquirorGICS");
    localStorage.removeItem("searchLocationTarget");
    localStorage.removeItem("searchLocationVendor");
    localStorage.removeItem("searchLocationAcquiror");
    localStorage.removeItem("searchFinancialAdvisor");
    localStorage.removeItem("searchLegalAdvisor");
    localStorage.removeItem("searchDealValueGt");
    localStorage.removeItem("searchDealValueLt");
    localStorage.removeItem("searchPercentageBoughtGt");
    localStorage.removeItem("searchPercentageBoughtLt");
    localStorage.removeItem("keywords");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("selectedSavedSearchMA");
  };

  const mergerSubmitData = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL("/mergers-and-acquisitions/results", baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, h:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set("companyName", companyName);
    }
    if (searchRestrictTarget) {
      resultsUrl.searchParams.set(
        "searchRestrictTarget",
        `${searchRestrictTarget}`
      );
    }
    if (searchRestrictVendor) {
      resultsUrl.searchParams.set(
        "searchRestrictVendor",
        `${searchRestrictVendor}`
      );
    }
    if (searchRestrictAcquiror) {
      resultsUrl.searchParams.set(
        "searchRestrictAcquiror",
        `${searchRestrictAcquiror}`
      );
    }
    if (searchTargetType) {
      resultsUrl.searchParams.set("searchTargetType", searchTargetType);
    }
    if (searchTargetFPIndustry) {
      resultsUrl.searchParams.set(
        "searchTargetFPIndustry",
        searchTargetFPIndustry
      );
    }
    if (searchTargetNAICS) {
      resultsUrl.searchParams.set("searchTargetNAICS", searchTargetNAICS);
    }

    if (searchTargetSIC) {
      resultsUrl.searchParams.set("searchTargetSIC", searchTargetSIC);
    }

    if (searchTargetGICS) {
      resultsUrl.searchParams.set("searchTargetGICS", searchTargetGICS);
    }

    if (searchLocationTarget) {
      resultsUrl.searchParams.set("searchLocationTarget", searchLocationTarget.toString());
    }

    if (searchFinancialAdvisor) {
      resultsUrl.searchParams.set(
        "searchFinancialAdvisor",
        searchFinancialAdvisor
      );
    }

    if (searchLegalAdvisor) {
      resultsUrl.searchParams.set("searchLegalAdvisor", searchLegalAdvisor);
    }

    if (searchDealValueGt) {
      resultsUrl.searchParams.set("searchDealValueGt", searchDealValueGt);
    }

    if (searchDealValueLt) {
      resultsUrl.searchParams.set("searchDealValueLt", searchDealValueLt);
    }

    if (startDate) {
      resultsUrl.searchParams.set("startDate", dateFilter(startDate));
    }

    if (endDate) {
      resultsUrl.searchParams.set("endDate", dateFilter(endDate));
    }

    if (searchDatesAnnounced) {
      resultsUrl.searchParams.set(
        "searchDatesAnnounced",
        `${searchDatesAnnounced}`
      );
    }

    if (searchDatesUpdated) {
      resultsUrl.searchParams.set(
        "searchDatesUpdated",
        `${searchDatesUpdated}`
      );
    }

    if (searchDealStatusCompleted) {
      resultsUrl.searchParams.set(
        "searchDealStatusCompleted",
        `${searchDealStatusCompleted}`
      );
    }

    if (searchDealStatusPending) {
      resultsUrl.searchParams.set(
        "searchDealStatusPending",
        `${searchDealStatusPending}`
      );
    }

    if (searchDealStatusTerminated) {
      resultsUrl.searchParams.set(
        "searchDealStatusTerminated",
        `${searchDealStatusTerminated}`
      );
    }

    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  const mergerSubmitDataAdvanced = () => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.host}`;

    const resultsUrl = new URL("/mergers-and-acquisitions/results", baseUrl);
    const newDate = new Date();
    const dateInfo = moment(newDate).format('MMMM D, YYYY, h:mm A');
    resultsUrl.searchParams.set("dateInfo", dateInfo);
    if (companyName) {
      resultsUrl.searchParams.set("companyName", companyName);
    }
    if (searchRestrictTarget) {
      resultsUrl.searchParams.set(
        "searchRestrictTarget",
        `${searchRestrictTarget}`
      );
    }
    if (searchRestrictVendor) {
      resultsUrl.searchParams.set(
        "searchRestrictVendor",
        `${searchRestrictVendor}`
      );
    }
    if (searchRestrictAcquiror) {
      resultsUrl.searchParams.set(
        "searchRestrictAcquiror",
        `${searchRestrictAcquiror}`
      );
    }
    if (searchTargetType) {
      resultsUrl.searchParams.set("searchTargetType", searchTargetType);
    }
    if (searchVendorType) {
      resultsUrl.searchParams.set("searchVendorType", searchVendorType);
    }
    if (searchAcquirorType) {
      resultsUrl.searchParams.set("searchAcquirorType", searchAcquirorType);
    }
    if (searchTargetFPIndustry) {
      resultsUrl.searchParams.set(
        "searchTargetFPIndustry",
        searchTargetFPIndustry
      );
    }
    if (searchVendorFPIndustry) {
      resultsUrl.searchParams.set(
        "searchVendorFPIndustry",
        searchVendorFPIndustry
      );
    }
    if (searchAcquirorFPIndustry) {
      resultsUrl.searchParams.set(
        "searchAcquirorFPIndustry",
        searchAcquirorFPIndustry
      );
    }
    if (searchTargetNAICS) {
      resultsUrl.searchParams.set("searchTargetNAICS", searchTargetNAICS);
    }
    if (searchVendorNAICS) {
      resultsUrl.searchParams.set("searchVendorNAICS", searchVendorNAICS);
    }
    if (searchAcquirorNAICS) {
      resultsUrl.searchParams.set("searchAcquirorNAICS", searchAcquirorNAICS);
    }
    if (searchTargetSIC) {
      resultsUrl.searchParams.set("searchTargetSIC", searchTargetSIC);
    }
    if (searchVendorSIC) {
      resultsUrl.searchParams.set("searchVendorSIC", searchVendorSIC);
    }
    if (searchAcquirorSIC) {
      resultsUrl.searchParams.set("searchAcquirorSIC", searchAcquirorSIC);
    }
    if (searchTargetGICS) {
      resultsUrl.searchParams.set("searchTargetGICS", searchTargetGICS);
    }
    if (searchVendorGICS) {
      resultsUrl.searchParams.set("searchVendorGICS", searchVendorGICS);
    }
    if (searchAcquirorGICS) {
      resultsUrl.searchParams.set("searchAcquirorGICS", searchAcquirorGICS);
    }
    if (searchLocationTarget) {
      resultsUrl.searchParams.set("searchLocationTarget", searchLocationTarget.toString());
    }
    if (searchLocationVendor) {
      resultsUrl.searchParams.set("searchLocationVendor", searchLocationVendor.toString());
    }
    if (searchLocationAcquiror) {
      resultsUrl.searchParams.set("searchLocationAcquiror", searchLocationAcquiror.toString());
    }

    if (searchPubliclyTraded) {
      resultsUrl.searchParams.set("searchPubliclyTraded", `${searchPubliclyTraded}`);
    }

    if (searchPrivatelyOwned) {
      resultsUrl.searchParams.set("searchPrivatelyOwned", `${searchPrivatelyOwned}`);
    }

    if (searchForeignTarget) {
      resultsUrl.searchParams.set("searchForeignTarget", `${searchForeignTarget}`);
    }

    if (searchDivestiture) {
      resultsUrl.searchParams.set("searchDivestiture", `${searchDivestiture}`);
    }

    if (searchDealValueGt) {
      resultsUrl.searchParams.set("searchDealValueGt", searchDealValueGt);
    }

    if (searchDealValueLt) {
      resultsUrl.searchParams.set("searchDealValueLt", searchDealValueLt);
    }

    if (searchPercentageBoughtGt) {
      resultsUrl.searchParams.set("searchPercentageBoughtGt", searchPercentageBoughtGt);
    }

    if (searchPercentageBoughtLt) {
      resultsUrl.searchParams.set("searchPercentageBoughtLt", searchPercentageBoughtLt);
    }

    if (searchPaymentCash) {
      resultsUrl.searchParams.set("searchPaymentCash", `${searchPaymentCash}`);
    }

    if (searchPaymentStock) {
      resultsUrl.searchParams.set("searchPaymentStock", `${searchPaymentStock}`);
    }

    if (searchPaymentAssumptionDebt) {
      resultsUrl.searchParams.set("searchPaymentAssumptionDebt", `${searchPaymentAssumptionDebt}`);
    }

    if (searchPaymentOther) {
      resultsUrl.searchParams.set("searchPaymentOther", `${searchPaymentOther}`);
    }

    if (keywords) {
      resultsUrl.searchParams.set("keywords", keywords);
    }

    if (searchType) {
      resultsUrl.searchParams.set("searchType", searchType);
    }

    if (searchDealDescription) {
      resultsUrl.searchParams.set("searchDealDescription", `${searchDealDescription}`);
    }

    if (searchTargetBusinessDescription) {
      resultsUrl.searchParams.set("searchTargetBusinessDescription", `${searchTargetBusinessDescription}`);
    }

    if (searchVendorBusinessDescription) {
      resultsUrl.searchParams.set("searchVendorBusinessDescription", `${searchVendorBusinessDescription}`);
    }

    if (searchAcquirorBusinessDescription) {
      resultsUrl.searchParams.set("searchAcquirorBusinessDescription", `${searchAcquirorBusinessDescription}`);
    }

    if (startDate) {
      resultsUrl.searchParams.set("startDate", dateFilter(startDate));
    }

    if (endDate) {
      resultsUrl.searchParams.set("endDate", dateFilter(endDate));
    }

    if (searchDatesAnnounced) {
      resultsUrl.searchParams.set(
        "searchDatesAnnounced",
        `${searchDatesAnnounced}`
      );
    }

    if (searchDatesUpdated) {
      resultsUrl.searchParams.set(
        "searchDatesUpdated",
        `${searchDatesUpdated}`
      );
    }

    if (searchDealStatusCompleted) {
      resultsUrl.searchParams.set(
        "searchDealStatusCompleted",
        `${searchDealStatusCompleted}`
      );
    }

    if (searchDealStatusPending) {
      resultsUrl.searchParams.set(
        "searchDealStatusPending",
        `${searchDealStatusPending}`
      );
    }

    if (searchDealStatusTerminated) {
      resultsUrl.searchParams.set(
        "searchDealStatusTerminated",
        `${searchDealStatusTerminated}`
      );
    }

    if (newSavedSearchName) {
      resultsUrl.searchParams.set('savedSearchName', newSavedSearchName);
      resultsUrl.searchParams.set('save_search', 'Save');
    }
    
    history.push(resultsUrl.href.replace(baseUrl, ""), { data: true });
  };

  return (
    <Page aria-label="Mergers &amp; Acquisitions">
      <Tabs aria-label="Search Options">
        <Tab label="Basic">
          <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]}>
            Mergers &amp; Acquisitions - Basic Search
          </Heading>
          <Text size="md" mt="md" mb="sm" lineHeight="md">
            Information on mergers, acquisitions and divestitures involving Canadian
            companies.
            <br></br>
            Data goes back to 1994. Updated hourly.
          </Text>
          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details will
            narrow your search results.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              mergerSubmitData();
            }}
            >
            <div>
              <TextField
                mt="lg"
                label="Company Name"
                value={companyName}
                onChange={setCompmanyName}
              />
              <label id="restrictToId" style={{display: "block", marginTop: "24px", marginBottom: "6px", fontWeight: 500}}>
                Restrict Company Name Result to
              </label>
              <div aria-labelledby="restrictToId">
                <input
                  type="checkbox"
                  id="chk_search_restrict_target"
                  checked={searchRestrictTarget}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_target_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictTarget(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_restrict_target"
                >
                  Target
                </label>
                <input
                  type="checkbox"
                  id="chk_search_restrict_vendor"
                  checked={searchRestrictVendor}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_vendor_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictVendor(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_restrict_vendor"
                >
                  Vendor
                </label>
                <input
                  type="checkbox"
                  id="chk_search_restrict_acquiror"
                  checked={searchRestrictAcquiror}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_acquiror_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictAcquiror(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_restrict_acquiror"
                >
                  Acquiror
                </label>
              </div>
            </div>
            <div>
              <SelectField
                title='Target Type'
                value={searchTargetType}
                setValue={setSearchTargetType}
                options={targetTypeLookup}
              />
            </div>
            <div>
              <SelectField
                title='Target FP Industry'
                value={searchTargetFPIndustry}
                setValue={setSearchTargetFPIndustry}
                options={fpIndustryLookup}
              />
            </div>
            <div>
              <SelectField
                title='Target NAICS Code'
                value={searchTargetNAICS}
                setValue={setSearchTargetNAICS}
                options={naicsLookupCodes}
              />
            </div>
            <div>
              <SelectField
                title='Target SIC Code'
                value={searchTargetSIC}
                setValue={setSearchTargetSIC}
                options={sicLookup}
              />
            </div>
            <div>
              <SelectField
                title='Target GICS'
                value={searchTargetGICS}
                setValue={setSearchTargetGICS}
                options={gicsLookup}
              />
            </div>
            <div>
              <SelectField
                title='Financial Advisor'
                value={searchFinancialAdvisor}
                setValue={setSearchFinancialAdvisor}
                options={advisorLookup}
              />
            </div>
            <div>
              <SelectField
                title='Legal Advisor'
                value={searchLegalAdvisor}
                setValue={setSearchLegalAdvisor}
                options={legalAdvisorLookup}
              />
            </div>

            <div style={{ marginTop: "24px" }}>
              <label id="dealValueId">
                Deal Value
                <Text color="light">(search is in Canadian dollars)</Text>
              </label>
              <div aria-labelledby="dealValueId" className="mna-section">
                <SelectFieldLtGt
                  title=''
                  valueGt={searchDealValueGt}
                  setValueGt={setSearchDealValueGt}
                  optionsGt={dealValueGTLookup}
                  valueLt={searchDealValueLt}
                  setValueLt={setSearchDealValueLt}
                  optionsLt={dealValueLTLookup}
                />
              </div>
            </div>

            <div style={{ marginTop: "24px" }}>
              <label style={{ display:'block', marginBottom: "6px" }}>
                Status Dates
              </label>
              <input
                type="checkbox"
                id="chk_status_dates_announced"
                checked={searchDatesAnnounced}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_status_dates_announced_MA",
                    `${e.target.checked}`
                  );
                  setSearchDatesAnnounced(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: "10px" }}
                htmlFor="chk_status_dates_announced"
              >
                Announced
              </label>
              <input
                type="checkbox"
                id="chk_status_dates_updated"
                checked={searchDatesUpdated}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_status_dates_updated_MA",
                    `${e.target.checked}`
                  );
                  setSearchDatesUpdated(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: "10px" }}
                htmlFor="chk_status_dates_updated"
              >
                Updated
              </label>
              <br /> <br />
              <DateRangePicker
                startDate={startDate}
                retainEndDateOnFirstSelection={true}
                moveRangeOnFirstSelection={false}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                disabled={!searchDatesUpdated && !searchDatesAnnounced}
              />
            </div>

            <div style={{ marginTop: "24px" }}>
              <label style={{ display:'block', marginBottom: "6px" }}>
                Deal Status
              </label>
              <input
                type="checkbox"
                id="chk_deal_status_pending"
                checked={searchDealStatusPending}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_pending_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusPending(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: "10px" }}
                htmlFor="chk_deal_status_pending"
              >
                Pending
              </label>

              <input
                type="checkbox"
                id="chk_deal_status_completed"
                checked={searchDealStatusCompleted}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_completed_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusCompleted(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: "10px" }}
                htmlFor="chk_deal_status_completed"
              >
                Completed
              </label>

              <input
                type="checkbox"
                id="chk_deal_status_terminated"
                checked={searchDealStatusTerminated}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_terminated_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusTerminated(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginRight: "10px" }}
                htmlFor="chk_deal_status_terminated"
              >
                Terminated
              </label>
            </div>
            <div>
              <Button
                mt="lg"
                label="Search"
                mb={"lg"}
                type="submit"
              />
            </div>
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>
        <Tab label="Advanced">
          <Heading id={headingAdvId} level="1" size={["xxl", "xxl", "xxxl"]}>
            Mergers &amp; Acquisitions - Advanced Search
          </Heading>
          <Text size="md" mt="md" mb="sm" lineHeight="md">
            Information on mergers, acquisitions and divestitures involving Canadian
            companies.
            <br></br>
            Data goes back to 1994. Updated hourly.
          </Text>
          <Text mt="md" mb="md" lineHeight="md">
            Use only the search options that you need. Providing more details will
            narrow your search results.
          </Text>
          <form
            aria-labelledby={headingAdvId}
            onSubmit={(e) => {
              e.preventDefault();
              mergerSubmitDataAdvanced();
            }}
            >
            <div>
              <Heading level="2" size="lg" mb="sm">
                  <span style={{ verticalAlign: 'top' }}><StarIcon /></span>
                  My Saved Searches
              </Heading>

              <label style={{ display: 'block', marginBottom: '4px', fontWeight: 500 }} htmlFor="select_saved_searches">Saved Searches</label>
              <Row>
                <select 
                  id="select_saved_searches"
                  value={selectedSavedSearch}
                  onChange={(e) => {
                      populateFromSavedSearch(e.target.value);
                      }}
                >
                  <option>No Search Selected</option>
                  { savedSearches?.mergersAcquisitionsAdvanced?.map(search => (
                      <option value={search.searchId}>{search.searchName}</option>
                  ))}
                </select>

                {selectedSavedSearch && (
                    <button type="button" style={{ marginLeft: '10px', padding: '8px 0 0 10px', textAlign: 'center' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) }}>
                      <TrashIcon size='sm' />
                    </button>
                  )
                }
              </Row>
            </div>
          <div style={{ marginBottom: '24px' }}>
            <div className="mna-col">
              <TextField
                mt="lg"
                label="Company Name"
                value={companyName}
                onChange={setCompmanyName}
                fullWidth={true}
              />
            </div>
            <div className="mna-col">
              <label id="restrictToAdvId">
                Restrict Company Name Result to
              </label>
              <div aria-labelledby="restrictToAdvId" style={{marginTop: "14px"}}>
                <input
                  type="checkbox"
                  id="chk_search_restrict_target_adv"
                  checked={searchRestrictTarget}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_target_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictTarget(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: "10px" }}
                  htmlFor="chk_search_restrict_target_adv"
                >
                  Target
                </label>
                <input
                  type="checkbox"
                  id="chk_search_restrict_vendor_adv"
                  checked={searchRestrictVendor}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_vendor_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictVendor(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: "10px" }}
                  htmlFor="chk_search_restrict_vendor_adv"
                >
                  Vendor
                </label>
                <input
                  type="checkbox"
                  id="chk_search_restrict_acquiror_adv"
                  checked={searchRestrictAcquiror}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_restrict_acquiror_MA",
                      `${e.target.checked}`
                    );
                    setSearchRestrictAcquiror(e.target.checked);
                  }}
                ></input>
                <label
                  style={{ marginRight: "10px" }}
                  htmlFor="chk_search_restrict_acquiror_adv"
                >
                  Acquiror
                </label>
              </div>
            </div>
          </div>
          <div className="mna-accordion">
            <Accordion>
              <Accordion.Heading size="xl" level="2" weight="semi-bold">
                Search by Target
              </Accordion.Heading>
              <Accordion.Content>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='Company Type'
                      value={searchTargetType}
                      setValue={setSearchTargetType}
                      options={typeLookup}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='FP Industry'
                      value={searchTargetFPIndustry}
                      setValue={setSearchTargetFPIndustry}
                      options={fpIndustryLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='NAICS Codes'
                      value={searchTargetNAICS}
                      setValue={setSearchTargetNAICS}
                      options={naicsLookupCodes}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='SIC Codes'
                      value={searchTargetSIC}
                      setValue={setSearchTargetSIC}
                      options={sicLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='GICS'
                      value={searchTargetGICS}
                      setValue={setSearchTargetGICS}
                      options={gicsLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col" style={{ marginTop: "24px" }}>
                    <MultiSelectFieldChips
                      label="Location"
                      width="full"
                      items={locationLookup.map(g => g.name)}
                      value={searchLocationTarget}
                      onChange={setSearchLocationTarget}
                    />
                  </div>
                </div>
              </Accordion.Content>
              <Accordion.Footer size="xl" level="2" weight="semi-bold">
                <div></div>
              </Accordion.Footer>
            </Accordion>
          </div>
          <div className="mna-accordion">
            <Accordion>
              <Accordion.Heading size="xl" level="2" weight="semi-bold">
                Search by Vendor
              </Accordion.Heading>
              <Accordion.Content>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='Company Type'
                      value={searchVendorType}
                      setValue={setSearchVendorType}
                      options={typeLookup}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='FP Industry'
                      value={searchVendorFPIndustry}
                      setValue={setSearchVendorFPIndustry}
                      options={fpIndustryLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='NAICS Codes'
                      value={searchVendorNAICS}
                      setValue={setSearchVendorNAICS}
                      options={naicsLookupCodes}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='SIC Codes'
                      value={searchVendorSIC}
                      setValue={setSearchVendorSIC}
                      options={sicLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='GICS'
                      value={searchVendorGICS}
                      setValue={setSearchVendorGICS}
                      options={gicsLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col"  style={{ marginTop: "24px" }}>
                    <MultiSelectFieldChips
                      label="Location"
                      width="full"
                      items={locationLookup.map(g => g.name)}
                      value={searchLocationVendor}
                      onChange={(e) => {
                        const selectedItems = e.filter(item => item.trim() !== 'By Province' && item.trim() !== 'By Continent' && item.trim() !== 'By Country')
                        setSearchLocationVendor(selectedItems);
                      }}
                    />
                  </div>
                </div>
              </Accordion.Content>
              <Accordion.Footer size="xl" level="2" weight="semi-bold">
                <div></div>
              </Accordion.Footer>
            </Accordion>
          </div>
          <div className="mna-accordion" style={{marginBottom: '32px'}}>
            <Accordion>
              <Accordion.Heading size="xl" level="2" weight="semi-bold">
                Search by Acquiror
              </Accordion.Heading>
              <Accordion.Content>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='Company Type'
                      value={searchAcquirorType}
                      setValue={setSearchAcquirorType}
                      options={typeLookup}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='FP Industry'
                      value={searchAcquirorFPIndustry}
                      setValue={setSearchAcquirorFPIndustry}
                      options={fpIndustryLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='NAICS Codes'
                      value={searchAcquirorNAICS}
                      setValue={setSearchAcquirorNAICS}
                      options={naicsLookupCodes}
                    />
                  </div>
                  <div className="mna-col">
                    <SelectField
                      title='SIC Codes'
                      value={searchAcquirorSIC}
                      setValue={setSearchAcquirorSIC}
                      options={sicLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col">
                    <SelectField
                      title='GICS'
                      value={searchAcquirorGICS}
                      setValue={setSearchAcquirorGICS}
                      options={gicsLookup}
                    />
                  </div>
                </div>
                <div>
                  <div className="mna-col" style={{ marginTop: "24px" }}>
                    <MultiSelectFieldChips
                      label="Location"
                      width="full"
                      items={locationLookup.map(g => g.name)}
                      value={searchLocationAcquiror}
                      onChange={(e) => {
                        const selectedItems = e.filter(item => item.trim() !== 'By Province' && item.trim() !== 'By Continent' && item.trim() !== 'By Country')
                        setSearchLocationAcquiror(selectedItems);
                      }}
                    />
                  </div>
                </div>
              </Accordion.Content>
              <Accordion.Footer size="xl" level="2" weight="semi-bold">
                <div></div>
              </Accordion.Footer>
            </Accordion>
          </div>
          <br></br>
          <Heading id="dealTypeId" level="2" size={["md", "md", "lg"]}>
            Deal Type
          </Heading>
          <Row aria-labelledby="dealTypeId" className="mna-row">
            <input
              type="checkbox"
              id="chk_publicly_traded"
              checked={searchPubliclyTraded}
              onChange={(e) => {
                localStorage.setItem(
                  "chk_publicly_traded_MA",
                  `${e.target.checked}`
                );
                setSearchPubliclyTraded(e.target.checked);
              }}
            ></input>
            <label
              style={{ marginLeft: "16px" }}
              htmlFor="chk_publicly_traded"
            >
              Publicly Traded
            </label>
            <input
              type="checkbox"
              id="chk_divestiture"
              checked={searchDivestiture}
              onChange={(e) => {
                localStorage.setItem(
                  "chk_divestiture_MA",
                  `${e.target.checked}`
                );
                setSearchDivestiture(e.target.checked);
              }}
            ></input>
            <label
              style={{ marginLeft: "16px" }}
              htmlFor="chk_divestiture"
            >
              Divestiture
            </label>
            <input
              type="checkbox"
              id="chk_privately_owned"
              checked={searchPrivatelyOwned}
              onChange={(e) => {
                localStorage.setItem(
                  "chk_privately_owned_MA",
                  `${e.target.checked}`
                );
                setSearchPrivatelyOwned(e.target.checked);
              }}
            ></input>
            <label
              style={{ marginLeft: "16px" }}
              htmlFor="chk_privately_owned"
            >
              Privately Owned
            </label>
            <input
              type="checkbox"
              id="chk_foreign_target"
              checked={searchForeignTarget}
              onChange={(e) => {
                localStorage.setItem(
                  "chk_foreign_target_MA",
                  `${e.target.checked}`
                );
                setSearchForeignTarget(e.target.checked);
              }}
            ></input>
            <label
                style={{ marginLeft: "16px" }}
              htmlFor="chk_foreign_target"
            >
              Foreign Target
            </label>
            <br /> <br />
          </Row>
          <Heading id="dealValueAdvId" level="2" size={["md", "md", "lg"]}>
            Deal Value
            <Text color="light">(search is in Canadian dollars)</Text>
          </Heading>
          <div aria-labelledby="dealValueAdvId" className="mna-section">
            <SelectFieldLtGt
              title=''
              valueGt={searchDealValueGt}
              setValueGt={setSearchDealValueGt}
              optionsGt={dealValueGTLookup}
              valueLt={searchDealValueLt}
              setValueLt={setSearchDealValueLt}
              optionsLt={dealValueLTLookup}
            />
          </div>
          <Heading id="percentageBoughtId" level="2" size={["md", "md", "lg"]}>
            % Bought
          </Heading>
          <div aria-labelledby="percentageBoughtId" className="mna-section" style={{marginBottom: "38px"}}>
            <SelectFieldLtGt
              title=''
              valueGt={searchPercentageBoughtGt}
              setValueGt={setSearchPercentageBoughtGt}
              optionsGt={percentageBoughtGTLookup}
              valueLt={searchPercentageBoughtLt}
              setValueLt={setSearchPercentageBoughtLt}
              optionsLt={percentageBoughtLTLookup}
            />
          </div>
          <Heading id="paymentId" level="2" size={["md", "md", "lg"]}>
            Payment
          </Heading>
          <Row aria-labelledby="paymentId" className="mna-row">
            <input
                type="checkbox"
                id="chk_payment_cash"
                checked={searchPaymentCash}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_payment_cash_MA",
                    `${e.target.checked}`
                  );
                  setSearchPaymentCash(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginLeft: "16px" }}
                htmlFor="chk_payment_cash"
              >
                Cash
              </label>
              <input
                type="checkbox"
                id="chk_payment_stock"
                checked={searchPaymentStock}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_payment_stock_MA",
                    `${e.target.checked}`
                  );
                  setSearchPaymentStock(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginLeft: "16px" }}
                htmlFor="chk_payment_stock"
              >
                Stock
              </label>
              <input
                type="checkbox"
                id="chk_payment_assumption_debt"
                checked={searchPaymentAssumptionDebt}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_payment_assumption_debt_MA",
                    `${e.target.checked}`
                  );
                  setSearchPaymentAssumptionDebt(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginLeft: "16px" }}
                htmlFor="chk_payment_assumption_debt"
              >
                Assumption of Debt
              </label>
              <input
                type="checkbox"
                id="chk_payment_other"
                checked={searchPaymentOther}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_payment_other_MA",
                    `${e.target.checked}`
                  );
                  setSearchPaymentOther(e.target.checked);
                }}
              ></input>
              <label
                style={{ marginLeft: "16px" }}
                htmlFor="chk_payment_other"
              >
                Other
              </label>
            </Row>
            <br></br>
            <Heading level="2" size={["md", "md", "lg"]}>
              Search by Keywords
            </Heading>
            <Row>
              <div>
                <TextField
                  mt="lg"
                  label="Keywords"
                  value={keywords}
                  onChange={setKeywords}
                />
              </div>
              <div className="searchpage-searchtype">
                <RadioList
                  aria-label="Search Type"
                  value={searchType}
                  // onChange={setSearchType}
                  onChange={(e) => {
                    localStorage.setItem('basicSearchRadioMnaMA', `${e}`);
                    setSearchType(e);
                          }}
                  options={searchTypeMnaLookup}
                  legend="Exact or All words"
                />
              </div>
            </Row>
            <Heading id="restrictKeywordToId" level="3" size={["sm", "sm", "md"]}>
              Restrict keyword results to
            </Heading> 
            <Row>
              <div aria-labelledby="restrictKeywordToId" style={{marginTop: "24px", marginBottom: "32px"}}>
                <input
                  type="checkbox"
                  id="chk_deal_description"
                  checked={searchDealDescription}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_deal_description_MA",
                      `${e.target.checked}`
                    );
                    setSearchDealDescription(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_deal_description"
                >
                  Deal Description
                </label>
                <input
                  type="checkbox"
                  id="chk_search_target_business_description"
                  checked={searchTargetBusinessDescription}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_target_business_description_MA",
                      `${e.target.checked}`
                    );
                    setSearchTargetBusinessDescription(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_target_business_description"
                >
                  Target Business Description
                </label>
                <input
                  type="checkbox"
                  id="chk_search_vendor_business_description"
                  checked={searchVendorBusinessDescription}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_vendor_business_description_MA",
                      `${e.target.checked}`
                    );
                    setSearchVendorBusinessDescription(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_vendor_business_description"
                >
                  Vendor Business Description
                </label>

                <input
                  type="checkbox"
                  id="chk_search_acquiror_business_description"
                  checked={searchAcquirorBusinessDescription}
                  onChange={(e) => {
                    localStorage.setItem(
                      "chk_search_acquiror_business_description_MA",
                      `${e.target.checked}`
                    );
                    setSearchAcquirorBusinessDescription(e.target.checked);
                  }}
                ></input>
                <label
                  htmlFor="chk_search_acquiror_business_description"
                >
                  Acquiror Business Description
                </label>
              </div>
            </Row>
            <Heading id="StatusDatesId" level="2" size={["md", "md", "lg"]}>
              Status Dates
            </Heading>
            <div aria-labelledby="StatusDatesId" style={{marginTop: "16px", marginBottom: "32px"}}>
              <input
                type="checkbox"
                id="chk_status_dates_announced_adv"
                checked={searchDatesAnnounced}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_status_dates_announced_MA",
                    `${e.target.checked}`
                  );
                  setSearchDatesAnnounced(e.target.checked);
                }}
              ></input>
              <label
                htmlFor="chk_status_dates_announced_adv"
              >
                Announced
              </label>
              <input
                type="checkbox"
                id="chk_status_dates_updated_adv"
                checked={searchDatesUpdated}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_status_dates_updated_MA",
                    `${e.target.checked}`
                  );
                  setSearchDatesUpdated(e.target.checked);
                }}
              ></input>
              <label
                htmlFor="chk_status_dates_updated_adv"
              >
                Updated
              </label>
              <br /> <br />
              <DateRangePicker
                startDate={startDate}
                retainEndDateOnFirstSelection={true}
                moveRangeOnFirstSelection={false}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                disabled={!searchDatesUpdated && !searchDatesAnnounced}
              />
            </div>
            <Heading id="DealStatusId" level="2" size={["md", "md", "lg"]}>
              Deal Status
            </Heading>
            <div aria-labelledby="DealStatusId" style={{marginTop: "16px", marginBottom: "36px"}}>
              <input
                type="checkbox"
                id="chk_deal_status_pending_adv"
                checked={searchDealStatusPending}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_pending_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusPending(e.target.checked);
                }}
              ></input>
              <label
                htmlFor="chk_deal_status_pending_adv"
              >
                Pending
              </label>

              <input
                type="checkbox"
                id="chk_deal_status_completed_adv"
                checked={searchDealStatusCompleted}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_completed_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusCompleted(e.target.checked);
                }}
              ></input>
              <label
                htmlFor="chk_deal_status_completed_adv"
              >
                Completed
              </label>

              <input
                type="checkbox"
                id="chk_deal_status_terminated_adv"
                checked={searchDealStatusTerminated}
                onChange={(e) => {
                  localStorage.setItem(
                    "chk_deal_status_terminated_MA",
                    `${e.target.checked}`
                  );
                  setSearchDealStatusTerminated(e.target.checked);
                }}
              ></input>
              <label
                htmlFor="chk_deal_status_terminated_adv"
              >
                Terminated
              </label>
            </div>
            <div>
              <Heading level="2" size="lg" mb="sm" mt="lg">
                Save Search
              </Heading>
              <Row>
                <div style={{ maxWidth: '90%' }}>
                  <TextField
                    label="Name of search form"
                    value={newSavedSearchName}
                    onChange={setNewSavedSearchName}
                  />
                  <Text color="light" size="sm" mt="xxs">
                    Give your search form a meaningful name
                  </Text>
                </div>
                <div>
                  { ((savedSearches?.mergersAcquisitionsAdvanced.find(s => s.searchName === newSavedSearchName)
                    ? (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={(e) => { deleteSavedSearch(selectedSavedSearch) } }>
                      <StarIcon size='sm' />
                      <ScreenReaderOnly>Delete saved search</ScreenReaderOnly>
                    </button>)
                    : (<button type="button" style={{ height: '40px', marginLeft: '12px', marginTop: '25px', padding: '4px 4px 1px 7px' }} onClick={saveSearch}>
                      <StarIcon size='sm' color='clear' />
                      <ScreenReaderOnly>Save search</ScreenReaderOnly>
                    </button>
                  )))}
                </div>
              </Row>
            </div>
            <div>
              <Button
                mt="lg"
                label="Search"
                mb={"lg"}
                type="submit"
              />
            </div>
          </form>
          <ClearButton onClick={clearCheck} className="clearstatus" />
        </Tab>
      </Tabs>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={24}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },
          // Default options for specific types
          success: {
            duration: 5000,
            style: {
              background: '#00754E',
              color: '#fff'
            }
          },
          error: {
            duration: 5000,
            style: {
              background: 'rgb(233, 22, 60)',
              color: '#fff'
            }
          }
        }}
      />
    </Page>
  );
}
