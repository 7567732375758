import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';
import { getExportCSVurl } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { useGetAccessToken } from '../../../containers/AuthProvider';
import { Row } from '../../../components/Row';
import { Tiles } from '../../../components/Tiles';
import { useId } from '../../../hooks';
import { DownloadIcon } from '../../../icons/DownloadIcon';

export function ExternalDatabasesCsvExportPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let docKeys = data?.keys as unknown as Array<string>;
  if (!docKeys) {
    const params = useParams<{ docKey: string }>();
    docKeys = decodeURIComponent(params.docKey).split(',');
  }
  const headingId = useId();
  const [exportSelectDeselect, setExportSelectDeselect] = React.useState(true);

  const [exportPlaintff, setExportPlaintff] = React.useState(true);
  const [exportDefendnt, setExportDefendnt] = React.useState(true);
  const [exportRcdType, setExportRcdType] = React.useState(true);
  const [exportAction, setExportAction] = React.useState(true);
  const [exportReason, setExportReason] = React.useState(true);
  const [exportAmount, setExportAmount] = React.useState(true);
  const [exportCourt, setExportCourt] = React.useState(true);
  const [exportFNumber, setExportFNumber] = React.useState(true);
  const [exportFDate, setExportFDate] = React.useState(true);
  const [exportSNumber, setExportSNumber] = React.useState(true);
  const [exportPostal, setExportPostal] = React.useState(true);
  const [exportMDate, setExportMDate] = React.useState(true);
  const [exportDNumber, setExportDNumber] = React.useState(true);

  const getAccessToken = useGetAccessToken();

  const generateCsvFields = () => {
    const fields = [];
    if (exportPlaintff) {
      fields.push('Plaintff');
    }
    if (exportDefendnt) {
      fields.push('Defendnt');
    }
    if (exportRcdType) {
      fields.push('RcdType');
    }
    if (exportAction) {
      fields.push('Action');
    }
    if (exportReason) {
      fields.push('Reason');
    }
    if (exportAmount) {
      fields.push('Amount');
    }
    if (exportCourt) {
      fields.push('Court');
    }
    if (exportFNumber) {
      fields.push('FNumber');
    }
    if (exportFDate) {
      fields.push('FDate');
    }
    if (exportSNumber) {
      fields.push('SNumber');
    }
    if (exportPostal) {
      fields.push('Postal');
    }
    if (exportMDate) {
      fields.push('MDate');
    }
    if (exportDNumber) {
      fields.push('DNumber');
    }
    return fields;
  }

  async function generateCsvFile () {
    const fields = generateCsvFields();
    if (fields.length > 0) {
      const paramsCSV = {
        keys: docKeys,
        fields: fields
      }
      const url = await getExportCSVurl(paramsCSV, '/doss/ccre_export_api.php', getAccessToken);
      if (url.length > 0) {
        window.location.href = config.apiUrl + url;
      }
    }
  }

  return (
    <Page aria-labelledby={headingId} padding='none' width='full'>
      <Page aria-labelledby={headingId}>
        <Heading level='1' mb='lg' mt='xxxs' id={headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt='sm'
              color='heading'
              size={['xxl', 'xxl', 'xxxl']}
              weight='semi-bold'
            >
              Financial Post Equifax Commercial Law - Basic Export CSV
              <ScreenReaderOnly>.</ScreenReaderOnly>
            </Text>
          </ScreenReaderAvoidSplitting>
        </Heading>

        <Row>
          <div className='detail--page--card'>
              <Card mb='lg'>
                <Accordion openOnLoad={true}>
                  <Accordion.Heading size='xl' level='2' weight='semi-bold'>
                    CSV Export Options
                  </Accordion.Heading>
                  <Accordion.Content isOverflowScrollStyling>
                    <ul>
                      <li>Basic Equifax Commercial Law record information is included in every export.</li>
                      <li>To reduce download size, you may select or unselect additional data to include in the exported file.</li>
                      <li>Data is exported in Excel-compatible CSV format.</li>
                      <li>The more records you select, the longer the export process will take.</li>
                      <li>Not all records contain data in all fields</li>
                    </ul>
                    <Text>
                      {docKeys.length} records selected for export.
                    </Text>
                    <br></br>
                    <Tiles
                      columns={[1, 1]}
                    >
                      <div style={{ marginBottom: '14px' }}>
                        <input
                          type="checkbox"
                          id="chk_select_deselect"
                          checked={exportSelectDeselect}
                          onChange={(e) => {
                            setExportSelectDeselect(e.target.checked);
                            setExportPlaintff(e.target.checked);
                            setExportDefendnt(e.target.checked);
                            setExportRcdType(e.target.checked);
                            setExportAction(e.target.checked);
                            setExportReason(e.target.checked);
                            setExportAmount(e.target.checked);
                            setExportCourt(e.target.checked);
                            setExportFNumber(e.target.checked);
                            setExportFDate(e.target.checked);
                            setExportSNumber(e.target.checked);
                            setExportPostal(e.target.checked);
                            setExportMDate(e.target.checked);
                            setExportDNumber(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_select_deselect"
                        >
                          SELECT/UNSELECT ALL
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_plaintff"
                          checked={exportPlaintff}
                          onChange={(e) => {
                            setExportPlaintff(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_plaintff"
                        >
                          Plaintff
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_defendant"
                          checked={exportDefendnt}
                          onChange={(e) => {
                            setExportDefendnt(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_defendant"
                        >
                          Defendant
                        </label>
                        <ul>
                          <li>Street</li>
                          <li>City</li>
                          <li>Province</li>
                          <li>Postal</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_record_type"
                          checked={exportRcdType}
                          onChange={(e) => {
                            setExportRcdType(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_record_type"
                        >
                          Record Type
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_action"
                          checked={exportAction}
                          onChange={(e) => {
                            setExportAction(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_action"
                        >
                          Action
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_reason"
                          checked={exportReason}
                          onChange={(e) => {
                            setExportReason(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_reason"
                        >
                          Reason
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_amount"
                          checked={exportAmount}
                          onChange={(e) => {
                            setExportAmount(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_amount"
                        >
                          Amount ($)
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_court"
                          checked={exportCourt}
                          onChange={(e) => {
                            setExportCourt(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_court"
                        >
                          Court
                        </label>
                        <ul>
                          <li>Location</li>
                        </ul>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_court_file_nb"
                          checked={exportFNumber}
                          onChange={(e) => {
                            setExportFNumber(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_court_file_nb"
                        >
                          Court File Number
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_filing_date"
                          checked={exportFDate}
                          onChange={(e) => {
                            setExportFDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_filing_date"
                        >
                          Filing Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_credit_rep"
                          checked={exportDNumber}
                          onChange={(e) => {
                            setExportDNumber(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_credit_rep"
                        >
                          Credit Report #
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_postal"
                          checked={exportPostal}
                          onChange={(e) => {
                            setExportPostal(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_postal"
                        >
                          Postal
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_meeting_d"
                          checked={exportMDate}
                          onChange={(e) => {
                            setExportMDate(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_meeting_d"
                        >
                          Meeting Date
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="chk_estate_id"
                          checked={exportSNumber}
                          onChange={(e) => {
                            setExportSNumber(e.target.checked);
                          }}
                        ></input>
                        <label
                          htmlFor="chk_estate_id"
                        >
                          Estate ID
                        </label>
                      </div>
                    </Tiles>
                    <div>
                      <button className="button-print" style={{ margin: '14px' }} onClick={generateCsvFile}>
                        <span className='button-icon'>
                          <DownloadIcon size="sm" />
                        </span>
                        <span className='button-text'>&nbsp;Generate CSV</span>
                      </button>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Card>
          </div>
        </Row>
      </Page>
    </Page>
  );
}
