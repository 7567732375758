import * as React from 'react';
import styled from 'styled-components/macro';

const DebugGridContext = React.createContext(false);
const DebugGridSetContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export function DebugGridProvider (props: { children: React.ReactNode }) {
  const [show, setShow] = React.useState(false);
  return (
    <DebugGridContext.Provider value={show}>
      <DebugGridSetContext.Provider value={setShow}>
        {props.children}
      </DebugGridSetContext.Provider>
    </DebugGridContext.Provider>
  );
}

export function DebugToggle () {
  const setShow = React.useContext(DebugGridSetContext);

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <StyledDebugToggle onClick={() => setShow((show) => !show)}>
      Toggle Grid
    </StyledDebugToggle>
  );
}

export const StyledDebugToggle = styled.button({
  position: 'fixed',
  left: '60px',
  bottom: '18px',
  zIndex: 99999999,
});

export function DebugGrid () {
  const show = React.useContext(DebugGridContext);
  if (!show) {
    return null;
  }

  const cols = [];
  for (let i = 0; i < 12; i++) {
    cols.push(<StyledDebugCol key={i} idx={i} />);
  }

  return <StyledDebugGrid>{cols}</StyledDebugGrid>;
}

export function DebugBreakpoints () {
  const show = React.useContext(DebugGridContext);
  if (!show) {
    return null;
  }

  return (
    <>
      <SmBreakpoint />
      <MdBreakpoint />
    </>
  );
}

const SmBreakpoint = styled.div((props) => ({
  position: 'fixed',
  left: `calc(${props.theme.breakpoints.sm} - 4px)`,
  top: 0,
  bottom: 0,
  width: '4px',
  backgroundColor: 'black',
  pointerEvents: 'none',
  zIndex: 999999999999,
}));

const MdBreakpoint = styled.div((props) => ({
  position: 'fixed',
  left: `calc(${props.theme.breakpoints.md} - 4px)`,
  top: 0,
  bottom: 0,
  width: '4px',
  backgroundColor: 'black',
  pointerEvents: 'none',
  zIndex: 999999999999,
}));

const StyledDebugCol = styled.div<{ idx: number }>((props) => ({
  width: '100%',
  backgroundColor: 'rgba(255,0,0,0.5)',
  marginRight: props.idx === 11 ? '0px' : '12px',
  marginLeft: props.idx === 0 ? '0px' : '12px',
}));

export const StyledDebugGrid = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(173,246,255,0.5)',
  display: 'flex',
  flexDirection: 'row',
  pointerEvents: 'none',
  zIndex: 999999999999,
});
