// import { saveExpandSettings } from 'containers/AuthProvider'
import Alert from 'components/Alert';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import {
	useExpandSettings,
	useSetNumberOfRows,
	useNumberOfRows,
  useSetUserSettings,
	AuthContext
} from 'containers/AuthProvider';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import './index.scss';

export interface ExpandProps {
	size?: 'sm' | 'md';
	weight?: 'medium' | 'semi-bold' | 'bold';
	setExpand?: any;
	expand?: boolean;
	modalname?: string;
	// isDisable?: any;
}

const ExpandDiv = styled.div(() => ({
	background: 'white',
	textDecoration: 'none',
	alignItems: 'center',
	border: '1px solid black',
	borderRadius: '4px',
	borderWidth: '1px',
	height: '30px',
	width: '100px',
	color: 'black',
	marginBottom: '12px',
	display: 'flex',
	minWidth: '110px',
}));

const Button = styled.button<ExpandProps>(() => ({
	background: 'transparent',
	textDecoration: 'none',
	alignItems: 'center',
	justifyContent: 'center',
	height: '29px',
	width: '75px',
	cursor: 'pointer',
	borderRadius: '0px',
	borderRight: '1px solid black !important',
	borderColor: 'unset',
	borderStyle: 'unset'
}));

const ChooseSettings = styled.button<ExpandProps>(() => ({
	background: 'transparent',
	textDecoration: 'none',
	alignItems: 'left',
	justifyContent: 'center',
	height: '32px',
	cursor: 'pointer',
	borderRadius: '0px',
	borderColor: 'unset',
	borderStyle: 'unset',
	margin: '2px'
}));

const DropDownIcon = styled.svg.attrs({
	version: '1.1',
	xmlns: 'http://www.w3.org/2000/svg',
	width: '14',
	height: '14',
	viewBox: '0 0 14 14',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: 'white'
	},
	'&.focus-visible': {
		backgroundColor: 'black'
	}
})`
  position: relative;
  top: 3px;
  left: 4px;
`;

const Settings = (
	<path
		d="M11.7599 7.62398C11.7839 7.42398 11.7999 7.21598 11.7999 6.99998C11.7999 6.78398 11.7839 6.57598 11.7519 6.37598L13.1039 5.31998C13.2239 5.22398 13.2559 5.04798 13.1839 4.91198L11.9039 2.69598C11.8239 2.55198 11.6559 2.50398 11.5119 2.55198L9.91991 3.19198C9.58391 2.93598 9.23191 2.72798 8.83991 2.56798L8.59991 0.871976C8.57591 0.711976 8.43991 0.599976 8.27991 0.599976H5.71991C5.55991 0.599976 5.43191 0.711976 5.40791 0.871976L5.16791 2.56798C4.77591 2.72798 4.41591 2.94398 4.08791 3.19198L2.49591 2.55198C2.35191 2.49598 2.18391 2.55198 2.10391 2.69598L0.823908 4.91198C0.743908 5.05598 0.775908 5.22398 0.903908 5.31998L2.25591 6.37598C2.22391 6.57598 2.19991 6.79198 2.19991 6.99998C2.19991 7.20798 2.21591 7.42398 2.24791 7.62398L0.895908 8.67998C0.775908 8.77598 0.743908 8.95198 0.815908 9.08798L2.09591 11.304C2.17591 11.448 2.34391 11.496 2.48791 11.448L4.07991 10.808C4.41591 11.064 4.76791 11.272 5.15991 11.432L5.39991 13.128C5.43191 13.288 5.55991 13.4 5.71991 13.4H8.27991C8.43991 13.4 8.57591 13.288 8.59191 13.128L8.83191 11.432C9.22391 11.272 9.58391 11.056 9.91191 10.808L11.5039 11.448C11.6479 11.504 11.8159 11.448 11.8959 11.304L13.1759 9.08798C13.2559 8.94398 13.2239 8.77598 13.0959 8.67998L11.7599 7.62398ZM6.99991 9.39998C5.67991 9.39998 4.59991 8.31998 4.59991 6.99998C4.59991 5.67998 5.67991 4.59998 6.99991 4.59998C8.31991 4.59998 9.39991 5.67998 9.39991 6.99998C9.39991 8.31998 8.31991 9.39998 6.99991 9.39998Z"
		fill="#333333"
	/>
);

const SettingsDiv = styled.div(() => ({
	marginTop: '-6px',
	background: 'white',
	textDecoration: 'none',
	alignItems: 'left',
	borderRadius: '4px',
	boxShadow: '0px 2px 4px 4px rgba(215, 215, 215, 0.5)',
	borderWidth: '1px',
	width: '118px',
	color: 'black',
	marginBottom: '12px'
}));

const ExpandAll: React.FC<ExpandProps> = ({
	setExpand,
	size,
	expand,
	modalname,
	// isDisable

}) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const authInfo = React.useContext(AuthContext);
	const isModuleKey = authInfo && Boolean(!Object.prototype.hasOwnProperty.call(authInfo?.expand_collapse_settings, modalname ?? ''))
	const [saveSettings, setSaveSettings] = React.useState<boolean>(Boolean(isModuleKey));
	const history = useHistory();
  const {
    updateUserSettings,
    isUpdatingUserSettings,
    updatingUserSettingsError,
    clearUpdatinguserSettingsError
  } = useSetUserSettings();
	const [submit, setSubmit] = React.useState(false);
	const setExpandSettings = useExpandSettings()
	const setNumberOfRowsInContext = useSetNumberOfRows();

	const gotoUserProfile = (): void => {
		history.push('/settings#settings');
	};
	const [resultsPerPage, setResultsPerPage] = React.useState(
		String(useNumberOfRows())
	);

	const pageData = async (data: any) => {
    const success = await updateUserSettings({ numberRows: authInfo?.DefaultNumberRows || 10, expandCollapse: data.expand_collapse_settings });
		if (success) {
			setSubmit(true);
		}
	};

	const handleSaveSetting = (data: any) => {
		setSaveSettings(!saveSettings);
		pageData(data);
	};

	return (
		<>
			<div style={{ display: 'flow-root' }}>
				<div style={{ float: 'right' }}>
					<ExpandDiv>
						<Button
							onClick={() => {
								setExpand(!expand);
								setExpandSettings(modalname, !expand);
							}}
							style={{ minWidth: '83px' }}
							size={size}
						>
							{expand === false ? 'Expand All' : 'Collapse All'}
						</Button>
						<Button className='setting--btn' aria-label='Settings Button' onClick={() => setOpen(!open)} style={{ borderRight: 'transparent !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<DropDownIcon style={{ position: 'unset', top: 'unset', right: 'unset' }}>
							{Settings}
						</DropDownIcon>
					</Button>
					</ExpandDiv>
					{open && (
						<SettingsDiv style={{ position: 'absolute' }}>
							<ChooseSettings
								onChange={(value) => {
									setSaveSettings(!!value);
								}}
								onClick={() => {
									saveSettings === false ? setExpandSettings(modalname, saveSettings, true) : setExpandSettings(modalname, expand);
									const removeData = authInfo?.expand_collapse_settings
									if (modalname && saveSettings === false && removeData) {
										delete removeData[modalname];
									}
									handleSaveSetting({
										expand_collapse_settings: {
											...(saveSettings === false) && { ...removeData },
											...((saveSettings !== false && modalname) && { ...authInfo?.expand_collapse_settings, [modalname]: expand }),
										}
									});
								}}
							>
								{saveSettings === false ? 'Remove Settings' : ' Save Settings'}
							</ChooseSettings>
							<ChooseSettings onClick={gotoUserProfile}>
								View Settings
							</ChooseSettings>
						</SettingsDiv>
					)}
				</div>
				<div>
					<Alert
						alertClassName='setting--alert'
						dismissible
						autoHide
						show={submit}
						onDismiss={() => (submit === true ? setSubmit(false) : '')}
						message={
							<>
								Content display settings updated{' '}
								<Link to="/settings#settings" style={{ color: 'white' }}>
									<u>View Settings</u>
								</Link>
							</>
						}
						design="success"
					/>
				</div>
			</div>
		</>
	);
};

export default ExpandAll;
