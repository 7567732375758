import styled from 'styled-components/macro';
import { getMarginStyles, MarginProps } from '../../utils/style';

/**
 * Hides its children on desktop.  Shows on mobile if `when` is true or not provided
 */
export const ShowOnMobile = styled.div<{ when?: boolean } & MarginProps>(
  ({ when = true, ...props }) => ({
    display: when ? 'block' : 'none',
    [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
      display: 'none',
    },
    ...getMarginStyles(props),
  })
);

/**
 * Hides its children on desktop.  Shows on tablet if `when` is true or not provided
 */
export const ShowOnTablet = styled.div<{ when?: boolean } & MarginProps>(
  ({ when = true, ...props }) => ({
    display: when ? 'block' : 'none',
    [`@media(min-width: ${props.theme.breakpoints.lg})`]: {
      display: 'none',
    },
    ...getMarginStyles(props),
  })
);

/**
 * Show on Print.
 */
 export const ShowOnPrint = styled.div<MarginProps>(
  ({ ...props }) => ({
    '@media print': {
      display: 'block',
    },
    ...getMarginStyles(props),
  })
);
