export const OptionsList = [10, 50, 100, 200, 500, 1000, 2000];

function generateArray (
  startFrom: number,
  diff: number,
  numberOfElements: number
) {
  return Array.from(
    { length: numberOfElements },
    (_, i) => startFrom + i * diff
  );
}
