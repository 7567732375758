/* eslint-disable */
import * as React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

export type NumTileColumns = 1 | 2 | 3 | 4 | 5 | 6;
export interface TilesProps {
  children: React.ReactNode;
  columns:
    | NumTileColumns
    | [xsScreen: NumTileColumns, smScreen: NumTileColumns]
    | [
        xsScreen: NumTileColumns,
        smScreen: NumTileColumns,
        mdScreen: NumTileColumns
      ];
		isTileOverflowStyling?: boolean
}

export const TilesHolder = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: `-${theme.spacing.lg}`,
  marginLeft: `-${theme.spacing.lg}`,
}));

const Tile = styled.div<{ columns: TilesProps['columns'] }>((props) => ({
  flex: `0 0 ${
    100 / (Array.isArray(props.columns) ? props.columns[0] : props.columns)
  }%`,
  ...getTileSmScreenStyles(props),
  ...getTileMdScreenStyles(props),
}));

function getTileSmScreenStyles (props: {
  theme: DefaultTheme;
  columns: TilesProps['columns'];
}) {
  if (Array.isArray(props.columns)) {
    return {
      [`@media(min-width: ${props.theme.breakpoints.sm})`]: {
        flex: `0 0 ${100 / props.columns[1]}%`,
      },
    };
  }

  return {};
}

function getTileMdScreenStyles (props: {
  theme: DefaultTheme;
  columns: TilesProps['columns'];
}) {
  if (Array.isArray(props.columns) && props.columns.length === 3) {
    return {
      [`@media(min-width: ${props.theme.breakpoints.md})`]: {
        flex: `0 0 ${100 / props.columns[2]}%`,
      },
    };
  }

  return {};
}

export const TileSpacer = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing.lg,
  paddingTop: theme.spacing.lg,
  height: '100%',
}));

export function Tiles (props: TilesProps) {
  return (
    <TilesHolder
		style={{ 
			overflowX: props.isTileOverflowStyling ? 'auto' : 'unset' 
	}}
		>
      {React.Children.toArray(props.children).map((child) => (
        <Tile columns={props.columns}>
          <TileSpacer>{child}</TileSpacer>
        </Tile>
      ))}
    </TilesHolder>
  );
}
