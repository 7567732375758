/* eslint-disable */
import * as React from "react";
import { Page } from "../../../components/Page";
import { Accordion } from "../../../components/Accordion";
import { Heading } from "../../../components/Heading";
import { Table } from "../../../components/Table";
import { Card } from "../../../components/Card";
import { Text } from "../../../components/Text";
import { Button } from "../../../components/Button";
import { useId, useQueryParams } from "../../../hooks";
import {
  getCorporateSurveysSearchResults,
  usePersonSearchResults,
} from "../../../data";
import { LoadingScreenReaderMessage } from "../../../components/LoadingScreenReaderMessage";
import { BulletedList } from "../../../components/BulletedList";
import { ListItem } from "../../../components/ListItem";
import { HideOnPrint } from 'components/HideOnMobile';
import { Link, useHistory } from "react-router-dom";
import {
  useGetAccessToken,
  useNumberOfRows,
  useSetNumberOfRows,
} from "../../../containers/AuthProvider";
import { searchDBs } from "data/searchDatabases";
import { stringify } from "query-string";
import { useSearchParams } from "components/UseSearchParams";

export function PersonSearchResultsPage(props: any) {
  const headingId = useId();
  const descriptionId = useId();
  const history = useHistory();
  const queryParams = useQueryParams(["name", "dbs", "page", 'dateInfo'], { onlyStrings: true });
  const [page, setPage] = React.useState(
    queryParams.page ? Number(queryParams.page) : 1
  );
  const [numberOfRows, setNumberOfRows] = React.useState(useNumberOfRows());
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const setNumberOfRowsInContext = useSetNumberOfRows();
  const searchResults = usePersonSearchResults({
    searchText: queryParams.name || "",
    dbs: queryParams.dbs || "",
    dateInfo: queryParams.dateInfo || undefined,
    page,
    numberOfRows,
  });
  const [selectedRows, setSelectedRows] = React.useState(Array(searchResults.resolvedData?.dbResults.length));
  const loading = searchResults.isFetching && !searchResults.latestData;
  const loadingFirstTime = loading && !searchResults.resolvedData;

  const noResults =
    searchResults.resolvedData &&
    searchResults.resolvedData.dbResults &&
    searchResults.resolvedData.dbResults.length === 0;

  // start
  const [loadingCompany, setLoadingCompany] = React.useState<boolean>(false);
  const getAccessToken = useGetAccessToken();
  const handleGetCompany = async (companyKey: string) => {
    setLoadingCompany(true);
    const getCompanySurvey = await getCorporateSurveysSearchResults(
      { searchForm: 'basic', companyName: companyKey, page: 0, numberOfRows: 1 },
      getAccessToken
    );
    setLoadingCompany(false);
    history.push(
      `/corporate-surveys/details/${getCompanySurvey.results[0].key}`
    );
  };
  // end

  const { value }: any = useSearchParams()
  const { page: removePage, ...newValue} = value;

  const switchCase = (param: any, value: any) => {
    switch (param) {
      case 'name':
        return queryParams.name
      case 'dbs': {
        // return queryParams.dbs
        const data = value?.split(',')
        return searchDBs.filter((item) => data.includes(item.code)).map((item) => item.name).join(', ')
      }
      default:
        return [];
    }
  }
  const handleSetPage = (page: number) => {
    const newPage = page + 1;
    const replaceUrl = '/person-search/results';
    const qs = {
      ...queryParams,
      page: newPage,
    };
    history.replace(`${replaceUrl}?${stringify(qs)}`, {
      ...(history.location.state as object),
    });
    
    setPage(newPage);
  };

  const previewSelectedResults = () => {
    props.history.push({
      pathname: '/corporate-surveys/details/',
      state: {
        keys: selectedKeys,
      },
    });
  }
  
  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level="1" size={["xxl", "xxl", "xxxl"]} mb="lg">
        Person Search - Search Results
        <LoadingScreenReaderMessage
          loading={loading}
          message=". Loading data..."
        />
      </Heading>
      <HideOnPrint>
        <Button
          label="New Search"
          mb={noResults ? "xxl" : "lg"}
          link="/person-search/search"
        />
      </HideOnPrint>

      {noResults && (
        <>
          <Text size="xl" mb="lg" weight="medium">
            0 results for{" "}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.name}
            </Text>
          </Text>
          <Text size="xl" mb="xxl" weight="medium">
            We are sorry, but 0 results were found for:{" "}
            <Text display="inline" size="xl" weight="bold">
              {queryParams.name}
            </Text>
          </Text>
          <Heading level="2" size="xl" mb="lg" weight="bold">
            Search Tips
          </Heading>
          <BulletedList>
            <ListItem gap="sm">
              Reduce the number of keywords used, or use more general words.
            </ListItem>
            <ListItem gap="sm">Double check spelling</ListItem>
          </BulletedList>
        </>
      )}

      {!noResults &&
        searchResults.resolvedData?.dbResults
          .filter((item) => item?.database?.code === "fpsu")
          .map((dbResult) => {
            const resolvedPage = dbResult.results.page || page;
            const startNum = resolvedPage * 10 - 9;
            const endNum =
              startNum + (dbResult.results.results.length || 1) - 1;

            return (
              <Card mb="xl">
                <Accordion openOnLoad>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    {dbResult.database.name} - Results
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Table
                      aria-labelledby={headingId}
                      aria-describedby={descriptionId}
                      onPageChange={handleSetPage}
                      page={page}
                      numTotalRows={endNum}
                      numRowsPerPage={numberOfRows}
                      loading={loading}
                      expectedColumns={4}
                      expectedRows={numberOfRows}
                      isEmpty={!dbResult.results.results.length}
                      data={dbResult.results}
                      queryParams={queryParams}
                      nbSelectedRows={selectedKeys.length}
                      print={true}
                      onNumberOfRowChange={(latestNumberOfRows) => {
                        setNumberOfRows(latestNumberOfRows);
                        setNumberOfRowsInContext(latestNumberOfRows);
                      }}
                      history={props.history.location.state}
                      switchCase={switchCase}
                    >
                      <Table.Head>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Text>Company Name</Text>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Text>Former Names</Text>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Text>Symbols</Text>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Text>Coverage Status</Text>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Head>

                      <Table.Body>
                        {dbResult.results.results.map((r, i) => (
                          <>
                            <Table.Row>
                              <Table.Cell minWidth="md">
                                <Link
                                  to={`/corporate-surveys/details/${r.key}`}
                                  className="black-text"
                                >
                                  <Text>{r.name}</Text>
                                </Link>
                              </Table.Cell>
                              {r.lead && (
                                <Table.Cell>
                                  <span>
                                    <Text>{r.lead}</Text>
                                  </span>
                                </Table.Cell>
                              )}
                              <Table.Cell minWidth="md">
                                {r.formerNames && r.formerNames?.length > 0 && (
                                  <BulletedList>
                                    {r.formerNames.map((formerName) => (
                                      <li>
                                        <Text>{formerName}</Text>
                                      </li>
                                    ))}
                                  </BulletedList>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {r.symbols && r.symbols?.length > 0 && (
                                  <BulletedList>
                                    {r.symbols.map((formerName) => (
                                      <li style={{ whiteSpace: "nowrap" }}>
                                        <Text>{formerName}</Text>
                                      </li>
                                    ))}
                                  </BulletedList>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {r.coverageStatus && (
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Text>
                                      {r.coverageStatus?.toUpperCase()}
                                    </Text>
                                  </span>
                                )}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        ))}
                      </Table.Body>
                    </Table>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            );
          })}

      {!noResults &&
        searchResults.resolvedData?.dbResults
          ?.filter((item) => item?.database?.code !== "fpsu")
          .map((dbResult) => {
            const resolvedPage = dbResult.results.page || page;
            const startNum = resolvedPage * 10 - 9;
            const endNum =
              startNum + (dbResult.results.results.length || 1) - 1;
            return searchDBs
              .map((item) => item?.code)
              .includes(dbResult?.database?.code) ? (
              // return (
              <Card mb="xl">
                <Accordion openOnLoad>
                  <Accordion.Heading size="xl" level="2" weight="semi-bold">
                    {dbResult?.database?.name} - Results
                  </Accordion.Heading>
                  <Accordion.Content>
                    <Table
                      aria-labelledby={headingId}
                      aria-describedby={descriptionId}
                      onPageChange={handleSetPage}
                      page={page}
                      numTotalRows={endNum}
                      numRowsPerPage={numberOfRows}
                      loading={loading}
                      expectedColumns={4}
                      expectedRows={numberOfRows}
                      isEmpty={!dbResult.results.results.length}
                      data={dbResult.results}
                      queryParams={queryParams}
                      onNumberOfRowChange={(latestNumberOfRows) => {
                        setNumberOfRows(latestNumberOfRows);
                        setNumberOfRowsInContext(latestNumberOfRows);
                      }}
                    >
                      <Table.Head>
                        <Table.Row>
                          {dbResult?.results?.columns?.map(
                            (columnName: string) => (
                              <>
                                <Table.HeaderCell>
                                  <Text>{columnName}</Text>
                                </Table.HeaderCell>
                              </>
                            )
                          )}
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {dbResult?.results?.results.map((r) => (
                          <>
                            <Table.Row>
                              <Table.Cell minWidth="md">
                                <Link
                                  to={
                                    dbResult?.database?.code === "tmga"
                                      ? `/external-databases/details/encyclopedia-of-associations/${r.key}`
                                      : `/directory-of-directors/details/${r.key}`
                                  }
                                  className="black-text"
                                >
                                  <Text>{r.name}</Text>
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    r.lead && handleGetCompany(r.lead);
                                  }}
                                  to={`/corporate-surveys/details/${r.key}`}
                                  className="black-text"
                                >
                                  <Text>{r.lead}</Text>
                                </Link>
                              </Table.Cell>
                            </Table.Row>
                          </>
                        ))}
                      </Table.Body>
                    </Table>
                  </Accordion.Content>
                  <Accordion.Footer size="xl" level="2" weight="semi-bold">
                    <div></div>
                  </Accordion.Footer>
                </Accordion>
              </Card>
            ) : (
              <></>
            );
          })}
    </Page>
  );
}
