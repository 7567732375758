import * as React from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import styled, { useTheme } from 'styled-components/macro';
import { ScreenReaderAvoidSplitting } from '../ScreenReaderAvoidSplitting';
import { Text } from '../Text';
import { usePopper } from 'react-popper';
import { lighten } from 'polished';
import { ArrowDownIcon } from '../../icons/ArrowDownIcon';
import { useId } from '../../hooks';
import './index.scss'

export interface MultiSelectFieldProps {
	/**
	 * Can be "full" or "md".  Defaults to "md", which is around 341px.  Can easily add other size codes like "sm" or "lg" here
	 */
	width?: 'full' | 'md';

	/**
	 * The text to place above the combobox
	 */
	label: string;

	/**
	 * The current value of the component, which should be an array of strings.  This component only supports controlled mode.
	 */
	value: string[];

  //	checked?: string[] | boolean | undefined;

	/**
	 * Called with the new array of strings as user interacts with multi select
	 */
	onChange: (newValue: string[]) => void;

	/**
	 * The "database" of items to search from.  This should be the full list of all items that the user can pick from
	 */
	items: string[] | Array<{ id: string, name: string }>;

	/**
	 * Sometimes the `items` array does not contain the currently selected items.
	 * In that case, this array should be used to provide the corresponding labels
	 * for the selected items.
	 *
	 * It's not necessary to use this prop if every selected item is inside the
	 * `items` prop already
	 */
	itemLabelHints?: Array<{ id: string, name: string }>;
}

/**
 * Accessible MultiSelect field.  Uses downshift, the standard react
 * library for doing this type of thing https://github.com/downshift-js/downshift
 */
export const MultiSelectField = (props: MultiSelectFieldProps) => {
	const items = props.items;
	const [inputValue, setInputValue] = React.useState('');

	const getItemLabelFromItem = (item: string | { id: string, name: string } | null): string => {
		if (!item) {
			return '';
		}

		if (typeof item === 'string') {
			return item;
		}

		return item.name;
	}

	const isItemSelected = (item: string | { id: string, name: string }) => {
		if (typeof item === 'string') {
			return selectedItems.indexOf(item) >= 0;
		}

		return selectedItems.indexOf(item.id) >= 0;
	}

	const findItemLabelByItemValue = (itemValue: string): string => {
		const allPossibleItems = [...items, ...(props.itemLabelHints || [])];
		const foundItem = (allPossibleItems as Array<string|{ id: string, name: string }>)
			.find((i) => {
				if (typeof i === 'string') {
					return i === itemValue;
				}

				return i.id === itemValue;
			});

		if (foundItem && typeof foundItem !== 'string') {
			return foundItem.name;
		}

		return foundItem || itemValue;
	}

	const {
		getSelectedItemProps,
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems, // selectedItem is an array of values (e.g. array of strings)
	} = useMultipleSelection({
		selectedItems: props.value,
		itemToString: findItemLabelByItemValue,
		onSelectedItemsChange: (params) => {
			props.onChange(params.selectedItems || []);
		}
	})

	const getFilteredItems = (items: Array<string> | Array<{ id: string, name: string }>): Array<string> | Array<{ id: string, name: string }> =>
		(items as Array<any>).filter(
			(item: string | { id: string, name: string }) =>
				!isItemSelected(item) &&
				getItemLabelFromItem(item).toLowerCase().trim().startsWith(inputValue.trim().toLowerCase()),
		)

	const {
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		getItemProps,
		selectItem,
	} = useCombobox<string | { id: string, name: string }>({
		inputValue,
		items: getFilteredItems(items), // This could be an array of strings or an array of objects with a value and label
		itemToString: getItemLabelFromItem,
		onStateChange: ({ inputValue, type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputChange:
					setInputValue(inputValue || '');

					break;
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
				case useCombobox.stateChangeTypes.InputBlur:
					if (selectedItem) {
						setInputValue('')
						addSelectedItem(typeof selectedItem === 'string' ? selectedItem : selectedItem.id)
						// @ts-ignore
						selectItem(null);
					}

					break;
				default:
					break;
			}
		}
	});

	const theme = useTheme();
	const descriptionId = useId();
	const [referenceElement, setReferenceElement] =
		React.useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
		null
	);
	const { styles, attributes, forceUpdate } = usePopper(
		referenceElement,
		popperElement,
		{
			strategy: 'fixed',
			placement: 'bottom'
		},
	);

	React.useEffect(() => {
		if (forceUpdate) {
			// See: https://github.com/popperjs/react-popper/issues/274
			forceUpdate();
		}
	}, [props.value, forceUpdate]);

	return (
		<Root widthCode={props.width}>
			<label {...getLabelProps()}>
				<ScreenReaderAvoidSplitting>
					<Text mb="xxs" weight='medium'>
						{props.label}
					</Text>
				</ScreenReaderAvoidSplitting>
			</label>
			<ComboboxWrapper hasItems={selectedItems.length > 0} ref={setReferenceElement}>
				{selectedItems.map((selectedItem, index) => (
					<SelectedItem
						key={`selected-item-${index}`}
						{...getSelectedItemProps({ selectedItem, index })}
					>
						{findItemLabelByItemValue(selectedItem)}
						<DeleteIcon
							onClick={(e) => {
								e.stopPropagation(); // See: https://github.com/downshift-js/downshift/issues/1068
								removeSelectedItem(selectedItem)
							}}
						/>
					</SelectedItem>
				))}
				<Combobox {...getComboboxProps()}>
					<Input
						{...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
						aria-describedby={descriptionId}
            className="multiselect-input"
					/>
					<DropDownButton type="button" {...getToggleButtonProps()} aria-label={'toggle menu'}>
						<ArrowDownIcon size="sm" />
					</DropDownButton>
				</Combobox>
			</ComboboxWrapper>
			<div
				ref={setPopperElement}
				style={{
					// ...styles.popper,
          position: 'absolute',
					width: referenceElement?.clientWidth || 0,
          marginBottom: '-20px',
					visibility: isOpen ? 'visible' : 'hidden',
					pointerEvents: isOpen ? undefined : 'none',
					zIndex: theme.zIndex.dropDownMenus
				}}
				{...attributes.popper}
			>
				<Listbox {...getMenuProps()}>
					{isOpen &&
						getFilteredItems(items).map((item: string | { id: string, name: string }, index: number) => (
							<ListboxItem
								key={`${typeof item === 'string' ? item : item.id}${index}`}
								{...getItemProps({ item, index })}
							>
								{getItemLabelFromItem(item)}
							</ListboxItem>
						))}
				</Listbox>
			</div>
			<Text id={descriptionId} size="xs" color="light" mt="xs">Search to add multiple selections</Text>
		</Root>
	);
};

const Root = styled.div<{ widthCode?: 'full' | 'md' }>((props) => ({
	width: props.widthCode === 'full' ? '100%' : '341px',
	maxWidth: '100%'
}));
const MultiSelectInput = styled.div({
	position: 'relative'
});
const ComboboxWrapper = styled.div<{ hasItems: boolean }>((props) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	border: `1px solid ${props.theme.palette.border.main}`,
	padding: '8px',
	paddingBottom: props.hasItems ? '6px' : undefined, // take off 2px to account for bottom margin on items
	borderRadius: '3px',
	flexWrap: 'wrap',
	'&:focus-within': {
		padding: '7px',
		paddingBottom: props.hasItems ? '5px' : undefined, // take off 2px to account for bottom margin on items
		border: `2px solid ${props.theme.palette.primary.main}`
	}
}));

const Input = styled.input<{ widthCode?: 'md' | 'full'; hasError?: boolean }>(
	(props) => ({
		width: '0px',
		flexGrow: 1,
		outline: 'none',
		border: 'none',
		fontSize: props.theme.typography.textSizes.md
	})
);

const Combobox = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexGrow: 1
});

const SelectedItem = styled.span((props) => ({
	display: 'inline-flex',
	flexDirection: 'row',
	alignItems: 'center',
	fontSize: props.theme.typography.textSizes.sm,
	backgroundColor: lighten(0.4, props.theme.palette.primary.main),
	padding: '1px',
	paddingLeft: '4px',
	paddingRight: '4px',
	marginRight: '2px',
	borderRadius: '4px',
	marginBottom: '2px',
	whiteSpace: 'pre-wrap',
	maxWidth: '90%'
}));

const Listbox = styled.ul({
	listStyle: 'none',
	margin: 0,
	padding: 0,
	borderRadius: '4px',
	border: '1px solid rgb(231,231,231)',
	boxShadow:
		'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
	paddingTop: '8px',
	paddingBottom: '8px',
	backgroundColor: 'white',
	maxHeight: '200px',
	overflowY: 'auto'
});

const ListboxItem = styled.li((props) => ({
	background: 'transparent',
	border: 'none',
	fontSize: props.theme.typography.textSizes.md,
	width: '100%',
	cursor: 'pointer',
	outline: 'none',
	paddingTop: '8px',
	paddingBottom: '8px',
	paddingLeft: '16px',
	paddingRight: '16px',
	textAlign: 'left',
	position: 'relative',
	zIndex: 1,
	whiteSpace: 'pre',
	"&[aria-selected='true']": {
		backgroundColor: '#f3f3f3'
	},
	'&:hover': {
		backgroundColor: '#f3f3f3'
	}
}));

const DropDownButton = styled.button({
	background: 'transparent',
	border: 'none',
	padding: 0,
	height: '16px'
});

function DeleteIcon (props: { onClick: (e: React.MouseEvent) => void }) {
	const size = 16;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={size}
			viewBox="0 0 24 24"
			width={size}
			aria-hidden
			onClick={props.onClick}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
		</svg>
	);
}

export default MultiSelectField;
