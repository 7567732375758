import * as React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/Page';
import { Heading } from '../../../components/Heading';
import { useId, usePreviousStorageData } from '../../../hooks';
import { TextField } from '../../../components/TextField';
import { RadioList } from '../../../components/RadioList';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Tabs, Tab } from '../../../components/Tabs';
import ClearButton from 'components/ClearButton';

export function HistoricalReportsSearchPage () {
  const history = useHistory();
  const headingId = useId();
  const [companyName, setCompanyName] = React.useState('');
  const [stockSymbol, setStockSymbol] = React.useState('');

  React.useEffect(() => {
    if (history.action === 'POP') {
      loadLatestEntries();
    } else {
      clearCheck();
    }
  }, []);

  /* companyName */
  const company = usePreviousStorageData(companyName) || '';
  React.useEffect(() => {
    if (company !== companyName) {
      localStorage.setItem('companyNameHr', JSON.stringify(companyName));
    }
  }, [companyName]);

  /* stockSymbol */
  const stock = usePreviousStorageData(stockSymbol) || '';
  React.useEffect(() => {
    if (stock !== stockSymbol) {
      localStorage.setItem('stockSymbolHr', JSON.stringify(stockSymbol));
    }
  }, [stockSymbol]);

  const loadLatestEntries = () => {
    const companyName = localStorage.getItem('companyNameHr');
    if (companyName) {
      setCompanyName(JSON.parse(companyName));
    }
    const stockSymbol = localStorage.getItem('stockSymbolHr');
    if (stockSymbol) {
      setStockSymbol(JSON.parse(stockSymbol));
    }
  }

  const clearCheck = () => {
    setCompanyName('');
    setStockSymbol('');
  }

  const historicalSubmitData = () => {
    history.push(
      `/historical-reports/results?companyName=${companyName}&stockSymbol=${stockSymbol}`
    );
  }

  return (
    <Page aria-label="Financial Post Historical Reports">
      <Tabs aria-label="Search Options">
        <Tab label="Search">
          <Heading id={headingId} level="1" size={['xxl', 'xxl', 'xxxl']}>
            Financial Post Historical Reports - Search
          </Heading>

          <Text mt="md" mb="md" lineHeight="md">
            Detailed descriptions on operations, 7-year consolidated financial
            statements, capital stock changes and company history dating back to
            incorporation for the top currently and formerly traded Canadian
            companies.
          </Text>

          <form
            aria-labelledby={headingId}
            onSubmit={(e) => {
              e.preventDefault();
              historicalSubmitData();
            }}
            >
            <TextField
              mt="lg"
              label="Company Name"
              value={companyName}
              onChange={setCompanyName}
            />

            <TextField
              mt="lg"
              mb="xl"
              label="Stock Symbol"
              size={8}
              value={stockSymbol}
              onChange={setStockSymbol}
            />
            <Button mt='lg' label='Search' mb={'lg'} type='submit'/>
          </form>
          <div>
            <ClearButton onClick={clearCheck} className="clearstatus" />
          </div>
        </Tab>
      </Tabs>
    </Page>
  );
}
