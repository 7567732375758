import React from 'react';
import { Tiles } from 'components/Tiles';
import { Heading } from 'components/Heading';
import styled from 'styled-components';

type IColumn = string | React.ReactElement;

type IRowElement = string | React.ReactElement | number;

type IRow = IRowElement[];

interface IProps {
  title?: string | React.ReactElement;
  columns: IColumn[];
  rows: IRow[];
}

export const StatisticsTable: React.FC<IProps> = ({ title, columns, rows }) => {
  return (
    <>
      <Tiles columns={[1, 1]}>
        {title && (
          <Heading level="3" size="lg">
            {title}
          </Heading>
        )}
        <table className="grouped-table share-price-statistics-table">
          <thead>
            <tr>
              {columns.map((column) => {
                return column
? (
                  <th className="cellbordertopbottom">{column}</th>
                )
: (
                  <td></td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr>
                {row.map((rowElement) => (
                  <td>{rowElement}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Tiles>
    </>
  );
};
