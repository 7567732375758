import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useIndustryReportInfo } from '../../../data';
import { Page } from '../../../components/Page';
import { Card } from '../../../components/Card';
import { Accordion } from '../../../components/Accordion';
import { PageAccordion } from '../../../components/PageAccordion';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { useId } from '../../../hooks';
import { ScreenReaderOnly } from '../../../components/ScreenReaderOnly';
import { ScreenReaderAvoidSplitting } from '../../../components/ScreenReaderAvoidSplitting';
import { Row } from '../../../components/Row';
import { HideOnPrint } from 'components/HideOnMobile';
import { PrintFriendly } from '../../../components/PrintFriendly';
import { ChartIcon } from '../../../icons/ChartIcon';
import { PeopleIcon } from '../../../icons/PeopleIcon';
import { ApartmentIcon } from '../../../icons/ApartmentIcon';
import { TrophyIcon } from '../../../icons/TrophyIcon';
import { Box, BoxProps } from '../../../components/Box';
import { Tiles } from '../../../components/Tiles';
import { parseIsolatedEntityName } from 'typescript';
import ExpandAll from 'components/ExpandSettings';
import { Col } from 'react-grid-system';
import { useAuthContext, useExpandSettings } from 'containers/AuthProvider';

import RecordFormatter from '../../../components/RecordFormatter';
import { ShowOnMobile, ShowOnTablet } from '../../../components/ShowOnMobile';
import { HideOnMobile, HideOnTablet } from '../../../components/HideOnMobile';
import { report } from 'process';

export function IndustryReportsDetailsPage () {
  const history = useHistory();
  const data = history.location.state as Array<string>;
  let keys = data?.keys as unknown as Array<string>;
  if (!keys) {
    const params = useParams<{ docKey: string }>();
    keys = decodeURIComponent(params.docKey).split(',');
  }
  const companies: any [] = [];
  keys.forEach((key: string) => {
    const { data: reportInfo, isLoading } = useIndustryReportInfo(key);
    const headingId = useId();

    companies.push({ reportInfo: reportInfo, isLoading: isLoading, headingId: headingId });
  });

  const [disable, setDisable] = React.useState<boolean>(false);

  const authContext = useAuthContext();
  const defaultExpand = authContext?.expand_collapse_settings['industry-reports'] ?? authContext?.expand_collapse_settings.default
  const [expand, setExpand] = React.useState<boolean>(Boolean(defaultExpand));

  return (
    <Page aria-label="Industry Reports" padding="none" width="full">
      {companies.map((element, i) =>
      <Page aria-labelledby={element.headingId}>
        <Heading level="1" mb="md" mt="xxxs" id={element.headingId}>
          <ScreenReaderAvoidSplitting>
            <Text
              mt="sm"
              color="heading"
              size={['xxl', 'xxl', 'xxxl']}
              weight="semi-bold"
            >
              Industry Reports
            </Text>
            {/* <Text mt="sm" color="heading" loading={isLoading} loadingWidth={200} size={[ "xl", "xl", "xxl" ]} weight="semi-bold">{fiInfo?.data.fpsecurity.fpcompany.names.legalname}</Text> */}
          </ScreenReaderAvoidSplitting>
        </Heading>

        {element.reportInfo && (
          <>
            <Heading level="2" mb="xxs" size={['lg', 'lg', 'xl']}>
              {element.reportInfo?.industryName}
            </Heading>
            <Text mb="xl">
              ({element.reportInfo?.numCompaniesIncluded} companies included)
            </Text>
          </>
        )}
        {i === 0 &&
        <HideOnPrint>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '16px', textAlign: 'right' }}>
              <PrintFriendly></PrintFriendly>
            </div>
            <ExpandAll setExpand={setExpand} expand={expand} modalname='industry-reports' />
          </div>
        </HideOnPrint>
        }
        {element.reportInfo?.htmlIndustryRecommendation &&
          element.reportInfo?.htmlIndustryRecommendation?.body.innerHTML.length > 0 && (
            <Card mb="lg">
              <Accordion openOnLoad={true}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Industry Recommendation
                </Accordion.Heading>
                <Accordion.Content>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.reportInfo?.htmlIndustryRecommendation?.body
                            .innerHTML || ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          )}

        {element.reportInfo?.htmlLatestQuarterlyResults &&
          element.reportInfo?.htmlLatestQuarterlyResults?.body.innerHTML.length > 0 && (
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Latest Quarterly Results
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.reportInfo?.htmlLatestQuarterlyResults?.body
                            .innerHTML || ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          )}

        {element.reportInfo?.htmlIndustryDataBank &&
          element.reportInfo?.htmlIndustryDataBank?.body.innerHTML.length > 0 && (
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Industry Data Bank
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.reportInfo?.htmlIndustryDataBank?.body.innerHTML || ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          )}

        {element.reportInfo?.htmlCompaniesIncluded &&
          element.reportInfo?.htmlCompaniesIncluded?.body.innerHTML.length > 0 && (
            <Card mb="lg">
              <Accordion openOnLoad={expand}>
                <Accordion.Heading size="xl" level="2" weight="semi-bold">
                  Companies Included
                </Accordion.Heading>
                <Accordion.Content isOverflowScrollStyling>
                  <Tiles columns={[1, 1]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.reportInfo?.htmlCompaniesIncluded?.body.innerHTML ||
                          ''
                      }}
                    />
                  </Tiles>
                </Accordion.Content>
                <Accordion.Footer size="xl" level="2" weight="semi-bold">
                  <div></div>
                </Accordion.Footer>
              </Accordion>
            </Card>
          )}
      </Page>
      )}
    </Page>
  );
}
