import * as React from 'react';
import { useHistory } from 'react-router';
import { FPCol } from 'components/FPCol/FPCol';
import { FPRow } from 'components/FPRow/FPRow';
import { Link } from 'components/Link';
import styled from 'styled-components';
import { Heading } from '../../../components/Heading';
import { Page } from '../../../components/Page';
import { Text } from '../../../components/Text';
import { useId } from '../../../hooks';
import { SearchIcon } from 'icons/SearchIcon';
import { getMarginStyles, MarginProps } from 'utils/style';
import { SearchField } from 'components/SearchField';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 2px 5px 2px rgba(215, 215, 215, 0.5);
  border-radius: 8px;
  padding: 1rem;
`;

const P = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  margin: 0;
`;

export function HelpIndexPage () {
  const headingId = useId();
	const history = useHistory();
	const [searchValue, setSearchValue] = React.useState('');

	const helpTopics: Array<{ slug: string; name: string; description: string; }> = [
		{
			slug: 'mergers-and-acquisitions',
			name: 'Mergers & Acquisitions in Canada',
			description: 'Quickly drill down into specific transactions in the areas that are of vital interest to your firm or clients',
		},
		{
			slug: 'external-databases',
			name: 'External Databases',
			description: 'Learn about the other 5 more databases available on FP Advisor',
		},
		{
			slug: 'content-overview',
			name: 'Content Overview',
			description: 'We provide a wide selection of Canadian news content, and Infomart also provides access to a variety of corporate information',
		},
		{
			slug: 'corporate-analyzer',
			name: 'Corporate Analyzer',
			description: 'Learn about fundamental information on the top 1,400 publicly traded Canadian companies',
		},
		{
			slug: 'corporate-snapshots',
			name: 'Corporate Snapshots',
			description: 'Get an overview of a company as well as its industry',
		},
		{
			slug: 'corporate-surveys',
			name: 'Corporate Surveys',
			description: 'Learn to get detailed information on all publicly traded Canadian companies',
		},
		{
			slug: 'directory-of-directors',
			name: 'Directory of Directors',
			description: 'Get information on over 28,000 Canadian directors and executives of Canadian companies who reside in Canada',
		},
		{
			slug: 'dividends',
			name: 'Dividends',
			description: 'Get Information about future and past dividend payments and frequencies on all Canadian and foreign inter-listed companies',
		},
		{
			slug: 'faq',
			name: 'FAQ - Frequently Asked Questions',
			description: 'What databases can I search? Where does the data come from? How often is it updated?',
		},
		{
			slug: 'fixed-income',
			name: 'Fixed Income',
			description: 'Learn about public offerings and private placements of preferred shares and corporate debt belonging to Canadian companies',
		},
		{
			slug: 'global-search',
			name: 'Global Search',
			description: 'Learn about how to use Global Search',
		},
		{
			slug: 'historical-reports',
			name: 'Historical Reports',
			description: 'Learn about the operations and history of the top currently and formerly traded Canadian companies',
		},
		{
			slug: 'industry-reports',
			name: 'Industry Reports',
			description: 'Learn about industry ratios for 30 industries in which top 450 publicly traded Canadian companies compete',
		},
		{
			slug: 'investor-reports',
			name: 'Investor Reports',
			description: 'Learn to access the following information from the top 450 publicly traded Canadian companies',
		},
		{
			slug: 'new-issues',
			name: 'New Issues',
			description: 'Learn about specific aspects of the new issue/IPO industry that are of vital interest to your firm or clients',
		},
		{
			slug: 'person-search',
			name: 'Person Search',
			description: 'Search and view corporate data about people in our 7 databases',
		},
		{
			slug: 'lead-list-generator',
			name: 'Lead List Generator',
			description: 'Learn about the advantages of organizing direct mail campaigns, prospecting, and researching sales territories quickly and easily',
		},
		{
			slug: 'predecessor-and-defunct',
			name: 'Predecessor & Defunct',
			description: 'Learn about specific aspects of the new issue/IPO industry that are of vital interest to your firm or clients',
		},
	];

  return (
    <Page aria-labelledby={headingId}>
      <Heading id={headingId} level='1' size={['xxl', 'xxl', 'xxxl']}>
        Help Centre
      </Heading>
      <Text mt='md' lineHeight='md'>
        For FP Advisor Customer Service, please e-mail <a href="mailto:fpadvisor@postmedia.com" className="linkEmail">fpadvisor@postmedia.com</a>
      </Text>
      <Text mt='md' lineHeight='md'>
        Detailed information on how to use FP Advisor information.
      </Text>
      <div
        style={{
          marginTop: '16px',
          maxWidth: '600px',
        }}
      >
				<form
					onSubmit={(e) => {
						e.preventDefault();
						history.push(`/help/search-results?query=${searchValue}`);
					}}
				>
					<SearchField placeholder='Search' aria-label='Search' value={searchValue} setValue={setSearchValue} />
				</form>
      </div>

      <Text mt='md' mb='lg'>
        Find answers to commonly asked questions about FP Advisor on the <Link style={{ color: '#2E4EBF', fontWeight: 'bold' }} to={'/help/details/faq'}>Frequently Asked Questions</Link> page.
      </Text>

      <FPRow>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
					<Card>
						<div>
							<Heading size={['lg', 'lg', 'lg']} level='2' mb='md'>
								Abbreviations
							</Heading>
							<Text lineHeight='md'>
								<P>
									Find out what acronym, abbreviation or intialism stands for
								</P>
							</Text>
						</div>
						<Text size='lg' alignX='right' lineHeight='md'>
              <Link to='/help/abbreviations' className='linkSearchCard'>
                <Text size='lg' alignX='right' lineHeight='md'>
                  <span className='linkSearch'>Learn More</span>
                  <span className='linkArrow'>&nbsp;&rsaquo;</span>
                </Text>
              </Link>
						</Text>
					</Card>
        </FPCol>
        <FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
					<Card>
						<div>
							<Heading size={['lg', 'lg', 'lg']} level='2' mb='md'>
								Glossary
							</Heading>
							<Text lineHeight='md'>
								<P>Find definitions for commonly used words in FP Advisor</P>
							</Text>
						</div>
						<Text size='lg' alignX='right' lineHeight='md'>
              <Link to='/help/details/glossary' className='linkSearchCard'>
                <Text size='lg' alignX='right' lineHeight='md'>
                  <span className='linkSearch'>Learn More</span>
                  <span className='linkArrow'>&nbsp;&rsaquo;</span>
                </Text>
              </Link>
						</Text>
					</Card>
        </FPCol>
      </FPRow>

      <Heading level='2' size='xl' mb='lg' mt="xl">
        Help by topic
      </Heading>

      <FPRow>
				{helpTopics.map(t => (
					<FPCol xs={12} sm={6} style={{ marginBottom: '1.5rem' }}>
						<Card>
								<div>
									<Heading size={['lg', 'lg', 'lg']} level='2' mb='md'>
										{t.name}
									</Heading>
									<Text lineHeight='md'>
										<p>
											{t.description}
										</p>
									</Text>
								</div>
								<Text size='lg' alignX='right' lineHeight='md'>
                  <Link to={`/help/details/${t.slug}`} className='linkSearchCard'>
                    <Text size='lg' alignX='right' lineHeight='md'>
                      <span className='linkSearch'>Learn More</span>
                      <span className='linkArrow'>&nbsp;&rsaquo;</span>
                    </Text>
                  </Link>
								</Text>
							</Card>
						</FPCol>
				))}
			</FPRow>
    </Page>
  );
}
