import * as React from 'react';
import { IconBase, IconBaseProps } from '../IconBase';

export function CsvIcon (props: Omit<IconBaseProps, 'paths'>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4V18H16.5V20H2C0.89543 20 0 19.1046 0 18V4H2Z" fill="#323232"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 0C5.34315 0 4 1.34315 4 3V13C4 14.6569 5.34315 16 7 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H7ZM7.23999 5.1C6.24588 5.1 5.43999 5.90589 5.43999 6.9V9.4C5.43999 10.3941 6.24588 11.2 7.23999 11.2H8.33999H9.13999V9.6H8.33999H7.23999C7.12953 9.6 7.03999 9.51046 7.03999 9.4V6.9C7.03999 6.78954 7.12953 6.7 7.23999 6.7H8.33999H9.13999V5.1H8.33999H7.23999ZM10.1 6.90002C10.1 5.90591 10.9059 5.10002 11.9 5.10002H13H13.8V6.70002H13H11.9C11.7895 6.70002 11.7 6.78957 11.7 6.90002V7.15002C11.7 7.26048 11.7895 7.35002 11.9 7.35002H12C12.9941 7.35002 13.8 8.15591 13.8 9.15002V9.40002C13.8 10.3941 12.9941 11.2 12 11.2H10.9H10.1V9.60002H10.9H12C12.1105 9.60002 12.2 9.51048 12.2 9.40002V9.15002C12.2 9.03957 12.1105 8.95002 12 8.95002H11.9C10.9059 8.95002 10.1 8.14414 10.1 7.15002V6.90002ZM16.0639 5.66237C15.9326 5.24049 15.4842 5.00489 15.0623 5.13614C14.6405 5.26739 14.4049 5.7158 14.5361 6.13768L15.9361 10.6377C16.0402 10.9721 16.3497 11.2 16.7 11.2C17.0503 11.2 17.3598 10.9721 17.4639 10.6377L18.8639 6.13768C18.9951 5.7158 18.7595 5.26739 18.3377 5.13614C17.9158 5.00489 17.4674 5.24049 17.3361 5.66237L16.7 7.70703L16.0639 5.66237Z" fill="#323232"/>
    </svg>
  );
}
