import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from './theme';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query-devtools';

/** components */
import {
  HideWhenGlobalSearchResultsOpen,
  GlobalSearchProvider,
  GlobalSearchResults,
} from './containers/GlobalSearch';
import { TopNav, ContentUnderTopNav } from './containers/TopNav';
import {
  SideNav,
  ContentBesideSideNav,
  SideNavProvider,
} from './containers/SideNav';

import { Row } from './components/Row';
import {
  DebugBreakpoints,
  DebugToggle,
  DebugGridProvider,
} from './components/DebugGrid';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './containers/AuthProvider';
import { SkipNavigationButton } from './containers/SkipNavigationButton';

/** pages */
import { HomePage } from './pages/HomePage';

import { CorporateSnapshotSearchResultsPage } from './pages/CorporateSnapshot/ResultsPage';
import { CorporateSnapshotSearchPage } from './pages/CorporateSnapshot/SearchPage';
import { CorporateSnapshotDetailsPage } from './pages/CorporateSnapshot/DetailsPage';

import { CorporateSurveysSearchPage } from './pages/CorporateSurveys/SearchPage';
import { CorporateSurveysResultsPage } from './pages/CorporateSurveys/ResultsPage';
import { CorporateSurveysDetailsPage } from './pages/CorporateSurveys/DetailsPage';
import { CorporateSurveysCsvExportPage } from './pages/CorporateSurveys/ExportPage';

import { CorporateAnalyzerSearchPage } from './pages/CorporateAnalyzer/SearchPage';
import { CorporateAnalyzerSearchResultsPage } from './pages/CorporateAnalyzer/ResultsPage';
import { CorporateAnalyzerDetailsPage } from './pages/CorporateAnalyzer/DetailsPage';

import { InvestorReportsSearchPage } from './pages/InvestorReports/SearchPage';
import { InvestorReportsSearchResultsPage } from './pages/InvestorReports/ResultsPage';
import { InvestorReportsDetailsPage } from './pages/InvestorReports/DetailsPage';

import { HistoricalReportsSearchPage } from './pages/HistoricalReports/SearchPage';
import { HistoricalReportsSearchResultsPage } from './pages/HistoricalReports/ResultsPage';
import { HistoricalReportsDetailsPage } from './pages/HistoricalReports/DetailsPage';

import { IndustryReportsListingPage } from './pages/IndustryReports/ListingPage';
import { IndustryReportsDetailsPage } from './pages/IndustryReports/DetailsPage';

import { MergersAcquisitionsSearchPage } from './pages/MergersAcquisitions/SearchPage';
import { MergersAcquisitionsResultsPage } from './pages/MergersAcquisitions/ResultsPage';
import { MergersAcquisitionsDetailsPage } from './pages/MergersAcquisitions/DetailsPage';
import { MergersAcquisitionsCsvExportPage } from './pages/MergersAcquisitions/ExportPage';

import { NewIssuesSearchPage } from './pages/NewIssues/SearchPage';
import { NewIssuesSearchResultsPage } from './pages/NewIssues/ResultsPage';
import { NewIssuesDetailsPage } from './pages/NewIssues/DetailsPage';
import { NewIssuesCsvExportPage } from './pages/NewIssues/ExportPage';

import { DividendsSearchPage } from './pages/Dividends/SearchPage';
import { DividendsSearchResultsPage } from './pages/Dividends/ResultsPage';
import { DividendsDetailsPage } from './pages/Dividends/DetailsPage';

import { FixedIncomeSearchPage } from './pages/FixedIncome/SearchPage';
import { FixedIncomeSearchResultsPage } from './pages/FixedIncome/ResultsPage';
import { FixedIncomeDetailsPage } from './pages/FixedIncome/DetailsPage';
import { FixedIncomeCsvExportPage } from './pages/FixedIncome/ExportPage';

import { PredecessorDefunctSearchPage } from './pages/PredecessorDefunct/SearchPage';
import { PredecessorDefunctSearchResultsPage } from './pages/PredecessorDefunct/ResultsPage';
import { PredecessorDefunctDetailsPage } from './pages/PredecessorDefunct/DetailsPage';
import { PredecessorDefunctCsvExportPage } from './pages/PredecessorDefunct/ExportPage';

import { LeadListGeneratorIndexPage } from './pages/LeadListGenerator/IndexPage';
import { LeadListGeneratorSearchExternalPage } from './pages/LeadListGenerator/SearchPages/ExternalDatabases';
import { LeadListGeneratorSearchDirectoryOfDirectorsPage } from './pages/LeadListGenerator/SearchPages/DirectoryOfDirectors';
import { LeadListGeneratorSearchCorporateSurveysPage } from './pages/LeadListGenerator/SearchPages/CorporateSurveys';
import { LeadListGeneratorSearchResultsPage } from './pages/LeadListGenerator/ResultsPage';

import { DirectorsSearchPage } from './pages/Directors/SearchPage';
import { DirectorsSearchResultsPage } from './pages/Directors/SearchResults';
import { DirectorsDetailsPage } from './pages/Directors/Details';
import { DirectorsCsvExportPage } from './pages/Directors/ExportPage';

import { DirectorsConnectorsSearchResultsPage } from './pages/Directors/Connectors/ResultsPage';
import { DirectorsConnectorsDetailsPage } from './pages/Directors/Connectors/DetailsPage';

import { PersonSearchPage } from './pages/PersonSearch/SearchPage';
import { PersonSearchResultsPage } from './pages/PersonSearch/ResultsPage';

import { ExternalDatabasesIndexPage } from './pages/ExternalDatabases/IndexPage';
import { ExternalDatabasesSearchPage } from './pages/ExternalDatabases/SearchPage';
import { ExternalDatabasesSearchResultsPage } from './pages/ExternalDatabases/ResultsPage';
import { ExternalDatabasesDetailsPage } from './pages/ExternalDatabases/DetailsPage';
import { ExternalDatabasesCsvExportPage } from './pages/ExternalDatabases/ExportPage';

import { NotFoundPage } from './pages/NotFoundPage';
import { Footer } from './containers/Footer';
import { CopyrightPage } from './pages/CopyrightPage';
import { LoginPage } from './pages/LoginPage';
import { LoginForgotPasswordPage } from './pages/LoginForgotPasswordPage';
import { AboutUsPage } from './pages/AboutUsPage';

import { HelpIndexPage } from './pages/HelpPage/HelpIndex';
import { HelpDetailsPage } from './pages/HelpPage/HelpDetails';
import { HelpSearchResultPage } from 'pages/HelpPage/HelpSearchResultPage';
import { HelpGlossaryPage } from 'pages/HelpPage/HelpGlossaryPage';
import { HelpAbbreviationsPage } from 'pages/HelpPage/HelpAbbreviationsPage';

import { OrderingProductsPage } from './pages/OrderingProductsPage';
import { CorporateDataPage } from './pages/CorporateDataPage';
import { SettingsPage } from './pages/SettingsPage';
import { BetaModal, BetaModalProvider } from './containers/BetaModal';
import { HideOnPrint } from 'components/HideOnMobile';
import { ShowOnPrint } from 'components/ShowOnMobile';

function App () {
  return (
    <Providers>
      <SkipNavigationButton />
      <HideOnPrint>
        <TopNav />
      </HideOnPrint>
      <ContentUnderTopNav enableStickyFooter>
        <GlobalSearchResults />
        <HideWhenGlobalSearchResultsOpen>
          <Row>
            <HideOnPrint>
              <SideNav />
            </HideOnPrint>
            <BetaModal />
            <ContentBesideSideNav>
              <ScrollToTop>
                <Switch>
                  <Route path="/" exact component={HomePage} />
                  /* Corporate Snapshots */
                  <Route
                    path="/corporate-snapshots/search"
                    exact
                    component={CorporateSnapshotSearchPage}
                  />
                  <Route
                    path="/corporate-snapshots/results"
                    exact
                    component={CorporateSnapshotSearchResultsPage}
                  />
                  <Route
                    path="/corporate-snapshots/details/:companyKey"
                    exact
                    component={CorporateSnapshotDetailsPage}
                  />
                  /* Corporate Surveys */
                  <Route
                    path="/corporate-surveys/search"
                    exact
                    component={CorporateSurveysSearchPage}
                  />
                  <Route
                    path="/corporate-surveys/results"
                    exact
                    component={CorporateSurveysResultsPage}
                  />
                  <Route
                    path="/corporate-surveys/details"
                    exact
                    component={CorporateSurveysDetailsPage}
                  />
                  <Route
                    path="/corporate-surveys/details/:companyKey"
                    exact
                    component={CorporateSurveysDetailsPage}
                  />
                  <Route
                    path="/corporate-surveys/csv-export"
                    exact
                    component={CorporateSurveysCsvExportPage}
                  />
                  /* Corporate Analyzer */
                  <Route
                    path="/corporate-analyzer/search"
                    exact
                    component={CorporateAnalyzerSearchPage}
                  />
                  <Route
                    path="/corporate-analyzer/results"
                    exact
                    component={CorporateAnalyzerSearchResultsPage}
                  />
                  <Route
                    path="/corporate-analyzer/details"
                    exact
                    component={CorporateAnalyzerDetailsPage}
                  />
                  <Route
                    path="/corporate-analyzer/details/:docKey"
                    exact
                    component={CorporateAnalyzerDetailsPage}
                  />
                  /* Investor Reports */
                  <Route
                    path="/investor-reports/search"
                    exact
                    component={InvestorReportsSearchPage}
                  />
                  <Route
                    path="/investor-reports/results"
                    exact
                    component={InvestorReportsSearchResultsPage}
                  />
                  <Route
                    path="/investor-reports/details"
                    exact
                    component={InvestorReportsDetailsPage}
                  />
                  <Route
                    path="/investor-reports/details/:docKey"
                    exact
                    component={InvestorReportsDetailsPage}
                  />
                  /* Historical Reports */
                  <Route
                    path="/historical-reports/search"
                    exact
                    component={HistoricalReportsSearchPage}
                  />
                  <Route
                    path="/historical-reports/results"
                    exact
                    component={HistoricalReportsSearchResultsPage}
                  />
                  <Route
                    path="/historical-reports/details"
                    exact
                    component={HistoricalReportsDetailsPage}
                  />
                  <Route
                    path="/historical-reports/details/:docKey"
                    exact
                    component={HistoricalReportsDetailsPage}
                  />
                  /* Industry Reports */
                  <Route
                    path="/industry-reports/"
                    exact
                    component={IndustryReportsListingPage}
                  />
                  <Route
                    path="/industry-reports/details"
                    exact
                    component={IndustryReportsDetailsPage}
                  />
                  <Route
                    path="/industry-reports/details/:docKey"
                    exact
                    component={IndustryReportsDetailsPage}
                  />
                  /* Mergers and Acquisitions */
                  <Route
                    path="/mergers-and-acquisitions/search"
                    exact
                    component={MergersAcquisitionsSearchPage}
                  />
                  <Route
                    path="/mergers-and-acquisitions/results"
                    exact
                    component={MergersAcquisitionsResultsPage}
                  />
                  <Route
                    path="/mergers-and-acquisitions/details"
                    exact
                    component={MergersAcquisitionsDetailsPage}
                  />
                  <Route
                    path="/mergers-and-acquisitions/details/:docKey"
                    exact
                    component={MergersAcquisitionsDetailsPage}
                  />
                  <Route
                    path="/mergers-and-acquisitions/csv-export"
                    exact
                    component={MergersAcquisitionsCsvExportPage}
                  />
                  /* New Issues */
                  <Route
                    path="/new-issues/search"
                    exact
                    component={NewIssuesSearchPage}
                  />
                  <Route
                    path="/new-issues/results"
                    exact
                    component={NewIssuesSearchResultsPage}
                  />{' '}
                  <Route
                    path="/new-issues/details"
                    exact
                    component={NewIssuesDetailsPage}
                  />
                  <Route
                    path="/new-issues/details/:docKey"
                    exact
                    component={NewIssuesDetailsPage}
                  />
                  <Route
                    path="/new-issues/csv-export"
                    exact
                    component={NewIssuesCsvExportPage}
                  />
                  /* Dividends */
                  <Route
                    path="/dividends/search"
                    exact
                    component={DividendsSearchPage}
                  />
                  <Route
                    path="/dividends/results"
                    exact
                    component={DividendsSearchResultsPage}
                  />{' '}
                  <Route
                    path="/dividends/details"
                    exact
                    component={DividendsDetailsPage}
                  />
                  <Route
                    path="/dividends/details/:docKey"
                    exact
                    component={DividendsDetailsPage}
                  />
                  /* Fixed Income */
                  <Route
                    path="/fixed-income/search"
                    exact
                    component={FixedIncomeSearchPage}
                  />
                  <Route
                    path="/fixed-income/results"
                    exact
                    component={FixedIncomeSearchResultsPage}
                  />
                  <Route
                    path="/fixed-income/details"
                    exact
                    component={FixedIncomeDetailsPage}
                  />
                  <Route
                    path="/fixed-income/details/:docKey"
                    exact
                    component={FixedIncomeDetailsPage}
                  />
                  <Route
                    path="/fixed-income/csv-export"
                    exact
                    component={FixedIncomeCsvExportPage}
                  />
                  /* Predecessor and Defunct */
                  <Route
                    path="/predecessor-and-defunct/search"
                    exact
                    component={PredecessorDefunctSearchPage}
                  />
                  <Route
                    path="/predecessor-and-defunct/results"
                    exact
                    component={PredecessorDefunctSearchResultsPage}
                  />
                  <Route
                    path="/predecessor-and-defunct/details"
                    exact
                    component={PredecessorDefunctDetailsPage}
                  />
                  <Route
                    path="/predecessor-and-defunct/details/:docKey"
                    exact
                    component={PredecessorDefunctDetailsPage}
                  />
                  <Route
                    path="/predecessor-and-defunct/csv-export"
                    exact
                    component={PredecessorDefunctCsvExportPage}
                  />

                  /* Directory of Directors Search */
                  <Route
                    path="/directory-of-directors/search"
                    exact
                    component={DirectorsSearchPage}
                  />
                  <Route
                    path="/directory-of-directors/results"
                    exact
                    component={DirectorsSearchResultsPage}
                  />
                  <Route
                    path="/directory-of-directors/details"
                    exact
                    component={DirectorsDetailsPage}
                  />
                  <Route
                    path="/directory-of-directors/details/:docKey"
                    exact
                    component={DirectorsDetailsPage}
                  />
                  <Route
                    path="/directory-of-directors/csv-export"
                    exact
                    component={DirectorsCsvExportPage}
                  />

                  /* Directory of Directors - Connctors - Search */
                  <Route
                    path="/directory-of-directors/connectors/results"
                    exact
                    component={DirectorsConnectorsSearchResultsPage}
                  />
                  <Route
                    path="/directory-of-directors/connectors/details"
                    exact
                    component={DirectorsConnectorsDetailsPage}
                  />
                  <Route
                    path="/directory-of-directors/connectors/details/:docKey"
                    exact
                    component={DirectorsConnectorsDetailsPage}
                  />

                  /* Person Search */
                  <Route
                    path="/person-search/search"
                    exact
                    component={PersonSearchPage}
                  />
                  <Route
                    path="/person-search/results"
                    exact
                    component={PersonSearchResultsPage}
                  />
                  {/* <Route path="/person-search/details/:companyKey" exact component=
                  {PersonSearchDetailsPage} /> */}
                  /* Lead List Generator */
                  <Route
                    path="/lead-list-generator"
                    exact
                    component={LeadListGeneratorIndexPage}
                  />
                  <Route
                    path="/lead-list-generator/search/fp-corporate-surveys"
                    exact
                    component={LeadListGeneratorSearchCorporateSurveysPage}
                  />
                  <Route
                    path="/lead-list-generator/search/fp-directory-of-directors"
                    exact
                    component={LeadListGeneratorSearchDirectoryOfDirectorsPage}
                  />
                  <Route
                    path="/lead-list-generator/search/:dbCode"
                    exact
                    component={LeadListGeneratorSearchExternalPage}
                  />
                  <Route
                    path="/lead-list-generator/results/:dbCode"
                    exact
                    component={LeadListGeneratorSearchResultsPage}
                  />
                  /* External Databases */
                  <Route
                    path="/external-databases"
                    exact
                    component={ExternalDatabasesIndexPage}
                  />
                  <Route
                    path="/external-databases/search/:dbCode"
                    exact
                    component={ExternalDatabasesSearchPage}
                  />
                  <Route
                    path="/external-databases/results/:dbCode"
                    exact
                    component={ExternalDatabasesSearchResultsPage}
                  />
                  <Route
                    path="/external-databases/details/:dbCode"
                    exact
                    component={ExternalDatabasesDetailsPage}
                  />
                  <Route
                    path="/external-databases/details/:dbCode/:docKey"
                    exact
                    component={ExternalDatabasesDetailsPage}
                  />
                  <Route
                    path="/external-databases/csv-export"
                    exact
                    component={ExternalDatabasesCsvExportPage}
                  />
                  /* Other/Standalone Pages */
                  <Route
                    path="/corporate-data"
                    exact
                    component={CorporateDataPage}
                  />
                  <Route
                    path="/ordering-products"
                    exact
                    component={OrderingProductsPage}
                  />
                  <Route path="/about-us" exact component={AboutUsPage} />
                  <Route path="/login" exact component={LoginPage} />
                  <Route path="/login/ip/:ipLoginUserName" exact component={LoginPage} />
                  <Route path="/forgot-password" exact component={LoginForgotPasswordPage} />
                  <Route path="/help" exact component={HelpIndexPage} />
                  <Route path="/help/details/:categorySlug" exact component={HelpDetailsPage} />
                  <Route path="/help/glossary" exact component={HelpGlossaryPage} />
                  <Route path="/help/abbreviations" exact component={HelpAbbreviationsPage} />
                  <Route path="/help/search-results" exact component={HelpSearchResultPage} />
                  <Route path="/settings" exact component={SettingsPage} />
                </Switch>
              </ScrollToTop>
            </ContentBesideSideNav>
          </Row>
        </HideWhenGlobalSearchResultsOpen>
      </ContentUnderTopNav>
      <HideWhenGlobalSearchResultsOpen>
        <HideOnPrint>
          <Footer />
        </HideOnPrint>
      </HideWhenGlobalSearchResultsOpen>
    </Providers>
  );
}

function Providers (props: { children: React.ReactNode }) {
  return (
    <>
      <DebugGridProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <BetaModalProvider>
              <SideNavProvider>
                <GlobalSearchProvider>
                  <BrowserRouter>{props.children}</BrowserRouter>
                </GlobalSearchProvider>
              </SideNavProvider>
            </BetaModalProvider>
            {/** dev tools */}
            <DebugToggle />
            <DebugBreakpoints />
            <ReactQueryDevtools />
          </ThemeProvider>
        </AuthProvider>
      </DebugGridProvider>
    </>
  );
}

export default App;
